import {ReactElement} from "react";
import {Dropdown, Field, Menu, Item, Select} from '@zendeskgarden/react-dropdowns';
import * as css from "../styles/propertyManagement.module.scss";

export interface IMonthDropdownProps {
    selectedMonth: string | undefined;
    months: string[];
    onMonthSelect: (selected: string | undefined) => void;
}

export default function ReforecastStartDropdown({selectedMonth, months, onMonthSelect}: IMonthDropdownProps): ReactElement {
    return (
        <Dropdown
            selectedItem={selectedMonth}
            onSelect={items => onMonthSelect(items ?? items[0])}
            downshiftProps={{defaultHighlightedIndex: 0}}
        >
            <Field>
                <Select>
                    {selectedMonth ? selectedMonth : <span style={{color: "var(--grey-300)"}}>Select Reforecast Start</span>}
                </Select>
            </Field>
            <Menu>
                {months.map(m => (
                    <Item
                        key={m}
                        value={m}
                        className={css.propertyDropdownItem}
                    >
                        <span>{m}</span>
                    </Item>
                ))}
            </Menu>
        </Dropdown>
    );
}
