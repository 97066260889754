import {ReactElement, useContext, useEffect, useState} from "react";
import useAppStore from "../../hooks/useAppStore";
import {ViziblyTheme} from "../analyst/ViziblyZDGTheme";
import {ThemeProvider} from "@zendeskgarden/react-theming";
import * as css from "./styles/budgetSeasonManagement.module.scss";
import AdminHeader from "../admin/AdminHeader";
import {useListBudgetYearsLazyQuery} from "../../__generated__/generated_types";
import {AuthContext} from "../../contexts/AuthContext";
import {Tab, TabList, TabPanel, Tabs} from "@zendeskgarden/react-tabs";
import BudgetSeasonsTab from "./components/BudgetSeasonsTab";
import ModelingMethodsTab from "./components/ModelingMethodsTab";
import PayrollEmployeesTab from "./components/PayrollEmployeesTab";
import RevenueAssumptionsTab from "./components/RevenueAssumptionsTab";

export default function BudgetSeasonManagement(): ReactElement {
    const appStore = useAppStore();

    const auth = useContext(AuthContext);
    const [selectedTab, setSelectedTab] = useState<string>("budget-seasons");

    const [getBudgetYears, {data: budgetYearsListData}] = useListBudgetYearsLazyQuery({
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        appStore.set({isLoading: false});

        getBudgetYears();
        return () => undefined;
    }, []);

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={css.wrapper}>
                <AdminHeader
                    title={"Budget Season Management"}
                    subtitle={"Roll budget season and adjust when reforecast starts"}
                />

                <Tabs selectedItem={selectedTab} onChange={setSelectedTab}>
                    <TabList>
                        <Tab item="budget-seasons">Budget Seasons</Tab>
                        <Tab item="modeling-methods">Modeling Methods</Tab>
                        <Tab item="payroll-employees">Payroll Employees</Tab>
                        <Tab item="revenue-assumptions">Revenue Assumptions</Tab>
                    </TabList>

                    <TabPanel item="budget-seasons">
                        <BudgetSeasonsTab
                            budgetYearsListData={budgetYearsListData}
                            onActivateBudgetYearComplete={() => {
                                getBudgetYears();
                                auth.reloadUserWithProperties();
                            }}
                            onAddBudgetYearComplete={() => {
                                getBudgetYears();
                                auth.reloadUserWithProperties();
                            }}
                            onBudgetYearLockingComplete={() => {
                                getBudgetYears();
                            }}
                            onBudgetYearSetIsAutoUnitTypeUpdateEnabledComplete={() => {
                                getBudgetYears();
                            }}
                        />
                    </TabPanel>

                    <TabPanel item="modeling-methods">
                        <ModelingMethodsTab budgetYearsListData={budgetYearsListData} />
                    </TabPanel>

                    <TabPanel item="payroll-employees">
                        <PayrollEmployeesTab budgetYearsListData={budgetYearsListData} />
                    </TabPanel>

                    <TabPanel item="revenue-assumptions">
                        <RevenueAssumptionsTab budgetYearsListData={budgetYearsListData} />
                    </TabPanel>
                </Tabs>
            </div>
        </ThemeProvider>
    );
}
