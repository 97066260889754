import {ReactElement, useEffect, useState} from "react";
import {Property} from "../../../contexts/properties/PropertiesContext";
import * as css from "../styles/propertyManagement.module.scss";
import {Button} from "@zendeskgarden/react-buttons";
import {useUpdateReforecastStartMonthMutation} from "../../../__generated__/generated_types";
import {MONTHS} from "../../../constants/Months";
import PropertyDropdown from "./PropertyDropdown";
import ReforecastStartDropdown from "./ReforecastStartDropdown";

export interface IPropertyLockBulkUpdaterProps {
    properties: Property[];
    onUpdateCompleted: () => void;
}

export default function PropertyReforecastStartBulkUpdater({properties, onUpdateCompleted}: IPropertyLockBulkUpdaterProps): ReactElement {
    const [selectedMonth, setSelectedMonth] = useState<string>();
    const [selectedProperties, setSelectedProperties] = useState<string[]>([]);
    const [saving, setSaving] = useState<boolean>(false);

    const [updateReforecastStartMonthIndex] = useUpdateReforecastStartMonthMutation({
        notifyOnNetworkStatusChange: true,
        onCompleted: () => {
            setSaving(false);
            resetForm();
            onUpdateCompleted();
        }
    });

    function resetForm() {
        setSelectedProperties([]);
        setSelectedMonth(undefined);
    }

    useEffect(
        () => {
            resetForm();
        },
        [properties]
    );

    return (
        <>
            <ol className={css.updaterControlsSteps}>
                <li>
                    Select Reforecast Start
                    <ReforecastStartDropdown
                        selectedMonth={selectedMonth}
                        months={MONTHS}
                        onMonthSelect={selected => {
                            setSelectedMonth(selected);
                        }}
                    />
                </li>
                <li>
                    Select Properties
                    <PropertyDropdown
                        selectedProperties={selectedProperties}
                        properties={properties}
                        onPropertySelect={selected => {
                            setSelectedProperties(selected);
                        }}
                    />
                </li>
            </ol>

            <div className={css.updaterControlsButtons}>
                {(selectedProperties.length > 0 || selectedMonth) &&
                    <Button
                        className={css.button}
                        isBasic
                        isDanger
                        onClick={resetForm}
                    >
                        Cancel
                    </Button>
                }

                <Button
                    className={css.button}
                    isPrimary
                    disabled={saving || selectedProperties.length == 0 || !selectedMonth}
                    onClick={() => {
                        if (!selectedMonth || selectedProperties.length == 0) {
                            return;
                        }
                        const monthIndex = MONTHS.indexOf(selectedMonth);
                        if (monthIndex < 0 || monthIndex > 11) {
                            return;
                        }
                        updateReforecastStartMonthIndex({
                            variables: {
                                reforecastStartMonthIndex: monthIndex,
                                propertyIds: selectedProperties
                            }
                        });
                        setSaving(true);
                    }}
                >
                    Save
                </Button>
            </div>
        </>
    );
}
