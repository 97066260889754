/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import useAppStore from '../../hooks/useAppStore';
import { saveStoreItem } from '../../utils/exports';
import { overviewSelector } from '../../utils/exports/contants';
import './CategoryHeader.scss';

interface CategoryHeaderProps {
    categoryName: string;
    level: number;
    needExpand?: () => void;
    needCollapse?: () => void;
    data?: any;
}

export const CategoryHeader: React.VFC<CategoryHeaderProps> = (props) => {

    const [isExpanded, setIsExpanded] = useState(false);
    const appStore = useAppStore();

    function toggleExpanded() {
        isExpanded ? props.needCollapse?.() : props.needExpand?.();
        setIsExpanded(!isExpanded);
        saveHistory();
    }

    const saveHistory = () => {
        const currentHistory = appStore.overviewSelectorItem?.parentkeys;
        let parentkeys: string[] = currentHistory || [];

        const thisItem = props.categoryName;

        parentkeys.push(thisItem);

        // if it's already expanded, remove from selectors;
        if (isExpanded) {
            const newKeys = currentHistory?.filter((a: any) => a !== thisItem) || [];
            parentkeys = newKeys;
        }

        appStore.set({ overviewSelectorItem: { parentkeys } });
        saveStoreItem(overviewSelector.storekey, parentkeys);
    };


    useEffect(() => {
        const hasKeyIncluded = appStore.overviewSelectorItem?.parentkeys?.includes(props.categoryName);
        if (hasKeyIncluded) {
            props.needExpand?.();
            setIsExpanded(true);
        }
        return () => { };
    }, [appStore.overviewSelectorItem?.parentkeys]);


    const Icon = isExpanded ? IoIosArrowDown : IoIosArrowForward;

    return (
        <td className="border-0 p-0 m-0 align-items-center">
            <div className="category-header tree-line d-flex justify-content-between h-100">
                <div className="flex-grow-1 text-start as-accounts">
                    {props.categoryName}
                </div>
                <Icon
                    className="mx-2 fs-5 text-philippine-grey"
                    onClick={toggleExpanded}
                />
            </div>
        </td>
    );
};
