import {ReactElement, useEffect, useMemo, useRef, useState} from "react";
import {IPortfolioFinancialTable, TDataRecord, TTableRow} from "./types";
import {buildDisplayRows, buildRows} from "./logic";
import {FinancialEntityType, useGetPortfolioSnapshotDataLazyQuery} from "../../__generated__/generated_types";
import {useAccountPropetiesDataLoader} from "./accountPropertiesDataLoader";
import {Button, ToggleButton} from "@zendeskgarden/react-buttons";
import HotTable from "@handsontable/react";
import {LICENSES} from "../../constants/Licenses";
import Handsontable, {CellCoords} from "handsontable";
import {AccountFilter} from "../../pages/portfolio-financial/components/account-filter/AccountFilter";
import {MONTHS} from "../../constants/Months";
import css from "../../pages/portfolio-financial/styles/css.module.scss";
import {formatterDollarUSNoDecimal} from "../../utils/formatters";
import { CellProperties } from 'handsontable/settings';
import { ReactComponent as MaximizeIcon } from '@zendeskgarden/svg-icons/src/16/maximize-stroke.svg';
import { ReactComponent as MinimizeIcon } from '@zendeskgarden/svg-icons/src/16/minimize-stroke.svg';
import { Home, ToggleOffOutlined, ToggleOn } from "@material-ui/icons";
import { BiFilter } from "react-icons/bi";
import ReactDOM from "react-dom";
import cn from "classnames";
import {Property} from "../../contexts/properties/PropertiesContext";


export function PortfolioFinancialTable(props: IPortfolioFinancialTable): ReactElement {
    const [fetchPortfolioData, {data: portfolioData, loading: portfolioDataLoading}] = useGetPortfolioSnapshotDataLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [data, setData] = useState<Record<string, TDataRecord>>({});
    const [properties, setProperties] = useState<Property[]>();
    const [rows, setRows] = useState<TTableRow[]>();
    const [displayRows, setDisplayRows] = useState<TTableRow[]>();
    // displayingRows contains subset of displayRows
    // once we introduced supressing zeroes - we broke the concept of displayRows always 1-1 match HOT rows
    // the displayingRows is now intended to be used for identifying table row based on HOT row
    const [displayingRows, setDisplayingRows] = useState<TTableRow[]>();
    const [collapsedRowIds, setCollapsedRowIds] = useState<string[]>();
    const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>();
    const [allRowsExpanded, setAllRowsExpanded] = useState<boolean>(false);
    const [isZeroSuppressed, setIsZeroSuppressed] = useState<boolean>(true);

    const [showAccountFilter, setShowAccountFilter] = useState(false);

    const [hotData, setHotData] = useState<unknown[][]>([]);
    const hotRef = useRef<HotTable>(null);

    const {loadDataForAccount: loadPropertiesValuesForAccount,
        data: accountPropertiesValues,
        reset: resetPropertiesData} =
        useAccountPropetiesDataLoader({snapshotId: props.snapshotId, propertyIds: props.properties.map(p => p.id)});

    const [lowestReforecastStartMonth, highestReforecastStartMonth] = useMemo(() => {
        if (!properties) {
            return [undefined, undefined]
        }

        const lowestReforecastStartMonth = Math.min(...properties.map(x => x.reforecastStartMonthIndex));
        const highestReforecastStartMonth = Math.max(...properties.map(x => x.reforecastStartMonthIndex));
        return [lowestReforecastStartMonth, highestReforecastStartMonth];
    }, [properties]);

    const COLHEADERS: string[] = [
        "Description",
        ...MONTHS.map(m => `
            <span>${(props.budgetYear - 1) % 100}</span>
            <span>${m}</span>
        `),
        ...MONTHS.map(m => `
            <span>${props.budgetYear % 100}</span>
            <span>${m}</span>
        `),
        `
            <span>${(props.budgetYear - 1) % 100}</span>
            <span>Total</span>
        `,
        `
            <span>${props.budgetYear % 100}</span>
            <span>Total</span>
        `,
        "Var $",
        "Var %"
    ];

    function triggerCollapsed(tableRow: TTableRow, collapsed: boolean) {
        if (!collapsed) {
            setCollapsedRowIds(prev =>
                prev?.filter(it => it != tableRow.id)
            );
        }
        else {
            setCollapsedRowIds(prev =>
                [...prev ?? [], tableRow.id]
            );
        }
    }

    function handleHotCellClick(_event: MouseEvent, coords: CellCoords) {
        if (coords.col > 0) {
            return;
        }
        // -1 row offset is to account for header row
        const tableRow = displayingRows?.[coords.row - 1];
        if (tableRow && !tableRow.isSubtotalRow && !tableRow.isPropertyRow) {
            triggerCollapsed(tableRow, !collapsedRowIds?.includes(tableRow.id));
        }
    }

    function handleExpandAll() {
        if (!rows) {
            return;
        }
        const ids = new Set<string>();
        for (const row of rows) {
            // As per discussion w/ Briant we expand to GL level and let them (users) expand to Property level when they want
            if (row.type === FinancialEntityType.Account) {
                ids.add(row.id);
            }
        }
        setAllRowsExpanded(true);
        setCollapsedRowIds(Array.from(ids));
    }

    function handleCollapseAll() {
        if (!rows) {
            return;
        }
        const ids = new Set<string>();
        for (const row of rows) {
            ids.add(row.id);
        }
        setAllRowsExpanded(false);
        setCollapsedRowIds(Array.from(ids));
    }

    useEffect(() => {
        fetchPortfolioData({
            variables: {
                snapshotId: props.snapshotId,
                propertyIds: props.properties.map(p => p.id),
            }
        });
        const rows = buildRows(props.chartOfAccountsTree);
        if (!collapsedRowIds) {
            setCollapsedRowIds(Array.from(new Set<string>(rows.map(row => row.id))));
        }
        if (!selectedAccountIds) {
            setSelectedAccountIds(rows.filter(row => !row.isPropertyRow && !row.isSubtotalRow).map(row => row.id));
        }
        setProperties(undefined);
        setRows(rows);
        resetPropertiesData();
    }, [props.chartOfAccountsTree, props.properties, props.snapshotId]);

    useEffect(() => {
        if (!rows || !selectedAccountIds || !properties) {
            return;
        }
        const displayRows = buildDisplayRows(rows, properties, collapsedRowIds ?? [], selectedAccountIds);
        setDisplayRows(displayRows);
    }, [rows, collapsedRowIds, selectedAccountIds, properties]);

    useEffect(() => {
        if (!displayRows) {
            return;
        }

        const EPSILON = 0.01;
        const hotData = [] as string[][];
        const displayingRows:TTableRow[] = [];
        for (const row of displayRows) {
            let values: string[] = new Array(28).fill(null);
            let name: string | undefined = undefined;
            if (row.isPropertyRow) {
                name = row.name as string;
                const accountId = row.parentIds[0];
                if (accountId) {
                    const accountPropertiesData = accountPropertiesValues[accountId];
                    if (accountPropertiesData) {
                        let propAccountValues = accountPropertiesData[row.id]

                        if (propAccountValues) {
                            const firstTwelveValues = propAccountValues.slice(0, 12);
                            const lastTwelveValues = propAccountValues.slice(12, 24);
                            const rfcstTotal = firstTwelveValues.sum();
                            const bdgtTotal = lastTwelveValues.sum();
                            let varianceAmount = null;
                            let variancePercent = null;
                            if (Math.abs(rfcstTotal) < EPSILON) {
                                varianceAmount = Math.abs(bdgtTotal) < EPSILON ? 0 : bdgtTotal;
                                variancePercent = 0;
                            } else {
                                varianceAmount = bdgtTotal - rfcstTotal;
                                variancePercent = parseFloat(((bdgtTotal / rfcstTotal - 1) * 100).toFixed(1));
                            }

                            propAccountValues = [
                                ...firstTwelveValues,
                                ...lastTwelveValues,
                                rfcstTotal,
                                bdgtTotal,
                                varianceAmount,
                            ];
                            values = propAccountValues.map(v => formatterDollarUSNoDecimal.format(v));
                            values.push(variancePercent.toString() + "%");
                        } else {
                            values = new Array(28).fill(null);
                        }
                    }
                    else {
                        loadPropertiesValuesForAccount(accountId);
                    }
                }
            }
            else {
                const {name: glName, number: glNumber} = row.name as {name: string, number: string;};
                const dbData = data[row.id];
                name = glNumber ? glNumber + " " + glName : glName
                if (row.isSubtotalRow) {
                    name = "Total " + name;
                }
                else if (row.type !== FinancialEntityType.Component && !row.isPropertyRow) {
                    name = (collapsedRowIds?.includes(row.id) ? "+ " : "- ") + name;
                }
                if (dbData) {
                    const firstTwelveValues = dbData.values.slice(0, 12);
                    const lastTwelveValues = dbData.values.slice(12, 24);
                    const rfcstTotal = firstTwelveValues.sum();
                    const bdgtTotal = lastTwelveValues.sum();
                    let varianceAmount = null;
                    let variancePercent = null;
                    if (Math.abs(rfcstTotal) < EPSILON) {
                        varianceAmount = Math.abs(bdgtTotal) < EPSILON ? 0 : bdgtTotal;
                        variancePercent = 0;
                    } else {
                        varianceAmount = bdgtTotal - rfcstTotal;
                        variancePercent = parseFloat(((bdgtTotal / rfcstTotal - 1) * 100).toFixed(1));
                    }

                    dbData.values = [
                        ...firstTwelveValues,
                        ...lastTwelveValues,
                        rfcstTotal,
                        bdgtTotal,
                        varianceAmount,
                    ];
                    values = dbData.values.map(v => formatterDollarUSNoDecimal.format(v));
                    values.push(variancePercent.toString() + "%");
                }
            }
            // gives the indentation for nested rows
            const extraPropertyRowIndent = row.isPropertyRow ? 10 : 0;
            name = `<span style='padding-left: ${row.parentIds.length * 10 + extraPropertyRowIndent}px'>${name}</span>`;

            if (isZeroSuppressed && values.every(val => val === "$0" || val === "0%" || val === "0" || val === null)) {
                continue;
            }

            hotData.push([name ?? "", ...values]);
            displayingRows.push(row);
        }

        hotData.unshift(COLHEADERS);

        setHotData(hotData);
        setDisplayingRows(displayingRows);
    }, [data, displayRows, accountPropertiesValues, isZeroSuppressed, selectedAccountIds]);

    useEffect(() => {
        if (!portfolioData || portfolioDataLoading) {
            return;
        }
        const data: Record<string, TDataRecord> = {};
        for (const {id, values} of portfolioData.getPortfolioSnapshotData.accountValues) {
            data[id] = {
                id,
                values: [...values],
            };
        }
        setData(data);
        const properties: Property[] = [];
        for (const property of props.properties) {
            let snapshotRfrcstStartMonth = portfolioData.getPortfolioSnapshotData.propertiesData.find(p => p.id == property.id)?.reforecastStartMonthIndex;
            if (snapshotRfrcstStartMonth === undefined) {
                snapshotRfrcstStartMonth = property.reforecastStartMonthIndex;
            }
            properties.push({
                ...property,
                reforecastStartMonthIndex: snapshotRfrcstStartMonth
            });
        }
        setProperties(properties);
    }, [portfolioData, portfolioDataLoading]);

    // following is a test but implements react component rendering within a cell
    // function for rendering react component in cell for properties
    function propertyCellRenderer(
        _instance: Handsontable,
        td: HTMLTableCellElement,
        _row: number,
        _col: number,
        _prop: string | number,
        value: any,
        _cellProperties: Handsontable.CellProperties
    ) {
        ReactDOM.render(
            <>
                <div className={css.propertyNameCell}>
                    <Home />{value}
                </div>
            </>,
            td
        );

        return td;
    }

    // called for each cell on render to let us assign custom classes or perform other actions
    function cellRenderer(this: CellProperties, _row: number, _col: number) {
        const budgetStartColumn = 13;
        const summaryStartColumn = 25;

        // render first column contents as html instead of string
        if (_col == 0 || _row == 0) {
            this.renderer = "html";
        }

        // add thick vertical bar to left of budget year start column
        if (_col == budgetStartColumn) {
            this.className += ` ${css.budgetCell}`;
        }

        if (_col >= summaryStartColumn) {
            this.className += ` ${css.summaryColumn}`;
        }

        if (_col == summaryStartColumn) {
            this.className += ` ${css.summaryColumnFirst}`;
        }

        if (!displayingRows || _row == 0 || lowestReforecastStartMonth == undefined || highestReforecastStartMonth == undefined) {
            return this;
        }

        const isMixedReforecastColumn = _col > lowestReforecastStartMonth && _col <= highestReforecastStartMonth;

        const rowData = displayingRows[_row - 1]; // the table data has extra row - header row. display rows do not have that row. hence shift

        if (!rowData) {
            return this;
        }

        // shade cells for a property row that fall within their reforecast months or the budget year
        if (_row > 0 &&
            _col > highestReforecastStartMonth &&
            _col < summaryStartColumn ||
            (rowData.reforecastStartMonthIndex !== undefined && rowData.isPropertyRow && _col > rowData.reforecastStartMonthIndex && _col < summaryStartColumn)
        ) {
            this.className += ` ${css.shadedCellFull}`;
        }

        if (_row > 0 && isMixedReforecastColumn && !rowData.isPropertyRow) {
            this.className += ` ${css.shadedCellHalf}`;
        }

        // add styling for first column and all rows not including the header row
        if (_col == 0 && _row > 0) {
            const rowData = displayingRows[_row];
            if (rowData && rowData.type !== FinancialEntityType.Component && !rowData.isPropertyRow) {
                this.className += ` ${css.expandableCol}`;
            }
        }

        // TODO:bowman decide on property row styling
        // if (_col == 0 && _row > 0 && rowData.isPropertyRow) {
        //     return { ...this, renderer: propertyCellRenderer };
        // }

        return this;
    }

    return (
        <div className={css.tableWrapper}>
            <div className={css.tableActions}>
                <div className={css.actionsLeft}>
                    {
                        allRowsExpanded ?
                            <Button onClick={handleCollapseAll} size="small" isBasic><MinimizeIcon /></Button>
                            : <Button onClick={handleExpandAll} size="small" isBasic><MaximizeIcon /></Button>
                    }

                    <div className={css.legend}>
                        <div className={css.legendBox}></div>
                        <span className={css.legendText}>RFCST/BDGT</span>
                    </div>

                    <div className={css.legend}>
                        <div className={cn(css.legendBox, css.legendHalfShade)}></div>
                        <span className={css.legendText}>Mix of RFCST/ACTUALS</span>
                    </div>
                </div>

                <div className={css.actionsRight}>
                    { props.propertiesFilter }

                    <Button onClick={() => setShowAccountFilter(true)}>
                        <Button.StartIcon><BiFilter /></Button.StartIcon>
                        Filter Accounts
                    </Button>

                    <ToggleButton
                        isBasic
                        onClick={() => setIsZeroSuppressed(!isZeroSuppressed)}
                    >
                        { isZeroSuppressed ?
                            <ToggleOn fontSize="large" />
                            : <ToggleOffOutlined fontSize="large" />
                        }
                        <span style={{marginLeft: ".5rem"}}>Suppress Zeroes</span>
                    </ToggleButton>
                </div>
            </div>
            <div className={css.tableOuter}>
                <HotTable
                    tableClassName={css.table}
                    ref={hotRef}
                    data={hotData}
                    columnSorting={true}
                    settings={{
                        width: "100%",
                        height: "100%",
                        afterOnCellMouseDown: handleHotCellClick,
                        fixedRowsTop: 1,
                        fixedColumnsLeft: 1,
                        readOnly: true,
                        rowHeights: 60,
                        licenseKey: LICENSES.HandsOnTable
                    }}
                    cells={cellRenderer}
                />

                {showAccountFilter && selectedAccountIds &&
                    <AccountFilter
                    chartOfAccountsTree={props.chartOfAccountsTree}
                    onClose={() => setShowAccountFilter(false)}
                    onConfirm={(ids) => {setSelectedAccountIds(ids); setShowAccountFilter(false);}}
                    selectedAccountIds={selectedAccountIds}
                    />
                }
            </div>
        </div>
    );
}