import { ThemeProvider } from "@zendeskgarden/react-theming";
import { ReactElement, useEffect, useState } from "react";
import { ViziblyTheme } from "../analyst/ViziblyZDGTheme";
import AdminHeader from "../admin/AdminHeader";
import * as css from "./styles/coaManagement.module.scss";
import useAppStore from "../../hooks/useAppStore";
import { FinancialEntityType, useGetCoaManagementDataLazyQuery, useUpdateCoaManagementDataMutation } from "../../__generated__/generated_types";
import { Button } from "@zendeskgarden/react-buttons";

import { FixedSizeList, ListChildComponentProps } from "react-window";
import { DragDropContext, Droppable, DragStart, Draggable, DropResult, DragUpdate } from "react-beautiful-dnd";
import { Field as FormField, MediaInput } from '@zendeskgarden/react-forms';
import { ReactComponent as SearchIcon } from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import { ReactComponent as MaximizeIcon } from '@zendeskgarden/svg-icons/src/16/maximize-stroke.svg';
import { ReactComponent as MinimizeIcon } from '@zendeskgarden/svg-icons/src/16/minimize-stroke.svg';
import { ReactComponent as AlertIcon } from '@zendeskgarden/svg-icons/src/16/alert-warning-fill.svg';

import "native-injects";
import { v4 as uuid } from "uuid";
import { ItemData, itemText, PMSAccount } from "./types";
import { MAX_DELETED_ACCOUNTS, reorder, reparent } from "./utilities";
import { EditRecord } from "./components/EditRecord";
import { Item } from "./components/Item";
import AccountStatusModal from "./components/AccountStatusModal";
import DeleteConfirmModal from "./components/DeleteConfirmModal";
import {toast} from "react-toastify";
import cn from "classnames";
import { Inline } from "@zendeskgarden/react-loaders";
import { COLORS } from "../../constants/Colors";
import {useChartOfAccounts} from "../../contexts/chartofaccounts/ChartOfAccountsContext";
import {Tooltip} from "@zendeskgarden/react-tooltips";


export default function COAManagement(): ReactElement {
    const appStore = useAppStore();
    const coaContext = useChartOfAccounts();
    const [loadChartOfAccountsData, { data: chartOfAccountsData, loading: chartOfAccountsLoading }] = useGetCoaManagementDataLazyQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
    });

    const [saveChanges, {loading: isSavingChanges}] = useUpdateCoaManagementDataMutation({
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
            if (data?.updateChartOfAccountsById === true) {
                toast.success(`Chart Of Accounts Updated Successfully`);
            }
            else {
                toast.error(`Failed To Update Chart Of Accounts`);
            }
            if (coaContext.chartOfAccountsId) {
                loadChartOfAccountsData({
                    variables: {
                        chartOfAccountsId: coaContext.chartOfAccountsId
                    }
                });
            }
            reset();
        },
    });

    const [items, setItems] = useState<ItemData[]>([]);
    const [pmsAccounts, setPMSAccounts] = useState<PMSAccount[]>([]);
    const [displayItems, setDisplayItems] = useState<ItemData[]>([]);
    const [inAlert, setInAlert] = useState<Set<string>>(new Set());
    const [showInAlertOnly, setShowInAlertOnly] = useState(false);
    const [deletedExistingItems, setDeletedExistingItems] = useState<ItemData[]>([]);
    const [searchString, setSearchString] = useState<string>();
    const [destinationAllowed, setDestinationAllowed] = useState(true);
    const [draggedItemDisplayIndex, setDraggedItemDisplayIndex] = useState<number>();
    const [draggedCount, setDraggedCount] = useState<number>();
    const [addNewRecord, setAddNewRecord] = useState<{ afterId: string }>();
    const [updateRecord, setUpdateRecord] = useState<{ recordId: string, glNumber: string | undefined, glName: string, type: FinancialEntityType }>();
    const [showItemInfo, setShowItem] = useState<ItemData>();
    const [deleteItemInfo, setDeleteItemInfo] = useState<ItemData>();
    const [hasChanges, setHasChanges] = useState(false);

    function handleSaveChanges() {
        if (chartOfAccountsData && coaContext.chartOfAccountsId) {
            saveChanges({
                variables: {
                    chartOfAccountsId: coaContext.chartOfAccountsId,
                    versionNum: chartOfAccountsData?.listChartOfAccountsById.versionNum,
                    records: items.filter(item => !item.isDeleted).map(item => ({
                        id: item.id,
                        glName: item.glName,
                        glNumber: item.glNumber,
                        type: item.type,
                        parentIds: item.parentIds.copy()
                    }))
                }
            });
        }
    }

    function handleCollapse(item: ItemData, collapsed: boolean) {
        const foundItem = items.find(it => it.id == item.id);
        if (foundItem) {
            foundItem.collapsed = collapsed;
        }
        updateDisplayItems(items, searchString, undefined, undefined, showInAlertOnly);
    }

    function handleCollapseAll(collapsed: boolean) {
        for (const item of items) {
            if (item.type != FinancialEntityType.Account) {
                item.collapsed = collapsed;
            }
        }
        updateDisplayItems(items, searchString, undefined, undefined, showInAlertOnly);
    }

    function COARow(props: ListChildComponentProps<ItemData[]>) {
        const { data: displayItems, index, style } = props;
        const item = displayItems[index];
        if (!item) return null;
        return (
            <Draggable draggableId={item.id} index={index} key={item.id} isDragDisabled={item.type == FinancialEntityType.Component || addNewRecord != undefined || updateRecord != undefined}>
                {(provided, _snapshot, rubric) =>
                    <Item
                        provided={provided}
                        item={item}
                        handleCollapse={(item: ItemData, collapsed: boolean) => handleCollapse(item, collapsed)}
                        isDeleteDisabled={deletedExistingItems.length >= MAX_DELETED_ACCOUNTS}
                        isAllDisabled={addNewRecord != undefined || updateRecord != undefined}
                        isAddingAfter={item.id == addNewRecord?.afterId}
                        isEditing={item.id == updateRecord?.recordId}
                        isInAlert={inAlert.has(item.id)}
                        hasAddedChildren={items.find(row => row.isNew && row.parentIds.includes(item.id)) != undefined}
                        hasDeletedChildren={deletedExistingItems.find(row => row.parentIds.includes(item.id)) != undefined}
                        onInfoClicked={(item) => setShowItem(item)}
                        onAddClicked={(item) => setAddNewRecord({ afterId: item.id })}
                        onEditClicked={(item) => setUpdateRecord({ recordId: item.id, glNumber: item.glNumber ?? undefined, glName: item.glName, type: item.type })}
                        onDeleteClicked={(item) => setDeleteItemInfo(item)}
                        index={rubric.source.index}
                        style={style}
                    />}
            </Draggable>
        );
    }

    function onDragStart(data: DragStart) {
        setDraggedItemDisplayIndex(data.source.index);
        const sourceItem = displayItems[data.source.index];
        if (sourceItem) {
            const sourceIndex = getIndex(items, sourceItem.id);
            const draggedCount = items.count(item => item.parentIds.includes(sourceItem.id));
            setDraggedCount(draggedCount);
            const filterEndIndex = sourceIndex + draggedCount;
            updateDisplayItems(items, searchString, sourceIndex, filterEndIndex, showInAlertOnly);
        }
    }

    function updateDisplayItems(sourceItems: ItemData[], searchString: string | undefined, skipIndexStart: number | undefined, skipIndexEnd: number | undefined, showInAlertOnly: boolean) {
        let updated = [...sourceItems];
        if (skipIndexStart != undefined && skipIndexEnd != undefined) {
            updated = updated.filter((_, idx) => idx <= skipIndexStart || idx > skipIndexEnd);
        }
        if (showInAlertOnly) {
            const temp = updated.filter(item => inAlert.has(item.id));
            const parentIds = temp.flatMap(item => item.parentIds).dedupe();
            updated = updated.filter(item =>
                inAlert.has(item.id)
                || parentIds.includes(item.id)
            );
        }
        if (searchString && searchString.length > 0) {
            const temp = updated.filter(item => itemText(item).toLowerCase().includes(searchString.toLowerCase()));
            const parentIds = temp.flatMap(item => item.parentIds).dedupe();
            updated = updated.filter(item =>
                itemText(item).toLowerCase().includes(searchString.toLowerCase())
                // I want to show user the path to the top level from the item filtered
                || parentIds.includes(item.id)
            );
        }
        else {
            const collapsedIds = sourceItems.filter(item => item.collapsed).map(item => item.id);
            updated = updated.filter(item => !item.parentIds.some(id => collapsedIds.includes(id)));
        }
        setDisplayItems(updated);
    }

    function getIndex(items: ItemData[], id: string): number {
        return items.findIndex(item => item.id == id);
    }

    function handleAddRecord(glNumber: string | undefined, glName: string, type: FinancialEntityType) {
        if (addNewRecord?.afterId) {
            const updated: ItemData[] = [];
            for (const item of items) {
                updated.push(item);
                if (item.id == addNewRecord.afterId) {
                    const parentIds = [...item.parentIds];
                    if (item.type != FinancialEntityType.Account) {
                        parentIds.push(item.id);
                    }
                    const newRecord: ItemData = {
                        id: uuid(),
                        glNumber: glNumber,
                        glName: glName,
                        parentIds: parentIds,
                        type: type,

                        isNew: true,
                        collapsed: false,
                        isDeleted: false,
                    };
                    updated.push(newRecord);
                }
            }
            if (updated.length > items.length) {
                setItems(updated);
            }
        }
        setAddNewRecord(undefined);
        updateInAlert(items, pmsAccounts);
    }

    function handleUpdateRecord(glNumber: string | undefined, glName: string) {
        if (updateRecord?.recordId) {
            const updated: ItemData[] = [];
            for (const item of items) {
                updated.push(item);
                if (item.id == updateRecord.recordId) {
                    item.glNumber = glNumber;
                    item.glName = glName;
                }
            }
            setItems(updated);
        }
        setUpdateRecord(undefined);
        updateInAlert(items, pmsAccounts);
    }

    function handleDeleteRecord(item: ItemData) {
        const updated: ItemData[] = [];
        const deletedExistingAccounts: ItemData[] = [];
        for (const row of items) {
            if (row.id == item.id || row.parentIds.includes(item.id)) {
                row.isDeleted = true;
                if (row.type == "ACCOUNT" && !row.isNew) {
                    deletedExistingAccounts.push(row);
                }
                if (!row.isNew) {
                    updated.push(row);
                }
            }
            else {
                updated.push(row);
            }
        }
        if (deletedExistingAccounts.length > 0) {
            setDeletedExistingItems(prev => prev.concat(deletedExistingAccounts));
        }
        setItems(updated);
    }
    function onDragEnd(result: DropResult) {
        setDraggedItemDisplayIndex(undefined);
        setDraggedCount(undefined);
        const draggedItem: ItemData | undefined = displayItems[result.source.index];
        let insertAfterItem: ItemData | undefined = undefined;
        let newParent: ItemData | undefined = undefined;

        if (result.combine) {
            insertAfterItem = items.find(item => item.id == result.combine?.draggableId);
            if (insertAfterItem) {
                newParent = insertAfterItem;
                if (insertAfterItem.type == FinancialEntityType.Account) {
                    // Put dragged item right after the account line
                    const newParentId = insertAfterItem.parentIds.lastElement;
                    newParent = items.find(item => item.id == newParentId);
                }
            }
        }
        else if (result.destination
            && result.destination.index != result.source.index
            && result.destination.index != 0
        ) {
            let destinationIndex = result.destination.index;
            if (destinationIndex < result.source.index) {
                destinationIndex--;
            }
            insertAfterItem = displayItems[destinationIndex];
            if (insertAfterItem) {
                if (insertAfterItem.type == FinancialEntityType.Component) {
                    newParent = insertAfterItem;
                }
                else if (insertAfterItem.type == FinancialEntityType.Account) {
                    // Put dragged item right after the account line
                    const newParentId = insertAfterItem.parentIds.lastElement;
                    newParent = items.find(item => item.id == newParentId);
                }
                else { // Category
                    if (!insertAfterItem.collapsed) {
                        newParent = insertAfterItem;
                    }
                    else {
                        // Put dragged item right after the last child of the "insertAfterItem"'s sub-tree
                        const newParentId = insertAfterItem.parentIds.lastElement;
                        newParent = items.find(item => item.id == newParentId);
                        const insertAfterItemId = insertAfterItem.id;
                        insertAfterItem = items.filter(item => item.parentIds.includes(insertAfterItemId)).lastElement;
                    }
                }
            }
        }
        if (!draggedItem || !insertAfterItem) {
            updateDisplayItems(items, searchString, undefined, undefined, showInAlertOnly);
            return;
        }

        if (newParent) {
            reparent(items, draggedItem, newParent);
        }

        const sourceIndex = getIndex(items, draggedItem.id);
        const destIndex = getIndex(items, insertAfterItem.id);
        const newItems = reorder(
            items,
            sourceIndex,
            destIndex,
            draggedCount
        );
        setItems(newItems);
    }
    function onDragUpdate(result: DragUpdate) {
        if (!result.destination) {
            return;
        }
        if (draggedItemDisplayIndex != undefined && draggedItemDisplayIndex != result.destination.index) {
            let destIndex = result.destination.index;
            if (draggedItemDisplayIndex > result.destination.index) {
                destIndex = result.destination.index - 1;
            }
            if (destIndex < 0) {
                setDestinationAllowed(false);
            }
            else {
                setDestinationAllowed(true);
            }
        }
        else {
            setDestinationAllowed(true);
        }
    }

    function updateInAlert(items: ItemData[], pmsAccounts: PMSAccount[]) {
        if (!pmsAccounts.length) {
            return;
        }
        const inAlert = new Set<string>();
        const latestDate = pmsAccounts.maxValue(item => item.seenLatest);
        const latestAccounts = pmsAccounts.filter(item => item.seenLatest == latestDate);
        for (const item of items) {
            if (item.type !== FinancialEntityType.Account) {
                continue;
            }
            const pmsAccountFound = latestAccounts.find(pms => pms.glName == item.glName && pms.glNumber == item.glNumber);
            if (!pmsAccountFound) {
                inAlert.add(item.id);
            }
        }
        setInAlert(inAlert);
    }

    function reset() {
        setSearchString("");
        if (chartOfAccountsData && !chartOfAccountsLoading) {
            const initialItems: ItemData[] = [];
            for (const row of chartOfAccountsData.listChartOfAccountsById.records) {
                initialItems.push({
                    id: row.id,
                    glNumber: row.glNumber,
                    glName: row.glName,
                    parentIds: row.parentIds.copy(),
                    type: row.type,

                    isNew: false,
                    collapsed: false,
                    isDeleted: false,
                });
            }
            setItems(initialItems);
            const pmsAccounts = chartOfAccountsData.fetchPMSAccounts.filter(acc => acc.type === "ACCOUNT");
            setPMSAccounts(pmsAccounts);
            updateInAlert(initialItems, pmsAccounts);
            setDeletedExistingItems([]);
        }
    }

    useEffect(
        () => {
            appStore.set({ isLoading: false });
            reset();
            return () => undefined;
        },
        [chartOfAccountsData, chartOfAccountsLoading]
    );

    useEffect(
        () => {
            updateDisplayItems(items, searchString, undefined, undefined, showInAlertOnly);
        },
        [searchString]
    );

    useEffect(() => {
      updateDisplayItems(items, searchString, undefined, undefined, showInAlertOnly);
    }, [showInAlertOnly]);


    useEffect(() => {
        if (items) {
            if (chartOfAccountsData) {
                let hasChanges = false;
                if (chartOfAccountsData.listChartOfAccountsById.records.length != items.filter(row => !row.isDeleted).length) {
                    hasChanges = true;
                }
                else {
                    for (let i = 0; i < items.length && !hasChanges; i++) {
                        const fromDb = chartOfAccountsData.listChartOfAccountsById.records[i];
                        const local = items[i];
                        hasChanges = !fromDb || !local || !(
                            fromDb.id == local.id
                            && fromDb.glNumber == local.glNumber
                            && fromDb.glName == local.glName
                            && fromDb.parentIds.equals(local.parentIds)
                        );
                    }
                }

                setHasChanges(hasChanges);
            }
            updateDisplayItems(items, searchString, undefined, undefined, showInAlertOnly);
        }
    }, [items]);

    useEffect(() => {
        if (coaContext.chartOfAccountsId) {
            loadChartOfAccountsData({
                variables: {
                    chartOfAccountsId: coaContext.chartOfAccountsId
                }
            });
        }
    }, [coaContext.chartOfAccountsId]);

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={css.wrapper}>
                <AdminHeader
                    title={"Chart Of Accounts Management"}
                    subtitle={"Add/Rename/Delete/Move around Accounts"}
                />
                <div className={css.updaterControlsCard}>
                    <div className={cn(
                        css.deletedItems,
                        deletedExistingItems.length > 0 ? css.highlight : undefined
                    )}>
                        Existing Accounts Deleted: {deletedExistingItems.length} of {MAX_DELETED_ACCOUNTS}
                    </div>
                    <div className={css.updaterControlsButtons}>
                        {hasChanges && !isSavingChanges &&
                        <Button
                            className={css.button}
                            isDanger
                            onClick={() => reset()}
                        >
                            Cancel
                        </Button>
                        }
                        <Button
                            className={css.button}
                            isPrimary
                            disabled={!hasChanges || isSavingChanges}
                            onClick={() => handleSaveChanges()}
                        >
                            {isSavingChanges
                            ? <Inline size={24} color={COLORS.POSITIVE_COLOR} aria-label="loading"/>
                            : "Save Changes"
                            }
                        </Button>
                    </div>
                </div>
                <div className={css.updaterControlsCard}>
                    <FormField className={css.inputBox}>
                        <MediaInput start={<SearchIcon />} value={searchString} placeholder="Search" onChange={e => setSearchString(e.target.value)} />
                    </FormField>
                    <div className={css.updaterControlsButtons}>
                        <Button
                            className={css.button}
                            isBasic={!showInAlertOnly}
                            isPrimary={showInAlertOnly}
                            onClick={() => setShowInAlertOnly(prev => !prev)}
                        >
                            <Tooltip content="Filter Accounts not found in PMS">
                                <AlertIcon style={{color: "var(--yellow-200)"}}/>
                            </Tooltip>
                        </Button>
                        <Button
                            className={css.button}
                            isBasic
                            onClick={() => handleCollapseAll(true)}
                            disabled={(searchString?.length ?? 0) > 0}
                        >
                            <MinimizeIcon/>
                        </Button>
                        <Button
                            className={css.button}
                            isBasic
                            onClick={() => handleCollapseAll(false)}
                            disabled={(searchString?.length ?? 0) > 0}
                        >
                            <MaximizeIcon/>
                        </Button>
                    </div>
                </div>
                {addNewRecord &&
                    <EditRecord
                        onCancel={() => setAddNewRecord(undefined)}
                        onSubmit={(glNumber, glName, type) => handleAddRecord(glNumber, glName, type)}
                        title="Add New Line"
                        initialType={FinancialEntityType.Account}
                        allowChooseType={true}
                        existingItems={items}
                        pmsAccounts={pmsAccounts}
                    />
                }
                {updateRecord &&
                    <EditRecord
                        title="Edit"
                        allowChooseType={false}
                        initialGlNumber={updateRecord.glNumber}
                        initialGlName={updateRecord.glName}
                        initialType={updateRecord.type}
                        itemId={updateRecord.recordId}
                        onCancel={() => setUpdateRecord(undefined)}
                        onSubmit={(glNumber, glName) => handleUpdateRecord(glNumber, glName)}
                        existingItems={items}
                        pmsAccounts={pmsAccounts}
                    />
                }
                <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate} onDragStart={onDragStart}>
                    <Droppable
                        droppableId="droppable"
                        mode="virtual"
                        isCombineEnabled
                        renderClone={(provided, snapshot, rubric) => (
                            <Item
                                provided={provided}
                                isDragging={snapshot.isDragging}
                                item={displayItems[rubric.source.index]}
                                isInAlert={inAlert.has(displayItems[rubric.source.index]?.id ?? "")}
                                isDestinationAllowed={destinationAllowed}
                                index={rubric.source.index}
                            />
                        )}
                    >
                        {provided => (
                            <FixedSizeList<ItemData[]>
                                height={900}
                                itemCount={displayItems.length}
                                itemSize={80}
                                width={"100%"}
                                outerRef={provided.innerRef}
                                itemData={displayItems}
                            >
                                {COARow}
                            </FixedSizeList>
                        )}
                    </Droppable>
                </DragDropContext>
                {showItemInfo && chartOfAccountsData &&
                <AccountStatusModal
                    item={showItemInfo}
                    title={(showItemInfo.glNumber ? showItemInfo.glNumber + " - " : "") + showItemInfo.glName}
                    onClose={() => setShowItem(undefined)}
                />
                }
                {deleteItemInfo && chartOfAccountsData &&
                <DeleteConfirmModal
                    item={deleteItemInfo}
                    allItems={items}
                    deletedExistingItems={deletedExistingItems}
                    onConfirm={() => {handleDeleteRecord(deleteItemInfo); setDeleteItemInfo(undefined);}}
                    onClose={() => setDeleteItemInfo(undefined)}
                />
                }
            </div>
        </ThemeProvider>
    );
}