import { FinancialEntityType } from "../../../__generated__/generated_types";
import { FinancialEntity } from "../../../contexts/chartofaccounts/ChartOfAccountsContext";
import { SelectableFinancialEntity } from "../PropertyDrivers";


export const checkSearchQueryMatch = (accountName: string, accountNumber: string, searchQuery: string): boolean => {
    const matchedWithName = accountName.toLowerCase().includes(searchQuery.toLowerCase());
    const matchedWithNumber = accountNumber.includes(searchQuery);
    return matchedWithName || matchedWithNumber;
};


/**
 * Let users query the account name and number for matching results
 * Avoid running .filter() unnecessarily if the input query is emptied
 */
export const getFilteredAccounts = (accounts: SelectableFinancialEntity[], filterInput: string): SelectableFinancialEntity[] =>
    filterInput.length === 0
        ? accounts
        : accounts.filter((account) => {
            return checkSearchQueryMatch(account.name, account.number, filterInput);
        });

export const getFilteredCategories = (categories: FinancialEntity[], filterInput: string): FinancialEntity[] =>
    filterInput.length === 0
        ? categories
        : categories.filter((category) => {
            return checkSearchQueryMatch(category.name, category.number, filterInput);
        });


        // 'length > 0' check because 'every()' returns true when the array is empty
export const areAllAccountsSelected = (accounts: SelectableFinancialEntity[]): boolean =>
    accounts.length > 0
    && accounts.every((account: SelectableFinancialEntity) => account.isSelected);


export const areAllFilteredAccountsSelected = (filteredAccounts: SelectableFinancialEntity[]): boolean =>
    filteredAccounts.length > 0
    && filteredAccounts.every((account: SelectableFinancialEntity) => account.isSelected);


export const isSelectAllSelected = (
    accounts: SelectableFinancialEntity[],
    filteredAccounts: SelectableFinancialEntity[],
    searchInput: string,
): boolean => searchInput.length > 0 ? areAllFilteredAccountsSelected(filteredAccounts) : areAllAccountsSelected(accounts);


export const isIndeterminate = (
    accounts: SelectableFinancialEntity[],
    filteredAccounts: SelectableFinancialEntity[],
    searchInput: string,
): boolean => {
    if (searchInput.length > 0) {
        return filteredAccounts.some((account: SelectableFinancialEntity) =>
            account.isSelected === false)
            && filteredAccounts.some((account: SelectableFinancialEntity) => account.isSelected === true);
    }
    return accounts.some((account: SelectableFinancialEntity) =>
        account.isSelected === false)
        && accounts.some((account: SelectableFinancialEntity) => account.isSelected === true);
};

export function getChildAccounts(category: FinancialEntity): SelectableFinancialEntity[] {
    const result: SelectableFinancialEntity[] = [];

    for (const child of category.children) {
        if (child.type === FinancialEntityType.Account) {
            result.push({isSelected: false, ...child});
        } else {
            result.push(...getChildAccounts(child));
        }
    }

    return result;
}
