import {ReactElement, useEffect, useState} from "react";
import {Button} from "@zendeskgarden/react-buttons";
import {Field, Label, Radio} from "@zendeskgarden/react-forms";
import {Body, Close, Footer, Header, Modal} from "@zendeskgarden/react-modals";
import {ThemeProvider} from "@zendeskgarden/react-theming";

import {ViziblyTheme} from "../../../styles/zendesk-garden/ViziblyZDGTheme";
import * as css from "./styles/copy-drivers-btn/css.module.scss";
import alertIcon from "../../../assets/icons/alert.svg";
import {useProperties} from "../../../contexts/properties/PropertiesContext";
import {
    BackgroundJob,
    DriversCopyPeriodInput,
    GetUserPropertiesForecastLocksQuery,
    useGetJobLazyQuery,
    useSubmitCopyDriversMutation,
    VersionType
} from "../../../__generated__/generated_types";
import {SelectableFinancialEntity} from "../PropertyDrivers";
import CopyDriversConfirmationModal from "./CopyDriversConfirmationModal";
import {CustomZDDropdown} from "../../../atoms/custom-zd-dropdown/CustomZDDropdown";
import { renderJobInformation } from "./shared";


export interface Option {
    label: string;
    value: string;
}

export enum CopyType {
    MM_ONLY = "MM_ONLY",
    MM_ASSUMPTNS = "MM_ASSUMPTNS"
}

interface ICopyDriversBtn {
    allAccountsCount: number;
    selectedAccounts: SelectableFinancialEntity[];
    versionType: VersionType;
    year: number;
    lockedProperties: GetUserPropertiesForecastLocksQuery | undefined;
    onJobSubmitted?: (job: BackgroundJob) => void;
}

export default function CopyDriversToProperties({
    allAccountsCount,
    selectedAccounts,
    versionType,
    year,
    onJobSubmitted,
}: ICopyDriversBtn): ReactElement {
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
    const [isCopyPDModalOpen, setIsCopyPDModalOpen] = useState<boolean>(false);
    const [isCopyDriversExecuting, setIsCopyDriversExecuting] = useState<boolean>(false);
    const [isFailedRequestsModalOpen, setIsFailedRequestsModalOpen] = useState<boolean>(false);
    const [selectedPropertyIds, setSelectedPropertyIds] = useState<string[]>([]);
    const [copyType, setCopyType] = useState<CopyType>(CopyType.MM_ONLY);
    const [copyJob, setCopyJob] = useState<BackgroundJob | null>(null);
    const [copyJobId, setCopyJobId] = useState<string | null>(null);

    const { currentProperty, properties } = useProperties();
    const [submitCopyDrivers] = useSubmitCopyDriversMutation({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache"
    });
    const [getJob, {data: jobData, loading: jobLoading}] = useGetJobLazyQuery({
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,

    });

    useEffect(() => {
        if(copyJobId === null) {
            return;
        }

        const interval = setInterval(() => {
            getJob({
                variables: {
                    jobId: copyJobId
                }
            });
        }, 5000);

        return () => clearInterval(interval);
    }, [copyJobId]);

    useEffect(() => {
        if(jobLoading || !jobData?.getJob) {
            return;
        }

        const job = jobData.getJob;
        if(job.ended) {
            setCopyJobId(null);
        }

        setCopyJob(job);
    }, [jobData, jobLoading]);

    const handleOpenCopyPDModal = () => setIsCopyPDModalOpen(true);

    const resetState = () => {
        setIsCopyDriversExecuting(false);
        setIsConfirmModalOpen(false);
        setIsFailedRequestsModalOpen(false);
        setSelectedPropertyIds([]);
        setCopyType(CopyType.MM_ONLY);
        setIsCopyPDModalOpen(false);
        setCopyJobId(null);
        setCopyJob(null);
    };

    const handleCopyDrivers = () => {
        const budgetYear = currentProperty?.budgetYear;

        if (!budgetYear || !currentProperty) {
            return;
        }

        const accountIds = allAccountsCount === selectedAccounts.length ? [] : selectedAccounts.map((e) => e.id);
        const period: DriversCopyPeriodInput = {
            destinationVersionType: versionType,
            sourceVersionType: versionType,
        };
        const destinationPropertyIds = selectedPropertyIds;

        submitCopyDrivers({
            variables: {
                ...(accountIds.length > 0 ? {accountIds} : {}),
                budgetYear: budgetYear,
                sourcePropertyId: currentProperty.id,
                destinationPropertyIds: destinationPropertyIds,
                period: period,
                copyAssumptions: copyType === CopyType.MM_ASSUMPTNS
            }
        }).then(resp => {
            if(resp.data?.submitCopyDrivers) {
                const job = resp.data.submitCopyDrivers;
                setCopyJob(job);
                setCopyJobId(job.id);
                if (onJobSubmitted) {
                    onJobSubmitted(job);
                }
            } else {
                setIsCopyDriversExecuting(false);
            }
        }).catch(() => setIsCopyDriversExecuting(false));

        setIsCopyDriversExecuting(true);
        setIsConfirmModalOpen(false);
    };

    if (!properties || !currentProperty) {
        return <></>;
    }

    // function formatDateTime(value: Date | string): string {
    //     if(typeof value === "string") {
    //         value = new Date(value);
    //     }

    //     const formatter = Intl.DateTimeFormat("en-US", {
    //         dateStyle: "short",
    //         timeStyle: "short"
    //     });
    //     return formatter.format(value);
    // }

    return (
        <ThemeProvider theme={ViziblyTheme}>
        <div className={css.copyDriversBtnWrapper}>
            <Button
                disabled={selectedAccounts.length === 0}
                onClick={handleOpenCopyPDModal}
            >
                Copy Modeling Methods to another property
            </Button>
            {isCopyPDModalOpen && (
                <Modal onClose={resetState} className={css.modal}>
                    <Header>
                        Copy Modeling Methods to another property
                    </Header>
                    <Body className={css.copyDriversBody}>
                        <div className={`${css.row} ${css.title}`}>
                            You're about to copy {selectedAccounts.length} modeling methods from your {year} {versionType.toLowerCase().capitalize()} to another property.
                        </div>
                        <div className={`${css.row} ${css.sectionHeader}`}>
                            <div className={`${css.column} ${css.sectionTitle}`}>
                                Which properties would you like to copy to?
                            </div>
                            <div className={`${css.column} ${css.sectionLabel}`}>
                                REQUIRED
                            </div>
                        </div>
                        {selectedPropertyIds.length > 0 &&
                            <div className={`${css.row} ${css.lightText}`}>
                                {selectedPropertyIds.length > 1 ?
                                    `${selectedPropertyIds.length} properties selected.` :
                                    "1 property selected."
                                }
                            </div>
                        }
                        {isFailedRequestsModalOpen && (
                            <div className={`${css.row} ${css.error}`}>
                                <img src={alertIcon} alt="alert" />
                                Modeling methods could not be copied to some properties. Those properties are already selected in the dropdown below.
                            </div>
                        )}
                        <div className={`${css.row} ${css.dropdownWrapper}`}>
                            <CustomZDDropdown
                                widthSpec={"100%"}
                                applySelectedItems={items => setSelectedPropertyIds(items as string[])}
                                openDropdownPlaceholder="Properties"
                                closedDropdownPlaceholder="Properties"
                                options={properties.filter(p => p.id !== currentProperty.id).map(p => ({value: p.id, label: p.name}))}
                                isError={undefined}
                                isMulti
                                allOption
                            />
                        </div>
                        <div className={`${css.row} ${css.sectionHeader} ${css.radioHeader}`}>
                            <div className={`${css.column} ${css.sectionTitle}`}>
                                What should be copied?
                            </div>
                            <div className={`${css.column} ${css.sectionLabel}`}>
                                REQUIRED
                            </div>
                        </div>
                        <div className={css.row}>
                            <Field className={css.radioField}>
                                <Radio
                                    name={CopyType.MM_ONLY}
                                    value={CopyType.MM_ONLY}
                                    checked={copyType === CopyType.MM_ONLY}
                                    onChange={() => setCopyType(CopyType.MM_ONLY)}
                                >
                                    <Label className={css.radioLabel}>Copy modeling methods only (recommended)</Label>
                                </Radio>
                            </Field>
                        </div>
                        <div className={css.row}>
                            <Field className={css.radioField}>
                                <Radio
                                    name={CopyType.MM_ASSUMPTNS}
                                    value={CopyType.MM_ASSUMPTNS}
                                    checked={copyType === CopyType.MM_ASSUMPTNS}
                                    onChange={() => setCopyType(CopyType.MM_ASSUMPTNS)}
                                >
                                    <Label className={css.radioLabel}>Copy modeling methods and assumptions</Label>
                                </Radio>
                            </Field>
                        </div>
                        {copyJob && (
                                <div className={css.jobStatus}>
                                    <div className={css.jobStatusText}>{renderJobInformation(copyJob)}</div>
                                    <div className={css.jobExplainer}>It is safe to close this dialog. Jobs can be viewed from the job list.</div>
                                </div>
                        )}
                    </Body>
                    <Footer>
                        <div className={`${css.row} ${css.actionsRow}`}>
                            <div className={css.column}>
                                <Button
                                    isBasic
                                    onClick={resetState}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div className={css.column}>
                                <Button
                                    isPrimary
                                    disabled={selectedPropertyIds.length === 0}
                                    onClick={() => setIsConfirmModalOpen(true)}
                                >
                                    Copy
                                </Button>
                            </div>
                        </div>
                    </Footer>
                    <Close aria-label="Close modal" />
                </Modal>
            )}
        </div>
        {isConfirmModalOpen && (
            <CopyDriversConfirmationModal
                canConfirm={!isCopyDriversExecuting}
                count={selectedAccounts.length}
                onClose={() => setIsConfirmModalOpen(false)}
                onConfirm={handleCopyDrivers}
            />
        )}
        </ThemeProvider>
    );
}
