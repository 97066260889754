import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: any;
  /** Represents a budget year within the system. */
  BudgetYearScalar: any;
  /** Represents a date object in ISO format with time element set to 00:00:00. */
  DateNoTime: any;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** GraphQL Scalar representing the Prisma.Decimal type, based on Decimal.js library. */
  Decimal: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** Represents a valid password. */
  PasswordScalar: any;
};

export type AccPercentageAssumption = {
  __typename?: 'AccPercentageAssumption';
  accPercentageDriverAccountId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  delayRecognition: Scalars['Int'];
  effectiveEndMonthIndex: Scalars['Int'];
  effectiveEndYear: Scalars['Int'];
  effectiveStartMonthIndex: Scalars['Int'];
  effectiveStartYear: Scalars['Int'];
  id: Scalars['String'];
  locked: Scalars['Boolean'];
  percentOf?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AccPercentageAssumptionAccPercentageDriverAccountIdEffectiveEndYearPropertyIdCompoundUniqueInput = {
  accPercentageDriverAccountId: Scalars['String'];
  effectiveEndYear: Scalars['Int'];
  propertyId: Scalars['String'];
};

export type AccPercentageAssumptionCreateManyAccPercentageDriverAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition?: Maybe<Scalars['Int']>;
  effectiveEndMonthIndex: Scalars['Int'];
  effectiveEndYear: Scalars['Int'];
  effectiveStartMonthIndex: Scalars['Int'];
  effectiveStartYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  percentOf?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccPercentageAssumptionCreateManyAccPercentageDriverAccountInputEnvelope = {
  data: Array<AccPercentageAssumptionCreateManyAccPercentageDriverAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccPercentageAssumptionCreateManyPropertyInput = {
  accPercentageDriverAccountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition?: Maybe<Scalars['Int']>;
  effectiveEndMonthIndex: Scalars['Int'];
  effectiveEndYear: Scalars['Int'];
  effectiveStartMonthIndex: Scalars['Int'];
  effectiveStartYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  percentOf?: Maybe<Scalars['Decimal']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccPercentageAssumptionCreateManyPropertyInputEnvelope = {
  data: Array<AccPercentageAssumptionCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccPercentageAssumptionCreateNestedManyWithoutAccPercentageDriverAccountInput = {
  connect?: Maybe<Array<AccPercentageAssumptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccPercentageAssumptionCreateOrConnectWithoutAccPercentageDriverAccountInput>>;
  create?: Maybe<Array<AccPercentageAssumptionCreateWithoutAccPercentageDriverAccountInput>>;
  createMany?: Maybe<AccPercentageAssumptionCreateManyAccPercentageDriverAccountInputEnvelope>;
};

export type AccPercentageAssumptionCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<AccPercentageAssumptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccPercentageAssumptionCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<AccPercentageAssumptionCreateWithoutPropertyInput>>;
  createMany?: Maybe<AccPercentageAssumptionCreateManyPropertyInputEnvelope>;
};

export type AccPercentageAssumptionCreateOrConnectWithoutAccPercentageDriverAccountInput = {
  create: AccPercentageAssumptionCreateWithoutAccPercentageDriverAccountInput;
  where: AccPercentageAssumptionWhereUniqueInput;
};

export type AccPercentageAssumptionCreateOrConnectWithoutPropertyInput = {
  create: AccPercentageAssumptionCreateWithoutPropertyInput;
  where: AccPercentageAssumptionWhereUniqueInput;
};

export type AccPercentageAssumptionCreateWithoutAccPercentageDriverAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition?: Maybe<Scalars['Int']>;
  effectiveEndMonthIndex: Scalars['Int'];
  effectiveEndYear: Scalars['Int'];
  effectiveStartMonthIndex: Scalars['Int'];
  effectiveStartYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  percentOf?: Maybe<Scalars['Decimal']>;
  property: PropertyCreateNestedOneWithoutAccPercentageAssumptionsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccPercentageAssumptionCreateWithoutPropertyInput = {
  accPercentageDriverAccount: AccPercentageDriverAccountCreateNestedOneWithoutAccPercentageAssumptionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition?: Maybe<Scalars['Int']>;
  effectiveEndMonthIndex: Scalars['Int'];
  effectiveEndYear: Scalars['Int'];
  effectiveStartMonthIndex: Scalars['Int'];
  effectiveStartYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  percentOf?: Maybe<Scalars['Decimal']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccPercentageAssumptionListRelationFilter = {
  every?: Maybe<AccPercentageAssumptionWhereInput>;
  none?: Maybe<AccPercentageAssumptionWhereInput>;
  some?: Maybe<AccPercentageAssumptionWhereInput>;
};

export type AccPercentageAssumptionOrderByInput = {
  accPercentageDriverAccountId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  delayRecognition?: Maybe<SortOrder>;
  effectiveEndMonthIndex?: Maybe<SortOrder>;
  effectiveEndYear?: Maybe<SortOrder>;
  effectiveStartMonthIndex?: Maybe<SortOrder>;
  effectiveStartYear?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  locked?: Maybe<SortOrder>;
  percentOf?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum AccPercentageAssumptionScalarFieldEnum {
  AccPercentageDriverAccountId = 'accPercentageDriverAccountId',
  CreatedAt = 'createdAt',
  DelayRecognition = 'delayRecognition',
  EffectiveEndMonthIndex = 'effectiveEndMonthIndex',
  EffectiveEndYear = 'effectiveEndYear',
  EffectiveStartMonthIndex = 'effectiveStartMonthIndex',
  EffectiveStartYear = 'effectiveStartYear',
  Id = 'id',
  Locked = 'locked',
  PercentOf = 'percentOf',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt'
}

export type AccPercentageAssumptionScalarWhereInput = {
  AND?: Maybe<Array<AccPercentageAssumptionScalarWhereInput>>;
  NOT?: Maybe<Array<AccPercentageAssumptionScalarWhereInput>>;
  OR?: Maybe<Array<AccPercentageAssumptionScalarWhereInput>>;
  accPercentageDriverAccountId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  delayRecognition?: Maybe<IntFilter>;
  effectiveEndMonthIndex?: Maybe<IntFilter>;
  effectiveEndYear?: Maybe<IntFilter>;
  effectiveStartMonthIndex?: Maybe<IntFilter>;
  effectiveStartYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  locked?: Maybe<BoolFilter>;
  percentOf?: Maybe<DecimalNullableFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AccPercentageAssumptionUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveEndMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveEndYear?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveStartYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  locked?: Maybe<BoolFieldUpdateOperationsInput>;
  percentOf?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AccPercentageAssumptionUpdateManyWithWhereWithoutAccPercentageDriverAccountInput = {
  data: AccPercentageAssumptionUpdateManyMutationInput;
  where: AccPercentageAssumptionScalarWhereInput;
};

export type AccPercentageAssumptionUpdateManyWithWhereWithoutPropertyInput = {
  data: AccPercentageAssumptionUpdateManyMutationInput;
  where: AccPercentageAssumptionScalarWhereInput;
};

export type AccPercentageAssumptionUpdateManyWithoutAccPercentageDriverAccountInput = {
  connect?: Maybe<Array<AccPercentageAssumptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccPercentageAssumptionCreateOrConnectWithoutAccPercentageDriverAccountInput>>;
  create?: Maybe<Array<AccPercentageAssumptionCreateWithoutAccPercentageDriverAccountInput>>;
  createMany?: Maybe<AccPercentageAssumptionCreateManyAccPercentageDriverAccountInputEnvelope>;
  delete?: Maybe<Array<AccPercentageAssumptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccPercentageAssumptionScalarWhereInput>>;
  disconnect?: Maybe<Array<AccPercentageAssumptionWhereUniqueInput>>;
  set?: Maybe<Array<AccPercentageAssumptionWhereUniqueInput>>;
  update?: Maybe<Array<AccPercentageAssumptionUpdateWithWhereUniqueWithoutAccPercentageDriverAccountInput>>;
  updateMany?: Maybe<Array<AccPercentageAssumptionUpdateManyWithWhereWithoutAccPercentageDriverAccountInput>>;
  upsert?: Maybe<Array<AccPercentageAssumptionUpsertWithWhereUniqueWithoutAccPercentageDriverAccountInput>>;
};

export type AccPercentageAssumptionUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<AccPercentageAssumptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccPercentageAssumptionCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<AccPercentageAssumptionCreateWithoutPropertyInput>>;
  createMany?: Maybe<AccPercentageAssumptionCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<AccPercentageAssumptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccPercentageAssumptionScalarWhereInput>>;
  disconnect?: Maybe<Array<AccPercentageAssumptionWhereUniqueInput>>;
  set?: Maybe<Array<AccPercentageAssumptionWhereUniqueInput>>;
  update?: Maybe<Array<AccPercentageAssumptionUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<AccPercentageAssumptionUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<AccPercentageAssumptionUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type AccPercentageAssumptionUpdateWithWhereUniqueWithoutAccPercentageDriverAccountInput = {
  data: AccPercentageAssumptionUpdateWithoutAccPercentageDriverAccountInput;
  where: AccPercentageAssumptionWhereUniqueInput;
};

export type AccPercentageAssumptionUpdateWithWhereUniqueWithoutPropertyInput = {
  data: AccPercentageAssumptionUpdateWithoutPropertyInput;
  where: AccPercentageAssumptionWhereUniqueInput;
};

export type AccPercentageAssumptionUpdateWithoutAccPercentageDriverAccountInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveEndMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveEndYear?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveStartYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  locked?: Maybe<BoolFieldUpdateOperationsInput>;
  percentOf?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutAccPercentageAssumptionsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AccPercentageAssumptionUpdateWithoutPropertyInput = {
  accPercentageDriverAccount?: Maybe<AccPercentageDriverAccountUpdateOneRequiredWithoutAccPercentageAssumptionsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveEndMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveEndYear?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveStartYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  locked?: Maybe<BoolFieldUpdateOperationsInput>;
  percentOf?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AccPercentageAssumptionUpsertWithWhereUniqueWithoutAccPercentageDriverAccountInput = {
  create: AccPercentageAssumptionCreateWithoutAccPercentageDriverAccountInput;
  update: AccPercentageAssumptionUpdateWithoutAccPercentageDriverAccountInput;
  where: AccPercentageAssumptionWhereUniqueInput;
};

export type AccPercentageAssumptionUpsertWithWhereUniqueWithoutPropertyInput = {
  create: AccPercentageAssumptionCreateWithoutPropertyInput;
  update: AccPercentageAssumptionUpdateWithoutPropertyInput;
  where: AccPercentageAssumptionWhereUniqueInput;
};

export type AccPercentageAssumptionWhereInput = {
  AND?: Maybe<Array<AccPercentageAssumptionWhereInput>>;
  NOT?: Maybe<Array<AccPercentageAssumptionWhereInput>>;
  OR?: Maybe<Array<AccPercentageAssumptionWhereInput>>;
  accPercentageDriverAccount?: Maybe<AccPercentageDriverAccountRelationFilter>;
  accPercentageDriverAccountId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  delayRecognition?: Maybe<IntFilter>;
  effectiveEndMonthIndex?: Maybe<IntFilter>;
  effectiveEndYear?: Maybe<IntFilter>;
  effectiveStartMonthIndex?: Maybe<IntFilter>;
  effectiveStartYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  locked?: Maybe<BoolFilter>;
  percentOf?: Maybe<DecimalNullableFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AccPercentageAssumptionWhereUniqueInput = {
  accPercentageDriverAccountId_effectiveEndYear_propertyId?: Maybe<AccPercentageAssumptionAccPercentageDriverAccountIdEffectiveEndYearPropertyIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type AccPercentageDriverAccount = {
  __typename?: 'AccPercentageDriverAccount';
  accountId: Scalars['String'];
  accountV2Id?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  driverId: Scalars['String'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AccPercentageDriverAccountCreateManyAccountV2Input = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  driverId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccPercentageDriverAccountCreateManyAccountV2InputEnvelope = {
  data: Array<AccPercentageDriverAccountCreateManyAccountV2Input>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccPercentageDriverAccountCreateManyDriverInput = {
  accountId: Scalars['String'];
  accountV2Id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccPercentageDriverAccountCreateManyDriverInputEnvelope = {
  data: Array<AccPercentageDriverAccountCreateManyDriverInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input = {
  connect?: Maybe<Array<AccPercentageDriverAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccPercentageDriverAccountCreateOrConnectWithoutAccountV2Input>>;
  create?: Maybe<Array<AccPercentageDriverAccountCreateWithoutAccountV2Input>>;
  createMany?: Maybe<AccPercentageDriverAccountCreateManyAccountV2InputEnvelope>;
};

export type AccPercentageDriverAccountCreateNestedManyWithoutDriverInput = {
  connect?: Maybe<Array<AccPercentageDriverAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccPercentageDriverAccountCreateOrConnectWithoutDriverInput>>;
  create?: Maybe<Array<AccPercentageDriverAccountCreateWithoutDriverInput>>;
  createMany?: Maybe<AccPercentageDriverAccountCreateManyDriverInputEnvelope>;
};

export type AccPercentageDriverAccountCreateNestedOneWithoutAccPercentageAssumptionsInput = {
  connect?: Maybe<AccPercentageDriverAccountWhereUniqueInput>;
  connectOrCreate?: Maybe<AccPercentageDriverAccountCreateOrConnectWithoutAccPercentageAssumptionsInput>;
  create?: Maybe<AccPercentageDriverAccountCreateWithoutAccPercentageAssumptionsInput>;
};

export type AccPercentageDriverAccountCreateOrConnectWithoutAccPercentageAssumptionsInput = {
  create: AccPercentageDriverAccountCreateWithoutAccPercentageAssumptionsInput;
  where: AccPercentageDriverAccountWhereUniqueInput;
};

export type AccPercentageDriverAccountCreateOrConnectWithoutAccountV2Input = {
  create: AccPercentageDriverAccountCreateWithoutAccountV2Input;
  where: AccPercentageDriverAccountWhereUniqueInput;
};

export type AccPercentageDriverAccountCreateOrConnectWithoutDriverInput = {
  create: AccPercentageDriverAccountCreateWithoutDriverInput;
  where: AccPercentageDriverAccountWhereUniqueInput;
};

export type AccPercentageDriverAccountCreateWithoutAccPercentageAssumptionsInput = {
  accountId: Scalars['String'];
  accountV2?: Maybe<AccountV2CreateNestedOneWithoutAccPercentageDriverAccountsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  driver: DriverCreateNestedOneWithoutAccPercentageDriverAccountsInput;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccPercentageDriverAccountCreateWithoutAccountV2Input = {
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutAccPercentageDriverAccountInput>;
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  driver: DriverCreateNestedOneWithoutAccPercentageDriverAccountsInput;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccPercentageDriverAccountCreateWithoutDriverInput = {
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutAccPercentageDriverAccountInput>;
  accountId: Scalars['String'];
  accountV2?: Maybe<AccountV2CreateNestedOneWithoutAccPercentageDriverAccountsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccPercentageDriverAccountDriverIdAccountIdAccountV2IdCompoundUniqueInput = {
  accountId: Scalars['String'];
  accountV2Id: Scalars['String'];
  driverId: Scalars['String'];
};

export type AccPercentageDriverAccountListRelationFilter = {
  every?: Maybe<AccPercentageDriverAccountWhereInput>;
  none?: Maybe<AccPercentageDriverAccountWhereInput>;
  some?: Maybe<AccPercentageDriverAccountWhereInput>;
};

export type AccPercentageDriverAccountOrderByInput = {
  accountId?: Maybe<SortOrder>;
  accountV2Id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  driverId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AccPercentageDriverAccountRelationFilter = {
  is?: Maybe<AccPercentageDriverAccountWhereInput>;
  isNot?: Maybe<AccPercentageDriverAccountWhereInput>;
};

export enum AccPercentageDriverAccountScalarFieldEnum {
  AccountId = 'accountId',
  AccountV2Id = 'accountV2Id',
  CreatedAt = 'createdAt',
  DriverId = 'driverId',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type AccPercentageDriverAccountScalarWhereInput = {
  AND?: Maybe<Array<AccPercentageDriverAccountScalarWhereInput>>;
  NOT?: Maybe<Array<AccPercentageDriverAccountScalarWhereInput>>;
  OR?: Maybe<Array<AccPercentageDriverAccountScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  accountV2Id?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  driverId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AccPercentageDriverAccountUpdate = {
  __typename?: 'AccPercentageDriverAccountUpdate';
  accPercentageAssumptions: Array<AccPercentageDriverAccountUpdateAssumptions>;
  accountV2Id?: Maybe<Scalars['String']>;
};

export type AccPercentageDriverAccountUpdateAssumptions = {
  __typename?: 'AccPercentageDriverAccountUpdateAssumptions';
  accPercentageDriverAccountId: Scalars['String'];
  delayRecognition: Scalars['Int'];
  locked: Scalars['Boolean'];
  percentOf: Scalars['Decimal'];
};

export type AccPercentageDriverAccountUpdateManyMutationInput = {
  accountId?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AccPercentageDriverAccountUpdateManyWithWhereWithoutAccountV2Input = {
  data: AccPercentageDriverAccountUpdateManyMutationInput;
  where: AccPercentageDriverAccountScalarWhereInput;
};

export type AccPercentageDriverAccountUpdateManyWithWhereWithoutDriverInput = {
  data: AccPercentageDriverAccountUpdateManyMutationInput;
  where: AccPercentageDriverAccountScalarWhereInput;
};

export type AccPercentageDriverAccountUpdateManyWithoutAccountV2Input = {
  connect?: Maybe<Array<AccPercentageDriverAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccPercentageDriverAccountCreateOrConnectWithoutAccountV2Input>>;
  create?: Maybe<Array<AccPercentageDriverAccountCreateWithoutAccountV2Input>>;
  createMany?: Maybe<AccPercentageDriverAccountCreateManyAccountV2InputEnvelope>;
  delete?: Maybe<Array<AccPercentageDriverAccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccPercentageDriverAccountScalarWhereInput>>;
  disconnect?: Maybe<Array<AccPercentageDriverAccountWhereUniqueInput>>;
  set?: Maybe<Array<AccPercentageDriverAccountWhereUniqueInput>>;
  update?: Maybe<Array<AccPercentageDriverAccountUpdateWithWhereUniqueWithoutAccountV2Input>>;
  updateMany?: Maybe<Array<AccPercentageDriverAccountUpdateManyWithWhereWithoutAccountV2Input>>;
  upsert?: Maybe<Array<AccPercentageDriverAccountUpsertWithWhereUniqueWithoutAccountV2Input>>;
};

export type AccPercentageDriverAccountUpdateManyWithoutDriverInput = {
  connect?: Maybe<Array<AccPercentageDriverAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccPercentageDriverAccountCreateOrConnectWithoutDriverInput>>;
  create?: Maybe<Array<AccPercentageDriverAccountCreateWithoutDriverInput>>;
  createMany?: Maybe<AccPercentageDriverAccountCreateManyDriverInputEnvelope>;
  delete?: Maybe<Array<AccPercentageDriverAccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccPercentageDriverAccountScalarWhereInput>>;
  disconnect?: Maybe<Array<AccPercentageDriverAccountWhereUniqueInput>>;
  set?: Maybe<Array<AccPercentageDriverAccountWhereUniqueInput>>;
  update?: Maybe<Array<AccPercentageDriverAccountUpdateWithWhereUniqueWithoutDriverInput>>;
  updateMany?: Maybe<Array<AccPercentageDriverAccountUpdateManyWithWhereWithoutDriverInput>>;
  upsert?: Maybe<Array<AccPercentageDriverAccountUpsertWithWhereUniqueWithoutDriverInput>>;
};

export type AccPercentageDriverAccountUpdateOneRequiredWithoutAccPercentageAssumptionsInput = {
  connect?: Maybe<AccPercentageDriverAccountWhereUniqueInput>;
  connectOrCreate?: Maybe<AccPercentageDriverAccountCreateOrConnectWithoutAccPercentageAssumptionsInput>;
  create?: Maybe<AccPercentageDriverAccountCreateWithoutAccPercentageAssumptionsInput>;
  update?: Maybe<AccPercentageDriverAccountUpdateWithoutAccPercentageAssumptionsInput>;
  upsert?: Maybe<AccPercentageDriverAccountUpsertWithoutAccPercentageAssumptionsInput>;
};

export type AccPercentageDriverAccountUpdateWithWhereUniqueWithoutAccountV2Input = {
  data: AccPercentageDriverAccountUpdateWithoutAccountV2Input;
  where: AccPercentageDriverAccountWhereUniqueInput;
};

export type AccPercentageDriverAccountUpdateWithWhereUniqueWithoutDriverInput = {
  data: AccPercentageDriverAccountUpdateWithoutDriverInput;
  where: AccPercentageDriverAccountWhereUniqueInput;
};

export type AccPercentageDriverAccountUpdateWithoutAccPercentageAssumptionsInput = {
  accountId?: Maybe<StringFieldUpdateOperationsInput>;
  accountV2?: Maybe<AccountV2UpdateOneWithoutAccPercentageDriverAccountsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  driver?: Maybe<DriverUpdateOneRequiredWithoutAccPercentageDriverAccountsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AccPercentageDriverAccountUpdateWithoutAccountV2Input = {
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutAccPercentageDriverAccountInput>;
  accountId?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  driver?: Maybe<DriverUpdateOneRequiredWithoutAccPercentageDriverAccountsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AccPercentageDriverAccountUpdateWithoutDriverInput = {
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutAccPercentageDriverAccountInput>;
  accountId?: Maybe<StringFieldUpdateOperationsInput>;
  accountV2?: Maybe<AccountV2UpdateOneWithoutAccPercentageDriverAccountsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AccPercentageDriverAccountUpsertWithWhereUniqueWithoutAccountV2Input = {
  create: AccPercentageDriverAccountCreateWithoutAccountV2Input;
  update: AccPercentageDriverAccountUpdateWithoutAccountV2Input;
  where: AccPercentageDriverAccountWhereUniqueInput;
};

export type AccPercentageDriverAccountUpsertWithWhereUniqueWithoutDriverInput = {
  create: AccPercentageDriverAccountCreateWithoutDriverInput;
  update: AccPercentageDriverAccountUpdateWithoutDriverInput;
  where: AccPercentageDriverAccountWhereUniqueInput;
};

export type AccPercentageDriverAccountUpsertWithoutAccPercentageAssumptionsInput = {
  create: AccPercentageDriverAccountCreateWithoutAccPercentageAssumptionsInput;
  update: AccPercentageDriverAccountUpdateWithoutAccPercentageAssumptionsInput;
};

export type AccPercentageDriverAccountWhereInput = {
  AND?: Maybe<Array<AccPercentageDriverAccountWhereInput>>;
  NOT?: Maybe<Array<AccPercentageDriverAccountWhereInput>>;
  OR?: Maybe<Array<AccPercentageDriverAccountWhereInput>>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionListRelationFilter>;
  accountId?: Maybe<StringFilter>;
  accountV2?: Maybe<AccountV2RelationFilter>;
  accountV2Id?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  driver?: Maybe<DriverRelationFilter>;
  driverId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AccPercentageDriverAccountWhereUniqueInput = {
  driverId_accountId_accountV2Id?: Maybe<AccPercentageDriverAccountDriverIdAccountIdAccountV2IdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type AccPercentageDriverAssumptionAndValueModel = {
  __typename?: 'AccPercentageDriverAssumptionAndValueModel';
  accountValue?: Maybe<Scalars['Decimal']>;
  lookbackPeriod?: Maybe<Scalars['Int']>;
  percentage?: Maybe<Scalars['Decimal']>;
};

export type AccPercentageDriverAssumptionModel = {
  __typename?: 'AccPercentageDriverAssumptionModel';
  lookbackPeriod?: Maybe<Scalars['Int']>;
  percentage?: Maybe<Scalars['Decimal']>;
};

export type AccPercentageDriverAssumptionViewModel = {
  __typename?: 'AccPercentageDriverAssumptionViewModel';
  delayRecognition: Scalars['Int'];
  destinationAccountId: Scalars['String'];
  percentMonth0?: Maybe<Scalars['Float']>;
  percentMonth1?: Maybe<Scalars['Float']>;
  percentMonth2?: Maybe<Scalars['Float']>;
  percentMonth3?: Maybe<Scalars['Float']>;
  percentMonth4?: Maybe<Scalars['Float']>;
  percentMonth5?: Maybe<Scalars['Float']>;
  percentMonth6?: Maybe<Scalars['Float']>;
  percentMonth7?: Maybe<Scalars['Float']>;
  percentMonth8?: Maybe<Scalars['Float']>;
  percentMonth9?: Maybe<Scalars['Float']>;
  percentMonth10?: Maybe<Scalars['Float']>;
  percentMonth11?: Maybe<Scalars['Float']>;
  propertyId: Scalars['String'];
  sourceAccountId: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Float'];
};

export type AccPercentageDriverAssumptionsBulkUpdateInput = {
  destinationAccountId: Scalars['String'];
  percentMonth0?: Maybe<Scalars['Float']>;
  percentMonth1?: Maybe<Scalars['Float']>;
  percentMonth2?: Maybe<Scalars['Float']>;
  percentMonth3?: Maybe<Scalars['Float']>;
  percentMonth4?: Maybe<Scalars['Float']>;
  percentMonth5?: Maybe<Scalars['Float']>;
  percentMonth6?: Maybe<Scalars['Float']>;
  percentMonth7?: Maybe<Scalars['Float']>;
  percentMonth8?: Maybe<Scalars['Float']>;
  percentMonth9?: Maybe<Scalars['Float']>;
  percentMonth10?: Maybe<Scalars['Float']>;
  percentMonth11?: Maybe<Scalars['Float']>;
  propertyId: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Float'];
};

export type AccPercentageDriverAssumptionsUpdateInput = {
  destinationAccountId: Scalars['String'];
  percentMonth0?: Maybe<Scalars['Float']>;
  percentMonth1?: Maybe<Scalars['Float']>;
  percentMonth2?: Maybe<Scalars['Float']>;
  percentMonth3?: Maybe<Scalars['Float']>;
  percentMonth4?: Maybe<Scalars['Float']>;
  percentMonth5?: Maybe<Scalars['Float']>;
  percentMonth6?: Maybe<Scalars['Float']>;
  percentMonth7?: Maybe<Scalars['Float']>;
  percentMonth8?: Maybe<Scalars['Float']>;
  percentMonth9?: Maybe<Scalars['Float']>;
  percentMonth10?: Maybe<Scalars['Float']>;
  percentMonth11?: Maybe<Scalars['Float']>;
  propertyId: Scalars['String'];
  sourceAccountId: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Float'];
};

export type AccPercentageDriverSetInput = {
  monthsAgo: Scalars['Int'];
  sourceAccountId: Scalars['ID'];
};

export type AccPercentageDriverUpdateInput = {
  accPercentageDriverId: Scalars['String'];
  monthsAgo?: Maybe<Scalars['Int']>;
  percentage?: Maybe<Scalars['Decimal']>;
  sourceAccountId?: Maybe<Scalars['String']>;
};

export type AccountClientIdAccountNumberCompoundUniqueInput = {
  accountNumber: Scalars['String'];
  clientId: Scalars['String'];
};

export type AccountCreateManyBudgetCategoryInput = {
  accountNumber: Scalars['String'];
  clientId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccountCreateManyBudgetCategoryInputEnvelope = {
  data: Array<AccountCreateManyBudgetCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccountCreateManyClientInput = {
  accountNumber: Scalars['String'];
  budgetCategoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccountCreateManyClientInputEnvelope = {
  data: Array<AccountCreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccountCreateNestedManyWithoutBudgetCategoryInput = {
  connect?: Maybe<Array<AccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountCreateOrConnectWithoutBudgetCategoryInput>>;
  create?: Maybe<Array<AccountCreateWithoutBudgetCategoryInput>>;
  createMany?: Maybe<AccountCreateManyBudgetCategoryInputEnvelope>;
};

export type AccountCreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<AccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<AccountCreateWithoutClientInput>>;
  createMany?: Maybe<AccountCreateManyClientInputEnvelope>;
};

export type AccountCreateNestedOneWithoutAccountTotalValuesInput = {
  connect?: Maybe<AccountWhereUniqueInput>;
  connectOrCreate?: Maybe<AccountCreateOrConnectWithoutAccountTotalValuesInput>;
  create?: Maybe<AccountCreateWithoutAccountTotalValuesInput>;
};

export type AccountCreateOrConnectWithoutAccountTotalValuesInput = {
  create: AccountCreateWithoutAccountTotalValuesInput;
  where: AccountWhereUniqueInput;
};

export type AccountCreateOrConnectWithoutBudgetCategoryInput = {
  create: AccountCreateWithoutBudgetCategoryInput;
  where: AccountWhereUniqueInput;
};

export type AccountCreateOrConnectWithoutClientInput = {
  create: AccountCreateWithoutClientInput;
  where: AccountWhereUniqueInput;
};

export type AccountCreateWithoutAccountTotalValuesInput = {
  accountNumber: Scalars['String'];
  budgetCategory?: Maybe<BudgetCategoryCreateNestedOneWithoutAccountsInput>;
  client: ClientCreateNestedOneWithoutAccountsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccountCreateWithoutBudgetCategoryInput = {
  accountNumber: Scalars['String'];
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutAccountInput>;
  client: ClientCreateNestedOneWithoutAccountsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccountCreateWithoutClientInput = {
  accountNumber: Scalars['String'];
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutAccountInput>;
  budgetCategory?: Maybe<BudgetCategoryCreateNestedOneWithoutAccountsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccountListRelationFilter = {
  every?: Maybe<AccountWhereInput>;
  none?: Maybe<AccountWhereInput>;
  some?: Maybe<AccountWhereInput>;
};

export type AccountLocksArgsItem = {
  accountId: Scalars['ID'];
  isLocked: Scalars['Boolean'];
};

export type AccountNoteModel = {
  __typename?: 'AccountNoteModel';
  accountId: Scalars['String'];
  propertyId: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type AccountPercentageAugmentsModel = {
  __typename?: 'AccountPercentageAugmentsModel';
  accountId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  maxValue?: Maybe<Scalars['Decimal']>;
  minValue?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['ID'];
  versionType: VersionType;
};

export type AccountPercentageDriverCycleModel = {
  __typename?: 'AccountPercentageDriverCycleModel';
  /** Ids of accounts in the cycle */
  accountIds: Array<Scalars['ID']>;
};

export type AccountPercentageDriverCyclesIndicatorsModel = {
  __typename?: 'AccountPercentageDriverCyclesIndicatorsModel';
  /** Does the BUDGET % of account have circular references */
  budget: Scalars['Boolean'];
  /** Does the REFORECAST % of account have circular references */
  reforecast: Scalars['Boolean'];
};

export type AccountPercentageDriverModel = {
  __typename?: 'AccountPercentageDriverModel';
  augments: AccountPercentageAugmentsModel;
  isDriven: Scalars['Boolean'];
  sourceAccountAssumptions: Array<SourceAccountAssumptionsModel>;
  sourceAccounts: Array<SourceAccountModel>;
  sourceAccountsBare: Array<SourceAccountBareModel>;
};

export type AccountRelationFilter = {
  is?: Maybe<AccountWhereInput>;
  isNot?: Maybe<AccountWhereInput>;
};

export type AccountScalarWhereInput = {
  AND?: Maybe<Array<AccountScalarWhereInput>>;
  NOT?: Maybe<Array<AccountScalarWhereInput>>;
  OR?: Maybe<Array<AccountScalarWhereInput>>;
  accountNumber?: Maybe<StringFilter>;
  budgetCategoryId?: Maybe<StringNullableFilter>;
  clientId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AccountShortModel = {
  __typename?: 'AccountShortModel';
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  order: Scalars['Int'];
};

export type AccountTagsModel = {
  __typename?: 'AccountTagsModel';
  accountId: Scalars['ID'];
  tags: Array<Scalars['String']>;
};

export type AccountTopVendors = {
  __typename?: 'AccountTopVendors';
  accountId: Scalars['String'];
  id: Scalars['String'];
  month: Scalars['Int'];
  propertyId: Scalars['String'];
  total_amount: Scalars['Decimal'];
  vendor_name: Scalars['String'];
  year: Scalars['Int'];
};

export type AccountTopVendorsCreateManyAccountInput = {
  id?: Maybe<Scalars['String']>;
  month: Scalars['Int'];
  propertyId: Scalars['String'];
  total_amount: Scalars['Decimal'];
  vendor_name: Scalars['String'];
  year: Scalars['Int'];
};

export type AccountTopVendorsCreateManyAccountInputEnvelope = {
  data: Array<AccountTopVendorsCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccountTopVendorsCreateManyPropertyInput = {
  accountId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  month: Scalars['Int'];
  total_amount: Scalars['Decimal'];
  vendor_name: Scalars['String'];
  year: Scalars['Int'];
};

export type AccountTopVendorsCreateManyPropertyInputEnvelope = {
  data: Array<AccountTopVendorsCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccountTopVendorsCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<AccountTopVendorsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountTopVendorsCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<AccountTopVendorsCreateWithoutAccountInput>>;
  createMany?: Maybe<AccountTopVendorsCreateManyAccountInputEnvelope>;
};

export type AccountTopVendorsCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<AccountTopVendorsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountTopVendorsCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<AccountTopVendorsCreateWithoutPropertyInput>>;
  createMany?: Maybe<AccountTopVendorsCreateManyPropertyInputEnvelope>;
};

export type AccountTopVendorsCreateOrConnectWithoutAccountInput = {
  create: AccountTopVendorsCreateWithoutAccountInput;
  where: AccountTopVendorsWhereUniqueInput;
};

export type AccountTopVendorsCreateOrConnectWithoutPropertyInput = {
  create: AccountTopVendorsCreateWithoutPropertyInput;
  where: AccountTopVendorsWhereUniqueInput;
};

export type AccountTopVendorsCreateWithoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  month: Scalars['Int'];
  property: PropertyCreateNestedOneWithoutAccountTopVendorsInput;
  total_amount: Scalars['Decimal'];
  vendor_name: Scalars['String'];
  year: Scalars['Int'];
};

export type AccountTopVendorsCreateWithoutPropertyInput = {
  account: AccountV2CreateNestedOneWithoutAccountTopVendorsInput;
  id?: Maybe<Scalars['String']>;
  month: Scalars['Int'];
  total_amount: Scalars['Decimal'];
  vendor_name: Scalars['String'];
  year: Scalars['Int'];
};

export type AccountTopVendorsListRelationFilter = {
  every?: Maybe<AccountTopVendorsWhereInput>;
  none?: Maybe<AccountTopVendorsWhereInput>;
  some?: Maybe<AccountTopVendorsWhereInput>;
};

export type AccountTopVendorsOrderByInput = {
  accountId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  month?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  total_amount?: Maybe<SortOrder>;
  vendor_name?: Maybe<SortOrder>;
  year?: Maybe<SortOrder>;
};

export enum AccountTopVendorsScalarFieldEnum {
  AccountId = 'accountId',
  Id = 'id',
  Month = 'month',
  PropertyId = 'propertyId',
  TotalAmount = 'total_amount',
  VendorName = 'vendor_name',
  Year = 'year'
}

export type AccountTopVendorsScalarWhereInput = {
  AND?: Maybe<Array<AccountTopVendorsScalarWhereInput>>;
  NOT?: Maybe<Array<AccountTopVendorsScalarWhereInput>>;
  OR?: Maybe<Array<AccountTopVendorsScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  month?: Maybe<IntFilter>;
  propertyId?: Maybe<StringFilter>;
  total_amount?: Maybe<DecimalFilter>;
  vendor_name?: Maybe<StringFilter>;
  year?: Maybe<IntFilter>;
};

export type AccountTopVendorsUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  month?: Maybe<IntFieldUpdateOperationsInput>;
  total_amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  vendor_name?: Maybe<StringFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type AccountTopVendorsUpdateManyWithWhereWithoutAccountInput = {
  data: AccountTopVendorsUpdateManyMutationInput;
  where: AccountTopVendorsScalarWhereInput;
};

export type AccountTopVendorsUpdateManyWithWhereWithoutPropertyInput = {
  data: AccountTopVendorsUpdateManyMutationInput;
  where: AccountTopVendorsScalarWhereInput;
};

export type AccountTopVendorsUpdateManyWithoutAccountInput = {
  connect?: Maybe<Array<AccountTopVendorsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountTopVendorsCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<AccountTopVendorsCreateWithoutAccountInput>>;
  createMany?: Maybe<AccountTopVendorsCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<AccountTopVendorsWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccountTopVendorsScalarWhereInput>>;
  disconnect?: Maybe<Array<AccountTopVendorsWhereUniqueInput>>;
  set?: Maybe<Array<AccountTopVendorsWhereUniqueInput>>;
  update?: Maybe<Array<AccountTopVendorsUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<AccountTopVendorsUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<AccountTopVendorsUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type AccountTopVendorsUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<AccountTopVendorsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountTopVendorsCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<AccountTopVendorsCreateWithoutPropertyInput>>;
  createMany?: Maybe<AccountTopVendorsCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<AccountTopVendorsWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccountTopVendorsScalarWhereInput>>;
  disconnect?: Maybe<Array<AccountTopVendorsWhereUniqueInput>>;
  set?: Maybe<Array<AccountTopVendorsWhereUniqueInput>>;
  update?: Maybe<Array<AccountTopVendorsUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<AccountTopVendorsUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<AccountTopVendorsUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type AccountTopVendorsUpdateWithWhereUniqueWithoutAccountInput = {
  data: AccountTopVendorsUpdateWithoutAccountInput;
  where: AccountTopVendorsWhereUniqueInput;
};

export type AccountTopVendorsUpdateWithWhereUniqueWithoutPropertyInput = {
  data: AccountTopVendorsUpdateWithoutPropertyInput;
  where: AccountTopVendorsWhereUniqueInput;
};

export type AccountTopVendorsUpdateWithoutAccountInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  month?: Maybe<IntFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutAccountTopVendorsInput>;
  total_amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  vendor_name?: Maybe<StringFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type AccountTopVendorsUpdateWithoutPropertyInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutAccountTopVendorsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  month?: Maybe<IntFieldUpdateOperationsInput>;
  total_amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  vendor_name?: Maybe<StringFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type AccountTopVendorsUpsertWithWhereUniqueWithoutAccountInput = {
  create: AccountTopVendorsCreateWithoutAccountInput;
  update: AccountTopVendorsUpdateWithoutAccountInput;
  where: AccountTopVendorsWhereUniqueInput;
};

export type AccountTopVendorsUpsertWithWhereUniqueWithoutPropertyInput = {
  create: AccountTopVendorsCreateWithoutPropertyInput;
  update: AccountTopVendorsUpdateWithoutPropertyInput;
  where: AccountTopVendorsWhereUniqueInput;
};

export type AccountTopVendorsWhereInput = {
  AND?: Maybe<Array<AccountTopVendorsWhereInput>>;
  NOT?: Maybe<Array<AccountTopVendorsWhereInput>>;
  OR?: Maybe<Array<AccountTopVendorsWhereInput>>;
  account?: Maybe<AccountV2RelationFilter>;
  accountId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  month?: Maybe<IntFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  total_amount?: Maybe<DecimalFilter>;
  vendor_name?: Maybe<StringFilter>;
  year?: Maybe<IntFilter>;
};

export type AccountTopVendorsWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type AccountTotalValue = {
  __typename?: 'AccountTotalValue';
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Decimal']>;
  versionId: Scalars['String'];
};

export type AccountTotalValueAccountIdVersionIdPropertyIdCompoundUniqueInput = {
  accountId: Scalars['String'];
  propertyId: Scalars['String'];
  versionId: Scalars['String'];
};

export type AccountTotalValueCreateManyAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Decimal']>;
  versionId: Scalars['String'];
};

export type AccountTotalValueCreateManyAccountInputEnvelope = {
  data: Array<AccountTotalValueCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccountTotalValueCreateManyPropertyInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Decimal']>;
  versionId: Scalars['String'];
};

export type AccountTotalValueCreateManyPropertyInputEnvelope = {
  data: Array<AccountTotalValueCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccountTotalValueCreateManyVersionInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Decimal']>;
};

export type AccountTotalValueCreateManyVersionInputEnvelope = {
  data: Array<AccountTotalValueCreateManyVersionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccountTotalValueCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<AccountTotalValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountTotalValueCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<AccountTotalValueCreateWithoutAccountInput>>;
  createMany?: Maybe<AccountTotalValueCreateManyAccountInputEnvelope>;
};

export type AccountTotalValueCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<AccountTotalValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountTotalValueCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<AccountTotalValueCreateWithoutPropertyInput>>;
  createMany?: Maybe<AccountTotalValueCreateManyPropertyInputEnvelope>;
};

export type AccountTotalValueCreateNestedManyWithoutVersionInput = {
  connect?: Maybe<Array<AccountTotalValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountTotalValueCreateOrConnectWithoutVersionInput>>;
  create?: Maybe<Array<AccountTotalValueCreateWithoutVersionInput>>;
  createMany?: Maybe<AccountTotalValueCreateManyVersionInputEnvelope>;
};

export type AccountTotalValueCreateOrConnectWithoutAccountInput = {
  create: AccountTotalValueCreateWithoutAccountInput;
  where: AccountTotalValueWhereUniqueInput;
};

export type AccountTotalValueCreateOrConnectWithoutPropertyInput = {
  create: AccountTotalValueCreateWithoutPropertyInput;
  where: AccountTotalValueWhereUniqueInput;
};

export type AccountTotalValueCreateOrConnectWithoutVersionInput = {
  create: AccountTotalValueCreateWithoutVersionInput;
  where: AccountTotalValueWhereUniqueInput;
};

export type AccountTotalValueCreateWithoutAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  property: PropertyCreateNestedOneWithoutAccountTotalValuesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Decimal']>;
  version: ValueVersionCreateNestedOneWithoutAccountTotalValuesInput;
};

export type AccountTotalValueCreateWithoutPropertyInput = {
  account: AccountCreateNestedOneWithoutAccountTotalValuesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Decimal']>;
  version: ValueVersionCreateNestedOneWithoutAccountTotalValuesInput;
};

export type AccountTotalValueCreateWithoutVersionInput = {
  account: AccountCreateNestedOneWithoutAccountTotalValuesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  property: PropertyCreateNestedOneWithoutAccountTotalValuesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Decimal']>;
};

export type AccountTotalValueListRelationFilter = {
  every?: Maybe<AccountTotalValueWhereInput>;
  none?: Maybe<AccountTotalValueWhereInput>;
  some?: Maybe<AccountTotalValueWhereInput>;
};

export type AccountTotalValueOrderByInput = {
  accountId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  versionId?: Maybe<SortOrder>;
};

export enum AccountTotalValueScalarFieldEnum {
  AccountId = 'accountId',
  CreatedAt = 'createdAt',
  Id = 'id',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt',
  Value = 'value',
  VersionId = 'versionId'
}

export type AccountTotalValueScalarWhereInput = {
  AND?: Maybe<Array<AccountTotalValueScalarWhereInput>>;
  NOT?: Maybe<Array<AccountTotalValueScalarWhereInput>>;
  OR?: Maybe<Array<AccountTotalValueScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringNullableFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeNullableFilter>;
  value?: Maybe<DecimalNullableFilter>;
  versionId?: Maybe<StringFilter>;
};

export type AccountTotalValueUpdateManyMutationInput = {
  createdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  value?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
};

export type AccountTotalValueUpdateManyWithWhereWithoutAccountInput = {
  data: AccountTotalValueUpdateManyMutationInput;
  where: AccountTotalValueScalarWhereInput;
};

export type AccountTotalValueUpdateManyWithWhereWithoutPropertyInput = {
  data: AccountTotalValueUpdateManyMutationInput;
  where: AccountTotalValueScalarWhereInput;
};

export type AccountTotalValueUpdateManyWithWhereWithoutVersionInput = {
  data: AccountTotalValueUpdateManyMutationInput;
  where: AccountTotalValueScalarWhereInput;
};

export type AccountTotalValueUpdateManyWithoutAccountInput = {
  connect?: Maybe<Array<AccountTotalValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountTotalValueCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<AccountTotalValueCreateWithoutAccountInput>>;
  createMany?: Maybe<AccountTotalValueCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<AccountTotalValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccountTotalValueScalarWhereInput>>;
  disconnect?: Maybe<Array<AccountTotalValueWhereUniqueInput>>;
  set?: Maybe<Array<AccountTotalValueWhereUniqueInput>>;
  update?: Maybe<Array<AccountTotalValueUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<AccountTotalValueUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<AccountTotalValueUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type AccountTotalValueUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<AccountTotalValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountTotalValueCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<AccountTotalValueCreateWithoutPropertyInput>>;
  createMany?: Maybe<AccountTotalValueCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<AccountTotalValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccountTotalValueScalarWhereInput>>;
  disconnect?: Maybe<Array<AccountTotalValueWhereUniqueInput>>;
  set?: Maybe<Array<AccountTotalValueWhereUniqueInput>>;
  update?: Maybe<Array<AccountTotalValueUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<AccountTotalValueUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<AccountTotalValueUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type AccountTotalValueUpdateManyWithoutVersionInput = {
  connect?: Maybe<Array<AccountTotalValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountTotalValueCreateOrConnectWithoutVersionInput>>;
  create?: Maybe<Array<AccountTotalValueCreateWithoutVersionInput>>;
  createMany?: Maybe<AccountTotalValueCreateManyVersionInputEnvelope>;
  delete?: Maybe<Array<AccountTotalValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccountTotalValueScalarWhereInput>>;
  disconnect?: Maybe<Array<AccountTotalValueWhereUniqueInput>>;
  set?: Maybe<Array<AccountTotalValueWhereUniqueInput>>;
  update?: Maybe<Array<AccountTotalValueUpdateWithWhereUniqueWithoutVersionInput>>;
  updateMany?: Maybe<Array<AccountTotalValueUpdateManyWithWhereWithoutVersionInput>>;
  upsert?: Maybe<Array<AccountTotalValueUpsertWithWhereUniqueWithoutVersionInput>>;
};

export type AccountTotalValueUpdateWithWhereUniqueWithoutAccountInput = {
  data: AccountTotalValueUpdateWithoutAccountInput;
  where: AccountTotalValueWhereUniqueInput;
};

export type AccountTotalValueUpdateWithWhereUniqueWithoutPropertyInput = {
  data: AccountTotalValueUpdateWithoutPropertyInput;
  where: AccountTotalValueWhereUniqueInput;
};

export type AccountTotalValueUpdateWithWhereUniqueWithoutVersionInput = {
  data: AccountTotalValueUpdateWithoutVersionInput;
  where: AccountTotalValueWhereUniqueInput;
};

export type AccountTotalValueUpdateWithoutAccountInput = {
  createdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutAccountTotalValuesInput>;
  updatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  value?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  version?: Maybe<ValueVersionUpdateOneRequiredWithoutAccountTotalValuesInput>;
};

export type AccountTotalValueUpdateWithoutPropertyInput = {
  account?: Maybe<AccountUpdateOneRequiredWithoutAccountTotalValuesInput>;
  createdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  value?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  version?: Maybe<ValueVersionUpdateOneRequiredWithoutAccountTotalValuesInput>;
};

export type AccountTotalValueUpdateWithoutVersionInput = {
  account?: Maybe<AccountUpdateOneRequiredWithoutAccountTotalValuesInput>;
  createdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutAccountTotalValuesInput>;
  updatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  value?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
};

export type AccountTotalValueUpsertWithWhereUniqueWithoutAccountInput = {
  create: AccountTotalValueCreateWithoutAccountInput;
  update: AccountTotalValueUpdateWithoutAccountInput;
  where: AccountTotalValueWhereUniqueInput;
};

export type AccountTotalValueUpsertWithWhereUniqueWithoutPropertyInput = {
  create: AccountTotalValueCreateWithoutPropertyInput;
  update: AccountTotalValueUpdateWithoutPropertyInput;
  where: AccountTotalValueWhereUniqueInput;
};

export type AccountTotalValueUpsertWithWhereUniqueWithoutVersionInput = {
  create: AccountTotalValueCreateWithoutVersionInput;
  update: AccountTotalValueUpdateWithoutVersionInput;
  where: AccountTotalValueWhereUniqueInput;
};

export type AccountTotalValueWhereInput = {
  AND?: Maybe<Array<AccountTotalValueWhereInput>>;
  NOT?: Maybe<Array<AccountTotalValueWhereInput>>;
  OR?: Maybe<Array<AccountTotalValueWhereInput>>;
  account?: Maybe<AccountRelationFilter>;
  accountId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringNullableFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeNullableFilter>;
  value?: Maybe<DecimalNullableFilter>;
  version?: Maybe<ValueVersionRelationFilter>;
  versionId?: Maybe<StringFilter>;
};

export type AccountTotalValueWhereUniqueInput = {
  accountId_versionId_propertyId?: Maybe<AccountTotalValueAccountIdVersionIdPropertyIdCompoundUniqueInput>;
};

export type AccountTwoPeriodNoteModel = {
  __typename?: 'AccountTwoPeriodNoteModel';
  accountId: Scalars['ID'];
  budgetNote?: Maybe<Scalars['String']>;
  reforecastNote?: Maybe<Scalars['String']>;
};

export enum AccountType {
  Account = 'ACCOUNT',
  Category = 'CATEGORY'
}

export type AccountUpdateManyMutationInput = {
  accountNumber?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AccountUpdateManyWithWhereWithoutBudgetCategoryInput = {
  data: AccountUpdateManyMutationInput;
  where: AccountScalarWhereInput;
};

export type AccountUpdateManyWithWhereWithoutClientInput = {
  data: AccountUpdateManyMutationInput;
  where: AccountScalarWhereInput;
};

export type AccountUpdateManyWithoutBudgetCategoryInput = {
  connect?: Maybe<Array<AccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountCreateOrConnectWithoutBudgetCategoryInput>>;
  create?: Maybe<Array<AccountCreateWithoutBudgetCategoryInput>>;
  createMany?: Maybe<AccountCreateManyBudgetCategoryInputEnvelope>;
  delete?: Maybe<Array<AccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccountScalarWhereInput>>;
  disconnect?: Maybe<Array<AccountWhereUniqueInput>>;
  set?: Maybe<Array<AccountWhereUniqueInput>>;
  update?: Maybe<Array<AccountUpdateWithWhereUniqueWithoutBudgetCategoryInput>>;
  updateMany?: Maybe<Array<AccountUpdateManyWithWhereWithoutBudgetCategoryInput>>;
  upsert?: Maybe<Array<AccountUpsertWithWhereUniqueWithoutBudgetCategoryInput>>;
};

export type AccountUpdateManyWithoutClientInput = {
  connect?: Maybe<Array<AccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<AccountCreateWithoutClientInput>>;
  createMany?: Maybe<AccountCreateManyClientInputEnvelope>;
  delete?: Maybe<Array<AccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccountScalarWhereInput>>;
  disconnect?: Maybe<Array<AccountWhereUniqueInput>>;
  set?: Maybe<Array<AccountWhereUniqueInput>>;
  update?: Maybe<Array<AccountUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<AccountUpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<AccountUpsertWithWhereUniqueWithoutClientInput>>;
};

export type AccountUpdateOneRequiredWithoutAccountTotalValuesInput = {
  connect?: Maybe<AccountWhereUniqueInput>;
  connectOrCreate?: Maybe<AccountCreateOrConnectWithoutAccountTotalValuesInput>;
  create?: Maybe<AccountCreateWithoutAccountTotalValuesInput>;
  update?: Maybe<AccountUpdateWithoutAccountTotalValuesInput>;
  upsert?: Maybe<AccountUpsertWithoutAccountTotalValuesInput>;
};

export type AccountUpdateWithWhereUniqueWithoutBudgetCategoryInput = {
  data: AccountUpdateWithoutBudgetCategoryInput;
  where: AccountWhereUniqueInput;
};

export type AccountUpdateWithWhereUniqueWithoutClientInput = {
  data: AccountUpdateWithoutClientInput;
  where: AccountWhereUniqueInput;
};

export type AccountUpdateWithoutAccountTotalValuesInput = {
  accountNumber?: Maybe<StringFieldUpdateOperationsInput>;
  budgetCategory?: Maybe<BudgetCategoryUpdateOneWithoutAccountsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AccountUpdateWithoutBudgetCategoryInput = {
  accountNumber?: Maybe<StringFieldUpdateOperationsInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutAccountInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AccountUpdateWithoutClientInput = {
  accountNumber?: Maybe<StringFieldUpdateOperationsInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutAccountInput>;
  budgetCategory?: Maybe<BudgetCategoryUpdateOneWithoutAccountsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AccountUpsertWithWhereUniqueWithoutBudgetCategoryInput = {
  create: AccountCreateWithoutBudgetCategoryInput;
  update: AccountUpdateWithoutBudgetCategoryInput;
  where: AccountWhereUniqueInput;
};

export type AccountUpsertWithWhereUniqueWithoutClientInput = {
  create: AccountCreateWithoutClientInput;
  update: AccountUpdateWithoutClientInput;
  where: AccountWhereUniqueInput;
};

export type AccountUpsertWithoutAccountTotalValuesInput = {
  create: AccountCreateWithoutAccountTotalValuesInput;
  update: AccountUpdateWithoutAccountTotalValuesInput;
};

export type AccountV2 = {
  __typename?: 'AccountV2';
  AccountsTopCardTags: Array<AccountsTopCardTags>;
  accPercentageDriverAccounts: Array<AccPercentageDriverAccount>;
  accountTopVendors: Array<AccountTopVendors>;
  accountValueOverride: Array<AccountValueOverride>;
  accountValues: Array<AccountValue>;
  accountVarianceNotes: Array<AccountVarianceNote>;
  appFolioAccountMetadata: Array<AppFolioReportAccountMetadata>;
  assignedAccountPercentageDrivers: Array<DriverAccountPercentage>;
  budgetCategoryValues: Array<BudgetCategoryValue>;
  budgetComponentMappings: Array<BudgetComponentMapping>;
  chartOfAccounts: ChartOfAccounts;
  chartOfAccountsId: Scalars['String'];
  children: Array<AccountV2>;
  client: Client;
  clientComments: Array<ClientCommentAccount>;
  clientId: Scalars['String'];
  clientUnitLevelConcessionConfigurations: Array<ClientUnitLevelConfiguration>;
  clientUnitLevelConfigurations: Array<ClientUnitLevelConfiguration>;
  consumedByAccountPercentageDrivers: Array<DriverAccountPercentage>;
  createdAt: Scalars['DateTime'];
  customItemDrivers: Array<DriverCustomItem>;
  driverAssignments: Array<DriverAssignment>;
  driverAugmentsAccountPercentage: Array<DriverAugmentAccountPercentage>;
  forecastedAccountValues: Array<ForecastedAccountValue>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers: Array<DriverGrowth>;
  id: Scalars['String'];
  instructions: Array<Instruction>;
  level: Scalars['Int'];
  lockedAccounts: Array<LockedAccount>;
  negate: Scalars['Boolean'];
  operationalDrivers: Array<DriverOperational>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2>;
  parentId?: Maybe<Scalars['String']>;
  payrollPositionCompensationAccount: Array<PayrollPositionCompensationAccount>;
  payrollPositionCompensationAccountV2: Array<PayrollPositionCompensationAccountV2>;
  payrollPositionCompensationItemAccount: Array<PayrollPositionCompensationItemAccount>;
  payrollPositionCompensationItemAccountV2: Array<PayrollPositionCompensationItemAccountV2>;
  propertyManagementSystemAccountValues: Array<PropertyManagementSystemAccountValue>;
  propertyUnitLevelConcessionConfigurations: Array<PropertyUnitLevelConfiguration>;
  propertyUnitLevelConfigurations: Array<PropertyUnitLevelConfiguration>;
  renovationCostCategories: Array<RenovationCostCategory>;
  renovationPremiumAccounts: Array<RenovationClientConfiguration>;
  rentalRevenueCapture: Array<RentalRevenueCapture>;
  reportTableAccounts: Array<ReportTableAccount>;
  revenueDrivers: Array<DriverRevenue>;
  starredAccounts: Array<StarredAccount>;
  transactions: Array<Transaction>;
  type: AccountType;
  updatedAt: Scalars['DateTime'];
  worksheets: Array<Worksheet>;
  yardiReportAccountMetadata: Array<YardiReportAccountMetadata>;
};


export type AccountV2AccountsTopCardTagsArgs = {
  cursor?: Maybe<AccountsTopCardTagsWhereUniqueInput>;
  distinct?: Maybe<Array<AccountsTopCardTagsScalarFieldEnum>>;
  orderBy?: Maybe<Array<AccountsTopCardTagsOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AccountsTopCardTagsWhereInput>;
};


export type AccountV2AccPercentageDriverAccountsArgs = {
  cursor?: Maybe<AccPercentageDriverAccountWhereUniqueInput>;
  distinct?: Maybe<Array<AccPercentageDriverAccountScalarFieldEnum>>;
  orderBy?: Maybe<Array<AccPercentageDriverAccountOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AccPercentageDriverAccountWhereInput>;
};


export type AccountV2AccountTopVendorsArgs = {
  cursor?: Maybe<AccountTopVendorsWhereUniqueInput>;
  distinct?: Maybe<Array<AccountTopVendorsScalarFieldEnum>>;
  orderBy?: Maybe<Array<AccountTopVendorsOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AccountTopVendorsWhereInput>;
};


export type AccountV2AccountValueOverrideArgs = {
  cursor?: Maybe<AccountValueOverrideWhereUniqueInput>;
  distinct?: Maybe<Array<AccountValueOverrideScalarFieldEnum>>;
  orderBy?: Maybe<Array<AccountValueOverrideOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AccountValueOverrideWhereInput>;
};


export type AccountV2AccountValuesArgs = {
  cursor?: Maybe<AccountValueWhereUniqueInput>;
  distinct?: Maybe<Array<AccountValueScalarFieldEnum>>;
  orderBy?: Maybe<Array<AccountValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AccountValueWhereInput>;
};


export type AccountV2AccountVarianceNotesArgs = {
  cursor?: Maybe<AccountVarianceNoteWhereUniqueInput>;
  distinct?: Maybe<Array<AccountVarianceNoteScalarFieldEnum>>;
  orderBy?: Maybe<Array<AccountVarianceNoteOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AccountVarianceNoteWhereInput>;
};


export type AccountV2AppFolioAccountMetadataArgs = {
  cursor?: Maybe<AppFolioReportAccountMetadataWhereUniqueInput>;
  distinct?: Maybe<Array<AppFolioReportAccountMetadataScalarFieldEnum>>;
  orderBy?: Maybe<Array<AppFolioReportAccountMetadataOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppFolioReportAccountMetadataWhereInput>;
};


export type AccountV2AssignedAccountPercentageDriversArgs = {
  cursor?: Maybe<DriverAccountPercentageWhereUniqueInput>;
  distinct?: Maybe<Array<DriverAccountPercentageScalarFieldEnum>>;
  orderBy?: Maybe<Array<DriverAccountPercentageOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DriverAccountPercentageWhereInput>;
};


export type AccountV2BudgetCategoryValuesArgs = {
  cursor?: Maybe<BudgetCategoryValueWhereUniqueInput>;
  distinct?: Maybe<Array<BudgetCategoryValueScalarFieldEnum>>;
  orderBy?: Maybe<Array<BudgetCategoryValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BudgetCategoryValueWhereInput>;
};


export type AccountV2BudgetComponentMappingsArgs = {
  cursor?: Maybe<BudgetComponentMappingWhereUniqueInput>;
  distinct?: Maybe<Array<BudgetComponentMappingScalarFieldEnum>>;
  orderBy?: Maybe<Array<BudgetComponentMappingOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BudgetComponentMappingWhereInput>;
};


export type AccountV2ChildrenArgs = {
  cursor?: Maybe<AccountV2WhereUniqueInput>;
  distinct?: Maybe<Array<AccountV2ScalarFieldEnum>>;
  orderBy?: Maybe<Array<AccountV2OrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AccountV2WhereInput>;
};


export type AccountV2ClientCommentsArgs = {
  cursor?: Maybe<ClientCommentAccountWhereUniqueInput>;
  distinct?: Maybe<Array<ClientCommentAccountScalarFieldEnum>>;
  orderBy?: Maybe<Array<ClientCommentAccountOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClientCommentAccountWhereInput>;
};


export type AccountV2ClientUnitLevelConcessionConfigurationsArgs = {
  cursor?: Maybe<ClientUnitLevelConfigurationWhereUniqueInput>;
  distinct?: Maybe<Array<ClientUnitLevelConfigurationScalarFieldEnum>>;
  orderBy?: Maybe<Array<ClientUnitLevelConfigurationOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClientUnitLevelConfigurationWhereInput>;
};


export type AccountV2ClientUnitLevelConfigurationsArgs = {
  cursor?: Maybe<ClientUnitLevelConfigurationWhereUniqueInput>;
  distinct?: Maybe<Array<ClientUnitLevelConfigurationScalarFieldEnum>>;
  orderBy?: Maybe<Array<ClientUnitLevelConfigurationOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClientUnitLevelConfigurationWhereInput>;
};


export type AccountV2ConsumedByAccountPercentageDriversArgs = {
  cursor?: Maybe<DriverAccountPercentageWhereUniqueInput>;
  distinct?: Maybe<Array<DriverAccountPercentageScalarFieldEnum>>;
  orderBy?: Maybe<Array<DriverAccountPercentageOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DriverAccountPercentageWhereInput>;
};


export type AccountV2CustomItemDriversArgs = {
  cursor?: Maybe<DriverCustomItemWhereUniqueInput>;
  distinct?: Maybe<Array<DriverCustomItemScalarFieldEnum>>;
  orderBy?: Maybe<Array<DriverCustomItemOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DriverCustomItemWhereInput>;
};


export type AccountV2DriverAssignmentsArgs = {
  cursor?: Maybe<DriverAssignmentWhereUniqueInput>;
  distinct?: Maybe<Array<DriverAssignmentScalarFieldEnum>>;
  orderBy?: Maybe<Array<DriverAssignmentOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DriverAssignmentWhereInput>;
};


export type AccountV2DriverAugmentsAccountPercentageArgs = {
  cursor?: Maybe<DriverAugmentAccountPercentageWhereUniqueInput>;
  distinct?: Maybe<Array<DriverAugmentAccountPercentageScalarFieldEnum>>;
  orderBy?: Maybe<Array<DriverAugmentAccountPercentageOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DriverAugmentAccountPercentageWhereInput>;
};


export type AccountV2ForecastedAccountValuesArgs = {
  cursor?: Maybe<ForecastedAccountValueWhereUniqueInput>;
  distinct?: Maybe<Array<ForecastedAccountValueScalarFieldEnum>>;
  orderBy?: Maybe<Array<ForecastedAccountValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ForecastedAccountValueWhereInput>;
};


export type AccountV2GrowthDriversArgs = {
  cursor?: Maybe<DriverGrowthWhereUniqueInput>;
  distinct?: Maybe<Array<DriverGrowthScalarFieldEnum>>;
  orderBy?: Maybe<Array<DriverGrowthOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DriverGrowthWhereInput>;
};


export type AccountV2InstructionsArgs = {
  cursor?: Maybe<InstructionWhereUniqueInput>;
  distinct?: Maybe<Array<InstructionScalarFieldEnum>>;
  orderBy?: Maybe<Array<InstructionOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InstructionWhereInput>;
};


export type AccountV2LockedAccountsArgs = {
  cursor?: Maybe<LockedAccountWhereUniqueInput>;
  distinct?: Maybe<Array<LockedAccountScalarFieldEnum>>;
  orderBy?: Maybe<Array<LockedAccountOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LockedAccountWhereInput>;
};


export type AccountV2OperationalDriversArgs = {
  cursor?: Maybe<DriverOperationalWhereUniqueInput>;
  distinct?: Maybe<Array<DriverOperationalScalarFieldEnum>>;
  orderBy?: Maybe<Array<DriverOperationalOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DriverOperationalWhereInput>;
};


export type AccountV2PayrollPositionCompensationAccountArgs = {
  cursor?: Maybe<PayrollPositionCompensationAccountWhereUniqueInput>;
  distinct?: Maybe<Array<PayrollPositionCompensationAccountScalarFieldEnum>>;
  orderBy?: Maybe<Array<PayrollPositionCompensationAccountOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PayrollPositionCompensationAccountWhereInput>;
};


export type AccountV2PayrollPositionCompensationAccountV2Args = {
  cursor?: Maybe<PayrollPositionCompensationAccountV2WhereUniqueInput>;
  distinct?: Maybe<Array<PayrollPositionCompensationAccountV2ScalarFieldEnum>>;
  orderBy?: Maybe<Array<PayrollPositionCompensationAccountV2OrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PayrollPositionCompensationAccountV2WhereInput>;
};


export type AccountV2PayrollPositionCompensationItemAccountArgs = {
  cursor?: Maybe<PayrollPositionCompensationItemAccountWhereUniqueInput>;
  distinct?: Maybe<Array<PayrollPositionCompensationItemAccountScalarFieldEnum>>;
  orderBy?: Maybe<Array<PayrollPositionCompensationItemAccountOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PayrollPositionCompensationItemAccountWhereInput>;
};


export type AccountV2PayrollPositionCompensationItemAccountV2Args = {
  cursor?: Maybe<PayrollPositionCompensationItemAccountV2WhereUniqueInput>;
  distinct?: Maybe<Array<PayrollPositionCompensationItemAccountV2ScalarFieldEnum>>;
  orderBy?: Maybe<Array<PayrollPositionCompensationItemAccountV2OrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PayrollPositionCompensationItemAccountV2WhereInput>;
};


export type AccountV2PropertyManagementSystemAccountValuesArgs = {
  cursor?: Maybe<PropertyManagementSystemAccountValueWhereUniqueInput>;
  distinct?: Maybe<Array<PropertyManagementSystemAccountValueScalarFieldEnum>>;
  orderBy?: Maybe<Array<PropertyManagementSystemAccountValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PropertyManagementSystemAccountValueWhereInput>;
};


export type AccountV2PropertyUnitLevelConcessionConfigurationsArgs = {
  cursor?: Maybe<PropertyUnitLevelConfigurationWhereUniqueInput>;
  distinct?: Maybe<Array<PropertyUnitLevelConfigurationScalarFieldEnum>>;
  orderBy?: Maybe<Array<PropertyUnitLevelConfigurationOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PropertyUnitLevelConfigurationWhereInput>;
};


export type AccountV2PropertyUnitLevelConfigurationsArgs = {
  cursor?: Maybe<PropertyUnitLevelConfigurationWhereUniqueInput>;
  distinct?: Maybe<Array<PropertyUnitLevelConfigurationScalarFieldEnum>>;
  orderBy?: Maybe<Array<PropertyUnitLevelConfigurationOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PropertyUnitLevelConfigurationWhereInput>;
};


export type AccountV2RenovationCostCategoriesArgs = {
  cursor?: Maybe<RenovationCostCategoryWhereUniqueInput>;
  distinct?: Maybe<Array<RenovationCostCategoryScalarFieldEnum>>;
  orderBy?: Maybe<Array<RenovationCostCategoryOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<RenovationCostCategoryWhereInput>;
};


export type AccountV2RenovationPremiumAccountsArgs = {
  cursor?: Maybe<RenovationClientConfigurationWhereUniqueInput>;
  distinct?: Maybe<Array<RenovationClientConfigurationScalarFieldEnum>>;
  orderBy?: Maybe<Array<RenovationClientConfigurationOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<RenovationClientConfigurationWhereInput>;
};


export type AccountV2RentalRevenueCaptureArgs = {
  cursor?: Maybe<RentalRevenueCaptureWhereUniqueInput>;
  distinct?: Maybe<Array<RentalRevenueCaptureScalarFieldEnum>>;
  orderBy?: Maybe<Array<RentalRevenueCaptureOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<RentalRevenueCaptureWhereInput>;
};


export type AccountV2ReportTableAccountsArgs = {
  cursor?: Maybe<ReportTableAccountWhereUniqueInput>;
  distinct?: Maybe<Array<ReportTableAccountScalarFieldEnum>>;
  orderBy?: Maybe<Array<ReportTableAccountOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ReportTableAccountWhereInput>;
};


export type AccountV2RevenueDriversArgs = {
  cursor?: Maybe<DriverRevenueWhereUniqueInput>;
  distinct?: Maybe<Array<DriverRevenueScalarFieldEnum>>;
  orderBy?: Maybe<Array<DriverRevenueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DriverRevenueWhereInput>;
};


export type AccountV2StarredAccountsArgs = {
  cursor?: Maybe<StarredAccountWhereUniqueInput>;
  distinct?: Maybe<Array<StarredAccountScalarFieldEnum>>;
  orderBy?: Maybe<Array<StarredAccountOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StarredAccountWhereInput>;
};


export type AccountV2TransactionsArgs = {
  cursor?: Maybe<TransactionWhereUniqueInput>;
  distinct?: Maybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: Maybe<Array<TransactionOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TransactionWhereInput>;
};


export type AccountV2WorksheetsArgs = {
  cursor?: Maybe<WorksheetWhereUniqueInput>;
  distinct?: Maybe<Array<WorksheetScalarFieldEnum>>;
  orderBy?: Maybe<Array<WorksheetOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<WorksheetWhereInput>;
};


export type AccountV2YardiReportAccountMetadataArgs = {
  cursor?: Maybe<YardiReportAccountMetadataWhereUniqueInput>;
  distinct?: Maybe<Array<YardiReportAccountMetadataScalarFieldEnum>>;
  orderBy?: Maybe<Array<YardiReportAccountMetadataOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<YardiReportAccountMetadataWhereInput>;
};

export type AccountV2ClientIdChartOfAccountsIdGlNumberGlNameCompoundUniqueInput = {
  chartOfAccountsId: Scalars['String'];
  clientId: Scalars['String'];
  glName: Scalars['String'];
  glNumber: Scalars['String'];
};

export type AccountV2CreateManyChartOfAccountsInput = {
  clientId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  level: Scalars['Int'];
  negate: Scalars['Boolean'];
  order: Scalars['Int'];
  parentId?: Maybe<Scalars['String']>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccountV2CreateManyChartOfAccountsInputEnvelope = {
  data: Array<AccountV2CreateManyChartOfAccountsInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccountV2CreateManyClientInput = {
  chartOfAccountsId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  level: Scalars['Int'];
  negate: Scalars['Boolean'];
  order: Scalars['Int'];
  parentId?: Maybe<Scalars['String']>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccountV2CreateManyClientInputEnvelope = {
  data: Array<AccountV2CreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccountV2CreateManyParentInput = {
  chartOfAccountsId: Scalars['String'];
  clientId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  level: Scalars['Int'];
  negate: Scalars['Boolean'];
  order: Scalars['Int'];
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccountV2CreateManyParentInputEnvelope = {
  data: Array<AccountV2CreateManyParentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccountV2CreateNestedManyWithoutChartOfAccountsInput = {
  connect?: Maybe<Array<AccountV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountV2CreateOrConnectWithoutChartOfAccountsInput>>;
  create?: Maybe<Array<AccountV2CreateWithoutChartOfAccountsInput>>;
  createMany?: Maybe<AccountV2CreateManyChartOfAccountsInputEnvelope>;
};

export type AccountV2CreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<AccountV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountV2CreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<AccountV2CreateWithoutClientInput>>;
  createMany?: Maybe<AccountV2CreateManyClientInputEnvelope>;
};

export type AccountV2CreateNestedManyWithoutParentInput = {
  connect?: Maybe<Array<AccountV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountV2CreateOrConnectWithoutParentInput>>;
  create?: Maybe<Array<AccountV2CreateWithoutParentInput>>;
  createMany?: Maybe<AccountV2CreateManyParentInputEnvelope>;
};

export type AccountV2CreateNestedOneWithoutAccPercentageDriverAccountsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutAccPercentageDriverAccountsInput>;
  create?: Maybe<AccountV2CreateWithoutAccPercentageDriverAccountsInput>;
};

export type AccountV2CreateNestedOneWithoutAccountTopVendorsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutAccountTopVendorsInput>;
  create?: Maybe<AccountV2CreateWithoutAccountTopVendorsInput>;
};

export type AccountV2CreateNestedOneWithoutAccountValueOverrideInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutAccountValueOverrideInput>;
  create?: Maybe<AccountV2CreateWithoutAccountValueOverrideInput>;
};

export type AccountV2CreateNestedOneWithoutAccountValuesInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutAccountValuesInput>;
  create?: Maybe<AccountV2CreateWithoutAccountValuesInput>;
};

export type AccountV2CreateNestedOneWithoutAccountVarianceNotesInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutAccountVarianceNotesInput>;
  create?: Maybe<AccountV2CreateWithoutAccountVarianceNotesInput>;
};

export type AccountV2CreateNestedOneWithoutAssignedAccountPercentageDriversInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutAssignedAccountPercentageDriversInput>;
  create?: Maybe<AccountV2CreateWithoutAssignedAccountPercentageDriversInput>;
};

export type AccountV2CreateNestedOneWithoutBudgetCategoryValuesInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutBudgetCategoryValuesInput>;
  create?: Maybe<AccountV2CreateWithoutBudgetCategoryValuesInput>;
};

export type AccountV2CreateNestedOneWithoutBudgetComponentMappingsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutBudgetComponentMappingsInput>;
  create?: Maybe<AccountV2CreateWithoutBudgetComponentMappingsInput>;
};

export type AccountV2CreateNestedOneWithoutChildrenInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutChildrenInput>;
  create?: Maybe<AccountV2CreateWithoutChildrenInput>;
};

export type AccountV2CreateNestedOneWithoutClientUnitLevelConcessionConfigurationsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutClientUnitLevelConcessionConfigurationsInput>;
  create?: Maybe<AccountV2CreateWithoutClientUnitLevelConcessionConfigurationsInput>;
};

export type AccountV2CreateNestedOneWithoutClientUnitLevelConfigurationsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutClientUnitLevelConfigurationsInput>;
  create?: Maybe<AccountV2CreateWithoutClientUnitLevelConfigurationsInput>;
};

export type AccountV2CreateNestedOneWithoutConsumedByAccountPercentageDriversInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutConsumedByAccountPercentageDriversInput>;
  create?: Maybe<AccountV2CreateWithoutConsumedByAccountPercentageDriversInput>;
};

export type AccountV2CreateNestedOneWithoutCustomItemDriversInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutCustomItemDriversInput>;
  create?: Maybe<AccountV2CreateWithoutCustomItemDriversInput>;
};

export type AccountV2CreateNestedOneWithoutDriverAssignmentsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutDriverAssignmentsInput>;
  create?: Maybe<AccountV2CreateWithoutDriverAssignmentsInput>;
};

export type AccountV2CreateNestedOneWithoutDriverAugmentsAccountPercentageInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutDriverAugmentsAccountPercentageInput>;
  create?: Maybe<AccountV2CreateWithoutDriverAugmentsAccountPercentageInput>;
};

export type AccountV2CreateNestedOneWithoutForecastedAccountValuesInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutForecastedAccountValuesInput>;
  create?: Maybe<AccountV2CreateWithoutForecastedAccountValuesInput>;
};

export type AccountV2CreateNestedOneWithoutGrowthDriversInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutGrowthDriversInput>;
  create?: Maybe<AccountV2CreateWithoutGrowthDriversInput>;
};

export type AccountV2CreateNestedOneWithoutInstructionsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutInstructionsInput>;
  create?: Maybe<AccountV2CreateWithoutInstructionsInput>;
};

export type AccountV2CreateNestedOneWithoutLockedAccountsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutLockedAccountsInput>;
  create?: Maybe<AccountV2CreateWithoutLockedAccountsInput>;
};

export type AccountV2CreateNestedOneWithoutOperationalDriversInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutOperationalDriversInput>;
  create?: Maybe<AccountV2CreateWithoutOperationalDriversInput>;
};

export type AccountV2CreateNestedOneWithoutPayrollPositionCompensationAccountInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutPayrollPositionCompensationAccountInput>;
  create?: Maybe<AccountV2CreateWithoutPayrollPositionCompensationAccountInput>;
};

export type AccountV2CreateNestedOneWithoutPayrollPositionCompensationAccountV2Input = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutPayrollPositionCompensationAccountV2Input>;
  create?: Maybe<AccountV2CreateWithoutPayrollPositionCompensationAccountV2Input>;
};

export type AccountV2CreateNestedOneWithoutPayrollPositionCompensationItemAccountInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutPayrollPositionCompensationItemAccountInput>;
  create?: Maybe<AccountV2CreateWithoutPayrollPositionCompensationItemAccountInput>;
};

export type AccountV2CreateNestedOneWithoutPayrollPositionCompensationItemAccountV2Input = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutPayrollPositionCompensationItemAccountV2Input>;
  create?: Maybe<AccountV2CreateWithoutPayrollPositionCompensationItemAccountV2Input>;
};

export type AccountV2CreateNestedOneWithoutPropertyManagementSystemAccountValuesInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutPropertyManagementSystemAccountValuesInput>;
  create?: Maybe<AccountV2CreateWithoutPropertyManagementSystemAccountValuesInput>;
};

export type AccountV2CreateNestedOneWithoutPropertyUnitLevelConcessionConfigurationsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutPropertyUnitLevelConcessionConfigurationsInput>;
  create?: Maybe<AccountV2CreateWithoutPropertyUnitLevelConcessionConfigurationsInput>;
};

export type AccountV2CreateNestedOneWithoutPropertyUnitLevelConfigurationsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutPropertyUnitLevelConfigurationsInput>;
  create?: Maybe<AccountV2CreateWithoutPropertyUnitLevelConfigurationsInput>;
};

export type AccountV2CreateNestedOneWithoutRenovationCostCategoriesInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutRenovationCostCategoriesInput>;
  create?: Maybe<AccountV2CreateWithoutRenovationCostCategoriesInput>;
};

export type AccountV2CreateNestedOneWithoutRenovationPremiumAccountsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutRenovationPremiumAccountsInput>;
  create?: Maybe<AccountV2CreateWithoutRenovationPremiumAccountsInput>;
};

export type AccountV2CreateNestedOneWithoutRentalRevenueCaptureInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutRentalRevenueCaptureInput>;
  create?: Maybe<AccountV2CreateWithoutRentalRevenueCaptureInput>;
};

export type AccountV2CreateNestedOneWithoutReportTableAccountsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutReportTableAccountsInput>;
  create?: Maybe<AccountV2CreateWithoutReportTableAccountsInput>;
};

export type AccountV2CreateNestedOneWithoutRevenueDriversInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutRevenueDriversInput>;
  create?: Maybe<AccountV2CreateWithoutRevenueDriversInput>;
};

export type AccountV2CreateNestedOneWithoutStarredAccountsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutStarredAccountsInput>;
  create?: Maybe<AccountV2CreateWithoutStarredAccountsInput>;
};

export type AccountV2CreateNestedOneWithoutTransactionsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutTransactionsInput>;
  create?: Maybe<AccountV2CreateWithoutTransactionsInput>;
};

export type AccountV2CreateNestedOneWithoutWorksheetsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutWorksheetsInput>;
  create?: Maybe<AccountV2CreateWithoutWorksheetsInput>;
};

export type AccountV2CreateNestedOneWithoutYardiReportAccountMetadataInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutYardiReportAccountMetadataInput>;
  create?: Maybe<AccountV2CreateWithoutYardiReportAccountMetadataInput>;
};

export type AccountV2CreateOrConnectWithoutAccPercentageDriverAccountsInput = {
  create: AccountV2CreateWithoutAccPercentageDriverAccountsInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutAccountTopVendorsInput = {
  create: AccountV2CreateWithoutAccountTopVendorsInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutAccountValueOverrideInput = {
  create: AccountV2CreateWithoutAccountValueOverrideInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutAccountValuesInput = {
  create: AccountV2CreateWithoutAccountValuesInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutAccountVarianceNotesInput = {
  create: AccountV2CreateWithoutAccountVarianceNotesInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutAssignedAccountPercentageDriversInput = {
  create: AccountV2CreateWithoutAssignedAccountPercentageDriversInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutBudgetCategoryValuesInput = {
  create: AccountV2CreateWithoutBudgetCategoryValuesInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutBudgetComponentMappingsInput = {
  create: AccountV2CreateWithoutBudgetComponentMappingsInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutChartOfAccountsInput = {
  create: AccountV2CreateWithoutChartOfAccountsInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutChildrenInput = {
  create: AccountV2CreateWithoutChildrenInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutClientInput = {
  create: AccountV2CreateWithoutClientInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutClientUnitLevelConcessionConfigurationsInput = {
  create: AccountV2CreateWithoutClientUnitLevelConcessionConfigurationsInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutClientUnitLevelConfigurationsInput = {
  create: AccountV2CreateWithoutClientUnitLevelConfigurationsInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutConsumedByAccountPercentageDriversInput = {
  create: AccountV2CreateWithoutConsumedByAccountPercentageDriversInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutCustomItemDriversInput = {
  create: AccountV2CreateWithoutCustomItemDriversInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutDriverAssignmentsInput = {
  create: AccountV2CreateWithoutDriverAssignmentsInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutDriverAugmentsAccountPercentageInput = {
  create: AccountV2CreateWithoutDriverAugmentsAccountPercentageInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutForecastedAccountValuesInput = {
  create: AccountV2CreateWithoutForecastedAccountValuesInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutGrowthDriversInput = {
  create: AccountV2CreateWithoutGrowthDriversInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutInstructionsInput = {
  create: AccountV2CreateWithoutInstructionsInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutLockedAccountsInput = {
  create: AccountV2CreateWithoutLockedAccountsInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutOperationalDriversInput = {
  create: AccountV2CreateWithoutOperationalDriversInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutParentInput = {
  create: AccountV2CreateWithoutParentInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutPayrollPositionCompensationAccountInput = {
  create: AccountV2CreateWithoutPayrollPositionCompensationAccountInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutPayrollPositionCompensationAccountV2Input = {
  create: AccountV2CreateWithoutPayrollPositionCompensationAccountV2Input;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutPayrollPositionCompensationItemAccountInput = {
  create: AccountV2CreateWithoutPayrollPositionCompensationItemAccountInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutPayrollPositionCompensationItemAccountV2Input = {
  create: AccountV2CreateWithoutPayrollPositionCompensationItemAccountV2Input;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutPropertyManagementSystemAccountValuesInput = {
  create: AccountV2CreateWithoutPropertyManagementSystemAccountValuesInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutPropertyUnitLevelConcessionConfigurationsInput = {
  create: AccountV2CreateWithoutPropertyUnitLevelConcessionConfigurationsInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutPropertyUnitLevelConfigurationsInput = {
  create: AccountV2CreateWithoutPropertyUnitLevelConfigurationsInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutRenovationCostCategoriesInput = {
  create: AccountV2CreateWithoutRenovationCostCategoriesInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutRenovationPremiumAccountsInput = {
  create: AccountV2CreateWithoutRenovationPremiumAccountsInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutRentalRevenueCaptureInput = {
  create: AccountV2CreateWithoutRentalRevenueCaptureInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutReportTableAccountsInput = {
  create: AccountV2CreateWithoutReportTableAccountsInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutRevenueDriversInput = {
  create: AccountV2CreateWithoutRevenueDriversInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutStarredAccountsInput = {
  create: AccountV2CreateWithoutStarredAccountsInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutTransactionsInput = {
  create: AccountV2CreateWithoutTransactionsInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutWorksheetsInput = {
  create: AccountV2CreateWithoutWorksheetsInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateOrConnectWithoutYardiReportAccountMetadataInput = {
  create: AccountV2CreateWithoutYardiReportAccountMetadataInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2CreateWithoutAccPercentageDriverAccountsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutAccountTopVendorsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutAccountValueOverrideInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutAccountValuesInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutAccountVarianceNotesInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutAssignedAccountPercentageDriversInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutBudgetCategoryValuesInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutBudgetComponentMappingsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutChartOfAccountsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutChildrenInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutClientInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutClientUnitLevelConcessionConfigurationsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutClientUnitLevelConfigurationsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutConsumedByAccountPercentageDriversInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutCustomItemDriversInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutDriverAssignmentsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutDriverAugmentsAccountPercentageInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutForecastedAccountValuesInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutGrowthDriversInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutInstructionsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutLockedAccountsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutOperationalDriversInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutParentInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutPayrollPositionCompensationAccountInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutPayrollPositionCompensationAccountV2Input = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutPayrollPositionCompensationItemAccountInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutPayrollPositionCompensationItemAccountV2Input = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutPropertyManagementSystemAccountValuesInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutPropertyUnitLevelConcessionConfigurationsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutPropertyUnitLevelConfigurationsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutRenovationCostCategoriesInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutRenovationPremiumAccountsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutRentalRevenueCaptureInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutReportTableAccountsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutRevenueDriversInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutStarredAccountsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutTransactionsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutWorksheetsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutAccountInput>;
};

export type AccountV2CreateWithoutYardiReportAccountMetadataInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsCreateNestedManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutAccountsV2Input;
  children?: Maybe<AccountV2CreateNestedManyWithoutParentInput>;
  client: ClientCreateNestedOneWithoutAccountsV2Input;
  clientComments?: Maybe<ClientCommentAccountCreateNestedManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutAccountInput>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutAccountInput>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutAccountInput>;
  level: Scalars['Int'];
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutAccountInput>;
  negate: Scalars['Boolean'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutDestinationAccountInput>;
  order: Scalars['Int'];
  parent?: Maybe<AccountV2CreateNestedOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountCreateNestedManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutAccountInput>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutAccountInput>;
  type: AccountType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutDestinationAccountInput>;
};

export type AccountV2ListRelationFilter = {
  every?: Maybe<AccountV2WhereInput>;
  none?: Maybe<AccountV2WhereInput>;
  some?: Maybe<AccountV2WhereInput>;
};

export type AccountV2OrderByInput = {
  chartOfAccountsId?: Maybe<SortOrder>;
  clientId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  glName?: Maybe<SortOrder>;
  glNumber?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  level?: Maybe<SortOrder>;
  negate?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  parentId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AccountV2RelationFilter = {
  is?: Maybe<AccountV2WhereInput>;
  isNot?: Maybe<AccountV2WhereInput>;
};

export enum AccountV2ScalarFieldEnum {
  ChartOfAccountsId = 'chartOfAccountsId',
  ClientId = 'clientId',
  CreatedAt = 'createdAt',
  GlName = 'glName',
  GlNumber = 'glNumber',
  Id = 'id',
  Level = 'level',
  Negate = 'negate',
  Order = 'order',
  ParentId = 'parentId',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type AccountV2ScalarWhereInput = {
  AND?: Maybe<Array<AccountV2ScalarWhereInput>>;
  NOT?: Maybe<Array<AccountV2ScalarWhereInput>>;
  OR?: Maybe<Array<AccountV2ScalarWhereInput>>;
  chartOfAccountsId?: Maybe<StringFilter>;
  clientId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  glName?: Maybe<StringFilter>;
  glNumber?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  level?: Maybe<IntFilter>;
  negate?: Maybe<BoolFilter>;
  order?: Maybe<IntFilter>;
  parentId?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumAccountTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AccountV2UpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AccountV2UpdateManyWithWhereWithoutChartOfAccountsInput = {
  data: AccountV2UpdateManyMutationInput;
  where: AccountV2ScalarWhereInput;
};

export type AccountV2UpdateManyWithWhereWithoutClientInput = {
  data: AccountV2UpdateManyMutationInput;
  where: AccountV2ScalarWhereInput;
};

export type AccountV2UpdateManyWithWhereWithoutParentInput = {
  data: AccountV2UpdateManyMutationInput;
  where: AccountV2ScalarWhereInput;
};

export type AccountV2UpdateManyWithoutChartOfAccountsInput = {
  connect?: Maybe<Array<AccountV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountV2CreateOrConnectWithoutChartOfAccountsInput>>;
  create?: Maybe<Array<AccountV2CreateWithoutChartOfAccountsInput>>;
  createMany?: Maybe<AccountV2CreateManyChartOfAccountsInputEnvelope>;
  delete?: Maybe<Array<AccountV2WhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccountV2ScalarWhereInput>>;
  disconnect?: Maybe<Array<AccountV2WhereUniqueInput>>;
  set?: Maybe<Array<AccountV2WhereUniqueInput>>;
  update?: Maybe<Array<AccountV2UpdateWithWhereUniqueWithoutChartOfAccountsInput>>;
  updateMany?: Maybe<Array<AccountV2UpdateManyWithWhereWithoutChartOfAccountsInput>>;
  upsert?: Maybe<Array<AccountV2UpsertWithWhereUniqueWithoutChartOfAccountsInput>>;
};

export type AccountV2UpdateManyWithoutClientInput = {
  connect?: Maybe<Array<AccountV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountV2CreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<AccountV2CreateWithoutClientInput>>;
  createMany?: Maybe<AccountV2CreateManyClientInputEnvelope>;
  delete?: Maybe<Array<AccountV2WhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccountV2ScalarWhereInput>>;
  disconnect?: Maybe<Array<AccountV2WhereUniqueInput>>;
  set?: Maybe<Array<AccountV2WhereUniqueInput>>;
  update?: Maybe<Array<AccountV2UpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<AccountV2UpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<AccountV2UpsertWithWhereUniqueWithoutClientInput>>;
};

export type AccountV2UpdateManyWithoutParentInput = {
  connect?: Maybe<Array<AccountV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountV2CreateOrConnectWithoutParentInput>>;
  create?: Maybe<Array<AccountV2CreateWithoutParentInput>>;
  createMany?: Maybe<AccountV2CreateManyParentInputEnvelope>;
  delete?: Maybe<Array<AccountV2WhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccountV2ScalarWhereInput>>;
  disconnect?: Maybe<Array<AccountV2WhereUniqueInput>>;
  set?: Maybe<Array<AccountV2WhereUniqueInput>>;
  update?: Maybe<Array<AccountV2UpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: Maybe<Array<AccountV2UpdateManyWithWhereWithoutParentInput>>;
  upsert?: Maybe<Array<AccountV2UpsertWithWhereUniqueWithoutParentInput>>;
};

export type AccountV2UpdateOneRequiredWithoutAccountTopVendorsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutAccountTopVendorsInput>;
  create?: Maybe<AccountV2CreateWithoutAccountTopVendorsInput>;
  update?: Maybe<AccountV2UpdateWithoutAccountTopVendorsInput>;
  upsert?: Maybe<AccountV2UpsertWithoutAccountTopVendorsInput>;
};

export type AccountV2UpdateOneRequiredWithoutAssignedAccountPercentageDriversInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutAssignedAccountPercentageDriversInput>;
  create?: Maybe<AccountV2CreateWithoutAssignedAccountPercentageDriversInput>;
  update?: Maybe<AccountV2UpdateWithoutAssignedAccountPercentageDriversInput>;
  upsert?: Maybe<AccountV2UpsertWithoutAssignedAccountPercentageDriversInput>;
};

export type AccountV2UpdateOneRequiredWithoutClientUnitLevelConcessionConfigurationsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutClientUnitLevelConcessionConfigurationsInput>;
  create?: Maybe<AccountV2CreateWithoutClientUnitLevelConcessionConfigurationsInput>;
  update?: Maybe<AccountV2UpdateWithoutClientUnitLevelConcessionConfigurationsInput>;
  upsert?: Maybe<AccountV2UpsertWithoutClientUnitLevelConcessionConfigurationsInput>;
};

export type AccountV2UpdateOneRequiredWithoutClientUnitLevelConfigurationsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutClientUnitLevelConfigurationsInput>;
  create?: Maybe<AccountV2CreateWithoutClientUnitLevelConfigurationsInput>;
  update?: Maybe<AccountV2UpdateWithoutClientUnitLevelConfigurationsInput>;
  upsert?: Maybe<AccountV2UpsertWithoutClientUnitLevelConfigurationsInput>;
};

export type AccountV2UpdateOneRequiredWithoutConsumedByAccountPercentageDriversInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutConsumedByAccountPercentageDriversInput>;
  create?: Maybe<AccountV2CreateWithoutConsumedByAccountPercentageDriversInput>;
  update?: Maybe<AccountV2UpdateWithoutConsumedByAccountPercentageDriversInput>;
  upsert?: Maybe<AccountV2UpsertWithoutConsumedByAccountPercentageDriversInput>;
};

export type AccountV2UpdateOneRequiredWithoutCustomItemDriversInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutCustomItemDriversInput>;
  create?: Maybe<AccountV2CreateWithoutCustomItemDriversInput>;
  update?: Maybe<AccountV2UpdateWithoutCustomItemDriversInput>;
  upsert?: Maybe<AccountV2UpsertWithoutCustomItemDriversInput>;
};

export type AccountV2UpdateOneRequiredWithoutDriverAugmentsAccountPercentageInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutDriverAugmentsAccountPercentageInput>;
  create?: Maybe<AccountV2CreateWithoutDriverAugmentsAccountPercentageInput>;
  update?: Maybe<AccountV2UpdateWithoutDriverAugmentsAccountPercentageInput>;
  upsert?: Maybe<AccountV2UpsertWithoutDriverAugmentsAccountPercentageInput>;
};

export type AccountV2UpdateOneRequiredWithoutForecastedAccountValuesInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutForecastedAccountValuesInput>;
  create?: Maybe<AccountV2CreateWithoutForecastedAccountValuesInput>;
  update?: Maybe<AccountV2UpdateWithoutForecastedAccountValuesInput>;
  upsert?: Maybe<AccountV2UpsertWithoutForecastedAccountValuesInput>;
};

export type AccountV2UpdateOneRequiredWithoutGrowthDriversInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutGrowthDriversInput>;
  create?: Maybe<AccountV2CreateWithoutGrowthDriversInput>;
  update?: Maybe<AccountV2UpdateWithoutGrowthDriversInput>;
  upsert?: Maybe<AccountV2UpsertWithoutGrowthDriversInput>;
};

export type AccountV2UpdateOneRequiredWithoutInstructionsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutInstructionsInput>;
  create?: Maybe<AccountV2CreateWithoutInstructionsInput>;
  update?: Maybe<AccountV2UpdateWithoutInstructionsInput>;
  upsert?: Maybe<AccountV2UpsertWithoutInstructionsInput>;
};

export type AccountV2UpdateOneRequiredWithoutLockedAccountsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutLockedAccountsInput>;
  create?: Maybe<AccountV2CreateWithoutLockedAccountsInput>;
  update?: Maybe<AccountV2UpdateWithoutLockedAccountsInput>;
  upsert?: Maybe<AccountV2UpsertWithoutLockedAccountsInput>;
};

export type AccountV2UpdateOneRequiredWithoutOperationalDriversInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutOperationalDriversInput>;
  create?: Maybe<AccountV2CreateWithoutOperationalDriversInput>;
  update?: Maybe<AccountV2UpdateWithoutOperationalDriversInput>;
  upsert?: Maybe<AccountV2UpsertWithoutOperationalDriversInput>;
};

export type AccountV2UpdateOneRequiredWithoutPayrollPositionCompensationAccountInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutPayrollPositionCompensationAccountInput>;
  create?: Maybe<AccountV2CreateWithoutPayrollPositionCompensationAccountInput>;
  update?: Maybe<AccountV2UpdateWithoutPayrollPositionCompensationAccountInput>;
  upsert?: Maybe<AccountV2UpsertWithoutPayrollPositionCompensationAccountInput>;
};

export type AccountV2UpdateOneRequiredWithoutPayrollPositionCompensationAccountV2Input = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutPayrollPositionCompensationAccountV2Input>;
  create?: Maybe<AccountV2CreateWithoutPayrollPositionCompensationAccountV2Input>;
  update?: Maybe<AccountV2UpdateWithoutPayrollPositionCompensationAccountV2Input>;
  upsert?: Maybe<AccountV2UpsertWithoutPayrollPositionCompensationAccountV2Input>;
};

export type AccountV2UpdateOneRequiredWithoutPayrollPositionCompensationItemAccountInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutPayrollPositionCompensationItemAccountInput>;
  create?: Maybe<AccountV2CreateWithoutPayrollPositionCompensationItemAccountInput>;
  update?: Maybe<AccountV2UpdateWithoutPayrollPositionCompensationItemAccountInput>;
  upsert?: Maybe<AccountV2UpsertWithoutPayrollPositionCompensationItemAccountInput>;
};

export type AccountV2UpdateOneRequiredWithoutPayrollPositionCompensationItemAccountV2Input = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutPayrollPositionCompensationItemAccountV2Input>;
  create?: Maybe<AccountV2CreateWithoutPayrollPositionCompensationItemAccountV2Input>;
  update?: Maybe<AccountV2UpdateWithoutPayrollPositionCompensationItemAccountV2Input>;
  upsert?: Maybe<AccountV2UpsertWithoutPayrollPositionCompensationItemAccountV2Input>;
};

export type AccountV2UpdateOneRequiredWithoutPropertyManagementSystemAccountValuesInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutPropertyManagementSystemAccountValuesInput>;
  create?: Maybe<AccountV2CreateWithoutPropertyManagementSystemAccountValuesInput>;
  update?: Maybe<AccountV2UpdateWithoutPropertyManagementSystemAccountValuesInput>;
  upsert?: Maybe<AccountV2UpsertWithoutPropertyManagementSystemAccountValuesInput>;
};

export type AccountV2UpdateOneRequiredWithoutPropertyUnitLevelConcessionConfigurationsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutPropertyUnitLevelConcessionConfigurationsInput>;
  create?: Maybe<AccountV2CreateWithoutPropertyUnitLevelConcessionConfigurationsInput>;
  update?: Maybe<AccountV2UpdateWithoutPropertyUnitLevelConcessionConfigurationsInput>;
  upsert?: Maybe<AccountV2UpsertWithoutPropertyUnitLevelConcessionConfigurationsInput>;
};

export type AccountV2UpdateOneRequiredWithoutPropertyUnitLevelConfigurationsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutPropertyUnitLevelConfigurationsInput>;
  create?: Maybe<AccountV2CreateWithoutPropertyUnitLevelConfigurationsInput>;
  update?: Maybe<AccountV2UpdateWithoutPropertyUnitLevelConfigurationsInput>;
  upsert?: Maybe<AccountV2UpsertWithoutPropertyUnitLevelConfigurationsInput>;
};

export type AccountV2UpdateOneRequiredWithoutRenovationCostCategoriesInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutRenovationCostCategoriesInput>;
  create?: Maybe<AccountV2CreateWithoutRenovationCostCategoriesInput>;
  update?: Maybe<AccountV2UpdateWithoutRenovationCostCategoriesInput>;
  upsert?: Maybe<AccountV2UpsertWithoutRenovationCostCategoriesInput>;
};

export type AccountV2UpdateOneRequiredWithoutRenovationPremiumAccountsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutRenovationPremiumAccountsInput>;
  create?: Maybe<AccountV2CreateWithoutRenovationPremiumAccountsInput>;
  update?: Maybe<AccountV2UpdateWithoutRenovationPremiumAccountsInput>;
  upsert?: Maybe<AccountV2UpsertWithoutRenovationPremiumAccountsInput>;
};

export type AccountV2UpdateOneRequiredWithoutReportTableAccountsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutReportTableAccountsInput>;
  create?: Maybe<AccountV2CreateWithoutReportTableAccountsInput>;
  update?: Maybe<AccountV2UpdateWithoutReportTableAccountsInput>;
  upsert?: Maybe<AccountV2UpsertWithoutReportTableAccountsInput>;
};

export type AccountV2UpdateOneRequiredWithoutRevenueDriversInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutRevenueDriversInput>;
  create?: Maybe<AccountV2CreateWithoutRevenueDriversInput>;
  update?: Maybe<AccountV2UpdateWithoutRevenueDriversInput>;
  upsert?: Maybe<AccountV2UpsertWithoutRevenueDriversInput>;
};

export type AccountV2UpdateOneRequiredWithoutStarredAccountsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutStarredAccountsInput>;
  create?: Maybe<AccountV2CreateWithoutStarredAccountsInput>;
  update?: Maybe<AccountV2UpdateWithoutStarredAccountsInput>;
  upsert?: Maybe<AccountV2UpsertWithoutStarredAccountsInput>;
};

export type AccountV2UpdateOneRequiredWithoutTransactionsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutTransactionsInput>;
  create?: Maybe<AccountV2CreateWithoutTransactionsInput>;
  update?: Maybe<AccountV2UpdateWithoutTransactionsInput>;
  upsert?: Maybe<AccountV2UpsertWithoutTransactionsInput>;
};

export type AccountV2UpdateOneRequiredWithoutWorksheetsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutWorksheetsInput>;
  create?: Maybe<AccountV2CreateWithoutWorksheetsInput>;
  update?: Maybe<AccountV2UpdateWithoutWorksheetsInput>;
  upsert?: Maybe<AccountV2UpsertWithoutWorksheetsInput>;
};

export type AccountV2UpdateOneRequiredWithoutYardiReportAccountMetadataInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutYardiReportAccountMetadataInput>;
  create?: Maybe<AccountV2CreateWithoutYardiReportAccountMetadataInput>;
  update?: Maybe<AccountV2UpdateWithoutYardiReportAccountMetadataInput>;
  upsert?: Maybe<AccountV2UpsertWithoutYardiReportAccountMetadataInput>;
};

export type AccountV2UpdateOneWithoutAccPercentageDriverAccountsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutAccPercentageDriverAccountsInput>;
  create?: Maybe<AccountV2CreateWithoutAccPercentageDriverAccountsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AccountV2UpdateWithoutAccPercentageDriverAccountsInput>;
  upsert?: Maybe<AccountV2UpsertWithoutAccPercentageDriverAccountsInput>;
};

export type AccountV2UpdateOneWithoutAccountValueOverrideInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutAccountValueOverrideInput>;
  create?: Maybe<AccountV2CreateWithoutAccountValueOverrideInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AccountV2UpdateWithoutAccountValueOverrideInput>;
  upsert?: Maybe<AccountV2UpsertWithoutAccountValueOverrideInput>;
};

export type AccountV2UpdateOneWithoutAccountValuesInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutAccountValuesInput>;
  create?: Maybe<AccountV2CreateWithoutAccountValuesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AccountV2UpdateWithoutAccountValuesInput>;
  upsert?: Maybe<AccountV2UpsertWithoutAccountValuesInput>;
};

export type AccountV2UpdateOneWithoutAccountVarianceNotesInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutAccountVarianceNotesInput>;
  create?: Maybe<AccountV2CreateWithoutAccountVarianceNotesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AccountV2UpdateWithoutAccountVarianceNotesInput>;
  upsert?: Maybe<AccountV2UpsertWithoutAccountVarianceNotesInput>;
};

export type AccountV2UpdateOneWithoutBudgetCategoryValuesInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutBudgetCategoryValuesInput>;
  create?: Maybe<AccountV2CreateWithoutBudgetCategoryValuesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AccountV2UpdateWithoutBudgetCategoryValuesInput>;
  upsert?: Maybe<AccountV2UpsertWithoutBudgetCategoryValuesInput>;
};

export type AccountV2UpdateOneWithoutBudgetComponentMappingsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutBudgetComponentMappingsInput>;
  create?: Maybe<AccountV2CreateWithoutBudgetComponentMappingsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AccountV2UpdateWithoutBudgetComponentMappingsInput>;
  upsert?: Maybe<AccountV2UpsertWithoutBudgetComponentMappingsInput>;
};

export type AccountV2UpdateOneWithoutChildrenInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutChildrenInput>;
  create?: Maybe<AccountV2CreateWithoutChildrenInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AccountV2UpdateWithoutChildrenInput>;
  upsert?: Maybe<AccountV2UpsertWithoutChildrenInput>;
};

export type AccountV2UpdateOneWithoutDriverAssignmentsInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutDriverAssignmentsInput>;
  create?: Maybe<AccountV2CreateWithoutDriverAssignmentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AccountV2UpdateWithoutDriverAssignmentsInput>;
  upsert?: Maybe<AccountV2UpsertWithoutDriverAssignmentsInput>;
};

export type AccountV2UpdateOneWithoutRentalRevenueCaptureInput = {
  connect?: Maybe<AccountV2WhereUniqueInput>;
  connectOrCreate?: Maybe<AccountV2CreateOrConnectWithoutRentalRevenueCaptureInput>;
  create?: Maybe<AccountV2CreateWithoutRentalRevenueCaptureInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AccountV2UpdateWithoutRentalRevenueCaptureInput>;
  upsert?: Maybe<AccountV2UpsertWithoutRentalRevenueCaptureInput>;
};

export type AccountV2UpdateWithWhereUniqueWithoutChartOfAccountsInput = {
  data: AccountV2UpdateWithoutChartOfAccountsInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2UpdateWithWhereUniqueWithoutClientInput = {
  data: AccountV2UpdateWithoutClientInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2UpdateWithWhereUniqueWithoutParentInput = {
  data: AccountV2UpdateWithoutParentInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2UpdateWithoutAccPercentageDriverAccountsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutAccountTopVendorsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutAccountValueOverrideInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutAccountValuesInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutAccountVarianceNotesInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutAssignedAccountPercentageDriversInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutBudgetCategoryValuesInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutBudgetComponentMappingsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutChartOfAccountsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutChildrenInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutClientInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutClientUnitLevelConcessionConfigurationsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutClientUnitLevelConfigurationsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutConsumedByAccountPercentageDriversInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutCustomItemDriversInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutDriverAssignmentsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutDriverAugmentsAccountPercentageInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutForecastedAccountValuesInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutGrowthDriversInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutInstructionsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutLockedAccountsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutOperationalDriversInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutParentInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutPayrollPositionCompensationAccountInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutPayrollPositionCompensationAccountV2Input = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutPayrollPositionCompensationItemAccountInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutPayrollPositionCompensationItemAccountV2Input = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutPropertyManagementSystemAccountValuesInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutPropertyUnitLevelConcessionConfigurationsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutPropertyUnitLevelConfigurationsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutRenovationCostCategoriesInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutRenovationPremiumAccountsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutRentalRevenueCaptureInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutReportTableAccountsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutRevenueDriversInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutStarredAccountsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutTransactionsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutWorksheetsInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutAccountInput>;
};

export type AccountV2UpdateWithoutYardiReportAccountMetadataInput = {
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsUpdateManyWithoutAccountInput>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutAccountV2Input>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutAccountInput>;
  accountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutAccountV2Input>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutAccountV2Input>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutAccountInput>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataUpdateManyWithoutAccountInput>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutAccountV2Input>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceAccountInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input>;
  children?: Maybe<AccountV2UpdateManyWithoutParentInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutAccountsV2Input>;
  clientComments?: Maybe<ClientCommentAccountUpdateManyWithoutAccountInput>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutSourceAccountInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutDestinationAccountInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutAccountV2Input>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutAccountInput>;
  glName?: Maybe<StringFieldUpdateOperationsInput>;
  glNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutAccountInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutAccountInput>;
  level?: Maybe<IntFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutAccountInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutDestinationAccountInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parent?: Maybe<AccountV2UpdateOneWithoutChildrenInput>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutAccountInput>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureUpdateManyWithoutAccountV2Input>;
  reportTableAccounts?: Maybe<ReportTableAccountUpdateManyWithoutAccountInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutDestinationAccountInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutAccountInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutAccountInput>;
  type?: Maybe<EnumAccountTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutDestinationAccountInput>;
};

export type AccountV2UpsertWithWhereUniqueWithoutChartOfAccountsInput = {
  create: AccountV2CreateWithoutChartOfAccountsInput;
  update: AccountV2UpdateWithoutChartOfAccountsInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2UpsertWithWhereUniqueWithoutClientInput = {
  create: AccountV2CreateWithoutClientInput;
  update: AccountV2UpdateWithoutClientInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2UpsertWithWhereUniqueWithoutParentInput = {
  create: AccountV2CreateWithoutParentInput;
  update: AccountV2UpdateWithoutParentInput;
  where: AccountV2WhereUniqueInput;
};

export type AccountV2UpsertWithoutAccPercentageDriverAccountsInput = {
  create: AccountV2CreateWithoutAccPercentageDriverAccountsInput;
  update: AccountV2UpdateWithoutAccPercentageDriverAccountsInput;
};

export type AccountV2UpsertWithoutAccountTopVendorsInput = {
  create: AccountV2CreateWithoutAccountTopVendorsInput;
  update: AccountV2UpdateWithoutAccountTopVendorsInput;
};

export type AccountV2UpsertWithoutAccountValueOverrideInput = {
  create: AccountV2CreateWithoutAccountValueOverrideInput;
  update: AccountV2UpdateWithoutAccountValueOverrideInput;
};

export type AccountV2UpsertWithoutAccountValuesInput = {
  create: AccountV2CreateWithoutAccountValuesInput;
  update: AccountV2UpdateWithoutAccountValuesInput;
};

export type AccountV2UpsertWithoutAccountVarianceNotesInput = {
  create: AccountV2CreateWithoutAccountVarianceNotesInput;
  update: AccountV2UpdateWithoutAccountVarianceNotesInput;
};

export type AccountV2UpsertWithoutAssignedAccountPercentageDriversInput = {
  create: AccountV2CreateWithoutAssignedAccountPercentageDriversInput;
  update: AccountV2UpdateWithoutAssignedAccountPercentageDriversInput;
};

export type AccountV2UpsertWithoutBudgetCategoryValuesInput = {
  create: AccountV2CreateWithoutBudgetCategoryValuesInput;
  update: AccountV2UpdateWithoutBudgetCategoryValuesInput;
};

export type AccountV2UpsertWithoutBudgetComponentMappingsInput = {
  create: AccountV2CreateWithoutBudgetComponentMappingsInput;
  update: AccountV2UpdateWithoutBudgetComponentMappingsInput;
};

export type AccountV2UpsertWithoutChildrenInput = {
  create: AccountV2CreateWithoutChildrenInput;
  update: AccountV2UpdateWithoutChildrenInput;
};

export type AccountV2UpsertWithoutClientUnitLevelConcessionConfigurationsInput = {
  create: AccountV2CreateWithoutClientUnitLevelConcessionConfigurationsInput;
  update: AccountV2UpdateWithoutClientUnitLevelConcessionConfigurationsInput;
};

export type AccountV2UpsertWithoutClientUnitLevelConfigurationsInput = {
  create: AccountV2CreateWithoutClientUnitLevelConfigurationsInput;
  update: AccountV2UpdateWithoutClientUnitLevelConfigurationsInput;
};

export type AccountV2UpsertWithoutConsumedByAccountPercentageDriversInput = {
  create: AccountV2CreateWithoutConsumedByAccountPercentageDriversInput;
  update: AccountV2UpdateWithoutConsumedByAccountPercentageDriversInput;
};

export type AccountV2UpsertWithoutCustomItemDriversInput = {
  create: AccountV2CreateWithoutCustomItemDriversInput;
  update: AccountV2UpdateWithoutCustomItemDriversInput;
};

export type AccountV2UpsertWithoutDriverAssignmentsInput = {
  create: AccountV2CreateWithoutDriverAssignmentsInput;
  update: AccountV2UpdateWithoutDriverAssignmentsInput;
};

export type AccountV2UpsertWithoutDriverAugmentsAccountPercentageInput = {
  create: AccountV2CreateWithoutDriverAugmentsAccountPercentageInput;
  update: AccountV2UpdateWithoutDriverAugmentsAccountPercentageInput;
};

export type AccountV2UpsertWithoutForecastedAccountValuesInput = {
  create: AccountV2CreateWithoutForecastedAccountValuesInput;
  update: AccountV2UpdateWithoutForecastedAccountValuesInput;
};

export type AccountV2UpsertWithoutGrowthDriversInput = {
  create: AccountV2CreateWithoutGrowthDriversInput;
  update: AccountV2UpdateWithoutGrowthDriversInput;
};

export type AccountV2UpsertWithoutInstructionsInput = {
  create: AccountV2CreateWithoutInstructionsInput;
  update: AccountV2UpdateWithoutInstructionsInput;
};

export type AccountV2UpsertWithoutLockedAccountsInput = {
  create: AccountV2CreateWithoutLockedAccountsInput;
  update: AccountV2UpdateWithoutLockedAccountsInput;
};

export type AccountV2UpsertWithoutOperationalDriversInput = {
  create: AccountV2CreateWithoutOperationalDriversInput;
  update: AccountV2UpdateWithoutOperationalDriversInput;
};

export type AccountV2UpsertWithoutPayrollPositionCompensationAccountInput = {
  create: AccountV2CreateWithoutPayrollPositionCompensationAccountInput;
  update: AccountV2UpdateWithoutPayrollPositionCompensationAccountInput;
};

export type AccountV2UpsertWithoutPayrollPositionCompensationAccountV2Input = {
  create: AccountV2CreateWithoutPayrollPositionCompensationAccountV2Input;
  update: AccountV2UpdateWithoutPayrollPositionCompensationAccountV2Input;
};

export type AccountV2UpsertWithoutPayrollPositionCompensationItemAccountInput = {
  create: AccountV2CreateWithoutPayrollPositionCompensationItemAccountInput;
  update: AccountV2UpdateWithoutPayrollPositionCompensationItemAccountInput;
};

export type AccountV2UpsertWithoutPayrollPositionCompensationItemAccountV2Input = {
  create: AccountV2CreateWithoutPayrollPositionCompensationItemAccountV2Input;
  update: AccountV2UpdateWithoutPayrollPositionCompensationItemAccountV2Input;
};

export type AccountV2UpsertWithoutPropertyManagementSystemAccountValuesInput = {
  create: AccountV2CreateWithoutPropertyManagementSystemAccountValuesInput;
  update: AccountV2UpdateWithoutPropertyManagementSystemAccountValuesInput;
};

export type AccountV2UpsertWithoutPropertyUnitLevelConcessionConfigurationsInput = {
  create: AccountV2CreateWithoutPropertyUnitLevelConcessionConfigurationsInput;
  update: AccountV2UpdateWithoutPropertyUnitLevelConcessionConfigurationsInput;
};

export type AccountV2UpsertWithoutPropertyUnitLevelConfigurationsInput = {
  create: AccountV2CreateWithoutPropertyUnitLevelConfigurationsInput;
  update: AccountV2UpdateWithoutPropertyUnitLevelConfigurationsInput;
};

export type AccountV2UpsertWithoutRenovationCostCategoriesInput = {
  create: AccountV2CreateWithoutRenovationCostCategoriesInput;
  update: AccountV2UpdateWithoutRenovationCostCategoriesInput;
};

export type AccountV2UpsertWithoutRenovationPremiumAccountsInput = {
  create: AccountV2CreateWithoutRenovationPremiumAccountsInput;
  update: AccountV2UpdateWithoutRenovationPremiumAccountsInput;
};

export type AccountV2UpsertWithoutRentalRevenueCaptureInput = {
  create: AccountV2CreateWithoutRentalRevenueCaptureInput;
  update: AccountV2UpdateWithoutRentalRevenueCaptureInput;
};

export type AccountV2UpsertWithoutReportTableAccountsInput = {
  create: AccountV2CreateWithoutReportTableAccountsInput;
  update: AccountV2UpdateWithoutReportTableAccountsInput;
};

export type AccountV2UpsertWithoutRevenueDriversInput = {
  create: AccountV2CreateWithoutRevenueDriversInput;
  update: AccountV2UpdateWithoutRevenueDriversInput;
};

export type AccountV2UpsertWithoutStarredAccountsInput = {
  create: AccountV2CreateWithoutStarredAccountsInput;
  update: AccountV2UpdateWithoutStarredAccountsInput;
};

export type AccountV2UpsertWithoutTransactionsInput = {
  create: AccountV2CreateWithoutTransactionsInput;
  update: AccountV2UpdateWithoutTransactionsInput;
};

export type AccountV2UpsertWithoutWorksheetsInput = {
  create: AccountV2CreateWithoutWorksheetsInput;
  update: AccountV2UpdateWithoutWorksheetsInput;
};

export type AccountV2UpsertWithoutYardiReportAccountMetadataInput = {
  create: AccountV2CreateWithoutYardiReportAccountMetadataInput;
  update: AccountV2UpdateWithoutYardiReportAccountMetadataInput;
};

export type AccountV2WhereInput = {
  AND?: Maybe<Array<AccountV2WhereInput>>;
  AccountsTopCardTags?: Maybe<AccountsTopCardTagsListRelationFilter>;
  NOT?: Maybe<Array<AccountV2WhereInput>>;
  OR?: Maybe<Array<AccountV2WhereInput>>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountListRelationFilter>;
  accountTopVendors?: Maybe<AccountTopVendorsListRelationFilter>;
  accountValueOverride?: Maybe<AccountValueOverrideListRelationFilter>;
  accountValues?: Maybe<AccountValueListRelationFilter>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteListRelationFilter>;
  appFolioAccountMetadata?: Maybe<AppFolioReportAccountMetadataListRelationFilter>;
  assignedAccountPercentageDrivers?: Maybe<DriverAccountPercentageListRelationFilter>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueListRelationFilter>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingListRelationFilter>;
  chartOfAccounts?: Maybe<ChartOfAccountsRelationFilter>;
  chartOfAccountsId?: Maybe<StringFilter>;
  children?: Maybe<AccountV2ListRelationFilter>;
  client?: Maybe<ClientRelationFilter>;
  clientComments?: Maybe<ClientCommentAccountListRelationFilter>;
  clientId?: Maybe<StringFilter>;
  clientUnitLevelConcessionConfigurations?: Maybe<ClientUnitLevelConfigurationListRelationFilter>;
  clientUnitLevelConfigurations?: Maybe<ClientUnitLevelConfigurationListRelationFilter>;
  consumedByAccountPercentageDrivers?: Maybe<DriverAccountPercentageListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  customItemDrivers?: Maybe<DriverCustomItemListRelationFilter>;
  driverAssignments?: Maybe<DriverAssignmentListRelationFilter>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageListRelationFilter>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueListRelationFilter>;
  glName?: Maybe<StringFilter>;
  glNumber?: Maybe<StringNullableFilter>;
  growthDrivers?: Maybe<DriverGrowthListRelationFilter>;
  id?: Maybe<StringFilter>;
  instructions?: Maybe<InstructionListRelationFilter>;
  level?: Maybe<IntFilter>;
  lockedAccounts?: Maybe<LockedAccountListRelationFilter>;
  negate?: Maybe<BoolFilter>;
  operationalDrivers?: Maybe<DriverOperationalListRelationFilter>;
  order?: Maybe<IntFilter>;
  parent?: Maybe<AccountV2RelationFilter>;
  parentId?: Maybe<StringNullableFilter>;
  payrollPositionCompensationAccount?: Maybe<PayrollPositionCompensationAccountListRelationFilter>;
  payrollPositionCompensationAccountV2?: Maybe<PayrollPositionCompensationAccountV2ListRelationFilter>;
  payrollPositionCompensationItemAccount?: Maybe<PayrollPositionCompensationItemAccountListRelationFilter>;
  payrollPositionCompensationItemAccountV2?: Maybe<PayrollPositionCompensationItemAccountV2ListRelationFilter>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueListRelationFilter>;
  propertyUnitLevelConcessionConfigurations?: Maybe<PropertyUnitLevelConfigurationListRelationFilter>;
  propertyUnitLevelConfigurations?: Maybe<PropertyUnitLevelConfigurationListRelationFilter>;
  renovationCostCategories?: Maybe<RenovationCostCategoryListRelationFilter>;
  renovationPremiumAccounts?: Maybe<RenovationClientConfigurationListRelationFilter>;
  rentalRevenueCapture?: Maybe<RentalRevenueCaptureListRelationFilter>;
  reportTableAccounts?: Maybe<ReportTableAccountListRelationFilter>;
  revenueDrivers?: Maybe<DriverRevenueListRelationFilter>;
  starredAccounts?: Maybe<StarredAccountListRelationFilter>;
  transactions?: Maybe<TransactionListRelationFilter>;
  type?: Maybe<EnumAccountTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  worksheets?: Maybe<WorksheetListRelationFilter>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataListRelationFilter>;
};

export type AccountV2WhereUniqueInput = {
  clientId_chartOfAccountsId_glNumber_glName?: Maybe<AccountV2ClientIdChartOfAccountsIdGlNumberGlNameCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type AccountValue = {
  __typename?: 'AccountValue';
  accountId: Scalars['String'];
  accountV2Id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Decimal']>;
  versionId: Scalars['String'];
};

export type AccountValueAccountIdVersionIdMonthIndexPropertyIdCompoundUniqueInput = {
  accountId: Scalars['String'];
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  versionId: Scalars['String'];
};

export type AccountValueAccountV2IdVersionIdMonthIndexPropertyIdAccountIdCompoundUniqueInput = {
  accountId: Scalars['String'];
  accountV2Id: Scalars['String'];
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  versionId: Scalars['String'];
};

export type AccountValueCreateManyAccountV2Input = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Decimal']>;
  versionId: Scalars['String'];
};

export type AccountValueCreateManyAccountV2InputEnvelope = {
  data: Array<AccountValueCreateManyAccountV2Input>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccountValueCreateManyPropertyInput = {
  accountId: Scalars['String'];
  accountV2Id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Decimal']>;
  versionId: Scalars['String'];
};

export type AccountValueCreateManyPropertyInputEnvelope = {
  data: Array<AccountValueCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccountValueCreateManyVersionInput = {
  accountId: Scalars['String'];
  accountV2Id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Decimal']>;
};

export type AccountValueCreateManyVersionInputEnvelope = {
  data: Array<AccountValueCreateManyVersionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccountValueCreateNestedManyWithoutAccountV2Input = {
  connect?: Maybe<Array<AccountValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountValueCreateOrConnectWithoutAccountV2Input>>;
  create?: Maybe<Array<AccountValueCreateWithoutAccountV2Input>>;
  createMany?: Maybe<AccountValueCreateManyAccountV2InputEnvelope>;
};

export type AccountValueCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<AccountValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountValueCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<AccountValueCreateWithoutPropertyInput>>;
  createMany?: Maybe<AccountValueCreateManyPropertyInputEnvelope>;
};

export type AccountValueCreateNestedManyWithoutVersionInput = {
  connect?: Maybe<Array<AccountValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountValueCreateOrConnectWithoutVersionInput>>;
  create?: Maybe<Array<AccountValueCreateWithoutVersionInput>>;
  createMany?: Maybe<AccountValueCreateManyVersionInputEnvelope>;
};

export type AccountValueCreateOrConnectWithoutAccountV2Input = {
  create: AccountValueCreateWithoutAccountV2Input;
  where: AccountValueWhereUniqueInput;
};

export type AccountValueCreateOrConnectWithoutPropertyInput = {
  create: AccountValueCreateWithoutPropertyInput;
  where: AccountValueWhereUniqueInput;
};

export type AccountValueCreateOrConnectWithoutVersionInput = {
  create: AccountValueCreateWithoutVersionInput;
  where: AccountValueWhereUniqueInput;
};

export type AccountValueCreateWithoutAccountV2Input = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  property: PropertyCreateNestedOneWithoutAccountValuesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Decimal']>;
  version: ValueVersionCreateNestedOneWithoutAccountValuesInput;
};

export type AccountValueCreateWithoutPropertyInput = {
  accountId: Scalars['String'];
  accountV2?: Maybe<AccountV2CreateNestedOneWithoutAccountValuesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Decimal']>;
  version: ValueVersionCreateNestedOneWithoutAccountValuesInput;
};

export type AccountValueCreateWithoutVersionInput = {
  accountId: Scalars['String'];
  accountV2?: Maybe<AccountV2CreateNestedOneWithoutAccountValuesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  property: PropertyCreateNestedOneWithoutAccountValuesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Decimal']>;
};

export type AccountValueListRelationFilter = {
  every?: Maybe<AccountValueWhereInput>;
  none?: Maybe<AccountValueWhereInput>;
  some?: Maybe<AccountValueWhereInput>;
};

export type AccountValueOrderByInput = {
  accountId?: Maybe<SortOrder>;
  accountV2Id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  monthIndex?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  versionId?: Maybe<SortOrder>;
};

export type AccountValueOverride = {
  __typename?: 'AccountValueOverride';
  accountId: Scalars['String'];
  accountV2Id?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Decimal']>;
  valueId?: Maybe<Scalars['String']>;
  versionId: Scalars['String'];
};

export type AccountValueOverrideAccountIdVersionIdMonthIndexPropertyIdCompoundUniqueInput = {
  accountId: Scalars['String'];
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  versionId: Scalars['String'];
};

export type AccountValueOverrideAccountV2IdVersionIdMonthIndexPropertyIdAccountIdCompoundUniqueInput = {
  accountId: Scalars['String'];
  accountV2Id: Scalars['String'];
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  versionId: Scalars['String'];
};

export type AccountValueOverrideCreateManyAccountV2Input = {
  accountId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Decimal']>;
  valueId?: Maybe<Scalars['String']>;
  versionId: Scalars['String'];
};

export type AccountValueOverrideCreateManyAccountV2InputEnvelope = {
  data: Array<AccountValueOverrideCreateManyAccountV2Input>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccountValueOverrideCreateManyPropertyInput = {
  accountId: Scalars['String'];
  accountV2Id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Decimal']>;
  valueId?: Maybe<Scalars['String']>;
  versionId: Scalars['String'];
};

export type AccountValueOverrideCreateManyPropertyInputEnvelope = {
  data: Array<AccountValueOverrideCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccountValueOverrideCreateManyVersionInput = {
  accountId: Scalars['String'];
  accountV2Id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Decimal']>;
  valueId?: Maybe<Scalars['String']>;
};

export type AccountValueOverrideCreateManyVersionInputEnvelope = {
  data: Array<AccountValueOverrideCreateManyVersionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccountValueOverrideCreateNestedManyWithoutAccountV2Input = {
  connect?: Maybe<Array<AccountValueOverrideWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountValueOverrideCreateOrConnectWithoutAccountV2Input>>;
  create?: Maybe<Array<AccountValueOverrideCreateWithoutAccountV2Input>>;
  createMany?: Maybe<AccountValueOverrideCreateManyAccountV2InputEnvelope>;
};

export type AccountValueOverrideCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<AccountValueOverrideWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountValueOverrideCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<AccountValueOverrideCreateWithoutPropertyInput>>;
  createMany?: Maybe<AccountValueOverrideCreateManyPropertyInputEnvelope>;
};

export type AccountValueOverrideCreateNestedManyWithoutVersionInput = {
  connect?: Maybe<Array<AccountValueOverrideWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountValueOverrideCreateOrConnectWithoutVersionInput>>;
  create?: Maybe<Array<AccountValueOverrideCreateWithoutVersionInput>>;
  createMany?: Maybe<AccountValueOverrideCreateManyVersionInputEnvelope>;
};

export type AccountValueOverrideCreateOrConnectWithoutAccountV2Input = {
  create: AccountValueOverrideCreateWithoutAccountV2Input;
  where: AccountValueOverrideWhereUniqueInput;
};

export type AccountValueOverrideCreateOrConnectWithoutPropertyInput = {
  create: AccountValueOverrideCreateWithoutPropertyInput;
  where: AccountValueOverrideWhereUniqueInput;
};

export type AccountValueOverrideCreateOrConnectWithoutVersionInput = {
  create: AccountValueOverrideCreateWithoutVersionInput;
  where: AccountValueOverrideWhereUniqueInput;
};

export type AccountValueOverrideCreateWithoutAccountV2Input = {
  accountId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  property: PropertyCreateNestedOneWithoutAccountValueOverrideInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Decimal']>;
  valueId?: Maybe<Scalars['String']>;
  version: ValueVersionCreateNestedOneWithoutAccountValueOverrideInput;
};

export type AccountValueOverrideCreateWithoutPropertyInput = {
  accountId: Scalars['String'];
  accountV2?: Maybe<AccountV2CreateNestedOneWithoutAccountValueOverrideInput>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Decimal']>;
  valueId?: Maybe<Scalars['String']>;
  version: ValueVersionCreateNestedOneWithoutAccountValueOverrideInput;
};

export type AccountValueOverrideCreateWithoutVersionInput = {
  accountId: Scalars['String'];
  accountV2?: Maybe<AccountV2CreateNestedOneWithoutAccountValueOverrideInput>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  property: PropertyCreateNestedOneWithoutAccountValueOverrideInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Decimal']>;
  valueId?: Maybe<Scalars['String']>;
};

export type AccountValueOverrideListRelationFilter = {
  every?: Maybe<AccountValueOverrideWhereInput>;
  none?: Maybe<AccountValueOverrideWhereInput>;
  some?: Maybe<AccountValueOverrideWhereInput>;
};

export type AccountValueOverrideOrderByInput = {
  accountId?: Maybe<SortOrder>;
  accountV2Id?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  monthIndex?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  valueId?: Maybe<SortOrder>;
  versionId?: Maybe<SortOrder>;
};

export enum AccountValueOverrideScalarFieldEnum {
  AccountId = 'accountId',
  AccountV2Id = 'accountV2Id',
  Id = 'id',
  MonthIndex = 'monthIndex',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt',
  Value = 'value',
  ValueId = 'valueId',
  VersionId = 'versionId'
}

export type AccountValueOverrideScalarWhereInput = {
  AND?: Maybe<Array<AccountValueOverrideScalarWhereInput>>;
  NOT?: Maybe<Array<AccountValueOverrideScalarWhereInput>>;
  OR?: Maybe<Array<AccountValueOverrideScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  accountV2Id?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  monthIndex?: Maybe<IntFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeNullableFilter>;
  value?: Maybe<DecimalNullableFilter>;
  valueId?: Maybe<StringNullableFilter>;
  versionId?: Maybe<StringFilter>;
};

export type AccountValueOverrideUpdateManyMutationInput = {
  accountId?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  value?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueId?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type AccountValueOverrideUpdateManyWithWhereWithoutAccountV2Input = {
  data: AccountValueOverrideUpdateManyMutationInput;
  where: AccountValueOverrideScalarWhereInput;
};

export type AccountValueOverrideUpdateManyWithWhereWithoutPropertyInput = {
  data: AccountValueOverrideUpdateManyMutationInput;
  where: AccountValueOverrideScalarWhereInput;
};

export type AccountValueOverrideUpdateManyWithWhereWithoutVersionInput = {
  data: AccountValueOverrideUpdateManyMutationInput;
  where: AccountValueOverrideScalarWhereInput;
};

export type AccountValueOverrideUpdateManyWithoutAccountV2Input = {
  connect?: Maybe<Array<AccountValueOverrideWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountValueOverrideCreateOrConnectWithoutAccountV2Input>>;
  create?: Maybe<Array<AccountValueOverrideCreateWithoutAccountV2Input>>;
  createMany?: Maybe<AccountValueOverrideCreateManyAccountV2InputEnvelope>;
  delete?: Maybe<Array<AccountValueOverrideWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccountValueOverrideScalarWhereInput>>;
  disconnect?: Maybe<Array<AccountValueOverrideWhereUniqueInput>>;
  set?: Maybe<Array<AccountValueOverrideWhereUniqueInput>>;
  update?: Maybe<Array<AccountValueOverrideUpdateWithWhereUniqueWithoutAccountV2Input>>;
  updateMany?: Maybe<Array<AccountValueOverrideUpdateManyWithWhereWithoutAccountV2Input>>;
  upsert?: Maybe<Array<AccountValueOverrideUpsertWithWhereUniqueWithoutAccountV2Input>>;
};

export type AccountValueOverrideUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<AccountValueOverrideWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountValueOverrideCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<AccountValueOverrideCreateWithoutPropertyInput>>;
  createMany?: Maybe<AccountValueOverrideCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<AccountValueOverrideWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccountValueOverrideScalarWhereInput>>;
  disconnect?: Maybe<Array<AccountValueOverrideWhereUniqueInput>>;
  set?: Maybe<Array<AccountValueOverrideWhereUniqueInput>>;
  update?: Maybe<Array<AccountValueOverrideUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<AccountValueOverrideUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<AccountValueOverrideUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type AccountValueOverrideUpdateManyWithoutVersionInput = {
  connect?: Maybe<Array<AccountValueOverrideWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountValueOverrideCreateOrConnectWithoutVersionInput>>;
  create?: Maybe<Array<AccountValueOverrideCreateWithoutVersionInput>>;
  createMany?: Maybe<AccountValueOverrideCreateManyVersionInputEnvelope>;
  delete?: Maybe<Array<AccountValueOverrideWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccountValueOverrideScalarWhereInput>>;
  disconnect?: Maybe<Array<AccountValueOverrideWhereUniqueInput>>;
  set?: Maybe<Array<AccountValueOverrideWhereUniqueInput>>;
  update?: Maybe<Array<AccountValueOverrideUpdateWithWhereUniqueWithoutVersionInput>>;
  updateMany?: Maybe<Array<AccountValueOverrideUpdateManyWithWhereWithoutVersionInput>>;
  upsert?: Maybe<Array<AccountValueOverrideUpsertWithWhereUniqueWithoutVersionInput>>;
};

export type AccountValueOverrideUpdateWithWhereUniqueWithoutAccountV2Input = {
  data: AccountValueOverrideUpdateWithoutAccountV2Input;
  where: AccountValueOverrideWhereUniqueInput;
};

export type AccountValueOverrideUpdateWithWhereUniqueWithoutPropertyInput = {
  data: AccountValueOverrideUpdateWithoutPropertyInput;
  where: AccountValueOverrideWhereUniqueInput;
};

export type AccountValueOverrideUpdateWithWhereUniqueWithoutVersionInput = {
  data: AccountValueOverrideUpdateWithoutVersionInput;
  where: AccountValueOverrideWhereUniqueInput;
};

export type AccountValueOverrideUpdateWithoutAccountV2Input = {
  accountId?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutAccountValueOverrideInput>;
  updatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  value?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  version?: Maybe<ValueVersionUpdateOneRequiredWithoutAccountValueOverrideInput>;
};

export type AccountValueOverrideUpdateWithoutPropertyInput = {
  accountId?: Maybe<StringFieldUpdateOperationsInput>;
  accountV2?: Maybe<AccountV2UpdateOneWithoutAccountValueOverrideInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  value?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  version?: Maybe<ValueVersionUpdateOneRequiredWithoutAccountValueOverrideInput>;
};

export type AccountValueOverrideUpdateWithoutVersionInput = {
  accountId?: Maybe<StringFieldUpdateOperationsInput>;
  accountV2?: Maybe<AccountV2UpdateOneWithoutAccountValueOverrideInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutAccountValueOverrideInput>;
  updatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  value?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueId?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type AccountValueOverrideUpsertWithWhereUniqueWithoutAccountV2Input = {
  create: AccountValueOverrideCreateWithoutAccountV2Input;
  update: AccountValueOverrideUpdateWithoutAccountV2Input;
  where: AccountValueOverrideWhereUniqueInput;
};

export type AccountValueOverrideUpsertWithWhereUniqueWithoutPropertyInput = {
  create: AccountValueOverrideCreateWithoutPropertyInput;
  update: AccountValueOverrideUpdateWithoutPropertyInput;
  where: AccountValueOverrideWhereUniqueInput;
};

export type AccountValueOverrideUpsertWithWhereUniqueWithoutVersionInput = {
  create: AccountValueOverrideCreateWithoutVersionInput;
  update: AccountValueOverrideUpdateWithoutVersionInput;
  where: AccountValueOverrideWhereUniqueInput;
};

export type AccountValueOverrideWhereInput = {
  AND?: Maybe<Array<AccountValueOverrideWhereInput>>;
  NOT?: Maybe<Array<AccountValueOverrideWhereInput>>;
  OR?: Maybe<Array<AccountValueOverrideWhereInput>>;
  accountId?: Maybe<StringFilter>;
  accountV2?: Maybe<AccountV2RelationFilter>;
  accountV2Id?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  monthIndex?: Maybe<IntFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeNullableFilter>;
  value?: Maybe<DecimalNullableFilter>;
  valueId?: Maybe<StringNullableFilter>;
  version?: Maybe<ValueVersionRelationFilter>;
  versionId?: Maybe<StringFilter>;
};

export type AccountValueOverrideWhereUniqueInput = {
  accountId_versionId_monthIndex_propertyId?: Maybe<AccountValueOverrideAccountIdVersionIdMonthIndexPropertyIdCompoundUniqueInput>;
  accountV2Id_versionId_monthIndex_propertyId_accountId?: Maybe<AccountValueOverrideAccountV2IdVersionIdMonthIndexPropertyIdAccountIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export enum AccountValueScalarFieldEnum {
  AccountId = 'accountId',
  AccountV2Id = 'accountV2Id',
  CreatedAt = 'createdAt',
  Id = 'id',
  MonthIndex = 'monthIndex',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt',
  Value = 'value',
  VersionId = 'versionId'
}

export type AccountValueScalarWhereInput = {
  AND?: Maybe<Array<AccountValueScalarWhereInput>>;
  NOT?: Maybe<Array<AccountValueScalarWhereInput>>;
  OR?: Maybe<Array<AccountValueScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  accountV2Id?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringNullableFilter>;
  monthIndex?: Maybe<IntFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeNullableFilter>;
  value?: Maybe<DecimalNullableFilter>;
  versionId?: Maybe<StringFilter>;
};

export type AccountValueUpdateManyMutationInput = {
  accountId?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  value?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
};

export type AccountValueUpdateManyWithWhereWithoutAccountV2Input = {
  data: AccountValueUpdateManyMutationInput;
  where: AccountValueScalarWhereInput;
};

export type AccountValueUpdateManyWithWhereWithoutPropertyInput = {
  data: AccountValueUpdateManyMutationInput;
  where: AccountValueScalarWhereInput;
};

export type AccountValueUpdateManyWithWhereWithoutVersionInput = {
  data: AccountValueUpdateManyMutationInput;
  where: AccountValueScalarWhereInput;
};

export type AccountValueUpdateManyWithoutAccountV2Input = {
  connect?: Maybe<Array<AccountValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountValueCreateOrConnectWithoutAccountV2Input>>;
  create?: Maybe<Array<AccountValueCreateWithoutAccountV2Input>>;
  createMany?: Maybe<AccountValueCreateManyAccountV2InputEnvelope>;
  delete?: Maybe<Array<AccountValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccountValueScalarWhereInput>>;
  disconnect?: Maybe<Array<AccountValueWhereUniqueInput>>;
  set?: Maybe<Array<AccountValueWhereUniqueInput>>;
  update?: Maybe<Array<AccountValueUpdateWithWhereUniqueWithoutAccountV2Input>>;
  updateMany?: Maybe<Array<AccountValueUpdateManyWithWhereWithoutAccountV2Input>>;
  upsert?: Maybe<Array<AccountValueUpsertWithWhereUniqueWithoutAccountV2Input>>;
};

export type AccountValueUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<AccountValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountValueCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<AccountValueCreateWithoutPropertyInput>>;
  createMany?: Maybe<AccountValueCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<AccountValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccountValueScalarWhereInput>>;
  disconnect?: Maybe<Array<AccountValueWhereUniqueInput>>;
  set?: Maybe<Array<AccountValueWhereUniqueInput>>;
  update?: Maybe<Array<AccountValueUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<AccountValueUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<AccountValueUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type AccountValueUpdateManyWithoutVersionInput = {
  connect?: Maybe<Array<AccountValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountValueCreateOrConnectWithoutVersionInput>>;
  create?: Maybe<Array<AccountValueCreateWithoutVersionInput>>;
  createMany?: Maybe<AccountValueCreateManyVersionInputEnvelope>;
  delete?: Maybe<Array<AccountValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccountValueScalarWhereInput>>;
  disconnect?: Maybe<Array<AccountValueWhereUniqueInput>>;
  set?: Maybe<Array<AccountValueWhereUniqueInput>>;
  update?: Maybe<Array<AccountValueUpdateWithWhereUniqueWithoutVersionInput>>;
  updateMany?: Maybe<Array<AccountValueUpdateManyWithWhereWithoutVersionInput>>;
  upsert?: Maybe<Array<AccountValueUpsertWithWhereUniqueWithoutVersionInput>>;
};

export type AccountValueUpdateWithWhereUniqueWithoutAccountV2Input = {
  data: AccountValueUpdateWithoutAccountV2Input;
  where: AccountValueWhereUniqueInput;
};

export type AccountValueUpdateWithWhereUniqueWithoutPropertyInput = {
  data: AccountValueUpdateWithoutPropertyInput;
  where: AccountValueWhereUniqueInput;
};

export type AccountValueUpdateWithWhereUniqueWithoutVersionInput = {
  data: AccountValueUpdateWithoutVersionInput;
  where: AccountValueWhereUniqueInput;
};

export type AccountValueUpdateWithoutAccountV2Input = {
  accountId?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutAccountValuesInput>;
  updatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  value?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  version?: Maybe<ValueVersionUpdateOneRequiredWithoutAccountValuesInput>;
};

export type AccountValueUpdateWithoutPropertyInput = {
  accountId?: Maybe<StringFieldUpdateOperationsInput>;
  accountV2?: Maybe<AccountV2UpdateOneWithoutAccountValuesInput>;
  createdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  value?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  version?: Maybe<ValueVersionUpdateOneRequiredWithoutAccountValuesInput>;
};

export type AccountValueUpdateWithoutVersionInput = {
  accountId?: Maybe<StringFieldUpdateOperationsInput>;
  accountV2?: Maybe<AccountV2UpdateOneWithoutAccountValuesInput>;
  createdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutAccountValuesInput>;
  updatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  value?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
};

export type AccountValueUpsertWithWhereUniqueWithoutAccountV2Input = {
  create: AccountValueCreateWithoutAccountV2Input;
  update: AccountValueUpdateWithoutAccountV2Input;
  where: AccountValueWhereUniqueInput;
};

export type AccountValueUpsertWithWhereUniqueWithoutPropertyInput = {
  create: AccountValueCreateWithoutPropertyInput;
  update: AccountValueUpdateWithoutPropertyInput;
  where: AccountValueWhereUniqueInput;
};

export type AccountValueUpsertWithWhereUniqueWithoutVersionInput = {
  create: AccountValueCreateWithoutVersionInput;
  update: AccountValueUpdateWithoutVersionInput;
  where: AccountValueWhereUniqueInput;
};

export type AccountValueWhereInput = {
  AND?: Maybe<Array<AccountValueWhereInput>>;
  NOT?: Maybe<Array<AccountValueWhereInput>>;
  OR?: Maybe<Array<AccountValueWhereInput>>;
  accountId?: Maybe<StringFilter>;
  accountV2?: Maybe<AccountV2RelationFilter>;
  accountV2Id?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringNullableFilter>;
  monthIndex?: Maybe<IntFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeNullableFilter>;
  value?: Maybe<DecimalNullableFilter>;
  version?: Maybe<ValueVersionRelationFilter>;
  versionId?: Maybe<StringFilter>;
};

export type AccountValueWhereUniqueInput = {
  accountId_versionId_monthIndex_propertyId?: Maybe<AccountValueAccountIdVersionIdMonthIndexPropertyIdCompoundUniqueInput>;
  accountV2Id_versionId_monthIndex_propertyId_accountId?: Maybe<AccountValueAccountV2IdVersionIdMonthIndexPropertyIdAccountIdCompoundUniqueInput>;
};

export type AccountVarianceInsightsModel = {
  __typename?: 'AccountVarianceInsightsModel';
  account: InsightsAccountModel;
  insights: InsightsModel;
};

export type AccountVarianceNote = {
  __typename?: 'AccountVarianceNote';
  accountId?: Maybe<Scalars['String']>;
  componentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export type AccountVarianceNoteCreateManyAccountInput = {
  componentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type AccountVarianceNoteCreateManyAccountInputEnvelope = {
  data: Array<AccountVarianceNoteCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccountVarianceNoteCreateManyComponentInput = {
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type AccountVarianceNoteCreateManyComponentInputEnvelope = {
  data: Array<AccountVarianceNoteCreateManyComponentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccountVarianceNoteCreateManyPropertyInput = {
  accountId?: Maybe<Scalars['String']>;
  componentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type AccountVarianceNoteCreateManyPropertyInputEnvelope = {
  data: Array<AccountVarianceNoteCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccountVarianceNoteCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<AccountVarianceNoteWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountVarianceNoteCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<AccountVarianceNoteCreateWithoutAccountInput>>;
  createMany?: Maybe<AccountVarianceNoteCreateManyAccountInputEnvelope>;
};

export type AccountVarianceNoteCreateNestedManyWithoutComponentInput = {
  connect?: Maybe<Array<AccountVarianceNoteWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountVarianceNoteCreateOrConnectWithoutComponentInput>>;
  create?: Maybe<Array<AccountVarianceNoteCreateWithoutComponentInput>>;
  createMany?: Maybe<AccountVarianceNoteCreateManyComponentInputEnvelope>;
};

export type AccountVarianceNoteCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<AccountVarianceNoteWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountVarianceNoteCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<AccountVarianceNoteCreateWithoutPropertyInput>>;
  createMany?: Maybe<AccountVarianceNoteCreateManyPropertyInputEnvelope>;
};

export type AccountVarianceNoteCreateOrConnectWithoutAccountInput = {
  create: AccountVarianceNoteCreateWithoutAccountInput;
  where: AccountVarianceNoteWhereUniqueInput;
};

export type AccountVarianceNoteCreateOrConnectWithoutComponentInput = {
  create: AccountVarianceNoteCreateWithoutComponentInput;
  where: AccountVarianceNoteWhereUniqueInput;
};

export type AccountVarianceNoteCreateOrConnectWithoutPropertyInput = {
  create: AccountVarianceNoteCreateWithoutPropertyInput;
  where: AccountVarianceNoteWhereUniqueInput;
};

export type AccountVarianceNoteCreateWithoutAccountInput = {
  component?: Maybe<BudgetComponentV2CreateNestedOneWithoutAccountVarianceNotesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  property: PropertyCreateNestedOneWithoutAccountVarianceNotesInput;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type AccountVarianceNoteCreateWithoutComponentInput = {
  account?: Maybe<AccountV2CreateNestedOneWithoutAccountVarianceNotesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  property: PropertyCreateNestedOneWithoutAccountVarianceNotesInput;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type AccountVarianceNoteCreateWithoutPropertyInput = {
  account?: Maybe<AccountV2CreateNestedOneWithoutAccountVarianceNotesInput>;
  component?: Maybe<BudgetComponentV2CreateNestedOneWithoutAccountVarianceNotesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type AccountVarianceNoteListRelationFilter = {
  every?: Maybe<AccountVarianceNoteWhereInput>;
  none?: Maybe<AccountVarianceNoteWhereInput>;
  some?: Maybe<AccountVarianceNoteWhereInput>;
};

export type AccountVarianceNoteModel = {
  __typename?: 'AccountVarianceNoteModel';
  accountId?: Maybe<Scalars['String']>;
  componentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  monthIndex: Scalars['Int'];
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export type AccountVarianceNoteOrderByInput = {
  accountId?: Maybe<SortOrder>;
  componentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  monthIndex?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  text?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  versionType?: Maybe<SortOrder>;
  year?: Maybe<SortOrder>;
};

export type AccountVarianceNotePropertyIdAccountIdComponentIdYearVersionTypeMonthIndexCompoundUniqueInput = {
  accountId: Scalars['String'];
  componentId: Scalars['String'];
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export type AccountVarianceNoteQueryInput = {
  accountIdFilter?: Maybe<Array<Scalars['ID']>>;
  componentIdFilter?: Maybe<Array<Scalars['ID']>>;
  monthIndex: Scalars['Int'];
  propertyId: Scalars['ID'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export enum AccountVarianceNoteScalarFieldEnum {
  AccountId = 'accountId',
  ComponentId = 'componentId',
  CreatedAt = 'createdAt',
  Id = 'id',
  MonthIndex = 'monthIndex',
  PropertyId = 'propertyId',
  Text = 'text',
  UpdatedAt = 'updatedAt',
  VersionType = 'versionType',
  Year = 'year'
}

export type AccountVarianceNoteScalarWhereInput = {
  AND?: Maybe<Array<AccountVarianceNoteScalarWhereInput>>;
  NOT?: Maybe<Array<AccountVarianceNoteScalarWhereInput>>;
  OR?: Maybe<Array<AccountVarianceNoteScalarWhereInput>>;
  accountId?: Maybe<StringNullableFilter>;
  componentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  monthIndex?: Maybe<IntFilter>;
  propertyId?: Maybe<StringFilter>;
  text?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type AccountVarianceNoteUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  text?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type AccountVarianceNoteUpdateManyWithWhereWithoutAccountInput = {
  data: AccountVarianceNoteUpdateManyMutationInput;
  where: AccountVarianceNoteScalarWhereInput;
};

export type AccountVarianceNoteUpdateManyWithWhereWithoutComponentInput = {
  data: AccountVarianceNoteUpdateManyMutationInput;
  where: AccountVarianceNoteScalarWhereInput;
};

export type AccountVarianceNoteUpdateManyWithWhereWithoutPropertyInput = {
  data: AccountVarianceNoteUpdateManyMutationInput;
  where: AccountVarianceNoteScalarWhereInput;
};

export type AccountVarianceNoteUpdateManyWithoutAccountInput = {
  connect?: Maybe<Array<AccountVarianceNoteWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountVarianceNoteCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<AccountVarianceNoteCreateWithoutAccountInput>>;
  createMany?: Maybe<AccountVarianceNoteCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<AccountVarianceNoteWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccountVarianceNoteScalarWhereInput>>;
  disconnect?: Maybe<Array<AccountVarianceNoteWhereUniqueInput>>;
  set?: Maybe<Array<AccountVarianceNoteWhereUniqueInput>>;
  update?: Maybe<Array<AccountVarianceNoteUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<AccountVarianceNoteUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<AccountVarianceNoteUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type AccountVarianceNoteUpdateManyWithoutComponentInput = {
  connect?: Maybe<Array<AccountVarianceNoteWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountVarianceNoteCreateOrConnectWithoutComponentInput>>;
  create?: Maybe<Array<AccountVarianceNoteCreateWithoutComponentInput>>;
  createMany?: Maybe<AccountVarianceNoteCreateManyComponentInputEnvelope>;
  delete?: Maybe<Array<AccountVarianceNoteWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccountVarianceNoteScalarWhereInput>>;
  disconnect?: Maybe<Array<AccountVarianceNoteWhereUniqueInput>>;
  set?: Maybe<Array<AccountVarianceNoteWhereUniqueInput>>;
  update?: Maybe<Array<AccountVarianceNoteUpdateWithWhereUniqueWithoutComponentInput>>;
  updateMany?: Maybe<Array<AccountVarianceNoteUpdateManyWithWhereWithoutComponentInput>>;
  upsert?: Maybe<Array<AccountVarianceNoteUpsertWithWhereUniqueWithoutComponentInput>>;
};

export type AccountVarianceNoteUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<AccountVarianceNoteWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountVarianceNoteCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<AccountVarianceNoteCreateWithoutPropertyInput>>;
  createMany?: Maybe<AccountVarianceNoteCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<AccountVarianceNoteWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccountVarianceNoteScalarWhereInput>>;
  disconnect?: Maybe<Array<AccountVarianceNoteWhereUniqueInput>>;
  set?: Maybe<Array<AccountVarianceNoteWhereUniqueInput>>;
  update?: Maybe<Array<AccountVarianceNoteUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<AccountVarianceNoteUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<AccountVarianceNoteUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type AccountVarianceNoteUpdateWithWhereUniqueWithoutAccountInput = {
  data: AccountVarianceNoteUpdateWithoutAccountInput;
  where: AccountVarianceNoteWhereUniqueInput;
};

export type AccountVarianceNoteUpdateWithWhereUniqueWithoutComponentInput = {
  data: AccountVarianceNoteUpdateWithoutComponentInput;
  where: AccountVarianceNoteWhereUniqueInput;
};

export type AccountVarianceNoteUpdateWithWhereUniqueWithoutPropertyInput = {
  data: AccountVarianceNoteUpdateWithoutPropertyInput;
  where: AccountVarianceNoteWhereUniqueInput;
};

export type AccountVarianceNoteUpdateWithoutAccountInput = {
  component?: Maybe<BudgetComponentV2UpdateOneWithoutAccountVarianceNotesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutAccountVarianceNotesInput>;
  text?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type AccountVarianceNoteUpdateWithoutComponentInput = {
  account?: Maybe<AccountV2UpdateOneWithoutAccountVarianceNotesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutAccountVarianceNotesInput>;
  text?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type AccountVarianceNoteUpdateWithoutPropertyInput = {
  account?: Maybe<AccountV2UpdateOneWithoutAccountVarianceNotesInput>;
  component?: Maybe<BudgetComponentV2UpdateOneWithoutAccountVarianceNotesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  text?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type AccountVarianceNoteUpsertInput = {
  accountId?: Maybe<Scalars['String']>;
  componentId?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  text: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export type AccountVarianceNoteUpsertWithWhereUniqueWithoutAccountInput = {
  create: AccountVarianceNoteCreateWithoutAccountInput;
  update: AccountVarianceNoteUpdateWithoutAccountInput;
  where: AccountVarianceNoteWhereUniqueInput;
};

export type AccountVarianceNoteUpsertWithWhereUniqueWithoutComponentInput = {
  create: AccountVarianceNoteCreateWithoutComponentInput;
  update: AccountVarianceNoteUpdateWithoutComponentInput;
  where: AccountVarianceNoteWhereUniqueInput;
};

export type AccountVarianceNoteUpsertWithWhereUniqueWithoutPropertyInput = {
  create: AccountVarianceNoteCreateWithoutPropertyInput;
  update: AccountVarianceNoteUpdateWithoutPropertyInput;
  where: AccountVarianceNoteWhereUniqueInput;
};

export type AccountVarianceNoteWhereInput = {
  AND?: Maybe<Array<AccountVarianceNoteWhereInput>>;
  NOT?: Maybe<Array<AccountVarianceNoteWhereInput>>;
  OR?: Maybe<Array<AccountVarianceNoteWhereInput>>;
  account?: Maybe<AccountV2RelationFilter>;
  accountId?: Maybe<StringNullableFilter>;
  component?: Maybe<BudgetComponentV2RelationFilter>;
  componentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  monthIndex?: Maybe<IntFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  text?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type AccountVarianceNoteWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  propertyId_accountId_componentId_year_versionType_monthIndex?: Maybe<AccountVarianceNotePropertyIdAccountIdComponentIdYearVersionTypeMonthIndexCompoundUniqueInput>;
};

export type AccountVendorModel = {
  __typename?: 'AccountVendorModel';
  amount: Scalars['Decimal'];
  vendorName: Scalars['String'];
};

export type AccountWhereInput = {
  AND?: Maybe<Array<AccountWhereInput>>;
  NOT?: Maybe<Array<AccountWhereInput>>;
  OR?: Maybe<Array<AccountWhereInput>>;
  accountNumber?: Maybe<StringFilter>;
  accountTotalValues?: Maybe<AccountTotalValueListRelationFilter>;
  budgetCategory?: Maybe<BudgetCategoryRelationFilter>;
  budgetCategoryId?: Maybe<StringNullableFilter>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  order?: Maybe<IntNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AccountWhereUniqueInput = {
  clientId_accountNumber?: Maybe<AccountClientIdAccountNumberCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type AccountsTableViewModel = IBaseMetaModel & IFinanceTotalMetaModel & INoteMetaModel & IStarringMetaModel & {
  __typename?: 'AccountsTableViewModel';
  accountGlName: Scalars['String'];
  accountGlNumber: Scalars['String'];
  accountId: Scalars['ID'];
  budgetValue?: Maybe<Scalars['Decimal']>;
  budgetVersionId: Scalars['String'];
  budgetVersionNote?: Maybe<Scalars['String']>;
  isBudgetVersionStarred: Scalars['Boolean'];
  isReforecastVersionStarred: Scalars['Boolean'];
  parentAccountId?: Maybe<Scalars['ID']>;
  propertyId: Scalars['ID'];
  reforecastValue?: Maybe<Scalars['Decimal']>;
  reforecastVersionId: Scalars['String'];
  reforecastVersionNote?: Maybe<Scalars['String']>;
};

export type AccountsTopCardTags = {
  __typename?: 'AccountsTopCardTags';
  accountId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  topCardTagType: TopCardTagType;
  updatedAt: Scalars['DateTime'];
};

export type AccountsTopCardTagsAccountIdTopCardTagTypeCompoundUniqueInput = {
  accountId: Scalars['String'];
  topCardTagType: TopCardTagType;
};

export type AccountsTopCardTagsCreateManyAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  topCardTagType: TopCardTagType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccountsTopCardTagsCreateManyAccountInputEnvelope = {
  data: Array<AccountsTopCardTagsCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccountsTopCardTagsCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<AccountsTopCardTagsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountsTopCardTagsCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<AccountsTopCardTagsCreateWithoutAccountInput>>;
  createMany?: Maybe<AccountsTopCardTagsCreateManyAccountInputEnvelope>;
};

export type AccountsTopCardTagsCreateOrConnectWithoutAccountInput = {
  create: AccountsTopCardTagsCreateWithoutAccountInput;
  where: AccountsTopCardTagsWhereUniqueInput;
};

export type AccountsTopCardTagsCreateWithoutAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  topCardTagType: TopCardTagType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccountsTopCardTagsListRelationFilter = {
  every?: Maybe<AccountsTopCardTagsWhereInput>;
  none?: Maybe<AccountsTopCardTagsWhereInput>;
  some?: Maybe<AccountsTopCardTagsWhereInput>;
};

export type AccountsTopCardTagsModel = {
  __typename?: 'AccountsTopCardTagsModel';
  accountId: Scalars['String'];
  topCardTagType: TopCardTagType;
};

export type AccountsTopCardTagsOrderByInput = {
  accountId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  topCardTagType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum AccountsTopCardTagsScalarFieldEnum {
  AccountId = 'accountId',
  CreatedAt = 'createdAt',
  TopCardTagType = 'topCardTagType',
  UpdatedAt = 'updatedAt'
}

export type AccountsTopCardTagsScalarWhereInput = {
  AND?: Maybe<Array<AccountsTopCardTagsScalarWhereInput>>;
  NOT?: Maybe<Array<AccountsTopCardTagsScalarWhereInput>>;
  OR?: Maybe<Array<AccountsTopCardTagsScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  topCardTagType?: Maybe<EnumTopCardTagTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AccountsTopCardTagsUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  topCardTagType?: Maybe<EnumTopCardTagTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AccountsTopCardTagsUpdateManyWithWhereWithoutAccountInput = {
  data: AccountsTopCardTagsUpdateManyMutationInput;
  where: AccountsTopCardTagsScalarWhereInput;
};

export type AccountsTopCardTagsUpdateManyWithoutAccountInput = {
  connect?: Maybe<Array<AccountsTopCardTagsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AccountsTopCardTagsCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<AccountsTopCardTagsCreateWithoutAccountInput>>;
  createMany?: Maybe<AccountsTopCardTagsCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<AccountsTopCardTagsWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AccountsTopCardTagsScalarWhereInput>>;
  disconnect?: Maybe<Array<AccountsTopCardTagsWhereUniqueInput>>;
  set?: Maybe<Array<AccountsTopCardTagsWhereUniqueInput>>;
  update?: Maybe<Array<AccountsTopCardTagsUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<AccountsTopCardTagsUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<AccountsTopCardTagsUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type AccountsTopCardTagsUpdateWithWhereUniqueWithoutAccountInput = {
  data: AccountsTopCardTagsUpdateWithoutAccountInput;
  where: AccountsTopCardTagsWhereUniqueInput;
};

export type AccountsTopCardTagsUpdateWithoutAccountInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  topCardTagType?: Maybe<EnumTopCardTagTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AccountsTopCardTagsUpsertWithWhereUniqueWithoutAccountInput = {
  create: AccountsTopCardTagsCreateWithoutAccountInput;
  update: AccountsTopCardTagsUpdateWithoutAccountInput;
  where: AccountsTopCardTagsWhereUniqueInput;
};

export type AccountsTopCardTagsWhereInput = {
  AND?: Maybe<Array<AccountsTopCardTagsWhereInput>>;
  NOT?: Maybe<Array<AccountsTopCardTagsWhereInput>>;
  OR?: Maybe<Array<AccountsTopCardTagsWhereInput>>;
  account?: Maybe<AccountV2RelationFilter>;
  accountId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  topCardTagType?: Maybe<EnumTopCardTagTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AccountsTopCardTagsWhereUniqueInput = {
  accountId_topCardTagType?: Maybe<AccountsTopCardTagsAccountIdTopCardTagTypeCompoundUniqueInput>;
};

export type AddDriverOperationalMetric = {
  driverMetricTypeId: Scalars['String'];
  lookbackPeriod?: Maybe<Scalars['Int']>;
  sourceMetricId: Scalars['String'];
  type: Scalars['String'];
};

export type AddDrivers = {
  account: Array<DriverAccountMetric>;
  accountPercentageAugment?: Maybe<DriverAccountPercentageAugmentInput>;
  annualTargetValue: Array<GrowthDriverInput>;
  customItems?: Maybe<Array<Scalars['String']>>;
  monthlyAverage: Array<GrowthDriverInput>;
  operational: Array<AddDriverOperationalMetric>;
  payroll: Array<DriverPayrollMetric>;
  percentGrowth: Array<GrowthDriverInput>;
  revenue: Array<DriverRevenueMetric>;
  worksheet: Array<DriverWorksheetMetric>;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type AggregateOperationalMetricType = {
  __typename?: 'AggregateOperationalMetricType';
  _count?: Maybe<OperationalMetricTypeCountAggregate>;
  _max?: Maybe<OperationalMetricTypeMaxAggregate>;
  _min?: Maybe<OperationalMetricTypeMinAggregate>;
};

export type AggregateProperty = {
  __typename?: 'AggregateProperty';
  _avg?: Maybe<PropertyAvgAggregate>;
  _count?: Maybe<PropertyCountAggregate>;
  _max?: Maybe<PropertyMaxAggregate>;
  _min?: Maybe<PropertyMinAggregate>;
  _sum?: Maybe<PropertySumAggregate>;
};

export type AggregateUnit = {
  __typename?: 'AggregateUnit';
  _avg?: Maybe<UnitAvgAggregate>;
  _count?: Maybe<UnitCountAggregate>;
  _max?: Maybe<UnitMaxAggregate>;
  _min?: Maybe<UnitMinAggregate>;
  _sum?: Maybe<UnitSumAggregate>;
};

export type AggregateUnitTypeScheduleValue = {
  __typename?: 'AggregateUnitTypeScheduleValue';
  _avg?: Maybe<UnitTypeScheduleValueAvgAggregate>;
  _count?: Maybe<UnitTypeScheduleValueCountAggregate>;
  _max?: Maybe<UnitTypeScheduleValueMaxAggregate>;
  _min?: Maybe<UnitTypeScheduleValueMinAggregate>;
  _sum?: Maybe<UnitTypeScheduleValueSumAggregate>;
};

export type AggregateValueVersion = {
  __typename?: 'AggregateValueVersion';
  _avg?: Maybe<ValueVersionAvgAggregate>;
  _count?: Maybe<ValueVersionCountAggregate>;
  _max?: Maybe<ValueVersionMaxAggregate>;
  _min?: Maybe<ValueVersionMinAggregate>;
  _sum?: Maybe<ValueVersionSumAggregate>;
};

export type AnalystDriverInfo = {
  __typename?: 'AnalystDriverInfo';
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  accountV2Id: Scalars['String'];
  /** @deprecated Not used anywhere. Will now return empty string for all values. */
  driverAssignmentId: Scalars['String'];
  driverType: DriverType;
  propertyId: Scalars['String'];
  propertyName: Scalars['String'];
  versionType: VersionType;
};

export type AnalystOpDriversQueryResult = {
  __typename?: 'AnalystOpDriversQueryResult';
  properties: Array<AnalystPropertyModel>;
};

export type AnalystPropertyAllMetricsModel = {
  __typename?: 'AnalystPropertyAllMetricsModel';
  /** The Early Terminations metric for a unit type. */
  earlyTerminations: Array<AnalystUnitYearIntegerMetric>;
  /** The Monthly Move Outs metric for a unit type. */
  monthlyMoveOuts: Array<AnalystUnitYearIntegerMetric>;
};

export type AnalystPropertyModel = {
  __typename?: 'AnalystPropertyModel';
  /** The property's cappedRenewalIncreasePercent. */
  propertyCappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  /** A unique identifier for the property. */
  propertyId: Scalars['String'];
  /** The complete set of operational driver metrics associated with this unit type. */
  propertyMetrics: AnalystPropertyAllMetricsModel;
  /** The property's name. */
  propertyName: Scalars['String'];
  /** An array of unitTypes that are associated with the property. */
  propertyUnits: Array<AnalystPropertyUnitTypeModel>;
  /** The number of total units a property has, regardless of Unit Type */
  totalUnitCount: Scalars['Int'];
};

export type AnalystPropertyUnitTypeModel = {
  __typename?: 'AnalystPropertyUnitTypeModel';
  /** How many of this particular unit type a property may have. */
  numUnits: Scalars['Int'];
  /** The complete set of operational driver metrics associated with this unit type. */
  unitMetrics: AnalystUnitAllMetricsModel;
  /** The name of the unit type. e.g. A1 */
  unitType: Scalars['String'];
  /** A unique identifier for this unit type. */
  unitTypeId: Scalars['String'];
};

export type AnalystUnitAllMetricsModel = {
  __typename?: 'AnalystUnitAllMetricsModel';
  /** The Average Market Rents metric for a unit type. */
  avgMarketRents: Array<AnalystUnitYearDecimalMetric>;
  /** The Lease Expirations metric for a unit type. */
  leaseExpirations: Array<AnalystUnitYearIntegerMetric>;
  /** The Move Out Rates metric for a unit type. */
  moveOutRates: Array<AnalystUnitYearDecimalMetric>;
  /** The Occupancy Rates metric for a unit type. */
  occupancyRates: Array<AnalystUnitYearDecimalMetric>;
  /** The Renewal Increases metric for a unit type. */
  renewalIncreases: Array<AnalystUnitYearDecimalMetric>;
  /** The Renewal Ratios metric for a unit type. */
  renewalRatios: Array<AnalystUnitYearDecimalMetric>;
};

export type AnalystUnitYearDecimalMetric = {
  __typename?: 'AnalystUnitYearDecimalMetric';
  /** Actual values for a year. Future months or months with no values will have null values. */
  actuals: Array<Maybe<Scalars['Decimal']>>;
  /** Expected values for this year. Expected values for past months are kept for historical purposes. */
  expected: Array<Maybe<Scalars['Decimal']>>;
  /** The year this set of Operational Driver metrics is associated with */
  year: Scalars['Int'];
};

export type AnalystUnitYearIntegerMetric = {
  __typename?: 'AnalystUnitYearIntegerMetric';
  /** Actual values for a year. Future months or months with no values will have null values. */
  actuals: Array<Maybe<Scalars['Int']>>;
  /** Expected values for this year. Expected values for past months are kept for historical purposes. */
  expected: Array<Maybe<Scalars['Int']>>;
  /** The year this set of Operational Driver metrics is associated with */
  year: Scalars['Int'];
};

export type AnnualBudgetRemainingModel = {
  __typename?: 'AnnualBudgetRemainingModel';
  budgetTotal: Scalars['Decimal'];
  percentRemaining: Scalars['Decimal'];
  totalRemaining: Scalars['Decimal'];
  year: Scalars['Int'];
};

export type AppFolioReportAccountMetadata = {
  __typename?: 'AppFolioReportAccountMetadata';
  accountId: Scalars['String'];
  classification: Scalars['String'];
};

export type AppFolioReportAccountMetadataCreateManyAccountInput = {
  classification: Scalars['String'];
};

export type AppFolioReportAccountMetadataCreateManyAccountInputEnvelope = {
  data: Array<AppFolioReportAccountMetadataCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppFolioReportAccountMetadataCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<AppFolioReportAccountMetadataWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppFolioReportAccountMetadataCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<AppFolioReportAccountMetadataCreateWithoutAccountInput>>;
  createMany?: Maybe<AppFolioReportAccountMetadataCreateManyAccountInputEnvelope>;
};

export type AppFolioReportAccountMetadataCreateOrConnectWithoutAccountInput = {
  create: AppFolioReportAccountMetadataCreateWithoutAccountInput;
  where: AppFolioReportAccountMetadataWhereUniqueInput;
};

export type AppFolioReportAccountMetadataCreateWithoutAccountInput = {
  classification: Scalars['String'];
};

export type AppFolioReportAccountMetadataListRelationFilter = {
  every?: Maybe<AppFolioReportAccountMetadataWhereInput>;
  none?: Maybe<AppFolioReportAccountMetadataWhereInput>;
  some?: Maybe<AppFolioReportAccountMetadataWhereInput>;
};

export type AppFolioReportAccountMetadataOrderByInput = {
  accountId?: Maybe<SortOrder>;
  classification?: Maybe<SortOrder>;
};

export enum AppFolioReportAccountMetadataScalarFieldEnum {
  AccountId = 'accountId',
  Classification = 'classification'
}

export type AppFolioReportAccountMetadataScalarWhereInput = {
  AND?: Maybe<Array<AppFolioReportAccountMetadataScalarWhereInput>>;
  NOT?: Maybe<Array<AppFolioReportAccountMetadataScalarWhereInput>>;
  OR?: Maybe<Array<AppFolioReportAccountMetadataScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  classification?: Maybe<StringFilter>;
};

export type AppFolioReportAccountMetadataUpdateManyMutationInput = {
  classification?: Maybe<StringFieldUpdateOperationsInput>;
};

export type AppFolioReportAccountMetadataUpdateManyWithWhereWithoutAccountInput = {
  data: AppFolioReportAccountMetadataUpdateManyMutationInput;
  where: AppFolioReportAccountMetadataScalarWhereInput;
};

export type AppFolioReportAccountMetadataUpdateManyWithoutAccountInput = {
  connect?: Maybe<Array<AppFolioReportAccountMetadataWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppFolioReportAccountMetadataCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<AppFolioReportAccountMetadataCreateWithoutAccountInput>>;
  createMany?: Maybe<AppFolioReportAccountMetadataCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<AppFolioReportAccountMetadataWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppFolioReportAccountMetadataScalarWhereInput>>;
  disconnect?: Maybe<Array<AppFolioReportAccountMetadataWhereUniqueInput>>;
  set?: Maybe<Array<AppFolioReportAccountMetadataWhereUniqueInput>>;
  update?: Maybe<Array<AppFolioReportAccountMetadataUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<AppFolioReportAccountMetadataUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<AppFolioReportAccountMetadataUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type AppFolioReportAccountMetadataUpdateWithWhereUniqueWithoutAccountInput = {
  data: AppFolioReportAccountMetadataUpdateWithoutAccountInput;
  where: AppFolioReportAccountMetadataWhereUniqueInput;
};

export type AppFolioReportAccountMetadataUpdateWithoutAccountInput = {
  classification?: Maybe<StringFieldUpdateOperationsInput>;
};

export type AppFolioReportAccountMetadataUpsertWithWhereUniqueWithoutAccountInput = {
  create: AppFolioReportAccountMetadataCreateWithoutAccountInput;
  update: AppFolioReportAccountMetadataUpdateWithoutAccountInput;
  where: AppFolioReportAccountMetadataWhereUniqueInput;
};

export type AppFolioReportAccountMetadataWhereInput = {
  AND?: Maybe<Array<AppFolioReportAccountMetadataWhereInput>>;
  NOT?: Maybe<Array<AppFolioReportAccountMetadataWhereInput>>;
  OR?: Maybe<Array<AppFolioReportAccountMetadataWhereInput>>;
  account?: Maybe<AccountV2RelationFilter>;
  accountId?: Maybe<StringFilter>;
  classification?: Maybe<StringFilter>;
};

export type AppFolioReportAccountMetadataWhereUniqueInput = {
  accountId?: Maybe<Scalars['String']>;
};

export type AuthorizationCreateNestedOneWithoutUserAuthorizationsInput = {
  connect?: Maybe<AuthorizationWhereUniqueInput>;
  connectOrCreate?: Maybe<AuthorizationCreateOrConnectWithoutUserAuthorizationsInput>;
  create?: Maybe<AuthorizationCreateWithoutUserAuthorizationsInput>;
};

export type AuthorizationCreateNestedOneWithoutUserGroupAuthorizationsInput = {
  connect?: Maybe<AuthorizationWhereUniqueInput>;
  connectOrCreate?: Maybe<AuthorizationCreateOrConnectWithoutUserGroupAuthorizationsInput>;
  create?: Maybe<AuthorizationCreateWithoutUserGroupAuthorizationsInput>;
};

export type AuthorizationCreateOrConnectWithoutUserAuthorizationsInput = {
  create: AuthorizationCreateWithoutUserAuthorizationsInput;
  where: AuthorizationWhereUniqueInput;
};

export type AuthorizationCreateOrConnectWithoutUserGroupAuthorizationsInput = {
  create: AuthorizationCreateWithoutUserGroupAuthorizationsInput;
  where: AuthorizationWhereUniqueInput;
};

export type AuthorizationCreateWithoutUserAuthorizationsInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  userGroupAuthorizations?: Maybe<UserGroupAuthorizationsCreateNestedManyWithoutAuthorizationInput>;
};

export type AuthorizationCreateWithoutUserGroupAuthorizationsInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  userAuthorizations?: Maybe<UserAuthorizationsCreateNestedManyWithoutAuthorizationInput>;
};

export type AuthorizationRelationFilter = {
  is?: Maybe<AuthorizationWhereInput>;
  isNot?: Maybe<AuthorizationWhereInput>;
};

export type AuthorizationUpdateOneRequiredWithoutUserAuthorizationsInput = {
  connect?: Maybe<AuthorizationWhereUniqueInput>;
  connectOrCreate?: Maybe<AuthorizationCreateOrConnectWithoutUserAuthorizationsInput>;
  create?: Maybe<AuthorizationCreateWithoutUserAuthorizationsInput>;
  update?: Maybe<AuthorizationUpdateWithoutUserAuthorizationsInput>;
  upsert?: Maybe<AuthorizationUpsertWithoutUserAuthorizationsInput>;
};

export type AuthorizationUpdateOneRequiredWithoutUserGroupAuthorizationsInput = {
  connect?: Maybe<AuthorizationWhereUniqueInput>;
  connectOrCreate?: Maybe<AuthorizationCreateOrConnectWithoutUserGroupAuthorizationsInput>;
  create?: Maybe<AuthorizationCreateWithoutUserGroupAuthorizationsInput>;
  update?: Maybe<AuthorizationUpdateWithoutUserGroupAuthorizationsInput>;
  upsert?: Maybe<AuthorizationUpsertWithoutUserGroupAuthorizationsInput>;
};

export type AuthorizationUpdateWithoutUserAuthorizationsInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  userGroupAuthorizations?: Maybe<UserGroupAuthorizationsUpdateManyWithoutAuthorizationInput>;
};

export type AuthorizationUpdateWithoutUserGroupAuthorizationsInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  userAuthorizations?: Maybe<UserAuthorizationsUpdateManyWithoutAuthorizationInput>;
};

export type AuthorizationUpsertWithoutUserAuthorizationsInput = {
  create: AuthorizationCreateWithoutUserAuthorizationsInput;
  update: AuthorizationUpdateWithoutUserAuthorizationsInput;
};

export type AuthorizationUpsertWithoutUserGroupAuthorizationsInput = {
  create: AuthorizationCreateWithoutUserGroupAuthorizationsInput;
  update: AuthorizationUpdateWithoutUserGroupAuthorizationsInput;
};

export type AuthorizationWhereInput = {
  AND?: Maybe<Array<AuthorizationWhereInput>>;
  NOT?: Maybe<Array<AuthorizationWhereInput>>;
  OR?: Maybe<Array<AuthorizationWhereInput>>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  userAuthorizations?: Maybe<UserAuthorizationsListRelationFilter>;
  userGroupAuthorizations?: Maybe<UserGroupAuthorizationsListRelationFilter>;
};

export type AuthorizationWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type BackgroundJob = {
  __typename?: 'BackgroundJob';
  createdAt: Scalars['DateTime'];
  ended?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  jobType: JobType;
  started?: Maybe<Scalars['DateTime']>;
  status: JobStatus;
  userEmail?: Maybe<Scalars['String']>;
  userFirstName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  userLastName?: Maybe<Scalars['String']>;
};

export type BackgroundJobCreateManyUserInput = {
  clientId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inputs: Scalars['JSON'];
  jobType: JobType;
  startTime?: Maybe<Scalars['DateTime']>;
  status: JobStatus;
};

export type BackgroundJobCreateManyUserInputEnvelope = {
  data: Array<BackgroundJobCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BackgroundJobCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<BackgroundJobWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BackgroundJobCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<BackgroundJobCreateWithoutUserInput>>;
  createMany?: Maybe<BackgroundJobCreateManyUserInputEnvelope>;
};

export type BackgroundJobCreateOrConnectWithoutUserInput = {
  create: BackgroundJobCreateWithoutUserInput;
  where: BackgroundJobWhereUniqueInput;
};

export type BackgroundJobCreateWithoutUserInput = {
  clientId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inputs: Scalars['JSON'];
  jobType: JobType;
  startTime?: Maybe<Scalars['DateTime']>;
  status: JobStatus;
};

export type BackgroundJobListRelationFilter = {
  every?: Maybe<BackgroundJobWhereInput>;
  none?: Maybe<BackgroundJobWhereInput>;
  some?: Maybe<BackgroundJobWhereInput>;
};

export type BackgroundJobScalarWhereInput = {
  AND?: Maybe<Array<BackgroundJobScalarWhereInput>>;
  NOT?: Maybe<Array<BackgroundJobScalarWhereInput>>;
  OR?: Maybe<Array<BackgroundJobScalarWhereInput>>;
  clientId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  endTime?: Maybe<DateTimeNullableFilter>;
  errorMessage?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  inputs?: Maybe<JsonFilter>;
  jobType?: Maybe<EnumJobTypeFilter>;
  startTime?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumJobStatusFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type BackgroundJobUpdateManyMutationInput = {
  clientId?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  endTime?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  errorMessage?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  inputs?: Maybe<Scalars['JSON']>;
  jobType?: Maybe<EnumJobTypeFieldUpdateOperationsInput>;
  startTime?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<EnumJobStatusFieldUpdateOperationsInput>;
};

export type BackgroundJobUpdateManyWithWhereWithoutUserInput = {
  data: BackgroundJobUpdateManyMutationInput;
  where: BackgroundJobScalarWhereInput;
};

export type BackgroundJobUpdateManyWithoutUserInput = {
  connect?: Maybe<Array<BackgroundJobWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BackgroundJobCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<BackgroundJobCreateWithoutUserInput>>;
  createMany?: Maybe<BackgroundJobCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<BackgroundJobWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BackgroundJobScalarWhereInput>>;
  disconnect?: Maybe<Array<BackgroundJobWhereUniqueInput>>;
  set?: Maybe<Array<BackgroundJobWhereUniqueInput>>;
  update?: Maybe<Array<BackgroundJobUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<BackgroundJobUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<BackgroundJobUpsertWithWhereUniqueWithoutUserInput>>;
};

export type BackgroundJobUpdateWithWhereUniqueWithoutUserInput = {
  data: BackgroundJobUpdateWithoutUserInput;
  where: BackgroundJobWhereUniqueInput;
};

export type BackgroundJobUpdateWithoutUserInput = {
  clientId?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  endTime?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  errorMessage?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  inputs?: Maybe<Scalars['JSON']>;
  jobType?: Maybe<EnumJobTypeFieldUpdateOperationsInput>;
  startTime?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<EnumJobStatusFieldUpdateOperationsInput>;
};

export type BackgroundJobUpsertWithWhereUniqueWithoutUserInput = {
  create: BackgroundJobCreateWithoutUserInput;
  update: BackgroundJobUpdateWithoutUserInput;
  where: BackgroundJobWhereUniqueInput;
};

export type BackgroundJobWhereInput = {
  AND?: Maybe<Array<BackgroundJobWhereInput>>;
  NOT?: Maybe<Array<BackgroundJobWhereInput>>;
  OR?: Maybe<Array<BackgroundJobWhereInput>>;
  clientId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  endTime?: Maybe<DateTimeNullableFilter>;
  errorMessage?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  inputs?: Maybe<JsonFilter>;
  jobType?: Maybe<EnumJobTypeFilter>;
  startTime?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumJobStatusFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type BackgroundJobWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};


export type BigIntFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['BigInt']>;
  divide?: Maybe<Scalars['BigInt']>;
  increment?: Maybe<Scalars['BigInt']>;
  multiply?: Maybe<Scalars['BigInt']>;
  set?: Maybe<Scalars['BigInt']>;
};

export type BigIntFilter = {
  equals?: Maybe<Scalars['BigInt']>;
  gt?: Maybe<Scalars['BigInt']>;
  gte?: Maybe<Scalars['BigInt']>;
  in?: Maybe<Array<Scalars['BigInt']>>;
  lt?: Maybe<Scalars['BigInt']>;
  lte?: Maybe<Scalars['BigInt']>;
  not?: Maybe<NestedBigIntFilter>;
  notIn?: Maybe<Array<Scalars['BigInt']>>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type BoolWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedBoolFilter>;
  _min?: Maybe<NestedBoolFilter>;
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolWithAggregatesFilter>;
};

export type BudgetCategory = {
  __typename?: 'BudgetCategory';
  budgetComponentId?: Maybe<Scalars['String']>;
  chartOfAccountsId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
  parentCategoryId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type BudgetCategoryCreateManyBudgetComponentInput = {
  chartOfAccountsId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  parentCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetCategoryCreateManyBudgetComponentInputEnvelope = {
  data: Array<BudgetCategoryCreateManyBudgetComponentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetCategoryCreateManyChartOfAccountsInput = {
  budgetComponentId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  parentCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetCategoryCreateManyChartOfAccountsInputEnvelope = {
  data: Array<BudgetCategoryCreateManyChartOfAccountsInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetCategoryCreateManyClientInput = {
  budgetComponentId?: Maybe<Scalars['String']>;
  chartOfAccountsId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  parentCategoryId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetCategoryCreateManyClientInputEnvelope = {
  data: Array<BudgetCategoryCreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetCategoryCreateManyParentCategoryInput = {
  budgetComponentId?: Maybe<Scalars['String']>;
  chartOfAccountsId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetCategoryCreateManyParentCategoryInputEnvelope = {
  data: Array<BudgetCategoryCreateManyParentCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetCategoryCreateNestedManyWithoutBudgetComponentInput = {
  connect?: Maybe<Array<BudgetCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCategoryCreateOrConnectWithoutBudgetComponentInput>>;
  create?: Maybe<Array<BudgetCategoryCreateWithoutBudgetComponentInput>>;
  createMany?: Maybe<BudgetCategoryCreateManyBudgetComponentInputEnvelope>;
};

export type BudgetCategoryCreateNestedManyWithoutChartOfAccountsInput = {
  connect?: Maybe<Array<BudgetCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCategoryCreateOrConnectWithoutChartOfAccountsInput>>;
  create?: Maybe<Array<BudgetCategoryCreateWithoutChartOfAccountsInput>>;
  createMany?: Maybe<BudgetCategoryCreateManyChartOfAccountsInputEnvelope>;
};

export type BudgetCategoryCreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<BudgetCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCategoryCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<BudgetCategoryCreateWithoutClientInput>>;
  createMany?: Maybe<BudgetCategoryCreateManyClientInputEnvelope>;
};

export type BudgetCategoryCreateNestedManyWithoutParentCategoryInput = {
  connect?: Maybe<Array<BudgetCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCategoryCreateOrConnectWithoutParentCategoryInput>>;
  create?: Maybe<Array<BudgetCategoryCreateWithoutParentCategoryInput>>;
  createMany?: Maybe<BudgetCategoryCreateManyParentCategoryInputEnvelope>;
};

export type BudgetCategoryCreateNestedOneWithoutAccountsInput = {
  connect?: Maybe<BudgetCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetCategoryCreateOrConnectWithoutAccountsInput>;
  create?: Maybe<BudgetCategoryCreateWithoutAccountsInput>;
};

export type BudgetCategoryCreateNestedOneWithoutBudgetCategoryTotalValuesInput = {
  connect?: Maybe<BudgetCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetCategoryCreateOrConnectWithoutBudgetCategoryTotalValuesInput>;
  create?: Maybe<BudgetCategoryCreateWithoutBudgetCategoryTotalValuesInput>;
};

export type BudgetCategoryCreateNestedOneWithoutChildCategoriesInput = {
  connect?: Maybe<BudgetCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetCategoryCreateOrConnectWithoutChildCategoriesInput>;
  create?: Maybe<BudgetCategoryCreateWithoutChildCategoriesInput>;
};

export type BudgetCategoryCreateOrConnectWithoutAccountsInput = {
  create: BudgetCategoryCreateWithoutAccountsInput;
  where: BudgetCategoryWhereUniqueInput;
};

export type BudgetCategoryCreateOrConnectWithoutBudgetCategoryTotalValuesInput = {
  create: BudgetCategoryCreateWithoutBudgetCategoryTotalValuesInput;
  where: BudgetCategoryWhereUniqueInput;
};

export type BudgetCategoryCreateOrConnectWithoutBudgetComponentInput = {
  create: BudgetCategoryCreateWithoutBudgetComponentInput;
  where: BudgetCategoryWhereUniqueInput;
};

export type BudgetCategoryCreateOrConnectWithoutChartOfAccountsInput = {
  create: BudgetCategoryCreateWithoutChartOfAccountsInput;
  where: BudgetCategoryWhereUniqueInput;
};

export type BudgetCategoryCreateOrConnectWithoutChildCategoriesInput = {
  create: BudgetCategoryCreateWithoutChildCategoriesInput;
  where: BudgetCategoryWhereUniqueInput;
};

export type BudgetCategoryCreateOrConnectWithoutClientInput = {
  create: BudgetCategoryCreateWithoutClientInput;
  where: BudgetCategoryWhereUniqueInput;
};

export type BudgetCategoryCreateOrConnectWithoutParentCategoryInput = {
  create: BudgetCategoryCreateWithoutParentCategoryInput;
  where: BudgetCategoryWhereUniqueInput;
};

export type BudgetCategoryCreateWithoutAccountsInput = {
  ChartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutBudgetCategoryInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutBudgetCategoryInput>;
  budgetComponent?: Maybe<BudgetComponentCreateNestedOneWithoutBudgetCategoriesInput>;
  childCategories?: Maybe<BudgetCategoryCreateNestedManyWithoutParentCategoryInput>;
  client?: Maybe<ClientCreateNestedOneWithoutBudgetCategoryInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  parentCategory?: Maybe<BudgetCategoryCreateNestedOneWithoutChildCategoriesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetCategoryCreateWithoutBudgetCategoryTotalValuesInput = {
  ChartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutBudgetCategoryInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutBudgetCategoryInput>;
  budgetComponent?: Maybe<BudgetComponentCreateNestedOneWithoutBudgetCategoriesInput>;
  childCategories?: Maybe<BudgetCategoryCreateNestedManyWithoutParentCategoryInput>;
  client?: Maybe<ClientCreateNestedOneWithoutBudgetCategoryInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  parentCategory?: Maybe<BudgetCategoryCreateNestedOneWithoutChildCategoriesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetCategoryCreateWithoutBudgetComponentInput = {
  ChartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutBudgetCategoryInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutBudgetCategoryInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutBudgetCategoryInput>;
  childCategories?: Maybe<BudgetCategoryCreateNestedManyWithoutParentCategoryInput>;
  client?: Maybe<ClientCreateNestedOneWithoutBudgetCategoryInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  parentCategory?: Maybe<BudgetCategoryCreateNestedOneWithoutChildCategoriesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetCategoryCreateWithoutChartOfAccountsInput = {
  accounts?: Maybe<AccountCreateNestedManyWithoutBudgetCategoryInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutBudgetCategoryInput>;
  budgetComponent?: Maybe<BudgetComponentCreateNestedOneWithoutBudgetCategoriesInput>;
  childCategories?: Maybe<BudgetCategoryCreateNestedManyWithoutParentCategoryInput>;
  client?: Maybe<ClientCreateNestedOneWithoutBudgetCategoryInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  parentCategory?: Maybe<BudgetCategoryCreateNestedOneWithoutChildCategoriesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetCategoryCreateWithoutChildCategoriesInput = {
  ChartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutBudgetCategoryInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutBudgetCategoryInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutBudgetCategoryInput>;
  budgetComponent?: Maybe<BudgetComponentCreateNestedOneWithoutBudgetCategoriesInput>;
  client?: Maybe<ClientCreateNestedOneWithoutBudgetCategoryInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  parentCategory?: Maybe<BudgetCategoryCreateNestedOneWithoutChildCategoriesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetCategoryCreateWithoutClientInput = {
  ChartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutBudgetCategoryInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutBudgetCategoryInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutBudgetCategoryInput>;
  budgetComponent?: Maybe<BudgetComponentCreateNestedOneWithoutBudgetCategoriesInput>;
  childCategories?: Maybe<BudgetCategoryCreateNestedManyWithoutParentCategoryInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  parentCategory?: Maybe<BudgetCategoryCreateNestedOneWithoutChildCategoriesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetCategoryCreateWithoutParentCategoryInput = {
  ChartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutBudgetCategoryInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutBudgetCategoryInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutBudgetCategoryInput>;
  budgetComponent?: Maybe<BudgetComponentCreateNestedOneWithoutBudgetCategoriesInput>;
  childCategories?: Maybe<BudgetCategoryCreateNestedManyWithoutParentCategoryInput>;
  client?: Maybe<ClientCreateNestedOneWithoutBudgetCategoryInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetCategoryListRelationFilter = {
  every?: Maybe<BudgetCategoryWhereInput>;
  none?: Maybe<BudgetCategoryWhereInput>;
  some?: Maybe<BudgetCategoryWhereInput>;
};

export type BudgetCategoryOrderByInput = {
  budgetComponentId?: Maybe<SortOrder>;
  chartOfAccountsId?: Maybe<SortOrder>;
  clientId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  parentCategoryId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BudgetCategoryParentCategoryIdNameCompoundUniqueInput = {
  name: Scalars['String'];
  parentCategoryId: Scalars['String'];
};

export type BudgetCategoryRelationFilter = {
  is?: Maybe<BudgetCategoryWhereInput>;
  isNot?: Maybe<BudgetCategoryWhereInput>;
};

export enum BudgetCategoryScalarFieldEnum {
  BudgetComponentId = 'budgetComponentId',
  ChartOfAccountsId = 'chartOfAccountsId',
  ClientId = 'clientId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Order = 'order',
  ParentCategoryId = 'parentCategoryId',
  UpdatedAt = 'updatedAt'
}

export type BudgetCategoryScalarWhereInput = {
  AND?: Maybe<Array<BudgetCategoryScalarWhereInput>>;
  NOT?: Maybe<Array<BudgetCategoryScalarWhereInput>>;
  OR?: Maybe<Array<BudgetCategoryScalarWhereInput>>;
  budgetComponentId?: Maybe<StringNullableFilter>;
  chartOfAccountsId?: Maybe<StringNullableFilter>;
  clientId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
  parentCategoryId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BudgetCategoryTotalValue = {
  __typename?: 'BudgetCategoryTotalValue';
  budgetCategoryId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  propertyId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['Decimal'];
  versionId: Scalars['String'];
};

export type BudgetCategoryTotalValueBudgetCategoryIdVersionIdPropertyIdCompoundUniqueInput = {
  budgetCategoryId: Scalars['String'];
  propertyId: Scalars['String'];
  versionId: Scalars['String'];
};

export type BudgetCategoryTotalValueCreateManyBudgetCategoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
  versionId: Scalars['String'];
};

export type BudgetCategoryTotalValueCreateManyBudgetCategoryInputEnvelope = {
  data: Array<BudgetCategoryTotalValueCreateManyBudgetCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetCategoryTotalValueCreateManyPropertyInput = {
  budgetCategoryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
  versionId: Scalars['String'];
};

export type BudgetCategoryTotalValueCreateManyPropertyInputEnvelope = {
  data: Array<BudgetCategoryTotalValueCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetCategoryTotalValueCreateManyVersionInput = {
  budgetCategoryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
};

export type BudgetCategoryTotalValueCreateManyVersionInputEnvelope = {
  data: Array<BudgetCategoryTotalValueCreateManyVersionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetCategoryTotalValueCreateNestedManyWithoutBudgetCategoryInput = {
  connect?: Maybe<Array<BudgetCategoryTotalValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCategoryTotalValueCreateOrConnectWithoutBudgetCategoryInput>>;
  create?: Maybe<Array<BudgetCategoryTotalValueCreateWithoutBudgetCategoryInput>>;
  createMany?: Maybe<BudgetCategoryTotalValueCreateManyBudgetCategoryInputEnvelope>;
};

export type BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<BudgetCategoryTotalValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCategoryTotalValueCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<BudgetCategoryTotalValueCreateWithoutPropertyInput>>;
  createMany?: Maybe<BudgetCategoryTotalValueCreateManyPropertyInputEnvelope>;
};

export type BudgetCategoryTotalValueCreateNestedManyWithoutVersionInput = {
  connect?: Maybe<Array<BudgetCategoryTotalValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCategoryTotalValueCreateOrConnectWithoutVersionInput>>;
  create?: Maybe<Array<BudgetCategoryTotalValueCreateWithoutVersionInput>>;
  createMany?: Maybe<BudgetCategoryTotalValueCreateManyVersionInputEnvelope>;
};

export type BudgetCategoryTotalValueCreateOrConnectWithoutBudgetCategoryInput = {
  create: BudgetCategoryTotalValueCreateWithoutBudgetCategoryInput;
  where: BudgetCategoryTotalValueWhereUniqueInput;
};

export type BudgetCategoryTotalValueCreateOrConnectWithoutPropertyInput = {
  create: BudgetCategoryTotalValueCreateWithoutPropertyInput;
  where: BudgetCategoryTotalValueWhereUniqueInput;
};

export type BudgetCategoryTotalValueCreateOrConnectWithoutVersionInput = {
  create: BudgetCategoryTotalValueCreateWithoutVersionInput;
  where: BudgetCategoryTotalValueWhereUniqueInput;
};

export type BudgetCategoryTotalValueCreateWithoutBudgetCategoryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  property: PropertyCreateNestedOneWithoutBudgetCategoryTotalValuesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
  version: ValueVersionCreateNestedOneWithoutBudgetCategoryTotalValuesInput;
};

export type BudgetCategoryTotalValueCreateWithoutPropertyInput = {
  budgetCategory: BudgetCategoryCreateNestedOneWithoutBudgetCategoryTotalValuesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
  version: ValueVersionCreateNestedOneWithoutBudgetCategoryTotalValuesInput;
};

export type BudgetCategoryTotalValueCreateWithoutVersionInput = {
  budgetCategory: BudgetCategoryCreateNestedOneWithoutBudgetCategoryTotalValuesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  property: PropertyCreateNestedOneWithoutBudgetCategoryTotalValuesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
};

export type BudgetCategoryTotalValueListRelationFilter = {
  every?: Maybe<BudgetCategoryTotalValueWhereInput>;
  none?: Maybe<BudgetCategoryTotalValueWhereInput>;
  some?: Maybe<BudgetCategoryTotalValueWhereInput>;
};

export type BudgetCategoryTotalValueOrderByInput = {
  budgetCategoryId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  versionId?: Maybe<SortOrder>;
};

export enum BudgetCategoryTotalValueScalarFieldEnum {
  BudgetCategoryId = 'budgetCategoryId',
  CreatedAt = 'createdAt',
  Id = 'id',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt',
  Value = 'value',
  VersionId = 'versionId'
}

export type BudgetCategoryTotalValueScalarWhereInput = {
  AND?: Maybe<Array<BudgetCategoryTotalValueScalarWhereInput>>;
  NOT?: Maybe<Array<BudgetCategoryTotalValueScalarWhereInput>>;
  OR?: Maybe<Array<BudgetCategoryTotalValueScalarWhereInput>>;
  budgetCategoryId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<DecimalFilter>;
  versionId?: Maybe<StringFilter>;
};

export type BudgetCategoryTotalValueUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<DecimalFieldUpdateOperationsInput>;
};

export type BudgetCategoryTotalValueUpdateManyWithWhereWithoutBudgetCategoryInput = {
  data: BudgetCategoryTotalValueUpdateManyMutationInput;
  where: BudgetCategoryTotalValueScalarWhereInput;
};

export type BudgetCategoryTotalValueUpdateManyWithWhereWithoutPropertyInput = {
  data: BudgetCategoryTotalValueUpdateManyMutationInput;
  where: BudgetCategoryTotalValueScalarWhereInput;
};

export type BudgetCategoryTotalValueUpdateManyWithWhereWithoutVersionInput = {
  data: BudgetCategoryTotalValueUpdateManyMutationInput;
  where: BudgetCategoryTotalValueScalarWhereInput;
};

export type BudgetCategoryTotalValueUpdateManyWithoutBudgetCategoryInput = {
  connect?: Maybe<Array<BudgetCategoryTotalValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCategoryTotalValueCreateOrConnectWithoutBudgetCategoryInput>>;
  create?: Maybe<Array<BudgetCategoryTotalValueCreateWithoutBudgetCategoryInput>>;
  createMany?: Maybe<BudgetCategoryTotalValueCreateManyBudgetCategoryInputEnvelope>;
  delete?: Maybe<Array<BudgetCategoryTotalValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetCategoryTotalValueScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetCategoryTotalValueWhereUniqueInput>>;
  set?: Maybe<Array<BudgetCategoryTotalValueWhereUniqueInput>>;
  update?: Maybe<Array<BudgetCategoryTotalValueUpdateWithWhereUniqueWithoutBudgetCategoryInput>>;
  updateMany?: Maybe<Array<BudgetCategoryTotalValueUpdateManyWithWhereWithoutBudgetCategoryInput>>;
  upsert?: Maybe<Array<BudgetCategoryTotalValueUpsertWithWhereUniqueWithoutBudgetCategoryInput>>;
};

export type BudgetCategoryTotalValueUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<BudgetCategoryTotalValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCategoryTotalValueCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<BudgetCategoryTotalValueCreateWithoutPropertyInput>>;
  createMany?: Maybe<BudgetCategoryTotalValueCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<BudgetCategoryTotalValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetCategoryTotalValueScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetCategoryTotalValueWhereUniqueInput>>;
  set?: Maybe<Array<BudgetCategoryTotalValueWhereUniqueInput>>;
  update?: Maybe<Array<BudgetCategoryTotalValueUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<BudgetCategoryTotalValueUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<BudgetCategoryTotalValueUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type BudgetCategoryTotalValueUpdateManyWithoutVersionInput = {
  connect?: Maybe<Array<BudgetCategoryTotalValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCategoryTotalValueCreateOrConnectWithoutVersionInput>>;
  create?: Maybe<Array<BudgetCategoryTotalValueCreateWithoutVersionInput>>;
  createMany?: Maybe<BudgetCategoryTotalValueCreateManyVersionInputEnvelope>;
  delete?: Maybe<Array<BudgetCategoryTotalValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetCategoryTotalValueScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetCategoryTotalValueWhereUniqueInput>>;
  set?: Maybe<Array<BudgetCategoryTotalValueWhereUniqueInput>>;
  update?: Maybe<Array<BudgetCategoryTotalValueUpdateWithWhereUniqueWithoutVersionInput>>;
  updateMany?: Maybe<Array<BudgetCategoryTotalValueUpdateManyWithWhereWithoutVersionInput>>;
  upsert?: Maybe<Array<BudgetCategoryTotalValueUpsertWithWhereUniqueWithoutVersionInput>>;
};

export type BudgetCategoryTotalValueUpdateWithWhereUniqueWithoutBudgetCategoryInput = {
  data: BudgetCategoryTotalValueUpdateWithoutBudgetCategoryInput;
  where: BudgetCategoryTotalValueWhereUniqueInput;
};

export type BudgetCategoryTotalValueUpdateWithWhereUniqueWithoutPropertyInput = {
  data: BudgetCategoryTotalValueUpdateWithoutPropertyInput;
  where: BudgetCategoryTotalValueWhereUniqueInput;
};

export type BudgetCategoryTotalValueUpdateWithWhereUniqueWithoutVersionInput = {
  data: BudgetCategoryTotalValueUpdateWithoutVersionInput;
  where: BudgetCategoryTotalValueWhereUniqueInput;
};

export type BudgetCategoryTotalValueUpdateWithoutBudgetCategoryInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutBudgetCategoryTotalValuesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<DecimalFieldUpdateOperationsInput>;
  version?: Maybe<ValueVersionUpdateOneRequiredWithoutBudgetCategoryTotalValuesInput>;
};

export type BudgetCategoryTotalValueUpdateWithoutPropertyInput = {
  budgetCategory?: Maybe<BudgetCategoryUpdateOneRequiredWithoutBudgetCategoryTotalValuesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<DecimalFieldUpdateOperationsInput>;
  version?: Maybe<ValueVersionUpdateOneRequiredWithoutBudgetCategoryTotalValuesInput>;
};

export type BudgetCategoryTotalValueUpdateWithoutVersionInput = {
  budgetCategory?: Maybe<BudgetCategoryUpdateOneRequiredWithoutBudgetCategoryTotalValuesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutBudgetCategoryTotalValuesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<DecimalFieldUpdateOperationsInput>;
};

export type BudgetCategoryTotalValueUpsertWithWhereUniqueWithoutBudgetCategoryInput = {
  create: BudgetCategoryTotalValueCreateWithoutBudgetCategoryInput;
  update: BudgetCategoryTotalValueUpdateWithoutBudgetCategoryInput;
  where: BudgetCategoryTotalValueWhereUniqueInput;
};

export type BudgetCategoryTotalValueUpsertWithWhereUniqueWithoutPropertyInput = {
  create: BudgetCategoryTotalValueCreateWithoutPropertyInput;
  update: BudgetCategoryTotalValueUpdateWithoutPropertyInput;
  where: BudgetCategoryTotalValueWhereUniqueInput;
};

export type BudgetCategoryTotalValueUpsertWithWhereUniqueWithoutVersionInput = {
  create: BudgetCategoryTotalValueCreateWithoutVersionInput;
  update: BudgetCategoryTotalValueUpdateWithoutVersionInput;
  where: BudgetCategoryTotalValueWhereUniqueInput;
};

export type BudgetCategoryTotalValueWhereInput = {
  AND?: Maybe<Array<BudgetCategoryTotalValueWhereInput>>;
  NOT?: Maybe<Array<BudgetCategoryTotalValueWhereInput>>;
  OR?: Maybe<Array<BudgetCategoryTotalValueWhereInput>>;
  budgetCategory?: Maybe<BudgetCategoryRelationFilter>;
  budgetCategoryId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<DecimalFilter>;
  version?: Maybe<ValueVersionRelationFilter>;
  versionId?: Maybe<StringFilter>;
};

export type BudgetCategoryTotalValueWhereUniqueInput = {
  budgetCategoryId_versionId_propertyId?: Maybe<BudgetCategoryTotalValueBudgetCategoryIdVersionIdPropertyIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type BudgetCategoryUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetCategoryUpdateManyWithWhereWithoutBudgetComponentInput = {
  data: BudgetCategoryUpdateManyMutationInput;
  where: BudgetCategoryScalarWhereInput;
};

export type BudgetCategoryUpdateManyWithWhereWithoutChartOfAccountsInput = {
  data: BudgetCategoryUpdateManyMutationInput;
  where: BudgetCategoryScalarWhereInput;
};

export type BudgetCategoryUpdateManyWithWhereWithoutClientInput = {
  data: BudgetCategoryUpdateManyMutationInput;
  where: BudgetCategoryScalarWhereInput;
};

export type BudgetCategoryUpdateManyWithWhereWithoutParentCategoryInput = {
  data: BudgetCategoryUpdateManyMutationInput;
  where: BudgetCategoryScalarWhereInput;
};

export type BudgetCategoryUpdateManyWithoutBudgetComponentInput = {
  connect?: Maybe<Array<BudgetCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCategoryCreateOrConnectWithoutBudgetComponentInput>>;
  create?: Maybe<Array<BudgetCategoryCreateWithoutBudgetComponentInput>>;
  createMany?: Maybe<BudgetCategoryCreateManyBudgetComponentInputEnvelope>;
  delete?: Maybe<Array<BudgetCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetCategoryWhereUniqueInput>>;
  set?: Maybe<Array<BudgetCategoryWhereUniqueInput>>;
  update?: Maybe<Array<BudgetCategoryUpdateWithWhereUniqueWithoutBudgetComponentInput>>;
  updateMany?: Maybe<Array<BudgetCategoryUpdateManyWithWhereWithoutBudgetComponentInput>>;
  upsert?: Maybe<Array<BudgetCategoryUpsertWithWhereUniqueWithoutBudgetComponentInput>>;
};

export type BudgetCategoryUpdateManyWithoutChartOfAccountsInput = {
  connect?: Maybe<Array<BudgetCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCategoryCreateOrConnectWithoutChartOfAccountsInput>>;
  create?: Maybe<Array<BudgetCategoryCreateWithoutChartOfAccountsInput>>;
  createMany?: Maybe<BudgetCategoryCreateManyChartOfAccountsInputEnvelope>;
  delete?: Maybe<Array<BudgetCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetCategoryWhereUniqueInput>>;
  set?: Maybe<Array<BudgetCategoryWhereUniqueInput>>;
  update?: Maybe<Array<BudgetCategoryUpdateWithWhereUniqueWithoutChartOfAccountsInput>>;
  updateMany?: Maybe<Array<BudgetCategoryUpdateManyWithWhereWithoutChartOfAccountsInput>>;
  upsert?: Maybe<Array<BudgetCategoryUpsertWithWhereUniqueWithoutChartOfAccountsInput>>;
};

export type BudgetCategoryUpdateManyWithoutClientInput = {
  connect?: Maybe<Array<BudgetCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCategoryCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<BudgetCategoryCreateWithoutClientInput>>;
  createMany?: Maybe<BudgetCategoryCreateManyClientInputEnvelope>;
  delete?: Maybe<Array<BudgetCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetCategoryWhereUniqueInput>>;
  set?: Maybe<Array<BudgetCategoryWhereUniqueInput>>;
  update?: Maybe<Array<BudgetCategoryUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<BudgetCategoryUpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<BudgetCategoryUpsertWithWhereUniqueWithoutClientInput>>;
};

export type BudgetCategoryUpdateManyWithoutParentCategoryInput = {
  connect?: Maybe<Array<BudgetCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCategoryCreateOrConnectWithoutParentCategoryInput>>;
  create?: Maybe<Array<BudgetCategoryCreateWithoutParentCategoryInput>>;
  createMany?: Maybe<BudgetCategoryCreateManyParentCategoryInputEnvelope>;
  delete?: Maybe<Array<BudgetCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetCategoryWhereUniqueInput>>;
  set?: Maybe<Array<BudgetCategoryWhereUniqueInput>>;
  update?: Maybe<Array<BudgetCategoryUpdateWithWhereUniqueWithoutParentCategoryInput>>;
  updateMany?: Maybe<Array<BudgetCategoryUpdateManyWithWhereWithoutParentCategoryInput>>;
  upsert?: Maybe<Array<BudgetCategoryUpsertWithWhereUniqueWithoutParentCategoryInput>>;
};

export type BudgetCategoryUpdateOneRequiredWithoutBudgetCategoryTotalValuesInput = {
  connect?: Maybe<BudgetCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetCategoryCreateOrConnectWithoutBudgetCategoryTotalValuesInput>;
  create?: Maybe<BudgetCategoryCreateWithoutBudgetCategoryTotalValuesInput>;
  update?: Maybe<BudgetCategoryUpdateWithoutBudgetCategoryTotalValuesInput>;
  upsert?: Maybe<BudgetCategoryUpsertWithoutBudgetCategoryTotalValuesInput>;
};

export type BudgetCategoryUpdateOneWithoutAccountsInput = {
  connect?: Maybe<BudgetCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetCategoryCreateOrConnectWithoutAccountsInput>;
  create?: Maybe<BudgetCategoryCreateWithoutAccountsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BudgetCategoryUpdateWithoutAccountsInput>;
  upsert?: Maybe<BudgetCategoryUpsertWithoutAccountsInput>;
};

export type BudgetCategoryUpdateOneWithoutChildCategoriesInput = {
  connect?: Maybe<BudgetCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetCategoryCreateOrConnectWithoutChildCategoriesInput>;
  create?: Maybe<BudgetCategoryCreateWithoutChildCategoriesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BudgetCategoryUpdateWithoutChildCategoriesInput>;
  upsert?: Maybe<BudgetCategoryUpsertWithoutChildCategoriesInput>;
};

export type BudgetCategoryUpdateWithWhereUniqueWithoutBudgetComponentInput = {
  data: BudgetCategoryUpdateWithoutBudgetComponentInput;
  where: BudgetCategoryWhereUniqueInput;
};

export type BudgetCategoryUpdateWithWhereUniqueWithoutChartOfAccountsInput = {
  data: BudgetCategoryUpdateWithoutChartOfAccountsInput;
  where: BudgetCategoryWhereUniqueInput;
};

export type BudgetCategoryUpdateWithWhereUniqueWithoutClientInput = {
  data: BudgetCategoryUpdateWithoutClientInput;
  where: BudgetCategoryWhereUniqueInput;
};

export type BudgetCategoryUpdateWithWhereUniqueWithoutParentCategoryInput = {
  data: BudgetCategoryUpdateWithoutParentCategoryInput;
  where: BudgetCategoryWhereUniqueInput;
};

export type BudgetCategoryUpdateWithoutAccountsInput = {
  ChartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutBudgetCategoryInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutBudgetCategoryInput>;
  budgetComponent?: Maybe<BudgetComponentUpdateOneWithoutBudgetCategoriesInput>;
  childCategories?: Maybe<BudgetCategoryUpdateManyWithoutParentCategoryInput>;
  client?: Maybe<ClientUpdateOneWithoutBudgetCategoryInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parentCategory?: Maybe<BudgetCategoryUpdateOneWithoutChildCategoriesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetCategoryUpdateWithoutBudgetCategoryTotalValuesInput = {
  ChartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutBudgetCategoryInput>;
  accounts?: Maybe<AccountUpdateManyWithoutBudgetCategoryInput>;
  budgetComponent?: Maybe<BudgetComponentUpdateOneWithoutBudgetCategoriesInput>;
  childCategories?: Maybe<BudgetCategoryUpdateManyWithoutParentCategoryInput>;
  client?: Maybe<ClientUpdateOneWithoutBudgetCategoryInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parentCategory?: Maybe<BudgetCategoryUpdateOneWithoutChildCategoriesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetCategoryUpdateWithoutBudgetComponentInput = {
  ChartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutBudgetCategoryInput>;
  accounts?: Maybe<AccountUpdateManyWithoutBudgetCategoryInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutBudgetCategoryInput>;
  childCategories?: Maybe<BudgetCategoryUpdateManyWithoutParentCategoryInput>;
  client?: Maybe<ClientUpdateOneWithoutBudgetCategoryInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parentCategory?: Maybe<BudgetCategoryUpdateOneWithoutChildCategoriesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetCategoryUpdateWithoutChartOfAccountsInput = {
  accounts?: Maybe<AccountUpdateManyWithoutBudgetCategoryInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutBudgetCategoryInput>;
  budgetComponent?: Maybe<BudgetComponentUpdateOneWithoutBudgetCategoriesInput>;
  childCategories?: Maybe<BudgetCategoryUpdateManyWithoutParentCategoryInput>;
  client?: Maybe<ClientUpdateOneWithoutBudgetCategoryInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parentCategory?: Maybe<BudgetCategoryUpdateOneWithoutChildCategoriesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetCategoryUpdateWithoutChildCategoriesInput = {
  ChartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutBudgetCategoryInput>;
  accounts?: Maybe<AccountUpdateManyWithoutBudgetCategoryInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutBudgetCategoryInput>;
  budgetComponent?: Maybe<BudgetComponentUpdateOneWithoutBudgetCategoriesInput>;
  client?: Maybe<ClientUpdateOneWithoutBudgetCategoryInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parentCategory?: Maybe<BudgetCategoryUpdateOneWithoutChildCategoriesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetCategoryUpdateWithoutClientInput = {
  ChartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutBudgetCategoryInput>;
  accounts?: Maybe<AccountUpdateManyWithoutBudgetCategoryInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutBudgetCategoryInput>;
  budgetComponent?: Maybe<BudgetComponentUpdateOneWithoutBudgetCategoriesInput>;
  childCategories?: Maybe<BudgetCategoryUpdateManyWithoutParentCategoryInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  parentCategory?: Maybe<BudgetCategoryUpdateOneWithoutChildCategoriesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetCategoryUpdateWithoutParentCategoryInput = {
  ChartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutBudgetCategoryInput>;
  accounts?: Maybe<AccountUpdateManyWithoutBudgetCategoryInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutBudgetCategoryInput>;
  budgetComponent?: Maybe<BudgetComponentUpdateOneWithoutBudgetCategoriesInput>;
  childCategories?: Maybe<BudgetCategoryUpdateManyWithoutParentCategoryInput>;
  client?: Maybe<ClientUpdateOneWithoutBudgetCategoryInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetCategoryUpsertWithWhereUniqueWithoutBudgetComponentInput = {
  create: BudgetCategoryCreateWithoutBudgetComponentInput;
  update: BudgetCategoryUpdateWithoutBudgetComponentInput;
  where: BudgetCategoryWhereUniqueInput;
};

export type BudgetCategoryUpsertWithWhereUniqueWithoutChartOfAccountsInput = {
  create: BudgetCategoryCreateWithoutChartOfAccountsInput;
  update: BudgetCategoryUpdateWithoutChartOfAccountsInput;
  where: BudgetCategoryWhereUniqueInput;
};

export type BudgetCategoryUpsertWithWhereUniqueWithoutClientInput = {
  create: BudgetCategoryCreateWithoutClientInput;
  update: BudgetCategoryUpdateWithoutClientInput;
  where: BudgetCategoryWhereUniqueInput;
};

export type BudgetCategoryUpsertWithWhereUniqueWithoutParentCategoryInput = {
  create: BudgetCategoryCreateWithoutParentCategoryInput;
  update: BudgetCategoryUpdateWithoutParentCategoryInput;
  where: BudgetCategoryWhereUniqueInput;
};

export type BudgetCategoryUpsertWithoutAccountsInput = {
  create: BudgetCategoryCreateWithoutAccountsInput;
  update: BudgetCategoryUpdateWithoutAccountsInput;
};

export type BudgetCategoryUpsertWithoutBudgetCategoryTotalValuesInput = {
  create: BudgetCategoryCreateWithoutBudgetCategoryTotalValuesInput;
  update: BudgetCategoryUpdateWithoutBudgetCategoryTotalValuesInput;
};

export type BudgetCategoryUpsertWithoutChildCategoriesInput = {
  create: BudgetCategoryCreateWithoutChildCategoriesInput;
  update: BudgetCategoryUpdateWithoutChildCategoriesInput;
};

export type BudgetCategoryValue = {
  __typename?: 'BudgetCategoryValue';
  accountV2Id?: Maybe<Scalars['String']>;
  budgetCategoryId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['Decimal'];
  versionId: Scalars['String'];
};

export type BudgetCategoryValueBudgetCategoryIdAccountV2IdVersionIdMonthIndexPropertyIdCompoundUniqueInput = {
  accountV2Id: Scalars['String'];
  budgetCategoryId: Scalars['String'];
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  versionId: Scalars['String'];
};

export type BudgetCategoryValueCreateManyAccountV2Input = {
  budgetCategoryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
  versionId: Scalars['String'];
};

export type BudgetCategoryValueCreateManyAccountV2InputEnvelope = {
  data: Array<BudgetCategoryValueCreateManyAccountV2Input>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetCategoryValueCreateManyPropertyInput = {
  accountV2Id?: Maybe<Scalars['String']>;
  budgetCategoryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
  versionId: Scalars['String'];
};

export type BudgetCategoryValueCreateManyPropertyInputEnvelope = {
  data: Array<BudgetCategoryValueCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetCategoryValueCreateManyVersionInput = {
  accountV2Id?: Maybe<Scalars['String']>;
  budgetCategoryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
};

export type BudgetCategoryValueCreateManyVersionInputEnvelope = {
  data: Array<BudgetCategoryValueCreateManyVersionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetCategoryValueCreateNestedManyWithoutAccountV2Input = {
  connect?: Maybe<Array<BudgetCategoryValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCategoryValueCreateOrConnectWithoutAccountV2Input>>;
  create?: Maybe<Array<BudgetCategoryValueCreateWithoutAccountV2Input>>;
  createMany?: Maybe<BudgetCategoryValueCreateManyAccountV2InputEnvelope>;
};

export type BudgetCategoryValueCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<BudgetCategoryValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCategoryValueCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<BudgetCategoryValueCreateWithoutPropertyInput>>;
  createMany?: Maybe<BudgetCategoryValueCreateManyPropertyInputEnvelope>;
};

export type BudgetCategoryValueCreateNestedManyWithoutVersionInput = {
  connect?: Maybe<Array<BudgetCategoryValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCategoryValueCreateOrConnectWithoutVersionInput>>;
  create?: Maybe<Array<BudgetCategoryValueCreateWithoutVersionInput>>;
  createMany?: Maybe<BudgetCategoryValueCreateManyVersionInputEnvelope>;
};

export type BudgetCategoryValueCreateOrConnectWithoutAccountV2Input = {
  create: BudgetCategoryValueCreateWithoutAccountV2Input;
  where: BudgetCategoryValueWhereUniqueInput;
};

export type BudgetCategoryValueCreateOrConnectWithoutPropertyInput = {
  create: BudgetCategoryValueCreateWithoutPropertyInput;
  where: BudgetCategoryValueWhereUniqueInput;
};

export type BudgetCategoryValueCreateOrConnectWithoutVersionInput = {
  create: BudgetCategoryValueCreateWithoutVersionInput;
  where: BudgetCategoryValueWhereUniqueInput;
};

export type BudgetCategoryValueCreateWithoutAccountV2Input = {
  budgetCategoryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  property: PropertyCreateNestedOneWithoutBudgetCategoryValuesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
  version: ValueVersionCreateNestedOneWithoutBudgetCategoryValuesInput;
};

export type BudgetCategoryValueCreateWithoutPropertyInput = {
  accountV2?: Maybe<AccountV2CreateNestedOneWithoutBudgetCategoryValuesInput>;
  budgetCategoryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
  version: ValueVersionCreateNestedOneWithoutBudgetCategoryValuesInput;
};

export type BudgetCategoryValueCreateWithoutVersionInput = {
  accountV2?: Maybe<AccountV2CreateNestedOneWithoutBudgetCategoryValuesInput>;
  budgetCategoryId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  property: PropertyCreateNestedOneWithoutBudgetCategoryValuesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
};

export type BudgetCategoryValueListRelationFilter = {
  every?: Maybe<BudgetCategoryValueWhereInput>;
  none?: Maybe<BudgetCategoryValueWhereInput>;
  some?: Maybe<BudgetCategoryValueWhereInput>;
};

export type BudgetCategoryValueOrderByInput = {
  accountV2Id?: Maybe<SortOrder>;
  budgetCategoryId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  monthIndex?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  versionId?: Maybe<SortOrder>;
};

export enum BudgetCategoryValueScalarFieldEnum {
  AccountV2Id = 'accountV2Id',
  BudgetCategoryId = 'budgetCategoryId',
  CreatedAt = 'createdAt',
  Id = 'id',
  MonthIndex = 'monthIndex',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt',
  Value = 'value',
  VersionId = 'versionId'
}

export type BudgetCategoryValueScalarWhereInput = {
  AND?: Maybe<Array<BudgetCategoryValueScalarWhereInput>>;
  NOT?: Maybe<Array<BudgetCategoryValueScalarWhereInput>>;
  OR?: Maybe<Array<BudgetCategoryValueScalarWhereInput>>;
  accountV2Id?: Maybe<StringNullableFilter>;
  budgetCategoryId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  monthIndex?: Maybe<IntFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<DecimalFilter>;
  versionId?: Maybe<StringFilter>;
};

export type BudgetCategoryValueUpdateManyMutationInput = {
  budgetCategoryId?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<DecimalFieldUpdateOperationsInput>;
};

export type BudgetCategoryValueUpdateManyWithWhereWithoutAccountV2Input = {
  data: BudgetCategoryValueUpdateManyMutationInput;
  where: BudgetCategoryValueScalarWhereInput;
};

export type BudgetCategoryValueUpdateManyWithWhereWithoutPropertyInput = {
  data: BudgetCategoryValueUpdateManyMutationInput;
  where: BudgetCategoryValueScalarWhereInput;
};

export type BudgetCategoryValueUpdateManyWithWhereWithoutVersionInput = {
  data: BudgetCategoryValueUpdateManyMutationInput;
  where: BudgetCategoryValueScalarWhereInput;
};

export type BudgetCategoryValueUpdateManyWithoutAccountV2Input = {
  connect?: Maybe<Array<BudgetCategoryValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCategoryValueCreateOrConnectWithoutAccountV2Input>>;
  create?: Maybe<Array<BudgetCategoryValueCreateWithoutAccountV2Input>>;
  createMany?: Maybe<BudgetCategoryValueCreateManyAccountV2InputEnvelope>;
  delete?: Maybe<Array<BudgetCategoryValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetCategoryValueScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetCategoryValueWhereUniqueInput>>;
  set?: Maybe<Array<BudgetCategoryValueWhereUniqueInput>>;
  update?: Maybe<Array<BudgetCategoryValueUpdateWithWhereUniqueWithoutAccountV2Input>>;
  updateMany?: Maybe<Array<BudgetCategoryValueUpdateManyWithWhereWithoutAccountV2Input>>;
  upsert?: Maybe<Array<BudgetCategoryValueUpsertWithWhereUniqueWithoutAccountV2Input>>;
};

export type BudgetCategoryValueUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<BudgetCategoryValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCategoryValueCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<BudgetCategoryValueCreateWithoutPropertyInput>>;
  createMany?: Maybe<BudgetCategoryValueCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<BudgetCategoryValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetCategoryValueScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetCategoryValueWhereUniqueInput>>;
  set?: Maybe<Array<BudgetCategoryValueWhereUniqueInput>>;
  update?: Maybe<Array<BudgetCategoryValueUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<BudgetCategoryValueUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<BudgetCategoryValueUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type BudgetCategoryValueUpdateManyWithoutVersionInput = {
  connect?: Maybe<Array<BudgetCategoryValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCategoryValueCreateOrConnectWithoutVersionInput>>;
  create?: Maybe<Array<BudgetCategoryValueCreateWithoutVersionInput>>;
  createMany?: Maybe<BudgetCategoryValueCreateManyVersionInputEnvelope>;
  delete?: Maybe<Array<BudgetCategoryValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetCategoryValueScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetCategoryValueWhereUniqueInput>>;
  set?: Maybe<Array<BudgetCategoryValueWhereUniqueInput>>;
  update?: Maybe<Array<BudgetCategoryValueUpdateWithWhereUniqueWithoutVersionInput>>;
  updateMany?: Maybe<Array<BudgetCategoryValueUpdateManyWithWhereWithoutVersionInput>>;
  upsert?: Maybe<Array<BudgetCategoryValueUpsertWithWhereUniqueWithoutVersionInput>>;
};

export type BudgetCategoryValueUpdateWithWhereUniqueWithoutAccountV2Input = {
  data: BudgetCategoryValueUpdateWithoutAccountV2Input;
  where: BudgetCategoryValueWhereUniqueInput;
};

export type BudgetCategoryValueUpdateWithWhereUniqueWithoutPropertyInput = {
  data: BudgetCategoryValueUpdateWithoutPropertyInput;
  where: BudgetCategoryValueWhereUniqueInput;
};

export type BudgetCategoryValueUpdateWithWhereUniqueWithoutVersionInput = {
  data: BudgetCategoryValueUpdateWithoutVersionInput;
  where: BudgetCategoryValueWhereUniqueInput;
};

export type BudgetCategoryValueUpdateWithoutAccountV2Input = {
  budgetCategoryId?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutBudgetCategoryValuesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<DecimalFieldUpdateOperationsInput>;
  version?: Maybe<ValueVersionUpdateOneRequiredWithoutBudgetCategoryValuesInput>;
};

export type BudgetCategoryValueUpdateWithoutPropertyInput = {
  accountV2?: Maybe<AccountV2UpdateOneWithoutBudgetCategoryValuesInput>;
  budgetCategoryId?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<DecimalFieldUpdateOperationsInput>;
  version?: Maybe<ValueVersionUpdateOneRequiredWithoutBudgetCategoryValuesInput>;
};

export type BudgetCategoryValueUpdateWithoutVersionInput = {
  accountV2?: Maybe<AccountV2UpdateOneWithoutBudgetCategoryValuesInput>;
  budgetCategoryId?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutBudgetCategoryValuesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<DecimalFieldUpdateOperationsInput>;
};

export type BudgetCategoryValueUpsertWithWhereUniqueWithoutAccountV2Input = {
  create: BudgetCategoryValueCreateWithoutAccountV2Input;
  update: BudgetCategoryValueUpdateWithoutAccountV2Input;
  where: BudgetCategoryValueWhereUniqueInput;
};

export type BudgetCategoryValueUpsertWithWhereUniqueWithoutPropertyInput = {
  create: BudgetCategoryValueCreateWithoutPropertyInput;
  update: BudgetCategoryValueUpdateWithoutPropertyInput;
  where: BudgetCategoryValueWhereUniqueInput;
};

export type BudgetCategoryValueUpsertWithWhereUniqueWithoutVersionInput = {
  create: BudgetCategoryValueCreateWithoutVersionInput;
  update: BudgetCategoryValueUpdateWithoutVersionInput;
  where: BudgetCategoryValueWhereUniqueInput;
};

export type BudgetCategoryValueWhereInput = {
  AND?: Maybe<Array<BudgetCategoryValueWhereInput>>;
  NOT?: Maybe<Array<BudgetCategoryValueWhereInput>>;
  OR?: Maybe<Array<BudgetCategoryValueWhereInput>>;
  accountV2?: Maybe<AccountV2RelationFilter>;
  accountV2Id?: Maybe<StringNullableFilter>;
  budgetCategoryId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  monthIndex?: Maybe<IntFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<DecimalFilter>;
  version?: Maybe<ValueVersionRelationFilter>;
  versionId?: Maybe<StringFilter>;
};

export type BudgetCategoryValueWhereUniqueInput = {
  budgetCategoryId_accountV2Id_versionId_monthIndex_propertyId?: Maybe<BudgetCategoryValueBudgetCategoryIdAccountV2IdVersionIdMonthIndexPropertyIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type BudgetCategoryWhereInput = {
  AND?: Maybe<Array<BudgetCategoryWhereInput>>;
  ChartOfAccounts?: Maybe<ChartOfAccountsRelationFilter>;
  NOT?: Maybe<Array<BudgetCategoryWhereInput>>;
  OR?: Maybe<Array<BudgetCategoryWhereInput>>;
  accounts?: Maybe<AccountListRelationFilter>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueListRelationFilter>;
  budgetComponent?: Maybe<BudgetComponentRelationFilter>;
  budgetComponentId?: Maybe<StringNullableFilter>;
  chartOfAccountsId?: Maybe<StringNullableFilter>;
  childCategories?: Maybe<BudgetCategoryListRelationFilter>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
  parentCategory?: Maybe<BudgetCategoryRelationFilter>;
  parentCategoryId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BudgetCategoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  parentCategoryId_name?: Maybe<BudgetCategoryParentCategoryIdNameCompoundUniqueInput>;
};

export type BudgetComponentChartOfAccountsIdNameCompoundUniqueInput = {
  chartOfAccountsId: Scalars['String'];
  name: Scalars['String'];
};

export type BudgetComponentCreateNestedOneWithoutBudgetCategoriesInput = {
  connect?: Maybe<BudgetComponentWhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetComponentCreateOrConnectWithoutBudgetCategoriesInput>;
  create?: Maybe<BudgetComponentCreateWithoutBudgetCategoriesInput>;
};

export type BudgetComponentCreateNestedOneWithoutBudgetComponentTotalValuesInput = {
  connect?: Maybe<BudgetComponentWhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetComponentCreateOrConnectWithoutBudgetComponentTotalValuesInput>;
  create?: Maybe<BudgetComponentCreateWithoutBudgetComponentTotalValuesInput>;
};

export type BudgetComponentCreateNestedOneWithoutInOpsInput = {
  connect?: Maybe<BudgetComponentWhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetComponentCreateOrConnectWithoutInOpsInput>;
  create?: Maybe<BudgetComponentCreateWithoutInOpsInput>;
};

export type BudgetComponentCreateNestedOneWithoutOutOpInput = {
  connect?: Maybe<BudgetComponentWhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetComponentCreateOrConnectWithoutOutOpInput>;
  create?: Maybe<BudgetComponentCreateWithoutOutOpInput>;
};

export type BudgetComponentCreateOrConnectWithoutBudgetCategoriesInput = {
  create: BudgetComponentCreateWithoutBudgetCategoriesInput;
  where: BudgetComponentWhereUniqueInput;
};

export type BudgetComponentCreateOrConnectWithoutBudgetComponentTotalValuesInput = {
  create: BudgetComponentCreateWithoutBudgetComponentTotalValuesInput;
  where: BudgetComponentWhereUniqueInput;
};

export type BudgetComponentCreateOrConnectWithoutInOpsInput = {
  create: BudgetComponentCreateWithoutInOpsInput;
  where: BudgetComponentWhereUniqueInput;
};

export type BudgetComponentCreateOrConnectWithoutOutOpInput = {
  create: BudgetComponentCreateWithoutOutOpInput;
  where: BudgetComponentWhereUniqueInput;
};

export type BudgetComponentCreateWithoutBudgetCategoriesInput = {
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutBudgetComponentInput>;
  budgetComponentType?: Maybe<BudgetComponentType>;
  chartOfAccountsId: Scalars['String'];
  clientId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  inOps?: Maybe<BudgetComponentOpCreateNestedManyWithoutRecipientComponentInput>;
  name: Scalars['String'];
  order: Scalars['Int'];
  outOp?: Maybe<BudgetComponentOpCreateNestedOneWithoutSourceComponentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetComponentCreateWithoutBudgetComponentTotalValuesInput = {
  budgetCategories?: Maybe<BudgetCategoryCreateNestedManyWithoutBudgetComponentInput>;
  budgetComponentType?: Maybe<BudgetComponentType>;
  chartOfAccountsId: Scalars['String'];
  clientId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  inOps?: Maybe<BudgetComponentOpCreateNestedManyWithoutRecipientComponentInput>;
  name: Scalars['String'];
  order: Scalars['Int'];
  outOp?: Maybe<BudgetComponentOpCreateNestedOneWithoutSourceComponentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetComponentCreateWithoutInOpsInput = {
  budgetCategories?: Maybe<BudgetCategoryCreateNestedManyWithoutBudgetComponentInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutBudgetComponentInput>;
  budgetComponentType?: Maybe<BudgetComponentType>;
  chartOfAccountsId: Scalars['String'];
  clientId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  outOp?: Maybe<BudgetComponentOpCreateNestedOneWithoutSourceComponentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetComponentCreateWithoutOutOpInput = {
  budgetCategories?: Maybe<BudgetCategoryCreateNestedManyWithoutBudgetComponentInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutBudgetComponentInput>;
  budgetComponentType?: Maybe<BudgetComponentType>;
  chartOfAccountsId: Scalars['String'];
  clientId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  inOps?: Maybe<BudgetComponentOpCreateNestedManyWithoutRecipientComponentInput>;
  name: Scalars['String'];
  order: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetComponentMapping = {
  __typename?: 'BudgetComponentMapping';
  component: BudgetComponentV2;
  componentId: Scalars['String'];
  id: Scalars['String'];
  negate: Scalars['Boolean'];
  sourceAccount?: Maybe<AccountV2>;
  sourceAccountId?: Maybe<Scalars['String']>;
  sourceComponent?: Maybe<BudgetComponentV2>;
  sourceComponentId?: Maybe<Scalars['String']>;
};

export type BudgetComponentMappingComponentIdSourceAccountIdSourceComponentIdCompoundUniqueInput = {
  componentId: Scalars['String'];
  sourceAccountId: Scalars['String'];
  sourceComponentId: Scalars['String'];
};

export type BudgetComponentMappingCreateManyComponentInput = {
  id?: Maybe<Scalars['String']>;
  negate: Scalars['Boolean'];
  sourceAccountId?: Maybe<Scalars['String']>;
  sourceComponentId?: Maybe<Scalars['String']>;
};

export type BudgetComponentMappingCreateManyComponentInputEnvelope = {
  data: Array<BudgetComponentMappingCreateManyComponentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetComponentMappingCreateManySourceAccountInput = {
  componentId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  negate: Scalars['Boolean'];
  sourceComponentId?: Maybe<Scalars['String']>;
};

export type BudgetComponentMappingCreateManySourceAccountInputEnvelope = {
  data: Array<BudgetComponentMappingCreateManySourceAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetComponentMappingCreateManySourceComponentInput = {
  componentId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  negate: Scalars['Boolean'];
  sourceAccountId?: Maybe<Scalars['String']>;
};

export type BudgetComponentMappingCreateManySourceComponentInputEnvelope = {
  data: Array<BudgetComponentMappingCreateManySourceComponentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetComponentMappingCreateNestedManyWithoutComponentInput = {
  connect?: Maybe<Array<BudgetComponentMappingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentMappingCreateOrConnectWithoutComponentInput>>;
  create?: Maybe<Array<BudgetComponentMappingCreateWithoutComponentInput>>;
  createMany?: Maybe<BudgetComponentMappingCreateManyComponentInputEnvelope>;
};

export type BudgetComponentMappingCreateNestedManyWithoutSourceAccountInput = {
  connect?: Maybe<Array<BudgetComponentMappingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentMappingCreateOrConnectWithoutSourceAccountInput>>;
  create?: Maybe<Array<BudgetComponentMappingCreateWithoutSourceAccountInput>>;
  createMany?: Maybe<BudgetComponentMappingCreateManySourceAccountInputEnvelope>;
};

export type BudgetComponentMappingCreateNestedManyWithoutSourceComponentInput = {
  connect?: Maybe<Array<BudgetComponentMappingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentMappingCreateOrConnectWithoutSourceComponentInput>>;
  create?: Maybe<Array<BudgetComponentMappingCreateWithoutSourceComponentInput>>;
  createMany?: Maybe<BudgetComponentMappingCreateManySourceComponentInputEnvelope>;
};

export type BudgetComponentMappingCreateOrConnectWithoutComponentInput = {
  create: BudgetComponentMappingCreateWithoutComponentInput;
  where: BudgetComponentMappingWhereUniqueInput;
};

export type BudgetComponentMappingCreateOrConnectWithoutSourceAccountInput = {
  create: BudgetComponentMappingCreateWithoutSourceAccountInput;
  where: BudgetComponentMappingWhereUniqueInput;
};

export type BudgetComponentMappingCreateOrConnectWithoutSourceComponentInput = {
  create: BudgetComponentMappingCreateWithoutSourceComponentInput;
  where: BudgetComponentMappingWhereUniqueInput;
};

export type BudgetComponentMappingCreateWithoutComponentInput = {
  id?: Maybe<Scalars['String']>;
  negate: Scalars['Boolean'];
  sourceAccount?: Maybe<AccountV2CreateNestedOneWithoutBudgetComponentMappingsInput>;
  sourceComponent?: Maybe<BudgetComponentV2CreateNestedOneWithoutBudgetComponentMappingSourcesInput>;
};

export type BudgetComponentMappingCreateWithoutSourceAccountInput = {
  component: BudgetComponentV2CreateNestedOneWithoutBudgetComponentMappingsInput;
  id?: Maybe<Scalars['String']>;
  negate: Scalars['Boolean'];
  sourceComponent?: Maybe<BudgetComponentV2CreateNestedOneWithoutBudgetComponentMappingSourcesInput>;
};

export type BudgetComponentMappingCreateWithoutSourceComponentInput = {
  component: BudgetComponentV2CreateNestedOneWithoutBudgetComponentMappingsInput;
  id?: Maybe<Scalars['String']>;
  negate: Scalars['Boolean'];
  sourceAccount?: Maybe<AccountV2CreateNestedOneWithoutBudgetComponentMappingsInput>;
};

export type BudgetComponentMappingListRelationFilter = {
  every?: Maybe<BudgetComponentMappingWhereInput>;
  none?: Maybe<BudgetComponentMappingWhereInput>;
  some?: Maybe<BudgetComponentMappingWhereInput>;
};

export type BudgetComponentMappingOrderByInput = {
  componentId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  negate?: Maybe<SortOrder>;
  sourceAccountId?: Maybe<SortOrder>;
  sourceComponentId?: Maybe<SortOrder>;
};

export enum BudgetComponentMappingScalarFieldEnum {
  ComponentId = 'componentId',
  Id = 'id',
  Negate = 'negate',
  SourceAccountId = 'sourceAccountId',
  SourceComponentId = 'sourceComponentId'
}

export type BudgetComponentMappingScalarWhereInput = {
  AND?: Maybe<Array<BudgetComponentMappingScalarWhereInput>>;
  NOT?: Maybe<Array<BudgetComponentMappingScalarWhereInput>>;
  OR?: Maybe<Array<BudgetComponentMappingScalarWhereInput>>;
  componentId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  negate?: Maybe<BoolFilter>;
  sourceAccountId?: Maybe<StringNullableFilter>;
  sourceComponentId?: Maybe<StringNullableFilter>;
};

export type BudgetComponentMappingUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type BudgetComponentMappingUpdateManyWithWhereWithoutComponentInput = {
  data: BudgetComponentMappingUpdateManyMutationInput;
  where: BudgetComponentMappingScalarWhereInput;
};

export type BudgetComponentMappingUpdateManyWithWhereWithoutSourceAccountInput = {
  data: BudgetComponentMappingUpdateManyMutationInput;
  where: BudgetComponentMappingScalarWhereInput;
};

export type BudgetComponentMappingUpdateManyWithWhereWithoutSourceComponentInput = {
  data: BudgetComponentMappingUpdateManyMutationInput;
  where: BudgetComponentMappingScalarWhereInput;
};

export type BudgetComponentMappingUpdateManyWithoutComponentInput = {
  connect?: Maybe<Array<BudgetComponentMappingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentMappingCreateOrConnectWithoutComponentInput>>;
  create?: Maybe<Array<BudgetComponentMappingCreateWithoutComponentInput>>;
  createMany?: Maybe<BudgetComponentMappingCreateManyComponentInputEnvelope>;
  delete?: Maybe<Array<BudgetComponentMappingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetComponentMappingScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetComponentMappingWhereUniqueInput>>;
  set?: Maybe<Array<BudgetComponentMappingWhereUniqueInput>>;
  update?: Maybe<Array<BudgetComponentMappingUpdateWithWhereUniqueWithoutComponentInput>>;
  updateMany?: Maybe<Array<BudgetComponentMappingUpdateManyWithWhereWithoutComponentInput>>;
  upsert?: Maybe<Array<BudgetComponentMappingUpsertWithWhereUniqueWithoutComponentInput>>;
};

export type BudgetComponentMappingUpdateManyWithoutSourceAccountInput = {
  connect?: Maybe<Array<BudgetComponentMappingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentMappingCreateOrConnectWithoutSourceAccountInput>>;
  create?: Maybe<Array<BudgetComponentMappingCreateWithoutSourceAccountInput>>;
  createMany?: Maybe<BudgetComponentMappingCreateManySourceAccountInputEnvelope>;
  delete?: Maybe<Array<BudgetComponentMappingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetComponentMappingScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetComponentMappingWhereUniqueInput>>;
  set?: Maybe<Array<BudgetComponentMappingWhereUniqueInput>>;
  update?: Maybe<Array<BudgetComponentMappingUpdateWithWhereUniqueWithoutSourceAccountInput>>;
  updateMany?: Maybe<Array<BudgetComponentMappingUpdateManyWithWhereWithoutSourceAccountInput>>;
  upsert?: Maybe<Array<BudgetComponentMappingUpsertWithWhereUniqueWithoutSourceAccountInput>>;
};

export type BudgetComponentMappingUpdateManyWithoutSourceComponentInput = {
  connect?: Maybe<Array<BudgetComponentMappingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentMappingCreateOrConnectWithoutSourceComponentInput>>;
  create?: Maybe<Array<BudgetComponentMappingCreateWithoutSourceComponentInput>>;
  createMany?: Maybe<BudgetComponentMappingCreateManySourceComponentInputEnvelope>;
  delete?: Maybe<Array<BudgetComponentMappingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetComponentMappingScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetComponentMappingWhereUniqueInput>>;
  set?: Maybe<Array<BudgetComponentMappingWhereUniqueInput>>;
  update?: Maybe<Array<BudgetComponentMappingUpdateWithWhereUniqueWithoutSourceComponentInput>>;
  updateMany?: Maybe<Array<BudgetComponentMappingUpdateManyWithWhereWithoutSourceComponentInput>>;
  upsert?: Maybe<Array<BudgetComponentMappingUpsertWithWhereUniqueWithoutSourceComponentInput>>;
};

export type BudgetComponentMappingUpdateWithWhereUniqueWithoutComponentInput = {
  data: BudgetComponentMappingUpdateWithoutComponentInput;
  where: BudgetComponentMappingWhereUniqueInput;
};

export type BudgetComponentMappingUpdateWithWhereUniqueWithoutSourceAccountInput = {
  data: BudgetComponentMappingUpdateWithoutSourceAccountInput;
  where: BudgetComponentMappingWhereUniqueInput;
};

export type BudgetComponentMappingUpdateWithWhereUniqueWithoutSourceComponentInput = {
  data: BudgetComponentMappingUpdateWithoutSourceComponentInput;
  where: BudgetComponentMappingWhereUniqueInput;
};

export type BudgetComponentMappingUpdateWithoutComponentInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  sourceAccount?: Maybe<AccountV2UpdateOneWithoutBudgetComponentMappingsInput>;
  sourceComponent?: Maybe<BudgetComponentV2UpdateOneWithoutBudgetComponentMappingSourcesInput>;
};

export type BudgetComponentMappingUpdateWithoutSourceAccountInput = {
  component?: Maybe<BudgetComponentV2UpdateOneRequiredWithoutBudgetComponentMappingsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  sourceComponent?: Maybe<BudgetComponentV2UpdateOneWithoutBudgetComponentMappingSourcesInput>;
};

export type BudgetComponentMappingUpdateWithoutSourceComponentInput = {
  component?: Maybe<BudgetComponentV2UpdateOneRequiredWithoutBudgetComponentMappingsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  negate?: Maybe<BoolFieldUpdateOperationsInput>;
  sourceAccount?: Maybe<AccountV2UpdateOneWithoutBudgetComponentMappingsInput>;
};

export type BudgetComponentMappingUpsertWithWhereUniqueWithoutComponentInput = {
  create: BudgetComponentMappingCreateWithoutComponentInput;
  update: BudgetComponentMappingUpdateWithoutComponentInput;
  where: BudgetComponentMappingWhereUniqueInput;
};

export type BudgetComponentMappingUpsertWithWhereUniqueWithoutSourceAccountInput = {
  create: BudgetComponentMappingCreateWithoutSourceAccountInput;
  update: BudgetComponentMappingUpdateWithoutSourceAccountInput;
  where: BudgetComponentMappingWhereUniqueInput;
};

export type BudgetComponentMappingUpsertWithWhereUniqueWithoutSourceComponentInput = {
  create: BudgetComponentMappingCreateWithoutSourceComponentInput;
  update: BudgetComponentMappingUpdateWithoutSourceComponentInput;
  where: BudgetComponentMappingWhereUniqueInput;
};

export type BudgetComponentMappingWhereInput = {
  AND?: Maybe<Array<BudgetComponentMappingWhereInput>>;
  NOT?: Maybe<Array<BudgetComponentMappingWhereInput>>;
  OR?: Maybe<Array<BudgetComponentMappingWhereInput>>;
  component?: Maybe<BudgetComponentV2RelationFilter>;
  componentId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  negate?: Maybe<BoolFilter>;
  sourceAccount?: Maybe<AccountV2RelationFilter>;
  sourceAccountId?: Maybe<StringNullableFilter>;
  sourceComponent?: Maybe<BudgetComponentV2RelationFilter>;
  sourceComponentId?: Maybe<StringNullableFilter>;
};

export type BudgetComponentMappingWhereUniqueInput = {
  componentId_sourceAccountId_sourceComponentId?: Maybe<BudgetComponentMappingComponentIdSourceAccountIdSourceComponentIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type BudgetComponentOpCreateManyRecipientComponentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  operationType?: Maybe<MathOperationType>;
  sourceComponentId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetComponentOpCreateManyRecipientComponentInputEnvelope = {
  data: Array<BudgetComponentOpCreateManyRecipientComponentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetComponentOpCreateNestedManyWithoutRecipientComponentInput = {
  connect?: Maybe<Array<BudgetComponentOpWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentOpCreateOrConnectWithoutRecipientComponentInput>>;
  create?: Maybe<Array<BudgetComponentOpCreateWithoutRecipientComponentInput>>;
  createMany?: Maybe<BudgetComponentOpCreateManyRecipientComponentInputEnvelope>;
};

export type BudgetComponentOpCreateNestedOneWithoutSourceComponentInput = {
  connect?: Maybe<BudgetComponentOpWhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetComponentOpCreateOrConnectWithoutSourceComponentInput>;
  create?: Maybe<BudgetComponentOpCreateWithoutSourceComponentInput>;
};

export type BudgetComponentOpCreateOrConnectWithoutRecipientComponentInput = {
  create: BudgetComponentOpCreateWithoutRecipientComponentInput;
  where: BudgetComponentOpWhereUniqueInput;
};

export type BudgetComponentOpCreateOrConnectWithoutSourceComponentInput = {
  create: BudgetComponentOpCreateWithoutSourceComponentInput;
  where: BudgetComponentOpWhereUniqueInput;
};

export type BudgetComponentOpCreateWithoutRecipientComponentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  operationType?: Maybe<MathOperationType>;
  sourceComponent: BudgetComponentCreateNestedOneWithoutOutOpInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetComponentOpCreateWithoutSourceComponentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  operationType?: Maybe<MathOperationType>;
  recipientComponent: BudgetComponentCreateNestedOneWithoutInOpsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetComponentOpListRelationFilter = {
  every?: Maybe<BudgetComponentOpWhereInput>;
  none?: Maybe<BudgetComponentOpWhereInput>;
  some?: Maybe<BudgetComponentOpWhereInput>;
};

export type BudgetComponentOpRelationFilter = {
  is?: Maybe<BudgetComponentOpWhereInput>;
  isNot?: Maybe<BudgetComponentOpWhereInput>;
};

export type BudgetComponentOpScalarWhereInput = {
  AND?: Maybe<Array<BudgetComponentOpScalarWhereInput>>;
  NOT?: Maybe<Array<BudgetComponentOpScalarWhereInput>>;
  OR?: Maybe<Array<BudgetComponentOpScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  operationType?: Maybe<EnumMathOperationTypeFilter>;
  recipientComponentId?: Maybe<StringFilter>;
  sourceComponentId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BudgetComponentOpSourceComponentIdRecipientComponentIdCompoundUniqueInput = {
  recipientComponentId: Scalars['String'];
  sourceComponentId: Scalars['String'];
};

export type BudgetComponentOpUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  operationType?: Maybe<EnumMathOperationTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetComponentOpUpdateManyWithWhereWithoutRecipientComponentInput = {
  data: BudgetComponentOpUpdateManyMutationInput;
  where: BudgetComponentOpScalarWhereInput;
};

export type BudgetComponentOpUpdateManyWithoutRecipientComponentInput = {
  connect?: Maybe<Array<BudgetComponentOpWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentOpCreateOrConnectWithoutRecipientComponentInput>>;
  create?: Maybe<Array<BudgetComponentOpCreateWithoutRecipientComponentInput>>;
  createMany?: Maybe<BudgetComponentOpCreateManyRecipientComponentInputEnvelope>;
  delete?: Maybe<Array<BudgetComponentOpWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetComponentOpScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetComponentOpWhereUniqueInput>>;
  set?: Maybe<Array<BudgetComponentOpWhereUniqueInput>>;
  update?: Maybe<Array<BudgetComponentOpUpdateWithWhereUniqueWithoutRecipientComponentInput>>;
  updateMany?: Maybe<Array<BudgetComponentOpUpdateManyWithWhereWithoutRecipientComponentInput>>;
  upsert?: Maybe<Array<BudgetComponentOpUpsertWithWhereUniqueWithoutRecipientComponentInput>>;
};

export type BudgetComponentOpUpdateOneWithoutSourceComponentInput = {
  connect?: Maybe<BudgetComponentOpWhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetComponentOpCreateOrConnectWithoutSourceComponentInput>;
  create?: Maybe<BudgetComponentOpCreateWithoutSourceComponentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BudgetComponentOpUpdateWithoutSourceComponentInput>;
  upsert?: Maybe<BudgetComponentOpUpsertWithoutSourceComponentInput>;
};

export type BudgetComponentOpUpdateWithWhereUniqueWithoutRecipientComponentInput = {
  data: BudgetComponentOpUpdateWithoutRecipientComponentInput;
  where: BudgetComponentOpWhereUniqueInput;
};

export type BudgetComponentOpUpdateWithoutRecipientComponentInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  operationType?: Maybe<EnumMathOperationTypeFieldUpdateOperationsInput>;
  sourceComponent?: Maybe<BudgetComponentUpdateOneRequiredWithoutOutOpInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetComponentOpUpdateWithoutSourceComponentInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  operationType?: Maybe<EnumMathOperationTypeFieldUpdateOperationsInput>;
  recipientComponent?: Maybe<BudgetComponentUpdateOneRequiredWithoutInOpsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetComponentOpUpsertWithWhereUniqueWithoutRecipientComponentInput = {
  create: BudgetComponentOpCreateWithoutRecipientComponentInput;
  update: BudgetComponentOpUpdateWithoutRecipientComponentInput;
  where: BudgetComponentOpWhereUniqueInput;
};

export type BudgetComponentOpUpsertWithoutSourceComponentInput = {
  create: BudgetComponentOpCreateWithoutSourceComponentInput;
  update: BudgetComponentOpUpdateWithoutSourceComponentInput;
};

export type BudgetComponentOpWhereInput = {
  AND?: Maybe<Array<BudgetComponentOpWhereInput>>;
  NOT?: Maybe<Array<BudgetComponentOpWhereInput>>;
  OR?: Maybe<Array<BudgetComponentOpWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  operationType?: Maybe<EnumMathOperationTypeFilter>;
  recipientComponent?: Maybe<BudgetComponentRelationFilter>;
  recipientComponentId?: Maybe<StringFilter>;
  sourceComponent?: Maybe<BudgetComponentRelationFilter>;
  sourceComponentId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BudgetComponentOpWhereUniqueInput = {
  sourceComponentId?: Maybe<Scalars['String']>;
  sourceComponentId_recipientComponentId?: Maybe<BudgetComponentOpSourceComponentIdRecipientComponentIdCompoundUniqueInput>;
};

export type BudgetComponentRelationFilter = {
  is?: Maybe<BudgetComponentWhereInput>;
  isNot?: Maybe<BudgetComponentWhereInput>;
};

export type BudgetComponentTotalValue = {
  __typename?: 'BudgetComponentTotalValue';
  budgetComponentId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  propertyId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['Decimal'];
  versionId: Scalars['String'];
};

export type BudgetComponentTotalValueBudgetComponentIdVersionIdPropertyIdCompoundUniqueInput = {
  budgetComponentId: Scalars['String'];
  propertyId: Scalars['String'];
  versionId: Scalars['String'];
};

export type BudgetComponentTotalValueCreateManyBudgetComponentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
  versionId: Scalars['String'];
};

export type BudgetComponentTotalValueCreateManyBudgetComponentInputEnvelope = {
  data: Array<BudgetComponentTotalValueCreateManyBudgetComponentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetComponentTotalValueCreateManyPropertyInput = {
  budgetComponentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
  versionId: Scalars['String'];
};

export type BudgetComponentTotalValueCreateManyPropertyInputEnvelope = {
  data: Array<BudgetComponentTotalValueCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetComponentTotalValueCreateManyVersionInput = {
  budgetComponentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
};

export type BudgetComponentTotalValueCreateManyVersionInputEnvelope = {
  data: Array<BudgetComponentTotalValueCreateManyVersionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetComponentTotalValueCreateNestedManyWithoutBudgetComponentInput = {
  connect?: Maybe<Array<BudgetComponentTotalValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentTotalValueCreateOrConnectWithoutBudgetComponentInput>>;
  create?: Maybe<Array<BudgetComponentTotalValueCreateWithoutBudgetComponentInput>>;
  createMany?: Maybe<BudgetComponentTotalValueCreateManyBudgetComponentInputEnvelope>;
};

export type BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<BudgetComponentTotalValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentTotalValueCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<BudgetComponentTotalValueCreateWithoutPropertyInput>>;
  createMany?: Maybe<BudgetComponentTotalValueCreateManyPropertyInputEnvelope>;
};

export type BudgetComponentTotalValueCreateNestedManyWithoutVersionInput = {
  connect?: Maybe<Array<BudgetComponentTotalValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentTotalValueCreateOrConnectWithoutVersionInput>>;
  create?: Maybe<Array<BudgetComponentTotalValueCreateWithoutVersionInput>>;
  createMany?: Maybe<BudgetComponentTotalValueCreateManyVersionInputEnvelope>;
};

export type BudgetComponentTotalValueCreateOrConnectWithoutBudgetComponentInput = {
  create: BudgetComponentTotalValueCreateWithoutBudgetComponentInput;
  where: BudgetComponentTotalValueWhereUniqueInput;
};

export type BudgetComponentTotalValueCreateOrConnectWithoutPropertyInput = {
  create: BudgetComponentTotalValueCreateWithoutPropertyInput;
  where: BudgetComponentTotalValueWhereUniqueInput;
};

export type BudgetComponentTotalValueCreateOrConnectWithoutVersionInput = {
  create: BudgetComponentTotalValueCreateWithoutVersionInput;
  where: BudgetComponentTotalValueWhereUniqueInput;
};

export type BudgetComponentTotalValueCreateWithoutBudgetComponentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  property: PropertyCreateNestedOneWithoutBudgetComponentTotalValuesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
  version: ValueVersionCreateNestedOneWithoutBudgetComponentTotalValuesInput;
};

export type BudgetComponentTotalValueCreateWithoutPropertyInput = {
  budgetComponent: BudgetComponentCreateNestedOneWithoutBudgetComponentTotalValuesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
  version: ValueVersionCreateNestedOneWithoutBudgetComponentTotalValuesInput;
};

export type BudgetComponentTotalValueCreateWithoutVersionInput = {
  budgetComponent: BudgetComponentCreateNestedOneWithoutBudgetComponentTotalValuesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  property: PropertyCreateNestedOneWithoutBudgetComponentTotalValuesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
};

export type BudgetComponentTotalValueListRelationFilter = {
  every?: Maybe<BudgetComponentTotalValueWhereInput>;
  none?: Maybe<BudgetComponentTotalValueWhereInput>;
  some?: Maybe<BudgetComponentTotalValueWhereInput>;
};

export type BudgetComponentTotalValueOrderByInput = {
  budgetComponentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  versionId?: Maybe<SortOrder>;
};

export enum BudgetComponentTotalValueScalarFieldEnum {
  BudgetComponentId = 'budgetComponentId',
  CreatedAt = 'createdAt',
  Id = 'id',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt',
  Value = 'value',
  VersionId = 'versionId'
}

export type BudgetComponentTotalValueScalarWhereInput = {
  AND?: Maybe<Array<BudgetComponentTotalValueScalarWhereInput>>;
  NOT?: Maybe<Array<BudgetComponentTotalValueScalarWhereInput>>;
  OR?: Maybe<Array<BudgetComponentTotalValueScalarWhereInput>>;
  budgetComponentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<DecimalFilter>;
  versionId?: Maybe<StringFilter>;
};

export type BudgetComponentTotalValueUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<DecimalFieldUpdateOperationsInput>;
};

export type BudgetComponentTotalValueUpdateManyWithWhereWithoutBudgetComponentInput = {
  data: BudgetComponentTotalValueUpdateManyMutationInput;
  where: BudgetComponentTotalValueScalarWhereInput;
};

export type BudgetComponentTotalValueUpdateManyWithWhereWithoutPropertyInput = {
  data: BudgetComponentTotalValueUpdateManyMutationInput;
  where: BudgetComponentTotalValueScalarWhereInput;
};

export type BudgetComponentTotalValueUpdateManyWithWhereWithoutVersionInput = {
  data: BudgetComponentTotalValueUpdateManyMutationInput;
  where: BudgetComponentTotalValueScalarWhereInput;
};

export type BudgetComponentTotalValueUpdateManyWithoutBudgetComponentInput = {
  connect?: Maybe<Array<BudgetComponentTotalValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentTotalValueCreateOrConnectWithoutBudgetComponentInput>>;
  create?: Maybe<Array<BudgetComponentTotalValueCreateWithoutBudgetComponentInput>>;
  createMany?: Maybe<BudgetComponentTotalValueCreateManyBudgetComponentInputEnvelope>;
  delete?: Maybe<Array<BudgetComponentTotalValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetComponentTotalValueScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetComponentTotalValueWhereUniqueInput>>;
  set?: Maybe<Array<BudgetComponentTotalValueWhereUniqueInput>>;
  update?: Maybe<Array<BudgetComponentTotalValueUpdateWithWhereUniqueWithoutBudgetComponentInput>>;
  updateMany?: Maybe<Array<BudgetComponentTotalValueUpdateManyWithWhereWithoutBudgetComponentInput>>;
  upsert?: Maybe<Array<BudgetComponentTotalValueUpsertWithWhereUniqueWithoutBudgetComponentInput>>;
};

export type BudgetComponentTotalValueUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<BudgetComponentTotalValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentTotalValueCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<BudgetComponentTotalValueCreateWithoutPropertyInput>>;
  createMany?: Maybe<BudgetComponentTotalValueCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<BudgetComponentTotalValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetComponentTotalValueScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetComponentTotalValueWhereUniqueInput>>;
  set?: Maybe<Array<BudgetComponentTotalValueWhereUniqueInput>>;
  update?: Maybe<Array<BudgetComponentTotalValueUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<BudgetComponentTotalValueUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<BudgetComponentTotalValueUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type BudgetComponentTotalValueUpdateManyWithoutVersionInput = {
  connect?: Maybe<Array<BudgetComponentTotalValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentTotalValueCreateOrConnectWithoutVersionInput>>;
  create?: Maybe<Array<BudgetComponentTotalValueCreateWithoutVersionInput>>;
  createMany?: Maybe<BudgetComponentTotalValueCreateManyVersionInputEnvelope>;
  delete?: Maybe<Array<BudgetComponentTotalValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetComponentTotalValueScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetComponentTotalValueWhereUniqueInput>>;
  set?: Maybe<Array<BudgetComponentTotalValueWhereUniqueInput>>;
  update?: Maybe<Array<BudgetComponentTotalValueUpdateWithWhereUniqueWithoutVersionInput>>;
  updateMany?: Maybe<Array<BudgetComponentTotalValueUpdateManyWithWhereWithoutVersionInput>>;
  upsert?: Maybe<Array<BudgetComponentTotalValueUpsertWithWhereUniqueWithoutVersionInput>>;
};

export type BudgetComponentTotalValueUpdateWithWhereUniqueWithoutBudgetComponentInput = {
  data: BudgetComponentTotalValueUpdateWithoutBudgetComponentInput;
  where: BudgetComponentTotalValueWhereUniqueInput;
};

export type BudgetComponentTotalValueUpdateWithWhereUniqueWithoutPropertyInput = {
  data: BudgetComponentTotalValueUpdateWithoutPropertyInput;
  where: BudgetComponentTotalValueWhereUniqueInput;
};

export type BudgetComponentTotalValueUpdateWithWhereUniqueWithoutVersionInput = {
  data: BudgetComponentTotalValueUpdateWithoutVersionInput;
  where: BudgetComponentTotalValueWhereUniqueInput;
};

export type BudgetComponentTotalValueUpdateWithoutBudgetComponentInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutBudgetComponentTotalValuesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<DecimalFieldUpdateOperationsInput>;
  version?: Maybe<ValueVersionUpdateOneRequiredWithoutBudgetComponentTotalValuesInput>;
};

export type BudgetComponentTotalValueUpdateWithoutPropertyInput = {
  budgetComponent?: Maybe<BudgetComponentUpdateOneRequiredWithoutBudgetComponentTotalValuesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<DecimalFieldUpdateOperationsInput>;
  version?: Maybe<ValueVersionUpdateOneRequiredWithoutBudgetComponentTotalValuesInput>;
};

export type BudgetComponentTotalValueUpdateWithoutVersionInput = {
  budgetComponent?: Maybe<BudgetComponentUpdateOneRequiredWithoutBudgetComponentTotalValuesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutBudgetComponentTotalValuesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<DecimalFieldUpdateOperationsInput>;
};

export type BudgetComponentTotalValueUpsertWithWhereUniqueWithoutBudgetComponentInput = {
  create: BudgetComponentTotalValueCreateWithoutBudgetComponentInput;
  update: BudgetComponentTotalValueUpdateWithoutBudgetComponentInput;
  where: BudgetComponentTotalValueWhereUniqueInput;
};

export type BudgetComponentTotalValueUpsertWithWhereUniqueWithoutPropertyInput = {
  create: BudgetComponentTotalValueCreateWithoutPropertyInput;
  update: BudgetComponentTotalValueUpdateWithoutPropertyInput;
  where: BudgetComponentTotalValueWhereUniqueInput;
};

export type BudgetComponentTotalValueUpsertWithWhereUniqueWithoutVersionInput = {
  create: BudgetComponentTotalValueCreateWithoutVersionInput;
  update: BudgetComponentTotalValueUpdateWithoutVersionInput;
  where: BudgetComponentTotalValueWhereUniqueInput;
};

export type BudgetComponentTotalValueWhereInput = {
  AND?: Maybe<Array<BudgetComponentTotalValueWhereInput>>;
  NOT?: Maybe<Array<BudgetComponentTotalValueWhereInput>>;
  OR?: Maybe<Array<BudgetComponentTotalValueWhereInput>>;
  budgetComponent?: Maybe<BudgetComponentRelationFilter>;
  budgetComponentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<DecimalFilter>;
  version?: Maybe<ValueVersionRelationFilter>;
  versionId?: Maybe<StringFilter>;
};

export type BudgetComponentTotalValueWhereUniqueInput = {
  budgetComponentId_versionId_propertyId?: Maybe<BudgetComponentTotalValueBudgetComponentIdVersionIdPropertyIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export enum BudgetComponentType {
  Expense = 'EXPENSE',
  Income = 'INCOME',
  Undefined = 'UNDEFINED'
}

export type BudgetComponentUpdateOneRequiredWithoutBudgetComponentTotalValuesInput = {
  connect?: Maybe<BudgetComponentWhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetComponentCreateOrConnectWithoutBudgetComponentTotalValuesInput>;
  create?: Maybe<BudgetComponentCreateWithoutBudgetComponentTotalValuesInput>;
  update?: Maybe<BudgetComponentUpdateWithoutBudgetComponentTotalValuesInput>;
  upsert?: Maybe<BudgetComponentUpsertWithoutBudgetComponentTotalValuesInput>;
};

export type BudgetComponentUpdateOneRequiredWithoutInOpsInput = {
  connect?: Maybe<BudgetComponentWhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetComponentCreateOrConnectWithoutInOpsInput>;
  create?: Maybe<BudgetComponentCreateWithoutInOpsInput>;
  update?: Maybe<BudgetComponentUpdateWithoutInOpsInput>;
  upsert?: Maybe<BudgetComponentUpsertWithoutInOpsInput>;
};

export type BudgetComponentUpdateOneRequiredWithoutOutOpInput = {
  connect?: Maybe<BudgetComponentWhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetComponentCreateOrConnectWithoutOutOpInput>;
  create?: Maybe<BudgetComponentCreateWithoutOutOpInput>;
  update?: Maybe<BudgetComponentUpdateWithoutOutOpInput>;
  upsert?: Maybe<BudgetComponentUpsertWithoutOutOpInput>;
};

export type BudgetComponentUpdateOneWithoutBudgetCategoriesInput = {
  connect?: Maybe<BudgetComponentWhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetComponentCreateOrConnectWithoutBudgetCategoriesInput>;
  create?: Maybe<BudgetComponentCreateWithoutBudgetCategoriesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BudgetComponentUpdateWithoutBudgetCategoriesInput>;
  upsert?: Maybe<BudgetComponentUpsertWithoutBudgetCategoriesInput>;
};

export type BudgetComponentUpdateWithoutBudgetCategoriesInput = {
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutBudgetComponentInput>;
  budgetComponentType?: Maybe<EnumBudgetComponentTypeFieldUpdateOperationsInput>;
  chartOfAccountsId?: Maybe<StringFieldUpdateOperationsInput>;
  clientId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  inOps?: Maybe<BudgetComponentOpUpdateManyWithoutRecipientComponentInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  outOp?: Maybe<BudgetComponentOpUpdateOneWithoutSourceComponentInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetComponentUpdateWithoutBudgetComponentTotalValuesInput = {
  budgetCategories?: Maybe<BudgetCategoryUpdateManyWithoutBudgetComponentInput>;
  budgetComponentType?: Maybe<EnumBudgetComponentTypeFieldUpdateOperationsInput>;
  chartOfAccountsId?: Maybe<StringFieldUpdateOperationsInput>;
  clientId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  inOps?: Maybe<BudgetComponentOpUpdateManyWithoutRecipientComponentInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  outOp?: Maybe<BudgetComponentOpUpdateOneWithoutSourceComponentInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetComponentUpdateWithoutInOpsInput = {
  budgetCategories?: Maybe<BudgetCategoryUpdateManyWithoutBudgetComponentInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutBudgetComponentInput>;
  budgetComponentType?: Maybe<EnumBudgetComponentTypeFieldUpdateOperationsInput>;
  chartOfAccountsId?: Maybe<StringFieldUpdateOperationsInput>;
  clientId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  outOp?: Maybe<BudgetComponentOpUpdateOneWithoutSourceComponentInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetComponentUpdateWithoutOutOpInput = {
  budgetCategories?: Maybe<BudgetCategoryUpdateManyWithoutBudgetComponentInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutBudgetComponentInput>;
  budgetComponentType?: Maybe<EnumBudgetComponentTypeFieldUpdateOperationsInput>;
  chartOfAccountsId?: Maybe<StringFieldUpdateOperationsInput>;
  clientId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  inOps?: Maybe<BudgetComponentOpUpdateManyWithoutRecipientComponentInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetComponentUpsertWithoutBudgetCategoriesInput = {
  create: BudgetComponentCreateWithoutBudgetCategoriesInput;
  update: BudgetComponentUpdateWithoutBudgetCategoriesInput;
};

export type BudgetComponentUpsertWithoutBudgetComponentTotalValuesInput = {
  create: BudgetComponentCreateWithoutBudgetComponentTotalValuesInput;
  update: BudgetComponentUpdateWithoutBudgetComponentTotalValuesInput;
};

export type BudgetComponentUpsertWithoutInOpsInput = {
  create: BudgetComponentCreateWithoutInOpsInput;
  update: BudgetComponentUpdateWithoutInOpsInput;
};

export type BudgetComponentUpsertWithoutOutOpInput = {
  create: BudgetComponentCreateWithoutOutOpInput;
  update: BudgetComponentUpdateWithoutOutOpInput;
};

export type BudgetComponentV2 = {
  __typename?: 'BudgetComponentV2';
  budgetComponentType: BudgetComponentType;
  chartOfAccountsId: Scalars['String'];
  clientId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type BudgetComponentV2ClientIdChartOfAccountsIdNameCompoundUniqueInput = {
  chartOfAccountsId: Scalars['String'];
  clientId: Scalars['String'];
  name: Scalars['String'];
};

export type BudgetComponentV2CreateManyChartOfAccountsInput = {
  budgetComponentType?: Maybe<BudgetComponentType>;
  clientId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetComponentV2CreateManyChartOfAccountsInputEnvelope = {
  data: Array<BudgetComponentV2CreateManyChartOfAccountsInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetComponentV2CreateManyClientInput = {
  budgetComponentType?: Maybe<BudgetComponentType>;
  chartOfAccountsId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetComponentV2CreateManyClientInputEnvelope = {
  data: Array<BudgetComponentV2CreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetComponentV2CreateNestedManyWithoutChartOfAccountsInput = {
  connect?: Maybe<Array<BudgetComponentV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentV2CreateOrConnectWithoutChartOfAccountsInput>>;
  create?: Maybe<Array<BudgetComponentV2CreateWithoutChartOfAccountsInput>>;
  createMany?: Maybe<BudgetComponentV2CreateManyChartOfAccountsInputEnvelope>;
};

export type BudgetComponentV2CreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<BudgetComponentV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentV2CreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<BudgetComponentV2CreateWithoutClientInput>>;
  createMany?: Maybe<BudgetComponentV2CreateManyClientInputEnvelope>;
};

export type BudgetComponentV2CreateNestedOneWithoutAccountVarianceNotesInput = {
  connect?: Maybe<BudgetComponentV2WhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetComponentV2CreateOrConnectWithoutAccountVarianceNotesInput>;
  create?: Maybe<BudgetComponentV2CreateWithoutAccountVarianceNotesInput>;
};

export type BudgetComponentV2CreateNestedOneWithoutBudgetComponentMappingSourcesInput = {
  connect?: Maybe<BudgetComponentV2WhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetComponentV2CreateOrConnectWithoutBudgetComponentMappingSourcesInput>;
  create?: Maybe<BudgetComponentV2CreateWithoutBudgetComponentMappingSourcesInput>;
};

export type BudgetComponentV2CreateNestedOneWithoutBudgetComponentMappingsInput = {
  connect?: Maybe<BudgetComponentV2WhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetComponentV2CreateOrConnectWithoutBudgetComponentMappingsInput>;
  create?: Maybe<BudgetComponentV2CreateWithoutBudgetComponentMappingsInput>;
};

export type BudgetComponentV2CreateNestedOneWithoutBudgetComponentValuesInput = {
  connect?: Maybe<BudgetComponentV2WhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetComponentV2CreateOrConnectWithoutBudgetComponentValuesInput>;
  create?: Maybe<BudgetComponentV2CreateWithoutBudgetComponentValuesInput>;
};

export type BudgetComponentV2CreateNestedOneWithoutReportTableBudgetComponentsInput = {
  connect?: Maybe<BudgetComponentV2WhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetComponentV2CreateOrConnectWithoutReportTableBudgetComponentsInput>;
  create?: Maybe<BudgetComponentV2CreateWithoutReportTableBudgetComponentsInput>;
};

export type BudgetComponentV2CreateOrConnectWithoutAccountVarianceNotesInput = {
  create: BudgetComponentV2CreateWithoutAccountVarianceNotesInput;
  where: BudgetComponentV2WhereUniqueInput;
};

export type BudgetComponentV2CreateOrConnectWithoutBudgetComponentMappingSourcesInput = {
  create: BudgetComponentV2CreateWithoutBudgetComponentMappingSourcesInput;
  where: BudgetComponentV2WhereUniqueInput;
};

export type BudgetComponentV2CreateOrConnectWithoutBudgetComponentMappingsInput = {
  create: BudgetComponentV2CreateWithoutBudgetComponentMappingsInput;
  where: BudgetComponentV2WhereUniqueInput;
};

export type BudgetComponentV2CreateOrConnectWithoutBudgetComponentValuesInput = {
  create: BudgetComponentV2CreateWithoutBudgetComponentValuesInput;
  where: BudgetComponentV2WhereUniqueInput;
};

export type BudgetComponentV2CreateOrConnectWithoutChartOfAccountsInput = {
  create: BudgetComponentV2CreateWithoutChartOfAccountsInput;
  where: BudgetComponentV2WhereUniqueInput;
};

export type BudgetComponentV2CreateOrConnectWithoutClientInput = {
  create: BudgetComponentV2CreateWithoutClientInput;
  where: BudgetComponentV2WhereUniqueInput;
};

export type BudgetComponentV2CreateOrConnectWithoutReportTableBudgetComponentsInput = {
  create: BudgetComponentV2CreateWithoutReportTableBudgetComponentsInput;
  where: BudgetComponentV2WhereUniqueInput;
};

export type BudgetComponentV2CreateWithoutAccountVarianceNotesInput = {
  BudgetComponentsTopCardTags?: Maybe<BudgetComponentsTopCardTagsCreateNestedManyWithoutBudgetComponentInput>;
  budgetComponentMappingSources?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceComponentInput>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutComponentInput>;
  budgetComponentType?: Maybe<BudgetComponentType>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutBudgetComponentV2Input>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutBudgetComponentsInput;
  client: ClientCreateNestedOneWithoutBudgetComponentInput;
  clientComments?: Maybe<ClientCommentBudgetComponentCreateNestedManyWithoutBudgetComponentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  reportTableBudgetComponents?: Maybe<ReportTableBudgetComponentCreateNestedManyWithoutBudgetComponentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetComponentV2CreateWithoutBudgetComponentMappingSourcesInput = {
  BudgetComponentsTopCardTags?: Maybe<BudgetComponentsTopCardTagsCreateNestedManyWithoutBudgetComponentInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutComponentInput>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutComponentInput>;
  budgetComponentType?: Maybe<BudgetComponentType>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutBudgetComponentV2Input>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutBudgetComponentsInput;
  client: ClientCreateNestedOneWithoutBudgetComponentInput;
  clientComments?: Maybe<ClientCommentBudgetComponentCreateNestedManyWithoutBudgetComponentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  reportTableBudgetComponents?: Maybe<ReportTableBudgetComponentCreateNestedManyWithoutBudgetComponentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetComponentV2CreateWithoutBudgetComponentMappingsInput = {
  BudgetComponentsTopCardTags?: Maybe<BudgetComponentsTopCardTagsCreateNestedManyWithoutBudgetComponentInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutComponentInput>;
  budgetComponentMappingSources?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceComponentInput>;
  budgetComponentType?: Maybe<BudgetComponentType>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutBudgetComponentV2Input>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutBudgetComponentsInput;
  client: ClientCreateNestedOneWithoutBudgetComponentInput;
  clientComments?: Maybe<ClientCommentBudgetComponentCreateNestedManyWithoutBudgetComponentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  reportTableBudgetComponents?: Maybe<ReportTableBudgetComponentCreateNestedManyWithoutBudgetComponentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetComponentV2CreateWithoutBudgetComponentValuesInput = {
  BudgetComponentsTopCardTags?: Maybe<BudgetComponentsTopCardTagsCreateNestedManyWithoutBudgetComponentInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutComponentInput>;
  budgetComponentMappingSources?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceComponentInput>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutComponentInput>;
  budgetComponentType?: Maybe<BudgetComponentType>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutBudgetComponentsInput;
  client: ClientCreateNestedOneWithoutBudgetComponentInput;
  clientComments?: Maybe<ClientCommentBudgetComponentCreateNestedManyWithoutBudgetComponentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  reportTableBudgetComponents?: Maybe<ReportTableBudgetComponentCreateNestedManyWithoutBudgetComponentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetComponentV2CreateWithoutChartOfAccountsInput = {
  BudgetComponentsTopCardTags?: Maybe<BudgetComponentsTopCardTagsCreateNestedManyWithoutBudgetComponentInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutComponentInput>;
  budgetComponentMappingSources?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceComponentInput>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutComponentInput>;
  budgetComponentType?: Maybe<BudgetComponentType>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutBudgetComponentV2Input>;
  client: ClientCreateNestedOneWithoutBudgetComponentInput;
  clientComments?: Maybe<ClientCommentBudgetComponentCreateNestedManyWithoutBudgetComponentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  reportTableBudgetComponents?: Maybe<ReportTableBudgetComponentCreateNestedManyWithoutBudgetComponentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetComponentV2CreateWithoutClientInput = {
  BudgetComponentsTopCardTags?: Maybe<BudgetComponentsTopCardTagsCreateNestedManyWithoutBudgetComponentInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutComponentInput>;
  budgetComponentMappingSources?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceComponentInput>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutComponentInput>;
  budgetComponentType?: Maybe<BudgetComponentType>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutBudgetComponentV2Input>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutBudgetComponentsInput;
  clientComments?: Maybe<ClientCommentBudgetComponentCreateNestedManyWithoutBudgetComponentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  reportTableBudgetComponents?: Maybe<ReportTableBudgetComponentCreateNestedManyWithoutBudgetComponentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetComponentV2CreateWithoutReportTableBudgetComponentsInput = {
  BudgetComponentsTopCardTags?: Maybe<BudgetComponentsTopCardTagsCreateNestedManyWithoutBudgetComponentInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutComponentInput>;
  budgetComponentMappingSources?: Maybe<BudgetComponentMappingCreateNestedManyWithoutSourceComponentInput>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingCreateNestedManyWithoutComponentInput>;
  budgetComponentType?: Maybe<BudgetComponentType>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutBudgetComponentV2Input>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutBudgetComponentsInput;
  client: ClientCreateNestedOneWithoutBudgetComponentInput;
  clientComments?: Maybe<ClientCommentBudgetComponentCreateNestedManyWithoutBudgetComponentInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetComponentV2ListRelationFilter = {
  every?: Maybe<BudgetComponentV2WhereInput>;
  none?: Maybe<BudgetComponentV2WhereInput>;
  some?: Maybe<BudgetComponentV2WhereInput>;
};

export type BudgetComponentV2OrderByInput = {
  budgetComponentType?: Maybe<SortOrder>;
  chartOfAccountsId?: Maybe<SortOrder>;
  clientId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BudgetComponentV2RelationFilter = {
  is?: Maybe<BudgetComponentV2WhereInput>;
  isNot?: Maybe<BudgetComponentV2WhereInput>;
};

export enum BudgetComponentV2ScalarFieldEnum {
  BudgetComponentType = 'budgetComponentType',
  ChartOfAccountsId = 'chartOfAccountsId',
  ClientId = 'clientId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Order = 'order',
  UpdatedAt = 'updatedAt'
}

export type BudgetComponentV2ScalarWhereInput = {
  AND?: Maybe<Array<BudgetComponentV2ScalarWhereInput>>;
  NOT?: Maybe<Array<BudgetComponentV2ScalarWhereInput>>;
  OR?: Maybe<Array<BudgetComponentV2ScalarWhereInput>>;
  budgetComponentType?: Maybe<EnumBudgetComponentTypeFilter>;
  chartOfAccountsId?: Maybe<StringFilter>;
  clientId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BudgetComponentV2UpdateManyMutationInput = {
  budgetComponentType?: Maybe<EnumBudgetComponentTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetComponentV2UpdateManyWithWhereWithoutChartOfAccountsInput = {
  data: BudgetComponentV2UpdateManyMutationInput;
  where: BudgetComponentV2ScalarWhereInput;
};

export type BudgetComponentV2UpdateManyWithWhereWithoutClientInput = {
  data: BudgetComponentV2UpdateManyMutationInput;
  where: BudgetComponentV2ScalarWhereInput;
};

export type BudgetComponentV2UpdateManyWithoutChartOfAccountsInput = {
  connect?: Maybe<Array<BudgetComponentV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentV2CreateOrConnectWithoutChartOfAccountsInput>>;
  create?: Maybe<Array<BudgetComponentV2CreateWithoutChartOfAccountsInput>>;
  createMany?: Maybe<BudgetComponentV2CreateManyChartOfAccountsInputEnvelope>;
  delete?: Maybe<Array<BudgetComponentV2WhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetComponentV2ScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetComponentV2WhereUniqueInput>>;
  set?: Maybe<Array<BudgetComponentV2WhereUniqueInput>>;
  update?: Maybe<Array<BudgetComponentV2UpdateWithWhereUniqueWithoutChartOfAccountsInput>>;
  updateMany?: Maybe<Array<BudgetComponentV2UpdateManyWithWhereWithoutChartOfAccountsInput>>;
  upsert?: Maybe<Array<BudgetComponentV2UpsertWithWhereUniqueWithoutChartOfAccountsInput>>;
};

export type BudgetComponentV2UpdateManyWithoutClientInput = {
  connect?: Maybe<Array<BudgetComponentV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentV2CreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<BudgetComponentV2CreateWithoutClientInput>>;
  createMany?: Maybe<BudgetComponentV2CreateManyClientInputEnvelope>;
  delete?: Maybe<Array<BudgetComponentV2WhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetComponentV2ScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetComponentV2WhereUniqueInput>>;
  set?: Maybe<Array<BudgetComponentV2WhereUniqueInput>>;
  update?: Maybe<Array<BudgetComponentV2UpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<BudgetComponentV2UpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<BudgetComponentV2UpsertWithWhereUniqueWithoutClientInput>>;
};

export type BudgetComponentV2UpdateOneRequiredWithoutBudgetComponentMappingsInput = {
  connect?: Maybe<BudgetComponentV2WhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetComponentV2CreateOrConnectWithoutBudgetComponentMappingsInput>;
  create?: Maybe<BudgetComponentV2CreateWithoutBudgetComponentMappingsInput>;
  update?: Maybe<BudgetComponentV2UpdateWithoutBudgetComponentMappingsInput>;
  upsert?: Maybe<BudgetComponentV2UpsertWithoutBudgetComponentMappingsInput>;
};

export type BudgetComponentV2UpdateOneRequiredWithoutReportTableBudgetComponentsInput = {
  connect?: Maybe<BudgetComponentV2WhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetComponentV2CreateOrConnectWithoutReportTableBudgetComponentsInput>;
  create?: Maybe<BudgetComponentV2CreateWithoutReportTableBudgetComponentsInput>;
  update?: Maybe<BudgetComponentV2UpdateWithoutReportTableBudgetComponentsInput>;
  upsert?: Maybe<BudgetComponentV2UpsertWithoutReportTableBudgetComponentsInput>;
};

export type BudgetComponentV2UpdateOneWithoutAccountVarianceNotesInput = {
  connect?: Maybe<BudgetComponentV2WhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetComponentV2CreateOrConnectWithoutAccountVarianceNotesInput>;
  create?: Maybe<BudgetComponentV2CreateWithoutAccountVarianceNotesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BudgetComponentV2UpdateWithoutAccountVarianceNotesInput>;
  upsert?: Maybe<BudgetComponentV2UpsertWithoutAccountVarianceNotesInput>;
};

export type BudgetComponentV2UpdateOneWithoutBudgetComponentMappingSourcesInput = {
  connect?: Maybe<BudgetComponentV2WhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetComponentV2CreateOrConnectWithoutBudgetComponentMappingSourcesInput>;
  create?: Maybe<BudgetComponentV2CreateWithoutBudgetComponentMappingSourcesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BudgetComponentV2UpdateWithoutBudgetComponentMappingSourcesInput>;
  upsert?: Maybe<BudgetComponentV2UpsertWithoutBudgetComponentMappingSourcesInput>;
};

export type BudgetComponentV2UpdateOneWithoutBudgetComponentValuesInput = {
  connect?: Maybe<BudgetComponentV2WhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetComponentV2CreateOrConnectWithoutBudgetComponentValuesInput>;
  create?: Maybe<BudgetComponentV2CreateWithoutBudgetComponentValuesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BudgetComponentV2UpdateWithoutBudgetComponentValuesInput>;
  upsert?: Maybe<BudgetComponentV2UpsertWithoutBudgetComponentValuesInput>;
};

export type BudgetComponentV2UpdateWithWhereUniqueWithoutChartOfAccountsInput = {
  data: BudgetComponentV2UpdateWithoutChartOfAccountsInput;
  where: BudgetComponentV2WhereUniqueInput;
};

export type BudgetComponentV2UpdateWithWhereUniqueWithoutClientInput = {
  data: BudgetComponentV2UpdateWithoutClientInput;
  where: BudgetComponentV2WhereUniqueInput;
};

export type BudgetComponentV2UpdateWithoutAccountVarianceNotesInput = {
  BudgetComponentsTopCardTags?: Maybe<BudgetComponentsTopCardTagsUpdateManyWithoutBudgetComponentInput>;
  budgetComponentMappingSources?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceComponentInput>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutComponentInput>;
  budgetComponentType?: Maybe<EnumBudgetComponentTypeFieldUpdateOperationsInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutBudgetComponentV2Input>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutBudgetComponentsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutBudgetComponentInput>;
  clientComments?: Maybe<ClientCommentBudgetComponentUpdateManyWithoutBudgetComponentInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  reportTableBudgetComponents?: Maybe<ReportTableBudgetComponentUpdateManyWithoutBudgetComponentInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetComponentV2UpdateWithoutBudgetComponentMappingSourcesInput = {
  BudgetComponentsTopCardTags?: Maybe<BudgetComponentsTopCardTagsUpdateManyWithoutBudgetComponentInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutComponentInput>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutComponentInput>;
  budgetComponentType?: Maybe<EnumBudgetComponentTypeFieldUpdateOperationsInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutBudgetComponentV2Input>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutBudgetComponentsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutBudgetComponentInput>;
  clientComments?: Maybe<ClientCommentBudgetComponentUpdateManyWithoutBudgetComponentInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  reportTableBudgetComponents?: Maybe<ReportTableBudgetComponentUpdateManyWithoutBudgetComponentInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetComponentV2UpdateWithoutBudgetComponentMappingsInput = {
  BudgetComponentsTopCardTags?: Maybe<BudgetComponentsTopCardTagsUpdateManyWithoutBudgetComponentInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutComponentInput>;
  budgetComponentMappingSources?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceComponentInput>;
  budgetComponentType?: Maybe<EnumBudgetComponentTypeFieldUpdateOperationsInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutBudgetComponentV2Input>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutBudgetComponentsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutBudgetComponentInput>;
  clientComments?: Maybe<ClientCommentBudgetComponentUpdateManyWithoutBudgetComponentInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  reportTableBudgetComponents?: Maybe<ReportTableBudgetComponentUpdateManyWithoutBudgetComponentInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetComponentV2UpdateWithoutBudgetComponentValuesInput = {
  BudgetComponentsTopCardTags?: Maybe<BudgetComponentsTopCardTagsUpdateManyWithoutBudgetComponentInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutComponentInput>;
  budgetComponentMappingSources?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceComponentInput>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutComponentInput>;
  budgetComponentType?: Maybe<EnumBudgetComponentTypeFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutBudgetComponentsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutBudgetComponentInput>;
  clientComments?: Maybe<ClientCommentBudgetComponentUpdateManyWithoutBudgetComponentInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  reportTableBudgetComponents?: Maybe<ReportTableBudgetComponentUpdateManyWithoutBudgetComponentInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetComponentV2UpdateWithoutChartOfAccountsInput = {
  BudgetComponentsTopCardTags?: Maybe<BudgetComponentsTopCardTagsUpdateManyWithoutBudgetComponentInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutComponentInput>;
  budgetComponentMappingSources?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceComponentInput>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutComponentInput>;
  budgetComponentType?: Maybe<EnumBudgetComponentTypeFieldUpdateOperationsInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutBudgetComponentV2Input>;
  client?: Maybe<ClientUpdateOneRequiredWithoutBudgetComponentInput>;
  clientComments?: Maybe<ClientCommentBudgetComponentUpdateManyWithoutBudgetComponentInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  reportTableBudgetComponents?: Maybe<ReportTableBudgetComponentUpdateManyWithoutBudgetComponentInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetComponentV2UpdateWithoutClientInput = {
  BudgetComponentsTopCardTags?: Maybe<BudgetComponentsTopCardTagsUpdateManyWithoutBudgetComponentInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutComponentInput>;
  budgetComponentMappingSources?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceComponentInput>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutComponentInput>;
  budgetComponentType?: Maybe<EnumBudgetComponentTypeFieldUpdateOperationsInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutBudgetComponentV2Input>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutBudgetComponentsInput>;
  clientComments?: Maybe<ClientCommentBudgetComponentUpdateManyWithoutBudgetComponentInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  reportTableBudgetComponents?: Maybe<ReportTableBudgetComponentUpdateManyWithoutBudgetComponentInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetComponentV2UpdateWithoutReportTableBudgetComponentsInput = {
  BudgetComponentsTopCardTags?: Maybe<BudgetComponentsTopCardTagsUpdateManyWithoutBudgetComponentInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutComponentInput>;
  budgetComponentMappingSources?: Maybe<BudgetComponentMappingUpdateManyWithoutSourceComponentInput>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingUpdateManyWithoutComponentInput>;
  budgetComponentType?: Maybe<EnumBudgetComponentTypeFieldUpdateOperationsInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutBudgetComponentV2Input>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutBudgetComponentsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutBudgetComponentInput>;
  clientComments?: Maybe<ClientCommentBudgetComponentUpdateManyWithoutBudgetComponentInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetComponentV2UpsertWithWhereUniqueWithoutChartOfAccountsInput = {
  create: BudgetComponentV2CreateWithoutChartOfAccountsInput;
  update: BudgetComponentV2UpdateWithoutChartOfAccountsInput;
  where: BudgetComponentV2WhereUniqueInput;
};

export type BudgetComponentV2UpsertWithWhereUniqueWithoutClientInput = {
  create: BudgetComponentV2CreateWithoutClientInput;
  update: BudgetComponentV2UpdateWithoutClientInput;
  where: BudgetComponentV2WhereUniqueInput;
};

export type BudgetComponentV2UpsertWithoutAccountVarianceNotesInput = {
  create: BudgetComponentV2CreateWithoutAccountVarianceNotesInput;
  update: BudgetComponentV2UpdateWithoutAccountVarianceNotesInput;
};

export type BudgetComponentV2UpsertWithoutBudgetComponentMappingSourcesInput = {
  create: BudgetComponentV2CreateWithoutBudgetComponentMappingSourcesInput;
  update: BudgetComponentV2UpdateWithoutBudgetComponentMappingSourcesInput;
};

export type BudgetComponentV2UpsertWithoutBudgetComponentMappingsInput = {
  create: BudgetComponentV2CreateWithoutBudgetComponentMappingsInput;
  update: BudgetComponentV2UpdateWithoutBudgetComponentMappingsInput;
};

export type BudgetComponentV2UpsertWithoutBudgetComponentValuesInput = {
  create: BudgetComponentV2CreateWithoutBudgetComponentValuesInput;
  update: BudgetComponentV2UpdateWithoutBudgetComponentValuesInput;
};

export type BudgetComponentV2UpsertWithoutReportTableBudgetComponentsInput = {
  create: BudgetComponentV2CreateWithoutReportTableBudgetComponentsInput;
  update: BudgetComponentV2UpdateWithoutReportTableBudgetComponentsInput;
};

export type BudgetComponentV2WhereInput = {
  AND?: Maybe<Array<BudgetComponentV2WhereInput>>;
  BudgetComponentsTopCardTags?: Maybe<BudgetComponentsTopCardTagsListRelationFilter>;
  NOT?: Maybe<Array<BudgetComponentV2WhereInput>>;
  OR?: Maybe<Array<BudgetComponentV2WhereInput>>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteListRelationFilter>;
  budgetComponentMappingSources?: Maybe<BudgetComponentMappingListRelationFilter>;
  budgetComponentMappings?: Maybe<BudgetComponentMappingListRelationFilter>;
  budgetComponentType?: Maybe<EnumBudgetComponentTypeFilter>;
  budgetComponentValues?: Maybe<BudgetComponentValueListRelationFilter>;
  chartOfAccounts?: Maybe<ChartOfAccountsRelationFilter>;
  chartOfAccountsId?: Maybe<StringFilter>;
  client?: Maybe<ClientRelationFilter>;
  clientComments?: Maybe<ClientCommentBudgetComponentListRelationFilter>;
  clientId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
  reportTableBudgetComponents?: Maybe<ReportTableBudgetComponentListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BudgetComponentV2WhereUniqueInput = {
  clientId_chartOfAccountsId_name?: Maybe<BudgetComponentV2ClientIdChartOfAccountsIdNameCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type BudgetComponentValue = {
  __typename?: 'BudgetComponentValue';
  budgetComponentId: Scalars['String'];
  budgetComponentV2Id?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['Decimal'];
  versionId: Scalars['String'];
};

export type BudgetComponentValueBudgetComponentIdBudgetComponentV2IdVersionIdMonthIndexPropertyIdCompoundUniqueInput = {
  budgetComponentId: Scalars['String'];
  budgetComponentV2Id: Scalars['String'];
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  versionId: Scalars['String'];
};

export type BudgetComponentValueCreateManyBudgetComponentV2Input = {
  budgetComponentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
  versionId: Scalars['String'];
};

export type BudgetComponentValueCreateManyBudgetComponentV2InputEnvelope = {
  data: Array<BudgetComponentValueCreateManyBudgetComponentV2Input>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetComponentValueCreateManyPropertyInput = {
  budgetComponentId: Scalars['String'];
  budgetComponentV2Id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
  versionId: Scalars['String'];
};

export type BudgetComponentValueCreateManyPropertyInputEnvelope = {
  data: Array<BudgetComponentValueCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetComponentValueCreateManyVersionInput = {
  budgetComponentId: Scalars['String'];
  budgetComponentV2Id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
};

export type BudgetComponentValueCreateManyVersionInputEnvelope = {
  data: Array<BudgetComponentValueCreateManyVersionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetComponentValueCreateNestedManyWithoutBudgetComponentV2Input = {
  connect?: Maybe<Array<BudgetComponentValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentValueCreateOrConnectWithoutBudgetComponentV2Input>>;
  create?: Maybe<Array<BudgetComponentValueCreateWithoutBudgetComponentV2Input>>;
  createMany?: Maybe<BudgetComponentValueCreateManyBudgetComponentV2InputEnvelope>;
};

export type BudgetComponentValueCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<BudgetComponentValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentValueCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<BudgetComponentValueCreateWithoutPropertyInput>>;
  createMany?: Maybe<BudgetComponentValueCreateManyPropertyInputEnvelope>;
};

export type BudgetComponentValueCreateNestedManyWithoutVersionInput = {
  connect?: Maybe<Array<BudgetComponentValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentValueCreateOrConnectWithoutVersionInput>>;
  create?: Maybe<Array<BudgetComponentValueCreateWithoutVersionInput>>;
  createMany?: Maybe<BudgetComponentValueCreateManyVersionInputEnvelope>;
};

export type BudgetComponentValueCreateOrConnectWithoutBudgetComponentV2Input = {
  create: BudgetComponentValueCreateWithoutBudgetComponentV2Input;
  where: BudgetComponentValueWhereUniqueInput;
};

export type BudgetComponentValueCreateOrConnectWithoutPropertyInput = {
  create: BudgetComponentValueCreateWithoutPropertyInput;
  where: BudgetComponentValueWhereUniqueInput;
};

export type BudgetComponentValueCreateOrConnectWithoutVersionInput = {
  create: BudgetComponentValueCreateWithoutVersionInput;
  where: BudgetComponentValueWhereUniqueInput;
};

export type BudgetComponentValueCreateWithoutBudgetComponentV2Input = {
  budgetComponentId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  property: PropertyCreateNestedOneWithoutBudgetComponentValuesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
  version: ValueVersionCreateNestedOneWithoutBudgetComponentValuesInput;
};

export type BudgetComponentValueCreateWithoutPropertyInput = {
  budgetComponentId: Scalars['String'];
  budgetComponentV2?: Maybe<BudgetComponentV2CreateNestedOneWithoutBudgetComponentValuesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
  version: ValueVersionCreateNestedOneWithoutBudgetComponentValuesInput;
};

export type BudgetComponentValueCreateWithoutVersionInput = {
  budgetComponentId: Scalars['String'];
  budgetComponentV2?: Maybe<BudgetComponentV2CreateNestedOneWithoutBudgetComponentValuesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  property: PropertyCreateNestedOneWithoutBudgetComponentValuesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
};

export type BudgetComponentValueListRelationFilter = {
  every?: Maybe<BudgetComponentValueWhereInput>;
  none?: Maybe<BudgetComponentValueWhereInput>;
  some?: Maybe<BudgetComponentValueWhereInput>;
};

export type BudgetComponentValueOrderByInput = {
  budgetComponentId?: Maybe<SortOrder>;
  budgetComponentV2Id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  monthIndex?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  versionId?: Maybe<SortOrder>;
};

export enum BudgetComponentValueScalarFieldEnum {
  BudgetComponentId = 'budgetComponentId',
  BudgetComponentV2Id = 'budgetComponentV2Id',
  CreatedAt = 'createdAt',
  Id = 'id',
  MonthIndex = 'monthIndex',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt',
  Value = 'value',
  VersionId = 'versionId'
}

export type BudgetComponentValueScalarWhereInput = {
  AND?: Maybe<Array<BudgetComponentValueScalarWhereInput>>;
  NOT?: Maybe<Array<BudgetComponentValueScalarWhereInput>>;
  OR?: Maybe<Array<BudgetComponentValueScalarWhereInput>>;
  budgetComponentId?: Maybe<StringFilter>;
  budgetComponentV2Id?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  monthIndex?: Maybe<IntFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<DecimalFilter>;
  versionId?: Maybe<StringFilter>;
};

export type BudgetComponentValueUpdateManyMutationInput = {
  budgetComponentId?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<DecimalFieldUpdateOperationsInput>;
};

export type BudgetComponentValueUpdateManyWithWhereWithoutBudgetComponentV2Input = {
  data: BudgetComponentValueUpdateManyMutationInput;
  where: BudgetComponentValueScalarWhereInput;
};

export type BudgetComponentValueUpdateManyWithWhereWithoutPropertyInput = {
  data: BudgetComponentValueUpdateManyMutationInput;
  where: BudgetComponentValueScalarWhereInput;
};

export type BudgetComponentValueUpdateManyWithWhereWithoutVersionInput = {
  data: BudgetComponentValueUpdateManyMutationInput;
  where: BudgetComponentValueScalarWhereInput;
};

export type BudgetComponentValueUpdateManyWithoutBudgetComponentV2Input = {
  connect?: Maybe<Array<BudgetComponentValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentValueCreateOrConnectWithoutBudgetComponentV2Input>>;
  create?: Maybe<Array<BudgetComponentValueCreateWithoutBudgetComponentV2Input>>;
  createMany?: Maybe<BudgetComponentValueCreateManyBudgetComponentV2InputEnvelope>;
  delete?: Maybe<Array<BudgetComponentValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetComponentValueScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetComponentValueWhereUniqueInput>>;
  set?: Maybe<Array<BudgetComponentValueWhereUniqueInput>>;
  update?: Maybe<Array<BudgetComponentValueUpdateWithWhereUniqueWithoutBudgetComponentV2Input>>;
  updateMany?: Maybe<Array<BudgetComponentValueUpdateManyWithWhereWithoutBudgetComponentV2Input>>;
  upsert?: Maybe<Array<BudgetComponentValueUpsertWithWhereUniqueWithoutBudgetComponentV2Input>>;
};

export type BudgetComponentValueUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<BudgetComponentValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentValueCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<BudgetComponentValueCreateWithoutPropertyInput>>;
  createMany?: Maybe<BudgetComponentValueCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<BudgetComponentValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetComponentValueScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetComponentValueWhereUniqueInput>>;
  set?: Maybe<Array<BudgetComponentValueWhereUniqueInput>>;
  update?: Maybe<Array<BudgetComponentValueUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<BudgetComponentValueUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<BudgetComponentValueUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type BudgetComponentValueUpdateManyWithoutVersionInput = {
  connect?: Maybe<Array<BudgetComponentValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentValueCreateOrConnectWithoutVersionInput>>;
  create?: Maybe<Array<BudgetComponentValueCreateWithoutVersionInput>>;
  createMany?: Maybe<BudgetComponentValueCreateManyVersionInputEnvelope>;
  delete?: Maybe<Array<BudgetComponentValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetComponentValueScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetComponentValueWhereUniqueInput>>;
  set?: Maybe<Array<BudgetComponentValueWhereUniqueInput>>;
  update?: Maybe<Array<BudgetComponentValueUpdateWithWhereUniqueWithoutVersionInput>>;
  updateMany?: Maybe<Array<BudgetComponentValueUpdateManyWithWhereWithoutVersionInput>>;
  upsert?: Maybe<Array<BudgetComponentValueUpsertWithWhereUniqueWithoutVersionInput>>;
};

export type BudgetComponentValueUpdateWithWhereUniqueWithoutBudgetComponentV2Input = {
  data: BudgetComponentValueUpdateWithoutBudgetComponentV2Input;
  where: BudgetComponentValueWhereUniqueInput;
};

export type BudgetComponentValueUpdateWithWhereUniqueWithoutPropertyInput = {
  data: BudgetComponentValueUpdateWithoutPropertyInput;
  where: BudgetComponentValueWhereUniqueInput;
};

export type BudgetComponentValueUpdateWithWhereUniqueWithoutVersionInput = {
  data: BudgetComponentValueUpdateWithoutVersionInput;
  where: BudgetComponentValueWhereUniqueInput;
};

export type BudgetComponentValueUpdateWithoutBudgetComponentV2Input = {
  budgetComponentId?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutBudgetComponentValuesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<DecimalFieldUpdateOperationsInput>;
  version?: Maybe<ValueVersionUpdateOneRequiredWithoutBudgetComponentValuesInput>;
};

export type BudgetComponentValueUpdateWithoutPropertyInput = {
  budgetComponentId?: Maybe<StringFieldUpdateOperationsInput>;
  budgetComponentV2?: Maybe<BudgetComponentV2UpdateOneWithoutBudgetComponentValuesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<DecimalFieldUpdateOperationsInput>;
  version?: Maybe<ValueVersionUpdateOneRequiredWithoutBudgetComponentValuesInput>;
};

export type BudgetComponentValueUpdateWithoutVersionInput = {
  budgetComponentId?: Maybe<StringFieldUpdateOperationsInput>;
  budgetComponentV2?: Maybe<BudgetComponentV2UpdateOneWithoutBudgetComponentValuesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutBudgetComponentValuesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<DecimalFieldUpdateOperationsInput>;
};

export type BudgetComponentValueUpsertWithWhereUniqueWithoutBudgetComponentV2Input = {
  create: BudgetComponentValueCreateWithoutBudgetComponentV2Input;
  update: BudgetComponentValueUpdateWithoutBudgetComponentV2Input;
  where: BudgetComponentValueWhereUniqueInput;
};

export type BudgetComponentValueUpsertWithWhereUniqueWithoutPropertyInput = {
  create: BudgetComponentValueCreateWithoutPropertyInput;
  update: BudgetComponentValueUpdateWithoutPropertyInput;
  where: BudgetComponentValueWhereUniqueInput;
};

export type BudgetComponentValueUpsertWithWhereUniqueWithoutVersionInput = {
  create: BudgetComponentValueCreateWithoutVersionInput;
  update: BudgetComponentValueUpdateWithoutVersionInput;
  where: BudgetComponentValueWhereUniqueInput;
};

export type BudgetComponentValueWhereInput = {
  AND?: Maybe<Array<BudgetComponentValueWhereInput>>;
  NOT?: Maybe<Array<BudgetComponentValueWhereInput>>;
  OR?: Maybe<Array<BudgetComponentValueWhereInput>>;
  budgetComponentId?: Maybe<StringFilter>;
  budgetComponentV2?: Maybe<BudgetComponentV2RelationFilter>;
  budgetComponentV2Id?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  monthIndex?: Maybe<IntFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<DecimalFilter>;
  version?: Maybe<ValueVersionRelationFilter>;
  versionId?: Maybe<StringFilter>;
};

export type BudgetComponentValueWhereUniqueInput = {
  budgetComponentId_budgetComponentV2Id_versionId_monthIndex_propertyId?: Maybe<BudgetComponentValueBudgetComponentIdBudgetComponentV2IdVersionIdMonthIndexPropertyIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type BudgetComponentWhereInput = {
  AND?: Maybe<Array<BudgetComponentWhereInput>>;
  NOT?: Maybe<Array<BudgetComponentWhereInput>>;
  OR?: Maybe<Array<BudgetComponentWhereInput>>;
  budgetCategories?: Maybe<BudgetCategoryListRelationFilter>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueListRelationFilter>;
  budgetComponentType?: Maybe<EnumBudgetComponentTypeFilter>;
  chartOfAccountsId?: Maybe<StringFilter>;
  clientId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  inOps?: Maybe<BudgetComponentOpListRelationFilter>;
  name?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
  outOp?: Maybe<BudgetComponentOpRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BudgetComponentWhereUniqueInput = {
  chartOfAccountsId_name?: Maybe<BudgetComponentChartOfAccountsIdNameCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type BudgetComponentsTopCardTagsBudgetComponentIdTopCardTagTypeCompoundUniqueInput = {
  budgetComponentId: Scalars['String'];
  topCardTagType: TopCardTagType;
};

export type BudgetComponentsTopCardTagsCreateManyBudgetComponentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  topCardTagType: TopCardTagType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetComponentsTopCardTagsCreateManyBudgetComponentInputEnvelope = {
  data: Array<BudgetComponentsTopCardTagsCreateManyBudgetComponentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetComponentsTopCardTagsCreateNestedManyWithoutBudgetComponentInput = {
  connect?: Maybe<Array<BudgetComponentsTopCardTagsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentsTopCardTagsCreateOrConnectWithoutBudgetComponentInput>>;
  create?: Maybe<Array<BudgetComponentsTopCardTagsCreateWithoutBudgetComponentInput>>;
  createMany?: Maybe<BudgetComponentsTopCardTagsCreateManyBudgetComponentInputEnvelope>;
};

export type BudgetComponentsTopCardTagsCreateOrConnectWithoutBudgetComponentInput = {
  create: BudgetComponentsTopCardTagsCreateWithoutBudgetComponentInput;
  where: BudgetComponentsTopCardTagsWhereUniqueInput;
};

export type BudgetComponentsTopCardTagsCreateWithoutBudgetComponentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  topCardTagType: TopCardTagType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetComponentsTopCardTagsListRelationFilter = {
  every?: Maybe<BudgetComponentsTopCardTagsWhereInput>;
  none?: Maybe<BudgetComponentsTopCardTagsWhereInput>;
  some?: Maybe<BudgetComponentsTopCardTagsWhereInput>;
};

export type BudgetComponentsTopCardTagsScalarWhereInput = {
  AND?: Maybe<Array<BudgetComponentsTopCardTagsScalarWhereInput>>;
  NOT?: Maybe<Array<BudgetComponentsTopCardTagsScalarWhereInput>>;
  OR?: Maybe<Array<BudgetComponentsTopCardTagsScalarWhereInput>>;
  budgetComponentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  topCardTagType?: Maybe<EnumTopCardTagTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BudgetComponentsTopCardTagsUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  topCardTagType?: Maybe<EnumTopCardTagTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetComponentsTopCardTagsUpdateManyWithWhereWithoutBudgetComponentInput = {
  data: BudgetComponentsTopCardTagsUpdateManyMutationInput;
  where: BudgetComponentsTopCardTagsScalarWhereInput;
};

export type BudgetComponentsTopCardTagsUpdateManyWithoutBudgetComponentInput = {
  connect?: Maybe<Array<BudgetComponentsTopCardTagsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetComponentsTopCardTagsCreateOrConnectWithoutBudgetComponentInput>>;
  create?: Maybe<Array<BudgetComponentsTopCardTagsCreateWithoutBudgetComponentInput>>;
  createMany?: Maybe<BudgetComponentsTopCardTagsCreateManyBudgetComponentInputEnvelope>;
  delete?: Maybe<Array<BudgetComponentsTopCardTagsWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetComponentsTopCardTagsScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetComponentsTopCardTagsWhereUniqueInput>>;
  set?: Maybe<Array<BudgetComponentsTopCardTagsWhereUniqueInput>>;
  update?: Maybe<Array<BudgetComponentsTopCardTagsUpdateWithWhereUniqueWithoutBudgetComponentInput>>;
  updateMany?: Maybe<Array<BudgetComponentsTopCardTagsUpdateManyWithWhereWithoutBudgetComponentInput>>;
  upsert?: Maybe<Array<BudgetComponentsTopCardTagsUpsertWithWhereUniqueWithoutBudgetComponentInput>>;
};

export type BudgetComponentsTopCardTagsUpdateWithWhereUniqueWithoutBudgetComponentInput = {
  data: BudgetComponentsTopCardTagsUpdateWithoutBudgetComponentInput;
  where: BudgetComponentsTopCardTagsWhereUniqueInput;
};

export type BudgetComponentsTopCardTagsUpdateWithoutBudgetComponentInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  topCardTagType?: Maybe<EnumTopCardTagTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BudgetComponentsTopCardTagsUpsertWithWhereUniqueWithoutBudgetComponentInput = {
  create: BudgetComponentsTopCardTagsCreateWithoutBudgetComponentInput;
  update: BudgetComponentsTopCardTagsUpdateWithoutBudgetComponentInput;
  where: BudgetComponentsTopCardTagsWhereUniqueInput;
};

export type BudgetComponentsTopCardTagsWhereInput = {
  AND?: Maybe<Array<BudgetComponentsTopCardTagsWhereInput>>;
  NOT?: Maybe<Array<BudgetComponentsTopCardTagsWhereInput>>;
  OR?: Maybe<Array<BudgetComponentsTopCardTagsWhereInput>>;
  budgetComponent?: Maybe<BudgetComponentV2RelationFilter>;
  budgetComponentId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  topCardTagType?: Maybe<EnumTopCardTagTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BudgetComponentsTopCardTagsWhereUniqueInput = {
  budgetComponentId_topCardTagType?: Maybe<BudgetComponentsTopCardTagsBudgetComponentIdTopCardTagTypeCompoundUniqueInput>;
};

/**
 * Used to allow an API user to choose how they want to view stored numbers.
 * FISCAL - means that numbers are input/returned with month 0 mapped to the start month of the fiscal (budget) year.
 * CALENDAR - means that data should be returned with month 0 mapped to January and month 11 mapped to December (aka the calendar year).
 */
export enum BudgetMapping {
  Calendar = 'CALENDAR',
  Fiscal = 'FISCAL'
}

export type BudgetYearModel = {
  __typename?: 'BudgetYearModel';
  isAutoUnitTypeUpdateEnabled: Scalars['Boolean'];
  protected: Scalars['Boolean'];
  year: Scalars['Int'];
};


export type BudgetYearsListViewModel = {
  __typename?: 'BudgetYearsListViewModel';
  activeBudgetYear: Scalars['Int'];
  budgetYears: Array<BudgetYearModel>;
};

export type BulkExportReportsReturn = {
  __typename?: 'BulkExportReportsReturn';
  dateNoTimeString: Scalars['String'];
  fileName: Scalars['String'];
  lastModified: Scalars['DateTime'];
  path: Scalars['String'];
};

/** Specifies whether the report is for financials or operational metrics. */
export enum BulkReportType {
  Financials = 'FINANCIALS',
  OperationalMetrics = 'OPERATIONAL_METRICS'
}

export type BulkUnitEventInput = {
  concessionMonths?: Maybe<Scalars['Decimal']>;
  eventEnd: Scalars['DateTime'];
  eventStart: Scalars['DateTime'];
  eventType: UnitEventType;
  externalId: Scalars['String'];
  inPlaceRent?: Maybe<Scalars['Decimal']>;
  propertyName: Scalars['String'];
  renewalAdjustmentPercentage?: Maybe<Scalars['Decimal']>;
  renovationCost?: Maybe<Scalars['Decimal']>;
  renovationPremium?: Maybe<Scalars['Decimal']>;
  shortTermEffectiveAvgDailyRent?: Maybe<Scalars['Decimal']>;
  unitNumber: Scalars['String'];
  unitTypeName: Scalars['String'];
};

export type BulkUploadUnitEventResult = {
  __typename?: 'BulkUploadUnitEventResult';
  createdExternalIds: Array<Scalars['String']>;
  errors: Array<Scalars['String']>;
  skippedExternalIds: Array<Scalars['String']>;
};

export type CoaListRecord = {
  __typename?: 'COAListRecord';
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  /** A unique identifier for account/category/component. */
  id: Scalars['String'];
  negate: Scalars['Boolean'];
  order: Scalars['Int'];
  /** List of ids walking up the tree */
  parentIds: Array<Scalars['ID']>;
  type: FinancialEntityType;
};

export type CoaListRecordInput = {
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** List of ids walking up the tree */
  parentIds: Array<Scalars['ID']>;
  type: FinancialEntityType;
};

export type CoaListResponse = {
  __typename?: 'COAListResponse';
  /** List of Chart Of Accounts Records */
  records: Array<CoaListRecord>;
  /** Incremental version of Chart Of Accounts */
  versionNum: Scalars['Int'];
};

export type CalculationParticipantCreateNestedOneWithoutCalculationParticipantPropertiesInput = {
  connect?: Maybe<CalculationParticipantWhereUniqueInput>;
  connectOrCreate?: Maybe<CalculationParticipantCreateOrConnectWithoutCalculationParticipantPropertiesInput>;
  create?: Maybe<CalculationParticipantCreateWithoutCalculationParticipantPropertiesInput>;
};

export type CalculationParticipantCreateOrConnectWithoutCalculationParticipantPropertiesInput = {
  create: CalculationParticipantCreateWithoutCalculationParticipantPropertiesInput;
  where: CalculationParticipantWhereUniqueInput;
};

export type CalculationParticipantCreateWithoutCalculationParticipantPropertiesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CalculationParticipantProperty = {
  __typename?: 'CalculationParticipantProperty';
  calculationParticipantId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  propertyId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CalculationParticipantPropertyCreateManyPropertyInput = {
  calculationParticipantId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CalculationParticipantPropertyCreateManyPropertyInputEnvelope = {
  data: Array<CalculationParticipantPropertyCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<CalculationParticipantPropertyWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CalculationParticipantPropertyCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<CalculationParticipantPropertyCreateWithoutPropertyInput>>;
  createMany?: Maybe<CalculationParticipantPropertyCreateManyPropertyInputEnvelope>;
};

export type CalculationParticipantPropertyCreateOrConnectWithoutPropertyInput = {
  create: CalculationParticipantPropertyCreateWithoutPropertyInput;
  where: CalculationParticipantPropertyWhereUniqueInput;
};

export type CalculationParticipantPropertyCreateWithoutPropertyInput = {
  calculationParticipant: CalculationParticipantCreateNestedOneWithoutCalculationParticipantPropertiesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CalculationParticipantPropertyListRelationFilter = {
  every?: Maybe<CalculationParticipantPropertyWhereInput>;
  none?: Maybe<CalculationParticipantPropertyWhereInput>;
  some?: Maybe<CalculationParticipantPropertyWhereInput>;
};

export type CalculationParticipantPropertyOrderByInput = {
  calculationParticipantId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CalculationParticipantPropertyPropertyIdCalculationParticipantIdCompoundUniqueInput = {
  calculationParticipantId: Scalars['String'];
  propertyId: Scalars['String'];
};

export enum CalculationParticipantPropertyScalarFieldEnum {
  CalculationParticipantId = 'calculationParticipantId',
  CreatedAt = 'createdAt',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt'
}

export type CalculationParticipantPropertyScalarWhereInput = {
  AND?: Maybe<Array<CalculationParticipantPropertyScalarWhereInput>>;
  NOT?: Maybe<Array<CalculationParticipantPropertyScalarWhereInput>>;
  OR?: Maybe<Array<CalculationParticipantPropertyScalarWhereInput>>;
  calculationParticipantId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CalculationParticipantPropertyUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CalculationParticipantPropertyUpdateManyWithWhereWithoutPropertyInput = {
  data: CalculationParticipantPropertyUpdateManyMutationInput;
  where: CalculationParticipantPropertyScalarWhereInput;
};

export type CalculationParticipantPropertyUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<CalculationParticipantPropertyWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CalculationParticipantPropertyCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<CalculationParticipantPropertyCreateWithoutPropertyInput>>;
  createMany?: Maybe<CalculationParticipantPropertyCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<CalculationParticipantPropertyWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CalculationParticipantPropertyScalarWhereInput>>;
  disconnect?: Maybe<Array<CalculationParticipantPropertyWhereUniqueInput>>;
  set?: Maybe<Array<CalculationParticipantPropertyWhereUniqueInput>>;
  update?: Maybe<Array<CalculationParticipantPropertyUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<CalculationParticipantPropertyUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<CalculationParticipantPropertyUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type CalculationParticipantPropertyUpdateWithWhereUniqueWithoutPropertyInput = {
  data: CalculationParticipantPropertyUpdateWithoutPropertyInput;
  where: CalculationParticipantPropertyWhereUniqueInput;
};

export type CalculationParticipantPropertyUpdateWithoutPropertyInput = {
  calculationParticipant?: Maybe<CalculationParticipantUpdateOneRequiredWithoutCalculationParticipantPropertiesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CalculationParticipantPropertyUpsertWithWhereUniqueWithoutPropertyInput = {
  create: CalculationParticipantPropertyCreateWithoutPropertyInput;
  update: CalculationParticipantPropertyUpdateWithoutPropertyInput;
  where: CalculationParticipantPropertyWhereUniqueInput;
};

export type CalculationParticipantPropertyWhereInput = {
  AND?: Maybe<Array<CalculationParticipantPropertyWhereInput>>;
  NOT?: Maybe<Array<CalculationParticipantPropertyWhereInput>>;
  OR?: Maybe<Array<CalculationParticipantPropertyWhereInput>>;
  calculationParticipant?: Maybe<CalculationParticipantRelationFilter>;
  calculationParticipantId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CalculationParticipantPropertyWhereUniqueInput = {
  propertyId_calculationParticipantId?: Maybe<CalculationParticipantPropertyPropertyIdCalculationParticipantIdCompoundUniqueInput>;
};

export type CalculationParticipantRelationFilter = {
  is?: Maybe<CalculationParticipantWhereInput>;
  isNot?: Maybe<CalculationParticipantWhereInput>;
};

export type CalculationParticipantUpdateOneRequiredWithoutCalculationParticipantPropertiesInput = {
  connect?: Maybe<CalculationParticipantWhereUniqueInput>;
  connectOrCreate?: Maybe<CalculationParticipantCreateOrConnectWithoutCalculationParticipantPropertiesInput>;
  create?: Maybe<CalculationParticipantCreateWithoutCalculationParticipantPropertiesInput>;
  update?: Maybe<CalculationParticipantUpdateWithoutCalculationParticipantPropertiesInput>;
  upsert?: Maybe<CalculationParticipantUpsertWithoutCalculationParticipantPropertiesInput>;
};

export type CalculationParticipantUpdateWithoutCalculationParticipantPropertiesInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CalculationParticipantUpsertWithoutCalculationParticipantPropertiesInput = {
  create: CalculationParticipantCreateWithoutCalculationParticipantPropertiesInput;
  update: CalculationParticipantUpdateWithoutCalculationParticipantPropertiesInput;
};

export type CalculationParticipantWhereInput = {
  AND?: Maybe<Array<CalculationParticipantWhereInput>>;
  NOT?: Maybe<Array<CalculationParticipantWhereInput>>;
  OR?: Maybe<Array<CalculationParticipantWhereInput>>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CalculationParticipantWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChartOfAccounts = {
  __typename?: 'ChartOfAccounts';
  BudgetCategory: Array<BudgetCategory>;
  accountsV2: Array<AccountV2>;
  budgetComponents: Array<BudgetComponentV2>;
  budgetYear: Scalars['Int'];
  client: Client;
  clientId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  gprEntities: Array<GprEntity>;
  growthDrivers: Array<DriverGrowth>;
  id: Scalars['String'];
  models: Array<Model>;
  name: Scalars['String'];
  operationalFinancialMetricsAggregateId: Scalars['String'];
  operationalFinancialMetricsAggregateType: OperationalFinancialMetricsAggregateType;
  properties: Array<Property>;
  rentalRevenueCaptures: Array<RentalRevenueCapture>;
  updatedAt: Scalars['DateTime'];
  versionNum: Scalars['Int'];
  yardiChartOfAccountsMetadata: Array<YardiChartOfAccountsMetadata>;
};


export type ChartOfAccountsBudgetCategoryArgs = {
  cursor?: Maybe<BudgetCategoryWhereUniqueInput>;
  distinct?: Maybe<Array<BudgetCategoryScalarFieldEnum>>;
  orderBy?: Maybe<Array<BudgetCategoryOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BudgetCategoryWhereInput>;
};


export type ChartOfAccountsAccountsV2Args = {
  cursor?: Maybe<AccountV2WhereUniqueInput>;
  distinct?: Maybe<Array<AccountV2ScalarFieldEnum>>;
  orderBy?: Maybe<Array<AccountV2OrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AccountV2WhereInput>;
};


export type ChartOfAccountsBudgetComponentsArgs = {
  cursor?: Maybe<BudgetComponentV2WhereUniqueInput>;
  distinct?: Maybe<Array<BudgetComponentV2ScalarFieldEnum>>;
  orderBy?: Maybe<Array<BudgetComponentV2OrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BudgetComponentV2WhereInput>;
};


export type ChartOfAccountsGprEntitiesArgs = {
  cursor?: Maybe<GprEntityWhereUniqueInput>;
  distinct?: Maybe<Array<GprEntityScalarFieldEnum>>;
  orderBy?: Maybe<Array<GprEntityOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<GprEntityWhereInput>;
};


export type ChartOfAccountsGrowthDriversArgs = {
  cursor?: Maybe<DriverGrowthWhereUniqueInput>;
  distinct?: Maybe<Array<DriverGrowthScalarFieldEnum>>;
  orderBy?: Maybe<Array<DriverGrowthOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DriverGrowthWhereInput>;
};


export type ChartOfAccountsModelsArgs = {
  cursor?: Maybe<ModelWhereUniqueInput>;
  distinct?: Maybe<Array<ModelScalarFieldEnum>>;
  orderBy?: Maybe<Array<ModelOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ModelWhereInput>;
};


export type ChartOfAccountsPropertiesArgs = {
  cursor?: Maybe<PropertyWhereUniqueInput>;
  distinct?: Maybe<Array<PropertyScalarFieldEnum>>;
  orderBy?: Maybe<Array<PropertyOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PropertyWhereInput>;
};


export type ChartOfAccountsRentalRevenueCapturesArgs = {
  cursor?: Maybe<RentalRevenueCaptureWhereUniqueInput>;
  distinct?: Maybe<Array<RentalRevenueCaptureScalarFieldEnum>>;
  orderBy?: Maybe<Array<RentalRevenueCaptureOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<RentalRevenueCaptureWhereInput>;
};


export type ChartOfAccountsYardiChartOfAccountsMetadataArgs = {
  cursor?: Maybe<YardiChartOfAccountsMetadataWhereUniqueInput>;
  distinct?: Maybe<Array<YardiChartOfAccountsMetadataScalarFieldEnum>>;
  orderBy?: Maybe<Array<YardiChartOfAccountsMetadataOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<YardiChartOfAccountsMetadataWhereInput>;
};

export type ChartOfAccountsClientIdBudgetYearCompoundUniqueInput = {
  budgetYear: Scalars['Int'];
  clientId: Scalars['String'];
};

export type ChartOfAccountsCreateManyClientInput = {
  budgetYear: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  operationalFinancialMetricsAggregateId?: Maybe<Scalars['String']>;
  operationalFinancialMetricsAggregateType?: Maybe<OperationalFinancialMetricsAggregateType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionNum?: Maybe<Scalars['Int']>;
};

export type ChartOfAccountsCreateManyClientInputEnvelope = {
  data: Array<ChartOfAccountsCreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChartOfAccountsCreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<ChartOfAccountsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChartOfAccountsCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<ChartOfAccountsCreateWithoutClientInput>>;
  createMany?: Maybe<ChartOfAccountsCreateManyClientInputEnvelope>;
};

export type ChartOfAccountsCreateNestedOneWithoutAccountsV2Input = {
  connect?: Maybe<ChartOfAccountsWhereUniqueInput>;
  connectOrCreate?: Maybe<ChartOfAccountsCreateOrConnectWithoutAccountsV2Input>;
  create?: Maybe<ChartOfAccountsCreateWithoutAccountsV2Input>;
};

export type ChartOfAccountsCreateNestedOneWithoutBudgetCategoryInput = {
  connect?: Maybe<ChartOfAccountsWhereUniqueInput>;
  connectOrCreate?: Maybe<ChartOfAccountsCreateOrConnectWithoutBudgetCategoryInput>;
  create?: Maybe<ChartOfAccountsCreateWithoutBudgetCategoryInput>;
};

export type ChartOfAccountsCreateNestedOneWithoutBudgetComponentsInput = {
  connect?: Maybe<ChartOfAccountsWhereUniqueInput>;
  connectOrCreate?: Maybe<ChartOfAccountsCreateOrConnectWithoutBudgetComponentsInput>;
  create?: Maybe<ChartOfAccountsCreateWithoutBudgetComponentsInput>;
};

export type ChartOfAccountsCreateNestedOneWithoutGrowthDriversInput = {
  connect?: Maybe<ChartOfAccountsWhereUniqueInput>;
  connectOrCreate?: Maybe<ChartOfAccountsCreateOrConnectWithoutGrowthDriversInput>;
  create?: Maybe<ChartOfAccountsCreateWithoutGrowthDriversInput>;
};

export type ChartOfAccountsCreateNestedOneWithoutModelsInput = {
  connect?: Maybe<ChartOfAccountsWhereUniqueInput>;
  connectOrCreate?: Maybe<ChartOfAccountsCreateOrConnectWithoutModelsInput>;
  create?: Maybe<ChartOfAccountsCreateWithoutModelsInput>;
};

export type ChartOfAccountsCreateNestedOneWithoutPropertiesInput = {
  connect?: Maybe<ChartOfAccountsWhereUniqueInput>;
  connectOrCreate?: Maybe<ChartOfAccountsCreateOrConnectWithoutPropertiesInput>;
  create?: Maybe<ChartOfAccountsCreateWithoutPropertiesInput>;
};

export type ChartOfAccountsCreateNestedOneWithoutRentalRevenueCapturesInput = {
  connect?: Maybe<ChartOfAccountsWhereUniqueInput>;
  connectOrCreate?: Maybe<ChartOfAccountsCreateOrConnectWithoutRentalRevenueCapturesInput>;
  create?: Maybe<ChartOfAccountsCreateWithoutRentalRevenueCapturesInput>;
};

export type ChartOfAccountsCreateOrConnectWithoutAccountsV2Input = {
  create: ChartOfAccountsCreateWithoutAccountsV2Input;
  where: ChartOfAccountsWhereUniqueInput;
};

export type ChartOfAccountsCreateOrConnectWithoutBudgetCategoryInput = {
  create: ChartOfAccountsCreateWithoutBudgetCategoryInput;
  where: ChartOfAccountsWhereUniqueInput;
};

export type ChartOfAccountsCreateOrConnectWithoutBudgetComponentsInput = {
  create: ChartOfAccountsCreateWithoutBudgetComponentsInput;
  where: ChartOfAccountsWhereUniqueInput;
};

export type ChartOfAccountsCreateOrConnectWithoutClientInput = {
  create: ChartOfAccountsCreateWithoutClientInput;
  where: ChartOfAccountsWhereUniqueInput;
};

export type ChartOfAccountsCreateOrConnectWithoutGrowthDriversInput = {
  create: ChartOfAccountsCreateWithoutGrowthDriversInput;
  where: ChartOfAccountsWhereUniqueInput;
};

export type ChartOfAccountsCreateOrConnectWithoutModelsInput = {
  create: ChartOfAccountsCreateWithoutModelsInput;
  where: ChartOfAccountsWhereUniqueInput;
};

export type ChartOfAccountsCreateOrConnectWithoutPropertiesInput = {
  create: ChartOfAccountsCreateWithoutPropertiesInput;
  where: ChartOfAccountsWhereUniqueInput;
};

export type ChartOfAccountsCreateOrConnectWithoutRentalRevenueCapturesInput = {
  create: ChartOfAccountsCreateWithoutRentalRevenueCapturesInput;
  where: ChartOfAccountsWhereUniqueInput;
};

export type ChartOfAccountsCreateWithoutAccountsV2Input = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutChartOfAccountsInput>;
  budgetComponents?: Maybe<BudgetComponentV2CreateNestedManyWithoutChartOfAccountsInput>;
  budgetYear: Scalars['Int'];
  client: ClientCreateNestedOneWithoutChartOfAccountsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  gprEntities?: Maybe<GprEntityCreateNestedManyWithoutChartOfAccountsInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutChartOfaccountsInput>;
  id?: Maybe<Scalars['String']>;
  models?: Maybe<ModelCreateNestedManyWithoutChartOfAccountsInput>;
  name: Scalars['String'];
  operationalFinancialMetricsAggregateId?: Maybe<Scalars['String']>;
  operationalFinancialMetricsAggregateType?: Maybe<OperationalFinancialMetricsAggregateType>;
  properties?: Maybe<PropertyCreateNestedManyWithoutChartOfAccountsInput>;
  rentalRevenueCaptures?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutChartOfAccountsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionNum?: Maybe<Scalars['Int']>;
  yardiChartOfAccountsMetadata?: Maybe<YardiChartOfAccountsMetadataCreateNestedManyWithoutChartOfAccountsInput>;
};

export type ChartOfAccountsCreateWithoutBudgetCategoryInput = {
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutChartOfAccountsInput>;
  budgetComponents?: Maybe<BudgetComponentV2CreateNestedManyWithoutChartOfAccountsInput>;
  budgetYear: Scalars['Int'];
  client: ClientCreateNestedOneWithoutChartOfAccountsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  gprEntities?: Maybe<GprEntityCreateNestedManyWithoutChartOfAccountsInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutChartOfaccountsInput>;
  id?: Maybe<Scalars['String']>;
  models?: Maybe<ModelCreateNestedManyWithoutChartOfAccountsInput>;
  name: Scalars['String'];
  operationalFinancialMetricsAggregateId?: Maybe<Scalars['String']>;
  operationalFinancialMetricsAggregateType?: Maybe<OperationalFinancialMetricsAggregateType>;
  properties?: Maybe<PropertyCreateNestedManyWithoutChartOfAccountsInput>;
  rentalRevenueCaptures?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutChartOfAccountsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionNum?: Maybe<Scalars['Int']>;
  yardiChartOfAccountsMetadata?: Maybe<YardiChartOfAccountsMetadataCreateNestedManyWithoutChartOfAccountsInput>;
};

export type ChartOfAccountsCreateWithoutBudgetComponentsInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutChartOfAccountsInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutChartOfAccountsInput>;
  budgetYear: Scalars['Int'];
  client: ClientCreateNestedOneWithoutChartOfAccountsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  gprEntities?: Maybe<GprEntityCreateNestedManyWithoutChartOfAccountsInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutChartOfaccountsInput>;
  id?: Maybe<Scalars['String']>;
  models?: Maybe<ModelCreateNestedManyWithoutChartOfAccountsInput>;
  name: Scalars['String'];
  operationalFinancialMetricsAggregateId?: Maybe<Scalars['String']>;
  operationalFinancialMetricsAggregateType?: Maybe<OperationalFinancialMetricsAggregateType>;
  properties?: Maybe<PropertyCreateNestedManyWithoutChartOfAccountsInput>;
  rentalRevenueCaptures?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutChartOfAccountsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionNum?: Maybe<Scalars['Int']>;
  yardiChartOfAccountsMetadata?: Maybe<YardiChartOfAccountsMetadataCreateNestedManyWithoutChartOfAccountsInput>;
};

export type ChartOfAccountsCreateWithoutClientInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutChartOfAccountsInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutChartOfAccountsInput>;
  budgetComponents?: Maybe<BudgetComponentV2CreateNestedManyWithoutChartOfAccountsInput>;
  budgetYear: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  gprEntities?: Maybe<GprEntityCreateNestedManyWithoutChartOfAccountsInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutChartOfaccountsInput>;
  id?: Maybe<Scalars['String']>;
  models?: Maybe<ModelCreateNestedManyWithoutChartOfAccountsInput>;
  name: Scalars['String'];
  operationalFinancialMetricsAggregateId?: Maybe<Scalars['String']>;
  operationalFinancialMetricsAggregateType?: Maybe<OperationalFinancialMetricsAggregateType>;
  properties?: Maybe<PropertyCreateNestedManyWithoutChartOfAccountsInput>;
  rentalRevenueCaptures?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutChartOfAccountsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionNum?: Maybe<Scalars['Int']>;
  yardiChartOfAccountsMetadata?: Maybe<YardiChartOfAccountsMetadataCreateNestedManyWithoutChartOfAccountsInput>;
};

export type ChartOfAccountsCreateWithoutGrowthDriversInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutChartOfAccountsInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutChartOfAccountsInput>;
  budgetComponents?: Maybe<BudgetComponentV2CreateNestedManyWithoutChartOfAccountsInput>;
  budgetYear: Scalars['Int'];
  client: ClientCreateNestedOneWithoutChartOfAccountsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  gprEntities?: Maybe<GprEntityCreateNestedManyWithoutChartOfAccountsInput>;
  id?: Maybe<Scalars['String']>;
  models?: Maybe<ModelCreateNestedManyWithoutChartOfAccountsInput>;
  name: Scalars['String'];
  operationalFinancialMetricsAggregateId?: Maybe<Scalars['String']>;
  operationalFinancialMetricsAggregateType?: Maybe<OperationalFinancialMetricsAggregateType>;
  properties?: Maybe<PropertyCreateNestedManyWithoutChartOfAccountsInput>;
  rentalRevenueCaptures?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutChartOfAccountsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionNum?: Maybe<Scalars['Int']>;
  yardiChartOfAccountsMetadata?: Maybe<YardiChartOfAccountsMetadataCreateNestedManyWithoutChartOfAccountsInput>;
};

export type ChartOfAccountsCreateWithoutModelsInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutChartOfAccountsInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutChartOfAccountsInput>;
  budgetComponents?: Maybe<BudgetComponentV2CreateNestedManyWithoutChartOfAccountsInput>;
  budgetYear: Scalars['Int'];
  client: ClientCreateNestedOneWithoutChartOfAccountsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  gprEntities?: Maybe<GprEntityCreateNestedManyWithoutChartOfAccountsInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutChartOfaccountsInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  operationalFinancialMetricsAggregateId?: Maybe<Scalars['String']>;
  operationalFinancialMetricsAggregateType?: Maybe<OperationalFinancialMetricsAggregateType>;
  properties?: Maybe<PropertyCreateNestedManyWithoutChartOfAccountsInput>;
  rentalRevenueCaptures?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutChartOfAccountsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionNum?: Maybe<Scalars['Int']>;
  yardiChartOfAccountsMetadata?: Maybe<YardiChartOfAccountsMetadataCreateNestedManyWithoutChartOfAccountsInput>;
};

export type ChartOfAccountsCreateWithoutPropertiesInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutChartOfAccountsInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutChartOfAccountsInput>;
  budgetComponents?: Maybe<BudgetComponentV2CreateNestedManyWithoutChartOfAccountsInput>;
  budgetYear: Scalars['Int'];
  client: ClientCreateNestedOneWithoutChartOfAccountsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  gprEntities?: Maybe<GprEntityCreateNestedManyWithoutChartOfAccountsInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutChartOfaccountsInput>;
  id?: Maybe<Scalars['String']>;
  models?: Maybe<ModelCreateNestedManyWithoutChartOfAccountsInput>;
  name: Scalars['String'];
  operationalFinancialMetricsAggregateId?: Maybe<Scalars['String']>;
  operationalFinancialMetricsAggregateType?: Maybe<OperationalFinancialMetricsAggregateType>;
  rentalRevenueCaptures?: Maybe<RentalRevenueCaptureCreateNestedManyWithoutChartOfAccountsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionNum?: Maybe<Scalars['Int']>;
  yardiChartOfAccountsMetadata?: Maybe<YardiChartOfAccountsMetadataCreateNestedManyWithoutChartOfAccountsInput>;
};

export type ChartOfAccountsCreateWithoutRentalRevenueCapturesInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutChartOfAccountsInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutChartOfAccountsInput>;
  budgetComponents?: Maybe<BudgetComponentV2CreateNestedManyWithoutChartOfAccountsInput>;
  budgetYear: Scalars['Int'];
  client: ClientCreateNestedOneWithoutChartOfAccountsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  gprEntities?: Maybe<GprEntityCreateNestedManyWithoutChartOfAccountsInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutChartOfaccountsInput>;
  id?: Maybe<Scalars['String']>;
  models?: Maybe<ModelCreateNestedManyWithoutChartOfAccountsInput>;
  name: Scalars['String'];
  operationalFinancialMetricsAggregateId?: Maybe<Scalars['String']>;
  operationalFinancialMetricsAggregateType?: Maybe<OperationalFinancialMetricsAggregateType>;
  properties?: Maybe<PropertyCreateNestedManyWithoutChartOfAccountsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionNum?: Maybe<Scalars['Int']>;
  yardiChartOfAccountsMetadata?: Maybe<YardiChartOfAccountsMetadataCreateNestedManyWithoutChartOfAccountsInput>;
};

export type ChartOfAccountsListRelationFilter = {
  every?: Maybe<ChartOfAccountsWhereInput>;
  none?: Maybe<ChartOfAccountsWhereInput>;
  some?: Maybe<ChartOfAccountsWhereInput>;
};

export type ChartOfAccountsRelationFilter = {
  is?: Maybe<ChartOfAccountsWhereInput>;
  isNot?: Maybe<ChartOfAccountsWhereInput>;
};

export type ChartOfAccountsScalarWhereInput = {
  AND?: Maybe<Array<ChartOfAccountsScalarWhereInput>>;
  NOT?: Maybe<Array<ChartOfAccountsScalarWhereInput>>;
  OR?: Maybe<Array<ChartOfAccountsScalarWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  clientId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  operationalFinancialMetricsAggregateId?: Maybe<StringFilter>;
  operationalFinancialMetricsAggregateType?: Maybe<EnumOperationalFinancialMetricsAggregateTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionNum?: Maybe<IntFilter>;
};

export type ChartOfAccountsSnapshotCreateManyClientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  data: Scalars['JSON'];
  dataFormatVersion: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
};

export type ChartOfAccountsSnapshotCreateManyClientInputEnvelope = {
  data: Array<ChartOfAccountsSnapshotCreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<ChartOfAccountsSnapshotWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChartOfAccountsSnapshotCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<ChartOfAccountsSnapshotCreateWithoutClientInput>>;
  createMany?: Maybe<ChartOfAccountsSnapshotCreateManyClientInputEnvelope>;
};

export type ChartOfAccountsSnapshotCreateOrConnectWithoutClientInput = {
  create: ChartOfAccountsSnapshotCreateWithoutClientInput;
  where: ChartOfAccountsSnapshotWhereUniqueInput;
};

export type ChartOfAccountsSnapshotCreateWithoutClientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  data: Scalars['JSON'];
  dataFormatVersion: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
};

export type ChartOfAccountsSnapshotListRelationFilter = {
  every?: Maybe<ChartOfAccountsSnapshotWhereInput>;
  none?: Maybe<ChartOfAccountsSnapshotWhereInput>;
  some?: Maybe<ChartOfAccountsSnapshotWhereInput>;
};

export type ChartOfAccountsSnapshotScalarWhereInput = {
  AND?: Maybe<Array<ChartOfAccountsSnapshotScalarWhereInput>>;
  NOT?: Maybe<Array<ChartOfAccountsSnapshotScalarWhereInput>>;
  OR?: Maybe<Array<ChartOfAccountsSnapshotScalarWhereInput>>;
  clientId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  data?: Maybe<JsonFilter>;
  dataFormatVersion?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
};

export type ChartOfAccountsSnapshotUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  data?: Maybe<Scalars['JSON']>;
  dataFormatVersion?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ChartOfAccountsSnapshotUpdateManyWithWhereWithoutClientInput = {
  data: ChartOfAccountsSnapshotUpdateManyMutationInput;
  where: ChartOfAccountsSnapshotScalarWhereInput;
};

export type ChartOfAccountsSnapshotUpdateManyWithoutClientInput = {
  connect?: Maybe<Array<ChartOfAccountsSnapshotWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChartOfAccountsSnapshotCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<ChartOfAccountsSnapshotCreateWithoutClientInput>>;
  createMany?: Maybe<ChartOfAccountsSnapshotCreateManyClientInputEnvelope>;
  delete?: Maybe<Array<ChartOfAccountsSnapshotWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChartOfAccountsSnapshotScalarWhereInput>>;
  disconnect?: Maybe<Array<ChartOfAccountsSnapshotWhereUniqueInput>>;
  set?: Maybe<Array<ChartOfAccountsSnapshotWhereUniqueInput>>;
  update?: Maybe<Array<ChartOfAccountsSnapshotUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<ChartOfAccountsSnapshotUpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<ChartOfAccountsSnapshotUpsertWithWhereUniqueWithoutClientInput>>;
};

export type ChartOfAccountsSnapshotUpdateWithWhereUniqueWithoutClientInput = {
  data: ChartOfAccountsSnapshotUpdateWithoutClientInput;
  where: ChartOfAccountsSnapshotWhereUniqueInput;
};

export type ChartOfAccountsSnapshotUpdateWithoutClientInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  data?: Maybe<Scalars['JSON']>;
  dataFormatVersion?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ChartOfAccountsSnapshotUpsertWithWhereUniqueWithoutClientInput = {
  create: ChartOfAccountsSnapshotCreateWithoutClientInput;
  update: ChartOfAccountsSnapshotUpdateWithoutClientInput;
  where: ChartOfAccountsSnapshotWhereUniqueInput;
};

export type ChartOfAccountsSnapshotWhereInput = {
  AND?: Maybe<Array<ChartOfAccountsSnapshotWhereInput>>;
  NOT?: Maybe<Array<ChartOfAccountsSnapshotWhereInput>>;
  OR?: Maybe<Array<ChartOfAccountsSnapshotWhereInput>>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  data?: Maybe<JsonFilter>;
  dataFormatVersion?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
};

export type ChartOfAccountsSnapshotWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChartOfAccountsUpdateManyMutationInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalFinancialMetricsAggregateId?: Maybe<StringFieldUpdateOperationsInput>;
  operationalFinancialMetricsAggregateType?: Maybe<EnumOperationalFinancialMetricsAggregateTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionNum?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ChartOfAccountsUpdateManyWithWhereWithoutClientInput = {
  data: ChartOfAccountsUpdateManyMutationInput;
  where: ChartOfAccountsScalarWhereInput;
};

export type ChartOfAccountsUpdateManyWithoutClientInput = {
  connect?: Maybe<Array<ChartOfAccountsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChartOfAccountsCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<ChartOfAccountsCreateWithoutClientInput>>;
  createMany?: Maybe<ChartOfAccountsCreateManyClientInputEnvelope>;
  delete?: Maybe<Array<ChartOfAccountsWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChartOfAccountsScalarWhereInput>>;
  disconnect?: Maybe<Array<ChartOfAccountsWhereUniqueInput>>;
  set?: Maybe<Array<ChartOfAccountsWhereUniqueInput>>;
  update?: Maybe<Array<ChartOfAccountsUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<ChartOfAccountsUpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<ChartOfAccountsUpsertWithWhereUniqueWithoutClientInput>>;
};

export type ChartOfAccountsUpdateOneRequiredWithoutAccountsV2Input = {
  connect?: Maybe<ChartOfAccountsWhereUniqueInput>;
  connectOrCreate?: Maybe<ChartOfAccountsCreateOrConnectWithoutAccountsV2Input>;
  create?: Maybe<ChartOfAccountsCreateWithoutAccountsV2Input>;
  update?: Maybe<ChartOfAccountsUpdateWithoutAccountsV2Input>;
  upsert?: Maybe<ChartOfAccountsUpsertWithoutAccountsV2Input>;
};

export type ChartOfAccountsUpdateOneRequiredWithoutBudgetComponentsInput = {
  connect?: Maybe<ChartOfAccountsWhereUniqueInput>;
  connectOrCreate?: Maybe<ChartOfAccountsCreateOrConnectWithoutBudgetComponentsInput>;
  create?: Maybe<ChartOfAccountsCreateWithoutBudgetComponentsInput>;
  update?: Maybe<ChartOfAccountsUpdateWithoutBudgetComponentsInput>;
  upsert?: Maybe<ChartOfAccountsUpsertWithoutBudgetComponentsInput>;
};

export type ChartOfAccountsUpdateOneRequiredWithoutGrowthDriversInput = {
  connect?: Maybe<ChartOfAccountsWhereUniqueInput>;
  connectOrCreate?: Maybe<ChartOfAccountsCreateOrConnectWithoutGrowthDriversInput>;
  create?: Maybe<ChartOfAccountsCreateWithoutGrowthDriversInput>;
  update?: Maybe<ChartOfAccountsUpdateWithoutGrowthDriversInput>;
  upsert?: Maybe<ChartOfAccountsUpsertWithoutGrowthDriversInput>;
};

export type ChartOfAccountsUpdateOneRequiredWithoutModelsInput = {
  connect?: Maybe<ChartOfAccountsWhereUniqueInput>;
  connectOrCreate?: Maybe<ChartOfAccountsCreateOrConnectWithoutModelsInput>;
  create?: Maybe<ChartOfAccountsCreateWithoutModelsInput>;
  update?: Maybe<ChartOfAccountsUpdateWithoutModelsInput>;
  upsert?: Maybe<ChartOfAccountsUpsertWithoutModelsInput>;
};

export type ChartOfAccountsUpdateOneRequiredWithoutRentalRevenueCapturesInput = {
  connect?: Maybe<ChartOfAccountsWhereUniqueInput>;
  connectOrCreate?: Maybe<ChartOfAccountsCreateOrConnectWithoutRentalRevenueCapturesInput>;
  create?: Maybe<ChartOfAccountsCreateWithoutRentalRevenueCapturesInput>;
  update?: Maybe<ChartOfAccountsUpdateWithoutRentalRevenueCapturesInput>;
  upsert?: Maybe<ChartOfAccountsUpsertWithoutRentalRevenueCapturesInput>;
};

export type ChartOfAccountsUpdateOneWithoutBudgetCategoryInput = {
  connect?: Maybe<ChartOfAccountsWhereUniqueInput>;
  connectOrCreate?: Maybe<ChartOfAccountsCreateOrConnectWithoutBudgetCategoryInput>;
  create?: Maybe<ChartOfAccountsCreateWithoutBudgetCategoryInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChartOfAccountsUpdateWithoutBudgetCategoryInput>;
  upsert?: Maybe<ChartOfAccountsUpsertWithoutBudgetCategoryInput>;
};

export type ChartOfAccountsUpdateOneWithoutPropertiesInput = {
  connect?: Maybe<ChartOfAccountsWhereUniqueInput>;
  connectOrCreate?: Maybe<ChartOfAccountsCreateOrConnectWithoutPropertiesInput>;
  create?: Maybe<ChartOfAccountsCreateWithoutPropertiesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChartOfAccountsUpdateWithoutPropertiesInput>;
  upsert?: Maybe<ChartOfAccountsUpsertWithoutPropertiesInput>;
};

export type ChartOfAccountsUpdateWithWhereUniqueWithoutClientInput = {
  data: ChartOfAccountsUpdateWithoutClientInput;
  where: ChartOfAccountsWhereUniqueInput;
};

export type ChartOfAccountsUpdateWithoutAccountsV2Input = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutChartOfAccountsInput>;
  budgetComponents?: Maybe<BudgetComponentV2UpdateManyWithoutChartOfAccountsInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutChartOfAccountsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  gprEntities?: Maybe<GprEntityUpdateManyWithoutChartOfAccountsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutChartOfaccountsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  models?: Maybe<ModelUpdateManyWithoutChartOfAccountsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalFinancialMetricsAggregateId?: Maybe<StringFieldUpdateOperationsInput>;
  operationalFinancialMetricsAggregateType?: Maybe<EnumOperationalFinancialMetricsAggregateTypeFieldUpdateOperationsInput>;
  properties?: Maybe<PropertyUpdateManyWithoutChartOfAccountsInput>;
  rentalRevenueCaptures?: Maybe<RentalRevenueCaptureUpdateManyWithoutChartOfAccountsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionNum?: Maybe<IntFieldUpdateOperationsInput>;
  yardiChartOfAccountsMetadata?: Maybe<YardiChartOfAccountsMetadataUpdateManyWithoutChartOfAccountsInput>;
};

export type ChartOfAccountsUpdateWithoutBudgetCategoryInput = {
  accountsV2?: Maybe<AccountV2UpdateManyWithoutChartOfAccountsInput>;
  budgetComponents?: Maybe<BudgetComponentV2UpdateManyWithoutChartOfAccountsInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutChartOfAccountsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  gprEntities?: Maybe<GprEntityUpdateManyWithoutChartOfAccountsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutChartOfaccountsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  models?: Maybe<ModelUpdateManyWithoutChartOfAccountsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalFinancialMetricsAggregateId?: Maybe<StringFieldUpdateOperationsInput>;
  operationalFinancialMetricsAggregateType?: Maybe<EnumOperationalFinancialMetricsAggregateTypeFieldUpdateOperationsInput>;
  properties?: Maybe<PropertyUpdateManyWithoutChartOfAccountsInput>;
  rentalRevenueCaptures?: Maybe<RentalRevenueCaptureUpdateManyWithoutChartOfAccountsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionNum?: Maybe<IntFieldUpdateOperationsInput>;
  yardiChartOfAccountsMetadata?: Maybe<YardiChartOfAccountsMetadataUpdateManyWithoutChartOfAccountsInput>;
};

export type ChartOfAccountsUpdateWithoutBudgetComponentsInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutChartOfAccountsInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutChartOfAccountsInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutChartOfAccountsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  gprEntities?: Maybe<GprEntityUpdateManyWithoutChartOfAccountsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutChartOfaccountsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  models?: Maybe<ModelUpdateManyWithoutChartOfAccountsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalFinancialMetricsAggregateId?: Maybe<StringFieldUpdateOperationsInput>;
  operationalFinancialMetricsAggregateType?: Maybe<EnumOperationalFinancialMetricsAggregateTypeFieldUpdateOperationsInput>;
  properties?: Maybe<PropertyUpdateManyWithoutChartOfAccountsInput>;
  rentalRevenueCaptures?: Maybe<RentalRevenueCaptureUpdateManyWithoutChartOfAccountsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionNum?: Maybe<IntFieldUpdateOperationsInput>;
  yardiChartOfAccountsMetadata?: Maybe<YardiChartOfAccountsMetadataUpdateManyWithoutChartOfAccountsInput>;
};

export type ChartOfAccountsUpdateWithoutClientInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutChartOfAccountsInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutChartOfAccountsInput>;
  budgetComponents?: Maybe<BudgetComponentV2UpdateManyWithoutChartOfAccountsInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  gprEntities?: Maybe<GprEntityUpdateManyWithoutChartOfAccountsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutChartOfaccountsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  models?: Maybe<ModelUpdateManyWithoutChartOfAccountsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalFinancialMetricsAggregateId?: Maybe<StringFieldUpdateOperationsInput>;
  operationalFinancialMetricsAggregateType?: Maybe<EnumOperationalFinancialMetricsAggregateTypeFieldUpdateOperationsInput>;
  properties?: Maybe<PropertyUpdateManyWithoutChartOfAccountsInput>;
  rentalRevenueCaptures?: Maybe<RentalRevenueCaptureUpdateManyWithoutChartOfAccountsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionNum?: Maybe<IntFieldUpdateOperationsInput>;
  yardiChartOfAccountsMetadata?: Maybe<YardiChartOfAccountsMetadataUpdateManyWithoutChartOfAccountsInput>;
};

export type ChartOfAccountsUpdateWithoutGrowthDriversInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutChartOfAccountsInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutChartOfAccountsInput>;
  budgetComponents?: Maybe<BudgetComponentV2UpdateManyWithoutChartOfAccountsInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutChartOfAccountsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  gprEntities?: Maybe<GprEntityUpdateManyWithoutChartOfAccountsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  models?: Maybe<ModelUpdateManyWithoutChartOfAccountsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalFinancialMetricsAggregateId?: Maybe<StringFieldUpdateOperationsInput>;
  operationalFinancialMetricsAggregateType?: Maybe<EnumOperationalFinancialMetricsAggregateTypeFieldUpdateOperationsInput>;
  properties?: Maybe<PropertyUpdateManyWithoutChartOfAccountsInput>;
  rentalRevenueCaptures?: Maybe<RentalRevenueCaptureUpdateManyWithoutChartOfAccountsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionNum?: Maybe<IntFieldUpdateOperationsInput>;
  yardiChartOfAccountsMetadata?: Maybe<YardiChartOfAccountsMetadataUpdateManyWithoutChartOfAccountsInput>;
};

export type ChartOfAccountsUpdateWithoutModelsInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutChartOfAccountsInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutChartOfAccountsInput>;
  budgetComponents?: Maybe<BudgetComponentV2UpdateManyWithoutChartOfAccountsInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutChartOfAccountsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  gprEntities?: Maybe<GprEntityUpdateManyWithoutChartOfAccountsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutChartOfaccountsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalFinancialMetricsAggregateId?: Maybe<StringFieldUpdateOperationsInput>;
  operationalFinancialMetricsAggregateType?: Maybe<EnumOperationalFinancialMetricsAggregateTypeFieldUpdateOperationsInput>;
  properties?: Maybe<PropertyUpdateManyWithoutChartOfAccountsInput>;
  rentalRevenueCaptures?: Maybe<RentalRevenueCaptureUpdateManyWithoutChartOfAccountsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionNum?: Maybe<IntFieldUpdateOperationsInput>;
  yardiChartOfAccountsMetadata?: Maybe<YardiChartOfAccountsMetadataUpdateManyWithoutChartOfAccountsInput>;
};

export type ChartOfAccountsUpdateWithoutPropertiesInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutChartOfAccountsInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutChartOfAccountsInput>;
  budgetComponents?: Maybe<BudgetComponentV2UpdateManyWithoutChartOfAccountsInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutChartOfAccountsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  gprEntities?: Maybe<GprEntityUpdateManyWithoutChartOfAccountsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutChartOfaccountsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  models?: Maybe<ModelUpdateManyWithoutChartOfAccountsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalFinancialMetricsAggregateId?: Maybe<StringFieldUpdateOperationsInput>;
  operationalFinancialMetricsAggregateType?: Maybe<EnumOperationalFinancialMetricsAggregateTypeFieldUpdateOperationsInput>;
  rentalRevenueCaptures?: Maybe<RentalRevenueCaptureUpdateManyWithoutChartOfAccountsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionNum?: Maybe<IntFieldUpdateOperationsInput>;
  yardiChartOfAccountsMetadata?: Maybe<YardiChartOfAccountsMetadataUpdateManyWithoutChartOfAccountsInput>;
};

export type ChartOfAccountsUpdateWithoutRentalRevenueCapturesInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutChartOfAccountsInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutChartOfAccountsInput>;
  budgetComponents?: Maybe<BudgetComponentV2UpdateManyWithoutChartOfAccountsInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutChartOfAccountsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  gprEntities?: Maybe<GprEntityUpdateManyWithoutChartOfAccountsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutChartOfaccountsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  models?: Maybe<ModelUpdateManyWithoutChartOfAccountsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalFinancialMetricsAggregateId?: Maybe<StringFieldUpdateOperationsInput>;
  operationalFinancialMetricsAggregateType?: Maybe<EnumOperationalFinancialMetricsAggregateTypeFieldUpdateOperationsInput>;
  properties?: Maybe<PropertyUpdateManyWithoutChartOfAccountsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionNum?: Maybe<IntFieldUpdateOperationsInput>;
  yardiChartOfAccountsMetadata?: Maybe<YardiChartOfAccountsMetadataUpdateManyWithoutChartOfAccountsInput>;
};

export type ChartOfAccountsUpsertWithWhereUniqueWithoutClientInput = {
  create: ChartOfAccountsCreateWithoutClientInput;
  update: ChartOfAccountsUpdateWithoutClientInput;
  where: ChartOfAccountsWhereUniqueInput;
};

export type ChartOfAccountsUpsertWithoutAccountsV2Input = {
  create: ChartOfAccountsCreateWithoutAccountsV2Input;
  update: ChartOfAccountsUpdateWithoutAccountsV2Input;
};

export type ChartOfAccountsUpsertWithoutBudgetCategoryInput = {
  create: ChartOfAccountsCreateWithoutBudgetCategoryInput;
  update: ChartOfAccountsUpdateWithoutBudgetCategoryInput;
};

export type ChartOfAccountsUpsertWithoutBudgetComponentsInput = {
  create: ChartOfAccountsCreateWithoutBudgetComponentsInput;
  update: ChartOfAccountsUpdateWithoutBudgetComponentsInput;
};

export type ChartOfAccountsUpsertWithoutGrowthDriversInput = {
  create: ChartOfAccountsCreateWithoutGrowthDriversInput;
  update: ChartOfAccountsUpdateWithoutGrowthDriversInput;
};

export type ChartOfAccountsUpsertWithoutModelsInput = {
  create: ChartOfAccountsCreateWithoutModelsInput;
  update: ChartOfAccountsUpdateWithoutModelsInput;
};

export type ChartOfAccountsUpsertWithoutPropertiesInput = {
  create: ChartOfAccountsCreateWithoutPropertiesInput;
  update: ChartOfAccountsUpdateWithoutPropertiesInput;
};

export type ChartOfAccountsUpsertWithoutRentalRevenueCapturesInput = {
  create: ChartOfAccountsCreateWithoutRentalRevenueCapturesInput;
  update: ChartOfAccountsUpdateWithoutRentalRevenueCapturesInput;
};

export type ChartOfAccountsWhereInput = {
  AND?: Maybe<Array<ChartOfAccountsWhereInput>>;
  BudgetCategory?: Maybe<BudgetCategoryListRelationFilter>;
  NOT?: Maybe<Array<ChartOfAccountsWhereInput>>;
  OR?: Maybe<Array<ChartOfAccountsWhereInput>>;
  accountsV2?: Maybe<AccountV2ListRelationFilter>;
  budgetComponents?: Maybe<BudgetComponentV2ListRelationFilter>;
  budgetYear?: Maybe<IntFilter>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  gprEntities?: Maybe<GprEntityListRelationFilter>;
  growthDrivers?: Maybe<DriverGrowthListRelationFilter>;
  id?: Maybe<StringFilter>;
  models?: Maybe<ModelListRelationFilter>;
  name?: Maybe<StringFilter>;
  operationalFinancialMetricsAggregateId?: Maybe<StringFilter>;
  operationalFinancialMetricsAggregateType?: Maybe<EnumOperationalFinancialMetricsAggregateTypeFilter>;
  properties?: Maybe<PropertyListRelationFilter>;
  rentalRevenueCaptures?: Maybe<RentalRevenueCaptureListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionNum?: Maybe<IntFilter>;
  yardiChartOfAccountsMetadata?: Maybe<YardiChartOfAccountsMetadataListRelationFilter>;
};

export type ChartOfAccountsWhereUniqueInput = {
  clientId_budgetYear?: Maybe<ChartOfAccountsClientIdBudgetYearCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type Client = {
  __typename?: 'Client';
  createdAt: Scalars['DateTime'];
  displayName: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  type: ClientType;
  updatedAt: Scalars['DateTime'];
};

export type ClientAwareChartOfAccountsWhereInput = {
  AND?: Maybe<Array<ClientAwareChartOfAccountsWhereInput>>;
  NOT?: Maybe<Array<ClientAwareChartOfAccountsWhereInput>>;
  OR?: Maybe<Array<ClientAwareChartOfAccountsWhereInput>>;
  budgetComponents?: Maybe<BudgetComponentV2ListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  models?: Maybe<ModelListRelationFilter>;
  name?: Maybe<StringFilter>;
  properties?: Maybe<PropertyListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ClientBudgetPlanClientIdBudgetYearCompoundUniqueInput = {
  budgetYear: Scalars['Int'];
  clientId: Scalars['String'];
};

export type ClientBudgetPlanCreateManyClientInput = {
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  isAutoUnitTypeUpdateEnabled?: Maybe<Scalars['Boolean']>;
  protected: Scalars['Boolean'];
};

export type ClientBudgetPlanCreateManyClientInputEnvelope = {
  data: Array<ClientBudgetPlanCreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClientBudgetPlanCreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<ClientBudgetPlanWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClientBudgetPlanCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<ClientBudgetPlanCreateWithoutClientInput>>;
  createMany?: Maybe<ClientBudgetPlanCreateManyClientInputEnvelope>;
};

export type ClientBudgetPlanCreateNestedOneWithoutPropertiesInput = {
  connect?: Maybe<ClientBudgetPlanWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientBudgetPlanCreateOrConnectWithoutPropertiesInput>;
  create?: Maybe<ClientBudgetPlanCreateWithoutPropertiesInput>;
};

export type ClientBudgetPlanCreateOrConnectWithoutClientInput = {
  create: ClientBudgetPlanCreateWithoutClientInput;
  where: ClientBudgetPlanWhereUniqueInput;
};

export type ClientBudgetPlanCreateOrConnectWithoutPropertiesInput = {
  create: ClientBudgetPlanCreateWithoutPropertiesInput;
  where: ClientBudgetPlanWhereUniqueInput;
};

export type ClientBudgetPlanCreateWithoutClientInput = {
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  isAutoUnitTypeUpdateEnabled?: Maybe<Scalars['Boolean']>;
  properties?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutClientBudgetPlanInput>;
  protected: Scalars['Boolean'];
};

export type ClientBudgetPlanCreateWithoutPropertiesInput = {
  budgetYear: Scalars['Int'];
  client: ClientCreateNestedOneWithoutBudgetPlansInput;
  id?: Maybe<Scalars['String']>;
  isAutoUnitTypeUpdateEnabled?: Maybe<Scalars['Boolean']>;
  protected: Scalars['Boolean'];
};

export type ClientBudgetPlanListRelationFilter = {
  every?: Maybe<ClientBudgetPlanWhereInput>;
  none?: Maybe<ClientBudgetPlanWhereInput>;
  some?: Maybe<ClientBudgetPlanWhereInput>;
};

export type ClientBudgetPlanProperty = {
  __typename?: 'ClientBudgetPlanProperty';
  clientBudgetPlanId: Scalars['String'];
  propertyId: Scalars['String'];
  reforecastStartMonthIndex: Scalars['Int'];
};

export type ClientBudgetPlanPropertyClientBudgetPlanIdPropertyIdCompoundUniqueInput = {
  clientBudgetPlanId: Scalars['String'];
  propertyId: Scalars['String'];
};

export type ClientBudgetPlanPropertyCreateManyClientBudgetPlanInput = {
  propertyId: Scalars['String'];
  reforecastStartMonthIndex: Scalars['Int'];
};

export type ClientBudgetPlanPropertyCreateManyClientBudgetPlanInputEnvelope = {
  data: Array<ClientBudgetPlanPropertyCreateManyClientBudgetPlanInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClientBudgetPlanPropertyCreateManyPropertyInput = {
  clientBudgetPlanId: Scalars['String'];
  reforecastStartMonthIndex: Scalars['Int'];
};

export type ClientBudgetPlanPropertyCreateManyPropertyInputEnvelope = {
  data: Array<ClientBudgetPlanPropertyCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClientBudgetPlanPropertyCreateNestedManyWithoutClientBudgetPlanInput = {
  connect?: Maybe<Array<ClientBudgetPlanPropertyWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClientBudgetPlanPropertyCreateOrConnectWithoutClientBudgetPlanInput>>;
  create?: Maybe<Array<ClientBudgetPlanPropertyCreateWithoutClientBudgetPlanInput>>;
  createMany?: Maybe<ClientBudgetPlanPropertyCreateManyClientBudgetPlanInputEnvelope>;
};

export type ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<ClientBudgetPlanPropertyWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClientBudgetPlanPropertyCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<ClientBudgetPlanPropertyCreateWithoutPropertyInput>>;
  createMany?: Maybe<ClientBudgetPlanPropertyCreateManyPropertyInputEnvelope>;
};

export type ClientBudgetPlanPropertyCreateOrConnectWithoutClientBudgetPlanInput = {
  create: ClientBudgetPlanPropertyCreateWithoutClientBudgetPlanInput;
  where: ClientBudgetPlanPropertyWhereUniqueInput;
};

export type ClientBudgetPlanPropertyCreateOrConnectWithoutPropertyInput = {
  create: ClientBudgetPlanPropertyCreateWithoutPropertyInput;
  where: ClientBudgetPlanPropertyWhereUniqueInput;
};

export type ClientBudgetPlanPropertyCreateWithoutClientBudgetPlanInput = {
  property: PropertyCreateNestedOneWithoutPropertyClientBudgetPlansInput;
  reforecastStartMonthIndex: Scalars['Int'];
};

export type ClientBudgetPlanPropertyCreateWithoutPropertyInput = {
  clientBudgetPlan: ClientBudgetPlanCreateNestedOneWithoutPropertiesInput;
  reforecastStartMonthIndex: Scalars['Int'];
};

export type ClientBudgetPlanPropertyListRelationFilter = {
  every?: Maybe<ClientBudgetPlanPropertyWhereInput>;
  none?: Maybe<ClientBudgetPlanPropertyWhereInput>;
  some?: Maybe<ClientBudgetPlanPropertyWhereInput>;
};

export type ClientBudgetPlanPropertyOrderByInput = {
  clientBudgetPlanId?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  reforecastStartMonthIndex?: Maybe<SortOrder>;
};

export enum ClientBudgetPlanPropertyScalarFieldEnum {
  ClientBudgetPlanId = 'clientBudgetPlanId',
  PropertyId = 'propertyId',
  ReforecastStartMonthIndex = 'reforecastStartMonthIndex'
}

export type ClientBudgetPlanPropertyScalarWhereInput = {
  AND?: Maybe<Array<ClientBudgetPlanPropertyScalarWhereInput>>;
  NOT?: Maybe<Array<ClientBudgetPlanPropertyScalarWhereInput>>;
  OR?: Maybe<Array<ClientBudgetPlanPropertyScalarWhereInput>>;
  clientBudgetPlanId?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  reforecastStartMonthIndex?: Maybe<IntFilter>;
};

export type ClientBudgetPlanPropertyUpdateManyMutationInput = {
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ClientBudgetPlanPropertyUpdateManyWithWhereWithoutClientBudgetPlanInput = {
  data: ClientBudgetPlanPropertyUpdateManyMutationInput;
  where: ClientBudgetPlanPropertyScalarWhereInput;
};

export type ClientBudgetPlanPropertyUpdateManyWithWhereWithoutPropertyInput = {
  data: ClientBudgetPlanPropertyUpdateManyMutationInput;
  where: ClientBudgetPlanPropertyScalarWhereInput;
};

export type ClientBudgetPlanPropertyUpdateManyWithoutClientBudgetPlanInput = {
  connect?: Maybe<Array<ClientBudgetPlanPropertyWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClientBudgetPlanPropertyCreateOrConnectWithoutClientBudgetPlanInput>>;
  create?: Maybe<Array<ClientBudgetPlanPropertyCreateWithoutClientBudgetPlanInput>>;
  createMany?: Maybe<ClientBudgetPlanPropertyCreateManyClientBudgetPlanInputEnvelope>;
  delete?: Maybe<Array<ClientBudgetPlanPropertyWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClientBudgetPlanPropertyScalarWhereInput>>;
  disconnect?: Maybe<Array<ClientBudgetPlanPropertyWhereUniqueInput>>;
  set?: Maybe<Array<ClientBudgetPlanPropertyWhereUniqueInput>>;
  update?: Maybe<Array<ClientBudgetPlanPropertyUpdateWithWhereUniqueWithoutClientBudgetPlanInput>>;
  updateMany?: Maybe<Array<ClientBudgetPlanPropertyUpdateManyWithWhereWithoutClientBudgetPlanInput>>;
  upsert?: Maybe<Array<ClientBudgetPlanPropertyUpsertWithWhereUniqueWithoutClientBudgetPlanInput>>;
};

export type ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<ClientBudgetPlanPropertyWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClientBudgetPlanPropertyCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<ClientBudgetPlanPropertyCreateWithoutPropertyInput>>;
  createMany?: Maybe<ClientBudgetPlanPropertyCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<ClientBudgetPlanPropertyWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClientBudgetPlanPropertyScalarWhereInput>>;
  disconnect?: Maybe<Array<ClientBudgetPlanPropertyWhereUniqueInput>>;
  set?: Maybe<Array<ClientBudgetPlanPropertyWhereUniqueInput>>;
  update?: Maybe<Array<ClientBudgetPlanPropertyUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<ClientBudgetPlanPropertyUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<ClientBudgetPlanPropertyUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type ClientBudgetPlanPropertyUpdateWithWhereUniqueWithoutClientBudgetPlanInput = {
  data: ClientBudgetPlanPropertyUpdateWithoutClientBudgetPlanInput;
  where: ClientBudgetPlanPropertyWhereUniqueInput;
};

export type ClientBudgetPlanPropertyUpdateWithWhereUniqueWithoutPropertyInput = {
  data: ClientBudgetPlanPropertyUpdateWithoutPropertyInput;
  where: ClientBudgetPlanPropertyWhereUniqueInput;
};

export type ClientBudgetPlanPropertyUpdateWithoutClientBudgetPlanInput = {
  property?: Maybe<PropertyUpdateOneRequiredWithoutPropertyClientBudgetPlansInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ClientBudgetPlanPropertyUpdateWithoutPropertyInput = {
  clientBudgetPlan?: Maybe<ClientBudgetPlanUpdateOneRequiredWithoutPropertiesInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ClientBudgetPlanPropertyUpsertWithWhereUniqueWithoutClientBudgetPlanInput = {
  create: ClientBudgetPlanPropertyCreateWithoutClientBudgetPlanInput;
  update: ClientBudgetPlanPropertyUpdateWithoutClientBudgetPlanInput;
  where: ClientBudgetPlanPropertyWhereUniqueInput;
};

export type ClientBudgetPlanPropertyUpsertWithWhereUniqueWithoutPropertyInput = {
  create: ClientBudgetPlanPropertyCreateWithoutPropertyInput;
  update: ClientBudgetPlanPropertyUpdateWithoutPropertyInput;
  where: ClientBudgetPlanPropertyWhereUniqueInput;
};

export type ClientBudgetPlanPropertyWhereInput = {
  AND?: Maybe<Array<ClientBudgetPlanPropertyWhereInput>>;
  NOT?: Maybe<Array<ClientBudgetPlanPropertyWhereInput>>;
  OR?: Maybe<Array<ClientBudgetPlanPropertyWhereInput>>;
  clientBudgetPlan?: Maybe<ClientBudgetPlanRelationFilter>;
  clientBudgetPlanId?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  reforecastStartMonthIndex?: Maybe<IntFilter>;
};

export type ClientBudgetPlanPropertyWhereUniqueInput = {
  clientBudgetPlanId_propertyId?: Maybe<ClientBudgetPlanPropertyClientBudgetPlanIdPropertyIdCompoundUniqueInput>;
};

export type ClientBudgetPlanRelationFilter = {
  is?: Maybe<ClientBudgetPlanWhereInput>;
  isNot?: Maybe<ClientBudgetPlanWhereInput>;
};

export type ClientBudgetPlanScalarWhereInput = {
  AND?: Maybe<Array<ClientBudgetPlanScalarWhereInput>>;
  NOT?: Maybe<Array<ClientBudgetPlanScalarWhereInput>>;
  OR?: Maybe<Array<ClientBudgetPlanScalarWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  clientId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isAutoUnitTypeUpdateEnabled?: Maybe<BoolNullableFilter>;
  protected?: Maybe<BoolFilter>;
};

export type ClientBudgetPlanUpdateManyMutationInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isAutoUnitTypeUpdateEnabled?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  protected?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type ClientBudgetPlanUpdateManyWithWhereWithoutClientInput = {
  data: ClientBudgetPlanUpdateManyMutationInput;
  where: ClientBudgetPlanScalarWhereInput;
};

export type ClientBudgetPlanUpdateManyWithoutClientInput = {
  connect?: Maybe<Array<ClientBudgetPlanWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClientBudgetPlanCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<ClientBudgetPlanCreateWithoutClientInput>>;
  createMany?: Maybe<ClientBudgetPlanCreateManyClientInputEnvelope>;
  delete?: Maybe<Array<ClientBudgetPlanWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClientBudgetPlanScalarWhereInput>>;
  disconnect?: Maybe<Array<ClientBudgetPlanWhereUniqueInput>>;
  set?: Maybe<Array<ClientBudgetPlanWhereUniqueInput>>;
  update?: Maybe<Array<ClientBudgetPlanUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<ClientBudgetPlanUpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<ClientBudgetPlanUpsertWithWhereUniqueWithoutClientInput>>;
};

export type ClientBudgetPlanUpdateOneRequiredWithoutPropertiesInput = {
  connect?: Maybe<ClientBudgetPlanWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientBudgetPlanCreateOrConnectWithoutPropertiesInput>;
  create?: Maybe<ClientBudgetPlanCreateWithoutPropertiesInput>;
  update?: Maybe<ClientBudgetPlanUpdateWithoutPropertiesInput>;
  upsert?: Maybe<ClientBudgetPlanUpsertWithoutPropertiesInput>;
};

export type ClientBudgetPlanUpdateWithWhereUniqueWithoutClientInput = {
  data: ClientBudgetPlanUpdateWithoutClientInput;
  where: ClientBudgetPlanWhereUniqueInput;
};

export type ClientBudgetPlanUpdateWithoutClientInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isAutoUnitTypeUpdateEnabled?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  properties?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutClientBudgetPlanInput>;
  protected?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type ClientBudgetPlanUpdateWithoutPropertiesInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutBudgetPlansInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isAutoUnitTypeUpdateEnabled?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  protected?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type ClientBudgetPlanUpsertWithWhereUniqueWithoutClientInput = {
  create: ClientBudgetPlanCreateWithoutClientInput;
  update: ClientBudgetPlanUpdateWithoutClientInput;
  where: ClientBudgetPlanWhereUniqueInput;
};

export type ClientBudgetPlanUpsertWithoutPropertiesInput = {
  create: ClientBudgetPlanCreateWithoutPropertiesInput;
  update: ClientBudgetPlanUpdateWithoutPropertiesInput;
};

export type ClientBudgetPlanWhereInput = {
  AND?: Maybe<Array<ClientBudgetPlanWhereInput>>;
  NOT?: Maybe<Array<ClientBudgetPlanWhereInput>>;
  OR?: Maybe<Array<ClientBudgetPlanWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isAutoUnitTypeUpdateEnabled?: Maybe<BoolNullableFilter>;
  properties?: Maybe<ClientBudgetPlanPropertyListRelationFilter>;
  protected?: Maybe<BoolFilter>;
};

export type ClientBudgetPlanWhereUniqueInput = {
  clientId_budgetYear?: Maybe<ClientBudgetPlanClientIdBudgetYearCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type ClientCommentAccount = {
  __typename?: 'ClientCommentAccount';
  accountId: Scalars['String'];
  authorEmail: Scalars['String'];
  authorId: Scalars['String'];
  authorLastName: Scalars['String'];
  authorName: Scalars['String'];
  budgetYear: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  propertyId: Scalars['String'];
  text: Scalars['String'];
};

export type ClientCommentAccountCreateManyAccountInput = {
  authorEmail: Scalars['String'];
  authorId: Scalars['String'];
  authorLastName: Scalars['String'];
  authorName: Scalars['String'];
  budgetYear: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  text: Scalars['String'];
};

export type ClientCommentAccountCreateManyAccountInputEnvelope = {
  data: Array<ClientCommentAccountCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClientCommentAccountCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<ClientCommentAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClientCommentAccountCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<ClientCommentAccountCreateWithoutAccountInput>>;
  createMany?: Maybe<ClientCommentAccountCreateManyAccountInputEnvelope>;
};

export type ClientCommentAccountCreateOrConnectWithoutAccountInput = {
  create: ClientCommentAccountCreateWithoutAccountInput;
  where: ClientCommentAccountWhereUniqueInput;
};

export type ClientCommentAccountCreateWithoutAccountInput = {
  authorEmail: Scalars['String'];
  authorId: Scalars['String'];
  authorLastName: Scalars['String'];
  authorName: Scalars['String'];
  budgetYear: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  text: Scalars['String'];
};

export type ClientCommentAccountListRelationFilter = {
  every?: Maybe<ClientCommentAccountWhereInput>;
  none?: Maybe<ClientCommentAccountWhereInput>;
  some?: Maybe<ClientCommentAccountWhereInput>;
};

export type ClientCommentAccountOrderByInput = {
  accountId?: Maybe<SortOrder>;
  authorEmail?: Maybe<SortOrder>;
  authorId?: Maybe<SortOrder>;
  authorLastName?: Maybe<SortOrder>;
  authorName?: Maybe<SortOrder>;
  budgetYear?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  text?: Maybe<SortOrder>;
};

export enum ClientCommentAccountScalarFieldEnum {
  AccountId = 'accountId',
  AuthorEmail = 'authorEmail',
  AuthorId = 'authorId',
  AuthorLastName = 'authorLastName',
  AuthorName = 'authorName',
  BudgetYear = 'budgetYear',
  CreatedAt = 'createdAt',
  Id = 'id',
  PropertyId = 'propertyId',
  Text = 'text'
}

export type ClientCommentAccountScalarWhereInput = {
  AND?: Maybe<Array<ClientCommentAccountScalarWhereInput>>;
  NOT?: Maybe<Array<ClientCommentAccountScalarWhereInput>>;
  OR?: Maybe<Array<ClientCommentAccountScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  authorEmail?: Maybe<StringFilter>;
  authorId?: Maybe<StringFilter>;
  authorLastName?: Maybe<StringFilter>;
  authorName?: Maybe<StringFilter>;
  budgetYear?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  text?: Maybe<StringFilter>;
};

export type ClientCommentAccountUpdateManyMutationInput = {
  authorEmail?: Maybe<StringFieldUpdateOperationsInput>;
  authorId?: Maybe<StringFieldUpdateOperationsInput>;
  authorLastName?: Maybe<StringFieldUpdateOperationsInput>;
  authorName?: Maybe<StringFieldUpdateOperationsInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  propertyId?: Maybe<StringFieldUpdateOperationsInput>;
  text?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ClientCommentAccountUpdateManyWithWhereWithoutAccountInput = {
  data: ClientCommentAccountUpdateManyMutationInput;
  where: ClientCommentAccountScalarWhereInput;
};

export type ClientCommentAccountUpdateManyWithoutAccountInput = {
  connect?: Maybe<Array<ClientCommentAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClientCommentAccountCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<ClientCommentAccountCreateWithoutAccountInput>>;
  createMany?: Maybe<ClientCommentAccountCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<ClientCommentAccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClientCommentAccountScalarWhereInput>>;
  disconnect?: Maybe<Array<ClientCommentAccountWhereUniqueInput>>;
  set?: Maybe<Array<ClientCommentAccountWhereUniqueInput>>;
  update?: Maybe<Array<ClientCommentAccountUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<ClientCommentAccountUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<ClientCommentAccountUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type ClientCommentAccountUpdateWithWhereUniqueWithoutAccountInput = {
  data: ClientCommentAccountUpdateWithoutAccountInput;
  where: ClientCommentAccountWhereUniqueInput;
};

export type ClientCommentAccountUpdateWithoutAccountInput = {
  authorEmail?: Maybe<StringFieldUpdateOperationsInput>;
  authorId?: Maybe<StringFieldUpdateOperationsInput>;
  authorLastName?: Maybe<StringFieldUpdateOperationsInput>;
  authorName?: Maybe<StringFieldUpdateOperationsInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  propertyId?: Maybe<StringFieldUpdateOperationsInput>;
  text?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ClientCommentAccountUpsertWithWhereUniqueWithoutAccountInput = {
  create: ClientCommentAccountCreateWithoutAccountInput;
  update: ClientCommentAccountUpdateWithoutAccountInput;
  where: ClientCommentAccountWhereUniqueInput;
};

export type ClientCommentAccountWhereInput = {
  AND?: Maybe<Array<ClientCommentAccountWhereInput>>;
  NOT?: Maybe<Array<ClientCommentAccountWhereInput>>;
  OR?: Maybe<Array<ClientCommentAccountWhereInput>>;
  account?: Maybe<AccountV2RelationFilter>;
  accountId?: Maybe<StringFilter>;
  authorEmail?: Maybe<StringFilter>;
  authorId?: Maybe<StringFilter>;
  authorLastName?: Maybe<StringFilter>;
  authorName?: Maybe<StringFilter>;
  budgetYear?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  text?: Maybe<StringFilter>;
};

export type ClientCommentAccountWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClientCommentBudgetComponentCreateManyBudgetComponentInput = {
  authorEmail: Scalars['String'];
  authorId: Scalars['String'];
  authorLastName: Scalars['String'];
  authorName: Scalars['String'];
  budgetYear: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  text: Scalars['String'];
};

export type ClientCommentBudgetComponentCreateManyBudgetComponentInputEnvelope = {
  data: Array<ClientCommentBudgetComponentCreateManyBudgetComponentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClientCommentBudgetComponentCreateNestedManyWithoutBudgetComponentInput = {
  connect?: Maybe<Array<ClientCommentBudgetComponentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClientCommentBudgetComponentCreateOrConnectWithoutBudgetComponentInput>>;
  create?: Maybe<Array<ClientCommentBudgetComponentCreateWithoutBudgetComponentInput>>;
  createMany?: Maybe<ClientCommentBudgetComponentCreateManyBudgetComponentInputEnvelope>;
};

export type ClientCommentBudgetComponentCreateOrConnectWithoutBudgetComponentInput = {
  create: ClientCommentBudgetComponentCreateWithoutBudgetComponentInput;
  where: ClientCommentBudgetComponentWhereUniqueInput;
};

export type ClientCommentBudgetComponentCreateWithoutBudgetComponentInput = {
  authorEmail: Scalars['String'];
  authorId: Scalars['String'];
  authorLastName: Scalars['String'];
  authorName: Scalars['String'];
  budgetYear: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  text: Scalars['String'];
};

export type ClientCommentBudgetComponentListRelationFilter = {
  every?: Maybe<ClientCommentBudgetComponentWhereInput>;
  none?: Maybe<ClientCommentBudgetComponentWhereInput>;
  some?: Maybe<ClientCommentBudgetComponentWhereInput>;
};

export type ClientCommentBudgetComponentScalarWhereInput = {
  AND?: Maybe<Array<ClientCommentBudgetComponentScalarWhereInput>>;
  NOT?: Maybe<Array<ClientCommentBudgetComponentScalarWhereInput>>;
  OR?: Maybe<Array<ClientCommentBudgetComponentScalarWhereInput>>;
  authorEmail?: Maybe<StringFilter>;
  authorId?: Maybe<StringFilter>;
  authorLastName?: Maybe<StringFilter>;
  authorName?: Maybe<StringFilter>;
  budgetComponentId?: Maybe<StringFilter>;
  budgetYear?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  text?: Maybe<StringFilter>;
};

export type ClientCommentBudgetComponentUpdateManyMutationInput = {
  authorEmail?: Maybe<StringFieldUpdateOperationsInput>;
  authorId?: Maybe<StringFieldUpdateOperationsInput>;
  authorLastName?: Maybe<StringFieldUpdateOperationsInput>;
  authorName?: Maybe<StringFieldUpdateOperationsInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  propertyId?: Maybe<StringFieldUpdateOperationsInput>;
  text?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ClientCommentBudgetComponentUpdateManyWithWhereWithoutBudgetComponentInput = {
  data: ClientCommentBudgetComponentUpdateManyMutationInput;
  where: ClientCommentBudgetComponentScalarWhereInput;
};

export type ClientCommentBudgetComponentUpdateManyWithoutBudgetComponentInput = {
  connect?: Maybe<Array<ClientCommentBudgetComponentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClientCommentBudgetComponentCreateOrConnectWithoutBudgetComponentInput>>;
  create?: Maybe<Array<ClientCommentBudgetComponentCreateWithoutBudgetComponentInput>>;
  createMany?: Maybe<ClientCommentBudgetComponentCreateManyBudgetComponentInputEnvelope>;
  delete?: Maybe<Array<ClientCommentBudgetComponentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClientCommentBudgetComponentScalarWhereInput>>;
  disconnect?: Maybe<Array<ClientCommentBudgetComponentWhereUniqueInput>>;
  set?: Maybe<Array<ClientCommentBudgetComponentWhereUniqueInput>>;
  update?: Maybe<Array<ClientCommentBudgetComponentUpdateWithWhereUniqueWithoutBudgetComponentInput>>;
  updateMany?: Maybe<Array<ClientCommentBudgetComponentUpdateManyWithWhereWithoutBudgetComponentInput>>;
  upsert?: Maybe<Array<ClientCommentBudgetComponentUpsertWithWhereUniqueWithoutBudgetComponentInput>>;
};

export type ClientCommentBudgetComponentUpdateWithWhereUniqueWithoutBudgetComponentInput = {
  data: ClientCommentBudgetComponentUpdateWithoutBudgetComponentInput;
  where: ClientCommentBudgetComponentWhereUniqueInput;
};

export type ClientCommentBudgetComponentUpdateWithoutBudgetComponentInput = {
  authorEmail?: Maybe<StringFieldUpdateOperationsInput>;
  authorId?: Maybe<StringFieldUpdateOperationsInput>;
  authorLastName?: Maybe<StringFieldUpdateOperationsInput>;
  authorName?: Maybe<StringFieldUpdateOperationsInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  propertyId?: Maybe<StringFieldUpdateOperationsInput>;
  text?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ClientCommentBudgetComponentUpsertWithWhereUniqueWithoutBudgetComponentInput = {
  create: ClientCommentBudgetComponentCreateWithoutBudgetComponentInput;
  update: ClientCommentBudgetComponentUpdateWithoutBudgetComponentInput;
  where: ClientCommentBudgetComponentWhereUniqueInput;
};

export type ClientCommentBudgetComponentWhereInput = {
  AND?: Maybe<Array<ClientCommentBudgetComponentWhereInput>>;
  NOT?: Maybe<Array<ClientCommentBudgetComponentWhereInput>>;
  OR?: Maybe<Array<ClientCommentBudgetComponentWhereInput>>;
  authorEmail?: Maybe<StringFilter>;
  authorId?: Maybe<StringFilter>;
  authorLastName?: Maybe<StringFilter>;
  authorName?: Maybe<StringFilter>;
  budgetComponent?: Maybe<BudgetComponentV2RelationFilter>;
  budgetComponentId?: Maybe<StringFilter>;
  budgetYear?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  text?: Maybe<StringFilter>;
};

export type ClientCommentBudgetComponentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClientCommentPreviewViewModel = {
  __typename?: 'ClientCommentPreviewViewModel';
  accountId: Scalars['String'];
  authorEmail: Scalars['String'];
  authorId: Scalars['String'];
  authorLastName: Scalars['String'];
  authorName: Scalars['String'];
  budgetYear: Scalars['Int'];
  count: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  propertyId: Scalars['String'];
  text: Scalars['String'];
};

export type ClientCommentViewModel = {
  __typename?: 'ClientCommentViewModel';
  accountId: Scalars['String'];
  authorEmail: Scalars['String'];
  authorId: Scalars['String'];
  authorLastName: Scalars['String'];
  authorName: Scalars['String'];
  budgetYear: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  propertyId: Scalars['String'];
  text: Scalars['String'];
};

export type ClientCreateNestedOneWithoutAccountsInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutAccountsInput>;
  create?: Maybe<ClientCreateWithoutAccountsInput>;
};

export type ClientCreateNestedOneWithoutAccountsV2Input = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutAccountsV2Input>;
  create?: Maybe<ClientCreateWithoutAccountsV2Input>;
};

export type ClientCreateNestedOneWithoutBudgetCategoryInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutBudgetCategoryInput>;
  create?: Maybe<ClientCreateWithoutBudgetCategoryInput>;
};

export type ClientCreateNestedOneWithoutBudgetComponentInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutBudgetComponentInput>;
  create?: Maybe<ClientCreateWithoutBudgetComponentInput>;
};

export type ClientCreateNestedOneWithoutBudgetPlansInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutBudgetPlansInput>;
  create?: Maybe<ClientCreateWithoutBudgetPlansInput>;
};

export type ClientCreateNestedOneWithoutChartOfAccountsInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutChartOfAccountsInput>;
  create?: Maybe<ClientCreateWithoutChartOfAccountsInput>;
};

export type ClientCreateNestedOneWithoutFileUploadRequestsInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutFileUploadRequestsInput>;
  create?: Maybe<ClientCreateWithoutFileUploadRequestsInput>;
};

export type ClientCreateNestedOneWithoutOwnersInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutOwnersInput>;
  create?: Maybe<ClientCreateWithoutOwnersInput>;
};

export type ClientCreateNestedOneWithoutPayrollCompensationItemInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutPayrollCompensationItemInput>;
  create?: Maybe<ClientCreateWithoutPayrollCompensationItemInput>;
};

export type ClientCreateNestedOneWithoutPayrollDefaultCompensationBaseValuesInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutPayrollDefaultCompensationBaseValuesInput>;
  create?: Maybe<ClientCreateWithoutPayrollDefaultCompensationBaseValuesInput>;
};

export type ClientCreateNestedOneWithoutPayrollDefaultCompensationBonusValuesInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutPayrollDefaultCompensationBonusValuesInput>;
  create?: Maybe<ClientCreateWithoutPayrollDefaultCompensationBonusValuesInput>;
};

export type ClientCreateNestedOneWithoutPayrollDefaultCompensationItemValuesInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutPayrollDefaultCompensationItemValuesInput>;
  create?: Maybe<ClientCreateWithoutPayrollDefaultCompensationItemValuesInput>;
};

export type ClientCreateNestedOneWithoutPayrollDefaultCompensationOvertimeValuesInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutPayrollDefaultCompensationOvertimeValuesInput>;
  create?: Maybe<ClientCreateWithoutPayrollDefaultCompensationOvertimeValuesInput>;
};

export type ClientCreateNestedOneWithoutPayrollDefaultCompensationRaiseValuesInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutPayrollDefaultCompensationRaiseValuesInput>;
  create?: Maybe<ClientCreateWithoutPayrollDefaultCompensationRaiseValuesInput>;
};

export type ClientCreateNestedOneWithoutPayrollPositionsInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutPayrollPositionsInput>;
  create?: Maybe<ClientCreateWithoutPayrollPositionsInput>;
};

export type ClientCreateNestedOneWithoutPropertiesInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutPropertiesInput>;
  create?: Maybe<ClientCreateWithoutPropertiesInput>;
};

export type ClientCreateNestedOneWithoutPropertyManagementsInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutPropertyManagementsInput>;
  create?: Maybe<ClientCreateWithoutPropertyManagementsInput>;
};

export type ClientCreateNestedOneWithoutRenovationClientConfigurationInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutRenovationClientConfigurationInput>;
  create?: Maybe<ClientCreateWithoutRenovationClientConfigurationInput>;
};

export type ClientCreateNestedOneWithoutRenovationCostCategoriesInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutRenovationCostCategoriesInput>;
  create?: Maybe<ClientCreateWithoutRenovationCostCategoriesInput>;
};

export type ClientCreateNestedOneWithoutReportTablesInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutReportTablesInput>;
  create?: Maybe<ClientCreateWithoutReportTablesInput>;
};

export type ClientCreateNestedOneWithoutSharedReportLinksInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutSharedReportLinksInput>;
  create?: Maybe<ClientCreateWithoutSharedReportLinksInput>;
};

export type ClientCreateNestedOneWithoutUnitLevelConfigurationInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutUnitLevelConfigurationInput>;
  create?: Maybe<ClientCreateWithoutUnitLevelConfigurationInput>;
};

export type ClientCreateNestedOneWithoutUserGroupsInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutUserGroupsInput>;
  create?: Maybe<ClientCreateWithoutUserGroupsInput>;
};

export type ClientCreateNestedOneWithoutUsersInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutUsersInput>;
  create?: Maybe<ClientCreateWithoutUsersInput>;
};

export type ClientCreateNestedOneWithoutYardiReportAccountMetadataInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutYardiReportAccountMetadataInput>;
  create?: Maybe<ClientCreateWithoutYardiReportAccountMetadataInput>;
};

export type ClientCreateOrConnectWithoutAccountsInput = {
  create: ClientCreateWithoutAccountsInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutAccountsV2Input = {
  create: ClientCreateWithoutAccountsV2Input;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutBudgetCategoryInput = {
  create: ClientCreateWithoutBudgetCategoryInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutBudgetComponentInput = {
  create: ClientCreateWithoutBudgetComponentInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutBudgetPlansInput = {
  create: ClientCreateWithoutBudgetPlansInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutChartOfAccountsInput = {
  create: ClientCreateWithoutChartOfAccountsInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutFileUploadRequestsInput = {
  create: ClientCreateWithoutFileUploadRequestsInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutOwnersInput = {
  create: ClientCreateWithoutOwnersInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutPayrollCompensationItemInput = {
  create: ClientCreateWithoutPayrollCompensationItemInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutPayrollDefaultCompensationBaseValuesInput = {
  create: ClientCreateWithoutPayrollDefaultCompensationBaseValuesInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutPayrollDefaultCompensationBonusValuesInput = {
  create: ClientCreateWithoutPayrollDefaultCompensationBonusValuesInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutPayrollDefaultCompensationItemValuesInput = {
  create: ClientCreateWithoutPayrollDefaultCompensationItemValuesInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutPayrollDefaultCompensationOvertimeValuesInput = {
  create: ClientCreateWithoutPayrollDefaultCompensationOvertimeValuesInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutPayrollDefaultCompensationRaiseValuesInput = {
  create: ClientCreateWithoutPayrollDefaultCompensationRaiseValuesInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutPayrollPositionsInput = {
  create: ClientCreateWithoutPayrollPositionsInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutPropertiesInput = {
  create: ClientCreateWithoutPropertiesInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutPropertyManagementsInput = {
  create: ClientCreateWithoutPropertyManagementsInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutRenovationClientConfigurationInput = {
  create: ClientCreateWithoutRenovationClientConfigurationInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutRenovationCostCategoriesInput = {
  create: ClientCreateWithoutRenovationCostCategoriesInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutReportTablesInput = {
  create: ClientCreateWithoutReportTablesInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutSharedReportLinksInput = {
  create: ClientCreateWithoutSharedReportLinksInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutUnitLevelConfigurationInput = {
  create: ClientCreateWithoutUnitLevelConfigurationInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutUserGroupsInput = {
  create: ClientCreateWithoutUserGroupsInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutUsersInput = {
  create: ClientCreateWithoutUsersInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutYardiReportAccountMetadataInput = {
  create: ClientCreateWithoutYardiReportAccountMetadataInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateWithoutAccountsInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutAccountsV2Input = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutBudgetCategoryInput = {
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutBudgetComponentInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutBudgetPlansInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutChartOfAccountsInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutFileUploadRequestsInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutOwnersInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutPayrollCompensationItemInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutPayrollDefaultCompensationBaseValuesInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutPayrollDefaultCompensationBonusValuesInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutPayrollDefaultCompensationItemValuesInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutPayrollDefaultCompensationOvertimeValuesInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutPayrollDefaultCompensationRaiseValuesInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutPayrollPositionsInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutPropertiesInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutPropertyManagementsInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutRenovationClientConfigurationInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutRenovationCostCategoriesInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutReportTablesInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutSharedReportLinksInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutUnitLevelConfigurationInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutUserGroupsInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutUsersInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutYardiReportAccountMetadataInput = {
  BudgetCategory?: Maybe<BudgetCategoryCreateNestedManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2CreateNestedManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigCreateNestedManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountCreateNestedManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2CreateNestedManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanCreateNestedManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotCreateNestedManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientCreateNestedManyWithoutClientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutClientInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owners?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationCreateNestedOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionCreateNestedManyWithoutClientInput>;
  properties?: Maybe<PropertyCreateNestedManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationCreateNestedOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryCreateNestedManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableCreateNestedManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutClientInput>;
  type?: Maybe<ClientType>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<UserGroupCreateNestedManyWithoutClientInput>;
  users?: Maybe<UserClientsCreateNestedManyWithoutClientInput>;
};

export type ClientFriendlyReportConfigurationClientIdBudgetYearCompoundUniqueInput = {
  budgetYear: Scalars['Int'];
  clientId: Scalars['String'];
};

export type ClientFriendlyReportConfigurationCreateManyClientInput = {
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  t12Month?: Maybe<Scalars['Int']>;
  t12Year?: Maybe<Scalars['Int']>;
};

export type ClientFriendlyReportConfigurationCreateManyClientInputEnvelope = {
  data: Array<ClientFriendlyReportConfigurationCreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClientFriendlyReportConfigurationCreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<ClientFriendlyReportConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClientFriendlyReportConfigurationCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<ClientFriendlyReportConfigurationCreateWithoutClientInput>>;
  createMany?: Maybe<ClientFriendlyReportConfigurationCreateManyClientInputEnvelope>;
};

export type ClientFriendlyReportConfigurationCreateOrConnectWithoutClientInput = {
  create: ClientFriendlyReportConfigurationCreateWithoutClientInput;
  where: ClientFriendlyReportConfigurationWhereUniqueInput;
};

export type ClientFriendlyReportConfigurationCreateWithoutClientInput = {
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  t12Month?: Maybe<Scalars['Int']>;
  t12Year?: Maybe<Scalars['Int']>;
};

export type ClientFriendlyReportConfigurationListRelationFilter = {
  every?: Maybe<ClientFriendlyReportConfigurationWhereInput>;
  none?: Maybe<ClientFriendlyReportConfigurationWhereInput>;
  some?: Maybe<ClientFriendlyReportConfigurationWhereInput>;
};

export type ClientFriendlyReportConfigurationScalarWhereInput = {
  AND?: Maybe<Array<ClientFriendlyReportConfigurationScalarWhereInput>>;
  NOT?: Maybe<Array<ClientFriendlyReportConfigurationScalarWhereInput>>;
  OR?: Maybe<Array<ClientFriendlyReportConfigurationScalarWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  clientId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  t12Month?: Maybe<IntNullableFilter>;
  t12Year?: Maybe<IntNullableFilter>;
};

export type ClientFriendlyReportConfigurationUpdateManyMutationInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  t12Month?: Maybe<NullableIntFieldUpdateOperationsInput>;
  t12Year?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type ClientFriendlyReportConfigurationUpdateManyWithWhereWithoutClientInput = {
  data: ClientFriendlyReportConfigurationUpdateManyMutationInput;
  where: ClientFriendlyReportConfigurationScalarWhereInput;
};

export type ClientFriendlyReportConfigurationUpdateManyWithoutClientInput = {
  connect?: Maybe<Array<ClientFriendlyReportConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClientFriendlyReportConfigurationCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<ClientFriendlyReportConfigurationCreateWithoutClientInput>>;
  createMany?: Maybe<ClientFriendlyReportConfigurationCreateManyClientInputEnvelope>;
  delete?: Maybe<Array<ClientFriendlyReportConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClientFriendlyReportConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<ClientFriendlyReportConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<ClientFriendlyReportConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<ClientFriendlyReportConfigurationUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<ClientFriendlyReportConfigurationUpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<ClientFriendlyReportConfigurationUpsertWithWhereUniqueWithoutClientInput>>;
};

export type ClientFriendlyReportConfigurationUpdateWithWhereUniqueWithoutClientInput = {
  data: ClientFriendlyReportConfigurationUpdateWithoutClientInput;
  where: ClientFriendlyReportConfigurationWhereUniqueInput;
};

export type ClientFriendlyReportConfigurationUpdateWithoutClientInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  t12Month?: Maybe<NullableIntFieldUpdateOperationsInput>;
  t12Year?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type ClientFriendlyReportConfigurationUpsertWithWhereUniqueWithoutClientInput = {
  create: ClientFriendlyReportConfigurationCreateWithoutClientInput;
  update: ClientFriendlyReportConfigurationUpdateWithoutClientInput;
  where: ClientFriendlyReportConfigurationWhereUniqueInput;
};

export type ClientFriendlyReportConfigurationWhereInput = {
  AND?: Maybe<Array<ClientFriendlyReportConfigurationWhereInput>>;
  NOT?: Maybe<Array<ClientFriendlyReportConfigurationWhereInput>>;
  OR?: Maybe<Array<ClientFriendlyReportConfigurationWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  t12Month?: Maybe<IntNullableFilter>;
  t12Year?: Maybe<IntNullableFilter>;
};

export type ClientFriendlyReportConfigurationWhereUniqueInput = {
  clientId_budgetYear?: Maybe<ClientFriendlyReportConfigurationClientIdBudgetYearCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type ClientRelationFilter = {
  is?: Maybe<ClientWhereInput>;
  isNot?: Maybe<ClientWhereInput>;
};

export type ClientReportT12AccountValues = {
  __typename?: 'ClientReportT12AccountValues';
  accountId: Scalars['ID'];
  values: Array<Scalars['Float']>;
};

export type ClientReportT12FinancialValues = {
  __typename?: 'ClientReportT12FinancialValues';
  financialValues: Array<ClientReportT12AccountValues>;
  t12Month: Scalars['Int'];
  t12Year: Scalars['Int'];
};

export enum ClientType {
  Owner = 'OWNER',
  OwnerOperator = 'OWNER_OPERATOR',
  PropertyManagement = 'PROPERTY_MANAGEMENT'
}

export type ClientUnitLevelConfiguration = {
  __typename?: 'ClientUnitLevelConfiguration';
  clientId: Scalars['String'];
  concessionsAccountId: Scalars['String'];
  renovationCostAccountId: Scalars['String'];
  unused?: Maybe<Scalars['Boolean']>;
};

export type ClientUnitLevelConfigurationCreateManyConcessionsAccountInput = {
  clientId: Scalars['String'];
  renovationCostAccountId: Scalars['String'];
  unused?: Maybe<Scalars['Boolean']>;
};

export type ClientUnitLevelConfigurationCreateManyConcessionsAccountInputEnvelope = {
  data: Array<ClientUnitLevelConfigurationCreateManyConcessionsAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClientUnitLevelConfigurationCreateManyRenovationCostAccountInput = {
  clientId: Scalars['String'];
  concessionsAccountId: Scalars['String'];
  unused?: Maybe<Scalars['Boolean']>;
};

export type ClientUnitLevelConfigurationCreateManyRenovationCostAccountInputEnvelope = {
  data: Array<ClientUnitLevelConfigurationCreateManyRenovationCostAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClientUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput = {
  connect?: Maybe<Array<ClientUnitLevelConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClientUnitLevelConfigurationCreateOrConnectWithoutConcessionsAccountInput>>;
  create?: Maybe<Array<ClientUnitLevelConfigurationCreateWithoutConcessionsAccountInput>>;
  createMany?: Maybe<ClientUnitLevelConfigurationCreateManyConcessionsAccountInputEnvelope>;
};

export type ClientUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput = {
  connect?: Maybe<Array<ClientUnitLevelConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClientUnitLevelConfigurationCreateOrConnectWithoutRenovationCostAccountInput>>;
  create?: Maybe<Array<ClientUnitLevelConfigurationCreateWithoutRenovationCostAccountInput>>;
  createMany?: Maybe<ClientUnitLevelConfigurationCreateManyRenovationCostAccountInputEnvelope>;
};

export type ClientUnitLevelConfigurationCreateNestedOneWithoutClientInput = {
  connect?: Maybe<ClientUnitLevelConfigurationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientUnitLevelConfigurationCreateOrConnectWithoutClientInput>;
  create?: Maybe<ClientUnitLevelConfigurationCreateWithoutClientInput>;
};

export type ClientUnitLevelConfigurationCreateOrConnectWithoutClientInput = {
  create: ClientUnitLevelConfigurationCreateWithoutClientInput;
  where: ClientUnitLevelConfigurationWhereUniqueInput;
};

export type ClientUnitLevelConfigurationCreateOrConnectWithoutConcessionsAccountInput = {
  create: ClientUnitLevelConfigurationCreateWithoutConcessionsAccountInput;
  where: ClientUnitLevelConfigurationWhereUniqueInput;
};

export type ClientUnitLevelConfigurationCreateOrConnectWithoutRenovationCostAccountInput = {
  create: ClientUnitLevelConfigurationCreateWithoutRenovationCostAccountInput;
  where: ClientUnitLevelConfigurationWhereUniqueInput;
};

export type ClientUnitLevelConfigurationCreateWithoutClientInput = {
  concessionsAccount: AccountV2CreateNestedOneWithoutClientUnitLevelConcessionConfigurationsInput;
  renovationCostAccount: AccountV2CreateNestedOneWithoutClientUnitLevelConfigurationsInput;
  unused?: Maybe<Scalars['Boolean']>;
};

export type ClientUnitLevelConfigurationCreateWithoutConcessionsAccountInput = {
  client: ClientCreateNestedOneWithoutUnitLevelConfigurationInput;
  renovationCostAccount: AccountV2CreateNestedOneWithoutClientUnitLevelConfigurationsInput;
  unused?: Maybe<Scalars['Boolean']>;
};

export type ClientUnitLevelConfigurationCreateWithoutRenovationCostAccountInput = {
  client: ClientCreateNestedOneWithoutUnitLevelConfigurationInput;
  concessionsAccount: AccountV2CreateNestedOneWithoutClientUnitLevelConcessionConfigurationsInput;
  unused?: Maybe<Scalars['Boolean']>;
};

export type ClientUnitLevelConfigurationListRelationFilter = {
  every?: Maybe<ClientUnitLevelConfigurationWhereInput>;
  none?: Maybe<ClientUnitLevelConfigurationWhereInput>;
  some?: Maybe<ClientUnitLevelConfigurationWhereInput>;
};

export type ClientUnitLevelConfigurationOrderByInput = {
  clientId?: Maybe<SortOrder>;
  concessionsAccountId?: Maybe<SortOrder>;
  renovationCostAccountId?: Maybe<SortOrder>;
  unused?: Maybe<SortOrder>;
};

export type ClientUnitLevelConfigurationRelationFilter = {
  is?: Maybe<ClientUnitLevelConfigurationWhereInput>;
  isNot?: Maybe<ClientUnitLevelConfigurationWhereInput>;
};

export enum ClientUnitLevelConfigurationScalarFieldEnum {
  ClientId = 'clientId',
  ConcessionsAccountId = 'concessionsAccountId',
  RenovationCostAccountId = 'renovationCostAccountId',
  Unused = 'unused'
}

export type ClientUnitLevelConfigurationScalarWhereInput = {
  AND?: Maybe<Array<ClientUnitLevelConfigurationScalarWhereInput>>;
  NOT?: Maybe<Array<ClientUnitLevelConfigurationScalarWhereInput>>;
  OR?: Maybe<Array<ClientUnitLevelConfigurationScalarWhereInput>>;
  clientId?: Maybe<StringFilter>;
  concessionsAccountId?: Maybe<StringFilter>;
  renovationCostAccountId?: Maybe<StringFilter>;
  unused?: Maybe<BoolNullableFilter>;
};

export type ClientUnitLevelConfigurationUpdateManyMutationInput = {
  unused?: Maybe<NullableBoolFieldUpdateOperationsInput>;
};

export type ClientUnitLevelConfigurationUpdateManyWithWhereWithoutConcessionsAccountInput = {
  data: ClientUnitLevelConfigurationUpdateManyMutationInput;
  where: ClientUnitLevelConfigurationScalarWhereInput;
};

export type ClientUnitLevelConfigurationUpdateManyWithWhereWithoutRenovationCostAccountInput = {
  data: ClientUnitLevelConfigurationUpdateManyMutationInput;
  where: ClientUnitLevelConfigurationScalarWhereInput;
};

export type ClientUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput = {
  connect?: Maybe<Array<ClientUnitLevelConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClientUnitLevelConfigurationCreateOrConnectWithoutConcessionsAccountInput>>;
  create?: Maybe<Array<ClientUnitLevelConfigurationCreateWithoutConcessionsAccountInput>>;
  createMany?: Maybe<ClientUnitLevelConfigurationCreateManyConcessionsAccountInputEnvelope>;
  delete?: Maybe<Array<ClientUnitLevelConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClientUnitLevelConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<ClientUnitLevelConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<ClientUnitLevelConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<ClientUnitLevelConfigurationUpdateWithWhereUniqueWithoutConcessionsAccountInput>>;
  updateMany?: Maybe<Array<ClientUnitLevelConfigurationUpdateManyWithWhereWithoutConcessionsAccountInput>>;
  upsert?: Maybe<Array<ClientUnitLevelConfigurationUpsertWithWhereUniqueWithoutConcessionsAccountInput>>;
};

export type ClientUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput = {
  connect?: Maybe<Array<ClientUnitLevelConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClientUnitLevelConfigurationCreateOrConnectWithoutRenovationCostAccountInput>>;
  create?: Maybe<Array<ClientUnitLevelConfigurationCreateWithoutRenovationCostAccountInput>>;
  createMany?: Maybe<ClientUnitLevelConfigurationCreateManyRenovationCostAccountInputEnvelope>;
  delete?: Maybe<Array<ClientUnitLevelConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ClientUnitLevelConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<ClientUnitLevelConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<ClientUnitLevelConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<ClientUnitLevelConfigurationUpdateWithWhereUniqueWithoutRenovationCostAccountInput>>;
  updateMany?: Maybe<Array<ClientUnitLevelConfigurationUpdateManyWithWhereWithoutRenovationCostAccountInput>>;
  upsert?: Maybe<Array<ClientUnitLevelConfigurationUpsertWithWhereUniqueWithoutRenovationCostAccountInput>>;
};

export type ClientUnitLevelConfigurationUpdateOneWithoutClientInput = {
  connect?: Maybe<ClientUnitLevelConfigurationWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientUnitLevelConfigurationCreateOrConnectWithoutClientInput>;
  create?: Maybe<ClientUnitLevelConfigurationCreateWithoutClientInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClientUnitLevelConfigurationUpdateWithoutClientInput>;
  upsert?: Maybe<ClientUnitLevelConfigurationUpsertWithoutClientInput>;
};

export type ClientUnitLevelConfigurationUpdateWithWhereUniqueWithoutConcessionsAccountInput = {
  data: ClientUnitLevelConfigurationUpdateWithoutConcessionsAccountInput;
  where: ClientUnitLevelConfigurationWhereUniqueInput;
};

export type ClientUnitLevelConfigurationUpdateWithWhereUniqueWithoutRenovationCostAccountInput = {
  data: ClientUnitLevelConfigurationUpdateWithoutRenovationCostAccountInput;
  where: ClientUnitLevelConfigurationWhereUniqueInput;
};

export type ClientUnitLevelConfigurationUpdateWithoutClientInput = {
  concessionsAccount?: Maybe<AccountV2UpdateOneRequiredWithoutClientUnitLevelConcessionConfigurationsInput>;
  renovationCostAccount?: Maybe<AccountV2UpdateOneRequiredWithoutClientUnitLevelConfigurationsInput>;
  unused?: Maybe<NullableBoolFieldUpdateOperationsInput>;
};

export type ClientUnitLevelConfigurationUpdateWithoutConcessionsAccountInput = {
  client?: Maybe<ClientUpdateOneRequiredWithoutUnitLevelConfigurationInput>;
  renovationCostAccount?: Maybe<AccountV2UpdateOneRequiredWithoutClientUnitLevelConfigurationsInput>;
  unused?: Maybe<NullableBoolFieldUpdateOperationsInput>;
};

export type ClientUnitLevelConfigurationUpdateWithoutRenovationCostAccountInput = {
  client?: Maybe<ClientUpdateOneRequiredWithoutUnitLevelConfigurationInput>;
  concessionsAccount?: Maybe<AccountV2UpdateOneRequiredWithoutClientUnitLevelConcessionConfigurationsInput>;
  unused?: Maybe<NullableBoolFieldUpdateOperationsInput>;
};

export type ClientUnitLevelConfigurationUpsertWithWhereUniqueWithoutConcessionsAccountInput = {
  create: ClientUnitLevelConfigurationCreateWithoutConcessionsAccountInput;
  update: ClientUnitLevelConfigurationUpdateWithoutConcessionsAccountInput;
  where: ClientUnitLevelConfigurationWhereUniqueInput;
};

export type ClientUnitLevelConfigurationUpsertWithWhereUniqueWithoutRenovationCostAccountInput = {
  create: ClientUnitLevelConfigurationCreateWithoutRenovationCostAccountInput;
  update: ClientUnitLevelConfigurationUpdateWithoutRenovationCostAccountInput;
  where: ClientUnitLevelConfigurationWhereUniqueInput;
};

export type ClientUnitLevelConfigurationUpsertWithoutClientInput = {
  create: ClientUnitLevelConfigurationCreateWithoutClientInput;
  update: ClientUnitLevelConfigurationUpdateWithoutClientInput;
};

export type ClientUnitLevelConfigurationWhereInput = {
  AND?: Maybe<Array<ClientUnitLevelConfigurationWhereInput>>;
  NOT?: Maybe<Array<ClientUnitLevelConfigurationWhereInput>>;
  OR?: Maybe<Array<ClientUnitLevelConfigurationWhereInput>>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  concessionsAccount?: Maybe<AccountV2RelationFilter>;
  concessionsAccountId?: Maybe<StringFilter>;
  renovationCostAccount?: Maybe<AccountV2RelationFilter>;
  renovationCostAccountId?: Maybe<StringFilter>;
  unused?: Maybe<BoolNullableFilter>;
};

export type ClientUnitLevelConfigurationWhereUniqueInput = {
  clientId?: Maybe<Scalars['String']>;
};

export type ClientUpdateOneRequiredWithoutAccountsInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutAccountsInput>;
  create?: Maybe<ClientCreateWithoutAccountsInput>;
  update?: Maybe<ClientUpdateWithoutAccountsInput>;
  upsert?: Maybe<ClientUpsertWithoutAccountsInput>;
};

export type ClientUpdateOneRequiredWithoutAccountsV2Input = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutAccountsV2Input>;
  create?: Maybe<ClientCreateWithoutAccountsV2Input>;
  update?: Maybe<ClientUpdateWithoutAccountsV2Input>;
  upsert?: Maybe<ClientUpsertWithoutAccountsV2Input>;
};

export type ClientUpdateOneRequiredWithoutBudgetComponentInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutBudgetComponentInput>;
  create?: Maybe<ClientCreateWithoutBudgetComponentInput>;
  update?: Maybe<ClientUpdateWithoutBudgetComponentInput>;
  upsert?: Maybe<ClientUpsertWithoutBudgetComponentInput>;
};

export type ClientUpdateOneRequiredWithoutBudgetPlansInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutBudgetPlansInput>;
  create?: Maybe<ClientCreateWithoutBudgetPlansInput>;
  update?: Maybe<ClientUpdateWithoutBudgetPlansInput>;
  upsert?: Maybe<ClientUpsertWithoutBudgetPlansInput>;
};

export type ClientUpdateOneRequiredWithoutChartOfAccountsInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutChartOfAccountsInput>;
  create?: Maybe<ClientCreateWithoutChartOfAccountsInput>;
  update?: Maybe<ClientUpdateWithoutChartOfAccountsInput>;
  upsert?: Maybe<ClientUpsertWithoutChartOfAccountsInput>;
};

export type ClientUpdateOneRequiredWithoutFileUploadRequestsInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutFileUploadRequestsInput>;
  create?: Maybe<ClientCreateWithoutFileUploadRequestsInput>;
  update?: Maybe<ClientUpdateWithoutFileUploadRequestsInput>;
  upsert?: Maybe<ClientUpsertWithoutFileUploadRequestsInput>;
};

export type ClientUpdateOneRequiredWithoutOwnersInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutOwnersInput>;
  create?: Maybe<ClientCreateWithoutOwnersInput>;
  update?: Maybe<ClientUpdateWithoutOwnersInput>;
  upsert?: Maybe<ClientUpsertWithoutOwnersInput>;
};

export type ClientUpdateOneRequiredWithoutPayrollCompensationItemInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutPayrollCompensationItemInput>;
  create?: Maybe<ClientCreateWithoutPayrollCompensationItemInput>;
  update?: Maybe<ClientUpdateWithoutPayrollCompensationItemInput>;
  upsert?: Maybe<ClientUpsertWithoutPayrollCompensationItemInput>;
};

export type ClientUpdateOneRequiredWithoutPayrollDefaultCompensationBaseValuesInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutPayrollDefaultCompensationBaseValuesInput>;
  create?: Maybe<ClientCreateWithoutPayrollDefaultCompensationBaseValuesInput>;
  update?: Maybe<ClientUpdateWithoutPayrollDefaultCompensationBaseValuesInput>;
  upsert?: Maybe<ClientUpsertWithoutPayrollDefaultCompensationBaseValuesInput>;
};

export type ClientUpdateOneRequiredWithoutPayrollDefaultCompensationBonusValuesInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutPayrollDefaultCompensationBonusValuesInput>;
  create?: Maybe<ClientCreateWithoutPayrollDefaultCompensationBonusValuesInput>;
  update?: Maybe<ClientUpdateWithoutPayrollDefaultCompensationBonusValuesInput>;
  upsert?: Maybe<ClientUpsertWithoutPayrollDefaultCompensationBonusValuesInput>;
};

export type ClientUpdateOneRequiredWithoutPayrollDefaultCompensationItemValuesInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutPayrollDefaultCompensationItemValuesInput>;
  create?: Maybe<ClientCreateWithoutPayrollDefaultCompensationItemValuesInput>;
  update?: Maybe<ClientUpdateWithoutPayrollDefaultCompensationItemValuesInput>;
  upsert?: Maybe<ClientUpsertWithoutPayrollDefaultCompensationItemValuesInput>;
};

export type ClientUpdateOneRequiredWithoutPayrollDefaultCompensationOvertimeValuesInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutPayrollDefaultCompensationOvertimeValuesInput>;
  create?: Maybe<ClientCreateWithoutPayrollDefaultCompensationOvertimeValuesInput>;
  update?: Maybe<ClientUpdateWithoutPayrollDefaultCompensationOvertimeValuesInput>;
  upsert?: Maybe<ClientUpsertWithoutPayrollDefaultCompensationOvertimeValuesInput>;
};

export type ClientUpdateOneRequiredWithoutPayrollDefaultCompensationRaiseValuesInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutPayrollDefaultCompensationRaiseValuesInput>;
  create?: Maybe<ClientCreateWithoutPayrollDefaultCompensationRaiseValuesInput>;
  update?: Maybe<ClientUpdateWithoutPayrollDefaultCompensationRaiseValuesInput>;
  upsert?: Maybe<ClientUpsertWithoutPayrollDefaultCompensationRaiseValuesInput>;
};

export type ClientUpdateOneRequiredWithoutPayrollPositionsInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutPayrollPositionsInput>;
  create?: Maybe<ClientCreateWithoutPayrollPositionsInput>;
  update?: Maybe<ClientUpdateWithoutPayrollPositionsInput>;
  upsert?: Maybe<ClientUpsertWithoutPayrollPositionsInput>;
};

export type ClientUpdateOneRequiredWithoutPropertiesInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutPropertiesInput>;
  create?: Maybe<ClientCreateWithoutPropertiesInput>;
  update?: Maybe<ClientUpdateWithoutPropertiesInput>;
  upsert?: Maybe<ClientUpsertWithoutPropertiesInput>;
};

export type ClientUpdateOneRequiredWithoutPropertyManagementsInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutPropertyManagementsInput>;
  create?: Maybe<ClientCreateWithoutPropertyManagementsInput>;
  update?: Maybe<ClientUpdateWithoutPropertyManagementsInput>;
  upsert?: Maybe<ClientUpsertWithoutPropertyManagementsInput>;
};

export type ClientUpdateOneRequiredWithoutRenovationClientConfigurationInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutRenovationClientConfigurationInput>;
  create?: Maybe<ClientCreateWithoutRenovationClientConfigurationInput>;
  update?: Maybe<ClientUpdateWithoutRenovationClientConfigurationInput>;
  upsert?: Maybe<ClientUpsertWithoutRenovationClientConfigurationInput>;
};

export type ClientUpdateOneRequiredWithoutRenovationCostCategoriesInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutRenovationCostCategoriesInput>;
  create?: Maybe<ClientCreateWithoutRenovationCostCategoriesInput>;
  update?: Maybe<ClientUpdateWithoutRenovationCostCategoriesInput>;
  upsert?: Maybe<ClientUpsertWithoutRenovationCostCategoriesInput>;
};

export type ClientUpdateOneRequiredWithoutReportTablesInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutReportTablesInput>;
  create?: Maybe<ClientCreateWithoutReportTablesInput>;
  update?: Maybe<ClientUpdateWithoutReportTablesInput>;
  upsert?: Maybe<ClientUpsertWithoutReportTablesInput>;
};

export type ClientUpdateOneRequiredWithoutSharedReportLinksInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutSharedReportLinksInput>;
  create?: Maybe<ClientCreateWithoutSharedReportLinksInput>;
  update?: Maybe<ClientUpdateWithoutSharedReportLinksInput>;
  upsert?: Maybe<ClientUpsertWithoutSharedReportLinksInput>;
};

export type ClientUpdateOneRequiredWithoutUnitLevelConfigurationInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutUnitLevelConfigurationInput>;
  create?: Maybe<ClientCreateWithoutUnitLevelConfigurationInput>;
  update?: Maybe<ClientUpdateWithoutUnitLevelConfigurationInput>;
  upsert?: Maybe<ClientUpsertWithoutUnitLevelConfigurationInput>;
};

export type ClientUpdateOneRequiredWithoutUserGroupsInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutUserGroupsInput>;
  create?: Maybe<ClientCreateWithoutUserGroupsInput>;
  update?: Maybe<ClientUpdateWithoutUserGroupsInput>;
  upsert?: Maybe<ClientUpsertWithoutUserGroupsInput>;
};

export type ClientUpdateOneRequiredWithoutUsersInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutUsersInput>;
  create?: Maybe<ClientCreateWithoutUsersInput>;
  update?: Maybe<ClientUpdateWithoutUsersInput>;
  upsert?: Maybe<ClientUpsertWithoutUsersInput>;
};

export type ClientUpdateOneRequiredWithoutYardiReportAccountMetadataInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutYardiReportAccountMetadataInput>;
  create?: Maybe<ClientCreateWithoutYardiReportAccountMetadataInput>;
  update?: Maybe<ClientUpdateWithoutYardiReportAccountMetadataInput>;
  upsert?: Maybe<ClientUpsertWithoutYardiReportAccountMetadataInput>;
};

export type ClientUpdateOneWithoutBudgetCategoryInput = {
  connect?: Maybe<ClientWhereUniqueInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutBudgetCategoryInput>;
  create?: Maybe<ClientCreateWithoutBudgetCategoryInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ClientUpdateWithoutBudgetCategoryInput>;
  upsert?: Maybe<ClientUpsertWithoutBudgetCategoryInput>;
};

export type ClientUpdateWithoutAccountsInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutAccountsV2Input = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutBudgetCategoryInput = {
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutBudgetComponentInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutBudgetPlansInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutChartOfAccountsInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutFileUploadRequestsInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutOwnersInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutPayrollCompensationItemInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutPayrollDefaultCompensationBaseValuesInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutPayrollDefaultCompensationBonusValuesInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutPayrollDefaultCompensationItemValuesInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutPayrollDefaultCompensationOvertimeValuesInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutPayrollDefaultCompensationRaiseValuesInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutPayrollPositionsInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutPropertiesInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutPropertyManagementsInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutRenovationClientConfigurationInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutRenovationCostCategoriesInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutReportTablesInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutSharedReportLinksInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutUnitLevelConfigurationInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutUserGroupsInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutUsersInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataUpdateManyWithoutClientInput>;
};

export type ClientUpdateWithoutYardiReportAccountMetadataInput = {
  BudgetCategory?: Maybe<BudgetCategoryUpdateManyWithoutClientInput>;
  BudgetComponent?: Maybe<BudgetComponentV2UpdateManyWithoutClientInput>;
  PowerBIConfig?: Maybe<PowerBiConfigUpdateManyWithoutVizibluClientInput>;
  accounts?: Maybe<AccountUpdateManyWithoutClientInput>;
  accountsV2?: Maybe<AccountV2UpdateManyWithoutClientInput>;
  budgetPlans?: Maybe<ClientBudgetPlanUpdateManyWithoutClientInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateManyWithoutClientInput>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotUpdateManyWithoutClientInput>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationUpdateManyWithoutClientInput>;
  clientLevelFeatures?: Maybe<FeatureClientUpdateManyWithoutClientInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutClientInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  owners?: Maybe<PropertyManagementOwnersUpdateManyWithoutOwnerInput>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationUpdateOneWithoutClientInput>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutClientInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutClientInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutClientInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput>;
  payrollPositions?: Maybe<PayrollPositionUpdateManyWithoutClientInput>;
  properties?: Maybe<PropertyUpdateManyWithoutClientInput>;
  propertyManagements?: Maybe<PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationUpdateOneWithoutClientInput>;
  renovationCostCategories?: Maybe<RenovationCostCategoryUpdateManyWithoutClientInput>;
  reportTables?: Maybe<ReportTableUpdateManyWithoutClientInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutClientInput>;
  type?: Maybe<EnumClientTypeFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationUpdateOneWithoutClientInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userGroups?: Maybe<UserGroupUpdateManyWithoutClientInput>;
  users?: Maybe<UserClientsUpdateManyWithoutClientInput>;
};

export type ClientUpsertWithoutAccountsInput = {
  create: ClientCreateWithoutAccountsInput;
  update: ClientUpdateWithoutAccountsInput;
};

export type ClientUpsertWithoutAccountsV2Input = {
  create: ClientCreateWithoutAccountsV2Input;
  update: ClientUpdateWithoutAccountsV2Input;
};

export type ClientUpsertWithoutBudgetCategoryInput = {
  create: ClientCreateWithoutBudgetCategoryInput;
  update: ClientUpdateWithoutBudgetCategoryInput;
};

export type ClientUpsertWithoutBudgetComponentInput = {
  create: ClientCreateWithoutBudgetComponentInput;
  update: ClientUpdateWithoutBudgetComponentInput;
};

export type ClientUpsertWithoutBudgetPlansInput = {
  create: ClientCreateWithoutBudgetPlansInput;
  update: ClientUpdateWithoutBudgetPlansInput;
};

export type ClientUpsertWithoutChartOfAccountsInput = {
  create: ClientCreateWithoutChartOfAccountsInput;
  update: ClientUpdateWithoutChartOfAccountsInput;
};

export type ClientUpsertWithoutFileUploadRequestsInput = {
  create: ClientCreateWithoutFileUploadRequestsInput;
  update: ClientUpdateWithoutFileUploadRequestsInput;
};

export type ClientUpsertWithoutOwnersInput = {
  create: ClientCreateWithoutOwnersInput;
  update: ClientUpdateWithoutOwnersInput;
};

export type ClientUpsertWithoutPayrollCompensationItemInput = {
  create: ClientCreateWithoutPayrollCompensationItemInput;
  update: ClientUpdateWithoutPayrollCompensationItemInput;
};

export type ClientUpsertWithoutPayrollDefaultCompensationBaseValuesInput = {
  create: ClientCreateWithoutPayrollDefaultCompensationBaseValuesInput;
  update: ClientUpdateWithoutPayrollDefaultCompensationBaseValuesInput;
};

export type ClientUpsertWithoutPayrollDefaultCompensationBonusValuesInput = {
  create: ClientCreateWithoutPayrollDefaultCompensationBonusValuesInput;
  update: ClientUpdateWithoutPayrollDefaultCompensationBonusValuesInput;
};

export type ClientUpsertWithoutPayrollDefaultCompensationItemValuesInput = {
  create: ClientCreateWithoutPayrollDefaultCompensationItemValuesInput;
  update: ClientUpdateWithoutPayrollDefaultCompensationItemValuesInput;
};

export type ClientUpsertWithoutPayrollDefaultCompensationOvertimeValuesInput = {
  create: ClientCreateWithoutPayrollDefaultCompensationOvertimeValuesInput;
  update: ClientUpdateWithoutPayrollDefaultCompensationOvertimeValuesInput;
};

export type ClientUpsertWithoutPayrollDefaultCompensationRaiseValuesInput = {
  create: ClientCreateWithoutPayrollDefaultCompensationRaiseValuesInput;
  update: ClientUpdateWithoutPayrollDefaultCompensationRaiseValuesInput;
};

export type ClientUpsertWithoutPayrollPositionsInput = {
  create: ClientCreateWithoutPayrollPositionsInput;
  update: ClientUpdateWithoutPayrollPositionsInput;
};

export type ClientUpsertWithoutPropertiesInput = {
  create: ClientCreateWithoutPropertiesInput;
  update: ClientUpdateWithoutPropertiesInput;
};

export type ClientUpsertWithoutPropertyManagementsInput = {
  create: ClientCreateWithoutPropertyManagementsInput;
  update: ClientUpdateWithoutPropertyManagementsInput;
};

export type ClientUpsertWithoutRenovationClientConfigurationInput = {
  create: ClientCreateWithoutRenovationClientConfigurationInput;
  update: ClientUpdateWithoutRenovationClientConfigurationInput;
};

export type ClientUpsertWithoutRenovationCostCategoriesInput = {
  create: ClientCreateWithoutRenovationCostCategoriesInput;
  update: ClientUpdateWithoutRenovationCostCategoriesInput;
};

export type ClientUpsertWithoutReportTablesInput = {
  create: ClientCreateWithoutReportTablesInput;
  update: ClientUpdateWithoutReportTablesInput;
};

export type ClientUpsertWithoutSharedReportLinksInput = {
  create: ClientCreateWithoutSharedReportLinksInput;
  update: ClientUpdateWithoutSharedReportLinksInput;
};

export type ClientUpsertWithoutUnitLevelConfigurationInput = {
  create: ClientCreateWithoutUnitLevelConfigurationInput;
  update: ClientUpdateWithoutUnitLevelConfigurationInput;
};

export type ClientUpsertWithoutUserGroupsInput = {
  create: ClientCreateWithoutUserGroupsInput;
  update: ClientUpdateWithoutUserGroupsInput;
};

export type ClientUpsertWithoutUsersInput = {
  create: ClientCreateWithoutUsersInput;
  update: ClientUpdateWithoutUsersInput;
};

export type ClientUpsertWithoutYardiReportAccountMetadataInput = {
  create: ClientCreateWithoutYardiReportAccountMetadataInput;
  update: ClientUpdateWithoutYardiReportAccountMetadataInput;
};

export type ClientWhereInput = {
  AND?: Maybe<Array<ClientWhereInput>>;
  BudgetCategory?: Maybe<BudgetCategoryListRelationFilter>;
  BudgetComponent?: Maybe<BudgetComponentV2ListRelationFilter>;
  NOT?: Maybe<Array<ClientWhereInput>>;
  OR?: Maybe<Array<ClientWhereInput>>;
  PowerBIConfig?: Maybe<PowerBiConfigListRelationFilter>;
  accounts?: Maybe<AccountListRelationFilter>;
  accountsV2?: Maybe<AccountV2ListRelationFilter>;
  budgetPlans?: Maybe<ClientBudgetPlanListRelationFilter>;
  chartOfAccounts?: Maybe<ChartOfAccountsListRelationFilter>;
  chartOfAccountsSnapshots?: Maybe<ChartOfAccountsSnapshotListRelationFilter>;
  clientFriendlyReportConfiguration?: Maybe<ClientFriendlyReportConfigurationListRelationFilter>;
  clientLevelFeatures?: Maybe<FeatureClientListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  displayName?: Maybe<StringFilter>;
  fileUploadRequests?: Maybe<FileUploadRequestListRelationFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  owners?: Maybe<PropertyManagementOwnersListRelationFilter>;
  payrollClientConfiguration?: Maybe<PayrollClientConfigurationRelationFilter>;
  payrollCompensationItem?: Maybe<PayrollCompensationItemListRelationFilter>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseListRelationFilter>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusListRelationFilter>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemListRelationFilter>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeListRelationFilter>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseListRelationFilter>;
  payrollPositions?: Maybe<PayrollPositionListRelationFilter>;
  properties?: Maybe<PropertyListRelationFilter>;
  propertyManagements?: Maybe<PropertyManagementOwnersListRelationFilter>;
  renovationClientConfiguration?: Maybe<RenovationClientConfigurationRelationFilter>;
  renovationCostCategories?: Maybe<RenovationCostCategoryListRelationFilter>;
  reportTables?: Maybe<ReportTableListRelationFilter>;
  sharedReportLinks?: Maybe<SharedReportLinkListRelationFilter>;
  type?: Maybe<EnumClientTypeFilter>;
  unitLevelConfiguration?: Maybe<ClientUnitLevelConfigurationRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userGroups?: Maybe<UserGroupListRelationFilter>;
  users?: Maybe<UserClientsListRelationFilter>;
  yardiReportAccountMetadata?: Maybe<YardiReportAccountMetadataListRelationFilter>;
};

export type ClientWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CopyDriversResponseModel = {
  __typename?: 'CopyDriversResponseModel';
  destinationPropertyId: Scalars['ID'];
  result: Scalars['Boolean'];
  resultString: Scalars['String'];
};

export type CopyRevenueAssumptionsClientLevelResponseModel = {
  __typename?: 'CopyRevenueAssumptionsClientLevelResponseModel';
  result: Scalars['Boolean'];
  resultString: Scalars['String'];
};

export type CostCategoryInput = {
  amount?: Maybe<Scalars['Decimal']>;
  /** The id of the RenovationCostCategory */
  costCategoryId: Scalars['String'];
  /** An integer number of months between [0, 12] that determines how long to wait until the cost of the renovation is added into the budget. If not specified on create, default will be 0. */
  delayRecognition?: Maybe<Scalars['Int']>;
};

export type CustomDriverItemAssumptionModel = {
  __typename?: 'CustomDriverItemAssumptionModel';
  amount?: Maybe<Scalars['Decimal']>;
  count?: Maybe<Scalars['Decimal']>;
  percentOf?: Maybe<Scalars['Decimal']>;
};

export type CustomDriverItemForPropertyDriversUpdateInput = {
  amountMonth0?: Maybe<Scalars['Float']>;
  amountMonth1?: Maybe<Scalars['Float']>;
  amountMonth2?: Maybe<Scalars['Float']>;
  amountMonth3?: Maybe<Scalars['Float']>;
  amountMonth4?: Maybe<Scalars['Float']>;
  amountMonth5?: Maybe<Scalars['Float']>;
  amountMonth6?: Maybe<Scalars['Float']>;
  amountMonth7?: Maybe<Scalars['Float']>;
  amountMonth8?: Maybe<Scalars['Float']>;
  amountMonth9?: Maybe<Scalars['Float']>;
  amountMonth10?: Maybe<Scalars['Float']>;
  amountMonth11?: Maybe<Scalars['Float']>;
  countMonth0?: Maybe<Scalars['Float']>;
  countMonth1?: Maybe<Scalars['Float']>;
  countMonth2?: Maybe<Scalars['Float']>;
  countMonth3?: Maybe<Scalars['Float']>;
  countMonth4?: Maybe<Scalars['Float']>;
  countMonth5?: Maybe<Scalars['Float']>;
  countMonth6?: Maybe<Scalars['Float']>;
  countMonth7?: Maybe<Scalars['Float']>;
  countMonth8?: Maybe<Scalars['Float']>;
  countMonth9?: Maybe<Scalars['Float']>;
  countMonth10?: Maybe<Scalars['Float']>;
  countMonth11?: Maybe<Scalars['Float']>;
  destinationAccountId: Scalars['String'];
  itemName: Scalars['String'];
  percentMonth0?: Maybe<Scalars['Float']>;
  percentMonth1?: Maybe<Scalars['Float']>;
  percentMonth2?: Maybe<Scalars['Float']>;
  percentMonth3?: Maybe<Scalars['Float']>;
  percentMonth4?: Maybe<Scalars['Float']>;
  percentMonth5?: Maybe<Scalars['Float']>;
  percentMonth6?: Maybe<Scalars['Float']>;
  percentMonth7?: Maybe<Scalars['Float']>;
  percentMonth8?: Maybe<Scalars['Float']>;
  percentMonth9?: Maybe<Scalars['Float']>;
  percentMonth10?: Maybe<Scalars['Float']>;
  percentMonth11?: Maybe<Scalars['Float']>;
  propertyId: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Float'];
};

export type CustomDriverItemModel = {
  __typename?: 'CustomDriverItemModel';
  assumptions: Array<CustomDriverItemAssumptionModel>;
  id: Scalars['ID'];
  itemName: Scalars['String'];
};

export type CustomDriverItemUpdateInput = {
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  countMonth0?: Maybe<Scalars['Decimal']>;
  countMonth1?: Maybe<Scalars['Decimal']>;
  countMonth2?: Maybe<Scalars['Decimal']>;
  countMonth3?: Maybe<Scalars['Decimal']>;
  countMonth4?: Maybe<Scalars['Decimal']>;
  countMonth5?: Maybe<Scalars['Decimal']>;
  countMonth6?: Maybe<Scalars['Decimal']>;
  countMonth7?: Maybe<Scalars['Decimal']>;
  countMonth8?: Maybe<Scalars['Decimal']>;
  countMonth9?: Maybe<Scalars['Decimal']>;
  countMonth10?: Maybe<Scalars['Decimal']>;
  countMonth11?: Maybe<Scalars['Decimal']>;
  id: Scalars['ID'];
  percentMonth0?: Maybe<Scalars['Decimal']>;
  percentMonth1?: Maybe<Scalars['Decimal']>;
  percentMonth2?: Maybe<Scalars['Decimal']>;
  percentMonth3?: Maybe<Scalars['Decimal']>;
  percentMonth4?: Maybe<Scalars['Decimal']>;
  percentMonth5?: Maybe<Scalars['Decimal']>;
  percentMonth6?: Maybe<Scalars['Decimal']>;
  percentMonth7?: Maybe<Scalars['Decimal']>;
  percentMonth8?: Maybe<Scalars['Decimal']>;
  percentMonth9?: Maybe<Scalars['Decimal']>;
  percentMonth10?: Maybe<Scalars['Decimal']>;
  percentMonth11?: Maybe<Scalars['Decimal']>;
};

export type CustomDriverModel = {
  __typename?: 'CustomDriverModel';
  isDriven: Scalars['Boolean'];
  items: Array<CustomDriverItemModel>;
};

export type CustomItemUpdateResultModel = {
  __typename?: 'CustomItemUpdateResultModel';
  errorCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CustomItemViewModel = {
  __typename?: 'CustomItemViewModel';
  id: Scalars['ID'];
  name: Scalars['String'];
};



export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedDateTimeFilter>;
  _min?: Maybe<NestedDateTimeFilter>;
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type DebugModel = {
  __typename?: 'DebugModel';
  workbookUrl?: Maybe<Scalars['String']>;
};


export type DecimalFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Decimal']>;
  divide?: Maybe<Scalars['Decimal']>;
  increment?: Maybe<Scalars['Decimal']>;
  multiply?: Maybe<Scalars['Decimal']>;
  set?: Maybe<Scalars['Decimal']>;
};

export type DecimalFilter = {
  equals?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalFilter>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
};

export type DecimalNullableFilter = {
  equals?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalNullableFilter>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
};

export type DecimalNullableWithAggregatesFilter = {
  _avg?: Maybe<NestedDecimalNullableFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedDecimalNullableFilter>;
  _min?: Maybe<NestedDecimalNullableFilter>;
  _sum?: Maybe<NestedDecimalNullableFilter>;
  equals?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
};

export type DecimalWithAggregatesFilter = {
  _avg?: Maybe<NestedDecimalFilter>;
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedDecimalFilter>;
  _min?: Maybe<NestedDecimalFilter>;
  _sum?: Maybe<NestedDecimalFilter>;
  equals?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
};

export enum DistributionMethod {
  Flat = 'FLAT',
  Seasonal = 'SEASONAL'
}

export type DriverAccountMetric = {
  accountId: Scalars['String'];
  lookbackPeriod: Scalars['Int'];
};

export type DriverAccountPercentage = {
  __typename?: 'DriverAccountPercentage';
  createdAt: Scalars['DateTime'];
  delayRecognition: Scalars['Int'];
  destinationAccountId: Scalars['String'];
  id: Scalars['String'];
  percentMonth0?: Maybe<Scalars['Decimal']>;
  percentMonth1?: Maybe<Scalars['Decimal']>;
  percentMonth2?: Maybe<Scalars['Decimal']>;
  percentMonth3?: Maybe<Scalars['Decimal']>;
  percentMonth4?: Maybe<Scalars['Decimal']>;
  percentMonth5?: Maybe<Scalars['Decimal']>;
  percentMonth6?: Maybe<Scalars['Decimal']>;
  percentMonth7?: Maybe<Scalars['Decimal']>;
  percentMonth8?: Maybe<Scalars['Decimal']>;
  percentMonth9?: Maybe<Scalars['Decimal']>;
  percentMonth10?: Maybe<Scalars['Decimal']>;
  percentMonth11?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  sourceAccountId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverAccountPercentageAugmentInput = {
  maxValue?: Maybe<Scalars['Decimal']>;
  minValue?: Maybe<Scalars['Decimal']>;
};

export type DriverAccountPercentageCreateManyDestinationAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  percentMonth0?: Maybe<Scalars['Decimal']>;
  percentMonth1?: Maybe<Scalars['Decimal']>;
  percentMonth2?: Maybe<Scalars['Decimal']>;
  percentMonth3?: Maybe<Scalars['Decimal']>;
  percentMonth4?: Maybe<Scalars['Decimal']>;
  percentMonth5?: Maybe<Scalars['Decimal']>;
  percentMonth6?: Maybe<Scalars['Decimal']>;
  percentMonth7?: Maybe<Scalars['Decimal']>;
  percentMonth8?: Maybe<Scalars['Decimal']>;
  percentMonth9?: Maybe<Scalars['Decimal']>;
  percentMonth10?: Maybe<Scalars['Decimal']>;
  percentMonth11?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  sourceAccountId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverAccountPercentageCreateManyDestinationAccountInputEnvelope = {
  data: Array<DriverAccountPercentageCreateManyDestinationAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DriverAccountPercentageCreateManyPropertyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition: Scalars['Int'];
  destinationAccountId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  percentMonth0?: Maybe<Scalars['Decimal']>;
  percentMonth1?: Maybe<Scalars['Decimal']>;
  percentMonth2?: Maybe<Scalars['Decimal']>;
  percentMonth3?: Maybe<Scalars['Decimal']>;
  percentMonth4?: Maybe<Scalars['Decimal']>;
  percentMonth5?: Maybe<Scalars['Decimal']>;
  percentMonth6?: Maybe<Scalars['Decimal']>;
  percentMonth7?: Maybe<Scalars['Decimal']>;
  percentMonth8?: Maybe<Scalars['Decimal']>;
  percentMonth9?: Maybe<Scalars['Decimal']>;
  percentMonth10?: Maybe<Scalars['Decimal']>;
  percentMonth11?: Maybe<Scalars['Decimal']>;
  sourceAccountId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverAccountPercentageCreateManyPropertyInputEnvelope = {
  data: Array<DriverAccountPercentageCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DriverAccountPercentageCreateManySourceAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition: Scalars['Int'];
  destinationAccountId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  percentMonth0?: Maybe<Scalars['Decimal']>;
  percentMonth1?: Maybe<Scalars['Decimal']>;
  percentMonth2?: Maybe<Scalars['Decimal']>;
  percentMonth3?: Maybe<Scalars['Decimal']>;
  percentMonth4?: Maybe<Scalars['Decimal']>;
  percentMonth5?: Maybe<Scalars['Decimal']>;
  percentMonth6?: Maybe<Scalars['Decimal']>;
  percentMonth7?: Maybe<Scalars['Decimal']>;
  percentMonth8?: Maybe<Scalars['Decimal']>;
  percentMonth9?: Maybe<Scalars['Decimal']>;
  percentMonth10?: Maybe<Scalars['Decimal']>;
  percentMonth11?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverAccountPercentageCreateManySourceAccountInputEnvelope = {
  data: Array<DriverAccountPercentageCreateManySourceAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DriverAccountPercentageCreateNestedManyWithoutDestinationAccountInput = {
  connect?: Maybe<Array<DriverAccountPercentageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverAccountPercentageCreateOrConnectWithoutDestinationAccountInput>>;
  create?: Maybe<Array<DriverAccountPercentageCreateWithoutDestinationAccountInput>>;
  createMany?: Maybe<DriverAccountPercentageCreateManyDestinationAccountInputEnvelope>;
};

export type DriverAccountPercentageCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<DriverAccountPercentageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverAccountPercentageCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<DriverAccountPercentageCreateWithoutPropertyInput>>;
  createMany?: Maybe<DriverAccountPercentageCreateManyPropertyInputEnvelope>;
};

export type DriverAccountPercentageCreateNestedManyWithoutSourceAccountInput = {
  connect?: Maybe<Array<DriverAccountPercentageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverAccountPercentageCreateOrConnectWithoutSourceAccountInput>>;
  create?: Maybe<Array<DriverAccountPercentageCreateWithoutSourceAccountInput>>;
  createMany?: Maybe<DriverAccountPercentageCreateManySourceAccountInputEnvelope>;
};

export type DriverAccountPercentageCreateOrConnectWithoutDestinationAccountInput = {
  create: DriverAccountPercentageCreateWithoutDestinationAccountInput;
  where: DriverAccountPercentageWhereUniqueInput;
};

export type DriverAccountPercentageCreateOrConnectWithoutPropertyInput = {
  create: DriverAccountPercentageCreateWithoutPropertyInput;
  where: DriverAccountPercentageWhereUniqueInput;
};

export type DriverAccountPercentageCreateOrConnectWithoutSourceAccountInput = {
  create: DriverAccountPercentageCreateWithoutSourceAccountInput;
  where: DriverAccountPercentageWhereUniqueInput;
};

export type DriverAccountPercentageCreateWithoutDestinationAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  percentMonth0?: Maybe<Scalars['Decimal']>;
  percentMonth1?: Maybe<Scalars['Decimal']>;
  percentMonth2?: Maybe<Scalars['Decimal']>;
  percentMonth3?: Maybe<Scalars['Decimal']>;
  percentMonth4?: Maybe<Scalars['Decimal']>;
  percentMonth5?: Maybe<Scalars['Decimal']>;
  percentMonth6?: Maybe<Scalars['Decimal']>;
  percentMonth7?: Maybe<Scalars['Decimal']>;
  percentMonth8?: Maybe<Scalars['Decimal']>;
  percentMonth9?: Maybe<Scalars['Decimal']>;
  percentMonth10?: Maybe<Scalars['Decimal']>;
  percentMonth11?: Maybe<Scalars['Decimal']>;
  property: PropertyCreateNestedOneWithoutAccountPercentageDriversInput;
  sourceAccount: AccountV2CreateNestedOneWithoutConsumedByAccountPercentageDriversInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverAccountPercentageCreateWithoutPropertyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition: Scalars['Int'];
  destinationAccount: AccountV2CreateNestedOneWithoutAssignedAccountPercentageDriversInput;
  id?: Maybe<Scalars['String']>;
  percentMonth0?: Maybe<Scalars['Decimal']>;
  percentMonth1?: Maybe<Scalars['Decimal']>;
  percentMonth2?: Maybe<Scalars['Decimal']>;
  percentMonth3?: Maybe<Scalars['Decimal']>;
  percentMonth4?: Maybe<Scalars['Decimal']>;
  percentMonth5?: Maybe<Scalars['Decimal']>;
  percentMonth6?: Maybe<Scalars['Decimal']>;
  percentMonth7?: Maybe<Scalars['Decimal']>;
  percentMonth8?: Maybe<Scalars['Decimal']>;
  percentMonth9?: Maybe<Scalars['Decimal']>;
  percentMonth10?: Maybe<Scalars['Decimal']>;
  percentMonth11?: Maybe<Scalars['Decimal']>;
  sourceAccount: AccountV2CreateNestedOneWithoutConsumedByAccountPercentageDriversInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverAccountPercentageCreateWithoutSourceAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition: Scalars['Int'];
  destinationAccount: AccountV2CreateNestedOneWithoutAssignedAccountPercentageDriversInput;
  id?: Maybe<Scalars['String']>;
  percentMonth0?: Maybe<Scalars['Decimal']>;
  percentMonth1?: Maybe<Scalars['Decimal']>;
  percentMonth2?: Maybe<Scalars['Decimal']>;
  percentMonth3?: Maybe<Scalars['Decimal']>;
  percentMonth4?: Maybe<Scalars['Decimal']>;
  percentMonth5?: Maybe<Scalars['Decimal']>;
  percentMonth6?: Maybe<Scalars['Decimal']>;
  percentMonth7?: Maybe<Scalars['Decimal']>;
  percentMonth8?: Maybe<Scalars['Decimal']>;
  percentMonth9?: Maybe<Scalars['Decimal']>;
  percentMonth10?: Maybe<Scalars['Decimal']>;
  percentMonth11?: Maybe<Scalars['Decimal']>;
  property: PropertyCreateNestedOneWithoutAccountPercentageDriversInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverAccountPercentageListRelationFilter = {
  every?: Maybe<DriverAccountPercentageWhereInput>;
  none?: Maybe<DriverAccountPercentageWhereInput>;
  some?: Maybe<DriverAccountPercentageWhereInput>;
};

export type DriverAccountPercentageOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  delayRecognition?: Maybe<SortOrder>;
  destinationAccountId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  percentMonth0?: Maybe<SortOrder>;
  percentMonth1?: Maybe<SortOrder>;
  percentMonth2?: Maybe<SortOrder>;
  percentMonth3?: Maybe<SortOrder>;
  percentMonth4?: Maybe<SortOrder>;
  percentMonth5?: Maybe<SortOrder>;
  percentMonth6?: Maybe<SortOrder>;
  percentMonth7?: Maybe<SortOrder>;
  percentMonth8?: Maybe<SortOrder>;
  percentMonth9?: Maybe<SortOrder>;
  percentMonth10?: Maybe<SortOrder>;
  percentMonth11?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  sourceAccountId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  versionType?: Maybe<SortOrder>;
  year?: Maybe<SortOrder>;
};

export enum DriverAccountPercentageScalarFieldEnum {
  CreatedAt = 'createdAt',
  DelayRecognition = 'delayRecognition',
  DestinationAccountId = 'destinationAccountId',
  Id = 'id',
  PercentMonth0 = 'percentMonth0',
  PercentMonth1 = 'percentMonth1',
  PercentMonth2 = 'percentMonth2',
  PercentMonth3 = 'percentMonth3',
  PercentMonth4 = 'percentMonth4',
  PercentMonth5 = 'percentMonth5',
  PercentMonth6 = 'percentMonth6',
  PercentMonth7 = 'percentMonth7',
  PercentMonth8 = 'percentMonth8',
  PercentMonth9 = 'percentMonth9',
  PercentMonth10 = 'percentMonth10',
  PercentMonth11 = 'percentMonth11',
  PropertyId = 'propertyId',
  SourceAccountId = 'sourceAccountId',
  UpdatedAt = 'updatedAt',
  VersionType = 'versionType',
  Year = 'year'
}

export type DriverAccountPercentageScalarWhereInput = {
  AND?: Maybe<Array<DriverAccountPercentageScalarWhereInput>>;
  NOT?: Maybe<Array<DriverAccountPercentageScalarWhereInput>>;
  OR?: Maybe<Array<DriverAccountPercentageScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  delayRecognition?: Maybe<IntFilter>;
  destinationAccountId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  percentMonth0?: Maybe<DecimalNullableFilter>;
  percentMonth1?: Maybe<DecimalNullableFilter>;
  percentMonth2?: Maybe<DecimalNullableFilter>;
  percentMonth3?: Maybe<DecimalNullableFilter>;
  percentMonth4?: Maybe<DecimalNullableFilter>;
  percentMonth5?: Maybe<DecimalNullableFilter>;
  percentMonth6?: Maybe<DecimalNullableFilter>;
  percentMonth7?: Maybe<DecimalNullableFilter>;
  percentMonth8?: Maybe<DecimalNullableFilter>;
  percentMonth9?: Maybe<DecimalNullableFilter>;
  percentMonth10?: Maybe<DecimalNullableFilter>;
  percentMonth11?: Maybe<DecimalNullableFilter>;
  propertyId?: Maybe<StringFilter>;
  sourceAccountId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type DriverAccountPercentageUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  percentMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DriverAccountPercentageUpdateManyWithWhereWithoutDestinationAccountInput = {
  data: DriverAccountPercentageUpdateManyMutationInput;
  where: DriverAccountPercentageScalarWhereInput;
};

export type DriverAccountPercentageUpdateManyWithWhereWithoutPropertyInput = {
  data: DriverAccountPercentageUpdateManyMutationInput;
  where: DriverAccountPercentageScalarWhereInput;
};

export type DriverAccountPercentageUpdateManyWithWhereWithoutSourceAccountInput = {
  data: DriverAccountPercentageUpdateManyMutationInput;
  where: DriverAccountPercentageScalarWhereInput;
};

export type DriverAccountPercentageUpdateManyWithoutDestinationAccountInput = {
  connect?: Maybe<Array<DriverAccountPercentageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverAccountPercentageCreateOrConnectWithoutDestinationAccountInput>>;
  create?: Maybe<Array<DriverAccountPercentageCreateWithoutDestinationAccountInput>>;
  createMany?: Maybe<DriverAccountPercentageCreateManyDestinationAccountInputEnvelope>;
  delete?: Maybe<Array<DriverAccountPercentageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DriverAccountPercentageScalarWhereInput>>;
  disconnect?: Maybe<Array<DriverAccountPercentageWhereUniqueInput>>;
  set?: Maybe<Array<DriverAccountPercentageWhereUniqueInput>>;
  update?: Maybe<Array<DriverAccountPercentageUpdateWithWhereUniqueWithoutDestinationAccountInput>>;
  updateMany?: Maybe<Array<DriverAccountPercentageUpdateManyWithWhereWithoutDestinationAccountInput>>;
  upsert?: Maybe<Array<DriverAccountPercentageUpsertWithWhereUniqueWithoutDestinationAccountInput>>;
};

export type DriverAccountPercentageUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<DriverAccountPercentageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverAccountPercentageCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<DriverAccountPercentageCreateWithoutPropertyInput>>;
  createMany?: Maybe<DriverAccountPercentageCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<DriverAccountPercentageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DriverAccountPercentageScalarWhereInput>>;
  disconnect?: Maybe<Array<DriverAccountPercentageWhereUniqueInput>>;
  set?: Maybe<Array<DriverAccountPercentageWhereUniqueInput>>;
  update?: Maybe<Array<DriverAccountPercentageUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<DriverAccountPercentageUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<DriverAccountPercentageUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type DriverAccountPercentageUpdateManyWithoutSourceAccountInput = {
  connect?: Maybe<Array<DriverAccountPercentageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverAccountPercentageCreateOrConnectWithoutSourceAccountInput>>;
  create?: Maybe<Array<DriverAccountPercentageCreateWithoutSourceAccountInput>>;
  createMany?: Maybe<DriverAccountPercentageCreateManySourceAccountInputEnvelope>;
  delete?: Maybe<Array<DriverAccountPercentageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DriverAccountPercentageScalarWhereInput>>;
  disconnect?: Maybe<Array<DriverAccountPercentageWhereUniqueInput>>;
  set?: Maybe<Array<DriverAccountPercentageWhereUniqueInput>>;
  update?: Maybe<Array<DriverAccountPercentageUpdateWithWhereUniqueWithoutSourceAccountInput>>;
  updateMany?: Maybe<Array<DriverAccountPercentageUpdateManyWithWhereWithoutSourceAccountInput>>;
  upsert?: Maybe<Array<DriverAccountPercentageUpsertWithWhereUniqueWithoutSourceAccountInput>>;
};

export type DriverAccountPercentageUpdateWithWhereUniqueWithoutDestinationAccountInput = {
  data: DriverAccountPercentageUpdateWithoutDestinationAccountInput;
  where: DriverAccountPercentageWhereUniqueInput;
};

export type DriverAccountPercentageUpdateWithWhereUniqueWithoutPropertyInput = {
  data: DriverAccountPercentageUpdateWithoutPropertyInput;
  where: DriverAccountPercentageWhereUniqueInput;
};

export type DriverAccountPercentageUpdateWithWhereUniqueWithoutSourceAccountInput = {
  data: DriverAccountPercentageUpdateWithoutSourceAccountInput;
  where: DriverAccountPercentageWhereUniqueInput;
};

export type DriverAccountPercentageUpdateWithoutDestinationAccountInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  percentMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutAccountPercentageDriversInput>;
  sourceAccount?: Maybe<AccountV2UpdateOneRequiredWithoutConsumedByAccountPercentageDriversInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DriverAccountPercentageUpdateWithoutPropertyInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  destinationAccount?: Maybe<AccountV2UpdateOneRequiredWithoutAssignedAccountPercentageDriversInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  percentMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  sourceAccount?: Maybe<AccountV2UpdateOneRequiredWithoutConsumedByAccountPercentageDriversInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DriverAccountPercentageUpdateWithoutSourceAccountInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  destinationAccount?: Maybe<AccountV2UpdateOneRequiredWithoutAssignedAccountPercentageDriversInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  percentMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutAccountPercentageDriversInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DriverAccountPercentageUpsertWithWhereUniqueWithoutDestinationAccountInput = {
  create: DriverAccountPercentageCreateWithoutDestinationAccountInput;
  update: DriverAccountPercentageUpdateWithoutDestinationAccountInput;
  where: DriverAccountPercentageWhereUniqueInput;
};

export type DriverAccountPercentageUpsertWithWhereUniqueWithoutPropertyInput = {
  create: DriverAccountPercentageCreateWithoutPropertyInput;
  update: DriverAccountPercentageUpdateWithoutPropertyInput;
  where: DriverAccountPercentageWhereUniqueInput;
};

export type DriverAccountPercentageUpsertWithWhereUniqueWithoutSourceAccountInput = {
  create: DriverAccountPercentageCreateWithoutSourceAccountInput;
  update: DriverAccountPercentageUpdateWithoutSourceAccountInput;
  where: DriverAccountPercentageWhereUniqueInput;
};

export type DriverAccountPercentageWhereInput = {
  AND?: Maybe<Array<DriverAccountPercentageWhereInput>>;
  NOT?: Maybe<Array<DriverAccountPercentageWhereInput>>;
  OR?: Maybe<Array<DriverAccountPercentageWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  delayRecognition?: Maybe<IntFilter>;
  destinationAccount?: Maybe<AccountV2RelationFilter>;
  destinationAccountId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  percentMonth0?: Maybe<DecimalNullableFilter>;
  percentMonth1?: Maybe<DecimalNullableFilter>;
  percentMonth2?: Maybe<DecimalNullableFilter>;
  percentMonth3?: Maybe<DecimalNullableFilter>;
  percentMonth4?: Maybe<DecimalNullableFilter>;
  percentMonth5?: Maybe<DecimalNullableFilter>;
  percentMonth6?: Maybe<DecimalNullableFilter>;
  percentMonth7?: Maybe<DecimalNullableFilter>;
  percentMonth8?: Maybe<DecimalNullableFilter>;
  percentMonth9?: Maybe<DecimalNullableFilter>;
  percentMonth10?: Maybe<DecimalNullableFilter>;
  percentMonth11?: Maybe<DecimalNullableFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  sourceAccount?: Maybe<AccountV2RelationFilter>;
  sourceAccountId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type DriverAccountPercentageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  year_versionType_propertyId_destinationAccountId_sourceAccountId?: Maybe<DriverAccountPercentageYearVersionTypePropertyIdDestinationAccountIdSourceAccountIdCompoundUniqueInput>;
};

export type DriverAccountPercentageYearVersionTypePropertyIdDestinationAccountIdSourceAccountIdCompoundUniqueInput = {
  destinationAccountId: Scalars['String'];
  propertyId: Scalars['String'];
  sourceAccountId: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverAssignment = {
  __typename?: 'DriverAssignment';
  accountId: Scalars['String'];
  accountV2Id?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  modelId: Scalars['String'];
  propertyId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  versionType: VersionType;
};

export type DriverAssignmentCreateManyAccountV2Input = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  modelId: Scalars['String'];
  propertyId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType?: Maybe<VersionType>;
};

export type DriverAssignmentCreateManyAccountV2InputEnvelope = {
  data: Array<DriverAssignmentCreateManyAccountV2Input>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DriverAssignmentCreateManyModelInput = {
  accountId: Scalars['String'];
  accountV2Id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType?: Maybe<VersionType>;
};

export type DriverAssignmentCreateManyModelInputEnvelope = {
  data: Array<DriverAssignmentCreateManyModelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DriverAssignmentCreateManyPropertyInput = {
  accountId: Scalars['String'];
  accountV2Id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  modelId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType?: Maybe<VersionType>;
};

export type DriverAssignmentCreateManyPropertyInputEnvelope = {
  data: Array<DriverAssignmentCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DriverAssignmentCreateNestedManyWithoutAccountV2Input = {
  connect?: Maybe<Array<DriverAssignmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverAssignmentCreateOrConnectWithoutAccountV2Input>>;
  create?: Maybe<Array<DriverAssignmentCreateWithoutAccountV2Input>>;
  createMany?: Maybe<DriverAssignmentCreateManyAccountV2InputEnvelope>;
};

export type DriverAssignmentCreateNestedManyWithoutModelInput = {
  connect?: Maybe<Array<DriverAssignmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverAssignmentCreateOrConnectWithoutModelInput>>;
  create?: Maybe<Array<DriverAssignmentCreateWithoutModelInput>>;
  createMany?: Maybe<DriverAssignmentCreateManyModelInputEnvelope>;
};

export type DriverAssignmentCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<DriverAssignmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverAssignmentCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<DriverAssignmentCreateWithoutPropertyInput>>;
  createMany?: Maybe<DriverAssignmentCreateManyPropertyInputEnvelope>;
};

export type DriverAssignmentCreateNestedOneWithoutDriversInput = {
  connect?: Maybe<DriverAssignmentWhereUniqueInput>;
  connectOrCreate?: Maybe<DriverAssignmentCreateOrConnectWithoutDriversInput>;
  create?: Maybe<DriverAssignmentCreateWithoutDriversInput>;
};

export type DriverAssignmentCreateOrConnectWithoutAccountV2Input = {
  create: DriverAssignmentCreateWithoutAccountV2Input;
  where: DriverAssignmentWhereUniqueInput;
};

export type DriverAssignmentCreateOrConnectWithoutDriversInput = {
  create: DriverAssignmentCreateWithoutDriversInput;
  where: DriverAssignmentWhereUniqueInput;
};

export type DriverAssignmentCreateOrConnectWithoutModelInput = {
  create: DriverAssignmentCreateWithoutModelInput;
  where: DriverAssignmentWhereUniqueInput;
};

export type DriverAssignmentCreateOrConnectWithoutPropertyInput = {
  create: DriverAssignmentCreateWithoutPropertyInput;
  where: DriverAssignmentWhereUniqueInput;
};

export type DriverAssignmentCreateWithoutAccountV2Input = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  drivers?: Maybe<DriverCreateNestedManyWithoutDriverAssignmentInput>;
  id?: Maybe<Scalars['String']>;
  model: ModelCreateNestedOneWithoutDriverAssignmentsInput;
  property?: Maybe<PropertyCreateNestedOneWithoutDriverAssignmentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType?: Maybe<VersionType>;
};

export type DriverAssignmentCreateWithoutDriversInput = {
  accountId: Scalars['String'];
  accountV2?: Maybe<AccountV2CreateNestedOneWithoutDriverAssignmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  model: ModelCreateNestedOneWithoutDriverAssignmentsInput;
  property?: Maybe<PropertyCreateNestedOneWithoutDriverAssignmentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType?: Maybe<VersionType>;
};

export type DriverAssignmentCreateWithoutModelInput = {
  accountId: Scalars['String'];
  accountV2?: Maybe<AccountV2CreateNestedOneWithoutDriverAssignmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  drivers?: Maybe<DriverCreateNestedManyWithoutDriverAssignmentInput>;
  id?: Maybe<Scalars['String']>;
  property?: Maybe<PropertyCreateNestedOneWithoutDriverAssignmentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType?: Maybe<VersionType>;
};

export type DriverAssignmentCreateWithoutPropertyInput = {
  accountId: Scalars['String'];
  accountV2?: Maybe<AccountV2CreateNestedOneWithoutDriverAssignmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  drivers?: Maybe<DriverCreateNestedManyWithoutDriverAssignmentInput>;
  id?: Maybe<Scalars['String']>;
  model: ModelCreateNestedOneWithoutDriverAssignmentsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType?: Maybe<VersionType>;
};

export type DriverAssignmentListRelationFilter = {
  every?: Maybe<DriverAssignmentWhereInput>;
  none?: Maybe<DriverAssignmentWhereInput>;
  some?: Maybe<DriverAssignmentWhereInput>;
};

export type DriverAssignmentModelIdAccountIdAccountV2IdCompoundUniqueInput = {
  accountId: Scalars['String'];
  accountV2Id: Scalars['String'];
  modelId: Scalars['String'];
};

export type DriverAssignmentOrderByInput = {
  accountId?: Maybe<SortOrder>;
  accountV2Id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  modelId?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  versionType?: Maybe<SortOrder>;
};

export type DriverAssignmentRelationFilter = {
  is?: Maybe<DriverAssignmentWhereInput>;
  isNot?: Maybe<DriverAssignmentWhereInput>;
};

export enum DriverAssignmentScalarFieldEnum {
  AccountId = 'accountId',
  AccountV2Id = 'accountV2Id',
  CreatedAt = 'createdAt',
  Id = 'id',
  ModelId = 'modelId',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt',
  VersionType = 'versionType'
}

export type DriverAssignmentScalarWhereInput = {
  AND?: Maybe<Array<DriverAssignmentScalarWhereInput>>;
  NOT?: Maybe<Array<DriverAssignmentScalarWhereInput>>;
  OR?: Maybe<Array<DriverAssignmentScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  accountV2Id?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  modelId?: Maybe<StringFilter>;
  propertyId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
};

export type DriverAssignmentUpdateManyMutationInput = {
  accountId?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type DriverAssignmentUpdateManyWithWhereWithoutAccountV2Input = {
  data: DriverAssignmentUpdateManyMutationInput;
  where: DriverAssignmentScalarWhereInput;
};

export type DriverAssignmentUpdateManyWithWhereWithoutModelInput = {
  data: DriverAssignmentUpdateManyMutationInput;
  where: DriverAssignmentScalarWhereInput;
};

export type DriverAssignmentUpdateManyWithWhereWithoutPropertyInput = {
  data: DriverAssignmentUpdateManyMutationInput;
  where: DriverAssignmentScalarWhereInput;
};

export type DriverAssignmentUpdateManyWithoutAccountV2Input = {
  connect?: Maybe<Array<DriverAssignmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverAssignmentCreateOrConnectWithoutAccountV2Input>>;
  create?: Maybe<Array<DriverAssignmentCreateWithoutAccountV2Input>>;
  createMany?: Maybe<DriverAssignmentCreateManyAccountV2InputEnvelope>;
  delete?: Maybe<Array<DriverAssignmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DriverAssignmentScalarWhereInput>>;
  disconnect?: Maybe<Array<DriverAssignmentWhereUniqueInput>>;
  set?: Maybe<Array<DriverAssignmentWhereUniqueInput>>;
  update?: Maybe<Array<DriverAssignmentUpdateWithWhereUniqueWithoutAccountV2Input>>;
  updateMany?: Maybe<Array<DriverAssignmentUpdateManyWithWhereWithoutAccountV2Input>>;
  upsert?: Maybe<Array<DriverAssignmentUpsertWithWhereUniqueWithoutAccountV2Input>>;
};

export type DriverAssignmentUpdateManyWithoutModelInput = {
  connect?: Maybe<Array<DriverAssignmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverAssignmentCreateOrConnectWithoutModelInput>>;
  create?: Maybe<Array<DriverAssignmentCreateWithoutModelInput>>;
  createMany?: Maybe<DriverAssignmentCreateManyModelInputEnvelope>;
  delete?: Maybe<Array<DriverAssignmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DriverAssignmentScalarWhereInput>>;
  disconnect?: Maybe<Array<DriverAssignmentWhereUniqueInput>>;
  set?: Maybe<Array<DriverAssignmentWhereUniqueInput>>;
  update?: Maybe<Array<DriverAssignmentUpdateWithWhereUniqueWithoutModelInput>>;
  updateMany?: Maybe<Array<DriverAssignmentUpdateManyWithWhereWithoutModelInput>>;
  upsert?: Maybe<Array<DriverAssignmentUpsertWithWhereUniqueWithoutModelInput>>;
};

export type DriverAssignmentUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<DriverAssignmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverAssignmentCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<DriverAssignmentCreateWithoutPropertyInput>>;
  createMany?: Maybe<DriverAssignmentCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<DriverAssignmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DriverAssignmentScalarWhereInput>>;
  disconnect?: Maybe<Array<DriverAssignmentWhereUniqueInput>>;
  set?: Maybe<Array<DriverAssignmentWhereUniqueInput>>;
  update?: Maybe<Array<DriverAssignmentUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<DriverAssignmentUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<DriverAssignmentUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type DriverAssignmentUpdateOneRequiredWithoutDriversInput = {
  connect?: Maybe<DriverAssignmentWhereUniqueInput>;
  connectOrCreate?: Maybe<DriverAssignmentCreateOrConnectWithoutDriversInput>;
  create?: Maybe<DriverAssignmentCreateWithoutDriversInput>;
  update?: Maybe<DriverAssignmentUpdateWithoutDriversInput>;
  upsert?: Maybe<DriverAssignmentUpsertWithoutDriversInput>;
};

export type DriverAssignmentUpdateWithWhereUniqueWithoutAccountV2Input = {
  data: DriverAssignmentUpdateWithoutAccountV2Input;
  where: DriverAssignmentWhereUniqueInput;
};

export type DriverAssignmentUpdateWithWhereUniqueWithoutModelInput = {
  data: DriverAssignmentUpdateWithoutModelInput;
  where: DriverAssignmentWhereUniqueInput;
};

export type DriverAssignmentUpdateWithWhereUniqueWithoutPropertyInput = {
  data: DriverAssignmentUpdateWithoutPropertyInput;
  where: DriverAssignmentWhereUniqueInput;
};

export type DriverAssignmentUpdateWithoutAccountV2Input = {
  accountId?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  drivers?: Maybe<DriverUpdateManyWithoutDriverAssignmentInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneRequiredWithoutDriverAssignmentsInput>;
  property?: Maybe<PropertyUpdateOneWithoutDriverAssignmentsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type DriverAssignmentUpdateWithoutDriversInput = {
  accountId?: Maybe<StringFieldUpdateOperationsInput>;
  accountV2?: Maybe<AccountV2UpdateOneWithoutDriverAssignmentsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneRequiredWithoutDriverAssignmentsInput>;
  property?: Maybe<PropertyUpdateOneWithoutDriverAssignmentsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type DriverAssignmentUpdateWithoutModelInput = {
  accountId?: Maybe<StringFieldUpdateOperationsInput>;
  accountV2?: Maybe<AccountV2UpdateOneWithoutDriverAssignmentsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  drivers?: Maybe<DriverUpdateManyWithoutDriverAssignmentInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneWithoutDriverAssignmentsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type DriverAssignmentUpdateWithoutPropertyInput = {
  accountId?: Maybe<StringFieldUpdateOperationsInput>;
  accountV2?: Maybe<AccountV2UpdateOneWithoutDriverAssignmentsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  drivers?: Maybe<DriverUpdateManyWithoutDriverAssignmentInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneRequiredWithoutDriverAssignmentsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type DriverAssignmentUpsertWithWhereUniqueWithoutAccountV2Input = {
  create: DriverAssignmentCreateWithoutAccountV2Input;
  update: DriverAssignmentUpdateWithoutAccountV2Input;
  where: DriverAssignmentWhereUniqueInput;
};

export type DriverAssignmentUpsertWithWhereUniqueWithoutModelInput = {
  create: DriverAssignmentCreateWithoutModelInput;
  update: DriverAssignmentUpdateWithoutModelInput;
  where: DriverAssignmentWhereUniqueInput;
};

export type DriverAssignmentUpsertWithWhereUniqueWithoutPropertyInput = {
  create: DriverAssignmentCreateWithoutPropertyInput;
  update: DriverAssignmentUpdateWithoutPropertyInput;
  where: DriverAssignmentWhereUniqueInput;
};

export type DriverAssignmentUpsertWithoutDriversInput = {
  create: DriverAssignmentCreateWithoutDriversInput;
  update: DriverAssignmentUpdateWithoutDriversInput;
};

export type DriverAssignmentWhereInput = {
  AND?: Maybe<Array<DriverAssignmentWhereInput>>;
  NOT?: Maybe<Array<DriverAssignmentWhereInput>>;
  OR?: Maybe<Array<DriverAssignmentWhereInput>>;
  accountId?: Maybe<StringFilter>;
  accountV2?: Maybe<AccountV2RelationFilter>;
  accountV2Id?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  drivers?: Maybe<DriverListRelationFilter>;
  id?: Maybe<StringFilter>;
  model?: Maybe<ModelRelationFilter>;
  modelId?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
};

export type DriverAssignmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  modelId_accountId_accountV2Id?: Maybe<DriverAssignmentModelIdAccountIdAccountV2IdCompoundUniqueInput>;
};

export type DriverAugmentAccountPercentage = {
  __typename?: 'DriverAugmentAccountPercentage';
  budgetYear: Scalars['Int'];
  destinationAccountId: Scalars['String'];
  id: Scalars['String'];
  maxValue?: Maybe<Scalars['Decimal']>;
  minValue?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  versionType: VersionType;
};

export type DriverAugmentAccountPercentageBudgetYearVersionTypePropertyIdDestinationAccountIdCompoundUniqueInput = {
  budgetYear: Scalars['Int'];
  destinationAccountId: Scalars['String'];
  propertyId: Scalars['String'];
  versionType: VersionType;
};

export type DriverAugmentAccountPercentageCreateManyDestinationAccountInput = {
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  maxValue?: Maybe<Scalars['Decimal']>;
  minValue?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  versionType: VersionType;
};

export type DriverAugmentAccountPercentageCreateManyDestinationAccountInputEnvelope = {
  data: Array<DriverAugmentAccountPercentageCreateManyDestinationAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DriverAugmentAccountPercentageCreateManyPropertyInput = {
  budgetYear: Scalars['Int'];
  destinationAccountId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  maxValue?: Maybe<Scalars['Decimal']>;
  minValue?: Maybe<Scalars['Decimal']>;
  versionType: VersionType;
};

export type DriverAugmentAccountPercentageCreateManyPropertyInputEnvelope = {
  data: Array<DriverAugmentAccountPercentageCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DriverAugmentAccountPercentageCreateNestedManyWithoutDestinationAccountInput = {
  connect?: Maybe<Array<DriverAugmentAccountPercentageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverAugmentAccountPercentageCreateOrConnectWithoutDestinationAccountInput>>;
  create?: Maybe<Array<DriverAugmentAccountPercentageCreateWithoutDestinationAccountInput>>;
  createMany?: Maybe<DriverAugmentAccountPercentageCreateManyDestinationAccountInputEnvelope>;
};

export type DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<DriverAugmentAccountPercentageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverAugmentAccountPercentageCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<DriverAugmentAccountPercentageCreateWithoutPropertyInput>>;
  createMany?: Maybe<DriverAugmentAccountPercentageCreateManyPropertyInputEnvelope>;
};

export type DriverAugmentAccountPercentageCreateOrConnectWithoutDestinationAccountInput = {
  create: DriverAugmentAccountPercentageCreateWithoutDestinationAccountInput;
  where: DriverAugmentAccountPercentageWhereUniqueInput;
};

export type DriverAugmentAccountPercentageCreateOrConnectWithoutPropertyInput = {
  create: DriverAugmentAccountPercentageCreateWithoutPropertyInput;
  where: DriverAugmentAccountPercentageWhereUniqueInput;
};

export type DriverAugmentAccountPercentageCreateWithoutDestinationAccountInput = {
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  maxValue?: Maybe<Scalars['Decimal']>;
  minValue?: Maybe<Scalars['Decimal']>;
  property: PropertyCreateNestedOneWithoutDriverAugmentsAccountPercentageInput;
  versionType: VersionType;
};

export type DriverAugmentAccountPercentageCreateWithoutPropertyInput = {
  budgetYear: Scalars['Int'];
  destinationAccount: AccountV2CreateNestedOneWithoutDriverAugmentsAccountPercentageInput;
  id?: Maybe<Scalars['String']>;
  maxValue?: Maybe<Scalars['Decimal']>;
  minValue?: Maybe<Scalars['Decimal']>;
  versionType: VersionType;
};

export type DriverAugmentAccountPercentageListRelationFilter = {
  every?: Maybe<DriverAugmentAccountPercentageWhereInput>;
  none?: Maybe<DriverAugmentAccountPercentageWhereInput>;
  some?: Maybe<DriverAugmentAccountPercentageWhereInput>;
};

export type DriverAugmentAccountPercentageOrderByInput = {
  budgetYear?: Maybe<SortOrder>;
  destinationAccountId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  maxValue?: Maybe<SortOrder>;
  minValue?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  versionType?: Maybe<SortOrder>;
};

export enum DriverAugmentAccountPercentageScalarFieldEnum {
  BudgetYear = 'budgetYear',
  DestinationAccountId = 'destinationAccountId',
  Id = 'id',
  MaxValue = 'maxValue',
  MinValue = 'minValue',
  PropertyId = 'propertyId',
  VersionType = 'versionType'
}

export type DriverAugmentAccountPercentageScalarWhereInput = {
  AND?: Maybe<Array<DriverAugmentAccountPercentageScalarWhereInput>>;
  NOT?: Maybe<Array<DriverAugmentAccountPercentageScalarWhereInput>>;
  OR?: Maybe<Array<DriverAugmentAccountPercentageScalarWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  destinationAccountId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  maxValue?: Maybe<DecimalNullableFilter>;
  minValue?: Maybe<DecimalNullableFilter>;
  propertyId?: Maybe<StringFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
};

export type DriverAugmentAccountPercentageUpdateManyMutationInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  maxValue?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  minValue?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type DriverAugmentAccountPercentageUpdateManyWithWhereWithoutDestinationAccountInput = {
  data: DriverAugmentAccountPercentageUpdateManyMutationInput;
  where: DriverAugmentAccountPercentageScalarWhereInput;
};

export type DriverAugmentAccountPercentageUpdateManyWithWhereWithoutPropertyInput = {
  data: DriverAugmentAccountPercentageUpdateManyMutationInput;
  where: DriverAugmentAccountPercentageScalarWhereInput;
};

export type DriverAugmentAccountPercentageUpdateManyWithoutDestinationAccountInput = {
  connect?: Maybe<Array<DriverAugmentAccountPercentageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverAugmentAccountPercentageCreateOrConnectWithoutDestinationAccountInput>>;
  create?: Maybe<Array<DriverAugmentAccountPercentageCreateWithoutDestinationAccountInput>>;
  createMany?: Maybe<DriverAugmentAccountPercentageCreateManyDestinationAccountInputEnvelope>;
  delete?: Maybe<Array<DriverAugmentAccountPercentageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DriverAugmentAccountPercentageScalarWhereInput>>;
  disconnect?: Maybe<Array<DriverAugmentAccountPercentageWhereUniqueInput>>;
  set?: Maybe<Array<DriverAugmentAccountPercentageWhereUniqueInput>>;
  update?: Maybe<Array<DriverAugmentAccountPercentageUpdateWithWhereUniqueWithoutDestinationAccountInput>>;
  updateMany?: Maybe<Array<DriverAugmentAccountPercentageUpdateManyWithWhereWithoutDestinationAccountInput>>;
  upsert?: Maybe<Array<DriverAugmentAccountPercentageUpsertWithWhereUniqueWithoutDestinationAccountInput>>;
};

export type DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<DriverAugmentAccountPercentageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverAugmentAccountPercentageCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<DriverAugmentAccountPercentageCreateWithoutPropertyInput>>;
  createMany?: Maybe<DriverAugmentAccountPercentageCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<DriverAugmentAccountPercentageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DriverAugmentAccountPercentageScalarWhereInput>>;
  disconnect?: Maybe<Array<DriverAugmentAccountPercentageWhereUniqueInput>>;
  set?: Maybe<Array<DriverAugmentAccountPercentageWhereUniqueInput>>;
  update?: Maybe<Array<DriverAugmentAccountPercentageUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<DriverAugmentAccountPercentageUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<DriverAugmentAccountPercentageUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type DriverAugmentAccountPercentageUpdateWithWhereUniqueWithoutDestinationAccountInput = {
  data: DriverAugmentAccountPercentageUpdateWithoutDestinationAccountInput;
  where: DriverAugmentAccountPercentageWhereUniqueInput;
};

export type DriverAugmentAccountPercentageUpdateWithWhereUniqueWithoutPropertyInput = {
  data: DriverAugmentAccountPercentageUpdateWithoutPropertyInput;
  where: DriverAugmentAccountPercentageWhereUniqueInput;
};

export type DriverAugmentAccountPercentageUpdateWithoutDestinationAccountInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  maxValue?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  minValue?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutDriverAugmentsAccountPercentageInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type DriverAugmentAccountPercentageUpdateWithoutPropertyInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  destinationAccount?: Maybe<AccountV2UpdateOneRequiredWithoutDriverAugmentsAccountPercentageInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  maxValue?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  minValue?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type DriverAugmentAccountPercentageUpsertWithWhereUniqueWithoutDestinationAccountInput = {
  create: DriverAugmentAccountPercentageCreateWithoutDestinationAccountInput;
  update: DriverAugmentAccountPercentageUpdateWithoutDestinationAccountInput;
  where: DriverAugmentAccountPercentageWhereUniqueInput;
};

export type DriverAugmentAccountPercentageUpsertWithWhereUniqueWithoutPropertyInput = {
  create: DriverAugmentAccountPercentageCreateWithoutPropertyInput;
  update: DriverAugmentAccountPercentageUpdateWithoutPropertyInput;
  where: DriverAugmentAccountPercentageWhereUniqueInput;
};

export type DriverAugmentAccountPercentageWhereInput = {
  AND?: Maybe<Array<DriverAugmentAccountPercentageWhereInput>>;
  NOT?: Maybe<Array<DriverAugmentAccountPercentageWhereInput>>;
  OR?: Maybe<Array<DriverAugmentAccountPercentageWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  destinationAccount?: Maybe<AccountV2RelationFilter>;
  destinationAccountId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  maxValue?: Maybe<DecimalNullableFilter>;
  minValue?: Maybe<DecimalNullableFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
};

export type DriverAugmentAccountPercentageWhereUniqueInput = {
  budgetYear_versionType_propertyId_destinationAccountId?: Maybe<DriverAugmentAccountPercentageBudgetYearVersionTypePropertyIdDestinationAccountIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type DriverCreateManyDriverAssignmentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  type: DriverType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverCreateManyDriverAssignmentInputEnvelope = {
  data: Array<DriverCreateManyDriverAssignmentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DriverCreateNestedManyWithoutDriverAssignmentInput = {
  connect?: Maybe<Array<DriverWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverCreateOrConnectWithoutDriverAssignmentInput>>;
  create?: Maybe<Array<DriverCreateWithoutDriverAssignmentInput>>;
  createMany?: Maybe<DriverCreateManyDriverAssignmentInputEnvelope>;
};

export type DriverCreateNestedOneWithoutAccPercentageDriverAccountsInput = {
  connect?: Maybe<DriverWhereUniqueInput>;
  connectOrCreate?: Maybe<DriverCreateOrConnectWithoutAccPercentageDriverAccountsInput>;
  create?: Maybe<DriverCreateWithoutAccPercentageDriverAccountsInput>;
};

export type DriverCreateNestedOneWithoutOperationalDriverMetricTypesInput = {
  connect?: Maybe<DriverWhereUniqueInput>;
  connectOrCreate?: Maybe<DriverCreateOrConnectWithoutOperationalDriverMetricTypesInput>;
  create?: Maybe<DriverCreateWithoutOperationalDriverMetricTypesInput>;
};

export type DriverCreateNestedOneWithoutRevenueDriverTypesInput = {
  connect?: Maybe<DriverWhereUniqueInput>;
  connectOrCreate?: Maybe<DriverCreateOrConnectWithoutRevenueDriverTypesInput>;
  create?: Maybe<DriverCreateWithoutRevenueDriverTypesInput>;
};

export type DriverCreateNestedOneWithoutWorksheetAssumptionsInput = {
  connect?: Maybe<DriverWhereUniqueInput>;
  connectOrCreate?: Maybe<DriverCreateOrConnectWithoutWorksheetAssumptionsInput>;
  create?: Maybe<DriverCreateWithoutWorksheetAssumptionsInput>;
};

export type DriverCreateOrConnectWithoutAccPercentageDriverAccountsInput = {
  create: DriverCreateWithoutAccPercentageDriverAccountsInput;
  where: DriverWhereUniqueInput;
};

export type DriverCreateOrConnectWithoutDriverAssignmentInput = {
  create: DriverCreateWithoutDriverAssignmentInput;
  where: DriverWhereUniqueInput;
};

export type DriverCreateOrConnectWithoutOperationalDriverMetricTypesInput = {
  create: DriverCreateWithoutOperationalDriverMetricTypesInput;
  where: DriverWhereUniqueInput;
};

export type DriverCreateOrConnectWithoutRevenueDriverTypesInput = {
  create: DriverCreateWithoutRevenueDriverTypesInput;
  where: DriverWhereUniqueInput;
};

export type DriverCreateOrConnectWithoutWorksheetAssumptionsInput = {
  create: DriverCreateWithoutWorksheetAssumptionsInput;
  where: DriverWhereUniqueInput;
};

export type DriverCreateWithoutAccPercentageDriverAccountsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  driverAssignment: DriverAssignmentCreateNestedOneWithoutDriversInput;
  id?: Maybe<Scalars['String']>;
  operationalDriverMetricTypes?: Maybe<OperationalDriverMetricTypeCreateNestedManyWithoutDriverInput>;
  order: Scalars['Int'];
  revenueDriverTypes?: Maybe<RevenueDriverTypeCreateNestedManyWithoutDriverInput>;
  type: DriverType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutWorksheetDriverInput>;
};

export type DriverCreateWithoutDriverAssignmentInput = {
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutDriverInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  operationalDriverMetricTypes?: Maybe<OperationalDriverMetricTypeCreateNestedManyWithoutDriverInput>;
  order: Scalars['Int'];
  revenueDriverTypes?: Maybe<RevenueDriverTypeCreateNestedManyWithoutDriverInput>;
  type: DriverType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutWorksheetDriverInput>;
};

export type DriverCreateWithoutOperationalDriverMetricTypesInput = {
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutDriverInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  driverAssignment: DriverAssignmentCreateNestedOneWithoutDriversInput;
  id?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  revenueDriverTypes?: Maybe<RevenueDriverTypeCreateNestedManyWithoutDriverInput>;
  type: DriverType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutWorksheetDriverInput>;
};

export type DriverCreateWithoutRevenueDriverTypesInput = {
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutDriverInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  driverAssignment: DriverAssignmentCreateNestedOneWithoutDriversInput;
  id?: Maybe<Scalars['String']>;
  operationalDriverMetricTypes?: Maybe<OperationalDriverMetricTypeCreateNestedManyWithoutDriverInput>;
  order: Scalars['Int'];
  type: DriverType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutWorksheetDriverInput>;
};

export type DriverCreateWithoutWorksheetAssumptionsInput = {
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountCreateNestedManyWithoutDriverInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  driverAssignment: DriverAssignmentCreateNestedOneWithoutDriversInput;
  id?: Maybe<Scalars['String']>;
  operationalDriverMetricTypes?: Maybe<OperationalDriverMetricTypeCreateNestedManyWithoutDriverInput>;
  order: Scalars['Int'];
  revenueDriverTypes?: Maybe<RevenueDriverTypeCreateNestedManyWithoutDriverInput>;
  type: DriverType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverCustomItem = {
  __typename?: 'DriverCustomItem';
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  countMonth0?: Maybe<Scalars['Decimal']>;
  countMonth1?: Maybe<Scalars['Decimal']>;
  countMonth2?: Maybe<Scalars['Decimal']>;
  countMonth3?: Maybe<Scalars['Decimal']>;
  countMonth4?: Maybe<Scalars['Decimal']>;
  countMonth5?: Maybe<Scalars['Decimal']>;
  countMonth6?: Maybe<Scalars['Decimal']>;
  countMonth7?: Maybe<Scalars['Decimal']>;
  countMonth8?: Maybe<Scalars['Decimal']>;
  countMonth9?: Maybe<Scalars['Decimal']>;
  countMonth10?: Maybe<Scalars['Decimal']>;
  countMonth11?: Maybe<Scalars['Decimal']>;
  createdAt: Scalars['DateTime'];
  destinationAccountId: Scalars['String'];
  id: Scalars['String'];
  itemName: Scalars['String'];
  percentMonth0?: Maybe<Scalars['Decimal']>;
  percentMonth1?: Maybe<Scalars['Decimal']>;
  percentMonth2?: Maybe<Scalars['Decimal']>;
  percentMonth3?: Maybe<Scalars['Decimal']>;
  percentMonth4?: Maybe<Scalars['Decimal']>;
  percentMonth5?: Maybe<Scalars['Decimal']>;
  percentMonth6?: Maybe<Scalars['Decimal']>;
  percentMonth7?: Maybe<Scalars['Decimal']>;
  percentMonth8?: Maybe<Scalars['Decimal']>;
  percentMonth9?: Maybe<Scalars['Decimal']>;
  percentMonth10?: Maybe<Scalars['Decimal']>;
  percentMonth11?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverCustomItemCreateManyDestinationAccountInput = {
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  countMonth0?: Maybe<Scalars['Decimal']>;
  countMonth1?: Maybe<Scalars['Decimal']>;
  countMonth2?: Maybe<Scalars['Decimal']>;
  countMonth3?: Maybe<Scalars['Decimal']>;
  countMonth4?: Maybe<Scalars['Decimal']>;
  countMonth5?: Maybe<Scalars['Decimal']>;
  countMonth6?: Maybe<Scalars['Decimal']>;
  countMonth7?: Maybe<Scalars['Decimal']>;
  countMonth8?: Maybe<Scalars['Decimal']>;
  countMonth9?: Maybe<Scalars['Decimal']>;
  countMonth10?: Maybe<Scalars['Decimal']>;
  countMonth11?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  itemName: Scalars['String'];
  percentMonth0?: Maybe<Scalars['Decimal']>;
  percentMonth1?: Maybe<Scalars['Decimal']>;
  percentMonth2?: Maybe<Scalars['Decimal']>;
  percentMonth3?: Maybe<Scalars['Decimal']>;
  percentMonth4?: Maybe<Scalars['Decimal']>;
  percentMonth5?: Maybe<Scalars['Decimal']>;
  percentMonth6?: Maybe<Scalars['Decimal']>;
  percentMonth7?: Maybe<Scalars['Decimal']>;
  percentMonth8?: Maybe<Scalars['Decimal']>;
  percentMonth9?: Maybe<Scalars['Decimal']>;
  percentMonth10?: Maybe<Scalars['Decimal']>;
  percentMonth11?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverCustomItemCreateManyDestinationAccountInputEnvelope = {
  data: Array<DriverCustomItemCreateManyDestinationAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DriverCustomItemCreateManyPropertyInput = {
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  countMonth0?: Maybe<Scalars['Decimal']>;
  countMonth1?: Maybe<Scalars['Decimal']>;
  countMonth2?: Maybe<Scalars['Decimal']>;
  countMonth3?: Maybe<Scalars['Decimal']>;
  countMonth4?: Maybe<Scalars['Decimal']>;
  countMonth5?: Maybe<Scalars['Decimal']>;
  countMonth6?: Maybe<Scalars['Decimal']>;
  countMonth7?: Maybe<Scalars['Decimal']>;
  countMonth8?: Maybe<Scalars['Decimal']>;
  countMonth9?: Maybe<Scalars['Decimal']>;
  countMonth10?: Maybe<Scalars['Decimal']>;
  countMonth11?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationAccountId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  itemName: Scalars['String'];
  percentMonth0?: Maybe<Scalars['Decimal']>;
  percentMonth1?: Maybe<Scalars['Decimal']>;
  percentMonth2?: Maybe<Scalars['Decimal']>;
  percentMonth3?: Maybe<Scalars['Decimal']>;
  percentMonth4?: Maybe<Scalars['Decimal']>;
  percentMonth5?: Maybe<Scalars['Decimal']>;
  percentMonth6?: Maybe<Scalars['Decimal']>;
  percentMonth7?: Maybe<Scalars['Decimal']>;
  percentMonth8?: Maybe<Scalars['Decimal']>;
  percentMonth9?: Maybe<Scalars['Decimal']>;
  percentMonth10?: Maybe<Scalars['Decimal']>;
  percentMonth11?: Maybe<Scalars['Decimal']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverCustomItemCreateManyPropertyInputEnvelope = {
  data: Array<DriverCustomItemCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DriverCustomItemCreateNestedManyWithoutDestinationAccountInput = {
  connect?: Maybe<Array<DriverCustomItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverCustomItemCreateOrConnectWithoutDestinationAccountInput>>;
  create?: Maybe<Array<DriverCustomItemCreateWithoutDestinationAccountInput>>;
  createMany?: Maybe<DriverCustomItemCreateManyDestinationAccountInputEnvelope>;
};

export type DriverCustomItemCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<DriverCustomItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverCustomItemCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<DriverCustomItemCreateWithoutPropertyInput>>;
  createMany?: Maybe<DriverCustomItemCreateManyPropertyInputEnvelope>;
};

export type DriverCustomItemCreateOrConnectWithoutDestinationAccountInput = {
  create: DriverCustomItemCreateWithoutDestinationAccountInput;
  where: DriverCustomItemWhereUniqueInput;
};

export type DriverCustomItemCreateOrConnectWithoutPropertyInput = {
  create: DriverCustomItemCreateWithoutPropertyInput;
  where: DriverCustomItemWhereUniqueInput;
};

export type DriverCustomItemCreateWithoutDestinationAccountInput = {
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  countMonth0?: Maybe<Scalars['Decimal']>;
  countMonth1?: Maybe<Scalars['Decimal']>;
  countMonth2?: Maybe<Scalars['Decimal']>;
  countMonth3?: Maybe<Scalars['Decimal']>;
  countMonth4?: Maybe<Scalars['Decimal']>;
  countMonth5?: Maybe<Scalars['Decimal']>;
  countMonth6?: Maybe<Scalars['Decimal']>;
  countMonth7?: Maybe<Scalars['Decimal']>;
  countMonth8?: Maybe<Scalars['Decimal']>;
  countMonth9?: Maybe<Scalars['Decimal']>;
  countMonth10?: Maybe<Scalars['Decimal']>;
  countMonth11?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  itemName: Scalars['String'];
  percentMonth0?: Maybe<Scalars['Decimal']>;
  percentMonth1?: Maybe<Scalars['Decimal']>;
  percentMonth2?: Maybe<Scalars['Decimal']>;
  percentMonth3?: Maybe<Scalars['Decimal']>;
  percentMonth4?: Maybe<Scalars['Decimal']>;
  percentMonth5?: Maybe<Scalars['Decimal']>;
  percentMonth6?: Maybe<Scalars['Decimal']>;
  percentMonth7?: Maybe<Scalars['Decimal']>;
  percentMonth8?: Maybe<Scalars['Decimal']>;
  percentMonth9?: Maybe<Scalars['Decimal']>;
  percentMonth10?: Maybe<Scalars['Decimal']>;
  percentMonth11?: Maybe<Scalars['Decimal']>;
  property: PropertyCreateNestedOneWithoutCustomItemDriversInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverCustomItemCreateWithoutPropertyInput = {
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  countMonth0?: Maybe<Scalars['Decimal']>;
  countMonth1?: Maybe<Scalars['Decimal']>;
  countMonth2?: Maybe<Scalars['Decimal']>;
  countMonth3?: Maybe<Scalars['Decimal']>;
  countMonth4?: Maybe<Scalars['Decimal']>;
  countMonth5?: Maybe<Scalars['Decimal']>;
  countMonth6?: Maybe<Scalars['Decimal']>;
  countMonth7?: Maybe<Scalars['Decimal']>;
  countMonth8?: Maybe<Scalars['Decimal']>;
  countMonth9?: Maybe<Scalars['Decimal']>;
  countMonth10?: Maybe<Scalars['Decimal']>;
  countMonth11?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationAccount: AccountV2CreateNestedOneWithoutCustomItemDriversInput;
  id?: Maybe<Scalars['String']>;
  itemName: Scalars['String'];
  percentMonth0?: Maybe<Scalars['Decimal']>;
  percentMonth1?: Maybe<Scalars['Decimal']>;
  percentMonth2?: Maybe<Scalars['Decimal']>;
  percentMonth3?: Maybe<Scalars['Decimal']>;
  percentMonth4?: Maybe<Scalars['Decimal']>;
  percentMonth5?: Maybe<Scalars['Decimal']>;
  percentMonth6?: Maybe<Scalars['Decimal']>;
  percentMonth7?: Maybe<Scalars['Decimal']>;
  percentMonth8?: Maybe<Scalars['Decimal']>;
  percentMonth9?: Maybe<Scalars['Decimal']>;
  percentMonth10?: Maybe<Scalars['Decimal']>;
  percentMonth11?: Maybe<Scalars['Decimal']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverCustomItemListRelationFilter = {
  every?: Maybe<DriverCustomItemWhereInput>;
  none?: Maybe<DriverCustomItemWhereInput>;
  some?: Maybe<DriverCustomItemWhereInput>;
};

export type DriverCustomItemOrderByInput = {
  amountMonth0?: Maybe<SortOrder>;
  amountMonth1?: Maybe<SortOrder>;
  amountMonth2?: Maybe<SortOrder>;
  amountMonth3?: Maybe<SortOrder>;
  amountMonth4?: Maybe<SortOrder>;
  amountMonth5?: Maybe<SortOrder>;
  amountMonth6?: Maybe<SortOrder>;
  amountMonth7?: Maybe<SortOrder>;
  amountMonth8?: Maybe<SortOrder>;
  amountMonth9?: Maybe<SortOrder>;
  amountMonth10?: Maybe<SortOrder>;
  amountMonth11?: Maybe<SortOrder>;
  countMonth0?: Maybe<SortOrder>;
  countMonth1?: Maybe<SortOrder>;
  countMonth2?: Maybe<SortOrder>;
  countMonth3?: Maybe<SortOrder>;
  countMonth4?: Maybe<SortOrder>;
  countMonth5?: Maybe<SortOrder>;
  countMonth6?: Maybe<SortOrder>;
  countMonth7?: Maybe<SortOrder>;
  countMonth8?: Maybe<SortOrder>;
  countMonth9?: Maybe<SortOrder>;
  countMonth10?: Maybe<SortOrder>;
  countMonth11?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  destinationAccountId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  itemName?: Maybe<SortOrder>;
  percentMonth0?: Maybe<SortOrder>;
  percentMonth1?: Maybe<SortOrder>;
  percentMonth2?: Maybe<SortOrder>;
  percentMonth3?: Maybe<SortOrder>;
  percentMonth4?: Maybe<SortOrder>;
  percentMonth5?: Maybe<SortOrder>;
  percentMonth6?: Maybe<SortOrder>;
  percentMonth7?: Maybe<SortOrder>;
  percentMonth8?: Maybe<SortOrder>;
  percentMonth9?: Maybe<SortOrder>;
  percentMonth10?: Maybe<SortOrder>;
  percentMonth11?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  versionType?: Maybe<SortOrder>;
  year?: Maybe<SortOrder>;
};

export enum DriverCustomItemScalarFieldEnum {
  AmountMonth0 = 'amountMonth0',
  AmountMonth1 = 'amountMonth1',
  AmountMonth2 = 'amountMonth2',
  AmountMonth3 = 'amountMonth3',
  AmountMonth4 = 'amountMonth4',
  AmountMonth5 = 'amountMonth5',
  AmountMonth6 = 'amountMonth6',
  AmountMonth7 = 'amountMonth7',
  AmountMonth8 = 'amountMonth8',
  AmountMonth9 = 'amountMonth9',
  AmountMonth10 = 'amountMonth10',
  AmountMonth11 = 'amountMonth11',
  CountMonth0 = 'countMonth0',
  CountMonth1 = 'countMonth1',
  CountMonth2 = 'countMonth2',
  CountMonth3 = 'countMonth3',
  CountMonth4 = 'countMonth4',
  CountMonth5 = 'countMonth5',
  CountMonth6 = 'countMonth6',
  CountMonth7 = 'countMonth7',
  CountMonth8 = 'countMonth8',
  CountMonth9 = 'countMonth9',
  CountMonth10 = 'countMonth10',
  CountMonth11 = 'countMonth11',
  CreatedAt = 'createdAt',
  DestinationAccountId = 'destinationAccountId',
  Id = 'id',
  ItemName = 'itemName',
  PercentMonth0 = 'percentMonth0',
  PercentMonth1 = 'percentMonth1',
  PercentMonth2 = 'percentMonth2',
  PercentMonth3 = 'percentMonth3',
  PercentMonth4 = 'percentMonth4',
  PercentMonth5 = 'percentMonth5',
  PercentMonth6 = 'percentMonth6',
  PercentMonth7 = 'percentMonth7',
  PercentMonth8 = 'percentMonth8',
  PercentMonth9 = 'percentMonth9',
  PercentMonth10 = 'percentMonth10',
  PercentMonth11 = 'percentMonth11',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt',
  VersionType = 'versionType',
  Year = 'year'
}

export type DriverCustomItemScalarWhereInput = {
  AND?: Maybe<Array<DriverCustomItemScalarWhereInput>>;
  NOT?: Maybe<Array<DriverCustomItemScalarWhereInput>>;
  OR?: Maybe<Array<DriverCustomItemScalarWhereInput>>;
  amountMonth0?: Maybe<DecimalNullableFilter>;
  amountMonth1?: Maybe<DecimalNullableFilter>;
  amountMonth2?: Maybe<DecimalNullableFilter>;
  amountMonth3?: Maybe<DecimalNullableFilter>;
  amountMonth4?: Maybe<DecimalNullableFilter>;
  amountMonth5?: Maybe<DecimalNullableFilter>;
  amountMonth6?: Maybe<DecimalNullableFilter>;
  amountMonth7?: Maybe<DecimalNullableFilter>;
  amountMonth8?: Maybe<DecimalNullableFilter>;
  amountMonth9?: Maybe<DecimalNullableFilter>;
  amountMonth10?: Maybe<DecimalNullableFilter>;
  amountMonth11?: Maybe<DecimalNullableFilter>;
  countMonth0?: Maybe<DecimalNullableFilter>;
  countMonth1?: Maybe<DecimalNullableFilter>;
  countMonth2?: Maybe<DecimalNullableFilter>;
  countMonth3?: Maybe<DecimalNullableFilter>;
  countMonth4?: Maybe<DecimalNullableFilter>;
  countMonth5?: Maybe<DecimalNullableFilter>;
  countMonth6?: Maybe<DecimalNullableFilter>;
  countMonth7?: Maybe<DecimalNullableFilter>;
  countMonth8?: Maybe<DecimalNullableFilter>;
  countMonth9?: Maybe<DecimalNullableFilter>;
  countMonth10?: Maybe<DecimalNullableFilter>;
  countMonth11?: Maybe<DecimalNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  destinationAccountId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  itemName?: Maybe<StringFilter>;
  percentMonth0?: Maybe<DecimalNullableFilter>;
  percentMonth1?: Maybe<DecimalNullableFilter>;
  percentMonth2?: Maybe<DecimalNullableFilter>;
  percentMonth3?: Maybe<DecimalNullableFilter>;
  percentMonth4?: Maybe<DecimalNullableFilter>;
  percentMonth5?: Maybe<DecimalNullableFilter>;
  percentMonth6?: Maybe<DecimalNullableFilter>;
  percentMonth7?: Maybe<DecimalNullableFilter>;
  percentMonth8?: Maybe<DecimalNullableFilter>;
  percentMonth9?: Maybe<DecimalNullableFilter>;
  percentMonth10?: Maybe<DecimalNullableFilter>;
  percentMonth11?: Maybe<DecimalNullableFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type DriverCustomItemUpdateManyMutationInput = {
  amountMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  itemName?: Maybe<StringFieldUpdateOperationsInput>;
  percentMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DriverCustomItemUpdateManyWithWhereWithoutDestinationAccountInput = {
  data: DriverCustomItemUpdateManyMutationInput;
  where: DriverCustomItemScalarWhereInput;
};

export type DriverCustomItemUpdateManyWithWhereWithoutPropertyInput = {
  data: DriverCustomItemUpdateManyMutationInput;
  where: DriverCustomItemScalarWhereInput;
};

export type DriverCustomItemUpdateManyWithoutDestinationAccountInput = {
  connect?: Maybe<Array<DriverCustomItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverCustomItemCreateOrConnectWithoutDestinationAccountInput>>;
  create?: Maybe<Array<DriverCustomItemCreateWithoutDestinationAccountInput>>;
  createMany?: Maybe<DriverCustomItemCreateManyDestinationAccountInputEnvelope>;
  delete?: Maybe<Array<DriverCustomItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DriverCustomItemScalarWhereInput>>;
  disconnect?: Maybe<Array<DriverCustomItemWhereUniqueInput>>;
  set?: Maybe<Array<DriverCustomItemWhereUniqueInput>>;
  update?: Maybe<Array<DriverCustomItemUpdateWithWhereUniqueWithoutDestinationAccountInput>>;
  updateMany?: Maybe<Array<DriverCustomItemUpdateManyWithWhereWithoutDestinationAccountInput>>;
  upsert?: Maybe<Array<DriverCustomItemUpsertWithWhereUniqueWithoutDestinationAccountInput>>;
};

export type DriverCustomItemUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<DriverCustomItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverCustomItemCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<DriverCustomItemCreateWithoutPropertyInput>>;
  createMany?: Maybe<DriverCustomItemCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<DriverCustomItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DriverCustomItemScalarWhereInput>>;
  disconnect?: Maybe<Array<DriverCustomItemWhereUniqueInput>>;
  set?: Maybe<Array<DriverCustomItemWhereUniqueInput>>;
  update?: Maybe<Array<DriverCustomItemUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<DriverCustomItemUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<DriverCustomItemUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type DriverCustomItemUpdateWithWhereUniqueWithoutDestinationAccountInput = {
  data: DriverCustomItemUpdateWithoutDestinationAccountInput;
  where: DriverCustomItemWhereUniqueInput;
};

export type DriverCustomItemUpdateWithWhereUniqueWithoutPropertyInput = {
  data: DriverCustomItemUpdateWithoutPropertyInput;
  where: DriverCustomItemWhereUniqueInput;
};

export type DriverCustomItemUpdateWithoutDestinationAccountInput = {
  amountMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  itemName?: Maybe<StringFieldUpdateOperationsInput>;
  percentMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutCustomItemDriversInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DriverCustomItemUpdateWithoutPropertyInput = {
  amountMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  countMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  destinationAccount?: Maybe<AccountV2UpdateOneRequiredWithoutCustomItemDriversInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  itemName?: Maybe<StringFieldUpdateOperationsInput>;
  percentMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DriverCustomItemUpsertWithWhereUniqueWithoutDestinationAccountInput = {
  create: DriverCustomItemCreateWithoutDestinationAccountInput;
  update: DriverCustomItemUpdateWithoutDestinationAccountInput;
  where: DriverCustomItemWhereUniqueInput;
};

export type DriverCustomItemUpsertWithWhereUniqueWithoutPropertyInput = {
  create: DriverCustomItemCreateWithoutPropertyInput;
  update: DriverCustomItemUpdateWithoutPropertyInput;
  where: DriverCustomItemWhereUniqueInput;
};

export type DriverCustomItemWhereInput = {
  AND?: Maybe<Array<DriverCustomItemWhereInput>>;
  NOT?: Maybe<Array<DriverCustomItemWhereInput>>;
  OR?: Maybe<Array<DriverCustomItemWhereInput>>;
  amountMonth0?: Maybe<DecimalNullableFilter>;
  amountMonth1?: Maybe<DecimalNullableFilter>;
  amountMonth2?: Maybe<DecimalNullableFilter>;
  amountMonth3?: Maybe<DecimalNullableFilter>;
  amountMonth4?: Maybe<DecimalNullableFilter>;
  amountMonth5?: Maybe<DecimalNullableFilter>;
  amountMonth6?: Maybe<DecimalNullableFilter>;
  amountMonth7?: Maybe<DecimalNullableFilter>;
  amountMonth8?: Maybe<DecimalNullableFilter>;
  amountMonth9?: Maybe<DecimalNullableFilter>;
  amountMonth10?: Maybe<DecimalNullableFilter>;
  amountMonth11?: Maybe<DecimalNullableFilter>;
  countMonth0?: Maybe<DecimalNullableFilter>;
  countMonth1?: Maybe<DecimalNullableFilter>;
  countMonth2?: Maybe<DecimalNullableFilter>;
  countMonth3?: Maybe<DecimalNullableFilter>;
  countMonth4?: Maybe<DecimalNullableFilter>;
  countMonth5?: Maybe<DecimalNullableFilter>;
  countMonth6?: Maybe<DecimalNullableFilter>;
  countMonth7?: Maybe<DecimalNullableFilter>;
  countMonth8?: Maybe<DecimalNullableFilter>;
  countMonth9?: Maybe<DecimalNullableFilter>;
  countMonth10?: Maybe<DecimalNullableFilter>;
  countMonth11?: Maybe<DecimalNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  destinationAccount?: Maybe<AccountV2RelationFilter>;
  destinationAccountId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  itemName?: Maybe<StringFilter>;
  percentMonth0?: Maybe<DecimalNullableFilter>;
  percentMonth1?: Maybe<DecimalNullableFilter>;
  percentMonth2?: Maybe<DecimalNullableFilter>;
  percentMonth3?: Maybe<DecimalNullableFilter>;
  percentMonth4?: Maybe<DecimalNullableFilter>;
  percentMonth5?: Maybe<DecimalNullableFilter>;
  percentMonth6?: Maybe<DecimalNullableFilter>;
  percentMonth7?: Maybe<DecimalNullableFilter>;
  percentMonth8?: Maybe<DecimalNullableFilter>;
  percentMonth9?: Maybe<DecimalNullableFilter>;
  percentMonth10?: Maybe<DecimalNullableFilter>;
  percentMonth11?: Maybe<DecimalNullableFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type DriverCustomItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  year_versionType_propertyId_destinationAccountId_itemName?: Maybe<DriverCustomItemYearVersionTypePropertyIdDestinationAccountIdItemNameCompoundUniqueInput>;
};

export type DriverCustomItemYearVersionTypePropertyIdDestinationAccountIdItemNameCompoundUniqueInput = {
  destinationAccountId: Scalars['String'];
  itemName: Scalars['String'];
  propertyId: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverDriverAssignmentIdTypeCompoundUniqueInput = {
  driverAssignmentId: Scalars['String'];
  type: DriverType;
};

export type DriverGrowth = {
  __typename?: 'DriverGrowth';
  accountId: Scalars['String'];
  annualTargetValueManualEntry?: Maybe<Scalars['Decimal']>;
  chartOfAccountsId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  distributionMethod?: Maybe<DistributionMethod>;
  growthCalcMethod: GrowthCalcMethod;
  id: Scalars['String'];
  lookbackPeriodEnd?: Maybe<Scalars['DateTime']>;
  lookbackPeriodStart?: Maybe<Scalars['DateTime']>;
  monthlyAdjustmentType?: Maybe<MonthlyAdjustmentType>;
  monthlyAdjustmentValue?: Maybe<Scalars['Decimal']>;
  monthlyAverageBasePeriod?: Maybe<MonthlyAverageBasePeriod>;
  percentGrowthAdjustmentValue?: Maybe<Scalars['Decimal']>;
  percentGrowthBasePeriod?: Maybe<PercentGrowthBasePeriod>;
  propertyId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverGrowthCreateManyAccountInput = {
  annualTargetValueManualEntry?: Maybe<Scalars['Decimal']>;
  chartOfAccountsId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  distributionMethod?: Maybe<DistributionMethod>;
  growthCalcMethod: GrowthCalcMethod;
  id?: Maybe<Scalars['String']>;
  lookbackPeriodEnd?: Maybe<Scalars['DateTime']>;
  lookbackPeriodStart?: Maybe<Scalars['DateTime']>;
  monthlyAdjustmentType?: Maybe<MonthlyAdjustmentType>;
  monthlyAdjustmentValue?: Maybe<Scalars['Decimal']>;
  monthlyAverageBasePeriod?: Maybe<MonthlyAverageBasePeriod>;
  percentGrowthAdjustmentValue?: Maybe<Scalars['Decimal']>;
  percentGrowthBasePeriod?: Maybe<PercentGrowthBasePeriod>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverGrowthCreateManyAccountInputEnvelope = {
  data: Array<DriverGrowthCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DriverGrowthCreateManyChartOfaccountsInput = {
  accountId: Scalars['String'];
  annualTargetValueManualEntry?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  distributionMethod?: Maybe<DistributionMethod>;
  growthCalcMethod: GrowthCalcMethod;
  id?: Maybe<Scalars['String']>;
  lookbackPeriodEnd?: Maybe<Scalars['DateTime']>;
  lookbackPeriodStart?: Maybe<Scalars['DateTime']>;
  monthlyAdjustmentType?: Maybe<MonthlyAdjustmentType>;
  monthlyAdjustmentValue?: Maybe<Scalars['Decimal']>;
  monthlyAverageBasePeriod?: Maybe<MonthlyAverageBasePeriod>;
  percentGrowthAdjustmentValue?: Maybe<Scalars['Decimal']>;
  percentGrowthBasePeriod?: Maybe<PercentGrowthBasePeriod>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverGrowthCreateManyChartOfaccountsInputEnvelope = {
  data: Array<DriverGrowthCreateManyChartOfaccountsInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DriverGrowthCreateManyPropertyInput = {
  accountId: Scalars['String'];
  annualTargetValueManualEntry?: Maybe<Scalars['Decimal']>;
  chartOfAccountsId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  distributionMethod?: Maybe<DistributionMethod>;
  growthCalcMethod: GrowthCalcMethod;
  id?: Maybe<Scalars['String']>;
  lookbackPeriodEnd?: Maybe<Scalars['DateTime']>;
  lookbackPeriodStart?: Maybe<Scalars['DateTime']>;
  monthlyAdjustmentType?: Maybe<MonthlyAdjustmentType>;
  monthlyAdjustmentValue?: Maybe<Scalars['Decimal']>;
  monthlyAverageBasePeriod?: Maybe<MonthlyAverageBasePeriod>;
  percentGrowthAdjustmentValue?: Maybe<Scalars['Decimal']>;
  percentGrowthBasePeriod?: Maybe<PercentGrowthBasePeriod>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverGrowthCreateManyPropertyInputEnvelope = {
  data: Array<DriverGrowthCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DriverGrowthCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<DriverGrowthWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverGrowthCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<DriverGrowthCreateWithoutAccountInput>>;
  createMany?: Maybe<DriverGrowthCreateManyAccountInputEnvelope>;
};

export type DriverGrowthCreateNestedManyWithoutChartOfaccountsInput = {
  connect?: Maybe<Array<DriverGrowthWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverGrowthCreateOrConnectWithoutChartOfaccountsInput>>;
  create?: Maybe<Array<DriverGrowthCreateWithoutChartOfaccountsInput>>;
  createMany?: Maybe<DriverGrowthCreateManyChartOfaccountsInputEnvelope>;
};

export type DriverGrowthCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<DriverGrowthWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverGrowthCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<DriverGrowthCreateWithoutPropertyInput>>;
  createMany?: Maybe<DriverGrowthCreateManyPropertyInputEnvelope>;
};

export type DriverGrowthCreateOrConnectWithoutAccountInput = {
  create: DriverGrowthCreateWithoutAccountInput;
  where: DriverGrowthWhereUniqueInput;
};

export type DriverGrowthCreateOrConnectWithoutChartOfaccountsInput = {
  create: DriverGrowthCreateWithoutChartOfaccountsInput;
  where: DriverGrowthWhereUniqueInput;
};

export type DriverGrowthCreateOrConnectWithoutPropertyInput = {
  create: DriverGrowthCreateWithoutPropertyInput;
  where: DriverGrowthWhereUniqueInput;
};

export type DriverGrowthCreateWithoutAccountInput = {
  annualTargetValueManualEntry?: Maybe<Scalars['Decimal']>;
  chartOfaccounts: ChartOfAccountsCreateNestedOneWithoutGrowthDriversInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  distributionMethod?: Maybe<DistributionMethod>;
  growthCalcMethod: GrowthCalcMethod;
  id?: Maybe<Scalars['String']>;
  lookbackPeriodEnd?: Maybe<Scalars['DateTime']>;
  lookbackPeriodStart?: Maybe<Scalars['DateTime']>;
  monthlyAdjustmentType?: Maybe<MonthlyAdjustmentType>;
  monthlyAdjustmentValue?: Maybe<Scalars['Decimal']>;
  monthlyAverageBasePeriod?: Maybe<MonthlyAverageBasePeriod>;
  percentGrowthAdjustmentValue?: Maybe<Scalars['Decimal']>;
  percentGrowthBasePeriod?: Maybe<PercentGrowthBasePeriod>;
  property: PropertyCreateNestedOneWithoutGrowthDriversInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverGrowthCreateWithoutChartOfaccountsInput = {
  account: AccountV2CreateNestedOneWithoutGrowthDriversInput;
  annualTargetValueManualEntry?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  distributionMethod?: Maybe<DistributionMethod>;
  growthCalcMethod: GrowthCalcMethod;
  id?: Maybe<Scalars['String']>;
  lookbackPeriodEnd?: Maybe<Scalars['DateTime']>;
  lookbackPeriodStart?: Maybe<Scalars['DateTime']>;
  monthlyAdjustmentType?: Maybe<MonthlyAdjustmentType>;
  monthlyAdjustmentValue?: Maybe<Scalars['Decimal']>;
  monthlyAverageBasePeriod?: Maybe<MonthlyAverageBasePeriod>;
  percentGrowthAdjustmentValue?: Maybe<Scalars['Decimal']>;
  percentGrowthBasePeriod?: Maybe<PercentGrowthBasePeriod>;
  property: PropertyCreateNestedOneWithoutGrowthDriversInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverGrowthCreateWithoutPropertyInput = {
  account: AccountV2CreateNestedOneWithoutGrowthDriversInput;
  annualTargetValueManualEntry?: Maybe<Scalars['Decimal']>;
  chartOfaccounts: ChartOfAccountsCreateNestedOneWithoutGrowthDriversInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  distributionMethod?: Maybe<DistributionMethod>;
  growthCalcMethod: GrowthCalcMethod;
  id?: Maybe<Scalars['String']>;
  lookbackPeriodEnd?: Maybe<Scalars['DateTime']>;
  lookbackPeriodStart?: Maybe<Scalars['DateTime']>;
  monthlyAdjustmentType?: Maybe<MonthlyAdjustmentType>;
  monthlyAdjustmentValue?: Maybe<Scalars['Decimal']>;
  monthlyAverageBasePeriod?: Maybe<MonthlyAverageBasePeriod>;
  percentGrowthAdjustmentValue?: Maybe<Scalars['Decimal']>;
  percentGrowthBasePeriod?: Maybe<PercentGrowthBasePeriod>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverGrowthListRelationFilter = {
  every?: Maybe<DriverGrowthWhereInput>;
  none?: Maybe<DriverGrowthWhereInput>;
  some?: Maybe<DriverGrowthWhereInput>;
};

export type DriverGrowthOrderByInput = {
  accountId?: Maybe<SortOrder>;
  annualTargetValueManualEntry?: Maybe<SortOrder>;
  chartOfAccountsId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  distributionMethod?: Maybe<SortOrder>;
  growthCalcMethod?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lookbackPeriodEnd?: Maybe<SortOrder>;
  lookbackPeriodStart?: Maybe<SortOrder>;
  monthlyAdjustmentType?: Maybe<SortOrder>;
  monthlyAdjustmentValue?: Maybe<SortOrder>;
  monthlyAverageBasePeriod?: Maybe<SortOrder>;
  percentGrowthAdjustmentValue?: Maybe<SortOrder>;
  percentGrowthBasePeriod?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  versionType?: Maybe<SortOrder>;
  year?: Maybe<SortOrder>;
};

export enum DriverGrowthScalarFieldEnum {
  AccountId = 'accountId',
  AnnualTargetValueManualEntry = 'annualTargetValueManualEntry',
  ChartOfAccountsId = 'chartOfAccountsId',
  CreatedAt = 'createdAt',
  DistributionMethod = 'distributionMethod',
  GrowthCalcMethod = 'growthCalcMethod',
  Id = 'id',
  LookbackPeriodEnd = 'lookbackPeriodEnd',
  LookbackPeriodStart = 'lookbackPeriodStart',
  MonthlyAdjustmentType = 'monthlyAdjustmentType',
  MonthlyAdjustmentValue = 'monthlyAdjustmentValue',
  MonthlyAverageBasePeriod = 'monthlyAverageBasePeriod',
  PercentGrowthAdjustmentValue = 'percentGrowthAdjustmentValue',
  PercentGrowthBasePeriod = 'percentGrowthBasePeriod',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt',
  VersionType = 'versionType',
  Year = 'year'
}

export type DriverGrowthScalarWhereInput = {
  AND?: Maybe<Array<DriverGrowthScalarWhereInput>>;
  NOT?: Maybe<Array<DriverGrowthScalarWhereInput>>;
  OR?: Maybe<Array<DriverGrowthScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  annualTargetValueManualEntry?: Maybe<DecimalNullableFilter>;
  chartOfAccountsId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  distributionMethod?: Maybe<EnumDistributionMethodNullableFilter>;
  growthCalcMethod?: Maybe<EnumGrowthCalcMethodFilter>;
  id?: Maybe<StringFilter>;
  lookbackPeriodEnd?: Maybe<DateTimeNullableFilter>;
  lookbackPeriodStart?: Maybe<DateTimeNullableFilter>;
  monthlyAdjustmentType?: Maybe<EnumMonthlyAdjustmentTypeNullableFilter>;
  monthlyAdjustmentValue?: Maybe<DecimalNullableFilter>;
  monthlyAverageBasePeriod?: Maybe<EnumMonthlyAverageBasePeriodNullableFilter>;
  percentGrowthAdjustmentValue?: Maybe<DecimalNullableFilter>;
  percentGrowthBasePeriod?: Maybe<EnumPercentGrowthBasePeriodNullableFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type DriverGrowthUpdateManyMutationInput = {
  annualTargetValueManualEntry?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  distributionMethod?: Maybe<NullableEnumDistributionMethodFieldUpdateOperationsInput>;
  growthCalcMethod?: Maybe<EnumGrowthCalcMethodFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lookbackPeriodEnd?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  lookbackPeriodStart?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  monthlyAdjustmentType?: Maybe<NullableEnumMonthlyAdjustmentTypeFieldUpdateOperationsInput>;
  monthlyAdjustmentValue?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  monthlyAverageBasePeriod?: Maybe<NullableEnumMonthlyAverageBasePeriodFieldUpdateOperationsInput>;
  percentGrowthAdjustmentValue?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentGrowthBasePeriod?: Maybe<NullableEnumPercentGrowthBasePeriodFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DriverGrowthUpdateManyWithWhereWithoutAccountInput = {
  data: DriverGrowthUpdateManyMutationInput;
  where: DriverGrowthScalarWhereInput;
};

export type DriverGrowthUpdateManyWithWhereWithoutChartOfaccountsInput = {
  data: DriverGrowthUpdateManyMutationInput;
  where: DriverGrowthScalarWhereInput;
};

export type DriverGrowthUpdateManyWithWhereWithoutPropertyInput = {
  data: DriverGrowthUpdateManyMutationInput;
  where: DriverGrowthScalarWhereInput;
};

export type DriverGrowthUpdateManyWithoutAccountInput = {
  connect?: Maybe<Array<DriverGrowthWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverGrowthCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<DriverGrowthCreateWithoutAccountInput>>;
  createMany?: Maybe<DriverGrowthCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<DriverGrowthWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DriverGrowthScalarWhereInput>>;
  disconnect?: Maybe<Array<DriverGrowthWhereUniqueInput>>;
  set?: Maybe<Array<DriverGrowthWhereUniqueInput>>;
  update?: Maybe<Array<DriverGrowthUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<DriverGrowthUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<DriverGrowthUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type DriverGrowthUpdateManyWithoutChartOfaccountsInput = {
  connect?: Maybe<Array<DriverGrowthWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverGrowthCreateOrConnectWithoutChartOfaccountsInput>>;
  create?: Maybe<Array<DriverGrowthCreateWithoutChartOfaccountsInput>>;
  createMany?: Maybe<DriverGrowthCreateManyChartOfaccountsInputEnvelope>;
  delete?: Maybe<Array<DriverGrowthWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DriverGrowthScalarWhereInput>>;
  disconnect?: Maybe<Array<DriverGrowthWhereUniqueInput>>;
  set?: Maybe<Array<DriverGrowthWhereUniqueInput>>;
  update?: Maybe<Array<DriverGrowthUpdateWithWhereUniqueWithoutChartOfaccountsInput>>;
  updateMany?: Maybe<Array<DriverGrowthUpdateManyWithWhereWithoutChartOfaccountsInput>>;
  upsert?: Maybe<Array<DriverGrowthUpsertWithWhereUniqueWithoutChartOfaccountsInput>>;
};

export type DriverGrowthUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<DriverGrowthWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverGrowthCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<DriverGrowthCreateWithoutPropertyInput>>;
  createMany?: Maybe<DriverGrowthCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<DriverGrowthWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DriverGrowthScalarWhereInput>>;
  disconnect?: Maybe<Array<DriverGrowthWhereUniqueInput>>;
  set?: Maybe<Array<DriverGrowthWhereUniqueInput>>;
  update?: Maybe<Array<DriverGrowthUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<DriverGrowthUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<DriverGrowthUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type DriverGrowthUpdateWithWhereUniqueWithoutAccountInput = {
  data: DriverGrowthUpdateWithoutAccountInput;
  where: DriverGrowthWhereUniqueInput;
};

export type DriverGrowthUpdateWithWhereUniqueWithoutChartOfaccountsInput = {
  data: DriverGrowthUpdateWithoutChartOfaccountsInput;
  where: DriverGrowthWhereUniqueInput;
};

export type DriverGrowthUpdateWithWhereUniqueWithoutPropertyInput = {
  data: DriverGrowthUpdateWithoutPropertyInput;
  where: DriverGrowthWhereUniqueInput;
};

export type DriverGrowthUpdateWithoutAccountInput = {
  annualTargetValueManualEntry?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfaccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutGrowthDriversInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  distributionMethod?: Maybe<NullableEnumDistributionMethodFieldUpdateOperationsInput>;
  growthCalcMethod?: Maybe<EnumGrowthCalcMethodFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lookbackPeriodEnd?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  lookbackPeriodStart?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  monthlyAdjustmentType?: Maybe<NullableEnumMonthlyAdjustmentTypeFieldUpdateOperationsInput>;
  monthlyAdjustmentValue?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  monthlyAverageBasePeriod?: Maybe<NullableEnumMonthlyAverageBasePeriodFieldUpdateOperationsInput>;
  percentGrowthAdjustmentValue?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentGrowthBasePeriod?: Maybe<NullableEnumPercentGrowthBasePeriodFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutGrowthDriversInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DriverGrowthUpdateWithoutChartOfaccountsInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutGrowthDriversInput>;
  annualTargetValueManualEntry?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  distributionMethod?: Maybe<NullableEnumDistributionMethodFieldUpdateOperationsInput>;
  growthCalcMethod?: Maybe<EnumGrowthCalcMethodFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lookbackPeriodEnd?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  lookbackPeriodStart?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  monthlyAdjustmentType?: Maybe<NullableEnumMonthlyAdjustmentTypeFieldUpdateOperationsInput>;
  monthlyAdjustmentValue?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  monthlyAverageBasePeriod?: Maybe<NullableEnumMonthlyAverageBasePeriodFieldUpdateOperationsInput>;
  percentGrowthAdjustmentValue?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentGrowthBasePeriod?: Maybe<NullableEnumPercentGrowthBasePeriodFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutGrowthDriversInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DriverGrowthUpdateWithoutPropertyInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutGrowthDriversInput>;
  annualTargetValueManualEntry?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfaccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutGrowthDriversInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  distributionMethod?: Maybe<NullableEnumDistributionMethodFieldUpdateOperationsInput>;
  growthCalcMethod?: Maybe<EnumGrowthCalcMethodFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lookbackPeriodEnd?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  lookbackPeriodStart?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  monthlyAdjustmentType?: Maybe<NullableEnumMonthlyAdjustmentTypeFieldUpdateOperationsInput>;
  monthlyAdjustmentValue?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  monthlyAverageBasePeriod?: Maybe<NullableEnumMonthlyAverageBasePeriodFieldUpdateOperationsInput>;
  percentGrowthAdjustmentValue?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentGrowthBasePeriod?: Maybe<NullableEnumPercentGrowthBasePeriodFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DriverGrowthUpsertWithWhereUniqueWithoutAccountInput = {
  create: DriverGrowthCreateWithoutAccountInput;
  update: DriverGrowthUpdateWithoutAccountInput;
  where: DriverGrowthWhereUniqueInput;
};

export type DriverGrowthUpsertWithWhereUniqueWithoutChartOfaccountsInput = {
  create: DriverGrowthCreateWithoutChartOfaccountsInput;
  update: DriverGrowthUpdateWithoutChartOfaccountsInput;
  where: DriverGrowthWhereUniqueInput;
};

export type DriverGrowthUpsertWithWhereUniqueWithoutPropertyInput = {
  create: DriverGrowthCreateWithoutPropertyInput;
  update: DriverGrowthUpdateWithoutPropertyInput;
  where: DriverGrowthWhereUniqueInput;
};

export type DriverGrowthWhereInput = {
  AND?: Maybe<Array<DriverGrowthWhereInput>>;
  NOT?: Maybe<Array<DriverGrowthWhereInput>>;
  OR?: Maybe<Array<DriverGrowthWhereInput>>;
  account?: Maybe<AccountV2RelationFilter>;
  accountId?: Maybe<StringFilter>;
  annualTargetValueManualEntry?: Maybe<DecimalNullableFilter>;
  chartOfAccountsId?: Maybe<StringFilter>;
  chartOfaccounts?: Maybe<ChartOfAccountsRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  distributionMethod?: Maybe<EnumDistributionMethodNullableFilter>;
  growthCalcMethod?: Maybe<EnumGrowthCalcMethodFilter>;
  id?: Maybe<StringFilter>;
  lookbackPeriodEnd?: Maybe<DateTimeNullableFilter>;
  lookbackPeriodStart?: Maybe<DateTimeNullableFilter>;
  monthlyAdjustmentType?: Maybe<EnumMonthlyAdjustmentTypeNullableFilter>;
  monthlyAdjustmentValue?: Maybe<DecimalNullableFilter>;
  monthlyAverageBasePeriod?: Maybe<EnumMonthlyAverageBasePeriodNullableFilter>;
  percentGrowthAdjustmentValue?: Maybe<DecimalNullableFilter>;
  percentGrowthBasePeriod?: Maybe<EnumPercentGrowthBasePeriodNullableFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type DriverGrowthWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  year_versionType_propertyId_accountId?: Maybe<DriverGrowthYearVersionTypePropertyIdAccountIdCompoundUniqueInput>;
};

export type DriverGrowthYearVersionTypePropertyIdAccountIdCompoundUniqueInput = {
  accountId: Scalars['String'];
  propertyId: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverListRelationFilter = {
  every?: Maybe<DriverWhereInput>;
  none?: Maybe<DriverWhereInput>;
  some?: Maybe<DriverWhereInput>;
};

export type DriverOperational = {
  __typename?: 'DriverOperational';
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  createdAt: Scalars['DateTime'];
  delayRecognition: Scalars['Int'];
  destinationAccountId: Scalars['String'];
  id: Scalars['String'];
  operationalMetricTypeId: Scalars['String'];
  percentMonth0?: Maybe<Scalars['Decimal']>;
  percentMonth1?: Maybe<Scalars['Decimal']>;
  percentMonth2?: Maybe<Scalars['Decimal']>;
  percentMonth3?: Maybe<Scalars['Decimal']>;
  percentMonth4?: Maybe<Scalars['Decimal']>;
  percentMonth5?: Maybe<Scalars['Decimal']>;
  percentMonth6?: Maybe<Scalars['Decimal']>;
  percentMonth7?: Maybe<Scalars['Decimal']>;
  percentMonth8?: Maybe<Scalars['Decimal']>;
  percentMonth9?: Maybe<Scalars['Decimal']>;
  percentMonth10?: Maybe<Scalars['Decimal']>;
  percentMonth11?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverOperationalCreateManyDestinationAccountInput = {
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  operationalMetricTypeId: Scalars['String'];
  percentMonth0?: Maybe<Scalars['Decimal']>;
  percentMonth1?: Maybe<Scalars['Decimal']>;
  percentMonth2?: Maybe<Scalars['Decimal']>;
  percentMonth3?: Maybe<Scalars['Decimal']>;
  percentMonth4?: Maybe<Scalars['Decimal']>;
  percentMonth5?: Maybe<Scalars['Decimal']>;
  percentMonth6?: Maybe<Scalars['Decimal']>;
  percentMonth7?: Maybe<Scalars['Decimal']>;
  percentMonth8?: Maybe<Scalars['Decimal']>;
  percentMonth9?: Maybe<Scalars['Decimal']>;
  percentMonth10?: Maybe<Scalars['Decimal']>;
  percentMonth11?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverOperationalCreateManyDestinationAccountInputEnvelope = {
  data: Array<DriverOperationalCreateManyDestinationAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DriverOperationalCreateManyOperationalMetricTypeInput = {
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition: Scalars['Int'];
  destinationAccountId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  percentMonth0?: Maybe<Scalars['Decimal']>;
  percentMonth1?: Maybe<Scalars['Decimal']>;
  percentMonth2?: Maybe<Scalars['Decimal']>;
  percentMonth3?: Maybe<Scalars['Decimal']>;
  percentMonth4?: Maybe<Scalars['Decimal']>;
  percentMonth5?: Maybe<Scalars['Decimal']>;
  percentMonth6?: Maybe<Scalars['Decimal']>;
  percentMonth7?: Maybe<Scalars['Decimal']>;
  percentMonth8?: Maybe<Scalars['Decimal']>;
  percentMonth9?: Maybe<Scalars['Decimal']>;
  percentMonth10?: Maybe<Scalars['Decimal']>;
  percentMonth11?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverOperationalCreateManyOperationalMetricTypeInputEnvelope = {
  data: Array<DriverOperationalCreateManyOperationalMetricTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DriverOperationalCreateManyPropertyInput = {
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition: Scalars['Int'];
  destinationAccountId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  operationalMetricTypeId: Scalars['String'];
  percentMonth0?: Maybe<Scalars['Decimal']>;
  percentMonth1?: Maybe<Scalars['Decimal']>;
  percentMonth2?: Maybe<Scalars['Decimal']>;
  percentMonth3?: Maybe<Scalars['Decimal']>;
  percentMonth4?: Maybe<Scalars['Decimal']>;
  percentMonth5?: Maybe<Scalars['Decimal']>;
  percentMonth6?: Maybe<Scalars['Decimal']>;
  percentMonth7?: Maybe<Scalars['Decimal']>;
  percentMonth8?: Maybe<Scalars['Decimal']>;
  percentMonth9?: Maybe<Scalars['Decimal']>;
  percentMonth10?: Maybe<Scalars['Decimal']>;
  percentMonth11?: Maybe<Scalars['Decimal']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverOperationalCreateManyPropertyInputEnvelope = {
  data: Array<DriverOperationalCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DriverOperationalCreateNestedManyWithoutDestinationAccountInput = {
  connect?: Maybe<Array<DriverOperationalWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverOperationalCreateOrConnectWithoutDestinationAccountInput>>;
  create?: Maybe<Array<DriverOperationalCreateWithoutDestinationAccountInput>>;
  createMany?: Maybe<DriverOperationalCreateManyDestinationAccountInputEnvelope>;
};

export type DriverOperationalCreateNestedManyWithoutOperationalMetricTypeInput = {
  connect?: Maybe<Array<DriverOperationalWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverOperationalCreateOrConnectWithoutOperationalMetricTypeInput>>;
  create?: Maybe<Array<DriverOperationalCreateWithoutOperationalMetricTypeInput>>;
  createMany?: Maybe<DriverOperationalCreateManyOperationalMetricTypeInputEnvelope>;
};

export type DriverOperationalCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<DriverOperationalWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverOperationalCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<DriverOperationalCreateWithoutPropertyInput>>;
  createMany?: Maybe<DriverOperationalCreateManyPropertyInputEnvelope>;
};

export type DriverOperationalCreateOrConnectWithoutDestinationAccountInput = {
  create: DriverOperationalCreateWithoutDestinationAccountInput;
  where: DriverOperationalWhereUniqueInput;
};

export type DriverOperationalCreateOrConnectWithoutOperationalMetricTypeInput = {
  create: DriverOperationalCreateWithoutOperationalMetricTypeInput;
  where: DriverOperationalWhereUniqueInput;
};

export type DriverOperationalCreateOrConnectWithoutPropertyInput = {
  create: DriverOperationalCreateWithoutPropertyInput;
  where: DriverOperationalWhereUniqueInput;
};

export type DriverOperationalCreateWithoutDestinationAccountInput = {
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  operationalMetricType: OperationalMetricTypeCreateNestedOneWithoutOperationalDriversInput;
  percentMonth0?: Maybe<Scalars['Decimal']>;
  percentMonth1?: Maybe<Scalars['Decimal']>;
  percentMonth2?: Maybe<Scalars['Decimal']>;
  percentMonth3?: Maybe<Scalars['Decimal']>;
  percentMonth4?: Maybe<Scalars['Decimal']>;
  percentMonth5?: Maybe<Scalars['Decimal']>;
  percentMonth6?: Maybe<Scalars['Decimal']>;
  percentMonth7?: Maybe<Scalars['Decimal']>;
  percentMonth8?: Maybe<Scalars['Decimal']>;
  percentMonth9?: Maybe<Scalars['Decimal']>;
  percentMonth10?: Maybe<Scalars['Decimal']>;
  percentMonth11?: Maybe<Scalars['Decimal']>;
  property: PropertyCreateNestedOneWithoutOperationalDriversInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverOperationalCreateWithoutOperationalMetricTypeInput = {
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition: Scalars['Int'];
  destinationAccount: AccountV2CreateNestedOneWithoutOperationalDriversInput;
  id?: Maybe<Scalars['String']>;
  percentMonth0?: Maybe<Scalars['Decimal']>;
  percentMonth1?: Maybe<Scalars['Decimal']>;
  percentMonth2?: Maybe<Scalars['Decimal']>;
  percentMonth3?: Maybe<Scalars['Decimal']>;
  percentMonth4?: Maybe<Scalars['Decimal']>;
  percentMonth5?: Maybe<Scalars['Decimal']>;
  percentMonth6?: Maybe<Scalars['Decimal']>;
  percentMonth7?: Maybe<Scalars['Decimal']>;
  percentMonth8?: Maybe<Scalars['Decimal']>;
  percentMonth9?: Maybe<Scalars['Decimal']>;
  percentMonth10?: Maybe<Scalars['Decimal']>;
  percentMonth11?: Maybe<Scalars['Decimal']>;
  property: PropertyCreateNestedOneWithoutOperationalDriversInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverOperationalCreateWithoutPropertyInput = {
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition: Scalars['Int'];
  destinationAccount: AccountV2CreateNestedOneWithoutOperationalDriversInput;
  id?: Maybe<Scalars['String']>;
  operationalMetricType: OperationalMetricTypeCreateNestedOneWithoutOperationalDriversInput;
  percentMonth0?: Maybe<Scalars['Decimal']>;
  percentMonth1?: Maybe<Scalars['Decimal']>;
  percentMonth2?: Maybe<Scalars['Decimal']>;
  percentMonth3?: Maybe<Scalars['Decimal']>;
  percentMonth4?: Maybe<Scalars['Decimal']>;
  percentMonth5?: Maybe<Scalars['Decimal']>;
  percentMonth6?: Maybe<Scalars['Decimal']>;
  percentMonth7?: Maybe<Scalars['Decimal']>;
  percentMonth8?: Maybe<Scalars['Decimal']>;
  percentMonth9?: Maybe<Scalars['Decimal']>;
  percentMonth10?: Maybe<Scalars['Decimal']>;
  percentMonth11?: Maybe<Scalars['Decimal']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverOperationalListRelationFilter = {
  every?: Maybe<DriverOperationalWhereInput>;
  none?: Maybe<DriverOperationalWhereInput>;
  some?: Maybe<DriverOperationalWhereInput>;
};

export type DriverOperationalOrderByInput = {
  amountMonth0?: Maybe<SortOrder>;
  amountMonth1?: Maybe<SortOrder>;
  amountMonth2?: Maybe<SortOrder>;
  amountMonth3?: Maybe<SortOrder>;
  amountMonth4?: Maybe<SortOrder>;
  amountMonth5?: Maybe<SortOrder>;
  amountMonth6?: Maybe<SortOrder>;
  amountMonth7?: Maybe<SortOrder>;
  amountMonth8?: Maybe<SortOrder>;
  amountMonth9?: Maybe<SortOrder>;
  amountMonth10?: Maybe<SortOrder>;
  amountMonth11?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  delayRecognition?: Maybe<SortOrder>;
  destinationAccountId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  operationalMetricTypeId?: Maybe<SortOrder>;
  percentMonth0?: Maybe<SortOrder>;
  percentMonth1?: Maybe<SortOrder>;
  percentMonth2?: Maybe<SortOrder>;
  percentMonth3?: Maybe<SortOrder>;
  percentMonth4?: Maybe<SortOrder>;
  percentMonth5?: Maybe<SortOrder>;
  percentMonth6?: Maybe<SortOrder>;
  percentMonth7?: Maybe<SortOrder>;
  percentMonth8?: Maybe<SortOrder>;
  percentMonth9?: Maybe<SortOrder>;
  percentMonth10?: Maybe<SortOrder>;
  percentMonth11?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  versionType?: Maybe<SortOrder>;
  year?: Maybe<SortOrder>;
};

export enum DriverOperationalScalarFieldEnum {
  AmountMonth0 = 'amountMonth0',
  AmountMonth1 = 'amountMonth1',
  AmountMonth2 = 'amountMonth2',
  AmountMonth3 = 'amountMonth3',
  AmountMonth4 = 'amountMonth4',
  AmountMonth5 = 'amountMonth5',
  AmountMonth6 = 'amountMonth6',
  AmountMonth7 = 'amountMonth7',
  AmountMonth8 = 'amountMonth8',
  AmountMonth9 = 'amountMonth9',
  AmountMonth10 = 'amountMonth10',
  AmountMonth11 = 'amountMonth11',
  CreatedAt = 'createdAt',
  DelayRecognition = 'delayRecognition',
  DestinationAccountId = 'destinationAccountId',
  Id = 'id',
  OperationalMetricTypeId = 'operationalMetricTypeId',
  PercentMonth0 = 'percentMonth0',
  PercentMonth1 = 'percentMonth1',
  PercentMonth2 = 'percentMonth2',
  PercentMonth3 = 'percentMonth3',
  PercentMonth4 = 'percentMonth4',
  PercentMonth5 = 'percentMonth5',
  PercentMonth6 = 'percentMonth6',
  PercentMonth7 = 'percentMonth7',
  PercentMonth8 = 'percentMonth8',
  PercentMonth9 = 'percentMonth9',
  PercentMonth10 = 'percentMonth10',
  PercentMonth11 = 'percentMonth11',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt',
  VersionType = 'versionType',
  Year = 'year'
}

export type DriverOperationalScalarWhereInput = {
  AND?: Maybe<Array<DriverOperationalScalarWhereInput>>;
  NOT?: Maybe<Array<DriverOperationalScalarWhereInput>>;
  OR?: Maybe<Array<DriverOperationalScalarWhereInput>>;
  amountMonth0?: Maybe<DecimalNullableFilter>;
  amountMonth1?: Maybe<DecimalNullableFilter>;
  amountMonth2?: Maybe<DecimalNullableFilter>;
  amountMonth3?: Maybe<DecimalNullableFilter>;
  amountMonth4?: Maybe<DecimalNullableFilter>;
  amountMonth5?: Maybe<DecimalNullableFilter>;
  amountMonth6?: Maybe<DecimalNullableFilter>;
  amountMonth7?: Maybe<DecimalNullableFilter>;
  amountMonth8?: Maybe<DecimalNullableFilter>;
  amountMonth9?: Maybe<DecimalNullableFilter>;
  amountMonth10?: Maybe<DecimalNullableFilter>;
  amountMonth11?: Maybe<DecimalNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  delayRecognition?: Maybe<IntFilter>;
  destinationAccountId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  operationalMetricTypeId?: Maybe<StringFilter>;
  percentMonth0?: Maybe<DecimalNullableFilter>;
  percentMonth1?: Maybe<DecimalNullableFilter>;
  percentMonth2?: Maybe<DecimalNullableFilter>;
  percentMonth3?: Maybe<DecimalNullableFilter>;
  percentMonth4?: Maybe<DecimalNullableFilter>;
  percentMonth5?: Maybe<DecimalNullableFilter>;
  percentMonth6?: Maybe<DecimalNullableFilter>;
  percentMonth7?: Maybe<DecimalNullableFilter>;
  percentMonth8?: Maybe<DecimalNullableFilter>;
  percentMonth9?: Maybe<DecimalNullableFilter>;
  percentMonth10?: Maybe<DecimalNullableFilter>;
  percentMonth11?: Maybe<DecimalNullableFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type DriverOperationalUpdateManyMutationInput = {
  amountMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  percentMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DriverOperationalUpdateManyWithWhereWithoutDestinationAccountInput = {
  data: DriverOperationalUpdateManyMutationInput;
  where: DriverOperationalScalarWhereInput;
};

export type DriverOperationalUpdateManyWithWhereWithoutOperationalMetricTypeInput = {
  data: DriverOperationalUpdateManyMutationInput;
  where: DriverOperationalScalarWhereInput;
};

export type DriverOperationalUpdateManyWithWhereWithoutPropertyInput = {
  data: DriverOperationalUpdateManyMutationInput;
  where: DriverOperationalScalarWhereInput;
};

export type DriverOperationalUpdateManyWithoutDestinationAccountInput = {
  connect?: Maybe<Array<DriverOperationalWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverOperationalCreateOrConnectWithoutDestinationAccountInput>>;
  create?: Maybe<Array<DriverOperationalCreateWithoutDestinationAccountInput>>;
  createMany?: Maybe<DriverOperationalCreateManyDestinationAccountInputEnvelope>;
  delete?: Maybe<Array<DriverOperationalWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DriverOperationalScalarWhereInput>>;
  disconnect?: Maybe<Array<DriverOperationalWhereUniqueInput>>;
  set?: Maybe<Array<DriverOperationalWhereUniqueInput>>;
  update?: Maybe<Array<DriverOperationalUpdateWithWhereUniqueWithoutDestinationAccountInput>>;
  updateMany?: Maybe<Array<DriverOperationalUpdateManyWithWhereWithoutDestinationAccountInput>>;
  upsert?: Maybe<Array<DriverOperationalUpsertWithWhereUniqueWithoutDestinationAccountInput>>;
};

export type DriverOperationalUpdateManyWithoutOperationalMetricTypeInput = {
  connect?: Maybe<Array<DriverOperationalWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverOperationalCreateOrConnectWithoutOperationalMetricTypeInput>>;
  create?: Maybe<Array<DriverOperationalCreateWithoutOperationalMetricTypeInput>>;
  createMany?: Maybe<DriverOperationalCreateManyOperationalMetricTypeInputEnvelope>;
  delete?: Maybe<Array<DriverOperationalWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DriverOperationalScalarWhereInput>>;
  disconnect?: Maybe<Array<DriverOperationalWhereUniqueInput>>;
  set?: Maybe<Array<DriverOperationalWhereUniqueInput>>;
  update?: Maybe<Array<DriverOperationalUpdateWithWhereUniqueWithoutOperationalMetricTypeInput>>;
  updateMany?: Maybe<Array<DriverOperationalUpdateManyWithWhereWithoutOperationalMetricTypeInput>>;
  upsert?: Maybe<Array<DriverOperationalUpsertWithWhereUniqueWithoutOperationalMetricTypeInput>>;
};

export type DriverOperationalUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<DriverOperationalWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverOperationalCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<DriverOperationalCreateWithoutPropertyInput>>;
  createMany?: Maybe<DriverOperationalCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<DriverOperationalWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DriverOperationalScalarWhereInput>>;
  disconnect?: Maybe<Array<DriverOperationalWhereUniqueInput>>;
  set?: Maybe<Array<DriverOperationalWhereUniqueInput>>;
  update?: Maybe<Array<DriverOperationalUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<DriverOperationalUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<DriverOperationalUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type DriverOperationalUpdateWithWhereUniqueWithoutDestinationAccountInput = {
  data: DriverOperationalUpdateWithoutDestinationAccountInput;
  where: DriverOperationalWhereUniqueInput;
};

export type DriverOperationalUpdateWithWhereUniqueWithoutOperationalMetricTypeInput = {
  data: DriverOperationalUpdateWithoutOperationalMetricTypeInput;
  where: DriverOperationalWhereUniqueInput;
};

export type DriverOperationalUpdateWithWhereUniqueWithoutPropertyInput = {
  data: DriverOperationalUpdateWithoutPropertyInput;
  where: DriverOperationalWhereUniqueInput;
};

export type DriverOperationalUpdateWithoutDestinationAccountInput = {
  amountMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  operationalMetricType?: Maybe<OperationalMetricTypeUpdateOneRequiredWithoutOperationalDriversInput>;
  percentMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutOperationalDriversInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DriverOperationalUpdateWithoutOperationalMetricTypeInput = {
  amountMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  destinationAccount?: Maybe<AccountV2UpdateOneRequiredWithoutOperationalDriversInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  percentMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutOperationalDriversInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DriverOperationalUpdateWithoutPropertyInput = {
  amountMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  destinationAccount?: Maybe<AccountV2UpdateOneRequiredWithoutOperationalDriversInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  operationalMetricType?: Maybe<OperationalMetricTypeUpdateOneRequiredWithoutOperationalDriversInput>;
  percentMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  percentMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DriverOperationalUpsertWithWhereUniqueWithoutDestinationAccountInput = {
  create: DriverOperationalCreateWithoutDestinationAccountInput;
  update: DriverOperationalUpdateWithoutDestinationAccountInput;
  where: DriverOperationalWhereUniqueInput;
};

export type DriverOperationalUpsertWithWhereUniqueWithoutOperationalMetricTypeInput = {
  create: DriverOperationalCreateWithoutOperationalMetricTypeInput;
  update: DriverOperationalUpdateWithoutOperationalMetricTypeInput;
  where: DriverOperationalWhereUniqueInput;
};

export type DriverOperationalUpsertWithWhereUniqueWithoutPropertyInput = {
  create: DriverOperationalCreateWithoutPropertyInput;
  update: DriverOperationalUpdateWithoutPropertyInput;
  where: DriverOperationalWhereUniqueInput;
};

export type DriverOperationalWhereInput = {
  AND?: Maybe<Array<DriverOperationalWhereInput>>;
  NOT?: Maybe<Array<DriverOperationalWhereInput>>;
  OR?: Maybe<Array<DriverOperationalWhereInput>>;
  amountMonth0?: Maybe<DecimalNullableFilter>;
  amountMonth1?: Maybe<DecimalNullableFilter>;
  amountMonth2?: Maybe<DecimalNullableFilter>;
  amountMonth3?: Maybe<DecimalNullableFilter>;
  amountMonth4?: Maybe<DecimalNullableFilter>;
  amountMonth5?: Maybe<DecimalNullableFilter>;
  amountMonth6?: Maybe<DecimalNullableFilter>;
  amountMonth7?: Maybe<DecimalNullableFilter>;
  amountMonth8?: Maybe<DecimalNullableFilter>;
  amountMonth9?: Maybe<DecimalNullableFilter>;
  amountMonth10?: Maybe<DecimalNullableFilter>;
  amountMonth11?: Maybe<DecimalNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  delayRecognition?: Maybe<IntFilter>;
  destinationAccount?: Maybe<AccountV2RelationFilter>;
  destinationAccountId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  operationalMetricType?: Maybe<OperationalMetricTypeRelationFilter>;
  operationalMetricTypeId?: Maybe<StringFilter>;
  percentMonth0?: Maybe<DecimalNullableFilter>;
  percentMonth1?: Maybe<DecimalNullableFilter>;
  percentMonth2?: Maybe<DecimalNullableFilter>;
  percentMonth3?: Maybe<DecimalNullableFilter>;
  percentMonth4?: Maybe<DecimalNullableFilter>;
  percentMonth5?: Maybe<DecimalNullableFilter>;
  percentMonth6?: Maybe<DecimalNullableFilter>;
  percentMonth7?: Maybe<DecimalNullableFilter>;
  percentMonth8?: Maybe<DecimalNullableFilter>;
  percentMonth9?: Maybe<DecimalNullableFilter>;
  percentMonth10?: Maybe<DecimalNullableFilter>;
  percentMonth11?: Maybe<DecimalNullableFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type DriverOperationalWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  year_versionType_propertyId_destinationAccountId_operationalMetricTypeId?: Maybe<DriverOperationalYearVersionTypePropertyIdDestinationAccountIdOperationalMetricTypeIdCompoundUniqueInput>;
};

export type DriverOperationalYearVersionTypePropertyIdDestinationAccountIdOperationalMetricTypeIdCompoundUniqueInput = {
  destinationAccountId: Scalars['String'];
  operationalMetricTypeId: Scalars['String'];
  propertyId: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverPayrollMetric = {
  compId?: Maybe<Scalars['String']>;
  itemType: Scalars['String'];
  positions: Array<DriverPayrollMetricPositions>;
};

export type DriverPayrollMetricPositions = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type DriverRelationFilter = {
  is?: Maybe<DriverWhereInput>;
  isNot?: Maybe<DriverWhereInput>;
};

export type DriverRevenue = {
  __typename?: 'DriverRevenue';
  createdAt: Scalars['DateTime'];
  destinationAccountId: Scalars['String'];
  id: Scalars['String'];
  propertyId: Scalars['String'];
  revenueType: RevenueType;
  updatedAt: Scalars['DateTime'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverRevenueCreateManyDestinationAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  revenueType: RevenueType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverRevenueCreateManyDestinationAccountInputEnvelope = {
  data: Array<DriverRevenueCreateManyDestinationAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DriverRevenueCreateManyPropertyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationAccountId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  revenueType: RevenueType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverRevenueCreateManyPropertyInputEnvelope = {
  data: Array<DriverRevenueCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DriverRevenueCreateNestedManyWithoutDestinationAccountInput = {
  connect?: Maybe<Array<DriverRevenueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverRevenueCreateOrConnectWithoutDestinationAccountInput>>;
  create?: Maybe<Array<DriverRevenueCreateWithoutDestinationAccountInput>>;
  createMany?: Maybe<DriverRevenueCreateManyDestinationAccountInputEnvelope>;
};

export type DriverRevenueCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<DriverRevenueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverRevenueCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<DriverRevenueCreateWithoutPropertyInput>>;
  createMany?: Maybe<DriverRevenueCreateManyPropertyInputEnvelope>;
};

export type DriverRevenueCreateOrConnectWithoutDestinationAccountInput = {
  create: DriverRevenueCreateWithoutDestinationAccountInput;
  where: DriverRevenueWhereUniqueInput;
};

export type DriverRevenueCreateOrConnectWithoutPropertyInput = {
  create: DriverRevenueCreateWithoutPropertyInput;
  where: DriverRevenueWhereUniqueInput;
};

export type DriverRevenueCreateWithoutDestinationAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  property: PropertyCreateNestedOneWithoutRevenueDriversInput;
  revenueType: RevenueType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverRevenueCreateWithoutPropertyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationAccount: AccountV2CreateNestedOneWithoutRevenueDriversInput;
  id?: Maybe<Scalars['String']>;
  revenueType: RevenueType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverRevenueListRelationFilter = {
  every?: Maybe<DriverRevenueWhereInput>;
  none?: Maybe<DriverRevenueWhereInput>;
  some?: Maybe<DriverRevenueWhereInput>;
};

export type DriverRevenueMetric = {
  revenueType: RevenueType;
  sourceId: Scalars['String'];
};

export type DriverRevenueOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  destinationAccountId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  revenueType?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  versionType?: Maybe<SortOrder>;
  year?: Maybe<SortOrder>;
};

export enum DriverRevenueScalarFieldEnum {
  CreatedAt = 'createdAt',
  DestinationAccountId = 'destinationAccountId',
  Id = 'id',
  PropertyId = 'propertyId',
  RevenueType = 'revenueType',
  UpdatedAt = 'updatedAt',
  VersionType = 'versionType',
  Year = 'year'
}

export type DriverRevenueScalarWhereInput = {
  AND?: Maybe<Array<DriverRevenueScalarWhereInput>>;
  NOT?: Maybe<Array<DriverRevenueScalarWhereInput>>;
  OR?: Maybe<Array<DriverRevenueScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  destinationAccountId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  revenueType?: Maybe<EnumRevenueTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type DriverRevenueUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  revenueType?: Maybe<EnumRevenueTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DriverRevenueUpdateManyWithWhereWithoutDestinationAccountInput = {
  data: DriverRevenueUpdateManyMutationInput;
  where: DriverRevenueScalarWhereInput;
};

export type DriverRevenueUpdateManyWithWhereWithoutPropertyInput = {
  data: DriverRevenueUpdateManyMutationInput;
  where: DriverRevenueScalarWhereInput;
};

export type DriverRevenueUpdateManyWithoutDestinationAccountInput = {
  connect?: Maybe<Array<DriverRevenueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverRevenueCreateOrConnectWithoutDestinationAccountInput>>;
  create?: Maybe<Array<DriverRevenueCreateWithoutDestinationAccountInput>>;
  createMany?: Maybe<DriverRevenueCreateManyDestinationAccountInputEnvelope>;
  delete?: Maybe<Array<DriverRevenueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DriverRevenueScalarWhereInput>>;
  disconnect?: Maybe<Array<DriverRevenueWhereUniqueInput>>;
  set?: Maybe<Array<DriverRevenueWhereUniqueInput>>;
  update?: Maybe<Array<DriverRevenueUpdateWithWhereUniqueWithoutDestinationAccountInput>>;
  updateMany?: Maybe<Array<DriverRevenueUpdateManyWithWhereWithoutDestinationAccountInput>>;
  upsert?: Maybe<Array<DriverRevenueUpsertWithWhereUniqueWithoutDestinationAccountInput>>;
};

export type DriverRevenueUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<DriverRevenueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverRevenueCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<DriverRevenueCreateWithoutPropertyInput>>;
  createMany?: Maybe<DriverRevenueCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<DriverRevenueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DriverRevenueScalarWhereInput>>;
  disconnect?: Maybe<Array<DriverRevenueWhereUniqueInput>>;
  set?: Maybe<Array<DriverRevenueWhereUniqueInput>>;
  update?: Maybe<Array<DriverRevenueUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<DriverRevenueUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<DriverRevenueUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type DriverRevenueUpdateWithWhereUniqueWithoutDestinationAccountInput = {
  data: DriverRevenueUpdateWithoutDestinationAccountInput;
  where: DriverRevenueWhereUniqueInput;
};

export type DriverRevenueUpdateWithWhereUniqueWithoutPropertyInput = {
  data: DriverRevenueUpdateWithoutPropertyInput;
  where: DriverRevenueWhereUniqueInput;
};

export type DriverRevenueUpdateWithoutDestinationAccountInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutRevenueDriversInput>;
  revenueType?: Maybe<EnumRevenueTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DriverRevenueUpdateWithoutPropertyInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  destinationAccount?: Maybe<AccountV2UpdateOneRequiredWithoutRevenueDriversInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  revenueType?: Maybe<EnumRevenueTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DriverRevenueUpsertWithWhereUniqueWithoutDestinationAccountInput = {
  create: DriverRevenueCreateWithoutDestinationAccountInput;
  update: DriverRevenueUpdateWithoutDestinationAccountInput;
  where: DriverRevenueWhereUniqueInput;
};

export type DriverRevenueUpsertWithWhereUniqueWithoutPropertyInput = {
  create: DriverRevenueCreateWithoutPropertyInput;
  update: DriverRevenueUpdateWithoutPropertyInput;
  where: DriverRevenueWhereUniqueInput;
};

export type DriverRevenueWhereInput = {
  AND?: Maybe<Array<DriverRevenueWhereInput>>;
  NOT?: Maybe<Array<DriverRevenueWhereInput>>;
  OR?: Maybe<Array<DriverRevenueWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  destinationAccount?: Maybe<AccountV2RelationFilter>;
  destinationAccountId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  revenueType?: Maybe<EnumRevenueTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type DriverRevenueWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  year_versionType_propertyId_revenueType?: Maybe<DriverRevenueYearVersionTypePropertyIdRevenueTypeCompoundUniqueInput>;
};

export type DriverRevenueYearVersionTypePropertyIdRevenueTypeCompoundUniqueInput = {
  propertyId: Scalars['String'];
  revenueType: RevenueType;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type DriverScalarWhereInput = {
  AND?: Maybe<Array<DriverScalarWhereInput>>;
  NOT?: Maybe<Array<DriverScalarWhereInput>>;
  OR?: Maybe<Array<DriverScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  driverAssignmentId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
  type?: Maybe<EnumDriverTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type DriverStatusForAccount = {
  __typename?: 'DriverStatusForAccount';
  accPercentageSourceForAccounts: Array<AccountV2>;
  accountId: Scalars['ID'];
  financialMetrics: Array<TopCardTagType>;
  hasModellingMethods: Scalars['Boolean'];
  renovationCostCategories: Array<Scalars['String']>;
};

export enum DriverType {
  AccPercentage = 'ACC_PERCENTAGE',
  Operational = 'OPERATIONAL',
  Revenue = 'REVENUE',
  Worksheet = 'WORKSHEET'
}

export type DriverUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<EnumDriverTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type DriverUpdateManyWithWhereWithoutDriverAssignmentInput = {
  data: DriverUpdateManyMutationInput;
  where: DriverScalarWhereInput;
};

export type DriverUpdateManyWithoutDriverAssignmentInput = {
  connect?: Maybe<Array<DriverWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DriverCreateOrConnectWithoutDriverAssignmentInput>>;
  create?: Maybe<Array<DriverCreateWithoutDriverAssignmentInput>>;
  createMany?: Maybe<DriverCreateManyDriverAssignmentInputEnvelope>;
  delete?: Maybe<Array<DriverWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DriverScalarWhereInput>>;
  disconnect?: Maybe<Array<DriverWhereUniqueInput>>;
  set?: Maybe<Array<DriverWhereUniqueInput>>;
  update?: Maybe<Array<DriverUpdateWithWhereUniqueWithoutDriverAssignmentInput>>;
  updateMany?: Maybe<Array<DriverUpdateManyWithWhereWithoutDriverAssignmentInput>>;
  upsert?: Maybe<Array<DriverUpsertWithWhereUniqueWithoutDriverAssignmentInput>>;
};

export type DriverUpdateOneRequiredWithoutAccPercentageDriverAccountsInput = {
  connect?: Maybe<DriverWhereUniqueInput>;
  connectOrCreate?: Maybe<DriverCreateOrConnectWithoutAccPercentageDriverAccountsInput>;
  create?: Maybe<DriverCreateWithoutAccPercentageDriverAccountsInput>;
  update?: Maybe<DriverUpdateWithoutAccPercentageDriverAccountsInput>;
  upsert?: Maybe<DriverUpsertWithoutAccPercentageDriverAccountsInput>;
};

export type DriverUpdateOneRequiredWithoutOperationalDriverMetricTypesInput = {
  connect?: Maybe<DriverWhereUniqueInput>;
  connectOrCreate?: Maybe<DriverCreateOrConnectWithoutOperationalDriverMetricTypesInput>;
  create?: Maybe<DriverCreateWithoutOperationalDriverMetricTypesInput>;
  update?: Maybe<DriverUpdateWithoutOperationalDriverMetricTypesInput>;
  upsert?: Maybe<DriverUpsertWithoutOperationalDriverMetricTypesInput>;
};

export type DriverUpdateOneRequiredWithoutRevenueDriverTypesInput = {
  connect?: Maybe<DriverWhereUniqueInput>;
  connectOrCreate?: Maybe<DriverCreateOrConnectWithoutRevenueDriverTypesInput>;
  create?: Maybe<DriverCreateWithoutRevenueDriverTypesInput>;
  update?: Maybe<DriverUpdateWithoutRevenueDriverTypesInput>;
  upsert?: Maybe<DriverUpsertWithoutRevenueDriverTypesInput>;
};

export type DriverUpdateOneRequiredWithoutWorksheetAssumptionsInput = {
  connect?: Maybe<DriverWhereUniqueInput>;
  connectOrCreate?: Maybe<DriverCreateOrConnectWithoutWorksheetAssumptionsInput>;
  create?: Maybe<DriverCreateWithoutWorksheetAssumptionsInput>;
  update?: Maybe<DriverUpdateWithoutWorksheetAssumptionsInput>;
  upsert?: Maybe<DriverUpsertWithoutWorksheetAssumptionsInput>;
};

export type DriverUpdateWithWhereUniqueWithoutDriverAssignmentInput = {
  data: DriverUpdateWithoutDriverAssignmentInput;
  where: DriverWhereUniqueInput;
};

export type DriverUpdateWithoutAccPercentageDriverAccountsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  driverAssignment?: Maybe<DriverAssignmentUpdateOneRequiredWithoutDriversInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDriverMetricTypes?: Maybe<OperationalDriverMetricTypeUpdateManyWithoutDriverInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  revenueDriverTypes?: Maybe<RevenueDriverTypeUpdateManyWithoutDriverInput>;
  type?: Maybe<EnumDriverTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutWorksheetDriverInput>;
};

export type DriverUpdateWithoutDriverAssignmentInput = {
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutDriverInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDriverMetricTypes?: Maybe<OperationalDriverMetricTypeUpdateManyWithoutDriverInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  revenueDriverTypes?: Maybe<RevenueDriverTypeUpdateManyWithoutDriverInput>;
  type?: Maybe<EnumDriverTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutWorksheetDriverInput>;
};

export type DriverUpdateWithoutOperationalDriverMetricTypesInput = {
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutDriverInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  driverAssignment?: Maybe<DriverAssignmentUpdateOneRequiredWithoutDriversInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  revenueDriverTypes?: Maybe<RevenueDriverTypeUpdateManyWithoutDriverInput>;
  type?: Maybe<EnumDriverTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutWorksheetDriverInput>;
};

export type DriverUpdateWithoutRevenueDriverTypesInput = {
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutDriverInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  driverAssignment?: Maybe<DriverAssignmentUpdateOneRequiredWithoutDriversInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDriverMetricTypes?: Maybe<OperationalDriverMetricTypeUpdateManyWithoutDriverInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<EnumDriverTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutWorksheetDriverInput>;
};

export type DriverUpdateWithoutWorksheetAssumptionsInput = {
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountUpdateManyWithoutDriverInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  driverAssignment?: Maybe<DriverAssignmentUpdateOneRequiredWithoutDriversInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDriverMetricTypes?: Maybe<OperationalDriverMetricTypeUpdateManyWithoutDriverInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  revenueDriverTypes?: Maybe<RevenueDriverTypeUpdateManyWithoutDriverInput>;
  type?: Maybe<EnumDriverTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type DriverUpsertWithWhereUniqueWithoutDriverAssignmentInput = {
  create: DriverCreateWithoutDriverAssignmentInput;
  update: DriverUpdateWithoutDriverAssignmentInput;
  where: DriverWhereUniqueInput;
};

export type DriverUpsertWithoutAccPercentageDriverAccountsInput = {
  create: DriverCreateWithoutAccPercentageDriverAccountsInput;
  update: DriverUpdateWithoutAccPercentageDriverAccountsInput;
};

export type DriverUpsertWithoutOperationalDriverMetricTypesInput = {
  create: DriverCreateWithoutOperationalDriverMetricTypesInput;
  update: DriverUpdateWithoutOperationalDriverMetricTypesInput;
};

export type DriverUpsertWithoutRevenueDriverTypesInput = {
  create: DriverCreateWithoutRevenueDriverTypesInput;
  update: DriverUpdateWithoutRevenueDriverTypesInput;
};

export type DriverUpsertWithoutWorksheetAssumptionsInput = {
  create: DriverCreateWithoutWorksheetAssumptionsInput;
  update: DriverUpdateWithoutWorksheetAssumptionsInput;
};

export type DriverWhereInput = {
  AND?: Maybe<Array<DriverWhereInput>>;
  NOT?: Maybe<Array<DriverWhereInput>>;
  OR?: Maybe<Array<DriverWhereInput>>;
  accPercentageDriverAccounts?: Maybe<AccPercentageDriverAccountListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  driverAssignment?: Maybe<DriverAssignmentRelationFilter>;
  driverAssignmentId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  operationalDriverMetricTypes?: Maybe<OperationalDriverMetricTypeListRelationFilter>;
  order?: Maybe<IntFilter>;
  revenueDriverTypes?: Maybe<RevenueDriverTypeListRelationFilter>;
  type?: Maybe<EnumDriverTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionListRelationFilter>;
};

export type DriverWhereUniqueInput = {
  driverAssignmentId_type?: Maybe<DriverDriverAssignmentIdTypeCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type DriverWorksheetMetric = {
  description: Scalars['String'];
};

export type DriversCopyPeriodInput = {
  destinationVersionType: VersionType;
  sourceVersionType: VersionType;
};

export type EnrichedUnitEventModel = {
  __typename?: 'EnrichedUnitEventModel';
  concessionMonths?: Maybe<Scalars['Decimal']>;
  endingCalculatedInPlaceRent: Scalars['Decimal'];
  endingLossToLease?: Maybe<Scalars['Decimal']>;
  endingMarketRent?: Maybe<Scalars['Decimal']>;
  eventEnd: Scalars['DateTime'];
  eventStart: Scalars['DateTime'];
  eventType: UnitEventType;
  id: Scalars['ID'];
  importedEvent: Scalars['Boolean'];
  inPlaceRent?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  renewalAdjustmentPercentage?: Maybe<Scalars['Decimal']>;
  renovationCost?: Maybe<Scalars['Decimal']>;
  renovationPremium?: Maybe<Scalars['Decimal']>;
  shortTermEffectiveAvgDailyRent?: Maybe<Scalars['Decimal']>;
  startingCalculatedInPlaceRent: Scalars['Decimal'];
  startingLossToLease?: Maybe<Scalars['Decimal']>;
  startingMarketRent?: Maybe<Scalars['Decimal']>;
  tenantName?: Maybe<Scalars['String']>;
  unitId: Scalars['ID'];
};

export enum EntityType {
  Account = 'ACCOUNT',
  BudgetCategory = 'BUDGET_CATEGORY',
  BudgetComponent = 'BUDGET_COMPONENT',
  ChartOfAccounts = 'CHART_OF_ACCOUNTS',
  Client = 'CLIENT',
  Property = 'PROPERTY'
}

export type EnumAccountTypeFieldUpdateOperationsInput = {
  set?: Maybe<AccountType>;
};

export type EnumAccountTypeFilter = {
  equals?: Maybe<AccountType>;
  in?: Maybe<Array<AccountType>>;
  not?: Maybe<NestedEnumAccountTypeFilter>;
  notIn?: Maybe<Array<AccountType>>;
};

export type EnumBudgetComponentTypeFieldUpdateOperationsInput = {
  set?: Maybe<BudgetComponentType>;
};

export type EnumBudgetComponentTypeFilter = {
  equals?: Maybe<BudgetComponentType>;
  in?: Maybe<Array<BudgetComponentType>>;
  not?: Maybe<NestedEnumBudgetComponentTypeFilter>;
  notIn?: Maybe<Array<BudgetComponentType>>;
};

export type EnumClientTypeFieldUpdateOperationsInput = {
  set?: Maybe<ClientType>;
};

export type EnumClientTypeFilter = {
  equals?: Maybe<ClientType>;
  in?: Maybe<Array<ClientType>>;
  not?: Maybe<NestedEnumClientTypeFilter>;
  notIn?: Maybe<Array<ClientType>>;
};

export type EnumDistributionMethodNullableFilter = {
  equals?: Maybe<DistributionMethod>;
  in?: Maybe<Array<DistributionMethod>>;
  not?: Maybe<NestedEnumDistributionMethodNullableFilter>;
  notIn?: Maybe<Array<DistributionMethod>>;
};

export type EnumDriverTypeFieldUpdateOperationsInput = {
  set?: Maybe<DriverType>;
};

export type EnumDriverTypeFilter = {
  equals?: Maybe<DriverType>;
  in?: Maybe<Array<DriverType>>;
  not?: Maybe<NestedEnumDriverTypeFilter>;
  notIn?: Maybe<Array<DriverType>>;
};

export type EnumEntityTypeFieldUpdateOperationsInput = {
  set?: Maybe<EntityType>;
};

export type EnumEntityTypeFilter = {
  equals?: Maybe<EntityType>;
  in?: Maybe<Array<EntityType>>;
  not?: Maybe<NestedEnumEntityTypeFilter>;
  notIn?: Maybe<Array<EntityType>>;
};

export type EnumFileUploadStatusFieldUpdateOperationsInput = {
  set?: Maybe<FileUploadStatus>;
};

export type EnumFileUploadStatusFilter = {
  equals?: Maybe<FileUploadStatus>;
  in?: Maybe<Array<FileUploadStatus>>;
  not?: Maybe<NestedEnumFileUploadStatusFilter>;
  notIn?: Maybe<Array<FileUploadStatus>>;
};

export type EnumFileUploadTypeFieldUpdateOperationsInput = {
  set?: Maybe<FileUploadType>;
};

export type EnumFileUploadTypeFilter = {
  equals?: Maybe<FileUploadType>;
  in?: Maybe<Array<FileUploadType>>;
  not?: Maybe<NestedEnumFileUploadTypeFilter>;
  notIn?: Maybe<Array<FileUploadType>>;
};

export type EnumGrowthCalcMethodFieldUpdateOperationsInput = {
  set?: Maybe<GrowthCalcMethod>;
};

export type EnumGrowthCalcMethodFilter = {
  equals?: Maybe<GrowthCalcMethod>;
  in?: Maybe<Array<GrowthCalcMethod>>;
  not?: Maybe<NestedEnumGrowthCalcMethodFilter>;
  notIn?: Maybe<Array<GrowthCalcMethod>>;
};

export type EnumJobStatusFieldUpdateOperationsInput = {
  set?: Maybe<JobStatus>;
};

export type EnumJobStatusFilter = {
  equals?: Maybe<JobStatus>;
  in?: Maybe<Array<JobStatus>>;
  not?: Maybe<NestedEnumJobStatusFilter>;
  notIn?: Maybe<Array<JobStatus>>;
};

export type EnumJobTypeFieldUpdateOperationsInput = {
  set?: Maybe<JobType>;
};

export type EnumJobTypeFilter = {
  equals?: Maybe<JobType>;
  in?: Maybe<Array<JobType>>;
  not?: Maybe<NestedEnumJobTypeFilter>;
  notIn?: Maybe<Array<JobType>>;
};

export type EnumMathOperationTypeFieldUpdateOperationsInput = {
  set?: Maybe<MathOperationType>;
};

export type EnumMathOperationTypeFilter = {
  equals?: Maybe<MathOperationType>;
  in?: Maybe<Array<MathOperationType>>;
  not?: Maybe<NestedEnumMathOperationTypeFilter>;
  notIn?: Maybe<Array<MathOperationType>>;
};

export type EnumMonthlyAdjustmentTypeNullableFilter = {
  equals?: Maybe<MonthlyAdjustmentType>;
  in?: Maybe<Array<MonthlyAdjustmentType>>;
  not?: Maybe<NestedEnumMonthlyAdjustmentTypeNullableFilter>;
  notIn?: Maybe<Array<MonthlyAdjustmentType>>;
};

export type EnumMonthlyAverageBasePeriodNullableFilter = {
  equals?: Maybe<MonthlyAverageBasePeriod>;
  in?: Maybe<Array<MonthlyAverageBasePeriod>>;
  not?: Maybe<NestedEnumMonthlyAverageBasePeriodNullableFilter>;
  notIn?: Maybe<Array<MonthlyAverageBasePeriod>>;
};

export type EnumOperationTypeFieldUpdateOperationsInput = {
  set?: Maybe<OperationType>;
};

export type EnumOperationTypeFilter = {
  equals?: Maybe<OperationType>;
  in?: Maybe<Array<OperationType>>;
  not?: Maybe<NestedEnumOperationTypeFilter>;
  notIn?: Maybe<Array<OperationType>>;
};

export type EnumOperationalFinancialMetricsAggregateTypeFieldUpdateOperationsInput = {
  set?: Maybe<OperationalFinancialMetricsAggregateType>;
};

export type EnumOperationalFinancialMetricsAggregateTypeFilter = {
  equals?: Maybe<OperationalFinancialMetricsAggregateType>;
  in?: Maybe<Array<OperationalFinancialMetricsAggregateType>>;
  not?: Maybe<NestedEnumOperationalFinancialMetricsAggregateTypeFilter>;
  notIn?: Maybe<Array<OperationalFinancialMetricsAggregateType>>;
};

export type EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter = {
  equals?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  in?: Maybe<Array<OriginalRevenueMarketRentAdjustmentType>>;
  not?: Maybe<NestedEnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  notIn?: Maybe<Array<OriginalRevenueMarketRentAdjustmentType>>;
};

export type EnumOriginalRevenueMarketRentModeTypeFieldUpdateOperationsInput = {
  set?: Maybe<OriginalRevenueMarketRentModeType>;
};

export type EnumOriginalRevenueMarketRentModeTypeFilter = {
  equals?: Maybe<OriginalRevenueMarketRentModeType>;
  in?: Maybe<Array<OriginalRevenueMarketRentModeType>>;
  not?: Maybe<NestedEnumOriginalRevenueMarketRentModeTypeFilter>;
  notIn?: Maybe<Array<OriginalRevenueMarketRentModeType>>;
};

export type EnumOriginalRevenueRenewalIncreaseTypeNullableFilter = {
  equals?: Maybe<OriginalRevenueRenewalIncreaseType>;
  in?: Maybe<Array<OriginalRevenueRenewalIncreaseType>>;
  not?: Maybe<NestedEnumOriginalRevenueRenewalIncreaseTypeNullableFilter>;
  notIn?: Maybe<Array<OriginalRevenueRenewalIncreaseType>>;
};

export type EnumPayrollActualCompensationItemSourceNullableFilter = {
  equals?: Maybe<PayrollActualCompensationItemSource>;
  in?: Maybe<Array<PayrollActualCompensationItemSource>>;
  not?: Maybe<NestedEnumPayrollActualCompensationItemSourceNullableFilter>;
  notIn?: Maybe<Array<PayrollActualCompensationItemSource>>;
};

export type EnumPayrollBonusFrequencyFieldUpdateOperationsInput = {
  set?: Maybe<PayrollBonusFrequency>;
};

export type EnumPayrollBonusFrequencyFilter = {
  equals?: Maybe<PayrollBonusFrequency>;
  in?: Maybe<Array<PayrollBonusFrequency>>;
  not?: Maybe<NestedEnumPayrollBonusFrequencyFilter>;
  notIn?: Maybe<Array<PayrollBonusFrequency>>;
};

export type EnumPayrollBonusTypeFieldUpdateOperationsInput = {
  set?: Maybe<PayrollBonusType>;
};

export type EnumPayrollBonusTypeFilter = {
  equals?: Maybe<PayrollBonusType>;
  in?: Maybe<Array<PayrollBonusType>>;
  not?: Maybe<NestedEnumPayrollBonusTypeFilter>;
  notIn?: Maybe<Array<PayrollBonusType>>;
};

export type EnumPayrollCompensationItemCalculationTypeFieldUpdateOperationsInput = {
  set?: Maybe<PayrollCompensationItemCalculationType>;
};

export type EnumPayrollCompensationItemCalculationTypeFilter = {
  equals?: Maybe<PayrollCompensationItemCalculationType>;
  in?: Maybe<Array<PayrollCompensationItemCalculationType>>;
  not?: Maybe<NestedEnumPayrollCompensationItemCalculationTypeFilter>;
  notIn?: Maybe<Array<PayrollCompensationItemCalculationType>>;
};

export type EnumPayrollCompensationItemTypeFieldUpdateOperationsInput = {
  set?: Maybe<PayrollCompensationItemType>;
};

export type EnumPayrollCompensationItemTypeFilter = {
  equals?: Maybe<PayrollCompensationItemType>;
  in?: Maybe<Array<PayrollCompensationItemType>>;
  not?: Maybe<NestedEnumPayrollCompensationItemTypeFilter>;
  notIn?: Maybe<Array<PayrollCompensationItemType>>;
};

export type EnumPayrollCompensationTypeFieldUpdateOperationsInput = {
  set?: Maybe<PayrollCompensationType>;
};

export type EnumPayrollCompensationTypeFilter = {
  equals?: Maybe<PayrollCompensationType>;
  in?: Maybe<Array<PayrollCompensationType>>;
  not?: Maybe<NestedEnumPayrollCompensationTypeFilter>;
  notIn?: Maybe<Array<PayrollCompensationType>>;
};

export type EnumPayrollDefaultLevelFieldUpdateOperationsInput = {
  set?: Maybe<PayrollDefaultLevel>;
};

export type EnumPayrollDefaultLevelFilter = {
  equals?: Maybe<PayrollDefaultLevel>;
  in?: Maybe<Array<PayrollDefaultLevel>>;
  not?: Maybe<NestedEnumPayrollDefaultLevelFilter>;
  notIn?: Maybe<Array<PayrollDefaultLevel>>;
};

export type EnumPayrollEmployeeClassificationFieldUpdateOperationsInput = {
  set?: Maybe<PayrollEmployeeClassification>;
};

export type EnumPayrollEmployeeClassificationFilter = {
  equals?: Maybe<PayrollEmployeeClassification>;
  in?: Maybe<Array<PayrollEmployeeClassification>>;
  not?: Maybe<NestedEnumPayrollEmployeeClassificationFilter>;
  notIn?: Maybe<Array<PayrollEmployeeClassification>>;
};

export type EnumPayrollModeledEmployeeTypeFieldUpdateOperationsInput = {
  set?: Maybe<PayrollModeledEmployeeType>;
};

export type EnumPayrollModeledEmployeeTypeFilter = {
  equals?: Maybe<PayrollModeledEmployeeType>;
  in?: Maybe<Array<PayrollModeledEmployeeType>>;
  not?: Maybe<NestedEnumPayrollModeledEmployeeTypeFilter>;
  notIn?: Maybe<Array<PayrollModeledEmployeeType>>;
};

export type EnumPayrollPayFrequencyFieldUpdateOperationsInput = {
  set?: Maybe<PayrollPayFrequency>;
};

export type EnumPayrollPayFrequencyFilter = {
  equals?: Maybe<PayrollPayFrequency>;
  in?: Maybe<Array<PayrollPayFrequency>>;
  not?: Maybe<NestedEnumPayrollPayFrequencyFilter>;
  notIn?: Maybe<Array<PayrollPayFrequency>>;
};

export type EnumPayrollPrimaryCompensationItemFieldUpdateOperationsInput = {
  set?: Maybe<PayrollPrimaryCompensationItem>;
};

export type EnumPayrollPrimaryCompensationItemFilter = {
  equals?: Maybe<PayrollPrimaryCompensationItem>;
  in?: Maybe<Array<PayrollPrimaryCompensationItem>>;
  not?: Maybe<NestedEnumPayrollPrimaryCompensationItemFilter>;
  notIn?: Maybe<Array<PayrollPrimaryCompensationItem>>;
};

export type EnumPayrollRaiseFrequencyFieldUpdateOperationsInput = {
  set?: Maybe<PayrollRaiseFrequency>;
};

export type EnumPayrollRaiseFrequencyFilter = {
  equals?: Maybe<PayrollRaiseFrequency>;
  in?: Maybe<Array<PayrollRaiseFrequency>>;
  not?: Maybe<NestedEnumPayrollRaiseFrequencyFilter>;
  notIn?: Maybe<Array<PayrollRaiseFrequency>>;
};

export type EnumPayrollRaiseTypeFieldUpdateOperationsInput = {
  set?: Maybe<PayrollRaiseType>;
};

export type EnumPayrollRaiseTypeFilter = {
  equals?: Maybe<PayrollRaiseType>;
  in?: Maybe<Array<PayrollRaiseType>>;
  not?: Maybe<NestedEnumPayrollRaiseTypeFilter>;
  notIn?: Maybe<Array<PayrollRaiseType>>;
};

export type EnumPercentGrowthBasePeriodNullableFilter = {
  equals?: Maybe<PercentGrowthBasePeriod>;
  in?: Maybe<Array<PercentGrowthBasePeriod>>;
  not?: Maybe<NestedEnumPercentGrowthBasePeriodNullableFilter>;
  notIn?: Maybe<Array<PercentGrowthBasePeriod>>;
};

export type EnumPropertyManagementSystemNullableFilter = {
  equals?: Maybe<PropertyManagementSystem>;
  in?: Maybe<Array<PropertyManagementSystem>>;
  not?: Maybe<NestedEnumPropertyManagementSystemNullableFilter>;
  notIn?: Maybe<Array<PropertyManagementSystem>>;
};

export type EnumPropertyManagementSystemNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumPropertyManagementSystemNullableFilter>;
  _min?: Maybe<NestedEnumPropertyManagementSystemNullableFilter>;
  equals?: Maybe<PropertyManagementSystem>;
  in?: Maybe<Array<PropertyManagementSystem>>;
  not?: Maybe<NestedEnumPropertyManagementSystemNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<PropertyManagementSystem>>;
};

export type EnumRevenueModelTypeFieldUpdateOperationsInput = {
  set?: Maybe<RevenueModelType>;
};

export type EnumRevenueModelTypeFilter = {
  equals?: Maybe<RevenueModelType>;
  in?: Maybe<Array<RevenueModelType>>;
  not?: Maybe<NestedEnumRevenueModelTypeFilter>;
  notIn?: Maybe<Array<RevenueModelType>>;
};

export type EnumRevenueTypeFieldUpdateOperationsInput = {
  set?: Maybe<RevenueType>;
};

export type EnumRevenueTypeFilter = {
  equals?: Maybe<RevenueType>;
  in?: Maybe<Array<RevenueType>>;
  not?: Maybe<NestedEnumRevenueTypeFilter>;
  notIn?: Maybe<Array<RevenueType>>;
};

export type EnumTopCardTagTypeFieldUpdateOperationsInput = {
  set?: Maybe<TopCardTagType>;
};

export type EnumTopCardTagTypeFilter = {
  equals?: Maybe<TopCardTagType>;
  in?: Maybe<Array<TopCardTagType>>;
  not?: Maybe<NestedEnumTopCardTagTypeFilter>;
  notIn?: Maybe<Array<TopCardTagType>>;
};

export type EnumUnitEventTypeFieldUpdateOperationsInput = {
  set?: Maybe<UnitEventType>;
};

export type EnumUnitEventTypeFilter = {
  equals?: Maybe<UnitEventType>;
  in?: Maybe<Array<UnitEventType>>;
  not?: Maybe<NestedEnumUnitEventTypeFilter>;
  notIn?: Maybe<Array<UnitEventType>>;
};

export type EnumUserRoleFieldUpdateOperationsInput = {
  set?: Maybe<UserRole>;
};

export type EnumUserRoleFilter = {
  equals?: Maybe<UserRole>;
  in?: Maybe<Array<UserRole>>;
  not?: Maybe<NestedEnumUserRoleFilter>;
  notIn?: Maybe<Array<UserRole>>;
};

export type EnumVersionTypeFieldUpdateOperationsInput = {
  set?: Maybe<VersionType>;
};

export type EnumVersionTypeFilter = {
  equals?: Maybe<VersionType>;
  in?: Maybe<Array<VersionType>>;
  not?: Maybe<NestedEnumVersionTypeFilter>;
  notIn?: Maybe<Array<VersionType>>;
};

export type EnumVersionTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumVersionTypeFilter>;
  _min?: Maybe<NestedEnumVersionTypeFilter>;
  equals?: Maybe<VersionType>;
  in?: Maybe<Array<VersionType>>;
  not?: Maybe<NestedEnumVersionTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<VersionType>>;
};

export type FakeEnrichedUnitEventModel = {
  __typename?: 'FakeEnrichedUnitEventModel';
  concessionMonths?: Maybe<Scalars['Decimal']>;
  endingCalculatedInPlaceRent: Scalars['Decimal'];
  endingLossToLease?: Maybe<Scalars['Decimal']>;
  endingMarketRent?: Maybe<Scalars['Decimal']>;
  eventEnd: Scalars['DateTime'];
  eventStart: Scalars['DateTime'];
  eventType: UnitEventType;
  importedEvent: Scalars['Boolean'];
  inPlaceRent?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  renewalAdjustmentPercentage?: Maybe<Scalars['Decimal']>;
  renovationCost?: Maybe<Scalars['Decimal']>;
  renovationPremium?: Maybe<Scalars['Decimal']>;
  shortTermEffectiveAvgDailyRent?: Maybe<Scalars['Decimal']>;
  startingCalculatedInPlaceRent: Scalars['Decimal'];
  startingLossToLease?: Maybe<Scalars['Decimal']>;
  startingMarketRent?: Maybe<Scalars['Decimal']>;
  tenantName?: Maybe<Scalars['String']>;
  unitId: Scalars['ID'];
};

export type FeatureClientCreateManyClientInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  feature: Scalars['String'];
};

export type FeatureClientCreateManyClientInputEnvelope = {
  data: Array<FeatureClientCreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FeatureClientCreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<FeatureClientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FeatureClientCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<FeatureClientCreateWithoutClientInput>>;
  createMany?: Maybe<FeatureClientCreateManyClientInputEnvelope>;
};

export type FeatureClientCreateOrConnectWithoutClientInput = {
  create: FeatureClientCreateWithoutClientInput;
  where: FeatureClientWhereUniqueInput;
};

export type FeatureClientCreateWithoutClientInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  feature: Scalars['String'];
};

export type FeatureClientFeatureClientIdCompoundUniqueInput = {
  clientId: Scalars['String'];
  feature: Scalars['String'];
};

export type FeatureClientListRelationFilter = {
  every?: Maybe<FeatureClientWhereInput>;
  none?: Maybe<FeatureClientWhereInput>;
  some?: Maybe<FeatureClientWhereInput>;
};

export type FeatureClientScalarWhereInput = {
  AND?: Maybe<Array<FeatureClientScalarWhereInput>>;
  NOT?: Maybe<Array<FeatureClientScalarWhereInput>>;
  OR?: Maybe<Array<FeatureClientScalarWhereInput>>;
  clientId?: Maybe<StringFilter>;
  enabled?: Maybe<BoolFilter>;
  feature?: Maybe<StringFilter>;
};

export type FeatureClientUpdateManyMutationInput = {
  enabled?: Maybe<BoolFieldUpdateOperationsInput>;
  feature?: Maybe<StringFieldUpdateOperationsInput>;
};

export type FeatureClientUpdateManyWithWhereWithoutClientInput = {
  data: FeatureClientUpdateManyMutationInput;
  where: FeatureClientScalarWhereInput;
};

export type FeatureClientUpdateManyWithoutClientInput = {
  connect?: Maybe<Array<FeatureClientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FeatureClientCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<FeatureClientCreateWithoutClientInput>>;
  createMany?: Maybe<FeatureClientCreateManyClientInputEnvelope>;
  delete?: Maybe<Array<FeatureClientWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FeatureClientScalarWhereInput>>;
  disconnect?: Maybe<Array<FeatureClientWhereUniqueInput>>;
  set?: Maybe<Array<FeatureClientWhereUniqueInput>>;
  update?: Maybe<Array<FeatureClientUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<FeatureClientUpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<FeatureClientUpsertWithWhereUniqueWithoutClientInput>>;
};

export type FeatureClientUpdateWithWhereUniqueWithoutClientInput = {
  data: FeatureClientUpdateWithoutClientInput;
  where: FeatureClientWhereUniqueInput;
};

export type FeatureClientUpdateWithoutClientInput = {
  enabled?: Maybe<BoolFieldUpdateOperationsInput>;
  feature?: Maybe<StringFieldUpdateOperationsInput>;
};

export type FeatureClientUpsertWithWhereUniqueWithoutClientInput = {
  create: FeatureClientCreateWithoutClientInput;
  update: FeatureClientUpdateWithoutClientInput;
  where: FeatureClientWhereUniqueInput;
};

export type FeatureClientWhereInput = {
  AND?: Maybe<Array<FeatureClientWhereInput>>;
  NOT?: Maybe<Array<FeatureClientWhereInput>>;
  OR?: Maybe<Array<FeatureClientWhereInput>>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  enabled?: Maybe<BoolFilter>;
  feature?: Maybe<StringFilter>;
};

export type FeatureClientWhereUniqueInput = {
  feature_clientId?: Maybe<FeatureClientFeatureClientIdCompoundUniqueInput>;
};

export type FeatureUserCreateManyUserInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  feature: Scalars['String'];
};

export type FeatureUserCreateManyUserInputEnvelope = {
  data: Array<FeatureUserCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FeatureUserCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<FeatureUserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FeatureUserCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<FeatureUserCreateWithoutUserInput>>;
  createMany?: Maybe<FeatureUserCreateManyUserInputEnvelope>;
};

export type FeatureUserCreateOrConnectWithoutUserInput = {
  create: FeatureUserCreateWithoutUserInput;
  where: FeatureUserWhereUniqueInput;
};

export type FeatureUserCreateWithoutUserInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  feature: Scalars['String'];
};

export type FeatureUserFeatureUserIdCompoundUniqueInput = {
  feature: Scalars['String'];
  userId: Scalars['String'];
};

export type FeatureUserListRelationFilter = {
  every?: Maybe<FeatureUserWhereInput>;
  none?: Maybe<FeatureUserWhereInput>;
  some?: Maybe<FeatureUserWhereInput>;
};

export type FeatureUserScalarWhereInput = {
  AND?: Maybe<Array<FeatureUserScalarWhereInput>>;
  NOT?: Maybe<Array<FeatureUserScalarWhereInput>>;
  OR?: Maybe<Array<FeatureUserScalarWhereInput>>;
  enabled?: Maybe<BoolFilter>;
  feature?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
};

export type FeatureUserUpdateManyMutationInput = {
  enabled?: Maybe<BoolFieldUpdateOperationsInput>;
  feature?: Maybe<StringFieldUpdateOperationsInput>;
};

export type FeatureUserUpdateManyWithWhereWithoutUserInput = {
  data: FeatureUserUpdateManyMutationInput;
  where: FeatureUserScalarWhereInput;
};

export type FeatureUserUpdateManyWithoutUserInput = {
  connect?: Maybe<Array<FeatureUserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FeatureUserCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<FeatureUserCreateWithoutUserInput>>;
  createMany?: Maybe<FeatureUserCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<FeatureUserWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FeatureUserScalarWhereInput>>;
  disconnect?: Maybe<Array<FeatureUserWhereUniqueInput>>;
  set?: Maybe<Array<FeatureUserWhereUniqueInput>>;
  update?: Maybe<Array<FeatureUserUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<FeatureUserUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<FeatureUserUpsertWithWhereUniqueWithoutUserInput>>;
};

export type FeatureUserUpdateWithWhereUniqueWithoutUserInput = {
  data: FeatureUserUpdateWithoutUserInput;
  where: FeatureUserWhereUniqueInput;
};

export type FeatureUserUpdateWithoutUserInput = {
  enabled?: Maybe<BoolFieldUpdateOperationsInput>;
  feature?: Maybe<StringFieldUpdateOperationsInput>;
};

export type FeatureUserUpsertWithWhereUniqueWithoutUserInput = {
  create: FeatureUserCreateWithoutUserInput;
  update: FeatureUserUpdateWithoutUserInput;
  where: FeatureUserWhereUniqueInput;
};

export type FeatureUserWhereInput = {
  AND?: Maybe<Array<FeatureUserWhereInput>>;
  NOT?: Maybe<Array<FeatureUserWhereInput>>;
  OR?: Maybe<Array<FeatureUserWhereInput>>;
  enabled?: Maybe<BoolFilter>;
  feature?: Maybe<StringFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringFilter>;
};

export type FeatureUserWhereUniqueInput = {
  feature_userId?: Maybe<FeatureUserFeatureUserIdCompoundUniqueInput>;
};

export type FileUploadRequest = {
  __typename?: 'FileUploadRequest';
  createdAt: Scalars['DateTime'];
  errors: Array<Scalars['String']>;
  id: Scalars['String'];
  s3Bucket: Scalars['String'];
  s3Key: Scalars['String'];
  status: FileUploadStatus;
  type: FileUploadType;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type FileUploadRequestCreateInput = {
  s3Bucket: Scalars['String'];
  s3Key: Scalars['String'];
  type: FileUploadType;
};

export type FileUploadRequestCreateManyClientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  errors?: Maybe<FileUploadRequestCreateManyerrorsInput>;
  id?: Maybe<Scalars['String']>;
  s3Bucket: Scalars['String'];
  s3Key: Scalars['String'];
  status: FileUploadStatus;
  type: FileUploadType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type FileUploadRequestCreateManyClientInputEnvelope = {
  data: Array<FileUploadRequestCreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FileUploadRequestCreateManyUserInput = {
  clientId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  errors?: Maybe<FileUploadRequestCreateManyerrorsInput>;
  id?: Maybe<Scalars['String']>;
  s3Bucket: Scalars['String'];
  s3Key: Scalars['String'];
  status: FileUploadStatus;
  type: FileUploadType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FileUploadRequestCreateManyUserInputEnvelope = {
  data: Array<FileUploadRequestCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FileUploadRequestCreateManyerrorsInput = {
  set: Array<Scalars['String']>;
};

export type FileUploadRequestCreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<FileUploadRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FileUploadRequestCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<FileUploadRequestCreateWithoutClientInput>>;
  createMany?: Maybe<FileUploadRequestCreateManyClientInputEnvelope>;
};

export type FileUploadRequestCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<FileUploadRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FileUploadRequestCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<FileUploadRequestCreateWithoutUserInput>>;
  createMany?: Maybe<FileUploadRequestCreateManyUserInputEnvelope>;
};

export type FileUploadRequestCreateOrConnectWithoutClientInput = {
  create: FileUploadRequestCreateWithoutClientInput;
  where: FileUploadRequestWhereUniqueInput;
};

export type FileUploadRequestCreateOrConnectWithoutUserInput = {
  create: FileUploadRequestCreateWithoutUserInput;
  where: FileUploadRequestWhereUniqueInput;
};

export type FileUploadRequestCreateWithoutClientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  errors?: Maybe<FileUploadRequestCreateerrorsInput>;
  id?: Maybe<Scalars['String']>;
  s3Bucket: Scalars['String'];
  s3Key: Scalars['String'];
  status: FileUploadStatus;
  type: FileUploadType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutFileUploadRequestsInput;
};

export type FileUploadRequestCreateWithoutUserInput = {
  client: ClientCreateNestedOneWithoutFileUploadRequestsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  errors?: Maybe<FileUploadRequestCreateerrorsInput>;
  id?: Maybe<Scalars['String']>;
  s3Bucket: Scalars['String'];
  s3Key: Scalars['String'];
  status: FileUploadStatus;
  type: FileUploadType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FileUploadRequestCreateerrorsInput = {
  set: Array<Scalars['String']>;
};

export type FileUploadRequestListRelationFilter = {
  every?: Maybe<FileUploadRequestWhereInput>;
  none?: Maybe<FileUploadRequestWhereInput>;
  some?: Maybe<FileUploadRequestWhereInput>;
};

export type FileUploadRequestQueryInput = {
  id: Scalars['String'];
};

export type FileUploadRequestScalarWhereInput = {
  AND?: Maybe<Array<FileUploadRequestScalarWhereInput>>;
  NOT?: Maybe<Array<FileUploadRequestScalarWhereInput>>;
  OR?: Maybe<Array<FileUploadRequestScalarWhereInput>>;
  clientId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  errors?: Maybe<StringNullableListFilter>;
  id?: Maybe<StringFilter>;
  s3Bucket?: Maybe<StringFilter>;
  s3Key?: Maybe<StringFilter>;
  status?: Maybe<EnumFileUploadStatusFilter>;
  type?: Maybe<EnumFileUploadTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringFilter>;
};

export type FileUploadRequestUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  errors?: Maybe<FileUploadRequestUpdateerrorsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  s3Bucket?: Maybe<StringFieldUpdateOperationsInput>;
  s3Key?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumFileUploadStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumFileUploadTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FileUploadRequestUpdateManyWithWhereWithoutClientInput = {
  data: FileUploadRequestUpdateManyMutationInput;
  where: FileUploadRequestScalarWhereInput;
};

export type FileUploadRequestUpdateManyWithWhereWithoutUserInput = {
  data: FileUploadRequestUpdateManyMutationInput;
  where: FileUploadRequestScalarWhereInput;
};

export type FileUploadRequestUpdateManyWithoutClientInput = {
  connect?: Maybe<Array<FileUploadRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FileUploadRequestCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<FileUploadRequestCreateWithoutClientInput>>;
  createMany?: Maybe<FileUploadRequestCreateManyClientInputEnvelope>;
  delete?: Maybe<Array<FileUploadRequestWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FileUploadRequestScalarWhereInput>>;
  disconnect?: Maybe<Array<FileUploadRequestWhereUniqueInput>>;
  set?: Maybe<Array<FileUploadRequestWhereUniqueInput>>;
  update?: Maybe<Array<FileUploadRequestUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<FileUploadRequestUpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<FileUploadRequestUpsertWithWhereUniqueWithoutClientInput>>;
};

export type FileUploadRequestUpdateManyWithoutUserInput = {
  connect?: Maybe<Array<FileUploadRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FileUploadRequestCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<FileUploadRequestCreateWithoutUserInput>>;
  createMany?: Maybe<FileUploadRequestCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<FileUploadRequestWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FileUploadRequestScalarWhereInput>>;
  disconnect?: Maybe<Array<FileUploadRequestWhereUniqueInput>>;
  set?: Maybe<Array<FileUploadRequestWhereUniqueInput>>;
  update?: Maybe<Array<FileUploadRequestUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<FileUploadRequestUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<FileUploadRequestUpsertWithWhereUniqueWithoutUserInput>>;
};

export type FileUploadRequestUpdateWithWhereUniqueWithoutClientInput = {
  data: FileUploadRequestUpdateWithoutClientInput;
  where: FileUploadRequestWhereUniqueInput;
};

export type FileUploadRequestUpdateWithWhereUniqueWithoutUserInput = {
  data: FileUploadRequestUpdateWithoutUserInput;
  where: FileUploadRequestWhereUniqueInput;
};

export type FileUploadRequestUpdateWithoutClientInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  errors?: Maybe<FileUploadRequestUpdateerrorsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  s3Bucket?: Maybe<StringFieldUpdateOperationsInput>;
  s3Key?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumFileUploadStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumFileUploadTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutFileUploadRequestsInput>;
};

export type FileUploadRequestUpdateWithoutUserInput = {
  client?: Maybe<ClientUpdateOneRequiredWithoutFileUploadRequestsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  errors?: Maybe<FileUploadRequestUpdateerrorsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  s3Bucket?: Maybe<StringFieldUpdateOperationsInput>;
  s3Key?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumFileUploadStatusFieldUpdateOperationsInput>;
  type?: Maybe<EnumFileUploadTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FileUploadRequestUpdateerrorsInput = {
  push?: Maybe<Array<Scalars['String']>>;
  set?: Maybe<Array<Scalars['String']>>;
};

export type FileUploadRequestUpsertWithWhereUniqueWithoutClientInput = {
  create: FileUploadRequestCreateWithoutClientInput;
  update: FileUploadRequestUpdateWithoutClientInput;
  where: FileUploadRequestWhereUniqueInput;
};

export type FileUploadRequestUpsertWithWhereUniqueWithoutUserInput = {
  create: FileUploadRequestCreateWithoutUserInput;
  update: FileUploadRequestUpdateWithoutUserInput;
  where: FileUploadRequestWhereUniqueInput;
};

export type FileUploadRequestWhereInput = {
  AND?: Maybe<Array<FileUploadRequestWhereInput>>;
  NOT?: Maybe<Array<FileUploadRequestWhereInput>>;
  OR?: Maybe<Array<FileUploadRequestWhereInput>>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  errors?: Maybe<StringNullableListFilter>;
  id?: Maybe<StringFilter>;
  s3Bucket?: Maybe<StringFilter>;
  s3Key?: Maybe<StringFilter>;
  status?: Maybe<EnumFileUploadStatusFilter>;
  type?: Maybe<EnumFileUploadTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringFilter>;
};

export type FileUploadRequestWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum FileUploadStatus {
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Successful = 'SUCCESSFUL'
}

export enum FileUploadType {
  AccountValue = 'ACCOUNT_VALUE',
  UnitTypeMetric = 'UNIT_TYPE_METRIC'
}

export type FilterArgs = {
  specs: Array<FilterSpec>;
};

export type FilterSpec = {
  field: Scalars['String'];
  values: Array<Scalars['String']>;
};

export enum FinancialEntityType {
  Account = 'ACCOUNT',
  Category = 'CATEGORY',
  Component = 'COMPONENT'
}

export type FinancialSummaryModel = {
  __typename?: 'FinancialSummaryModel';
  gross_potential_rent: Array<PerformanceMetricModel>;
  net_operating_income: Array<PerformanceMetricModel>;
  occupancy: Array<PerformanceMetricModel>;
  total_expenses: Array<PerformanceMetricModel>;
  total_revenue: Array<PerformanceMetricModel>;
};

export type FinancialTotalValueArgInput = {
  type: Scalars['String'];
  versionId: Scalars['String'];
  year: Scalars['Float'];
};

export type FinancialTotalValueO = {
  __typename?: 'FinancialTotalValueO';
  entityId: Scalars['String'];
  entityType: FinancialEntityType;
  propertyId: Scalars['String'];
  value?: Maybe<Scalars['Decimal']>;
  versionId: Scalars['String'];
};

export type FinancialTotalValueQueryInput = {
  entityIds: Array<Scalars['String']>;
  entityType: FinancialEntityType;
  propertyIds: Array<Scalars['String']>;
  versionIds: Array<Scalars['String']>;
};

export type FinancialValue = {
  __typename?: 'FinancialValue';
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  monthIndex: Scalars['Float'];
  propertyId: Scalars['String'];
  value: Scalars['Float'];
  versionId: Scalars['String'];
};

export type FinancialValueO = {
  __typename?: 'FinancialValueO';
  entityId: Scalars['String'];
  entityType: FinancialEntityType;
  monthIndex: Scalars['Int'];
  originalValue?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  value?: Maybe<Scalars['Decimal']>;
  versionId: Scalars['String'];
};

export type FinancialValueQueryInput = {
  entityIds: Array<Scalars['String']>;
  entityType: FinancialEntityType;
  monthIndexes: Array<Scalars['Int']>;
  propertyIds: Array<Scalars['String']>;
  versionIds: Array<Scalars['String']>;
};

export type FinancialValuesQueryResult = {
  __typename?: 'FinancialValuesQueryResult';
  financialTotalValues: Array<FinancialTotalValueO>;
  financialValues: Array<FinancialValueO>;
};

export type FinancialValuesYearBareModel = {
  __typename?: 'FinancialValuesYearBareModel';
  accountId: Scalars['String'];
  values: Array<Scalars['Float']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type FinancialValuesYearModel = {
  __typename?: 'FinancialValuesYearModel';
  entityId: Scalars['String'];
  entityType: FinancialEntityType;
  propertyId: Scalars['String'];
  values: Array<Scalars['Float']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type ForecastLockModel = {
  __typename?: 'ForecastLockModel';
  account?: Maybe<ForecastLocks>;
  property: ForecastLocks;
};

export type ForecastLocks = {
  __typename?: 'ForecastLocks';
  budgetLocked: Scalars['Boolean'];
  reforecastLocked: Scalars['Boolean'];
};

export type ForecastedAccountValue = {
  __typename?: 'ForecastedAccountValue';
  accountId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  propertyId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type ForecastedAccountValueCreateManyAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type ForecastedAccountValueCreateManyAccountInputEnvelope = {
  data: Array<ForecastedAccountValueCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ForecastedAccountValueCreateManyPropertyInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type ForecastedAccountValueCreateManyPropertyInputEnvelope = {
  data: Array<ForecastedAccountValueCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ForecastedAccountValueCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<ForecastedAccountValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ForecastedAccountValueCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<ForecastedAccountValueCreateWithoutAccountInput>>;
  createMany?: Maybe<ForecastedAccountValueCreateManyAccountInputEnvelope>;
};

export type ForecastedAccountValueCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<ForecastedAccountValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ForecastedAccountValueCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<ForecastedAccountValueCreateWithoutPropertyInput>>;
  createMany?: Maybe<ForecastedAccountValueCreateManyPropertyInputEnvelope>;
};

export type ForecastedAccountValueCreateOrConnectWithoutAccountInput = {
  create: ForecastedAccountValueCreateWithoutAccountInput;
  where: ForecastedAccountValueWhereUniqueInput;
};

export type ForecastedAccountValueCreateOrConnectWithoutPropertyInput = {
  create: ForecastedAccountValueCreateWithoutPropertyInput;
  where: ForecastedAccountValueWhereUniqueInput;
};

export type ForecastedAccountValueCreateWithoutAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  property: PropertyCreateNestedOneWithoutForecastedAccountValuesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type ForecastedAccountValueCreateWithoutPropertyInput = {
  account: AccountV2CreateNestedOneWithoutForecastedAccountValuesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type ForecastedAccountValueListRelationFilter = {
  every?: Maybe<ForecastedAccountValueWhereInput>;
  none?: Maybe<ForecastedAccountValueWhereInput>;
  some?: Maybe<ForecastedAccountValueWhereInput>;
};

export type ForecastedAccountValueOrderByInput = {
  accountId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  valueMonth0?: Maybe<SortOrder>;
  valueMonth1?: Maybe<SortOrder>;
  valueMonth2?: Maybe<SortOrder>;
  valueMonth3?: Maybe<SortOrder>;
  valueMonth4?: Maybe<SortOrder>;
  valueMonth5?: Maybe<SortOrder>;
  valueMonth6?: Maybe<SortOrder>;
  valueMonth7?: Maybe<SortOrder>;
  valueMonth8?: Maybe<SortOrder>;
  valueMonth9?: Maybe<SortOrder>;
  valueMonth10?: Maybe<SortOrder>;
  valueMonth11?: Maybe<SortOrder>;
  versionType?: Maybe<SortOrder>;
  year?: Maybe<SortOrder>;
};

export type ForecastedAccountValuePropertyIdAccountIdYearVersionTypeCompoundUniqueInput = {
  accountId: Scalars['String'];
  propertyId: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export enum ForecastedAccountValueScalarFieldEnum {
  AccountId = 'accountId',
  CreatedAt = 'createdAt',
  Id = 'id',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt',
  ValueMonth0 = 'valueMonth0',
  ValueMonth1 = 'valueMonth1',
  ValueMonth2 = 'valueMonth2',
  ValueMonth3 = 'valueMonth3',
  ValueMonth4 = 'valueMonth4',
  ValueMonth5 = 'valueMonth5',
  ValueMonth6 = 'valueMonth6',
  ValueMonth7 = 'valueMonth7',
  ValueMonth8 = 'valueMonth8',
  ValueMonth9 = 'valueMonth9',
  ValueMonth10 = 'valueMonth10',
  ValueMonth11 = 'valueMonth11',
  VersionType = 'versionType',
  Year = 'year'
}

export type ForecastedAccountValueScalarWhereInput = {
  AND?: Maybe<Array<ForecastedAccountValueScalarWhereInput>>;
  NOT?: Maybe<Array<ForecastedAccountValueScalarWhereInput>>;
  OR?: Maybe<Array<ForecastedAccountValueScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  valueMonth0?: Maybe<DecimalNullableFilter>;
  valueMonth1?: Maybe<DecimalNullableFilter>;
  valueMonth2?: Maybe<DecimalNullableFilter>;
  valueMonth3?: Maybe<DecimalNullableFilter>;
  valueMonth4?: Maybe<DecimalNullableFilter>;
  valueMonth5?: Maybe<DecimalNullableFilter>;
  valueMonth6?: Maybe<DecimalNullableFilter>;
  valueMonth7?: Maybe<DecimalNullableFilter>;
  valueMonth8?: Maybe<DecimalNullableFilter>;
  valueMonth9?: Maybe<DecimalNullableFilter>;
  valueMonth10?: Maybe<DecimalNullableFilter>;
  valueMonth11?: Maybe<DecimalNullableFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type ForecastedAccountValueUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  valueMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ForecastedAccountValueUpdateManyWithWhereWithoutAccountInput = {
  data: ForecastedAccountValueUpdateManyMutationInput;
  where: ForecastedAccountValueScalarWhereInput;
};

export type ForecastedAccountValueUpdateManyWithWhereWithoutPropertyInput = {
  data: ForecastedAccountValueUpdateManyMutationInput;
  where: ForecastedAccountValueScalarWhereInput;
};

export type ForecastedAccountValueUpdateManyWithoutAccountInput = {
  connect?: Maybe<Array<ForecastedAccountValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ForecastedAccountValueCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<ForecastedAccountValueCreateWithoutAccountInput>>;
  createMany?: Maybe<ForecastedAccountValueCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<ForecastedAccountValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ForecastedAccountValueScalarWhereInput>>;
  disconnect?: Maybe<Array<ForecastedAccountValueWhereUniqueInput>>;
  set?: Maybe<Array<ForecastedAccountValueWhereUniqueInput>>;
  update?: Maybe<Array<ForecastedAccountValueUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<ForecastedAccountValueUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<ForecastedAccountValueUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type ForecastedAccountValueUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<ForecastedAccountValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ForecastedAccountValueCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<ForecastedAccountValueCreateWithoutPropertyInput>>;
  createMany?: Maybe<ForecastedAccountValueCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<ForecastedAccountValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ForecastedAccountValueScalarWhereInput>>;
  disconnect?: Maybe<Array<ForecastedAccountValueWhereUniqueInput>>;
  set?: Maybe<Array<ForecastedAccountValueWhereUniqueInput>>;
  update?: Maybe<Array<ForecastedAccountValueUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<ForecastedAccountValueUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<ForecastedAccountValueUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type ForecastedAccountValueUpdateWithWhereUniqueWithoutAccountInput = {
  data: ForecastedAccountValueUpdateWithoutAccountInput;
  where: ForecastedAccountValueWhereUniqueInput;
};

export type ForecastedAccountValueUpdateWithWhereUniqueWithoutPropertyInput = {
  data: ForecastedAccountValueUpdateWithoutPropertyInput;
  where: ForecastedAccountValueWhereUniqueInput;
};

export type ForecastedAccountValueUpdateWithoutAccountInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutForecastedAccountValuesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  valueMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ForecastedAccountValueUpdateWithoutPropertyInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutForecastedAccountValuesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  valueMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ForecastedAccountValueUpsertWithWhereUniqueWithoutAccountInput = {
  create: ForecastedAccountValueCreateWithoutAccountInput;
  update: ForecastedAccountValueUpdateWithoutAccountInput;
  where: ForecastedAccountValueWhereUniqueInput;
};

export type ForecastedAccountValueUpsertWithWhereUniqueWithoutPropertyInput = {
  create: ForecastedAccountValueCreateWithoutPropertyInput;
  update: ForecastedAccountValueUpdateWithoutPropertyInput;
  where: ForecastedAccountValueWhereUniqueInput;
};

export type ForecastedAccountValueWhereInput = {
  AND?: Maybe<Array<ForecastedAccountValueWhereInput>>;
  NOT?: Maybe<Array<ForecastedAccountValueWhereInput>>;
  OR?: Maybe<Array<ForecastedAccountValueWhereInput>>;
  account?: Maybe<AccountV2RelationFilter>;
  accountId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  valueMonth0?: Maybe<DecimalNullableFilter>;
  valueMonth1?: Maybe<DecimalNullableFilter>;
  valueMonth2?: Maybe<DecimalNullableFilter>;
  valueMonth3?: Maybe<DecimalNullableFilter>;
  valueMonth4?: Maybe<DecimalNullableFilter>;
  valueMonth5?: Maybe<DecimalNullableFilter>;
  valueMonth6?: Maybe<DecimalNullableFilter>;
  valueMonth7?: Maybe<DecimalNullableFilter>;
  valueMonth8?: Maybe<DecimalNullableFilter>;
  valueMonth9?: Maybe<DecimalNullableFilter>;
  valueMonth10?: Maybe<DecimalNullableFilter>;
  valueMonth11?: Maybe<DecimalNullableFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type ForecastedAccountValueWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  propertyId_accountId_year_versionType?: Maybe<ForecastedAccountValuePropertyIdAccountIdYearVersionTypeCompoundUniqueInput>;
};

export type ForecastedLeaseExpirationCountModel = {
  __typename?: 'ForecastedLeaseExpirationCountModel';
  propertyId: Scalars['ID'];
  unitTypeId: Scalars['ID'];
  unitTypeName: Scalars['String'];
  values: Array<Maybe<Scalars['Int']>>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type GprEntity = {
  __typename?: 'GPREntity';
  chartOfAccountsId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  entityId: Scalars['String'];
  entityType: EntityType;
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type GprEntityCreateManyChartOfAccountsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId: Scalars['String'];
  entityType: EntityType;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GprEntityCreateManyChartOfAccountsInputEnvelope = {
  data: Array<GprEntityCreateManyChartOfAccountsInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type GprEntityCreateNestedManyWithoutChartOfAccountsInput = {
  connect?: Maybe<Array<GprEntityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<GprEntityCreateOrConnectWithoutChartOfAccountsInput>>;
  create?: Maybe<Array<GprEntityCreateWithoutChartOfAccountsInput>>;
  createMany?: Maybe<GprEntityCreateManyChartOfAccountsInputEnvelope>;
};

export type GprEntityCreateOrConnectWithoutChartOfAccountsInput = {
  create: GprEntityCreateWithoutChartOfAccountsInput;
  where: GprEntityWhereUniqueInput;
};

export type GprEntityCreateWithoutChartOfAccountsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId: Scalars['String'];
  entityType: EntityType;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GprEntityEntityIdEntityTypeChartOfAccountsIdCompoundUniqueInput = {
  chartOfAccountsId: Scalars['String'];
  entityId: Scalars['String'];
  entityType: EntityType;
};

export type GprEntityListRelationFilter = {
  every?: Maybe<GprEntityWhereInput>;
  none?: Maybe<GprEntityWhereInput>;
  some?: Maybe<GprEntityWhereInput>;
};

export type GprEntityOrderByInput = {
  chartOfAccountsId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum GprEntityScalarFieldEnum {
  ChartOfAccountsId = 'chartOfAccountsId',
  CreatedAt = 'createdAt',
  EntityId = 'entityId',
  EntityType = 'entityType',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type GprEntityScalarWhereInput = {
  AND?: Maybe<Array<GprEntityScalarWhereInput>>;
  NOT?: Maybe<Array<GprEntityScalarWhereInput>>;
  OR?: Maybe<Array<GprEntityScalarWhereInput>>;
  chartOfAccountsId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  entityId?: Maybe<StringFilter>;
  entityType?: Maybe<EnumEntityTypeFilter>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type GprEntityUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  entityId?: Maybe<StringFieldUpdateOperationsInput>;
  entityType?: Maybe<EnumEntityTypeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type GprEntityUpdateManyWithWhereWithoutChartOfAccountsInput = {
  data: GprEntityUpdateManyMutationInput;
  where: GprEntityScalarWhereInput;
};

export type GprEntityUpdateManyWithoutChartOfAccountsInput = {
  connect?: Maybe<Array<GprEntityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<GprEntityCreateOrConnectWithoutChartOfAccountsInput>>;
  create?: Maybe<Array<GprEntityCreateWithoutChartOfAccountsInput>>;
  createMany?: Maybe<GprEntityCreateManyChartOfAccountsInputEnvelope>;
  delete?: Maybe<Array<GprEntityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<GprEntityScalarWhereInput>>;
  disconnect?: Maybe<Array<GprEntityWhereUniqueInput>>;
  set?: Maybe<Array<GprEntityWhereUniqueInput>>;
  update?: Maybe<Array<GprEntityUpdateWithWhereUniqueWithoutChartOfAccountsInput>>;
  updateMany?: Maybe<Array<GprEntityUpdateManyWithWhereWithoutChartOfAccountsInput>>;
  upsert?: Maybe<Array<GprEntityUpsertWithWhereUniqueWithoutChartOfAccountsInput>>;
};

export type GprEntityUpdateWithWhereUniqueWithoutChartOfAccountsInput = {
  data: GprEntityUpdateWithoutChartOfAccountsInput;
  where: GprEntityWhereUniqueInput;
};

export type GprEntityUpdateWithoutChartOfAccountsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  entityId?: Maybe<StringFieldUpdateOperationsInput>;
  entityType?: Maybe<EnumEntityTypeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type GprEntityUpsertWithWhereUniqueWithoutChartOfAccountsInput = {
  create: GprEntityCreateWithoutChartOfAccountsInput;
  update: GprEntityUpdateWithoutChartOfAccountsInput;
  where: GprEntityWhereUniqueInput;
};

export type GprEntityWhereInput = {
  AND?: Maybe<Array<GprEntityWhereInput>>;
  NOT?: Maybe<Array<GprEntityWhereInput>>;
  OR?: Maybe<Array<GprEntityWhereInput>>;
  chartOfAccounts?: Maybe<ChartOfAccountsRelationFilter>;
  chartOfAccountsId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  entityId?: Maybe<StringFilter>;
  entityType?: Maybe<EnumEntityTypeFilter>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type GprEntityWhereUniqueInput = {
  entityId_entityType_chartOfAccountsId?: Maybe<GprEntityEntityIdEntityTypeChartOfAccountsIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type GlContributionsEntityModel = {
  __typename?: 'GlContributionsEntityModel';
  glNumber?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  parentTotal: Scalars['Decimal'];
  percentContributed: Scalars['Decimal'];
  type: FinancialEntityType;
};

export enum GrowthCalcMethod {
  AnnualTargetValue = 'ANNUAL_TARGET_VALUE',
  MonthlyAverage = 'MONTHLY_AVERAGE',
  PercentGrowth = 'PERCENT_GROWTH'
}

/** New Growth Driver data */
export type GrowthDriverInput = {
  accountId?: Maybe<Scalars['String']>;
  annualTargetValueManualEntry?: Maybe<Scalars['Float']>;
  annualValues?: Maybe<Array<Scalars['Float']>>;
  distributionMethod?: Maybe<DistributionMethod>;
  growthCalcMethod: GrowthCalcMethod;
  id?: Maybe<Scalars['String']>;
  lookbackPeriodEnd?: Maybe<Scalars['DateNoTime']>;
  lookbackPeriodStart?: Maybe<Scalars['DateNoTime']>;
  monthlyAdjustmentType?: Maybe<MonthlyAdjustmentType>;
  monthlyAdjustmentValue?: Maybe<Scalars['Float']>;
  monthlyAverageBasePeriod?: Maybe<MonthlyAverageBasePeriod>;
  percentGrowthAdjustmentValue?: Maybe<Scalars['Float']>;
  percentGrowthBasePeriod?: Maybe<PercentGrowthBasePeriod>;
  propertyId?: Maybe<Scalars['String']>;
  versionType?: Maybe<VersionType>;
  year?: Maybe<Scalars['Int']>;
};

export type GrowthDriverModel = {
  __typename?: 'GrowthDriverModel';
  accountId: Scalars['String'];
  annualTargetValueManualEntry?: Maybe<Scalars['Float']>;
  annualValues: Array<Scalars['Float']>;
  distributionMethod?: Maybe<DistributionMethod>;
  growthCalcMethod: GrowthCalcMethod;
  id: Scalars['String'];
  lookbackPeriodEnd?: Maybe<Scalars['DateNoTime']>;
  lookbackPeriodStart?: Maybe<Scalars['DateNoTime']>;
  monthlyAdjustmentType?: Maybe<MonthlyAdjustmentType>;
  monthlyAdjustmentValue?: Maybe<Scalars['Float']>;
  monthlyAverageBasePeriod?: Maybe<MonthlyAverageBasePeriod>;
  percentGrowthAdjustmentValue?: Maybe<Scalars['Float']>;
  percentGrowthBasePeriod?: Maybe<PercentGrowthBasePeriod>;
  propertyId: Scalars['String'];
  versionType: Scalars['String'];
  year: Scalars['Int'];
};

export type IBaseMetaModel = {
  accountId: Scalars['ID'];
  propertyId: Scalars['ID'];
};

export type IFinanceTotalMetaModel = {
  accountId: Scalars['ID'];
  budgetValue?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['ID'];
  reforecastValue?: Maybe<Scalars['Decimal']>;
};

export type INoteMetaModel = {
  accountId: Scalars['ID'];
  budgetVersionNote?: Maybe<Scalars['String']>;
  propertyId: Scalars['ID'];
  reforecastVersionNote?: Maybe<Scalars['String']>;
};

export type IStarringMetaModel = {
  accountGlName: Scalars['String'];
  accountGlNumber: Scalars['String'];
  accountId: Scalars['ID'];
  budgetVersionId: Scalars['String'];
  isBudgetVersionStarred: Scalars['Boolean'];
  isReforecastVersionStarred: Scalars['Boolean'];
  parentAccountId?: Maybe<Scalars['ID']>;
  propertyId: Scalars['ID'];
  reforecastVersionId: Scalars['String'];
};

export type InsightsAccountModel = {
  __typename?: 'InsightsAccountModel';
  budgetComponentType: BudgetComponentType;
  negate: Scalars['Boolean'];
};

export type InsightsModel = {
  __typename?: 'InsightsModel';
  annualBudgetRemaining: AnnualBudgetRemainingModel;
  glContributions: Array<GlContributionsEntityModel>;
  topVendors: Array<AccountVendorModel>;
  trends: TrendsModel;
};

export type Instruction = {
  __typename?: 'Instruction';
  accountId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  valueVersionId: Scalars['String'];
};

export type InstructionAccountIdValueVersionIdCompoundUniqueInput = {
  accountId: Scalars['String'];
  valueVersionId: Scalars['String'];
};

export type InstructionCreateManyAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueVersionId: Scalars['String'];
};

export type InstructionCreateManyAccountInputEnvelope = {
  data: Array<InstructionCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InstructionCreateManyValueVersionInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InstructionCreateManyValueVersionInputEnvelope = {
  data: Array<InstructionCreateManyValueVersionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InstructionCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<InstructionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InstructionCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<InstructionCreateWithoutAccountInput>>;
  createMany?: Maybe<InstructionCreateManyAccountInputEnvelope>;
};

export type InstructionCreateNestedManyWithoutValueVersionInput = {
  connect?: Maybe<Array<InstructionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InstructionCreateOrConnectWithoutValueVersionInput>>;
  create?: Maybe<Array<InstructionCreateWithoutValueVersionInput>>;
  createMany?: Maybe<InstructionCreateManyValueVersionInputEnvelope>;
};

export type InstructionCreateOrConnectWithoutAccountInput = {
  create: InstructionCreateWithoutAccountInput;
  where: InstructionWhereUniqueInput;
};

export type InstructionCreateOrConnectWithoutValueVersionInput = {
  create: InstructionCreateWithoutValueVersionInput;
  where: InstructionWhereUniqueInput;
};

export type InstructionCreateWithoutAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueVersion: ValueVersionCreateNestedOneWithoutInstructionsInput;
};

export type InstructionCreateWithoutValueVersionInput = {
  account: AccountV2CreateNestedOneWithoutInstructionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InstructionListRelationFilter = {
  every?: Maybe<InstructionWhereInput>;
  none?: Maybe<InstructionWhereInput>;
  some?: Maybe<InstructionWhereInput>;
};

export type InstructionModel = {
  __typename?: 'InstructionModel';
  accountId: Scalars['String'];
  id: Scalars['ID'];
  text: Scalars['String'];
  version: InstructionValueVersionModel;
};

export type InstructionOrderByInput = {
  accountId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  text?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  valueVersionId?: Maybe<SortOrder>;
};

export enum InstructionScalarFieldEnum {
  AccountId = 'accountId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Text = 'text',
  UpdatedAt = 'updatedAt',
  ValueVersionId = 'valueVersionId'
}

export type InstructionScalarWhereInput = {
  AND?: Maybe<Array<InstructionScalarWhereInput>>;
  NOT?: Maybe<Array<InstructionScalarWhereInput>>;
  OR?: Maybe<Array<InstructionScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  text?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  valueVersionId?: Maybe<StringFilter>;
};

export type InstructionUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  text?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type InstructionUpdateManyWithWhereWithoutAccountInput = {
  data: InstructionUpdateManyMutationInput;
  where: InstructionScalarWhereInput;
};

export type InstructionUpdateManyWithWhereWithoutValueVersionInput = {
  data: InstructionUpdateManyMutationInput;
  where: InstructionScalarWhereInput;
};

export type InstructionUpdateManyWithoutAccountInput = {
  connect?: Maybe<Array<InstructionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InstructionCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<InstructionCreateWithoutAccountInput>>;
  createMany?: Maybe<InstructionCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<InstructionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InstructionScalarWhereInput>>;
  disconnect?: Maybe<Array<InstructionWhereUniqueInput>>;
  set?: Maybe<Array<InstructionWhereUniqueInput>>;
  update?: Maybe<Array<InstructionUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<InstructionUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<InstructionUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type InstructionUpdateManyWithoutValueVersionInput = {
  connect?: Maybe<Array<InstructionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InstructionCreateOrConnectWithoutValueVersionInput>>;
  create?: Maybe<Array<InstructionCreateWithoutValueVersionInput>>;
  createMany?: Maybe<InstructionCreateManyValueVersionInputEnvelope>;
  delete?: Maybe<Array<InstructionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InstructionScalarWhereInput>>;
  disconnect?: Maybe<Array<InstructionWhereUniqueInput>>;
  set?: Maybe<Array<InstructionWhereUniqueInput>>;
  update?: Maybe<Array<InstructionUpdateWithWhereUniqueWithoutValueVersionInput>>;
  updateMany?: Maybe<Array<InstructionUpdateManyWithWhereWithoutValueVersionInput>>;
  upsert?: Maybe<Array<InstructionUpsertWithWhereUniqueWithoutValueVersionInput>>;
};

export type InstructionUpdateWithWhereUniqueWithoutAccountInput = {
  data: InstructionUpdateWithoutAccountInput;
  where: InstructionWhereUniqueInput;
};

export type InstructionUpdateWithWhereUniqueWithoutValueVersionInput = {
  data: InstructionUpdateWithoutValueVersionInput;
  where: InstructionWhereUniqueInput;
};

export type InstructionUpdateWithoutAccountInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  text?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  valueVersion?: Maybe<ValueVersionUpdateOneRequiredWithoutInstructionsInput>;
};

export type InstructionUpdateWithoutValueVersionInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutInstructionsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  text?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type InstructionUpsertWithWhereUniqueWithoutAccountInput = {
  create: InstructionCreateWithoutAccountInput;
  update: InstructionUpdateWithoutAccountInput;
  where: InstructionWhereUniqueInput;
};

export type InstructionUpsertWithWhereUniqueWithoutValueVersionInput = {
  create: InstructionCreateWithoutValueVersionInput;
  update: InstructionUpdateWithoutValueVersionInput;
  where: InstructionWhereUniqueInput;
};

export type InstructionValueVersionModel = {
  __typename?: 'InstructionValueVersionModel';
  type: Scalars['String'];
  year: Scalars['Float'];
};

export type InstructionWhereInput = {
  AND?: Maybe<Array<InstructionWhereInput>>;
  NOT?: Maybe<Array<InstructionWhereInput>>;
  OR?: Maybe<Array<InstructionWhereInput>>;
  account?: Maybe<AccountV2RelationFilter>;
  accountId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  text?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  valueVersion?: Maybe<ValueVersionRelationFilter>;
  valueVersionId?: Maybe<StringFilter>;
};

export type InstructionWhereUniqueInput = {
  accountId_valueVersionId?: Maybe<InstructionAccountIdValueVersionIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  set?: Maybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type IntNullableWithAggregatesFilter = {
  _avg?: Maybe<NestedFloatNullableFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedIntNullableFilter>;
  _sum?: Maybe<NestedIntNullableFilter>;
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type IntWithAggregatesFilter = {
  _avg?: Maybe<NestedFloatFilter>;
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedIntFilter>;
  _sum?: Maybe<NestedIntFilter>;
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};


export enum JobStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Running = 'RUNNING',
  Submitted = 'SUBMITTED'
}

export enum JobType {
  CopyDrivers = 'COPY_DRIVERS',
  CreatePortfolioCalcSnapshot = 'CREATE_PORTFOLIO_CALC_SNAPSHOT',
  SendBudget = 'SEND_BUDGET'
}

export type JsonFilter = {
  equals?: Maybe<Scalars['JSON']>;
  not?: Maybe<Scalars['JSON']>;
};

export type LeaseExpirationCountsInput = {
  unitTypeId: Scalars['ID'];
  values: Array<Maybe<Scalars['Int']>>;
};

export type LockedAccount = {
  __typename?: 'LockedAccount';
  accountId: Scalars['String'];
  budgetYear: Scalars['Int'];
  id: Scalars['String'];
  propertyId: Scalars['String'];
  versionType: VersionType;
};

export type LockedAccountAccountIdPropertyIdBudgetYearVersionTypeCompoundUniqueInput = {
  accountId: Scalars['String'];
  budgetYear: Scalars['Int'];
  propertyId: Scalars['String'];
  versionType: VersionType;
};

export type LockedAccountCreateManyAccountInput = {
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  versionType: VersionType;
};

export type LockedAccountCreateManyAccountInputEnvelope = {
  data: Array<LockedAccountCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LockedAccountCreateManyPropertyInput = {
  accountId: Scalars['String'];
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  versionType: VersionType;
};

export type LockedAccountCreateManyPropertyInputEnvelope = {
  data: Array<LockedAccountCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LockedAccountCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<LockedAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LockedAccountCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<LockedAccountCreateWithoutAccountInput>>;
  createMany?: Maybe<LockedAccountCreateManyAccountInputEnvelope>;
};

export type LockedAccountCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<LockedAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LockedAccountCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<LockedAccountCreateWithoutPropertyInput>>;
  createMany?: Maybe<LockedAccountCreateManyPropertyInputEnvelope>;
};

export type LockedAccountCreateOrConnectWithoutAccountInput = {
  create: LockedAccountCreateWithoutAccountInput;
  where: LockedAccountWhereUniqueInput;
};

export type LockedAccountCreateOrConnectWithoutPropertyInput = {
  create: LockedAccountCreateWithoutPropertyInput;
  where: LockedAccountWhereUniqueInput;
};

export type LockedAccountCreateWithoutAccountInput = {
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  property: PropertyCreateNestedOneWithoutLockedAccountsInput;
  versionType: VersionType;
};

export type LockedAccountCreateWithoutPropertyInput = {
  account: AccountV2CreateNestedOneWithoutLockedAccountsInput;
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  versionType: VersionType;
};

export type LockedAccountListRelationFilter = {
  every?: Maybe<LockedAccountWhereInput>;
  none?: Maybe<LockedAccountWhereInput>;
  some?: Maybe<LockedAccountWhereInput>;
};

export type LockedAccountOrderByInput = {
  accountId?: Maybe<SortOrder>;
  budgetYear?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  versionType?: Maybe<SortOrder>;
};

export enum LockedAccountScalarFieldEnum {
  AccountId = 'accountId',
  BudgetYear = 'budgetYear',
  Id = 'id',
  PropertyId = 'propertyId',
  VersionType = 'versionType'
}

export type LockedAccountScalarWhereInput = {
  AND?: Maybe<Array<LockedAccountScalarWhereInput>>;
  NOT?: Maybe<Array<LockedAccountScalarWhereInput>>;
  OR?: Maybe<Array<LockedAccountScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  budgetYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
};

export type LockedAccountUpdateManyMutationInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type LockedAccountUpdateManyWithWhereWithoutAccountInput = {
  data: LockedAccountUpdateManyMutationInput;
  where: LockedAccountScalarWhereInput;
};

export type LockedAccountUpdateManyWithWhereWithoutPropertyInput = {
  data: LockedAccountUpdateManyMutationInput;
  where: LockedAccountScalarWhereInput;
};

export type LockedAccountUpdateManyWithoutAccountInput = {
  connect?: Maybe<Array<LockedAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LockedAccountCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<LockedAccountCreateWithoutAccountInput>>;
  createMany?: Maybe<LockedAccountCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<LockedAccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LockedAccountScalarWhereInput>>;
  disconnect?: Maybe<Array<LockedAccountWhereUniqueInput>>;
  set?: Maybe<Array<LockedAccountWhereUniqueInput>>;
  update?: Maybe<Array<LockedAccountUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<LockedAccountUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<LockedAccountUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type LockedAccountUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<LockedAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LockedAccountCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<LockedAccountCreateWithoutPropertyInput>>;
  createMany?: Maybe<LockedAccountCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<LockedAccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LockedAccountScalarWhereInput>>;
  disconnect?: Maybe<Array<LockedAccountWhereUniqueInput>>;
  set?: Maybe<Array<LockedAccountWhereUniqueInput>>;
  update?: Maybe<Array<LockedAccountUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<LockedAccountUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<LockedAccountUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type LockedAccountUpdateWithWhereUniqueWithoutAccountInput = {
  data: LockedAccountUpdateWithoutAccountInput;
  where: LockedAccountWhereUniqueInput;
};

export type LockedAccountUpdateWithWhereUniqueWithoutPropertyInput = {
  data: LockedAccountUpdateWithoutPropertyInput;
  where: LockedAccountWhereUniqueInput;
};

export type LockedAccountUpdateWithoutAccountInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutLockedAccountsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type LockedAccountUpdateWithoutPropertyInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutLockedAccountsInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type LockedAccountUpsertWithWhereUniqueWithoutAccountInput = {
  create: LockedAccountCreateWithoutAccountInput;
  update: LockedAccountUpdateWithoutAccountInput;
  where: LockedAccountWhereUniqueInput;
};

export type LockedAccountUpsertWithWhereUniqueWithoutPropertyInput = {
  create: LockedAccountCreateWithoutPropertyInput;
  update: LockedAccountUpdateWithoutPropertyInput;
  where: LockedAccountWhereUniqueInput;
};

export type LockedAccountWhereInput = {
  AND?: Maybe<Array<LockedAccountWhereInput>>;
  NOT?: Maybe<Array<LockedAccountWhereInput>>;
  OR?: Maybe<Array<LockedAccountWhereInput>>;
  account?: Maybe<AccountV2RelationFilter>;
  accountId?: Maybe<StringFilter>;
  budgetYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
};

export type LockedAccountWhereUniqueInput = {
  accountId_propertyId_budgetYear_versionType?: Maybe<LockedAccountAccountIdPropertyIdBudgetYearVersionTypeCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export enum MathOperationType {
  Add = 'ADD',
  Sub = 'SUB'
}

export type MetricInput = {
  fieldName: Scalars['String'];
  valuesData: Array<MetricValues>;
};

export type MetricValues = {
  monthIndexes: Array<Scalars['Int']>;
  values: Array<Scalars['Decimal']>;
  year: Scalars['Int'];
};

export type Model = {
  __typename?: 'Model';
  chartOfAccountsId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  fiscalYearStartMonthIndex: Scalars['Int'];
  id: Scalars['String'];
  name: Scalars['String'];
  reforecastStartMonthIndex: Scalars['Int'];
  reforecastYear: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type ModelChartOfAccountsIdNameCompoundUniqueInput = {
  chartOfAccountsId: Scalars['String'];
  name: Scalars['String'];
};

export type ModelCreateManyChartOfAccountsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ModelCreateManyChartOfAccountsInputEnvelope = {
  data: Array<ModelCreateManyChartOfAccountsInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ModelCreateNestedManyWithoutChartOfAccountsInput = {
  connect?: Maybe<Array<ModelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ModelCreateOrConnectWithoutChartOfAccountsInput>>;
  create?: Maybe<Array<ModelCreateWithoutChartOfAccountsInput>>;
  createMany?: Maybe<ModelCreateManyChartOfAccountsInputEnvelope>;
};

export type ModelCreateNestedOneWithoutDriverAssignmentsInput = {
  connect?: Maybe<ModelWhereUniqueInput>;
  connectOrCreate?: Maybe<ModelCreateOrConnectWithoutDriverAssignmentsInput>;
  create?: Maybe<ModelCreateWithoutDriverAssignmentsInput>;
};

export type ModelCreateNestedOneWithoutPropertiesInput = {
  connect?: Maybe<ModelWhereUniqueInput>;
  connectOrCreate?: Maybe<ModelCreateOrConnectWithoutPropertiesInput>;
  create?: Maybe<ModelCreateWithoutPropertiesInput>;
};

export type ModelCreateOrConnectWithoutChartOfAccountsInput = {
  create: ModelCreateWithoutChartOfAccountsInput;
  where: ModelWhereUniqueInput;
};

export type ModelCreateOrConnectWithoutDriverAssignmentsInput = {
  create: ModelCreateWithoutDriverAssignmentsInput;
  where: ModelWhereUniqueInput;
};

export type ModelCreateOrConnectWithoutPropertiesInput = {
  create: ModelCreateWithoutPropertiesInput;
  where: ModelWhereUniqueInput;
};

export type ModelCreateWithoutChartOfAccountsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutModelInput>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  properties?: Maybe<PropertyCreateNestedManyWithoutModelInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ModelCreateWithoutDriverAssignmentsInput = {
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutModelsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  properties?: Maybe<PropertyCreateNestedManyWithoutModelInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ModelCreateWithoutPropertiesInput = {
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutModelsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutModelInput>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ModelListRelationFilter = {
  every?: Maybe<ModelWhereInput>;
  none?: Maybe<ModelWhereInput>;
  some?: Maybe<ModelWhereInput>;
};

export type ModelOrderByInput = {
  chartOfAccountsId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  fiscalYearStartMonthIndex?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  reforecastStartMonthIndex?: Maybe<SortOrder>;
  reforecastYear?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ModelRelationFilter = {
  is?: Maybe<ModelWhereInput>;
  isNot?: Maybe<ModelWhereInput>;
};

export enum ModelScalarFieldEnum {
  ChartOfAccountsId = 'chartOfAccountsId',
  CreatedAt = 'createdAt',
  FiscalYearStartMonthIndex = 'fiscalYearStartMonthIndex',
  Id = 'id',
  Name = 'name',
  ReforecastStartMonthIndex = 'reforecastStartMonthIndex',
  ReforecastYear = 'reforecastYear',
  UpdatedAt = 'updatedAt'
}

export type ModelScalarWhereInput = {
  AND?: Maybe<Array<ModelScalarWhereInput>>;
  NOT?: Maybe<Array<ModelScalarWhereInput>>;
  OR?: Maybe<Array<ModelScalarWhereInput>>;
  chartOfAccountsId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  fiscalYearStartMonthIndex?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  reforecastStartMonthIndex?: Maybe<IntFilter>;
  reforecastYear?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ModelUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ModelUpdateManyWithWhereWithoutChartOfAccountsInput = {
  data: ModelUpdateManyMutationInput;
  where: ModelScalarWhereInput;
};

export type ModelUpdateManyWithoutChartOfAccountsInput = {
  connect?: Maybe<Array<ModelWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ModelCreateOrConnectWithoutChartOfAccountsInput>>;
  create?: Maybe<Array<ModelCreateWithoutChartOfAccountsInput>>;
  createMany?: Maybe<ModelCreateManyChartOfAccountsInputEnvelope>;
  delete?: Maybe<Array<ModelWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ModelScalarWhereInput>>;
  disconnect?: Maybe<Array<ModelWhereUniqueInput>>;
  set?: Maybe<Array<ModelWhereUniqueInput>>;
  update?: Maybe<Array<ModelUpdateWithWhereUniqueWithoutChartOfAccountsInput>>;
  updateMany?: Maybe<Array<ModelUpdateManyWithWhereWithoutChartOfAccountsInput>>;
  upsert?: Maybe<Array<ModelUpsertWithWhereUniqueWithoutChartOfAccountsInput>>;
};

export type ModelUpdateOneRequiredWithoutDriverAssignmentsInput = {
  connect?: Maybe<ModelWhereUniqueInput>;
  connectOrCreate?: Maybe<ModelCreateOrConnectWithoutDriverAssignmentsInput>;
  create?: Maybe<ModelCreateWithoutDriverAssignmentsInput>;
  update?: Maybe<ModelUpdateWithoutDriverAssignmentsInput>;
  upsert?: Maybe<ModelUpsertWithoutDriverAssignmentsInput>;
};

export type ModelUpdateOneWithoutPropertiesInput = {
  connect?: Maybe<ModelWhereUniqueInput>;
  connectOrCreate?: Maybe<ModelCreateOrConnectWithoutPropertiesInput>;
  create?: Maybe<ModelCreateWithoutPropertiesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ModelUpdateWithoutPropertiesInput>;
  upsert?: Maybe<ModelUpsertWithoutPropertiesInput>;
};

export type ModelUpdateWithWhereUniqueWithoutChartOfAccountsInput = {
  data: ModelUpdateWithoutChartOfAccountsInput;
  where: ModelWhereUniqueInput;
};

export type ModelUpdateWithoutChartOfAccountsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutModelInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  properties?: Maybe<PropertyUpdateManyWithoutModelInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ModelUpdateWithoutDriverAssignmentsInput = {
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutModelsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  properties?: Maybe<PropertyUpdateManyWithoutModelInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ModelUpdateWithoutPropertiesInput = {
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutModelsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutModelInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ModelUpsertWithWhereUniqueWithoutChartOfAccountsInput = {
  create: ModelCreateWithoutChartOfAccountsInput;
  update: ModelUpdateWithoutChartOfAccountsInput;
  where: ModelWhereUniqueInput;
};

export type ModelUpsertWithoutDriverAssignmentsInput = {
  create: ModelCreateWithoutDriverAssignmentsInput;
  update: ModelUpdateWithoutDriverAssignmentsInput;
};

export type ModelUpsertWithoutPropertiesInput = {
  create: ModelCreateWithoutPropertiesInput;
  update: ModelUpdateWithoutPropertiesInput;
};

export type ModelWhereInput = {
  AND?: Maybe<Array<ModelWhereInput>>;
  NOT?: Maybe<Array<ModelWhereInput>>;
  OR?: Maybe<Array<ModelWhereInput>>;
  chartOfAccounts?: Maybe<ChartOfAccountsRelationFilter>;
  chartOfAccountsId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  driverAssignments?: Maybe<DriverAssignmentListRelationFilter>;
  fiscalYearStartMonthIndex?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  properties?: Maybe<PropertyListRelationFilter>;
  reforecastStartMonthIndex?: Maybe<IntFilter>;
  reforecastYear?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ModelWhereUniqueInput = {
  chartOfAccountsId_name?: Maybe<ModelChartOfAccountsIdNameCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export enum MonthlyAdjustmentType {
  Dollar = 'DOLLAR',
  Percent = 'PERCENT'
}

export enum MonthlyAverageBasePeriod {
  CustomLookbackPeriod = 'CUSTOM_LOOKBACK_PERIOD',
  LastMonth = 'LAST_MONTH',
  LastSixMonthsActuals = 'LAST_SIX_MONTHS_ACTUALS',
  LastThreeMonthsActuals = 'LAST_THREE_MONTHS_ACTUALS',
  LastTwelveMonthsActuals = 'LAST_TWELVE_MONTHS_ACTUALS'
}

export type MultiPropertyDriverModel = {
  __typename?: 'MultiPropertyDriverModel';
  acctPercentage: Array<MultiPropertySourceAccountModel>;
  operational: Array<MultiPropertyOperationalDriverModel>;
  worksheet: Array<WorksheetLineWithPropertyAccountVersionTypeModel>;
};

export type MultiPropertyOperationalDriverModel = {
  __typename?: 'MultiPropertyOperationalDriverModel';
  accountId: Scalars['ID'];
  amount?: Maybe<Scalars['Float']>;
  assumptionId: Scalars['ID'];
  delayRecognition: Scalars['Int'];
  driverMetricTypeId: Scalars['ID'];
  driverType: DriverType;
  effectiveEndMonthIndex: Scalars['Int'];
  effectiveEndYear: Scalars['Int'];
  effectiveStartMonthIndex: Scalars['Int'];
  effectiveStartYear: Scalars['Int'];
  locked: Scalars['Boolean'];
  percentageOf?: Maybe<Scalars['Float']>;
  propertyId: Scalars['ID'];
  propertyName: Scalars['String'];
  sourceMetricId: Scalars['ID'];
  sourceMetricName: Scalars['String'];
  versionType: VersionType;
};

export type MultiPropertySourceAccountModel = {
  __typename?: 'MultiPropertySourceAccountModel';
  accountId: Scalars['ID'];
  acctPercentageDriverAccountId: Scalars['String'];
  assumptionId: Scalars['String'];
  delayRecognition: Scalars['Int'];
  driverType: DriverType;
  effectiveEndMonthIndex: Scalars['Int'];
  effectiveEndYear: Scalars['Int'];
  effectiveStartMonthIndex: Scalars['Int'];
  effectiveStartYear: Scalars['Int'];
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  percentageOf?: Maybe<Scalars['Float']>;
  propertyId: Scalars['ID'];
  propertyName: Scalars['String'];
  sourceAccountId: Scalars['ID'];
  versionType: VersionType;
};

export type Mutation = {
  __typename?: 'Mutation';
  accountValueOverride: Array<AccountValueOverride>;
  activateBudgetYear: BudgetYearsListViewModel;
  addAccountTags: Scalars['Boolean'];
  addBudgetYear: BudgetYearsListViewModel;
  addCustomItem: Scalars['Boolean'];
  addReportTable: Scalars['Boolean'];
  addUnitType: UnitTypeViewModel;
  bulkUpdateAccPercentageDriverAssumptions: Scalars['Boolean'];
  bulkUploadUnitEvents: BulkUploadUnitEventResult;
  copyDrivers: CopyDriversResponseModel;
  copyDriversClientLevel: CopyDriversResponseModel;
  copyPayrollEmployees: Scalars['Boolean'];
  copyRevenueAssumptionsClientLevel: CopyRevenueAssumptionsClientLevelResponseModel;
  createFileUploadRequest: FileUploadRequest;
  createManyOperationalMetricType: AffectedRowsOutput;
  createManyProperty: AffectedRowsOutput;
  createManyUnitTypeScheduleValue: AffectedRowsOutput;
  createManyValueVersion: AffectedRowsOutput;
  createOperationalMetricType: OperationalMetricType;
  createPayrollEmployee: PayrollEmployeeModel;
  createPayrollEmployeeBonus: PayrollEmployeeBonusModel;
  createPayrollEmployeeCompItem: PayrollEmployeeCompItemModel;
  createPayrollEmployeeForBudgetYear: PayrollEmployeeModel;
  createPayrollEmployeeManyBonuses: Scalars['Boolean'];
  createPayrollEmployeeManyOvertimes: Scalars['Boolean'];
  createPayrollEmployeeManyRaises: Scalars['Boolean'];
  createPayrollEmployeeOvertime: PayrollEmployeeOvertimeModel;
  createPayrollEmployeeRaise: PayrollEmployeeRaiseModel;
  createProperty: Property;
  createRenovationPackage: RenovationPackageModel;
  createSharedReportLinks: Array<Scalars['String']>;
  /** @deprecated Not compatible with multi-year or plan budgeting. */
  createUnitEvent: UnitEventModel;
  createUnitEventWithBudgetYear: UnitEventModel;
  createUnitTypeScheduleValue: UnitTypeScheduleValue;
  createUser: UserModel;
  createUserGroup: UserGroupModel;
  createValueVersion: ValueVersion;
  createWorksheetDriverData: Array<WorksheetLine>;
  deleteAccPercentageAssumptions: StringIdListResult;
  deleteAccountValueOverrides?: Maybe<Array<AccountValueOverride>>;
  deleteClientComment: Scalars['Boolean'];
  deleteCompItemsFromEmployees: Scalars['Boolean'];
  deleteCustomItem: Scalars['Boolean'];
  deleteManyOperationalMetricType: AffectedRowsOutput;
  deleteManyProperty: AffectedRowsOutput;
  deleteManyUnitTypeScheduleValue: AffectedRowsOutput;
  deleteManyValueVersion: AffectedRowsOutput;
  deleteOperationalMetricAssumptions: StringIdListResult;
  deleteOperationalMetricType?: Maybe<OperationalMetricType>;
  deletePayrollEmployeeBonus: Scalars['Boolean'];
  deletePayrollEmployeeCompItem: Scalars['Boolean'];
  deletePayrollEmployeeManyBonuses: Scalars['Boolean'];
  deletePayrollEmployeeManyOvertimes: Scalars['Boolean'];
  deletePayrollEmployeeManyRaises: Scalars['Boolean'];
  deletePayrollEmployeeOvertime: Scalars['Boolean'];
  deletePayrollEmployeeRaise: Scalars['Boolean'];
  deletePayrollEmployees: Scalars['Boolean'];
  deleteProperty?: Maybe<Property>;
  deleteReportTable: Scalars['Boolean'];
  deleteUnitEvents: Scalars['Boolean'];
  deleteUnitType: UnitTypeViewModel;
  deleteUnitTypeScheduleValue?: Maybe<UnitTypeScheduleValue>;
  deleteUserGroups: Scalars['Boolean'];
  deleteUsers: Scalars['Boolean'];
  deleteValueVersion?: Maybe<ValueVersion>;
  internalCreateUser: UserModel;
  internalDeleteUsers: Scalars['Boolean'];
  internalUpdateUser?: Maybe<UserModel>;
  massUpdateLeaseExpirationCountsForPropertyAndPeriod: Scalars['Boolean'];
  moveReportTable: Scalars['Boolean'];
  removeAccountTags: Scalars['Boolean'];
  removeRenovationPackage: Scalars['Boolean'];
  removeSharedReportLink: Scalars['Boolean'];
  removeWorksheetDriverData: Scalars['Boolean'];
  requestPortfolioCalcSnapshotCreation: Scalars['Boolean'];
  resendSharedReportLink: Scalars['Boolean'];
  setAccPercentageDrivers: Scalars['Boolean'];
  setAccountLock: Scalars['Boolean'];
  setAccountTags: Scalars['Boolean'];
  setAccountsTopCardTags: Array<AccountsTopCardTagsModel>;
  setActiveRentRoll: RentRollMetaDataModel;
  setBudgetApproval: Scalars['Boolean'];
  setClientComment?: Maybe<ClientCommentViewModel>;
  setCustomDriverAssumptions: Scalars['Boolean'];
  setDrivers: TSetDriversResolverReturn;
  setExpirationCurve: OriginalRevenueExpirationCurveModel;
  setMarketRent: UnitLevelMarketRentModel;
  setMultiPropertyForecastPeriodLocks: Array<PropertyRevenueConfiguration>;
  setMultipleAccountAndPropertyLocks: Scalars['Boolean'];
  setMultipleAccountLocks: Scalars['Boolean'];
  setOriginalRevenueMarketRentAdjustments: Scalars['Boolean'];
  setOriginalRevenueMarketRentAdjustmentsGranular: Scalars['Boolean'];
  setOriginalRevenueMarketRentMode: Scalars['Boolean'];
  setOriginalRevenueMarketRentOverrides: Scalars['Boolean'];
  setOriginalRevenueMarketRentOverridesGranular: Scalars['Boolean'];
  setOriginalRevenuePropertyRenewalIncreasePercent: Scalars['Boolean'];
  setPayrollEmployeeData: PayrollEmployeeModel;
  setPayrollEmployeeManyCompItems: Scalars['Boolean'];
  setPropertyDrivers: TSetPropertyDriversResolverReturn;
  setPropertyForecastPeriodLocks: TForecastPeriodLocks;
  setReportTableAccounts: Scalars['Boolean'];
  setUnitTypePMSOverrides: Scalars['Boolean'];
  setUsersBulk: Scalars['Boolean'];
  simplifiedRevenueRemoveRenovationMapping: Scalars['Boolean'];
  simplifiedRevenueSetRenovationMapping: SimplifiedRevenueRenovationMapping;
  simplifiedRevenueSetUnitTypeStartingMarketRent: Scalars['Boolean'];
  simplifiedRevenueUpdateFakeUnitTypeMarketRent: Array<SimplifiedRevenueFakeUnitTypeStartingMarketRentModel>;
  submitCopyDrivers?: Maybe<BackgroundJob>;
  submitSendBudget: BackgroundJob;
  updateAccPercentageDriverAssumptions: Array<AccPercentageDriverAssumptionViewModel>;
  updateAccPercentageDrivers: Array<AccPercentageDriverAccountUpdate>;
  updateBudgetYearIsAutoUnitTypeUpdateEnabled: BudgetYearsListViewModel;
  updateBudgetYearProtection: BudgetYearsListViewModel;
  /** @deprecated Use updateChartOfAccountsById */
  updateChartOfAccounts: Scalars['Boolean'];
  updateChartOfAccountsById: Scalars['Boolean'];
  updateCustomItem: CustomItemUpdateResultModel;
  updateManyOperationalMetricType: AffectedRowsOutput;
  updateManyProperty: AffectedRowsOutput;
  updateManyUnitTypeScheduleValue: AffectedRowsOutput;
  updateManyValueVersion: AffectedRowsOutput;
  updateOperationalDriverAssumptions: Array<OperationalDriverAssumptionViewModel>;
  updateOperationalDrivers: Scalars['Boolean'];
  updateOperationalMetricType?: Maybe<OperationalMetricType>;
  updatePayrollEmployee: PayrollEmployeeModel;
  updatePayrollEmployeeBaseCompensation: PayrollEmployeeBaseCompModel;
  updatePayrollEmployeeBonus: PayrollEmployeeBonusModel;
  updatePayrollEmployeeCompItem: PayrollEmployeeCompItemModel;
  updatePayrollEmployeeManyBonuses: Scalars['Boolean'];
  updatePayrollEmployeeManyOvertimes: Scalars['Boolean'];
  updatePayrollEmployeeManyRaises: Scalars['Boolean'];
  updatePayrollEmployeeOvertime: PayrollEmployeeOvertimeModel;
  updatePayrollEmployeeRaise: PayrollEmployeeRaiseModel;
  updateProperty?: Maybe<Property>;
  updatePropertyBudgetCompletion: Scalars['Boolean'];
  updateReforecastStartMonth: Scalars['Boolean'];
  /** @deprecated Not usable with multi-year/plan budgeting. */
  updateRenovationNumbers: RenovationNumbersModel;
  updateRenovationPackage: RenovationPackageModel;
  updateRenovationsNumbersByYearAndType: RenovationNumbersModel;
  updateReportTable: Scalars['Boolean'];
  updateSimplifiedDefaultRevenueRenovationRatio: SimplifiedRevenueRenovationRatio;
  updateSimplifiedRevenueDefaultMarketRentIncrease: Scalars['Boolean'];
  updateSimplifiedRevenueDefaultRenewalRatios: SimplifiedRevenueRenewalRatio;
  updateSimplifiedRevenueDefaultRenovatedRenewalRatios: SimplifiedRevenueRenewalRatio;
  updateSimplifiedRevenueDefaultVacancyPercentage: SimplifiedRevenueVacancyPercentage;
  updateSimplifiedRevenueExpiringUnits: Array<Scalars['String']>;
  updateSimplifiedRevenueRenewalTradeOutConfiguration: SimplifiedRevenueRenewalConfiguration;
  updateUnitEvent: UnitEventModel;
  updateUnitType: UnitTypeViewModel;
  updateUnitTypeScheduleValue?: Maybe<UnitTypeScheduleValue>;
  updateUser?: Maybe<UserModel>;
  updateUserGroup?: Maybe<UserGroupModel>;
  updateValueVersion?: Maybe<ValueVersion>;
  updateWorksheetDriverData: Scalars['Boolean'];
  upsertAccPercentageAssumptions: Array<AccPercentageAssumption>;
  upsertAccountValues: Array<AccountValue>;
  upsertAccountVarianceNote: AccountVarianceNoteModel;
  upsertMetricValuesMultiProperty: Scalars['Boolean'];
  /** @deprecated Will not work with multi-year budgeting. */
  upsertNote: Note;
  upsertNoteText: NoteModel;
  upsertOpMetricValues: Scalars['Boolean'];
  upsertOperationalMetricAssumptions: Array<OperationalMetricAssumption>;
  upsertOperationalMetricType: OperationalMetricType;
  upsertProperty: Property;
  upsertStarredAccounts: Scalars['Boolean'];
  upsertUnitTypeScheduleValue: UnitTypeScheduleValue;
  upsertUnitTypeScheduleValues: Scalars['Boolean'];
  upsertValueVersion: ValueVersion;
};


export type MutationAccountValueOverrideArgs = {
  accountId: Scalars['String'];
  monthIndexes: Array<Scalars['Int']>;
  propertyId: Scalars['String'];
  shouldRevert?: Maybe<Scalars['Boolean']>;
  values: Array<Scalars['Decimal']>;
  versionId: Scalars['String'];
};


export type MutationActivateBudgetYearArgs = {
  budgetYear: Scalars['Int'];
};


export type MutationAddAccountTagsArgs = {
  accountIds: Array<Scalars['ID']>;
  tags: Array<Scalars['String']>;
};


export type MutationAddBudgetYearArgs = {
  budgetYear: Scalars['Int'];
};


export type MutationAddCustomItemArgs = {
  name: Scalars['String'];
};


export type MutationAddReportTableArgs = {
  afterTableId?: Maybe<Scalars['ID']>;
  budgetYear: Scalars['Int'];
  reportTableName: Scalars['String'];
};


export type MutationAddUnitTypeArgs = {
  name: Scalars['String'];
  propertyId: Scalars['ID'];
};


export type MutationBulkUpdateAccPercentageDriverAssumptionsArgs = {
  item: AccPercentageDriverAssumptionsBulkUpdateInput;
};


export type MutationBulkUploadUnitEventsArgs = {
  budgetYear: Scalars['Int'];
  unitEvents: Array<BulkUnitEventInput>;
};


export type MutationCopyDriversArgs = {
  accountIds?: Maybe<Array<Scalars['ID']>>;
  budgetYear: Scalars['Int'];
  copyAssumptions?: Maybe<Scalars['Boolean']>;
  destinationPropertyId: Scalars['String'];
  period?: Maybe<DriversCopyPeriodInput>;
  sourcePropertyId: Scalars['String'];
};


export type MutationCopyDriversClientLevelArgs = {
  destinationBudgetYear: Scalars['Int'];
  destinationVersionType: VersionType;
  sourceBudgetYear: Scalars['Int'];
  sourceVersionType: VersionType;
};


export type MutationCopyPayrollEmployeesArgs = {
  destBudgetYear: Scalars['Int'];
  sourceBudgetYear: Scalars['Int'];
};


export type MutationCopyRevenueAssumptionsClientLevelArgs = {
  destinationBudgetYear: Scalars['Int'];
  destinationVersionType: VersionType;
  renovationCopyBothPeriods: Scalars['Boolean'];
  sourceBudgetYear: Scalars['Int'];
  sourceVersionType: VersionType;
};


export type MutationCreateFileUploadRequestArgs = {
  input: FileUploadRequestCreateInput;
};


export type MutationCreateManyOperationalMetricTypeArgs = {
  data: Array<OperationalMetricTypeCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateManyPropertyArgs = {
  data: Array<PropertyCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateManyUnitTypeScheduleValueArgs = {
  data: Array<UnitTypeScheduleValueCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateManyValueVersionArgs = {
  data: Array<ValueVersionCreateManyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateOperationalMetricTypeArgs = {
  data: OperationalMetricTypeCreateInput;
};


export type MutationCreatePayrollEmployeeArgs = {
  baseComp: PayrollCreateEmployeeBaseCompInput;
  bonuses: Array<PayrollCreateEmployeeBonusInput>;
  classification: PayrollEmployeeClassification;
  externalSystemEmployeeId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  items: Array<PayrollCreateEmployeeCompItemInput>;
  lastName?: Maybe<Scalars['String']>;
  overtime: Array<PayrollCreateEmployeeOvertimeInput>;
  positionId: Scalars['ID'];
  propertyAttributions?: Maybe<Array<PayrollEmployeePropertyAttributionInput>>;
  propertyIds?: Maybe<Array<Scalars['String']>>;
  raises: Array<PayrollCreateEmployeeRaiseInput>;
  startDate: Scalars['DateTime'];
  terminationDate?: Maybe<Scalars['DateTime']>;
  type: PayrollModeledEmployeeType;
};


export type MutationCreatePayrollEmployeeBonusArgs = {
  employeeId: Scalars['ID'];
  input: PayrollCreateEmployeeBonusInput;
};


export type MutationCreatePayrollEmployeeCompItemArgs = {
  employeeId: Scalars['ID'];
  input: PayrollCreateEmployeeCompItemInput;
};


export type MutationCreatePayrollEmployeeForBudgetYearArgs = {
  baseComp: PayrollCreateEmployeeBaseCompInput;
  bonuses: Array<PayrollCreateEmployeeBonusInput>;
  budgetYear: Scalars['Int'];
  classification: PayrollEmployeeClassification;
  externalSystemEmployeeId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  items: Array<PayrollCreateEmployeeCompItemInput>;
  lastName?: Maybe<Scalars['String']>;
  overtime: Array<PayrollCreateEmployeeOvertimeInput>;
  positionId: Scalars['ID'];
  propertyAttributions?: Maybe<Array<PayrollEmployeePropertyAttributionInput>>;
  propertyIds?: Maybe<Array<Scalars['String']>>;
  raises: Array<PayrollCreateEmployeeRaiseInput>;
  startDate: Scalars['DateTime'];
  terminationDate?: Maybe<Scalars['DateTime']>;
  type: PayrollModeledEmployeeType;
};


export type MutationCreatePayrollEmployeeManyBonusesArgs = {
  amount: Scalars['Decimal'];
  bonusDay?: Maybe<Scalars['Int']>;
  bonusMonth?: Maybe<Scalars['Int']>;
  bonusType: PayrollBonusType;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  employeeIds: Array<Scalars['ID']>;
  frequency: PayrollBonusFrequency;
  onetimeBonusDate?: Maybe<Scalars['DateTime']>;
};


export type MutationCreatePayrollEmployeeManyOvertimesArgs = {
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  employeeIds: Array<Scalars['ID']>;
  expectedHoursPerWeek: Scalars['Decimal'];
  hourlyCompensation: Scalars['Decimal'];
};


export type MutationCreatePayrollEmployeeManyRaisesArgs = {
  amount: Scalars['Decimal'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  employeeIds: Array<Scalars['ID']>;
  frequency: PayrollRaiseFrequency;
  onetimeRaiseDate?: Maybe<Scalars['DateTime']>;
  raiseDay?: Maybe<Scalars['Int']>;
  raiseMonth?: Maybe<Scalars['Int']>;
  raiseType: PayrollRaiseType;
};


export type MutationCreatePayrollEmployeeOvertimeArgs = {
  employeeId: Scalars['ID'];
  input: PayrollCreateEmployeeOvertimeInput;
};


export type MutationCreatePayrollEmployeeRaiseArgs = {
  employeeId: Scalars['ID'];
  input: PayrollCreateEmployeeRaiseInput;
};


export type MutationCreatePropertyArgs = {
  data: PropertyCreateInput;
};


export type MutationCreateRenovationPackageArgs = {
  costCategoryMapping: Array<CostCategoryInput>;
  name: Scalars['String'];
  premium?: Maybe<Scalars['Decimal']>;
  premiumDelayRecognition?: Maybe<Scalars['Int']>;
  propertyId: Scalars['String'];
  unitTypeId: Scalars['String'];
};


export type MutationCreateSharedReportLinksArgs = {
  budgetYear: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  persons: Array<PersonEmailInput>;
  propertyId: Scalars['ID'];
};


export type MutationCreateUnitEventArgs = {
  concessionMonths?: Maybe<Scalars['Decimal']>;
  eventEnd: Scalars['DateTime'];
  eventStart: Scalars['DateTime'];
  eventType: UnitEventType;
  inPlaceRent?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  renewalAdjustmentPercentage?: Maybe<Scalars['Decimal']>;
  renovationCost?: Maybe<Scalars['Decimal']>;
  renovationPremium?: Maybe<Scalars['Decimal']>;
  shortTermEffectiveAvgDailyRent?: Maybe<Scalars['Decimal']>;
  unitId: Scalars['ID'];
};


export type MutationCreateUnitEventWithBudgetYearArgs = {
  budgetYear: Scalars['Int'];
  concessionMonths?: Maybe<Scalars['Decimal']>;
  eventEnd: Scalars['DateTime'];
  eventStart: Scalars['DateTime'];
  eventType: UnitEventType;
  inPlaceRent?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  renewalAdjustmentPercentage?: Maybe<Scalars['Decimal']>;
  renovationCost?: Maybe<Scalars['Decimal']>;
  renovationPremium?: Maybe<Scalars['Decimal']>;
  shortTermEffectiveAvgDailyRent?: Maybe<Scalars['Decimal']>;
  unitId: Scalars['ID'];
};


export type MutationCreateUnitTypeScheduleValueArgs = {
  data: UnitTypeScheduleValueCreateInput;
};


export type MutationCreateUserArgs = {
  authorizations: Array<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  generatePassword?: Maybe<Scalars['Boolean']>;
  groups?: Maybe<Array<Scalars['String']>>;
  isReadOnly: Scalars['Boolean'];
  lastName: Scalars['String'];
  password?: Maybe<Scalars['PasswordScalar']>;
  properties: Array<Scalars['String']>;
  role: UserRole;
};


export type MutationCreateUserGroupArgs = {
  authorizations: Array<Scalars['String']>;
  name: Scalars['String'];
};


export type MutationCreateValueVersionArgs = {
  data: ValueVersionCreateInput;
};


export type MutationCreateWorksheetDriverDataArgs = {
  inputs: Array<WorksheetDriverLineCreateInput>;
};


export type MutationDeleteAccPercentageAssumptionsArgs = {
  accPercentageAssumptionIds: Array<Scalars['String']>;
};


export type MutationDeleteAccountValueOverridesArgs = {
  accountId: Scalars['String'];
  monthIndexes: Array<Scalars['Int']>;
  propertyId: Scalars['String'];
  versionId: Scalars['String'];
};


export type MutationDeleteClientCommentArgs = {
  clientCommentId: Scalars['ID'];
};


export type MutationDeleteCompItemsFromEmployeesArgs = {
  employeeIds: Array<Scalars['ID']>;
  itemType: PayrollCompensationItemType;
};


export type MutationDeleteCustomItemArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteManyOperationalMetricTypeArgs = {
  where?: Maybe<OperationalMetricTypeWhereInput>;
};


export type MutationDeleteManyPropertyArgs = {
  where?: Maybe<PropertyWhereInput>;
};


export type MutationDeleteManyUnitTypeScheduleValueArgs = {
  where?: Maybe<UnitTypeScheduleValueWhereInput>;
};


export type MutationDeleteManyValueVersionArgs = {
  where?: Maybe<ValueVersionWhereInput>;
};


export type MutationDeleteOperationalMetricAssumptionsArgs = {
  operationalMetricAssumptionIds: Array<Scalars['String']>;
};


export type MutationDeleteOperationalMetricTypeArgs = {
  where: OperationalMetricTypeWhereUniqueInput;
};


export type MutationDeletePayrollEmployeeBonusArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePayrollEmployeeCompItemArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePayrollEmployeeManyBonusesArgs = {
  bonusIds: Array<Scalars['ID']>;
};


export type MutationDeletePayrollEmployeeManyOvertimesArgs = {
  overtimeIds: Array<Scalars['ID']>;
};


export type MutationDeletePayrollEmployeeManyRaisesArgs = {
  raiseIds: Array<Scalars['ID']>;
};


export type MutationDeletePayrollEmployeeOvertimeArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePayrollEmployeeRaiseArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePayrollEmployeesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeletePropertyArgs = {
  where: PropertyWhereUniqueInput;
};


export type MutationDeleteReportTableArgs = {
  budgetYear: Scalars['Int'];
  reportTableId: Scalars['ID'];
};


export type MutationDeleteUnitEventsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteUnitTypeArgs = {
  unitTypeId: Scalars['ID'];
};


export type MutationDeleteUnitTypeScheduleValueArgs = {
  where: UnitTypeScheduleValueWhereUniqueInput;
};


export type MutationDeleteUserGroupsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteUsersArgs = {
  userIds: Array<Scalars['ID']>;
};


export type MutationDeleteValueVersionArgs = {
  where: ValueVersionWhereUniqueInput;
};


export type MutationInternalCreateUserArgs = {
  authorizations: Array<Scalars['String']>;
  clientId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  generatePassword?: Maybe<Scalars['Boolean']>;
  groups?: Maybe<Array<Scalars['String']>>;
  isReadOnly: Scalars['Boolean'];
  lastName: Scalars['String'];
  password?: Maybe<Scalars['PasswordScalar']>;
  properties: Array<Scalars['String']>;
  role: UserRole;
};


export type MutationInternalDeleteUsersArgs = {
  clientId: Scalars['String'];
  userIds: Array<Scalars['ID']>;
};


export type MutationInternalUpdateUserArgs = {
  authorizations?: Maybe<Array<Scalars['String']>>;
  clientId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  isReadOnly?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  properties?: Maybe<Array<Scalars['String']>>;
  role?: Maybe<UserRole>;
};


export type MutationMassUpdateLeaseExpirationCountsForPropertyAndPeriodArgs = {
  propertyId: Scalars['ID'];
  updates: Array<LeaseExpirationCountsInput>;
  versionType: VersionType;
};


export type MutationMoveReportTableArgs = {
  afterTableId?: Maybe<Scalars['ID']>;
  budgetYear: Scalars['Int'];
  reportTableId: Scalars['ID'];
};


export type MutationRemoveAccountTagsArgs = {
  accountIds: Array<Scalars['ID']>;
  tags: Array<Scalars['String']>;
};


export type MutationRemoveRenovationPackageArgs = {
  force?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationRemoveSharedReportLinkArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveWorksheetDriverDataArgs = {
  lineIds: Array<Scalars['String']>;
};


export type MutationRequestPortfolioCalcSnapshotCreationArgs = {
  budgetYear: Scalars['Int'];
};


export type MutationResendSharedReportLinkArgs = {
  id: Scalars['ID'];
};


export type MutationSetAccPercentageDriversArgs = {
  destinationAccountId: Scalars['ID'];
  drivers: Array<AccPercentageDriverSetInput>;
  propertyId: Scalars['ID'];
  versionType: VersionType;
  year: Scalars['Int'];
};


export type MutationSetAccountLockArgs = {
  accountId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  locked: Scalars['Boolean'];
  propertyId: Scalars['ID'];
  versionType: VersionType;
};


export type MutationSetAccountTagsArgs = {
  accountIds: Array<Scalars['ID']>;
  tags: Array<Scalars['String']>;
};


export type MutationSetAccountsTopCardTagsArgs = {
  accountIds: Array<Scalars['String']>;
  topCardTagType: TopCardTagType;
};


export type MutationSetActiveRentRollArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
  rentRollId: Scalars['ID'];
};


export type MutationSetBudgetApprovalArgs = {
  approved: Scalars['Boolean'];
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
};


export type MutationSetClientCommentArgs = {
  accountId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
  text: Scalars['String'];
};


export type MutationSetCustomDriverAssumptionsArgs = {
  destinationAccountId: Scalars['ID'];
  items: Array<CustomDriverItemUpdateInput>;
  propertyId: Scalars['ID'];
  versionType: VersionType;
  year: Scalars['Int'];
};


export type MutationSetDriversArgs = {
  accountId: Scalars['String'];
  isUserRequestingWorksheetDeletion: Scalars['Boolean'];
  pendingUpdates: PendingUpdates;
  propertyId: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Int'];
};


export type MutationSetExpirationCurveArgs = {
  budgetYear: Scalars['Int'];
  curve: Array<Scalars['Decimal']>;
  propertyId: Scalars['ID'];
};


export type MutationSetMarketRentArgs = {
  marketRent: Scalars['Decimal'];
  month: Scalars['Int'];
  type: VersionType;
  unitId: Scalars['ID'];
  year: Scalars['Int'];
};


export type MutationSetMultiPropertyForecastPeriodLocksArgs = {
  budgetYear: Scalars['Int'];
  locks: PropertyForecastLocksArgs;
  propertyIds: Array<Scalars['String']>;
};


export type MutationSetMultipleAccountAndPropertyLocksArgs = {
  accountIds: Array<Scalars['String']>;
  budgetYear: Scalars['Int'];
  lockBdgt?: Maybe<Scalars['Boolean']>;
  lockRfcst?: Maybe<Scalars['Boolean']>;
  propertyIds: Array<Scalars['String']>;
};


export type MutationSetMultipleAccountLocksArgs = {
  accountLocks: Array<AccountLocksArgsItem>;
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
  versionType: VersionType;
};


export type MutationSetOriginalRevenueMarketRentAdjustmentsArgs = {
  adjustmentTypes: Array<Maybe<OriginalRevenueMarketRentAdjustmentType>>;
  adjustments: Array<Maybe<Scalars['Float']>>;
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
  versionType: VersionType;
};


export type MutationSetOriginalRevenueMarketRentAdjustmentsGranularArgs = {
  adjM0?: Maybe<Scalars['Float']>;
  adjM1?: Maybe<Scalars['Float']>;
  adjM2?: Maybe<Scalars['Float']>;
  adjM3?: Maybe<Scalars['Float']>;
  adjM4?: Maybe<Scalars['Float']>;
  adjM5?: Maybe<Scalars['Float']>;
  adjM6?: Maybe<Scalars['Float']>;
  adjM7?: Maybe<Scalars['Float']>;
  adjM8?: Maybe<Scalars['Float']>;
  adjM9?: Maybe<Scalars['Float']>;
  adjM10?: Maybe<Scalars['Float']>;
  adjM11?: Maybe<Scalars['Float']>;
  adjTpM0?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjTpM1?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjTpM2?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjTpM3?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjTpM4?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjTpM5?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjTpM6?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjTpM7?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjTpM8?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjTpM9?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjTpM10?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjTpM11?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
  versionType: VersionType;
};


export type MutationSetOriginalRevenueMarketRentModeArgs = {
  budgetYear: Scalars['Int'];
  originalRevenueMarketRentModeType: OriginalRevenueMarketRentModeType;
  propertyId: Scalars['ID'];
};


export type MutationSetOriginalRevenueMarketRentOverridesArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
  unitTypeOverrides: Array<UnitTypeWithOriginalrevenueMarketRentOverridesInput>;
  versionType: VersionType;
};


export type MutationSetOriginalRevenueMarketRentOverridesGranularArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
  unitTypeOverrides: Array<UnitTypeWithOriginalrevenueMarketRentOverridesGranularInput>;
  versionType: VersionType;
};


export type MutationSetOriginalRevenuePropertyRenewalIncreasePercentArgs = {
  budgetYear: Scalars['Int'];
  percent?: Maybe<Scalars['Float']>;
  propertyId: Scalars['ID'];
  type?: Maybe<OriginalRevenueRenewalIncreaseType>;
};


export type MutationSetPayrollEmployeeDataArgs = {
  baseComp: PayrollCreateEmployeeBaseCompInput;
  bonuses: Array<PayrollDifferentialEmployeeBonusInput>;
  classification: PayrollEmployeeClassification;
  externalSystemEmployeeId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  items: Array<PayrollDifferentialEmployeeCompItemInput>;
  lastName?: Maybe<Scalars['String']>;
  overtime: Array<PayrollDifferentialEmployeeOvertimeInput>;
  positionId: Scalars['ID'];
  propertyAttributions?: Maybe<Array<PayrollEmployeePropertyAttributionInput>>;
  propertyIds?: Maybe<Array<Scalars['String']>>;
  raises: Array<PayrollDifferentialEmployeeRaiseInput>;
  startDate: Scalars['DateTime'];
  terminationDate?: Maybe<Scalars['DateTime']>;
  type: PayrollModeledEmployeeType;
};


export type MutationSetPayrollEmployeeManyCompItemsArgs = {
  createCalcType: PayrollCompensationItemCalculationType;
  employeeIds: Array<Scalars['ID']>;
  input: PayrollUpdateEmployeeCompItemInput;
  type: PayrollCompensationItemType;
};


export type MutationSetPropertyDriversArgs = {
  accountLocks: Array<AccountLocksArgsItem>;
  items: Array<SetPropertyDriversArgsItem>;
  propertyId: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Int'];
};


export type MutationSetPropertyForecastPeriodLocksArgs = {
  budgetYear: Scalars['Int'];
  locks: PropertyForecastLocksArgs;
  propertyId: Scalars['String'];
};


export type MutationSetReportTableAccountsArgs = {
  accountIds: Array<Scalars['ID']>;
  budgetYear: Scalars['Int'];
  reportTableId: Scalars['ID'];
};


export type MutationSetUnitTypePmsOverridesArgs = {
  inPlaceRent?: Maybe<Array<UnitTypeForPmsActualOverridesUpdateInput>>;
  occupancy?: Maybe<Array<UnitTypeForPmsActualOverridesUpdateInput>>;
  propertyId: Scalars['ID'];
  unitCount?: Maybe<Array<UnitTypeForPmsActualOverridesUpdateInput>>;
};


export type MutationSetUsersBulkArgs = {
  groupIds?: Maybe<Array<Scalars['ID']>>;
  ids: Array<Scalars['ID']>;
  propertyIds?: Maybe<Array<Scalars['ID']>>;
  readOnly?: Maybe<Scalars['Boolean']>;
};


export type MutationSimplifiedRevenueRemoveRenovationMappingArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
  renovationPackageId: Scalars['ID'];
};


export type MutationSimplifiedRevenueSetRenovationMappingArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
  setData: SimplifiedRevenueSetRenovationMappingInput;
};


export type MutationSimplifiedRevenueSetUnitTypeStartingMarketRentArgs = {
  budgetYear: Scalars['Int'];
  overrides: Array<SimplifiedRevenueSetUnitTypeStartingMarketRentOverrideInput>;
  propertyId: Scalars['ID'];
};


export type MutationSimplifiedRevenueUpdateFakeUnitTypeMarketRentArgs = {
  budgetYear: Scalars['Int'];
  marketRents: Array<SimplifiedRevenueFakeUnitTypeMarketRentInput>;
  propertyId: Scalars['ID'];
};


export type MutationSubmitCopyDriversArgs = {
  accountIds?: Maybe<Array<Scalars['ID']>>;
  budgetYear: Scalars['Int'];
  copyAssumptions?: Maybe<Scalars['Boolean']>;
  destinationPropertyIds: Array<Scalars['ID']>;
  period?: Maybe<DriversCopyPeriodInput>;
  sourcePropertyId: Scalars['ID'];
};


export type MutationSubmitSendBudgetArgs = {
  budgetNameTemplate: Scalars['String'];
  propertyIds: Array<Scalars['ID']>;
};


export type MutationUpdateAccPercentageDriverAssumptionsArgs = {
  items: Array<AccPercentageDriverAssumptionsUpdateInput>;
};


export type MutationUpdateAccPercentageDriversArgs = {
  items: Array<AccPercentageDriverUpdateInput>;
};


export type MutationUpdateBudgetYearIsAutoUnitTypeUpdateEnabledArgs = {
  budgetYear: Scalars['Int'];
  isAutoUnitTypeUpdateEnabled: Scalars['Boolean'];
};


export type MutationUpdateBudgetYearProtectionArgs = {
  budgetYear: Scalars['Int'];
  protected: Scalars['Boolean'];
};


export type MutationUpdateChartOfAccountsArgs = {
  records: Array<CoaListRecordInput>;
  versionNum: Scalars['Int'];
};


export type MutationUpdateChartOfAccountsByIdArgs = {
  chartOfAccountsId: Scalars['ID'];
  records: Array<CoaListRecordInput>;
  versionNum: Scalars['Int'];
};


export type MutationUpdateCustomItemArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateManyOperationalMetricTypeArgs = {
  data: OperationalMetricTypeUpdateManyMutationInput;
  where?: Maybe<OperationalMetricTypeWhereInput>;
};


export type MutationUpdateManyPropertyArgs = {
  data: PropertyUpdateManyMutationInput;
  where?: Maybe<PropertyWhereInput>;
};


export type MutationUpdateManyUnitTypeScheduleValueArgs = {
  data: UnitTypeScheduleValueUpdateManyMutationInput;
  where?: Maybe<UnitTypeScheduleValueWhereInput>;
};


export type MutationUpdateManyValueVersionArgs = {
  data: ValueVersionUpdateManyMutationInput;
  where?: Maybe<ValueVersionWhereInput>;
};


export type MutationUpdateOperationalDriverAssumptionsArgs = {
  items: Array<OperationalDriverAssumptionsUpdateInput>;
};


export type MutationUpdateOperationalDriversArgs = {
  items: Array<OperationalDriverUpdateInput>;
};


export type MutationUpdateOperationalMetricTypeArgs = {
  data: OperationalMetricTypeUpdateInput;
  where: OperationalMetricTypeWhereUniqueInput;
};


export type MutationUpdatePayrollEmployeeArgs = {
  classification?: Maybe<Scalars['String']>;
  externalSystemEmployeeId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['ID']>;
  propertyAttributions?: Maybe<Array<PayrollEmployeePropertyAttributionInput>>;
  propertyIds?: Maybe<Array<Scalars['String']>>;
  startDate?: Maybe<Scalars['DateTime']>;
  terminationDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<PayrollModeledEmployeeType>;
};


export type MutationUpdatePayrollEmployeeBaseCompensationArgs = {
  employeeId: Scalars['ID'];
  input: PayrollUpdateEmployeeBaseCompInput;
};


export type MutationUpdatePayrollEmployeeBonusArgs = {
  id: Scalars['ID'];
  input: PayrollUpdateEmployeeBonusInput;
};


export type MutationUpdatePayrollEmployeeCompItemArgs = {
  id: Scalars['ID'];
  input: PayrollUpdateEmployeeCompItemInput;
};


export type MutationUpdatePayrollEmployeeManyBonusesArgs = {
  amount: Scalars['Decimal'];
  bonusDay?: Maybe<Scalars['Int']>;
  bonusIds: Array<Scalars['ID']>;
  bonusMonth?: Maybe<Scalars['Int']>;
  bonusType: PayrollBonusType;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollBonusFrequency;
  onetimeBonusDate?: Maybe<Scalars['DateTime']>;
};


export type MutationUpdatePayrollEmployeeManyOvertimesArgs = {
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  expectedHoursPerWeek: Scalars['Decimal'];
  hourlyCompensation: Scalars['Decimal'];
  overtimeIds: Array<Scalars['ID']>;
};


export type MutationUpdatePayrollEmployeeManyRaisesArgs = {
  amount: Scalars['Decimal'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollRaiseFrequency;
  onetimeRaiseDate?: Maybe<Scalars['DateTime']>;
  raiseDay?: Maybe<Scalars['Int']>;
  raiseIds: Array<Scalars['ID']>;
  raiseMonth?: Maybe<Scalars['Int']>;
  raiseType: PayrollRaiseType;
};


export type MutationUpdatePayrollEmployeeOvertimeArgs = {
  id: Scalars['ID'];
  input: PayrollUpdateEmployeeOvertimeInput;
};


export type MutationUpdatePayrollEmployeeRaiseArgs = {
  id: Scalars['ID'];
  input: PayrollUpdateEmployeeRaiseInput;
};


export type MutationUpdatePropertyArgs = {
  data: PropertyUpdateInput;
  where: PropertyWhereUniqueInput;
};


export type MutationUpdatePropertyBudgetCompletionArgs = {
  budgetYear: Scalars['Int'];
  completed: Scalars['Boolean'];
  propertyId: Scalars['ID'];
};


export type MutationUpdateReforecastStartMonthArgs = {
  propertyIds: Array<Scalars['ID']>;
  reforecastStartMonthIndex: Scalars['Int'];
};


export type MutationUpdateRenovationNumbersArgs = {
  expected: Array<RenovationNumberInput>;
  mapping?: Maybe<BudgetMapping>;
  renovationPackageId: Scalars['String'];
  year: Scalars['BudgetYearScalar'];
};


export type MutationUpdateRenovationPackageArgs = {
  costCategoryMapping?: Maybe<Array<CostCategoryInput>>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  premium?: Maybe<Scalars['Decimal']>;
  premiumDelayRecognition?: Maybe<Scalars['Int']>;
};


export type MutationUpdateRenovationsNumbersByYearAndTypeArgs = {
  expected: Array<RenovationNumberInput>;
  renovationPackageId: Scalars['String'];
  yearAndType: YearAndType;
};


export type MutationUpdateReportTableArgs = {
  budgetYear: Scalars['Int'];
  newName: Scalars['String'];
  reportTableId: Scalars['ID'];
};


export type MutationUpdateSimplifiedDefaultRevenueRenovationRatioArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
  renovationRatios: SimplifiedRevenueRenovationRatioInput;
};


export type MutationUpdateSimplifiedRevenueDefaultMarketRentIncreaseArgs = {
  budgetYear: Scalars['Int'];
  increases: SimplifiedRevenueMarketRentIncreaseInput;
  propertyId: Scalars['ID'];
};


export type MutationUpdateSimplifiedRevenueDefaultRenewalRatiosArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
  renewalRatios: SimplifiedRevenueRenewalRatioInput;
};


export type MutationUpdateSimplifiedRevenueDefaultRenovatedRenewalRatiosArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
  renewalRatios: SimplifiedRevenueRenewalRatioInput;
};


export type MutationUpdateSimplifiedRevenueDefaultVacancyPercentageArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
  vacancyPercentages: SimplifiedRevenueVacancyPercentageInput;
};


export type MutationUpdateSimplifiedRevenueExpiringUnitsArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
  updates: Array<UpdateRentRollExpiringUnitConversionInput>;
};


export type MutationUpdateSimplifiedRevenueRenewalTradeOutConfigurationArgs = {
  budgetYear: Scalars['Int'];
  configuration: SimplifiedRevenueRenewalTradeOutConfigurationInput;
  propertyId: Scalars['ID'];
};


export type MutationUpdateUnitEventArgs = {
  concessionMonths?: Maybe<Scalars['Decimal']>;
  eventEnd: Scalars['DateTime'];
  eventStart: Scalars['DateTime'];
  eventType: UnitEventType;
  id: Scalars['ID'];
  inPlaceRent?: Maybe<Scalars['Decimal']>;
  renewalAdjustmentPercentage?: Maybe<Scalars['Decimal']>;
  renovationCost?: Maybe<Scalars['Decimal']>;
  renovationPremium?: Maybe<Scalars['Decimal']>;
  shortTermEffectiveAvgDailyRent?: Maybe<Scalars['Decimal']>;
};


export type MutationUpdateUnitTypeArgs = {
  name: Scalars['String'];
  unitTypeId: Scalars['ID'];
};


export type MutationUpdateUnitTypeScheduleValueArgs = {
  data: UnitTypeScheduleValueUpdateInput;
  where: UnitTypeScheduleValueWhereUniqueInput;
};


export type MutationUpdateUserArgs = {
  authorizations?: Maybe<Array<Scalars['String']>>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  isReadOnly?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  properties?: Maybe<Array<Scalars['String']>>;
  role?: Maybe<UserRole>;
};


export type MutationUpdateUserGroupArgs = {
  authorizations?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};


export type MutationUpdateValueVersionArgs = {
  data: ValueVersionUpdateInput;
  where: ValueVersionWhereUniqueInput;
};


export type MutationUpdateWorksheetDriverDataArgs = {
  creates: Array<WorksheetDriverLineCreateInput>;
  updates: Array<WorksheetDriverLineUpdateInput>;
};


export type MutationUpsertAccPercentageAssumptionsArgs = {
  multiArgs: Array<UpsertAccPercentageAssumptions>;
};


export type MutationUpsertAccountValuesArgs = {
  accountId: Scalars['String'];
  monthIndexes: Array<Scalars['Int']>;
  propertyId: Scalars['String'];
  values: Array<Scalars['Decimal']>;
  versionId: Scalars['String'];
};


export type MutationUpsertAccountVarianceNoteArgs = {
  input: AccountVarianceNoteUpsertInput;
};


export type MutationUpsertMetricValuesMultiPropertyArgs = {
  propertyMetrics: Array<PropertyMetrics>;
  unitTypeMetrics: Array<UnitTypeMetrics>;
};


export type MutationUpsertNoteArgs = {
  create: NoteCreateInput;
  update: NoteUpdateInput;
  where: NoteWhereUniqueInput;
};


export type MutationUpsertNoteTextArgs = {
  entityId: Scalars['ID'];
  entityType: EntityType;
  propertyId: Scalars['ID'];
  text: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Int'];
};


export type MutationUpsertOpMetricValuesArgs = {
  fieldName: Scalars['String'];
  monthIndexes: Array<Scalars['Int']>;
  propertyId: Scalars['String'];
  values: Array<Scalars['Decimal']>;
  versionId: Scalars['String'];
};


export type MutationUpsertOperationalMetricAssumptionsArgs = {
  multiArgs: Array<UpsertOperationMetricAssumptions>;
};


export type MutationUpsertOperationalMetricTypeArgs = {
  create: OperationalMetricTypeCreateInput;
  update: OperationalMetricTypeUpdateInput;
  where: OperationalMetricTypeWhereUniqueInput;
};


export type MutationUpsertPropertyArgs = {
  create: PropertyCreateInput;
  update: PropertyUpdateInput;
  where: PropertyWhereUniqueInput;
};


export type MutationUpsertStarredAccountsArgs = {
  multiAccountsArgs: Array<StarredAccountMutationArgs>;
};


export type MutationUpsertUnitTypeScheduleValueArgs = {
  create: UnitTypeScheduleValueCreateInput;
  update: UnitTypeScheduleValueUpdateInput;
  where: UnitTypeScheduleValueWhereUniqueInput;
};


export type MutationUpsertUnitTypeScheduleValuesArgs = {
  fieldName: Scalars['String'];
  propertyId: Scalars['String'];
  values: Array<UnitTypeScheduleValueUpsertInput>;
  versionId: Scalars['String'];
};


export type MutationUpsertValueVersionArgs = {
  create: ValueVersionCreateInput;
  update: ValueVersionUpdateInput;
  where: ValueVersionWhereUniqueInput;
};

export type MyUser = {
  __typename?: 'MyUser';
  authorizations: Array<Scalars['String']>;
  clientDisplayName: Scalars['String'];
  clientId: Scalars['String'];
  clientName: Scalars['String'];
  email: Scalars['String'];
  features: Array<Scalars['String']>;
  firstName: Scalars['String'];
  hasPowerBi: Scalars['Boolean'];
  id: Scalars['String'];
  isReadOnly: Scalars['Boolean'];
  lastName: Scalars['String'];
  properties: Array<Property>;
  role: UserRole;
};

export type NestedBigIntFilter = {
  equals?: Maybe<Scalars['BigInt']>;
  gt?: Maybe<Scalars['BigInt']>;
  gte?: Maybe<Scalars['BigInt']>;
  in?: Maybe<Array<Scalars['BigInt']>>;
  lt?: Maybe<Scalars['BigInt']>;
  lte?: Maybe<Scalars['BigInt']>;
  not?: Maybe<NestedBigIntFilter>;
  notIn?: Maybe<Array<Scalars['BigInt']>>;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedBoolFilter>;
  _min?: Maybe<NestedBoolFilter>;
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedDateTimeFilter>;
  _min?: Maybe<NestedDateTimeFilter>;
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type NestedDecimalFilter = {
  equals?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalFilter>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
};

export type NestedDecimalNullableFilter = {
  equals?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalNullableFilter>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
};

export type NestedDecimalNullableWithAggregatesFilter = {
  _avg?: Maybe<NestedDecimalNullableFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedDecimalNullableFilter>;
  _min?: Maybe<NestedDecimalNullableFilter>;
  _sum?: Maybe<NestedDecimalNullableFilter>;
  equals?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
};

export type NestedDecimalWithAggregatesFilter = {
  _avg?: Maybe<NestedDecimalFilter>;
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedDecimalFilter>;
  _min?: Maybe<NestedDecimalFilter>;
  _sum?: Maybe<NestedDecimalFilter>;
  equals?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
};

export type NestedEnumAccountTypeFilter = {
  equals?: Maybe<AccountType>;
  in?: Maybe<Array<AccountType>>;
  not?: Maybe<NestedEnumAccountTypeFilter>;
  notIn?: Maybe<Array<AccountType>>;
};

export type NestedEnumBudgetComponentTypeFilter = {
  equals?: Maybe<BudgetComponentType>;
  in?: Maybe<Array<BudgetComponentType>>;
  not?: Maybe<NestedEnumBudgetComponentTypeFilter>;
  notIn?: Maybe<Array<BudgetComponentType>>;
};

export type NestedEnumClientTypeFilter = {
  equals?: Maybe<ClientType>;
  in?: Maybe<Array<ClientType>>;
  not?: Maybe<NestedEnumClientTypeFilter>;
  notIn?: Maybe<Array<ClientType>>;
};

export type NestedEnumDistributionMethodNullableFilter = {
  equals?: Maybe<DistributionMethod>;
  in?: Maybe<Array<DistributionMethod>>;
  not?: Maybe<NestedEnumDistributionMethodNullableFilter>;
  notIn?: Maybe<Array<DistributionMethod>>;
};

export type NestedEnumDriverTypeFilter = {
  equals?: Maybe<DriverType>;
  in?: Maybe<Array<DriverType>>;
  not?: Maybe<NestedEnumDriverTypeFilter>;
  notIn?: Maybe<Array<DriverType>>;
};

export type NestedEnumEntityTypeFilter = {
  equals?: Maybe<EntityType>;
  in?: Maybe<Array<EntityType>>;
  not?: Maybe<NestedEnumEntityTypeFilter>;
  notIn?: Maybe<Array<EntityType>>;
};

export type NestedEnumFileUploadStatusFilter = {
  equals?: Maybe<FileUploadStatus>;
  in?: Maybe<Array<FileUploadStatus>>;
  not?: Maybe<NestedEnumFileUploadStatusFilter>;
  notIn?: Maybe<Array<FileUploadStatus>>;
};

export type NestedEnumFileUploadTypeFilter = {
  equals?: Maybe<FileUploadType>;
  in?: Maybe<Array<FileUploadType>>;
  not?: Maybe<NestedEnumFileUploadTypeFilter>;
  notIn?: Maybe<Array<FileUploadType>>;
};

export type NestedEnumGrowthCalcMethodFilter = {
  equals?: Maybe<GrowthCalcMethod>;
  in?: Maybe<Array<GrowthCalcMethod>>;
  not?: Maybe<NestedEnumGrowthCalcMethodFilter>;
  notIn?: Maybe<Array<GrowthCalcMethod>>;
};

export type NestedEnumJobStatusFilter = {
  equals?: Maybe<JobStatus>;
  in?: Maybe<Array<JobStatus>>;
  not?: Maybe<NestedEnumJobStatusFilter>;
  notIn?: Maybe<Array<JobStatus>>;
};

export type NestedEnumJobTypeFilter = {
  equals?: Maybe<JobType>;
  in?: Maybe<Array<JobType>>;
  not?: Maybe<NestedEnumJobTypeFilter>;
  notIn?: Maybe<Array<JobType>>;
};

export type NestedEnumMathOperationTypeFilter = {
  equals?: Maybe<MathOperationType>;
  in?: Maybe<Array<MathOperationType>>;
  not?: Maybe<NestedEnumMathOperationTypeFilter>;
  notIn?: Maybe<Array<MathOperationType>>;
};

export type NestedEnumMonthlyAdjustmentTypeNullableFilter = {
  equals?: Maybe<MonthlyAdjustmentType>;
  in?: Maybe<Array<MonthlyAdjustmentType>>;
  not?: Maybe<NestedEnumMonthlyAdjustmentTypeNullableFilter>;
  notIn?: Maybe<Array<MonthlyAdjustmentType>>;
};

export type NestedEnumMonthlyAverageBasePeriodNullableFilter = {
  equals?: Maybe<MonthlyAverageBasePeriod>;
  in?: Maybe<Array<MonthlyAverageBasePeriod>>;
  not?: Maybe<NestedEnumMonthlyAverageBasePeriodNullableFilter>;
  notIn?: Maybe<Array<MonthlyAverageBasePeriod>>;
};

export type NestedEnumOperationTypeFilter = {
  equals?: Maybe<OperationType>;
  in?: Maybe<Array<OperationType>>;
  not?: Maybe<NestedEnumOperationTypeFilter>;
  notIn?: Maybe<Array<OperationType>>;
};

export type NestedEnumOperationalFinancialMetricsAggregateTypeFilter = {
  equals?: Maybe<OperationalFinancialMetricsAggregateType>;
  in?: Maybe<Array<OperationalFinancialMetricsAggregateType>>;
  not?: Maybe<NestedEnumOperationalFinancialMetricsAggregateTypeFilter>;
  notIn?: Maybe<Array<OperationalFinancialMetricsAggregateType>>;
};

export type NestedEnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter = {
  equals?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  in?: Maybe<Array<OriginalRevenueMarketRentAdjustmentType>>;
  not?: Maybe<NestedEnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  notIn?: Maybe<Array<OriginalRevenueMarketRentAdjustmentType>>;
};

export type NestedEnumOriginalRevenueMarketRentModeTypeFilter = {
  equals?: Maybe<OriginalRevenueMarketRentModeType>;
  in?: Maybe<Array<OriginalRevenueMarketRentModeType>>;
  not?: Maybe<NestedEnumOriginalRevenueMarketRentModeTypeFilter>;
  notIn?: Maybe<Array<OriginalRevenueMarketRentModeType>>;
};

export type NestedEnumOriginalRevenueRenewalIncreaseTypeNullableFilter = {
  equals?: Maybe<OriginalRevenueRenewalIncreaseType>;
  in?: Maybe<Array<OriginalRevenueRenewalIncreaseType>>;
  not?: Maybe<NestedEnumOriginalRevenueRenewalIncreaseTypeNullableFilter>;
  notIn?: Maybe<Array<OriginalRevenueRenewalIncreaseType>>;
};

export type NestedEnumPayrollActualCompensationItemSourceNullableFilter = {
  equals?: Maybe<PayrollActualCompensationItemSource>;
  in?: Maybe<Array<PayrollActualCompensationItemSource>>;
  not?: Maybe<NestedEnumPayrollActualCompensationItemSourceNullableFilter>;
  notIn?: Maybe<Array<PayrollActualCompensationItemSource>>;
};

export type NestedEnumPayrollBonusFrequencyFilter = {
  equals?: Maybe<PayrollBonusFrequency>;
  in?: Maybe<Array<PayrollBonusFrequency>>;
  not?: Maybe<NestedEnumPayrollBonusFrequencyFilter>;
  notIn?: Maybe<Array<PayrollBonusFrequency>>;
};

export type NestedEnumPayrollBonusTypeFilter = {
  equals?: Maybe<PayrollBonusType>;
  in?: Maybe<Array<PayrollBonusType>>;
  not?: Maybe<NestedEnumPayrollBonusTypeFilter>;
  notIn?: Maybe<Array<PayrollBonusType>>;
};

export type NestedEnumPayrollCompensationItemCalculationTypeFilter = {
  equals?: Maybe<PayrollCompensationItemCalculationType>;
  in?: Maybe<Array<PayrollCompensationItemCalculationType>>;
  not?: Maybe<NestedEnumPayrollCompensationItemCalculationTypeFilter>;
  notIn?: Maybe<Array<PayrollCompensationItemCalculationType>>;
};

export type NestedEnumPayrollCompensationItemTypeFilter = {
  equals?: Maybe<PayrollCompensationItemType>;
  in?: Maybe<Array<PayrollCompensationItemType>>;
  not?: Maybe<NestedEnumPayrollCompensationItemTypeFilter>;
  notIn?: Maybe<Array<PayrollCompensationItemType>>;
};

export type NestedEnumPayrollCompensationTypeFilter = {
  equals?: Maybe<PayrollCompensationType>;
  in?: Maybe<Array<PayrollCompensationType>>;
  not?: Maybe<NestedEnumPayrollCompensationTypeFilter>;
  notIn?: Maybe<Array<PayrollCompensationType>>;
};

export type NestedEnumPayrollDefaultLevelFilter = {
  equals?: Maybe<PayrollDefaultLevel>;
  in?: Maybe<Array<PayrollDefaultLevel>>;
  not?: Maybe<NestedEnumPayrollDefaultLevelFilter>;
  notIn?: Maybe<Array<PayrollDefaultLevel>>;
};

export type NestedEnumPayrollEmployeeClassificationFilter = {
  equals?: Maybe<PayrollEmployeeClassification>;
  in?: Maybe<Array<PayrollEmployeeClassification>>;
  not?: Maybe<NestedEnumPayrollEmployeeClassificationFilter>;
  notIn?: Maybe<Array<PayrollEmployeeClassification>>;
};

export type NestedEnumPayrollModeledEmployeeTypeFilter = {
  equals?: Maybe<PayrollModeledEmployeeType>;
  in?: Maybe<Array<PayrollModeledEmployeeType>>;
  not?: Maybe<NestedEnumPayrollModeledEmployeeTypeFilter>;
  notIn?: Maybe<Array<PayrollModeledEmployeeType>>;
};

export type NestedEnumPayrollPayFrequencyFilter = {
  equals?: Maybe<PayrollPayFrequency>;
  in?: Maybe<Array<PayrollPayFrequency>>;
  not?: Maybe<NestedEnumPayrollPayFrequencyFilter>;
  notIn?: Maybe<Array<PayrollPayFrequency>>;
};

export type NestedEnumPayrollPrimaryCompensationItemFilter = {
  equals?: Maybe<PayrollPrimaryCompensationItem>;
  in?: Maybe<Array<PayrollPrimaryCompensationItem>>;
  not?: Maybe<NestedEnumPayrollPrimaryCompensationItemFilter>;
  notIn?: Maybe<Array<PayrollPrimaryCompensationItem>>;
};

export type NestedEnumPayrollRaiseFrequencyFilter = {
  equals?: Maybe<PayrollRaiseFrequency>;
  in?: Maybe<Array<PayrollRaiseFrequency>>;
  not?: Maybe<NestedEnumPayrollRaiseFrequencyFilter>;
  notIn?: Maybe<Array<PayrollRaiseFrequency>>;
};

export type NestedEnumPayrollRaiseTypeFilter = {
  equals?: Maybe<PayrollRaiseType>;
  in?: Maybe<Array<PayrollRaiseType>>;
  not?: Maybe<NestedEnumPayrollRaiseTypeFilter>;
  notIn?: Maybe<Array<PayrollRaiseType>>;
};

export type NestedEnumPercentGrowthBasePeriodNullableFilter = {
  equals?: Maybe<PercentGrowthBasePeriod>;
  in?: Maybe<Array<PercentGrowthBasePeriod>>;
  not?: Maybe<NestedEnumPercentGrowthBasePeriodNullableFilter>;
  notIn?: Maybe<Array<PercentGrowthBasePeriod>>;
};

export type NestedEnumPropertyManagementSystemNullableFilter = {
  equals?: Maybe<PropertyManagementSystem>;
  in?: Maybe<Array<PropertyManagementSystem>>;
  not?: Maybe<NestedEnumPropertyManagementSystemNullableFilter>;
  notIn?: Maybe<Array<PropertyManagementSystem>>;
};

export type NestedEnumPropertyManagementSystemNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedEnumPropertyManagementSystemNullableFilter>;
  _min?: Maybe<NestedEnumPropertyManagementSystemNullableFilter>;
  equals?: Maybe<PropertyManagementSystem>;
  in?: Maybe<Array<PropertyManagementSystem>>;
  not?: Maybe<NestedEnumPropertyManagementSystemNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<PropertyManagementSystem>>;
};

export type NestedEnumRevenueModelTypeFilter = {
  equals?: Maybe<RevenueModelType>;
  in?: Maybe<Array<RevenueModelType>>;
  not?: Maybe<NestedEnumRevenueModelTypeFilter>;
  notIn?: Maybe<Array<RevenueModelType>>;
};

export type NestedEnumRevenueTypeFilter = {
  equals?: Maybe<RevenueType>;
  in?: Maybe<Array<RevenueType>>;
  not?: Maybe<NestedEnumRevenueTypeFilter>;
  notIn?: Maybe<Array<RevenueType>>;
};

export type NestedEnumTopCardTagTypeFilter = {
  equals?: Maybe<TopCardTagType>;
  in?: Maybe<Array<TopCardTagType>>;
  not?: Maybe<NestedEnumTopCardTagTypeFilter>;
  notIn?: Maybe<Array<TopCardTagType>>;
};

export type NestedEnumUnitEventTypeFilter = {
  equals?: Maybe<UnitEventType>;
  in?: Maybe<Array<UnitEventType>>;
  not?: Maybe<NestedEnumUnitEventTypeFilter>;
  notIn?: Maybe<Array<UnitEventType>>;
};

export type NestedEnumUserRoleFilter = {
  equals?: Maybe<UserRole>;
  in?: Maybe<Array<UserRole>>;
  not?: Maybe<NestedEnumUserRoleFilter>;
  notIn?: Maybe<Array<UserRole>>;
};

export type NestedEnumVersionTypeFilter = {
  equals?: Maybe<VersionType>;
  in?: Maybe<Array<VersionType>>;
  not?: Maybe<NestedEnumVersionTypeFilter>;
  notIn?: Maybe<Array<VersionType>>;
};

export type NestedEnumVersionTypeWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedEnumVersionTypeFilter>;
  _min?: Maybe<NestedEnumVersionTypeFilter>;
  equals?: Maybe<VersionType>;
  in?: Maybe<Array<VersionType>>;
  not?: Maybe<NestedEnumVersionTypeWithAggregatesFilter>;
  notIn?: Maybe<Array<VersionType>>;
};

export type NestedFloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableWithAggregatesFilter = {
  _avg?: Maybe<NestedFloatNullableFilter>;
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedIntNullableFilter>;
  _min?: Maybe<NestedIntNullableFilter>;
  _sum?: Maybe<NestedIntNullableFilter>;
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: Maybe<NestedFloatFilter>;
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedIntFilter>;
  _min?: Maybe<NestedIntFilter>;
  _sum?: Maybe<NestedIntFilter>;
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedStringNullableFilter>;
  _min?: Maybe<NestedStringNullableFilter>;
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedStringFilter>;
  _min?: Maybe<NestedStringFilter>;
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type Note = {
  __typename?: 'Note';
  createdAt: Scalars['DateTime'];
  entityId: Scalars['String'];
  entityType: EntityType;
  id: Scalars['String'];
  propertyId: Scalars['String'];
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  versionType: VersionType;
};

export type NoteCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId: Scalars['String'];
  entityType: EntityType;
  id?: Maybe<Scalars['String']>;
  property: PropertyCreateNestedOneWithoutNoteInput;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
};

export type NoteCreateManyPropertyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId: Scalars['String'];
  entityType: EntityType;
  id?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
};

export type NoteCreateManyPropertyInputEnvelope = {
  data: Array<NoteCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type NoteCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<NoteWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<NoteCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<NoteCreateWithoutPropertyInput>>;
  createMany?: Maybe<NoteCreateManyPropertyInputEnvelope>;
};

export type NoteCreateOrConnectWithoutPropertyInput = {
  create: NoteCreateWithoutPropertyInput;
  where: NoteWhereUniqueInput;
};

export type NoteCreateWithoutPropertyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId: Scalars['String'];
  entityType: EntityType;
  id?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
};

export type NoteEntityIdEntityTypeVersionTypePropertyIdCompoundUniqueInput = {
  entityId: Scalars['String'];
  entityType: EntityType;
  propertyId: Scalars['String'];
  versionType: VersionType;
};

export type NoteListRelationFilter = {
  every?: Maybe<NoteWhereInput>;
  none?: Maybe<NoteWhereInput>;
  some?: Maybe<NoteWhereInput>;
};

export type NoteModel = {
  __typename?: 'NoteModel';
  entityId: Scalars['ID'];
  entityType: EntityType;
  propertyId: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type NoteOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  entityId?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  text?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  versionType?: Maybe<SortOrder>;
};

export enum NoteScalarFieldEnum {
  CreatedAt = 'createdAt',
  EntityId = 'entityId',
  EntityType = 'entityType',
  Id = 'id',
  PropertyId = 'propertyId',
  Text = 'text',
  UpdatedAt = 'updatedAt',
  VersionType = 'versionType'
}

export type NoteScalarWhereInput = {
  AND?: Maybe<Array<NoteScalarWhereInput>>;
  NOT?: Maybe<Array<NoteScalarWhereInput>>;
  OR?: Maybe<Array<NoteScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  entityId?: Maybe<StringFilter>;
  entityType?: Maybe<EnumEntityTypeFilter>;
  id?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  text?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
};

export type NoteUpdateInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  entityId?: Maybe<StringFieldUpdateOperationsInput>;
  entityType?: Maybe<EnumEntityTypeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutNoteInput>;
  text?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type NoteUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  entityId?: Maybe<StringFieldUpdateOperationsInput>;
  entityType?: Maybe<EnumEntityTypeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  text?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type NoteUpdateManyWithWhereWithoutPropertyInput = {
  data: NoteUpdateManyMutationInput;
  where: NoteScalarWhereInput;
};

export type NoteUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<NoteWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<NoteCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<NoteCreateWithoutPropertyInput>>;
  createMany?: Maybe<NoteCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<NoteWhereUniqueInput>>;
  deleteMany?: Maybe<Array<NoteScalarWhereInput>>;
  disconnect?: Maybe<Array<NoteWhereUniqueInput>>;
  set?: Maybe<Array<NoteWhereUniqueInput>>;
  update?: Maybe<Array<NoteUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<NoteUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<NoteUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type NoteUpdateWithWhereUniqueWithoutPropertyInput = {
  data: NoteUpdateWithoutPropertyInput;
  where: NoteWhereUniqueInput;
};

export type NoteUpdateWithoutPropertyInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  entityId?: Maybe<StringFieldUpdateOperationsInput>;
  entityType?: Maybe<EnumEntityTypeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  text?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type NoteUpsertWithWhereUniqueWithoutPropertyInput = {
  create: NoteCreateWithoutPropertyInput;
  update: NoteUpdateWithoutPropertyInput;
  where: NoteWhereUniqueInput;
};

export type NoteWhereInput = {
  AND?: Maybe<Array<NoteWhereInput>>;
  NOT?: Maybe<Array<NoteWhereInput>>;
  OR?: Maybe<Array<NoteWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  entityId?: Maybe<StringFilter>;
  entityType?: Maybe<EnumEntityTypeFilter>;
  id?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  text?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
};

export type NoteWhereUniqueInput = {
  entityId_entityType_versionType_propertyId?: Maybe<NoteEntityIdEntityTypeVersionTypePropertyIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type NullableDecimalFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Decimal']>;
  divide?: Maybe<Scalars['Decimal']>;
  increment?: Maybe<Scalars['Decimal']>;
  multiply?: Maybe<Scalars['Decimal']>;
  set?: Maybe<Scalars['Decimal']>;
};

export type NullableEnumDistributionMethodFieldUpdateOperationsInput = {
  set?: Maybe<DistributionMethod>;
};

export type NullableEnumMonthlyAdjustmentTypeFieldUpdateOperationsInput = {
  set?: Maybe<MonthlyAdjustmentType>;
};

export type NullableEnumMonthlyAverageBasePeriodFieldUpdateOperationsInput = {
  set?: Maybe<MonthlyAverageBasePeriod>;
};

export type NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput = {
  set?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
};

export type NullableEnumOriginalRevenueRenewalIncreaseTypeFieldUpdateOperationsInput = {
  set?: Maybe<OriginalRevenueRenewalIncreaseType>;
};

export type NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput = {
  set?: Maybe<PayrollActualCompensationItemSource>;
};

export type NullableEnumPercentGrowthBasePeriodFieldUpdateOperationsInput = {
  set?: Maybe<PercentGrowthBasePeriod>;
};

export type NullableEnumPropertyManagementSystemFieldUpdateOperationsInput = {
  set?: Maybe<PropertyManagementSystem>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  set?: Maybe<Scalars['Int']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export enum OperationType {
  Lock = 'LOCK',
  Read = 'READ',
  Write = 'WRITE'
}

export type OperationalDriverAssumptionViewModel = {
  __typename?: 'OperationalDriverAssumptionViewModel';
  amountMonth0?: Maybe<Scalars['Float']>;
  amountMonth1?: Maybe<Scalars['Float']>;
  amountMonth2?: Maybe<Scalars['Float']>;
  amountMonth3?: Maybe<Scalars['Float']>;
  amountMonth4?: Maybe<Scalars['Float']>;
  amountMonth5?: Maybe<Scalars['Float']>;
  amountMonth6?: Maybe<Scalars['Float']>;
  amountMonth7?: Maybe<Scalars['Float']>;
  amountMonth8?: Maybe<Scalars['Float']>;
  amountMonth9?: Maybe<Scalars['Float']>;
  amountMonth10?: Maybe<Scalars['Float']>;
  amountMonth11?: Maybe<Scalars['Float']>;
  delayRecognition: Scalars['Int'];
  destinationAccountId: Scalars['String'];
  percentMonth0?: Maybe<Scalars['Float']>;
  percentMonth1?: Maybe<Scalars['Float']>;
  percentMonth2?: Maybe<Scalars['Float']>;
  percentMonth3?: Maybe<Scalars['Float']>;
  percentMonth4?: Maybe<Scalars['Float']>;
  percentMonth5?: Maybe<Scalars['Float']>;
  percentMonth6?: Maybe<Scalars['Float']>;
  percentMonth7?: Maybe<Scalars['Float']>;
  percentMonth8?: Maybe<Scalars['Float']>;
  percentMonth9?: Maybe<Scalars['Float']>;
  percentMonth10?: Maybe<Scalars['Float']>;
  percentMonth11?: Maybe<Scalars['Float']>;
  propertyId: Scalars['String'];
  sourceMetricId: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Float'];
};

export type OperationalDriverAssumptionsUpdateInput = {
  amountMonth0?: Maybe<Scalars['Float']>;
  amountMonth1?: Maybe<Scalars['Float']>;
  amountMonth2?: Maybe<Scalars['Float']>;
  amountMonth3?: Maybe<Scalars['Float']>;
  amountMonth4?: Maybe<Scalars['Float']>;
  amountMonth5?: Maybe<Scalars['Float']>;
  amountMonth6?: Maybe<Scalars['Float']>;
  amountMonth7?: Maybe<Scalars['Float']>;
  amountMonth8?: Maybe<Scalars['Float']>;
  amountMonth9?: Maybe<Scalars['Float']>;
  amountMonth10?: Maybe<Scalars['Float']>;
  amountMonth11?: Maybe<Scalars['Float']>;
  destinationAccountId: Scalars['String'];
  percentMonth0?: Maybe<Scalars['Float']>;
  percentMonth1?: Maybe<Scalars['Float']>;
  percentMonth2?: Maybe<Scalars['Float']>;
  percentMonth3?: Maybe<Scalars['Float']>;
  percentMonth4?: Maybe<Scalars['Float']>;
  percentMonth5?: Maybe<Scalars['Float']>;
  percentMonth6?: Maybe<Scalars['Float']>;
  percentMonth7?: Maybe<Scalars['Float']>;
  percentMonth8?: Maybe<Scalars['Float']>;
  percentMonth9?: Maybe<Scalars['Float']>;
  percentMonth10?: Maybe<Scalars['Float']>;
  percentMonth11?: Maybe<Scalars['Float']>;
  propertyId: Scalars['String'];
  sourceMetricId: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Float'];
};

export type OperationalDriverMetricTypeCreateManyDriverInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  operationalMetricTypeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OperationalDriverMetricTypeCreateManyDriverInputEnvelope = {
  data: Array<OperationalDriverMetricTypeCreateManyDriverInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OperationalDriverMetricTypeCreateManyOperationalMetricTypeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  driverId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OperationalDriverMetricTypeCreateManyOperationalMetricTypeInputEnvelope = {
  data: Array<OperationalDriverMetricTypeCreateManyOperationalMetricTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OperationalDriverMetricTypeCreateNestedManyWithoutDriverInput = {
  connect?: Maybe<Array<OperationalDriverMetricTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OperationalDriverMetricTypeCreateOrConnectWithoutDriverInput>>;
  create?: Maybe<Array<OperationalDriverMetricTypeCreateWithoutDriverInput>>;
  createMany?: Maybe<OperationalDriverMetricTypeCreateManyDriverInputEnvelope>;
};

export type OperationalDriverMetricTypeCreateNestedManyWithoutOperationalMetricTypeInput = {
  connect?: Maybe<Array<OperationalDriverMetricTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OperationalDriverMetricTypeCreateOrConnectWithoutOperationalMetricTypeInput>>;
  create?: Maybe<Array<OperationalDriverMetricTypeCreateWithoutOperationalMetricTypeInput>>;
  createMany?: Maybe<OperationalDriverMetricTypeCreateManyOperationalMetricTypeInputEnvelope>;
};

export type OperationalDriverMetricTypeCreateNestedOneWithoutOperationalMetricAssumptionsInput = {
  connect?: Maybe<OperationalDriverMetricTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<OperationalDriverMetricTypeCreateOrConnectWithoutOperationalMetricAssumptionsInput>;
  create?: Maybe<OperationalDriverMetricTypeCreateWithoutOperationalMetricAssumptionsInput>;
};

export type OperationalDriverMetricTypeCreateOrConnectWithoutDriverInput = {
  create: OperationalDriverMetricTypeCreateWithoutDriverInput;
  where: OperationalDriverMetricTypeWhereUniqueInput;
};

export type OperationalDriverMetricTypeCreateOrConnectWithoutOperationalMetricAssumptionsInput = {
  create: OperationalDriverMetricTypeCreateWithoutOperationalMetricAssumptionsInput;
  where: OperationalDriverMetricTypeWhereUniqueInput;
};

export type OperationalDriverMetricTypeCreateOrConnectWithoutOperationalMetricTypeInput = {
  create: OperationalDriverMetricTypeCreateWithoutOperationalMetricTypeInput;
  where: OperationalDriverMetricTypeWhereUniqueInput;
};

export type OperationalDriverMetricTypeCreateWithoutDriverInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutOperationalDriverMetricTypeInput>;
  operationalMetricType: OperationalMetricTypeCreateNestedOneWithoutOperationalDriverMetricTypesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OperationalDriverMetricTypeCreateWithoutOperationalMetricAssumptionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  driver: DriverCreateNestedOneWithoutOperationalDriverMetricTypesInput;
  id?: Maybe<Scalars['String']>;
  operationalMetricType: OperationalMetricTypeCreateNestedOneWithoutOperationalDriverMetricTypesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OperationalDriverMetricTypeCreateWithoutOperationalMetricTypeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  driver: DriverCreateNestedOneWithoutOperationalDriverMetricTypesInput;
  id?: Maybe<Scalars['String']>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutOperationalDriverMetricTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OperationalDriverMetricTypeDriverIdOperationalMetricTypeIdCompoundUniqueInput = {
  driverId: Scalars['String'];
  operationalMetricTypeId: Scalars['String'];
};

export type OperationalDriverMetricTypeListRelationFilter = {
  every?: Maybe<OperationalDriverMetricTypeWhereInput>;
  none?: Maybe<OperationalDriverMetricTypeWhereInput>;
  some?: Maybe<OperationalDriverMetricTypeWhereInput>;
};

export type OperationalDriverMetricTypeRelationFilter = {
  is?: Maybe<OperationalDriverMetricTypeWhereInput>;
  isNot?: Maybe<OperationalDriverMetricTypeWhereInput>;
};

export type OperationalDriverMetricTypeScalarWhereInput = {
  AND?: Maybe<Array<OperationalDriverMetricTypeScalarWhereInput>>;
  NOT?: Maybe<Array<OperationalDriverMetricTypeScalarWhereInput>>;
  OR?: Maybe<Array<OperationalDriverMetricTypeScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  driverId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  operationalMetricTypeId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OperationalDriverMetricTypeUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperationalDriverMetricTypeUpdateManyWithWhereWithoutDriverInput = {
  data: OperationalDriverMetricTypeUpdateManyMutationInput;
  where: OperationalDriverMetricTypeScalarWhereInput;
};

export type OperationalDriverMetricTypeUpdateManyWithWhereWithoutOperationalMetricTypeInput = {
  data: OperationalDriverMetricTypeUpdateManyMutationInput;
  where: OperationalDriverMetricTypeScalarWhereInput;
};

export type OperationalDriverMetricTypeUpdateManyWithoutDriverInput = {
  connect?: Maybe<Array<OperationalDriverMetricTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OperationalDriverMetricTypeCreateOrConnectWithoutDriverInput>>;
  create?: Maybe<Array<OperationalDriverMetricTypeCreateWithoutDriverInput>>;
  createMany?: Maybe<OperationalDriverMetricTypeCreateManyDriverInputEnvelope>;
  delete?: Maybe<Array<OperationalDriverMetricTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OperationalDriverMetricTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<OperationalDriverMetricTypeWhereUniqueInput>>;
  set?: Maybe<Array<OperationalDriverMetricTypeWhereUniqueInput>>;
  update?: Maybe<Array<OperationalDriverMetricTypeUpdateWithWhereUniqueWithoutDriverInput>>;
  updateMany?: Maybe<Array<OperationalDriverMetricTypeUpdateManyWithWhereWithoutDriverInput>>;
  upsert?: Maybe<Array<OperationalDriverMetricTypeUpsertWithWhereUniqueWithoutDriverInput>>;
};

export type OperationalDriverMetricTypeUpdateManyWithoutOperationalMetricTypeInput = {
  connect?: Maybe<Array<OperationalDriverMetricTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OperationalDriverMetricTypeCreateOrConnectWithoutOperationalMetricTypeInput>>;
  create?: Maybe<Array<OperationalDriverMetricTypeCreateWithoutOperationalMetricTypeInput>>;
  createMany?: Maybe<OperationalDriverMetricTypeCreateManyOperationalMetricTypeInputEnvelope>;
  delete?: Maybe<Array<OperationalDriverMetricTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OperationalDriverMetricTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<OperationalDriverMetricTypeWhereUniqueInput>>;
  set?: Maybe<Array<OperationalDriverMetricTypeWhereUniqueInput>>;
  update?: Maybe<Array<OperationalDriverMetricTypeUpdateWithWhereUniqueWithoutOperationalMetricTypeInput>>;
  updateMany?: Maybe<Array<OperationalDriverMetricTypeUpdateManyWithWhereWithoutOperationalMetricTypeInput>>;
  upsert?: Maybe<Array<OperationalDriverMetricTypeUpsertWithWhereUniqueWithoutOperationalMetricTypeInput>>;
};

export type OperationalDriverMetricTypeUpdateOneRequiredWithoutOperationalMetricAssumptionsInput = {
  connect?: Maybe<OperationalDriverMetricTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<OperationalDriverMetricTypeCreateOrConnectWithoutOperationalMetricAssumptionsInput>;
  create?: Maybe<OperationalDriverMetricTypeCreateWithoutOperationalMetricAssumptionsInput>;
  update?: Maybe<OperationalDriverMetricTypeUpdateWithoutOperationalMetricAssumptionsInput>;
  upsert?: Maybe<OperationalDriverMetricTypeUpsertWithoutOperationalMetricAssumptionsInput>;
};

export type OperationalDriverMetricTypeUpdateWithWhereUniqueWithoutDriverInput = {
  data: OperationalDriverMetricTypeUpdateWithoutDriverInput;
  where: OperationalDriverMetricTypeWhereUniqueInput;
};

export type OperationalDriverMetricTypeUpdateWithWhereUniqueWithoutOperationalMetricTypeInput = {
  data: OperationalDriverMetricTypeUpdateWithoutOperationalMetricTypeInput;
  where: OperationalDriverMetricTypeWhereUniqueInput;
};

export type OperationalDriverMetricTypeUpdateWithoutDriverInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutOperationalDriverMetricTypeInput>;
  operationalMetricType?: Maybe<OperationalMetricTypeUpdateOneRequiredWithoutOperationalDriverMetricTypesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperationalDriverMetricTypeUpdateWithoutOperationalMetricAssumptionsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  driver?: Maybe<DriverUpdateOneRequiredWithoutOperationalDriverMetricTypesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  operationalMetricType?: Maybe<OperationalMetricTypeUpdateOneRequiredWithoutOperationalDriverMetricTypesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperationalDriverMetricTypeUpdateWithoutOperationalMetricTypeInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  driver?: Maybe<DriverUpdateOneRequiredWithoutOperationalDriverMetricTypesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutOperationalDriverMetricTypeInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperationalDriverMetricTypeUpsertWithWhereUniqueWithoutDriverInput = {
  create: OperationalDriverMetricTypeCreateWithoutDriverInput;
  update: OperationalDriverMetricTypeUpdateWithoutDriverInput;
  where: OperationalDriverMetricTypeWhereUniqueInput;
};

export type OperationalDriverMetricTypeUpsertWithWhereUniqueWithoutOperationalMetricTypeInput = {
  create: OperationalDriverMetricTypeCreateWithoutOperationalMetricTypeInput;
  update: OperationalDriverMetricTypeUpdateWithoutOperationalMetricTypeInput;
  where: OperationalDriverMetricTypeWhereUniqueInput;
};

export type OperationalDriverMetricTypeUpsertWithoutOperationalMetricAssumptionsInput = {
  create: OperationalDriverMetricTypeCreateWithoutOperationalMetricAssumptionsInput;
  update: OperationalDriverMetricTypeUpdateWithoutOperationalMetricAssumptionsInput;
};

export type OperationalDriverMetricTypeWhereInput = {
  AND?: Maybe<Array<OperationalDriverMetricTypeWhereInput>>;
  NOT?: Maybe<Array<OperationalDriverMetricTypeWhereInput>>;
  OR?: Maybe<Array<OperationalDriverMetricTypeWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  driver?: Maybe<DriverRelationFilter>;
  driverId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionListRelationFilter>;
  operationalMetricType?: Maybe<OperationalMetricTypeRelationFilter>;
  operationalMetricTypeId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OperationalDriverMetricTypeWhereUniqueInput = {
  driverId_operationalMetricTypeId?: Maybe<OperationalDriverMetricTypeDriverIdOperationalMetricTypeIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type OperationalDriverUpdateInput = {
  amount?: Maybe<Scalars['Decimal']>;
  metricId?: Maybe<Scalars['ID']>;
  monthsAgo?: Maybe<Scalars['Float']>;
  opDriverMetricTypeId: Scalars['ID'];
  percentOf?: Maybe<Scalars['Decimal']>;
};

export enum OperationalFinancialMetricsAggregateType {
  Account = 'ACCOUNT',
  Category = 'CATEGORY'
}

export type OperationalMetric = {
  __typename?: 'OperationalMetric';
  averageMarketRent: Scalars['Decimal'];
  averageResidentialRent: Scalars['Decimal'];
  averageSquareFeet: Scalars['Decimal'];
  budgetYear: Scalars['Int'];
  combinedTradeOut: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  earlyTerminationCount: Scalars['Int'];
  id: Scalars['String'];
  leaseExpirationCount: Scalars['Int'];
  lossToLease: Scalars['Decimal'];
  monthIndex: Scalars['Int'];
  monthToMonthCount: Scalars['Int'];
  monthToMonthMoveOutCount: Scalars['Int'];
  moveInCount: Scalars['Decimal'];
  moveOutCount: Scalars['Decimal'];
  moveOutRate: Scalars['Decimal'];
  newLeaseRent?: Maybe<Scalars['Decimal']>;
  newLeaseRentCount?: Maybe<Scalars['Int']>;
  newLeaseTradeOut: Scalars['Decimal'];
  occupancyRate: Scalars['Decimal'];
  occupiedUnits: Scalars['Decimal'];
  propertyId: Scalars['String'];
  renewalCount: Scalars['Decimal'];
  renewalRate: Scalars['Decimal'];
  renewalRatio: Scalars['Decimal'];
  renewalRent?: Maybe<Scalars['Decimal']>;
  renewalRentCount?: Maybe<Scalars['Int']>;
  totalMoveOutCount: Scalars['Decimal'];
  unitCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  versionId: Scalars['String'];
};

export type OperationalMetricAssumption = {
  __typename?: 'OperationalMetricAssumption';
  amount?: Maybe<Scalars['Decimal']>;
  createdAt: Scalars['DateTime'];
  delayRecognition: Scalars['Int'];
  effectiveEndMonthIndex: Scalars['Int'];
  effectiveEndYear: Scalars['Int'];
  effectiveStartMonthIndex: Scalars['Int'];
  effectiveStartYear: Scalars['Int'];
  id: Scalars['String'];
  locked: Scalars['Boolean'];
  operationalDriverMetricTypeId: Scalars['String'];
  percentOf?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OperationalMetricAssumptionCreateManyOperationalDriverMetricTypeInput = {
  amount?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition?: Maybe<Scalars['Int']>;
  effectiveEndMonthIndex: Scalars['Int'];
  effectiveEndYear: Scalars['Int'];
  effectiveStartMonthIndex: Scalars['Int'];
  effectiveStartYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  percentOf?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OperationalMetricAssumptionCreateManyOperationalDriverMetricTypeInputEnvelope = {
  data: Array<OperationalMetricAssumptionCreateManyOperationalDriverMetricTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OperationalMetricAssumptionCreateManyPropertyInput = {
  amount?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition?: Maybe<Scalars['Int']>;
  effectiveEndMonthIndex: Scalars['Int'];
  effectiveEndYear: Scalars['Int'];
  effectiveStartMonthIndex: Scalars['Int'];
  effectiveStartYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  operationalDriverMetricTypeId: Scalars['String'];
  percentOf?: Maybe<Scalars['Decimal']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OperationalMetricAssumptionCreateManyPropertyInputEnvelope = {
  data: Array<OperationalMetricAssumptionCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OperationalMetricAssumptionCreateNestedManyWithoutOperationalDriverMetricTypeInput = {
  connect?: Maybe<Array<OperationalMetricAssumptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OperationalMetricAssumptionCreateOrConnectWithoutOperationalDriverMetricTypeInput>>;
  create?: Maybe<Array<OperationalMetricAssumptionCreateWithoutOperationalDriverMetricTypeInput>>;
  createMany?: Maybe<OperationalMetricAssumptionCreateManyOperationalDriverMetricTypeInputEnvelope>;
};

export type OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<OperationalMetricAssumptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OperationalMetricAssumptionCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<OperationalMetricAssumptionCreateWithoutPropertyInput>>;
  createMany?: Maybe<OperationalMetricAssumptionCreateManyPropertyInputEnvelope>;
};

export type OperationalMetricAssumptionCreateOrConnectWithoutOperationalDriverMetricTypeInput = {
  create: OperationalMetricAssumptionCreateWithoutOperationalDriverMetricTypeInput;
  where: OperationalMetricAssumptionWhereUniqueInput;
};

export type OperationalMetricAssumptionCreateOrConnectWithoutPropertyInput = {
  create: OperationalMetricAssumptionCreateWithoutPropertyInput;
  where: OperationalMetricAssumptionWhereUniqueInput;
};

export type OperationalMetricAssumptionCreateWithoutOperationalDriverMetricTypeInput = {
  amount?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition?: Maybe<Scalars['Int']>;
  effectiveEndMonthIndex: Scalars['Int'];
  effectiveEndYear: Scalars['Int'];
  effectiveStartMonthIndex: Scalars['Int'];
  effectiveStartYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  percentOf?: Maybe<Scalars['Decimal']>;
  property: PropertyCreateNestedOneWithoutOperationalMetricAssumptionsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OperationalMetricAssumptionCreateWithoutPropertyInput = {
  amount?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition?: Maybe<Scalars['Int']>;
  effectiveEndMonthIndex: Scalars['Int'];
  effectiveEndYear: Scalars['Int'];
  effectiveStartMonthIndex: Scalars['Int'];
  effectiveStartYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  operationalDriverMetricType: OperationalDriverMetricTypeCreateNestedOneWithoutOperationalMetricAssumptionsInput;
  percentOf?: Maybe<Scalars['Decimal']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OperationalMetricAssumptionListRelationFilter = {
  every?: Maybe<OperationalMetricAssumptionWhereInput>;
  none?: Maybe<OperationalMetricAssumptionWhereInput>;
  some?: Maybe<OperationalMetricAssumptionWhereInput>;
};

export type OperationalMetricAssumptionOperationalDriverMetricTypeIdEffectiveEndYearPropertyIdCompoundUniqueInput = {
  effectiveEndYear: Scalars['Int'];
  operationalDriverMetricTypeId: Scalars['String'];
  propertyId: Scalars['String'];
};

export type OperationalMetricAssumptionOrderByInput = {
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  delayRecognition?: Maybe<SortOrder>;
  effectiveEndMonthIndex?: Maybe<SortOrder>;
  effectiveEndYear?: Maybe<SortOrder>;
  effectiveStartMonthIndex?: Maybe<SortOrder>;
  effectiveStartYear?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  locked?: Maybe<SortOrder>;
  operationalDriverMetricTypeId?: Maybe<SortOrder>;
  percentOf?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum OperationalMetricAssumptionScalarFieldEnum {
  Amount = 'amount',
  CreatedAt = 'createdAt',
  DelayRecognition = 'delayRecognition',
  EffectiveEndMonthIndex = 'effectiveEndMonthIndex',
  EffectiveEndYear = 'effectiveEndYear',
  EffectiveStartMonthIndex = 'effectiveStartMonthIndex',
  EffectiveStartYear = 'effectiveStartYear',
  Id = 'id',
  Locked = 'locked',
  OperationalDriverMetricTypeId = 'operationalDriverMetricTypeId',
  PercentOf = 'percentOf',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt'
}

export type OperationalMetricAssumptionScalarWhereInput = {
  AND?: Maybe<Array<OperationalMetricAssumptionScalarWhereInput>>;
  NOT?: Maybe<Array<OperationalMetricAssumptionScalarWhereInput>>;
  OR?: Maybe<Array<OperationalMetricAssumptionScalarWhereInput>>;
  amount?: Maybe<DecimalNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  delayRecognition?: Maybe<IntFilter>;
  effectiveEndMonthIndex?: Maybe<IntFilter>;
  effectiveEndYear?: Maybe<IntFilter>;
  effectiveStartMonthIndex?: Maybe<IntFilter>;
  effectiveStartYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  locked?: Maybe<BoolFilter>;
  operationalDriverMetricTypeId?: Maybe<StringFilter>;
  percentOf?: Maybe<DecimalNullableFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OperationalMetricAssumptionUpdateManyMutationInput = {
  amount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveEndMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveEndYear?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveStartYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  locked?: Maybe<BoolFieldUpdateOperationsInput>;
  percentOf?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperationalMetricAssumptionUpdateManyWithWhereWithoutOperationalDriverMetricTypeInput = {
  data: OperationalMetricAssumptionUpdateManyMutationInput;
  where: OperationalMetricAssumptionScalarWhereInput;
};

export type OperationalMetricAssumptionUpdateManyWithWhereWithoutPropertyInput = {
  data: OperationalMetricAssumptionUpdateManyMutationInput;
  where: OperationalMetricAssumptionScalarWhereInput;
};

export type OperationalMetricAssumptionUpdateManyWithoutOperationalDriverMetricTypeInput = {
  connect?: Maybe<Array<OperationalMetricAssumptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OperationalMetricAssumptionCreateOrConnectWithoutOperationalDriverMetricTypeInput>>;
  create?: Maybe<Array<OperationalMetricAssumptionCreateWithoutOperationalDriverMetricTypeInput>>;
  createMany?: Maybe<OperationalMetricAssumptionCreateManyOperationalDriverMetricTypeInputEnvelope>;
  delete?: Maybe<Array<OperationalMetricAssumptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OperationalMetricAssumptionScalarWhereInput>>;
  disconnect?: Maybe<Array<OperationalMetricAssumptionWhereUniqueInput>>;
  set?: Maybe<Array<OperationalMetricAssumptionWhereUniqueInput>>;
  update?: Maybe<Array<OperationalMetricAssumptionUpdateWithWhereUniqueWithoutOperationalDriverMetricTypeInput>>;
  updateMany?: Maybe<Array<OperationalMetricAssumptionUpdateManyWithWhereWithoutOperationalDriverMetricTypeInput>>;
  upsert?: Maybe<Array<OperationalMetricAssumptionUpsertWithWhereUniqueWithoutOperationalDriverMetricTypeInput>>;
};

export type OperationalMetricAssumptionUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<OperationalMetricAssumptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OperationalMetricAssumptionCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<OperationalMetricAssumptionCreateWithoutPropertyInput>>;
  createMany?: Maybe<OperationalMetricAssumptionCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<OperationalMetricAssumptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OperationalMetricAssumptionScalarWhereInput>>;
  disconnect?: Maybe<Array<OperationalMetricAssumptionWhereUniqueInput>>;
  set?: Maybe<Array<OperationalMetricAssumptionWhereUniqueInput>>;
  update?: Maybe<Array<OperationalMetricAssumptionUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<OperationalMetricAssumptionUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<OperationalMetricAssumptionUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type OperationalMetricAssumptionUpdateWithWhereUniqueWithoutOperationalDriverMetricTypeInput = {
  data: OperationalMetricAssumptionUpdateWithoutOperationalDriverMetricTypeInput;
  where: OperationalMetricAssumptionWhereUniqueInput;
};

export type OperationalMetricAssumptionUpdateWithWhereUniqueWithoutPropertyInput = {
  data: OperationalMetricAssumptionUpdateWithoutPropertyInput;
  where: OperationalMetricAssumptionWhereUniqueInput;
};

export type OperationalMetricAssumptionUpdateWithoutOperationalDriverMetricTypeInput = {
  amount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveEndMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveEndYear?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveStartYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  locked?: Maybe<BoolFieldUpdateOperationsInput>;
  percentOf?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutOperationalMetricAssumptionsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperationalMetricAssumptionUpdateWithoutPropertyInput = {
  amount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveEndMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveEndYear?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveStartYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  locked?: Maybe<BoolFieldUpdateOperationsInput>;
  operationalDriverMetricType?: Maybe<OperationalDriverMetricTypeUpdateOneRequiredWithoutOperationalMetricAssumptionsInput>;
  percentOf?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperationalMetricAssumptionUpsertWithWhereUniqueWithoutOperationalDriverMetricTypeInput = {
  create: OperationalMetricAssumptionCreateWithoutOperationalDriverMetricTypeInput;
  update: OperationalMetricAssumptionUpdateWithoutOperationalDriverMetricTypeInput;
  where: OperationalMetricAssumptionWhereUniqueInput;
};

export type OperationalMetricAssumptionUpsertWithWhereUniqueWithoutPropertyInput = {
  create: OperationalMetricAssumptionCreateWithoutPropertyInput;
  update: OperationalMetricAssumptionUpdateWithoutPropertyInput;
  where: OperationalMetricAssumptionWhereUniqueInput;
};

export type OperationalMetricAssumptionWhereInput = {
  AND?: Maybe<Array<OperationalMetricAssumptionWhereInput>>;
  NOT?: Maybe<Array<OperationalMetricAssumptionWhereInput>>;
  OR?: Maybe<Array<OperationalMetricAssumptionWhereInput>>;
  amount?: Maybe<DecimalNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  delayRecognition?: Maybe<IntFilter>;
  effectiveEndMonthIndex?: Maybe<IntFilter>;
  effectiveEndYear?: Maybe<IntFilter>;
  effectiveStartMonthIndex?: Maybe<IntFilter>;
  effectiveStartYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  locked?: Maybe<BoolFilter>;
  operationalDriverMetricType?: Maybe<OperationalDriverMetricTypeRelationFilter>;
  operationalDriverMetricTypeId?: Maybe<StringFilter>;
  percentOf?: Maybe<DecimalNullableFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OperationalMetricAssumptionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  operationalDriverMetricTypeId_effectiveEndYear_propertyId?: Maybe<OperationalMetricAssumptionOperationalDriverMetricTypeIdEffectiveEndYearPropertyIdCompoundUniqueInput>;
};

export type OperationalMetricCreateManyPropertyInput = {
  averageMarketRent: Scalars['Decimal'];
  averageResidentialRent: Scalars['Decimal'];
  averageSquareFeet: Scalars['Decimal'];
  budgetYear: Scalars['Int'];
  combinedTradeOut?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earlyTerminationCount: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  leaseExpirationCount: Scalars['Int'];
  lossToLease: Scalars['Decimal'];
  monthIndex: Scalars['Int'];
  monthToMonthCount: Scalars['Int'];
  monthToMonthMoveOutCount: Scalars['Int'];
  moveInCount: Scalars['Decimal'];
  moveOutCount?: Maybe<Scalars['Decimal']>;
  moveOutRate: Scalars['Decimal'];
  newLeaseRent?: Maybe<Scalars['Decimal']>;
  newLeaseRentCount?: Maybe<Scalars['Int']>;
  newLeaseTradeOut?: Maybe<Scalars['Decimal']>;
  occupancyRate: Scalars['Decimal'];
  occupiedUnits?: Maybe<Scalars['Decimal']>;
  renewalCount?: Maybe<Scalars['Decimal']>;
  renewalRate: Scalars['Decimal'];
  renewalRatio: Scalars['Decimal'];
  renewalRent?: Maybe<Scalars['Decimal']>;
  renewalRentCount?: Maybe<Scalars['Int']>;
  totalMoveOutCount?: Maybe<Scalars['Decimal']>;
  unitCount: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId: Scalars['String'];
};

export type OperationalMetricCreateManyPropertyInputEnvelope = {
  data: Array<OperationalMetricCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OperationalMetricCreateManyVersionInput = {
  averageMarketRent: Scalars['Decimal'];
  averageResidentialRent: Scalars['Decimal'];
  averageSquareFeet: Scalars['Decimal'];
  budgetYear: Scalars['Int'];
  combinedTradeOut?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earlyTerminationCount: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  leaseExpirationCount: Scalars['Int'];
  lossToLease: Scalars['Decimal'];
  monthIndex: Scalars['Int'];
  monthToMonthCount: Scalars['Int'];
  monthToMonthMoveOutCount: Scalars['Int'];
  moveInCount: Scalars['Decimal'];
  moveOutCount?: Maybe<Scalars['Decimal']>;
  moveOutRate: Scalars['Decimal'];
  newLeaseRent?: Maybe<Scalars['Decimal']>;
  newLeaseRentCount?: Maybe<Scalars['Int']>;
  newLeaseTradeOut?: Maybe<Scalars['Decimal']>;
  occupancyRate: Scalars['Decimal'];
  occupiedUnits?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  renewalCount?: Maybe<Scalars['Decimal']>;
  renewalRate: Scalars['Decimal'];
  renewalRatio: Scalars['Decimal'];
  renewalRent?: Maybe<Scalars['Decimal']>;
  renewalRentCount?: Maybe<Scalars['Int']>;
  totalMoveOutCount?: Maybe<Scalars['Decimal']>;
  unitCount: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OperationalMetricCreateManyVersionInputEnvelope = {
  data: Array<OperationalMetricCreateManyVersionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OperationalMetricCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<OperationalMetricWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OperationalMetricCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<OperationalMetricCreateWithoutPropertyInput>>;
  createMany?: Maybe<OperationalMetricCreateManyPropertyInputEnvelope>;
};

export type OperationalMetricCreateNestedManyWithoutVersionInput = {
  connect?: Maybe<Array<OperationalMetricWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OperationalMetricCreateOrConnectWithoutVersionInput>>;
  create?: Maybe<Array<OperationalMetricCreateWithoutVersionInput>>;
  createMany?: Maybe<OperationalMetricCreateManyVersionInputEnvelope>;
};

export type OperationalMetricCreateOrConnectWithoutPropertyInput = {
  create: OperationalMetricCreateWithoutPropertyInput;
  where: OperationalMetricWhereUniqueInput;
};

export type OperationalMetricCreateOrConnectWithoutVersionInput = {
  create: OperationalMetricCreateWithoutVersionInput;
  where: OperationalMetricWhereUniqueInput;
};

export type OperationalMetricCreateWithoutPropertyInput = {
  averageMarketRent: Scalars['Decimal'];
  averageResidentialRent: Scalars['Decimal'];
  averageSquareFeet: Scalars['Decimal'];
  budgetYear: Scalars['Int'];
  combinedTradeOut?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earlyTerminationCount: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  leaseExpirationCount: Scalars['Int'];
  lossToLease: Scalars['Decimal'];
  monthIndex: Scalars['Int'];
  monthToMonthCount: Scalars['Int'];
  monthToMonthMoveOutCount: Scalars['Int'];
  moveInCount: Scalars['Decimal'];
  moveOutCount?: Maybe<Scalars['Decimal']>;
  moveOutRate: Scalars['Decimal'];
  newLeaseRent?: Maybe<Scalars['Decimal']>;
  newLeaseRentCount?: Maybe<Scalars['Int']>;
  newLeaseTradeOut?: Maybe<Scalars['Decimal']>;
  occupancyRate: Scalars['Decimal'];
  occupiedUnits?: Maybe<Scalars['Decimal']>;
  renewalCount?: Maybe<Scalars['Decimal']>;
  renewalRate: Scalars['Decimal'];
  renewalRatio: Scalars['Decimal'];
  renewalRent?: Maybe<Scalars['Decimal']>;
  renewalRentCount?: Maybe<Scalars['Int']>;
  totalMoveOutCount?: Maybe<Scalars['Decimal']>;
  unitCount: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  version: ValueVersionCreateNestedOneWithoutOperationalMetricsInput;
};

export type OperationalMetricCreateWithoutVersionInput = {
  averageMarketRent: Scalars['Decimal'];
  averageResidentialRent: Scalars['Decimal'];
  averageSquareFeet: Scalars['Decimal'];
  budgetYear: Scalars['Int'];
  combinedTradeOut?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earlyTerminationCount: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  leaseExpirationCount: Scalars['Int'];
  lossToLease: Scalars['Decimal'];
  monthIndex: Scalars['Int'];
  monthToMonthCount: Scalars['Int'];
  monthToMonthMoveOutCount: Scalars['Int'];
  moveInCount: Scalars['Decimal'];
  moveOutCount?: Maybe<Scalars['Decimal']>;
  moveOutRate: Scalars['Decimal'];
  newLeaseRent?: Maybe<Scalars['Decimal']>;
  newLeaseRentCount?: Maybe<Scalars['Int']>;
  newLeaseTradeOut?: Maybe<Scalars['Decimal']>;
  occupancyRate: Scalars['Decimal'];
  occupiedUnits?: Maybe<Scalars['Decimal']>;
  property: PropertyCreateNestedOneWithoutOperationalMetricsInput;
  renewalCount?: Maybe<Scalars['Decimal']>;
  renewalRate: Scalars['Decimal'];
  renewalRatio: Scalars['Decimal'];
  renewalRent?: Maybe<Scalars['Decimal']>;
  renewalRentCount?: Maybe<Scalars['Int']>;
  totalMoveOutCount?: Maybe<Scalars['Decimal']>;
  unitCount: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OperationalMetricDriverAssumptionAndValueModel = {
  __typename?: 'OperationalMetricDriverAssumptionAndValueModel';
  amount?: Maybe<Scalars['Decimal']>;
  lookbackPeriod?: Maybe<Scalars['Int']>;
  metricValue?: Maybe<Scalars['Decimal']>;
  percentage?: Maybe<Scalars['Decimal']>;
};

export type OperationalMetricDriverAssumptionModel = {
  __typename?: 'OperationalMetricDriverAssumptionModel';
  amount?: Maybe<Scalars['Decimal']>;
  lookbackPeriod?: Maybe<Scalars['Int']>;
  percentage?: Maybe<Scalars['Decimal']>;
};

export type OperationalMetricDriverModel = {
  __typename?: 'OperationalMetricDriverModel';
  isDriven: Scalars['Boolean'];
  sourceMetricAssumptions: Array<SourceMetricAssumptionsModel>;
  sourceMetrics: Array<SourceMetricModel>;
};

export type OperationalMetricListRelationFilter = {
  every?: Maybe<OperationalMetricWhereInput>;
  none?: Maybe<OperationalMetricWhereInput>;
  some?: Maybe<OperationalMetricWhereInput>;
};

export type OperationalMetricOrderByInput = {
  averageMarketRent?: Maybe<SortOrder>;
  averageResidentialRent?: Maybe<SortOrder>;
  averageSquareFeet?: Maybe<SortOrder>;
  budgetYear?: Maybe<SortOrder>;
  combinedTradeOut?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  earlyTerminationCount?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  leaseExpirationCount?: Maybe<SortOrder>;
  lossToLease?: Maybe<SortOrder>;
  monthIndex?: Maybe<SortOrder>;
  monthToMonthCount?: Maybe<SortOrder>;
  monthToMonthMoveOutCount?: Maybe<SortOrder>;
  moveInCount?: Maybe<SortOrder>;
  moveOutCount?: Maybe<SortOrder>;
  moveOutRate?: Maybe<SortOrder>;
  newLeaseRent?: Maybe<SortOrder>;
  newLeaseRentCount?: Maybe<SortOrder>;
  newLeaseTradeOut?: Maybe<SortOrder>;
  occupancyRate?: Maybe<SortOrder>;
  occupiedUnits?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  renewalCount?: Maybe<SortOrder>;
  renewalRate?: Maybe<SortOrder>;
  renewalRatio?: Maybe<SortOrder>;
  renewalRent?: Maybe<SortOrder>;
  renewalRentCount?: Maybe<SortOrder>;
  totalMoveOutCount?: Maybe<SortOrder>;
  unitCount?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  versionId?: Maybe<SortOrder>;
};

export type OperationalMetricPerformanceItemModel = {
  __typename?: 'OperationalMetricPerformanceItemModel';
  counts: Array<Scalars['Float']>;
  values: Array<Scalars['Float']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type OperationalMetricPerformanceModel = {
  __typename?: 'OperationalMetricPerformanceModel';
  expirations: Array<OperationalMetricPerformanceItemModel>;
  move_out_rates: Array<OperationalMetricPerformanceItemModel>;
  occupancy_rates: Array<OperationalMetricPerformanceItemModel>;
  renewal_ratio: Array<OperationalMetricPerformanceItemModel>;
};

export type OperationalMetricPropertyIdVersionIdMonthIndexBudgetYearCompoundUniqueInput = {
  budgetYear: Scalars['Int'];
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  versionId: Scalars['String'];
};

export enum OperationalMetricScalarFieldEnum {
  AverageMarketRent = 'averageMarketRent',
  AverageResidentialRent = 'averageResidentialRent',
  AverageSquareFeet = 'averageSquareFeet',
  BudgetYear = 'budgetYear',
  CombinedTradeOut = 'combinedTradeOut',
  CreatedAt = 'createdAt',
  EarlyTerminationCount = 'earlyTerminationCount',
  Id = 'id',
  LeaseExpirationCount = 'leaseExpirationCount',
  LossToLease = 'lossToLease',
  MonthIndex = 'monthIndex',
  MonthToMonthCount = 'monthToMonthCount',
  MonthToMonthMoveOutCount = 'monthToMonthMoveOutCount',
  MoveInCount = 'moveInCount',
  MoveOutCount = 'moveOutCount',
  MoveOutRate = 'moveOutRate',
  NewLeaseRent = 'newLeaseRent',
  NewLeaseRentCount = 'newLeaseRentCount',
  NewLeaseTradeOut = 'newLeaseTradeOut',
  OccupancyRate = 'occupancyRate',
  OccupiedUnits = 'occupiedUnits',
  PropertyId = 'propertyId',
  RenewalCount = 'renewalCount',
  RenewalRate = 'renewalRate',
  RenewalRatio = 'renewalRatio',
  RenewalRent = 'renewalRent',
  RenewalRentCount = 'renewalRentCount',
  TotalMoveOutCount = 'totalMoveOutCount',
  UnitCount = 'unitCount',
  UpdatedAt = 'updatedAt',
  VersionId = 'versionId'
}

export type OperationalMetricScalarWhereInput = {
  AND?: Maybe<Array<OperationalMetricScalarWhereInput>>;
  NOT?: Maybe<Array<OperationalMetricScalarWhereInput>>;
  OR?: Maybe<Array<OperationalMetricScalarWhereInput>>;
  averageMarketRent?: Maybe<DecimalFilter>;
  averageResidentialRent?: Maybe<DecimalFilter>;
  averageSquareFeet?: Maybe<DecimalFilter>;
  budgetYear?: Maybe<IntFilter>;
  combinedTradeOut?: Maybe<DecimalFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  earlyTerminationCount?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  leaseExpirationCount?: Maybe<IntFilter>;
  lossToLease?: Maybe<DecimalFilter>;
  monthIndex?: Maybe<IntFilter>;
  monthToMonthCount?: Maybe<IntFilter>;
  monthToMonthMoveOutCount?: Maybe<IntFilter>;
  moveInCount?: Maybe<DecimalFilter>;
  moveOutCount?: Maybe<DecimalFilter>;
  moveOutRate?: Maybe<DecimalFilter>;
  newLeaseRent?: Maybe<DecimalNullableFilter>;
  newLeaseRentCount?: Maybe<IntNullableFilter>;
  newLeaseTradeOut?: Maybe<DecimalFilter>;
  occupancyRate?: Maybe<DecimalFilter>;
  occupiedUnits?: Maybe<DecimalFilter>;
  propertyId?: Maybe<StringFilter>;
  renewalCount?: Maybe<DecimalFilter>;
  renewalRate?: Maybe<DecimalFilter>;
  renewalRatio?: Maybe<DecimalFilter>;
  renewalRent?: Maybe<DecimalNullableFilter>;
  renewalRentCount?: Maybe<IntNullableFilter>;
  totalMoveOutCount?: Maybe<DecimalFilter>;
  unitCount?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionId?: Maybe<StringFilter>;
};

export type OperationalMetricType = {
  __typename?: 'OperationalMetricType';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OperationalMetricTypeCountAggregate = {
  __typename?: 'OperationalMetricTypeCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type OperationalMetricTypeCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  operationalDriverMetricTypes?: Maybe<OperationalDriverMetricTypeCreateNestedManyWithoutOperationalMetricTypeInput>;
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutOperationalMetricTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OperationalMetricTypeCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OperationalMetricTypeCreateNestedOneWithoutOperationalDriverMetricTypesInput = {
  connect?: Maybe<OperationalMetricTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<OperationalMetricTypeCreateOrConnectWithoutOperationalDriverMetricTypesInput>;
  create?: Maybe<OperationalMetricTypeCreateWithoutOperationalDriverMetricTypesInput>;
};

export type OperationalMetricTypeCreateNestedOneWithoutOperationalDriversInput = {
  connect?: Maybe<OperationalMetricTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<OperationalMetricTypeCreateOrConnectWithoutOperationalDriversInput>;
  create?: Maybe<OperationalMetricTypeCreateWithoutOperationalDriversInput>;
};

export type OperationalMetricTypeCreateOrConnectWithoutOperationalDriverMetricTypesInput = {
  create: OperationalMetricTypeCreateWithoutOperationalDriverMetricTypesInput;
  where: OperationalMetricTypeWhereUniqueInput;
};

export type OperationalMetricTypeCreateOrConnectWithoutOperationalDriversInput = {
  create: OperationalMetricTypeCreateWithoutOperationalDriversInput;
  where: OperationalMetricTypeWhereUniqueInput;
};

export type OperationalMetricTypeCreateWithoutOperationalDriverMetricTypesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutOperationalMetricTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OperationalMetricTypeCreateWithoutOperationalDriversInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  operationalDriverMetricTypes?: Maybe<OperationalDriverMetricTypeCreateNestedManyWithoutOperationalMetricTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OperationalMetricTypeGroupBy = {
  __typename?: 'OperationalMetricTypeGroupBy';
  _count?: Maybe<OperationalMetricTypeCountAggregate>;
  _max?: Maybe<OperationalMetricTypeMaxAggregate>;
  _min?: Maybe<OperationalMetricTypeMinAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OperationalMetricTypeMaxAggregate = {
  __typename?: 'OperationalMetricTypeMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OperationalMetricTypeMinAggregate = {
  __typename?: 'OperationalMetricTypeMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OperationalMetricTypeModel = {
  __typename?: 'OperationalMetricTypeModel';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type OperationalMetricTypeOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type OperationalMetricTypeRelationFilter = {
  is?: Maybe<OperationalMetricTypeWhereInput>;
  isNot?: Maybe<OperationalMetricTypeWhereInput>;
};

export enum OperationalMetricTypeScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type OperationalMetricTypeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<OperationalMetricTypeScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<OperationalMetricTypeScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<OperationalMetricTypeScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type OperationalMetricTypeUpdateInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDriverMetricTypes?: Maybe<OperationalDriverMetricTypeUpdateManyWithoutOperationalMetricTypeInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutOperationalMetricTypeInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperationalMetricTypeUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperationalMetricTypeUpdateOneRequiredWithoutOperationalDriverMetricTypesInput = {
  connect?: Maybe<OperationalMetricTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<OperationalMetricTypeCreateOrConnectWithoutOperationalDriverMetricTypesInput>;
  create?: Maybe<OperationalMetricTypeCreateWithoutOperationalDriverMetricTypesInput>;
  update?: Maybe<OperationalMetricTypeUpdateWithoutOperationalDriverMetricTypesInput>;
  upsert?: Maybe<OperationalMetricTypeUpsertWithoutOperationalDriverMetricTypesInput>;
};

export type OperationalMetricTypeUpdateOneRequiredWithoutOperationalDriversInput = {
  connect?: Maybe<OperationalMetricTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<OperationalMetricTypeCreateOrConnectWithoutOperationalDriversInput>;
  create?: Maybe<OperationalMetricTypeCreateWithoutOperationalDriversInput>;
  update?: Maybe<OperationalMetricTypeUpdateWithoutOperationalDriversInput>;
  upsert?: Maybe<OperationalMetricTypeUpsertWithoutOperationalDriversInput>;
};

export type OperationalMetricTypeUpdateWithoutOperationalDriverMetricTypesInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutOperationalMetricTypeInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperationalMetricTypeUpdateWithoutOperationalDriversInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDriverMetricTypes?: Maybe<OperationalDriverMetricTypeUpdateManyWithoutOperationalMetricTypeInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperationalMetricTypeUpsertWithoutOperationalDriverMetricTypesInput = {
  create: OperationalMetricTypeCreateWithoutOperationalDriverMetricTypesInput;
  update: OperationalMetricTypeUpdateWithoutOperationalDriverMetricTypesInput;
};

export type OperationalMetricTypeUpsertWithoutOperationalDriversInput = {
  create: OperationalMetricTypeCreateWithoutOperationalDriversInput;
  update: OperationalMetricTypeUpdateWithoutOperationalDriversInput;
};

export type OperationalMetricTypeWhereInput = {
  AND?: Maybe<Array<OperationalMetricTypeWhereInput>>;
  NOT?: Maybe<Array<OperationalMetricTypeWhereInput>>;
  OR?: Maybe<Array<OperationalMetricTypeWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  operationalDriverMetricTypes?: Maybe<OperationalDriverMetricTypeListRelationFilter>;
  operationalDrivers?: Maybe<DriverOperationalListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type OperationalMetricTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type OperationalMetricUpdateManyMutationInput = {
  averageMarketRent?: Maybe<DecimalFieldUpdateOperationsInput>;
  averageResidentialRent?: Maybe<DecimalFieldUpdateOperationsInput>;
  averageSquareFeet?: Maybe<DecimalFieldUpdateOperationsInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  combinedTradeOut?: Maybe<DecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  earlyTerminationCount?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  leaseExpirationCount?: Maybe<IntFieldUpdateOperationsInput>;
  lossToLease?: Maybe<DecimalFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  monthToMonthCount?: Maybe<IntFieldUpdateOperationsInput>;
  monthToMonthMoveOutCount?: Maybe<IntFieldUpdateOperationsInput>;
  moveInCount?: Maybe<DecimalFieldUpdateOperationsInput>;
  moveOutCount?: Maybe<DecimalFieldUpdateOperationsInput>;
  moveOutRate?: Maybe<DecimalFieldUpdateOperationsInput>;
  newLeaseRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  newLeaseRentCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  newLeaseTradeOut?: Maybe<DecimalFieldUpdateOperationsInput>;
  occupancyRate?: Maybe<DecimalFieldUpdateOperationsInput>;
  occupiedUnits?: Maybe<DecimalFieldUpdateOperationsInput>;
  renewalCount?: Maybe<DecimalFieldUpdateOperationsInput>;
  renewalRate?: Maybe<DecimalFieldUpdateOperationsInput>;
  renewalRatio?: Maybe<DecimalFieldUpdateOperationsInput>;
  renewalRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRentCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  totalMoveOutCount?: Maybe<DecimalFieldUpdateOperationsInput>;
  unitCount?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperationalMetricUpdateManyWithWhereWithoutPropertyInput = {
  data: OperationalMetricUpdateManyMutationInput;
  where: OperationalMetricScalarWhereInput;
};

export type OperationalMetricUpdateManyWithWhereWithoutVersionInput = {
  data: OperationalMetricUpdateManyMutationInput;
  where: OperationalMetricScalarWhereInput;
};

export type OperationalMetricUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<OperationalMetricWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OperationalMetricCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<OperationalMetricCreateWithoutPropertyInput>>;
  createMany?: Maybe<OperationalMetricCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<OperationalMetricWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OperationalMetricScalarWhereInput>>;
  disconnect?: Maybe<Array<OperationalMetricWhereUniqueInput>>;
  set?: Maybe<Array<OperationalMetricWhereUniqueInput>>;
  update?: Maybe<Array<OperationalMetricUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<OperationalMetricUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<OperationalMetricUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type OperationalMetricUpdateManyWithoutVersionInput = {
  connect?: Maybe<Array<OperationalMetricWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OperationalMetricCreateOrConnectWithoutVersionInput>>;
  create?: Maybe<Array<OperationalMetricCreateWithoutVersionInput>>;
  createMany?: Maybe<OperationalMetricCreateManyVersionInputEnvelope>;
  delete?: Maybe<Array<OperationalMetricWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OperationalMetricScalarWhereInput>>;
  disconnect?: Maybe<Array<OperationalMetricWhereUniqueInput>>;
  set?: Maybe<Array<OperationalMetricWhereUniqueInput>>;
  update?: Maybe<Array<OperationalMetricUpdateWithWhereUniqueWithoutVersionInput>>;
  updateMany?: Maybe<Array<OperationalMetricUpdateManyWithWhereWithoutVersionInput>>;
  upsert?: Maybe<Array<OperationalMetricUpsertWithWhereUniqueWithoutVersionInput>>;
};

export type OperationalMetricUpdateWithWhereUniqueWithoutPropertyInput = {
  data: OperationalMetricUpdateWithoutPropertyInput;
  where: OperationalMetricWhereUniqueInput;
};

export type OperationalMetricUpdateWithWhereUniqueWithoutVersionInput = {
  data: OperationalMetricUpdateWithoutVersionInput;
  where: OperationalMetricWhereUniqueInput;
};

export type OperationalMetricUpdateWithoutPropertyInput = {
  averageMarketRent?: Maybe<DecimalFieldUpdateOperationsInput>;
  averageResidentialRent?: Maybe<DecimalFieldUpdateOperationsInput>;
  averageSquareFeet?: Maybe<DecimalFieldUpdateOperationsInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  combinedTradeOut?: Maybe<DecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  earlyTerminationCount?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  leaseExpirationCount?: Maybe<IntFieldUpdateOperationsInput>;
  lossToLease?: Maybe<DecimalFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  monthToMonthCount?: Maybe<IntFieldUpdateOperationsInput>;
  monthToMonthMoveOutCount?: Maybe<IntFieldUpdateOperationsInput>;
  moveInCount?: Maybe<DecimalFieldUpdateOperationsInput>;
  moveOutCount?: Maybe<DecimalFieldUpdateOperationsInput>;
  moveOutRate?: Maybe<DecimalFieldUpdateOperationsInput>;
  newLeaseRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  newLeaseRentCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  newLeaseTradeOut?: Maybe<DecimalFieldUpdateOperationsInput>;
  occupancyRate?: Maybe<DecimalFieldUpdateOperationsInput>;
  occupiedUnits?: Maybe<DecimalFieldUpdateOperationsInput>;
  renewalCount?: Maybe<DecimalFieldUpdateOperationsInput>;
  renewalRate?: Maybe<DecimalFieldUpdateOperationsInput>;
  renewalRatio?: Maybe<DecimalFieldUpdateOperationsInput>;
  renewalRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRentCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  totalMoveOutCount?: Maybe<DecimalFieldUpdateOperationsInput>;
  unitCount?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  version?: Maybe<ValueVersionUpdateOneRequiredWithoutOperationalMetricsInput>;
};

export type OperationalMetricUpdateWithoutVersionInput = {
  averageMarketRent?: Maybe<DecimalFieldUpdateOperationsInput>;
  averageResidentialRent?: Maybe<DecimalFieldUpdateOperationsInput>;
  averageSquareFeet?: Maybe<DecimalFieldUpdateOperationsInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  combinedTradeOut?: Maybe<DecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  earlyTerminationCount?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  leaseExpirationCount?: Maybe<IntFieldUpdateOperationsInput>;
  lossToLease?: Maybe<DecimalFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  monthToMonthCount?: Maybe<IntFieldUpdateOperationsInput>;
  monthToMonthMoveOutCount?: Maybe<IntFieldUpdateOperationsInput>;
  moveInCount?: Maybe<DecimalFieldUpdateOperationsInput>;
  moveOutCount?: Maybe<DecimalFieldUpdateOperationsInput>;
  moveOutRate?: Maybe<DecimalFieldUpdateOperationsInput>;
  newLeaseRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  newLeaseRentCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  newLeaseTradeOut?: Maybe<DecimalFieldUpdateOperationsInput>;
  occupancyRate?: Maybe<DecimalFieldUpdateOperationsInput>;
  occupiedUnits?: Maybe<DecimalFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutOperationalMetricsInput>;
  renewalCount?: Maybe<DecimalFieldUpdateOperationsInput>;
  renewalRate?: Maybe<DecimalFieldUpdateOperationsInput>;
  renewalRatio?: Maybe<DecimalFieldUpdateOperationsInput>;
  renewalRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRentCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  totalMoveOutCount?: Maybe<DecimalFieldUpdateOperationsInput>;
  unitCount?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type OperationalMetricUpsertWithWhereUniqueWithoutPropertyInput = {
  create: OperationalMetricCreateWithoutPropertyInput;
  update: OperationalMetricUpdateWithoutPropertyInput;
  where: OperationalMetricWhereUniqueInput;
};

export type OperationalMetricUpsertWithWhereUniqueWithoutVersionInput = {
  create: OperationalMetricCreateWithoutVersionInput;
  update: OperationalMetricUpdateWithoutVersionInput;
  where: OperationalMetricWhereUniqueInput;
};

export type OperationalMetricWhereInput = {
  AND?: Maybe<Array<OperationalMetricWhereInput>>;
  NOT?: Maybe<Array<OperationalMetricWhereInput>>;
  OR?: Maybe<Array<OperationalMetricWhereInput>>;
  averageMarketRent?: Maybe<DecimalFilter>;
  averageResidentialRent?: Maybe<DecimalFilter>;
  averageSquareFeet?: Maybe<DecimalFilter>;
  budgetYear?: Maybe<IntFilter>;
  combinedTradeOut?: Maybe<DecimalFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  earlyTerminationCount?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  leaseExpirationCount?: Maybe<IntFilter>;
  lossToLease?: Maybe<DecimalFilter>;
  monthIndex?: Maybe<IntFilter>;
  monthToMonthCount?: Maybe<IntFilter>;
  monthToMonthMoveOutCount?: Maybe<IntFilter>;
  moveInCount?: Maybe<DecimalFilter>;
  moveOutCount?: Maybe<DecimalFilter>;
  moveOutRate?: Maybe<DecimalFilter>;
  newLeaseRent?: Maybe<DecimalNullableFilter>;
  newLeaseRentCount?: Maybe<IntNullableFilter>;
  newLeaseTradeOut?: Maybe<DecimalFilter>;
  occupancyRate?: Maybe<DecimalFilter>;
  occupiedUnits?: Maybe<DecimalFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  renewalCount?: Maybe<DecimalFilter>;
  renewalRate?: Maybe<DecimalFilter>;
  renewalRatio?: Maybe<DecimalFilter>;
  renewalRent?: Maybe<DecimalNullableFilter>;
  renewalRentCount?: Maybe<IntNullableFilter>;
  totalMoveOutCount?: Maybe<DecimalFilter>;
  unitCount?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  version?: Maybe<ValueVersionRelationFilter>;
  versionId?: Maybe<StringFilter>;
};

export type OperationalMetricWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  propertyId_versionId_monthIndex_budgetYear?: Maybe<OperationalMetricPropertyIdVersionIdMonthIndexBudgetYearCompoundUniqueInput>;
};

export type OriginalRevenueExpirationCurveModel = {
  __typename?: 'OriginalRevenueExpirationCurveModel';
  budgetYear: Scalars['Int'];
  curve: Array<Scalars['Decimal']>;
  propertyId: Scalars['ID'];
};

export type OriginalRevenueForecastedNumbersModel = {
  __typename?: 'OriginalRevenueForecastedNumbersModel';
  actuals: Array<Maybe<Scalars['Float']>>;
  budget: Array<Maybe<Scalars['Float']>>;
  reforecast: Array<Maybe<Scalars['Float']>>;
};

export type OriginalRevenueMarketRentAdjustment = {
  __typename?: 'OriginalRevenueMarketRentAdjustment';
  adjustmentMonth0?: Maybe<Scalars['Decimal']>;
  adjustmentMonth1?: Maybe<Scalars['Decimal']>;
  adjustmentMonth2?: Maybe<Scalars['Decimal']>;
  adjustmentMonth3?: Maybe<Scalars['Decimal']>;
  adjustmentMonth4?: Maybe<Scalars['Decimal']>;
  adjustmentMonth5?: Maybe<Scalars['Decimal']>;
  adjustmentMonth6?: Maybe<Scalars['Decimal']>;
  adjustmentMonth7?: Maybe<Scalars['Decimal']>;
  adjustmentMonth8?: Maybe<Scalars['Decimal']>;
  adjustmentMonth9?: Maybe<Scalars['Decimal']>;
  adjustmentMonth10?: Maybe<Scalars['Decimal']>;
  adjustmentMonth11?: Maybe<Scalars['Decimal']>;
  adjustmentTypeMonth0?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth1?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth2?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth3?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth4?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth5?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth6?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth7?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth8?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth9?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth10?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth11?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  propertyId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export type OriginalRevenueMarketRentAdjustmentCreateManyPropertyInput = {
  adjustmentMonth0?: Maybe<Scalars['Decimal']>;
  adjustmentMonth1?: Maybe<Scalars['Decimal']>;
  adjustmentMonth2?: Maybe<Scalars['Decimal']>;
  adjustmentMonth3?: Maybe<Scalars['Decimal']>;
  adjustmentMonth4?: Maybe<Scalars['Decimal']>;
  adjustmentMonth5?: Maybe<Scalars['Decimal']>;
  adjustmentMonth6?: Maybe<Scalars['Decimal']>;
  adjustmentMonth7?: Maybe<Scalars['Decimal']>;
  adjustmentMonth8?: Maybe<Scalars['Decimal']>;
  adjustmentMonth9?: Maybe<Scalars['Decimal']>;
  adjustmentMonth10?: Maybe<Scalars['Decimal']>;
  adjustmentMonth11?: Maybe<Scalars['Decimal']>;
  adjustmentTypeMonth0?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth1?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth2?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth3?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth4?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth5?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth6?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth7?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth8?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth9?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth10?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth11?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type OriginalRevenueMarketRentAdjustmentCreateManyPropertyInputEnvelope = {
  data: Array<OriginalRevenueMarketRentAdjustmentCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<OriginalRevenueMarketRentAdjustmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OriginalRevenueMarketRentAdjustmentCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<OriginalRevenueMarketRentAdjustmentCreateWithoutPropertyInput>>;
  createMany?: Maybe<OriginalRevenueMarketRentAdjustmentCreateManyPropertyInputEnvelope>;
};

export type OriginalRevenueMarketRentAdjustmentCreateOrConnectWithoutPropertyInput = {
  create: OriginalRevenueMarketRentAdjustmentCreateWithoutPropertyInput;
  where: OriginalRevenueMarketRentAdjustmentWhereUniqueInput;
};

export type OriginalRevenueMarketRentAdjustmentCreateWithoutPropertyInput = {
  adjustmentMonth0?: Maybe<Scalars['Decimal']>;
  adjustmentMonth1?: Maybe<Scalars['Decimal']>;
  adjustmentMonth2?: Maybe<Scalars['Decimal']>;
  adjustmentMonth3?: Maybe<Scalars['Decimal']>;
  adjustmentMonth4?: Maybe<Scalars['Decimal']>;
  adjustmentMonth5?: Maybe<Scalars['Decimal']>;
  adjustmentMonth6?: Maybe<Scalars['Decimal']>;
  adjustmentMonth7?: Maybe<Scalars['Decimal']>;
  adjustmentMonth8?: Maybe<Scalars['Decimal']>;
  adjustmentMonth9?: Maybe<Scalars['Decimal']>;
  adjustmentMonth10?: Maybe<Scalars['Decimal']>;
  adjustmentMonth11?: Maybe<Scalars['Decimal']>;
  adjustmentTypeMonth0?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth1?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth2?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth3?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth4?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth5?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth6?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth7?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth8?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth9?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth10?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjustmentTypeMonth11?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type OriginalRevenueMarketRentAdjustmentListRelationFilter = {
  every?: Maybe<OriginalRevenueMarketRentAdjustmentWhereInput>;
  none?: Maybe<OriginalRevenueMarketRentAdjustmentWhereInput>;
  some?: Maybe<OriginalRevenueMarketRentAdjustmentWhereInput>;
};

export type OriginalRevenueMarketRentAdjustmentOrderByInput = {
  adjustmentMonth0?: Maybe<SortOrder>;
  adjustmentMonth1?: Maybe<SortOrder>;
  adjustmentMonth2?: Maybe<SortOrder>;
  adjustmentMonth3?: Maybe<SortOrder>;
  adjustmentMonth4?: Maybe<SortOrder>;
  adjustmentMonth5?: Maybe<SortOrder>;
  adjustmentMonth6?: Maybe<SortOrder>;
  adjustmentMonth7?: Maybe<SortOrder>;
  adjustmentMonth8?: Maybe<SortOrder>;
  adjustmentMonth9?: Maybe<SortOrder>;
  adjustmentMonth10?: Maybe<SortOrder>;
  adjustmentMonth11?: Maybe<SortOrder>;
  adjustmentTypeMonth0?: Maybe<SortOrder>;
  adjustmentTypeMonth1?: Maybe<SortOrder>;
  adjustmentTypeMonth2?: Maybe<SortOrder>;
  adjustmentTypeMonth3?: Maybe<SortOrder>;
  adjustmentTypeMonth4?: Maybe<SortOrder>;
  adjustmentTypeMonth5?: Maybe<SortOrder>;
  adjustmentTypeMonth6?: Maybe<SortOrder>;
  adjustmentTypeMonth7?: Maybe<SortOrder>;
  adjustmentTypeMonth8?: Maybe<SortOrder>;
  adjustmentTypeMonth9?: Maybe<SortOrder>;
  adjustmentTypeMonth10?: Maybe<SortOrder>;
  adjustmentTypeMonth11?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  versionType?: Maybe<SortOrder>;
  year?: Maybe<SortOrder>;
};

export enum OriginalRevenueMarketRentAdjustmentScalarFieldEnum {
  AdjustmentMonth0 = 'adjustmentMonth0',
  AdjustmentMonth1 = 'adjustmentMonth1',
  AdjustmentMonth2 = 'adjustmentMonth2',
  AdjustmentMonth3 = 'adjustmentMonth3',
  AdjustmentMonth4 = 'adjustmentMonth4',
  AdjustmentMonth5 = 'adjustmentMonth5',
  AdjustmentMonth6 = 'adjustmentMonth6',
  AdjustmentMonth7 = 'adjustmentMonth7',
  AdjustmentMonth8 = 'adjustmentMonth8',
  AdjustmentMonth9 = 'adjustmentMonth9',
  AdjustmentMonth10 = 'adjustmentMonth10',
  AdjustmentMonth11 = 'adjustmentMonth11',
  AdjustmentTypeMonth0 = 'adjustmentTypeMonth0',
  AdjustmentTypeMonth1 = 'adjustmentTypeMonth1',
  AdjustmentTypeMonth2 = 'adjustmentTypeMonth2',
  AdjustmentTypeMonth3 = 'adjustmentTypeMonth3',
  AdjustmentTypeMonth4 = 'adjustmentTypeMonth4',
  AdjustmentTypeMonth5 = 'adjustmentTypeMonth5',
  AdjustmentTypeMonth6 = 'adjustmentTypeMonth6',
  AdjustmentTypeMonth7 = 'adjustmentTypeMonth7',
  AdjustmentTypeMonth8 = 'adjustmentTypeMonth8',
  AdjustmentTypeMonth9 = 'adjustmentTypeMonth9',
  AdjustmentTypeMonth10 = 'adjustmentTypeMonth10',
  AdjustmentTypeMonth11 = 'adjustmentTypeMonth11',
  CreatedAt = 'createdAt',
  Id = 'id',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt',
  VersionType = 'versionType',
  Year = 'year'
}

export type OriginalRevenueMarketRentAdjustmentScalarWhereInput = {
  AND?: Maybe<Array<OriginalRevenueMarketRentAdjustmentScalarWhereInput>>;
  NOT?: Maybe<Array<OriginalRevenueMarketRentAdjustmentScalarWhereInput>>;
  OR?: Maybe<Array<OriginalRevenueMarketRentAdjustmentScalarWhereInput>>;
  adjustmentMonth0?: Maybe<DecimalNullableFilter>;
  adjustmentMonth1?: Maybe<DecimalNullableFilter>;
  adjustmentMonth2?: Maybe<DecimalNullableFilter>;
  adjustmentMonth3?: Maybe<DecimalNullableFilter>;
  adjustmentMonth4?: Maybe<DecimalNullableFilter>;
  adjustmentMonth5?: Maybe<DecimalNullableFilter>;
  adjustmentMonth6?: Maybe<DecimalNullableFilter>;
  adjustmentMonth7?: Maybe<DecimalNullableFilter>;
  adjustmentMonth8?: Maybe<DecimalNullableFilter>;
  adjustmentMonth9?: Maybe<DecimalNullableFilter>;
  adjustmentMonth10?: Maybe<DecimalNullableFilter>;
  adjustmentMonth11?: Maybe<DecimalNullableFilter>;
  adjustmentTypeMonth0?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  adjustmentTypeMonth1?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  adjustmentTypeMonth2?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  adjustmentTypeMonth3?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  adjustmentTypeMonth4?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  adjustmentTypeMonth5?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  adjustmentTypeMonth6?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  adjustmentTypeMonth7?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  adjustmentTypeMonth8?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  adjustmentTypeMonth9?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  adjustmentTypeMonth10?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  adjustmentTypeMonth11?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export enum OriginalRevenueMarketRentAdjustmentType {
  Dollar = 'DOLLAR',
  Percent = 'PERCENT'
}

export type OriginalRevenueMarketRentAdjustmentUpdateManyMutationInput = {
  adjustmentMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentTypeMonth0?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  adjustmentTypeMonth1?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  adjustmentTypeMonth2?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  adjustmentTypeMonth3?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  adjustmentTypeMonth4?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  adjustmentTypeMonth5?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  adjustmentTypeMonth6?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  adjustmentTypeMonth7?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  adjustmentTypeMonth8?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  adjustmentTypeMonth9?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  adjustmentTypeMonth10?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  adjustmentTypeMonth11?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OriginalRevenueMarketRentAdjustmentUpdateManyWithWhereWithoutPropertyInput = {
  data: OriginalRevenueMarketRentAdjustmentUpdateManyMutationInput;
  where: OriginalRevenueMarketRentAdjustmentScalarWhereInput;
};

export type OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<OriginalRevenueMarketRentAdjustmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OriginalRevenueMarketRentAdjustmentCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<OriginalRevenueMarketRentAdjustmentCreateWithoutPropertyInput>>;
  createMany?: Maybe<OriginalRevenueMarketRentAdjustmentCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<OriginalRevenueMarketRentAdjustmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OriginalRevenueMarketRentAdjustmentScalarWhereInput>>;
  disconnect?: Maybe<Array<OriginalRevenueMarketRentAdjustmentWhereUniqueInput>>;
  set?: Maybe<Array<OriginalRevenueMarketRentAdjustmentWhereUniqueInput>>;
  update?: Maybe<Array<OriginalRevenueMarketRentAdjustmentUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<OriginalRevenueMarketRentAdjustmentUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<OriginalRevenueMarketRentAdjustmentUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type OriginalRevenueMarketRentAdjustmentUpdateWithWhereUniqueWithoutPropertyInput = {
  data: OriginalRevenueMarketRentAdjustmentUpdateWithoutPropertyInput;
  where: OriginalRevenueMarketRentAdjustmentWhereUniqueInput;
};

export type OriginalRevenueMarketRentAdjustmentUpdateWithoutPropertyInput = {
  adjustmentMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  adjustmentTypeMonth0?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  adjustmentTypeMonth1?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  adjustmentTypeMonth2?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  adjustmentTypeMonth3?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  adjustmentTypeMonth4?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  adjustmentTypeMonth5?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  adjustmentTypeMonth6?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  adjustmentTypeMonth7?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  adjustmentTypeMonth8?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  adjustmentTypeMonth9?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  adjustmentTypeMonth10?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  adjustmentTypeMonth11?: Maybe<NullableEnumOriginalRevenueMarketRentAdjustmentTypeFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OriginalRevenueMarketRentAdjustmentUpsertWithWhereUniqueWithoutPropertyInput = {
  create: OriginalRevenueMarketRentAdjustmentCreateWithoutPropertyInput;
  update: OriginalRevenueMarketRentAdjustmentUpdateWithoutPropertyInput;
  where: OriginalRevenueMarketRentAdjustmentWhereUniqueInput;
};

export type OriginalRevenueMarketRentAdjustmentWhereInput = {
  AND?: Maybe<Array<OriginalRevenueMarketRentAdjustmentWhereInput>>;
  NOT?: Maybe<Array<OriginalRevenueMarketRentAdjustmentWhereInput>>;
  OR?: Maybe<Array<OriginalRevenueMarketRentAdjustmentWhereInput>>;
  adjustmentMonth0?: Maybe<DecimalNullableFilter>;
  adjustmentMonth1?: Maybe<DecimalNullableFilter>;
  adjustmentMonth2?: Maybe<DecimalNullableFilter>;
  adjustmentMonth3?: Maybe<DecimalNullableFilter>;
  adjustmentMonth4?: Maybe<DecimalNullableFilter>;
  adjustmentMonth5?: Maybe<DecimalNullableFilter>;
  adjustmentMonth6?: Maybe<DecimalNullableFilter>;
  adjustmentMonth7?: Maybe<DecimalNullableFilter>;
  adjustmentMonth8?: Maybe<DecimalNullableFilter>;
  adjustmentMonth9?: Maybe<DecimalNullableFilter>;
  adjustmentMonth10?: Maybe<DecimalNullableFilter>;
  adjustmentMonth11?: Maybe<DecimalNullableFilter>;
  adjustmentTypeMonth0?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  adjustmentTypeMonth1?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  adjustmentTypeMonth2?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  adjustmentTypeMonth3?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  adjustmentTypeMonth4?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  adjustmentTypeMonth5?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  adjustmentTypeMonth6?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  adjustmentTypeMonth7?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  adjustmentTypeMonth8?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  adjustmentTypeMonth9?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  adjustmentTypeMonth10?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  adjustmentTypeMonth11?: Maybe<EnumOriginalRevenueMarketRentAdjustmentTypeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type OriginalRevenueMarketRentAdjustmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  year_versionType_propertyId?: Maybe<OriginalRevenueMarketRentAdjustmentYearVersionTypePropertyIdCompoundUniqueInput>;
};

export type OriginalRevenueMarketRentAdjustmentYearVersionTypePropertyIdCompoundUniqueInput = {
  propertyId: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export type OriginalRevenueMarketRentAdjustmentsModel = {
  __typename?: 'OriginalRevenueMarketRentAdjustmentsModel';
  adjustmentTypes: Array<Maybe<OriginalRevenueMarketRentAdjustmentType>>;
  adjustments: Array<Maybe<Scalars['Float']>>;
  propertyId: Scalars['ID'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export enum OriginalRevenueMarketRentModeType {
  Growth = 'GROWTH',
  Manual = 'MANUAL'
}

export type OriginalRevenueMarketRentOverride = {
  __typename?: 'OriginalRevenueMarketRentOverride';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  overrideMonth0?: Maybe<Scalars['Decimal']>;
  overrideMonth1?: Maybe<Scalars['Decimal']>;
  overrideMonth2?: Maybe<Scalars['Decimal']>;
  overrideMonth3?: Maybe<Scalars['Decimal']>;
  overrideMonth4?: Maybe<Scalars['Decimal']>;
  overrideMonth5?: Maybe<Scalars['Decimal']>;
  overrideMonth6?: Maybe<Scalars['Decimal']>;
  overrideMonth7?: Maybe<Scalars['Decimal']>;
  overrideMonth8?: Maybe<Scalars['Decimal']>;
  overrideMonth9?: Maybe<Scalars['Decimal']>;
  overrideMonth10?: Maybe<Scalars['Decimal']>;
  overrideMonth11?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  unitTypeId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export type OriginalRevenueMarketRentOverrideCreateManyPropertyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  overrideMonth0?: Maybe<Scalars['Decimal']>;
  overrideMonth1?: Maybe<Scalars['Decimal']>;
  overrideMonth2?: Maybe<Scalars['Decimal']>;
  overrideMonth3?: Maybe<Scalars['Decimal']>;
  overrideMonth4?: Maybe<Scalars['Decimal']>;
  overrideMonth5?: Maybe<Scalars['Decimal']>;
  overrideMonth6?: Maybe<Scalars['Decimal']>;
  overrideMonth7?: Maybe<Scalars['Decimal']>;
  overrideMonth8?: Maybe<Scalars['Decimal']>;
  overrideMonth9?: Maybe<Scalars['Decimal']>;
  overrideMonth10?: Maybe<Scalars['Decimal']>;
  overrideMonth11?: Maybe<Scalars['Decimal']>;
  unitTypeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type OriginalRevenueMarketRentOverrideCreateManyPropertyInputEnvelope = {
  data: Array<OriginalRevenueMarketRentOverrideCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OriginalRevenueMarketRentOverrideCreateManyUnitTypeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  overrideMonth0?: Maybe<Scalars['Decimal']>;
  overrideMonth1?: Maybe<Scalars['Decimal']>;
  overrideMonth2?: Maybe<Scalars['Decimal']>;
  overrideMonth3?: Maybe<Scalars['Decimal']>;
  overrideMonth4?: Maybe<Scalars['Decimal']>;
  overrideMonth5?: Maybe<Scalars['Decimal']>;
  overrideMonth6?: Maybe<Scalars['Decimal']>;
  overrideMonth7?: Maybe<Scalars['Decimal']>;
  overrideMonth8?: Maybe<Scalars['Decimal']>;
  overrideMonth9?: Maybe<Scalars['Decimal']>;
  overrideMonth10?: Maybe<Scalars['Decimal']>;
  overrideMonth11?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type OriginalRevenueMarketRentOverrideCreateManyUnitTypeInputEnvelope = {
  data: Array<OriginalRevenueMarketRentOverrideCreateManyUnitTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<OriginalRevenueMarketRentOverrideWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OriginalRevenueMarketRentOverrideCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<OriginalRevenueMarketRentOverrideCreateWithoutPropertyInput>>;
  createMany?: Maybe<OriginalRevenueMarketRentOverrideCreateManyPropertyInputEnvelope>;
};

export type OriginalRevenueMarketRentOverrideCreateNestedManyWithoutUnitTypeInput = {
  connect?: Maybe<Array<OriginalRevenueMarketRentOverrideWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OriginalRevenueMarketRentOverrideCreateOrConnectWithoutUnitTypeInput>>;
  create?: Maybe<Array<OriginalRevenueMarketRentOverrideCreateWithoutUnitTypeInput>>;
  createMany?: Maybe<OriginalRevenueMarketRentOverrideCreateManyUnitTypeInputEnvelope>;
};

export type OriginalRevenueMarketRentOverrideCreateOrConnectWithoutPropertyInput = {
  create: OriginalRevenueMarketRentOverrideCreateWithoutPropertyInput;
  where: OriginalRevenueMarketRentOverrideWhereUniqueInput;
};

export type OriginalRevenueMarketRentOverrideCreateOrConnectWithoutUnitTypeInput = {
  create: OriginalRevenueMarketRentOverrideCreateWithoutUnitTypeInput;
  where: OriginalRevenueMarketRentOverrideWhereUniqueInput;
};

export type OriginalRevenueMarketRentOverrideCreateWithoutPropertyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  overrideMonth0?: Maybe<Scalars['Decimal']>;
  overrideMonth1?: Maybe<Scalars['Decimal']>;
  overrideMonth2?: Maybe<Scalars['Decimal']>;
  overrideMonth3?: Maybe<Scalars['Decimal']>;
  overrideMonth4?: Maybe<Scalars['Decimal']>;
  overrideMonth5?: Maybe<Scalars['Decimal']>;
  overrideMonth6?: Maybe<Scalars['Decimal']>;
  overrideMonth7?: Maybe<Scalars['Decimal']>;
  overrideMonth8?: Maybe<Scalars['Decimal']>;
  overrideMonth9?: Maybe<Scalars['Decimal']>;
  overrideMonth10?: Maybe<Scalars['Decimal']>;
  overrideMonth11?: Maybe<Scalars['Decimal']>;
  unitType: UnitTypeCreateNestedOneWithoutOriginalRevenueMarketRentOverridesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type OriginalRevenueMarketRentOverrideCreateWithoutUnitTypeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  overrideMonth0?: Maybe<Scalars['Decimal']>;
  overrideMonth1?: Maybe<Scalars['Decimal']>;
  overrideMonth2?: Maybe<Scalars['Decimal']>;
  overrideMonth3?: Maybe<Scalars['Decimal']>;
  overrideMonth4?: Maybe<Scalars['Decimal']>;
  overrideMonth5?: Maybe<Scalars['Decimal']>;
  overrideMonth6?: Maybe<Scalars['Decimal']>;
  overrideMonth7?: Maybe<Scalars['Decimal']>;
  overrideMonth8?: Maybe<Scalars['Decimal']>;
  overrideMonth9?: Maybe<Scalars['Decimal']>;
  overrideMonth10?: Maybe<Scalars['Decimal']>;
  overrideMonth11?: Maybe<Scalars['Decimal']>;
  property: PropertyCreateNestedOneWithoutOriginalRevenueMarketRentOverridesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type OriginalRevenueMarketRentOverrideListRelationFilter = {
  every?: Maybe<OriginalRevenueMarketRentOverrideWhereInput>;
  none?: Maybe<OriginalRevenueMarketRentOverrideWhereInput>;
  some?: Maybe<OriginalRevenueMarketRentOverrideWhereInput>;
};

export type OriginalRevenueMarketRentOverrideOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  overrideMonth0?: Maybe<SortOrder>;
  overrideMonth1?: Maybe<SortOrder>;
  overrideMonth2?: Maybe<SortOrder>;
  overrideMonth3?: Maybe<SortOrder>;
  overrideMonth4?: Maybe<SortOrder>;
  overrideMonth5?: Maybe<SortOrder>;
  overrideMonth6?: Maybe<SortOrder>;
  overrideMonth7?: Maybe<SortOrder>;
  overrideMonth8?: Maybe<SortOrder>;
  overrideMonth9?: Maybe<SortOrder>;
  overrideMonth10?: Maybe<SortOrder>;
  overrideMonth11?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  unitTypeId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  versionType?: Maybe<SortOrder>;
  year?: Maybe<SortOrder>;
};

export enum OriginalRevenueMarketRentOverrideScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  OverrideMonth0 = 'overrideMonth0',
  OverrideMonth1 = 'overrideMonth1',
  OverrideMonth2 = 'overrideMonth2',
  OverrideMonth3 = 'overrideMonth3',
  OverrideMonth4 = 'overrideMonth4',
  OverrideMonth5 = 'overrideMonth5',
  OverrideMonth6 = 'overrideMonth6',
  OverrideMonth7 = 'overrideMonth7',
  OverrideMonth8 = 'overrideMonth8',
  OverrideMonth9 = 'overrideMonth9',
  OverrideMonth10 = 'overrideMonth10',
  OverrideMonth11 = 'overrideMonth11',
  PropertyId = 'propertyId',
  UnitTypeId = 'unitTypeId',
  UpdatedAt = 'updatedAt',
  VersionType = 'versionType',
  Year = 'year'
}

export type OriginalRevenueMarketRentOverrideScalarWhereInput = {
  AND?: Maybe<Array<OriginalRevenueMarketRentOverrideScalarWhereInput>>;
  NOT?: Maybe<Array<OriginalRevenueMarketRentOverrideScalarWhereInput>>;
  OR?: Maybe<Array<OriginalRevenueMarketRentOverrideScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  overrideMonth0?: Maybe<DecimalNullableFilter>;
  overrideMonth1?: Maybe<DecimalNullableFilter>;
  overrideMonth2?: Maybe<DecimalNullableFilter>;
  overrideMonth3?: Maybe<DecimalNullableFilter>;
  overrideMonth4?: Maybe<DecimalNullableFilter>;
  overrideMonth5?: Maybe<DecimalNullableFilter>;
  overrideMonth6?: Maybe<DecimalNullableFilter>;
  overrideMonth7?: Maybe<DecimalNullableFilter>;
  overrideMonth8?: Maybe<DecimalNullableFilter>;
  overrideMonth9?: Maybe<DecimalNullableFilter>;
  overrideMonth10?: Maybe<DecimalNullableFilter>;
  overrideMonth11?: Maybe<DecimalNullableFilter>;
  propertyId?: Maybe<StringFilter>;
  unitTypeId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type OriginalRevenueMarketRentOverrideUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  overrideMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OriginalRevenueMarketRentOverrideUpdateManyWithWhereWithoutPropertyInput = {
  data: OriginalRevenueMarketRentOverrideUpdateManyMutationInput;
  where: OriginalRevenueMarketRentOverrideScalarWhereInput;
};

export type OriginalRevenueMarketRentOverrideUpdateManyWithWhereWithoutUnitTypeInput = {
  data: OriginalRevenueMarketRentOverrideUpdateManyMutationInput;
  where: OriginalRevenueMarketRentOverrideScalarWhereInput;
};

export type OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<OriginalRevenueMarketRentOverrideWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OriginalRevenueMarketRentOverrideCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<OriginalRevenueMarketRentOverrideCreateWithoutPropertyInput>>;
  createMany?: Maybe<OriginalRevenueMarketRentOverrideCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<OriginalRevenueMarketRentOverrideWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OriginalRevenueMarketRentOverrideScalarWhereInput>>;
  disconnect?: Maybe<Array<OriginalRevenueMarketRentOverrideWhereUniqueInput>>;
  set?: Maybe<Array<OriginalRevenueMarketRentOverrideWhereUniqueInput>>;
  update?: Maybe<Array<OriginalRevenueMarketRentOverrideUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<OriginalRevenueMarketRentOverrideUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<OriginalRevenueMarketRentOverrideUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type OriginalRevenueMarketRentOverrideUpdateManyWithoutUnitTypeInput = {
  connect?: Maybe<Array<OriginalRevenueMarketRentOverrideWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OriginalRevenueMarketRentOverrideCreateOrConnectWithoutUnitTypeInput>>;
  create?: Maybe<Array<OriginalRevenueMarketRentOverrideCreateWithoutUnitTypeInput>>;
  createMany?: Maybe<OriginalRevenueMarketRentOverrideCreateManyUnitTypeInputEnvelope>;
  delete?: Maybe<Array<OriginalRevenueMarketRentOverrideWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OriginalRevenueMarketRentOverrideScalarWhereInput>>;
  disconnect?: Maybe<Array<OriginalRevenueMarketRentOverrideWhereUniqueInput>>;
  set?: Maybe<Array<OriginalRevenueMarketRentOverrideWhereUniqueInput>>;
  update?: Maybe<Array<OriginalRevenueMarketRentOverrideUpdateWithWhereUniqueWithoutUnitTypeInput>>;
  updateMany?: Maybe<Array<OriginalRevenueMarketRentOverrideUpdateManyWithWhereWithoutUnitTypeInput>>;
  upsert?: Maybe<Array<OriginalRevenueMarketRentOverrideUpsertWithWhereUniqueWithoutUnitTypeInput>>;
};

export type OriginalRevenueMarketRentOverrideUpdateWithWhereUniqueWithoutPropertyInput = {
  data: OriginalRevenueMarketRentOverrideUpdateWithoutPropertyInput;
  where: OriginalRevenueMarketRentOverrideWhereUniqueInput;
};

export type OriginalRevenueMarketRentOverrideUpdateWithWhereUniqueWithoutUnitTypeInput = {
  data: OriginalRevenueMarketRentOverrideUpdateWithoutUnitTypeInput;
  where: OriginalRevenueMarketRentOverrideWhereUniqueInput;
};

export type OriginalRevenueMarketRentOverrideUpdateWithoutPropertyInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  overrideMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  unitType?: Maybe<UnitTypeUpdateOneRequiredWithoutOriginalRevenueMarketRentOverridesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OriginalRevenueMarketRentOverrideUpdateWithoutUnitTypeInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  overrideMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  overrideMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutOriginalRevenueMarketRentOverridesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type OriginalRevenueMarketRentOverrideUpsertWithWhereUniqueWithoutPropertyInput = {
  create: OriginalRevenueMarketRentOverrideCreateWithoutPropertyInput;
  update: OriginalRevenueMarketRentOverrideUpdateWithoutPropertyInput;
  where: OriginalRevenueMarketRentOverrideWhereUniqueInput;
};

export type OriginalRevenueMarketRentOverrideUpsertWithWhereUniqueWithoutUnitTypeInput = {
  create: OriginalRevenueMarketRentOverrideCreateWithoutUnitTypeInput;
  update: OriginalRevenueMarketRentOverrideUpdateWithoutUnitTypeInput;
  where: OriginalRevenueMarketRentOverrideWhereUniqueInput;
};

export type OriginalRevenueMarketRentOverrideWhereInput = {
  AND?: Maybe<Array<OriginalRevenueMarketRentOverrideWhereInput>>;
  NOT?: Maybe<Array<OriginalRevenueMarketRentOverrideWhereInput>>;
  OR?: Maybe<Array<OriginalRevenueMarketRentOverrideWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  overrideMonth0?: Maybe<DecimalNullableFilter>;
  overrideMonth1?: Maybe<DecimalNullableFilter>;
  overrideMonth2?: Maybe<DecimalNullableFilter>;
  overrideMonth3?: Maybe<DecimalNullableFilter>;
  overrideMonth4?: Maybe<DecimalNullableFilter>;
  overrideMonth5?: Maybe<DecimalNullableFilter>;
  overrideMonth6?: Maybe<DecimalNullableFilter>;
  overrideMonth7?: Maybe<DecimalNullableFilter>;
  overrideMonth8?: Maybe<DecimalNullableFilter>;
  overrideMonth9?: Maybe<DecimalNullableFilter>;
  overrideMonth10?: Maybe<DecimalNullableFilter>;
  overrideMonth11?: Maybe<DecimalNullableFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  unitType?: Maybe<UnitTypeRelationFilter>;
  unitTypeId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type OriginalRevenueMarketRentOverrideWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  year_versionType_propertyId_unitTypeId?: Maybe<OriginalRevenueMarketRentOverrideYearVersionTypePropertyIdUnitTypeIdCompoundUniqueInput>;
};

export type OriginalRevenueMarketRentOverrideYearVersionTypePropertyIdUnitTypeIdCompoundUniqueInput = {
  propertyId: Scalars['String'];
  unitTypeId: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export type OriginalRevenueModel = {
  __typename?: 'OriginalRevenueModel';
  beginningGrossPotentialRent?: Maybe<Scalars['Float']>;
  beginningOccupancy?: Maybe<Scalars['Float']>;
  budgetYear: Scalars['Float'];
  earlyTerminationCount: OriginalRevenueForecastedNumbersModel;
  grossPotentialRent: OriginalRevenueForecastedNumbersModel;
  grossPotentialRentAndVacancyLoss: OriginalRevenueForecastedNumbersModel;
  lossToLease: OriginalRevenueForecastedNumbersModel;
  m2mMoveOutCount: OriginalRevenueForecastedNumbersModel;
  momLossToLeaseChange: OriginalRevenueForecastedNumbersModel;
  occupiedCount: Array<OriginalRevenueUnitTypeNumbersModel>;
  propertyId: Scalars['ID'];
  rent: OriginalRevenueForecastedNumbersModel;
  totalMoveInCount: OriginalRevenueForecastedNumbersModel;
  totalMoveOutCount: OriginalRevenueForecastedNumbersModel;
  unitTypeAvgMarketRent: Array<OriginalRevenueUnitTypeNumbersModel>;
  unitTypeBeginningOccupancy: Array<OriginalRevenueUnitTypeNumberModel>;
  unitTypeExpirationCount: Array<OriginalRevenueUnitTypeNumbersModel>;
  unitTypeInPlaceRent: Array<OriginalRevenueUnitTypeNumbersModel>;
  unitTypeMoveInCount: Array<OriginalRevenueUnitTypeNumbersModel>;
  unitTypeMoveOutCount: Array<OriginalRevenueUnitTypeNumbersModel>;
  unitTypeNewLeaseTradeOut: Array<OriginalRevenueUnitTypeNumbersModel>;
  unitTypeOccupiedCount: Array<OriginalRevenueUnitTypeNumbersModel>;
  unitTypeRenewalCount: Array<OriginalRevenueUnitTypeNumbersModel>;
  unitTypeRenewalTradeOut: Array<OriginalRevenueUnitTypeNumbersModel>;
  unitTypeStartingInPlaceRent: Array<OriginalRevenueUnitTypeNumberModel>;
  unitTypeUnitCount: Array<OriginalRevenueUnitTypeNumberModel>;
  unitTypeUnitCountMonthly: Array<OriginalRevenueUnitTypeNumbersModel>;
  unitTypeVacancyCount: Array<OriginalRevenueUnitTypeNumbersModel>;
  vacancyLoss: OriginalRevenueForecastedNumbersModel;
};

export type OriginalRevenuePropertyMarketRentModeType = {
  __typename?: 'OriginalRevenuePropertyMarketRentModeType';
  budgetYear: Scalars['Int'];
  id: Scalars['String'];
  marketRentModeType: OriginalRevenueMarketRentModeType;
  propertyId: Scalars['String'];
};

export type OriginalRevenuePropertyMarketRentModeTypeCreateManyPropertyInput = {
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  marketRentModeType: OriginalRevenueMarketRentModeType;
};

export type OriginalRevenuePropertyMarketRentModeTypeCreateManyPropertyInputEnvelope = {
  data: Array<OriginalRevenuePropertyMarketRentModeTypeCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<OriginalRevenuePropertyMarketRentModeTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OriginalRevenuePropertyMarketRentModeTypeCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<OriginalRevenuePropertyMarketRentModeTypeCreateWithoutPropertyInput>>;
  createMany?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateManyPropertyInputEnvelope>;
};

export type OriginalRevenuePropertyMarketRentModeTypeCreateOrConnectWithoutPropertyInput = {
  create: OriginalRevenuePropertyMarketRentModeTypeCreateWithoutPropertyInput;
  where: OriginalRevenuePropertyMarketRentModeTypeWhereUniqueInput;
};

export type OriginalRevenuePropertyMarketRentModeTypeCreateWithoutPropertyInput = {
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  marketRentModeType: OriginalRevenueMarketRentModeType;
};

export type OriginalRevenuePropertyMarketRentModeTypeListRelationFilter = {
  every?: Maybe<OriginalRevenuePropertyMarketRentModeTypeWhereInput>;
  none?: Maybe<OriginalRevenuePropertyMarketRentModeTypeWhereInput>;
  some?: Maybe<OriginalRevenuePropertyMarketRentModeTypeWhereInput>;
};

export type OriginalRevenuePropertyMarketRentModeTypeOrderByInput = {
  budgetYear?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  marketRentModeType?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
};

export type OriginalRevenuePropertyMarketRentModeTypePropertyIdBudgetYearCompoundUniqueInput = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['String'];
};

export enum OriginalRevenuePropertyMarketRentModeTypeScalarFieldEnum {
  BudgetYear = 'budgetYear',
  Id = 'id',
  MarketRentModeType = 'marketRentModeType',
  PropertyId = 'propertyId'
}

export type OriginalRevenuePropertyMarketRentModeTypeScalarWhereInput = {
  AND?: Maybe<Array<OriginalRevenuePropertyMarketRentModeTypeScalarWhereInput>>;
  NOT?: Maybe<Array<OriginalRevenuePropertyMarketRentModeTypeScalarWhereInput>>;
  OR?: Maybe<Array<OriginalRevenuePropertyMarketRentModeTypeScalarWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  marketRentModeType?: Maybe<EnumOriginalRevenueMarketRentModeTypeFilter>;
  propertyId?: Maybe<StringFilter>;
};

export type OriginalRevenuePropertyMarketRentModeTypeUpdateManyMutationInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  marketRentModeType?: Maybe<EnumOriginalRevenueMarketRentModeTypeFieldUpdateOperationsInput>;
};

export type OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithWhereWithoutPropertyInput = {
  data: OriginalRevenuePropertyMarketRentModeTypeUpdateManyMutationInput;
  where: OriginalRevenuePropertyMarketRentModeTypeScalarWhereInput;
};

export type OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<OriginalRevenuePropertyMarketRentModeTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OriginalRevenuePropertyMarketRentModeTypeCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<OriginalRevenuePropertyMarketRentModeTypeCreateWithoutPropertyInput>>;
  createMany?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<OriginalRevenuePropertyMarketRentModeTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OriginalRevenuePropertyMarketRentModeTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<OriginalRevenuePropertyMarketRentModeTypeWhereUniqueInput>>;
  set?: Maybe<Array<OriginalRevenuePropertyMarketRentModeTypeWhereUniqueInput>>;
  update?: Maybe<Array<OriginalRevenuePropertyMarketRentModeTypeUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<OriginalRevenuePropertyMarketRentModeTypeUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type OriginalRevenuePropertyMarketRentModeTypeUpdateWithWhereUniqueWithoutPropertyInput = {
  data: OriginalRevenuePropertyMarketRentModeTypeUpdateWithoutPropertyInput;
  where: OriginalRevenuePropertyMarketRentModeTypeWhereUniqueInput;
};

export type OriginalRevenuePropertyMarketRentModeTypeUpdateWithoutPropertyInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  marketRentModeType?: Maybe<EnumOriginalRevenueMarketRentModeTypeFieldUpdateOperationsInput>;
};

export type OriginalRevenuePropertyMarketRentModeTypeUpsertWithWhereUniqueWithoutPropertyInput = {
  create: OriginalRevenuePropertyMarketRentModeTypeCreateWithoutPropertyInput;
  update: OriginalRevenuePropertyMarketRentModeTypeUpdateWithoutPropertyInput;
  where: OriginalRevenuePropertyMarketRentModeTypeWhereUniqueInput;
};

export type OriginalRevenuePropertyMarketRentModeTypeWhereInput = {
  AND?: Maybe<Array<OriginalRevenuePropertyMarketRentModeTypeWhereInput>>;
  NOT?: Maybe<Array<OriginalRevenuePropertyMarketRentModeTypeWhereInput>>;
  OR?: Maybe<Array<OriginalRevenuePropertyMarketRentModeTypeWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  marketRentModeType?: Maybe<EnumOriginalRevenueMarketRentModeTypeFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
};

export type OriginalRevenuePropertyMarketRentModeTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  propertyId_budgetYear?: Maybe<OriginalRevenuePropertyMarketRentModeTypePropertyIdBudgetYearCompoundUniqueInput>;
};

export type OriginalRevenuePropertyRenewalIncreasePercent = {
  __typename?: 'OriginalRevenuePropertyRenewalIncreasePercent';
  budgetYear: Scalars['Int'];
  id: Scalars['String'];
  percent?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  type?: Maybe<OriginalRevenueRenewalIncreaseType>;
};

export type OriginalRevenuePropertyRenewalIncreasePercentCreateManyPropertyInput = {
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  percent?: Maybe<Scalars['Decimal']>;
  type?: Maybe<OriginalRevenueRenewalIncreaseType>;
};

export type OriginalRevenuePropertyRenewalIncreasePercentCreateManyPropertyInputEnvelope = {
  data: Array<OriginalRevenuePropertyRenewalIncreasePercentCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<OriginalRevenuePropertyRenewalIncreasePercentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OriginalRevenuePropertyRenewalIncreasePercentCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<OriginalRevenuePropertyRenewalIncreasePercentCreateWithoutPropertyInput>>;
  createMany?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateManyPropertyInputEnvelope>;
};

export type OriginalRevenuePropertyRenewalIncreasePercentCreateOrConnectWithoutPropertyInput = {
  create: OriginalRevenuePropertyRenewalIncreasePercentCreateWithoutPropertyInput;
  where: OriginalRevenuePropertyRenewalIncreasePercentWhereUniqueInput;
};

export type OriginalRevenuePropertyRenewalIncreasePercentCreateWithoutPropertyInput = {
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  percent?: Maybe<Scalars['Decimal']>;
  type?: Maybe<OriginalRevenueRenewalIncreaseType>;
};

export type OriginalRevenuePropertyRenewalIncreasePercentListRelationFilter = {
  every?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentWhereInput>;
  none?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentWhereInput>;
  some?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentWhereInput>;
};

export type OriginalRevenuePropertyRenewalIncreasePercentModel = {
  __typename?: 'OriginalRevenuePropertyRenewalIncreasePercentModel';
  percent?: Maybe<Scalars['Float']>;
  type?: Maybe<OriginalRevenueRenewalIncreaseType>;
};

export type OriginalRevenuePropertyRenewalIncreasePercentOrderByInput = {
  budgetYear?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  percent?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type OriginalRevenuePropertyRenewalIncreasePercentPropertyIdBudgetYearCompoundUniqueInput = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['String'];
};

export enum OriginalRevenuePropertyRenewalIncreasePercentScalarFieldEnum {
  BudgetYear = 'budgetYear',
  Id = 'id',
  Percent = 'percent',
  PropertyId = 'propertyId',
  Type = 'type'
}

export type OriginalRevenuePropertyRenewalIncreasePercentScalarWhereInput = {
  AND?: Maybe<Array<OriginalRevenuePropertyRenewalIncreasePercentScalarWhereInput>>;
  NOT?: Maybe<Array<OriginalRevenuePropertyRenewalIncreasePercentScalarWhereInput>>;
  OR?: Maybe<Array<OriginalRevenuePropertyRenewalIncreasePercentScalarWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  percent?: Maybe<DecimalNullableFilter>;
  propertyId?: Maybe<StringFilter>;
  type?: Maybe<EnumOriginalRevenueRenewalIncreaseTypeNullableFilter>;
};

export type OriginalRevenuePropertyRenewalIncreasePercentUpdateManyMutationInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  percent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  type?: Maybe<NullableEnumOriginalRevenueRenewalIncreaseTypeFieldUpdateOperationsInput>;
};

export type OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithWhereWithoutPropertyInput = {
  data: OriginalRevenuePropertyRenewalIncreasePercentUpdateManyMutationInput;
  where: OriginalRevenuePropertyRenewalIncreasePercentScalarWhereInput;
};

export type OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<OriginalRevenuePropertyRenewalIncreasePercentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<OriginalRevenuePropertyRenewalIncreasePercentCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<OriginalRevenuePropertyRenewalIncreasePercentCreateWithoutPropertyInput>>;
  createMany?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<OriginalRevenuePropertyRenewalIncreasePercentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<OriginalRevenuePropertyRenewalIncreasePercentScalarWhereInput>>;
  disconnect?: Maybe<Array<OriginalRevenuePropertyRenewalIncreasePercentWhereUniqueInput>>;
  set?: Maybe<Array<OriginalRevenuePropertyRenewalIncreasePercentWhereUniqueInput>>;
  update?: Maybe<Array<OriginalRevenuePropertyRenewalIncreasePercentUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<OriginalRevenuePropertyRenewalIncreasePercentUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type OriginalRevenuePropertyRenewalIncreasePercentUpdateWithWhereUniqueWithoutPropertyInput = {
  data: OriginalRevenuePropertyRenewalIncreasePercentUpdateWithoutPropertyInput;
  where: OriginalRevenuePropertyRenewalIncreasePercentWhereUniqueInput;
};

export type OriginalRevenuePropertyRenewalIncreasePercentUpdateWithoutPropertyInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  percent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  type?: Maybe<NullableEnumOriginalRevenueRenewalIncreaseTypeFieldUpdateOperationsInput>;
};

export type OriginalRevenuePropertyRenewalIncreasePercentUpsertWithWhereUniqueWithoutPropertyInput = {
  create: OriginalRevenuePropertyRenewalIncreasePercentCreateWithoutPropertyInput;
  update: OriginalRevenuePropertyRenewalIncreasePercentUpdateWithoutPropertyInput;
  where: OriginalRevenuePropertyRenewalIncreasePercentWhereUniqueInput;
};

export type OriginalRevenuePropertyRenewalIncreasePercentWhereInput = {
  AND?: Maybe<Array<OriginalRevenuePropertyRenewalIncreasePercentWhereInput>>;
  NOT?: Maybe<Array<OriginalRevenuePropertyRenewalIncreasePercentWhereInput>>;
  OR?: Maybe<Array<OriginalRevenuePropertyRenewalIncreasePercentWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  percent?: Maybe<DecimalNullableFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  type?: Maybe<EnumOriginalRevenueRenewalIncreaseTypeNullableFilter>;
};

export type OriginalRevenuePropertyRenewalIncreasePercentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  propertyId_budgetYear?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentPropertyIdBudgetYearCompoundUniqueInput>;
};

export enum OriginalRevenueRenewalIncreaseType {
  MarketOrInPlaceRent = 'MARKET_OR_IN_PLACE_RENT',
  MarketRentDiscount = 'MARKET_RENT_DISCOUNT'
}

export type OriginalRevenueUnitTypeCalculatedExpirationsModel = {
  __typename?: 'OriginalRevenueUnitTypeCalculatedExpirationsModel';
  budgetExpirations: Array<Scalars['Int']>;
  propertyId: Scalars['ID'];
  reforecastExpirations: Array<Scalars['Int']>;
  unitTypeId: Scalars['ID'];
  unitTypeName: Scalars['String'];
};

export type OriginalRevenueUnitTypeMarketRentOverridesModel = {
  __typename?: 'OriginalRevenueUnitTypeMarketRentOverridesModel';
  overrides: Array<Maybe<Scalars['Float']>>;
  propertyId: Scalars['ID'];
  unitTypeId: Scalars['ID'];
  unitTypeName: Scalars['String'];
};

export type OriginalRevenueUnitTypeMarketRentValuesModel = {
  __typename?: 'OriginalRevenueUnitTypeMarketRentValuesModel';
  actuals: Array<Maybe<Scalars['Float']>>;
  budget: Array<Maybe<Scalars['Float']>>;
  propertyId: Scalars['ID'];
  reforecast: Array<Maybe<Scalars['Float']>>;
  unitTypeId: Scalars['ID'];
  unitTypeName: Scalars['String'];
};

export type OriginalRevenueUnitTypeNumberModel = {
  __typename?: 'OriginalRevenueUnitTypeNumberModel';
  unitTypeId: Scalars['ID'];
  unitTypeName: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
};

export type OriginalRevenueUnitTypeNumbersModel = {
  __typename?: 'OriginalRevenueUnitTypeNumbersModel';
  actuals: Array<Maybe<Scalars['Float']>>;
  budget: Array<Maybe<Scalars['Float']>>;
  reforecast: Array<Maybe<Scalars['Float']>>;
  unitTypeId: Scalars['ID'];
  unitTypeName: Scalars['String'];
};

export type PmsAccountSnapshot = {
  __typename?: 'PMSAccountSnapshot';
  glName: Scalars['String'];
  glNumber: Scalars['String'];
  order: Scalars['Int'];
  seenEarliest: Scalars['DateNoTime'];
  seenLatest: Scalars['DateNoTime'];
  type: Scalars['String'];
};

export type PmsActualMetricOverrideAverageResidentialRent = {
  __typename?: 'PMSActualMetricOverrideAverageResidentialRent';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  propertyId: Scalars['String'];
  unitTypeId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  year: Scalars['Int'];
};

export type PmsActualMetricOverrideAverageResidentialRentCreateManyPropertyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  unitTypeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  year: Scalars['Int'];
};

export type PmsActualMetricOverrideAverageResidentialRentCreateManyPropertyInputEnvelope = {
  data: Array<PmsActualMetricOverrideAverageResidentialRentCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PmsActualMetricOverrideAverageResidentialRentCreateManyUnitTypeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  year: Scalars['Int'];
};

export type PmsActualMetricOverrideAverageResidentialRentCreateManyUnitTypeInputEnvelope = {
  data: Array<PmsActualMetricOverrideAverageResidentialRentCreateManyUnitTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentCreateWithoutPropertyInput>>;
  createMany?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateManyPropertyInputEnvelope>;
};

export type PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutUnitTypeInput = {
  connect?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentCreateOrConnectWithoutUnitTypeInput>>;
  create?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentCreateWithoutUnitTypeInput>>;
  createMany?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateManyUnitTypeInputEnvelope>;
};

export type PmsActualMetricOverrideAverageResidentialRentCreateOrConnectWithoutPropertyInput = {
  create: PmsActualMetricOverrideAverageResidentialRentCreateWithoutPropertyInput;
  where: PmsActualMetricOverrideAverageResidentialRentWhereUniqueInput;
};

export type PmsActualMetricOverrideAverageResidentialRentCreateOrConnectWithoutUnitTypeInput = {
  create: PmsActualMetricOverrideAverageResidentialRentCreateWithoutUnitTypeInput;
  where: PmsActualMetricOverrideAverageResidentialRentWhereUniqueInput;
};

export type PmsActualMetricOverrideAverageResidentialRentCreateWithoutPropertyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  unitType: UnitTypeCreateNestedOneWithoutPmsActualMetricOverrideAverageResidentialRentsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  year: Scalars['Int'];
};

export type PmsActualMetricOverrideAverageResidentialRentCreateWithoutUnitTypeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  property: PropertyCreateNestedOneWithoutPmsActualMetricOverrideAverageResidentialRentsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  year: Scalars['Int'];
};

export type PmsActualMetricOverrideAverageResidentialRentListRelationFilter = {
  every?: Maybe<PmsActualMetricOverrideAverageResidentialRentWhereInput>;
  none?: Maybe<PmsActualMetricOverrideAverageResidentialRentWhereInput>;
  some?: Maybe<PmsActualMetricOverrideAverageResidentialRentWhereInput>;
};

export type PmsActualMetricOverrideAverageResidentialRentOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  unitTypeId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  valueMonth0?: Maybe<SortOrder>;
  valueMonth1?: Maybe<SortOrder>;
  valueMonth2?: Maybe<SortOrder>;
  valueMonth3?: Maybe<SortOrder>;
  valueMonth4?: Maybe<SortOrder>;
  valueMonth5?: Maybe<SortOrder>;
  valueMonth6?: Maybe<SortOrder>;
  valueMonth7?: Maybe<SortOrder>;
  valueMonth8?: Maybe<SortOrder>;
  valueMonth9?: Maybe<SortOrder>;
  valueMonth10?: Maybe<SortOrder>;
  valueMonth11?: Maybe<SortOrder>;
  year?: Maybe<SortOrder>;
};

export type PmsActualMetricOverrideAverageResidentialRentPropertyIdUnitTypeIdYearCompoundUniqueInput = {
  propertyId: Scalars['String'];
  unitTypeId: Scalars['String'];
  year: Scalars['Int'];
};

export enum PmsActualMetricOverrideAverageResidentialRentScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  PropertyId = 'propertyId',
  UnitTypeId = 'unitTypeId',
  UpdatedAt = 'updatedAt',
  ValueMonth0 = 'valueMonth0',
  ValueMonth1 = 'valueMonth1',
  ValueMonth2 = 'valueMonth2',
  ValueMonth3 = 'valueMonth3',
  ValueMonth4 = 'valueMonth4',
  ValueMonth5 = 'valueMonth5',
  ValueMonth6 = 'valueMonth6',
  ValueMonth7 = 'valueMonth7',
  ValueMonth8 = 'valueMonth8',
  ValueMonth9 = 'valueMonth9',
  ValueMonth10 = 'valueMonth10',
  ValueMonth11 = 'valueMonth11',
  Year = 'year'
}

export type PmsActualMetricOverrideAverageResidentialRentScalarWhereInput = {
  AND?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentScalarWhereInput>>;
  NOT?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentScalarWhereInput>>;
  OR?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  unitTypeId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  valueMonth0?: Maybe<DecimalNullableFilter>;
  valueMonth1?: Maybe<DecimalNullableFilter>;
  valueMonth2?: Maybe<DecimalNullableFilter>;
  valueMonth3?: Maybe<DecimalNullableFilter>;
  valueMonth4?: Maybe<DecimalNullableFilter>;
  valueMonth5?: Maybe<DecimalNullableFilter>;
  valueMonth6?: Maybe<DecimalNullableFilter>;
  valueMonth7?: Maybe<DecimalNullableFilter>;
  valueMonth8?: Maybe<DecimalNullableFilter>;
  valueMonth9?: Maybe<DecimalNullableFilter>;
  valueMonth10?: Maybe<DecimalNullableFilter>;
  valueMonth11?: Maybe<DecimalNullableFilter>;
  year?: Maybe<IntFilter>;
};

export type PmsActualMetricOverrideAverageResidentialRentUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  valueMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PmsActualMetricOverrideAverageResidentialRentUpdateManyWithWhereWithoutPropertyInput = {
  data: PmsActualMetricOverrideAverageResidentialRentUpdateManyMutationInput;
  where: PmsActualMetricOverrideAverageResidentialRentScalarWhereInput;
};

export type PmsActualMetricOverrideAverageResidentialRentUpdateManyWithWhereWithoutUnitTypeInput = {
  data: PmsActualMetricOverrideAverageResidentialRentUpdateManyMutationInput;
  where: PmsActualMetricOverrideAverageResidentialRentScalarWhereInput;
};

export type PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentCreateWithoutPropertyInput>>;
  createMany?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentScalarWhereInput>>;
  disconnect?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentWhereUniqueInput>>;
  set?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentWhereUniqueInput>>;
  update?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutUnitTypeInput = {
  connect?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentCreateOrConnectWithoutUnitTypeInput>>;
  create?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentCreateWithoutUnitTypeInput>>;
  createMany?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateManyUnitTypeInputEnvelope>;
  delete?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentScalarWhereInput>>;
  disconnect?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentWhereUniqueInput>>;
  set?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentWhereUniqueInput>>;
  update?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentUpdateWithWhereUniqueWithoutUnitTypeInput>>;
  updateMany?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithWhereWithoutUnitTypeInput>>;
  upsert?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentUpsertWithWhereUniqueWithoutUnitTypeInput>>;
};

export type PmsActualMetricOverrideAverageResidentialRentUpdateWithWhereUniqueWithoutPropertyInput = {
  data: PmsActualMetricOverrideAverageResidentialRentUpdateWithoutPropertyInput;
  where: PmsActualMetricOverrideAverageResidentialRentWhereUniqueInput;
};

export type PmsActualMetricOverrideAverageResidentialRentUpdateWithWhereUniqueWithoutUnitTypeInput = {
  data: PmsActualMetricOverrideAverageResidentialRentUpdateWithoutUnitTypeInput;
  where: PmsActualMetricOverrideAverageResidentialRentWhereUniqueInput;
};

export type PmsActualMetricOverrideAverageResidentialRentUpdateWithoutPropertyInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  unitType?: Maybe<UnitTypeUpdateOneRequiredWithoutPmsActualMetricOverrideAverageResidentialRentsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  valueMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PmsActualMetricOverrideAverageResidentialRentUpdateWithoutUnitTypeInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutPmsActualMetricOverrideAverageResidentialRentsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  valueMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PmsActualMetricOverrideAverageResidentialRentUpsertWithWhereUniqueWithoutPropertyInput = {
  create: PmsActualMetricOverrideAverageResidentialRentCreateWithoutPropertyInput;
  update: PmsActualMetricOverrideAverageResidentialRentUpdateWithoutPropertyInput;
  where: PmsActualMetricOverrideAverageResidentialRentWhereUniqueInput;
};

export type PmsActualMetricOverrideAverageResidentialRentUpsertWithWhereUniqueWithoutUnitTypeInput = {
  create: PmsActualMetricOverrideAverageResidentialRentCreateWithoutUnitTypeInput;
  update: PmsActualMetricOverrideAverageResidentialRentUpdateWithoutUnitTypeInput;
  where: PmsActualMetricOverrideAverageResidentialRentWhereUniqueInput;
};

export type PmsActualMetricOverrideAverageResidentialRentWhereInput = {
  AND?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentWhereInput>>;
  NOT?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentWhereInput>>;
  OR?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  unitType?: Maybe<UnitTypeRelationFilter>;
  unitTypeId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  valueMonth0?: Maybe<DecimalNullableFilter>;
  valueMonth1?: Maybe<DecimalNullableFilter>;
  valueMonth2?: Maybe<DecimalNullableFilter>;
  valueMonth3?: Maybe<DecimalNullableFilter>;
  valueMonth4?: Maybe<DecimalNullableFilter>;
  valueMonth5?: Maybe<DecimalNullableFilter>;
  valueMonth6?: Maybe<DecimalNullableFilter>;
  valueMonth7?: Maybe<DecimalNullableFilter>;
  valueMonth8?: Maybe<DecimalNullableFilter>;
  valueMonth9?: Maybe<DecimalNullableFilter>;
  valueMonth10?: Maybe<DecimalNullableFilter>;
  valueMonth11?: Maybe<DecimalNullableFilter>;
  year?: Maybe<IntFilter>;
};

export type PmsActualMetricOverrideAverageResidentialRentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  propertyId_unitTypeId_year?: Maybe<PmsActualMetricOverrideAverageResidentialRentPropertyIdUnitTypeIdYearCompoundUniqueInput>;
};

export type PmsActualMetricOverrideOccupancyRate = {
  __typename?: 'PMSActualMetricOverrideOccupancyRate';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  propertyId: Scalars['String'];
  unitTypeId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  year: Scalars['Int'];
};

export type PmsActualMetricOverrideOccupancyRateCreateManyPropertyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  unitTypeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  year: Scalars['Int'];
};

export type PmsActualMetricOverrideOccupancyRateCreateManyPropertyInputEnvelope = {
  data: Array<PmsActualMetricOverrideOccupancyRateCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PmsActualMetricOverrideOccupancyRateCreateManyUnitTypeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  year: Scalars['Int'];
};

export type PmsActualMetricOverrideOccupancyRateCreateManyUnitTypeInputEnvelope = {
  data: Array<PmsActualMetricOverrideOccupancyRateCreateManyUnitTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<PmsActualMetricOverrideOccupancyRateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PmsActualMetricOverrideOccupancyRateCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PmsActualMetricOverrideOccupancyRateCreateWithoutPropertyInput>>;
  createMany?: Maybe<PmsActualMetricOverrideOccupancyRateCreateManyPropertyInputEnvelope>;
};

export type PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutUnitTypeInput = {
  connect?: Maybe<Array<PmsActualMetricOverrideOccupancyRateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PmsActualMetricOverrideOccupancyRateCreateOrConnectWithoutUnitTypeInput>>;
  create?: Maybe<Array<PmsActualMetricOverrideOccupancyRateCreateWithoutUnitTypeInput>>;
  createMany?: Maybe<PmsActualMetricOverrideOccupancyRateCreateManyUnitTypeInputEnvelope>;
};

export type PmsActualMetricOverrideOccupancyRateCreateOrConnectWithoutPropertyInput = {
  create: PmsActualMetricOverrideOccupancyRateCreateWithoutPropertyInput;
  where: PmsActualMetricOverrideOccupancyRateWhereUniqueInput;
};

export type PmsActualMetricOverrideOccupancyRateCreateOrConnectWithoutUnitTypeInput = {
  create: PmsActualMetricOverrideOccupancyRateCreateWithoutUnitTypeInput;
  where: PmsActualMetricOverrideOccupancyRateWhereUniqueInput;
};

export type PmsActualMetricOverrideOccupancyRateCreateWithoutPropertyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  unitType: UnitTypeCreateNestedOneWithoutPmsActualMetricOverrideOccupancyRatesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  year: Scalars['Int'];
};

export type PmsActualMetricOverrideOccupancyRateCreateWithoutUnitTypeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  property: PropertyCreateNestedOneWithoutPmsActualMetricOverrideOccupancyRatesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  year: Scalars['Int'];
};

export type PmsActualMetricOverrideOccupancyRateListRelationFilter = {
  every?: Maybe<PmsActualMetricOverrideOccupancyRateWhereInput>;
  none?: Maybe<PmsActualMetricOverrideOccupancyRateWhereInput>;
  some?: Maybe<PmsActualMetricOverrideOccupancyRateWhereInput>;
};

export type PmsActualMetricOverrideOccupancyRateOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  unitTypeId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  valueMonth0?: Maybe<SortOrder>;
  valueMonth1?: Maybe<SortOrder>;
  valueMonth2?: Maybe<SortOrder>;
  valueMonth3?: Maybe<SortOrder>;
  valueMonth4?: Maybe<SortOrder>;
  valueMonth5?: Maybe<SortOrder>;
  valueMonth6?: Maybe<SortOrder>;
  valueMonth7?: Maybe<SortOrder>;
  valueMonth8?: Maybe<SortOrder>;
  valueMonth9?: Maybe<SortOrder>;
  valueMonth10?: Maybe<SortOrder>;
  valueMonth11?: Maybe<SortOrder>;
  year?: Maybe<SortOrder>;
};

export type PmsActualMetricOverrideOccupancyRatePropertyIdUnitTypeIdYearCompoundUniqueInput = {
  propertyId: Scalars['String'];
  unitTypeId: Scalars['String'];
  year: Scalars['Int'];
};

export enum PmsActualMetricOverrideOccupancyRateScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  PropertyId = 'propertyId',
  UnitTypeId = 'unitTypeId',
  UpdatedAt = 'updatedAt',
  ValueMonth0 = 'valueMonth0',
  ValueMonth1 = 'valueMonth1',
  ValueMonth2 = 'valueMonth2',
  ValueMonth3 = 'valueMonth3',
  ValueMonth4 = 'valueMonth4',
  ValueMonth5 = 'valueMonth5',
  ValueMonth6 = 'valueMonth6',
  ValueMonth7 = 'valueMonth7',
  ValueMonth8 = 'valueMonth8',
  ValueMonth9 = 'valueMonth9',
  ValueMonth10 = 'valueMonth10',
  ValueMonth11 = 'valueMonth11',
  Year = 'year'
}

export type PmsActualMetricOverrideOccupancyRateScalarWhereInput = {
  AND?: Maybe<Array<PmsActualMetricOverrideOccupancyRateScalarWhereInput>>;
  NOT?: Maybe<Array<PmsActualMetricOverrideOccupancyRateScalarWhereInput>>;
  OR?: Maybe<Array<PmsActualMetricOverrideOccupancyRateScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  unitTypeId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  valueMonth0?: Maybe<DecimalNullableFilter>;
  valueMonth1?: Maybe<DecimalNullableFilter>;
  valueMonth2?: Maybe<DecimalNullableFilter>;
  valueMonth3?: Maybe<DecimalNullableFilter>;
  valueMonth4?: Maybe<DecimalNullableFilter>;
  valueMonth5?: Maybe<DecimalNullableFilter>;
  valueMonth6?: Maybe<DecimalNullableFilter>;
  valueMonth7?: Maybe<DecimalNullableFilter>;
  valueMonth8?: Maybe<DecimalNullableFilter>;
  valueMonth9?: Maybe<DecimalNullableFilter>;
  valueMonth10?: Maybe<DecimalNullableFilter>;
  valueMonth11?: Maybe<DecimalNullableFilter>;
  year?: Maybe<IntFilter>;
};

export type PmsActualMetricOverrideOccupancyRateUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  valueMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PmsActualMetricOverrideOccupancyRateUpdateManyWithWhereWithoutPropertyInput = {
  data: PmsActualMetricOverrideOccupancyRateUpdateManyMutationInput;
  where: PmsActualMetricOverrideOccupancyRateScalarWhereInput;
};

export type PmsActualMetricOverrideOccupancyRateUpdateManyWithWhereWithoutUnitTypeInput = {
  data: PmsActualMetricOverrideOccupancyRateUpdateManyMutationInput;
  where: PmsActualMetricOverrideOccupancyRateScalarWhereInput;
};

export type PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<PmsActualMetricOverrideOccupancyRateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PmsActualMetricOverrideOccupancyRateCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PmsActualMetricOverrideOccupancyRateCreateWithoutPropertyInput>>;
  createMany?: Maybe<PmsActualMetricOverrideOccupancyRateCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<PmsActualMetricOverrideOccupancyRateWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PmsActualMetricOverrideOccupancyRateScalarWhereInput>>;
  disconnect?: Maybe<Array<PmsActualMetricOverrideOccupancyRateWhereUniqueInput>>;
  set?: Maybe<Array<PmsActualMetricOverrideOccupancyRateWhereUniqueInput>>;
  update?: Maybe<Array<PmsActualMetricOverrideOccupancyRateUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<PmsActualMetricOverrideOccupancyRateUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<PmsActualMetricOverrideOccupancyRateUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type PmsActualMetricOverrideOccupancyRateUpdateManyWithoutUnitTypeInput = {
  connect?: Maybe<Array<PmsActualMetricOverrideOccupancyRateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PmsActualMetricOverrideOccupancyRateCreateOrConnectWithoutUnitTypeInput>>;
  create?: Maybe<Array<PmsActualMetricOverrideOccupancyRateCreateWithoutUnitTypeInput>>;
  createMany?: Maybe<PmsActualMetricOverrideOccupancyRateCreateManyUnitTypeInputEnvelope>;
  delete?: Maybe<Array<PmsActualMetricOverrideOccupancyRateWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PmsActualMetricOverrideOccupancyRateScalarWhereInput>>;
  disconnect?: Maybe<Array<PmsActualMetricOverrideOccupancyRateWhereUniqueInput>>;
  set?: Maybe<Array<PmsActualMetricOverrideOccupancyRateWhereUniqueInput>>;
  update?: Maybe<Array<PmsActualMetricOverrideOccupancyRateUpdateWithWhereUniqueWithoutUnitTypeInput>>;
  updateMany?: Maybe<Array<PmsActualMetricOverrideOccupancyRateUpdateManyWithWhereWithoutUnitTypeInput>>;
  upsert?: Maybe<Array<PmsActualMetricOverrideOccupancyRateUpsertWithWhereUniqueWithoutUnitTypeInput>>;
};

export type PmsActualMetricOverrideOccupancyRateUpdateWithWhereUniqueWithoutPropertyInput = {
  data: PmsActualMetricOverrideOccupancyRateUpdateWithoutPropertyInput;
  where: PmsActualMetricOverrideOccupancyRateWhereUniqueInput;
};

export type PmsActualMetricOverrideOccupancyRateUpdateWithWhereUniqueWithoutUnitTypeInput = {
  data: PmsActualMetricOverrideOccupancyRateUpdateWithoutUnitTypeInput;
  where: PmsActualMetricOverrideOccupancyRateWhereUniqueInput;
};

export type PmsActualMetricOverrideOccupancyRateUpdateWithoutPropertyInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  unitType?: Maybe<UnitTypeUpdateOneRequiredWithoutPmsActualMetricOverrideOccupancyRatesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  valueMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PmsActualMetricOverrideOccupancyRateUpdateWithoutUnitTypeInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutPmsActualMetricOverrideOccupancyRatesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  valueMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PmsActualMetricOverrideOccupancyRateUpsertWithWhereUniqueWithoutPropertyInput = {
  create: PmsActualMetricOverrideOccupancyRateCreateWithoutPropertyInput;
  update: PmsActualMetricOverrideOccupancyRateUpdateWithoutPropertyInput;
  where: PmsActualMetricOverrideOccupancyRateWhereUniqueInput;
};

export type PmsActualMetricOverrideOccupancyRateUpsertWithWhereUniqueWithoutUnitTypeInput = {
  create: PmsActualMetricOverrideOccupancyRateCreateWithoutUnitTypeInput;
  update: PmsActualMetricOverrideOccupancyRateUpdateWithoutUnitTypeInput;
  where: PmsActualMetricOverrideOccupancyRateWhereUniqueInput;
};

export type PmsActualMetricOverrideOccupancyRateWhereInput = {
  AND?: Maybe<Array<PmsActualMetricOverrideOccupancyRateWhereInput>>;
  NOT?: Maybe<Array<PmsActualMetricOverrideOccupancyRateWhereInput>>;
  OR?: Maybe<Array<PmsActualMetricOverrideOccupancyRateWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  unitType?: Maybe<UnitTypeRelationFilter>;
  unitTypeId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  valueMonth0?: Maybe<DecimalNullableFilter>;
  valueMonth1?: Maybe<DecimalNullableFilter>;
  valueMonth2?: Maybe<DecimalNullableFilter>;
  valueMonth3?: Maybe<DecimalNullableFilter>;
  valueMonth4?: Maybe<DecimalNullableFilter>;
  valueMonth5?: Maybe<DecimalNullableFilter>;
  valueMonth6?: Maybe<DecimalNullableFilter>;
  valueMonth7?: Maybe<DecimalNullableFilter>;
  valueMonth8?: Maybe<DecimalNullableFilter>;
  valueMonth9?: Maybe<DecimalNullableFilter>;
  valueMonth10?: Maybe<DecimalNullableFilter>;
  valueMonth11?: Maybe<DecimalNullableFilter>;
  year?: Maybe<IntFilter>;
};

export type PmsActualMetricOverrideOccupancyRateWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  propertyId_unitTypeId_year?: Maybe<PmsActualMetricOverrideOccupancyRatePropertyIdUnitTypeIdYearCompoundUniqueInput>;
};

export type PmsActualMetricOverrideUnitCount = {
  __typename?: 'PMSActualMetricOverrideUnitCount';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  propertyId: Scalars['String'];
  unitTypeId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  year: Scalars['Int'];
};

export type PmsActualMetricOverrideUnitCountCreateManyPropertyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  unitTypeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  year: Scalars['Int'];
};

export type PmsActualMetricOverrideUnitCountCreateManyPropertyInputEnvelope = {
  data: Array<PmsActualMetricOverrideUnitCountCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PmsActualMetricOverrideUnitCountCreateManyUnitTypeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  year: Scalars['Int'];
};

export type PmsActualMetricOverrideUnitCountCreateManyUnitTypeInputEnvelope = {
  data: Array<PmsActualMetricOverrideUnitCountCreateManyUnitTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<PmsActualMetricOverrideUnitCountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PmsActualMetricOverrideUnitCountCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PmsActualMetricOverrideUnitCountCreateWithoutPropertyInput>>;
  createMany?: Maybe<PmsActualMetricOverrideUnitCountCreateManyPropertyInputEnvelope>;
};

export type PmsActualMetricOverrideUnitCountCreateNestedManyWithoutUnitTypeInput = {
  connect?: Maybe<Array<PmsActualMetricOverrideUnitCountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PmsActualMetricOverrideUnitCountCreateOrConnectWithoutUnitTypeInput>>;
  create?: Maybe<Array<PmsActualMetricOverrideUnitCountCreateWithoutUnitTypeInput>>;
  createMany?: Maybe<PmsActualMetricOverrideUnitCountCreateManyUnitTypeInputEnvelope>;
};

export type PmsActualMetricOverrideUnitCountCreateOrConnectWithoutPropertyInput = {
  create: PmsActualMetricOverrideUnitCountCreateWithoutPropertyInput;
  where: PmsActualMetricOverrideUnitCountWhereUniqueInput;
};

export type PmsActualMetricOverrideUnitCountCreateOrConnectWithoutUnitTypeInput = {
  create: PmsActualMetricOverrideUnitCountCreateWithoutUnitTypeInput;
  where: PmsActualMetricOverrideUnitCountWhereUniqueInput;
};

export type PmsActualMetricOverrideUnitCountCreateWithoutPropertyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  unitType: UnitTypeCreateNestedOneWithoutPmsActualMetricOverrideUnitCountsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  year: Scalars['Int'];
};

export type PmsActualMetricOverrideUnitCountCreateWithoutUnitTypeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  property: PropertyCreateNestedOneWithoutPmsActualMetricOverrideUnitCountsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  year: Scalars['Int'];
};

export type PmsActualMetricOverrideUnitCountListRelationFilter = {
  every?: Maybe<PmsActualMetricOverrideUnitCountWhereInput>;
  none?: Maybe<PmsActualMetricOverrideUnitCountWhereInput>;
  some?: Maybe<PmsActualMetricOverrideUnitCountWhereInput>;
};

export type PmsActualMetricOverrideUnitCountOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  unitTypeId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  valueMonth0?: Maybe<SortOrder>;
  valueMonth1?: Maybe<SortOrder>;
  valueMonth2?: Maybe<SortOrder>;
  valueMonth3?: Maybe<SortOrder>;
  valueMonth4?: Maybe<SortOrder>;
  valueMonth5?: Maybe<SortOrder>;
  valueMonth6?: Maybe<SortOrder>;
  valueMonth7?: Maybe<SortOrder>;
  valueMonth8?: Maybe<SortOrder>;
  valueMonth9?: Maybe<SortOrder>;
  valueMonth10?: Maybe<SortOrder>;
  valueMonth11?: Maybe<SortOrder>;
  year?: Maybe<SortOrder>;
};

export type PmsActualMetricOverrideUnitCountPropertyIdUnitTypeIdYearCompoundUniqueInput = {
  propertyId: Scalars['String'];
  unitTypeId: Scalars['String'];
  year: Scalars['Int'];
};

export enum PmsActualMetricOverrideUnitCountScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  PropertyId = 'propertyId',
  UnitTypeId = 'unitTypeId',
  UpdatedAt = 'updatedAt',
  ValueMonth0 = 'valueMonth0',
  ValueMonth1 = 'valueMonth1',
  ValueMonth2 = 'valueMonth2',
  ValueMonth3 = 'valueMonth3',
  ValueMonth4 = 'valueMonth4',
  ValueMonth5 = 'valueMonth5',
  ValueMonth6 = 'valueMonth6',
  ValueMonth7 = 'valueMonth7',
  ValueMonth8 = 'valueMonth8',
  ValueMonth9 = 'valueMonth9',
  ValueMonth10 = 'valueMonth10',
  ValueMonth11 = 'valueMonth11',
  Year = 'year'
}

export type PmsActualMetricOverrideUnitCountScalarWhereInput = {
  AND?: Maybe<Array<PmsActualMetricOverrideUnitCountScalarWhereInput>>;
  NOT?: Maybe<Array<PmsActualMetricOverrideUnitCountScalarWhereInput>>;
  OR?: Maybe<Array<PmsActualMetricOverrideUnitCountScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  unitTypeId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  valueMonth0?: Maybe<DecimalNullableFilter>;
  valueMonth1?: Maybe<DecimalNullableFilter>;
  valueMonth2?: Maybe<DecimalNullableFilter>;
  valueMonth3?: Maybe<DecimalNullableFilter>;
  valueMonth4?: Maybe<DecimalNullableFilter>;
  valueMonth5?: Maybe<DecimalNullableFilter>;
  valueMonth6?: Maybe<DecimalNullableFilter>;
  valueMonth7?: Maybe<DecimalNullableFilter>;
  valueMonth8?: Maybe<DecimalNullableFilter>;
  valueMonth9?: Maybe<DecimalNullableFilter>;
  valueMonth10?: Maybe<DecimalNullableFilter>;
  valueMonth11?: Maybe<DecimalNullableFilter>;
  year?: Maybe<IntFilter>;
};

export type PmsActualMetricOverrideUnitCountUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  valueMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PmsActualMetricOverrideUnitCountUpdateManyWithWhereWithoutPropertyInput = {
  data: PmsActualMetricOverrideUnitCountUpdateManyMutationInput;
  where: PmsActualMetricOverrideUnitCountScalarWhereInput;
};

export type PmsActualMetricOverrideUnitCountUpdateManyWithWhereWithoutUnitTypeInput = {
  data: PmsActualMetricOverrideUnitCountUpdateManyMutationInput;
  where: PmsActualMetricOverrideUnitCountScalarWhereInput;
};

export type PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<PmsActualMetricOverrideUnitCountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PmsActualMetricOverrideUnitCountCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PmsActualMetricOverrideUnitCountCreateWithoutPropertyInput>>;
  createMany?: Maybe<PmsActualMetricOverrideUnitCountCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<PmsActualMetricOverrideUnitCountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PmsActualMetricOverrideUnitCountScalarWhereInput>>;
  disconnect?: Maybe<Array<PmsActualMetricOverrideUnitCountWhereUniqueInput>>;
  set?: Maybe<Array<PmsActualMetricOverrideUnitCountWhereUniqueInput>>;
  update?: Maybe<Array<PmsActualMetricOverrideUnitCountUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<PmsActualMetricOverrideUnitCountUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<PmsActualMetricOverrideUnitCountUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type PmsActualMetricOverrideUnitCountUpdateManyWithoutUnitTypeInput = {
  connect?: Maybe<Array<PmsActualMetricOverrideUnitCountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PmsActualMetricOverrideUnitCountCreateOrConnectWithoutUnitTypeInput>>;
  create?: Maybe<Array<PmsActualMetricOverrideUnitCountCreateWithoutUnitTypeInput>>;
  createMany?: Maybe<PmsActualMetricOverrideUnitCountCreateManyUnitTypeInputEnvelope>;
  delete?: Maybe<Array<PmsActualMetricOverrideUnitCountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PmsActualMetricOverrideUnitCountScalarWhereInput>>;
  disconnect?: Maybe<Array<PmsActualMetricOverrideUnitCountWhereUniqueInput>>;
  set?: Maybe<Array<PmsActualMetricOverrideUnitCountWhereUniqueInput>>;
  update?: Maybe<Array<PmsActualMetricOverrideUnitCountUpdateWithWhereUniqueWithoutUnitTypeInput>>;
  updateMany?: Maybe<Array<PmsActualMetricOverrideUnitCountUpdateManyWithWhereWithoutUnitTypeInput>>;
  upsert?: Maybe<Array<PmsActualMetricOverrideUnitCountUpsertWithWhereUniqueWithoutUnitTypeInput>>;
};

export type PmsActualMetricOverrideUnitCountUpdateWithWhereUniqueWithoutPropertyInput = {
  data: PmsActualMetricOverrideUnitCountUpdateWithoutPropertyInput;
  where: PmsActualMetricOverrideUnitCountWhereUniqueInput;
};

export type PmsActualMetricOverrideUnitCountUpdateWithWhereUniqueWithoutUnitTypeInput = {
  data: PmsActualMetricOverrideUnitCountUpdateWithoutUnitTypeInput;
  where: PmsActualMetricOverrideUnitCountWhereUniqueInput;
};

export type PmsActualMetricOverrideUnitCountUpdateWithoutPropertyInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  unitType?: Maybe<UnitTypeUpdateOneRequiredWithoutPmsActualMetricOverrideUnitCountsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  valueMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PmsActualMetricOverrideUnitCountUpdateWithoutUnitTypeInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutPmsActualMetricOverrideUnitCountsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  valueMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PmsActualMetricOverrideUnitCountUpsertWithWhereUniqueWithoutPropertyInput = {
  create: PmsActualMetricOverrideUnitCountCreateWithoutPropertyInput;
  update: PmsActualMetricOverrideUnitCountUpdateWithoutPropertyInput;
  where: PmsActualMetricOverrideUnitCountWhereUniqueInput;
};

export type PmsActualMetricOverrideUnitCountUpsertWithWhereUniqueWithoutUnitTypeInput = {
  create: PmsActualMetricOverrideUnitCountCreateWithoutUnitTypeInput;
  update: PmsActualMetricOverrideUnitCountUpdateWithoutUnitTypeInput;
  where: PmsActualMetricOverrideUnitCountWhereUniqueInput;
};

export type PmsActualMetricOverrideUnitCountWhereInput = {
  AND?: Maybe<Array<PmsActualMetricOverrideUnitCountWhereInput>>;
  NOT?: Maybe<Array<PmsActualMetricOverrideUnitCountWhereInput>>;
  OR?: Maybe<Array<PmsActualMetricOverrideUnitCountWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  unitType?: Maybe<UnitTypeRelationFilter>;
  unitTypeId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  valueMonth0?: Maybe<DecimalNullableFilter>;
  valueMonth1?: Maybe<DecimalNullableFilter>;
  valueMonth2?: Maybe<DecimalNullableFilter>;
  valueMonth3?: Maybe<DecimalNullableFilter>;
  valueMonth4?: Maybe<DecimalNullableFilter>;
  valueMonth5?: Maybe<DecimalNullableFilter>;
  valueMonth6?: Maybe<DecimalNullableFilter>;
  valueMonth7?: Maybe<DecimalNullableFilter>;
  valueMonth8?: Maybe<DecimalNullableFilter>;
  valueMonth9?: Maybe<DecimalNullableFilter>;
  valueMonth10?: Maybe<DecimalNullableFilter>;
  valueMonth11?: Maybe<DecimalNullableFilter>;
  year?: Maybe<IntFilter>;
};

export type PmsActualMetricOverrideUnitCountWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  propertyId_unitTypeId_year?: Maybe<PmsActualMetricOverrideUnitCountPropertyIdUnitTypeIdYearCompoundUniqueInput>;
};

export type PmsLeaseExpirationCountModel = {
  __typename?: 'PMSLeaseExpirationCountModel';
  propertyId: Scalars['ID'];
  unitTypeId: Scalars['ID'];
  unitTypeName: Scalars['String'];
  values: Array<Maybe<Scalars['Int']>>;
  year: Scalars['Int'];
};

export type PmsPropertySnapshot = {
  __typename?: 'PMSPropertySnapshot';
  name: Scalars['String'];
  seenEarliest: Scalars['DateNoTime'];
  seenLatest: Scalars['DateNoTime'];
};

export type PaginatedBackgroundJobs = {
  __typename?: 'PaginatedBackgroundJobs';
  items: Array<BackgroundJob>;
  maxPage: Scalars['Int'];
  page: Scalars['Int'];
  take: Scalars['Int'];
  total: Scalars['Int'];
};

export type PaginatedPayrollEmployeeModel = {
  __typename?: 'PaginatedPayrollEmployeeModel';
  cursor?: Maybe<Scalars['String']>;
  hasMore: Scalars['Boolean'];
  items: Array<PayrollEmployeeModel>;
  total: Scalars['Int'];
};

export type PaginatedRenovationPackageModel = {
  __typename?: 'PaginatedRenovationPackageModel';
  cursor?: Maybe<Scalars['String']>;
  hasMore: Scalars['Boolean'];
  items: Array<RenovationPackageModel>;
  total: Scalars['Int'];
};


export enum PayrollActualCompensationItemSource {
  Inferred = 'INFERRED',
  Payroll = 'PAYROLL'
}

export type PayrollActualEmployeeBaseAmountsCreateManyEmployeeInput = {
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  year: Scalars['Int'];
};

export type PayrollActualEmployeeBaseAmountsCreateManyEmployeeInputEnvelope = {
  data: Array<PayrollActualEmployeeBaseAmountsCreateManyEmployeeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollActualEmployeeBaseAmountsCreateNestedManyWithoutEmployeeInput = {
  connect?: Maybe<Array<PayrollActualEmployeeBaseAmountsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollActualEmployeeBaseAmountsCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<PayrollActualEmployeeBaseAmountsCreateWithoutEmployeeInput>>;
  createMany?: Maybe<PayrollActualEmployeeBaseAmountsCreateManyEmployeeInputEnvelope>;
};

export type PayrollActualEmployeeBaseAmountsCreateOrConnectWithoutEmployeeInput = {
  create: PayrollActualEmployeeBaseAmountsCreateWithoutEmployeeInput;
  where: PayrollActualEmployeeBaseAmountsWhereUniqueInput;
};

export type PayrollActualEmployeeBaseAmountsCreateWithoutEmployeeInput = {
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  year: Scalars['Int'];
};

export type PayrollActualEmployeeBaseAmountsEmployeeIdYearCompoundUniqueInput = {
  employeeId: Scalars['String'];
  year: Scalars['Int'];
};

export type PayrollActualEmployeeBaseAmountsListRelationFilter = {
  every?: Maybe<PayrollActualEmployeeBaseAmountsWhereInput>;
  none?: Maybe<PayrollActualEmployeeBaseAmountsWhereInput>;
  some?: Maybe<PayrollActualEmployeeBaseAmountsWhereInput>;
};

export type PayrollActualEmployeeBaseAmountsScalarWhereInput = {
  AND?: Maybe<Array<PayrollActualEmployeeBaseAmountsScalarWhereInput>>;
  NOT?: Maybe<Array<PayrollActualEmployeeBaseAmountsScalarWhereInput>>;
  OR?: Maybe<Array<PayrollActualEmployeeBaseAmountsScalarWhereInput>>;
  amountMonth0?: Maybe<DecimalNullableFilter>;
  amountMonth1?: Maybe<DecimalNullableFilter>;
  amountMonth2?: Maybe<DecimalNullableFilter>;
  amountMonth3?: Maybe<DecimalNullableFilter>;
  amountMonth4?: Maybe<DecimalNullableFilter>;
  amountMonth5?: Maybe<DecimalNullableFilter>;
  amountMonth6?: Maybe<DecimalNullableFilter>;
  amountMonth7?: Maybe<DecimalNullableFilter>;
  amountMonth8?: Maybe<DecimalNullableFilter>;
  amountMonth9?: Maybe<DecimalNullableFilter>;
  amountMonth10?: Maybe<DecimalNullableFilter>;
  amountMonth11?: Maybe<DecimalNullableFilter>;
  employeeId?: Maybe<StringFilter>;
  year?: Maybe<IntFilter>;
};

export type PayrollActualEmployeeBaseAmountsUpdateManyMutationInput = {
  amountMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PayrollActualEmployeeBaseAmountsUpdateManyWithWhereWithoutEmployeeInput = {
  data: PayrollActualEmployeeBaseAmountsUpdateManyMutationInput;
  where: PayrollActualEmployeeBaseAmountsScalarWhereInput;
};

export type PayrollActualEmployeeBaseAmountsUpdateManyWithoutEmployeeInput = {
  connect?: Maybe<Array<PayrollActualEmployeeBaseAmountsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollActualEmployeeBaseAmountsCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<PayrollActualEmployeeBaseAmountsCreateWithoutEmployeeInput>>;
  createMany?: Maybe<PayrollActualEmployeeBaseAmountsCreateManyEmployeeInputEnvelope>;
  delete?: Maybe<Array<PayrollActualEmployeeBaseAmountsWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollActualEmployeeBaseAmountsScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollActualEmployeeBaseAmountsWhereUniqueInput>>;
  set?: Maybe<Array<PayrollActualEmployeeBaseAmountsWhereUniqueInput>>;
  update?: Maybe<Array<PayrollActualEmployeeBaseAmountsUpdateWithWhereUniqueWithoutEmployeeInput>>;
  updateMany?: Maybe<Array<PayrollActualEmployeeBaseAmountsUpdateManyWithWhereWithoutEmployeeInput>>;
  upsert?: Maybe<Array<PayrollActualEmployeeBaseAmountsUpsertWithWhereUniqueWithoutEmployeeInput>>;
};

export type PayrollActualEmployeeBaseAmountsUpdateWithWhereUniqueWithoutEmployeeInput = {
  data: PayrollActualEmployeeBaseAmountsUpdateWithoutEmployeeInput;
  where: PayrollActualEmployeeBaseAmountsWhereUniqueInput;
};

export type PayrollActualEmployeeBaseAmountsUpdateWithoutEmployeeInput = {
  amountMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PayrollActualEmployeeBaseAmountsUpsertWithWhereUniqueWithoutEmployeeInput = {
  create: PayrollActualEmployeeBaseAmountsCreateWithoutEmployeeInput;
  update: PayrollActualEmployeeBaseAmountsUpdateWithoutEmployeeInput;
  where: PayrollActualEmployeeBaseAmountsWhereUniqueInput;
};

export type PayrollActualEmployeeBaseAmountsWhereInput = {
  AND?: Maybe<Array<PayrollActualEmployeeBaseAmountsWhereInput>>;
  NOT?: Maybe<Array<PayrollActualEmployeeBaseAmountsWhereInput>>;
  OR?: Maybe<Array<PayrollActualEmployeeBaseAmountsWhereInput>>;
  amountMonth0?: Maybe<DecimalNullableFilter>;
  amountMonth1?: Maybe<DecimalNullableFilter>;
  amountMonth2?: Maybe<DecimalNullableFilter>;
  amountMonth3?: Maybe<DecimalNullableFilter>;
  amountMonth4?: Maybe<DecimalNullableFilter>;
  amountMonth5?: Maybe<DecimalNullableFilter>;
  amountMonth6?: Maybe<DecimalNullableFilter>;
  amountMonth7?: Maybe<DecimalNullableFilter>;
  amountMonth8?: Maybe<DecimalNullableFilter>;
  amountMonth9?: Maybe<DecimalNullableFilter>;
  amountMonth10?: Maybe<DecimalNullableFilter>;
  amountMonth11?: Maybe<DecimalNullableFilter>;
  employee?: Maybe<PayrollEmployeeRelationFilter>;
  employeeId?: Maybe<StringFilter>;
  year?: Maybe<IntFilter>;
};

export type PayrollActualEmployeeBaseAmountsWhereUniqueInput = {
  employeeId_year?: Maybe<PayrollActualEmployeeBaseAmountsEmployeeIdYearCompoundUniqueInput>;
};

export type PayrollActualEmployeeBonusAmountsCreateManyEmployeeInput = {
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  year: Scalars['Int'];
};

export type PayrollActualEmployeeBonusAmountsCreateManyEmployeeInputEnvelope = {
  data: Array<PayrollActualEmployeeBonusAmountsCreateManyEmployeeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollActualEmployeeBonusAmountsCreateNestedManyWithoutEmployeeInput = {
  connect?: Maybe<Array<PayrollActualEmployeeBonusAmountsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollActualEmployeeBonusAmountsCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<PayrollActualEmployeeBonusAmountsCreateWithoutEmployeeInput>>;
  createMany?: Maybe<PayrollActualEmployeeBonusAmountsCreateManyEmployeeInputEnvelope>;
};

export type PayrollActualEmployeeBonusAmountsCreateOrConnectWithoutEmployeeInput = {
  create: PayrollActualEmployeeBonusAmountsCreateWithoutEmployeeInput;
  where: PayrollActualEmployeeBonusAmountsWhereUniqueInput;
};

export type PayrollActualEmployeeBonusAmountsCreateWithoutEmployeeInput = {
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  year: Scalars['Int'];
};

export type PayrollActualEmployeeBonusAmountsEmployeeIdYearCompoundUniqueInput = {
  employeeId: Scalars['String'];
  year: Scalars['Int'];
};

export type PayrollActualEmployeeBonusAmountsListRelationFilter = {
  every?: Maybe<PayrollActualEmployeeBonusAmountsWhereInput>;
  none?: Maybe<PayrollActualEmployeeBonusAmountsWhereInput>;
  some?: Maybe<PayrollActualEmployeeBonusAmountsWhereInput>;
};

export type PayrollActualEmployeeBonusAmountsScalarWhereInput = {
  AND?: Maybe<Array<PayrollActualEmployeeBonusAmountsScalarWhereInput>>;
  NOT?: Maybe<Array<PayrollActualEmployeeBonusAmountsScalarWhereInput>>;
  OR?: Maybe<Array<PayrollActualEmployeeBonusAmountsScalarWhereInput>>;
  amountMonth0?: Maybe<DecimalNullableFilter>;
  amountMonth1?: Maybe<DecimalNullableFilter>;
  amountMonth2?: Maybe<DecimalNullableFilter>;
  amountMonth3?: Maybe<DecimalNullableFilter>;
  amountMonth4?: Maybe<DecimalNullableFilter>;
  amountMonth5?: Maybe<DecimalNullableFilter>;
  amountMonth6?: Maybe<DecimalNullableFilter>;
  amountMonth7?: Maybe<DecimalNullableFilter>;
  amountMonth8?: Maybe<DecimalNullableFilter>;
  amountMonth9?: Maybe<DecimalNullableFilter>;
  amountMonth10?: Maybe<DecimalNullableFilter>;
  amountMonth11?: Maybe<DecimalNullableFilter>;
  employeeId?: Maybe<StringFilter>;
  year?: Maybe<IntFilter>;
};

export type PayrollActualEmployeeBonusAmountsUpdateManyMutationInput = {
  amountMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PayrollActualEmployeeBonusAmountsUpdateManyWithWhereWithoutEmployeeInput = {
  data: PayrollActualEmployeeBonusAmountsUpdateManyMutationInput;
  where: PayrollActualEmployeeBonusAmountsScalarWhereInput;
};

export type PayrollActualEmployeeBonusAmountsUpdateManyWithoutEmployeeInput = {
  connect?: Maybe<Array<PayrollActualEmployeeBonusAmountsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollActualEmployeeBonusAmountsCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<PayrollActualEmployeeBonusAmountsCreateWithoutEmployeeInput>>;
  createMany?: Maybe<PayrollActualEmployeeBonusAmountsCreateManyEmployeeInputEnvelope>;
  delete?: Maybe<Array<PayrollActualEmployeeBonusAmountsWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollActualEmployeeBonusAmountsScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollActualEmployeeBonusAmountsWhereUniqueInput>>;
  set?: Maybe<Array<PayrollActualEmployeeBonusAmountsWhereUniqueInput>>;
  update?: Maybe<Array<PayrollActualEmployeeBonusAmountsUpdateWithWhereUniqueWithoutEmployeeInput>>;
  updateMany?: Maybe<Array<PayrollActualEmployeeBonusAmountsUpdateManyWithWhereWithoutEmployeeInput>>;
  upsert?: Maybe<Array<PayrollActualEmployeeBonusAmountsUpsertWithWhereUniqueWithoutEmployeeInput>>;
};

export type PayrollActualEmployeeBonusAmountsUpdateWithWhereUniqueWithoutEmployeeInput = {
  data: PayrollActualEmployeeBonusAmountsUpdateWithoutEmployeeInput;
  where: PayrollActualEmployeeBonusAmountsWhereUniqueInput;
};

export type PayrollActualEmployeeBonusAmountsUpdateWithoutEmployeeInput = {
  amountMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PayrollActualEmployeeBonusAmountsUpsertWithWhereUniqueWithoutEmployeeInput = {
  create: PayrollActualEmployeeBonusAmountsCreateWithoutEmployeeInput;
  update: PayrollActualEmployeeBonusAmountsUpdateWithoutEmployeeInput;
  where: PayrollActualEmployeeBonusAmountsWhereUniqueInput;
};

export type PayrollActualEmployeeBonusAmountsWhereInput = {
  AND?: Maybe<Array<PayrollActualEmployeeBonusAmountsWhereInput>>;
  NOT?: Maybe<Array<PayrollActualEmployeeBonusAmountsWhereInput>>;
  OR?: Maybe<Array<PayrollActualEmployeeBonusAmountsWhereInput>>;
  amountMonth0?: Maybe<DecimalNullableFilter>;
  amountMonth1?: Maybe<DecimalNullableFilter>;
  amountMonth2?: Maybe<DecimalNullableFilter>;
  amountMonth3?: Maybe<DecimalNullableFilter>;
  amountMonth4?: Maybe<DecimalNullableFilter>;
  amountMonth5?: Maybe<DecimalNullableFilter>;
  amountMonth6?: Maybe<DecimalNullableFilter>;
  amountMonth7?: Maybe<DecimalNullableFilter>;
  amountMonth8?: Maybe<DecimalNullableFilter>;
  amountMonth9?: Maybe<DecimalNullableFilter>;
  amountMonth10?: Maybe<DecimalNullableFilter>;
  amountMonth11?: Maybe<DecimalNullableFilter>;
  employee?: Maybe<PayrollEmployeeRelationFilter>;
  employeeId?: Maybe<StringFilter>;
  year?: Maybe<IntFilter>;
};

export type PayrollActualEmployeeBonusAmountsWhereUniqueInput = {
  employeeId_year?: Maybe<PayrollActualEmployeeBonusAmountsEmployeeIdYearCompoundUniqueInput>;
};

export type PayrollActualEmployeeCompensationItemAmountsCreateManyCompensationItemInput = {
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  dataSource0?: Maybe<PayrollActualCompensationItemSource>;
  dataSource1?: Maybe<PayrollActualCompensationItemSource>;
  dataSource2?: Maybe<PayrollActualCompensationItemSource>;
  dataSource3?: Maybe<PayrollActualCompensationItemSource>;
  dataSource4?: Maybe<PayrollActualCompensationItemSource>;
  dataSource5?: Maybe<PayrollActualCompensationItemSource>;
  dataSource6?: Maybe<PayrollActualCompensationItemSource>;
  dataSource7?: Maybe<PayrollActualCompensationItemSource>;
  dataSource8?: Maybe<PayrollActualCompensationItemSource>;
  dataSource9?: Maybe<PayrollActualCompensationItemSource>;
  dataSource10?: Maybe<PayrollActualCompensationItemSource>;
  dataSource11?: Maybe<PayrollActualCompensationItemSource>;
  employeeId: Scalars['String'];
  year: Scalars['Int'];
};

export type PayrollActualEmployeeCompensationItemAmountsCreateManyCompensationItemInputEnvelope = {
  data: Array<PayrollActualEmployeeCompensationItemAmountsCreateManyCompensationItemInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollActualEmployeeCompensationItemAmountsCreateManyEmployeeInput = {
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  compensationItemId: Scalars['String'];
  dataSource0?: Maybe<PayrollActualCompensationItemSource>;
  dataSource1?: Maybe<PayrollActualCompensationItemSource>;
  dataSource2?: Maybe<PayrollActualCompensationItemSource>;
  dataSource3?: Maybe<PayrollActualCompensationItemSource>;
  dataSource4?: Maybe<PayrollActualCompensationItemSource>;
  dataSource5?: Maybe<PayrollActualCompensationItemSource>;
  dataSource6?: Maybe<PayrollActualCompensationItemSource>;
  dataSource7?: Maybe<PayrollActualCompensationItemSource>;
  dataSource8?: Maybe<PayrollActualCompensationItemSource>;
  dataSource9?: Maybe<PayrollActualCompensationItemSource>;
  dataSource10?: Maybe<PayrollActualCompensationItemSource>;
  dataSource11?: Maybe<PayrollActualCompensationItemSource>;
  year: Scalars['Int'];
};

export type PayrollActualEmployeeCompensationItemAmountsCreateManyEmployeeInputEnvelope = {
  data: Array<PayrollActualEmployeeCompensationItemAmountsCreateManyEmployeeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollActualEmployeeCompensationItemAmountsCreateNestedManyWithoutCompensationItemInput = {
  connect?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsCreateOrConnectWithoutCompensationItemInput>>;
  create?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsCreateWithoutCompensationItemInput>>;
  createMany?: Maybe<PayrollActualEmployeeCompensationItemAmountsCreateManyCompensationItemInputEnvelope>;
};

export type PayrollActualEmployeeCompensationItemAmountsCreateNestedManyWithoutEmployeeInput = {
  connect?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsCreateWithoutEmployeeInput>>;
  createMany?: Maybe<PayrollActualEmployeeCompensationItemAmountsCreateManyEmployeeInputEnvelope>;
};

export type PayrollActualEmployeeCompensationItemAmountsCreateOrConnectWithoutCompensationItemInput = {
  create: PayrollActualEmployeeCompensationItemAmountsCreateWithoutCompensationItemInput;
  where: PayrollActualEmployeeCompensationItemAmountsWhereUniqueInput;
};

export type PayrollActualEmployeeCompensationItemAmountsCreateOrConnectWithoutEmployeeInput = {
  create: PayrollActualEmployeeCompensationItemAmountsCreateWithoutEmployeeInput;
  where: PayrollActualEmployeeCompensationItemAmountsWhereUniqueInput;
};

export type PayrollActualEmployeeCompensationItemAmountsCreateWithoutCompensationItemInput = {
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  dataSource0?: Maybe<PayrollActualCompensationItemSource>;
  dataSource1?: Maybe<PayrollActualCompensationItemSource>;
  dataSource2?: Maybe<PayrollActualCompensationItemSource>;
  dataSource3?: Maybe<PayrollActualCompensationItemSource>;
  dataSource4?: Maybe<PayrollActualCompensationItemSource>;
  dataSource5?: Maybe<PayrollActualCompensationItemSource>;
  dataSource6?: Maybe<PayrollActualCompensationItemSource>;
  dataSource7?: Maybe<PayrollActualCompensationItemSource>;
  dataSource8?: Maybe<PayrollActualCompensationItemSource>;
  dataSource9?: Maybe<PayrollActualCompensationItemSource>;
  dataSource10?: Maybe<PayrollActualCompensationItemSource>;
  dataSource11?: Maybe<PayrollActualCompensationItemSource>;
  employee: PayrollEmployeeCreateNestedOneWithoutActualCompensationItemAmountsInput;
  year: Scalars['Int'];
};

export type PayrollActualEmployeeCompensationItemAmountsCreateWithoutEmployeeInput = {
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  compensationItem: PayrollCompensationItemCreateNestedOneWithoutActualEmployeeCompensationItemAmountsInput;
  dataSource0?: Maybe<PayrollActualCompensationItemSource>;
  dataSource1?: Maybe<PayrollActualCompensationItemSource>;
  dataSource2?: Maybe<PayrollActualCompensationItemSource>;
  dataSource3?: Maybe<PayrollActualCompensationItemSource>;
  dataSource4?: Maybe<PayrollActualCompensationItemSource>;
  dataSource5?: Maybe<PayrollActualCompensationItemSource>;
  dataSource6?: Maybe<PayrollActualCompensationItemSource>;
  dataSource7?: Maybe<PayrollActualCompensationItemSource>;
  dataSource8?: Maybe<PayrollActualCompensationItemSource>;
  dataSource9?: Maybe<PayrollActualCompensationItemSource>;
  dataSource10?: Maybe<PayrollActualCompensationItemSource>;
  dataSource11?: Maybe<PayrollActualCompensationItemSource>;
  year: Scalars['Int'];
};

export type PayrollActualEmployeeCompensationItemAmountsEmployeeIdYearCompensationItemIdCompoundUniqueInput = {
  compensationItemId: Scalars['String'];
  employeeId: Scalars['String'];
  year: Scalars['Int'];
};

export type PayrollActualEmployeeCompensationItemAmountsListRelationFilter = {
  every?: Maybe<PayrollActualEmployeeCompensationItemAmountsWhereInput>;
  none?: Maybe<PayrollActualEmployeeCompensationItemAmountsWhereInput>;
  some?: Maybe<PayrollActualEmployeeCompensationItemAmountsWhereInput>;
};

export type PayrollActualEmployeeCompensationItemAmountsScalarWhereInput = {
  AND?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsScalarWhereInput>>;
  NOT?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsScalarWhereInput>>;
  OR?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsScalarWhereInput>>;
  amountMonth0?: Maybe<DecimalNullableFilter>;
  amountMonth1?: Maybe<DecimalNullableFilter>;
  amountMonth2?: Maybe<DecimalNullableFilter>;
  amountMonth3?: Maybe<DecimalNullableFilter>;
  amountMonth4?: Maybe<DecimalNullableFilter>;
  amountMonth5?: Maybe<DecimalNullableFilter>;
  amountMonth6?: Maybe<DecimalNullableFilter>;
  amountMonth7?: Maybe<DecimalNullableFilter>;
  amountMonth8?: Maybe<DecimalNullableFilter>;
  amountMonth9?: Maybe<DecimalNullableFilter>;
  amountMonth10?: Maybe<DecimalNullableFilter>;
  amountMonth11?: Maybe<DecimalNullableFilter>;
  compensationItemId?: Maybe<StringFilter>;
  dataSource0?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  dataSource1?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  dataSource2?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  dataSource3?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  dataSource4?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  dataSource5?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  dataSource6?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  dataSource7?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  dataSource8?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  dataSource9?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  dataSource10?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  dataSource11?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  employeeId?: Maybe<StringFilter>;
  year?: Maybe<IntFilter>;
};

export type PayrollActualEmployeeCompensationItemAmountsUpdateManyMutationInput = {
  amountMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  dataSource0?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource1?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource2?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource3?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource4?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource5?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource6?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource7?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource8?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource9?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource10?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource11?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PayrollActualEmployeeCompensationItemAmountsUpdateManyWithWhereWithoutCompensationItemInput = {
  data: PayrollActualEmployeeCompensationItemAmountsUpdateManyMutationInput;
  where: PayrollActualEmployeeCompensationItemAmountsScalarWhereInput;
};

export type PayrollActualEmployeeCompensationItemAmountsUpdateManyWithWhereWithoutEmployeeInput = {
  data: PayrollActualEmployeeCompensationItemAmountsUpdateManyMutationInput;
  where: PayrollActualEmployeeCompensationItemAmountsScalarWhereInput;
};

export type PayrollActualEmployeeCompensationItemAmountsUpdateManyWithoutCompensationItemInput = {
  connect?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsCreateOrConnectWithoutCompensationItemInput>>;
  create?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsCreateWithoutCompensationItemInput>>;
  createMany?: Maybe<PayrollActualEmployeeCompensationItemAmountsCreateManyCompensationItemInputEnvelope>;
  delete?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsWhereUniqueInput>>;
  set?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsWhereUniqueInput>>;
  update?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsUpdateWithWhereUniqueWithoutCompensationItemInput>>;
  updateMany?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsUpdateManyWithWhereWithoutCompensationItemInput>>;
  upsert?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsUpsertWithWhereUniqueWithoutCompensationItemInput>>;
};

export type PayrollActualEmployeeCompensationItemAmountsUpdateManyWithoutEmployeeInput = {
  connect?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsCreateWithoutEmployeeInput>>;
  createMany?: Maybe<PayrollActualEmployeeCompensationItemAmountsCreateManyEmployeeInputEnvelope>;
  delete?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsWhereUniqueInput>>;
  set?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsWhereUniqueInput>>;
  update?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsUpdateWithWhereUniqueWithoutEmployeeInput>>;
  updateMany?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsUpdateManyWithWhereWithoutEmployeeInput>>;
  upsert?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsUpsertWithWhereUniqueWithoutEmployeeInput>>;
};

export type PayrollActualEmployeeCompensationItemAmountsUpdateWithWhereUniqueWithoutCompensationItemInput = {
  data: PayrollActualEmployeeCompensationItemAmountsUpdateWithoutCompensationItemInput;
  where: PayrollActualEmployeeCompensationItemAmountsWhereUniqueInput;
};

export type PayrollActualEmployeeCompensationItemAmountsUpdateWithWhereUniqueWithoutEmployeeInput = {
  data: PayrollActualEmployeeCompensationItemAmountsUpdateWithoutEmployeeInput;
  where: PayrollActualEmployeeCompensationItemAmountsWhereUniqueInput;
};

export type PayrollActualEmployeeCompensationItemAmountsUpdateWithoutCompensationItemInput = {
  amountMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  dataSource0?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource1?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource2?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource3?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource4?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource5?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource6?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource7?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource8?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource9?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource10?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource11?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  employee?: Maybe<PayrollEmployeeUpdateOneRequiredWithoutActualCompensationItemAmountsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PayrollActualEmployeeCompensationItemAmountsUpdateWithoutEmployeeInput = {
  amountMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  compensationItem?: Maybe<PayrollCompensationItemUpdateOneRequiredWithoutActualEmployeeCompensationItemAmountsInput>;
  dataSource0?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource1?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource2?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource3?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource4?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource5?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource6?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource7?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource8?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource9?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource10?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  dataSource11?: Maybe<NullableEnumPayrollActualCompensationItemSourceFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PayrollActualEmployeeCompensationItemAmountsUpsertWithWhereUniqueWithoutCompensationItemInput = {
  create: PayrollActualEmployeeCompensationItemAmountsCreateWithoutCompensationItemInput;
  update: PayrollActualEmployeeCompensationItemAmountsUpdateWithoutCompensationItemInput;
  where: PayrollActualEmployeeCompensationItemAmountsWhereUniqueInput;
};

export type PayrollActualEmployeeCompensationItemAmountsUpsertWithWhereUniqueWithoutEmployeeInput = {
  create: PayrollActualEmployeeCompensationItemAmountsCreateWithoutEmployeeInput;
  update: PayrollActualEmployeeCompensationItemAmountsUpdateWithoutEmployeeInput;
  where: PayrollActualEmployeeCompensationItemAmountsWhereUniqueInput;
};

export type PayrollActualEmployeeCompensationItemAmountsWhereInput = {
  AND?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsWhereInput>>;
  NOT?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsWhereInput>>;
  OR?: Maybe<Array<PayrollActualEmployeeCompensationItemAmountsWhereInput>>;
  amountMonth0?: Maybe<DecimalNullableFilter>;
  amountMonth1?: Maybe<DecimalNullableFilter>;
  amountMonth2?: Maybe<DecimalNullableFilter>;
  amountMonth3?: Maybe<DecimalNullableFilter>;
  amountMonth4?: Maybe<DecimalNullableFilter>;
  amountMonth5?: Maybe<DecimalNullableFilter>;
  amountMonth6?: Maybe<DecimalNullableFilter>;
  amountMonth7?: Maybe<DecimalNullableFilter>;
  amountMonth8?: Maybe<DecimalNullableFilter>;
  amountMonth9?: Maybe<DecimalNullableFilter>;
  amountMonth10?: Maybe<DecimalNullableFilter>;
  amountMonth11?: Maybe<DecimalNullableFilter>;
  compensationItem?: Maybe<PayrollCompensationItemRelationFilter>;
  compensationItemId?: Maybe<StringFilter>;
  dataSource0?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  dataSource1?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  dataSource2?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  dataSource3?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  dataSource4?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  dataSource5?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  dataSource6?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  dataSource7?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  dataSource8?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  dataSource9?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  dataSource10?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  dataSource11?: Maybe<EnumPayrollActualCompensationItemSourceNullableFilter>;
  employee?: Maybe<PayrollEmployeeRelationFilter>;
  employeeId?: Maybe<StringFilter>;
  year?: Maybe<IntFilter>;
};

export type PayrollActualEmployeeCompensationItemAmountsWhereUniqueInput = {
  employeeId_year_compensationItemId?: Maybe<PayrollActualEmployeeCompensationItemAmountsEmployeeIdYearCompensationItemIdCompoundUniqueInput>;
};

export type PayrollActualEmployeeOvertimeAmountsCreateManyEmployeeInput = {
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  year: Scalars['Int'];
};

export type PayrollActualEmployeeOvertimeAmountsCreateManyEmployeeInputEnvelope = {
  data: Array<PayrollActualEmployeeOvertimeAmountsCreateManyEmployeeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollActualEmployeeOvertimeAmountsCreateNestedManyWithoutEmployeeInput = {
  connect?: Maybe<Array<PayrollActualEmployeeOvertimeAmountsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollActualEmployeeOvertimeAmountsCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<PayrollActualEmployeeOvertimeAmountsCreateWithoutEmployeeInput>>;
  createMany?: Maybe<PayrollActualEmployeeOvertimeAmountsCreateManyEmployeeInputEnvelope>;
};

export type PayrollActualEmployeeOvertimeAmountsCreateOrConnectWithoutEmployeeInput = {
  create: PayrollActualEmployeeOvertimeAmountsCreateWithoutEmployeeInput;
  where: PayrollActualEmployeeOvertimeAmountsWhereUniqueInput;
};

export type PayrollActualEmployeeOvertimeAmountsCreateWithoutEmployeeInput = {
  amountMonth0?: Maybe<Scalars['Decimal']>;
  amountMonth1?: Maybe<Scalars['Decimal']>;
  amountMonth2?: Maybe<Scalars['Decimal']>;
  amountMonth3?: Maybe<Scalars['Decimal']>;
  amountMonth4?: Maybe<Scalars['Decimal']>;
  amountMonth5?: Maybe<Scalars['Decimal']>;
  amountMonth6?: Maybe<Scalars['Decimal']>;
  amountMonth7?: Maybe<Scalars['Decimal']>;
  amountMonth8?: Maybe<Scalars['Decimal']>;
  amountMonth9?: Maybe<Scalars['Decimal']>;
  amountMonth10?: Maybe<Scalars['Decimal']>;
  amountMonth11?: Maybe<Scalars['Decimal']>;
  year: Scalars['Int'];
};

export type PayrollActualEmployeeOvertimeAmountsEmployeeIdYearCompoundUniqueInput = {
  employeeId: Scalars['String'];
  year: Scalars['Int'];
};

export type PayrollActualEmployeeOvertimeAmountsListRelationFilter = {
  every?: Maybe<PayrollActualEmployeeOvertimeAmountsWhereInput>;
  none?: Maybe<PayrollActualEmployeeOvertimeAmountsWhereInput>;
  some?: Maybe<PayrollActualEmployeeOvertimeAmountsWhereInput>;
};

export type PayrollActualEmployeeOvertimeAmountsScalarWhereInput = {
  AND?: Maybe<Array<PayrollActualEmployeeOvertimeAmountsScalarWhereInput>>;
  NOT?: Maybe<Array<PayrollActualEmployeeOvertimeAmountsScalarWhereInput>>;
  OR?: Maybe<Array<PayrollActualEmployeeOvertimeAmountsScalarWhereInput>>;
  amountMonth0?: Maybe<DecimalNullableFilter>;
  amountMonth1?: Maybe<DecimalNullableFilter>;
  amountMonth2?: Maybe<DecimalNullableFilter>;
  amountMonth3?: Maybe<DecimalNullableFilter>;
  amountMonth4?: Maybe<DecimalNullableFilter>;
  amountMonth5?: Maybe<DecimalNullableFilter>;
  amountMonth6?: Maybe<DecimalNullableFilter>;
  amountMonth7?: Maybe<DecimalNullableFilter>;
  amountMonth8?: Maybe<DecimalNullableFilter>;
  amountMonth9?: Maybe<DecimalNullableFilter>;
  amountMonth10?: Maybe<DecimalNullableFilter>;
  amountMonth11?: Maybe<DecimalNullableFilter>;
  employeeId?: Maybe<StringFilter>;
  year?: Maybe<IntFilter>;
};

export type PayrollActualEmployeeOvertimeAmountsUpdateManyMutationInput = {
  amountMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PayrollActualEmployeeOvertimeAmountsUpdateManyWithWhereWithoutEmployeeInput = {
  data: PayrollActualEmployeeOvertimeAmountsUpdateManyMutationInput;
  where: PayrollActualEmployeeOvertimeAmountsScalarWhereInput;
};

export type PayrollActualEmployeeOvertimeAmountsUpdateManyWithoutEmployeeInput = {
  connect?: Maybe<Array<PayrollActualEmployeeOvertimeAmountsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollActualEmployeeOvertimeAmountsCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<PayrollActualEmployeeOvertimeAmountsCreateWithoutEmployeeInput>>;
  createMany?: Maybe<PayrollActualEmployeeOvertimeAmountsCreateManyEmployeeInputEnvelope>;
  delete?: Maybe<Array<PayrollActualEmployeeOvertimeAmountsWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollActualEmployeeOvertimeAmountsScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollActualEmployeeOvertimeAmountsWhereUniqueInput>>;
  set?: Maybe<Array<PayrollActualEmployeeOvertimeAmountsWhereUniqueInput>>;
  update?: Maybe<Array<PayrollActualEmployeeOvertimeAmountsUpdateWithWhereUniqueWithoutEmployeeInput>>;
  updateMany?: Maybe<Array<PayrollActualEmployeeOvertimeAmountsUpdateManyWithWhereWithoutEmployeeInput>>;
  upsert?: Maybe<Array<PayrollActualEmployeeOvertimeAmountsUpsertWithWhereUniqueWithoutEmployeeInput>>;
};

export type PayrollActualEmployeeOvertimeAmountsUpdateWithWhereUniqueWithoutEmployeeInput = {
  data: PayrollActualEmployeeOvertimeAmountsUpdateWithoutEmployeeInput;
  where: PayrollActualEmployeeOvertimeAmountsWhereUniqueInput;
};

export type PayrollActualEmployeeOvertimeAmountsUpdateWithoutEmployeeInput = {
  amountMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  amountMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PayrollActualEmployeeOvertimeAmountsUpsertWithWhereUniqueWithoutEmployeeInput = {
  create: PayrollActualEmployeeOvertimeAmountsCreateWithoutEmployeeInput;
  update: PayrollActualEmployeeOvertimeAmountsUpdateWithoutEmployeeInput;
  where: PayrollActualEmployeeOvertimeAmountsWhereUniqueInput;
};

export type PayrollActualEmployeeOvertimeAmountsWhereInput = {
  AND?: Maybe<Array<PayrollActualEmployeeOvertimeAmountsWhereInput>>;
  NOT?: Maybe<Array<PayrollActualEmployeeOvertimeAmountsWhereInput>>;
  OR?: Maybe<Array<PayrollActualEmployeeOvertimeAmountsWhereInput>>;
  amountMonth0?: Maybe<DecimalNullableFilter>;
  amountMonth1?: Maybe<DecimalNullableFilter>;
  amountMonth2?: Maybe<DecimalNullableFilter>;
  amountMonth3?: Maybe<DecimalNullableFilter>;
  amountMonth4?: Maybe<DecimalNullableFilter>;
  amountMonth5?: Maybe<DecimalNullableFilter>;
  amountMonth6?: Maybe<DecimalNullableFilter>;
  amountMonth7?: Maybe<DecimalNullableFilter>;
  amountMonth8?: Maybe<DecimalNullableFilter>;
  amountMonth9?: Maybe<DecimalNullableFilter>;
  amountMonth10?: Maybe<DecimalNullableFilter>;
  amountMonth11?: Maybe<DecimalNullableFilter>;
  employee?: Maybe<PayrollEmployeeRelationFilter>;
  employeeId?: Maybe<StringFilter>;
  year?: Maybe<IntFilter>;
};

export type PayrollActualEmployeeOvertimeAmountsWhereUniqueInput = {
  employeeId_year?: Maybe<PayrollActualEmployeeOvertimeAmountsEmployeeIdYearCompoundUniqueInput>;
};

export enum PayrollBonusFrequency {
  Annually = 'ANNUALLY',
  Once = 'ONCE'
}

export enum PayrollBonusType {
  FixedAmount = 'FIXED_AMOUNT',
  PercentOfSalary = 'PERCENT_OF_SALARY'
}

export type PayrollClientConfigurationCreateNestedOneWithoutClientInput = {
  connect?: Maybe<PayrollClientConfigurationWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollClientConfigurationCreateOrConnectWithoutClientInput>;
  create?: Maybe<PayrollClientConfigurationCreateWithoutClientInput>;
};

export type PayrollClientConfigurationCreateOrConnectWithoutClientInput = {
  create: PayrollClientConfigurationCreateWithoutClientInput;
  where: PayrollClientConfigurationWhereUniqueInput;
};

export type PayrollClientConfigurationCreateWithoutClientInput = {
  payDateCalculationSeed?: Maybe<Scalars['DateTime']>;
  payFrequency: PayrollPayFrequency;
};

export type PayrollClientConfigurationRelationFilter = {
  is?: Maybe<PayrollClientConfigurationWhereInput>;
  isNot?: Maybe<PayrollClientConfigurationWhereInput>;
};

export type PayrollClientConfigurationUpdateOneWithoutClientInput = {
  connect?: Maybe<PayrollClientConfigurationWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollClientConfigurationCreateOrConnectWithoutClientInput>;
  create?: Maybe<PayrollClientConfigurationCreateWithoutClientInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PayrollClientConfigurationUpdateWithoutClientInput>;
  upsert?: Maybe<PayrollClientConfigurationUpsertWithoutClientInput>;
};

export type PayrollClientConfigurationUpdateWithoutClientInput = {
  payDateCalculationSeed?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  payFrequency?: Maybe<EnumPayrollPayFrequencyFieldUpdateOperationsInput>;
};

export type PayrollClientConfigurationUpsertWithoutClientInput = {
  create: PayrollClientConfigurationCreateWithoutClientInput;
  update: PayrollClientConfigurationUpdateWithoutClientInput;
};

export type PayrollClientConfigurationWhereInput = {
  AND?: Maybe<Array<PayrollClientConfigurationWhereInput>>;
  NOT?: Maybe<Array<PayrollClientConfigurationWhereInput>>;
  OR?: Maybe<Array<PayrollClientConfigurationWhereInput>>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  payDateCalculationSeed?: Maybe<DateTimeNullableFilter>;
  payFrequency?: Maybe<EnumPayrollPayFrequencyFilter>;
};

export type PayrollClientConfigurationWhereUniqueInput = {
  clientId?: Maybe<Scalars['String']>;
};

export enum PayrollCompensationItemCalculationType {
  FixedAmount = 'FIXED_AMOUNT',
  PercentOfPrimaryCompensation = 'PERCENT_OF_PRIMARY_COMPENSATION'
}

export type PayrollCompensationItemCreateManyClientInput = {
  customName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type: PayrollCompensationItemType;
};

export type PayrollCompensationItemCreateManyClientInputEnvelope = {
  data: Array<PayrollCompensationItemCreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollCompensationItemCreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<PayrollCompensationItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollCompensationItemCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<PayrollCompensationItemCreateWithoutClientInput>>;
  createMany?: Maybe<PayrollCompensationItemCreateManyClientInputEnvelope>;
};

export type PayrollCompensationItemCreateNestedOneWithoutActualEmployeeCompensationItemAmountsInput = {
  connect?: Maybe<PayrollCompensationItemWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollCompensationItemCreateOrConnectWithoutActualEmployeeCompensationItemAmountsInput>;
  create?: Maybe<PayrollCompensationItemCreateWithoutActualEmployeeCompensationItemAmountsInput>;
};

export type PayrollCompensationItemCreateNestedOneWithoutCompensationItemPositionAccountsInput = {
  connect?: Maybe<PayrollCompensationItemWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollCompensationItemCreateOrConnectWithoutCompensationItemPositionAccountsInput>;
  create?: Maybe<PayrollCompensationItemCreateWithoutCompensationItemPositionAccountsInput>;
};

export type PayrollCompensationItemCreateNestedOneWithoutCompensationItemPositionAccountsV2Input = {
  connect?: Maybe<PayrollCompensationItemWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollCompensationItemCreateOrConnectWithoutCompensationItemPositionAccountsV2Input>;
  create?: Maybe<PayrollCompensationItemCreateWithoutCompensationItemPositionAccountsV2Input>;
};

export type PayrollCompensationItemCreateNestedOneWithoutDefaultCompensationItemsInput = {
  connect?: Maybe<PayrollCompensationItemWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollCompensationItemCreateOrConnectWithoutDefaultCompensationItemsInput>;
  create?: Maybe<PayrollCompensationItemCreateWithoutDefaultCompensationItemsInput>;
};

export type PayrollCompensationItemCreateNestedOneWithoutEmployeeCompensationItemsInput = {
  connect?: Maybe<PayrollCompensationItemWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollCompensationItemCreateOrConnectWithoutEmployeeCompensationItemsInput>;
  create?: Maybe<PayrollCompensationItemCreateWithoutEmployeeCompensationItemsInput>;
};

export type PayrollCompensationItemCreateOrConnectWithoutActualEmployeeCompensationItemAmountsInput = {
  create: PayrollCompensationItemCreateWithoutActualEmployeeCompensationItemAmountsInput;
  where: PayrollCompensationItemWhereUniqueInput;
};

export type PayrollCompensationItemCreateOrConnectWithoutClientInput = {
  create: PayrollCompensationItemCreateWithoutClientInput;
  where: PayrollCompensationItemWhereUniqueInput;
};

export type PayrollCompensationItemCreateOrConnectWithoutCompensationItemPositionAccountsInput = {
  create: PayrollCompensationItemCreateWithoutCompensationItemPositionAccountsInput;
  where: PayrollCompensationItemWhereUniqueInput;
};

export type PayrollCompensationItemCreateOrConnectWithoutCompensationItemPositionAccountsV2Input = {
  create: PayrollCompensationItemCreateWithoutCompensationItemPositionAccountsV2Input;
  where: PayrollCompensationItemWhereUniqueInput;
};

export type PayrollCompensationItemCreateOrConnectWithoutDefaultCompensationItemsInput = {
  create: PayrollCompensationItemCreateWithoutDefaultCompensationItemsInput;
  where: PayrollCompensationItemWhereUniqueInput;
};

export type PayrollCompensationItemCreateOrConnectWithoutEmployeeCompensationItemsInput = {
  create: PayrollCompensationItemCreateWithoutEmployeeCompensationItemsInput;
  where: PayrollCompensationItemWhereUniqueInput;
};

export type PayrollCompensationItemCreateWithoutActualEmployeeCompensationItemAmountsInput = {
  client: ClientCreateNestedOneWithoutPayrollCompensationItemInput;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutCompensationItemInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutCompensationItemInput>;
  customName?: Maybe<Scalars['String']>;
  defaultCompensationItems?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutCompensationItemInput>;
  employeeCompensationItems?: Maybe<PayrollEmployeeCompensationItemCreateNestedManyWithoutCompensationItemInput>;
  id?: Maybe<Scalars['String']>;
  type: PayrollCompensationItemType;
};

export type PayrollCompensationItemCreateWithoutClientInput = {
  actualEmployeeCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsCreateNestedManyWithoutCompensationItemInput>;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutCompensationItemInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutCompensationItemInput>;
  customName?: Maybe<Scalars['String']>;
  defaultCompensationItems?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutCompensationItemInput>;
  employeeCompensationItems?: Maybe<PayrollEmployeeCompensationItemCreateNestedManyWithoutCompensationItemInput>;
  id?: Maybe<Scalars['String']>;
  type: PayrollCompensationItemType;
};

export type PayrollCompensationItemCreateWithoutCompensationItemPositionAccountsInput = {
  actualEmployeeCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsCreateNestedManyWithoutCompensationItemInput>;
  client: ClientCreateNestedOneWithoutPayrollCompensationItemInput;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutCompensationItemInput>;
  customName?: Maybe<Scalars['String']>;
  defaultCompensationItems?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutCompensationItemInput>;
  employeeCompensationItems?: Maybe<PayrollEmployeeCompensationItemCreateNestedManyWithoutCompensationItemInput>;
  id?: Maybe<Scalars['String']>;
  type: PayrollCompensationItemType;
};

export type PayrollCompensationItemCreateWithoutCompensationItemPositionAccountsV2Input = {
  actualEmployeeCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsCreateNestedManyWithoutCompensationItemInput>;
  client: ClientCreateNestedOneWithoutPayrollCompensationItemInput;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutCompensationItemInput>;
  customName?: Maybe<Scalars['String']>;
  defaultCompensationItems?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutCompensationItemInput>;
  employeeCompensationItems?: Maybe<PayrollEmployeeCompensationItemCreateNestedManyWithoutCompensationItemInput>;
  id?: Maybe<Scalars['String']>;
  type: PayrollCompensationItemType;
};

export type PayrollCompensationItemCreateWithoutDefaultCompensationItemsInput = {
  actualEmployeeCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsCreateNestedManyWithoutCompensationItemInput>;
  client: ClientCreateNestedOneWithoutPayrollCompensationItemInput;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutCompensationItemInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutCompensationItemInput>;
  customName?: Maybe<Scalars['String']>;
  employeeCompensationItems?: Maybe<PayrollEmployeeCompensationItemCreateNestedManyWithoutCompensationItemInput>;
  id?: Maybe<Scalars['String']>;
  type: PayrollCompensationItemType;
};

export type PayrollCompensationItemCreateWithoutEmployeeCompensationItemsInput = {
  actualEmployeeCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsCreateNestedManyWithoutCompensationItemInput>;
  client: ClientCreateNestedOneWithoutPayrollCompensationItemInput;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutCompensationItemInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutCompensationItemInput>;
  customName?: Maybe<Scalars['String']>;
  defaultCompensationItems?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutCompensationItemInput>;
  id?: Maybe<Scalars['String']>;
  type: PayrollCompensationItemType;
};

export type PayrollCompensationItemListRelationFilter = {
  every?: Maybe<PayrollCompensationItemWhereInput>;
  none?: Maybe<PayrollCompensationItemWhereInput>;
  some?: Maybe<PayrollCompensationItemWhereInput>;
};

export type PayrollCompensationItemModel = {
  __typename?: 'PayrollCompensationItemModel';
  clientId: Scalars['String'];
  customName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type: PayrollCompensationItemType;
};

export type PayrollCompensationItemRelationFilter = {
  is?: Maybe<PayrollCompensationItemWhereInput>;
  isNot?: Maybe<PayrollCompensationItemWhereInput>;
};

export type PayrollCompensationItemScalarWhereInput = {
  AND?: Maybe<Array<PayrollCompensationItemScalarWhereInput>>;
  NOT?: Maybe<Array<PayrollCompensationItemScalarWhereInput>>;
  OR?: Maybe<Array<PayrollCompensationItemScalarWhereInput>>;
  clientId?: Maybe<StringFilter>;
  customName?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  type?: Maybe<EnumPayrollCompensationItemTypeFilter>;
};

export enum PayrollCompensationItemType {
  Custom = 'CUSTOM',
  Dental = 'DENTAL',
  FederalUnemploymentTax = 'FEDERAL_UNEMPLOYMENT_TAX',
  Medical = 'MEDICAL',
  MedicareTax = 'MEDICARE_TAX',
  Retirement_401KMatch = 'RETIREMENT_401K_MATCH',
  SocialSecurityTax = 'SOCIAL_SECURITY_TAX',
  StateUnemploymentTax = 'STATE_UNEMPLOYMENT_TAX',
  Vision = 'VISION',
  WorkersCompensation = 'WORKERS_COMPENSATION'
}

export type PayrollCompensationItemUpdateManyMutationInput = {
  customName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumPayrollCompensationItemTypeFieldUpdateOperationsInput>;
};

export type PayrollCompensationItemUpdateManyWithWhereWithoutClientInput = {
  data: PayrollCompensationItemUpdateManyMutationInput;
  where: PayrollCompensationItemScalarWhereInput;
};

export type PayrollCompensationItemUpdateManyWithoutClientInput = {
  connect?: Maybe<Array<PayrollCompensationItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollCompensationItemCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<PayrollCompensationItemCreateWithoutClientInput>>;
  createMany?: Maybe<PayrollCompensationItemCreateManyClientInputEnvelope>;
  delete?: Maybe<Array<PayrollCompensationItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollCompensationItemScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollCompensationItemWhereUniqueInput>>;
  set?: Maybe<Array<PayrollCompensationItemWhereUniqueInput>>;
  update?: Maybe<Array<PayrollCompensationItemUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<PayrollCompensationItemUpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<PayrollCompensationItemUpsertWithWhereUniqueWithoutClientInput>>;
};

export type PayrollCompensationItemUpdateOneRequiredWithoutActualEmployeeCompensationItemAmountsInput = {
  connect?: Maybe<PayrollCompensationItemWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollCompensationItemCreateOrConnectWithoutActualEmployeeCompensationItemAmountsInput>;
  create?: Maybe<PayrollCompensationItemCreateWithoutActualEmployeeCompensationItemAmountsInput>;
  update?: Maybe<PayrollCompensationItemUpdateWithoutActualEmployeeCompensationItemAmountsInput>;
  upsert?: Maybe<PayrollCompensationItemUpsertWithoutActualEmployeeCompensationItemAmountsInput>;
};

export type PayrollCompensationItemUpdateOneRequiredWithoutCompensationItemPositionAccountsInput = {
  connect?: Maybe<PayrollCompensationItemWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollCompensationItemCreateOrConnectWithoutCompensationItemPositionAccountsInput>;
  create?: Maybe<PayrollCompensationItemCreateWithoutCompensationItemPositionAccountsInput>;
  update?: Maybe<PayrollCompensationItemUpdateWithoutCompensationItemPositionAccountsInput>;
  upsert?: Maybe<PayrollCompensationItemUpsertWithoutCompensationItemPositionAccountsInput>;
};

export type PayrollCompensationItemUpdateOneRequiredWithoutCompensationItemPositionAccountsV2Input = {
  connect?: Maybe<PayrollCompensationItemWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollCompensationItemCreateOrConnectWithoutCompensationItemPositionAccountsV2Input>;
  create?: Maybe<PayrollCompensationItemCreateWithoutCompensationItemPositionAccountsV2Input>;
  update?: Maybe<PayrollCompensationItemUpdateWithoutCompensationItemPositionAccountsV2Input>;
  upsert?: Maybe<PayrollCompensationItemUpsertWithoutCompensationItemPositionAccountsV2Input>;
};

export type PayrollCompensationItemUpdateOneRequiredWithoutDefaultCompensationItemsInput = {
  connect?: Maybe<PayrollCompensationItemWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollCompensationItemCreateOrConnectWithoutDefaultCompensationItemsInput>;
  create?: Maybe<PayrollCompensationItemCreateWithoutDefaultCompensationItemsInput>;
  update?: Maybe<PayrollCompensationItemUpdateWithoutDefaultCompensationItemsInput>;
  upsert?: Maybe<PayrollCompensationItemUpsertWithoutDefaultCompensationItemsInput>;
};

export type PayrollCompensationItemUpdateOneRequiredWithoutEmployeeCompensationItemsInput = {
  connect?: Maybe<PayrollCompensationItemWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollCompensationItemCreateOrConnectWithoutEmployeeCompensationItemsInput>;
  create?: Maybe<PayrollCompensationItemCreateWithoutEmployeeCompensationItemsInput>;
  update?: Maybe<PayrollCompensationItemUpdateWithoutEmployeeCompensationItemsInput>;
  upsert?: Maybe<PayrollCompensationItemUpsertWithoutEmployeeCompensationItemsInput>;
};

export type PayrollCompensationItemUpdateWithWhereUniqueWithoutClientInput = {
  data: PayrollCompensationItemUpdateWithoutClientInput;
  where: PayrollCompensationItemWhereUniqueInput;
};

export type PayrollCompensationItemUpdateWithoutActualEmployeeCompensationItemAmountsInput = {
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollCompensationItemInput>;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutCompensationItemInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutCompensationItemInput>;
  customName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  defaultCompensationItems?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutCompensationItemInput>;
  employeeCompensationItems?: Maybe<PayrollEmployeeCompensationItemUpdateManyWithoutCompensationItemInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumPayrollCompensationItemTypeFieldUpdateOperationsInput>;
};

export type PayrollCompensationItemUpdateWithoutClientInput = {
  actualEmployeeCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsUpdateManyWithoutCompensationItemInput>;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutCompensationItemInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutCompensationItemInput>;
  customName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  defaultCompensationItems?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutCompensationItemInput>;
  employeeCompensationItems?: Maybe<PayrollEmployeeCompensationItemUpdateManyWithoutCompensationItemInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumPayrollCompensationItemTypeFieldUpdateOperationsInput>;
};

export type PayrollCompensationItemUpdateWithoutCompensationItemPositionAccountsInput = {
  actualEmployeeCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsUpdateManyWithoutCompensationItemInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollCompensationItemInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutCompensationItemInput>;
  customName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  defaultCompensationItems?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutCompensationItemInput>;
  employeeCompensationItems?: Maybe<PayrollEmployeeCompensationItemUpdateManyWithoutCompensationItemInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumPayrollCompensationItemTypeFieldUpdateOperationsInput>;
};

export type PayrollCompensationItemUpdateWithoutCompensationItemPositionAccountsV2Input = {
  actualEmployeeCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsUpdateManyWithoutCompensationItemInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollCompensationItemInput>;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutCompensationItemInput>;
  customName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  defaultCompensationItems?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutCompensationItemInput>;
  employeeCompensationItems?: Maybe<PayrollEmployeeCompensationItemUpdateManyWithoutCompensationItemInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumPayrollCompensationItemTypeFieldUpdateOperationsInput>;
};

export type PayrollCompensationItemUpdateWithoutDefaultCompensationItemsInput = {
  actualEmployeeCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsUpdateManyWithoutCompensationItemInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollCompensationItemInput>;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutCompensationItemInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutCompensationItemInput>;
  customName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  employeeCompensationItems?: Maybe<PayrollEmployeeCompensationItemUpdateManyWithoutCompensationItemInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumPayrollCompensationItemTypeFieldUpdateOperationsInput>;
};

export type PayrollCompensationItemUpdateWithoutEmployeeCompensationItemsInput = {
  actualEmployeeCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsUpdateManyWithoutCompensationItemInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollCompensationItemInput>;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutCompensationItemInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutCompensationItemInput>;
  customName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  defaultCompensationItems?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutCompensationItemInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumPayrollCompensationItemTypeFieldUpdateOperationsInput>;
};

export type PayrollCompensationItemUpsertWithWhereUniqueWithoutClientInput = {
  create: PayrollCompensationItemCreateWithoutClientInput;
  update: PayrollCompensationItemUpdateWithoutClientInput;
  where: PayrollCompensationItemWhereUniqueInput;
};

export type PayrollCompensationItemUpsertWithoutActualEmployeeCompensationItemAmountsInput = {
  create: PayrollCompensationItemCreateWithoutActualEmployeeCompensationItemAmountsInput;
  update: PayrollCompensationItemUpdateWithoutActualEmployeeCompensationItemAmountsInput;
};

export type PayrollCompensationItemUpsertWithoutCompensationItemPositionAccountsInput = {
  create: PayrollCompensationItemCreateWithoutCompensationItemPositionAccountsInput;
  update: PayrollCompensationItemUpdateWithoutCompensationItemPositionAccountsInput;
};

export type PayrollCompensationItemUpsertWithoutCompensationItemPositionAccountsV2Input = {
  create: PayrollCompensationItemCreateWithoutCompensationItemPositionAccountsV2Input;
  update: PayrollCompensationItemUpdateWithoutCompensationItemPositionAccountsV2Input;
};

export type PayrollCompensationItemUpsertWithoutDefaultCompensationItemsInput = {
  create: PayrollCompensationItemCreateWithoutDefaultCompensationItemsInput;
  update: PayrollCompensationItemUpdateWithoutDefaultCompensationItemsInput;
};

export type PayrollCompensationItemUpsertWithoutEmployeeCompensationItemsInput = {
  create: PayrollCompensationItemCreateWithoutEmployeeCompensationItemsInput;
  update: PayrollCompensationItemUpdateWithoutEmployeeCompensationItemsInput;
};

export type PayrollCompensationItemWhereInput = {
  AND?: Maybe<Array<PayrollCompensationItemWhereInput>>;
  NOT?: Maybe<Array<PayrollCompensationItemWhereInput>>;
  OR?: Maybe<Array<PayrollCompensationItemWhereInput>>;
  actualEmployeeCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsListRelationFilter>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountListRelationFilter>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2ListRelationFilter>;
  customName?: Maybe<StringNullableFilter>;
  defaultCompensationItems?: Maybe<PayrollDefaultCompensationItemListRelationFilter>;
  employeeCompensationItems?: Maybe<PayrollEmployeeCompensationItemListRelationFilter>;
  id?: Maybe<StringFilter>;
  type?: Maybe<EnumPayrollCompensationItemTypeFilter>;
};

export type PayrollCompensationItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum PayrollCompensationType {
  Annual = 'ANNUAL',
  Hourly = 'HOURLY'
}

export type PayrollCreateEmployeeBaseCompInput = {
  /** This value represents the total annual compensation, and it is set when compensationType is Annual. */
  annualCompensation?: Maybe<Scalars['Decimal']>;
  compensationType: PayrollCompensationType;
  /** Expected number of weekly hours for an hourly employee. It is set when the compensationType is hourly. */
  expectedHoursPerWeek?: Maybe<Scalars['Decimal']>;
  /** This value represents the hourly compensation, and it is set when compensationType is hourly. */
  hourlyCompensation?: Maybe<Scalars['Decimal']>;
};

export type PayrollCreateEmployeeBonusInput = {
  amount: Scalars['Decimal'];
  bonusDay?: Maybe<Scalars['Int']>;
  bonusMonth?: Maybe<Scalars['Int']>;
  bonusType: PayrollBonusType;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollBonusFrequency;
  onetimeBonusDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollCreateEmployeeCompItemInput = {
  annualCostCap?: Maybe<Scalars['Decimal']>;
  calculationType: PayrollCompensationItemCalculationType;
  compensationItemId: Scalars['ID'];
  costPerPayPeriod: Scalars['Decimal'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
};

export type PayrollCreateEmployeeOvertimeInput = {
  /** When set, indicates that this overtime will end on the given date. When unset, the overtime will continue forever. */
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  /** When the overtime will start. */
  effectiveStartDate: Scalars['DateTime'];
  /** The number of hours of overtime expected to be worked at the given compensation rate. */
  expectedHoursPerWeek: Scalars['Decimal'];
  /** Hourly compensation amount for overtime. */
  hourlyCompensation: Scalars['Decimal'];
};

export type PayrollCreateEmployeeRaiseInput = {
  amount: Scalars['Decimal'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollRaiseFrequency;
  onetimeRaiseDate?: Maybe<Scalars['DateTime']>;
  raiseDay?: Maybe<Scalars['Int']>;
  raiseMonth?: Maybe<Scalars['Int']>;
  raiseType: PayrollRaiseType;
};

export type PayrollDefaultBaseCompModel = {
  __typename?: 'PayrollDefaultBaseCompModel';
  /** This value represents the total annual compensation, and it is set when compensationType is Annual. */
  annualCompensation?: Maybe<Scalars['Decimal']>;
  compensationType: PayrollCompensationType;
  /** Expected number of weekly hours for an hourly employee. It is set when the compensationType is hourly. */
  expectedHoursPerWeek?: Maybe<Scalars['Decimal']>;
  /** This value represents the hourly compensation, and it is set when compensationType is hourly. */
  hourlyCompensation?: Maybe<Scalars['Decimal']>;
};

export type PayrollDefaultBonusModel = {
  __typename?: 'PayrollDefaultBonusModel';
  amount: Scalars['Decimal'];
  bonusDay?: Maybe<Scalars['Int']>;
  bonusMonth?: Maybe<Scalars['Int']>;
  /** The type of raise either fixed or recurring annually. */
  bonusType: PayrollBonusType;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollBonusFrequency;
  onetimeBonusDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollDefaultCompItemModel = {
  __typename?: 'PayrollDefaultCompItemModel';
  annualCostCap?: Maybe<Scalars['Decimal']>;
  calculationType: PayrollCompensationItemCalculationType;
  compensationItem: PayrollCompensationItemModel;
  costPerPayPeriod: Scalars['Decimal'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
};

export type PayrollDefaultCompensationBase = {
  __typename?: 'PayrollDefaultCompensationBase';
  annualCompensation?: Maybe<Scalars['Decimal']>;
  clientId: Scalars['String'];
  compensationType: PayrollCompensationType;
  defaultLevel: PayrollDefaultLevel;
  expectedHoursPerWeek?: Maybe<Scalars['Decimal']>;
  hourlyCompensation?: Maybe<Scalars['Decimal']>;
  id: Scalars['String'];
  positionId: Scalars['String'];
  propertyId?: Maybe<Scalars['String']>;
};

export type PayrollDefaultCompensationBaseCreateManyClientInput = {
  annualCompensation?: Maybe<Scalars['Decimal']>;
  compensationType: PayrollCompensationType;
  defaultLevel: PayrollDefaultLevel;
  expectedHoursPerWeek?: Maybe<Scalars['Decimal']>;
  hourlyCompensation?: Maybe<Scalars['Decimal']>;
  id?: Maybe<Scalars['String']>;
  positionId: Scalars['String'];
  propertyId?: Maybe<Scalars['String']>;
};

export type PayrollDefaultCompensationBaseCreateManyClientInputEnvelope = {
  data: Array<PayrollDefaultCompensationBaseCreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollDefaultCompensationBaseCreateManyPositionInput = {
  annualCompensation?: Maybe<Scalars['Decimal']>;
  clientId: Scalars['String'];
  compensationType: PayrollCompensationType;
  defaultLevel: PayrollDefaultLevel;
  expectedHoursPerWeek?: Maybe<Scalars['Decimal']>;
  hourlyCompensation?: Maybe<Scalars['Decimal']>;
  id?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['String']>;
};

export type PayrollDefaultCompensationBaseCreateManyPositionInputEnvelope = {
  data: Array<PayrollDefaultCompensationBaseCreateManyPositionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollDefaultCompensationBaseCreateManyPropertyInput = {
  annualCompensation?: Maybe<Scalars['Decimal']>;
  clientId: Scalars['String'];
  compensationType: PayrollCompensationType;
  defaultLevel: PayrollDefaultLevel;
  expectedHoursPerWeek?: Maybe<Scalars['Decimal']>;
  hourlyCompensation?: Maybe<Scalars['Decimal']>;
  id?: Maybe<Scalars['String']>;
  positionId: Scalars['String'];
};

export type PayrollDefaultCompensationBaseCreateManyPropertyInputEnvelope = {
  data: Array<PayrollDefaultCompensationBaseCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollDefaultCompensationBaseCreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationBaseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationBaseCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationBaseCreateWithoutClientInput>>;
  createMany?: Maybe<PayrollDefaultCompensationBaseCreateManyClientInputEnvelope>;
};

export type PayrollDefaultCompensationBaseCreateNestedManyWithoutPositionInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationBaseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationBaseCreateOrConnectWithoutPositionInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationBaseCreateWithoutPositionInput>>;
  createMany?: Maybe<PayrollDefaultCompensationBaseCreateManyPositionInputEnvelope>;
};

export type PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationBaseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationBaseCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationBaseCreateWithoutPropertyInput>>;
  createMany?: Maybe<PayrollDefaultCompensationBaseCreateManyPropertyInputEnvelope>;
};

export type PayrollDefaultCompensationBaseCreateOrConnectWithoutClientInput = {
  create: PayrollDefaultCompensationBaseCreateWithoutClientInput;
  where: PayrollDefaultCompensationBaseWhereUniqueInput;
};

export type PayrollDefaultCompensationBaseCreateOrConnectWithoutPositionInput = {
  create: PayrollDefaultCompensationBaseCreateWithoutPositionInput;
  where: PayrollDefaultCompensationBaseWhereUniqueInput;
};

export type PayrollDefaultCompensationBaseCreateOrConnectWithoutPropertyInput = {
  create: PayrollDefaultCompensationBaseCreateWithoutPropertyInput;
  where: PayrollDefaultCompensationBaseWhereUniqueInput;
};

export type PayrollDefaultCompensationBaseCreateWithoutClientInput = {
  annualCompensation?: Maybe<Scalars['Decimal']>;
  compensationType: PayrollCompensationType;
  defaultLevel: PayrollDefaultLevel;
  expectedHoursPerWeek?: Maybe<Scalars['Decimal']>;
  hourlyCompensation?: Maybe<Scalars['Decimal']>;
  id?: Maybe<Scalars['String']>;
  position: PayrollPositionCreateNestedOneWithoutDefaultBaseValuesInput;
  property?: Maybe<PropertyCreateNestedOneWithoutPayrollDefaultCompensationBaseValuesInput>;
};

export type PayrollDefaultCompensationBaseCreateWithoutPositionInput = {
  annualCompensation?: Maybe<Scalars['Decimal']>;
  client: ClientCreateNestedOneWithoutPayrollDefaultCompensationBaseValuesInput;
  compensationType: PayrollCompensationType;
  defaultLevel: PayrollDefaultLevel;
  expectedHoursPerWeek?: Maybe<Scalars['Decimal']>;
  hourlyCompensation?: Maybe<Scalars['Decimal']>;
  id?: Maybe<Scalars['String']>;
  property?: Maybe<PropertyCreateNestedOneWithoutPayrollDefaultCompensationBaseValuesInput>;
};

export type PayrollDefaultCompensationBaseCreateWithoutPropertyInput = {
  annualCompensation?: Maybe<Scalars['Decimal']>;
  client: ClientCreateNestedOneWithoutPayrollDefaultCompensationBaseValuesInput;
  compensationType: PayrollCompensationType;
  defaultLevel: PayrollDefaultLevel;
  expectedHoursPerWeek?: Maybe<Scalars['Decimal']>;
  hourlyCompensation?: Maybe<Scalars['Decimal']>;
  id?: Maybe<Scalars['String']>;
  position: PayrollPositionCreateNestedOneWithoutDefaultBaseValuesInput;
};

export type PayrollDefaultCompensationBaseListRelationFilter = {
  every?: Maybe<PayrollDefaultCompensationBaseWhereInput>;
  none?: Maybe<PayrollDefaultCompensationBaseWhereInput>;
  some?: Maybe<PayrollDefaultCompensationBaseWhereInput>;
};

export type PayrollDefaultCompensationBaseOrderByInput = {
  annualCompensation?: Maybe<SortOrder>;
  clientId?: Maybe<SortOrder>;
  compensationType?: Maybe<SortOrder>;
  defaultLevel?: Maybe<SortOrder>;
  expectedHoursPerWeek?: Maybe<SortOrder>;
  hourlyCompensation?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  positionId?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
};

export enum PayrollDefaultCompensationBaseScalarFieldEnum {
  AnnualCompensation = 'annualCompensation',
  ClientId = 'clientId',
  CompensationType = 'compensationType',
  DefaultLevel = 'defaultLevel',
  ExpectedHoursPerWeek = 'expectedHoursPerWeek',
  HourlyCompensation = 'hourlyCompensation',
  Id = 'id',
  PositionId = 'positionId',
  PropertyId = 'propertyId'
}

export type PayrollDefaultCompensationBaseScalarWhereInput = {
  AND?: Maybe<Array<PayrollDefaultCompensationBaseScalarWhereInput>>;
  NOT?: Maybe<Array<PayrollDefaultCompensationBaseScalarWhereInput>>;
  OR?: Maybe<Array<PayrollDefaultCompensationBaseScalarWhereInput>>;
  annualCompensation?: Maybe<DecimalNullableFilter>;
  clientId?: Maybe<StringFilter>;
  compensationType?: Maybe<EnumPayrollCompensationTypeFilter>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFilter>;
  expectedHoursPerWeek?: Maybe<DecimalNullableFilter>;
  hourlyCompensation?: Maybe<DecimalNullableFilter>;
  id?: Maybe<StringFilter>;
  positionId?: Maybe<StringFilter>;
  propertyId?: Maybe<StringNullableFilter>;
};

export type PayrollDefaultCompensationBaseUpdateManyMutationInput = {
  annualCompensation?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  compensationType?: Maybe<EnumPayrollCompensationTypeFieldUpdateOperationsInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  expectedHoursPerWeek?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  hourlyCompensation?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PayrollDefaultCompensationBaseUpdateManyWithWhereWithoutClientInput = {
  data: PayrollDefaultCompensationBaseUpdateManyMutationInput;
  where: PayrollDefaultCompensationBaseScalarWhereInput;
};

export type PayrollDefaultCompensationBaseUpdateManyWithWhereWithoutPositionInput = {
  data: PayrollDefaultCompensationBaseUpdateManyMutationInput;
  where: PayrollDefaultCompensationBaseScalarWhereInput;
};

export type PayrollDefaultCompensationBaseUpdateManyWithWhereWithoutPropertyInput = {
  data: PayrollDefaultCompensationBaseUpdateManyMutationInput;
  where: PayrollDefaultCompensationBaseScalarWhereInput;
};

export type PayrollDefaultCompensationBaseUpdateManyWithoutClientInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationBaseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationBaseCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationBaseCreateWithoutClientInput>>;
  createMany?: Maybe<PayrollDefaultCompensationBaseCreateManyClientInputEnvelope>;
  delete?: Maybe<Array<PayrollDefaultCompensationBaseWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollDefaultCompensationBaseScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollDefaultCompensationBaseWhereUniqueInput>>;
  set?: Maybe<Array<PayrollDefaultCompensationBaseWhereUniqueInput>>;
  update?: Maybe<Array<PayrollDefaultCompensationBaseUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<PayrollDefaultCompensationBaseUpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<PayrollDefaultCompensationBaseUpsertWithWhereUniqueWithoutClientInput>>;
};

export type PayrollDefaultCompensationBaseUpdateManyWithoutPositionInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationBaseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationBaseCreateOrConnectWithoutPositionInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationBaseCreateWithoutPositionInput>>;
  createMany?: Maybe<PayrollDefaultCompensationBaseCreateManyPositionInputEnvelope>;
  delete?: Maybe<Array<PayrollDefaultCompensationBaseWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollDefaultCompensationBaseScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollDefaultCompensationBaseWhereUniqueInput>>;
  set?: Maybe<Array<PayrollDefaultCompensationBaseWhereUniqueInput>>;
  update?: Maybe<Array<PayrollDefaultCompensationBaseUpdateWithWhereUniqueWithoutPositionInput>>;
  updateMany?: Maybe<Array<PayrollDefaultCompensationBaseUpdateManyWithWhereWithoutPositionInput>>;
  upsert?: Maybe<Array<PayrollDefaultCompensationBaseUpsertWithWhereUniqueWithoutPositionInput>>;
};

export type PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationBaseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationBaseCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationBaseCreateWithoutPropertyInput>>;
  createMany?: Maybe<PayrollDefaultCompensationBaseCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<PayrollDefaultCompensationBaseWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollDefaultCompensationBaseScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollDefaultCompensationBaseWhereUniqueInput>>;
  set?: Maybe<Array<PayrollDefaultCompensationBaseWhereUniqueInput>>;
  update?: Maybe<Array<PayrollDefaultCompensationBaseUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<PayrollDefaultCompensationBaseUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<PayrollDefaultCompensationBaseUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type PayrollDefaultCompensationBaseUpdateWithWhereUniqueWithoutClientInput = {
  data: PayrollDefaultCompensationBaseUpdateWithoutClientInput;
  where: PayrollDefaultCompensationBaseWhereUniqueInput;
};

export type PayrollDefaultCompensationBaseUpdateWithWhereUniqueWithoutPositionInput = {
  data: PayrollDefaultCompensationBaseUpdateWithoutPositionInput;
  where: PayrollDefaultCompensationBaseWhereUniqueInput;
};

export type PayrollDefaultCompensationBaseUpdateWithWhereUniqueWithoutPropertyInput = {
  data: PayrollDefaultCompensationBaseUpdateWithoutPropertyInput;
  where: PayrollDefaultCompensationBaseWhereUniqueInput;
};

export type PayrollDefaultCompensationBaseUpdateWithoutClientInput = {
  annualCompensation?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  compensationType?: Maybe<EnumPayrollCompensationTypeFieldUpdateOperationsInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  expectedHoursPerWeek?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  hourlyCompensation?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutDefaultBaseValuesInput>;
  property?: Maybe<PropertyUpdateOneWithoutPayrollDefaultCompensationBaseValuesInput>;
};

export type PayrollDefaultCompensationBaseUpdateWithoutPositionInput = {
  annualCompensation?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollDefaultCompensationBaseValuesInput>;
  compensationType?: Maybe<EnumPayrollCompensationTypeFieldUpdateOperationsInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  expectedHoursPerWeek?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  hourlyCompensation?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneWithoutPayrollDefaultCompensationBaseValuesInput>;
};

export type PayrollDefaultCompensationBaseUpdateWithoutPropertyInput = {
  annualCompensation?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollDefaultCompensationBaseValuesInput>;
  compensationType?: Maybe<EnumPayrollCompensationTypeFieldUpdateOperationsInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  expectedHoursPerWeek?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  hourlyCompensation?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutDefaultBaseValuesInput>;
};

export type PayrollDefaultCompensationBaseUpsertWithWhereUniqueWithoutClientInput = {
  create: PayrollDefaultCompensationBaseCreateWithoutClientInput;
  update: PayrollDefaultCompensationBaseUpdateWithoutClientInput;
  where: PayrollDefaultCompensationBaseWhereUniqueInput;
};

export type PayrollDefaultCompensationBaseUpsertWithWhereUniqueWithoutPositionInput = {
  create: PayrollDefaultCompensationBaseCreateWithoutPositionInput;
  update: PayrollDefaultCompensationBaseUpdateWithoutPositionInput;
  where: PayrollDefaultCompensationBaseWhereUniqueInput;
};

export type PayrollDefaultCompensationBaseUpsertWithWhereUniqueWithoutPropertyInput = {
  create: PayrollDefaultCompensationBaseCreateWithoutPropertyInput;
  update: PayrollDefaultCompensationBaseUpdateWithoutPropertyInput;
  where: PayrollDefaultCompensationBaseWhereUniqueInput;
};

export type PayrollDefaultCompensationBaseWhereInput = {
  AND?: Maybe<Array<PayrollDefaultCompensationBaseWhereInput>>;
  NOT?: Maybe<Array<PayrollDefaultCompensationBaseWhereInput>>;
  OR?: Maybe<Array<PayrollDefaultCompensationBaseWhereInput>>;
  annualCompensation?: Maybe<DecimalNullableFilter>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  compensationType?: Maybe<EnumPayrollCompensationTypeFilter>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFilter>;
  expectedHoursPerWeek?: Maybe<DecimalNullableFilter>;
  hourlyCompensation?: Maybe<DecimalNullableFilter>;
  id?: Maybe<StringFilter>;
  position?: Maybe<PayrollPositionRelationFilter>;
  positionId?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringNullableFilter>;
};

export type PayrollDefaultCompensationBaseWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PayrollDefaultCompensationBonus = {
  __typename?: 'PayrollDefaultCompensationBonus';
  amount: Scalars['Decimal'];
  bonusDay?: Maybe<Scalars['Int']>;
  bonusMonth?: Maybe<Scalars['Int']>;
  bonusType: PayrollBonusType;
  clientId: Scalars['String'];
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollBonusFrequency;
  id: Scalars['String'];
  oneTimeBonusDate?: Maybe<Scalars['DateTime']>;
  positionId: Scalars['String'];
  propertyId?: Maybe<Scalars['String']>;
};

export type PayrollDefaultCompensationBonusCreateManyClientInput = {
  amount: Scalars['Decimal'];
  bonusDay?: Maybe<Scalars['Int']>;
  bonusMonth?: Maybe<Scalars['Int']>;
  bonusType: PayrollBonusType;
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollBonusFrequency;
  id?: Maybe<Scalars['String']>;
  oneTimeBonusDate?: Maybe<Scalars['DateTime']>;
  positionId: Scalars['String'];
  propertyId?: Maybe<Scalars['String']>;
};

export type PayrollDefaultCompensationBonusCreateManyClientInputEnvelope = {
  data: Array<PayrollDefaultCompensationBonusCreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollDefaultCompensationBonusCreateManyPositionInput = {
  amount: Scalars['Decimal'];
  bonusDay?: Maybe<Scalars['Int']>;
  bonusMonth?: Maybe<Scalars['Int']>;
  bonusType: PayrollBonusType;
  clientId: Scalars['String'];
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollBonusFrequency;
  id?: Maybe<Scalars['String']>;
  oneTimeBonusDate?: Maybe<Scalars['DateTime']>;
  propertyId?: Maybe<Scalars['String']>;
};

export type PayrollDefaultCompensationBonusCreateManyPositionInputEnvelope = {
  data: Array<PayrollDefaultCompensationBonusCreateManyPositionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollDefaultCompensationBonusCreateManyPropertyInput = {
  amount: Scalars['Decimal'];
  bonusDay?: Maybe<Scalars['Int']>;
  bonusMonth?: Maybe<Scalars['Int']>;
  bonusType: PayrollBonusType;
  clientId: Scalars['String'];
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollBonusFrequency;
  id?: Maybe<Scalars['String']>;
  oneTimeBonusDate?: Maybe<Scalars['DateTime']>;
  positionId: Scalars['String'];
};

export type PayrollDefaultCompensationBonusCreateManyPropertyInputEnvelope = {
  data: Array<PayrollDefaultCompensationBonusCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollDefaultCompensationBonusCreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationBonusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationBonusCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationBonusCreateWithoutClientInput>>;
  createMany?: Maybe<PayrollDefaultCompensationBonusCreateManyClientInputEnvelope>;
};

export type PayrollDefaultCompensationBonusCreateNestedManyWithoutPositionInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationBonusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationBonusCreateOrConnectWithoutPositionInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationBonusCreateWithoutPositionInput>>;
  createMany?: Maybe<PayrollDefaultCompensationBonusCreateManyPositionInputEnvelope>;
};

export type PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationBonusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationBonusCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationBonusCreateWithoutPropertyInput>>;
  createMany?: Maybe<PayrollDefaultCompensationBonusCreateManyPropertyInputEnvelope>;
};

export type PayrollDefaultCompensationBonusCreateNestedOneWithoutOriginatedEmployeeBonusValuesInput = {
  connect?: Maybe<PayrollDefaultCompensationBonusWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollDefaultCompensationBonusCreateOrConnectWithoutOriginatedEmployeeBonusValuesInput>;
  create?: Maybe<PayrollDefaultCompensationBonusCreateWithoutOriginatedEmployeeBonusValuesInput>;
};

export type PayrollDefaultCompensationBonusCreateOrConnectWithoutClientInput = {
  create: PayrollDefaultCompensationBonusCreateWithoutClientInput;
  where: PayrollDefaultCompensationBonusWhereUniqueInput;
};

export type PayrollDefaultCompensationBonusCreateOrConnectWithoutOriginatedEmployeeBonusValuesInput = {
  create: PayrollDefaultCompensationBonusCreateWithoutOriginatedEmployeeBonusValuesInput;
  where: PayrollDefaultCompensationBonusWhereUniqueInput;
};

export type PayrollDefaultCompensationBonusCreateOrConnectWithoutPositionInput = {
  create: PayrollDefaultCompensationBonusCreateWithoutPositionInput;
  where: PayrollDefaultCompensationBonusWhereUniqueInput;
};

export type PayrollDefaultCompensationBonusCreateOrConnectWithoutPropertyInput = {
  create: PayrollDefaultCompensationBonusCreateWithoutPropertyInput;
  where: PayrollDefaultCompensationBonusWhereUniqueInput;
};

export type PayrollDefaultCompensationBonusCreateWithoutClientInput = {
  amount: Scalars['Decimal'];
  bonusDay?: Maybe<Scalars['Int']>;
  bonusMonth?: Maybe<Scalars['Int']>;
  bonusType: PayrollBonusType;
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollBonusFrequency;
  id?: Maybe<Scalars['String']>;
  oneTimeBonusDate?: Maybe<Scalars['DateTime']>;
  originatedEmployeeBonusValues?: Maybe<PayrollEmployeeCompensationBonusCreateNestedManyWithoutCreatedFromDefaultInput>;
  position: PayrollPositionCreateNestedOneWithoutDefaultBonusValuesInput;
  property?: Maybe<PropertyCreateNestedOneWithoutPayrollDefaultCompensationBonusValuesInput>;
};

export type PayrollDefaultCompensationBonusCreateWithoutOriginatedEmployeeBonusValuesInput = {
  amount: Scalars['Decimal'];
  bonusDay?: Maybe<Scalars['Int']>;
  bonusMonth?: Maybe<Scalars['Int']>;
  bonusType: PayrollBonusType;
  client: ClientCreateNestedOneWithoutPayrollDefaultCompensationBonusValuesInput;
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollBonusFrequency;
  id?: Maybe<Scalars['String']>;
  oneTimeBonusDate?: Maybe<Scalars['DateTime']>;
  position: PayrollPositionCreateNestedOneWithoutDefaultBonusValuesInput;
  property?: Maybe<PropertyCreateNestedOneWithoutPayrollDefaultCompensationBonusValuesInput>;
};

export type PayrollDefaultCompensationBonusCreateWithoutPositionInput = {
  amount: Scalars['Decimal'];
  bonusDay?: Maybe<Scalars['Int']>;
  bonusMonth?: Maybe<Scalars['Int']>;
  bonusType: PayrollBonusType;
  client: ClientCreateNestedOneWithoutPayrollDefaultCompensationBonusValuesInput;
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollBonusFrequency;
  id?: Maybe<Scalars['String']>;
  oneTimeBonusDate?: Maybe<Scalars['DateTime']>;
  originatedEmployeeBonusValues?: Maybe<PayrollEmployeeCompensationBonusCreateNestedManyWithoutCreatedFromDefaultInput>;
  property?: Maybe<PropertyCreateNestedOneWithoutPayrollDefaultCompensationBonusValuesInput>;
};

export type PayrollDefaultCompensationBonusCreateWithoutPropertyInput = {
  amount: Scalars['Decimal'];
  bonusDay?: Maybe<Scalars['Int']>;
  bonusMonth?: Maybe<Scalars['Int']>;
  bonusType: PayrollBonusType;
  client: ClientCreateNestedOneWithoutPayrollDefaultCompensationBonusValuesInput;
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollBonusFrequency;
  id?: Maybe<Scalars['String']>;
  oneTimeBonusDate?: Maybe<Scalars['DateTime']>;
  originatedEmployeeBonusValues?: Maybe<PayrollEmployeeCompensationBonusCreateNestedManyWithoutCreatedFromDefaultInput>;
  position: PayrollPositionCreateNestedOneWithoutDefaultBonusValuesInput;
};

export type PayrollDefaultCompensationBonusListRelationFilter = {
  every?: Maybe<PayrollDefaultCompensationBonusWhereInput>;
  none?: Maybe<PayrollDefaultCompensationBonusWhereInput>;
  some?: Maybe<PayrollDefaultCompensationBonusWhereInput>;
};

export type PayrollDefaultCompensationBonusOrderByInput = {
  amount?: Maybe<SortOrder>;
  bonusDay?: Maybe<SortOrder>;
  bonusMonth?: Maybe<SortOrder>;
  bonusType?: Maybe<SortOrder>;
  clientId?: Maybe<SortOrder>;
  defaultLevel?: Maybe<SortOrder>;
  effectiveEndDate?: Maybe<SortOrder>;
  effectiveStartDate?: Maybe<SortOrder>;
  frequency?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  oneTimeBonusDate?: Maybe<SortOrder>;
  positionId?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
};

export type PayrollDefaultCompensationBonusRelationFilter = {
  is?: Maybe<PayrollDefaultCompensationBonusWhereInput>;
  isNot?: Maybe<PayrollDefaultCompensationBonusWhereInput>;
};

export enum PayrollDefaultCompensationBonusScalarFieldEnum {
  Amount = 'amount',
  BonusDay = 'bonusDay',
  BonusMonth = 'bonusMonth',
  BonusType = 'bonusType',
  ClientId = 'clientId',
  DefaultLevel = 'defaultLevel',
  EffectiveEndDate = 'effectiveEndDate',
  EffectiveStartDate = 'effectiveStartDate',
  Frequency = 'frequency',
  Id = 'id',
  OneTimeBonusDate = 'oneTimeBonusDate',
  PositionId = 'positionId',
  PropertyId = 'propertyId'
}

export type PayrollDefaultCompensationBonusScalarWhereInput = {
  AND?: Maybe<Array<PayrollDefaultCompensationBonusScalarWhereInput>>;
  NOT?: Maybe<Array<PayrollDefaultCompensationBonusScalarWhereInput>>;
  OR?: Maybe<Array<PayrollDefaultCompensationBonusScalarWhereInput>>;
  amount?: Maybe<DecimalFilter>;
  bonusDay?: Maybe<IntNullableFilter>;
  bonusMonth?: Maybe<IntNullableFilter>;
  bonusType?: Maybe<EnumPayrollBonusTypeFilter>;
  clientId?: Maybe<StringFilter>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFilter>;
  effectiveEndDate?: Maybe<DateTimeNullableFilter>;
  effectiveStartDate?: Maybe<DateTimeNullableFilter>;
  frequency?: Maybe<EnumPayrollBonusFrequencyFilter>;
  id?: Maybe<StringFilter>;
  oneTimeBonusDate?: Maybe<DateTimeNullableFilter>;
  positionId?: Maybe<StringFilter>;
  propertyId?: Maybe<StringNullableFilter>;
};

export type PayrollDefaultCompensationBonusUpdateManyMutationInput = {
  amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  bonusDay?: Maybe<NullableIntFieldUpdateOperationsInput>;
  bonusMonth?: Maybe<NullableIntFieldUpdateOperationsInput>;
  bonusType?: Maybe<EnumPayrollBonusTypeFieldUpdateOperationsInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  frequency?: Maybe<EnumPayrollBonusFrequencyFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  oneTimeBonusDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PayrollDefaultCompensationBonusUpdateManyWithWhereWithoutClientInput = {
  data: PayrollDefaultCompensationBonusUpdateManyMutationInput;
  where: PayrollDefaultCompensationBonusScalarWhereInput;
};

export type PayrollDefaultCompensationBonusUpdateManyWithWhereWithoutPositionInput = {
  data: PayrollDefaultCompensationBonusUpdateManyMutationInput;
  where: PayrollDefaultCompensationBonusScalarWhereInput;
};

export type PayrollDefaultCompensationBonusUpdateManyWithWhereWithoutPropertyInput = {
  data: PayrollDefaultCompensationBonusUpdateManyMutationInput;
  where: PayrollDefaultCompensationBonusScalarWhereInput;
};

export type PayrollDefaultCompensationBonusUpdateManyWithoutClientInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationBonusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationBonusCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationBonusCreateWithoutClientInput>>;
  createMany?: Maybe<PayrollDefaultCompensationBonusCreateManyClientInputEnvelope>;
  delete?: Maybe<Array<PayrollDefaultCompensationBonusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollDefaultCompensationBonusScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollDefaultCompensationBonusWhereUniqueInput>>;
  set?: Maybe<Array<PayrollDefaultCompensationBonusWhereUniqueInput>>;
  update?: Maybe<Array<PayrollDefaultCompensationBonusUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<PayrollDefaultCompensationBonusUpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<PayrollDefaultCompensationBonusUpsertWithWhereUniqueWithoutClientInput>>;
};

export type PayrollDefaultCompensationBonusUpdateManyWithoutPositionInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationBonusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationBonusCreateOrConnectWithoutPositionInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationBonusCreateWithoutPositionInput>>;
  createMany?: Maybe<PayrollDefaultCompensationBonusCreateManyPositionInputEnvelope>;
  delete?: Maybe<Array<PayrollDefaultCompensationBonusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollDefaultCompensationBonusScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollDefaultCompensationBonusWhereUniqueInput>>;
  set?: Maybe<Array<PayrollDefaultCompensationBonusWhereUniqueInput>>;
  update?: Maybe<Array<PayrollDefaultCompensationBonusUpdateWithWhereUniqueWithoutPositionInput>>;
  updateMany?: Maybe<Array<PayrollDefaultCompensationBonusUpdateManyWithWhereWithoutPositionInput>>;
  upsert?: Maybe<Array<PayrollDefaultCompensationBonusUpsertWithWhereUniqueWithoutPositionInput>>;
};

export type PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationBonusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationBonusCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationBonusCreateWithoutPropertyInput>>;
  createMany?: Maybe<PayrollDefaultCompensationBonusCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<PayrollDefaultCompensationBonusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollDefaultCompensationBonusScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollDefaultCompensationBonusWhereUniqueInput>>;
  set?: Maybe<Array<PayrollDefaultCompensationBonusWhereUniqueInput>>;
  update?: Maybe<Array<PayrollDefaultCompensationBonusUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<PayrollDefaultCompensationBonusUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<PayrollDefaultCompensationBonusUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type PayrollDefaultCompensationBonusUpdateOneWithoutOriginatedEmployeeBonusValuesInput = {
  connect?: Maybe<PayrollDefaultCompensationBonusWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollDefaultCompensationBonusCreateOrConnectWithoutOriginatedEmployeeBonusValuesInput>;
  create?: Maybe<PayrollDefaultCompensationBonusCreateWithoutOriginatedEmployeeBonusValuesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PayrollDefaultCompensationBonusUpdateWithoutOriginatedEmployeeBonusValuesInput>;
  upsert?: Maybe<PayrollDefaultCompensationBonusUpsertWithoutOriginatedEmployeeBonusValuesInput>;
};

export type PayrollDefaultCompensationBonusUpdateWithWhereUniqueWithoutClientInput = {
  data: PayrollDefaultCompensationBonusUpdateWithoutClientInput;
  where: PayrollDefaultCompensationBonusWhereUniqueInput;
};

export type PayrollDefaultCompensationBonusUpdateWithWhereUniqueWithoutPositionInput = {
  data: PayrollDefaultCompensationBonusUpdateWithoutPositionInput;
  where: PayrollDefaultCompensationBonusWhereUniqueInput;
};

export type PayrollDefaultCompensationBonusUpdateWithWhereUniqueWithoutPropertyInput = {
  data: PayrollDefaultCompensationBonusUpdateWithoutPropertyInput;
  where: PayrollDefaultCompensationBonusWhereUniqueInput;
};

export type PayrollDefaultCompensationBonusUpdateWithoutClientInput = {
  amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  bonusDay?: Maybe<NullableIntFieldUpdateOperationsInput>;
  bonusMonth?: Maybe<NullableIntFieldUpdateOperationsInput>;
  bonusType?: Maybe<EnumPayrollBonusTypeFieldUpdateOperationsInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  frequency?: Maybe<EnumPayrollBonusFrequencyFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  oneTimeBonusDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  originatedEmployeeBonusValues?: Maybe<PayrollEmployeeCompensationBonusUpdateManyWithoutCreatedFromDefaultInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutDefaultBonusValuesInput>;
  property?: Maybe<PropertyUpdateOneWithoutPayrollDefaultCompensationBonusValuesInput>;
};

export type PayrollDefaultCompensationBonusUpdateWithoutOriginatedEmployeeBonusValuesInput = {
  amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  bonusDay?: Maybe<NullableIntFieldUpdateOperationsInput>;
  bonusMonth?: Maybe<NullableIntFieldUpdateOperationsInput>;
  bonusType?: Maybe<EnumPayrollBonusTypeFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollDefaultCompensationBonusValuesInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  frequency?: Maybe<EnumPayrollBonusFrequencyFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  oneTimeBonusDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutDefaultBonusValuesInput>;
  property?: Maybe<PropertyUpdateOneWithoutPayrollDefaultCompensationBonusValuesInput>;
};

export type PayrollDefaultCompensationBonusUpdateWithoutPositionInput = {
  amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  bonusDay?: Maybe<NullableIntFieldUpdateOperationsInput>;
  bonusMonth?: Maybe<NullableIntFieldUpdateOperationsInput>;
  bonusType?: Maybe<EnumPayrollBonusTypeFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollDefaultCompensationBonusValuesInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  frequency?: Maybe<EnumPayrollBonusFrequencyFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  oneTimeBonusDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  originatedEmployeeBonusValues?: Maybe<PayrollEmployeeCompensationBonusUpdateManyWithoutCreatedFromDefaultInput>;
  property?: Maybe<PropertyUpdateOneWithoutPayrollDefaultCompensationBonusValuesInput>;
};

export type PayrollDefaultCompensationBonusUpdateWithoutPropertyInput = {
  amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  bonusDay?: Maybe<NullableIntFieldUpdateOperationsInput>;
  bonusMonth?: Maybe<NullableIntFieldUpdateOperationsInput>;
  bonusType?: Maybe<EnumPayrollBonusTypeFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollDefaultCompensationBonusValuesInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  frequency?: Maybe<EnumPayrollBonusFrequencyFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  oneTimeBonusDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  originatedEmployeeBonusValues?: Maybe<PayrollEmployeeCompensationBonusUpdateManyWithoutCreatedFromDefaultInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutDefaultBonusValuesInput>;
};

export type PayrollDefaultCompensationBonusUpsertWithWhereUniqueWithoutClientInput = {
  create: PayrollDefaultCompensationBonusCreateWithoutClientInput;
  update: PayrollDefaultCompensationBonusUpdateWithoutClientInput;
  where: PayrollDefaultCompensationBonusWhereUniqueInput;
};

export type PayrollDefaultCompensationBonusUpsertWithWhereUniqueWithoutPositionInput = {
  create: PayrollDefaultCompensationBonusCreateWithoutPositionInput;
  update: PayrollDefaultCompensationBonusUpdateWithoutPositionInput;
  where: PayrollDefaultCompensationBonusWhereUniqueInput;
};

export type PayrollDefaultCompensationBonusUpsertWithWhereUniqueWithoutPropertyInput = {
  create: PayrollDefaultCompensationBonusCreateWithoutPropertyInput;
  update: PayrollDefaultCompensationBonusUpdateWithoutPropertyInput;
  where: PayrollDefaultCompensationBonusWhereUniqueInput;
};

export type PayrollDefaultCompensationBonusUpsertWithoutOriginatedEmployeeBonusValuesInput = {
  create: PayrollDefaultCompensationBonusCreateWithoutOriginatedEmployeeBonusValuesInput;
  update: PayrollDefaultCompensationBonusUpdateWithoutOriginatedEmployeeBonusValuesInput;
};

export type PayrollDefaultCompensationBonusWhereInput = {
  AND?: Maybe<Array<PayrollDefaultCompensationBonusWhereInput>>;
  NOT?: Maybe<Array<PayrollDefaultCompensationBonusWhereInput>>;
  OR?: Maybe<Array<PayrollDefaultCompensationBonusWhereInput>>;
  amount?: Maybe<DecimalFilter>;
  bonusDay?: Maybe<IntNullableFilter>;
  bonusMonth?: Maybe<IntNullableFilter>;
  bonusType?: Maybe<EnumPayrollBonusTypeFilter>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFilter>;
  effectiveEndDate?: Maybe<DateTimeNullableFilter>;
  effectiveStartDate?: Maybe<DateTimeNullableFilter>;
  frequency?: Maybe<EnumPayrollBonusFrequencyFilter>;
  id?: Maybe<StringFilter>;
  oneTimeBonusDate?: Maybe<DateTimeNullableFilter>;
  originatedEmployeeBonusValues?: Maybe<PayrollEmployeeCompensationBonusListRelationFilter>;
  position?: Maybe<PayrollPositionRelationFilter>;
  positionId?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringNullableFilter>;
};

export type PayrollDefaultCompensationBonusWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PayrollDefaultCompensationItem = {
  __typename?: 'PayrollDefaultCompensationItem';
  annualCostCap?: Maybe<Scalars['Decimal']>;
  calculationType: PayrollCompensationItemCalculationType;
  clientId: Scalars['String'];
  compensationItemId: Scalars['String'];
  costPerPayPeriod: Scalars['Decimal'];
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  id: Scalars['String'];
  positionId: Scalars['String'];
  propertyId?: Maybe<Scalars['String']>;
};

export type PayrollDefaultCompensationItemCreateManyClientInput = {
  annualCostCap?: Maybe<Scalars['Decimal']>;
  calculationType: PayrollCompensationItemCalculationType;
  compensationItemId: Scalars['String'];
  costPerPayPeriod: Scalars['Decimal'];
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  positionId: Scalars['String'];
  propertyId?: Maybe<Scalars['String']>;
};

export type PayrollDefaultCompensationItemCreateManyClientInputEnvelope = {
  data: Array<PayrollDefaultCompensationItemCreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollDefaultCompensationItemCreateManyCompensationItemInput = {
  annualCostCap?: Maybe<Scalars['Decimal']>;
  calculationType: PayrollCompensationItemCalculationType;
  clientId: Scalars['String'];
  costPerPayPeriod: Scalars['Decimal'];
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  positionId: Scalars['String'];
  propertyId?: Maybe<Scalars['String']>;
};

export type PayrollDefaultCompensationItemCreateManyCompensationItemInputEnvelope = {
  data: Array<PayrollDefaultCompensationItemCreateManyCompensationItemInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollDefaultCompensationItemCreateManyPositionInput = {
  annualCostCap?: Maybe<Scalars['Decimal']>;
  calculationType: PayrollCompensationItemCalculationType;
  clientId: Scalars['String'];
  compensationItemId: Scalars['String'];
  costPerPayPeriod: Scalars['Decimal'];
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['String']>;
};

export type PayrollDefaultCompensationItemCreateManyPositionInputEnvelope = {
  data: Array<PayrollDefaultCompensationItemCreateManyPositionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollDefaultCompensationItemCreateManyPropertyInput = {
  annualCostCap?: Maybe<Scalars['Decimal']>;
  calculationType: PayrollCompensationItemCalculationType;
  clientId: Scalars['String'];
  compensationItemId: Scalars['String'];
  costPerPayPeriod: Scalars['Decimal'];
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  positionId: Scalars['String'];
};

export type PayrollDefaultCompensationItemCreateManyPropertyInputEnvelope = {
  data: Array<PayrollDefaultCompensationItemCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollDefaultCompensationItemCreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationItemCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationItemCreateWithoutClientInput>>;
  createMany?: Maybe<PayrollDefaultCompensationItemCreateManyClientInputEnvelope>;
};

export type PayrollDefaultCompensationItemCreateNestedManyWithoutCompensationItemInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationItemCreateOrConnectWithoutCompensationItemInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationItemCreateWithoutCompensationItemInput>>;
  createMany?: Maybe<PayrollDefaultCompensationItemCreateManyCompensationItemInputEnvelope>;
};

export type PayrollDefaultCompensationItemCreateNestedManyWithoutPositionInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationItemCreateOrConnectWithoutPositionInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationItemCreateWithoutPositionInput>>;
  createMany?: Maybe<PayrollDefaultCompensationItemCreateManyPositionInputEnvelope>;
};

export type PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationItemCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationItemCreateWithoutPropertyInput>>;
  createMany?: Maybe<PayrollDefaultCompensationItemCreateManyPropertyInputEnvelope>;
};

export type PayrollDefaultCompensationItemCreateNestedOneWithoutOriginatedEmployeeItemValuesInput = {
  connect?: Maybe<PayrollDefaultCompensationItemWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollDefaultCompensationItemCreateOrConnectWithoutOriginatedEmployeeItemValuesInput>;
  create?: Maybe<PayrollDefaultCompensationItemCreateWithoutOriginatedEmployeeItemValuesInput>;
};

export type PayrollDefaultCompensationItemCreateOrConnectWithoutClientInput = {
  create: PayrollDefaultCompensationItemCreateWithoutClientInput;
  where: PayrollDefaultCompensationItemWhereUniqueInput;
};

export type PayrollDefaultCompensationItemCreateOrConnectWithoutCompensationItemInput = {
  create: PayrollDefaultCompensationItemCreateWithoutCompensationItemInput;
  where: PayrollDefaultCompensationItemWhereUniqueInput;
};

export type PayrollDefaultCompensationItemCreateOrConnectWithoutOriginatedEmployeeItemValuesInput = {
  create: PayrollDefaultCompensationItemCreateWithoutOriginatedEmployeeItemValuesInput;
  where: PayrollDefaultCompensationItemWhereUniqueInput;
};

export type PayrollDefaultCompensationItemCreateOrConnectWithoutPositionInput = {
  create: PayrollDefaultCompensationItemCreateWithoutPositionInput;
  where: PayrollDefaultCompensationItemWhereUniqueInput;
};

export type PayrollDefaultCompensationItemCreateOrConnectWithoutPropertyInput = {
  create: PayrollDefaultCompensationItemCreateWithoutPropertyInput;
  where: PayrollDefaultCompensationItemWhereUniqueInput;
};

export type PayrollDefaultCompensationItemCreateWithoutClientInput = {
  annualCostCap?: Maybe<Scalars['Decimal']>;
  calculationType: PayrollCompensationItemCalculationType;
  compensationItem: PayrollCompensationItemCreateNestedOneWithoutDefaultCompensationItemsInput;
  costPerPayPeriod: Scalars['Decimal'];
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  originatedEmployeeItemValues?: Maybe<PayrollEmployeeCompensationItemCreateNestedManyWithoutCreatedFromDefaultInput>;
  position: PayrollPositionCreateNestedOneWithoutDefaultCompensationItemValuesInput;
  property?: Maybe<PropertyCreateNestedOneWithoutPayrollDefaultCompensationItemValuesInput>;
};

export type PayrollDefaultCompensationItemCreateWithoutCompensationItemInput = {
  annualCostCap?: Maybe<Scalars['Decimal']>;
  calculationType: PayrollCompensationItemCalculationType;
  client: ClientCreateNestedOneWithoutPayrollDefaultCompensationItemValuesInput;
  costPerPayPeriod: Scalars['Decimal'];
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  originatedEmployeeItemValues?: Maybe<PayrollEmployeeCompensationItemCreateNestedManyWithoutCreatedFromDefaultInput>;
  position: PayrollPositionCreateNestedOneWithoutDefaultCompensationItemValuesInput;
  property?: Maybe<PropertyCreateNestedOneWithoutPayrollDefaultCompensationItemValuesInput>;
};

export type PayrollDefaultCompensationItemCreateWithoutOriginatedEmployeeItemValuesInput = {
  annualCostCap?: Maybe<Scalars['Decimal']>;
  calculationType: PayrollCompensationItemCalculationType;
  client: ClientCreateNestedOneWithoutPayrollDefaultCompensationItemValuesInput;
  compensationItem: PayrollCompensationItemCreateNestedOneWithoutDefaultCompensationItemsInput;
  costPerPayPeriod: Scalars['Decimal'];
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  position: PayrollPositionCreateNestedOneWithoutDefaultCompensationItemValuesInput;
  property?: Maybe<PropertyCreateNestedOneWithoutPayrollDefaultCompensationItemValuesInput>;
};

export type PayrollDefaultCompensationItemCreateWithoutPositionInput = {
  annualCostCap?: Maybe<Scalars['Decimal']>;
  calculationType: PayrollCompensationItemCalculationType;
  client: ClientCreateNestedOneWithoutPayrollDefaultCompensationItemValuesInput;
  compensationItem: PayrollCompensationItemCreateNestedOneWithoutDefaultCompensationItemsInput;
  costPerPayPeriod: Scalars['Decimal'];
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  originatedEmployeeItemValues?: Maybe<PayrollEmployeeCompensationItemCreateNestedManyWithoutCreatedFromDefaultInput>;
  property?: Maybe<PropertyCreateNestedOneWithoutPayrollDefaultCompensationItemValuesInput>;
};

export type PayrollDefaultCompensationItemCreateWithoutPropertyInput = {
  annualCostCap?: Maybe<Scalars['Decimal']>;
  calculationType: PayrollCompensationItemCalculationType;
  client: ClientCreateNestedOneWithoutPayrollDefaultCompensationItemValuesInput;
  compensationItem: PayrollCompensationItemCreateNestedOneWithoutDefaultCompensationItemsInput;
  costPerPayPeriod: Scalars['Decimal'];
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  originatedEmployeeItemValues?: Maybe<PayrollEmployeeCompensationItemCreateNestedManyWithoutCreatedFromDefaultInput>;
  position: PayrollPositionCreateNestedOneWithoutDefaultCompensationItemValuesInput;
};

export type PayrollDefaultCompensationItemListRelationFilter = {
  every?: Maybe<PayrollDefaultCompensationItemWhereInput>;
  none?: Maybe<PayrollDefaultCompensationItemWhereInput>;
  some?: Maybe<PayrollDefaultCompensationItemWhereInput>;
};

export type PayrollDefaultCompensationItemOrderByInput = {
  annualCostCap?: Maybe<SortOrder>;
  calculationType?: Maybe<SortOrder>;
  clientId?: Maybe<SortOrder>;
  compensationItemId?: Maybe<SortOrder>;
  costPerPayPeriod?: Maybe<SortOrder>;
  defaultLevel?: Maybe<SortOrder>;
  effectiveEndDate?: Maybe<SortOrder>;
  effectiveStartDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  positionId?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
};

export type PayrollDefaultCompensationItemRelationFilter = {
  is?: Maybe<PayrollDefaultCompensationItemWhereInput>;
  isNot?: Maybe<PayrollDefaultCompensationItemWhereInput>;
};

export enum PayrollDefaultCompensationItemScalarFieldEnum {
  AnnualCostCap = 'annualCostCap',
  CalculationType = 'calculationType',
  ClientId = 'clientId',
  CompensationItemId = 'compensationItemId',
  CostPerPayPeriod = 'costPerPayPeriod',
  DefaultLevel = 'defaultLevel',
  EffectiveEndDate = 'effectiveEndDate',
  EffectiveStartDate = 'effectiveStartDate',
  Id = 'id',
  PositionId = 'positionId',
  PropertyId = 'propertyId'
}

export type PayrollDefaultCompensationItemScalarWhereInput = {
  AND?: Maybe<Array<PayrollDefaultCompensationItemScalarWhereInput>>;
  NOT?: Maybe<Array<PayrollDefaultCompensationItemScalarWhereInput>>;
  OR?: Maybe<Array<PayrollDefaultCompensationItemScalarWhereInput>>;
  annualCostCap?: Maybe<DecimalNullableFilter>;
  calculationType?: Maybe<EnumPayrollCompensationItemCalculationTypeFilter>;
  clientId?: Maybe<StringFilter>;
  compensationItemId?: Maybe<StringFilter>;
  costPerPayPeriod?: Maybe<DecimalFilter>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFilter>;
  effectiveEndDate?: Maybe<DateTimeNullableFilter>;
  effectiveStartDate?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  positionId?: Maybe<StringFilter>;
  propertyId?: Maybe<StringNullableFilter>;
};

export type PayrollDefaultCompensationItemUpdateManyMutationInput = {
  annualCostCap?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  calculationType?: Maybe<EnumPayrollCompensationItemCalculationTypeFieldUpdateOperationsInput>;
  costPerPayPeriod?: Maybe<DecimalFieldUpdateOperationsInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PayrollDefaultCompensationItemUpdateManyWithWhereWithoutClientInput = {
  data: PayrollDefaultCompensationItemUpdateManyMutationInput;
  where: PayrollDefaultCompensationItemScalarWhereInput;
};

export type PayrollDefaultCompensationItemUpdateManyWithWhereWithoutCompensationItemInput = {
  data: PayrollDefaultCompensationItemUpdateManyMutationInput;
  where: PayrollDefaultCompensationItemScalarWhereInput;
};

export type PayrollDefaultCompensationItemUpdateManyWithWhereWithoutPositionInput = {
  data: PayrollDefaultCompensationItemUpdateManyMutationInput;
  where: PayrollDefaultCompensationItemScalarWhereInput;
};

export type PayrollDefaultCompensationItemUpdateManyWithWhereWithoutPropertyInput = {
  data: PayrollDefaultCompensationItemUpdateManyMutationInput;
  where: PayrollDefaultCompensationItemScalarWhereInput;
};

export type PayrollDefaultCompensationItemUpdateManyWithoutClientInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationItemCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationItemCreateWithoutClientInput>>;
  createMany?: Maybe<PayrollDefaultCompensationItemCreateManyClientInputEnvelope>;
  delete?: Maybe<Array<PayrollDefaultCompensationItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollDefaultCompensationItemScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollDefaultCompensationItemWhereUniqueInput>>;
  set?: Maybe<Array<PayrollDefaultCompensationItemWhereUniqueInput>>;
  update?: Maybe<Array<PayrollDefaultCompensationItemUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<PayrollDefaultCompensationItemUpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<PayrollDefaultCompensationItemUpsertWithWhereUniqueWithoutClientInput>>;
};

export type PayrollDefaultCompensationItemUpdateManyWithoutCompensationItemInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationItemCreateOrConnectWithoutCompensationItemInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationItemCreateWithoutCompensationItemInput>>;
  createMany?: Maybe<PayrollDefaultCompensationItemCreateManyCompensationItemInputEnvelope>;
  delete?: Maybe<Array<PayrollDefaultCompensationItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollDefaultCompensationItemScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollDefaultCompensationItemWhereUniqueInput>>;
  set?: Maybe<Array<PayrollDefaultCompensationItemWhereUniqueInput>>;
  update?: Maybe<Array<PayrollDefaultCompensationItemUpdateWithWhereUniqueWithoutCompensationItemInput>>;
  updateMany?: Maybe<Array<PayrollDefaultCompensationItemUpdateManyWithWhereWithoutCompensationItemInput>>;
  upsert?: Maybe<Array<PayrollDefaultCompensationItemUpsertWithWhereUniqueWithoutCompensationItemInput>>;
};

export type PayrollDefaultCompensationItemUpdateManyWithoutPositionInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationItemCreateOrConnectWithoutPositionInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationItemCreateWithoutPositionInput>>;
  createMany?: Maybe<PayrollDefaultCompensationItemCreateManyPositionInputEnvelope>;
  delete?: Maybe<Array<PayrollDefaultCompensationItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollDefaultCompensationItemScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollDefaultCompensationItemWhereUniqueInput>>;
  set?: Maybe<Array<PayrollDefaultCompensationItemWhereUniqueInput>>;
  update?: Maybe<Array<PayrollDefaultCompensationItemUpdateWithWhereUniqueWithoutPositionInput>>;
  updateMany?: Maybe<Array<PayrollDefaultCompensationItemUpdateManyWithWhereWithoutPositionInput>>;
  upsert?: Maybe<Array<PayrollDefaultCompensationItemUpsertWithWhereUniqueWithoutPositionInput>>;
};

export type PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationItemCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationItemCreateWithoutPropertyInput>>;
  createMany?: Maybe<PayrollDefaultCompensationItemCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<PayrollDefaultCompensationItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollDefaultCompensationItemScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollDefaultCompensationItemWhereUniqueInput>>;
  set?: Maybe<Array<PayrollDefaultCompensationItemWhereUniqueInput>>;
  update?: Maybe<Array<PayrollDefaultCompensationItemUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<PayrollDefaultCompensationItemUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<PayrollDefaultCompensationItemUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type PayrollDefaultCompensationItemUpdateOneWithoutOriginatedEmployeeItemValuesInput = {
  connect?: Maybe<PayrollDefaultCompensationItemWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollDefaultCompensationItemCreateOrConnectWithoutOriginatedEmployeeItemValuesInput>;
  create?: Maybe<PayrollDefaultCompensationItemCreateWithoutOriginatedEmployeeItemValuesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PayrollDefaultCompensationItemUpdateWithoutOriginatedEmployeeItemValuesInput>;
  upsert?: Maybe<PayrollDefaultCompensationItemUpsertWithoutOriginatedEmployeeItemValuesInput>;
};

export type PayrollDefaultCompensationItemUpdateWithWhereUniqueWithoutClientInput = {
  data: PayrollDefaultCompensationItemUpdateWithoutClientInput;
  where: PayrollDefaultCompensationItemWhereUniqueInput;
};

export type PayrollDefaultCompensationItemUpdateWithWhereUniqueWithoutCompensationItemInput = {
  data: PayrollDefaultCompensationItemUpdateWithoutCompensationItemInput;
  where: PayrollDefaultCompensationItemWhereUniqueInput;
};

export type PayrollDefaultCompensationItemUpdateWithWhereUniqueWithoutPositionInput = {
  data: PayrollDefaultCompensationItemUpdateWithoutPositionInput;
  where: PayrollDefaultCompensationItemWhereUniqueInput;
};

export type PayrollDefaultCompensationItemUpdateWithWhereUniqueWithoutPropertyInput = {
  data: PayrollDefaultCompensationItemUpdateWithoutPropertyInput;
  where: PayrollDefaultCompensationItemWhereUniqueInput;
};

export type PayrollDefaultCompensationItemUpdateWithoutClientInput = {
  annualCostCap?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  calculationType?: Maybe<EnumPayrollCompensationItemCalculationTypeFieldUpdateOperationsInput>;
  compensationItem?: Maybe<PayrollCompensationItemUpdateOneRequiredWithoutDefaultCompensationItemsInput>;
  costPerPayPeriod?: Maybe<DecimalFieldUpdateOperationsInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  originatedEmployeeItemValues?: Maybe<PayrollEmployeeCompensationItemUpdateManyWithoutCreatedFromDefaultInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutDefaultCompensationItemValuesInput>;
  property?: Maybe<PropertyUpdateOneWithoutPayrollDefaultCompensationItemValuesInput>;
};

export type PayrollDefaultCompensationItemUpdateWithoutCompensationItemInput = {
  annualCostCap?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  calculationType?: Maybe<EnumPayrollCompensationItemCalculationTypeFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollDefaultCompensationItemValuesInput>;
  costPerPayPeriod?: Maybe<DecimalFieldUpdateOperationsInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  originatedEmployeeItemValues?: Maybe<PayrollEmployeeCompensationItemUpdateManyWithoutCreatedFromDefaultInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutDefaultCompensationItemValuesInput>;
  property?: Maybe<PropertyUpdateOneWithoutPayrollDefaultCompensationItemValuesInput>;
};

export type PayrollDefaultCompensationItemUpdateWithoutOriginatedEmployeeItemValuesInput = {
  annualCostCap?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  calculationType?: Maybe<EnumPayrollCompensationItemCalculationTypeFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollDefaultCompensationItemValuesInput>;
  compensationItem?: Maybe<PayrollCompensationItemUpdateOneRequiredWithoutDefaultCompensationItemsInput>;
  costPerPayPeriod?: Maybe<DecimalFieldUpdateOperationsInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutDefaultCompensationItemValuesInput>;
  property?: Maybe<PropertyUpdateOneWithoutPayrollDefaultCompensationItemValuesInput>;
};

export type PayrollDefaultCompensationItemUpdateWithoutPositionInput = {
  annualCostCap?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  calculationType?: Maybe<EnumPayrollCompensationItemCalculationTypeFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollDefaultCompensationItemValuesInput>;
  compensationItem?: Maybe<PayrollCompensationItemUpdateOneRequiredWithoutDefaultCompensationItemsInput>;
  costPerPayPeriod?: Maybe<DecimalFieldUpdateOperationsInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  originatedEmployeeItemValues?: Maybe<PayrollEmployeeCompensationItemUpdateManyWithoutCreatedFromDefaultInput>;
  property?: Maybe<PropertyUpdateOneWithoutPayrollDefaultCompensationItemValuesInput>;
};

export type PayrollDefaultCompensationItemUpdateWithoutPropertyInput = {
  annualCostCap?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  calculationType?: Maybe<EnumPayrollCompensationItemCalculationTypeFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollDefaultCompensationItemValuesInput>;
  compensationItem?: Maybe<PayrollCompensationItemUpdateOneRequiredWithoutDefaultCompensationItemsInput>;
  costPerPayPeriod?: Maybe<DecimalFieldUpdateOperationsInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  originatedEmployeeItemValues?: Maybe<PayrollEmployeeCompensationItemUpdateManyWithoutCreatedFromDefaultInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutDefaultCompensationItemValuesInput>;
};

export type PayrollDefaultCompensationItemUpsertWithWhereUniqueWithoutClientInput = {
  create: PayrollDefaultCompensationItemCreateWithoutClientInput;
  update: PayrollDefaultCompensationItemUpdateWithoutClientInput;
  where: PayrollDefaultCompensationItemWhereUniqueInput;
};

export type PayrollDefaultCompensationItemUpsertWithWhereUniqueWithoutCompensationItemInput = {
  create: PayrollDefaultCompensationItemCreateWithoutCompensationItemInput;
  update: PayrollDefaultCompensationItemUpdateWithoutCompensationItemInput;
  where: PayrollDefaultCompensationItemWhereUniqueInput;
};

export type PayrollDefaultCompensationItemUpsertWithWhereUniqueWithoutPositionInput = {
  create: PayrollDefaultCompensationItemCreateWithoutPositionInput;
  update: PayrollDefaultCompensationItemUpdateWithoutPositionInput;
  where: PayrollDefaultCompensationItemWhereUniqueInput;
};

export type PayrollDefaultCompensationItemUpsertWithWhereUniqueWithoutPropertyInput = {
  create: PayrollDefaultCompensationItemCreateWithoutPropertyInput;
  update: PayrollDefaultCompensationItemUpdateWithoutPropertyInput;
  where: PayrollDefaultCompensationItemWhereUniqueInput;
};

export type PayrollDefaultCompensationItemUpsertWithoutOriginatedEmployeeItemValuesInput = {
  create: PayrollDefaultCompensationItemCreateWithoutOriginatedEmployeeItemValuesInput;
  update: PayrollDefaultCompensationItemUpdateWithoutOriginatedEmployeeItemValuesInput;
};

export type PayrollDefaultCompensationItemWhereInput = {
  AND?: Maybe<Array<PayrollDefaultCompensationItemWhereInput>>;
  NOT?: Maybe<Array<PayrollDefaultCompensationItemWhereInput>>;
  OR?: Maybe<Array<PayrollDefaultCompensationItemWhereInput>>;
  annualCostCap?: Maybe<DecimalNullableFilter>;
  calculationType?: Maybe<EnumPayrollCompensationItemCalculationTypeFilter>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  compensationItem?: Maybe<PayrollCompensationItemRelationFilter>;
  compensationItemId?: Maybe<StringFilter>;
  costPerPayPeriod?: Maybe<DecimalFilter>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFilter>;
  effectiveEndDate?: Maybe<DateTimeNullableFilter>;
  effectiveStartDate?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  originatedEmployeeItemValues?: Maybe<PayrollEmployeeCompensationItemListRelationFilter>;
  position?: Maybe<PayrollPositionRelationFilter>;
  positionId?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringNullableFilter>;
};

export type PayrollDefaultCompensationItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PayrollDefaultCompensationOvertime = {
  __typename?: 'PayrollDefaultCompensationOvertime';
  clientId: Scalars['String'];
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  expectedHoursPerWeek: Scalars['Decimal'];
  hourlyCompensation: Scalars['Decimal'];
  id: Scalars['String'];
  positionId: Scalars['String'];
  propertyId?: Maybe<Scalars['String']>;
};

export type PayrollDefaultCompensationOvertimeCreateManyClientInput = {
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  expectedHoursPerWeek: Scalars['Decimal'];
  hourlyCompensation: Scalars['Decimal'];
  id?: Maybe<Scalars['String']>;
  positionId: Scalars['String'];
  propertyId?: Maybe<Scalars['String']>;
};

export type PayrollDefaultCompensationOvertimeCreateManyClientInputEnvelope = {
  data: Array<PayrollDefaultCompensationOvertimeCreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollDefaultCompensationOvertimeCreateManyPositionInput = {
  clientId: Scalars['String'];
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  expectedHoursPerWeek: Scalars['Decimal'];
  hourlyCompensation: Scalars['Decimal'];
  id?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['String']>;
};

export type PayrollDefaultCompensationOvertimeCreateManyPositionInputEnvelope = {
  data: Array<PayrollDefaultCompensationOvertimeCreateManyPositionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollDefaultCompensationOvertimeCreateManyPropertyInput = {
  clientId: Scalars['String'];
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  expectedHoursPerWeek: Scalars['Decimal'];
  hourlyCompensation: Scalars['Decimal'];
  id?: Maybe<Scalars['String']>;
  positionId: Scalars['String'];
};

export type PayrollDefaultCompensationOvertimeCreateManyPropertyInputEnvelope = {
  data: Array<PayrollDefaultCompensationOvertimeCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollDefaultCompensationOvertimeCreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationOvertimeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationOvertimeCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationOvertimeCreateWithoutClientInput>>;
  createMany?: Maybe<PayrollDefaultCompensationOvertimeCreateManyClientInputEnvelope>;
};

export type PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPositionInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationOvertimeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationOvertimeCreateOrConnectWithoutPositionInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationOvertimeCreateWithoutPositionInput>>;
  createMany?: Maybe<PayrollDefaultCompensationOvertimeCreateManyPositionInputEnvelope>;
};

export type PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationOvertimeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationOvertimeCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationOvertimeCreateWithoutPropertyInput>>;
  createMany?: Maybe<PayrollDefaultCompensationOvertimeCreateManyPropertyInputEnvelope>;
};

export type PayrollDefaultCompensationOvertimeCreateNestedOneWithoutOriginatedEmployeeOvertimeValuesInput = {
  connect?: Maybe<PayrollDefaultCompensationOvertimeWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollDefaultCompensationOvertimeCreateOrConnectWithoutOriginatedEmployeeOvertimeValuesInput>;
  create?: Maybe<PayrollDefaultCompensationOvertimeCreateWithoutOriginatedEmployeeOvertimeValuesInput>;
};

export type PayrollDefaultCompensationOvertimeCreateOrConnectWithoutClientInput = {
  create: PayrollDefaultCompensationOvertimeCreateWithoutClientInput;
  where: PayrollDefaultCompensationOvertimeWhereUniqueInput;
};

export type PayrollDefaultCompensationOvertimeCreateOrConnectWithoutOriginatedEmployeeOvertimeValuesInput = {
  create: PayrollDefaultCompensationOvertimeCreateWithoutOriginatedEmployeeOvertimeValuesInput;
  where: PayrollDefaultCompensationOvertimeWhereUniqueInput;
};

export type PayrollDefaultCompensationOvertimeCreateOrConnectWithoutPositionInput = {
  create: PayrollDefaultCompensationOvertimeCreateWithoutPositionInput;
  where: PayrollDefaultCompensationOvertimeWhereUniqueInput;
};

export type PayrollDefaultCompensationOvertimeCreateOrConnectWithoutPropertyInput = {
  create: PayrollDefaultCompensationOvertimeCreateWithoutPropertyInput;
  where: PayrollDefaultCompensationOvertimeWhereUniqueInput;
};

export type PayrollDefaultCompensationOvertimeCreateWithoutClientInput = {
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  expectedHoursPerWeek: Scalars['Decimal'];
  hourlyCompensation: Scalars['Decimal'];
  id?: Maybe<Scalars['String']>;
  originatedEmployeeOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeCreateNestedManyWithoutCreatedFromDefaultInput>;
  position: PayrollPositionCreateNestedOneWithoutDefaultOvertimeValuesInput;
  property?: Maybe<PropertyCreateNestedOneWithoutPayrollDefaultCompensationOvertimeValuesInput>;
};

export type PayrollDefaultCompensationOvertimeCreateWithoutOriginatedEmployeeOvertimeValuesInput = {
  client: ClientCreateNestedOneWithoutPayrollDefaultCompensationOvertimeValuesInput;
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  expectedHoursPerWeek: Scalars['Decimal'];
  hourlyCompensation: Scalars['Decimal'];
  id?: Maybe<Scalars['String']>;
  position: PayrollPositionCreateNestedOneWithoutDefaultOvertimeValuesInput;
  property?: Maybe<PropertyCreateNestedOneWithoutPayrollDefaultCompensationOvertimeValuesInput>;
};

export type PayrollDefaultCompensationOvertimeCreateWithoutPositionInput = {
  client: ClientCreateNestedOneWithoutPayrollDefaultCompensationOvertimeValuesInput;
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  expectedHoursPerWeek: Scalars['Decimal'];
  hourlyCompensation: Scalars['Decimal'];
  id?: Maybe<Scalars['String']>;
  originatedEmployeeOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeCreateNestedManyWithoutCreatedFromDefaultInput>;
  property?: Maybe<PropertyCreateNestedOneWithoutPayrollDefaultCompensationOvertimeValuesInput>;
};

export type PayrollDefaultCompensationOvertimeCreateWithoutPropertyInput = {
  client: ClientCreateNestedOneWithoutPayrollDefaultCompensationOvertimeValuesInput;
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  expectedHoursPerWeek: Scalars['Decimal'];
  hourlyCompensation: Scalars['Decimal'];
  id?: Maybe<Scalars['String']>;
  originatedEmployeeOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeCreateNestedManyWithoutCreatedFromDefaultInput>;
  position: PayrollPositionCreateNestedOneWithoutDefaultOvertimeValuesInput;
};

export type PayrollDefaultCompensationOvertimeListRelationFilter = {
  every?: Maybe<PayrollDefaultCompensationOvertimeWhereInput>;
  none?: Maybe<PayrollDefaultCompensationOvertimeWhereInput>;
  some?: Maybe<PayrollDefaultCompensationOvertimeWhereInput>;
};

export type PayrollDefaultCompensationOvertimeOrderByInput = {
  clientId?: Maybe<SortOrder>;
  defaultLevel?: Maybe<SortOrder>;
  effectiveEndDate?: Maybe<SortOrder>;
  effectiveStartDate?: Maybe<SortOrder>;
  expectedHoursPerWeek?: Maybe<SortOrder>;
  hourlyCompensation?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  positionId?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
};

export type PayrollDefaultCompensationOvertimeRelationFilter = {
  is?: Maybe<PayrollDefaultCompensationOvertimeWhereInput>;
  isNot?: Maybe<PayrollDefaultCompensationOvertimeWhereInput>;
};

export enum PayrollDefaultCompensationOvertimeScalarFieldEnum {
  ClientId = 'clientId',
  DefaultLevel = 'defaultLevel',
  EffectiveEndDate = 'effectiveEndDate',
  EffectiveStartDate = 'effectiveStartDate',
  ExpectedHoursPerWeek = 'expectedHoursPerWeek',
  HourlyCompensation = 'hourlyCompensation',
  Id = 'id',
  PositionId = 'positionId',
  PropertyId = 'propertyId'
}

export type PayrollDefaultCompensationOvertimeScalarWhereInput = {
  AND?: Maybe<Array<PayrollDefaultCompensationOvertimeScalarWhereInput>>;
  NOT?: Maybe<Array<PayrollDefaultCompensationOvertimeScalarWhereInput>>;
  OR?: Maybe<Array<PayrollDefaultCompensationOvertimeScalarWhereInput>>;
  clientId?: Maybe<StringFilter>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFilter>;
  effectiveEndDate?: Maybe<DateTimeNullableFilter>;
  effectiveStartDate?: Maybe<DateTimeFilter>;
  expectedHoursPerWeek?: Maybe<DecimalFilter>;
  hourlyCompensation?: Maybe<DecimalFilter>;
  id?: Maybe<StringFilter>;
  positionId?: Maybe<StringFilter>;
  propertyId?: Maybe<StringNullableFilter>;
};

export type PayrollDefaultCompensationOvertimeUpdateManyMutationInput = {
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expectedHoursPerWeek?: Maybe<DecimalFieldUpdateOperationsInput>;
  hourlyCompensation?: Maybe<DecimalFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PayrollDefaultCompensationOvertimeUpdateManyWithWhereWithoutClientInput = {
  data: PayrollDefaultCompensationOvertimeUpdateManyMutationInput;
  where: PayrollDefaultCompensationOvertimeScalarWhereInput;
};

export type PayrollDefaultCompensationOvertimeUpdateManyWithWhereWithoutPositionInput = {
  data: PayrollDefaultCompensationOvertimeUpdateManyMutationInput;
  where: PayrollDefaultCompensationOvertimeScalarWhereInput;
};

export type PayrollDefaultCompensationOvertimeUpdateManyWithWhereWithoutPropertyInput = {
  data: PayrollDefaultCompensationOvertimeUpdateManyMutationInput;
  where: PayrollDefaultCompensationOvertimeScalarWhereInput;
};

export type PayrollDefaultCompensationOvertimeUpdateManyWithoutClientInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationOvertimeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationOvertimeCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationOvertimeCreateWithoutClientInput>>;
  createMany?: Maybe<PayrollDefaultCompensationOvertimeCreateManyClientInputEnvelope>;
  delete?: Maybe<Array<PayrollDefaultCompensationOvertimeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollDefaultCompensationOvertimeScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollDefaultCompensationOvertimeWhereUniqueInput>>;
  set?: Maybe<Array<PayrollDefaultCompensationOvertimeWhereUniqueInput>>;
  update?: Maybe<Array<PayrollDefaultCompensationOvertimeUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<PayrollDefaultCompensationOvertimeUpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<PayrollDefaultCompensationOvertimeUpsertWithWhereUniqueWithoutClientInput>>;
};

export type PayrollDefaultCompensationOvertimeUpdateManyWithoutPositionInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationOvertimeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationOvertimeCreateOrConnectWithoutPositionInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationOvertimeCreateWithoutPositionInput>>;
  createMany?: Maybe<PayrollDefaultCompensationOvertimeCreateManyPositionInputEnvelope>;
  delete?: Maybe<Array<PayrollDefaultCompensationOvertimeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollDefaultCompensationOvertimeScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollDefaultCompensationOvertimeWhereUniqueInput>>;
  set?: Maybe<Array<PayrollDefaultCompensationOvertimeWhereUniqueInput>>;
  update?: Maybe<Array<PayrollDefaultCompensationOvertimeUpdateWithWhereUniqueWithoutPositionInput>>;
  updateMany?: Maybe<Array<PayrollDefaultCompensationOvertimeUpdateManyWithWhereWithoutPositionInput>>;
  upsert?: Maybe<Array<PayrollDefaultCompensationOvertimeUpsertWithWhereUniqueWithoutPositionInput>>;
};

export type PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationOvertimeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationOvertimeCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationOvertimeCreateWithoutPropertyInput>>;
  createMany?: Maybe<PayrollDefaultCompensationOvertimeCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<PayrollDefaultCompensationOvertimeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollDefaultCompensationOvertimeScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollDefaultCompensationOvertimeWhereUniqueInput>>;
  set?: Maybe<Array<PayrollDefaultCompensationOvertimeWhereUniqueInput>>;
  update?: Maybe<Array<PayrollDefaultCompensationOvertimeUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<PayrollDefaultCompensationOvertimeUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<PayrollDefaultCompensationOvertimeUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type PayrollDefaultCompensationOvertimeUpdateOneWithoutOriginatedEmployeeOvertimeValuesInput = {
  connect?: Maybe<PayrollDefaultCompensationOvertimeWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollDefaultCompensationOvertimeCreateOrConnectWithoutOriginatedEmployeeOvertimeValuesInput>;
  create?: Maybe<PayrollDefaultCompensationOvertimeCreateWithoutOriginatedEmployeeOvertimeValuesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PayrollDefaultCompensationOvertimeUpdateWithoutOriginatedEmployeeOvertimeValuesInput>;
  upsert?: Maybe<PayrollDefaultCompensationOvertimeUpsertWithoutOriginatedEmployeeOvertimeValuesInput>;
};

export type PayrollDefaultCompensationOvertimeUpdateWithWhereUniqueWithoutClientInput = {
  data: PayrollDefaultCompensationOvertimeUpdateWithoutClientInput;
  where: PayrollDefaultCompensationOvertimeWhereUniqueInput;
};

export type PayrollDefaultCompensationOvertimeUpdateWithWhereUniqueWithoutPositionInput = {
  data: PayrollDefaultCompensationOvertimeUpdateWithoutPositionInput;
  where: PayrollDefaultCompensationOvertimeWhereUniqueInput;
};

export type PayrollDefaultCompensationOvertimeUpdateWithWhereUniqueWithoutPropertyInput = {
  data: PayrollDefaultCompensationOvertimeUpdateWithoutPropertyInput;
  where: PayrollDefaultCompensationOvertimeWhereUniqueInput;
};

export type PayrollDefaultCompensationOvertimeUpdateWithoutClientInput = {
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expectedHoursPerWeek?: Maybe<DecimalFieldUpdateOperationsInput>;
  hourlyCompensation?: Maybe<DecimalFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  originatedEmployeeOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeUpdateManyWithoutCreatedFromDefaultInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutDefaultOvertimeValuesInput>;
  property?: Maybe<PropertyUpdateOneWithoutPayrollDefaultCompensationOvertimeValuesInput>;
};

export type PayrollDefaultCompensationOvertimeUpdateWithoutOriginatedEmployeeOvertimeValuesInput = {
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollDefaultCompensationOvertimeValuesInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expectedHoursPerWeek?: Maybe<DecimalFieldUpdateOperationsInput>;
  hourlyCompensation?: Maybe<DecimalFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutDefaultOvertimeValuesInput>;
  property?: Maybe<PropertyUpdateOneWithoutPayrollDefaultCompensationOvertimeValuesInput>;
};

export type PayrollDefaultCompensationOvertimeUpdateWithoutPositionInput = {
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollDefaultCompensationOvertimeValuesInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expectedHoursPerWeek?: Maybe<DecimalFieldUpdateOperationsInput>;
  hourlyCompensation?: Maybe<DecimalFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  originatedEmployeeOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeUpdateManyWithoutCreatedFromDefaultInput>;
  property?: Maybe<PropertyUpdateOneWithoutPayrollDefaultCompensationOvertimeValuesInput>;
};

export type PayrollDefaultCompensationOvertimeUpdateWithoutPropertyInput = {
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollDefaultCompensationOvertimeValuesInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expectedHoursPerWeek?: Maybe<DecimalFieldUpdateOperationsInput>;
  hourlyCompensation?: Maybe<DecimalFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  originatedEmployeeOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeUpdateManyWithoutCreatedFromDefaultInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutDefaultOvertimeValuesInput>;
};

export type PayrollDefaultCompensationOvertimeUpsertWithWhereUniqueWithoutClientInput = {
  create: PayrollDefaultCompensationOvertimeCreateWithoutClientInput;
  update: PayrollDefaultCompensationOvertimeUpdateWithoutClientInput;
  where: PayrollDefaultCompensationOvertimeWhereUniqueInput;
};

export type PayrollDefaultCompensationOvertimeUpsertWithWhereUniqueWithoutPositionInput = {
  create: PayrollDefaultCompensationOvertimeCreateWithoutPositionInput;
  update: PayrollDefaultCompensationOvertimeUpdateWithoutPositionInput;
  where: PayrollDefaultCompensationOvertimeWhereUniqueInput;
};

export type PayrollDefaultCompensationOvertimeUpsertWithWhereUniqueWithoutPropertyInput = {
  create: PayrollDefaultCompensationOvertimeCreateWithoutPropertyInput;
  update: PayrollDefaultCompensationOvertimeUpdateWithoutPropertyInput;
  where: PayrollDefaultCompensationOvertimeWhereUniqueInput;
};

export type PayrollDefaultCompensationOvertimeUpsertWithoutOriginatedEmployeeOvertimeValuesInput = {
  create: PayrollDefaultCompensationOvertimeCreateWithoutOriginatedEmployeeOvertimeValuesInput;
  update: PayrollDefaultCompensationOvertimeUpdateWithoutOriginatedEmployeeOvertimeValuesInput;
};

export type PayrollDefaultCompensationOvertimeWhereInput = {
  AND?: Maybe<Array<PayrollDefaultCompensationOvertimeWhereInput>>;
  NOT?: Maybe<Array<PayrollDefaultCompensationOvertimeWhereInput>>;
  OR?: Maybe<Array<PayrollDefaultCompensationOvertimeWhereInput>>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFilter>;
  effectiveEndDate?: Maybe<DateTimeNullableFilter>;
  effectiveStartDate?: Maybe<DateTimeFilter>;
  expectedHoursPerWeek?: Maybe<DecimalFilter>;
  hourlyCompensation?: Maybe<DecimalFilter>;
  id?: Maybe<StringFilter>;
  originatedEmployeeOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeListRelationFilter>;
  position?: Maybe<PayrollPositionRelationFilter>;
  positionId?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringNullableFilter>;
};

export type PayrollDefaultCompensationOvertimeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PayrollDefaultCompensationRaise = {
  __typename?: 'PayrollDefaultCompensationRaise';
  amount: Scalars['Decimal'];
  clientId: Scalars['String'];
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  fixedRaiseDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollRaiseFrequency;
  id: Scalars['String'];
  positionId: Scalars['String'];
  propertyId?: Maybe<Scalars['String']>;
  raiseDay?: Maybe<Scalars['Int']>;
  raiseMonth?: Maybe<Scalars['Int']>;
  raiseType: PayrollRaiseType;
};

export type PayrollDefaultCompensationRaiseCreateManyClientInput = {
  amount: Scalars['Decimal'];
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  fixedRaiseDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollRaiseFrequency;
  id?: Maybe<Scalars['String']>;
  positionId: Scalars['String'];
  propertyId?: Maybe<Scalars['String']>;
  raiseDay?: Maybe<Scalars['Int']>;
  raiseMonth?: Maybe<Scalars['Int']>;
  raiseType: PayrollRaiseType;
};

export type PayrollDefaultCompensationRaiseCreateManyClientInputEnvelope = {
  data: Array<PayrollDefaultCompensationRaiseCreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollDefaultCompensationRaiseCreateManyPositionInput = {
  amount: Scalars['Decimal'];
  clientId: Scalars['String'];
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  fixedRaiseDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollRaiseFrequency;
  id?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['String']>;
  raiseDay?: Maybe<Scalars['Int']>;
  raiseMonth?: Maybe<Scalars['Int']>;
  raiseType: PayrollRaiseType;
};

export type PayrollDefaultCompensationRaiseCreateManyPositionInputEnvelope = {
  data: Array<PayrollDefaultCompensationRaiseCreateManyPositionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollDefaultCompensationRaiseCreateManyPropertyInput = {
  amount: Scalars['Decimal'];
  clientId: Scalars['String'];
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  fixedRaiseDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollRaiseFrequency;
  id?: Maybe<Scalars['String']>;
  positionId: Scalars['String'];
  raiseDay?: Maybe<Scalars['Int']>;
  raiseMonth?: Maybe<Scalars['Int']>;
  raiseType: PayrollRaiseType;
};

export type PayrollDefaultCompensationRaiseCreateManyPropertyInputEnvelope = {
  data: Array<PayrollDefaultCompensationRaiseCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollDefaultCompensationRaiseCreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationRaiseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationRaiseCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationRaiseCreateWithoutClientInput>>;
  createMany?: Maybe<PayrollDefaultCompensationRaiseCreateManyClientInputEnvelope>;
};

export type PayrollDefaultCompensationRaiseCreateNestedManyWithoutPositionInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationRaiseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationRaiseCreateOrConnectWithoutPositionInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationRaiseCreateWithoutPositionInput>>;
  createMany?: Maybe<PayrollDefaultCompensationRaiseCreateManyPositionInputEnvelope>;
};

export type PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationRaiseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationRaiseCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationRaiseCreateWithoutPropertyInput>>;
  createMany?: Maybe<PayrollDefaultCompensationRaiseCreateManyPropertyInputEnvelope>;
};

export type PayrollDefaultCompensationRaiseCreateNestedOneWithoutOriginatedEmployeeRaiseValuesInput = {
  connect?: Maybe<PayrollDefaultCompensationRaiseWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollDefaultCompensationRaiseCreateOrConnectWithoutOriginatedEmployeeRaiseValuesInput>;
  create?: Maybe<PayrollDefaultCompensationRaiseCreateWithoutOriginatedEmployeeRaiseValuesInput>;
};

export type PayrollDefaultCompensationRaiseCreateOrConnectWithoutClientInput = {
  create: PayrollDefaultCompensationRaiseCreateWithoutClientInput;
  where: PayrollDefaultCompensationRaiseWhereUniqueInput;
};

export type PayrollDefaultCompensationRaiseCreateOrConnectWithoutOriginatedEmployeeRaiseValuesInput = {
  create: PayrollDefaultCompensationRaiseCreateWithoutOriginatedEmployeeRaiseValuesInput;
  where: PayrollDefaultCompensationRaiseWhereUniqueInput;
};

export type PayrollDefaultCompensationRaiseCreateOrConnectWithoutPositionInput = {
  create: PayrollDefaultCompensationRaiseCreateWithoutPositionInput;
  where: PayrollDefaultCompensationRaiseWhereUniqueInput;
};

export type PayrollDefaultCompensationRaiseCreateOrConnectWithoutPropertyInput = {
  create: PayrollDefaultCompensationRaiseCreateWithoutPropertyInput;
  where: PayrollDefaultCompensationRaiseWhereUniqueInput;
};

export type PayrollDefaultCompensationRaiseCreateWithoutClientInput = {
  amount: Scalars['Decimal'];
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  fixedRaiseDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollRaiseFrequency;
  id?: Maybe<Scalars['String']>;
  originatedEmployeeRaiseValues?: Maybe<PayrollEmployeeCompensationRaiseCreateNestedManyWithoutCreatedFromDefaultInput>;
  position: PayrollPositionCreateNestedOneWithoutDefaultRaiseValuesInput;
  property?: Maybe<PropertyCreateNestedOneWithoutPayrollDefaultCompensationRaiseValuesInput>;
  raiseDay?: Maybe<Scalars['Int']>;
  raiseMonth?: Maybe<Scalars['Int']>;
  raiseType: PayrollRaiseType;
};

export type PayrollDefaultCompensationRaiseCreateWithoutOriginatedEmployeeRaiseValuesInput = {
  amount: Scalars['Decimal'];
  client: ClientCreateNestedOneWithoutPayrollDefaultCompensationRaiseValuesInput;
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  fixedRaiseDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollRaiseFrequency;
  id?: Maybe<Scalars['String']>;
  position: PayrollPositionCreateNestedOneWithoutDefaultRaiseValuesInput;
  property?: Maybe<PropertyCreateNestedOneWithoutPayrollDefaultCompensationRaiseValuesInput>;
  raiseDay?: Maybe<Scalars['Int']>;
  raiseMonth?: Maybe<Scalars['Int']>;
  raiseType: PayrollRaiseType;
};

export type PayrollDefaultCompensationRaiseCreateWithoutPositionInput = {
  amount: Scalars['Decimal'];
  client: ClientCreateNestedOneWithoutPayrollDefaultCompensationRaiseValuesInput;
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  fixedRaiseDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollRaiseFrequency;
  id?: Maybe<Scalars['String']>;
  originatedEmployeeRaiseValues?: Maybe<PayrollEmployeeCompensationRaiseCreateNestedManyWithoutCreatedFromDefaultInput>;
  property?: Maybe<PropertyCreateNestedOneWithoutPayrollDefaultCompensationRaiseValuesInput>;
  raiseDay?: Maybe<Scalars['Int']>;
  raiseMonth?: Maybe<Scalars['Int']>;
  raiseType: PayrollRaiseType;
};

export type PayrollDefaultCompensationRaiseCreateWithoutPropertyInput = {
  amount: Scalars['Decimal'];
  client: ClientCreateNestedOneWithoutPayrollDefaultCompensationRaiseValuesInput;
  defaultLevel: PayrollDefaultLevel;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  fixedRaiseDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollRaiseFrequency;
  id?: Maybe<Scalars['String']>;
  originatedEmployeeRaiseValues?: Maybe<PayrollEmployeeCompensationRaiseCreateNestedManyWithoutCreatedFromDefaultInput>;
  position: PayrollPositionCreateNestedOneWithoutDefaultRaiseValuesInput;
  raiseDay?: Maybe<Scalars['Int']>;
  raiseMonth?: Maybe<Scalars['Int']>;
  raiseType: PayrollRaiseType;
};

export type PayrollDefaultCompensationRaiseListRelationFilter = {
  every?: Maybe<PayrollDefaultCompensationRaiseWhereInput>;
  none?: Maybe<PayrollDefaultCompensationRaiseWhereInput>;
  some?: Maybe<PayrollDefaultCompensationRaiseWhereInput>;
};

export type PayrollDefaultCompensationRaiseOrderByInput = {
  amount?: Maybe<SortOrder>;
  clientId?: Maybe<SortOrder>;
  defaultLevel?: Maybe<SortOrder>;
  effectiveEndDate?: Maybe<SortOrder>;
  effectiveStartDate?: Maybe<SortOrder>;
  fixedRaiseDate?: Maybe<SortOrder>;
  frequency?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  positionId?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  raiseDay?: Maybe<SortOrder>;
  raiseMonth?: Maybe<SortOrder>;
  raiseType?: Maybe<SortOrder>;
};

export type PayrollDefaultCompensationRaiseRelationFilter = {
  is?: Maybe<PayrollDefaultCompensationRaiseWhereInput>;
  isNot?: Maybe<PayrollDefaultCompensationRaiseWhereInput>;
};

export enum PayrollDefaultCompensationRaiseScalarFieldEnum {
  Amount = 'amount',
  ClientId = 'clientId',
  DefaultLevel = 'defaultLevel',
  EffectiveEndDate = 'effectiveEndDate',
  EffectiveStartDate = 'effectiveStartDate',
  FixedRaiseDate = 'fixedRaiseDate',
  Frequency = 'frequency',
  Id = 'id',
  PositionId = 'positionId',
  PropertyId = 'propertyId',
  RaiseDay = 'raiseDay',
  RaiseMonth = 'raiseMonth',
  RaiseType = 'raiseType'
}

export type PayrollDefaultCompensationRaiseScalarWhereInput = {
  AND?: Maybe<Array<PayrollDefaultCompensationRaiseScalarWhereInput>>;
  NOT?: Maybe<Array<PayrollDefaultCompensationRaiseScalarWhereInput>>;
  OR?: Maybe<Array<PayrollDefaultCompensationRaiseScalarWhereInput>>;
  amount?: Maybe<DecimalFilter>;
  clientId?: Maybe<StringFilter>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFilter>;
  effectiveEndDate?: Maybe<DateTimeNullableFilter>;
  effectiveStartDate?: Maybe<DateTimeNullableFilter>;
  fixedRaiseDate?: Maybe<DateTimeNullableFilter>;
  frequency?: Maybe<EnumPayrollRaiseFrequencyFilter>;
  id?: Maybe<StringFilter>;
  positionId?: Maybe<StringFilter>;
  propertyId?: Maybe<StringNullableFilter>;
  raiseDay?: Maybe<IntNullableFilter>;
  raiseMonth?: Maybe<IntNullableFilter>;
  raiseType?: Maybe<EnumPayrollRaiseTypeFilter>;
};

export type PayrollDefaultCompensationRaiseUpdateManyMutationInput = {
  amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  fixedRaiseDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  frequency?: Maybe<EnumPayrollRaiseFrequencyFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  raiseDay?: Maybe<NullableIntFieldUpdateOperationsInput>;
  raiseMonth?: Maybe<NullableIntFieldUpdateOperationsInput>;
  raiseType?: Maybe<EnumPayrollRaiseTypeFieldUpdateOperationsInput>;
};

export type PayrollDefaultCompensationRaiseUpdateManyWithWhereWithoutClientInput = {
  data: PayrollDefaultCompensationRaiseUpdateManyMutationInput;
  where: PayrollDefaultCompensationRaiseScalarWhereInput;
};

export type PayrollDefaultCompensationRaiseUpdateManyWithWhereWithoutPositionInput = {
  data: PayrollDefaultCompensationRaiseUpdateManyMutationInput;
  where: PayrollDefaultCompensationRaiseScalarWhereInput;
};

export type PayrollDefaultCompensationRaiseUpdateManyWithWhereWithoutPropertyInput = {
  data: PayrollDefaultCompensationRaiseUpdateManyMutationInput;
  where: PayrollDefaultCompensationRaiseScalarWhereInput;
};

export type PayrollDefaultCompensationRaiseUpdateManyWithoutClientInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationRaiseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationRaiseCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationRaiseCreateWithoutClientInput>>;
  createMany?: Maybe<PayrollDefaultCompensationRaiseCreateManyClientInputEnvelope>;
  delete?: Maybe<Array<PayrollDefaultCompensationRaiseWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollDefaultCompensationRaiseScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollDefaultCompensationRaiseWhereUniqueInput>>;
  set?: Maybe<Array<PayrollDefaultCompensationRaiseWhereUniqueInput>>;
  update?: Maybe<Array<PayrollDefaultCompensationRaiseUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<PayrollDefaultCompensationRaiseUpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<PayrollDefaultCompensationRaiseUpsertWithWhereUniqueWithoutClientInput>>;
};

export type PayrollDefaultCompensationRaiseUpdateManyWithoutPositionInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationRaiseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationRaiseCreateOrConnectWithoutPositionInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationRaiseCreateWithoutPositionInput>>;
  createMany?: Maybe<PayrollDefaultCompensationRaiseCreateManyPositionInputEnvelope>;
  delete?: Maybe<Array<PayrollDefaultCompensationRaiseWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollDefaultCompensationRaiseScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollDefaultCompensationRaiseWhereUniqueInput>>;
  set?: Maybe<Array<PayrollDefaultCompensationRaiseWhereUniqueInput>>;
  update?: Maybe<Array<PayrollDefaultCompensationRaiseUpdateWithWhereUniqueWithoutPositionInput>>;
  updateMany?: Maybe<Array<PayrollDefaultCompensationRaiseUpdateManyWithWhereWithoutPositionInput>>;
  upsert?: Maybe<Array<PayrollDefaultCompensationRaiseUpsertWithWhereUniqueWithoutPositionInput>>;
};

export type PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<PayrollDefaultCompensationRaiseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollDefaultCompensationRaiseCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PayrollDefaultCompensationRaiseCreateWithoutPropertyInput>>;
  createMany?: Maybe<PayrollDefaultCompensationRaiseCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<PayrollDefaultCompensationRaiseWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollDefaultCompensationRaiseScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollDefaultCompensationRaiseWhereUniqueInput>>;
  set?: Maybe<Array<PayrollDefaultCompensationRaiseWhereUniqueInput>>;
  update?: Maybe<Array<PayrollDefaultCompensationRaiseUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<PayrollDefaultCompensationRaiseUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<PayrollDefaultCompensationRaiseUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type PayrollDefaultCompensationRaiseUpdateOneWithoutOriginatedEmployeeRaiseValuesInput = {
  connect?: Maybe<PayrollDefaultCompensationRaiseWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollDefaultCompensationRaiseCreateOrConnectWithoutOriginatedEmployeeRaiseValuesInput>;
  create?: Maybe<PayrollDefaultCompensationRaiseCreateWithoutOriginatedEmployeeRaiseValuesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PayrollDefaultCompensationRaiseUpdateWithoutOriginatedEmployeeRaiseValuesInput>;
  upsert?: Maybe<PayrollDefaultCompensationRaiseUpsertWithoutOriginatedEmployeeRaiseValuesInput>;
};

export type PayrollDefaultCompensationRaiseUpdateWithWhereUniqueWithoutClientInput = {
  data: PayrollDefaultCompensationRaiseUpdateWithoutClientInput;
  where: PayrollDefaultCompensationRaiseWhereUniqueInput;
};

export type PayrollDefaultCompensationRaiseUpdateWithWhereUniqueWithoutPositionInput = {
  data: PayrollDefaultCompensationRaiseUpdateWithoutPositionInput;
  where: PayrollDefaultCompensationRaiseWhereUniqueInput;
};

export type PayrollDefaultCompensationRaiseUpdateWithWhereUniqueWithoutPropertyInput = {
  data: PayrollDefaultCompensationRaiseUpdateWithoutPropertyInput;
  where: PayrollDefaultCompensationRaiseWhereUniqueInput;
};

export type PayrollDefaultCompensationRaiseUpdateWithoutClientInput = {
  amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  fixedRaiseDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  frequency?: Maybe<EnumPayrollRaiseFrequencyFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  originatedEmployeeRaiseValues?: Maybe<PayrollEmployeeCompensationRaiseUpdateManyWithoutCreatedFromDefaultInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutDefaultRaiseValuesInput>;
  property?: Maybe<PropertyUpdateOneWithoutPayrollDefaultCompensationRaiseValuesInput>;
  raiseDay?: Maybe<NullableIntFieldUpdateOperationsInput>;
  raiseMonth?: Maybe<NullableIntFieldUpdateOperationsInput>;
  raiseType?: Maybe<EnumPayrollRaiseTypeFieldUpdateOperationsInput>;
};

export type PayrollDefaultCompensationRaiseUpdateWithoutOriginatedEmployeeRaiseValuesInput = {
  amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollDefaultCompensationRaiseValuesInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  fixedRaiseDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  frequency?: Maybe<EnumPayrollRaiseFrequencyFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutDefaultRaiseValuesInput>;
  property?: Maybe<PropertyUpdateOneWithoutPayrollDefaultCompensationRaiseValuesInput>;
  raiseDay?: Maybe<NullableIntFieldUpdateOperationsInput>;
  raiseMonth?: Maybe<NullableIntFieldUpdateOperationsInput>;
  raiseType?: Maybe<EnumPayrollRaiseTypeFieldUpdateOperationsInput>;
};

export type PayrollDefaultCompensationRaiseUpdateWithoutPositionInput = {
  amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollDefaultCompensationRaiseValuesInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  fixedRaiseDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  frequency?: Maybe<EnumPayrollRaiseFrequencyFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  originatedEmployeeRaiseValues?: Maybe<PayrollEmployeeCompensationRaiseUpdateManyWithoutCreatedFromDefaultInput>;
  property?: Maybe<PropertyUpdateOneWithoutPayrollDefaultCompensationRaiseValuesInput>;
  raiseDay?: Maybe<NullableIntFieldUpdateOperationsInput>;
  raiseMonth?: Maybe<NullableIntFieldUpdateOperationsInput>;
  raiseType?: Maybe<EnumPayrollRaiseTypeFieldUpdateOperationsInput>;
};

export type PayrollDefaultCompensationRaiseUpdateWithoutPropertyInput = {
  amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollDefaultCompensationRaiseValuesInput>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  fixedRaiseDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  frequency?: Maybe<EnumPayrollRaiseFrequencyFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  originatedEmployeeRaiseValues?: Maybe<PayrollEmployeeCompensationRaiseUpdateManyWithoutCreatedFromDefaultInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutDefaultRaiseValuesInput>;
  raiseDay?: Maybe<NullableIntFieldUpdateOperationsInput>;
  raiseMonth?: Maybe<NullableIntFieldUpdateOperationsInput>;
  raiseType?: Maybe<EnumPayrollRaiseTypeFieldUpdateOperationsInput>;
};

export type PayrollDefaultCompensationRaiseUpsertWithWhereUniqueWithoutClientInput = {
  create: PayrollDefaultCompensationRaiseCreateWithoutClientInput;
  update: PayrollDefaultCompensationRaiseUpdateWithoutClientInput;
  where: PayrollDefaultCompensationRaiseWhereUniqueInput;
};

export type PayrollDefaultCompensationRaiseUpsertWithWhereUniqueWithoutPositionInput = {
  create: PayrollDefaultCompensationRaiseCreateWithoutPositionInput;
  update: PayrollDefaultCompensationRaiseUpdateWithoutPositionInput;
  where: PayrollDefaultCompensationRaiseWhereUniqueInput;
};

export type PayrollDefaultCompensationRaiseUpsertWithWhereUniqueWithoutPropertyInput = {
  create: PayrollDefaultCompensationRaiseCreateWithoutPropertyInput;
  update: PayrollDefaultCompensationRaiseUpdateWithoutPropertyInput;
  where: PayrollDefaultCompensationRaiseWhereUniqueInput;
};

export type PayrollDefaultCompensationRaiseUpsertWithoutOriginatedEmployeeRaiseValuesInput = {
  create: PayrollDefaultCompensationRaiseCreateWithoutOriginatedEmployeeRaiseValuesInput;
  update: PayrollDefaultCompensationRaiseUpdateWithoutOriginatedEmployeeRaiseValuesInput;
};

export type PayrollDefaultCompensationRaiseWhereInput = {
  AND?: Maybe<Array<PayrollDefaultCompensationRaiseWhereInput>>;
  NOT?: Maybe<Array<PayrollDefaultCompensationRaiseWhereInput>>;
  OR?: Maybe<Array<PayrollDefaultCompensationRaiseWhereInput>>;
  amount?: Maybe<DecimalFilter>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  defaultLevel?: Maybe<EnumPayrollDefaultLevelFilter>;
  effectiveEndDate?: Maybe<DateTimeNullableFilter>;
  effectiveStartDate?: Maybe<DateTimeNullableFilter>;
  fixedRaiseDate?: Maybe<DateTimeNullableFilter>;
  frequency?: Maybe<EnumPayrollRaiseFrequencyFilter>;
  id?: Maybe<StringFilter>;
  originatedEmployeeRaiseValues?: Maybe<PayrollEmployeeCompensationRaiseListRelationFilter>;
  position?: Maybe<PayrollPositionRelationFilter>;
  positionId?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringNullableFilter>;
  raiseDay?: Maybe<IntNullableFilter>;
  raiseMonth?: Maybe<IntNullableFilter>;
  raiseType?: Maybe<EnumPayrollRaiseTypeFilter>;
};

export type PayrollDefaultCompensationRaiseWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum PayrollDefaultLevel {
  Client = 'CLIENT',
  Property = 'PROPERTY'
}

export type PayrollDefaultOvertimeModel = {
  __typename?: 'PayrollDefaultOvertimeModel';
  /** When set, indicates that this overtime will end on the given date. When unset, the overtime will continue forever. */
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  /** When the overtime will start. */
  effectiveStartDate: Scalars['DateTime'];
  /** The number of hours of overtime expected to be worked at the given compensation rate. */
  expectedHoursPerWeek: Scalars['Decimal'];
  /** Hourly compensation amount for overtime. */
  hourlyCompensation: Scalars['Decimal'];
};

export type PayrollDefaultRaiseModel = {
  __typename?: 'PayrollDefaultRaiseModel';
  amount: Scalars['Decimal'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollRaiseFrequency;
  onetimeRaiseDate?: Maybe<Scalars['DateTime']>;
  raiseDay?: Maybe<Scalars['Int']>;
  raiseMonth?: Maybe<Scalars['Int']>;
  /** The type of raise either fixed or recurring annually. */
  raiseType: PayrollRaiseType;
};

export type PayrollDefaultsModel = {
  __typename?: 'PayrollDefaultsModel';
  baseComp?: Maybe<PayrollDefaultBaseCompModel>;
  bonuses: Array<PayrollDefaultBonusModel>;
  compItems: Array<PayrollDefaultCompItemModel>;
  overtime: Array<PayrollDefaultOvertimeModel>;
  position: PayrollPositionModel;
  propertyId: Scalars['String'];
  raises: Array<PayrollDefaultRaiseModel>;
};

export type PayrollDifferentialEmployeeBonusInput = {
  amount: Scalars['Decimal'];
  bonusDay?: Maybe<Scalars['Int']>;
  bonusMonth?: Maybe<Scalars['Int']>;
  bonusType: PayrollBonusType;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollBonusFrequency;
  /** When set and matching an existing bonus, the supplied data updates the existing bonus entry. Otherwise, a new bonus entry is created. */
  id?: Maybe<Scalars['ID']>;
  onetimeBonusDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollDifferentialEmployeeCompItemInput = {
  annualCostCap?: Maybe<Scalars['Decimal']>;
  calculationType: PayrollCompensationItemCalculationType;
  compensationItemId: Scalars['ID'];
  costPerPayPeriod: Scalars['Decimal'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  /** When set and matching an existing compensation item, the supplied data updates the existing compensation item entry. Otherwise, a new compensation item entry is created. */
  id?: Maybe<Scalars['ID']>;
};

export type PayrollDifferentialEmployeeOvertimeInput = {
  /** When set, indicates that this overtime will end on the given date. When unset, the overtime will continue forever. */
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  /** When the overtime will start. */
  effectiveStartDate: Scalars['DateTime'];
  /** The number of hours of overtime expected to be worked at the given compensation rate. */
  expectedHoursPerWeek: Scalars['Decimal'];
  /** Hourly compensation amount for overtime. */
  hourlyCompensation: Scalars['Decimal'];
  /** When set and matching an existing overtime, the supplied data updates the existing overtime entry. Otherwise, a new overtime entry is created. */
  id?: Maybe<Scalars['ID']>;
};

export type PayrollDifferentialEmployeeRaiseInput = {
  amount: Scalars['Decimal'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollRaiseFrequency;
  /** When set and matching an existing raise, the supplied data updates the existing raise entry. Otherwise, a new raise entry is created. */
  id?: Maybe<Scalars['ID']>;
  onetimeRaiseDate?: Maybe<Scalars['DateTime']>;
  raiseDay?: Maybe<Scalars['Int']>;
  raiseMonth?: Maybe<Scalars['Int']>;
  raiseType: PayrollRaiseType;
};

export type PayrollDriverCompensationItemModel = {
  __typename?: 'PayrollDriverCompensationItemModel';
  itemType: Scalars['String'];
  positions: Array<PayrollDriverCompensationItemPositionsModel>;
};

export type PayrollDriverCompensationItemPositionsModel = {
  __typename?: 'PayrollDriverCompensationItemPositionsModel';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type PayrollDriverModel = {
  __typename?: 'PayrollDriverModel';
  compensationItems: Array<PayrollDriverCompensationItemModel>;
  isDriven: Scalars['Boolean'];
};

export type PayrollEmployeeBaseCompModel = {
  __typename?: 'PayrollEmployeeBaseCompModel';
  /** This value represents the total annual compensation, and it is set when compensationType is Annual. */
  annualCompensation?: Maybe<Scalars['Decimal']>;
  compensationType: PayrollCompensationType;
  employeeId: Scalars['ID'];
  /** Expected number of weekly hours for an hourly employee. It is set when the compensationType is hourly. */
  expectedHoursPerWeek?: Maybe<Scalars['Decimal']>;
  /** This value represents the hourly compensation, and it is set when compensationType is hourly. */
  hourlyCompensation?: Maybe<Scalars['Decimal']>;
};

export type PayrollEmployeeBonusModel = {
  __typename?: 'PayrollEmployeeBonusModel';
  amount: Scalars['Decimal'];
  bonusDay?: Maybe<Scalars['Int']>;
  bonusMonth?: Maybe<Scalars['Int']>;
  /** The type of raise either fixed or recurring annually. */
  bonusType: PayrollBonusType;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollBonusFrequency;
  id: Scalars['ID'];
  onetimeBonusDate?: Maybe<Scalars['DateTime']>;
};

export enum PayrollEmployeeClassification {
  Contractor = 'CONTRACTOR',
  Employee = 'EMPLOYEE'
}

export type PayrollEmployeeCompItemModel = {
  __typename?: 'PayrollEmployeeCompItemModel';
  annualCostCap?: Maybe<Scalars['Decimal']>;
  calculationType: PayrollCompensationItemCalculationType;
  compensationItem: PayrollCompensationItemModel;
  costPerPayPeriod: Scalars['Decimal'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type PayrollEmployeeCompensationBaseCreateNestedOneWithoutEmployeeInput = {
  connect?: Maybe<PayrollEmployeeCompensationBaseWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollEmployeeCompensationBaseCreateOrConnectWithoutEmployeeInput>;
  create?: Maybe<PayrollEmployeeCompensationBaseCreateWithoutEmployeeInput>;
};

export type PayrollEmployeeCompensationBaseCreateOrConnectWithoutEmployeeInput = {
  create: PayrollEmployeeCompensationBaseCreateWithoutEmployeeInput;
  where: PayrollEmployeeCompensationBaseWhereUniqueInput;
};

export type PayrollEmployeeCompensationBaseCreateWithoutEmployeeInput = {
  annualCompensation?: Maybe<Scalars['Decimal']>;
  compensationType: PayrollCompensationType;
  expectedHoursPerWeek?: Maybe<Scalars['Decimal']>;
  hourlyCompensation?: Maybe<Scalars['Decimal']>;
};

export type PayrollEmployeeCompensationBaseRelationFilter = {
  is?: Maybe<PayrollEmployeeCompensationBaseWhereInput>;
  isNot?: Maybe<PayrollEmployeeCompensationBaseWhereInput>;
};

export type PayrollEmployeeCompensationBaseUpdateOneWithoutEmployeeInput = {
  connect?: Maybe<PayrollEmployeeCompensationBaseWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollEmployeeCompensationBaseCreateOrConnectWithoutEmployeeInput>;
  create?: Maybe<PayrollEmployeeCompensationBaseCreateWithoutEmployeeInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PayrollEmployeeCompensationBaseUpdateWithoutEmployeeInput>;
  upsert?: Maybe<PayrollEmployeeCompensationBaseUpsertWithoutEmployeeInput>;
};

export type PayrollEmployeeCompensationBaseUpdateWithoutEmployeeInput = {
  annualCompensation?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  compensationType?: Maybe<EnumPayrollCompensationTypeFieldUpdateOperationsInput>;
  expectedHoursPerWeek?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  hourlyCompensation?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
};

export type PayrollEmployeeCompensationBaseUpsertWithoutEmployeeInput = {
  create: PayrollEmployeeCompensationBaseCreateWithoutEmployeeInput;
  update: PayrollEmployeeCompensationBaseUpdateWithoutEmployeeInput;
};

export type PayrollEmployeeCompensationBaseWhereInput = {
  AND?: Maybe<Array<PayrollEmployeeCompensationBaseWhereInput>>;
  NOT?: Maybe<Array<PayrollEmployeeCompensationBaseWhereInput>>;
  OR?: Maybe<Array<PayrollEmployeeCompensationBaseWhereInput>>;
  annualCompensation?: Maybe<DecimalNullableFilter>;
  compensationType?: Maybe<EnumPayrollCompensationTypeFilter>;
  employee?: Maybe<PayrollEmployeeRelationFilter>;
  employeeId?: Maybe<StringFilter>;
  expectedHoursPerWeek?: Maybe<DecimalNullableFilter>;
  hourlyCompensation?: Maybe<DecimalNullableFilter>;
};

export type PayrollEmployeeCompensationBaseWhereUniqueInput = {
  employeeId?: Maybe<Scalars['String']>;
};

export type PayrollEmployeeCompensationBonusCreateManyCreatedFromDefaultInput = {
  amount: Scalars['Decimal'];
  bonusDay?: Maybe<Scalars['Int']>;
  bonusMonth?: Maybe<Scalars['Int']>;
  bonusType: PayrollBonusType;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  employeeId: Scalars['String'];
  frequency: PayrollBonusFrequency;
  id?: Maybe<Scalars['String']>;
  onetimeBonusDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollEmployeeCompensationBonusCreateManyCreatedFromDefaultInputEnvelope = {
  data: Array<PayrollEmployeeCompensationBonusCreateManyCreatedFromDefaultInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollEmployeeCompensationBonusCreateManyEmployeeInput = {
  amount: Scalars['Decimal'];
  bonusDay?: Maybe<Scalars['Int']>;
  bonusMonth?: Maybe<Scalars['Int']>;
  bonusType: PayrollBonusType;
  createdFromDefaultId?: Maybe<Scalars['String']>;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollBonusFrequency;
  id?: Maybe<Scalars['String']>;
  onetimeBonusDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollEmployeeCompensationBonusCreateManyEmployeeInputEnvelope = {
  data: Array<PayrollEmployeeCompensationBonusCreateManyEmployeeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollEmployeeCompensationBonusCreateNestedManyWithoutCreatedFromDefaultInput = {
  connect?: Maybe<Array<PayrollEmployeeCompensationBonusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeeCompensationBonusCreateOrConnectWithoutCreatedFromDefaultInput>>;
  create?: Maybe<Array<PayrollEmployeeCompensationBonusCreateWithoutCreatedFromDefaultInput>>;
  createMany?: Maybe<PayrollEmployeeCompensationBonusCreateManyCreatedFromDefaultInputEnvelope>;
};

export type PayrollEmployeeCompensationBonusCreateNestedManyWithoutEmployeeInput = {
  connect?: Maybe<Array<PayrollEmployeeCompensationBonusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeeCompensationBonusCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<PayrollEmployeeCompensationBonusCreateWithoutEmployeeInput>>;
  createMany?: Maybe<PayrollEmployeeCompensationBonusCreateManyEmployeeInputEnvelope>;
};

export type PayrollEmployeeCompensationBonusCreateOrConnectWithoutCreatedFromDefaultInput = {
  create: PayrollEmployeeCompensationBonusCreateWithoutCreatedFromDefaultInput;
  where: PayrollEmployeeCompensationBonusWhereUniqueInput;
};

export type PayrollEmployeeCompensationBonusCreateOrConnectWithoutEmployeeInput = {
  create: PayrollEmployeeCompensationBonusCreateWithoutEmployeeInput;
  where: PayrollEmployeeCompensationBonusWhereUniqueInput;
};

export type PayrollEmployeeCompensationBonusCreateWithoutCreatedFromDefaultInput = {
  amount: Scalars['Decimal'];
  bonusDay?: Maybe<Scalars['Int']>;
  bonusMonth?: Maybe<Scalars['Int']>;
  bonusType: PayrollBonusType;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  employee: PayrollEmployeeCreateNestedOneWithoutCompensationBonusesInput;
  frequency: PayrollBonusFrequency;
  id?: Maybe<Scalars['String']>;
  onetimeBonusDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollEmployeeCompensationBonusCreateWithoutEmployeeInput = {
  amount: Scalars['Decimal'];
  bonusDay?: Maybe<Scalars['Int']>;
  bonusMonth?: Maybe<Scalars['Int']>;
  bonusType: PayrollBonusType;
  createdFromDefault?: Maybe<PayrollDefaultCompensationBonusCreateNestedOneWithoutOriginatedEmployeeBonusValuesInput>;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollBonusFrequency;
  id?: Maybe<Scalars['String']>;
  onetimeBonusDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollEmployeeCompensationBonusListRelationFilter = {
  every?: Maybe<PayrollEmployeeCompensationBonusWhereInput>;
  none?: Maybe<PayrollEmployeeCompensationBonusWhereInput>;
  some?: Maybe<PayrollEmployeeCompensationBonusWhereInput>;
};

export type PayrollEmployeeCompensationBonusScalarWhereInput = {
  AND?: Maybe<Array<PayrollEmployeeCompensationBonusScalarWhereInput>>;
  NOT?: Maybe<Array<PayrollEmployeeCompensationBonusScalarWhereInput>>;
  OR?: Maybe<Array<PayrollEmployeeCompensationBonusScalarWhereInput>>;
  amount?: Maybe<DecimalFilter>;
  bonusDay?: Maybe<IntNullableFilter>;
  bonusMonth?: Maybe<IntNullableFilter>;
  bonusType?: Maybe<EnumPayrollBonusTypeFilter>;
  createdFromDefaultId?: Maybe<StringNullableFilter>;
  effectiveEndDate?: Maybe<DateTimeNullableFilter>;
  effectiveStartDate?: Maybe<DateTimeNullableFilter>;
  employeeId?: Maybe<StringFilter>;
  frequency?: Maybe<EnumPayrollBonusFrequencyFilter>;
  id?: Maybe<StringFilter>;
  onetimeBonusDate?: Maybe<DateTimeNullableFilter>;
};

export type PayrollEmployeeCompensationBonusUpdateManyMutationInput = {
  amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  bonusDay?: Maybe<NullableIntFieldUpdateOperationsInput>;
  bonusMonth?: Maybe<NullableIntFieldUpdateOperationsInput>;
  bonusType?: Maybe<EnumPayrollBonusTypeFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  frequency?: Maybe<EnumPayrollBonusFrequencyFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  onetimeBonusDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PayrollEmployeeCompensationBonusUpdateManyWithWhereWithoutCreatedFromDefaultInput = {
  data: PayrollEmployeeCompensationBonusUpdateManyMutationInput;
  where: PayrollEmployeeCompensationBonusScalarWhereInput;
};

export type PayrollEmployeeCompensationBonusUpdateManyWithWhereWithoutEmployeeInput = {
  data: PayrollEmployeeCompensationBonusUpdateManyMutationInput;
  where: PayrollEmployeeCompensationBonusScalarWhereInput;
};

export type PayrollEmployeeCompensationBonusUpdateManyWithoutCreatedFromDefaultInput = {
  connect?: Maybe<Array<PayrollEmployeeCompensationBonusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeeCompensationBonusCreateOrConnectWithoutCreatedFromDefaultInput>>;
  create?: Maybe<Array<PayrollEmployeeCompensationBonusCreateWithoutCreatedFromDefaultInput>>;
  createMany?: Maybe<PayrollEmployeeCompensationBonusCreateManyCreatedFromDefaultInputEnvelope>;
  delete?: Maybe<Array<PayrollEmployeeCompensationBonusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollEmployeeCompensationBonusScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollEmployeeCompensationBonusWhereUniqueInput>>;
  set?: Maybe<Array<PayrollEmployeeCompensationBonusWhereUniqueInput>>;
  update?: Maybe<Array<PayrollEmployeeCompensationBonusUpdateWithWhereUniqueWithoutCreatedFromDefaultInput>>;
  updateMany?: Maybe<Array<PayrollEmployeeCompensationBonusUpdateManyWithWhereWithoutCreatedFromDefaultInput>>;
  upsert?: Maybe<Array<PayrollEmployeeCompensationBonusUpsertWithWhereUniqueWithoutCreatedFromDefaultInput>>;
};

export type PayrollEmployeeCompensationBonusUpdateManyWithoutEmployeeInput = {
  connect?: Maybe<Array<PayrollEmployeeCompensationBonusWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeeCompensationBonusCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<PayrollEmployeeCompensationBonusCreateWithoutEmployeeInput>>;
  createMany?: Maybe<PayrollEmployeeCompensationBonusCreateManyEmployeeInputEnvelope>;
  delete?: Maybe<Array<PayrollEmployeeCompensationBonusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollEmployeeCompensationBonusScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollEmployeeCompensationBonusWhereUniqueInput>>;
  set?: Maybe<Array<PayrollEmployeeCompensationBonusWhereUniqueInput>>;
  update?: Maybe<Array<PayrollEmployeeCompensationBonusUpdateWithWhereUniqueWithoutEmployeeInput>>;
  updateMany?: Maybe<Array<PayrollEmployeeCompensationBonusUpdateManyWithWhereWithoutEmployeeInput>>;
  upsert?: Maybe<Array<PayrollEmployeeCompensationBonusUpsertWithWhereUniqueWithoutEmployeeInput>>;
};

export type PayrollEmployeeCompensationBonusUpdateWithWhereUniqueWithoutCreatedFromDefaultInput = {
  data: PayrollEmployeeCompensationBonusUpdateWithoutCreatedFromDefaultInput;
  where: PayrollEmployeeCompensationBonusWhereUniqueInput;
};

export type PayrollEmployeeCompensationBonusUpdateWithWhereUniqueWithoutEmployeeInput = {
  data: PayrollEmployeeCompensationBonusUpdateWithoutEmployeeInput;
  where: PayrollEmployeeCompensationBonusWhereUniqueInput;
};

export type PayrollEmployeeCompensationBonusUpdateWithoutCreatedFromDefaultInput = {
  amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  bonusDay?: Maybe<NullableIntFieldUpdateOperationsInput>;
  bonusMonth?: Maybe<NullableIntFieldUpdateOperationsInput>;
  bonusType?: Maybe<EnumPayrollBonusTypeFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  employee?: Maybe<PayrollEmployeeUpdateOneRequiredWithoutCompensationBonusesInput>;
  frequency?: Maybe<EnumPayrollBonusFrequencyFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  onetimeBonusDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PayrollEmployeeCompensationBonusUpdateWithoutEmployeeInput = {
  amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  bonusDay?: Maybe<NullableIntFieldUpdateOperationsInput>;
  bonusMonth?: Maybe<NullableIntFieldUpdateOperationsInput>;
  bonusType?: Maybe<EnumPayrollBonusTypeFieldUpdateOperationsInput>;
  createdFromDefault?: Maybe<PayrollDefaultCompensationBonusUpdateOneWithoutOriginatedEmployeeBonusValuesInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  frequency?: Maybe<EnumPayrollBonusFrequencyFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  onetimeBonusDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PayrollEmployeeCompensationBonusUpsertWithWhereUniqueWithoutCreatedFromDefaultInput = {
  create: PayrollEmployeeCompensationBonusCreateWithoutCreatedFromDefaultInput;
  update: PayrollEmployeeCompensationBonusUpdateWithoutCreatedFromDefaultInput;
  where: PayrollEmployeeCompensationBonusWhereUniqueInput;
};

export type PayrollEmployeeCompensationBonusUpsertWithWhereUniqueWithoutEmployeeInput = {
  create: PayrollEmployeeCompensationBonusCreateWithoutEmployeeInput;
  update: PayrollEmployeeCompensationBonusUpdateWithoutEmployeeInput;
  where: PayrollEmployeeCompensationBonusWhereUniqueInput;
};

export type PayrollEmployeeCompensationBonusWhereInput = {
  AND?: Maybe<Array<PayrollEmployeeCompensationBonusWhereInput>>;
  NOT?: Maybe<Array<PayrollEmployeeCompensationBonusWhereInput>>;
  OR?: Maybe<Array<PayrollEmployeeCompensationBonusWhereInput>>;
  amount?: Maybe<DecimalFilter>;
  bonusDay?: Maybe<IntNullableFilter>;
  bonusMonth?: Maybe<IntNullableFilter>;
  bonusType?: Maybe<EnumPayrollBonusTypeFilter>;
  createdFromDefault?: Maybe<PayrollDefaultCompensationBonusRelationFilter>;
  createdFromDefaultId?: Maybe<StringNullableFilter>;
  effectiveEndDate?: Maybe<DateTimeNullableFilter>;
  effectiveStartDate?: Maybe<DateTimeNullableFilter>;
  employee?: Maybe<PayrollEmployeeRelationFilter>;
  employeeId?: Maybe<StringFilter>;
  frequency?: Maybe<EnumPayrollBonusFrequencyFilter>;
  id?: Maybe<StringFilter>;
  onetimeBonusDate?: Maybe<DateTimeNullableFilter>;
};

export type PayrollEmployeeCompensationBonusWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PayrollEmployeeCompensationItemCreateManyCompensationItemInput = {
  annualCostCap?: Maybe<Scalars['Decimal']>;
  calculationType: PayrollCompensationItemCalculationType;
  costPerPayPeriod: Scalars['Decimal'];
  createdFromDefaultId?: Maybe<Scalars['String']>;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  employeeId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
};

export type PayrollEmployeeCompensationItemCreateManyCompensationItemInputEnvelope = {
  data: Array<PayrollEmployeeCompensationItemCreateManyCompensationItemInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollEmployeeCompensationItemCreateManyCreatedFromDefaultInput = {
  annualCostCap?: Maybe<Scalars['Decimal']>;
  calculationType: PayrollCompensationItemCalculationType;
  compensationItemId: Scalars['String'];
  costPerPayPeriod: Scalars['Decimal'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  employeeId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
};

export type PayrollEmployeeCompensationItemCreateManyCreatedFromDefaultInputEnvelope = {
  data: Array<PayrollEmployeeCompensationItemCreateManyCreatedFromDefaultInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollEmployeeCompensationItemCreateManyEmployeeInput = {
  annualCostCap?: Maybe<Scalars['Decimal']>;
  calculationType: PayrollCompensationItemCalculationType;
  compensationItemId: Scalars['String'];
  costPerPayPeriod: Scalars['Decimal'];
  createdFromDefaultId?: Maybe<Scalars['String']>;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
};

export type PayrollEmployeeCompensationItemCreateManyEmployeeInputEnvelope = {
  data: Array<PayrollEmployeeCompensationItemCreateManyEmployeeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollEmployeeCompensationItemCreateNestedManyWithoutCompensationItemInput = {
  connect?: Maybe<Array<PayrollEmployeeCompensationItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeeCompensationItemCreateOrConnectWithoutCompensationItemInput>>;
  create?: Maybe<Array<PayrollEmployeeCompensationItemCreateWithoutCompensationItemInput>>;
  createMany?: Maybe<PayrollEmployeeCompensationItemCreateManyCompensationItemInputEnvelope>;
};

export type PayrollEmployeeCompensationItemCreateNestedManyWithoutCreatedFromDefaultInput = {
  connect?: Maybe<Array<PayrollEmployeeCompensationItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeeCompensationItemCreateOrConnectWithoutCreatedFromDefaultInput>>;
  create?: Maybe<Array<PayrollEmployeeCompensationItemCreateWithoutCreatedFromDefaultInput>>;
  createMany?: Maybe<PayrollEmployeeCompensationItemCreateManyCreatedFromDefaultInputEnvelope>;
};

export type PayrollEmployeeCompensationItemCreateNestedManyWithoutEmployeeInput = {
  connect?: Maybe<Array<PayrollEmployeeCompensationItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeeCompensationItemCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<PayrollEmployeeCompensationItemCreateWithoutEmployeeInput>>;
  createMany?: Maybe<PayrollEmployeeCompensationItemCreateManyEmployeeInputEnvelope>;
};

export type PayrollEmployeeCompensationItemCreateOrConnectWithoutCompensationItemInput = {
  create: PayrollEmployeeCompensationItemCreateWithoutCompensationItemInput;
  where: PayrollEmployeeCompensationItemWhereUniqueInput;
};

export type PayrollEmployeeCompensationItemCreateOrConnectWithoutCreatedFromDefaultInput = {
  create: PayrollEmployeeCompensationItemCreateWithoutCreatedFromDefaultInput;
  where: PayrollEmployeeCompensationItemWhereUniqueInput;
};

export type PayrollEmployeeCompensationItemCreateOrConnectWithoutEmployeeInput = {
  create: PayrollEmployeeCompensationItemCreateWithoutEmployeeInput;
  where: PayrollEmployeeCompensationItemWhereUniqueInput;
};

export type PayrollEmployeeCompensationItemCreateWithoutCompensationItemInput = {
  annualCostCap?: Maybe<Scalars['Decimal']>;
  calculationType: PayrollCompensationItemCalculationType;
  costPerPayPeriod: Scalars['Decimal'];
  createdFromDefault?: Maybe<PayrollDefaultCompensationItemCreateNestedOneWithoutOriginatedEmployeeItemValuesInput>;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  employee: PayrollEmployeeCreateNestedOneWithoutCompensationItemsInput;
  id?: Maybe<Scalars['String']>;
};

export type PayrollEmployeeCompensationItemCreateWithoutCreatedFromDefaultInput = {
  annualCostCap?: Maybe<Scalars['Decimal']>;
  calculationType: PayrollCompensationItemCalculationType;
  compensationItem: PayrollCompensationItemCreateNestedOneWithoutEmployeeCompensationItemsInput;
  costPerPayPeriod: Scalars['Decimal'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  employee: PayrollEmployeeCreateNestedOneWithoutCompensationItemsInput;
  id?: Maybe<Scalars['String']>;
};

export type PayrollEmployeeCompensationItemCreateWithoutEmployeeInput = {
  annualCostCap?: Maybe<Scalars['Decimal']>;
  calculationType: PayrollCompensationItemCalculationType;
  compensationItem: PayrollCompensationItemCreateNestedOneWithoutEmployeeCompensationItemsInput;
  costPerPayPeriod: Scalars['Decimal'];
  createdFromDefault?: Maybe<PayrollDefaultCompensationItemCreateNestedOneWithoutOriginatedEmployeeItemValuesInput>;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
};

export type PayrollEmployeeCompensationItemListRelationFilter = {
  every?: Maybe<PayrollEmployeeCompensationItemWhereInput>;
  none?: Maybe<PayrollEmployeeCompensationItemWhereInput>;
  some?: Maybe<PayrollEmployeeCompensationItemWhereInput>;
};

export type PayrollEmployeeCompensationItemScalarWhereInput = {
  AND?: Maybe<Array<PayrollEmployeeCompensationItemScalarWhereInput>>;
  NOT?: Maybe<Array<PayrollEmployeeCompensationItemScalarWhereInput>>;
  OR?: Maybe<Array<PayrollEmployeeCompensationItemScalarWhereInput>>;
  annualCostCap?: Maybe<DecimalNullableFilter>;
  calculationType?: Maybe<EnumPayrollCompensationItemCalculationTypeFilter>;
  compensationItemId?: Maybe<StringFilter>;
  costPerPayPeriod?: Maybe<DecimalFilter>;
  createdFromDefaultId?: Maybe<StringNullableFilter>;
  effectiveEndDate?: Maybe<DateTimeNullableFilter>;
  effectiveStartDate?: Maybe<DateTimeFilter>;
  employeeId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
};

export type PayrollEmployeeCompensationItemUpdateManyMutationInput = {
  annualCostCap?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  calculationType?: Maybe<EnumPayrollCompensationItemCalculationTypeFieldUpdateOperationsInput>;
  costPerPayPeriod?: Maybe<DecimalFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PayrollEmployeeCompensationItemUpdateManyWithWhereWithoutCompensationItemInput = {
  data: PayrollEmployeeCompensationItemUpdateManyMutationInput;
  where: PayrollEmployeeCompensationItemScalarWhereInput;
};

export type PayrollEmployeeCompensationItemUpdateManyWithWhereWithoutCreatedFromDefaultInput = {
  data: PayrollEmployeeCompensationItemUpdateManyMutationInput;
  where: PayrollEmployeeCompensationItemScalarWhereInput;
};

export type PayrollEmployeeCompensationItemUpdateManyWithWhereWithoutEmployeeInput = {
  data: PayrollEmployeeCompensationItemUpdateManyMutationInput;
  where: PayrollEmployeeCompensationItemScalarWhereInput;
};

export type PayrollEmployeeCompensationItemUpdateManyWithoutCompensationItemInput = {
  connect?: Maybe<Array<PayrollEmployeeCompensationItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeeCompensationItemCreateOrConnectWithoutCompensationItemInput>>;
  create?: Maybe<Array<PayrollEmployeeCompensationItemCreateWithoutCompensationItemInput>>;
  createMany?: Maybe<PayrollEmployeeCompensationItemCreateManyCompensationItemInputEnvelope>;
  delete?: Maybe<Array<PayrollEmployeeCompensationItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollEmployeeCompensationItemScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollEmployeeCompensationItemWhereUniqueInput>>;
  set?: Maybe<Array<PayrollEmployeeCompensationItemWhereUniqueInput>>;
  update?: Maybe<Array<PayrollEmployeeCompensationItemUpdateWithWhereUniqueWithoutCompensationItemInput>>;
  updateMany?: Maybe<Array<PayrollEmployeeCompensationItemUpdateManyWithWhereWithoutCompensationItemInput>>;
  upsert?: Maybe<Array<PayrollEmployeeCompensationItemUpsertWithWhereUniqueWithoutCompensationItemInput>>;
};

export type PayrollEmployeeCompensationItemUpdateManyWithoutCreatedFromDefaultInput = {
  connect?: Maybe<Array<PayrollEmployeeCompensationItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeeCompensationItemCreateOrConnectWithoutCreatedFromDefaultInput>>;
  create?: Maybe<Array<PayrollEmployeeCompensationItemCreateWithoutCreatedFromDefaultInput>>;
  createMany?: Maybe<PayrollEmployeeCompensationItemCreateManyCreatedFromDefaultInputEnvelope>;
  delete?: Maybe<Array<PayrollEmployeeCompensationItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollEmployeeCompensationItemScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollEmployeeCompensationItemWhereUniqueInput>>;
  set?: Maybe<Array<PayrollEmployeeCompensationItemWhereUniqueInput>>;
  update?: Maybe<Array<PayrollEmployeeCompensationItemUpdateWithWhereUniqueWithoutCreatedFromDefaultInput>>;
  updateMany?: Maybe<Array<PayrollEmployeeCompensationItemUpdateManyWithWhereWithoutCreatedFromDefaultInput>>;
  upsert?: Maybe<Array<PayrollEmployeeCompensationItemUpsertWithWhereUniqueWithoutCreatedFromDefaultInput>>;
};

export type PayrollEmployeeCompensationItemUpdateManyWithoutEmployeeInput = {
  connect?: Maybe<Array<PayrollEmployeeCompensationItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeeCompensationItemCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<PayrollEmployeeCompensationItemCreateWithoutEmployeeInput>>;
  createMany?: Maybe<PayrollEmployeeCompensationItemCreateManyEmployeeInputEnvelope>;
  delete?: Maybe<Array<PayrollEmployeeCompensationItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollEmployeeCompensationItemScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollEmployeeCompensationItemWhereUniqueInput>>;
  set?: Maybe<Array<PayrollEmployeeCompensationItemWhereUniqueInput>>;
  update?: Maybe<Array<PayrollEmployeeCompensationItemUpdateWithWhereUniqueWithoutEmployeeInput>>;
  updateMany?: Maybe<Array<PayrollEmployeeCompensationItemUpdateManyWithWhereWithoutEmployeeInput>>;
  upsert?: Maybe<Array<PayrollEmployeeCompensationItemUpsertWithWhereUniqueWithoutEmployeeInput>>;
};

export type PayrollEmployeeCompensationItemUpdateWithWhereUniqueWithoutCompensationItemInput = {
  data: PayrollEmployeeCompensationItemUpdateWithoutCompensationItemInput;
  where: PayrollEmployeeCompensationItemWhereUniqueInput;
};

export type PayrollEmployeeCompensationItemUpdateWithWhereUniqueWithoutCreatedFromDefaultInput = {
  data: PayrollEmployeeCompensationItemUpdateWithoutCreatedFromDefaultInput;
  where: PayrollEmployeeCompensationItemWhereUniqueInput;
};

export type PayrollEmployeeCompensationItemUpdateWithWhereUniqueWithoutEmployeeInput = {
  data: PayrollEmployeeCompensationItemUpdateWithoutEmployeeInput;
  where: PayrollEmployeeCompensationItemWhereUniqueInput;
};

export type PayrollEmployeeCompensationItemUpdateWithoutCompensationItemInput = {
  annualCostCap?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  calculationType?: Maybe<EnumPayrollCompensationItemCalculationTypeFieldUpdateOperationsInput>;
  costPerPayPeriod?: Maybe<DecimalFieldUpdateOperationsInput>;
  createdFromDefault?: Maybe<PayrollDefaultCompensationItemUpdateOneWithoutOriginatedEmployeeItemValuesInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  employee?: Maybe<PayrollEmployeeUpdateOneRequiredWithoutCompensationItemsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PayrollEmployeeCompensationItemUpdateWithoutCreatedFromDefaultInput = {
  annualCostCap?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  calculationType?: Maybe<EnumPayrollCompensationItemCalculationTypeFieldUpdateOperationsInput>;
  compensationItem?: Maybe<PayrollCompensationItemUpdateOneRequiredWithoutEmployeeCompensationItemsInput>;
  costPerPayPeriod?: Maybe<DecimalFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  employee?: Maybe<PayrollEmployeeUpdateOneRequiredWithoutCompensationItemsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PayrollEmployeeCompensationItemUpdateWithoutEmployeeInput = {
  annualCostCap?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  calculationType?: Maybe<EnumPayrollCompensationItemCalculationTypeFieldUpdateOperationsInput>;
  compensationItem?: Maybe<PayrollCompensationItemUpdateOneRequiredWithoutEmployeeCompensationItemsInput>;
  costPerPayPeriod?: Maybe<DecimalFieldUpdateOperationsInput>;
  createdFromDefault?: Maybe<PayrollDefaultCompensationItemUpdateOneWithoutOriginatedEmployeeItemValuesInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PayrollEmployeeCompensationItemUpsertWithWhereUniqueWithoutCompensationItemInput = {
  create: PayrollEmployeeCompensationItemCreateWithoutCompensationItemInput;
  update: PayrollEmployeeCompensationItemUpdateWithoutCompensationItemInput;
  where: PayrollEmployeeCompensationItemWhereUniqueInput;
};

export type PayrollEmployeeCompensationItemUpsertWithWhereUniqueWithoutCreatedFromDefaultInput = {
  create: PayrollEmployeeCompensationItemCreateWithoutCreatedFromDefaultInput;
  update: PayrollEmployeeCompensationItemUpdateWithoutCreatedFromDefaultInput;
  where: PayrollEmployeeCompensationItemWhereUniqueInput;
};

export type PayrollEmployeeCompensationItemUpsertWithWhereUniqueWithoutEmployeeInput = {
  create: PayrollEmployeeCompensationItemCreateWithoutEmployeeInput;
  update: PayrollEmployeeCompensationItemUpdateWithoutEmployeeInput;
  where: PayrollEmployeeCompensationItemWhereUniqueInput;
};

export type PayrollEmployeeCompensationItemWhereInput = {
  AND?: Maybe<Array<PayrollEmployeeCompensationItemWhereInput>>;
  NOT?: Maybe<Array<PayrollEmployeeCompensationItemWhereInput>>;
  OR?: Maybe<Array<PayrollEmployeeCompensationItemWhereInput>>;
  annualCostCap?: Maybe<DecimalNullableFilter>;
  calculationType?: Maybe<EnumPayrollCompensationItemCalculationTypeFilter>;
  compensationItem?: Maybe<PayrollCompensationItemRelationFilter>;
  compensationItemId?: Maybe<StringFilter>;
  costPerPayPeriod?: Maybe<DecimalFilter>;
  createdFromDefault?: Maybe<PayrollDefaultCompensationItemRelationFilter>;
  createdFromDefaultId?: Maybe<StringNullableFilter>;
  effectiveEndDate?: Maybe<DateTimeNullableFilter>;
  effectiveStartDate?: Maybe<DateTimeFilter>;
  employee?: Maybe<PayrollEmployeeRelationFilter>;
  employeeId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
};

export type PayrollEmployeeCompensationItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PayrollEmployeeCompensationOvertimeCreateManyCreatedFromDefaultInput = {
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  employeeId: Scalars['String'];
  expectedHoursPerWeek: Scalars['Decimal'];
  hourlyCompensation: Scalars['Decimal'];
  id?: Maybe<Scalars['String']>;
};

export type PayrollEmployeeCompensationOvertimeCreateManyCreatedFromDefaultInputEnvelope = {
  data: Array<PayrollEmployeeCompensationOvertimeCreateManyCreatedFromDefaultInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollEmployeeCompensationOvertimeCreateManyEmployeeInput = {
  createdFromDefaultId?: Maybe<Scalars['String']>;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  expectedHoursPerWeek: Scalars['Decimal'];
  hourlyCompensation: Scalars['Decimal'];
  id?: Maybe<Scalars['String']>;
};

export type PayrollEmployeeCompensationOvertimeCreateManyEmployeeInputEnvelope = {
  data: Array<PayrollEmployeeCompensationOvertimeCreateManyEmployeeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollEmployeeCompensationOvertimeCreateNestedManyWithoutCreatedFromDefaultInput = {
  connect?: Maybe<Array<PayrollEmployeeCompensationOvertimeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeeCompensationOvertimeCreateOrConnectWithoutCreatedFromDefaultInput>>;
  create?: Maybe<Array<PayrollEmployeeCompensationOvertimeCreateWithoutCreatedFromDefaultInput>>;
  createMany?: Maybe<PayrollEmployeeCompensationOvertimeCreateManyCreatedFromDefaultInputEnvelope>;
};

export type PayrollEmployeeCompensationOvertimeCreateNestedManyWithoutEmployeeInput = {
  connect?: Maybe<Array<PayrollEmployeeCompensationOvertimeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeeCompensationOvertimeCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<PayrollEmployeeCompensationOvertimeCreateWithoutEmployeeInput>>;
  createMany?: Maybe<PayrollEmployeeCompensationOvertimeCreateManyEmployeeInputEnvelope>;
};

export type PayrollEmployeeCompensationOvertimeCreateOrConnectWithoutCreatedFromDefaultInput = {
  create: PayrollEmployeeCompensationOvertimeCreateWithoutCreatedFromDefaultInput;
  where: PayrollEmployeeCompensationOvertimeWhereUniqueInput;
};

export type PayrollEmployeeCompensationOvertimeCreateOrConnectWithoutEmployeeInput = {
  create: PayrollEmployeeCompensationOvertimeCreateWithoutEmployeeInput;
  where: PayrollEmployeeCompensationOvertimeWhereUniqueInput;
};

export type PayrollEmployeeCompensationOvertimeCreateWithoutCreatedFromDefaultInput = {
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  employee: PayrollEmployeeCreateNestedOneWithoutCompensationOvertimeValuesInput;
  expectedHoursPerWeek: Scalars['Decimal'];
  hourlyCompensation: Scalars['Decimal'];
  id?: Maybe<Scalars['String']>;
};

export type PayrollEmployeeCompensationOvertimeCreateWithoutEmployeeInput = {
  createdFromDefault?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedOneWithoutOriginatedEmployeeOvertimeValuesInput>;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate: Scalars['DateTime'];
  expectedHoursPerWeek: Scalars['Decimal'];
  hourlyCompensation: Scalars['Decimal'];
  id?: Maybe<Scalars['String']>;
};

export type PayrollEmployeeCompensationOvertimeListRelationFilter = {
  every?: Maybe<PayrollEmployeeCompensationOvertimeWhereInput>;
  none?: Maybe<PayrollEmployeeCompensationOvertimeWhereInput>;
  some?: Maybe<PayrollEmployeeCompensationOvertimeWhereInput>;
};

export type PayrollEmployeeCompensationOvertimeScalarWhereInput = {
  AND?: Maybe<Array<PayrollEmployeeCompensationOvertimeScalarWhereInput>>;
  NOT?: Maybe<Array<PayrollEmployeeCompensationOvertimeScalarWhereInput>>;
  OR?: Maybe<Array<PayrollEmployeeCompensationOvertimeScalarWhereInput>>;
  createdFromDefaultId?: Maybe<StringNullableFilter>;
  effectiveEndDate?: Maybe<DateTimeNullableFilter>;
  effectiveStartDate?: Maybe<DateTimeFilter>;
  employeeId?: Maybe<StringFilter>;
  expectedHoursPerWeek?: Maybe<DecimalFilter>;
  hourlyCompensation?: Maybe<DecimalFilter>;
  id?: Maybe<StringFilter>;
};

export type PayrollEmployeeCompensationOvertimeUpdateManyMutationInput = {
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expectedHoursPerWeek?: Maybe<DecimalFieldUpdateOperationsInput>;
  hourlyCompensation?: Maybe<DecimalFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PayrollEmployeeCompensationOvertimeUpdateManyWithWhereWithoutCreatedFromDefaultInput = {
  data: PayrollEmployeeCompensationOvertimeUpdateManyMutationInput;
  where: PayrollEmployeeCompensationOvertimeScalarWhereInput;
};

export type PayrollEmployeeCompensationOvertimeUpdateManyWithWhereWithoutEmployeeInput = {
  data: PayrollEmployeeCompensationOvertimeUpdateManyMutationInput;
  where: PayrollEmployeeCompensationOvertimeScalarWhereInput;
};

export type PayrollEmployeeCompensationOvertimeUpdateManyWithoutCreatedFromDefaultInput = {
  connect?: Maybe<Array<PayrollEmployeeCompensationOvertimeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeeCompensationOvertimeCreateOrConnectWithoutCreatedFromDefaultInput>>;
  create?: Maybe<Array<PayrollEmployeeCompensationOvertimeCreateWithoutCreatedFromDefaultInput>>;
  createMany?: Maybe<PayrollEmployeeCompensationOvertimeCreateManyCreatedFromDefaultInputEnvelope>;
  delete?: Maybe<Array<PayrollEmployeeCompensationOvertimeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollEmployeeCompensationOvertimeScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollEmployeeCompensationOvertimeWhereUniqueInput>>;
  set?: Maybe<Array<PayrollEmployeeCompensationOvertimeWhereUniqueInput>>;
  update?: Maybe<Array<PayrollEmployeeCompensationOvertimeUpdateWithWhereUniqueWithoutCreatedFromDefaultInput>>;
  updateMany?: Maybe<Array<PayrollEmployeeCompensationOvertimeUpdateManyWithWhereWithoutCreatedFromDefaultInput>>;
  upsert?: Maybe<Array<PayrollEmployeeCompensationOvertimeUpsertWithWhereUniqueWithoutCreatedFromDefaultInput>>;
};

export type PayrollEmployeeCompensationOvertimeUpdateManyWithoutEmployeeInput = {
  connect?: Maybe<Array<PayrollEmployeeCompensationOvertimeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeeCompensationOvertimeCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<PayrollEmployeeCompensationOvertimeCreateWithoutEmployeeInput>>;
  createMany?: Maybe<PayrollEmployeeCompensationOvertimeCreateManyEmployeeInputEnvelope>;
  delete?: Maybe<Array<PayrollEmployeeCompensationOvertimeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollEmployeeCompensationOvertimeScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollEmployeeCompensationOvertimeWhereUniqueInput>>;
  set?: Maybe<Array<PayrollEmployeeCompensationOvertimeWhereUniqueInput>>;
  update?: Maybe<Array<PayrollEmployeeCompensationOvertimeUpdateWithWhereUniqueWithoutEmployeeInput>>;
  updateMany?: Maybe<Array<PayrollEmployeeCompensationOvertimeUpdateManyWithWhereWithoutEmployeeInput>>;
  upsert?: Maybe<Array<PayrollEmployeeCompensationOvertimeUpsertWithWhereUniqueWithoutEmployeeInput>>;
};

export type PayrollEmployeeCompensationOvertimeUpdateWithWhereUniqueWithoutCreatedFromDefaultInput = {
  data: PayrollEmployeeCompensationOvertimeUpdateWithoutCreatedFromDefaultInput;
  where: PayrollEmployeeCompensationOvertimeWhereUniqueInput;
};

export type PayrollEmployeeCompensationOvertimeUpdateWithWhereUniqueWithoutEmployeeInput = {
  data: PayrollEmployeeCompensationOvertimeUpdateWithoutEmployeeInput;
  where: PayrollEmployeeCompensationOvertimeWhereUniqueInput;
};

export type PayrollEmployeeCompensationOvertimeUpdateWithoutCreatedFromDefaultInput = {
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  employee?: Maybe<PayrollEmployeeUpdateOneRequiredWithoutCompensationOvertimeValuesInput>;
  expectedHoursPerWeek?: Maybe<DecimalFieldUpdateOperationsInput>;
  hourlyCompensation?: Maybe<DecimalFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PayrollEmployeeCompensationOvertimeUpdateWithoutEmployeeInput = {
  createdFromDefault?: Maybe<PayrollDefaultCompensationOvertimeUpdateOneWithoutOriginatedEmployeeOvertimeValuesInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expectedHoursPerWeek?: Maybe<DecimalFieldUpdateOperationsInput>;
  hourlyCompensation?: Maybe<DecimalFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PayrollEmployeeCompensationOvertimeUpsertWithWhereUniqueWithoutCreatedFromDefaultInput = {
  create: PayrollEmployeeCompensationOvertimeCreateWithoutCreatedFromDefaultInput;
  update: PayrollEmployeeCompensationOvertimeUpdateWithoutCreatedFromDefaultInput;
  where: PayrollEmployeeCompensationOvertimeWhereUniqueInput;
};

export type PayrollEmployeeCompensationOvertimeUpsertWithWhereUniqueWithoutEmployeeInput = {
  create: PayrollEmployeeCompensationOvertimeCreateWithoutEmployeeInput;
  update: PayrollEmployeeCompensationOvertimeUpdateWithoutEmployeeInput;
  where: PayrollEmployeeCompensationOvertimeWhereUniqueInput;
};

export type PayrollEmployeeCompensationOvertimeWhereInput = {
  AND?: Maybe<Array<PayrollEmployeeCompensationOvertimeWhereInput>>;
  NOT?: Maybe<Array<PayrollEmployeeCompensationOvertimeWhereInput>>;
  OR?: Maybe<Array<PayrollEmployeeCompensationOvertimeWhereInput>>;
  createdFromDefault?: Maybe<PayrollDefaultCompensationOvertimeRelationFilter>;
  createdFromDefaultId?: Maybe<StringNullableFilter>;
  effectiveEndDate?: Maybe<DateTimeNullableFilter>;
  effectiveStartDate?: Maybe<DateTimeFilter>;
  employee?: Maybe<PayrollEmployeeRelationFilter>;
  employeeId?: Maybe<StringFilter>;
  expectedHoursPerWeek?: Maybe<DecimalFilter>;
  hourlyCompensation?: Maybe<DecimalFilter>;
  id?: Maybe<StringFilter>;
};

export type PayrollEmployeeCompensationOvertimeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PayrollEmployeeCompensationRaiseCreateManyCreatedFromDefaultInput = {
  amount: Scalars['Decimal'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  employeeId: Scalars['String'];
  frequency: PayrollRaiseFrequency;
  id?: Maybe<Scalars['String']>;
  onetimeRaiseDate?: Maybe<Scalars['DateTime']>;
  raiseDay?: Maybe<Scalars['Int']>;
  raiseMonth?: Maybe<Scalars['Int']>;
  raiseType: PayrollRaiseType;
};

export type PayrollEmployeeCompensationRaiseCreateManyCreatedFromDefaultInputEnvelope = {
  data: Array<PayrollEmployeeCompensationRaiseCreateManyCreatedFromDefaultInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollEmployeeCompensationRaiseCreateManyEmployeeInput = {
  amount: Scalars['Decimal'];
  createdFromDefaultId?: Maybe<Scalars['String']>;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollRaiseFrequency;
  id?: Maybe<Scalars['String']>;
  onetimeRaiseDate?: Maybe<Scalars['DateTime']>;
  raiseDay?: Maybe<Scalars['Int']>;
  raiseMonth?: Maybe<Scalars['Int']>;
  raiseType: PayrollRaiseType;
};

export type PayrollEmployeeCompensationRaiseCreateManyEmployeeInputEnvelope = {
  data: Array<PayrollEmployeeCompensationRaiseCreateManyEmployeeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollEmployeeCompensationRaiseCreateNestedManyWithoutCreatedFromDefaultInput = {
  connect?: Maybe<Array<PayrollEmployeeCompensationRaiseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeeCompensationRaiseCreateOrConnectWithoutCreatedFromDefaultInput>>;
  create?: Maybe<Array<PayrollEmployeeCompensationRaiseCreateWithoutCreatedFromDefaultInput>>;
  createMany?: Maybe<PayrollEmployeeCompensationRaiseCreateManyCreatedFromDefaultInputEnvelope>;
};

export type PayrollEmployeeCompensationRaiseCreateNestedManyWithoutEmployeeInput = {
  connect?: Maybe<Array<PayrollEmployeeCompensationRaiseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeeCompensationRaiseCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<PayrollEmployeeCompensationRaiseCreateWithoutEmployeeInput>>;
  createMany?: Maybe<PayrollEmployeeCompensationRaiseCreateManyEmployeeInputEnvelope>;
};

export type PayrollEmployeeCompensationRaiseCreateOrConnectWithoutCreatedFromDefaultInput = {
  create: PayrollEmployeeCompensationRaiseCreateWithoutCreatedFromDefaultInput;
  where: PayrollEmployeeCompensationRaiseWhereUniqueInput;
};

export type PayrollEmployeeCompensationRaiseCreateOrConnectWithoutEmployeeInput = {
  create: PayrollEmployeeCompensationRaiseCreateWithoutEmployeeInput;
  where: PayrollEmployeeCompensationRaiseWhereUniqueInput;
};

export type PayrollEmployeeCompensationRaiseCreateWithoutCreatedFromDefaultInput = {
  amount: Scalars['Decimal'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  employee: PayrollEmployeeCreateNestedOneWithoutCompensationRaisesInput;
  frequency: PayrollRaiseFrequency;
  id?: Maybe<Scalars['String']>;
  onetimeRaiseDate?: Maybe<Scalars['DateTime']>;
  raiseDay?: Maybe<Scalars['Int']>;
  raiseMonth?: Maybe<Scalars['Int']>;
  raiseType: PayrollRaiseType;
};

export type PayrollEmployeeCompensationRaiseCreateWithoutEmployeeInput = {
  amount: Scalars['Decimal'];
  createdFromDefault?: Maybe<PayrollDefaultCompensationRaiseCreateNestedOneWithoutOriginatedEmployeeRaiseValuesInput>;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollRaiseFrequency;
  id?: Maybe<Scalars['String']>;
  onetimeRaiseDate?: Maybe<Scalars['DateTime']>;
  raiseDay?: Maybe<Scalars['Int']>;
  raiseMonth?: Maybe<Scalars['Int']>;
  raiseType: PayrollRaiseType;
};

export type PayrollEmployeeCompensationRaiseListRelationFilter = {
  every?: Maybe<PayrollEmployeeCompensationRaiseWhereInput>;
  none?: Maybe<PayrollEmployeeCompensationRaiseWhereInput>;
  some?: Maybe<PayrollEmployeeCompensationRaiseWhereInput>;
};

export type PayrollEmployeeCompensationRaiseScalarWhereInput = {
  AND?: Maybe<Array<PayrollEmployeeCompensationRaiseScalarWhereInput>>;
  NOT?: Maybe<Array<PayrollEmployeeCompensationRaiseScalarWhereInput>>;
  OR?: Maybe<Array<PayrollEmployeeCompensationRaiseScalarWhereInput>>;
  amount?: Maybe<DecimalFilter>;
  createdFromDefaultId?: Maybe<StringNullableFilter>;
  effectiveEndDate?: Maybe<DateTimeNullableFilter>;
  effectiveStartDate?: Maybe<DateTimeNullableFilter>;
  employeeId?: Maybe<StringFilter>;
  frequency?: Maybe<EnumPayrollRaiseFrequencyFilter>;
  id?: Maybe<StringFilter>;
  onetimeRaiseDate?: Maybe<DateTimeNullableFilter>;
  raiseDay?: Maybe<IntNullableFilter>;
  raiseMonth?: Maybe<IntNullableFilter>;
  raiseType?: Maybe<EnumPayrollRaiseTypeFilter>;
};

export type PayrollEmployeeCompensationRaiseUpdateManyMutationInput = {
  amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  frequency?: Maybe<EnumPayrollRaiseFrequencyFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  onetimeRaiseDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  raiseDay?: Maybe<NullableIntFieldUpdateOperationsInput>;
  raiseMonth?: Maybe<NullableIntFieldUpdateOperationsInput>;
  raiseType?: Maybe<EnumPayrollRaiseTypeFieldUpdateOperationsInput>;
};

export type PayrollEmployeeCompensationRaiseUpdateManyWithWhereWithoutCreatedFromDefaultInput = {
  data: PayrollEmployeeCompensationRaiseUpdateManyMutationInput;
  where: PayrollEmployeeCompensationRaiseScalarWhereInput;
};

export type PayrollEmployeeCompensationRaiseUpdateManyWithWhereWithoutEmployeeInput = {
  data: PayrollEmployeeCompensationRaiseUpdateManyMutationInput;
  where: PayrollEmployeeCompensationRaiseScalarWhereInput;
};

export type PayrollEmployeeCompensationRaiseUpdateManyWithoutCreatedFromDefaultInput = {
  connect?: Maybe<Array<PayrollEmployeeCompensationRaiseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeeCompensationRaiseCreateOrConnectWithoutCreatedFromDefaultInput>>;
  create?: Maybe<Array<PayrollEmployeeCompensationRaiseCreateWithoutCreatedFromDefaultInput>>;
  createMany?: Maybe<PayrollEmployeeCompensationRaiseCreateManyCreatedFromDefaultInputEnvelope>;
  delete?: Maybe<Array<PayrollEmployeeCompensationRaiseWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollEmployeeCompensationRaiseScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollEmployeeCompensationRaiseWhereUniqueInput>>;
  set?: Maybe<Array<PayrollEmployeeCompensationRaiseWhereUniqueInput>>;
  update?: Maybe<Array<PayrollEmployeeCompensationRaiseUpdateWithWhereUniqueWithoutCreatedFromDefaultInput>>;
  updateMany?: Maybe<Array<PayrollEmployeeCompensationRaiseUpdateManyWithWhereWithoutCreatedFromDefaultInput>>;
  upsert?: Maybe<Array<PayrollEmployeeCompensationRaiseUpsertWithWhereUniqueWithoutCreatedFromDefaultInput>>;
};

export type PayrollEmployeeCompensationRaiseUpdateManyWithoutEmployeeInput = {
  connect?: Maybe<Array<PayrollEmployeeCompensationRaiseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeeCompensationRaiseCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<PayrollEmployeeCompensationRaiseCreateWithoutEmployeeInput>>;
  createMany?: Maybe<PayrollEmployeeCompensationRaiseCreateManyEmployeeInputEnvelope>;
  delete?: Maybe<Array<PayrollEmployeeCompensationRaiseWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollEmployeeCompensationRaiseScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollEmployeeCompensationRaiseWhereUniqueInput>>;
  set?: Maybe<Array<PayrollEmployeeCompensationRaiseWhereUniqueInput>>;
  update?: Maybe<Array<PayrollEmployeeCompensationRaiseUpdateWithWhereUniqueWithoutEmployeeInput>>;
  updateMany?: Maybe<Array<PayrollEmployeeCompensationRaiseUpdateManyWithWhereWithoutEmployeeInput>>;
  upsert?: Maybe<Array<PayrollEmployeeCompensationRaiseUpsertWithWhereUniqueWithoutEmployeeInput>>;
};

export type PayrollEmployeeCompensationRaiseUpdateWithWhereUniqueWithoutCreatedFromDefaultInput = {
  data: PayrollEmployeeCompensationRaiseUpdateWithoutCreatedFromDefaultInput;
  where: PayrollEmployeeCompensationRaiseWhereUniqueInput;
};

export type PayrollEmployeeCompensationRaiseUpdateWithWhereUniqueWithoutEmployeeInput = {
  data: PayrollEmployeeCompensationRaiseUpdateWithoutEmployeeInput;
  where: PayrollEmployeeCompensationRaiseWhereUniqueInput;
};

export type PayrollEmployeeCompensationRaiseUpdateWithoutCreatedFromDefaultInput = {
  amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  employee?: Maybe<PayrollEmployeeUpdateOneRequiredWithoutCompensationRaisesInput>;
  frequency?: Maybe<EnumPayrollRaiseFrequencyFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  onetimeRaiseDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  raiseDay?: Maybe<NullableIntFieldUpdateOperationsInput>;
  raiseMonth?: Maybe<NullableIntFieldUpdateOperationsInput>;
  raiseType?: Maybe<EnumPayrollRaiseTypeFieldUpdateOperationsInput>;
};

export type PayrollEmployeeCompensationRaiseUpdateWithoutEmployeeInput = {
  amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  createdFromDefault?: Maybe<PayrollDefaultCompensationRaiseUpdateOneWithoutOriginatedEmployeeRaiseValuesInput>;
  effectiveEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  effectiveStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  frequency?: Maybe<EnumPayrollRaiseFrequencyFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  onetimeRaiseDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  raiseDay?: Maybe<NullableIntFieldUpdateOperationsInput>;
  raiseMonth?: Maybe<NullableIntFieldUpdateOperationsInput>;
  raiseType?: Maybe<EnumPayrollRaiseTypeFieldUpdateOperationsInput>;
};

export type PayrollEmployeeCompensationRaiseUpsertWithWhereUniqueWithoutCreatedFromDefaultInput = {
  create: PayrollEmployeeCompensationRaiseCreateWithoutCreatedFromDefaultInput;
  update: PayrollEmployeeCompensationRaiseUpdateWithoutCreatedFromDefaultInput;
  where: PayrollEmployeeCompensationRaiseWhereUniqueInput;
};

export type PayrollEmployeeCompensationRaiseUpsertWithWhereUniqueWithoutEmployeeInput = {
  create: PayrollEmployeeCompensationRaiseCreateWithoutEmployeeInput;
  update: PayrollEmployeeCompensationRaiseUpdateWithoutEmployeeInput;
  where: PayrollEmployeeCompensationRaiseWhereUniqueInput;
};

export type PayrollEmployeeCompensationRaiseWhereInput = {
  AND?: Maybe<Array<PayrollEmployeeCompensationRaiseWhereInput>>;
  NOT?: Maybe<Array<PayrollEmployeeCompensationRaiseWhereInput>>;
  OR?: Maybe<Array<PayrollEmployeeCompensationRaiseWhereInput>>;
  amount?: Maybe<DecimalFilter>;
  createdFromDefault?: Maybe<PayrollDefaultCompensationRaiseRelationFilter>;
  createdFromDefaultId?: Maybe<StringNullableFilter>;
  effectiveEndDate?: Maybe<DateTimeNullableFilter>;
  effectiveStartDate?: Maybe<DateTimeNullableFilter>;
  employee?: Maybe<PayrollEmployeeRelationFilter>;
  employeeId?: Maybe<StringFilter>;
  frequency?: Maybe<EnumPayrollRaiseFrequencyFilter>;
  id?: Maybe<StringFilter>;
  onetimeRaiseDate?: Maybe<DateTimeNullableFilter>;
  raiseDay?: Maybe<IntNullableFilter>;
  raiseMonth?: Maybe<IntNullableFilter>;
  raiseType?: Maybe<EnumPayrollRaiseTypeFilter>;
};

export type PayrollEmployeeCompensationRaiseWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PayrollEmployeeCreateManyPositionInput = {
  actualEmployeeId?: Maybe<Scalars['String']>;
  budgetYear?: Maybe<Scalars['Int']>;
  classification: PayrollEmployeeClassification;
  externalSystemEmployeeId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  modeledType: PayrollModeledEmployeeType;
  startDate: Scalars['DateTime'];
  terminationDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollEmployeeCreateManyPositionInputEnvelope = {
  data: Array<PayrollEmployeeCreateManyPositionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollEmployeeCreateNestedManyWithoutPositionInput = {
  connect?: Maybe<Array<PayrollEmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeeCreateOrConnectWithoutPositionInput>>;
  create?: Maybe<Array<PayrollEmployeeCreateWithoutPositionInput>>;
  createMany?: Maybe<PayrollEmployeeCreateManyPositionInputEnvelope>;
};

export type PayrollEmployeeCreateNestedOneWithoutActualCompensationItemAmountsInput = {
  connect?: Maybe<PayrollEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollEmployeeCreateOrConnectWithoutActualCompensationItemAmountsInput>;
  create?: Maybe<PayrollEmployeeCreateWithoutActualCompensationItemAmountsInput>;
};

export type PayrollEmployeeCreateNestedOneWithoutActualEmployeeInput = {
  connect?: Maybe<PayrollEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollEmployeeCreateOrConnectWithoutActualEmployeeInput>;
  create?: Maybe<PayrollEmployeeCreateWithoutActualEmployeeInput>;
};

export type PayrollEmployeeCreateNestedOneWithoutCompensationBonusesInput = {
  connect?: Maybe<PayrollEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollEmployeeCreateOrConnectWithoutCompensationBonusesInput>;
  create?: Maybe<PayrollEmployeeCreateWithoutCompensationBonusesInput>;
};

export type PayrollEmployeeCreateNestedOneWithoutCompensationItemsInput = {
  connect?: Maybe<PayrollEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollEmployeeCreateOrConnectWithoutCompensationItemsInput>;
  create?: Maybe<PayrollEmployeeCreateWithoutCompensationItemsInput>;
};

export type PayrollEmployeeCreateNestedOneWithoutCompensationOvertimeValuesInput = {
  connect?: Maybe<PayrollEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollEmployeeCreateOrConnectWithoutCompensationOvertimeValuesInput>;
  create?: Maybe<PayrollEmployeeCreateWithoutCompensationOvertimeValuesInput>;
};

export type PayrollEmployeeCreateNestedOneWithoutCompensationRaisesInput = {
  connect?: Maybe<PayrollEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollEmployeeCreateOrConnectWithoutCompensationRaisesInput>;
  create?: Maybe<PayrollEmployeeCreateWithoutCompensationRaisesInput>;
};

export type PayrollEmployeeCreateNestedOneWithoutExpectedEmployeeInput = {
  connect?: Maybe<PayrollEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollEmployeeCreateOrConnectWithoutExpectedEmployeeInput>;
  create?: Maybe<PayrollEmployeeCreateWithoutExpectedEmployeeInput>;
};

export type PayrollEmployeeCreateNestedOneWithoutPropertyAttributionsInput = {
  connect?: Maybe<PayrollEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollEmployeeCreateOrConnectWithoutPropertyAttributionsInput>;
  create?: Maybe<PayrollEmployeeCreateWithoutPropertyAttributionsInput>;
};

export type PayrollEmployeeCreateOrConnectWithoutActualCompensationItemAmountsInput = {
  create: PayrollEmployeeCreateWithoutActualCompensationItemAmountsInput;
  where: PayrollEmployeeWhereUniqueInput;
};

export type PayrollEmployeeCreateOrConnectWithoutActualEmployeeInput = {
  create: PayrollEmployeeCreateWithoutActualEmployeeInput;
  where: PayrollEmployeeWhereUniqueInput;
};

export type PayrollEmployeeCreateOrConnectWithoutCompensationBonusesInput = {
  create: PayrollEmployeeCreateWithoutCompensationBonusesInput;
  where: PayrollEmployeeWhereUniqueInput;
};

export type PayrollEmployeeCreateOrConnectWithoutCompensationItemsInput = {
  create: PayrollEmployeeCreateWithoutCompensationItemsInput;
  where: PayrollEmployeeWhereUniqueInput;
};

export type PayrollEmployeeCreateOrConnectWithoutCompensationOvertimeValuesInput = {
  create: PayrollEmployeeCreateWithoutCompensationOvertimeValuesInput;
  where: PayrollEmployeeWhereUniqueInput;
};

export type PayrollEmployeeCreateOrConnectWithoutCompensationRaisesInput = {
  create: PayrollEmployeeCreateWithoutCompensationRaisesInput;
  where: PayrollEmployeeWhereUniqueInput;
};

export type PayrollEmployeeCreateOrConnectWithoutExpectedEmployeeInput = {
  create: PayrollEmployeeCreateWithoutExpectedEmployeeInput;
  where: PayrollEmployeeWhereUniqueInput;
};

export type PayrollEmployeeCreateOrConnectWithoutPositionInput = {
  create: PayrollEmployeeCreateWithoutPositionInput;
  where: PayrollEmployeeWhereUniqueInput;
};

export type PayrollEmployeeCreateOrConnectWithoutPropertyAttributionsInput = {
  create: PayrollEmployeeCreateWithoutPropertyAttributionsInput;
  where: PayrollEmployeeWhereUniqueInput;
};

export type PayrollEmployeeCreateWithoutActualCompensationItemAmountsInput = {
  actualBaseAmounts?: Maybe<PayrollActualEmployeeBaseAmountsCreateNestedManyWithoutEmployeeInput>;
  actualBonusAmounts?: Maybe<PayrollActualEmployeeBonusAmountsCreateNestedManyWithoutEmployeeInput>;
  actualEmployee?: Maybe<PayrollEmployeeCreateNestedOneWithoutExpectedEmployeeInput>;
  actualOvertimeAmounts?: Maybe<PayrollActualEmployeeOvertimeAmountsCreateNestedManyWithoutEmployeeInput>;
  budgetYear?: Maybe<Scalars['Int']>;
  classification: PayrollEmployeeClassification;
  compensationBase?: Maybe<PayrollEmployeeCompensationBaseCreateNestedOneWithoutEmployeeInput>;
  compensationBonuses?: Maybe<PayrollEmployeeCompensationBonusCreateNestedManyWithoutEmployeeInput>;
  compensationItems?: Maybe<PayrollEmployeeCompensationItemCreateNestedManyWithoutEmployeeInput>;
  compensationOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeCreateNestedManyWithoutEmployeeInput>;
  compensationRaises?: Maybe<PayrollEmployeeCompensationRaiseCreateNestedManyWithoutEmployeeInput>;
  expectedEmployee?: Maybe<PayrollEmployeeCreateNestedOneWithoutActualEmployeeInput>;
  externalSystemEmployeeId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  modeledType: PayrollModeledEmployeeType;
  position: PayrollPositionCreateNestedOneWithoutEmployeesInput;
  propertyAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutEmployeeInput>;
  startDate: Scalars['DateTime'];
  terminationDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollEmployeeCreateWithoutActualEmployeeInput = {
  actualBaseAmounts?: Maybe<PayrollActualEmployeeBaseAmountsCreateNestedManyWithoutEmployeeInput>;
  actualBonusAmounts?: Maybe<PayrollActualEmployeeBonusAmountsCreateNestedManyWithoutEmployeeInput>;
  actualCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsCreateNestedManyWithoutEmployeeInput>;
  actualOvertimeAmounts?: Maybe<PayrollActualEmployeeOvertimeAmountsCreateNestedManyWithoutEmployeeInput>;
  budgetYear?: Maybe<Scalars['Int']>;
  classification: PayrollEmployeeClassification;
  compensationBase?: Maybe<PayrollEmployeeCompensationBaseCreateNestedOneWithoutEmployeeInput>;
  compensationBonuses?: Maybe<PayrollEmployeeCompensationBonusCreateNestedManyWithoutEmployeeInput>;
  compensationItems?: Maybe<PayrollEmployeeCompensationItemCreateNestedManyWithoutEmployeeInput>;
  compensationOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeCreateNestedManyWithoutEmployeeInput>;
  compensationRaises?: Maybe<PayrollEmployeeCompensationRaiseCreateNestedManyWithoutEmployeeInput>;
  expectedEmployee?: Maybe<PayrollEmployeeCreateNestedOneWithoutActualEmployeeInput>;
  externalSystemEmployeeId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  modeledType: PayrollModeledEmployeeType;
  position: PayrollPositionCreateNestedOneWithoutEmployeesInput;
  propertyAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutEmployeeInput>;
  startDate: Scalars['DateTime'];
  terminationDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollEmployeeCreateWithoutCompensationBonusesInput = {
  actualBaseAmounts?: Maybe<PayrollActualEmployeeBaseAmountsCreateNestedManyWithoutEmployeeInput>;
  actualBonusAmounts?: Maybe<PayrollActualEmployeeBonusAmountsCreateNestedManyWithoutEmployeeInput>;
  actualCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsCreateNestedManyWithoutEmployeeInput>;
  actualEmployee?: Maybe<PayrollEmployeeCreateNestedOneWithoutExpectedEmployeeInput>;
  actualOvertimeAmounts?: Maybe<PayrollActualEmployeeOvertimeAmountsCreateNestedManyWithoutEmployeeInput>;
  budgetYear?: Maybe<Scalars['Int']>;
  classification: PayrollEmployeeClassification;
  compensationBase?: Maybe<PayrollEmployeeCompensationBaseCreateNestedOneWithoutEmployeeInput>;
  compensationItems?: Maybe<PayrollEmployeeCompensationItemCreateNestedManyWithoutEmployeeInput>;
  compensationOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeCreateNestedManyWithoutEmployeeInput>;
  compensationRaises?: Maybe<PayrollEmployeeCompensationRaiseCreateNestedManyWithoutEmployeeInput>;
  expectedEmployee?: Maybe<PayrollEmployeeCreateNestedOneWithoutActualEmployeeInput>;
  externalSystemEmployeeId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  modeledType: PayrollModeledEmployeeType;
  position: PayrollPositionCreateNestedOneWithoutEmployeesInput;
  propertyAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutEmployeeInput>;
  startDate: Scalars['DateTime'];
  terminationDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollEmployeeCreateWithoutCompensationItemsInput = {
  actualBaseAmounts?: Maybe<PayrollActualEmployeeBaseAmountsCreateNestedManyWithoutEmployeeInput>;
  actualBonusAmounts?: Maybe<PayrollActualEmployeeBonusAmountsCreateNestedManyWithoutEmployeeInput>;
  actualCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsCreateNestedManyWithoutEmployeeInput>;
  actualEmployee?: Maybe<PayrollEmployeeCreateNestedOneWithoutExpectedEmployeeInput>;
  actualOvertimeAmounts?: Maybe<PayrollActualEmployeeOvertimeAmountsCreateNestedManyWithoutEmployeeInput>;
  budgetYear?: Maybe<Scalars['Int']>;
  classification: PayrollEmployeeClassification;
  compensationBase?: Maybe<PayrollEmployeeCompensationBaseCreateNestedOneWithoutEmployeeInput>;
  compensationBonuses?: Maybe<PayrollEmployeeCompensationBonusCreateNestedManyWithoutEmployeeInput>;
  compensationOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeCreateNestedManyWithoutEmployeeInput>;
  compensationRaises?: Maybe<PayrollEmployeeCompensationRaiseCreateNestedManyWithoutEmployeeInput>;
  expectedEmployee?: Maybe<PayrollEmployeeCreateNestedOneWithoutActualEmployeeInput>;
  externalSystemEmployeeId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  modeledType: PayrollModeledEmployeeType;
  position: PayrollPositionCreateNestedOneWithoutEmployeesInput;
  propertyAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutEmployeeInput>;
  startDate: Scalars['DateTime'];
  terminationDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollEmployeeCreateWithoutCompensationOvertimeValuesInput = {
  actualBaseAmounts?: Maybe<PayrollActualEmployeeBaseAmountsCreateNestedManyWithoutEmployeeInput>;
  actualBonusAmounts?: Maybe<PayrollActualEmployeeBonusAmountsCreateNestedManyWithoutEmployeeInput>;
  actualCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsCreateNestedManyWithoutEmployeeInput>;
  actualEmployee?: Maybe<PayrollEmployeeCreateNestedOneWithoutExpectedEmployeeInput>;
  actualOvertimeAmounts?: Maybe<PayrollActualEmployeeOvertimeAmountsCreateNestedManyWithoutEmployeeInput>;
  budgetYear?: Maybe<Scalars['Int']>;
  classification: PayrollEmployeeClassification;
  compensationBase?: Maybe<PayrollEmployeeCompensationBaseCreateNestedOneWithoutEmployeeInput>;
  compensationBonuses?: Maybe<PayrollEmployeeCompensationBonusCreateNestedManyWithoutEmployeeInput>;
  compensationItems?: Maybe<PayrollEmployeeCompensationItemCreateNestedManyWithoutEmployeeInput>;
  compensationRaises?: Maybe<PayrollEmployeeCompensationRaiseCreateNestedManyWithoutEmployeeInput>;
  expectedEmployee?: Maybe<PayrollEmployeeCreateNestedOneWithoutActualEmployeeInput>;
  externalSystemEmployeeId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  modeledType: PayrollModeledEmployeeType;
  position: PayrollPositionCreateNestedOneWithoutEmployeesInput;
  propertyAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutEmployeeInput>;
  startDate: Scalars['DateTime'];
  terminationDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollEmployeeCreateWithoutCompensationRaisesInput = {
  actualBaseAmounts?: Maybe<PayrollActualEmployeeBaseAmountsCreateNestedManyWithoutEmployeeInput>;
  actualBonusAmounts?: Maybe<PayrollActualEmployeeBonusAmountsCreateNestedManyWithoutEmployeeInput>;
  actualCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsCreateNestedManyWithoutEmployeeInput>;
  actualEmployee?: Maybe<PayrollEmployeeCreateNestedOneWithoutExpectedEmployeeInput>;
  actualOvertimeAmounts?: Maybe<PayrollActualEmployeeOvertimeAmountsCreateNestedManyWithoutEmployeeInput>;
  budgetYear?: Maybe<Scalars['Int']>;
  classification: PayrollEmployeeClassification;
  compensationBase?: Maybe<PayrollEmployeeCompensationBaseCreateNestedOneWithoutEmployeeInput>;
  compensationBonuses?: Maybe<PayrollEmployeeCompensationBonusCreateNestedManyWithoutEmployeeInput>;
  compensationItems?: Maybe<PayrollEmployeeCompensationItemCreateNestedManyWithoutEmployeeInput>;
  compensationOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeCreateNestedManyWithoutEmployeeInput>;
  expectedEmployee?: Maybe<PayrollEmployeeCreateNestedOneWithoutActualEmployeeInput>;
  externalSystemEmployeeId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  modeledType: PayrollModeledEmployeeType;
  position: PayrollPositionCreateNestedOneWithoutEmployeesInput;
  propertyAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutEmployeeInput>;
  startDate: Scalars['DateTime'];
  terminationDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollEmployeeCreateWithoutExpectedEmployeeInput = {
  actualBaseAmounts?: Maybe<PayrollActualEmployeeBaseAmountsCreateNestedManyWithoutEmployeeInput>;
  actualBonusAmounts?: Maybe<PayrollActualEmployeeBonusAmountsCreateNestedManyWithoutEmployeeInput>;
  actualCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsCreateNestedManyWithoutEmployeeInput>;
  actualEmployee?: Maybe<PayrollEmployeeCreateNestedOneWithoutExpectedEmployeeInput>;
  actualOvertimeAmounts?: Maybe<PayrollActualEmployeeOvertimeAmountsCreateNestedManyWithoutEmployeeInput>;
  budgetYear?: Maybe<Scalars['Int']>;
  classification: PayrollEmployeeClassification;
  compensationBase?: Maybe<PayrollEmployeeCompensationBaseCreateNestedOneWithoutEmployeeInput>;
  compensationBonuses?: Maybe<PayrollEmployeeCompensationBonusCreateNestedManyWithoutEmployeeInput>;
  compensationItems?: Maybe<PayrollEmployeeCompensationItemCreateNestedManyWithoutEmployeeInput>;
  compensationOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeCreateNestedManyWithoutEmployeeInput>;
  compensationRaises?: Maybe<PayrollEmployeeCompensationRaiseCreateNestedManyWithoutEmployeeInput>;
  externalSystemEmployeeId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  modeledType: PayrollModeledEmployeeType;
  position: PayrollPositionCreateNestedOneWithoutEmployeesInput;
  propertyAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutEmployeeInput>;
  startDate: Scalars['DateTime'];
  terminationDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollEmployeeCreateWithoutPositionInput = {
  actualBaseAmounts?: Maybe<PayrollActualEmployeeBaseAmountsCreateNestedManyWithoutEmployeeInput>;
  actualBonusAmounts?: Maybe<PayrollActualEmployeeBonusAmountsCreateNestedManyWithoutEmployeeInput>;
  actualCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsCreateNestedManyWithoutEmployeeInput>;
  actualEmployee?: Maybe<PayrollEmployeeCreateNestedOneWithoutExpectedEmployeeInput>;
  actualOvertimeAmounts?: Maybe<PayrollActualEmployeeOvertimeAmountsCreateNestedManyWithoutEmployeeInput>;
  budgetYear?: Maybe<Scalars['Int']>;
  classification: PayrollEmployeeClassification;
  compensationBase?: Maybe<PayrollEmployeeCompensationBaseCreateNestedOneWithoutEmployeeInput>;
  compensationBonuses?: Maybe<PayrollEmployeeCompensationBonusCreateNestedManyWithoutEmployeeInput>;
  compensationItems?: Maybe<PayrollEmployeeCompensationItemCreateNestedManyWithoutEmployeeInput>;
  compensationOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeCreateNestedManyWithoutEmployeeInput>;
  compensationRaises?: Maybe<PayrollEmployeeCompensationRaiseCreateNestedManyWithoutEmployeeInput>;
  expectedEmployee?: Maybe<PayrollEmployeeCreateNestedOneWithoutActualEmployeeInput>;
  externalSystemEmployeeId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  modeledType: PayrollModeledEmployeeType;
  propertyAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutEmployeeInput>;
  startDate: Scalars['DateTime'];
  terminationDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollEmployeeCreateWithoutPropertyAttributionsInput = {
  actualBaseAmounts?: Maybe<PayrollActualEmployeeBaseAmountsCreateNestedManyWithoutEmployeeInput>;
  actualBonusAmounts?: Maybe<PayrollActualEmployeeBonusAmountsCreateNestedManyWithoutEmployeeInput>;
  actualCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsCreateNestedManyWithoutEmployeeInput>;
  actualEmployee?: Maybe<PayrollEmployeeCreateNestedOneWithoutExpectedEmployeeInput>;
  actualOvertimeAmounts?: Maybe<PayrollActualEmployeeOvertimeAmountsCreateNestedManyWithoutEmployeeInput>;
  budgetYear?: Maybe<Scalars['Int']>;
  classification: PayrollEmployeeClassification;
  compensationBase?: Maybe<PayrollEmployeeCompensationBaseCreateNestedOneWithoutEmployeeInput>;
  compensationBonuses?: Maybe<PayrollEmployeeCompensationBonusCreateNestedManyWithoutEmployeeInput>;
  compensationItems?: Maybe<PayrollEmployeeCompensationItemCreateNestedManyWithoutEmployeeInput>;
  compensationOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeCreateNestedManyWithoutEmployeeInput>;
  compensationRaises?: Maybe<PayrollEmployeeCompensationRaiseCreateNestedManyWithoutEmployeeInput>;
  expectedEmployee?: Maybe<PayrollEmployeeCreateNestedOneWithoutActualEmployeeInput>;
  externalSystemEmployeeId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  modeledType: PayrollModeledEmployeeType;
  position: PayrollPositionCreateNestedOneWithoutEmployeesInput;
  startDate: Scalars['DateTime'];
  terminationDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollEmployeeListRelationFilter = {
  every?: Maybe<PayrollEmployeeWhereInput>;
  none?: Maybe<PayrollEmployeeWhereInput>;
  some?: Maybe<PayrollEmployeeWhereInput>;
};

export type PayrollEmployeeModel = {
  __typename?: 'PayrollEmployeeModel';
  baseComp?: Maybe<PayrollEmployeeBaseCompModel>;
  bonuses: Array<PayrollEmployeeBonusModel>;
  classification: PayrollEmployeeClassification;
  compItems: Array<PayrollEmployeeCompItemModel>;
  externalSystemEmployeeId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  overtime: Array<PayrollEmployeeOvertimeModel>;
  position: PayrollPositionModel;
  /** @deprecated This is replaced by the propertyAttributions array with the property and the percentage. */
  properties: Array<Property>;
  propertyAttributions: Array<PayrollEmployeePropertyAttributionModel>;
  raises: Array<PayrollEmployeeRaiseModel>;
  startDate: Scalars['DateTime'];
  terminationDate?: Maybe<Scalars['DateTime']>;
  type: PayrollModeledEmployeeType;
};

export type PayrollEmployeeOvertimeModel = {
  __typename?: 'PayrollEmployeeOvertimeModel';
  /** When set, indicates that this overtime will end on the given date. When unset, the overtime will continue forever. */
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  /** When the overtime will start. */
  effectiveStartDate: Scalars['DateTime'];
  /** The number of hours of overtime expected to be worked at the given compensation rate. */
  expectedHoursPerWeek: Scalars['Decimal'];
  /** Hourly compensation amount for overtime. */
  hourlyCompensation: Scalars['Decimal'];
  id: Scalars['ID'];
};

export type PayrollEmployeePropertyAttribution = {
  __typename?: 'PayrollEmployeePropertyAttribution';
  attributionPercentage?: Maybe<Scalars['Decimal']>;
  employeeId: Scalars['String'];
  propertyId: Scalars['String'];
  unused?: Maybe<Scalars['Int']>;
};

export type PayrollEmployeePropertyAttributionCreateManyEmployeeInput = {
  attributionPercentage?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  unused?: Maybe<Scalars['Int']>;
};

export type PayrollEmployeePropertyAttributionCreateManyEmployeeInputEnvelope = {
  data: Array<PayrollEmployeePropertyAttributionCreateManyEmployeeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollEmployeePropertyAttributionCreateManyPropertyInput = {
  attributionPercentage?: Maybe<Scalars['Decimal']>;
  employeeId: Scalars['String'];
  unused?: Maybe<Scalars['Int']>;
};

export type PayrollEmployeePropertyAttributionCreateManyPropertyInputEnvelope = {
  data: Array<PayrollEmployeePropertyAttributionCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollEmployeePropertyAttributionCreateNestedManyWithoutEmployeeInput = {
  connect?: Maybe<Array<PayrollEmployeePropertyAttributionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeePropertyAttributionCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<PayrollEmployeePropertyAttributionCreateWithoutEmployeeInput>>;
  createMany?: Maybe<PayrollEmployeePropertyAttributionCreateManyEmployeeInputEnvelope>;
};

export type PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<PayrollEmployeePropertyAttributionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeePropertyAttributionCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PayrollEmployeePropertyAttributionCreateWithoutPropertyInput>>;
  createMany?: Maybe<PayrollEmployeePropertyAttributionCreateManyPropertyInputEnvelope>;
};

export type PayrollEmployeePropertyAttributionCreateOrConnectWithoutEmployeeInput = {
  create: PayrollEmployeePropertyAttributionCreateWithoutEmployeeInput;
  where: PayrollEmployeePropertyAttributionWhereUniqueInput;
};

export type PayrollEmployeePropertyAttributionCreateOrConnectWithoutPropertyInput = {
  create: PayrollEmployeePropertyAttributionCreateWithoutPropertyInput;
  where: PayrollEmployeePropertyAttributionWhereUniqueInput;
};

export type PayrollEmployeePropertyAttributionCreateWithoutEmployeeInput = {
  attributionPercentage?: Maybe<Scalars['Decimal']>;
  property: PropertyCreateNestedOneWithoutPayrollEmployeeAttributionsInput;
  unused?: Maybe<Scalars['Int']>;
};

export type PayrollEmployeePropertyAttributionCreateWithoutPropertyInput = {
  attributionPercentage?: Maybe<Scalars['Decimal']>;
  employee: PayrollEmployeeCreateNestedOneWithoutPropertyAttributionsInput;
  unused?: Maybe<Scalars['Int']>;
};

export type PayrollEmployeePropertyAttributionEmployeeIdPropertyIdCompoundUniqueInput = {
  employeeId: Scalars['String'];
  propertyId: Scalars['String'];
};

export type PayrollEmployeePropertyAttributionInput = {
  attributionPercentage?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['ID'];
};

export type PayrollEmployeePropertyAttributionListRelationFilter = {
  every?: Maybe<PayrollEmployeePropertyAttributionWhereInput>;
  none?: Maybe<PayrollEmployeePropertyAttributionWhereInput>;
  some?: Maybe<PayrollEmployeePropertyAttributionWhereInput>;
};

export type PayrollEmployeePropertyAttributionModel = {
  __typename?: 'PayrollEmployeePropertyAttributionModel';
  attributionPercentage?: Maybe<Scalars['Decimal']>;
  property: Property;
};

export type PayrollEmployeePropertyAttributionOrderByInput = {
  attributionPercentage?: Maybe<SortOrder>;
  employeeId?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  unused?: Maybe<SortOrder>;
};

export enum PayrollEmployeePropertyAttributionScalarFieldEnum {
  AttributionPercentage = 'attributionPercentage',
  EmployeeId = 'employeeId',
  PropertyId = 'propertyId',
  Unused = 'unused'
}

export type PayrollEmployeePropertyAttributionScalarWhereInput = {
  AND?: Maybe<Array<PayrollEmployeePropertyAttributionScalarWhereInput>>;
  NOT?: Maybe<Array<PayrollEmployeePropertyAttributionScalarWhereInput>>;
  OR?: Maybe<Array<PayrollEmployeePropertyAttributionScalarWhereInput>>;
  attributionPercentage?: Maybe<DecimalNullableFilter>;
  employeeId?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  unused?: Maybe<IntNullableFilter>;
};

export type PayrollEmployeePropertyAttributionUpdateManyMutationInput = {
  attributionPercentage?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  unused?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type PayrollEmployeePropertyAttributionUpdateManyWithWhereWithoutEmployeeInput = {
  data: PayrollEmployeePropertyAttributionUpdateManyMutationInput;
  where: PayrollEmployeePropertyAttributionScalarWhereInput;
};

export type PayrollEmployeePropertyAttributionUpdateManyWithWhereWithoutPropertyInput = {
  data: PayrollEmployeePropertyAttributionUpdateManyMutationInput;
  where: PayrollEmployeePropertyAttributionScalarWhereInput;
};

export type PayrollEmployeePropertyAttributionUpdateManyWithoutEmployeeInput = {
  connect?: Maybe<Array<PayrollEmployeePropertyAttributionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeePropertyAttributionCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<PayrollEmployeePropertyAttributionCreateWithoutEmployeeInput>>;
  createMany?: Maybe<PayrollEmployeePropertyAttributionCreateManyEmployeeInputEnvelope>;
  delete?: Maybe<Array<PayrollEmployeePropertyAttributionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollEmployeePropertyAttributionScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollEmployeePropertyAttributionWhereUniqueInput>>;
  set?: Maybe<Array<PayrollEmployeePropertyAttributionWhereUniqueInput>>;
  update?: Maybe<Array<PayrollEmployeePropertyAttributionUpdateWithWhereUniqueWithoutEmployeeInput>>;
  updateMany?: Maybe<Array<PayrollEmployeePropertyAttributionUpdateManyWithWhereWithoutEmployeeInput>>;
  upsert?: Maybe<Array<PayrollEmployeePropertyAttributionUpsertWithWhereUniqueWithoutEmployeeInput>>;
};

export type PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<PayrollEmployeePropertyAttributionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeePropertyAttributionCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PayrollEmployeePropertyAttributionCreateWithoutPropertyInput>>;
  createMany?: Maybe<PayrollEmployeePropertyAttributionCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<PayrollEmployeePropertyAttributionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollEmployeePropertyAttributionScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollEmployeePropertyAttributionWhereUniqueInput>>;
  set?: Maybe<Array<PayrollEmployeePropertyAttributionWhereUniqueInput>>;
  update?: Maybe<Array<PayrollEmployeePropertyAttributionUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<PayrollEmployeePropertyAttributionUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<PayrollEmployeePropertyAttributionUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type PayrollEmployeePropertyAttributionUpdateWithWhereUniqueWithoutEmployeeInput = {
  data: PayrollEmployeePropertyAttributionUpdateWithoutEmployeeInput;
  where: PayrollEmployeePropertyAttributionWhereUniqueInput;
};

export type PayrollEmployeePropertyAttributionUpdateWithWhereUniqueWithoutPropertyInput = {
  data: PayrollEmployeePropertyAttributionUpdateWithoutPropertyInput;
  where: PayrollEmployeePropertyAttributionWhereUniqueInput;
};

export type PayrollEmployeePropertyAttributionUpdateWithoutEmployeeInput = {
  attributionPercentage?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutPayrollEmployeeAttributionsInput>;
  unused?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type PayrollEmployeePropertyAttributionUpdateWithoutPropertyInput = {
  attributionPercentage?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  employee?: Maybe<PayrollEmployeeUpdateOneRequiredWithoutPropertyAttributionsInput>;
  unused?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type PayrollEmployeePropertyAttributionUpsertWithWhereUniqueWithoutEmployeeInput = {
  create: PayrollEmployeePropertyAttributionCreateWithoutEmployeeInput;
  update: PayrollEmployeePropertyAttributionUpdateWithoutEmployeeInput;
  where: PayrollEmployeePropertyAttributionWhereUniqueInput;
};

export type PayrollEmployeePropertyAttributionUpsertWithWhereUniqueWithoutPropertyInput = {
  create: PayrollEmployeePropertyAttributionCreateWithoutPropertyInput;
  update: PayrollEmployeePropertyAttributionUpdateWithoutPropertyInput;
  where: PayrollEmployeePropertyAttributionWhereUniqueInput;
};

export type PayrollEmployeePropertyAttributionWhereInput = {
  AND?: Maybe<Array<PayrollEmployeePropertyAttributionWhereInput>>;
  NOT?: Maybe<Array<PayrollEmployeePropertyAttributionWhereInput>>;
  OR?: Maybe<Array<PayrollEmployeePropertyAttributionWhereInput>>;
  attributionPercentage?: Maybe<DecimalNullableFilter>;
  employee?: Maybe<PayrollEmployeeRelationFilter>;
  employeeId?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  unused?: Maybe<IntNullableFilter>;
};

export type PayrollEmployeePropertyAttributionWhereUniqueInput = {
  employeeId_propertyId?: Maybe<PayrollEmployeePropertyAttributionEmployeeIdPropertyIdCompoundUniqueInput>;
};

export type PayrollEmployeeRaiseModel = {
  __typename?: 'PayrollEmployeeRaiseModel';
  amount: Scalars['Decimal'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency: PayrollRaiseFrequency;
  id: Scalars['ID'];
  onetimeRaiseDate?: Maybe<Scalars['DateTime']>;
  raiseDay?: Maybe<Scalars['Int']>;
  raiseMonth?: Maybe<Scalars['Int']>;
  /** The type of raise either fixed or recurring annually. */
  raiseType: PayrollRaiseType;
};

export type PayrollEmployeeRelationFilter = {
  is?: Maybe<PayrollEmployeeWhereInput>;
  isNot?: Maybe<PayrollEmployeeWhereInput>;
};

export type PayrollEmployeeScalarWhereInput = {
  AND?: Maybe<Array<PayrollEmployeeScalarWhereInput>>;
  NOT?: Maybe<Array<PayrollEmployeeScalarWhereInput>>;
  OR?: Maybe<Array<PayrollEmployeeScalarWhereInput>>;
  actualEmployeeId?: Maybe<StringNullableFilter>;
  budgetYear?: Maybe<IntNullableFilter>;
  classification?: Maybe<EnumPayrollEmployeeClassificationFilter>;
  externalSystemEmployeeId?: Maybe<StringNullableFilter>;
  firstName?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  lastName?: Maybe<StringNullableFilter>;
  modeledType?: Maybe<EnumPayrollModeledEmployeeTypeFilter>;
  positionId?: Maybe<StringFilter>;
  startDate?: Maybe<DateTimeFilter>;
  terminationDate?: Maybe<DateTimeNullableFilter>;
};

export type PayrollEmployeeTotalsModel = {
  __typename?: 'PayrollEmployeeTotalsModel';
  basePayTotal: Scalars['Float'];
  bonusTotal: Scalars['Float'];
  employeeId: Scalars['ID'];
  overtimeTotal: Scalars['Float'];
  positionName: Scalars['String'];
  raiseTotal: Scalars['Float'];
};

export type PayrollEmployeeUpdateManyMutationInput = {
  budgetYear?: Maybe<NullableIntFieldUpdateOperationsInput>;
  classification?: Maybe<EnumPayrollEmployeeClassificationFieldUpdateOperationsInput>;
  externalSystemEmployeeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modeledType?: Maybe<EnumPayrollModeledEmployeeTypeFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  terminationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PayrollEmployeeUpdateManyWithWhereWithoutPositionInput = {
  data: PayrollEmployeeUpdateManyMutationInput;
  where: PayrollEmployeeScalarWhereInput;
};

export type PayrollEmployeeUpdateManyWithoutPositionInput = {
  connect?: Maybe<Array<PayrollEmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollEmployeeCreateOrConnectWithoutPositionInput>>;
  create?: Maybe<Array<PayrollEmployeeCreateWithoutPositionInput>>;
  createMany?: Maybe<PayrollEmployeeCreateManyPositionInputEnvelope>;
  delete?: Maybe<Array<PayrollEmployeeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollEmployeeScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollEmployeeWhereUniqueInput>>;
  set?: Maybe<Array<PayrollEmployeeWhereUniqueInput>>;
  update?: Maybe<Array<PayrollEmployeeUpdateWithWhereUniqueWithoutPositionInput>>;
  updateMany?: Maybe<Array<PayrollEmployeeUpdateManyWithWhereWithoutPositionInput>>;
  upsert?: Maybe<Array<PayrollEmployeeUpsertWithWhereUniqueWithoutPositionInput>>;
};

export type PayrollEmployeeUpdateOneRequiredWithoutActualCompensationItemAmountsInput = {
  connect?: Maybe<PayrollEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollEmployeeCreateOrConnectWithoutActualCompensationItemAmountsInput>;
  create?: Maybe<PayrollEmployeeCreateWithoutActualCompensationItemAmountsInput>;
  update?: Maybe<PayrollEmployeeUpdateWithoutActualCompensationItemAmountsInput>;
  upsert?: Maybe<PayrollEmployeeUpsertWithoutActualCompensationItemAmountsInput>;
};

export type PayrollEmployeeUpdateOneRequiredWithoutCompensationBonusesInput = {
  connect?: Maybe<PayrollEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollEmployeeCreateOrConnectWithoutCompensationBonusesInput>;
  create?: Maybe<PayrollEmployeeCreateWithoutCompensationBonusesInput>;
  update?: Maybe<PayrollEmployeeUpdateWithoutCompensationBonusesInput>;
  upsert?: Maybe<PayrollEmployeeUpsertWithoutCompensationBonusesInput>;
};

export type PayrollEmployeeUpdateOneRequiredWithoutCompensationItemsInput = {
  connect?: Maybe<PayrollEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollEmployeeCreateOrConnectWithoutCompensationItemsInput>;
  create?: Maybe<PayrollEmployeeCreateWithoutCompensationItemsInput>;
  update?: Maybe<PayrollEmployeeUpdateWithoutCompensationItemsInput>;
  upsert?: Maybe<PayrollEmployeeUpsertWithoutCompensationItemsInput>;
};

export type PayrollEmployeeUpdateOneRequiredWithoutCompensationOvertimeValuesInput = {
  connect?: Maybe<PayrollEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollEmployeeCreateOrConnectWithoutCompensationOvertimeValuesInput>;
  create?: Maybe<PayrollEmployeeCreateWithoutCompensationOvertimeValuesInput>;
  update?: Maybe<PayrollEmployeeUpdateWithoutCompensationOvertimeValuesInput>;
  upsert?: Maybe<PayrollEmployeeUpsertWithoutCompensationOvertimeValuesInput>;
};

export type PayrollEmployeeUpdateOneRequiredWithoutCompensationRaisesInput = {
  connect?: Maybe<PayrollEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollEmployeeCreateOrConnectWithoutCompensationRaisesInput>;
  create?: Maybe<PayrollEmployeeCreateWithoutCompensationRaisesInput>;
  update?: Maybe<PayrollEmployeeUpdateWithoutCompensationRaisesInput>;
  upsert?: Maybe<PayrollEmployeeUpsertWithoutCompensationRaisesInput>;
};

export type PayrollEmployeeUpdateOneRequiredWithoutPropertyAttributionsInput = {
  connect?: Maybe<PayrollEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollEmployeeCreateOrConnectWithoutPropertyAttributionsInput>;
  create?: Maybe<PayrollEmployeeCreateWithoutPropertyAttributionsInput>;
  update?: Maybe<PayrollEmployeeUpdateWithoutPropertyAttributionsInput>;
  upsert?: Maybe<PayrollEmployeeUpsertWithoutPropertyAttributionsInput>;
};

export type PayrollEmployeeUpdateOneWithoutActualEmployeeInput = {
  connect?: Maybe<PayrollEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollEmployeeCreateOrConnectWithoutActualEmployeeInput>;
  create?: Maybe<PayrollEmployeeCreateWithoutActualEmployeeInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PayrollEmployeeUpdateWithoutActualEmployeeInput>;
  upsert?: Maybe<PayrollEmployeeUpsertWithoutActualEmployeeInput>;
};

export type PayrollEmployeeUpdateOneWithoutExpectedEmployeeInput = {
  connect?: Maybe<PayrollEmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollEmployeeCreateOrConnectWithoutExpectedEmployeeInput>;
  create?: Maybe<PayrollEmployeeCreateWithoutExpectedEmployeeInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PayrollEmployeeUpdateWithoutExpectedEmployeeInput>;
  upsert?: Maybe<PayrollEmployeeUpsertWithoutExpectedEmployeeInput>;
};

export type PayrollEmployeeUpdateWithWhereUniqueWithoutPositionInput = {
  data: PayrollEmployeeUpdateWithoutPositionInput;
  where: PayrollEmployeeWhereUniqueInput;
};

export type PayrollEmployeeUpdateWithoutActualCompensationItemAmountsInput = {
  actualBaseAmounts?: Maybe<PayrollActualEmployeeBaseAmountsUpdateManyWithoutEmployeeInput>;
  actualBonusAmounts?: Maybe<PayrollActualEmployeeBonusAmountsUpdateManyWithoutEmployeeInput>;
  actualEmployee?: Maybe<PayrollEmployeeUpdateOneWithoutExpectedEmployeeInput>;
  actualOvertimeAmounts?: Maybe<PayrollActualEmployeeOvertimeAmountsUpdateManyWithoutEmployeeInput>;
  budgetYear?: Maybe<NullableIntFieldUpdateOperationsInput>;
  classification?: Maybe<EnumPayrollEmployeeClassificationFieldUpdateOperationsInput>;
  compensationBase?: Maybe<PayrollEmployeeCompensationBaseUpdateOneWithoutEmployeeInput>;
  compensationBonuses?: Maybe<PayrollEmployeeCompensationBonusUpdateManyWithoutEmployeeInput>;
  compensationItems?: Maybe<PayrollEmployeeCompensationItemUpdateManyWithoutEmployeeInput>;
  compensationOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeUpdateManyWithoutEmployeeInput>;
  compensationRaises?: Maybe<PayrollEmployeeCompensationRaiseUpdateManyWithoutEmployeeInput>;
  expectedEmployee?: Maybe<PayrollEmployeeUpdateOneWithoutActualEmployeeInput>;
  externalSystemEmployeeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modeledType?: Maybe<EnumPayrollModeledEmployeeTypeFieldUpdateOperationsInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutEmployeesInput>;
  propertyAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutEmployeeInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  terminationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PayrollEmployeeUpdateWithoutActualEmployeeInput = {
  actualBaseAmounts?: Maybe<PayrollActualEmployeeBaseAmountsUpdateManyWithoutEmployeeInput>;
  actualBonusAmounts?: Maybe<PayrollActualEmployeeBonusAmountsUpdateManyWithoutEmployeeInput>;
  actualCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsUpdateManyWithoutEmployeeInput>;
  actualOvertimeAmounts?: Maybe<PayrollActualEmployeeOvertimeAmountsUpdateManyWithoutEmployeeInput>;
  budgetYear?: Maybe<NullableIntFieldUpdateOperationsInput>;
  classification?: Maybe<EnumPayrollEmployeeClassificationFieldUpdateOperationsInput>;
  compensationBase?: Maybe<PayrollEmployeeCompensationBaseUpdateOneWithoutEmployeeInput>;
  compensationBonuses?: Maybe<PayrollEmployeeCompensationBonusUpdateManyWithoutEmployeeInput>;
  compensationItems?: Maybe<PayrollEmployeeCompensationItemUpdateManyWithoutEmployeeInput>;
  compensationOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeUpdateManyWithoutEmployeeInput>;
  compensationRaises?: Maybe<PayrollEmployeeCompensationRaiseUpdateManyWithoutEmployeeInput>;
  expectedEmployee?: Maybe<PayrollEmployeeUpdateOneWithoutActualEmployeeInput>;
  externalSystemEmployeeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modeledType?: Maybe<EnumPayrollModeledEmployeeTypeFieldUpdateOperationsInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutEmployeesInput>;
  propertyAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutEmployeeInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  terminationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PayrollEmployeeUpdateWithoutCompensationBonusesInput = {
  actualBaseAmounts?: Maybe<PayrollActualEmployeeBaseAmountsUpdateManyWithoutEmployeeInput>;
  actualBonusAmounts?: Maybe<PayrollActualEmployeeBonusAmountsUpdateManyWithoutEmployeeInput>;
  actualCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsUpdateManyWithoutEmployeeInput>;
  actualEmployee?: Maybe<PayrollEmployeeUpdateOneWithoutExpectedEmployeeInput>;
  actualOvertimeAmounts?: Maybe<PayrollActualEmployeeOvertimeAmountsUpdateManyWithoutEmployeeInput>;
  budgetYear?: Maybe<NullableIntFieldUpdateOperationsInput>;
  classification?: Maybe<EnumPayrollEmployeeClassificationFieldUpdateOperationsInput>;
  compensationBase?: Maybe<PayrollEmployeeCompensationBaseUpdateOneWithoutEmployeeInput>;
  compensationItems?: Maybe<PayrollEmployeeCompensationItemUpdateManyWithoutEmployeeInput>;
  compensationOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeUpdateManyWithoutEmployeeInput>;
  compensationRaises?: Maybe<PayrollEmployeeCompensationRaiseUpdateManyWithoutEmployeeInput>;
  expectedEmployee?: Maybe<PayrollEmployeeUpdateOneWithoutActualEmployeeInput>;
  externalSystemEmployeeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modeledType?: Maybe<EnumPayrollModeledEmployeeTypeFieldUpdateOperationsInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutEmployeesInput>;
  propertyAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutEmployeeInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  terminationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PayrollEmployeeUpdateWithoutCompensationItemsInput = {
  actualBaseAmounts?: Maybe<PayrollActualEmployeeBaseAmountsUpdateManyWithoutEmployeeInput>;
  actualBonusAmounts?: Maybe<PayrollActualEmployeeBonusAmountsUpdateManyWithoutEmployeeInput>;
  actualCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsUpdateManyWithoutEmployeeInput>;
  actualEmployee?: Maybe<PayrollEmployeeUpdateOneWithoutExpectedEmployeeInput>;
  actualOvertimeAmounts?: Maybe<PayrollActualEmployeeOvertimeAmountsUpdateManyWithoutEmployeeInput>;
  budgetYear?: Maybe<NullableIntFieldUpdateOperationsInput>;
  classification?: Maybe<EnumPayrollEmployeeClassificationFieldUpdateOperationsInput>;
  compensationBase?: Maybe<PayrollEmployeeCompensationBaseUpdateOneWithoutEmployeeInput>;
  compensationBonuses?: Maybe<PayrollEmployeeCompensationBonusUpdateManyWithoutEmployeeInput>;
  compensationOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeUpdateManyWithoutEmployeeInput>;
  compensationRaises?: Maybe<PayrollEmployeeCompensationRaiseUpdateManyWithoutEmployeeInput>;
  expectedEmployee?: Maybe<PayrollEmployeeUpdateOneWithoutActualEmployeeInput>;
  externalSystemEmployeeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modeledType?: Maybe<EnumPayrollModeledEmployeeTypeFieldUpdateOperationsInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutEmployeesInput>;
  propertyAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutEmployeeInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  terminationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PayrollEmployeeUpdateWithoutCompensationOvertimeValuesInput = {
  actualBaseAmounts?: Maybe<PayrollActualEmployeeBaseAmountsUpdateManyWithoutEmployeeInput>;
  actualBonusAmounts?: Maybe<PayrollActualEmployeeBonusAmountsUpdateManyWithoutEmployeeInput>;
  actualCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsUpdateManyWithoutEmployeeInput>;
  actualEmployee?: Maybe<PayrollEmployeeUpdateOneWithoutExpectedEmployeeInput>;
  actualOvertimeAmounts?: Maybe<PayrollActualEmployeeOvertimeAmountsUpdateManyWithoutEmployeeInput>;
  budgetYear?: Maybe<NullableIntFieldUpdateOperationsInput>;
  classification?: Maybe<EnumPayrollEmployeeClassificationFieldUpdateOperationsInput>;
  compensationBase?: Maybe<PayrollEmployeeCompensationBaseUpdateOneWithoutEmployeeInput>;
  compensationBonuses?: Maybe<PayrollEmployeeCompensationBonusUpdateManyWithoutEmployeeInput>;
  compensationItems?: Maybe<PayrollEmployeeCompensationItemUpdateManyWithoutEmployeeInput>;
  compensationRaises?: Maybe<PayrollEmployeeCompensationRaiseUpdateManyWithoutEmployeeInput>;
  expectedEmployee?: Maybe<PayrollEmployeeUpdateOneWithoutActualEmployeeInput>;
  externalSystemEmployeeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modeledType?: Maybe<EnumPayrollModeledEmployeeTypeFieldUpdateOperationsInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutEmployeesInput>;
  propertyAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutEmployeeInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  terminationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PayrollEmployeeUpdateWithoutCompensationRaisesInput = {
  actualBaseAmounts?: Maybe<PayrollActualEmployeeBaseAmountsUpdateManyWithoutEmployeeInput>;
  actualBonusAmounts?: Maybe<PayrollActualEmployeeBonusAmountsUpdateManyWithoutEmployeeInput>;
  actualCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsUpdateManyWithoutEmployeeInput>;
  actualEmployee?: Maybe<PayrollEmployeeUpdateOneWithoutExpectedEmployeeInput>;
  actualOvertimeAmounts?: Maybe<PayrollActualEmployeeOvertimeAmountsUpdateManyWithoutEmployeeInput>;
  budgetYear?: Maybe<NullableIntFieldUpdateOperationsInput>;
  classification?: Maybe<EnumPayrollEmployeeClassificationFieldUpdateOperationsInput>;
  compensationBase?: Maybe<PayrollEmployeeCompensationBaseUpdateOneWithoutEmployeeInput>;
  compensationBonuses?: Maybe<PayrollEmployeeCompensationBonusUpdateManyWithoutEmployeeInput>;
  compensationItems?: Maybe<PayrollEmployeeCompensationItemUpdateManyWithoutEmployeeInput>;
  compensationOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeUpdateManyWithoutEmployeeInput>;
  expectedEmployee?: Maybe<PayrollEmployeeUpdateOneWithoutActualEmployeeInput>;
  externalSystemEmployeeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modeledType?: Maybe<EnumPayrollModeledEmployeeTypeFieldUpdateOperationsInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutEmployeesInput>;
  propertyAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutEmployeeInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  terminationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PayrollEmployeeUpdateWithoutExpectedEmployeeInput = {
  actualBaseAmounts?: Maybe<PayrollActualEmployeeBaseAmountsUpdateManyWithoutEmployeeInput>;
  actualBonusAmounts?: Maybe<PayrollActualEmployeeBonusAmountsUpdateManyWithoutEmployeeInput>;
  actualCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsUpdateManyWithoutEmployeeInput>;
  actualEmployee?: Maybe<PayrollEmployeeUpdateOneWithoutExpectedEmployeeInput>;
  actualOvertimeAmounts?: Maybe<PayrollActualEmployeeOvertimeAmountsUpdateManyWithoutEmployeeInput>;
  budgetYear?: Maybe<NullableIntFieldUpdateOperationsInput>;
  classification?: Maybe<EnumPayrollEmployeeClassificationFieldUpdateOperationsInput>;
  compensationBase?: Maybe<PayrollEmployeeCompensationBaseUpdateOneWithoutEmployeeInput>;
  compensationBonuses?: Maybe<PayrollEmployeeCompensationBonusUpdateManyWithoutEmployeeInput>;
  compensationItems?: Maybe<PayrollEmployeeCompensationItemUpdateManyWithoutEmployeeInput>;
  compensationOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeUpdateManyWithoutEmployeeInput>;
  compensationRaises?: Maybe<PayrollEmployeeCompensationRaiseUpdateManyWithoutEmployeeInput>;
  externalSystemEmployeeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modeledType?: Maybe<EnumPayrollModeledEmployeeTypeFieldUpdateOperationsInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutEmployeesInput>;
  propertyAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutEmployeeInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  terminationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PayrollEmployeeUpdateWithoutPositionInput = {
  actualBaseAmounts?: Maybe<PayrollActualEmployeeBaseAmountsUpdateManyWithoutEmployeeInput>;
  actualBonusAmounts?: Maybe<PayrollActualEmployeeBonusAmountsUpdateManyWithoutEmployeeInput>;
  actualCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsUpdateManyWithoutEmployeeInput>;
  actualEmployee?: Maybe<PayrollEmployeeUpdateOneWithoutExpectedEmployeeInput>;
  actualOvertimeAmounts?: Maybe<PayrollActualEmployeeOvertimeAmountsUpdateManyWithoutEmployeeInput>;
  budgetYear?: Maybe<NullableIntFieldUpdateOperationsInput>;
  classification?: Maybe<EnumPayrollEmployeeClassificationFieldUpdateOperationsInput>;
  compensationBase?: Maybe<PayrollEmployeeCompensationBaseUpdateOneWithoutEmployeeInput>;
  compensationBonuses?: Maybe<PayrollEmployeeCompensationBonusUpdateManyWithoutEmployeeInput>;
  compensationItems?: Maybe<PayrollEmployeeCompensationItemUpdateManyWithoutEmployeeInput>;
  compensationOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeUpdateManyWithoutEmployeeInput>;
  compensationRaises?: Maybe<PayrollEmployeeCompensationRaiseUpdateManyWithoutEmployeeInput>;
  expectedEmployee?: Maybe<PayrollEmployeeUpdateOneWithoutActualEmployeeInput>;
  externalSystemEmployeeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modeledType?: Maybe<EnumPayrollModeledEmployeeTypeFieldUpdateOperationsInput>;
  propertyAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutEmployeeInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  terminationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PayrollEmployeeUpdateWithoutPropertyAttributionsInput = {
  actualBaseAmounts?: Maybe<PayrollActualEmployeeBaseAmountsUpdateManyWithoutEmployeeInput>;
  actualBonusAmounts?: Maybe<PayrollActualEmployeeBonusAmountsUpdateManyWithoutEmployeeInput>;
  actualCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsUpdateManyWithoutEmployeeInput>;
  actualEmployee?: Maybe<PayrollEmployeeUpdateOneWithoutExpectedEmployeeInput>;
  actualOvertimeAmounts?: Maybe<PayrollActualEmployeeOvertimeAmountsUpdateManyWithoutEmployeeInput>;
  budgetYear?: Maybe<NullableIntFieldUpdateOperationsInput>;
  classification?: Maybe<EnumPayrollEmployeeClassificationFieldUpdateOperationsInput>;
  compensationBase?: Maybe<PayrollEmployeeCompensationBaseUpdateOneWithoutEmployeeInput>;
  compensationBonuses?: Maybe<PayrollEmployeeCompensationBonusUpdateManyWithoutEmployeeInput>;
  compensationItems?: Maybe<PayrollEmployeeCompensationItemUpdateManyWithoutEmployeeInput>;
  compensationOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeUpdateManyWithoutEmployeeInput>;
  compensationRaises?: Maybe<PayrollEmployeeCompensationRaiseUpdateManyWithoutEmployeeInput>;
  expectedEmployee?: Maybe<PayrollEmployeeUpdateOneWithoutActualEmployeeInput>;
  externalSystemEmployeeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modeledType?: Maybe<EnumPayrollModeledEmployeeTypeFieldUpdateOperationsInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutEmployeesInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  terminationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PayrollEmployeeUpsertWithWhereUniqueWithoutPositionInput = {
  create: PayrollEmployeeCreateWithoutPositionInput;
  update: PayrollEmployeeUpdateWithoutPositionInput;
  where: PayrollEmployeeWhereUniqueInput;
};

export type PayrollEmployeeUpsertWithoutActualCompensationItemAmountsInput = {
  create: PayrollEmployeeCreateWithoutActualCompensationItemAmountsInput;
  update: PayrollEmployeeUpdateWithoutActualCompensationItemAmountsInput;
};

export type PayrollEmployeeUpsertWithoutActualEmployeeInput = {
  create: PayrollEmployeeCreateWithoutActualEmployeeInput;
  update: PayrollEmployeeUpdateWithoutActualEmployeeInput;
};

export type PayrollEmployeeUpsertWithoutCompensationBonusesInput = {
  create: PayrollEmployeeCreateWithoutCompensationBonusesInput;
  update: PayrollEmployeeUpdateWithoutCompensationBonusesInput;
};

export type PayrollEmployeeUpsertWithoutCompensationItemsInput = {
  create: PayrollEmployeeCreateWithoutCompensationItemsInput;
  update: PayrollEmployeeUpdateWithoutCompensationItemsInput;
};

export type PayrollEmployeeUpsertWithoutCompensationOvertimeValuesInput = {
  create: PayrollEmployeeCreateWithoutCompensationOvertimeValuesInput;
  update: PayrollEmployeeUpdateWithoutCompensationOvertimeValuesInput;
};

export type PayrollEmployeeUpsertWithoutCompensationRaisesInput = {
  create: PayrollEmployeeCreateWithoutCompensationRaisesInput;
  update: PayrollEmployeeUpdateWithoutCompensationRaisesInput;
};

export type PayrollEmployeeUpsertWithoutExpectedEmployeeInput = {
  create: PayrollEmployeeCreateWithoutExpectedEmployeeInput;
  update: PayrollEmployeeUpdateWithoutExpectedEmployeeInput;
};

export type PayrollEmployeeUpsertWithoutPropertyAttributionsInput = {
  create: PayrollEmployeeCreateWithoutPropertyAttributionsInput;
  update: PayrollEmployeeUpdateWithoutPropertyAttributionsInput;
};

export type PayrollEmployeeWhereInput = {
  AND?: Maybe<Array<PayrollEmployeeWhereInput>>;
  NOT?: Maybe<Array<PayrollEmployeeWhereInput>>;
  OR?: Maybe<Array<PayrollEmployeeWhereInput>>;
  actualBaseAmounts?: Maybe<PayrollActualEmployeeBaseAmountsListRelationFilter>;
  actualBonusAmounts?: Maybe<PayrollActualEmployeeBonusAmountsListRelationFilter>;
  actualCompensationItemAmounts?: Maybe<PayrollActualEmployeeCompensationItemAmountsListRelationFilter>;
  actualEmployee?: Maybe<PayrollEmployeeRelationFilter>;
  actualEmployeeId?: Maybe<StringNullableFilter>;
  actualOvertimeAmounts?: Maybe<PayrollActualEmployeeOvertimeAmountsListRelationFilter>;
  budgetYear?: Maybe<IntNullableFilter>;
  classification?: Maybe<EnumPayrollEmployeeClassificationFilter>;
  compensationBase?: Maybe<PayrollEmployeeCompensationBaseRelationFilter>;
  compensationBonuses?: Maybe<PayrollEmployeeCompensationBonusListRelationFilter>;
  compensationItems?: Maybe<PayrollEmployeeCompensationItemListRelationFilter>;
  compensationOvertimeValues?: Maybe<PayrollEmployeeCompensationOvertimeListRelationFilter>;
  compensationRaises?: Maybe<PayrollEmployeeCompensationRaiseListRelationFilter>;
  expectedEmployee?: Maybe<PayrollEmployeeRelationFilter>;
  externalSystemEmployeeId?: Maybe<StringNullableFilter>;
  firstName?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  lastName?: Maybe<StringNullableFilter>;
  modeledType?: Maybe<EnumPayrollModeledEmployeeTypeFilter>;
  position?: Maybe<PayrollPositionRelationFilter>;
  positionId?: Maybe<StringFilter>;
  propertyAttributions?: Maybe<PayrollEmployeePropertyAttributionListRelationFilter>;
  startDate?: Maybe<DateTimeFilter>;
  terminationDate?: Maybe<DateTimeNullableFilter>;
};

export type PayrollEmployeeWhereUniqueInput = {
  actualEmployeeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** //////////////////////////////////////////////// */
export enum PayrollModeledEmployeeType {
  Actual = 'ACTUAL',
  Expected = 'EXPECTED'
}

export enum PayrollPayFrequency {
  BiWeekly = 'BI_WEEKLY',
  Monthly = 'MONTHLY',
  SemiMonthly = 'SEMI_MONTHLY',
  Weekly = 'WEEKLY'
}

export type PayrollPositionClientIdNameCompoundUniqueInput = {
  clientId: Scalars['String'];
  name: Scalars['String'];
};

export type PayrollPositionCompensationAccount = {
  __typename?: 'PayrollPositionCompensationAccount';
  accountId: Scalars['String'];
  positionId: Scalars['String'];
  primaryCompensationItem: PayrollPrimaryCompensationItem;
  propertyId: Scalars['String'];
};

export type PayrollPositionCompensationAccountCreateManyAccountInput = {
  positionId: Scalars['String'];
  primaryCompensationItem: PayrollPrimaryCompensationItem;
  propertyId: Scalars['String'];
};

export type PayrollPositionCompensationAccountCreateManyAccountInputEnvelope = {
  data: Array<PayrollPositionCompensationAccountCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollPositionCompensationAccountCreateManyPositionInput = {
  accountId: Scalars['String'];
  primaryCompensationItem: PayrollPrimaryCompensationItem;
  propertyId: Scalars['String'];
};

export type PayrollPositionCompensationAccountCreateManyPositionInputEnvelope = {
  data: Array<PayrollPositionCompensationAccountCreateManyPositionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollPositionCompensationAccountCreateManyPropertyInput = {
  accountId: Scalars['String'];
  positionId: Scalars['String'];
  primaryCompensationItem: PayrollPrimaryCompensationItem;
};

export type PayrollPositionCompensationAccountCreateManyPropertyInputEnvelope = {
  data: Array<PayrollPositionCompensationAccountCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollPositionCompensationAccountCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<PayrollPositionCompensationAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationAccountCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<PayrollPositionCompensationAccountCreateWithoutAccountInput>>;
  createMany?: Maybe<PayrollPositionCompensationAccountCreateManyAccountInputEnvelope>;
};

export type PayrollPositionCompensationAccountCreateNestedManyWithoutPositionInput = {
  connect?: Maybe<Array<PayrollPositionCompensationAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationAccountCreateOrConnectWithoutPositionInput>>;
  create?: Maybe<Array<PayrollPositionCompensationAccountCreateWithoutPositionInput>>;
  createMany?: Maybe<PayrollPositionCompensationAccountCreateManyPositionInputEnvelope>;
};

export type PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<PayrollPositionCompensationAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationAccountCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PayrollPositionCompensationAccountCreateWithoutPropertyInput>>;
  createMany?: Maybe<PayrollPositionCompensationAccountCreateManyPropertyInputEnvelope>;
};

export type PayrollPositionCompensationAccountCreateOrConnectWithoutAccountInput = {
  create: PayrollPositionCompensationAccountCreateWithoutAccountInput;
  where: PayrollPositionCompensationAccountWhereUniqueInput;
};

export type PayrollPositionCompensationAccountCreateOrConnectWithoutPositionInput = {
  create: PayrollPositionCompensationAccountCreateWithoutPositionInput;
  where: PayrollPositionCompensationAccountWhereUniqueInput;
};

export type PayrollPositionCompensationAccountCreateOrConnectWithoutPropertyInput = {
  create: PayrollPositionCompensationAccountCreateWithoutPropertyInput;
  where: PayrollPositionCompensationAccountWhereUniqueInput;
};

export type PayrollPositionCompensationAccountCreateWithoutAccountInput = {
  position: PayrollPositionCreateNestedOneWithoutPositionCompensationAccountsInput;
  primaryCompensationItem: PayrollPrimaryCompensationItem;
  property: PropertyCreateNestedOneWithoutPayrollPositionCompensationAccountsInput;
};

export type PayrollPositionCompensationAccountCreateWithoutPositionInput = {
  account: AccountV2CreateNestedOneWithoutPayrollPositionCompensationAccountInput;
  primaryCompensationItem: PayrollPrimaryCompensationItem;
  property: PropertyCreateNestedOneWithoutPayrollPositionCompensationAccountsInput;
};

export type PayrollPositionCompensationAccountCreateWithoutPropertyInput = {
  account: AccountV2CreateNestedOneWithoutPayrollPositionCompensationAccountInput;
  position: PayrollPositionCreateNestedOneWithoutPositionCompensationAccountsInput;
  primaryCompensationItem: PayrollPrimaryCompensationItem;
};

export type PayrollPositionCompensationAccountListRelationFilter = {
  every?: Maybe<PayrollPositionCompensationAccountWhereInput>;
  none?: Maybe<PayrollPositionCompensationAccountWhereInput>;
  some?: Maybe<PayrollPositionCompensationAccountWhereInput>;
};

export type PayrollPositionCompensationAccountOrderByInput = {
  accountId?: Maybe<SortOrder>;
  positionId?: Maybe<SortOrder>;
  primaryCompensationItem?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
};

export type PayrollPositionCompensationAccountPropertyIdPositionIdPrimaryCompensationItemCompoundUniqueInput = {
  positionId: Scalars['String'];
  primaryCompensationItem: PayrollPrimaryCompensationItem;
  propertyId: Scalars['String'];
};

export enum PayrollPositionCompensationAccountScalarFieldEnum {
  AccountId = 'accountId',
  PositionId = 'positionId',
  PrimaryCompensationItem = 'primaryCompensationItem',
  PropertyId = 'propertyId'
}

export type PayrollPositionCompensationAccountScalarWhereInput = {
  AND?: Maybe<Array<PayrollPositionCompensationAccountScalarWhereInput>>;
  NOT?: Maybe<Array<PayrollPositionCompensationAccountScalarWhereInput>>;
  OR?: Maybe<Array<PayrollPositionCompensationAccountScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  positionId?: Maybe<StringFilter>;
  primaryCompensationItem?: Maybe<EnumPayrollPrimaryCompensationItemFilter>;
  propertyId?: Maybe<StringFilter>;
};

export type PayrollPositionCompensationAccountUpdateManyMutationInput = {
  primaryCompensationItem?: Maybe<EnumPayrollPrimaryCompensationItemFieldUpdateOperationsInput>;
};

export type PayrollPositionCompensationAccountUpdateManyWithWhereWithoutAccountInput = {
  data: PayrollPositionCompensationAccountUpdateManyMutationInput;
  where: PayrollPositionCompensationAccountScalarWhereInput;
};

export type PayrollPositionCompensationAccountUpdateManyWithWhereWithoutPositionInput = {
  data: PayrollPositionCompensationAccountUpdateManyMutationInput;
  where: PayrollPositionCompensationAccountScalarWhereInput;
};

export type PayrollPositionCompensationAccountUpdateManyWithWhereWithoutPropertyInput = {
  data: PayrollPositionCompensationAccountUpdateManyMutationInput;
  where: PayrollPositionCompensationAccountScalarWhereInput;
};

export type PayrollPositionCompensationAccountUpdateManyWithoutAccountInput = {
  connect?: Maybe<Array<PayrollPositionCompensationAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationAccountCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<PayrollPositionCompensationAccountCreateWithoutAccountInput>>;
  createMany?: Maybe<PayrollPositionCompensationAccountCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<PayrollPositionCompensationAccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollPositionCompensationAccountScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollPositionCompensationAccountWhereUniqueInput>>;
  set?: Maybe<Array<PayrollPositionCompensationAccountWhereUniqueInput>>;
  update?: Maybe<Array<PayrollPositionCompensationAccountUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<PayrollPositionCompensationAccountUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<PayrollPositionCompensationAccountUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type PayrollPositionCompensationAccountUpdateManyWithoutPositionInput = {
  connect?: Maybe<Array<PayrollPositionCompensationAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationAccountCreateOrConnectWithoutPositionInput>>;
  create?: Maybe<Array<PayrollPositionCompensationAccountCreateWithoutPositionInput>>;
  createMany?: Maybe<PayrollPositionCompensationAccountCreateManyPositionInputEnvelope>;
  delete?: Maybe<Array<PayrollPositionCompensationAccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollPositionCompensationAccountScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollPositionCompensationAccountWhereUniqueInput>>;
  set?: Maybe<Array<PayrollPositionCompensationAccountWhereUniqueInput>>;
  update?: Maybe<Array<PayrollPositionCompensationAccountUpdateWithWhereUniqueWithoutPositionInput>>;
  updateMany?: Maybe<Array<PayrollPositionCompensationAccountUpdateManyWithWhereWithoutPositionInput>>;
  upsert?: Maybe<Array<PayrollPositionCompensationAccountUpsertWithWhereUniqueWithoutPositionInput>>;
};

export type PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<PayrollPositionCompensationAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationAccountCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PayrollPositionCompensationAccountCreateWithoutPropertyInput>>;
  createMany?: Maybe<PayrollPositionCompensationAccountCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<PayrollPositionCompensationAccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollPositionCompensationAccountScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollPositionCompensationAccountWhereUniqueInput>>;
  set?: Maybe<Array<PayrollPositionCompensationAccountWhereUniqueInput>>;
  update?: Maybe<Array<PayrollPositionCompensationAccountUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<PayrollPositionCompensationAccountUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<PayrollPositionCompensationAccountUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type PayrollPositionCompensationAccountUpdateWithWhereUniqueWithoutAccountInput = {
  data: PayrollPositionCompensationAccountUpdateWithoutAccountInput;
  where: PayrollPositionCompensationAccountWhereUniqueInput;
};

export type PayrollPositionCompensationAccountUpdateWithWhereUniqueWithoutPositionInput = {
  data: PayrollPositionCompensationAccountUpdateWithoutPositionInput;
  where: PayrollPositionCompensationAccountWhereUniqueInput;
};

export type PayrollPositionCompensationAccountUpdateWithWhereUniqueWithoutPropertyInput = {
  data: PayrollPositionCompensationAccountUpdateWithoutPropertyInput;
  where: PayrollPositionCompensationAccountWhereUniqueInput;
};

export type PayrollPositionCompensationAccountUpdateWithoutAccountInput = {
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutPositionCompensationAccountsInput>;
  primaryCompensationItem?: Maybe<EnumPayrollPrimaryCompensationItemFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutPayrollPositionCompensationAccountsInput>;
};

export type PayrollPositionCompensationAccountUpdateWithoutPositionInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutPayrollPositionCompensationAccountInput>;
  primaryCompensationItem?: Maybe<EnumPayrollPrimaryCompensationItemFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutPayrollPositionCompensationAccountsInput>;
};

export type PayrollPositionCompensationAccountUpdateWithoutPropertyInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutPayrollPositionCompensationAccountInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutPositionCompensationAccountsInput>;
  primaryCompensationItem?: Maybe<EnumPayrollPrimaryCompensationItemFieldUpdateOperationsInput>;
};

export type PayrollPositionCompensationAccountUpsertWithWhereUniqueWithoutAccountInput = {
  create: PayrollPositionCompensationAccountCreateWithoutAccountInput;
  update: PayrollPositionCompensationAccountUpdateWithoutAccountInput;
  where: PayrollPositionCompensationAccountWhereUniqueInput;
};

export type PayrollPositionCompensationAccountUpsertWithWhereUniqueWithoutPositionInput = {
  create: PayrollPositionCompensationAccountCreateWithoutPositionInput;
  update: PayrollPositionCompensationAccountUpdateWithoutPositionInput;
  where: PayrollPositionCompensationAccountWhereUniqueInput;
};

export type PayrollPositionCompensationAccountUpsertWithWhereUniqueWithoutPropertyInput = {
  create: PayrollPositionCompensationAccountCreateWithoutPropertyInput;
  update: PayrollPositionCompensationAccountUpdateWithoutPropertyInput;
  where: PayrollPositionCompensationAccountWhereUniqueInput;
};

export type PayrollPositionCompensationAccountV2 = {
  __typename?: 'PayrollPositionCompensationAccountV2';
  accountId: Scalars['String'];
  budgetYear: Scalars['Int'];
  id: Scalars['String'];
  positionId: Scalars['String'];
  primaryCompensationItem: PayrollPrimaryCompensationItem;
  propertyId: Scalars['String'];
};

export type PayrollPositionCompensationAccountV2CreateManyAccountInput = {
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  positionId: Scalars['String'];
  primaryCompensationItem: PayrollPrimaryCompensationItem;
  propertyId: Scalars['String'];
};

export type PayrollPositionCompensationAccountV2CreateManyAccountInputEnvelope = {
  data: Array<PayrollPositionCompensationAccountV2CreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollPositionCompensationAccountV2CreateManyPositionInput = {
  accountId: Scalars['String'];
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  primaryCompensationItem: PayrollPrimaryCompensationItem;
  propertyId: Scalars['String'];
};

export type PayrollPositionCompensationAccountV2CreateManyPositionInputEnvelope = {
  data: Array<PayrollPositionCompensationAccountV2CreateManyPositionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollPositionCompensationAccountV2CreateManyPropertyInput = {
  accountId: Scalars['String'];
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  positionId: Scalars['String'];
  primaryCompensationItem: PayrollPrimaryCompensationItem;
};

export type PayrollPositionCompensationAccountV2CreateManyPropertyInputEnvelope = {
  data: Array<PayrollPositionCompensationAccountV2CreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollPositionCompensationAccountV2CreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<PayrollPositionCompensationAccountV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationAccountV2CreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<PayrollPositionCompensationAccountV2CreateWithoutAccountInput>>;
  createMany?: Maybe<PayrollPositionCompensationAccountV2CreateManyAccountInputEnvelope>;
};

export type PayrollPositionCompensationAccountV2CreateNestedManyWithoutPositionInput = {
  connect?: Maybe<Array<PayrollPositionCompensationAccountV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationAccountV2CreateOrConnectWithoutPositionInput>>;
  create?: Maybe<Array<PayrollPositionCompensationAccountV2CreateWithoutPositionInput>>;
  createMany?: Maybe<PayrollPositionCompensationAccountV2CreateManyPositionInputEnvelope>;
};

export type PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<PayrollPositionCompensationAccountV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationAccountV2CreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PayrollPositionCompensationAccountV2CreateWithoutPropertyInput>>;
  createMany?: Maybe<PayrollPositionCompensationAccountV2CreateManyPropertyInputEnvelope>;
};

export type PayrollPositionCompensationAccountV2CreateOrConnectWithoutAccountInput = {
  create: PayrollPositionCompensationAccountV2CreateWithoutAccountInput;
  where: PayrollPositionCompensationAccountV2WhereUniqueInput;
};

export type PayrollPositionCompensationAccountV2CreateOrConnectWithoutPositionInput = {
  create: PayrollPositionCompensationAccountV2CreateWithoutPositionInput;
  where: PayrollPositionCompensationAccountV2WhereUniqueInput;
};

export type PayrollPositionCompensationAccountV2CreateOrConnectWithoutPropertyInput = {
  create: PayrollPositionCompensationAccountV2CreateWithoutPropertyInput;
  where: PayrollPositionCompensationAccountV2WhereUniqueInput;
};

export type PayrollPositionCompensationAccountV2CreateWithoutAccountInput = {
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  position: PayrollPositionCreateNestedOneWithoutPositionCompensationAccountsV2Input;
  primaryCompensationItem: PayrollPrimaryCompensationItem;
  property: PropertyCreateNestedOneWithoutPayrollPositionCompensationAccountsV2Input;
};

export type PayrollPositionCompensationAccountV2CreateWithoutPositionInput = {
  account: AccountV2CreateNestedOneWithoutPayrollPositionCompensationAccountV2Input;
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  primaryCompensationItem: PayrollPrimaryCompensationItem;
  property: PropertyCreateNestedOneWithoutPayrollPositionCompensationAccountsV2Input;
};

export type PayrollPositionCompensationAccountV2CreateWithoutPropertyInput = {
  account: AccountV2CreateNestedOneWithoutPayrollPositionCompensationAccountV2Input;
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  position: PayrollPositionCreateNestedOneWithoutPositionCompensationAccountsV2Input;
  primaryCompensationItem: PayrollPrimaryCompensationItem;
};

export type PayrollPositionCompensationAccountV2ListRelationFilter = {
  every?: Maybe<PayrollPositionCompensationAccountV2WhereInput>;
  none?: Maybe<PayrollPositionCompensationAccountV2WhereInput>;
  some?: Maybe<PayrollPositionCompensationAccountV2WhereInput>;
};

export type PayrollPositionCompensationAccountV2OrderByInput = {
  accountId?: Maybe<SortOrder>;
  budgetYear?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  positionId?: Maybe<SortOrder>;
  primaryCompensationItem?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
};

export enum PayrollPositionCompensationAccountV2ScalarFieldEnum {
  AccountId = 'accountId',
  BudgetYear = 'budgetYear',
  Id = 'id',
  PositionId = 'positionId',
  PrimaryCompensationItem = 'primaryCompensationItem',
  PropertyId = 'propertyId'
}

export type PayrollPositionCompensationAccountV2ScalarWhereInput = {
  AND?: Maybe<Array<PayrollPositionCompensationAccountV2ScalarWhereInput>>;
  NOT?: Maybe<Array<PayrollPositionCompensationAccountV2ScalarWhereInput>>;
  OR?: Maybe<Array<PayrollPositionCompensationAccountV2ScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  budgetYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  positionId?: Maybe<StringFilter>;
  primaryCompensationItem?: Maybe<EnumPayrollPrimaryCompensationItemFilter>;
  propertyId?: Maybe<StringFilter>;
};

export type PayrollPositionCompensationAccountV2UpdateManyMutationInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  primaryCompensationItem?: Maybe<EnumPayrollPrimaryCompensationItemFieldUpdateOperationsInput>;
};

export type PayrollPositionCompensationAccountV2UpdateManyWithWhereWithoutAccountInput = {
  data: PayrollPositionCompensationAccountV2UpdateManyMutationInput;
  where: PayrollPositionCompensationAccountV2ScalarWhereInput;
};

export type PayrollPositionCompensationAccountV2UpdateManyWithWhereWithoutPositionInput = {
  data: PayrollPositionCompensationAccountV2UpdateManyMutationInput;
  where: PayrollPositionCompensationAccountV2ScalarWhereInput;
};

export type PayrollPositionCompensationAccountV2UpdateManyWithWhereWithoutPropertyInput = {
  data: PayrollPositionCompensationAccountV2UpdateManyMutationInput;
  where: PayrollPositionCompensationAccountV2ScalarWhereInput;
};

export type PayrollPositionCompensationAccountV2UpdateManyWithoutAccountInput = {
  connect?: Maybe<Array<PayrollPositionCompensationAccountV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationAccountV2CreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<PayrollPositionCompensationAccountV2CreateWithoutAccountInput>>;
  createMany?: Maybe<PayrollPositionCompensationAccountV2CreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<PayrollPositionCompensationAccountV2WhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollPositionCompensationAccountV2ScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollPositionCompensationAccountV2WhereUniqueInput>>;
  set?: Maybe<Array<PayrollPositionCompensationAccountV2WhereUniqueInput>>;
  update?: Maybe<Array<PayrollPositionCompensationAccountV2UpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<PayrollPositionCompensationAccountV2UpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<PayrollPositionCompensationAccountV2UpsertWithWhereUniqueWithoutAccountInput>>;
};

export type PayrollPositionCompensationAccountV2UpdateManyWithoutPositionInput = {
  connect?: Maybe<Array<PayrollPositionCompensationAccountV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationAccountV2CreateOrConnectWithoutPositionInput>>;
  create?: Maybe<Array<PayrollPositionCompensationAccountV2CreateWithoutPositionInput>>;
  createMany?: Maybe<PayrollPositionCompensationAccountV2CreateManyPositionInputEnvelope>;
  delete?: Maybe<Array<PayrollPositionCompensationAccountV2WhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollPositionCompensationAccountV2ScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollPositionCompensationAccountV2WhereUniqueInput>>;
  set?: Maybe<Array<PayrollPositionCompensationAccountV2WhereUniqueInput>>;
  update?: Maybe<Array<PayrollPositionCompensationAccountV2UpdateWithWhereUniqueWithoutPositionInput>>;
  updateMany?: Maybe<Array<PayrollPositionCompensationAccountV2UpdateManyWithWhereWithoutPositionInput>>;
  upsert?: Maybe<Array<PayrollPositionCompensationAccountV2UpsertWithWhereUniqueWithoutPositionInput>>;
};

export type PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<PayrollPositionCompensationAccountV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationAccountV2CreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PayrollPositionCompensationAccountV2CreateWithoutPropertyInput>>;
  createMany?: Maybe<PayrollPositionCompensationAccountV2CreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<PayrollPositionCompensationAccountV2WhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollPositionCompensationAccountV2ScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollPositionCompensationAccountV2WhereUniqueInput>>;
  set?: Maybe<Array<PayrollPositionCompensationAccountV2WhereUniqueInput>>;
  update?: Maybe<Array<PayrollPositionCompensationAccountV2UpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<PayrollPositionCompensationAccountV2UpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<PayrollPositionCompensationAccountV2UpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type PayrollPositionCompensationAccountV2UpdateWithWhereUniqueWithoutAccountInput = {
  data: PayrollPositionCompensationAccountV2UpdateWithoutAccountInput;
  where: PayrollPositionCompensationAccountV2WhereUniqueInput;
};

export type PayrollPositionCompensationAccountV2UpdateWithWhereUniqueWithoutPositionInput = {
  data: PayrollPositionCompensationAccountV2UpdateWithoutPositionInput;
  where: PayrollPositionCompensationAccountV2WhereUniqueInput;
};

export type PayrollPositionCompensationAccountV2UpdateWithWhereUniqueWithoutPropertyInput = {
  data: PayrollPositionCompensationAccountV2UpdateWithoutPropertyInput;
  where: PayrollPositionCompensationAccountV2WhereUniqueInput;
};

export type PayrollPositionCompensationAccountV2UpdateWithoutAccountInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutPositionCompensationAccountsV2Input>;
  primaryCompensationItem?: Maybe<EnumPayrollPrimaryCompensationItemFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutPayrollPositionCompensationAccountsV2Input>;
};

export type PayrollPositionCompensationAccountV2UpdateWithoutPositionInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutPayrollPositionCompensationAccountV2Input>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  primaryCompensationItem?: Maybe<EnumPayrollPrimaryCompensationItemFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutPayrollPositionCompensationAccountsV2Input>;
};

export type PayrollPositionCompensationAccountV2UpdateWithoutPropertyInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutPayrollPositionCompensationAccountV2Input>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutPositionCompensationAccountsV2Input>;
  primaryCompensationItem?: Maybe<EnumPayrollPrimaryCompensationItemFieldUpdateOperationsInput>;
};

export type PayrollPositionCompensationAccountV2UpsertWithWhereUniqueWithoutAccountInput = {
  create: PayrollPositionCompensationAccountV2CreateWithoutAccountInput;
  update: PayrollPositionCompensationAccountV2UpdateWithoutAccountInput;
  where: PayrollPositionCompensationAccountV2WhereUniqueInput;
};

export type PayrollPositionCompensationAccountV2UpsertWithWhereUniqueWithoutPositionInput = {
  create: PayrollPositionCompensationAccountV2CreateWithoutPositionInput;
  update: PayrollPositionCompensationAccountV2UpdateWithoutPositionInput;
  where: PayrollPositionCompensationAccountV2WhereUniqueInput;
};

export type PayrollPositionCompensationAccountV2UpsertWithWhereUniqueWithoutPropertyInput = {
  create: PayrollPositionCompensationAccountV2CreateWithoutPropertyInput;
  update: PayrollPositionCompensationAccountV2UpdateWithoutPropertyInput;
  where: PayrollPositionCompensationAccountV2WhereUniqueInput;
};

export type PayrollPositionCompensationAccountV2WhereInput = {
  AND?: Maybe<Array<PayrollPositionCompensationAccountV2WhereInput>>;
  NOT?: Maybe<Array<PayrollPositionCompensationAccountV2WhereInput>>;
  OR?: Maybe<Array<PayrollPositionCompensationAccountV2WhereInput>>;
  account?: Maybe<AccountV2RelationFilter>;
  accountId?: Maybe<StringFilter>;
  budgetYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  position?: Maybe<PayrollPositionRelationFilter>;
  positionId?: Maybe<StringFilter>;
  primaryCompensationItem?: Maybe<EnumPayrollPrimaryCompensationItemFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
};

export type PayrollPositionCompensationAccountV2WhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PayrollPositionCompensationAccountWhereInput = {
  AND?: Maybe<Array<PayrollPositionCompensationAccountWhereInput>>;
  NOT?: Maybe<Array<PayrollPositionCompensationAccountWhereInput>>;
  OR?: Maybe<Array<PayrollPositionCompensationAccountWhereInput>>;
  account?: Maybe<AccountV2RelationFilter>;
  accountId?: Maybe<StringFilter>;
  position?: Maybe<PayrollPositionRelationFilter>;
  positionId?: Maybe<StringFilter>;
  primaryCompensationItem?: Maybe<EnumPayrollPrimaryCompensationItemFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
};

export type PayrollPositionCompensationAccountWhereUniqueInput = {
  propertyId_positionId_primaryCompensationItem?: Maybe<PayrollPositionCompensationAccountPropertyIdPositionIdPrimaryCompensationItemCompoundUniqueInput>;
};

export type PayrollPositionCompensationItemAccount = {
  __typename?: 'PayrollPositionCompensationItemAccount';
  accountId: Scalars['String'];
  compensationItemId: Scalars['String'];
  positionId: Scalars['String'];
  propertyId: Scalars['String'];
  unused?: Maybe<Scalars['Int']>;
};

export type PayrollPositionCompensationItemAccountCreateManyAccountInput = {
  compensationItemId: Scalars['String'];
  positionId: Scalars['String'];
  propertyId: Scalars['String'];
  unused?: Maybe<Scalars['Int']>;
};

export type PayrollPositionCompensationItemAccountCreateManyAccountInputEnvelope = {
  data: Array<PayrollPositionCompensationItemAccountCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollPositionCompensationItemAccountCreateManyCompensationItemInput = {
  accountId: Scalars['String'];
  positionId: Scalars['String'];
  propertyId: Scalars['String'];
  unused?: Maybe<Scalars['Int']>;
};

export type PayrollPositionCompensationItemAccountCreateManyCompensationItemInputEnvelope = {
  data: Array<PayrollPositionCompensationItemAccountCreateManyCompensationItemInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollPositionCompensationItemAccountCreateManyPositionInput = {
  accountId: Scalars['String'];
  compensationItemId: Scalars['String'];
  propertyId: Scalars['String'];
  unused?: Maybe<Scalars['Int']>;
};

export type PayrollPositionCompensationItemAccountCreateManyPositionInputEnvelope = {
  data: Array<PayrollPositionCompensationItemAccountCreateManyPositionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollPositionCompensationItemAccountCreateManyPropertyInput = {
  accountId: Scalars['String'];
  compensationItemId: Scalars['String'];
  positionId: Scalars['String'];
  unused?: Maybe<Scalars['Int']>;
};

export type PayrollPositionCompensationItemAccountCreateManyPropertyInputEnvelope = {
  data: Array<PayrollPositionCompensationItemAccountCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollPositionCompensationItemAccountCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<PayrollPositionCompensationItemAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationItemAccountCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<PayrollPositionCompensationItemAccountCreateWithoutAccountInput>>;
  createMany?: Maybe<PayrollPositionCompensationItemAccountCreateManyAccountInputEnvelope>;
};

export type PayrollPositionCompensationItemAccountCreateNestedManyWithoutCompensationItemInput = {
  connect?: Maybe<Array<PayrollPositionCompensationItemAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationItemAccountCreateOrConnectWithoutCompensationItemInput>>;
  create?: Maybe<Array<PayrollPositionCompensationItemAccountCreateWithoutCompensationItemInput>>;
  createMany?: Maybe<PayrollPositionCompensationItemAccountCreateManyCompensationItemInputEnvelope>;
};

export type PayrollPositionCompensationItemAccountCreateNestedManyWithoutPositionInput = {
  connect?: Maybe<Array<PayrollPositionCompensationItemAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationItemAccountCreateOrConnectWithoutPositionInput>>;
  create?: Maybe<Array<PayrollPositionCompensationItemAccountCreateWithoutPositionInput>>;
  createMany?: Maybe<PayrollPositionCompensationItemAccountCreateManyPositionInputEnvelope>;
};

export type PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<PayrollPositionCompensationItemAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationItemAccountCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PayrollPositionCompensationItemAccountCreateWithoutPropertyInput>>;
  createMany?: Maybe<PayrollPositionCompensationItemAccountCreateManyPropertyInputEnvelope>;
};

export type PayrollPositionCompensationItemAccountCreateOrConnectWithoutAccountInput = {
  create: PayrollPositionCompensationItemAccountCreateWithoutAccountInput;
  where: PayrollPositionCompensationItemAccountWhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountCreateOrConnectWithoutCompensationItemInput = {
  create: PayrollPositionCompensationItemAccountCreateWithoutCompensationItemInput;
  where: PayrollPositionCompensationItemAccountWhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountCreateOrConnectWithoutPositionInput = {
  create: PayrollPositionCompensationItemAccountCreateWithoutPositionInput;
  where: PayrollPositionCompensationItemAccountWhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountCreateOrConnectWithoutPropertyInput = {
  create: PayrollPositionCompensationItemAccountCreateWithoutPropertyInput;
  where: PayrollPositionCompensationItemAccountWhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountCreateWithoutAccountInput = {
  compensationItem: PayrollCompensationItemCreateNestedOneWithoutCompensationItemPositionAccountsInput;
  position: PayrollPositionCreateNestedOneWithoutCompensationItemPositionAccountsInput;
  property: PropertyCreateNestedOneWithoutPayrollPositionCompensationItemAccountsInput;
  unused?: Maybe<Scalars['Int']>;
};

export type PayrollPositionCompensationItemAccountCreateWithoutCompensationItemInput = {
  account: AccountV2CreateNestedOneWithoutPayrollPositionCompensationItemAccountInput;
  position: PayrollPositionCreateNestedOneWithoutCompensationItemPositionAccountsInput;
  property: PropertyCreateNestedOneWithoutPayrollPositionCompensationItemAccountsInput;
  unused?: Maybe<Scalars['Int']>;
};

export type PayrollPositionCompensationItemAccountCreateWithoutPositionInput = {
  account: AccountV2CreateNestedOneWithoutPayrollPositionCompensationItemAccountInput;
  compensationItem: PayrollCompensationItemCreateNestedOneWithoutCompensationItemPositionAccountsInput;
  property: PropertyCreateNestedOneWithoutPayrollPositionCompensationItemAccountsInput;
  unused?: Maybe<Scalars['Int']>;
};

export type PayrollPositionCompensationItemAccountCreateWithoutPropertyInput = {
  account: AccountV2CreateNestedOneWithoutPayrollPositionCompensationItemAccountInput;
  compensationItem: PayrollCompensationItemCreateNestedOneWithoutCompensationItemPositionAccountsInput;
  position: PayrollPositionCreateNestedOneWithoutCompensationItemPositionAccountsInput;
  unused?: Maybe<Scalars['Int']>;
};

export type PayrollPositionCompensationItemAccountListRelationFilter = {
  every?: Maybe<PayrollPositionCompensationItemAccountWhereInput>;
  none?: Maybe<PayrollPositionCompensationItemAccountWhereInput>;
  some?: Maybe<PayrollPositionCompensationItemAccountWhereInput>;
};

export type PayrollPositionCompensationItemAccountOrderByInput = {
  accountId?: Maybe<SortOrder>;
  compensationItemId?: Maybe<SortOrder>;
  positionId?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  unused?: Maybe<SortOrder>;
};

export type PayrollPositionCompensationItemAccountPositionIdPropertyIdCompensationItemIdCompoundUniqueInput = {
  compensationItemId: Scalars['String'];
  positionId: Scalars['String'];
  propertyId: Scalars['String'];
};

export enum PayrollPositionCompensationItemAccountScalarFieldEnum {
  AccountId = 'accountId',
  CompensationItemId = 'compensationItemId',
  PositionId = 'positionId',
  PropertyId = 'propertyId',
  Unused = 'unused'
}

export type PayrollPositionCompensationItemAccountScalarWhereInput = {
  AND?: Maybe<Array<PayrollPositionCompensationItemAccountScalarWhereInput>>;
  NOT?: Maybe<Array<PayrollPositionCompensationItemAccountScalarWhereInput>>;
  OR?: Maybe<Array<PayrollPositionCompensationItemAccountScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  compensationItemId?: Maybe<StringFilter>;
  positionId?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  unused?: Maybe<IntNullableFilter>;
};

export type PayrollPositionCompensationItemAccountUpdateManyMutationInput = {
  unused?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type PayrollPositionCompensationItemAccountUpdateManyWithWhereWithoutAccountInput = {
  data: PayrollPositionCompensationItemAccountUpdateManyMutationInput;
  where: PayrollPositionCompensationItemAccountScalarWhereInput;
};

export type PayrollPositionCompensationItemAccountUpdateManyWithWhereWithoutCompensationItemInput = {
  data: PayrollPositionCompensationItemAccountUpdateManyMutationInput;
  where: PayrollPositionCompensationItemAccountScalarWhereInput;
};

export type PayrollPositionCompensationItemAccountUpdateManyWithWhereWithoutPositionInput = {
  data: PayrollPositionCompensationItemAccountUpdateManyMutationInput;
  where: PayrollPositionCompensationItemAccountScalarWhereInput;
};

export type PayrollPositionCompensationItemAccountUpdateManyWithWhereWithoutPropertyInput = {
  data: PayrollPositionCompensationItemAccountUpdateManyMutationInput;
  where: PayrollPositionCompensationItemAccountScalarWhereInput;
};

export type PayrollPositionCompensationItemAccountUpdateManyWithoutAccountInput = {
  connect?: Maybe<Array<PayrollPositionCompensationItemAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationItemAccountCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<PayrollPositionCompensationItemAccountCreateWithoutAccountInput>>;
  createMany?: Maybe<PayrollPositionCompensationItemAccountCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<PayrollPositionCompensationItemAccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollPositionCompensationItemAccountScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollPositionCompensationItemAccountWhereUniqueInput>>;
  set?: Maybe<Array<PayrollPositionCompensationItemAccountWhereUniqueInput>>;
  update?: Maybe<Array<PayrollPositionCompensationItemAccountUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<PayrollPositionCompensationItemAccountUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<PayrollPositionCompensationItemAccountUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type PayrollPositionCompensationItemAccountUpdateManyWithoutCompensationItemInput = {
  connect?: Maybe<Array<PayrollPositionCompensationItemAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationItemAccountCreateOrConnectWithoutCompensationItemInput>>;
  create?: Maybe<Array<PayrollPositionCompensationItemAccountCreateWithoutCompensationItemInput>>;
  createMany?: Maybe<PayrollPositionCompensationItemAccountCreateManyCompensationItemInputEnvelope>;
  delete?: Maybe<Array<PayrollPositionCompensationItemAccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollPositionCompensationItemAccountScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollPositionCompensationItemAccountWhereUniqueInput>>;
  set?: Maybe<Array<PayrollPositionCompensationItemAccountWhereUniqueInput>>;
  update?: Maybe<Array<PayrollPositionCompensationItemAccountUpdateWithWhereUniqueWithoutCompensationItemInput>>;
  updateMany?: Maybe<Array<PayrollPositionCompensationItemAccountUpdateManyWithWhereWithoutCompensationItemInput>>;
  upsert?: Maybe<Array<PayrollPositionCompensationItemAccountUpsertWithWhereUniqueWithoutCompensationItemInput>>;
};

export type PayrollPositionCompensationItemAccountUpdateManyWithoutPositionInput = {
  connect?: Maybe<Array<PayrollPositionCompensationItemAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationItemAccountCreateOrConnectWithoutPositionInput>>;
  create?: Maybe<Array<PayrollPositionCompensationItemAccountCreateWithoutPositionInput>>;
  createMany?: Maybe<PayrollPositionCompensationItemAccountCreateManyPositionInputEnvelope>;
  delete?: Maybe<Array<PayrollPositionCompensationItemAccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollPositionCompensationItemAccountScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollPositionCompensationItemAccountWhereUniqueInput>>;
  set?: Maybe<Array<PayrollPositionCompensationItemAccountWhereUniqueInput>>;
  update?: Maybe<Array<PayrollPositionCompensationItemAccountUpdateWithWhereUniqueWithoutPositionInput>>;
  updateMany?: Maybe<Array<PayrollPositionCompensationItemAccountUpdateManyWithWhereWithoutPositionInput>>;
  upsert?: Maybe<Array<PayrollPositionCompensationItemAccountUpsertWithWhereUniqueWithoutPositionInput>>;
};

export type PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<PayrollPositionCompensationItemAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationItemAccountCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PayrollPositionCompensationItemAccountCreateWithoutPropertyInput>>;
  createMany?: Maybe<PayrollPositionCompensationItemAccountCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<PayrollPositionCompensationItemAccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollPositionCompensationItemAccountScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollPositionCompensationItemAccountWhereUniqueInput>>;
  set?: Maybe<Array<PayrollPositionCompensationItemAccountWhereUniqueInput>>;
  update?: Maybe<Array<PayrollPositionCompensationItemAccountUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<PayrollPositionCompensationItemAccountUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<PayrollPositionCompensationItemAccountUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type PayrollPositionCompensationItemAccountUpdateWithWhereUniqueWithoutAccountInput = {
  data: PayrollPositionCompensationItemAccountUpdateWithoutAccountInput;
  where: PayrollPositionCompensationItemAccountWhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountUpdateWithWhereUniqueWithoutCompensationItemInput = {
  data: PayrollPositionCompensationItemAccountUpdateWithoutCompensationItemInput;
  where: PayrollPositionCompensationItemAccountWhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountUpdateWithWhereUniqueWithoutPositionInput = {
  data: PayrollPositionCompensationItemAccountUpdateWithoutPositionInput;
  where: PayrollPositionCompensationItemAccountWhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountUpdateWithWhereUniqueWithoutPropertyInput = {
  data: PayrollPositionCompensationItemAccountUpdateWithoutPropertyInput;
  where: PayrollPositionCompensationItemAccountWhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountUpdateWithoutAccountInput = {
  compensationItem?: Maybe<PayrollCompensationItemUpdateOneRequiredWithoutCompensationItemPositionAccountsInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutCompensationItemPositionAccountsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutPayrollPositionCompensationItemAccountsInput>;
  unused?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type PayrollPositionCompensationItemAccountUpdateWithoutCompensationItemInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutPayrollPositionCompensationItemAccountInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutCompensationItemPositionAccountsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutPayrollPositionCompensationItemAccountsInput>;
  unused?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type PayrollPositionCompensationItemAccountUpdateWithoutPositionInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutPayrollPositionCompensationItemAccountInput>;
  compensationItem?: Maybe<PayrollCompensationItemUpdateOneRequiredWithoutCompensationItemPositionAccountsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutPayrollPositionCompensationItemAccountsInput>;
  unused?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type PayrollPositionCompensationItemAccountUpdateWithoutPropertyInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutPayrollPositionCompensationItemAccountInput>;
  compensationItem?: Maybe<PayrollCompensationItemUpdateOneRequiredWithoutCompensationItemPositionAccountsInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutCompensationItemPositionAccountsInput>;
  unused?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type PayrollPositionCompensationItemAccountUpsertWithWhereUniqueWithoutAccountInput = {
  create: PayrollPositionCompensationItemAccountCreateWithoutAccountInput;
  update: PayrollPositionCompensationItemAccountUpdateWithoutAccountInput;
  where: PayrollPositionCompensationItemAccountWhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountUpsertWithWhereUniqueWithoutCompensationItemInput = {
  create: PayrollPositionCompensationItemAccountCreateWithoutCompensationItemInput;
  update: PayrollPositionCompensationItemAccountUpdateWithoutCompensationItemInput;
  where: PayrollPositionCompensationItemAccountWhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountUpsertWithWhereUniqueWithoutPositionInput = {
  create: PayrollPositionCompensationItemAccountCreateWithoutPositionInput;
  update: PayrollPositionCompensationItemAccountUpdateWithoutPositionInput;
  where: PayrollPositionCompensationItemAccountWhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountUpsertWithWhereUniqueWithoutPropertyInput = {
  create: PayrollPositionCompensationItemAccountCreateWithoutPropertyInput;
  update: PayrollPositionCompensationItemAccountUpdateWithoutPropertyInput;
  where: PayrollPositionCompensationItemAccountWhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountV2 = {
  __typename?: 'PayrollPositionCompensationItemAccountV2';
  accountId: Scalars['String'];
  budgetYear: Scalars['Int'];
  compensationItemId: Scalars['String'];
  id: Scalars['String'];
  positionId: Scalars['String'];
  propertyId: Scalars['String'];
};

export type PayrollPositionCompensationItemAccountV2CreateManyAccountInput = {
  budgetYear: Scalars['Int'];
  compensationItemId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  positionId: Scalars['String'];
  propertyId: Scalars['String'];
};

export type PayrollPositionCompensationItemAccountV2CreateManyAccountInputEnvelope = {
  data: Array<PayrollPositionCompensationItemAccountV2CreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollPositionCompensationItemAccountV2CreateManyCompensationItemInput = {
  accountId: Scalars['String'];
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  positionId: Scalars['String'];
  propertyId: Scalars['String'];
};

export type PayrollPositionCompensationItemAccountV2CreateManyCompensationItemInputEnvelope = {
  data: Array<PayrollPositionCompensationItemAccountV2CreateManyCompensationItemInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollPositionCompensationItemAccountV2CreateManyPositionInput = {
  accountId: Scalars['String'];
  budgetYear: Scalars['Int'];
  compensationItemId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
};

export type PayrollPositionCompensationItemAccountV2CreateManyPositionInputEnvelope = {
  data: Array<PayrollPositionCompensationItemAccountV2CreateManyPositionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollPositionCompensationItemAccountV2CreateManyPropertyInput = {
  accountId: Scalars['String'];
  budgetYear: Scalars['Int'];
  compensationItemId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  positionId: Scalars['String'];
};

export type PayrollPositionCompensationItemAccountV2CreateManyPropertyInputEnvelope = {
  data: Array<PayrollPositionCompensationItemAccountV2CreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationItemAccountV2CreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<PayrollPositionCompensationItemAccountV2CreateWithoutAccountInput>>;
  createMany?: Maybe<PayrollPositionCompensationItemAccountV2CreateManyAccountInputEnvelope>;
};

export type PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutCompensationItemInput = {
  connect?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationItemAccountV2CreateOrConnectWithoutCompensationItemInput>>;
  create?: Maybe<Array<PayrollPositionCompensationItemAccountV2CreateWithoutCompensationItemInput>>;
  createMany?: Maybe<PayrollPositionCompensationItemAccountV2CreateManyCompensationItemInputEnvelope>;
};

export type PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPositionInput = {
  connect?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationItemAccountV2CreateOrConnectWithoutPositionInput>>;
  create?: Maybe<Array<PayrollPositionCompensationItemAccountV2CreateWithoutPositionInput>>;
  createMany?: Maybe<PayrollPositionCompensationItemAccountV2CreateManyPositionInputEnvelope>;
};

export type PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationItemAccountV2CreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PayrollPositionCompensationItemAccountV2CreateWithoutPropertyInput>>;
  createMany?: Maybe<PayrollPositionCompensationItemAccountV2CreateManyPropertyInputEnvelope>;
};

export type PayrollPositionCompensationItemAccountV2CreateOrConnectWithoutAccountInput = {
  create: PayrollPositionCompensationItemAccountV2CreateWithoutAccountInput;
  where: PayrollPositionCompensationItemAccountV2WhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountV2CreateOrConnectWithoutCompensationItemInput = {
  create: PayrollPositionCompensationItemAccountV2CreateWithoutCompensationItemInput;
  where: PayrollPositionCompensationItemAccountV2WhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountV2CreateOrConnectWithoutPositionInput = {
  create: PayrollPositionCompensationItemAccountV2CreateWithoutPositionInput;
  where: PayrollPositionCompensationItemAccountV2WhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountV2CreateOrConnectWithoutPropertyInput = {
  create: PayrollPositionCompensationItemAccountV2CreateWithoutPropertyInput;
  where: PayrollPositionCompensationItemAccountV2WhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountV2CreateWithoutAccountInput = {
  budgetYear: Scalars['Int'];
  compensationItem: PayrollCompensationItemCreateNestedOneWithoutCompensationItemPositionAccountsV2Input;
  id?: Maybe<Scalars['String']>;
  position: PayrollPositionCreateNestedOneWithoutCompensationItemPositionAccountsV2Input;
  property: PropertyCreateNestedOneWithoutPayrollPositionCompensationItemAccountsV2Input;
};

export type PayrollPositionCompensationItemAccountV2CreateWithoutCompensationItemInput = {
  account: AccountV2CreateNestedOneWithoutPayrollPositionCompensationItemAccountV2Input;
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  position: PayrollPositionCreateNestedOneWithoutCompensationItemPositionAccountsV2Input;
  property: PropertyCreateNestedOneWithoutPayrollPositionCompensationItemAccountsV2Input;
};

export type PayrollPositionCompensationItemAccountV2CreateWithoutPositionInput = {
  account: AccountV2CreateNestedOneWithoutPayrollPositionCompensationItemAccountV2Input;
  budgetYear: Scalars['Int'];
  compensationItem: PayrollCompensationItemCreateNestedOneWithoutCompensationItemPositionAccountsV2Input;
  id?: Maybe<Scalars['String']>;
  property: PropertyCreateNestedOneWithoutPayrollPositionCompensationItemAccountsV2Input;
};

export type PayrollPositionCompensationItemAccountV2CreateWithoutPropertyInput = {
  account: AccountV2CreateNestedOneWithoutPayrollPositionCompensationItemAccountV2Input;
  budgetYear: Scalars['Int'];
  compensationItem: PayrollCompensationItemCreateNestedOneWithoutCompensationItemPositionAccountsV2Input;
  id?: Maybe<Scalars['String']>;
  position: PayrollPositionCreateNestedOneWithoutCompensationItemPositionAccountsV2Input;
};

export type PayrollPositionCompensationItemAccountV2ListRelationFilter = {
  every?: Maybe<PayrollPositionCompensationItemAccountV2WhereInput>;
  none?: Maybe<PayrollPositionCompensationItemAccountV2WhereInput>;
  some?: Maybe<PayrollPositionCompensationItemAccountV2WhereInput>;
};

export type PayrollPositionCompensationItemAccountV2OrderByInput = {
  accountId?: Maybe<SortOrder>;
  budgetYear?: Maybe<SortOrder>;
  compensationItemId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  positionId?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
};

export enum PayrollPositionCompensationItemAccountV2ScalarFieldEnum {
  AccountId = 'accountId',
  BudgetYear = 'budgetYear',
  CompensationItemId = 'compensationItemId',
  Id = 'id',
  PositionId = 'positionId',
  PropertyId = 'propertyId'
}

export type PayrollPositionCompensationItemAccountV2ScalarWhereInput = {
  AND?: Maybe<Array<PayrollPositionCompensationItemAccountV2ScalarWhereInput>>;
  NOT?: Maybe<Array<PayrollPositionCompensationItemAccountV2ScalarWhereInput>>;
  OR?: Maybe<Array<PayrollPositionCompensationItemAccountV2ScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  budgetYear?: Maybe<IntFilter>;
  compensationItemId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  positionId?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
};

export type PayrollPositionCompensationItemAccountV2UpdateManyMutationInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PayrollPositionCompensationItemAccountV2UpdateManyWithWhereWithoutAccountInput = {
  data: PayrollPositionCompensationItemAccountV2UpdateManyMutationInput;
  where: PayrollPositionCompensationItemAccountV2ScalarWhereInput;
};

export type PayrollPositionCompensationItemAccountV2UpdateManyWithWhereWithoutCompensationItemInput = {
  data: PayrollPositionCompensationItemAccountV2UpdateManyMutationInput;
  where: PayrollPositionCompensationItemAccountV2ScalarWhereInput;
};

export type PayrollPositionCompensationItemAccountV2UpdateManyWithWhereWithoutPositionInput = {
  data: PayrollPositionCompensationItemAccountV2UpdateManyMutationInput;
  where: PayrollPositionCompensationItemAccountV2ScalarWhereInput;
};

export type PayrollPositionCompensationItemAccountV2UpdateManyWithWhereWithoutPropertyInput = {
  data: PayrollPositionCompensationItemAccountV2UpdateManyMutationInput;
  where: PayrollPositionCompensationItemAccountV2ScalarWhereInput;
};

export type PayrollPositionCompensationItemAccountV2UpdateManyWithoutAccountInput = {
  connect?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationItemAccountV2CreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<PayrollPositionCompensationItemAccountV2CreateWithoutAccountInput>>;
  createMany?: Maybe<PayrollPositionCompensationItemAccountV2CreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollPositionCompensationItemAccountV2ScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereUniqueInput>>;
  set?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereUniqueInput>>;
  update?: Maybe<Array<PayrollPositionCompensationItemAccountV2UpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<PayrollPositionCompensationItemAccountV2UpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<PayrollPositionCompensationItemAccountV2UpsertWithWhereUniqueWithoutAccountInput>>;
};

export type PayrollPositionCompensationItemAccountV2UpdateManyWithoutCompensationItemInput = {
  connect?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationItemAccountV2CreateOrConnectWithoutCompensationItemInput>>;
  create?: Maybe<Array<PayrollPositionCompensationItemAccountV2CreateWithoutCompensationItemInput>>;
  createMany?: Maybe<PayrollPositionCompensationItemAccountV2CreateManyCompensationItemInputEnvelope>;
  delete?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollPositionCompensationItemAccountV2ScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereUniqueInput>>;
  set?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereUniqueInput>>;
  update?: Maybe<Array<PayrollPositionCompensationItemAccountV2UpdateWithWhereUniqueWithoutCompensationItemInput>>;
  updateMany?: Maybe<Array<PayrollPositionCompensationItemAccountV2UpdateManyWithWhereWithoutCompensationItemInput>>;
  upsert?: Maybe<Array<PayrollPositionCompensationItemAccountV2UpsertWithWhereUniqueWithoutCompensationItemInput>>;
};

export type PayrollPositionCompensationItemAccountV2UpdateManyWithoutPositionInput = {
  connect?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationItemAccountV2CreateOrConnectWithoutPositionInput>>;
  create?: Maybe<Array<PayrollPositionCompensationItemAccountV2CreateWithoutPositionInput>>;
  createMany?: Maybe<PayrollPositionCompensationItemAccountV2CreateManyPositionInputEnvelope>;
  delete?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollPositionCompensationItemAccountV2ScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereUniqueInput>>;
  set?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereUniqueInput>>;
  update?: Maybe<Array<PayrollPositionCompensationItemAccountV2UpdateWithWhereUniqueWithoutPositionInput>>;
  updateMany?: Maybe<Array<PayrollPositionCompensationItemAccountV2UpdateManyWithWhereWithoutPositionInput>>;
  upsert?: Maybe<Array<PayrollPositionCompensationItemAccountV2UpsertWithWhereUniqueWithoutPositionInput>>;
};

export type PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCompensationItemAccountV2CreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PayrollPositionCompensationItemAccountV2CreateWithoutPropertyInput>>;
  createMany?: Maybe<PayrollPositionCompensationItemAccountV2CreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollPositionCompensationItemAccountV2ScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereUniqueInput>>;
  set?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereUniqueInput>>;
  update?: Maybe<Array<PayrollPositionCompensationItemAccountV2UpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<PayrollPositionCompensationItemAccountV2UpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<PayrollPositionCompensationItemAccountV2UpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type PayrollPositionCompensationItemAccountV2UpdateWithWhereUniqueWithoutAccountInput = {
  data: PayrollPositionCompensationItemAccountV2UpdateWithoutAccountInput;
  where: PayrollPositionCompensationItemAccountV2WhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountV2UpdateWithWhereUniqueWithoutCompensationItemInput = {
  data: PayrollPositionCompensationItemAccountV2UpdateWithoutCompensationItemInput;
  where: PayrollPositionCompensationItemAccountV2WhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountV2UpdateWithWhereUniqueWithoutPositionInput = {
  data: PayrollPositionCompensationItemAccountV2UpdateWithoutPositionInput;
  where: PayrollPositionCompensationItemAccountV2WhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountV2UpdateWithWhereUniqueWithoutPropertyInput = {
  data: PayrollPositionCompensationItemAccountV2UpdateWithoutPropertyInput;
  where: PayrollPositionCompensationItemAccountV2WhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountV2UpdateWithoutAccountInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  compensationItem?: Maybe<PayrollCompensationItemUpdateOneRequiredWithoutCompensationItemPositionAccountsV2Input>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutCompensationItemPositionAccountsV2Input>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutPayrollPositionCompensationItemAccountsV2Input>;
};

export type PayrollPositionCompensationItemAccountV2UpdateWithoutCompensationItemInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutPayrollPositionCompensationItemAccountV2Input>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutCompensationItemPositionAccountsV2Input>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutPayrollPositionCompensationItemAccountsV2Input>;
};

export type PayrollPositionCompensationItemAccountV2UpdateWithoutPositionInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutPayrollPositionCompensationItemAccountV2Input>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  compensationItem?: Maybe<PayrollCompensationItemUpdateOneRequiredWithoutCompensationItemPositionAccountsV2Input>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutPayrollPositionCompensationItemAccountsV2Input>;
};

export type PayrollPositionCompensationItemAccountV2UpdateWithoutPropertyInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutPayrollPositionCompensationItemAccountV2Input>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  compensationItem?: Maybe<PayrollCompensationItemUpdateOneRequiredWithoutCompensationItemPositionAccountsV2Input>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  position?: Maybe<PayrollPositionUpdateOneRequiredWithoutCompensationItemPositionAccountsV2Input>;
};

export type PayrollPositionCompensationItemAccountV2UpsertWithWhereUniqueWithoutAccountInput = {
  create: PayrollPositionCompensationItemAccountV2CreateWithoutAccountInput;
  update: PayrollPositionCompensationItemAccountV2UpdateWithoutAccountInput;
  where: PayrollPositionCompensationItemAccountV2WhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountV2UpsertWithWhereUniqueWithoutCompensationItemInput = {
  create: PayrollPositionCompensationItemAccountV2CreateWithoutCompensationItemInput;
  update: PayrollPositionCompensationItemAccountV2UpdateWithoutCompensationItemInput;
  where: PayrollPositionCompensationItemAccountV2WhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountV2UpsertWithWhereUniqueWithoutPositionInput = {
  create: PayrollPositionCompensationItemAccountV2CreateWithoutPositionInput;
  update: PayrollPositionCompensationItemAccountV2UpdateWithoutPositionInput;
  where: PayrollPositionCompensationItemAccountV2WhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountV2UpsertWithWhereUniqueWithoutPropertyInput = {
  create: PayrollPositionCompensationItemAccountV2CreateWithoutPropertyInput;
  update: PayrollPositionCompensationItemAccountV2UpdateWithoutPropertyInput;
  where: PayrollPositionCompensationItemAccountV2WhereUniqueInput;
};

export type PayrollPositionCompensationItemAccountV2WhereInput = {
  AND?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereInput>>;
  NOT?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereInput>>;
  OR?: Maybe<Array<PayrollPositionCompensationItemAccountV2WhereInput>>;
  account?: Maybe<AccountV2RelationFilter>;
  accountId?: Maybe<StringFilter>;
  budgetYear?: Maybe<IntFilter>;
  compensationItem?: Maybe<PayrollCompensationItemRelationFilter>;
  compensationItemId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  position?: Maybe<PayrollPositionRelationFilter>;
  positionId?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
};

export type PayrollPositionCompensationItemAccountV2WhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PayrollPositionCompensationItemAccountWhereInput = {
  AND?: Maybe<Array<PayrollPositionCompensationItemAccountWhereInput>>;
  NOT?: Maybe<Array<PayrollPositionCompensationItemAccountWhereInput>>;
  OR?: Maybe<Array<PayrollPositionCompensationItemAccountWhereInput>>;
  account?: Maybe<AccountV2RelationFilter>;
  accountId?: Maybe<StringFilter>;
  compensationItem?: Maybe<PayrollCompensationItemRelationFilter>;
  compensationItemId?: Maybe<StringFilter>;
  position?: Maybe<PayrollPositionRelationFilter>;
  positionId?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  unused?: Maybe<IntNullableFilter>;
};

export type PayrollPositionCompensationItemAccountWhereUniqueInput = {
  positionId_propertyId_compensationItemId?: Maybe<PayrollPositionCompensationItemAccountPositionIdPropertyIdCompensationItemIdCompoundUniqueInput>;
};

export type PayrollPositionCreateManyClientInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type PayrollPositionCreateManyClientInputEnvelope = {
  data: Array<PayrollPositionCreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PayrollPositionCreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<PayrollPositionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<PayrollPositionCreateWithoutClientInput>>;
  createMany?: Maybe<PayrollPositionCreateManyClientInputEnvelope>;
};

export type PayrollPositionCreateNestedOneWithoutCompensationItemPositionAccountsInput = {
  connect?: Maybe<PayrollPositionWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollPositionCreateOrConnectWithoutCompensationItemPositionAccountsInput>;
  create?: Maybe<PayrollPositionCreateWithoutCompensationItemPositionAccountsInput>;
};

export type PayrollPositionCreateNestedOneWithoutCompensationItemPositionAccountsV2Input = {
  connect?: Maybe<PayrollPositionWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollPositionCreateOrConnectWithoutCompensationItemPositionAccountsV2Input>;
  create?: Maybe<PayrollPositionCreateWithoutCompensationItemPositionAccountsV2Input>;
};

export type PayrollPositionCreateNestedOneWithoutDefaultBaseValuesInput = {
  connect?: Maybe<PayrollPositionWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollPositionCreateOrConnectWithoutDefaultBaseValuesInput>;
  create?: Maybe<PayrollPositionCreateWithoutDefaultBaseValuesInput>;
};

export type PayrollPositionCreateNestedOneWithoutDefaultBonusValuesInput = {
  connect?: Maybe<PayrollPositionWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollPositionCreateOrConnectWithoutDefaultBonusValuesInput>;
  create?: Maybe<PayrollPositionCreateWithoutDefaultBonusValuesInput>;
};

export type PayrollPositionCreateNestedOneWithoutDefaultCompensationItemValuesInput = {
  connect?: Maybe<PayrollPositionWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollPositionCreateOrConnectWithoutDefaultCompensationItemValuesInput>;
  create?: Maybe<PayrollPositionCreateWithoutDefaultCompensationItemValuesInput>;
};

export type PayrollPositionCreateNestedOneWithoutDefaultOvertimeValuesInput = {
  connect?: Maybe<PayrollPositionWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollPositionCreateOrConnectWithoutDefaultOvertimeValuesInput>;
  create?: Maybe<PayrollPositionCreateWithoutDefaultOvertimeValuesInput>;
};

export type PayrollPositionCreateNestedOneWithoutDefaultRaiseValuesInput = {
  connect?: Maybe<PayrollPositionWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollPositionCreateOrConnectWithoutDefaultRaiseValuesInput>;
  create?: Maybe<PayrollPositionCreateWithoutDefaultRaiseValuesInput>;
};

export type PayrollPositionCreateNestedOneWithoutEmployeesInput = {
  connect?: Maybe<PayrollPositionWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollPositionCreateOrConnectWithoutEmployeesInput>;
  create?: Maybe<PayrollPositionCreateWithoutEmployeesInput>;
};

export type PayrollPositionCreateNestedOneWithoutPositionCompensationAccountsInput = {
  connect?: Maybe<PayrollPositionWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollPositionCreateOrConnectWithoutPositionCompensationAccountsInput>;
  create?: Maybe<PayrollPositionCreateWithoutPositionCompensationAccountsInput>;
};

export type PayrollPositionCreateNestedOneWithoutPositionCompensationAccountsV2Input = {
  connect?: Maybe<PayrollPositionWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollPositionCreateOrConnectWithoutPositionCompensationAccountsV2Input>;
  create?: Maybe<PayrollPositionCreateWithoutPositionCompensationAccountsV2Input>;
};

export type PayrollPositionCreateOrConnectWithoutClientInput = {
  create: PayrollPositionCreateWithoutClientInput;
  where: PayrollPositionWhereUniqueInput;
};

export type PayrollPositionCreateOrConnectWithoutCompensationItemPositionAccountsInput = {
  create: PayrollPositionCreateWithoutCompensationItemPositionAccountsInput;
  where: PayrollPositionWhereUniqueInput;
};

export type PayrollPositionCreateOrConnectWithoutCompensationItemPositionAccountsV2Input = {
  create: PayrollPositionCreateWithoutCompensationItemPositionAccountsV2Input;
  where: PayrollPositionWhereUniqueInput;
};

export type PayrollPositionCreateOrConnectWithoutDefaultBaseValuesInput = {
  create: PayrollPositionCreateWithoutDefaultBaseValuesInput;
  where: PayrollPositionWhereUniqueInput;
};

export type PayrollPositionCreateOrConnectWithoutDefaultBonusValuesInput = {
  create: PayrollPositionCreateWithoutDefaultBonusValuesInput;
  where: PayrollPositionWhereUniqueInput;
};

export type PayrollPositionCreateOrConnectWithoutDefaultCompensationItemValuesInput = {
  create: PayrollPositionCreateWithoutDefaultCompensationItemValuesInput;
  where: PayrollPositionWhereUniqueInput;
};

export type PayrollPositionCreateOrConnectWithoutDefaultOvertimeValuesInput = {
  create: PayrollPositionCreateWithoutDefaultOvertimeValuesInput;
  where: PayrollPositionWhereUniqueInput;
};

export type PayrollPositionCreateOrConnectWithoutDefaultRaiseValuesInput = {
  create: PayrollPositionCreateWithoutDefaultRaiseValuesInput;
  where: PayrollPositionWhereUniqueInput;
};

export type PayrollPositionCreateOrConnectWithoutEmployeesInput = {
  create: PayrollPositionCreateWithoutEmployeesInput;
  where: PayrollPositionWhereUniqueInput;
};

export type PayrollPositionCreateOrConnectWithoutPositionCompensationAccountsInput = {
  create: PayrollPositionCreateWithoutPositionCompensationAccountsInput;
  where: PayrollPositionWhereUniqueInput;
};

export type PayrollPositionCreateOrConnectWithoutPositionCompensationAccountsV2Input = {
  create: PayrollPositionCreateWithoutPositionCompensationAccountsV2Input;
  where: PayrollPositionWhereUniqueInput;
};

export type PayrollPositionCreateWithoutClientInput = {
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPositionInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPositionInput>;
  defaultBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPositionInput>;
  defaultBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPositionInput>;
  defaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPositionInput>;
  defaultOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPositionInput>;
  defaultRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPositionInput>;
  employees?: Maybe<PayrollEmployeeCreateNestedManyWithoutPositionInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  positionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPositionInput>;
  positionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPositionInput>;
};

export type PayrollPositionCreateWithoutCompensationItemPositionAccountsInput = {
  client: ClientCreateNestedOneWithoutPayrollPositionsInput;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPositionInput>;
  defaultBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPositionInput>;
  defaultBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPositionInput>;
  defaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPositionInput>;
  defaultOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPositionInput>;
  defaultRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPositionInput>;
  employees?: Maybe<PayrollEmployeeCreateNestedManyWithoutPositionInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  positionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPositionInput>;
  positionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPositionInput>;
};

export type PayrollPositionCreateWithoutCompensationItemPositionAccountsV2Input = {
  client: ClientCreateNestedOneWithoutPayrollPositionsInput;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPositionInput>;
  defaultBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPositionInput>;
  defaultBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPositionInput>;
  defaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPositionInput>;
  defaultOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPositionInput>;
  defaultRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPositionInput>;
  employees?: Maybe<PayrollEmployeeCreateNestedManyWithoutPositionInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  positionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPositionInput>;
  positionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPositionInput>;
};

export type PayrollPositionCreateWithoutDefaultBaseValuesInput = {
  client: ClientCreateNestedOneWithoutPayrollPositionsInput;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPositionInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPositionInput>;
  defaultBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPositionInput>;
  defaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPositionInput>;
  defaultOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPositionInput>;
  defaultRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPositionInput>;
  employees?: Maybe<PayrollEmployeeCreateNestedManyWithoutPositionInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  positionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPositionInput>;
  positionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPositionInput>;
};

export type PayrollPositionCreateWithoutDefaultBonusValuesInput = {
  client: ClientCreateNestedOneWithoutPayrollPositionsInput;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPositionInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPositionInput>;
  defaultBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPositionInput>;
  defaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPositionInput>;
  defaultOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPositionInput>;
  defaultRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPositionInput>;
  employees?: Maybe<PayrollEmployeeCreateNestedManyWithoutPositionInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  positionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPositionInput>;
  positionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPositionInput>;
};

export type PayrollPositionCreateWithoutDefaultCompensationItemValuesInput = {
  client: ClientCreateNestedOneWithoutPayrollPositionsInput;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPositionInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPositionInput>;
  defaultBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPositionInput>;
  defaultBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPositionInput>;
  defaultOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPositionInput>;
  defaultRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPositionInput>;
  employees?: Maybe<PayrollEmployeeCreateNestedManyWithoutPositionInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  positionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPositionInput>;
  positionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPositionInput>;
};

export type PayrollPositionCreateWithoutDefaultOvertimeValuesInput = {
  client: ClientCreateNestedOneWithoutPayrollPositionsInput;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPositionInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPositionInput>;
  defaultBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPositionInput>;
  defaultBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPositionInput>;
  defaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPositionInput>;
  defaultRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPositionInput>;
  employees?: Maybe<PayrollEmployeeCreateNestedManyWithoutPositionInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  positionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPositionInput>;
  positionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPositionInput>;
};

export type PayrollPositionCreateWithoutDefaultRaiseValuesInput = {
  client: ClientCreateNestedOneWithoutPayrollPositionsInput;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPositionInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPositionInput>;
  defaultBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPositionInput>;
  defaultBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPositionInput>;
  defaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPositionInput>;
  defaultOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPositionInput>;
  employees?: Maybe<PayrollEmployeeCreateNestedManyWithoutPositionInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  positionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPositionInput>;
  positionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPositionInput>;
};

export type PayrollPositionCreateWithoutEmployeesInput = {
  client: ClientCreateNestedOneWithoutPayrollPositionsInput;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPositionInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPositionInput>;
  defaultBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPositionInput>;
  defaultBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPositionInput>;
  defaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPositionInput>;
  defaultOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPositionInput>;
  defaultRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPositionInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  positionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPositionInput>;
  positionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPositionInput>;
};

export type PayrollPositionCreateWithoutPositionCompensationAccountsInput = {
  client: ClientCreateNestedOneWithoutPayrollPositionsInput;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPositionInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPositionInput>;
  defaultBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPositionInput>;
  defaultBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPositionInput>;
  defaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPositionInput>;
  defaultOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPositionInput>;
  defaultRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPositionInput>;
  employees?: Maybe<PayrollEmployeeCreateNestedManyWithoutPositionInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  positionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPositionInput>;
};

export type PayrollPositionCreateWithoutPositionCompensationAccountsV2Input = {
  client: ClientCreateNestedOneWithoutPayrollPositionsInput;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPositionInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPositionInput>;
  defaultBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPositionInput>;
  defaultBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPositionInput>;
  defaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPositionInput>;
  defaultOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPositionInput>;
  defaultRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPositionInput>;
  employees?: Maybe<PayrollEmployeeCreateNestedManyWithoutPositionInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  positionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPositionInput>;
};

export type PayrollPositionListRelationFilter = {
  every?: Maybe<PayrollPositionWhereInput>;
  none?: Maybe<PayrollPositionWhereInput>;
  some?: Maybe<PayrollPositionWhereInput>;
};

export type PayrollPositionModel = {
  __typename?: 'PayrollPositionModel';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PayrollPositionRelationFilter = {
  is?: Maybe<PayrollPositionWhereInput>;
  isNot?: Maybe<PayrollPositionWhereInput>;
};

export type PayrollPositionScalarWhereInput = {
  AND?: Maybe<Array<PayrollPositionScalarWhereInput>>;
  NOT?: Maybe<Array<PayrollPositionScalarWhereInput>>;
  OR?: Maybe<Array<PayrollPositionScalarWhereInput>>;
  clientId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
};

export type PayrollPositionUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PayrollPositionUpdateManyWithWhereWithoutClientInput = {
  data: PayrollPositionUpdateManyMutationInput;
  where: PayrollPositionScalarWhereInput;
};

export type PayrollPositionUpdateManyWithoutClientInput = {
  connect?: Maybe<Array<PayrollPositionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PayrollPositionCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<PayrollPositionCreateWithoutClientInput>>;
  createMany?: Maybe<PayrollPositionCreateManyClientInputEnvelope>;
  delete?: Maybe<Array<PayrollPositionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PayrollPositionScalarWhereInput>>;
  disconnect?: Maybe<Array<PayrollPositionWhereUniqueInput>>;
  set?: Maybe<Array<PayrollPositionWhereUniqueInput>>;
  update?: Maybe<Array<PayrollPositionUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<PayrollPositionUpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<PayrollPositionUpsertWithWhereUniqueWithoutClientInput>>;
};

export type PayrollPositionUpdateOneRequiredWithoutCompensationItemPositionAccountsInput = {
  connect?: Maybe<PayrollPositionWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollPositionCreateOrConnectWithoutCompensationItemPositionAccountsInput>;
  create?: Maybe<PayrollPositionCreateWithoutCompensationItemPositionAccountsInput>;
  update?: Maybe<PayrollPositionUpdateWithoutCompensationItemPositionAccountsInput>;
  upsert?: Maybe<PayrollPositionUpsertWithoutCompensationItemPositionAccountsInput>;
};

export type PayrollPositionUpdateOneRequiredWithoutCompensationItemPositionAccountsV2Input = {
  connect?: Maybe<PayrollPositionWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollPositionCreateOrConnectWithoutCompensationItemPositionAccountsV2Input>;
  create?: Maybe<PayrollPositionCreateWithoutCompensationItemPositionAccountsV2Input>;
  update?: Maybe<PayrollPositionUpdateWithoutCompensationItemPositionAccountsV2Input>;
  upsert?: Maybe<PayrollPositionUpsertWithoutCompensationItemPositionAccountsV2Input>;
};

export type PayrollPositionUpdateOneRequiredWithoutDefaultBaseValuesInput = {
  connect?: Maybe<PayrollPositionWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollPositionCreateOrConnectWithoutDefaultBaseValuesInput>;
  create?: Maybe<PayrollPositionCreateWithoutDefaultBaseValuesInput>;
  update?: Maybe<PayrollPositionUpdateWithoutDefaultBaseValuesInput>;
  upsert?: Maybe<PayrollPositionUpsertWithoutDefaultBaseValuesInput>;
};

export type PayrollPositionUpdateOneRequiredWithoutDefaultBonusValuesInput = {
  connect?: Maybe<PayrollPositionWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollPositionCreateOrConnectWithoutDefaultBonusValuesInput>;
  create?: Maybe<PayrollPositionCreateWithoutDefaultBonusValuesInput>;
  update?: Maybe<PayrollPositionUpdateWithoutDefaultBonusValuesInput>;
  upsert?: Maybe<PayrollPositionUpsertWithoutDefaultBonusValuesInput>;
};

export type PayrollPositionUpdateOneRequiredWithoutDefaultCompensationItemValuesInput = {
  connect?: Maybe<PayrollPositionWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollPositionCreateOrConnectWithoutDefaultCompensationItemValuesInput>;
  create?: Maybe<PayrollPositionCreateWithoutDefaultCompensationItemValuesInput>;
  update?: Maybe<PayrollPositionUpdateWithoutDefaultCompensationItemValuesInput>;
  upsert?: Maybe<PayrollPositionUpsertWithoutDefaultCompensationItemValuesInput>;
};

export type PayrollPositionUpdateOneRequiredWithoutDefaultOvertimeValuesInput = {
  connect?: Maybe<PayrollPositionWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollPositionCreateOrConnectWithoutDefaultOvertimeValuesInput>;
  create?: Maybe<PayrollPositionCreateWithoutDefaultOvertimeValuesInput>;
  update?: Maybe<PayrollPositionUpdateWithoutDefaultOvertimeValuesInput>;
  upsert?: Maybe<PayrollPositionUpsertWithoutDefaultOvertimeValuesInput>;
};

export type PayrollPositionUpdateOneRequiredWithoutDefaultRaiseValuesInput = {
  connect?: Maybe<PayrollPositionWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollPositionCreateOrConnectWithoutDefaultRaiseValuesInput>;
  create?: Maybe<PayrollPositionCreateWithoutDefaultRaiseValuesInput>;
  update?: Maybe<PayrollPositionUpdateWithoutDefaultRaiseValuesInput>;
  upsert?: Maybe<PayrollPositionUpsertWithoutDefaultRaiseValuesInput>;
};

export type PayrollPositionUpdateOneRequiredWithoutEmployeesInput = {
  connect?: Maybe<PayrollPositionWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollPositionCreateOrConnectWithoutEmployeesInput>;
  create?: Maybe<PayrollPositionCreateWithoutEmployeesInput>;
  update?: Maybe<PayrollPositionUpdateWithoutEmployeesInput>;
  upsert?: Maybe<PayrollPositionUpsertWithoutEmployeesInput>;
};

export type PayrollPositionUpdateOneRequiredWithoutPositionCompensationAccountsInput = {
  connect?: Maybe<PayrollPositionWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollPositionCreateOrConnectWithoutPositionCompensationAccountsInput>;
  create?: Maybe<PayrollPositionCreateWithoutPositionCompensationAccountsInput>;
  update?: Maybe<PayrollPositionUpdateWithoutPositionCompensationAccountsInput>;
  upsert?: Maybe<PayrollPositionUpsertWithoutPositionCompensationAccountsInput>;
};

export type PayrollPositionUpdateOneRequiredWithoutPositionCompensationAccountsV2Input = {
  connect?: Maybe<PayrollPositionWhereUniqueInput>;
  connectOrCreate?: Maybe<PayrollPositionCreateOrConnectWithoutPositionCompensationAccountsV2Input>;
  create?: Maybe<PayrollPositionCreateWithoutPositionCompensationAccountsV2Input>;
  update?: Maybe<PayrollPositionUpdateWithoutPositionCompensationAccountsV2Input>;
  upsert?: Maybe<PayrollPositionUpsertWithoutPositionCompensationAccountsV2Input>;
};

export type PayrollPositionUpdateWithWhereUniqueWithoutClientInput = {
  data: PayrollPositionUpdateWithoutClientInput;
  where: PayrollPositionWhereUniqueInput;
};

export type PayrollPositionUpdateWithoutClientInput = {
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPositionInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPositionInput>;
  defaultBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPositionInput>;
  defaultBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPositionInput>;
  defaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPositionInput>;
  defaultOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPositionInput>;
  defaultRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPositionInput>;
  employees?: Maybe<PayrollEmployeeUpdateManyWithoutPositionInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  positionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPositionInput>;
  positionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPositionInput>;
};

export type PayrollPositionUpdateWithoutCompensationItemPositionAccountsInput = {
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollPositionsInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPositionInput>;
  defaultBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPositionInput>;
  defaultBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPositionInput>;
  defaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPositionInput>;
  defaultOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPositionInput>;
  defaultRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPositionInput>;
  employees?: Maybe<PayrollEmployeeUpdateManyWithoutPositionInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  positionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPositionInput>;
  positionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPositionInput>;
};

export type PayrollPositionUpdateWithoutCompensationItemPositionAccountsV2Input = {
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollPositionsInput>;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPositionInput>;
  defaultBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPositionInput>;
  defaultBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPositionInput>;
  defaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPositionInput>;
  defaultOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPositionInput>;
  defaultRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPositionInput>;
  employees?: Maybe<PayrollEmployeeUpdateManyWithoutPositionInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  positionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPositionInput>;
  positionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPositionInput>;
};

export type PayrollPositionUpdateWithoutDefaultBaseValuesInput = {
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollPositionsInput>;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPositionInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPositionInput>;
  defaultBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPositionInput>;
  defaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPositionInput>;
  defaultOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPositionInput>;
  defaultRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPositionInput>;
  employees?: Maybe<PayrollEmployeeUpdateManyWithoutPositionInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  positionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPositionInput>;
  positionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPositionInput>;
};

export type PayrollPositionUpdateWithoutDefaultBonusValuesInput = {
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollPositionsInput>;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPositionInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPositionInput>;
  defaultBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPositionInput>;
  defaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPositionInput>;
  defaultOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPositionInput>;
  defaultRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPositionInput>;
  employees?: Maybe<PayrollEmployeeUpdateManyWithoutPositionInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  positionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPositionInput>;
  positionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPositionInput>;
};

export type PayrollPositionUpdateWithoutDefaultCompensationItemValuesInput = {
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollPositionsInput>;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPositionInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPositionInput>;
  defaultBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPositionInput>;
  defaultBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPositionInput>;
  defaultOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPositionInput>;
  defaultRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPositionInput>;
  employees?: Maybe<PayrollEmployeeUpdateManyWithoutPositionInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  positionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPositionInput>;
  positionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPositionInput>;
};

export type PayrollPositionUpdateWithoutDefaultOvertimeValuesInput = {
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollPositionsInput>;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPositionInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPositionInput>;
  defaultBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPositionInput>;
  defaultBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPositionInput>;
  defaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPositionInput>;
  defaultRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPositionInput>;
  employees?: Maybe<PayrollEmployeeUpdateManyWithoutPositionInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  positionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPositionInput>;
  positionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPositionInput>;
};

export type PayrollPositionUpdateWithoutDefaultRaiseValuesInput = {
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollPositionsInput>;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPositionInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPositionInput>;
  defaultBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPositionInput>;
  defaultBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPositionInput>;
  defaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPositionInput>;
  defaultOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPositionInput>;
  employees?: Maybe<PayrollEmployeeUpdateManyWithoutPositionInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  positionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPositionInput>;
  positionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPositionInput>;
};

export type PayrollPositionUpdateWithoutEmployeesInput = {
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollPositionsInput>;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPositionInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPositionInput>;
  defaultBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPositionInput>;
  defaultBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPositionInput>;
  defaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPositionInput>;
  defaultOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPositionInput>;
  defaultRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPositionInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  positionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPositionInput>;
  positionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPositionInput>;
};

export type PayrollPositionUpdateWithoutPositionCompensationAccountsInput = {
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollPositionsInput>;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPositionInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPositionInput>;
  defaultBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPositionInput>;
  defaultBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPositionInput>;
  defaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPositionInput>;
  defaultOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPositionInput>;
  defaultRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPositionInput>;
  employees?: Maybe<PayrollEmployeeUpdateManyWithoutPositionInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  positionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPositionInput>;
};

export type PayrollPositionUpdateWithoutPositionCompensationAccountsV2Input = {
  client?: Maybe<ClientUpdateOneRequiredWithoutPayrollPositionsInput>;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPositionInput>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPositionInput>;
  defaultBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPositionInput>;
  defaultBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPositionInput>;
  defaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPositionInput>;
  defaultOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPositionInput>;
  defaultRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPositionInput>;
  employees?: Maybe<PayrollEmployeeUpdateManyWithoutPositionInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  positionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPositionInput>;
};

export type PayrollPositionUpsertWithWhereUniqueWithoutClientInput = {
  create: PayrollPositionCreateWithoutClientInput;
  update: PayrollPositionUpdateWithoutClientInput;
  where: PayrollPositionWhereUniqueInput;
};

export type PayrollPositionUpsertWithoutCompensationItemPositionAccountsInput = {
  create: PayrollPositionCreateWithoutCompensationItemPositionAccountsInput;
  update: PayrollPositionUpdateWithoutCompensationItemPositionAccountsInput;
};

export type PayrollPositionUpsertWithoutCompensationItemPositionAccountsV2Input = {
  create: PayrollPositionCreateWithoutCompensationItemPositionAccountsV2Input;
  update: PayrollPositionUpdateWithoutCompensationItemPositionAccountsV2Input;
};

export type PayrollPositionUpsertWithoutDefaultBaseValuesInput = {
  create: PayrollPositionCreateWithoutDefaultBaseValuesInput;
  update: PayrollPositionUpdateWithoutDefaultBaseValuesInput;
};

export type PayrollPositionUpsertWithoutDefaultBonusValuesInput = {
  create: PayrollPositionCreateWithoutDefaultBonusValuesInput;
  update: PayrollPositionUpdateWithoutDefaultBonusValuesInput;
};

export type PayrollPositionUpsertWithoutDefaultCompensationItemValuesInput = {
  create: PayrollPositionCreateWithoutDefaultCompensationItemValuesInput;
  update: PayrollPositionUpdateWithoutDefaultCompensationItemValuesInput;
};

export type PayrollPositionUpsertWithoutDefaultOvertimeValuesInput = {
  create: PayrollPositionCreateWithoutDefaultOvertimeValuesInput;
  update: PayrollPositionUpdateWithoutDefaultOvertimeValuesInput;
};

export type PayrollPositionUpsertWithoutDefaultRaiseValuesInput = {
  create: PayrollPositionCreateWithoutDefaultRaiseValuesInput;
  update: PayrollPositionUpdateWithoutDefaultRaiseValuesInput;
};

export type PayrollPositionUpsertWithoutEmployeesInput = {
  create: PayrollPositionCreateWithoutEmployeesInput;
  update: PayrollPositionUpdateWithoutEmployeesInput;
};

export type PayrollPositionUpsertWithoutPositionCompensationAccountsInput = {
  create: PayrollPositionCreateWithoutPositionCompensationAccountsInput;
  update: PayrollPositionUpdateWithoutPositionCompensationAccountsInput;
};

export type PayrollPositionUpsertWithoutPositionCompensationAccountsV2Input = {
  create: PayrollPositionCreateWithoutPositionCompensationAccountsV2Input;
  update: PayrollPositionUpdateWithoutPositionCompensationAccountsV2Input;
};

export type PayrollPositionWhereInput = {
  AND?: Maybe<Array<PayrollPositionWhereInput>>;
  NOT?: Maybe<Array<PayrollPositionWhereInput>>;
  OR?: Maybe<Array<PayrollPositionWhereInput>>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  compensationItemPositionAccounts?: Maybe<PayrollPositionCompensationItemAccountListRelationFilter>;
  compensationItemPositionAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2ListRelationFilter>;
  defaultBaseValues?: Maybe<PayrollDefaultCompensationBaseListRelationFilter>;
  defaultBonusValues?: Maybe<PayrollDefaultCompensationBonusListRelationFilter>;
  defaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemListRelationFilter>;
  defaultOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeListRelationFilter>;
  defaultRaiseValues?: Maybe<PayrollDefaultCompensationRaiseListRelationFilter>;
  employees?: Maybe<PayrollEmployeeListRelationFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  positionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountListRelationFilter>;
  positionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2ListRelationFilter>;
};

export type PayrollPositionWhereUniqueInput = {
  clientId_name?: Maybe<PayrollPositionClientIdNameCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export enum PayrollPrimaryCompensationItem {
  Bonus = 'BONUS',
  Compensation = 'COMPENSATION',
  Overtime = 'OVERTIME'
}

export type PayrollPropertyEmployeeCountModel = {
  __typename?: 'PayrollPropertyEmployeeCountModel';
  employeeCount: Scalars['Int'];
  propertyId: Scalars['ID'];
  propertyName: Scalars['String'];
};

export enum PayrollRaiseFrequency {
  Annually = 'ANNUALLY',
  Once = 'ONCE'
}

export enum PayrollRaiseType {
  FixedAmount = 'FIXED_AMOUNT',
  PercentOfSalary = 'PERCENT_OF_SALARY'
}

export type PayrollUpdateEmployeeBaseCompInput = {
  /** This value represents the total annual compensation, and it is set when compensationType is Annual. */
  annualCompensation?: Maybe<Scalars['Decimal']>;
  compensationType?: Maybe<PayrollCompensationType>;
  /** Expected number of weekly hours for an hourly employee. It is set when the compensationType is hourly. */
  expectedHoursPerWeek?: Maybe<Scalars['Decimal']>;
  /** This value represents the hourly compensation, and it is set when compensationType is hourly. */
  hourlyCompensation?: Maybe<Scalars['Decimal']>;
};

export type PayrollUpdateEmployeeBonusInput = {
  amount?: Maybe<Scalars['Decimal']>;
  bonusDay?: Maybe<Scalars['Int']>;
  bonusMonth?: Maybe<Scalars['Int']>;
  bonusType?: Maybe<PayrollBonusType>;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency?: Maybe<PayrollBonusFrequency>;
  onetimeBonusDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollUpdateEmployeeCompItemInput = {
  annualCostCap?: Maybe<Scalars['Decimal']>;
  costPerPayPeriod?: Maybe<Scalars['Decimal']>;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollUpdateEmployeeOvertimeInput = {
  /** When set, indicates that this overtime will end on the given date. When unset, the overtime will continue forever. */
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  /** When the overtime will start. */
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  /** The number of hours of overtime expected to be worked at the given compensation rate. */
  expectedHoursPerWeek?: Maybe<Scalars['Decimal']>;
  /** Hourly compensation amount for overtime. */
  hourlyCompensation?: Maybe<Scalars['Decimal']>;
};

export type PayrollUpdateEmployeeRaiseInput = {
  amount?: Maybe<Scalars['Decimal']>;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  frequency?: Maybe<PayrollRaiseFrequency>;
  onetimeRaiseDate?: Maybe<Scalars['DateTime']>;
  raiseDay?: Maybe<Scalars['Int']>;
  raiseMonth?: Maybe<Scalars['Int']>;
  raiseType?: Maybe<PayrollRaiseType>;
};

export type PendingUpdates = {
  add: AddDrivers;
  remove: UpdateAndRemoveDrivers;
  update: UpdateAndRemoveDrivers;
};

export enum PercentGrowthBasePeriod {
  BudgetSameMonthCurrentYear = 'BUDGET_SAME_MONTH_CURRENT_YEAR',
  CurrentYearBudget = 'CURRENT_YEAR_BUDGET',
  LastYearActuals = 'LAST_YEAR_ACTUALS',
  LastYearBudget = 'LAST_YEAR_BUDGET',
  SameMonthLastYear = 'SAME_MONTH_LAST_YEAR',
  TwoYearsAgoActuals = 'TWO_YEARS_AGO_ACTUALS'
}

export type PerformanceMetricModel = {
  __typename?: 'PerformanceMetricModel';
  monthly_values: Array<Scalars['Decimal']>;
  type: Scalars['String'];
  year: Scalars['Int'];
  year_total: Scalars['Decimal'];
};

export type PermissionCreateNestedOneWithoutUsersInput = {
  connect?: Maybe<PermissionWhereUniqueInput>;
  connectOrCreate?: Maybe<PermissionCreateOrConnectWithoutUsersInput>;
  create?: Maybe<PermissionCreateWithoutUsersInput>;
};

export type PermissionCreateOrConnectWithoutUsersInput = {
  create: PermissionCreateWithoutUsersInput;
  where: PermissionWhereUniqueInput;
};

export type PermissionCreateWithoutUsersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  entityId: Scalars['String'];
  entityType: EntityType;
  id?: Maybe<Scalars['String']>;
  operationType: OperationType;
  updateAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionEntityIdOperationTypeCompoundUniqueInput = {
  entityId: Scalars['String'];
  operationType: OperationType;
};

export type PermissionRelationFilter = {
  is?: Maybe<PermissionWhereInput>;
  isNot?: Maybe<PermissionWhereInput>;
};

export type PermissionUpdateOneRequiredWithoutUsersInput = {
  connect?: Maybe<PermissionWhereUniqueInput>;
  connectOrCreate?: Maybe<PermissionCreateOrConnectWithoutUsersInput>;
  create?: Maybe<PermissionCreateWithoutUsersInput>;
  update?: Maybe<PermissionUpdateWithoutUsersInput>;
  upsert?: Maybe<PermissionUpsertWithoutUsersInput>;
};

export type PermissionUpdateWithoutUsersInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  entityId?: Maybe<StringFieldUpdateOperationsInput>;
  entityType?: Maybe<EnumEntityTypeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  operationType?: Maybe<EnumOperationTypeFieldUpdateOperationsInput>;
  updateAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PermissionUpsertWithoutUsersInput = {
  create: PermissionCreateWithoutUsersInput;
  update: PermissionUpdateWithoutUsersInput;
};

export type PermissionWhereInput = {
  AND?: Maybe<Array<PermissionWhereInput>>;
  NOT?: Maybe<Array<PermissionWhereInput>>;
  OR?: Maybe<Array<PermissionWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  entityId?: Maybe<StringFilter>;
  entityType?: Maybe<EnumEntityTypeFilter>;
  id?: Maybe<StringFilter>;
  operationType?: Maybe<EnumOperationTypeFilter>;
  updateAt?: Maybe<DateTimeFilter>;
  users?: Maybe<UserPermissionListRelationFilter>;
};

export type PermissionWhereUniqueInput = {
  entityId_operationType?: Maybe<PermissionEntityIdOperationTypeCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type PersonEmailInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type PortfolioCalcSnapshotAccountPropertiesDataModel = {
  __typename?: 'PortfolioCalcSnapshotAccountPropertiesDataModel';
  accountId: Scalars['String'];
  propertiesValues: Array<PortfolioCalcSnapshotValuesRow>;
};

export type PortfolioCalcSnapshotDataModel = {
  __typename?: 'PortfolioCalcSnapshotDataModel';
  accountValues: Array<PortfolioCalcSnapshotValuesRow>;
  propertiesData: Array<PortfolioCalcSnapshotPropertyData>;
};

export type PortfolioCalcSnapshotModel = {
  __typename?: 'PortfolioCalcSnapshotModel';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
};

export type PortfolioCalcSnapshotPropertyData = {
  __typename?: 'PortfolioCalcSnapshotPropertyData';
  id: Scalars['String'];
  reforecastStartMonthIndex: Scalars['Int'];
};

export type PortfolioCalcSnapshotValuesRow = {
  __typename?: 'PortfolioCalcSnapshotValuesRow';
  id: Scalars['String'];
  values: Array<Scalars['Float']>;
};

export type PowerBiConfigCreateManyVizibluClientInput = {
  datasetId?: Maybe<Scalars['String']>;
  reportId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type PowerBiConfigCreateManyVizibluClientInputEnvelope = {
  data: Array<PowerBiConfigCreateManyVizibluClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PowerBiConfigCreateNestedManyWithoutVizibluClientInput = {
  connect?: Maybe<Array<PowerBiConfigWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PowerBiConfigCreateOrConnectWithoutVizibluClientInput>>;
  create?: Maybe<Array<PowerBiConfigCreateWithoutVizibluClientInput>>;
  createMany?: Maybe<PowerBiConfigCreateManyVizibluClientInputEnvelope>;
};

export type PowerBiConfigCreateOrConnectWithoutVizibluClientInput = {
  create: PowerBiConfigCreateWithoutVizibluClientInput;
  where: PowerBiConfigWhereUniqueInput;
};

export type PowerBiConfigCreateWithoutVizibluClientInput = {
  datasetId?: Maybe<Scalars['String']>;
  reportId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type PowerBiConfigListRelationFilter = {
  every?: Maybe<PowerBiConfigWhereInput>;
  none?: Maybe<PowerBiConfigWhereInput>;
  some?: Maybe<PowerBiConfigWhereInput>;
};

export type PowerBiConfigScalarWhereInput = {
  AND?: Maybe<Array<PowerBiConfigScalarWhereInput>>;
  NOT?: Maybe<Array<PowerBiConfigScalarWhereInput>>;
  OR?: Maybe<Array<PowerBiConfigScalarWhereInput>>;
  datasetId?: Maybe<StringFilter>;
  reportId?: Maybe<StringFilter>;
  viziblyClientId?: Maybe<StringFilter>;
  workspaceId?: Maybe<StringFilter>;
};

export type PowerBiConfigUpdateManyMutationInput = {
  datasetId?: Maybe<StringFieldUpdateOperationsInput>;
  reportId?: Maybe<StringFieldUpdateOperationsInput>;
  workspaceId?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PowerBiConfigUpdateManyWithWhereWithoutVizibluClientInput = {
  data: PowerBiConfigUpdateManyMutationInput;
  where: PowerBiConfigScalarWhereInput;
};

export type PowerBiConfigUpdateManyWithoutVizibluClientInput = {
  connect?: Maybe<Array<PowerBiConfigWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PowerBiConfigCreateOrConnectWithoutVizibluClientInput>>;
  create?: Maybe<Array<PowerBiConfigCreateWithoutVizibluClientInput>>;
  createMany?: Maybe<PowerBiConfigCreateManyVizibluClientInputEnvelope>;
  delete?: Maybe<Array<PowerBiConfigWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PowerBiConfigScalarWhereInput>>;
  disconnect?: Maybe<Array<PowerBiConfigWhereUniqueInput>>;
  set?: Maybe<Array<PowerBiConfigWhereUniqueInput>>;
  update?: Maybe<Array<PowerBiConfigUpdateWithWhereUniqueWithoutVizibluClientInput>>;
  updateMany?: Maybe<Array<PowerBiConfigUpdateManyWithWhereWithoutVizibluClientInput>>;
  upsert?: Maybe<Array<PowerBiConfigUpsertWithWhereUniqueWithoutVizibluClientInput>>;
};

export type PowerBiConfigUpdateWithWhereUniqueWithoutVizibluClientInput = {
  data: PowerBiConfigUpdateWithoutVizibluClientInput;
  where: PowerBiConfigWhereUniqueInput;
};

export type PowerBiConfigUpdateWithoutVizibluClientInput = {
  datasetId?: Maybe<StringFieldUpdateOperationsInput>;
  reportId?: Maybe<StringFieldUpdateOperationsInput>;
  workspaceId?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PowerBiConfigUpsertWithWhereUniqueWithoutVizibluClientInput = {
  create: PowerBiConfigCreateWithoutVizibluClientInput;
  update: PowerBiConfigUpdateWithoutVizibluClientInput;
  where: PowerBiConfigWhereUniqueInput;
};

export type PowerBiConfigWhereInput = {
  AND?: Maybe<Array<PowerBiConfigWhereInput>>;
  NOT?: Maybe<Array<PowerBiConfigWhereInput>>;
  OR?: Maybe<Array<PowerBiConfigWhereInput>>;
  datasetId?: Maybe<StringFilter>;
  reportId?: Maybe<StringFilter>;
  vizibluClient?: Maybe<ClientRelationFilter>;
  viziblyClientId?: Maybe<StringFilter>;
  workspaceId?: Maybe<StringFilter>;
};

export type PowerBiConfigWhereUniqueInput = {
  viziblyClientId?: Maybe<Scalars['String']>;
};

export type Property = {
  __typename?: 'Property';
  AccountValueOverride: Array<AccountValueOverride>;
  BudgetComponentTotalValues: Array<BudgetComponentTotalValue>;
  Note: Array<Note>;
  RevenueExpirationCurve: Array<RevenueExpirationCurve>;
  accPercentageAssumptions: Array<AccPercentageAssumption>;
  accountPercentageDrivers: Array<DriverAccountPercentage>;
  accountTopVendors: Array<AccountTopVendors>;
  accountTotalValues: Array<AccountTotalValue>;
  accountValues: Array<AccountValue>;
  accountVarianceNotes: Array<AccountVarianceNote>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues: Array<BudgetCategoryTotalValue>;
  budgetCategoryValues: Array<BudgetCategoryValue>;
  budgetComponentValues: Array<BudgetComponentValue>;
  calculationParticipantProperties: Array<CalculationParticipantProperty>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccounts>;
  chartOfAccountsId?: Maybe<Scalars['String']>;
  client: Client;
  clientId: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customItemDrivers: Array<DriverCustomItem>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments: Array<DriverAssignment>;
  driverAugmentsAccountPercentage: Array<DriverAugmentAccountPercentage>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex: Scalars['Int'];
  forecastedAccountValues: Array<ForecastedAccountValue>;
  growthDrivers: Array<DriverGrowth>;
  id: Scalars['String'];
  isApproved: Scalars['Boolean'];
  isDeactivated: Scalars['Boolean'];
  lockedAccounts: Array<LockedAccount>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<Model>;
  modelId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  operationalDrivers: Array<DriverOperational>;
  operationalMetricAssumptions: Array<OperationalMetricAssumption>;
  operationalMetrics: Array<OperationalMetric>;
  originalRevenueMarketRentAdjustments: Array<OriginalRevenueMarketRentAdjustment>;
  originalRevenueMarketRentModeType: OriginalRevenueMarketRentModeType;
  originalRevenueMarketRentOverrides: Array<OriginalRevenueMarketRentOverride>;
  originalRevenuePropertyMarketRentModeType: Array<OriginalRevenuePropertyMarketRentModeType>;
  originalRevenuePropertyRenewalIncreasePercents: Array<OriginalRevenuePropertyRenewalIncreasePercent>;
  payrollDefaultCompensationBaseValues: Array<PayrollDefaultCompensationBase>;
  payrollDefaultCompensationBonusValues: Array<PayrollDefaultCompensationBonus>;
  payrollDefaultCompensationItemValues: Array<PayrollDefaultCompensationItem>;
  payrollDefaultCompensationOvertimeValues: Array<PayrollDefaultCompensationOvertime>;
  payrollDefaultCompensationRaiseValues: Array<PayrollDefaultCompensationRaise>;
  payrollEmployeeAttributions: Array<PayrollEmployeePropertyAttribution>;
  payrollPositionCompensationAccounts: Array<PayrollPositionCompensationAccount>;
  payrollPositionCompensationAccountsV2: Array<PayrollPositionCompensationAccountV2>;
  payrollPositionCompensationItemAccounts: Array<PayrollPositionCompensationItemAccount>;
  payrollPositionCompensationItemAccountsV2: Array<PayrollPositionCompensationItemAccountV2>;
  pmsActualMetricOverrideAverageResidentialRents: Array<PmsActualMetricOverrideAverageResidentialRent>;
  pmsActualMetricOverrideOccupancyRates: Array<PmsActualMetricOverrideOccupancyRate>;
  pmsActualMetricOverrideUnitCounts: Array<PmsActualMetricOverrideUnitCount>;
  propertyBudgetApprovals: Array<PropertyBudgetApproval>;
  propertyBudgetCompletions: Array<PropertyBudgetCompletion>;
  propertyClientBudgetPlans: Array<ClientBudgetPlanProperty>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues: Array<PropertyManagementSystemAccountValue>;
  reforecastStartMonthIndex: Scalars['Int'];
  reforecastYear: Scalars['Int'];
  renewalIncrease: RenewalIncreaseAmountAndPercent;
  renovationPackages: Array<RenovationPackage>;
  revenueDriverAssumptions: Array<RevenueDriverAssumption>;
  revenueDrivers: Array<DriverRevenue>;
  revenueMetrics: Array<RevenueMetric>;
  revenueModel: RevenueModelType;
  revenueModelConfigurations: Array<PropertyRevenueConfiguration>;
  sharedReportLinks: Array<SharedReportLink>;
  simplifiedRevenuePropertyMarketRentGrowthPercents: Array<SimplifiedRevenuePropertyMarketRentGrowthPercent>;
  simplifiedRevenuePropertyRenewalPercents: Array<SimplifiedRevenuePropertyRenewalPercent>;
  simplifiedRevenuePropertyRenewalTradeOutRules: Array<SimplifiedRevenuePropertyRenewalTradeOutRule>;
  simplifiedRevenuePropertyRenovationPercents: Array<SimplifiedRevenuePropertyRenovationPercent>;
  simplifiedRevenuePropertyVacancyPercents: Array<SimplifiedRevenuePropertyVacancyPercent>;
  simplifiedRevenueRentRolls: Array<SimplifiedRevenueRentRoll>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings: Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMapping>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts: Array<StarredAccount>;
  submarketName?: Maybe<Scalars['String']>;
  transactions: Array<Transaction>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents: Array<UnitEvent>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfiguration>;
  unitLevelModelingEnabled: Scalars['Boolean'];
  unitTypeScheduleValues: Array<UnitTypeScheduleValue>;
  unitTypes: Array<UnitType>;
  unitTypesCustom: Array<UnitType>;
  units: Array<Unit>;
  updatedAt: Scalars['DateTime'];
  worksheetAssumptions: Array<WorksheetAssumption>;
  worksheets: Array<Worksheet>;
};


export type PropertyAccountValueOverrideArgs = {
  cursor?: Maybe<AccountValueOverrideWhereUniqueInput>;
  distinct?: Maybe<Array<AccountValueOverrideScalarFieldEnum>>;
  orderBy?: Maybe<Array<AccountValueOverrideOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AccountValueOverrideWhereInput>;
};


export type PropertyBudgetComponentTotalValuesArgs = {
  cursor?: Maybe<BudgetComponentTotalValueWhereUniqueInput>;
  distinct?: Maybe<Array<BudgetComponentTotalValueScalarFieldEnum>>;
  orderBy?: Maybe<Array<BudgetComponentTotalValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BudgetComponentTotalValueWhereInput>;
};


export type PropertyNoteArgs = {
  cursor?: Maybe<NoteWhereUniqueInput>;
  distinct?: Maybe<Array<NoteScalarFieldEnum>>;
  orderBy?: Maybe<Array<NoteOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<NoteWhereInput>;
};


export type PropertyRevenueExpirationCurveArgs = {
  cursor?: Maybe<RevenueExpirationCurveWhereUniqueInput>;
  distinct?: Maybe<Array<RevenueExpirationCurveScalarFieldEnum>>;
  orderBy?: Maybe<Array<RevenueExpirationCurveOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<RevenueExpirationCurveWhereInput>;
};


export type PropertyAccPercentageAssumptionsArgs = {
  cursor?: Maybe<AccPercentageAssumptionWhereUniqueInput>;
  distinct?: Maybe<Array<AccPercentageAssumptionScalarFieldEnum>>;
  orderBy?: Maybe<Array<AccPercentageAssumptionOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AccPercentageAssumptionWhereInput>;
};


export type PropertyAccountPercentageDriversArgs = {
  cursor?: Maybe<DriverAccountPercentageWhereUniqueInput>;
  distinct?: Maybe<Array<DriverAccountPercentageScalarFieldEnum>>;
  orderBy?: Maybe<Array<DriverAccountPercentageOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DriverAccountPercentageWhereInput>;
};


export type PropertyAccountTopVendorsArgs = {
  cursor?: Maybe<AccountTopVendorsWhereUniqueInput>;
  distinct?: Maybe<Array<AccountTopVendorsScalarFieldEnum>>;
  orderBy?: Maybe<Array<AccountTopVendorsOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AccountTopVendorsWhereInput>;
};


export type PropertyAccountTotalValuesArgs = {
  cursor?: Maybe<AccountTotalValueWhereUniqueInput>;
  distinct?: Maybe<Array<AccountTotalValueScalarFieldEnum>>;
  orderBy?: Maybe<Array<AccountTotalValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AccountTotalValueWhereInput>;
};


export type PropertyAccountValuesArgs = {
  cursor?: Maybe<AccountValueWhereUniqueInput>;
  distinct?: Maybe<Array<AccountValueScalarFieldEnum>>;
  orderBy?: Maybe<Array<AccountValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AccountValueWhereInput>;
};


export type PropertyAccountVarianceNotesArgs = {
  cursor?: Maybe<AccountVarianceNoteWhereUniqueInput>;
  distinct?: Maybe<Array<AccountVarianceNoteScalarFieldEnum>>;
  orderBy?: Maybe<Array<AccountVarianceNoteOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AccountVarianceNoteWhereInput>;
};


export type PropertyBudgetCategoryTotalValuesArgs = {
  cursor?: Maybe<BudgetCategoryTotalValueWhereUniqueInput>;
  distinct?: Maybe<Array<BudgetCategoryTotalValueScalarFieldEnum>>;
  orderBy?: Maybe<Array<BudgetCategoryTotalValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BudgetCategoryTotalValueWhereInput>;
};


export type PropertyBudgetCategoryValuesArgs = {
  cursor?: Maybe<BudgetCategoryValueWhereUniqueInput>;
  distinct?: Maybe<Array<BudgetCategoryValueScalarFieldEnum>>;
  orderBy?: Maybe<Array<BudgetCategoryValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BudgetCategoryValueWhereInput>;
};


export type PropertyBudgetComponentValuesArgs = {
  cursor?: Maybe<BudgetComponentValueWhereUniqueInput>;
  distinct?: Maybe<Array<BudgetComponentValueScalarFieldEnum>>;
  orderBy?: Maybe<Array<BudgetComponentValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BudgetComponentValueWhereInput>;
};


export type PropertyCalculationParticipantPropertiesArgs = {
  cursor?: Maybe<CalculationParticipantPropertyWhereUniqueInput>;
  distinct?: Maybe<Array<CalculationParticipantPropertyScalarFieldEnum>>;
  orderBy?: Maybe<Array<CalculationParticipantPropertyOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CalculationParticipantPropertyWhereInput>;
};


export type PropertyCustomItemDriversArgs = {
  cursor?: Maybe<DriverCustomItemWhereUniqueInput>;
  distinct?: Maybe<Array<DriverCustomItemScalarFieldEnum>>;
  orderBy?: Maybe<Array<DriverCustomItemOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DriverCustomItemWhereInput>;
};


export type PropertyDriverAssignmentsArgs = {
  cursor?: Maybe<DriverAssignmentWhereUniqueInput>;
  distinct?: Maybe<Array<DriverAssignmentScalarFieldEnum>>;
  orderBy?: Maybe<Array<DriverAssignmentOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DriverAssignmentWhereInput>;
};


export type PropertyDriverAugmentsAccountPercentageArgs = {
  cursor?: Maybe<DriverAugmentAccountPercentageWhereUniqueInput>;
  distinct?: Maybe<Array<DriverAugmentAccountPercentageScalarFieldEnum>>;
  orderBy?: Maybe<Array<DriverAugmentAccountPercentageOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DriverAugmentAccountPercentageWhereInput>;
};


export type PropertyForecastedAccountValuesArgs = {
  cursor?: Maybe<ForecastedAccountValueWhereUniqueInput>;
  distinct?: Maybe<Array<ForecastedAccountValueScalarFieldEnum>>;
  orderBy?: Maybe<Array<ForecastedAccountValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ForecastedAccountValueWhereInput>;
};


export type PropertyGrowthDriversArgs = {
  cursor?: Maybe<DriverGrowthWhereUniqueInput>;
  distinct?: Maybe<Array<DriverGrowthScalarFieldEnum>>;
  orderBy?: Maybe<Array<DriverGrowthOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DriverGrowthWhereInput>;
};


export type PropertyLockedAccountsArgs = {
  cursor?: Maybe<LockedAccountWhereUniqueInput>;
  distinct?: Maybe<Array<LockedAccountScalarFieldEnum>>;
  orderBy?: Maybe<Array<LockedAccountOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LockedAccountWhereInput>;
};


export type PropertyOperationalDriversArgs = {
  cursor?: Maybe<DriverOperationalWhereUniqueInput>;
  distinct?: Maybe<Array<DriverOperationalScalarFieldEnum>>;
  orderBy?: Maybe<Array<DriverOperationalOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DriverOperationalWhereInput>;
};


export type PropertyOperationalMetricAssumptionsArgs = {
  cursor?: Maybe<OperationalMetricAssumptionWhereUniqueInput>;
  distinct?: Maybe<Array<OperationalMetricAssumptionScalarFieldEnum>>;
  orderBy?: Maybe<Array<OperationalMetricAssumptionOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OperationalMetricAssumptionWhereInput>;
};


export type PropertyOperationalMetricsArgs = {
  cursor?: Maybe<OperationalMetricWhereUniqueInput>;
  distinct?: Maybe<Array<OperationalMetricScalarFieldEnum>>;
  orderBy?: Maybe<Array<OperationalMetricOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OperationalMetricWhereInput>;
};


export type PropertyOriginalRevenueMarketRentAdjustmentsArgs = {
  cursor?: Maybe<OriginalRevenueMarketRentAdjustmentWhereUniqueInput>;
  distinct?: Maybe<Array<OriginalRevenueMarketRentAdjustmentScalarFieldEnum>>;
  orderBy?: Maybe<Array<OriginalRevenueMarketRentAdjustmentOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OriginalRevenueMarketRentAdjustmentWhereInput>;
};


export type PropertyOriginalRevenueMarketRentOverridesArgs = {
  cursor?: Maybe<OriginalRevenueMarketRentOverrideWhereUniqueInput>;
  distinct?: Maybe<Array<OriginalRevenueMarketRentOverrideScalarFieldEnum>>;
  orderBy?: Maybe<Array<OriginalRevenueMarketRentOverrideOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OriginalRevenueMarketRentOverrideWhereInput>;
};


export type PropertyOriginalRevenuePropertyMarketRentModeTypeArgs = {
  cursor?: Maybe<OriginalRevenuePropertyMarketRentModeTypeWhereUniqueInput>;
  distinct?: Maybe<Array<OriginalRevenuePropertyMarketRentModeTypeScalarFieldEnum>>;
  orderBy?: Maybe<Array<OriginalRevenuePropertyMarketRentModeTypeOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OriginalRevenuePropertyMarketRentModeTypeWhereInput>;
};


export type PropertyOriginalRevenuePropertyRenewalIncreasePercentsArgs = {
  cursor?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentWhereUniqueInput>;
  distinct?: Maybe<Array<OriginalRevenuePropertyRenewalIncreasePercentScalarFieldEnum>>;
  orderBy?: Maybe<Array<OriginalRevenuePropertyRenewalIncreasePercentOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentWhereInput>;
};


export type PropertyPayrollDefaultCompensationBaseValuesArgs = {
  cursor?: Maybe<PayrollDefaultCompensationBaseWhereUniqueInput>;
  distinct?: Maybe<Array<PayrollDefaultCompensationBaseScalarFieldEnum>>;
  orderBy?: Maybe<Array<PayrollDefaultCompensationBaseOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PayrollDefaultCompensationBaseWhereInput>;
};


export type PropertyPayrollDefaultCompensationBonusValuesArgs = {
  cursor?: Maybe<PayrollDefaultCompensationBonusWhereUniqueInput>;
  distinct?: Maybe<Array<PayrollDefaultCompensationBonusScalarFieldEnum>>;
  orderBy?: Maybe<Array<PayrollDefaultCompensationBonusOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PayrollDefaultCompensationBonusWhereInput>;
};


export type PropertyPayrollDefaultCompensationItemValuesArgs = {
  cursor?: Maybe<PayrollDefaultCompensationItemWhereUniqueInput>;
  distinct?: Maybe<Array<PayrollDefaultCompensationItemScalarFieldEnum>>;
  orderBy?: Maybe<Array<PayrollDefaultCompensationItemOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PayrollDefaultCompensationItemWhereInput>;
};


export type PropertyPayrollDefaultCompensationOvertimeValuesArgs = {
  cursor?: Maybe<PayrollDefaultCompensationOvertimeWhereUniqueInput>;
  distinct?: Maybe<Array<PayrollDefaultCompensationOvertimeScalarFieldEnum>>;
  orderBy?: Maybe<Array<PayrollDefaultCompensationOvertimeOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PayrollDefaultCompensationOvertimeWhereInput>;
};


export type PropertyPayrollDefaultCompensationRaiseValuesArgs = {
  cursor?: Maybe<PayrollDefaultCompensationRaiseWhereUniqueInput>;
  distinct?: Maybe<Array<PayrollDefaultCompensationRaiseScalarFieldEnum>>;
  orderBy?: Maybe<Array<PayrollDefaultCompensationRaiseOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PayrollDefaultCompensationRaiseWhereInput>;
};


export type PropertyPayrollEmployeeAttributionsArgs = {
  cursor?: Maybe<PayrollEmployeePropertyAttributionWhereUniqueInput>;
  distinct?: Maybe<Array<PayrollEmployeePropertyAttributionScalarFieldEnum>>;
  orderBy?: Maybe<Array<PayrollEmployeePropertyAttributionOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PayrollEmployeePropertyAttributionWhereInput>;
};


export type PropertyPayrollPositionCompensationAccountsArgs = {
  cursor?: Maybe<PayrollPositionCompensationAccountWhereUniqueInput>;
  distinct?: Maybe<Array<PayrollPositionCompensationAccountScalarFieldEnum>>;
  orderBy?: Maybe<Array<PayrollPositionCompensationAccountOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PayrollPositionCompensationAccountWhereInput>;
};


export type PropertyPayrollPositionCompensationAccountsV2Args = {
  cursor?: Maybe<PayrollPositionCompensationAccountV2WhereUniqueInput>;
  distinct?: Maybe<Array<PayrollPositionCompensationAccountV2ScalarFieldEnum>>;
  orderBy?: Maybe<Array<PayrollPositionCompensationAccountV2OrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PayrollPositionCompensationAccountV2WhereInput>;
};


export type PropertyPayrollPositionCompensationItemAccountsArgs = {
  cursor?: Maybe<PayrollPositionCompensationItemAccountWhereUniqueInput>;
  distinct?: Maybe<Array<PayrollPositionCompensationItemAccountScalarFieldEnum>>;
  orderBy?: Maybe<Array<PayrollPositionCompensationItemAccountOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PayrollPositionCompensationItemAccountWhereInput>;
};


export type PropertyPayrollPositionCompensationItemAccountsV2Args = {
  cursor?: Maybe<PayrollPositionCompensationItemAccountV2WhereUniqueInput>;
  distinct?: Maybe<Array<PayrollPositionCompensationItemAccountV2ScalarFieldEnum>>;
  orderBy?: Maybe<Array<PayrollPositionCompensationItemAccountV2OrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PayrollPositionCompensationItemAccountV2WhereInput>;
};


export type PropertyPmsActualMetricOverrideAverageResidentialRentsArgs = {
  cursor?: Maybe<PmsActualMetricOverrideAverageResidentialRentWhereUniqueInput>;
  distinct?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentScalarFieldEnum>>;
  orderBy?: Maybe<Array<PmsActualMetricOverrideAverageResidentialRentOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PmsActualMetricOverrideAverageResidentialRentWhereInput>;
};


export type PropertyPmsActualMetricOverrideOccupancyRatesArgs = {
  cursor?: Maybe<PmsActualMetricOverrideOccupancyRateWhereUniqueInput>;
  distinct?: Maybe<Array<PmsActualMetricOverrideOccupancyRateScalarFieldEnum>>;
  orderBy?: Maybe<Array<PmsActualMetricOverrideOccupancyRateOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PmsActualMetricOverrideOccupancyRateWhereInput>;
};


export type PropertyPmsActualMetricOverrideUnitCountsArgs = {
  cursor?: Maybe<PmsActualMetricOverrideUnitCountWhereUniqueInput>;
  distinct?: Maybe<Array<PmsActualMetricOverrideUnitCountScalarFieldEnum>>;
  orderBy?: Maybe<Array<PmsActualMetricOverrideUnitCountOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PmsActualMetricOverrideUnitCountWhereInput>;
};


export type PropertyPropertyBudgetApprovalsArgs = {
  cursor?: Maybe<PropertyBudgetApprovalWhereUniqueInput>;
  distinct?: Maybe<Array<PropertyBudgetApprovalScalarFieldEnum>>;
  orderBy?: Maybe<Array<PropertyBudgetApprovalOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PropertyBudgetApprovalWhereInput>;
};


export type PropertyPropertyBudgetCompletionsArgs = {
  cursor?: Maybe<PropertyBudgetCompletionWhereUniqueInput>;
  distinct?: Maybe<Array<PropertyBudgetCompletionScalarFieldEnum>>;
  orderBy?: Maybe<Array<PropertyBudgetCompletionOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PropertyBudgetCompletionWhereInput>;
};


export type PropertyPropertyClientBudgetPlansArgs = {
  cursor?: Maybe<ClientBudgetPlanPropertyWhereUniqueInput>;
  distinct?: Maybe<Array<ClientBudgetPlanPropertyScalarFieldEnum>>;
  orderBy?: Maybe<Array<ClientBudgetPlanPropertyOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ClientBudgetPlanPropertyWhereInput>;
};


export type PropertyPropertyManagementSystemAccountValuesArgs = {
  cursor?: Maybe<PropertyManagementSystemAccountValueWhereUniqueInput>;
  distinct?: Maybe<Array<PropertyManagementSystemAccountValueScalarFieldEnum>>;
  orderBy?: Maybe<Array<PropertyManagementSystemAccountValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PropertyManagementSystemAccountValueWhereInput>;
};


export type PropertyRenewalIncreaseArgs = {
  versionType: VersionType;
  year: Scalars['Int'];
};


export type PropertyRenovationPackagesArgs = {
  cursor?: Maybe<RenovationPackageWhereUniqueInput>;
  distinct?: Maybe<Array<RenovationPackageScalarFieldEnum>>;
  orderBy?: Maybe<Array<RenovationPackageOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<RenovationPackageWhereInput>;
};


export type PropertyRevenueDriverAssumptionsArgs = {
  cursor?: Maybe<RevenueDriverAssumptionWhereUniqueInput>;
  distinct?: Maybe<Array<RevenueDriverAssumptionScalarFieldEnum>>;
  orderBy?: Maybe<Array<RevenueDriverAssumptionOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<RevenueDriverAssumptionWhereInput>;
};


export type PropertyRevenueDriversArgs = {
  cursor?: Maybe<DriverRevenueWhereUniqueInput>;
  distinct?: Maybe<Array<DriverRevenueScalarFieldEnum>>;
  orderBy?: Maybe<Array<DriverRevenueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DriverRevenueWhereInput>;
};


export type PropertyRevenueMetricsArgs = {
  cursor?: Maybe<RevenueMetricWhereUniqueInput>;
  distinct?: Maybe<Array<RevenueMetricScalarFieldEnum>>;
  orderBy?: Maybe<Array<RevenueMetricOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<RevenueMetricWhereInput>;
};


export type PropertyRevenueModelConfigurationsArgs = {
  cursor?: Maybe<PropertyRevenueConfigurationWhereUniqueInput>;
  distinct?: Maybe<Array<PropertyRevenueConfigurationScalarFieldEnum>>;
  orderBy?: Maybe<Array<PropertyRevenueConfigurationOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PropertyRevenueConfigurationWhereInput>;
};


export type PropertySharedReportLinksArgs = {
  cursor?: Maybe<SharedReportLinkWhereUniqueInput>;
  distinct?: Maybe<Array<SharedReportLinkScalarFieldEnum>>;
  orderBy?: Maybe<Array<SharedReportLinkOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SharedReportLinkWhereInput>;
};


export type PropertySimplifiedRevenuePropertyMarketRentGrowthPercentsArgs = {
  cursor?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentWhereUniqueInput>;
  distinct?: Maybe<Array<SimplifiedRevenuePropertyMarketRentGrowthPercentScalarFieldEnum>>;
  orderBy?: Maybe<Array<SimplifiedRevenuePropertyMarketRentGrowthPercentOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentWhereInput>;
};


export type PropertySimplifiedRevenuePropertyRenewalPercentsArgs = {
  cursor?: Maybe<SimplifiedRevenuePropertyRenewalPercentWhereUniqueInput>;
  distinct?: Maybe<Array<SimplifiedRevenuePropertyRenewalPercentScalarFieldEnum>>;
  orderBy?: Maybe<Array<SimplifiedRevenuePropertyRenewalPercentOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SimplifiedRevenuePropertyRenewalPercentWhereInput>;
};


export type PropertySimplifiedRevenuePropertyRenewalTradeOutRulesArgs = {
  cursor?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleWhereUniqueInput>;
  distinct?: Maybe<Array<SimplifiedRevenuePropertyRenewalTradeOutRuleScalarFieldEnum>>;
  orderBy?: Maybe<Array<SimplifiedRevenuePropertyRenewalTradeOutRuleOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleWhereInput>;
};


export type PropertySimplifiedRevenuePropertyRenovationPercentsArgs = {
  cursor?: Maybe<SimplifiedRevenuePropertyRenovationPercentWhereUniqueInput>;
  distinct?: Maybe<Array<SimplifiedRevenuePropertyRenovationPercentScalarFieldEnum>>;
  orderBy?: Maybe<Array<SimplifiedRevenuePropertyRenovationPercentOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SimplifiedRevenuePropertyRenovationPercentWhereInput>;
};


export type PropertySimplifiedRevenuePropertyVacancyPercentsArgs = {
  cursor?: Maybe<SimplifiedRevenuePropertyVacancyPercentWhereUniqueInput>;
  distinct?: Maybe<Array<SimplifiedRevenuePropertyVacancyPercentScalarFieldEnum>>;
  orderBy?: Maybe<Array<SimplifiedRevenuePropertyVacancyPercentOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SimplifiedRevenuePropertyVacancyPercentWhereInput>;
};


export type PropertySimplifiedRevenueRentRollsArgs = {
  cursor?: Maybe<SimplifiedRevenueRentRollWhereUniqueInput>;
  distinct?: Maybe<Array<SimplifiedRevenueRentRollScalarFieldEnum>>;
  orderBy?: Maybe<Array<SimplifiedRevenueRentRollOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SimplifiedRevenueRentRollWhereInput>;
};


export type PropertySimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingsArgs = {
  cursor?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput>;
  distinct?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingScalarFieldEnum>>;
  orderBy?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereInput>;
};


export type PropertyStarredAccountsArgs = {
  cursor?: Maybe<StarredAccountWhereUniqueInput>;
  distinct?: Maybe<Array<StarredAccountScalarFieldEnum>>;
  orderBy?: Maybe<Array<StarredAccountOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StarredAccountWhereInput>;
};


export type PropertyTransactionsArgs = {
  cursor?: Maybe<TransactionWhereUniqueInput>;
  distinct?: Maybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: Maybe<Array<TransactionOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TransactionWhereInput>;
};


export type PropertyUnitEventsArgs = {
  cursor?: Maybe<UnitEventWhereUniqueInput>;
  distinct?: Maybe<Array<UnitEventScalarFieldEnum>>;
  orderBy?: Maybe<Array<UnitEventOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UnitEventWhereInput>;
};


export type PropertyUnitTypeScheduleValuesArgs = {
  cursor?: Maybe<UnitTypeScheduleValueWhereUniqueInput>;
  distinct?: Maybe<Array<UnitTypeScheduleValueScalarFieldEnum>>;
  orderBy?: Maybe<Array<UnitTypeScheduleValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UnitTypeScheduleValueWhereInput>;
};


export type PropertyUnitTypesArgs = {
  cursor?: Maybe<UnitTypeWhereUniqueInput>;
  distinct?: Maybe<Array<UnitTypeScalarFieldEnum>>;
  orderBy?: Maybe<Array<UnitTypeOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UnitTypeWhereInput>;
};


export type PropertyUnitsArgs = {
  cursor?: Maybe<UnitWhereUniqueInput>;
  distinct?: Maybe<Array<UnitScalarFieldEnum>>;
  orderBy?: Maybe<Array<UnitOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UnitWhereInput>;
};


export type PropertyWorksheetAssumptionsArgs = {
  cursor?: Maybe<WorksheetAssumptionWhereUniqueInput>;
  distinct?: Maybe<Array<WorksheetAssumptionScalarFieldEnum>>;
  orderBy?: Maybe<Array<WorksheetAssumptionOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<WorksheetAssumptionWhereInput>;
};


export type PropertyWorksheetsArgs = {
  cursor?: Maybe<WorksheetWhereUniqueInput>;
  distinct?: Maybe<Array<WorksheetScalarFieldEnum>>;
  orderBy?: Maybe<Array<WorksheetOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<WorksheetWhereInput>;
};

export type PropertyAccountDriverStatusViewModel = {
  __typename?: 'PropertyAccountDriverStatusViewModel';
  accPercentage: Scalars['Int'];
  accountId: Scalars['ID'];
  customDriver: Scalars['Int'];
  growth: Scalars['Int'];
  hasOverrides: Scalars['Boolean'];
  operational: Scalars['Int'];
  payroll: Scalars['Int'];
  propertyId: Scalars['ID'];
  propertyName: Scalars['String'];
  renovation: Scalars['Int'];
  revenue: Scalars['Int'];
  versionType: VersionType;
  worksheet: Scalars['Int'];
  year: Scalars['Int'];
};

export type PropertyAccountVersionTypeModel = {
  __typename?: 'PropertyAccountVersionTypeModel';
  accountId: Scalars['ID'];
  accountPercentageDriver?: Maybe<AccountPercentageDriverModel>;
  accountValues: Array<Scalars['Float']>;
  customDriver?: Maybe<CustomDriverModel>;
  growthDriver: Array<GrowthDriverModel>;
  operationalMetricDriver?: Maybe<OperationalMetricDriverModel>;
  originalValues: Array<Maybe<Scalars['Float']>>;
  originalValuesRaw: Array<Maybe<Scalars['Float']>>;
  overrideValues: Array<Maybe<Scalars['Float']>>;
  payrollBreakdown?: Maybe<Array<Scalars['Float']>>;
  payrollDriver?: Maybe<PayrollDriverModel>;
  propertyId: Scalars['ID'];
  renovationsDriver?: Maybe<RenovationsDriverModel>;
  revenueDriver?: Maybe<RevenueDriverModel>;
  versionType: VersionType;
  worksheetDriver?: Maybe<WorksheetDriverModel>;
  year: Scalars['Int'];
};

export type PropertyAvgAggregate = {
  __typename?: 'PropertyAvgAggregate';
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Float']>;
  reforecastStartMonthIndex?: Maybe<Scalars['Float']>;
  reforecastYear?: Maybe<Scalars['Float']>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  unitCountTmp?: Maybe<Scalars['Float']>;
};

export type PropertyBudgetApproval = {
  __typename?: 'PropertyBudgetApproval';
  approved: Scalars['Boolean'];
  budgetYear: Scalars['Int'];
  id: Scalars['String'];
  propertyId: Scalars['String'];
  timestamp: Scalars['DateTime'];
  userEmail: Scalars['String'];
  userFirstName: Scalars['String'];
  userId: Scalars['String'];
  userLastName: Scalars['String'];
};

export type PropertyBudgetApprovalCreateManyPropertyInput = {
  approved: Scalars['Boolean'];
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  userEmail: Scalars['String'];
  userFirstName: Scalars['String'];
  userId: Scalars['String'];
  userLastName: Scalars['String'];
};

export type PropertyBudgetApprovalCreateManyPropertyInputEnvelope = {
  data: Array<PropertyBudgetApprovalCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<PropertyBudgetApprovalWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyBudgetApprovalCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PropertyBudgetApprovalCreateWithoutPropertyInput>>;
  createMany?: Maybe<PropertyBudgetApprovalCreateManyPropertyInputEnvelope>;
};

export type PropertyBudgetApprovalCreateOrConnectWithoutPropertyInput = {
  create: PropertyBudgetApprovalCreateWithoutPropertyInput;
  where: PropertyBudgetApprovalWhereUniqueInput;
};

export type PropertyBudgetApprovalCreateWithoutPropertyInput = {
  approved: Scalars['Boolean'];
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  userEmail: Scalars['String'];
  userFirstName: Scalars['String'];
  userId: Scalars['String'];
  userLastName: Scalars['String'];
};

export type PropertyBudgetApprovalListRelationFilter = {
  every?: Maybe<PropertyBudgetApprovalWhereInput>;
  none?: Maybe<PropertyBudgetApprovalWhereInput>;
  some?: Maybe<PropertyBudgetApprovalWhereInput>;
};

export type PropertyBudgetApprovalModel = {
  __typename?: 'PropertyBudgetApprovalModel';
  approved: Scalars['Boolean'];
  budgetYear: Scalars['Float'];
  id: Scalars['ID'];
  propertyId: Scalars['String'];
  timestamp: Scalars['DateTime'];
  userEmail: Scalars['String'];
  userFirstName: Scalars['String'];
  userId: Scalars['String'];
  userLastName: Scalars['String'];
};

export type PropertyBudgetApprovalOrderByInput = {
  approved?: Maybe<SortOrder>;
  budgetYear?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  timestamp?: Maybe<SortOrder>;
  userEmail?: Maybe<SortOrder>;
  userFirstName?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  userLastName?: Maybe<SortOrder>;
};

export enum PropertyBudgetApprovalScalarFieldEnum {
  Approved = 'approved',
  BudgetYear = 'budgetYear',
  Id = 'id',
  PropertyId = 'propertyId',
  Timestamp = 'timestamp',
  UserEmail = 'userEmail',
  UserFirstName = 'userFirstName',
  UserId = 'userId',
  UserLastName = 'userLastName'
}

export type PropertyBudgetApprovalScalarWhereInput = {
  AND?: Maybe<Array<PropertyBudgetApprovalScalarWhereInput>>;
  NOT?: Maybe<Array<PropertyBudgetApprovalScalarWhereInput>>;
  OR?: Maybe<Array<PropertyBudgetApprovalScalarWhereInput>>;
  approved?: Maybe<BoolFilter>;
  budgetYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  timestamp?: Maybe<DateTimeFilter>;
  userEmail?: Maybe<StringFilter>;
  userFirstName?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  userLastName?: Maybe<StringFilter>;
};

export type PropertyBudgetApprovalUpdateManyMutationInput = {
  approved?: Maybe<BoolFieldUpdateOperationsInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  timestamp?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userEmail?: Maybe<StringFieldUpdateOperationsInput>;
  userFirstName?: Maybe<StringFieldUpdateOperationsInput>;
  userId?: Maybe<StringFieldUpdateOperationsInput>;
  userLastName?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PropertyBudgetApprovalUpdateManyWithWhereWithoutPropertyInput = {
  data: PropertyBudgetApprovalUpdateManyMutationInput;
  where: PropertyBudgetApprovalScalarWhereInput;
};

export type PropertyBudgetApprovalUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<PropertyBudgetApprovalWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyBudgetApprovalCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PropertyBudgetApprovalCreateWithoutPropertyInput>>;
  createMany?: Maybe<PropertyBudgetApprovalCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<PropertyBudgetApprovalWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PropertyBudgetApprovalScalarWhereInput>>;
  disconnect?: Maybe<Array<PropertyBudgetApprovalWhereUniqueInput>>;
  set?: Maybe<Array<PropertyBudgetApprovalWhereUniqueInput>>;
  update?: Maybe<Array<PropertyBudgetApprovalUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<PropertyBudgetApprovalUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<PropertyBudgetApprovalUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type PropertyBudgetApprovalUpdateWithWhereUniqueWithoutPropertyInput = {
  data: PropertyBudgetApprovalUpdateWithoutPropertyInput;
  where: PropertyBudgetApprovalWhereUniqueInput;
};

export type PropertyBudgetApprovalUpdateWithoutPropertyInput = {
  approved?: Maybe<BoolFieldUpdateOperationsInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  timestamp?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userEmail?: Maybe<StringFieldUpdateOperationsInput>;
  userFirstName?: Maybe<StringFieldUpdateOperationsInput>;
  userId?: Maybe<StringFieldUpdateOperationsInput>;
  userLastName?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PropertyBudgetApprovalUpsertWithWhereUniqueWithoutPropertyInput = {
  create: PropertyBudgetApprovalCreateWithoutPropertyInput;
  update: PropertyBudgetApprovalUpdateWithoutPropertyInput;
  where: PropertyBudgetApprovalWhereUniqueInput;
};

export type PropertyBudgetApprovalWhereInput = {
  AND?: Maybe<Array<PropertyBudgetApprovalWhereInput>>;
  NOT?: Maybe<Array<PropertyBudgetApprovalWhereInput>>;
  OR?: Maybe<Array<PropertyBudgetApprovalWhereInput>>;
  approved?: Maybe<BoolFilter>;
  budgetYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  timestamp?: Maybe<DateTimeFilter>;
  userEmail?: Maybe<StringFilter>;
  userFirstName?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  userLastName?: Maybe<StringFilter>;
};

export type PropertyBudgetApprovalWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PropertyBudgetCompletion = {
  __typename?: 'PropertyBudgetCompletion';
  budgetYear: Scalars['Int'];
  completed: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  propertyId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PropertyBudgetCompletionCreateManyPropertyInput = {
  budgetYear: Scalars['Int'];
  completed: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PropertyBudgetCompletionCreateManyPropertyInputEnvelope = {
  data: Array<PropertyBudgetCompletionCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<PropertyBudgetCompletionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyBudgetCompletionCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PropertyBudgetCompletionCreateWithoutPropertyInput>>;
  createMany?: Maybe<PropertyBudgetCompletionCreateManyPropertyInputEnvelope>;
};

export type PropertyBudgetCompletionCreateOrConnectWithoutPropertyInput = {
  create: PropertyBudgetCompletionCreateWithoutPropertyInput;
  where: PropertyBudgetCompletionWhereUniqueInput;
};

export type PropertyBudgetCompletionCreateWithoutPropertyInput = {
  budgetYear: Scalars['Int'];
  completed: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PropertyBudgetCompletionListRelationFilter = {
  every?: Maybe<PropertyBudgetCompletionWhereInput>;
  none?: Maybe<PropertyBudgetCompletionWhereInput>;
  some?: Maybe<PropertyBudgetCompletionWhereInput>;
};

export type PropertyBudgetCompletionModel = {
  __typename?: 'PropertyBudgetCompletionModel';
  budgetYear: Scalars['Int'];
  /** Is budget completed */
  completed: Scalars['Boolean'];
  propertyDisplayName?: Maybe<Scalars['String']>;
  propertyId: Scalars['ID'];
  propertyName: Scalars['String'];
  /** When the completion was updated last time. Or null if never. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PropertyBudgetCompletionOrderByInput = {
  budgetYear?: Maybe<SortOrder>;
  completed?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PropertyBudgetCompletionPropertyIdBudgetYearCompoundUniqueInput = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['String'];
};

export enum PropertyBudgetCompletionScalarFieldEnum {
  BudgetYear = 'budgetYear',
  Completed = 'completed',
  CreatedAt = 'createdAt',
  Id = 'id',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt'
}

export type PropertyBudgetCompletionScalarWhereInput = {
  AND?: Maybe<Array<PropertyBudgetCompletionScalarWhereInput>>;
  NOT?: Maybe<Array<PropertyBudgetCompletionScalarWhereInput>>;
  OR?: Maybe<Array<PropertyBudgetCompletionScalarWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  completed?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PropertyBudgetCompletionUpdateManyMutationInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  completed?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PropertyBudgetCompletionUpdateManyWithWhereWithoutPropertyInput = {
  data: PropertyBudgetCompletionUpdateManyMutationInput;
  where: PropertyBudgetCompletionScalarWhereInput;
};

export type PropertyBudgetCompletionUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<PropertyBudgetCompletionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyBudgetCompletionCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PropertyBudgetCompletionCreateWithoutPropertyInput>>;
  createMany?: Maybe<PropertyBudgetCompletionCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<PropertyBudgetCompletionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PropertyBudgetCompletionScalarWhereInput>>;
  disconnect?: Maybe<Array<PropertyBudgetCompletionWhereUniqueInput>>;
  set?: Maybe<Array<PropertyBudgetCompletionWhereUniqueInput>>;
  update?: Maybe<Array<PropertyBudgetCompletionUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<PropertyBudgetCompletionUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<PropertyBudgetCompletionUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type PropertyBudgetCompletionUpdateWithWhereUniqueWithoutPropertyInput = {
  data: PropertyBudgetCompletionUpdateWithoutPropertyInput;
  where: PropertyBudgetCompletionWhereUniqueInput;
};

export type PropertyBudgetCompletionUpdateWithoutPropertyInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  completed?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PropertyBudgetCompletionUpsertWithWhereUniqueWithoutPropertyInput = {
  create: PropertyBudgetCompletionCreateWithoutPropertyInput;
  update: PropertyBudgetCompletionUpdateWithoutPropertyInput;
  where: PropertyBudgetCompletionWhereUniqueInput;
};

export type PropertyBudgetCompletionWhereInput = {
  AND?: Maybe<Array<PropertyBudgetCompletionWhereInput>>;
  NOT?: Maybe<Array<PropertyBudgetCompletionWhereInput>>;
  OR?: Maybe<Array<PropertyBudgetCompletionWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  completed?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PropertyBudgetCompletionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  propertyId_budgetYear?: Maybe<PropertyBudgetCompletionPropertyIdBudgetYearCompoundUniqueInput>;
};

export type PropertyBudgetExportModel = {
  __typename?: 'PropertyBudgetExportModel';
  budgetNote?: Maybe<Scalars['String']>;
  budgetYearTotal: Scalars['Float'];
  budgetYearValues: Array<Scalars['Float']>;
  glName: Scalars['String'];
  glNumber: Scalars['String'];
  id: Scalars['ID'];
  order: Scalars['Int'];
  propertyId: Scalars['ID'];
  reforecastNote?: Maybe<Scalars['String']>;
  reforecastYearTotal: Scalars['Float'];
  reforecastYearValues: Array<Scalars['Float']>;
  type: FinancialEntityType;
};

export type PropertyBudgetExportSingleYearModel = {
  __typename?: 'PropertyBudgetExportSingleYearModel';
  accountClassification?: Maybe<Scalars['String']>;
  extraReferenceYearTotal?: Maybe<Scalars['Float']>;
  glName: Scalars['String'];
  glNumber: Scalars['String'];
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  propertyId: Scalars['ID'];
  referenceYearTotal: Scalars['Float'];
  type: FinancialEntityType;
  yardiExternalAccountId?: Maybe<Scalars['String']>;
  yearTotal: Scalars['Float'];
  yearValues: Array<Scalars['Float']>;
};


export type PropertyBudgetExportSingleYearModelExtraReferenceYearTotalArgs = {
  valueType: VersionType;
  year: Scalars['Int'];
};

export type PropertyBudgetT12ReportModel = {
  __typename?: 'PropertyBudgetT12ReportModel';
  propertyId: Scalars['ID'];
  records: Array<T12ReportRecordModel>;
  totalExpensesActualAnnualized: Scalars['Float'];
  totalExpensesActualT12: Scalars['Float'];
  totalExpensesBudget: Scalars['Float'];
  totalRevenueActualAnnualized: Scalars['Float'];
  totalRevenueActualT12: Scalars['Float'];
  totalRevenueBudget: Scalars['Float'];
  totalSqFoot: Scalars['Float'];
  unitCount: Scalars['Int'];
};

export type PropertyClientIdNameCompoundUniqueInput = {
  clientId: Scalars['String'];
  name: Scalars['String'];
};

export type PropertyCountAggregate = {
  __typename?: 'PropertyCountAggregate';
  _all: Scalars['Int'];
  address: Scalars['Int'];
  cappedRenewalIncreasePercent: Scalars['Int'];
  chartOfAccountsId: Scalars['Int'];
  clientId: Scalars['Int'];
  code: Scalars['Int'];
  createdAt: Scalars['Int'];
  displayName: Scalars['Int'];
  externalId: Scalars['Int'];
  fiscalYearStartMonthIndex: Scalars['Int'];
  id: Scalars['Int'];
  isApproved: Scalars['Int'];
  isDeactivated: Scalars['Int'];
  marketName: Scalars['Int'];
  modelId: Scalars['Int'];
  name: Scalars['Int'];
  propertyManagementSystem: Scalars['Int'];
  reforecastStartMonthIndex: Scalars['Int'];
  reforecastYear: Scalars['Int'];
  sqFtTmp: Scalars['Int'];
  submarketName: Scalars['Int'];
  unitCountTmp: Scalars['Int'];
  unitLevelModelingEnabled: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PropertyCreateInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateManyChartOfAccountsInput = {
  address?: Maybe<Scalars['String']>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  clientId: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  marketName?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  submarketName?: Maybe<Scalars['String']>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PropertyCreateManyChartOfAccountsInputEnvelope = {
  data: Array<PropertyCreateManyChartOfAccountsInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PropertyCreateManyClientInput = {
  address?: Maybe<Scalars['String']>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccountsId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  marketName?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  submarketName?: Maybe<Scalars['String']>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PropertyCreateManyClientInputEnvelope = {
  data: Array<PropertyCreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PropertyCreateManyInput = {
  address?: Maybe<Scalars['String']>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccountsId?: Maybe<Scalars['String']>;
  clientId: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  marketName?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  submarketName?: Maybe<Scalars['String']>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PropertyCreateManyModelInput = {
  address?: Maybe<Scalars['String']>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccountsId?: Maybe<Scalars['String']>;
  clientId: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  marketName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  submarketName?: Maybe<Scalars['String']>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PropertyCreateManyModelInputEnvelope = {
  data: Array<PropertyCreateManyModelInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PropertyCreateNestedManyWithoutChartOfAccountsInput = {
  connect?: Maybe<Array<PropertyWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyCreateOrConnectWithoutChartOfAccountsInput>>;
  create?: Maybe<Array<PropertyCreateWithoutChartOfAccountsInput>>;
  createMany?: Maybe<PropertyCreateManyChartOfAccountsInputEnvelope>;
};

export type PropertyCreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<PropertyWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<PropertyCreateWithoutClientInput>>;
  createMany?: Maybe<PropertyCreateManyClientInputEnvelope>;
};

export type PropertyCreateNestedManyWithoutModelInput = {
  connect?: Maybe<Array<PropertyWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyCreateOrConnectWithoutModelInput>>;
  create?: Maybe<Array<PropertyCreateWithoutModelInput>>;
  createMany?: Maybe<PropertyCreateManyModelInputEnvelope>;
};

export type PropertyCreateNestedOneWithoutAccPercentageAssumptionsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutAccPercentageAssumptionsInput>;
  create?: Maybe<PropertyCreateWithoutAccPercentageAssumptionsInput>;
};

export type PropertyCreateNestedOneWithoutAccountPercentageDriversInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutAccountPercentageDriversInput>;
  create?: Maybe<PropertyCreateWithoutAccountPercentageDriversInput>;
};

export type PropertyCreateNestedOneWithoutAccountTopVendorsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutAccountTopVendorsInput>;
  create?: Maybe<PropertyCreateWithoutAccountTopVendorsInput>;
};

export type PropertyCreateNestedOneWithoutAccountTotalValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutAccountTotalValuesInput>;
  create?: Maybe<PropertyCreateWithoutAccountTotalValuesInput>;
};

export type PropertyCreateNestedOneWithoutAccountValueOverrideInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutAccountValueOverrideInput>;
  create?: Maybe<PropertyCreateWithoutAccountValueOverrideInput>;
};

export type PropertyCreateNestedOneWithoutAccountValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutAccountValuesInput>;
  create?: Maybe<PropertyCreateWithoutAccountValuesInput>;
};

export type PropertyCreateNestedOneWithoutAccountVarianceNotesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutAccountVarianceNotesInput>;
  create?: Maybe<PropertyCreateWithoutAccountVarianceNotesInput>;
};

export type PropertyCreateNestedOneWithoutBudgetCategoryTotalValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutBudgetCategoryTotalValuesInput>;
  create?: Maybe<PropertyCreateWithoutBudgetCategoryTotalValuesInput>;
};

export type PropertyCreateNestedOneWithoutBudgetCategoryValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutBudgetCategoryValuesInput>;
  create?: Maybe<PropertyCreateWithoutBudgetCategoryValuesInput>;
};

export type PropertyCreateNestedOneWithoutBudgetComponentTotalValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutBudgetComponentTotalValuesInput>;
  create?: Maybe<PropertyCreateWithoutBudgetComponentTotalValuesInput>;
};

export type PropertyCreateNestedOneWithoutBudgetComponentValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutBudgetComponentValuesInput>;
  create?: Maybe<PropertyCreateWithoutBudgetComponentValuesInput>;
};

export type PropertyCreateNestedOneWithoutCustomItemDriversInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutCustomItemDriversInput>;
  create?: Maybe<PropertyCreateWithoutCustomItemDriversInput>;
};

export type PropertyCreateNestedOneWithoutDriverAssignmentsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutDriverAssignmentsInput>;
  create?: Maybe<PropertyCreateWithoutDriverAssignmentsInput>;
};

export type PropertyCreateNestedOneWithoutDriverAugmentsAccountPercentageInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutDriverAugmentsAccountPercentageInput>;
  create?: Maybe<PropertyCreateWithoutDriverAugmentsAccountPercentageInput>;
};

export type PropertyCreateNestedOneWithoutForecastedAccountValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutForecastedAccountValuesInput>;
  create?: Maybe<PropertyCreateWithoutForecastedAccountValuesInput>;
};

export type PropertyCreateNestedOneWithoutGrowthDriversInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutGrowthDriversInput>;
  create?: Maybe<PropertyCreateWithoutGrowthDriversInput>;
};

export type PropertyCreateNestedOneWithoutLockedAccountsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutLockedAccountsInput>;
  create?: Maybe<PropertyCreateWithoutLockedAccountsInput>;
};

export type PropertyCreateNestedOneWithoutNoteInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutNoteInput>;
  create?: Maybe<PropertyCreateWithoutNoteInput>;
};

export type PropertyCreateNestedOneWithoutOperationalDriversInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutOperationalDriversInput>;
  create?: Maybe<PropertyCreateWithoutOperationalDriversInput>;
};

export type PropertyCreateNestedOneWithoutOperationalMetricAssumptionsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutOperationalMetricAssumptionsInput>;
  create?: Maybe<PropertyCreateWithoutOperationalMetricAssumptionsInput>;
};

export type PropertyCreateNestedOneWithoutOperationalMetricsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutOperationalMetricsInput>;
  create?: Maybe<PropertyCreateWithoutOperationalMetricsInput>;
};

export type PropertyCreateNestedOneWithoutOriginalRevenueMarketRentOverridesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutOriginalRevenueMarketRentOverridesInput>;
  create?: Maybe<PropertyCreateWithoutOriginalRevenueMarketRentOverridesInput>;
};

export type PropertyCreateNestedOneWithoutPayrollDefaultCompensationBaseValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPayrollDefaultCompensationBaseValuesInput>;
  create?: Maybe<PropertyCreateWithoutPayrollDefaultCompensationBaseValuesInput>;
};

export type PropertyCreateNestedOneWithoutPayrollDefaultCompensationBonusValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPayrollDefaultCompensationBonusValuesInput>;
  create?: Maybe<PropertyCreateWithoutPayrollDefaultCompensationBonusValuesInput>;
};

export type PropertyCreateNestedOneWithoutPayrollDefaultCompensationItemValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPayrollDefaultCompensationItemValuesInput>;
  create?: Maybe<PropertyCreateWithoutPayrollDefaultCompensationItemValuesInput>;
};

export type PropertyCreateNestedOneWithoutPayrollDefaultCompensationOvertimeValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPayrollDefaultCompensationOvertimeValuesInput>;
  create?: Maybe<PropertyCreateWithoutPayrollDefaultCompensationOvertimeValuesInput>;
};

export type PropertyCreateNestedOneWithoutPayrollDefaultCompensationRaiseValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPayrollDefaultCompensationRaiseValuesInput>;
  create?: Maybe<PropertyCreateWithoutPayrollDefaultCompensationRaiseValuesInput>;
};

export type PropertyCreateNestedOneWithoutPayrollEmployeeAttributionsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPayrollEmployeeAttributionsInput>;
  create?: Maybe<PropertyCreateWithoutPayrollEmployeeAttributionsInput>;
};

export type PropertyCreateNestedOneWithoutPayrollPositionCompensationAccountsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPayrollPositionCompensationAccountsInput>;
  create?: Maybe<PropertyCreateWithoutPayrollPositionCompensationAccountsInput>;
};

export type PropertyCreateNestedOneWithoutPayrollPositionCompensationAccountsV2Input = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPayrollPositionCompensationAccountsV2Input>;
  create?: Maybe<PropertyCreateWithoutPayrollPositionCompensationAccountsV2Input>;
};

export type PropertyCreateNestedOneWithoutPayrollPositionCompensationItemAccountsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPayrollPositionCompensationItemAccountsInput>;
  create?: Maybe<PropertyCreateWithoutPayrollPositionCompensationItemAccountsInput>;
};

export type PropertyCreateNestedOneWithoutPayrollPositionCompensationItemAccountsV2Input = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPayrollPositionCompensationItemAccountsV2Input>;
  create?: Maybe<PropertyCreateWithoutPayrollPositionCompensationItemAccountsV2Input>;
};

export type PropertyCreateNestedOneWithoutPmsActualMetricOverrideAverageResidentialRentsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPmsActualMetricOverrideAverageResidentialRentsInput>;
  create?: Maybe<PropertyCreateWithoutPmsActualMetricOverrideAverageResidentialRentsInput>;
};

export type PropertyCreateNestedOneWithoutPmsActualMetricOverrideOccupancyRatesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPmsActualMetricOverrideOccupancyRatesInput>;
  create?: Maybe<PropertyCreateWithoutPmsActualMetricOverrideOccupancyRatesInput>;
};

export type PropertyCreateNestedOneWithoutPmsActualMetricOverrideUnitCountsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPmsActualMetricOverrideUnitCountsInput>;
  create?: Maybe<PropertyCreateWithoutPmsActualMetricOverrideUnitCountsInput>;
};

export type PropertyCreateNestedOneWithoutPropertyClientBudgetPlansInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPropertyClientBudgetPlansInput>;
  create?: Maybe<PropertyCreateWithoutPropertyClientBudgetPlansInput>;
};

export type PropertyCreateNestedOneWithoutPropertyManagementSystemAccountValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPropertyManagementSystemAccountValuesInput>;
  create?: Maybe<PropertyCreateWithoutPropertyManagementSystemAccountValuesInput>;
};

export type PropertyCreateNestedOneWithoutRenovationPackagesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutRenovationPackagesInput>;
  create?: Maybe<PropertyCreateWithoutRenovationPackagesInput>;
};

export type PropertyCreateNestedOneWithoutRevenueDriverAssumptionsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutRevenueDriverAssumptionsInput>;
  create?: Maybe<PropertyCreateWithoutRevenueDriverAssumptionsInput>;
};

export type PropertyCreateNestedOneWithoutRevenueDriversInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutRevenueDriversInput>;
  create?: Maybe<PropertyCreateWithoutRevenueDriversInput>;
};

export type PropertyCreateNestedOneWithoutRevenueMetricsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutRevenueMetricsInput>;
  create?: Maybe<PropertyCreateWithoutRevenueMetricsInput>;
};

export type PropertyCreateNestedOneWithoutSharedReportLinksInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutSharedReportLinksInput>;
  create?: Maybe<PropertyCreateWithoutSharedReportLinksInput>;
};

export type PropertyCreateNestedOneWithoutSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingsInput>;
  create?: Maybe<PropertyCreateWithoutSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingsInput>;
};

export type PropertyCreateNestedOneWithoutStarredAccountsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutStarredAccountsInput>;
  create?: Maybe<PropertyCreateWithoutStarredAccountsInput>;
};

export type PropertyCreateNestedOneWithoutTransactionsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutTransactionsInput>;
  create?: Maybe<PropertyCreateWithoutTransactionsInput>;
};

export type PropertyCreateNestedOneWithoutUnitEventsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutUnitEventsInput>;
  create?: Maybe<PropertyCreateWithoutUnitEventsInput>;
};

export type PropertyCreateNestedOneWithoutUnitLevelConfigurationInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutUnitLevelConfigurationInput>;
  create?: Maybe<PropertyCreateWithoutUnitLevelConfigurationInput>;
};

export type PropertyCreateNestedOneWithoutUnitTypeScheduleValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutUnitTypeScheduleValuesInput>;
  create?: Maybe<PropertyCreateWithoutUnitTypeScheduleValuesInput>;
};

export type PropertyCreateNestedOneWithoutUnitTypesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutUnitTypesInput>;
  create?: Maybe<PropertyCreateWithoutUnitTypesInput>;
};

export type PropertyCreateNestedOneWithoutUnitsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutUnitsInput>;
  create?: Maybe<PropertyCreateWithoutUnitsInput>;
};

export type PropertyCreateNestedOneWithoutWorksheetAssumptionsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutWorksheetAssumptionsInput>;
  create?: Maybe<PropertyCreateWithoutWorksheetAssumptionsInput>;
};

export type PropertyCreateNestedOneWithoutWorksheetsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutWorksheetsInput>;
  create?: Maybe<PropertyCreateWithoutWorksheetsInput>;
};

export type PropertyCreateOrConnectWithoutAccPercentageAssumptionsInput = {
  create: PropertyCreateWithoutAccPercentageAssumptionsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutAccountPercentageDriversInput = {
  create: PropertyCreateWithoutAccountPercentageDriversInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutAccountTopVendorsInput = {
  create: PropertyCreateWithoutAccountTopVendorsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutAccountTotalValuesInput = {
  create: PropertyCreateWithoutAccountTotalValuesInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutAccountValueOverrideInput = {
  create: PropertyCreateWithoutAccountValueOverrideInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutAccountValuesInput = {
  create: PropertyCreateWithoutAccountValuesInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutAccountVarianceNotesInput = {
  create: PropertyCreateWithoutAccountVarianceNotesInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutBudgetCategoryTotalValuesInput = {
  create: PropertyCreateWithoutBudgetCategoryTotalValuesInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutBudgetCategoryValuesInput = {
  create: PropertyCreateWithoutBudgetCategoryValuesInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutBudgetComponentTotalValuesInput = {
  create: PropertyCreateWithoutBudgetComponentTotalValuesInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutBudgetComponentValuesInput = {
  create: PropertyCreateWithoutBudgetComponentValuesInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutChartOfAccountsInput = {
  create: PropertyCreateWithoutChartOfAccountsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutClientInput = {
  create: PropertyCreateWithoutClientInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutCustomItemDriversInput = {
  create: PropertyCreateWithoutCustomItemDriversInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutDriverAssignmentsInput = {
  create: PropertyCreateWithoutDriverAssignmentsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutDriverAugmentsAccountPercentageInput = {
  create: PropertyCreateWithoutDriverAugmentsAccountPercentageInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutForecastedAccountValuesInput = {
  create: PropertyCreateWithoutForecastedAccountValuesInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutGrowthDriversInput = {
  create: PropertyCreateWithoutGrowthDriversInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutLockedAccountsInput = {
  create: PropertyCreateWithoutLockedAccountsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutModelInput = {
  create: PropertyCreateWithoutModelInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutNoteInput = {
  create: PropertyCreateWithoutNoteInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutOperationalDriversInput = {
  create: PropertyCreateWithoutOperationalDriversInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutOperationalMetricAssumptionsInput = {
  create: PropertyCreateWithoutOperationalMetricAssumptionsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutOperationalMetricsInput = {
  create: PropertyCreateWithoutOperationalMetricsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutOriginalRevenueMarketRentOverridesInput = {
  create: PropertyCreateWithoutOriginalRevenueMarketRentOverridesInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutPayrollDefaultCompensationBaseValuesInput = {
  create: PropertyCreateWithoutPayrollDefaultCompensationBaseValuesInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutPayrollDefaultCompensationBonusValuesInput = {
  create: PropertyCreateWithoutPayrollDefaultCompensationBonusValuesInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutPayrollDefaultCompensationItemValuesInput = {
  create: PropertyCreateWithoutPayrollDefaultCompensationItemValuesInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutPayrollDefaultCompensationOvertimeValuesInput = {
  create: PropertyCreateWithoutPayrollDefaultCompensationOvertimeValuesInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutPayrollDefaultCompensationRaiseValuesInput = {
  create: PropertyCreateWithoutPayrollDefaultCompensationRaiseValuesInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutPayrollEmployeeAttributionsInput = {
  create: PropertyCreateWithoutPayrollEmployeeAttributionsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutPayrollPositionCompensationAccountsInput = {
  create: PropertyCreateWithoutPayrollPositionCompensationAccountsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutPayrollPositionCompensationAccountsV2Input = {
  create: PropertyCreateWithoutPayrollPositionCompensationAccountsV2Input;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutPayrollPositionCompensationItemAccountsInput = {
  create: PropertyCreateWithoutPayrollPositionCompensationItemAccountsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutPayrollPositionCompensationItemAccountsV2Input = {
  create: PropertyCreateWithoutPayrollPositionCompensationItemAccountsV2Input;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutPmsActualMetricOverrideAverageResidentialRentsInput = {
  create: PropertyCreateWithoutPmsActualMetricOverrideAverageResidentialRentsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutPmsActualMetricOverrideOccupancyRatesInput = {
  create: PropertyCreateWithoutPmsActualMetricOverrideOccupancyRatesInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutPmsActualMetricOverrideUnitCountsInput = {
  create: PropertyCreateWithoutPmsActualMetricOverrideUnitCountsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutPropertyClientBudgetPlansInput = {
  create: PropertyCreateWithoutPropertyClientBudgetPlansInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutPropertyManagementSystemAccountValuesInput = {
  create: PropertyCreateWithoutPropertyManagementSystemAccountValuesInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutRenovationPackagesInput = {
  create: PropertyCreateWithoutRenovationPackagesInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutRevenueDriverAssumptionsInput = {
  create: PropertyCreateWithoutRevenueDriverAssumptionsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutRevenueDriversInput = {
  create: PropertyCreateWithoutRevenueDriversInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutRevenueMetricsInput = {
  create: PropertyCreateWithoutRevenueMetricsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutSharedReportLinksInput = {
  create: PropertyCreateWithoutSharedReportLinksInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingsInput = {
  create: PropertyCreateWithoutSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutStarredAccountsInput = {
  create: PropertyCreateWithoutStarredAccountsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutTransactionsInput = {
  create: PropertyCreateWithoutTransactionsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutUnitEventsInput = {
  create: PropertyCreateWithoutUnitEventsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutUnitLevelConfigurationInput = {
  create: PropertyCreateWithoutUnitLevelConfigurationInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutUnitTypeScheduleValuesInput = {
  create: PropertyCreateWithoutUnitTypeScheduleValuesInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutUnitTypesInput = {
  create: PropertyCreateWithoutUnitTypesInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutUnitsInput = {
  create: PropertyCreateWithoutUnitsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutWorksheetAssumptionsInput = {
  create: PropertyCreateWithoutWorksheetAssumptionsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutWorksheetsInput = {
  create: PropertyCreateWithoutWorksheetsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateWithoutAccPercentageAssumptionsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutAccountPercentageDriversInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutAccountTopVendorsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutAccountTotalValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutAccountValueOverrideInput = {
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutAccountValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutAccountVarianceNotesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutBudgetCategoryTotalValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutBudgetCategoryValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutBudgetComponentTotalValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutBudgetComponentValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutChartOfAccountsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutClientInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutCustomItemDriversInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutDriverAssignmentsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutDriverAugmentsAccountPercentageInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutForecastedAccountValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutGrowthDriversInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutLockedAccountsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutModelInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutNoteInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutOperationalDriversInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutOperationalMetricAssumptionsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutOperationalMetricsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutOriginalRevenueMarketRentOverridesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutPayrollDefaultCompensationBaseValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutPayrollDefaultCompensationBonusValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutPayrollDefaultCompensationItemValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutPayrollDefaultCompensationOvertimeValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutPayrollDefaultCompensationRaiseValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutPayrollEmployeeAttributionsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutPayrollPositionCompensationAccountsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutPayrollPositionCompensationAccountsV2Input = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutPayrollPositionCompensationItemAccountsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutPayrollPositionCompensationItemAccountsV2Input = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutPmsActualMetricOverrideAverageResidentialRentsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutPmsActualMetricOverrideOccupancyRatesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutPmsActualMetricOverrideUnitCountsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutPropertyClientBudgetPlansInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutPropertyManagementSystemAccountValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutRenovationPackagesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutRevenueDriverAssumptionsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutRevenueDriversInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutRevenueMetricsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutSharedReportLinksInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutStarredAccountsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutTransactionsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutUnitEventsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutUnitLevelConfigurationInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutUnitTypeScheduleValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutUnitTypesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutUnitsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutWorksheetAssumptionsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheets?: Maybe<WorksheetCreateNestedManyWithoutPropertyInput>;
};

export type PropertyCreateWithoutWorksheetsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutPropertyInput>;
  Note?: Maybe<NoteCreateNestedManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveCreateNestedManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionCreateNestedManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageCreateNestedManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsCreateNestedManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteCreateNestedManyWithoutPropertyInput>;
  address?: Maybe<Scalars['String']>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyCreateNestedManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccounts?: Maybe<ChartOfAccountsCreateNestedOneWithoutPropertiesInput>;
  client: ClientCreateNestedOneWithoutPropertiesInput;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customItemDrivers?: Maybe<DriverCustomItemCreateNestedManyWithoutPropertyInput>;
  displayName?: Maybe<Scalars['String']>;
  driverAssignments?: Maybe<DriverAssignmentCreateNestedManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageCreateNestedManyWithoutPropertyInput>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueCreateNestedManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthCreateNestedManyWithoutPropertyInput>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  lockedAccounts?: Maybe<LockedAccountCreateNestedManyWithoutPropertyInput>;
  marketName?: Maybe<Scalars['String']>;
  model?: Maybe<ModelCreateNestedOneWithoutPropertiesInput>;
  name: Scalars['String'];
  operationalDrivers?: Maybe<DriverOperationalCreateNestedManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionCreateNestedManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentCreateNestedManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeCreateNestedManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeCreateNestedManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseCreateNestedManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2CreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountCreateNestedManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2CreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalCreateNestedManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionCreateNestedManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyCreateNestedManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueCreateNestedManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutPropertyInput>;
  submarketName?: Maybe<Scalars['String']>;
  transactions?: Maybe<TransactionCreateNestedManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeCreateNestedManyWithoutPropertyInput>;
  units?: Maybe<UnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionCreateNestedManyWithoutPropertyInput>;
};

export type PropertyDriverStatusViewModel = {
  __typename?: 'PropertyDriverStatusViewModel';
  accPercentage: Scalars['Int'];
  customDriver: Scalars['Int'];
  growth: Scalars['Int'];
  operational: Scalars['Int'];
  payroll: Scalars['Int'];
  propertyId: Scalars['ID'];
  propertyName: Scalars['String'];
  revenue: Scalars['Int'];
  versionType: VersionType;
  worksheet: Scalars['Int'];
  year: Scalars['Int'];
};

export type PropertyForPmsActualOverridesModel = {
  __typename?: 'PropertyForPMSActualOverridesModel';
  inPlaceRent: Array<UnitTypePmsActualOverrideMonthModel>;
  monthIndex: Scalars['Int'];
  occupancy: Array<UnitTypePmsActualOverrideMonthModel>;
  propertyId: Scalars['ID'];
  unitCount: Array<UnitTypePmsActualOverrideMonthModel>;
};

export type PropertyForecastLocksArgs = {
  isBudgetLocked?: Maybe<Scalars['Boolean']>;
  isReforecastLocked?: Maybe<Scalars['Boolean']>;
};

export type PropertyGroupBy = {
  __typename?: 'PropertyGroupBy';
  _avg?: Maybe<PropertyAvgAggregate>;
  _count?: Maybe<PropertyCountAggregate>;
  _max?: Maybe<PropertyMaxAggregate>;
  _min?: Maybe<PropertyMinAggregate>;
  _sum?: Maybe<PropertySumAggregate>;
  address?: Maybe<Scalars['String']>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccountsId?: Maybe<Scalars['String']>;
  clientId: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  displayName?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex: Scalars['Int'];
  id: Scalars['String'];
  isApproved: Scalars['Boolean'];
  isDeactivated: Scalars['Boolean'];
  marketName?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  reforecastStartMonthIndex: Scalars['Int'];
  reforecastYear: Scalars['Int'];
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  submarketName?: Maybe<Scalars['String']>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitLevelModelingEnabled: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type PropertyIdChartOfAccountsIdCompoundUniqueInput = {
  chartOfAccountsId: Scalars['String'];
  id: Scalars['String'];
};

export type PropertyListRelationFilter = {
  every?: Maybe<PropertyWhereInput>;
  none?: Maybe<PropertyWhereInput>;
  some?: Maybe<PropertyWhereInput>;
};

export type PropertyManagementOwnersCreateManyOwnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  propertyManagementId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PropertyManagementOwnersCreateManyOwnerInputEnvelope = {
  data: Array<PropertyManagementOwnersCreateManyOwnerInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PropertyManagementOwnersCreateManyPropertyManagementInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  ownerId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PropertyManagementOwnersCreateManyPropertyManagementInputEnvelope = {
  data: Array<PropertyManagementOwnersCreateManyPropertyManagementInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PropertyManagementOwnersCreateNestedManyWithoutOwnerInput = {
  connect?: Maybe<Array<PropertyManagementOwnersWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyManagementOwnersCreateOrConnectWithoutOwnerInput>>;
  create?: Maybe<Array<PropertyManagementOwnersCreateWithoutOwnerInput>>;
  createMany?: Maybe<PropertyManagementOwnersCreateManyOwnerInputEnvelope>;
};

export type PropertyManagementOwnersCreateNestedManyWithoutPropertyManagementInput = {
  connect?: Maybe<Array<PropertyManagementOwnersWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyManagementOwnersCreateOrConnectWithoutPropertyManagementInput>>;
  create?: Maybe<Array<PropertyManagementOwnersCreateWithoutPropertyManagementInput>>;
  createMany?: Maybe<PropertyManagementOwnersCreateManyPropertyManagementInputEnvelope>;
};

export type PropertyManagementOwnersCreateOrConnectWithoutOwnerInput = {
  create: PropertyManagementOwnersCreateWithoutOwnerInput;
  where: PropertyManagementOwnersWhereUniqueInput;
};

export type PropertyManagementOwnersCreateOrConnectWithoutPropertyManagementInput = {
  create: PropertyManagementOwnersCreateWithoutPropertyManagementInput;
  where: PropertyManagementOwnersWhereUniqueInput;
};

export type PropertyManagementOwnersCreateWithoutOwnerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  propertyManagement: ClientCreateNestedOneWithoutPropertyManagementsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PropertyManagementOwnersCreateWithoutPropertyManagementInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  owner: ClientCreateNestedOneWithoutOwnersInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PropertyManagementOwnersListRelationFilter = {
  every?: Maybe<PropertyManagementOwnersWhereInput>;
  none?: Maybe<PropertyManagementOwnersWhereInput>;
  some?: Maybe<PropertyManagementOwnersWhereInput>;
};

export type PropertyManagementOwnersPropertyManagementIdOwnerIdCompoundUniqueInput = {
  ownerId: Scalars['String'];
  propertyManagementId: Scalars['String'];
};

export type PropertyManagementOwnersScalarWhereInput = {
  AND?: Maybe<Array<PropertyManagementOwnersScalarWhereInput>>;
  NOT?: Maybe<Array<PropertyManagementOwnersScalarWhereInput>>;
  OR?: Maybe<Array<PropertyManagementOwnersScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  ownerId?: Maybe<StringFilter>;
  propertyManagementId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PropertyManagementOwnersUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PropertyManagementOwnersUpdateManyWithWhereWithoutOwnerInput = {
  data: PropertyManagementOwnersUpdateManyMutationInput;
  where: PropertyManagementOwnersScalarWhereInput;
};

export type PropertyManagementOwnersUpdateManyWithWhereWithoutPropertyManagementInput = {
  data: PropertyManagementOwnersUpdateManyMutationInput;
  where: PropertyManagementOwnersScalarWhereInput;
};

export type PropertyManagementOwnersUpdateManyWithoutOwnerInput = {
  connect?: Maybe<Array<PropertyManagementOwnersWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyManagementOwnersCreateOrConnectWithoutOwnerInput>>;
  create?: Maybe<Array<PropertyManagementOwnersCreateWithoutOwnerInput>>;
  createMany?: Maybe<PropertyManagementOwnersCreateManyOwnerInputEnvelope>;
  delete?: Maybe<Array<PropertyManagementOwnersWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PropertyManagementOwnersScalarWhereInput>>;
  disconnect?: Maybe<Array<PropertyManagementOwnersWhereUniqueInput>>;
  set?: Maybe<Array<PropertyManagementOwnersWhereUniqueInput>>;
  update?: Maybe<Array<PropertyManagementOwnersUpdateWithWhereUniqueWithoutOwnerInput>>;
  updateMany?: Maybe<Array<PropertyManagementOwnersUpdateManyWithWhereWithoutOwnerInput>>;
  upsert?: Maybe<Array<PropertyManagementOwnersUpsertWithWhereUniqueWithoutOwnerInput>>;
};

export type PropertyManagementOwnersUpdateManyWithoutPropertyManagementInput = {
  connect?: Maybe<Array<PropertyManagementOwnersWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyManagementOwnersCreateOrConnectWithoutPropertyManagementInput>>;
  create?: Maybe<Array<PropertyManagementOwnersCreateWithoutPropertyManagementInput>>;
  createMany?: Maybe<PropertyManagementOwnersCreateManyPropertyManagementInputEnvelope>;
  delete?: Maybe<Array<PropertyManagementOwnersWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PropertyManagementOwnersScalarWhereInput>>;
  disconnect?: Maybe<Array<PropertyManagementOwnersWhereUniqueInput>>;
  set?: Maybe<Array<PropertyManagementOwnersWhereUniqueInput>>;
  update?: Maybe<Array<PropertyManagementOwnersUpdateWithWhereUniqueWithoutPropertyManagementInput>>;
  updateMany?: Maybe<Array<PropertyManagementOwnersUpdateManyWithWhereWithoutPropertyManagementInput>>;
  upsert?: Maybe<Array<PropertyManagementOwnersUpsertWithWhereUniqueWithoutPropertyManagementInput>>;
};

export type PropertyManagementOwnersUpdateWithWhereUniqueWithoutOwnerInput = {
  data: PropertyManagementOwnersUpdateWithoutOwnerInput;
  where: PropertyManagementOwnersWhereUniqueInput;
};

export type PropertyManagementOwnersUpdateWithWhereUniqueWithoutPropertyManagementInput = {
  data: PropertyManagementOwnersUpdateWithoutPropertyManagementInput;
  where: PropertyManagementOwnersWhereUniqueInput;
};

export type PropertyManagementOwnersUpdateWithoutOwnerInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  propertyManagement?: Maybe<ClientUpdateOneRequiredWithoutPropertyManagementsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PropertyManagementOwnersUpdateWithoutPropertyManagementInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  owner?: Maybe<ClientUpdateOneRequiredWithoutOwnersInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PropertyManagementOwnersUpsertWithWhereUniqueWithoutOwnerInput = {
  create: PropertyManagementOwnersCreateWithoutOwnerInput;
  update: PropertyManagementOwnersUpdateWithoutOwnerInput;
  where: PropertyManagementOwnersWhereUniqueInput;
};

export type PropertyManagementOwnersUpsertWithWhereUniqueWithoutPropertyManagementInput = {
  create: PropertyManagementOwnersCreateWithoutPropertyManagementInput;
  update: PropertyManagementOwnersUpdateWithoutPropertyManagementInput;
  where: PropertyManagementOwnersWhereUniqueInput;
};

export type PropertyManagementOwnersWhereInput = {
  AND?: Maybe<Array<PropertyManagementOwnersWhereInput>>;
  NOT?: Maybe<Array<PropertyManagementOwnersWhereInput>>;
  OR?: Maybe<Array<PropertyManagementOwnersWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  owner?: Maybe<ClientRelationFilter>;
  ownerId?: Maybe<StringFilter>;
  propertyManagement?: Maybe<ClientRelationFilter>;
  propertyManagementId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PropertyManagementOwnersWhereUniqueInput = {
  propertyManagementId_ownerId?: Maybe<PropertyManagementOwnersPropertyManagementIdOwnerIdCompoundUniqueInput>;
};

export enum PropertyManagementSystem {
  Entrata = 'Entrata',
  Yardi = 'Yardi'
}

export type PropertyManagementSystemAccountValue = {
  __typename?: 'PropertyManagementSystemAccountValue';
  accountId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  propertyId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type PropertyManagementSystemAccountValueCreateManyAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type PropertyManagementSystemAccountValueCreateManyAccountInputEnvelope = {
  data: Array<PropertyManagementSystemAccountValueCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PropertyManagementSystemAccountValueCreateManyPropertyInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type PropertyManagementSystemAccountValueCreateManyPropertyInputEnvelope = {
  data: Array<PropertyManagementSystemAccountValueCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PropertyManagementSystemAccountValueCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<PropertyManagementSystemAccountValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyManagementSystemAccountValueCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<PropertyManagementSystemAccountValueCreateWithoutAccountInput>>;
  createMany?: Maybe<PropertyManagementSystemAccountValueCreateManyAccountInputEnvelope>;
};

export type PropertyManagementSystemAccountValueCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<PropertyManagementSystemAccountValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyManagementSystemAccountValueCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PropertyManagementSystemAccountValueCreateWithoutPropertyInput>>;
  createMany?: Maybe<PropertyManagementSystemAccountValueCreateManyPropertyInputEnvelope>;
};

export type PropertyManagementSystemAccountValueCreateOrConnectWithoutAccountInput = {
  create: PropertyManagementSystemAccountValueCreateWithoutAccountInput;
  where: PropertyManagementSystemAccountValueWhereUniqueInput;
};

export type PropertyManagementSystemAccountValueCreateOrConnectWithoutPropertyInput = {
  create: PropertyManagementSystemAccountValueCreateWithoutPropertyInput;
  where: PropertyManagementSystemAccountValueWhereUniqueInput;
};

export type PropertyManagementSystemAccountValueCreateWithoutAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  property: PropertyCreateNestedOneWithoutPropertyManagementSystemAccountValuesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type PropertyManagementSystemAccountValueCreateWithoutPropertyInput = {
  account: AccountV2CreateNestedOneWithoutPropertyManagementSystemAccountValuesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type PropertyManagementSystemAccountValueListRelationFilter = {
  every?: Maybe<PropertyManagementSystemAccountValueWhereInput>;
  none?: Maybe<PropertyManagementSystemAccountValueWhereInput>;
  some?: Maybe<PropertyManagementSystemAccountValueWhereInput>;
};

export type PropertyManagementSystemAccountValueOrderByInput = {
  accountId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  valueMonth0?: Maybe<SortOrder>;
  valueMonth1?: Maybe<SortOrder>;
  valueMonth2?: Maybe<SortOrder>;
  valueMonth3?: Maybe<SortOrder>;
  valueMonth4?: Maybe<SortOrder>;
  valueMonth5?: Maybe<SortOrder>;
  valueMonth6?: Maybe<SortOrder>;
  valueMonth7?: Maybe<SortOrder>;
  valueMonth8?: Maybe<SortOrder>;
  valueMonth9?: Maybe<SortOrder>;
  valueMonth10?: Maybe<SortOrder>;
  valueMonth11?: Maybe<SortOrder>;
  versionType?: Maybe<SortOrder>;
  year?: Maybe<SortOrder>;
};

export type PropertyManagementSystemAccountValuePropertyIdAccountIdYearVersionTypeCompoundUniqueInput = {
  accountId: Scalars['String'];
  propertyId: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export enum PropertyManagementSystemAccountValueScalarFieldEnum {
  AccountId = 'accountId',
  CreatedAt = 'createdAt',
  Id = 'id',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt',
  ValueMonth0 = 'valueMonth0',
  ValueMonth1 = 'valueMonth1',
  ValueMonth2 = 'valueMonth2',
  ValueMonth3 = 'valueMonth3',
  ValueMonth4 = 'valueMonth4',
  ValueMonth5 = 'valueMonth5',
  ValueMonth6 = 'valueMonth6',
  ValueMonth7 = 'valueMonth7',
  ValueMonth8 = 'valueMonth8',
  ValueMonth9 = 'valueMonth9',
  ValueMonth10 = 'valueMonth10',
  ValueMonth11 = 'valueMonth11',
  VersionType = 'versionType',
  Year = 'year'
}

export type PropertyManagementSystemAccountValueScalarWhereInput = {
  AND?: Maybe<Array<PropertyManagementSystemAccountValueScalarWhereInput>>;
  NOT?: Maybe<Array<PropertyManagementSystemAccountValueScalarWhereInput>>;
  OR?: Maybe<Array<PropertyManagementSystemAccountValueScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  valueMonth0?: Maybe<DecimalNullableFilter>;
  valueMonth1?: Maybe<DecimalNullableFilter>;
  valueMonth2?: Maybe<DecimalNullableFilter>;
  valueMonth3?: Maybe<DecimalNullableFilter>;
  valueMonth4?: Maybe<DecimalNullableFilter>;
  valueMonth5?: Maybe<DecimalNullableFilter>;
  valueMonth6?: Maybe<DecimalNullableFilter>;
  valueMonth7?: Maybe<DecimalNullableFilter>;
  valueMonth8?: Maybe<DecimalNullableFilter>;
  valueMonth9?: Maybe<DecimalNullableFilter>;
  valueMonth10?: Maybe<DecimalNullableFilter>;
  valueMonth11?: Maybe<DecimalNullableFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type PropertyManagementSystemAccountValueUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  valueMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PropertyManagementSystemAccountValueUpdateManyWithWhereWithoutAccountInput = {
  data: PropertyManagementSystemAccountValueUpdateManyMutationInput;
  where: PropertyManagementSystemAccountValueScalarWhereInput;
};

export type PropertyManagementSystemAccountValueUpdateManyWithWhereWithoutPropertyInput = {
  data: PropertyManagementSystemAccountValueUpdateManyMutationInput;
  where: PropertyManagementSystemAccountValueScalarWhereInput;
};

export type PropertyManagementSystemAccountValueUpdateManyWithoutAccountInput = {
  connect?: Maybe<Array<PropertyManagementSystemAccountValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyManagementSystemAccountValueCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<PropertyManagementSystemAccountValueCreateWithoutAccountInput>>;
  createMany?: Maybe<PropertyManagementSystemAccountValueCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<PropertyManagementSystemAccountValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PropertyManagementSystemAccountValueScalarWhereInput>>;
  disconnect?: Maybe<Array<PropertyManagementSystemAccountValueWhereUniqueInput>>;
  set?: Maybe<Array<PropertyManagementSystemAccountValueWhereUniqueInput>>;
  update?: Maybe<Array<PropertyManagementSystemAccountValueUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<PropertyManagementSystemAccountValueUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<PropertyManagementSystemAccountValueUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<PropertyManagementSystemAccountValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyManagementSystemAccountValueCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PropertyManagementSystemAccountValueCreateWithoutPropertyInput>>;
  createMany?: Maybe<PropertyManagementSystemAccountValueCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<PropertyManagementSystemAccountValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PropertyManagementSystemAccountValueScalarWhereInput>>;
  disconnect?: Maybe<Array<PropertyManagementSystemAccountValueWhereUniqueInput>>;
  set?: Maybe<Array<PropertyManagementSystemAccountValueWhereUniqueInput>>;
  update?: Maybe<Array<PropertyManagementSystemAccountValueUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<PropertyManagementSystemAccountValueUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<PropertyManagementSystemAccountValueUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type PropertyManagementSystemAccountValueUpdateWithWhereUniqueWithoutAccountInput = {
  data: PropertyManagementSystemAccountValueUpdateWithoutAccountInput;
  where: PropertyManagementSystemAccountValueWhereUniqueInput;
};

export type PropertyManagementSystemAccountValueUpdateWithWhereUniqueWithoutPropertyInput = {
  data: PropertyManagementSystemAccountValueUpdateWithoutPropertyInput;
  where: PropertyManagementSystemAccountValueWhereUniqueInput;
};

export type PropertyManagementSystemAccountValueUpdateWithoutAccountInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutPropertyManagementSystemAccountValuesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  valueMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PropertyManagementSystemAccountValueUpdateWithoutPropertyInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutPropertyManagementSystemAccountValuesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  valueMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PropertyManagementSystemAccountValueUpsertWithWhereUniqueWithoutAccountInput = {
  create: PropertyManagementSystemAccountValueCreateWithoutAccountInput;
  update: PropertyManagementSystemAccountValueUpdateWithoutAccountInput;
  where: PropertyManagementSystemAccountValueWhereUniqueInput;
};

export type PropertyManagementSystemAccountValueUpsertWithWhereUniqueWithoutPropertyInput = {
  create: PropertyManagementSystemAccountValueCreateWithoutPropertyInput;
  update: PropertyManagementSystemAccountValueUpdateWithoutPropertyInput;
  where: PropertyManagementSystemAccountValueWhereUniqueInput;
};

export type PropertyManagementSystemAccountValueWhereInput = {
  AND?: Maybe<Array<PropertyManagementSystemAccountValueWhereInput>>;
  NOT?: Maybe<Array<PropertyManagementSystemAccountValueWhereInput>>;
  OR?: Maybe<Array<PropertyManagementSystemAccountValueWhereInput>>;
  account?: Maybe<AccountV2RelationFilter>;
  accountId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  valueMonth0?: Maybe<DecimalNullableFilter>;
  valueMonth1?: Maybe<DecimalNullableFilter>;
  valueMonth2?: Maybe<DecimalNullableFilter>;
  valueMonth3?: Maybe<DecimalNullableFilter>;
  valueMonth4?: Maybe<DecimalNullableFilter>;
  valueMonth5?: Maybe<DecimalNullableFilter>;
  valueMonth6?: Maybe<DecimalNullableFilter>;
  valueMonth7?: Maybe<DecimalNullableFilter>;
  valueMonth8?: Maybe<DecimalNullableFilter>;
  valueMonth9?: Maybe<DecimalNullableFilter>;
  valueMonth10?: Maybe<DecimalNullableFilter>;
  valueMonth11?: Maybe<DecimalNullableFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type PropertyManagementSystemAccountValueWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  propertyId_accountId_year_versionType?: Maybe<PropertyManagementSystemAccountValuePropertyIdAccountIdYearVersionTypeCompoundUniqueInput>;
};

export type PropertyMaxAggregate = {
  __typename?: 'PropertyMaxAggregate';
  address?: Maybe<Scalars['String']>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccountsId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  marketName?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  submarketName?: Maybe<Scalars['String']>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PropertyMetrics = {
  metrics: Array<MetricInput>;
  propertyId: Scalars['String'];
};

export type PropertyMinAggregate = {
  __typename?: 'PropertyMinAggregate';
  address?: Maybe<Scalars['String']>;
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  chartOfAccountsId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  marketName?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  propertyManagementSystem?: Maybe<PropertyManagementSystem>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  submarketName?: Maybe<Scalars['String']>;
  unitCountTmp?: Maybe<Scalars['Int']>;
  unitLevelModelingEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PropertyOrderByInput = {
  address?: Maybe<SortOrder>;
  cappedRenewalIncreasePercent?: Maybe<SortOrder>;
  chartOfAccountsId?: Maybe<SortOrder>;
  clientId?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  fiscalYearStartMonthIndex?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isApproved?: Maybe<SortOrder>;
  isDeactivated?: Maybe<SortOrder>;
  marketName?: Maybe<SortOrder>;
  modelId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  propertyManagementSystem?: Maybe<SortOrder>;
  reforecastStartMonthIndex?: Maybe<SortOrder>;
  reforecastYear?: Maybe<SortOrder>;
  sqFtTmp?: Maybe<SortOrder>;
  submarketName?: Maybe<SortOrder>;
  unitCountTmp?: Maybe<SortOrder>;
  unitLevelModelingEnabled?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PropertyPerformanceModel = {
  __typename?: 'PropertyPerformanceModel';
  financial_summary: FinancialSummaryModel;
  rent: RentSummaryModel;
};

export type PropertyRelationFilter = {
  is?: Maybe<PropertyWhereInput>;
  isNot?: Maybe<PropertyWhereInput>;
};

export type PropertyRevenueAssumptionsStatusViewModel = {
  __typename?: 'PropertyRevenueAssumptionsStatusViewModel';
  hasAssumptions: Scalars['Boolean'];
  propertyId: Scalars['ID'];
  propertyName: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export type PropertyRevenueConfiguration = {
  __typename?: 'PropertyRevenueConfiguration';
  budgetYear: Scalars['Int'];
  id: Scalars['String'];
  isBudgetLocked: Scalars['Boolean'];
  isReforecastLocked: Scalars['Boolean'];
  propertyId: Scalars['String'];
  revenueModel: RevenueModelType;
};

export type PropertyRevenueConfigurationCreateManyPropertyInput = {
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  isBudgetLocked?: Maybe<Scalars['Boolean']>;
  isReforecastLocked?: Maybe<Scalars['Boolean']>;
  revenueModel: RevenueModelType;
};

export type PropertyRevenueConfigurationCreateManyPropertyInputEnvelope = {
  data: Array<PropertyRevenueConfigurationCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PropertyRevenueConfigurationCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<PropertyRevenueConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyRevenueConfigurationCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PropertyRevenueConfigurationCreateWithoutPropertyInput>>;
  createMany?: Maybe<PropertyRevenueConfigurationCreateManyPropertyInputEnvelope>;
};

export type PropertyRevenueConfigurationCreateOrConnectWithoutPropertyInput = {
  create: PropertyRevenueConfigurationCreateWithoutPropertyInput;
  where: PropertyRevenueConfigurationWhereUniqueInput;
};

export type PropertyRevenueConfigurationCreateWithoutPropertyInput = {
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  isBudgetLocked?: Maybe<Scalars['Boolean']>;
  isReforecastLocked?: Maybe<Scalars['Boolean']>;
  revenueModel: RevenueModelType;
};

export type PropertyRevenueConfigurationListRelationFilter = {
  every?: Maybe<PropertyRevenueConfigurationWhereInput>;
  none?: Maybe<PropertyRevenueConfigurationWhereInput>;
  some?: Maybe<PropertyRevenueConfigurationWhereInput>;
};

export type PropertyRevenueConfigurationOrderByInput = {
  budgetYear?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isBudgetLocked?: Maybe<SortOrder>;
  isReforecastLocked?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  revenueModel?: Maybe<SortOrder>;
};

export type PropertyRevenueConfigurationPropertyIdBudgetYearCompoundUniqueInput = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['String'];
};

export enum PropertyRevenueConfigurationScalarFieldEnum {
  BudgetYear = 'budgetYear',
  Id = 'id',
  IsBudgetLocked = 'isBudgetLocked',
  IsReforecastLocked = 'isReforecastLocked',
  PropertyId = 'propertyId',
  RevenueModel = 'revenueModel'
}

export type PropertyRevenueConfigurationScalarWhereInput = {
  AND?: Maybe<Array<PropertyRevenueConfigurationScalarWhereInput>>;
  NOT?: Maybe<Array<PropertyRevenueConfigurationScalarWhereInput>>;
  OR?: Maybe<Array<PropertyRevenueConfigurationScalarWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  isBudgetLocked?: Maybe<BoolFilter>;
  isReforecastLocked?: Maybe<BoolFilter>;
  propertyId?: Maybe<StringFilter>;
  revenueModel?: Maybe<EnumRevenueModelTypeFilter>;
};

export type PropertyRevenueConfigurationUpdateManyMutationInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isBudgetLocked?: Maybe<BoolFieldUpdateOperationsInput>;
  isReforecastLocked?: Maybe<BoolFieldUpdateOperationsInput>;
  revenueModel?: Maybe<EnumRevenueModelTypeFieldUpdateOperationsInput>;
};

export type PropertyRevenueConfigurationUpdateManyWithWhereWithoutPropertyInput = {
  data: PropertyRevenueConfigurationUpdateManyMutationInput;
  where: PropertyRevenueConfigurationScalarWhereInput;
};

export type PropertyRevenueConfigurationUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<PropertyRevenueConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyRevenueConfigurationCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<PropertyRevenueConfigurationCreateWithoutPropertyInput>>;
  createMany?: Maybe<PropertyRevenueConfigurationCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<PropertyRevenueConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PropertyRevenueConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<PropertyRevenueConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<PropertyRevenueConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<PropertyRevenueConfigurationUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<PropertyRevenueConfigurationUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<PropertyRevenueConfigurationUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type PropertyRevenueConfigurationUpdateWithWhereUniqueWithoutPropertyInput = {
  data: PropertyRevenueConfigurationUpdateWithoutPropertyInput;
  where: PropertyRevenueConfigurationWhereUniqueInput;
};

export type PropertyRevenueConfigurationUpdateWithoutPropertyInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isBudgetLocked?: Maybe<BoolFieldUpdateOperationsInput>;
  isReforecastLocked?: Maybe<BoolFieldUpdateOperationsInput>;
  revenueModel?: Maybe<EnumRevenueModelTypeFieldUpdateOperationsInput>;
};

export type PropertyRevenueConfigurationUpsertWithWhereUniqueWithoutPropertyInput = {
  create: PropertyRevenueConfigurationCreateWithoutPropertyInput;
  update: PropertyRevenueConfigurationUpdateWithoutPropertyInput;
  where: PropertyRevenueConfigurationWhereUniqueInput;
};

export type PropertyRevenueConfigurationWhereInput = {
  AND?: Maybe<Array<PropertyRevenueConfigurationWhereInput>>;
  NOT?: Maybe<Array<PropertyRevenueConfigurationWhereInput>>;
  OR?: Maybe<Array<PropertyRevenueConfigurationWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  isBudgetLocked?: Maybe<BoolFilter>;
  isReforecastLocked?: Maybe<BoolFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  revenueModel?: Maybe<EnumRevenueModelTypeFilter>;
};

export type PropertyRevenueConfigurationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  propertyId_budgetYear?: Maybe<PropertyRevenueConfigurationPropertyIdBudgetYearCompoundUniqueInput>;
};

export enum PropertyScalarFieldEnum {
  Address = 'address',
  CappedRenewalIncreasePercent = 'cappedRenewalIncreasePercent',
  ChartOfAccountsId = 'chartOfAccountsId',
  ClientId = 'clientId',
  Code = 'code',
  CreatedAt = 'createdAt',
  DisplayName = 'displayName',
  ExternalId = 'externalId',
  FiscalYearStartMonthIndex = 'fiscalYearStartMonthIndex',
  Id = 'id',
  IsApproved = 'isApproved',
  IsDeactivated = 'isDeactivated',
  MarketName = 'marketName',
  ModelId = 'modelId',
  Name = 'name',
  PropertyManagementSystem = 'propertyManagementSystem',
  ReforecastStartMonthIndex = 'reforecastStartMonthIndex',
  ReforecastYear = 'reforecastYear',
  SqFtTmp = 'sqFtTmp',
  SubmarketName = 'submarketName',
  UnitCountTmp = 'unitCountTmp',
  UnitLevelModelingEnabled = 'unitLevelModelingEnabled',
  UpdatedAt = 'updatedAt'
}

export type PropertyScalarWhereInput = {
  AND?: Maybe<Array<PropertyScalarWhereInput>>;
  NOT?: Maybe<Array<PropertyScalarWhereInput>>;
  OR?: Maybe<Array<PropertyScalarWhereInput>>;
  address?: Maybe<StringNullableFilter>;
  cappedRenewalIncreasePercent?: Maybe<DecimalNullableFilter>;
  chartOfAccountsId?: Maybe<StringNullableFilter>;
  clientId?: Maybe<StringFilter>;
  code?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  displayName?: Maybe<StringNullableFilter>;
  externalId?: Maybe<StringNullableFilter>;
  fiscalYearStartMonthIndex?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  isApproved?: Maybe<BoolFilter>;
  isDeactivated?: Maybe<BoolFilter>;
  marketName?: Maybe<StringNullableFilter>;
  modelId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  propertyManagementSystem?: Maybe<EnumPropertyManagementSystemNullableFilter>;
  reforecastStartMonthIndex?: Maybe<IntFilter>;
  reforecastYear?: Maybe<IntFilter>;
  sqFtTmp?: Maybe<DecimalNullableFilter>;
  submarketName?: Maybe<StringNullableFilter>;
  unitCountTmp?: Maybe<IntNullableFilter>;
  unitLevelModelingEnabled?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PropertyScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<PropertyScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<PropertyScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<PropertyScalarWhereWithAggregatesInput>>;
  address?: Maybe<StringNullableWithAggregatesFilter>;
  cappedRenewalIncreasePercent?: Maybe<DecimalNullableWithAggregatesFilter>;
  chartOfAccountsId?: Maybe<StringNullableWithAggregatesFilter>;
  clientId?: Maybe<StringWithAggregatesFilter>;
  code?: Maybe<StringNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  displayName?: Maybe<StringNullableWithAggregatesFilter>;
  externalId?: Maybe<StringNullableWithAggregatesFilter>;
  fiscalYearStartMonthIndex?: Maybe<IntWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  isApproved?: Maybe<BoolWithAggregatesFilter>;
  isDeactivated?: Maybe<BoolWithAggregatesFilter>;
  marketName?: Maybe<StringNullableWithAggregatesFilter>;
  modelId?: Maybe<StringNullableWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  propertyManagementSystem?: Maybe<EnumPropertyManagementSystemNullableWithAggregatesFilter>;
  reforecastStartMonthIndex?: Maybe<IntWithAggregatesFilter>;
  reforecastYear?: Maybe<IntWithAggregatesFilter>;
  sqFtTmp?: Maybe<DecimalNullableWithAggregatesFilter>;
  submarketName?: Maybe<StringNullableWithAggregatesFilter>;
  unitCountTmp?: Maybe<IntNullableWithAggregatesFilter>;
  unitLevelModelingEnabled?: Maybe<BoolWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
};

export type PropertySumAggregate = {
  __typename?: 'PropertySumAggregate';
  cappedRenewalIncreasePercent?: Maybe<Scalars['Decimal']>;
  fiscalYearStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastStartMonthIndex?: Maybe<Scalars['Int']>;
  reforecastYear?: Maybe<Scalars['Int']>;
  sqFtTmp?: Maybe<Scalars['Decimal']>;
  unitCountTmp?: Maybe<Scalars['Int']>;
};

export type PropertyUnitCountResult = {
  __typename?: 'PropertyUnitCountResult';
  count: Scalars['Int'];
  propertyId: Scalars['String'];
  sqft: Scalars['Int'];
  unitTypeId: Scalars['String'];
  unitTypeName: Scalars['String'];
};

export type PropertyUnitLevelConfiguration = {
  __typename?: 'PropertyUnitLevelConfiguration';
  concessionsAccountId: Scalars['String'];
  propertyId: Scalars['String'];
  renovationCostAccountId: Scalars['String'];
  unused?: Maybe<Scalars['Boolean']>;
};

export type PropertyUnitLevelConfigurationCreateManyConcessionsAccountInput = {
  propertyId: Scalars['String'];
  renovationCostAccountId: Scalars['String'];
  unused?: Maybe<Scalars['Boolean']>;
};

export type PropertyUnitLevelConfigurationCreateManyConcessionsAccountInputEnvelope = {
  data: Array<PropertyUnitLevelConfigurationCreateManyConcessionsAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PropertyUnitLevelConfigurationCreateManyRenovationCostAccountInput = {
  concessionsAccountId: Scalars['String'];
  propertyId: Scalars['String'];
  unused?: Maybe<Scalars['Boolean']>;
};

export type PropertyUnitLevelConfigurationCreateManyRenovationCostAccountInputEnvelope = {
  data: Array<PropertyUnitLevelConfigurationCreateManyRenovationCostAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PropertyUnitLevelConfigurationCreateNestedManyWithoutConcessionsAccountInput = {
  connect?: Maybe<Array<PropertyUnitLevelConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyUnitLevelConfigurationCreateOrConnectWithoutConcessionsAccountInput>>;
  create?: Maybe<Array<PropertyUnitLevelConfigurationCreateWithoutConcessionsAccountInput>>;
  createMany?: Maybe<PropertyUnitLevelConfigurationCreateManyConcessionsAccountInputEnvelope>;
};

export type PropertyUnitLevelConfigurationCreateNestedManyWithoutRenovationCostAccountInput = {
  connect?: Maybe<Array<PropertyUnitLevelConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyUnitLevelConfigurationCreateOrConnectWithoutRenovationCostAccountInput>>;
  create?: Maybe<Array<PropertyUnitLevelConfigurationCreateWithoutRenovationCostAccountInput>>;
  createMany?: Maybe<PropertyUnitLevelConfigurationCreateManyRenovationCostAccountInputEnvelope>;
};

export type PropertyUnitLevelConfigurationCreateNestedOneWithoutPropertyInput = {
  connect?: Maybe<PropertyUnitLevelConfigurationWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyUnitLevelConfigurationCreateOrConnectWithoutPropertyInput>;
  create?: Maybe<PropertyUnitLevelConfigurationCreateWithoutPropertyInput>;
};

export type PropertyUnitLevelConfigurationCreateOrConnectWithoutConcessionsAccountInput = {
  create: PropertyUnitLevelConfigurationCreateWithoutConcessionsAccountInput;
  where: PropertyUnitLevelConfigurationWhereUniqueInput;
};

export type PropertyUnitLevelConfigurationCreateOrConnectWithoutPropertyInput = {
  create: PropertyUnitLevelConfigurationCreateWithoutPropertyInput;
  where: PropertyUnitLevelConfigurationWhereUniqueInput;
};

export type PropertyUnitLevelConfigurationCreateOrConnectWithoutRenovationCostAccountInput = {
  create: PropertyUnitLevelConfigurationCreateWithoutRenovationCostAccountInput;
  where: PropertyUnitLevelConfigurationWhereUniqueInput;
};

export type PropertyUnitLevelConfigurationCreateWithoutConcessionsAccountInput = {
  property: PropertyCreateNestedOneWithoutUnitLevelConfigurationInput;
  renovationCostAccount: AccountV2CreateNestedOneWithoutPropertyUnitLevelConfigurationsInput;
  unused?: Maybe<Scalars['Boolean']>;
};

export type PropertyUnitLevelConfigurationCreateWithoutPropertyInput = {
  concessionsAccount: AccountV2CreateNestedOneWithoutPropertyUnitLevelConcessionConfigurationsInput;
  renovationCostAccount: AccountV2CreateNestedOneWithoutPropertyUnitLevelConfigurationsInput;
  unused?: Maybe<Scalars['Boolean']>;
};

export type PropertyUnitLevelConfigurationCreateWithoutRenovationCostAccountInput = {
  concessionsAccount: AccountV2CreateNestedOneWithoutPropertyUnitLevelConcessionConfigurationsInput;
  property: PropertyCreateNestedOneWithoutUnitLevelConfigurationInput;
  unused?: Maybe<Scalars['Boolean']>;
};

export type PropertyUnitLevelConfigurationListRelationFilter = {
  every?: Maybe<PropertyUnitLevelConfigurationWhereInput>;
  none?: Maybe<PropertyUnitLevelConfigurationWhereInput>;
  some?: Maybe<PropertyUnitLevelConfigurationWhereInput>;
};

export type PropertyUnitLevelConfigurationOrderByInput = {
  concessionsAccountId?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  renovationCostAccountId?: Maybe<SortOrder>;
  unused?: Maybe<SortOrder>;
};

export type PropertyUnitLevelConfigurationRelationFilter = {
  is?: Maybe<PropertyUnitLevelConfigurationWhereInput>;
  isNot?: Maybe<PropertyUnitLevelConfigurationWhereInput>;
};

export enum PropertyUnitLevelConfigurationScalarFieldEnum {
  ConcessionsAccountId = 'concessionsAccountId',
  PropertyId = 'propertyId',
  RenovationCostAccountId = 'renovationCostAccountId',
  Unused = 'unused'
}

export type PropertyUnitLevelConfigurationScalarWhereInput = {
  AND?: Maybe<Array<PropertyUnitLevelConfigurationScalarWhereInput>>;
  NOT?: Maybe<Array<PropertyUnitLevelConfigurationScalarWhereInput>>;
  OR?: Maybe<Array<PropertyUnitLevelConfigurationScalarWhereInput>>;
  concessionsAccountId?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  renovationCostAccountId?: Maybe<StringFilter>;
  unused?: Maybe<BoolNullableFilter>;
};

export type PropertyUnitLevelConfigurationUpdateManyMutationInput = {
  unused?: Maybe<NullableBoolFieldUpdateOperationsInput>;
};

export type PropertyUnitLevelConfigurationUpdateManyWithWhereWithoutConcessionsAccountInput = {
  data: PropertyUnitLevelConfigurationUpdateManyMutationInput;
  where: PropertyUnitLevelConfigurationScalarWhereInput;
};

export type PropertyUnitLevelConfigurationUpdateManyWithWhereWithoutRenovationCostAccountInput = {
  data: PropertyUnitLevelConfigurationUpdateManyMutationInput;
  where: PropertyUnitLevelConfigurationScalarWhereInput;
};

export type PropertyUnitLevelConfigurationUpdateManyWithoutConcessionsAccountInput = {
  connect?: Maybe<Array<PropertyUnitLevelConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyUnitLevelConfigurationCreateOrConnectWithoutConcessionsAccountInput>>;
  create?: Maybe<Array<PropertyUnitLevelConfigurationCreateWithoutConcessionsAccountInput>>;
  createMany?: Maybe<PropertyUnitLevelConfigurationCreateManyConcessionsAccountInputEnvelope>;
  delete?: Maybe<Array<PropertyUnitLevelConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PropertyUnitLevelConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<PropertyUnitLevelConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<PropertyUnitLevelConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<PropertyUnitLevelConfigurationUpdateWithWhereUniqueWithoutConcessionsAccountInput>>;
  updateMany?: Maybe<Array<PropertyUnitLevelConfigurationUpdateManyWithWhereWithoutConcessionsAccountInput>>;
  upsert?: Maybe<Array<PropertyUnitLevelConfigurationUpsertWithWhereUniqueWithoutConcessionsAccountInput>>;
};

export type PropertyUnitLevelConfigurationUpdateManyWithoutRenovationCostAccountInput = {
  connect?: Maybe<Array<PropertyUnitLevelConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyUnitLevelConfigurationCreateOrConnectWithoutRenovationCostAccountInput>>;
  create?: Maybe<Array<PropertyUnitLevelConfigurationCreateWithoutRenovationCostAccountInput>>;
  createMany?: Maybe<PropertyUnitLevelConfigurationCreateManyRenovationCostAccountInputEnvelope>;
  delete?: Maybe<Array<PropertyUnitLevelConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PropertyUnitLevelConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<PropertyUnitLevelConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<PropertyUnitLevelConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<PropertyUnitLevelConfigurationUpdateWithWhereUniqueWithoutRenovationCostAccountInput>>;
  updateMany?: Maybe<Array<PropertyUnitLevelConfigurationUpdateManyWithWhereWithoutRenovationCostAccountInput>>;
  upsert?: Maybe<Array<PropertyUnitLevelConfigurationUpsertWithWhereUniqueWithoutRenovationCostAccountInput>>;
};

export type PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput = {
  connect?: Maybe<PropertyUnitLevelConfigurationWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyUnitLevelConfigurationCreateOrConnectWithoutPropertyInput>;
  create?: Maybe<PropertyUnitLevelConfigurationCreateWithoutPropertyInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PropertyUnitLevelConfigurationUpdateWithoutPropertyInput>;
  upsert?: Maybe<PropertyUnitLevelConfigurationUpsertWithoutPropertyInput>;
};

export type PropertyUnitLevelConfigurationUpdateWithWhereUniqueWithoutConcessionsAccountInput = {
  data: PropertyUnitLevelConfigurationUpdateWithoutConcessionsAccountInput;
  where: PropertyUnitLevelConfigurationWhereUniqueInput;
};

export type PropertyUnitLevelConfigurationUpdateWithWhereUniqueWithoutRenovationCostAccountInput = {
  data: PropertyUnitLevelConfigurationUpdateWithoutRenovationCostAccountInput;
  where: PropertyUnitLevelConfigurationWhereUniqueInput;
};

export type PropertyUnitLevelConfigurationUpdateWithoutConcessionsAccountInput = {
  property?: Maybe<PropertyUpdateOneRequiredWithoutUnitLevelConfigurationInput>;
  renovationCostAccount?: Maybe<AccountV2UpdateOneRequiredWithoutPropertyUnitLevelConfigurationsInput>;
  unused?: Maybe<NullableBoolFieldUpdateOperationsInput>;
};

export type PropertyUnitLevelConfigurationUpdateWithoutPropertyInput = {
  concessionsAccount?: Maybe<AccountV2UpdateOneRequiredWithoutPropertyUnitLevelConcessionConfigurationsInput>;
  renovationCostAccount?: Maybe<AccountV2UpdateOneRequiredWithoutPropertyUnitLevelConfigurationsInput>;
  unused?: Maybe<NullableBoolFieldUpdateOperationsInput>;
};

export type PropertyUnitLevelConfigurationUpdateWithoutRenovationCostAccountInput = {
  concessionsAccount?: Maybe<AccountV2UpdateOneRequiredWithoutPropertyUnitLevelConcessionConfigurationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutUnitLevelConfigurationInput>;
  unused?: Maybe<NullableBoolFieldUpdateOperationsInput>;
};

export type PropertyUnitLevelConfigurationUpsertWithWhereUniqueWithoutConcessionsAccountInput = {
  create: PropertyUnitLevelConfigurationCreateWithoutConcessionsAccountInput;
  update: PropertyUnitLevelConfigurationUpdateWithoutConcessionsAccountInput;
  where: PropertyUnitLevelConfigurationWhereUniqueInput;
};

export type PropertyUnitLevelConfigurationUpsertWithWhereUniqueWithoutRenovationCostAccountInput = {
  create: PropertyUnitLevelConfigurationCreateWithoutRenovationCostAccountInput;
  update: PropertyUnitLevelConfigurationUpdateWithoutRenovationCostAccountInput;
  where: PropertyUnitLevelConfigurationWhereUniqueInput;
};

export type PropertyUnitLevelConfigurationUpsertWithoutPropertyInput = {
  create: PropertyUnitLevelConfigurationCreateWithoutPropertyInput;
  update: PropertyUnitLevelConfigurationUpdateWithoutPropertyInput;
};

export type PropertyUnitLevelConfigurationWhereInput = {
  AND?: Maybe<Array<PropertyUnitLevelConfigurationWhereInput>>;
  NOT?: Maybe<Array<PropertyUnitLevelConfigurationWhereInput>>;
  OR?: Maybe<Array<PropertyUnitLevelConfigurationWhereInput>>;
  concessionsAccount?: Maybe<AccountV2RelationFilter>;
  concessionsAccountId?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  renovationCostAccount?: Maybe<AccountV2RelationFilter>;
  renovationCostAccountId?: Maybe<StringFilter>;
  unused?: Maybe<BoolNullableFilter>;
};

export type PropertyUnitLevelConfigurationWhereUniqueInput = {
  propertyId?: Maybe<Scalars['String']>;
};

export type PropertyUpdateInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateManyMutationInput = {
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PropertyUpdateManyWithWhereWithoutChartOfAccountsInput = {
  data: PropertyUpdateManyMutationInput;
  where: PropertyScalarWhereInput;
};

export type PropertyUpdateManyWithWhereWithoutClientInput = {
  data: PropertyUpdateManyMutationInput;
  where: PropertyScalarWhereInput;
};

export type PropertyUpdateManyWithWhereWithoutModelInput = {
  data: PropertyUpdateManyMutationInput;
  where: PropertyScalarWhereInput;
};

export type PropertyUpdateManyWithoutChartOfAccountsInput = {
  connect?: Maybe<Array<PropertyWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyCreateOrConnectWithoutChartOfAccountsInput>>;
  create?: Maybe<Array<PropertyCreateWithoutChartOfAccountsInput>>;
  createMany?: Maybe<PropertyCreateManyChartOfAccountsInputEnvelope>;
  delete?: Maybe<Array<PropertyWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PropertyScalarWhereInput>>;
  disconnect?: Maybe<Array<PropertyWhereUniqueInput>>;
  set?: Maybe<Array<PropertyWhereUniqueInput>>;
  update?: Maybe<Array<PropertyUpdateWithWhereUniqueWithoutChartOfAccountsInput>>;
  updateMany?: Maybe<Array<PropertyUpdateManyWithWhereWithoutChartOfAccountsInput>>;
  upsert?: Maybe<Array<PropertyUpsertWithWhereUniqueWithoutChartOfAccountsInput>>;
};

export type PropertyUpdateManyWithoutClientInput = {
  connect?: Maybe<Array<PropertyWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<PropertyCreateWithoutClientInput>>;
  createMany?: Maybe<PropertyCreateManyClientInputEnvelope>;
  delete?: Maybe<Array<PropertyWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PropertyScalarWhereInput>>;
  disconnect?: Maybe<Array<PropertyWhereUniqueInput>>;
  set?: Maybe<Array<PropertyWhereUniqueInput>>;
  update?: Maybe<Array<PropertyUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<PropertyUpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<PropertyUpsertWithWhereUniqueWithoutClientInput>>;
};

export type PropertyUpdateManyWithoutModelInput = {
  connect?: Maybe<Array<PropertyWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PropertyCreateOrConnectWithoutModelInput>>;
  create?: Maybe<Array<PropertyCreateWithoutModelInput>>;
  createMany?: Maybe<PropertyCreateManyModelInputEnvelope>;
  delete?: Maybe<Array<PropertyWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PropertyScalarWhereInput>>;
  disconnect?: Maybe<Array<PropertyWhereUniqueInput>>;
  set?: Maybe<Array<PropertyWhereUniqueInput>>;
  update?: Maybe<Array<PropertyUpdateWithWhereUniqueWithoutModelInput>>;
  updateMany?: Maybe<Array<PropertyUpdateManyWithWhereWithoutModelInput>>;
  upsert?: Maybe<Array<PropertyUpsertWithWhereUniqueWithoutModelInput>>;
};

export type PropertyUpdateOneRequiredWithoutAccPercentageAssumptionsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutAccPercentageAssumptionsInput>;
  create?: Maybe<PropertyCreateWithoutAccPercentageAssumptionsInput>;
  update?: Maybe<PropertyUpdateWithoutAccPercentageAssumptionsInput>;
  upsert?: Maybe<PropertyUpsertWithoutAccPercentageAssumptionsInput>;
};

export type PropertyUpdateOneRequiredWithoutAccountPercentageDriversInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutAccountPercentageDriversInput>;
  create?: Maybe<PropertyCreateWithoutAccountPercentageDriversInput>;
  update?: Maybe<PropertyUpdateWithoutAccountPercentageDriversInput>;
  upsert?: Maybe<PropertyUpsertWithoutAccountPercentageDriversInput>;
};

export type PropertyUpdateOneRequiredWithoutAccountTopVendorsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutAccountTopVendorsInput>;
  create?: Maybe<PropertyCreateWithoutAccountTopVendorsInput>;
  update?: Maybe<PropertyUpdateWithoutAccountTopVendorsInput>;
  upsert?: Maybe<PropertyUpsertWithoutAccountTopVendorsInput>;
};

export type PropertyUpdateOneRequiredWithoutAccountTotalValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutAccountTotalValuesInput>;
  create?: Maybe<PropertyCreateWithoutAccountTotalValuesInput>;
  update?: Maybe<PropertyUpdateWithoutAccountTotalValuesInput>;
  upsert?: Maybe<PropertyUpsertWithoutAccountTotalValuesInput>;
};

export type PropertyUpdateOneRequiredWithoutAccountValueOverrideInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutAccountValueOverrideInput>;
  create?: Maybe<PropertyCreateWithoutAccountValueOverrideInput>;
  update?: Maybe<PropertyUpdateWithoutAccountValueOverrideInput>;
  upsert?: Maybe<PropertyUpsertWithoutAccountValueOverrideInput>;
};

export type PropertyUpdateOneRequiredWithoutAccountValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutAccountValuesInput>;
  create?: Maybe<PropertyCreateWithoutAccountValuesInput>;
  update?: Maybe<PropertyUpdateWithoutAccountValuesInput>;
  upsert?: Maybe<PropertyUpsertWithoutAccountValuesInput>;
};

export type PropertyUpdateOneRequiredWithoutAccountVarianceNotesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutAccountVarianceNotesInput>;
  create?: Maybe<PropertyCreateWithoutAccountVarianceNotesInput>;
  update?: Maybe<PropertyUpdateWithoutAccountVarianceNotesInput>;
  upsert?: Maybe<PropertyUpsertWithoutAccountVarianceNotesInput>;
};

export type PropertyUpdateOneRequiredWithoutBudgetCategoryTotalValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutBudgetCategoryTotalValuesInput>;
  create?: Maybe<PropertyCreateWithoutBudgetCategoryTotalValuesInput>;
  update?: Maybe<PropertyUpdateWithoutBudgetCategoryTotalValuesInput>;
  upsert?: Maybe<PropertyUpsertWithoutBudgetCategoryTotalValuesInput>;
};

export type PropertyUpdateOneRequiredWithoutBudgetCategoryValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutBudgetCategoryValuesInput>;
  create?: Maybe<PropertyCreateWithoutBudgetCategoryValuesInput>;
  update?: Maybe<PropertyUpdateWithoutBudgetCategoryValuesInput>;
  upsert?: Maybe<PropertyUpsertWithoutBudgetCategoryValuesInput>;
};

export type PropertyUpdateOneRequiredWithoutBudgetComponentTotalValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutBudgetComponentTotalValuesInput>;
  create?: Maybe<PropertyCreateWithoutBudgetComponentTotalValuesInput>;
  update?: Maybe<PropertyUpdateWithoutBudgetComponentTotalValuesInput>;
  upsert?: Maybe<PropertyUpsertWithoutBudgetComponentTotalValuesInput>;
};

export type PropertyUpdateOneRequiredWithoutBudgetComponentValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutBudgetComponentValuesInput>;
  create?: Maybe<PropertyCreateWithoutBudgetComponentValuesInput>;
  update?: Maybe<PropertyUpdateWithoutBudgetComponentValuesInput>;
  upsert?: Maybe<PropertyUpsertWithoutBudgetComponentValuesInput>;
};

export type PropertyUpdateOneRequiredWithoutCustomItemDriversInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutCustomItemDriversInput>;
  create?: Maybe<PropertyCreateWithoutCustomItemDriversInput>;
  update?: Maybe<PropertyUpdateWithoutCustomItemDriversInput>;
  upsert?: Maybe<PropertyUpsertWithoutCustomItemDriversInput>;
};

export type PropertyUpdateOneRequiredWithoutDriverAugmentsAccountPercentageInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutDriverAugmentsAccountPercentageInput>;
  create?: Maybe<PropertyCreateWithoutDriverAugmentsAccountPercentageInput>;
  update?: Maybe<PropertyUpdateWithoutDriverAugmentsAccountPercentageInput>;
  upsert?: Maybe<PropertyUpsertWithoutDriverAugmentsAccountPercentageInput>;
};

export type PropertyUpdateOneRequiredWithoutForecastedAccountValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutForecastedAccountValuesInput>;
  create?: Maybe<PropertyCreateWithoutForecastedAccountValuesInput>;
  update?: Maybe<PropertyUpdateWithoutForecastedAccountValuesInput>;
  upsert?: Maybe<PropertyUpsertWithoutForecastedAccountValuesInput>;
};

export type PropertyUpdateOneRequiredWithoutGrowthDriversInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutGrowthDriversInput>;
  create?: Maybe<PropertyCreateWithoutGrowthDriversInput>;
  update?: Maybe<PropertyUpdateWithoutGrowthDriversInput>;
  upsert?: Maybe<PropertyUpsertWithoutGrowthDriversInput>;
};

export type PropertyUpdateOneRequiredWithoutLockedAccountsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutLockedAccountsInput>;
  create?: Maybe<PropertyCreateWithoutLockedAccountsInput>;
  update?: Maybe<PropertyUpdateWithoutLockedAccountsInput>;
  upsert?: Maybe<PropertyUpsertWithoutLockedAccountsInput>;
};

export type PropertyUpdateOneRequiredWithoutNoteInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutNoteInput>;
  create?: Maybe<PropertyCreateWithoutNoteInput>;
  update?: Maybe<PropertyUpdateWithoutNoteInput>;
  upsert?: Maybe<PropertyUpsertWithoutNoteInput>;
};

export type PropertyUpdateOneRequiredWithoutOperationalDriversInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutOperationalDriversInput>;
  create?: Maybe<PropertyCreateWithoutOperationalDriversInput>;
  update?: Maybe<PropertyUpdateWithoutOperationalDriversInput>;
  upsert?: Maybe<PropertyUpsertWithoutOperationalDriversInput>;
};

export type PropertyUpdateOneRequiredWithoutOperationalMetricAssumptionsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutOperationalMetricAssumptionsInput>;
  create?: Maybe<PropertyCreateWithoutOperationalMetricAssumptionsInput>;
  update?: Maybe<PropertyUpdateWithoutOperationalMetricAssumptionsInput>;
  upsert?: Maybe<PropertyUpsertWithoutOperationalMetricAssumptionsInput>;
};

export type PropertyUpdateOneRequiredWithoutOperationalMetricsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutOperationalMetricsInput>;
  create?: Maybe<PropertyCreateWithoutOperationalMetricsInput>;
  update?: Maybe<PropertyUpdateWithoutOperationalMetricsInput>;
  upsert?: Maybe<PropertyUpsertWithoutOperationalMetricsInput>;
};

export type PropertyUpdateOneRequiredWithoutOriginalRevenueMarketRentOverridesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutOriginalRevenueMarketRentOverridesInput>;
  create?: Maybe<PropertyCreateWithoutOriginalRevenueMarketRentOverridesInput>;
  update?: Maybe<PropertyUpdateWithoutOriginalRevenueMarketRentOverridesInput>;
  upsert?: Maybe<PropertyUpsertWithoutOriginalRevenueMarketRentOverridesInput>;
};

export type PropertyUpdateOneRequiredWithoutPayrollEmployeeAttributionsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPayrollEmployeeAttributionsInput>;
  create?: Maybe<PropertyCreateWithoutPayrollEmployeeAttributionsInput>;
  update?: Maybe<PropertyUpdateWithoutPayrollEmployeeAttributionsInput>;
  upsert?: Maybe<PropertyUpsertWithoutPayrollEmployeeAttributionsInput>;
};

export type PropertyUpdateOneRequiredWithoutPayrollPositionCompensationAccountsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPayrollPositionCompensationAccountsInput>;
  create?: Maybe<PropertyCreateWithoutPayrollPositionCompensationAccountsInput>;
  update?: Maybe<PropertyUpdateWithoutPayrollPositionCompensationAccountsInput>;
  upsert?: Maybe<PropertyUpsertWithoutPayrollPositionCompensationAccountsInput>;
};

export type PropertyUpdateOneRequiredWithoutPayrollPositionCompensationAccountsV2Input = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPayrollPositionCompensationAccountsV2Input>;
  create?: Maybe<PropertyCreateWithoutPayrollPositionCompensationAccountsV2Input>;
  update?: Maybe<PropertyUpdateWithoutPayrollPositionCompensationAccountsV2Input>;
  upsert?: Maybe<PropertyUpsertWithoutPayrollPositionCompensationAccountsV2Input>;
};

export type PropertyUpdateOneRequiredWithoutPayrollPositionCompensationItemAccountsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPayrollPositionCompensationItemAccountsInput>;
  create?: Maybe<PropertyCreateWithoutPayrollPositionCompensationItemAccountsInput>;
  update?: Maybe<PropertyUpdateWithoutPayrollPositionCompensationItemAccountsInput>;
  upsert?: Maybe<PropertyUpsertWithoutPayrollPositionCompensationItemAccountsInput>;
};

export type PropertyUpdateOneRequiredWithoutPayrollPositionCompensationItemAccountsV2Input = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPayrollPositionCompensationItemAccountsV2Input>;
  create?: Maybe<PropertyCreateWithoutPayrollPositionCompensationItemAccountsV2Input>;
  update?: Maybe<PropertyUpdateWithoutPayrollPositionCompensationItemAccountsV2Input>;
  upsert?: Maybe<PropertyUpsertWithoutPayrollPositionCompensationItemAccountsV2Input>;
};

export type PropertyUpdateOneRequiredWithoutPmsActualMetricOverrideAverageResidentialRentsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPmsActualMetricOverrideAverageResidentialRentsInput>;
  create?: Maybe<PropertyCreateWithoutPmsActualMetricOverrideAverageResidentialRentsInput>;
  update?: Maybe<PropertyUpdateWithoutPmsActualMetricOverrideAverageResidentialRentsInput>;
  upsert?: Maybe<PropertyUpsertWithoutPmsActualMetricOverrideAverageResidentialRentsInput>;
};

export type PropertyUpdateOneRequiredWithoutPmsActualMetricOverrideOccupancyRatesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPmsActualMetricOverrideOccupancyRatesInput>;
  create?: Maybe<PropertyCreateWithoutPmsActualMetricOverrideOccupancyRatesInput>;
  update?: Maybe<PropertyUpdateWithoutPmsActualMetricOverrideOccupancyRatesInput>;
  upsert?: Maybe<PropertyUpsertWithoutPmsActualMetricOverrideOccupancyRatesInput>;
};

export type PropertyUpdateOneRequiredWithoutPmsActualMetricOverrideUnitCountsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPmsActualMetricOverrideUnitCountsInput>;
  create?: Maybe<PropertyCreateWithoutPmsActualMetricOverrideUnitCountsInput>;
  update?: Maybe<PropertyUpdateWithoutPmsActualMetricOverrideUnitCountsInput>;
  upsert?: Maybe<PropertyUpsertWithoutPmsActualMetricOverrideUnitCountsInput>;
};

export type PropertyUpdateOneRequiredWithoutPropertyClientBudgetPlansInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPropertyClientBudgetPlansInput>;
  create?: Maybe<PropertyCreateWithoutPropertyClientBudgetPlansInput>;
  update?: Maybe<PropertyUpdateWithoutPropertyClientBudgetPlansInput>;
  upsert?: Maybe<PropertyUpsertWithoutPropertyClientBudgetPlansInput>;
};

export type PropertyUpdateOneRequiredWithoutPropertyManagementSystemAccountValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPropertyManagementSystemAccountValuesInput>;
  create?: Maybe<PropertyCreateWithoutPropertyManagementSystemAccountValuesInput>;
  update?: Maybe<PropertyUpdateWithoutPropertyManagementSystemAccountValuesInput>;
  upsert?: Maybe<PropertyUpsertWithoutPropertyManagementSystemAccountValuesInput>;
};

export type PropertyUpdateOneRequiredWithoutRenovationPackagesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutRenovationPackagesInput>;
  create?: Maybe<PropertyCreateWithoutRenovationPackagesInput>;
  update?: Maybe<PropertyUpdateWithoutRenovationPackagesInput>;
  upsert?: Maybe<PropertyUpsertWithoutRenovationPackagesInput>;
};

export type PropertyUpdateOneRequiredWithoutRevenueDriverAssumptionsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutRevenueDriverAssumptionsInput>;
  create?: Maybe<PropertyCreateWithoutRevenueDriverAssumptionsInput>;
  update?: Maybe<PropertyUpdateWithoutRevenueDriverAssumptionsInput>;
  upsert?: Maybe<PropertyUpsertWithoutRevenueDriverAssumptionsInput>;
};

export type PropertyUpdateOneRequiredWithoutRevenueDriversInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutRevenueDriversInput>;
  create?: Maybe<PropertyCreateWithoutRevenueDriversInput>;
  update?: Maybe<PropertyUpdateWithoutRevenueDriversInput>;
  upsert?: Maybe<PropertyUpsertWithoutRevenueDriversInput>;
};

export type PropertyUpdateOneRequiredWithoutRevenueMetricsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutRevenueMetricsInput>;
  create?: Maybe<PropertyCreateWithoutRevenueMetricsInput>;
  update?: Maybe<PropertyUpdateWithoutRevenueMetricsInput>;
  upsert?: Maybe<PropertyUpsertWithoutRevenueMetricsInput>;
};

export type PropertyUpdateOneRequiredWithoutSharedReportLinksInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutSharedReportLinksInput>;
  create?: Maybe<PropertyCreateWithoutSharedReportLinksInput>;
  update?: Maybe<PropertyUpdateWithoutSharedReportLinksInput>;
  upsert?: Maybe<PropertyUpsertWithoutSharedReportLinksInput>;
};

export type PropertyUpdateOneRequiredWithoutSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingsInput>;
  create?: Maybe<PropertyCreateWithoutSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingsInput>;
  update?: Maybe<PropertyUpdateWithoutSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingsInput>;
  upsert?: Maybe<PropertyUpsertWithoutSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingsInput>;
};

export type PropertyUpdateOneRequiredWithoutStarredAccountsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutStarredAccountsInput>;
  create?: Maybe<PropertyCreateWithoutStarredAccountsInput>;
  update?: Maybe<PropertyUpdateWithoutStarredAccountsInput>;
  upsert?: Maybe<PropertyUpsertWithoutStarredAccountsInput>;
};

export type PropertyUpdateOneRequiredWithoutTransactionsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutTransactionsInput>;
  create?: Maybe<PropertyCreateWithoutTransactionsInput>;
  update?: Maybe<PropertyUpdateWithoutTransactionsInput>;
  upsert?: Maybe<PropertyUpsertWithoutTransactionsInput>;
};

export type PropertyUpdateOneRequiredWithoutUnitEventsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutUnitEventsInput>;
  create?: Maybe<PropertyCreateWithoutUnitEventsInput>;
  update?: Maybe<PropertyUpdateWithoutUnitEventsInput>;
  upsert?: Maybe<PropertyUpsertWithoutUnitEventsInput>;
};

export type PropertyUpdateOneRequiredWithoutUnitLevelConfigurationInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutUnitLevelConfigurationInput>;
  create?: Maybe<PropertyCreateWithoutUnitLevelConfigurationInput>;
  update?: Maybe<PropertyUpdateWithoutUnitLevelConfigurationInput>;
  upsert?: Maybe<PropertyUpsertWithoutUnitLevelConfigurationInput>;
};

export type PropertyUpdateOneRequiredWithoutUnitTypeScheduleValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutUnitTypeScheduleValuesInput>;
  create?: Maybe<PropertyCreateWithoutUnitTypeScheduleValuesInput>;
  update?: Maybe<PropertyUpdateWithoutUnitTypeScheduleValuesInput>;
  upsert?: Maybe<PropertyUpsertWithoutUnitTypeScheduleValuesInput>;
};

export type PropertyUpdateOneRequiredWithoutUnitTypesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutUnitTypesInput>;
  create?: Maybe<PropertyCreateWithoutUnitTypesInput>;
  update?: Maybe<PropertyUpdateWithoutUnitTypesInput>;
  upsert?: Maybe<PropertyUpsertWithoutUnitTypesInput>;
};

export type PropertyUpdateOneRequiredWithoutUnitsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutUnitsInput>;
  create?: Maybe<PropertyCreateWithoutUnitsInput>;
  update?: Maybe<PropertyUpdateWithoutUnitsInput>;
  upsert?: Maybe<PropertyUpsertWithoutUnitsInput>;
};

export type PropertyUpdateOneRequiredWithoutWorksheetAssumptionsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutWorksheetAssumptionsInput>;
  create?: Maybe<PropertyCreateWithoutWorksheetAssumptionsInput>;
  update?: Maybe<PropertyUpdateWithoutWorksheetAssumptionsInput>;
  upsert?: Maybe<PropertyUpsertWithoutWorksheetAssumptionsInput>;
};

export type PropertyUpdateOneRequiredWithoutWorksheetsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutWorksheetsInput>;
  create?: Maybe<PropertyCreateWithoutWorksheetsInput>;
  update?: Maybe<PropertyUpdateWithoutWorksheetsInput>;
  upsert?: Maybe<PropertyUpsertWithoutWorksheetsInput>;
};

export type PropertyUpdateOneWithoutDriverAssignmentsInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutDriverAssignmentsInput>;
  create?: Maybe<PropertyCreateWithoutDriverAssignmentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PropertyUpdateWithoutDriverAssignmentsInput>;
  upsert?: Maybe<PropertyUpsertWithoutDriverAssignmentsInput>;
};

export type PropertyUpdateOneWithoutPayrollDefaultCompensationBaseValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPayrollDefaultCompensationBaseValuesInput>;
  create?: Maybe<PropertyCreateWithoutPayrollDefaultCompensationBaseValuesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PropertyUpdateWithoutPayrollDefaultCompensationBaseValuesInput>;
  upsert?: Maybe<PropertyUpsertWithoutPayrollDefaultCompensationBaseValuesInput>;
};

export type PropertyUpdateOneWithoutPayrollDefaultCompensationBonusValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPayrollDefaultCompensationBonusValuesInput>;
  create?: Maybe<PropertyCreateWithoutPayrollDefaultCompensationBonusValuesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PropertyUpdateWithoutPayrollDefaultCompensationBonusValuesInput>;
  upsert?: Maybe<PropertyUpsertWithoutPayrollDefaultCompensationBonusValuesInput>;
};

export type PropertyUpdateOneWithoutPayrollDefaultCompensationItemValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPayrollDefaultCompensationItemValuesInput>;
  create?: Maybe<PropertyCreateWithoutPayrollDefaultCompensationItemValuesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PropertyUpdateWithoutPayrollDefaultCompensationItemValuesInput>;
  upsert?: Maybe<PropertyUpsertWithoutPayrollDefaultCompensationItemValuesInput>;
};

export type PropertyUpdateOneWithoutPayrollDefaultCompensationOvertimeValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPayrollDefaultCompensationOvertimeValuesInput>;
  create?: Maybe<PropertyCreateWithoutPayrollDefaultCompensationOvertimeValuesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PropertyUpdateWithoutPayrollDefaultCompensationOvertimeValuesInput>;
  upsert?: Maybe<PropertyUpsertWithoutPayrollDefaultCompensationOvertimeValuesInput>;
};

export type PropertyUpdateOneWithoutPayrollDefaultCompensationRaiseValuesInput = {
  connect?: Maybe<PropertyWhereUniqueInput>;
  connectOrCreate?: Maybe<PropertyCreateOrConnectWithoutPayrollDefaultCompensationRaiseValuesInput>;
  create?: Maybe<PropertyCreateWithoutPayrollDefaultCompensationRaiseValuesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PropertyUpdateWithoutPayrollDefaultCompensationRaiseValuesInput>;
  upsert?: Maybe<PropertyUpsertWithoutPayrollDefaultCompensationRaiseValuesInput>;
};

export type PropertyUpdateWithWhereUniqueWithoutChartOfAccountsInput = {
  data: PropertyUpdateWithoutChartOfAccountsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyUpdateWithWhereUniqueWithoutClientInput = {
  data: PropertyUpdateWithoutClientInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyUpdateWithWhereUniqueWithoutModelInput = {
  data: PropertyUpdateWithoutModelInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyUpdateWithoutAccPercentageAssumptionsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutAccountPercentageDriversInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutAccountTopVendorsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutAccountTotalValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutAccountValueOverrideInput = {
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutAccountValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutAccountVarianceNotesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutBudgetCategoryTotalValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutBudgetCategoryValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutBudgetComponentTotalValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutBudgetComponentValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutChartOfAccountsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutClientInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutCustomItemDriversInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutDriverAssignmentsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutDriverAugmentsAccountPercentageInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutForecastedAccountValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutGrowthDriversInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutLockedAccountsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutModelInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutNoteInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutOperationalDriversInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutOperationalMetricAssumptionsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutOperationalMetricsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutOriginalRevenueMarketRentOverridesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutPayrollDefaultCompensationBaseValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutPayrollDefaultCompensationBonusValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutPayrollDefaultCompensationItemValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutPayrollDefaultCompensationOvertimeValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutPayrollDefaultCompensationRaiseValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutPayrollEmployeeAttributionsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutPayrollPositionCompensationAccountsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutPayrollPositionCompensationAccountsV2Input = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutPayrollPositionCompensationItemAccountsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutPayrollPositionCompensationItemAccountsV2Input = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutPmsActualMetricOverrideAverageResidentialRentsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutPmsActualMetricOverrideOccupancyRatesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutPmsActualMetricOverrideUnitCountsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutPropertyClientBudgetPlansInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutPropertyManagementSystemAccountValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutRenovationPackagesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutRevenueDriverAssumptionsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutRevenueDriversInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutRevenueMetricsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutSharedReportLinksInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutStarredAccountsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutTransactionsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutUnitEventsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutUnitLevelConfigurationInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutUnitTypeScheduleValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutUnitTypesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutUnitsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutWorksheetAssumptionsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheets?: Maybe<WorksheetUpdateManyWithoutPropertyInput>;
};

export type PropertyUpdateWithoutWorksheetsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutPropertyInput>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutPropertyInput>;
  Note?: Maybe<NoteUpdateManyWithoutPropertyInput>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveUpdateManyWithoutPropertyInput>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionUpdateManyWithoutPropertyInput>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageUpdateManyWithoutPropertyInput>;
  accountTopVendors?: Maybe<AccountTopVendorsUpdateManyWithoutPropertyInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutPropertyInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutPropertyInput>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteUpdateManyWithoutPropertyInput>;
  address?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutPropertyInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutPropertyInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutPropertyInput>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyUpdateManyWithoutPropertyInput>;
  cappedRenewalIncreasePercent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneWithoutPropertiesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutPropertiesInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  customItemDrivers?: Maybe<DriverCustomItemUpdateManyWithoutPropertyInput>;
  displayName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  driverAssignments?: Maybe<DriverAssignmentUpdateManyWithoutPropertyInput>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageUpdateManyWithoutPropertyInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fiscalYearStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueUpdateManyWithoutPropertyInput>;
  growthDrivers?: Maybe<DriverGrowthUpdateManyWithoutPropertyInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isApproved?: Maybe<BoolFieldUpdateOperationsInput>;
  isDeactivated?: Maybe<BoolFieldUpdateOperationsInput>;
  lockedAccounts?: Maybe<LockedAccountUpdateManyWithoutPropertyInput>;
  marketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  model?: Maybe<ModelUpdateOneWithoutPropertiesInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  operationalDrivers?: Maybe<DriverOperationalUpdateManyWithoutPropertyInput>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionUpdateManyWithoutPropertyInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentUpdateManyWithoutPropertyInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeUpdateManyWithoutPropertyInput>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeUpdateManyWithoutPropertyInput>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseUpdateManyWithoutPropertyInput>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2UpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountUpdateManyWithoutPropertyInput>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2UpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutPropertyInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutPropertyInput>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalUpdateManyWithoutPropertyInput>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionUpdateManyWithoutPropertyInput>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyUpdateManyWithoutPropertyInput>;
  propertyManagementSystem?: Maybe<NullableEnumPropertyManagementSystemFieldUpdateOperationsInput>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueUpdateManyWithoutPropertyInput>;
  reforecastStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  reforecastYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutPropertyInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutPropertyInput>;
  revenueDrivers?: Maybe<DriverRevenueUpdateManyWithoutPropertyInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutPropertyInput>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationUpdateManyWithoutPropertyInput>;
  sharedReportLinks?: Maybe<SharedReportLinkUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput>;
  sqFtTmp?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutPropertyInput>;
  submarketName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  transactions?: Maybe<TransactionUpdateManyWithoutPropertyInput>;
  unitCountTmp?: Maybe<NullableIntFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutPropertyInput>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationUpdateOneWithoutPropertyInput>;
  unitLevelModelingEnabled?: Maybe<BoolFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutPropertyInput>;
  unitTypes?: Maybe<UnitTypeUpdateManyWithoutPropertyInput>;
  units?: Maybe<UnitUpdateManyWithoutPropertyInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionUpdateManyWithoutPropertyInput>;
};

export type PropertyUpsertWithWhereUniqueWithoutChartOfAccountsInput = {
  create: PropertyCreateWithoutChartOfAccountsInput;
  update: PropertyUpdateWithoutChartOfAccountsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyUpsertWithWhereUniqueWithoutClientInput = {
  create: PropertyCreateWithoutClientInput;
  update: PropertyUpdateWithoutClientInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyUpsertWithWhereUniqueWithoutModelInput = {
  create: PropertyCreateWithoutModelInput;
  update: PropertyUpdateWithoutModelInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyUpsertWithoutAccPercentageAssumptionsInput = {
  create: PropertyCreateWithoutAccPercentageAssumptionsInput;
  update: PropertyUpdateWithoutAccPercentageAssumptionsInput;
};

export type PropertyUpsertWithoutAccountPercentageDriversInput = {
  create: PropertyCreateWithoutAccountPercentageDriversInput;
  update: PropertyUpdateWithoutAccountPercentageDriversInput;
};

export type PropertyUpsertWithoutAccountTopVendorsInput = {
  create: PropertyCreateWithoutAccountTopVendorsInput;
  update: PropertyUpdateWithoutAccountTopVendorsInput;
};

export type PropertyUpsertWithoutAccountTotalValuesInput = {
  create: PropertyCreateWithoutAccountTotalValuesInput;
  update: PropertyUpdateWithoutAccountTotalValuesInput;
};

export type PropertyUpsertWithoutAccountValueOverrideInput = {
  create: PropertyCreateWithoutAccountValueOverrideInput;
  update: PropertyUpdateWithoutAccountValueOverrideInput;
};

export type PropertyUpsertWithoutAccountValuesInput = {
  create: PropertyCreateWithoutAccountValuesInput;
  update: PropertyUpdateWithoutAccountValuesInput;
};

export type PropertyUpsertWithoutAccountVarianceNotesInput = {
  create: PropertyCreateWithoutAccountVarianceNotesInput;
  update: PropertyUpdateWithoutAccountVarianceNotesInput;
};

export type PropertyUpsertWithoutBudgetCategoryTotalValuesInput = {
  create: PropertyCreateWithoutBudgetCategoryTotalValuesInput;
  update: PropertyUpdateWithoutBudgetCategoryTotalValuesInput;
};

export type PropertyUpsertWithoutBudgetCategoryValuesInput = {
  create: PropertyCreateWithoutBudgetCategoryValuesInput;
  update: PropertyUpdateWithoutBudgetCategoryValuesInput;
};

export type PropertyUpsertWithoutBudgetComponentTotalValuesInput = {
  create: PropertyCreateWithoutBudgetComponentTotalValuesInput;
  update: PropertyUpdateWithoutBudgetComponentTotalValuesInput;
};

export type PropertyUpsertWithoutBudgetComponentValuesInput = {
  create: PropertyCreateWithoutBudgetComponentValuesInput;
  update: PropertyUpdateWithoutBudgetComponentValuesInput;
};

export type PropertyUpsertWithoutCustomItemDriversInput = {
  create: PropertyCreateWithoutCustomItemDriversInput;
  update: PropertyUpdateWithoutCustomItemDriversInput;
};

export type PropertyUpsertWithoutDriverAssignmentsInput = {
  create: PropertyCreateWithoutDriverAssignmentsInput;
  update: PropertyUpdateWithoutDriverAssignmentsInput;
};

export type PropertyUpsertWithoutDriverAugmentsAccountPercentageInput = {
  create: PropertyCreateWithoutDriverAugmentsAccountPercentageInput;
  update: PropertyUpdateWithoutDriverAugmentsAccountPercentageInput;
};

export type PropertyUpsertWithoutForecastedAccountValuesInput = {
  create: PropertyCreateWithoutForecastedAccountValuesInput;
  update: PropertyUpdateWithoutForecastedAccountValuesInput;
};

export type PropertyUpsertWithoutGrowthDriversInput = {
  create: PropertyCreateWithoutGrowthDriversInput;
  update: PropertyUpdateWithoutGrowthDriversInput;
};

export type PropertyUpsertWithoutLockedAccountsInput = {
  create: PropertyCreateWithoutLockedAccountsInput;
  update: PropertyUpdateWithoutLockedAccountsInput;
};

export type PropertyUpsertWithoutNoteInput = {
  create: PropertyCreateWithoutNoteInput;
  update: PropertyUpdateWithoutNoteInput;
};

export type PropertyUpsertWithoutOperationalDriversInput = {
  create: PropertyCreateWithoutOperationalDriversInput;
  update: PropertyUpdateWithoutOperationalDriversInput;
};

export type PropertyUpsertWithoutOperationalMetricAssumptionsInput = {
  create: PropertyCreateWithoutOperationalMetricAssumptionsInput;
  update: PropertyUpdateWithoutOperationalMetricAssumptionsInput;
};

export type PropertyUpsertWithoutOperationalMetricsInput = {
  create: PropertyCreateWithoutOperationalMetricsInput;
  update: PropertyUpdateWithoutOperationalMetricsInput;
};

export type PropertyUpsertWithoutOriginalRevenueMarketRentOverridesInput = {
  create: PropertyCreateWithoutOriginalRevenueMarketRentOverridesInput;
  update: PropertyUpdateWithoutOriginalRevenueMarketRentOverridesInput;
};

export type PropertyUpsertWithoutPayrollDefaultCompensationBaseValuesInput = {
  create: PropertyCreateWithoutPayrollDefaultCompensationBaseValuesInput;
  update: PropertyUpdateWithoutPayrollDefaultCompensationBaseValuesInput;
};

export type PropertyUpsertWithoutPayrollDefaultCompensationBonusValuesInput = {
  create: PropertyCreateWithoutPayrollDefaultCompensationBonusValuesInput;
  update: PropertyUpdateWithoutPayrollDefaultCompensationBonusValuesInput;
};

export type PropertyUpsertWithoutPayrollDefaultCompensationItemValuesInput = {
  create: PropertyCreateWithoutPayrollDefaultCompensationItemValuesInput;
  update: PropertyUpdateWithoutPayrollDefaultCompensationItemValuesInput;
};

export type PropertyUpsertWithoutPayrollDefaultCompensationOvertimeValuesInput = {
  create: PropertyCreateWithoutPayrollDefaultCompensationOvertimeValuesInput;
  update: PropertyUpdateWithoutPayrollDefaultCompensationOvertimeValuesInput;
};

export type PropertyUpsertWithoutPayrollDefaultCompensationRaiseValuesInput = {
  create: PropertyCreateWithoutPayrollDefaultCompensationRaiseValuesInput;
  update: PropertyUpdateWithoutPayrollDefaultCompensationRaiseValuesInput;
};

export type PropertyUpsertWithoutPayrollEmployeeAttributionsInput = {
  create: PropertyCreateWithoutPayrollEmployeeAttributionsInput;
  update: PropertyUpdateWithoutPayrollEmployeeAttributionsInput;
};

export type PropertyUpsertWithoutPayrollPositionCompensationAccountsInput = {
  create: PropertyCreateWithoutPayrollPositionCompensationAccountsInput;
  update: PropertyUpdateWithoutPayrollPositionCompensationAccountsInput;
};

export type PropertyUpsertWithoutPayrollPositionCompensationAccountsV2Input = {
  create: PropertyCreateWithoutPayrollPositionCompensationAccountsV2Input;
  update: PropertyUpdateWithoutPayrollPositionCompensationAccountsV2Input;
};

export type PropertyUpsertWithoutPayrollPositionCompensationItemAccountsInput = {
  create: PropertyCreateWithoutPayrollPositionCompensationItemAccountsInput;
  update: PropertyUpdateWithoutPayrollPositionCompensationItemAccountsInput;
};

export type PropertyUpsertWithoutPayrollPositionCompensationItemAccountsV2Input = {
  create: PropertyCreateWithoutPayrollPositionCompensationItemAccountsV2Input;
  update: PropertyUpdateWithoutPayrollPositionCompensationItemAccountsV2Input;
};

export type PropertyUpsertWithoutPmsActualMetricOverrideAverageResidentialRentsInput = {
  create: PropertyCreateWithoutPmsActualMetricOverrideAverageResidentialRentsInput;
  update: PropertyUpdateWithoutPmsActualMetricOverrideAverageResidentialRentsInput;
};

export type PropertyUpsertWithoutPmsActualMetricOverrideOccupancyRatesInput = {
  create: PropertyCreateWithoutPmsActualMetricOverrideOccupancyRatesInput;
  update: PropertyUpdateWithoutPmsActualMetricOverrideOccupancyRatesInput;
};

export type PropertyUpsertWithoutPmsActualMetricOverrideUnitCountsInput = {
  create: PropertyCreateWithoutPmsActualMetricOverrideUnitCountsInput;
  update: PropertyUpdateWithoutPmsActualMetricOverrideUnitCountsInput;
};

export type PropertyUpsertWithoutPropertyClientBudgetPlansInput = {
  create: PropertyCreateWithoutPropertyClientBudgetPlansInput;
  update: PropertyUpdateWithoutPropertyClientBudgetPlansInput;
};

export type PropertyUpsertWithoutPropertyManagementSystemAccountValuesInput = {
  create: PropertyCreateWithoutPropertyManagementSystemAccountValuesInput;
  update: PropertyUpdateWithoutPropertyManagementSystemAccountValuesInput;
};

export type PropertyUpsertWithoutRenovationPackagesInput = {
  create: PropertyCreateWithoutRenovationPackagesInput;
  update: PropertyUpdateWithoutRenovationPackagesInput;
};

export type PropertyUpsertWithoutRevenueDriverAssumptionsInput = {
  create: PropertyCreateWithoutRevenueDriverAssumptionsInput;
  update: PropertyUpdateWithoutRevenueDriverAssumptionsInput;
};

export type PropertyUpsertWithoutRevenueDriversInput = {
  create: PropertyCreateWithoutRevenueDriversInput;
  update: PropertyUpdateWithoutRevenueDriversInput;
};

export type PropertyUpsertWithoutRevenueMetricsInput = {
  create: PropertyCreateWithoutRevenueMetricsInput;
  update: PropertyUpdateWithoutRevenueMetricsInput;
};

export type PropertyUpsertWithoutSharedReportLinksInput = {
  create: PropertyCreateWithoutSharedReportLinksInput;
  update: PropertyUpdateWithoutSharedReportLinksInput;
};

export type PropertyUpsertWithoutSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingsInput = {
  create: PropertyCreateWithoutSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingsInput;
  update: PropertyUpdateWithoutSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingsInput;
};

export type PropertyUpsertWithoutStarredAccountsInput = {
  create: PropertyCreateWithoutStarredAccountsInput;
  update: PropertyUpdateWithoutStarredAccountsInput;
};

export type PropertyUpsertWithoutTransactionsInput = {
  create: PropertyCreateWithoutTransactionsInput;
  update: PropertyUpdateWithoutTransactionsInput;
};

export type PropertyUpsertWithoutUnitEventsInput = {
  create: PropertyCreateWithoutUnitEventsInput;
  update: PropertyUpdateWithoutUnitEventsInput;
};

export type PropertyUpsertWithoutUnitLevelConfigurationInput = {
  create: PropertyCreateWithoutUnitLevelConfigurationInput;
  update: PropertyUpdateWithoutUnitLevelConfigurationInput;
};

export type PropertyUpsertWithoutUnitTypeScheduleValuesInput = {
  create: PropertyCreateWithoutUnitTypeScheduleValuesInput;
  update: PropertyUpdateWithoutUnitTypeScheduleValuesInput;
};

export type PropertyUpsertWithoutUnitTypesInput = {
  create: PropertyCreateWithoutUnitTypesInput;
  update: PropertyUpdateWithoutUnitTypesInput;
};

export type PropertyUpsertWithoutUnitsInput = {
  create: PropertyCreateWithoutUnitsInput;
  update: PropertyUpdateWithoutUnitsInput;
};

export type PropertyUpsertWithoutWorksheetAssumptionsInput = {
  create: PropertyCreateWithoutWorksheetAssumptionsInput;
  update: PropertyUpdateWithoutWorksheetAssumptionsInput;
};

export type PropertyUpsertWithoutWorksheetsInput = {
  create: PropertyCreateWithoutWorksheetsInput;
  update: PropertyUpdateWithoutWorksheetsInput;
};

export type PropertyWhereInput = {
  AND?: Maybe<Array<PropertyWhereInput>>;
  AccountValueOverride?: Maybe<AccountValueOverrideListRelationFilter>;
  BudgetComponentTotalValues?: Maybe<BudgetComponentTotalValueListRelationFilter>;
  NOT?: Maybe<Array<PropertyWhereInput>>;
  Note?: Maybe<NoteListRelationFilter>;
  OR?: Maybe<Array<PropertyWhereInput>>;
  RevenueExpirationCurve?: Maybe<RevenueExpirationCurveListRelationFilter>;
  accPercentageAssumptions?: Maybe<AccPercentageAssumptionListRelationFilter>;
  accountPercentageDrivers?: Maybe<DriverAccountPercentageListRelationFilter>;
  accountTopVendors?: Maybe<AccountTopVendorsListRelationFilter>;
  accountTotalValues?: Maybe<AccountTotalValueListRelationFilter>;
  accountValues?: Maybe<AccountValueListRelationFilter>;
  accountVarianceNotes?: Maybe<AccountVarianceNoteListRelationFilter>;
  address?: Maybe<StringNullableFilter>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueListRelationFilter>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueListRelationFilter>;
  budgetComponentValues?: Maybe<BudgetComponentValueListRelationFilter>;
  calculationParticipantProperties?: Maybe<CalculationParticipantPropertyListRelationFilter>;
  cappedRenewalIncreasePercent?: Maybe<DecimalNullableFilter>;
  chartOfAccounts?: Maybe<ChartOfAccountsRelationFilter>;
  chartOfAccountsId?: Maybe<StringNullableFilter>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  code?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  customItemDrivers?: Maybe<DriverCustomItemListRelationFilter>;
  displayName?: Maybe<StringNullableFilter>;
  driverAssignments?: Maybe<DriverAssignmentListRelationFilter>;
  driverAugmentsAccountPercentage?: Maybe<DriverAugmentAccountPercentageListRelationFilter>;
  externalId?: Maybe<StringNullableFilter>;
  fiscalYearStartMonthIndex?: Maybe<IntFilter>;
  forecastedAccountValues?: Maybe<ForecastedAccountValueListRelationFilter>;
  growthDrivers?: Maybe<DriverGrowthListRelationFilter>;
  id?: Maybe<StringFilter>;
  isApproved?: Maybe<BoolFilter>;
  isDeactivated?: Maybe<BoolFilter>;
  lockedAccounts?: Maybe<LockedAccountListRelationFilter>;
  marketName?: Maybe<StringNullableFilter>;
  model?: Maybe<ModelRelationFilter>;
  modelId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  operationalDrivers?: Maybe<DriverOperationalListRelationFilter>;
  operationalMetricAssumptions?: Maybe<OperationalMetricAssumptionListRelationFilter>;
  operationalMetrics?: Maybe<OperationalMetricListRelationFilter>;
  originalRevenueMarketRentAdjustments?: Maybe<OriginalRevenueMarketRentAdjustmentListRelationFilter>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideListRelationFilter>;
  originalRevenuePropertyMarketRentModeType?: Maybe<OriginalRevenuePropertyMarketRentModeTypeListRelationFilter>;
  originalRevenuePropertyRenewalIncreasePercents?: Maybe<OriginalRevenuePropertyRenewalIncreasePercentListRelationFilter>;
  payrollDefaultCompensationBaseValues?: Maybe<PayrollDefaultCompensationBaseListRelationFilter>;
  payrollDefaultCompensationBonusValues?: Maybe<PayrollDefaultCompensationBonusListRelationFilter>;
  payrollDefaultCompensationItemValues?: Maybe<PayrollDefaultCompensationItemListRelationFilter>;
  payrollDefaultCompensationOvertimeValues?: Maybe<PayrollDefaultCompensationOvertimeListRelationFilter>;
  payrollDefaultCompensationRaiseValues?: Maybe<PayrollDefaultCompensationRaiseListRelationFilter>;
  payrollEmployeeAttributions?: Maybe<PayrollEmployeePropertyAttributionListRelationFilter>;
  payrollPositionCompensationAccounts?: Maybe<PayrollPositionCompensationAccountListRelationFilter>;
  payrollPositionCompensationAccountsV2?: Maybe<PayrollPositionCompensationAccountV2ListRelationFilter>;
  payrollPositionCompensationItemAccounts?: Maybe<PayrollPositionCompensationItemAccountListRelationFilter>;
  payrollPositionCompensationItemAccountsV2?: Maybe<PayrollPositionCompensationItemAccountV2ListRelationFilter>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentListRelationFilter>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateListRelationFilter>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountListRelationFilter>;
  propertyBudgetApprovals?: Maybe<PropertyBudgetApprovalListRelationFilter>;
  propertyBudgetCompletions?: Maybe<PropertyBudgetCompletionListRelationFilter>;
  propertyClientBudgetPlans?: Maybe<ClientBudgetPlanPropertyListRelationFilter>;
  propertyManagementSystem?: Maybe<EnumPropertyManagementSystemNullableFilter>;
  propertyManagementSystemAccountValues?: Maybe<PropertyManagementSystemAccountValueListRelationFilter>;
  reforecastStartMonthIndex?: Maybe<IntFilter>;
  reforecastYear?: Maybe<IntFilter>;
  renovationPackages?: Maybe<RenovationPackageListRelationFilter>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionListRelationFilter>;
  revenueDrivers?: Maybe<DriverRevenueListRelationFilter>;
  revenueMetrics?: Maybe<RevenueMetricListRelationFilter>;
  revenueModelConfigurations?: Maybe<PropertyRevenueConfigurationListRelationFilter>;
  sharedReportLinks?: Maybe<SharedReportLinkListRelationFilter>;
  simplifiedRevenuePropertyMarketRentGrowthPercents?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentListRelationFilter>;
  simplifiedRevenuePropertyRenewalPercents?: Maybe<SimplifiedRevenuePropertyRenewalPercentListRelationFilter>;
  simplifiedRevenuePropertyRenewalTradeOutRules?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleListRelationFilter>;
  simplifiedRevenuePropertyRenovationPercents?: Maybe<SimplifiedRevenuePropertyRenovationPercentListRelationFilter>;
  simplifiedRevenuePropertyVacancyPercents?: Maybe<SimplifiedRevenuePropertyVacancyPercentListRelationFilter>;
  simplifiedRevenueRentRolls?: Maybe<SimplifiedRevenueRentRollListRelationFilter>;
  simplifiedRevenueUnrenovatedToRenovatedUnitTypeMappings?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingListRelationFilter>;
  sqFtTmp?: Maybe<DecimalNullableFilter>;
  starredAccounts?: Maybe<StarredAccountListRelationFilter>;
  submarketName?: Maybe<StringNullableFilter>;
  transactions?: Maybe<TransactionListRelationFilter>;
  unitCountTmp?: Maybe<IntNullableFilter>;
  unitEvents?: Maybe<UnitEventListRelationFilter>;
  unitLevelConfiguration?: Maybe<PropertyUnitLevelConfigurationRelationFilter>;
  unitLevelModelingEnabled?: Maybe<BoolFilter>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueListRelationFilter>;
  unitTypes?: Maybe<UnitTypeListRelationFilter>;
  units?: Maybe<UnitListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  worksheetAssumptions?: Maybe<WorksheetAssumptionListRelationFilter>;
  worksheets?: Maybe<WorksheetListRelationFilter>;
};

export type PropertyWhereUniqueInput = {
  clientId_name?: Maybe<PropertyClientIdNameCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
  id_chartOfAccountsId?: Maybe<PropertyIdChartOfAccountsIdCompoundUniqueInput>;
};

export type Query = {
  __typename?: 'Query';
  aggregateOperationalMetricType: AggregateOperationalMetricType;
  aggregateProperty: AggregateProperty;
  aggregateUnit: AggregateUnit;
  aggregateUnitTypeScheduleValue: AggregateUnitTypeScheduleValue;
  aggregateValueVersion: AggregateValueVersion;
  analystDrivers: Array<AnalystDriverInfo>;
  analystOpDrivers: AnalystOpDriversQueryResult;
  authenticateToken?: Maybe<Scalars['String']>;
  budgetComponentMappings: Array<BudgetComponentMapping>;
  calculateExpirationCurveExpirations: Array<OriginalRevenueUnitTypeCalculatedExpirationsModel>;
  caseSensitiveEmail: Scalars['String'];
  clientReportT12FinancialValues?: Maybe<ClientReportT12FinancialValues>;
  countEmployeesForBudgetYearGroupByProperty: Array<PayrollPropertyEmployeeCountModel>;
  downloadLink?: Maybe<Scalars['String']>;
  employeeTotals: Array<PayrollEmployeeTotalsModel>;
  enrichPossibleUnitEvent: FakeEnrichedUnitEventModel;
  enrichPossibleUnitEventContainingBudgetYear: FakeEnrichedUnitEventModel;
  expirationCurve?: Maybe<OriginalRevenueExpirationCurveModel>;
  exportWorkbookAsXls?: Maybe<DebugModel>;
  fetchPMSAccounts: Array<PmsAccountSnapshot>;
  fetchPMSProperties: Array<PmsPropertySnapshot>;
  financialReports?: Maybe<Array<BulkExportReportsReturn>>;
  /** @deprecated This query is horrible and painful to use, and we want to remove it. DO NOT USE IN NEW CODE. */
  financialValues: FinancialValuesQueryResult;
  financialValuesForYear: Array<FinancialValuesYearModel>;
  financialValuesPropertyBudgetSeason: Array<FinancialValuesYearBareModel>;
  findFirstChartOfAccounts?: Maybe<ChartOfAccounts>;
  findFirstOperationalMetricType?: Maybe<OperationalMetricType>;
  findFirstProperty?: Maybe<Property>;
  findFirstUnitTypeScheduleValue?: Maybe<UnitTypeScheduleValue>;
  findFirstValueVersion?: Maybe<ValueVersion>;
  findUserGroups: Array<UserGroupModel>;
  forecastLocks?: Maybe<ForecastLockModel>;
  getAccountVarianceInsights: AccountVarianceInsightsModel;
  getAccountsTopCardTags: Array<AccountsTopCardTagsModel>;
  getBudgetApprovalHistory: Array<PropertyBudgetApprovalModel>;
  getBudgetApprovalStatus?: Maybe<PropertyBudgetApprovalModel>;
  getBudgetApprovalStatusForProperties: Array<PropertyBudgetApprovalModel>;
  getClientCommentSummaryForAccount?: Maybe<ClientCommentPreviewViewModel>;
  getClientComments: Array<ClientCommentViewModel>;
  getClientCommentsForAccount: Array<ClientCommentViewModel>;
  getClientFirstComments: Array<ClientCommentPreviewViewModel>;
  getEmployee?: Maybe<PayrollEmployeeModel>;
  getInstructionByAccountIdAndValueVersionId?: Maybe<InstructionModel>;
  getInstructionById?: Maybe<InstructionModel>;
  getIsAccountLocked?: Maybe<Scalars['Boolean']>;
  getJob?: Maybe<BackgroundJob>;
  getLatestPortfolioCalcSnapshotInfo?: Maybe<PortfolioCalcSnapshotModel>;
  getOperationalPropertyPerformance: OperationalMetricPerformanceModel;
  getPortfolioSnapshotAccountPerPropertyData: PortfolioCalcSnapshotAccountPropertiesDataModel;
  getPortfolioSnapshotData: PortfolioCalcSnapshotDataModel;
  getPropertyBudgetExport?: Maybe<Array<PropertyBudgetExportModel>>;
  getPropertyBudgetExportSingleYear?: Maybe<Array<PropertyBudgetExportSingleYearModel>>;
  getPropertyBudgetT12Report: PropertyBudgetT12ReportModel;
  getPropertyPerformance: PropertyPerformanceModel;
  getReportTables: Array<ReportTableViewModel>;
  getRoles: Array<Scalars['String']>;
  getSharedReportLinks: Array<SharedLinkViewModel>;
  getStarredAccountByPropertyIdAndAccountIdAndVersionIds?: Maybe<Array<StarredAccountModel>>;
  getStarringMetaForStarredAccountsByPropertyIdAndVersionIds?: Maybe<Array<AccountsTableViewModel>>;
  getTableViewForEmptyAccountsByPropertyIdAndVersionIds?: Maybe<Array<AccountsTableViewModel>>;
  getTableViewForOverriddenAccountsByPropertyIdAndVersionIds?: Maybe<Array<AccountsTableViewModel>>;
  getTableViewForStarredAccountsByPropertyIdAndVersionIds?: Maybe<Array<AccountsTableViewModel>>;
  getUnit: UnitModel;
  getUser: UserModel;
  groupByOperationalMetricType: Array<OperationalMetricTypeGroupBy>;
  groupByProperty: Array<PropertyGroupBy>;
  groupByUnitTypeScheduleValue: Array<UnitTypeScheduleValueGroupBy>;
  groupByValueVersion: Array<ValueVersionGroupBy>;
  internalListUsers: Array<UserModel>;
  listAuthorizations: Array<Scalars['String']>;
  listBudgetYears: BudgetYearsListViewModel;
  /** @deprecated Deprecated: use listChartOfAccountsById */
  listChartOfAccounts: CoaListResponse;
  listChartOfAccountsById: CoaListResponse;
  listCompensationItems: Array<PayrollCompensationItemModel>;
  listEmployees: PaginatedPayrollEmployeeModel;
  listEmployeesByProperties: PaginatedPayrollEmployeeModel;
  listEmployeesByPropertiesForBudgetYear: PaginatedPayrollEmployeeModel;
  listEmployeesByProperty: Array<PayrollEmployeeModel>;
  listEmployeesByPropertyForBudgetYear: Array<PayrollEmployeeModel>;
  listEmployeesForBudgetYear: PaginatedPayrollEmployeeModel;
  listJobs: PaginatedBackgroundJobs;
  listPayrollPositions: Array<PayrollPositionModel>;
  listUnitTypes: Array<UnitTypeExtViewModel>;
  listUnits: TraversablePaginatedUnitModel;
  listUserGroups: Array<UserGroupModel>;
  listUsers: Array<UserModel>;
  multiPropertyAccountDrivers: MultiPropertyDriverModel;
  myProperties: Array<Property>;
  myUser?: Maybe<MyUser>;
  /** @deprecated Not compatible with multi-year budgeting. */
  note?: Maybe<Note>;
  operationalMetricType: Array<OperationalMetricTypeModel>;
  operationalMetricTypes: Array<OperationalMetricType>;
  operationalReports?: Maybe<Array<BulkExportReportsReturn>>;
  originalRevenueMarketRentAdjustments: OriginalRevenueMarketRentAdjustmentsModel;
  originalRevenueMarketRentOverrides: Array<OriginalRevenueUnitTypeMarketRentOverridesModel>;
  originalRevenueMarketRentValues: Array<OriginalRevenueUnitTypeMarketRentValuesModel>;
  originalRevenueModel?: Maybe<OriginalRevenueModel>;
  originalRevenuePropertyRenewalIncreasePercent: OriginalRevenuePropertyRenewalIncreasePercentModel;
  payrollDefaults: PayrollDefaultsModel;
  properties: Array<Property>;
  property?: Maybe<Property>;
  propertyForecastLocks?: Maybe<TForecastPeriodLocks>;
  propertyUnitCounts: Array<PropertyUnitCountResult>;
  queryAccountNote?: Maybe<AccountNoteModel>;
  queryAccountPercentageDestinationAccountsWithCycles: Array<AccountShortModel>;
  queryAccountPercentageDriverCycles: Array<AccountPercentageDriverCycleModel>;
  queryAccountPercentageDriverCyclesIndicators: AccountPercentageDriverCyclesIndicatorsModel;
  queryAccountTags: Array<AccountTagsModel>;
  queryAccountVarianceNotes: Array<AccountVarianceNoteModel>;
  queryCustomItems: Array<CustomItemViewModel>;
  queryDriverStatus: Array<PropertyDriverStatusViewModel>;
  queryDriverStatusForAccount: DriverStatusForAccount;
  queryDriverStatusForAccounts: Array<DriverStatusForAccount>;
  queryFileUploadRequests: Array<FileUploadRequest>;
  queryForecastedLeaseExpirationCountsForPropertyAndPeriod: Array<ForecastedLeaseExpirationCountModel>;
  queryNote?: Maybe<NoteModel>;
  queryNotesForProperty: Array<AccountTwoPeriodNoteModel>;
  queryPMSLeaseExpirationCountsForPropertyAndPeriod: Array<PmsLeaseExpirationCountModel>;
  queryPropertyAccountDriverStatus: Array<PropertyAccountDriverStatusViewModel>;
  queryPropertyBudgetCompletions: Array<PropertyBudgetCompletionModel>;
  queryPropertyPMSActualsWithOverridesMonth?: Maybe<PropertyForPmsActualOverridesModel>;
  queryRenovationCostCategories: Array<RenovationCostCategoryModel>;
  queryRenovationPackage: RenovationPackageModel;
  queryRenovationPackages: Array<RenovationPackageModel>;
  queryRenovationPackagesPaginated: PaginatedRenovationPackageModel;
  queryRentalRevenueCaptures: Array<RentalRevenueCaptureModel>;
  queryRevenueAssumptionsStatus: Array<PropertyRevenueAssumptionsStatusViewModel>;
  queryTransactions: TraversablePaginatedTransactionsModel;
  queryUnitTypePMSActualsWithOverridesYear?: Maybe<UnitTypeForPmsActualOverridesModel>;
  queryUnitTypesPMSActualsOverridesFull: Array<UnitTypeForPmsActualOverridesFullModel>;
  renovationPackagesByUnitType: Array<RenovationPackageModel>;
  requestTokenOTP: Scalars['Boolean'];
  revenueTypes: Array<Scalars['String']>;
  simplifiedRevenueModel?: Maybe<SimplifiedRevenueModel>;
  singlePropertyAccount: PropertyAccountVersionTypeModel;
  /** @deprecated Unit Level Modelling Is Deprecated by Business Decision */
  unitLevelPropertyFinancialMetrics: Array<UnitLevelPropertyFinancialMetricsModel>;
  /** @deprecated Unit Level Modelling Deprecated by Business Decision */
  unitLevelPropertyMetrics: Array<UnitLevelPropertyMetricsModel>;
  unitTypeScheduleValue?: Maybe<UnitTypeScheduleValue>;
  unitTypeScheduleValues: Array<UnitTypeScheduleValue>;
  userPropertiesForecastLocks?: Maybe<UserPropertiesForecastLockModel>;
  valueVersion?: Maybe<ValueVersion>;
  valueVersions: Array<ValueVersion>;
  welcomeUnauthorizedUser: User;
  worksheetDescriptionsForBudgetYear: Array<Scalars['String']>;
  worksheetLines: Array<WorksheetLineWithPropertyAccountVersionTypeModel>;
  worksheetTypeahead: Array<Scalars['String']>;
};


export type QueryAggregateOperationalMetricTypeArgs = {
  cursor?: Maybe<OperationalMetricTypeWhereUniqueInput>;
  orderBy?: Maybe<Array<OperationalMetricTypeOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OperationalMetricTypeWhereInput>;
};


export type QueryAggregatePropertyArgs = {
  cursor?: Maybe<PropertyWhereUniqueInput>;
  orderBy?: Maybe<Array<PropertyOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PropertyWhereInput>;
};


export type QueryAggregateUnitArgs = {
  cursor?: Maybe<UnitWhereUniqueInput>;
  orderBy?: Maybe<Array<UnitOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UnitWhereInput>;
};


export type QueryAggregateUnitTypeScheduleValueArgs = {
  cursor?: Maybe<UnitTypeScheduleValueWhereUniqueInput>;
  orderBy?: Maybe<Array<UnitTypeScheduleValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UnitTypeScheduleValueWhereInput>;
};


export type QueryAggregateValueVersionArgs = {
  cursor?: Maybe<ValueVersionWhereUniqueInput>;
  orderBy?: Maybe<Array<ValueVersionOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ValueVersionWhereInput>;
};


export type QueryAnalystDriversArgs = {
  propertyIds: Array<Scalars['String']>;
};


export type QueryAnalystOpDriversArgs = {
  year: Scalars['Int'];
};


export type QueryAuthenticateTokenArgs = {
  accessToken: Scalars['String'];
  otp: Scalars['String'];
};


export type QueryBudgetComponentMappingsArgs = {
  cursor?: Maybe<BudgetComponentMappingWhereUniqueInput>;
  distinct?: Maybe<Array<BudgetComponentMappingScalarFieldEnum>>;
  orderBy?: Maybe<Array<BudgetComponentMappingOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BudgetComponentMappingWhereInput>;
};


export type QueryCalculateExpirationCurveExpirationsArgs = {
  budgetYear: Scalars['Int'];
  curve: Array<Scalars['Decimal']>;
  propertyId: Scalars['ID'];
};


export type QueryCaseSensitiveEmailArgs = {
  email: Scalars['String'];
};


export type QueryClientReportT12FinancialValuesArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
};


export type QueryCountEmployeesForBudgetYearGroupByPropertyArgs = {
  budgetYear: Scalars['Int'];
};


export type QueryDownloadLinkArgs = {
  date: Scalars['String'];
  reportType: BulkReportType;
};


export type QueryEmployeeTotalsArgs = {
  propertyId: Scalars['ID'];
};


export type QueryEnrichPossibleUnitEventArgs = {
  concessionMonths?: Maybe<Scalars['Decimal']>;
  eventEnd: Scalars['DateTime'];
  eventStart: Scalars['DateTime'];
  eventType: UnitEventType;
  inPlaceRent?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  renewalAdjustmentPercentage?: Maybe<Scalars['Decimal']>;
  renovationCost?: Maybe<Scalars['Decimal']>;
  renovationPremium?: Maybe<Scalars['Decimal']>;
  shortTermEffectiveAvgDailyRent?: Maybe<Scalars['Decimal']>;
  unitId: Scalars['ID'];
};


export type QueryEnrichPossibleUnitEventContainingBudgetYearArgs = {
  budgetYear: Scalars['Int'];
  concessionMonths?: Maybe<Scalars['Decimal']>;
  eventEnd: Scalars['DateTime'];
  eventStart: Scalars['DateTime'];
  eventType: UnitEventType;
  inPlaceRent?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  renewalAdjustmentPercentage?: Maybe<Scalars['Decimal']>;
  renovationCost?: Maybe<Scalars['Decimal']>;
  renovationPremium?: Maybe<Scalars['Decimal']>;
  shortTermEffectiveAvgDailyRent?: Maybe<Scalars['Decimal']>;
  unitId: Scalars['ID'];
};


export type QueryExpirationCurveArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
};


export type QueryExportWorkbookAsXlsArgs = {
  propertyId?: Maybe<Scalars['String']>;
};


export type QueryFetchPmsAccountsArgs = {
  chartOfAccountsId: Scalars['ID'];
};


export type QueryFinancialValuesArgs = {
  financialTotalValueSelectorsHistorical: Array<FinancialTotalValueQueryInput>;
  financialTotalValueSelectorsProjections: Array<FinancialTotalValueQueryInput>;
  financialValueSelectors: Array<FinancialValueQueryInput>;
  useCache?: Maybe<Scalars['Boolean']>;
};


export type QueryFinancialValuesForYearArgs = {
  entityIds: Array<Scalars['ID']>;
  propertyIds: Array<Scalars['ID']>;
  years: Array<YearAndType>;
};


export type QueryFinancialValuesPropertyBudgetSeasonArgs = {
  propertyId: Scalars['ID'];
};


export type QueryFindFirstChartOfAccountsArgs = {
  where?: Maybe<ClientAwareChartOfAccountsWhereInput>;
};


export type QueryFindFirstOperationalMetricTypeArgs = {
  cursor?: Maybe<OperationalMetricTypeWhereUniqueInput>;
  distinct?: Maybe<Array<OperationalMetricTypeScalarFieldEnum>>;
  orderBy?: Maybe<Array<OperationalMetricTypeOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OperationalMetricTypeWhereInput>;
};


export type QueryFindFirstPropertyArgs = {
  cursor?: Maybe<PropertyWhereUniqueInput>;
  distinct?: Maybe<Array<PropertyScalarFieldEnum>>;
  orderBy?: Maybe<Array<PropertyOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PropertyWhereInput>;
};


export type QueryFindFirstUnitTypeScheduleValueArgs = {
  cursor?: Maybe<UnitTypeScheduleValueWhereUniqueInput>;
  distinct?: Maybe<Array<UnitTypeScheduleValueScalarFieldEnum>>;
  orderBy?: Maybe<Array<UnitTypeScheduleValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UnitTypeScheduleValueWhereInput>;
};


export type QueryFindFirstValueVersionArgs = {
  cursor?: Maybe<ValueVersionWhereUniqueInput>;
  distinct?: Maybe<Array<ValueVersionScalarFieldEnum>>;
  orderBy?: Maybe<Array<ValueVersionOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ValueVersionWhereInput>;
};


export type QueryFindUserGroupsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryForecastLocksArgs = {
  accountId?: Maybe<Scalars['ID']>;
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
};


export type QueryGetAccountVarianceInsightsArgs = {
  accountId: Scalars['String'];
  month: Scalars['Int'];
  propertyId: Scalars['String'];
  year: Scalars['Int'];
};


export type QueryGetAccountsTopCardTagsArgs = {
  accountIds: Array<Scalars['String']>;
  topCardTagTypes: Array<TopCardTagType>;
};


export type QueryGetBudgetApprovalHistoryArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
};


export type QueryGetBudgetApprovalStatusArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
};


export type QueryGetBudgetApprovalStatusForPropertiesArgs = {
  budgetYear: Scalars['Int'];
};


export type QueryGetClientCommentSummaryForAccountArgs = {
  accountId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
};


export type QueryGetClientCommentsArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
};


export type QueryGetClientCommentsForAccountArgs = {
  accountId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
};


export type QueryGetClientFirstCommentsArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
};


export type QueryGetEmployeeArgs = {
  id: Scalars['ID'];
};


export type QueryGetInstructionByAccountIdAndValueVersionIdArgs = {
  accountId: Scalars['ID'];
  versionId: Scalars['ID'];
};


export type QueryGetInstructionByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetIsAccountLockedArgs = {
  accountId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
  versionType: VersionType;
};


export type QueryGetJobArgs = {
  jobId: Scalars['String'];
};


export type QueryGetLatestPortfolioCalcSnapshotInfoArgs = {
  budgetYear: Scalars['Int'];
};


export type QueryGetOperationalPropertyPerformanceArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['String'];
};


export type QueryGetPortfolioSnapshotAccountPerPropertyDataArgs = {
  accountId: Scalars['ID'];
  propertyIds: Array<Scalars['ID']>;
  snapshotId: Scalars['ID'];
};


export type QueryGetPortfolioSnapshotDataArgs = {
  propertyIds: Array<Scalars['ID']>;
  snapshotId: Scalars['ID'];
};


export type QueryGetPropertyBudgetExportArgs = {
  propertyId: Scalars['ID'];
};


export type QueryGetPropertyBudgetExportSingleYearArgs = {
  propertyId: Scalars['ID'];
  referenceValueType: VersionType;
  referenceYear: Scalars['Int'];
  requestValueType: VersionType;
};


export type QueryGetPropertyBudgetT12ReportArgs = {
  propertyId: Scalars['ID'];
};


export type QueryGetPropertyPerformanceArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['String'];
};


export type QueryGetReportTablesArgs = {
  budgetYear: Scalars['Int'];
};


export type QueryGetSharedReportLinksArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
};


export type QueryGetStarredAccountByPropertyIdAndAccountIdAndVersionIdsArgs = {
  accountId: Scalars['ID'];
  propertyId: Scalars['ID'];
  versionIds: Array<Scalars['ID']>;
};


export type QueryGetStarringMetaForStarredAccountsByPropertyIdAndVersionIdsArgs = {
  propertyId: Scalars['ID'];
  versionIds: Array<Scalars['ID']>;
};


export type QueryGetTableViewForEmptyAccountsByPropertyIdAndVersionIdsArgs = {
  financialTotalValueArgInput: Array<FinancialTotalValueArgInput>;
  propertyId: Scalars['ID'];
  starringMetaArgInput: Array<StarringMetaArgInput>;
};


export type QueryGetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsArgs = {
  financialTotalValueArgInput: Array<FinancialTotalValueArgInput>;
  propertyId: Scalars['ID'];
  reforecastStartMonthIndex: Scalars['Int'];
  starringMetaArgInput: Array<StarringMetaArgInput>;
};


export type QueryGetTableViewForStarredAccountsByPropertyIdAndVersionIdsArgs = {
  financialTotalValueArgInput: Array<FinancialTotalValueArgInput>;
  propertyId: Scalars['ID'];
  versionIds: Array<Scalars['ID']>;
};


export type QueryGetUnitArgs = {
  unitId: Scalars['ID'];
};


export type QueryGetUserArgs = {
  id: Scalars['ID'];
};


export type QueryGroupByOperationalMetricTypeArgs = {
  by: Array<OperationalMetricTypeScalarFieldEnum>;
  having?: Maybe<OperationalMetricTypeScalarWhereWithAggregatesInput>;
  orderBy?: Maybe<Array<OperationalMetricTypeOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OperationalMetricTypeWhereInput>;
};


export type QueryGroupByPropertyArgs = {
  by: Array<PropertyScalarFieldEnum>;
  having?: Maybe<PropertyScalarWhereWithAggregatesInput>;
  orderBy?: Maybe<Array<PropertyOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PropertyWhereInput>;
};


export type QueryGroupByUnitTypeScheduleValueArgs = {
  by: Array<UnitTypeScheduleValueScalarFieldEnum>;
  having?: Maybe<UnitTypeScheduleValueScalarWhereWithAggregatesInput>;
  orderBy?: Maybe<Array<UnitTypeScheduleValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UnitTypeScheduleValueWhereInput>;
};


export type QueryGroupByValueVersionArgs = {
  by: Array<ValueVersionScalarFieldEnum>;
  having?: Maybe<ValueVersionScalarWhereWithAggregatesInput>;
  orderBy?: Maybe<Array<ValueVersionOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ValueVersionWhereInput>;
};


export type QueryInternalListUsersArgs = {
  clientId: Scalars['String'];
};


export type QueryListChartOfAccountsByIdArgs = {
  chartOfAccountsId: Scalars['ID'];
};


export type QueryListEmployeesArgs = {
  cursor?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryListEmployeesByPropertiesArgs = {
  cursor?: Maybe<Scalars['String']>;
  propertyIds: Array<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryListEmployeesByPropertiesForBudgetYearArgs = {
  budgetYear: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
  propertyIds: Array<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryListEmployeesByPropertyArgs = {
  propertyId: Scalars['String'];
};


export type QueryListEmployeesByPropertyForBudgetYearArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['String'];
};


export type QueryListEmployeesForBudgetYearArgs = {
  budgetYear: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryListJobsArgs = {
  jobType?: Maybe<JobType>;
  page: Scalars['Int'];
  take: Scalars['Int'];
  userId?: Maybe<Scalars['ID']>;
};


export type QueryListPayrollPositionsArgs = {
  namePrefix?: Maybe<Scalars['String']>;
};


export type QueryListUnitTypesArgs = {
  propertyId: Scalars['ID'];
};


export type QueryListUnitsArgs = {
  page?: Maybe<Scalars['Int']>;
  propertyId: Scalars['String'];
  take?: Maybe<Scalars['Int']>;
};


export type QueryMultiPropertyAccountDriversArgs = {
  accountId: Scalars['ID'];
  driverTypes?: Maybe<Array<DriverType>>;
  propertyIds?: Maybe<Array<Scalars['ID']>>;
  yearsVersionTypes: Array<YearAndType>;
};


export type QueryNoteArgs = {
  where: NoteWhereUniqueInput;
};


export type QueryOperationalMetricTypesArgs = {
  cursor?: Maybe<OperationalMetricTypeWhereUniqueInput>;
  distinct?: Maybe<Array<OperationalMetricTypeScalarFieldEnum>>;
  orderBy?: Maybe<Array<OperationalMetricTypeOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OperationalMetricTypeWhereInput>;
};


export type QueryOriginalRevenueMarketRentAdjustmentsArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
  versionType: VersionType;
};


export type QueryOriginalRevenueMarketRentOverridesArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
  versionType: VersionType;
};


export type QueryOriginalRevenueMarketRentValuesArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
};


export type QueryOriginalRevenueModelArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
};


export type QueryOriginalRevenuePropertyRenewalIncreasePercentArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
};


export type QueryPayrollDefaultsArgs = {
  positionId: Scalars['ID'];
  propertyId: Scalars['String'];
};


export type QueryPropertiesArgs = {
  cursor?: Maybe<PropertyWhereUniqueInput>;
  distinct?: Maybe<Array<PropertyScalarFieldEnum>>;
  orderBy?: Maybe<Array<PropertyOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PropertyWhereInput>;
};


export type QueryPropertyArgs = {
  where: PropertyWhereUniqueInput;
};


export type QueryPropertyForecastLocksArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
};


export type QueryPropertyUnitCountsArgs = {
  propertyId: Scalars['String'];
};


export type QueryQueryAccountNoteArgs = {
  accountId: Scalars['String'];
  propertyId: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Int'];
};


export type QueryQueryAccountPercentageDestinationAccountsWithCyclesArgs = {
  propertyId: Scalars['ID'];
  versionType: VersionType;
};


export type QueryQueryAccountPercentageDriverCyclesArgs = {
  destinationAccountIds: Array<Scalars['ID']>;
  propertyId: Scalars['ID'];
  sourceAccountIds: Array<Scalars['ID']>;
  versionType: VersionType;
  year: Scalars['Int'];
};


export type QueryQueryAccountPercentageDriverCyclesIndicatorsArgs = {
  propertyId: Scalars['ID'];
};


export type QueryQueryAccountVarianceNotesArgs = {
  input: AccountVarianceNoteQueryInput;
};


export type QueryQueryDriverStatusArgs = {
  budgetYear: Scalars['Int'];
  versionType: VersionType;
};


export type QueryQueryDriverStatusForAccountArgs = {
  accountId: Scalars['ID'];
};


export type QueryQueryDriverStatusForAccountsArgs = {
  accountIds: Scalars['ID'];
};


export type QueryQueryFileUploadRequestsArgs = {
  input: FileUploadRequestQueryInput;
};


export type QueryQueryForecastedLeaseExpirationCountsForPropertyAndPeriodArgs = {
  propertyId: Scalars['ID'];
  versionType: VersionType;
};


export type QueryQueryNoteArgs = {
  entityId: Scalars['ID'];
  entityType: EntityType;
  propertyId: Scalars['ID'];
  versionType: VersionType;
  year: Scalars['Int'];
};


export type QueryQueryNotesForPropertyArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
};


export type QueryQueryPmsLeaseExpirationCountsForPropertyAndPeriodArgs = {
  propertyId: Scalars['ID'];
  versionType: VersionType;
};


export type QueryQueryPropertyAccountDriverStatusArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
  versionType: VersionType;
};


export type QueryQueryPropertyPmsActualsWithOverridesMonthArgs = {
  monthIndex: Scalars['Int'];
  propertyId: Scalars['ID'];
};


export type QueryQueryRenovationPackageArgs = {
  id: Scalars['String'];
};


export type QueryQueryRenovationPackagesArgs = {
  namePrefix?: Maybe<Scalars['String']>;
  propertyIds: Array<Scalars['String']>;
};


export type QueryQueryRenovationPackagesPaginatedArgs = {
  cursor?: Maybe<Scalars['String']>;
  namePrefix?: Maybe<Scalars['String']>;
  propertyIds: Array<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryQueryRevenueAssumptionsStatusArgs = {
  budgetYear: Scalars['Int'];
  versionType: VersionType;
};


export type QueryQueryTransactionsArgs = {
  accountId: Scalars['String'];
  filter?: Maybe<FilterArgs>;
  monthIndex: Scalars['Int'];
  pagination?: Maybe<TraversablePaginationArgs>;
  propertyId: Scalars['String'];
  sort?: Maybe<SortArgs>;
  year: Scalars['Int'];
};


export type QueryQueryUnitTypePmsActualsWithOverridesYearArgs = {
  propertyId: Scalars['ID'];
  unitTypeId: Scalars['ID'];
};


export type QueryQueryUnitTypesPmsActualsOverridesFullArgs = {
  propertyId: Scalars['ID'];
  year: Scalars['Int'];
};


export type QueryRenovationPackagesByUnitTypeArgs = {
  budgetYear: Scalars['Int'];
  unitTypeId: Scalars['ID'];
};


export type QueryRequestTokenOtpArgs = {
  accessToken: Scalars['String'];
};


export type QuerySimplifiedRevenueModelArgs = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
};


export type QuerySinglePropertyAccountArgs = {
  accountId: Scalars['ID'];
  propertyId: Scalars['ID'];
  versionType: VersionType;
  year: Scalars['Int'];
};


export type QueryUnitLevelPropertyFinancialMetricsArgs = {
  chartOfAccountsId: Scalars['ID'];
  propertyId: Scalars['ID'];
};


export type QueryUnitLevelPropertyMetricsArgs = {
  propertyId: Scalars['ID'];
};


export type QueryUnitTypeScheduleValueArgs = {
  where: UnitTypeScheduleValueWhereUniqueInput;
};


export type QueryUnitTypeScheduleValuesArgs = {
  cursor?: Maybe<UnitTypeScheduleValueWhereUniqueInput>;
  distinct?: Maybe<Array<UnitTypeScheduleValueScalarFieldEnum>>;
  orderBy?: Maybe<Array<UnitTypeScheduleValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UnitTypeScheduleValueWhereInput>;
};


export type QueryUserPropertiesForecastLocksArgs = {
  budgetYear: Scalars['Int'];
};


export type QueryValueVersionArgs = {
  where: ValueVersionWhereUniqueInput;
};


export type QueryValueVersionsArgs = {
  cursor?: Maybe<ValueVersionWhereUniqueInput>;
  distinct?: Maybe<Array<ValueVersionScalarFieldEnum>>;
  orderBy?: Maybe<Array<ValueVersionOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ValueVersionWhereInput>;
};


export type QueryWelcomeUnauthorizedUserArgs = {
  userId: Scalars['String'];
};


export type QueryWorksheetDescriptionsForBudgetYearArgs = {
  accountId: Scalars['String'];
  budgetYear: Scalars['Int'];
};


export type QueryWorksheetLinesArgs = {
  accountIds: Array<Scalars['ID']>;
  propertyIds: Array<Scalars['ID']>;
  versionTypes: Array<VersionType>;
};


export type QueryWorksheetTypeaheadArgs = {
  accountId: Scalars['String'];
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type RemoveDriverOperationalMetric = {
  driverMetricTypeId: Scalars['String'];
  lookbackPeriod?: Maybe<Scalars['Int']>;
  sourceMetricId: Scalars['String'];
  type: Scalars['String'];
};

export type RenewalIncreaseAmountAndPercent = {
  __typename?: 'RenewalIncreaseAmountAndPercent';
  /** Dollar renewal increase amounts. 12 values sparse array */
  amounts: Array<Maybe<Scalars['Decimal']>>;
  /** In place rents per month. 12 values sparse array */
  inPlaceRents: Array<Maybe<Scalars['Decimal']>>;
  /** Percentage renewal increases. 12 values sparse array */
  percents: Array<Maybe<Scalars['Decimal']>>;
  versionType: VersionType;
  year?: Maybe<Scalars['Float']>;
};

/** //////////////////////////////////////////////// */
export type RenovationClientConfiguration = {
  __typename?: 'RenovationClientConfiguration';
  clientId: Scalars['String'];
  premiumAccountId: Scalars['String'];
  unused?: Maybe<Scalars['Int']>;
};

export type RenovationClientConfigurationCreateManyPremiumAccountInput = {
  clientId: Scalars['String'];
  unused?: Maybe<Scalars['Int']>;
};

export type RenovationClientConfigurationCreateManyPremiumAccountInputEnvelope = {
  data: Array<RenovationClientConfigurationCreateManyPremiumAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RenovationClientConfigurationCreateNestedManyWithoutPremiumAccountInput = {
  connect?: Maybe<Array<RenovationClientConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RenovationClientConfigurationCreateOrConnectWithoutPremiumAccountInput>>;
  create?: Maybe<Array<RenovationClientConfigurationCreateWithoutPremiumAccountInput>>;
  createMany?: Maybe<RenovationClientConfigurationCreateManyPremiumAccountInputEnvelope>;
};

export type RenovationClientConfigurationCreateNestedOneWithoutClientInput = {
  connect?: Maybe<RenovationClientConfigurationWhereUniqueInput>;
  connectOrCreate?: Maybe<RenovationClientConfigurationCreateOrConnectWithoutClientInput>;
  create?: Maybe<RenovationClientConfigurationCreateWithoutClientInput>;
};

export type RenovationClientConfigurationCreateOrConnectWithoutClientInput = {
  create: RenovationClientConfigurationCreateWithoutClientInput;
  where: RenovationClientConfigurationWhereUniqueInput;
};

export type RenovationClientConfigurationCreateOrConnectWithoutPremiumAccountInput = {
  create: RenovationClientConfigurationCreateWithoutPremiumAccountInput;
  where: RenovationClientConfigurationWhereUniqueInput;
};

export type RenovationClientConfigurationCreateWithoutClientInput = {
  premiumAccount: AccountV2CreateNestedOneWithoutRenovationPremiumAccountsInput;
  unused?: Maybe<Scalars['Int']>;
};

export type RenovationClientConfigurationCreateWithoutPremiumAccountInput = {
  client: ClientCreateNestedOneWithoutRenovationClientConfigurationInput;
  unused?: Maybe<Scalars['Int']>;
};

export type RenovationClientConfigurationListRelationFilter = {
  every?: Maybe<RenovationClientConfigurationWhereInput>;
  none?: Maybe<RenovationClientConfigurationWhereInput>;
  some?: Maybe<RenovationClientConfigurationWhereInput>;
};

export type RenovationClientConfigurationOrderByInput = {
  clientId?: Maybe<SortOrder>;
  premiumAccountId?: Maybe<SortOrder>;
  unused?: Maybe<SortOrder>;
};

export type RenovationClientConfigurationRelationFilter = {
  is?: Maybe<RenovationClientConfigurationWhereInput>;
  isNot?: Maybe<RenovationClientConfigurationWhereInput>;
};

export enum RenovationClientConfigurationScalarFieldEnum {
  ClientId = 'clientId',
  PremiumAccountId = 'premiumAccountId',
  Unused = 'unused'
}

export type RenovationClientConfigurationScalarWhereInput = {
  AND?: Maybe<Array<RenovationClientConfigurationScalarWhereInput>>;
  NOT?: Maybe<Array<RenovationClientConfigurationScalarWhereInput>>;
  OR?: Maybe<Array<RenovationClientConfigurationScalarWhereInput>>;
  clientId?: Maybe<StringFilter>;
  premiumAccountId?: Maybe<StringFilter>;
  unused?: Maybe<IntNullableFilter>;
};

export type RenovationClientConfigurationUpdateManyMutationInput = {
  unused?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type RenovationClientConfigurationUpdateManyWithWhereWithoutPremiumAccountInput = {
  data: RenovationClientConfigurationUpdateManyMutationInput;
  where: RenovationClientConfigurationScalarWhereInput;
};

export type RenovationClientConfigurationUpdateManyWithoutPremiumAccountInput = {
  connect?: Maybe<Array<RenovationClientConfigurationWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RenovationClientConfigurationCreateOrConnectWithoutPremiumAccountInput>>;
  create?: Maybe<Array<RenovationClientConfigurationCreateWithoutPremiumAccountInput>>;
  createMany?: Maybe<RenovationClientConfigurationCreateManyPremiumAccountInputEnvelope>;
  delete?: Maybe<Array<RenovationClientConfigurationWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RenovationClientConfigurationScalarWhereInput>>;
  disconnect?: Maybe<Array<RenovationClientConfigurationWhereUniqueInput>>;
  set?: Maybe<Array<RenovationClientConfigurationWhereUniqueInput>>;
  update?: Maybe<Array<RenovationClientConfigurationUpdateWithWhereUniqueWithoutPremiumAccountInput>>;
  updateMany?: Maybe<Array<RenovationClientConfigurationUpdateManyWithWhereWithoutPremiumAccountInput>>;
  upsert?: Maybe<Array<RenovationClientConfigurationUpsertWithWhereUniqueWithoutPremiumAccountInput>>;
};

export type RenovationClientConfigurationUpdateOneWithoutClientInput = {
  connect?: Maybe<RenovationClientConfigurationWhereUniqueInput>;
  connectOrCreate?: Maybe<RenovationClientConfigurationCreateOrConnectWithoutClientInput>;
  create?: Maybe<RenovationClientConfigurationCreateWithoutClientInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<RenovationClientConfigurationUpdateWithoutClientInput>;
  upsert?: Maybe<RenovationClientConfigurationUpsertWithoutClientInput>;
};

export type RenovationClientConfigurationUpdateWithWhereUniqueWithoutPremiumAccountInput = {
  data: RenovationClientConfigurationUpdateWithoutPremiumAccountInput;
  where: RenovationClientConfigurationWhereUniqueInput;
};

export type RenovationClientConfigurationUpdateWithoutClientInput = {
  premiumAccount?: Maybe<AccountV2UpdateOneRequiredWithoutRenovationPremiumAccountsInput>;
  unused?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type RenovationClientConfigurationUpdateWithoutPremiumAccountInput = {
  client?: Maybe<ClientUpdateOneRequiredWithoutRenovationClientConfigurationInput>;
  unused?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type RenovationClientConfigurationUpsertWithWhereUniqueWithoutPremiumAccountInput = {
  create: RenovationClientConfigurationCreateWithoutPremiumAccountInput;
  update: RenovationClientConfigurationUpdateWithoutPremiumAccountInput;
  where: RenovationClientConfigurationWhereUniqueInput;
};

export type RenovationClientConfigurationUpsertWithoutClientInput = {
  create: RenovationClientConfigurationCreateWithoutClientInput;
  update: RenovationClientConfigurationUpdateWithoutClientInput;
};

export type RenovationClientConfigurationWhereInput = {
  AND?: Maybe<Array<RenovationClientConfigurationWhereInput>>;
  NOT?: Maybe<Array<RenovationClientConfigurationWhereInput>>;
  OR?: Maybe<Array<RenovationClientConfigurationWhereInput>>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  premiumAccount?: Maybe<AccountV2RelationFilter>;
  premiumAccountId?: Maybe<StringFilter>;
  unused?: Maybe<IntNullableFilter>;
};

export type RenovationClientConfigurationWhereUniqueInput = {
  clientId?: Maybe<Scalars['String']>;
};

export type RenovationCostCategory = {
  __typename?: 'RenovationCostCategory';
  accountId: Scalars['String'];
  clientId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  versionId: Scalars['BigInt'];
};

export type RenovationCostCategoryClientIdAccountIdNameCompoundUniqueInput = {
  accountId: Scalars['String'];
  clientId: Scalars['String'];
  name: Scalars['String'];
};

export type RenovationCostCategoryCreateManyAccountInput = {
  clientId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId?: Maybe<Scalars['BigInt']>;
};

export type RenovationCostCategoryCreateManyAccountInputEnvelope = {
  data: Array<RenovationCostCategoryCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RenovationCostCategoryCreateManyClientInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId?: Maybe<Scalars['BigInt']>;
};

export type RenovationCostCategoryCreateManyClientInputEnvelope = {
  data: Array<RenovationCostCategoryCreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RenovationCostCategoryCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<RenovationCostCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RenovationCostCategoryCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<RenovationCostCategoryCreateWithoutAccountInput>>;
  createMany?: Maybe<RenovationCostCategoryCreateManyAccountInputEnvelope>;
};

export type RenovationCostCategoryCreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<RenovationCostCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RenovationCostCategoryCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<RenovationCostCategoryCreateWithoutClientInput>>;
  createMany?: Maybe<RenovationCostCategoryCreateManyClientInputEnvelope>;
};

export type RenovationCostCategoryCreateNestedOneWithoutRenovationPackageRenovationCostCategoriesInput = {
  connect?: Maybe<RenovationCostCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<RenovationCostCategoryCreateOrConnectWithoutRenovationPackageRenovationCostCategoriesInput>;
  create?: Maybe<RenovationCostCategoryCreateWithoutRenovationPackageRenovationCostCategoriesInput>;
};

export type RenovationCostCategoryCreateOrConnectWithoutAccountInput = {
  create: RenovationCostCategoryCreateWithoutAccountInput;
  where: RenovationCostCategoryWhereUniqueInput;
};

export type RenovationCostCategoryCreateOrConnectWithoutClientInput = {
  create: RenovationCostCategoryCreateWithoutClientInput;
  where: RenovationCostCategoryWhereUniqueInput;
};

export type RenovationCostCategoryCreateOrConnectWithoutRenovationPackageRenovationCostCategoriesInput = {
  create: RenovationCostCategoryCreateWithoutRenovationPackageRenovationCostCategoriesInput;
  where: RenovationCostCategoryWhereUniqueInput;
};

export type RenovationCostCategoryCreateWithoutAccountInput = {
  client: ClientCreateNestedOneWithoutRenovationCostCategoriesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  renovationPackageRenovationCostCategories?: Maybe<RenovationPackageRenovationCostCategoryCreateNestedManyWithoutRenovationCostCategoryInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId?: Maybe<Scalars['BigInt']>;
};

export type RenovationCostCategoryCreateWithoutClientInput = {
  account: AccountV2CreateNestedOneWithoutRenovationCostCategoriesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  renovationPackageRenovationCostCategories?: Maybe<RenovationPackageRenovationCostCategoryCreateNestedManyWithoutRenovationCostCategoryInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId?: Maybe<Scalars['BigInt']>;
};

export type RenovationCostCategoryCreateWithoutRenovationPackageRenovationCostCategoriesInput = {
  account: AccountV2CreateNestedOneWithoutRenovationCostCategoriesInput;
  client: ClientCreateNestedOneWithoutRenovationCostCategoriesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId?: Maybe<Scalars['BigInt']>;
};

export type RenovationCostCategoryListRelationFilter = {
  every?: Maybe<RenovationCostCategoryWhereInput>;
  none?: Maybe<RenovationCostCategoryWhereInput>;
  some?: Maybe<RenovationCostCategoryWhereInput>;
};

export type RenovationCostCategoryMappingModel = {
  __typename?: 'RenovationCostCategoryMappingModel';
  amount?: Maybe<Scalars['Decimal']>;
  delayRecognition: Scalars['Int'];
  renovationCostCategoryId: Scalars['String'];
};

export type RenovationCostCategoryModel = {
  __typename?: 'RenovationCostCategoryModel';
  account: AccountV2;
  accountId: Scalars['String'];
  clientId: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type RenovationCostCategoryOrderByInput = {
  accountId?: Maybe<SortOrder>;
  clientId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  versionId?: Maybe<SortOrder>;
};

export type RenovationCostCategoryRelationFilter = {
  is?: Maybe<RenovationCostCategoryWhereInput>;
  isNot?: Maybe<RenovationCostCategoryWhereInput>;
};

export enum RenovationCostCategoryScalarFieldEnum {
  AccountId = 'accountId',
  ClientId = 'clientId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  VersionId = 'versionId'
}

export type RenovationCostCategoryScalarWhereInput = {
  AND?: Maybe<Array<RenovationCostCategoryScalarWhereInput>>;
  NOT?: Maybe<Array<RenovationCostCategoryScalarWhereInput>>;
  OR?: Maybe<Array<RenovationCostCategoryScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  clientId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionId?: Maybe<BigIntFilter>;
};

export type RenovationCostCategoryUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionId?: Maybe<BigIntFieldUpdateOperationsInput>;
};

export type RenovationCostCategoryUpdateManyWithWhereWithoutAccountInput = {
  data: RenovationCostCategoryUpdateManyMutationInput;
  where: RenovationCostCategoryScalarWhereInput;
};

export type RenovationCostCategoryUpdateManyWithWhereWithoutClientInput = {
  data: RenovationCostCategoryUpdateManyMutationInput;
  where: RenovationCostCategoryScalarWhereInput;
};

export type RenovationCostCategoryUpdateManyWithoutAccountInput = {
  connect?: Maybe<Array<RenovationCostCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RenovationCostCategoryCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<RenovationCostCategoryCreateWithoutAccountInput>>;
  createMany?: Maybe<RenovationCostCategoryCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<RenovationCostCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RenovationCostCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<RenovationCostCategoryWhereUniqueInput>>;
  set?: Maybe<Array<RenovationCostCategoryWhereUniqueInput>>;
  update?: Maybe<Array<RenovationCostCategoryUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<RenovationCostCategoryUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<RenovationCostCategoryUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type RenovationCostCategoryUpdateManyWithoutClientInput = {
  connect?: Maybe<Array<RenovationCostCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RenovationCostCategoryCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<RenovationCostCategoryCreateWithoutClientInput>>;
  createMany?: Maybe<RenovationCostCategoryCreateManyClientInputEnvelope>;
  delete?: Maybe<Array<RenovationCostCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RenovationCostCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<RenovationCostCategoryWhereUniqueInput>>;
  set?: Maybe<Array<RenovationCostCategoryWhereUniqueInput>>;
  update?: Maybe<Array<RenovationCostCategoryUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<RenovationCostCategoryUpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<RenovationCostCategoryUpsertWithWhereUniqueWithoutClientInput>>;
};

export type RenovationCostCategoryUpdateOneRequiredWithoutRenovationPackageRenovationCostCategoriesInput = {
  connect?: Maybe<RenovationCostCategoryWhereUniqueInput>;
  connectOrCreate?: Maybe<RenovationCostCategoryCreateOrConnectWithoutRenovationPackageRenovationCostCategoriesInput>;
  create?: Maybe<RenovationCostCategoryCreateWithoutRenovationPackageRenovationCostCategoriesInput>;
  update?: Maybe<RenovationCostCategoryUpdateWithoutRenovationPackageRenovationCostCategoriesInput>;
  upsert?: Maybe<RenovationCostCategoryUpsertWithoutRenovationPackageRenovationCostCategoriesInput>;
};

export type RenovationCostCategoryUpdateWithWhereUniqueWithoutAccountInput = {
  data: RenovationCostCategoryUpdateWithoutAccountInput;
  where: RenovationCostCategoryWhereUniqueInput;
};

export type RenovationCostCategoryUpdateWithWhereUniqueWithoutClientInput = {
  data: RenovationCostCategoryUpdateWithoutClientInput;
  where: RenovationCostCategoryWhereUniqueInput;
};

export type RenovationCostCategoryUpdateWithoutAccountInput = {
  client?: Maybe<ClientUpdateOneRequiredWithoutRenovationCostCategoriesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  renovationPackageRenovationCostCategories?: Maybe<RenovationPackageRenovationCostCategoryUpdateManyWithoutRenovationCostCategoryInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionId?: Maybe<BigIntFieldUpdateOperationsInput>;
};

export type RenovationCostCategoryUpdateWithoutClientInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutRenovationCostCategoriesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  renovationPackageRenovationCostCategories?: Maybe<RenovationPackageRenovationCostCategoryUpdateManyWithoutRenovationCostCategoryInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionId?: Maybe<BigIntFieldUpdateOperationsInput>;
};

export type RenovationCostCategoryUpdateWithoutRenovationPackageRenovationCostCategoriesInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutRenovationCostCategoriesInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutRenovationCostCategoriesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionId?: Maybe<BigIntFieldUpdateOperationsInput>;
};

export type RenovationCostCategoryUpsertWithWhereUniqueWithoutAccountInput = {
  create: RenovationCostCategoryCreateWithoutAccountInput;
  update: RenovationCostCategoryUpdateWithoutAccountInput;
  where: RenovationCostCategoryWhereUniqueInput;
};

export type RenovationCostCategoryUpsertWithWhereUniqueWithoutClientInput = {
  create: RenovationCostCategoryCreateWithoutClientInput;
  update: RenovationCostCategoryUpdateWithoutClientInput;
  where: RenovationCostCategoryWhereUniqueInput;
};

export type RenovationCostCategoryUpsertWithoutRenovationPackageRenovationCostCategoriesInput = {
  create: RenovationCostCategoryCreateWithoutRenovationPackageRenovationCostCategoriesInput;
  update: RenovationCostCategoryUpdateWithoutRenovationPackageRenovationCostCategoriesInput;
};

export type RenovationCostCategoryWhereInput = {
  AND?: Maybe<Array<RenovationCostCategoryWhereInput>>;
  NOT?: Maybe<Array<RenovationCostCategoryWhereInput>>;
  OR?: Maybe<Array<RenovationCostCategoryWhereInput>>;
  account?: Maybe<AccountV2RelationFilter>;
  accountId?: Maybe<StringFilter>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  renovationPackageRenovationCostCategories?: Maybe<RenovationPackageRenovationCostCategoryListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionId?: Maybe<BigIntFilter>;
};

export type RenovationCostCategoryWhereUniqueInput = {
  clientId_accountId_name?: Maybe<RenovationCostCategoryClientIdAccountIdNameCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type RenovationNumberInput = {
  monthIndex: Scalars['Int'];
  value?: Maybe<Scalars['Int']>;
};

export type RenovationNumberModel = {
  __typename?: 'RenovationNumberModel';
  /** A month index where 0 => Start Budget Month ... 11 => End Budget Month. */
  monthIndex: Scalars['Int'];
  /** The number of units getting or that got this renovation. If null, no value exists. */
  value?: Maybe<Scalars['Int']>;
};

export type RenovationNumbersCreateManyRenovationPackageInput = {
  actualsMonth0?: Maybe<Scalars['Int']>;
  actualsMonth1?: Maybe<Scalars['Int']>;
  actualsMonth2?: Maybe<Scalars['Int']>;
  actualsMonth3?: Maybe<Scalars['Int']>;
  actualsMonth4?: Maybe<Scalars['Int']>;
  actualsMonth5?: Maybe<Scalars['Int']>;
  actualsMonth6?: Maybe<Scalars['Int']>;
  actualsMonth7?: Maybe<Scalars['Int']>;
  actualsMonth8?: Maybe<Scalars['Int']>;
  actualsMonth9?: Maybe<Scalars['Int']>;
  actualsMonth10?: Maybe<Scalars['Int']>;
  actualsMonth11?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expectedMonth0?: Maybe<Scalars['Int']>;
  expectedMonth1?: Maybe<Scalars['Int']>;
  expectedMonth2?: Maybe<Scalars['Int']>;
  expectedMonth3?: Maybe<Scalars['Int']>;
  expectedMonth4?: Maybe<Scalars['Int']>;
  expectedMonth5?: Maybe<Scalars['Int']>;
  expectedMonth6?: Maybe<Scalars['Int']>;
  expectedMonth7?: Maybe<Scalars['Int']>;
  expectedMonth8?: Maybe<Scalars['Int']>;
  expectedMonth9?: Maybe<Scalars['Int']>;
  expectedMonth10?: Maybe<Scalars['Int']>;
  expectedMonth11?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId?: Maybe<Scalars['BigInt']>;
  year: Scalars['Int'];
};

export type RenovationNumbersCreateManyRenovationPackageInputEnvelope = {
  data: Array<RenovationNumbersCreateManyRenovationPackageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RenovationNumbersCreateNestedManyWithoutRenovationPackageInput = {
  connect?: Maybe<Array<RenovationNumbersWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RenovationNumbersCreateOrConnectWithoutRenovationPackageInput>>;
  create?: Maybe<Array<RenovationNumbersCreateWithoutRenovationPackageInput>>;
  createMany?: Maybe<RenovationNumbersCreateManyRenovationPackageInputEnvelope>;
};

export type RenovationNumbersCreateOrConnectWithoutRenovationPackageInput = {
  create: RenovationNumbersCreateWithoutRenovationPackageInput;
  where: RenovationNumbersWhereUniqueInput;
};

export type RenovationNumbersCreateWithoutRenovationPackageInput = {
  actualsMonth0?: Maybe<Scalars['Int']>;
  actualsMonth1?: Maybe<Scalars['Int']>;
  actualsMonth2?: Maybe<Scalars['Int']>;
  actualsMonth3?: Maybe<Scalars['Int']>;
  actualsMonth4?: Maybe<Scalars['Int']>;
  actualsMonth5?: Maybe<Scalars['Int']>;
  actualsMonth6?: Maybe<Scalars['Int']>;
  actualsMonth7?: Maybe<Scalars['Int']>;
  actualsMonth8?: Maybe<Scalars['Int']>;
  actualsMonth9?: Maybe<Scalars['Int']>;
  actualsMonth10?: Maybe<Scalars['Int']>;
  actualsMonth11?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expectedMonth0?: Maybe<Scalars['Int']>;
  expectedMonth1?: Maybe<Scalars['Int']>;
  expectedMonth2?: Maybe<Scalars['Int']>;
  expectedMonth3?: Maybe<Scalars['Int']>;
  expectedMonth4?: Maybe<Scalars['Int']>;
  expectedMonth5?: Maybe<Scalars['Int']>;
  expectedMonth6?: Maybe<Scalars['Int']>;
  expectedMonth7?: Maybe<Scalars['Int']>;
  expectedMonth8?: Maybe<Scalars['Int']>;
  expectedMonth9?: Maybe<Scalars['Int']>;
  expectedMonth10?: Maybe<Scalars['Int']>;
  expectedMonth11?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId?: Maybe<Scalars['BigInt']>;
  year: Scalars['Int'];
};

export type RenovationNumbersListRelationFilter = {
  every?: Maybe<RenovationNumbersWhereInput>;
  none?: Maybe<RenovationNumbersWhereInput>;
  some?: Maybe<RenovationNumbersWhereInput>;
};

export type RenovationNumbersModel = {
  __typename?: 'RenovationNumbersModel';
  /** @deprecated Never used. Will be an empty array. */
  actuals: Array<RenovationNumberModel>;
  expected: Array<RenovationNumberModel>;
  /** @deprecated No longer used. Will be a fixed value of CALENDAR. */
  mapping: BudgetMapping;
  renovationPackageId: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export type RenovationNumbersRenovationPackageIdYearCompoundUniqueInput = {
  renovationPackageId: Scalars['String'];
  year: Scalars['Int'];
};

export type RenovationNumbersScalarWhereInput = {
  AND?: Maybe<Array<RenovationNumbersScalarWhereInput>>;
  NOT?: Maybe<Array<RenovationNumbersScalarWhereInput>>;
  OR?: Maybe<Array<RenovationNumbersScalarWhereInput>>;
  actualsMonth0?: Maybe<IntNullableFilter>;
  actualsMonth1?: Maybe<IntNullableFilter>;
  actualsMonth2?: Maybe<IntNullableFilter>;
  actualsMonth3?: Maybe<IntNullableFilter>;
  actualsMonth4?: Maybe<IntNullableFilter>;
  actualsMonth5?: Maybe<IntNullableFilter>;
  actualsMonth6?: Maybe<IntNullableFilter>;
  actualsMonth7?: Maybe<IntNullableFilter>;
  actualsMonth8?: Maybe<IntNullableFilter>;
  actualsMonth9?: Maybe<IntNullableFilter>;
  actualsMonth10?: Maybe<IntNullableFilter>;
  actualsMonth11?: Maybe<IntNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  expectedMonth0?: Maybe<IntNullableFilter>;
  expectedMonth1?: Maybe<IntNullableFilter>;
  expectedMonth2?: Maybe<IntNullableFilter>;
  expectedMonth3?: Maybe<IntNullableFilter>;
  expectedMonth4?: Maybe<IntNullableFilter>;
  expectedMonth5?: Maybe<IntNullableFilter>;
  expectedMonth6?: Maybe<IntNullableFilter>;
  expectedMonth7?: Maybe<IntNullableFilter>;
  expectedMonth8?: Maybe<IntNullableFilter>;
  expectedMonth9?: Maybe<IntNullableFilter>;
  expectedMonth10?: Maybe<IntNullableFilter>;
  expectedMonth11?: Maybe<IntNullableFilter>;
  renovationPackageId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionId?: Maybe<BigIntFilter>;
  year?: Maybe<IntFilter>;
};

export type RenovationNumbersUpdateManyMutationInput = {
  actualsMonth0?: Maybe<NullableIntFieldUpdateOperationsInput>;
  actualsMonth1?: Maybe<NullableIntFieldUpdateOperationsInput>;
  actualsMonth2?: Maybe<NullableIntFieldUpdateOperationsInput>;
  actualsMonth3?: Maybe<NullableIntFieldUpdateOperationsInput>;
  actualsMonth4?: Maybe<NullableIntFieldUpdateOperationsInput>;
  actualsMonth5?: Maybe<NullableIntFieldUpdateOperationsInput>;
  actualsMonth6?: Maybe<NullableIntFieldUpdateOperationsInput>;
  actualsMonth7?: Maybe<NullableIntFieldUpdateOperationsInput>;
  actualsMonth8?: Maybe<NullableIntFieldUpdateOperationsInput>;
  actualsMonth9?: Maybe<NullableIntFieldUpdateOperationsInput>;
  actualsMonth10?: Maybe<NullableIntFieldUpdateOperationsInput>;
  actualsMonth11?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expectedMonth0?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth1?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth2?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth3?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth4?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth5?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth6?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth7?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth8?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth9?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth10?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth11?: Maybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionId?: Maybe<BigIntFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type RenovationNumbersUpdateManyWithWhereWithoutRenovationPackageInput = {
  data: RenovationNumbersUpdateManyMutationInput;
  where: RenovationNumbersScalarWhereInput;
};

export type RenovationNumbersUpdateManyWithoutRenovationPackageInput = {
  connect?: Maybe<Array<RenovationNumbersWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RenovationNumbersCreateOrConnectWithoutRenovationPackageInput>>;
  create?: Maybe<Array<RenovationNumbersCreateWithoutRenovationPackageInput>>;
  createMany?: Maybe<RenovationNumbersCreateManyRenovationPackageInputEnvelope>;
  delete?: Maybe<Array<RenovationNumbersWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RenovationNumbersScalarWhereInput>>;
  disconnect?: Maybe<Array<RenovationNumbersWhereUniqueInput>>;
  set?: Maybe<Array<RenovationNumbersWhereUniqueInput>>;
  update?: Maybe<Array<RenovationNumbersUpdateWithWhereUniqueWithoutRenovationPackageInput>>;
  updateMany?: Maybe<Array<RenovationNumbersUpdateManyWithWhereWithoutRenovationPackageInput>>;
  upsert?: Maybe<Array<RenovationNumbersUpsertWithWhereUniqueWithoutRenovationPackageInput>>;
};

export type RenovationNumbersUpdateWithWhereUniqueWithoutRenovationPackageInput = {
  data: RenovationNumbersUpdateWithoutRenovationPackageInput;
  where: RenovationNumbersWhereUniqueInput;
};

export type RenovationNumbersUpdateWithoutRenovationPackageInput = {
  actualsMonth0?: Maybe<NullableIntFieldUpdateOperationsInput>;
  actualsMonth1?: Maybe<NullableIntFieldUpdateOperationsInput>;
  actualsMonth2?: Maybe<NullableIntFieldUpdateOperationsInput>;
  actualsMonth3?: Maybe<NullableIntFieldUpdateOperationsInput>;
  actualsMonth4?: Maybe<NullableIntFieldUpdateOperationsInput>;
  actualsMonth5?: Maybe<NullableIntFieldUpdateOperationsInput>;
  actualsMonth6?: Maybe<NullableIntFieldUpdateOperationsInput>;
  actualsMonth7?: Maybe<NullableIntFieldUpdateOperationsInput>;
  actualsMonth8?: Maybe<NullableIntFieldUpdateOperationsInput>;
  actualsMonth9?: Maybe<NullableIntFieldUpdateOperationsInput>;
  actualsMonth10?: Maybe<NullableIntFieldUpdateOperationsInput>;
  actualsMonth11?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expectedMonth0?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth1?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth2?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth3?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth4?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth5?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth6?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth7?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth8?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth9?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth10?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth11?: Maybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionId?: Maybe<BigIntFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type RenovationNumbersUpsertWithWhereUniqueWithoutRenovationPackageInput = {
  create: RenovationNumbersCreateWithoutRenovationPackageInput;
  update: RenovationNumbersUpdateWithoutRenovationPackageInput;
  where: RenovationNumbersWhereUniqueInput;
};

export type RenovationNumbersV2CreateManyRenovationPackageInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expectedMonth0?: Maybe<Scalars['Int']>;
  expectedMonth1?: Maybe<Scalars['Int']>;
  expectedMonth2?: Maybe<Scalars['Int']>;
  expectedMonth3?: Maybe<Scalars['Int']>;
  expectedMonth4?: Maybe<Scalars['Int']>;
  expectedMonth5?: Maybe<Scalars['Int']>;
  expectedMonth6?: Maybe<Scalars['Int']>;
  expectedMonth7?: Maybe<Scalars['Int']>;
  expectedMonth8?: Maybe<Scalars['Int']>;
  expectedMonth9?: Maybe<Scalars['Int']>;
  expectedMonth10?: Maybe<Scalars['Int']>;
  expectedMonth11?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type RenovationNumbersV2CreateManyRenovationPackageInputEnvelope = {
  data: Array<RenovationNumbersV2CreateManyRenovationPackageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RenovationNumbersV2CreateNestedManyWithoutRenovationPackageInput = {
  connect?: Maybe<Array<RenovationNumbersV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RenovationNumbersV2CreateOrConnectWithoutRenovationPackageInput>>;
  create?: Maybe<Array<RenovationNumbersV2CreateWithoutRenovationPackageInput>>;
  createMany?: Maybe<RenovationNumbersV2CreateManyRenovationPackageInputEnvelope>;
};

export type RenovationNumbersV2CreateOrConnectWithoutRenovationPackageInput = {
  create: RenovationNumbersV2CreateWithoutRenovationPackageInput;
  where: RenovationNumbersV2WhereUniqueInput;
};

export type RenovationNumbersV2CreateWithoutRenovationPackageInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expectedMonth0?: Maybe<Scalars['Int']>;
  expectedMonth1?: Maybe<Scalars['Int']>;
  expectedMonth2?: Maybe<Scalars['Int']>;
  expectedMonth3?: Maybe<Scalars['Int']>;
  expectedMonth4?: Maybe<Scalars['Int']>;
  expectedMonth5?: Maybe<Scalars['Int']>;
  expectedMonth6?: Maybe<Scalars['Int']>;
  expectedMonth7?: Maybe<Scalars['Int']>;
  expectedMonth8?: Maybe<Scalars['Int']>;
  expectedMonth9?: Maybe<Scalars['Int']>;
  expectedMonth10?: Maybe<Scalars['Int']>;
  expectedMonth11?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type RenovationNumbersV2ListRelationFilter = {
  every?: Maybe<RenovationNumbersV2WhereInput>;
  none?: Maybe<RenovationNumbersV2WhereInput>;
  some?: Maybe<RenovationNumbersV2WhereInput>;
};

export type RenovationNumbersV2RenovationPackageIdYearVersionTypeCompoundUniqueInput = {
  renovationPackageId: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export type RenovationNumbersV2ScalarWhereInput = {
  AND?: Maybe<Array<RenovationNumbersV2ScalarWhereInput>>;
  NOT?: Maybe<Array<RenovationNumbersV2ScalarWhereInput>>;
  OR?: Maybe<Array<RenovationNumbersV2ScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  expectedMonth0?: Maybe<IntNullableFilter>;
  expectedMonth1?: Maybe<IntNullableFilter>;
  expectedMonth2?: Maybe<IntNullableFilter>;
  expectedMonth3?: Maybe<IntNullableFilter>;
  expectedMonth4?: Maybe<IntNullableFilter>;
  expectedMonth5?: Maybe<IntNullableFilter>;
  expectedMonth6?: Maybe<IntNullableFilter>;
  expectedMonth7?: Maybe<IntNullableFilter>;
  expectedMonth8?: Maybe<IntNullableFilter>;
  expectedMonth9?: Maybe<IntNullableFilter>;
  expectedMonth10?: Maybe<IntNullableFilter>;
  expectedMonth11?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  renovationPackageId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type RenovationNumbersV2UpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expectedMonth0?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth1?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth2?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth3?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth4?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth5?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth6?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth7?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth8?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth9?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth10?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth11?: Maybe<NullableIntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type RenovationNumbersV2UpdateManyWithWhereWithoutRenovationPackageInput = {
  data: RenovationNumbersV2UpdateManyMutationInput;
  where: RenovationNumbersV2ScalarWhereInput;
};

export type RenovationNumbersV2UpdateManyWithoutRenovationPackageInput = {
  connect?: Maybe<Array<RenovationNumbersV2WhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RenovationNumbersV2CreateOrConnectWithoutRenovationPackageInput>>;
  create?: Maybe<Array<RenovationNumbersV2CreateWithoutRenovationPackageInput>>;
  createMany?: Maybe<RenovationNumbersV2CreateManyRenovationPackageInputEnvelope>;
  delete?: Maybe<Array<RenovationNumbersV2WhereUniqueInput>>;
  deleteMany?: Maybe<Array<RenovationNumbersV2ScalarWhereInput>>;
  disconnect?: Maybe<Array<RenovationNumbersV2WhereUniqueInput>>;
  set?: Maybe<Array<RenovationNumbersV2WhereUniqueInput>>;
  update?: Maybe<Array<RenovationNumbersV2UpdateWithWhereUniqueWithoutRenovationPackageInput>>;
  updateMany?: Maybe<Array<RenovationNumbersV2UpdateManyWithWhereWithoutRenovationPackageInput>>;
  upsert?: Maybe<Array<RenovationNumbersV2UpsertWithWhereUniqueWithoutRenovationPackageInput>>;
};

export type RenovationNumbersV2UpdateWithWhereUniqueWithoutRenovationPackageInput = {
  data: RenovationNumbersV2UpdateWithoutRenovationPackageInput;
  where: RenovationNumbersV2WhereUniqueInput;
};

export type RenovationNumbersV2UpdateWithoutRenovationPackageInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expectedMonth0?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth1?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth2?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth3?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth4?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth5?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth6?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth7?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth8?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth9?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth10?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expectedMonth11?: Maybe<NullableIntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type RenovationNumbersV2UpsertWithWhereUniqueWithoutRenovationPackageInput = {
  create: RenovationNumbersV2CreateWithoutRenovationPackageInput;
  update: RenovationNumbersV2UpdateWithoutRenovationPackageInput;
  where: RenovationNumbersV2WhereUniqueInput;
};

export type RenovationNumbersV2WhereInput = {
  AND?: Maybe<Array<RenovationNumbersV2WhereInput>>;
  NOT?: Maybe<Array<RenovationNumbersV2WhereInput>>;
  OR?: Maybe<Array<RenovationNumbersV2WhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  expectedMonth0?: Maybe<IntNullableFilter>;
  expectedMonth1?: Maybe<IntNullableFilter>;
  expectedMonth2?: Maybe<IntNullableFilter>;
  expectedMonth3?: Maybe<IntNullableFilter>;
  expectedMonth4?: Maybe<IntNullableFilter>;
  expectedMonth5?: Maybe<IntNullableFilter>;
  expectedMonth6?: Maybe<IntNullableFilter>;
  expectedMonth7?: Maybe<IntNullableFilter>;
  expectedMonth8?: Maybe<IntNullableFilter>;
  expectedMonth9?: Maybe<IntNullableFilter>;
  expectedMonth10?: Maybe<IntNullableFilter>;
  expectedMonth11?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  renovationPackage?: Maybe<RenovationPackageRelationFilter>;
  renovationPackageId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type RenovationNumbersV2WhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  renovationPackageId_year_versionType?: Maybe<RenovationNumbersV2RenovationPackageIdYearVersionTypeCompoundUniqueInput>;
};

export type RenovationNumbersWhereInput = {
  AND?: Maybe<Array<RenovationNumbersWhereInput>>;
  NOT?: Maybe<Array<RenovationNumbersWhereInput>>;
  OR?: Maybe<Array<RenovationNumbersWhereInput>>;
  actualsMonth0?: Maybe<IntNullableFilter>;
  actualsMonth1?: Maybe<IntNullableFilter>;
  actualsMonth2?: Maybe<IntNullableFilter>;
  actualsMonth3?: Maybe<IntNullableFilter>;
  actualsMonth4?: Maybe<IntNullableFilter>;
  actualsMonth5?: Maybe<IntNullableFilter>;
  actualsMonth6?: Maybe<IntNullableFilter>;
  actualsMonth7?: Maybe<IntNullableFilter>;
  actualsMonth8?: Maybe<IntNullableFilter>;
  actualsMonth9?: Maybe<IntNullableFilter>;
  actualsMonth10?: Maybe<IntNullableFilter>;
  actualsMonth11?: Maybe<IntNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  expectedMonth0?: Maybe<IntNullableFilter>;
  expectedMonth1?: Maybe<IntNullableFilter>;
  expectedMonth2?: Maybe<IntNullableFilter>;
  expectedMonth3?: Maybe<IntNullableFilter>;
  expectedMonth4?: Maybe<IntNullableFilter>;
  expectedMonth5?: Maybe<IntNullableFilter>;
  expectedMonth6?: Maybe<IntNullableFilter>;
  expectedMonth7?: Maybe<IntNullableFilter>;
  expectedMonth8?: Maybe<IntNullableFilter>;
  expectedMonth9?: Maybe<IntNullableFilter>;
  expectedMonth10?: Maybe<IntNullableFilter>;
  expectedMonth11?: Maybe<IntNullableFilter>;
  renovationPackage?: Maybe<RenovationPackageRelationFilter>;
  renovationPackageId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionId?: Maybe<BigIntFilter>;
  year?: Maybe<IntFilter>;
};

export type RenovationNumbersWhereUniqueInput = {
  renovationPackageId_year?: Maybe<RenovationNumbersRenovationPackageIdYearCompoundUniqueInput>;
};

export type RenovationPackage = {
  __typename?: 'RenovationPackage';
  budgetYear: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  premium: Scalars['Decimal'];
  premiumDelayRecognition: Scalars['Int'];
  propertyId: Scalars['String'];
  unitTypeId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  versionId: Scalars['BigInt'];
};

export type RenovationPackageCreateManyPropertyInput = {
  budgetYear: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  premium?: Maybe<Scalars['Decimal']>;
  premiumDelayRecognition?: Maybe<Scalars['Int']>;
  unitTypeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId?: Maybe<Scalars['BigInt']>;
};

export type RenovationPackageCreateManyPropertyInputEnvelope = {
  data: Array<RenovationPackageCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RenovationPackageCreateManyUnitTypeInput = {
  budgetYear: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  premium?: Maybe<Scalars['Decimal']>;
  premiumDelayRecognition?: Maybe<Scalars['Int']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId?: Maybe<Scalars['BigInt']>;
};

export type RenovationPackageCreateManyUnitTypeInputEnvelope = {
  data: Array<RenovationPackageCreateManyUnitTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RenovationPackageCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<RenovationPackageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RenovationPackageCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<RenovationPackageCreateWithoutPropertyInput>>;
  createMany?: Maybe<RenovationPackageCreateManyPropertyInputEnvelope>;
};

export type RenovationPackageCreateNestedManyWithoutUnitTypeInput = {
  connect?: Maybe<Array<RenovationPackageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RenovationPackageCreateOrConnectWithoutUnitTypeInput>>;
  create?: Maybe<Array<RenovationPackageCreateWithoutUnitTypeInput>>;
  createMany?: Maybe<RenovationPackageCreateManyUnitTypeInputEnvelope>;
};

export type RenovationPackageCreateNestedOneWithoutRenovationPackageRenovationCostCategoriesInput = {
  connect?: Maybe<RenovationPackageWhereUniqueInput>;
  connectOrCreate?: Maybe<RenovationPackageCreateOrConnectWithoutRenovationPackageRenovationCostCategoriesInput>;
  create?: Maybe<RenovationPackageCreateWithoutRenovationPackageRenovationCostCategoriesInput>;
};

export type RenovationPackageCreateNestedOneWithoutUnitTypeMappingInput = {
  connect?: Maybe<RenovationPackageWhereUniqueInput>;
  connectOrCreate?: Maybe<RenovationPackageCreateOrConnectWithoutUnitTypeMappingInput>;
  create?: Maybe<RenovationPackageCreateWithoutUnitTypeMappingInput>;
};

export type RenovationPackageCreateOrConnectWithoutPropertyInput = {
  create: RenovationPackageCreateWithoutPropertyInput;
  where: RenovationPackageWhereUniqueInput;
};

export type RenovationPackageCreateOrConnectWithoutRenovationPackageRenovationCostCategoriesInput = {
  create: RenovationPackageCreateWithoutRenovationPackageRenovationCostCategoriesInput;
  where: RenovationPackageWhereUniqueInput;
};

export type RenovationPackageCreateOrConnectWithoutUnitTypeInput = {
  create: RenovationPackageCreateWithoutUnitTypeInput;
  where: RenovationPackageWhereUniqueInput;
};

export type RenovationPackageCreateOrConnectWithoutUnitTypeMappingInput = {
  create: RenovationPackageCreateWithoutUnitTypeMappingInput;
  where: RenovationPackageWhereUniqueInput;
};

export type RenovationPackageCreateWithoutPropertyInput = {
  budgetYear: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  premium?: Maybe<Scalars['Decimal']>;
  premiumDelayRecognition?: Maybe<Scalars['Int']>;
  renovationNumbers?: Maybe<RenovationNumbersCreateNestedManyWithoutRenovationPackageInput>;
  renovationNumbersV2?: Maybe<RenovationNumbersV2CreateNestedManyWithoutRenovationPackageInput>;
  renovationPackageRenovationCostCategories?: Maybe<RenovationPackageRenovationCostCategoryCreateNestedManyWithoutRenovationPackageInput>;
  unitType: UnitTypeCreateNestedOneWithoutRenovationPackagesInput;
  unitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutRenovationPackageInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId?: Maybe<Scalars['BigInt']>;
};

export type RenovationPackageCreateWithoutRenovationPackageRenovationCostCategoriesInput = {
  budgetYear: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  premium?: Maybe<Scalars['Decimal']>;
  premiumDelayRecognition?: Maybe<Scalars['Int']>;
  property: PropertyCreateNestedOneWithoutRenovationPackagesInput;
  renovationNumbers?: Maybe<RenovationNumbersCreateNestedManyWithoutRenovationPackageInput>;
  renovationNumbersV2?: Maybe<RenovationNumbersV2CreateNestedManyWithoutRenovationPackageInput>;
  unitType: UnitTypeCreateNestedOneWithoutRenovationPackagesInput;
  unitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutRenovationPackageInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId?: Maybe<Scalars['BigInt']>;
};

export type RenovationPackageCreateWithoutUnitTypeInput = {
  budgetYear: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  premium?: Maybe<Scalars['Decimal']>;
  premiumDelayRecognition?: Maybe<Scalars['Int']>;
  property: PropertyCreateNestedOneWithoutRenovationPackagesInput;
  renovationNumbers?: Maybe<RenovationNumbersCreateNestedManyWithoutRenovationPackageInput>;
  renovationNumbersV2?: Maybe<RenovationNumbersV2CreateNestedManyWithoutRenovationPackageInput>;
  renovationPackageRenovationCostCategories?: Maybe<RenovationPackageRenovationCostCategoryCreateNestedManyWithoutRenovationPackageInput>;
  unitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutRenovationPackageInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId?: Maybe<Scalars['BigInt']>;
};

export type RenovationPackageCreateWithoutUnitTypeMappingInput = {
  budgetYear: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  premium?: Maybe<Scalars['Decimal']>;
  premiumDelayRecognition?: Maybe<Scalars['Int']>;
  property: PropertyCreateNestedOneWithoutRenovationPackagesInput;
  renovationNumbers?: Maybe<RenovationNumbersCreateNestedManyWithoutRenovationPackageInput>;
  renovationNumbersV2?: Maybe<RenovationNumbersV2CreateNestedManyWithoutRenovationPackageInput>;
  renovationPackageRenovationCostCategories?: Maybe<RenovationPackageRenovationCostCategoryCreateNestedManyWithoutRenovationPackageInput>;
  unitType: UnitTypeCreateNestedOneWithoutRenovationPackagesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId?: Maybe<Scalars['BigInt']>;
};

export type RenovationPackageListRelationFilter = {
  every?: Maybe<RenovationPackageWhereInput>;
  none?: Maybe<RenovationPackageWhereInput>;
  some?: Maybe<RenovationPackageWhereInput>;
};

export type RenovationPackageModel = {
  __typename?: 'RenovationPackageModel';
  budgetYear: Scalars['Int'];
  costCategoryMappings: Array<RenovationCostCategoryMappingModel>;
  id: Scalars['String'];
  name: Scalars['String'];
  premium: Scalars['Decimal'];
  premiumDelayRecognition: Scalars['Int'];
  propertyId: Scalars['String'];
  renovationNumbers: Array<RenovationNumbersModel>;
  simplifiedRevenueRenovationMapping?: Maybe<SimplifiedRevenueRenovationMapping>;
  unitTypeId: Scalars['String'];
  unitTypeScheduleValues: Array<UnitTypeScheduleValue>;
};


export type RenovationPackageModelRenovationNumbersArgs = {
  yearAndTypes: Array<YearAndType>;
};


export type RenovationPackageModelSimplifiedRevenueRenovationMappingArgs = {
  budgetYear: Scalars['Int'];
};

export type RenovationPackageOrderByInput = {
  budgetYear?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  premium?: Maybe<SortOrder>;
  premiumDelayRecognition?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  unitTypeId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  versionId?: Maybe<SortOrder>;
};

export type RenovationPackageRelationFilter = {
  is?: Maybe<RenovationPackageWhereInput>;
  isNot?: Maybe<RenovationPackageWhereInput>;
};

export type RenovationPackageRenovationCostCategoryCreateManyRenovationCostCategoryInput = {
  amount?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition?: Maybe<Scalars['Int']>;
  renovationPackageId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RenovationPackageRenovationCostCategoryCreateManyRenovationCostCategoryInputEnvelope = {
  data: Array<RenovationPackageRenovationCostCategoryCreateManyRenovationCostCategoryInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RenovationPackageRenovationCostCategoryCreateManyRenovationPackageInput = {
  amount?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition?: Maybe<Scalars['Int']>;
  renovationCostCategoryId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RenovationPackageRenovationCostCategoryCreateManyRenovationPackageInputEnvelope = {
  data: Array<RenovationPackageRenovationCostCategoryCreateManyRenovationPackageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RenovationPackageRenovationCostCategoryCreateNestedManyWithoutRenovationCostCategoryInput = {
  connect?: Maybe<Array<RenovationPackageRenovationCostCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RenovationPackageRenovationCostCategoryCreateOrConnectWithoutRenovationCostCategoryInput>>;
  create?: Maybe<Array<RenovationPackageRenovationCostCategoryCreateWithoutRenovationCostCategoryInput>>;
  createMany?: Maybe<RenovationPackageRenovationCostCategoryCreateManyRenovationCostCategoryInputEnvelope>;
};

export type RenovationPackageRenovationCostCategoryCreateNestedManyWithoutRenovationPackageInput = {
  connect?: Maybe<Array<RenovationPackageRenovationCostCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RenovationPackageRenovationCostCategoryCreateOrConnectWithoutRenovationPackageInput>>;
  create?: Maybe<Array<RenovationPackageRenovationCostCategoryCreateWithoutRenovationPackageInput>>;
  createMany?: Maybe<RenovationPackageRenovationCostCategoryCreateManyRenovationPackageInputEnvelope>;
};

export type RenovationPackageRenovationCostCategoryCreateOrConnectWithoutRenovationCostCategoryInput = {
  create: RenovationPackageRenovationCostCategoryCreateWithoutRenovationCostCategoryInput;
  where: RenovationPackageRenovationCostCategoryWhereUniqueInput;
};

export type RenovationPackageRenovationCostCategoryCreateOrConnectWithoutRenovationPackageInput = {
  create: RenovationPackageRenovationCostCategoryCreateWithoutRenovationPackageInput;
  where: RenovationPackageRenovationCostCategoryWhereUniqueInput;
};

export type RenovationPackageRenovationCostCategoryCreateWithoutRenovationCostCategoryInput = {
  amount?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition?: Maybe<Scalars['Int']>;
  renovationPackage: RenovationPackageCreateNestedOneWithoutRenovationPackageRenovationCostCategoriesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RenovationPackageRenovationCostCategoryCreateWithoutRenovationPackageInput = {
  amount?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition?: Maybe<Scalars['Int']>;
  renovationCostCategory: RenovationCostCategoryCreateNestedOneWithoutRenovationPackageRenovationCostCategoriesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RenovationPackageRenovationCostCategoryListRelationFilter = {
  every?: Maybe<RenovationPackageRenovationCostCategoryWhereInput>;
  none?: Maybe<RenovationPackageRenovationCostCategoryWhereInput>;
  some?: Maybe<RenovationPackageRenovationCostCategoryWhereInput>;
};

export type RenovationPackageRenovationCostCategoryRenovationPackageIdRenovationCostCategoryIdCompoundUniqueInput = {
  renovationCostCategoryId: Scalars['String'];
  renovationPackageId: Scalars['String'];
};

export type RenovationPackageRenovationCostCategoryScalarWhereInput = {
  AND?: Maybe<Array<RenovationPackageRenovationCostCategoryScalarWhereInput>>;
  NOT?: Maybe<Array<RenovationPackageRenovationCostCategoryScalarWhereInput>>;
  OR?: Maybe<Array<RenovationPackageRenovationCostCategoryScalarWhereInput>>;
  amount?: Maybe<DecimalNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  delayRecognition?: Maybe<IntFilter>;
  renovationCostCategoryId?: Maybe<StringFilter>;
  renovationPackageId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RenovationPackageRenovationCostCategoryUpdateManyMutationInput = {
  amount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type RenovationPackageRenovationCostCategoryUpdateManyWithWhereWithoutRenovationCostCategoryInput = {
  data: RenovationPackageRenovationCostCategoryUpdateManyMutationInput;
  where: RenovationPackageRenovationCostCategoryScalarWhereInput;
};

export type RenovationPackageRenovationCostCategoryUpdateManyWithWhereWithoutRenovationPackageInput = {
  data: RenovationPackageRenovationCostCategoryUpdateManyMutationInput;
  where: RenovationPackageRenovationCostCategoryScalarWhereInput;
};

export type RenovationPackageRenovationCostCategoryUpdateManyWithoutRenovationCostCategoryInput = {
  connect?: Maybe<Array<RenovationPackageRenovationCostCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RenovationPackageRenovationCostCategoryCreateOrConnectWithoutRenovationCostCategoryInput>>;
  create?: Maybe<Array<RenovationPackageRenovationCostCategoryCreateWithoutRenovationCostCategoryInput>>;
  createMany?: Maybe<RenovationPackageRenovationCostCategoryCreateManyRenovationCostCategoryInputEnvelope>;
  delete?: Maybe<Array<RenovationPackageRenovationCostCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RenovationPackageRenovationCostCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<RenovationPackageRenovationCostCategoryWhereUniqueInput>>;
  set?: Maybe<Array<RenovationPackageRenovationCostCategoryWhereUniqueInput>>;
  update?: Maybe<Array<RenovationPackageRenovationCostCategoryUpdateWithWhereUniqueWithoutRenovationCostCategoryInput>>;
  updateMany?: Maybe<Array<RenovationPackageRenovationCostCategoryUpdateManyWithWhereWithoutRenovationCostCategoryInput>>;
  upsert?: Maybe<Array<RenovationPackageRenovationCostCategoryUpsertWithWhereUniqueWithoutRenovationCostCategoryInput>>;
};

export type RenovationPackageRenovationCostCategoryUpdateManyWithoutRenovationPackageInput = {
  connect?: Maybe<Array<RenovationPackageRenovationCostCategoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RenovationPackageRenovationCostCategoryCreateOrConnectWithoutRenovationPackageInput>>;
  create?: Maybe<Array<RenovationPackageRenovationCostCategoryCreateWithoutRenovationPackageInput>>;
  createMany?: Maybe<RenovationPackageRenovationCostCategoryCreateManyRenovationPackageInputEnvelope>;
  delete?: Maybe<Array<RenovationPackageRenovationCostCategoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RenovationPackageRenovationCostCategoryScalarWhereInput>>;
  disconnect?: Maybe<Array<RenovationPackageRenovationCostCategoryWhereUniqueInput>>;
  set?: Maybe<Array<RenovationPackageRenovationCostCategoryWhereUniqueInput>>;
  update?: Maybe<Array<RenovationPackageRenovationCostCategoryUpdateWithWhereUniqueWithoutRenovationPackageInput>>;
  updateMany?: Maybe<Array<RenovationPackageRenovationCostCategoryUpdateManyWithWhereWithoutRenovationPackageInput>>;
  upsert?: Maybe<Array<RenovationPackageRenovationCostCategoryUpsertWithWhereUniqueWithoutRenovationPackageInput>>;
};

export type RenovationPackageRenovationCostCategoryUpdateWithWhereUniqueWithoutRenovationCostCategoryInput = {
  data: RenovationPackageRenovationCostCategoryUpdateWithoutRenovationCostCategoryInput;
  where: RenovationPackageRenovationCostCategoryWhereUniqueInput;
};

export type RenovationPackageRenovationCostCategoryUpdateWithWhereUniqueWithoutRenovationPackageInput = {
  data: RenovationPackageRenovationCostCategoryUpdateWithoutRenovationPackageInput;
  where: RenovationPackageRenovationCostCategoryWhereUniqueInput;
};

export type RenovationPackageRenovationCostCategoryUpdateWithoutRenovationCostCategoryInput = {
  amount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  renovationPackage?: Maybe<RenovationPackageUpdateOneRequiredWithoutRenovationPackageRenovationCostCategoriesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type RenovationPackageRenovationCostCategoryUpdateWithoutRenovationPackageInput = {
  amount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  renovationCostCategory?: Maybe<RenovationCostCategoryUpdateOneRequiredWithoutRenovationPackageRenovationCostCategoriesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type RenovationPackageRenovationCostCategoryUpsertWithWhereUniqueWithoutRenovationCostCategoryInput = {
  create: RenovationPackageRenovationCostCategoryCreateWithoutRenovationCostCategoryInput;
  update: RenovationPackageRenovationCostCategoryUpdateWithoutRenovationCostCategoryInput;
  where: RenovationPackageRenovationCostCategoryWhereUniqueInput;
};

export type RenovationPackageRenovationCostCategoryUpsertWithWhereUniqueWithoutRenovationPackageInput = {
  create: RenovationPackageRenovationCostCategoryCreateWithoutRenovationPackageInput;
  update: RenovationPackageRenovationCostCategoryUpdateWithoutRenovationPackageInput;
  where: RenovationPackageRenovationCostCategoryWhereUniqueInput;
};

export type RenovationPackageRenovationCostCategoryWhereInput = {
  AND?: Maybe<Array<RenovationPackageRenovationCostCategoryWhereInput>>;
  NOT?: Maybe<Array<RenovationPackageRenovationCostCategoryWhereInput>>;
  OR?: Maybe<Array<RenovationPackageRenovationCostCategoryWhereInput>>;
  amount?: Maybe<DecimalNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  delayRecognition?: Maybe<IntFilter>;
  renovationCostCategory?: Maybe<RenovationCostCategoryRelationFilter>;
  renovationCostCategoryId?: Maybe<StringFilter>;
  renovationPackage?: Maybe<RenovationPackageRelationFilter>;
  renovationPackageId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RenovationPackageRenovationCostCategoryWhereUniqueInput = {
  renovationPackageId_renovationCostCategoryId?: Maybe<RenovationPackageRenovationCostCategoryRenovationPackageIdRenovationCostCategoryIdCompoundUniqueInput>;
};

export enum RenovationPackageScalarFieldEnum {
  BudgetYear = 'budgetYear',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Premium = 'premium',
  PremiumDelayRecognition = 'premiumDelayRecognition',
  PropertyId = 'propertyId',
  UnitTypeId = 'unitTypeId',
  UpdatedAt = 'updatedAt',
  VersionId = 'versionId'
}

export type RenovationPackageScalarWhereInput = {
  AND?: Maybe<Array<RenovationPackageScalarWhereInput>>;
  NOT?: Maybe<Array<RenovationPackageScalarWhereInput>>;
  OR?: Maybe<Array<RenovationPackageScalarWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  premium?: Maybe<DecimalFilter>;
  premiumDelayRecognition?: Maybe<IntFilter>;
  propertyId?: Maybe<StringFilter>;
  unitTypeId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionId?: Maybe<BigIntFilter>;
};

export type RenovationPackageUnitTypeIdPropertyIdNameBudgetYearCompoundUniqueInput = {
  budgetYear: Scalars['Int'];
  name: Scalars['String'];
  propertyId: Scalars['String'];
  unitTypeId: Scalars['String'];
};

export type RenovationPackageUpdateManyMutationInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  premium?: Maybe<DecimalFieldUpdateOperationsInput>;
  premiumDelayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionId?: Maybe<BigIntFieldUpdateOperationsInput>;
};

export type RenovationPackageUpdateManyWithWhereWithoutPropertyInput = {
  data: RenovationPackageUpdateManyMutationInput;
  where: RenovationPackageScalarWhereInput;
};

export type RenovationPackageUpdateManyWithWhereWithoutUnitTypeInput = {
  data: RenovationPackageUpdateManyMutationInput;
  where: RenovationPackageScalarWhereInput;
};

export type RenovationPackageUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<RenovationPackageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RenovationPackageCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<RenovationPackageCreateWithoutPropertyInput>>;
  createMany?: Maybe<RenovationPackageCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<RenovationPackageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RenovationPackageScalarWhereInput>>;
  disconnect?: Maybe<Array<RenovationPackageWhereUniqueInput>>;
  set?: Maybe<Array<RenovationPackageWhereUniqueInput>>;
  update?: Maybe<Array<RenovationPackageUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<RenovationPackageUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<RenovationPackageUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type RenovationPackageUpdateManyWithoutUnitTypeInput = {
  connect?: Maybe<Array<RenovationPackageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RenovationPackageCreateOrConnectWithoutUnitTypeInput>>;
  create?: Maybe<Array<RenovationPackageCreateWithoutUnitTypeInput>>;
  createMany?: Maybe<RenovationPackageCreateManyUnitTypeInputEnvelope>;
  delete?: Maybe<Array<RenovationPackageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RenovationPackageScalarWhereInput>>;
  disconnect?: Maybe<Array<RenovationPackageWhereUniqueInput>>;
  set?: Maybe<Array<RenovationPackageWhereUniqueInput>>;
  update?: Maybe<Array<RenovationPackageUpdateWithWhereUniqueWithoutUnitTypeInput>>;
  updateMany?: Maybe<Array<RenovationPackageUpdateManyWithWhereWithoutUnitTypeInput>>;
  upsert?: Maybe<Array<RenovationPackageUpsertWithWhereUniqueWithoutUnitTypeInput>>;
};

export type RenovationPackageUpdateOneRequiredWithoutRenovationPackageRenovationCostCategoriesInput = {
  connect?: Maybe<RenovationPackageWhereUniqueInput>;
  connectOrCreate?: Maybe<RenovationPackageCreateOrConnectWithoutRenovationPackageRenovationCostCategoriesInput>;
  create?: Maybe<RenovationPackageCreateWithoutRenovationPackageRenovationCostCategoriesInput>;
  update?: Maybe<RenovationPackageUpdateWithoutRenovationPackageRenovationCostCategoriesInput>;
  upsert?: Maybe<RenovationPackageUpsertWithoutRenovationPackageRenovationCostCategoriesInput>;
};

export type RenovationPackageUpdateOneRequiredWithoutUnitTypeMappingInput = {
  connect?: Maybe<RenovationPackageWhereUniqueInput>;
  connectOrCreate?: Maybe<RenovationPackageCreateOrConnectWithoutUnitTypeMappingInput>;
  create?: Maybe<RenovationPackageCreateWithoutUnitTypeMappingInput>;
  update?: Maybe<RenovationPackageUpdateWithoutUnitTypeMappingInput>;
  upsert?: Maybe<RenovationPackageUpsertWithoutUnitTypeMappingInput>;
};

export type RenovationPackageUpdateWithWhereUniqueWithoutPropertyInput = {
  data: RenovationPackageUpdateWithoutPropertyInput;
  where: RenovationPackageWhereUniqueInput;
};

export type RenovationPackageUpdateWithWhereUniqueWithoutUnitTypeInput = {
  data: RenovationPackageUpdateWithoutUnitTypeInput;
  where: RenovationPackageWhereUniqueInput;
};

export type RenovationPackageUpdateWithoutPropertyInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  premium?: Maybe<DecimalFieldUpdateOperationsInput>;
  premiumDelayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  renovationNumbers?: Maybe<RenovationNumbersUpdateManyWithoutRenovationPackageInput>;
  renovationNumbersV2?: Maybe<RenovationNumbersV2UpdateManyWithoutRenovationPackageInput>;
  renovationPackageRenovationCostCategories?: Maybe<RenovationPackageRenovationCostCategoryUpdateManyWithoutRenovationPackageInput>;
  unitType?: Maybe<UnitTypeUpdateOneRequiredWithoutRenovationPackagesInput>;
  unitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutRenovationPackageInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionId?: Maybe<BigIntFieldUpdateOperationsInput>;
};

export type RenovationPackageUpdateWithoutRenovationPackageRenovationCostCategoriesInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  premium?: Maybe<DecimalFieldUpdateOperationsInput>;
  premiumDelayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutRenovationPackagesInput>;
  renovationNumbers?: Maybe<RenovationNumbersUpdateManyWithoutRenovationPackageInput>;
  renovationNumbersV2?: Maybe<RenovationNumbersV2UpdateManyWithoutRenovationPackageInput>;
  unitType?: Maybe<UnitTypeUpdateOneRequiredWithoutRenovationPackagesInput>;
  unitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutRenovationPackageInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionId?: Maybe<BigIntFieldUpdateOperationsInput>;
};

export type RenovationPackageUpdateWithoutUnitTypeInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  premium?: Maybe<DecimalFieldUpdateOperationsInput>;
  premiumDelayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutRenovationPackagesInput>;
  renovationNumbers?: Maybe<RenovationNumbersUpdateManyWithoutRenovationPackageInput>;
  renovationNumbersV2?: Maybe<RenovationNumbersV2UpdateManyWithoutRenovationPackageInput>;
  renovationPackageRenovationCostCategories?: Maybe<RenovationPackageRenovationCostCategoryUpdateManyWithoutRenovationPackageInput>;
  unitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutRenovationPackageInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionId?: Maybe<BigIntFieldUpdateOperationsInput>;
};

export type RenovationPackageUpdateWithoutUnitTypeMappingInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  premium?: Maybe<DecimalFieldUpdateOperationsInput>;
  premiumDelayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutRenovationPackagesInput>;
  renovationNumbers?: Maybe<RenovationNumbersUpdateManyWithoutRenovationPackageInput>;
  renovationNumbersV2?: Maybe<RenovationNumbersV2UpdateManyWithoutRenovationPackageInput>;
  renovationPackageRenovationCostCategories?: Maybe<RenovationPackageRenovationCostCategoryUpdateManyWithoutRenovationPackageInput>;
  unitType?: Maybe<UnitTypeUpdateOneRequiredWithoutRenovationPackagesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionId?: Maybe<BigIntFieldUpdateOperationsInput>;
};

export type RenovationPackageUpsertWithWhereUniqueWithoutPropertyInput = {
  create: RenovationPackageCreateWithoutPropertyInput;
  update: RenovationPackageUpdateWithoutPropertyInput;
  where: RenovationPackageWhereUniqueInput;
};

export type RenovationPackageUpsertWithWhereUniqueWithoutUnitTypeInput = {
  create: RenovationPackageCreateWithoutUnitTypeInput;
  update: RenovationPackageUpdateWithoutUnitTypeInput;
  where: RenovationPackageWhereUniqueInput;
};

export type RenovationPackageUpsertWithoutRenovationPackageRenovationCostCategoriesInput = {
  create: RenovationPackageCreateWithoutRenovationPackageRenovationCostCategoriesInput;
  update: RenovationPackageUpdateWithoutRenovationPackageRenovationCostCategoriesInput;
};

export type RenovationPackageUpsertWithoutUnitTypeMappingInput = {
  create: RenovationPackageCreateWithoutUnitTypeMappingInput;
  update: RenovationPackageUpdateWithoutUnitTypeMappingInput;
};

export type RenovationPackageWhereInput = {
  AND?: Maybe<Array<RenovationPackageWhereInput>>;
  NOT?: Maybe<Array<RenovationPackageWhereInput>>;
  OR?: Maybe<Array<RenovationPackageWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  premium?: Maybe<DecimalFilter>;
  premiumDelayRecognition?: Maybe<IntFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  renovationNumbers?: Maybe<RenovationNumbersListRelationFilter>;
  renovationNumbersV2?: Maybe<RenovationNumbersV2ListRelationFilter>;
  renovationPackageRenovationCostCategories?: Maybe<RenovationPackageRenovationCostCategoryListRelationFilter>;
  unitType?: Maybe<UnitTypeRelationFilter>;
  unitTypeId?: Maybe<StringFilter>;
  unitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionId?: Maybe<BigIntFilter>;
};

export type RenovationPackageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  unitTypeId_propertyId_name_budgetYear?: Maybe<RenovationPackageUnitTypeIdPropertyIdNameBudgetYearCompoundUniqueInput>;
};

export type RenovationsDriverModel = {
  __typename?: 'RenovationsDriverModel';
  costCategories: Array<RenovationCostCategoryModel>;
  isDriven: Scalars['Boolean'];
};

export type RentRollExpiringUnitModel = {
  __typename?: 'RentRollExpiringUnitModel';
  conversionMonth?: Maybe<Scalars['DateNoTime']>;
  reason: RentRollExpiringUnitReason;
  unitId: Scalars['ID'];
  unitNumber: Scalars['String'];
};

/** Provides the reason that a RentRollExpiringUnit is expiring. */
export enum RentRollExpiringUnitReason {
  MonthToMonth = 'MonthToMonth',
  Vacant = 'Vacant'
}

export type RentRollMetaDataModel = {
  __typename?: 'RentRollMetaDataModel';
  date: Scalars['DateNoTime'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  propertyId: Scalars['ID'];
};

export type RentRollModel = {
  __typename?: 'RentRollModel';
  date: Scalars['DateNoTime'];
  expiringUnits: Array<RentRollExpiringUnitModel>;
};

export type RentSummaryMetricModel = {
  __typename?: 'RentSummaryMetricModel';
  type: Scalars['String'];
  year: Scalars['Int'];
  year_total: Scalars['Decimal'];
};

export type RentSummaryModel = {
  __typename?: 'RentSummaryModel';
  in_place_rent: Array<RentSummaryMetricModel>;
  market_rents: Array<RentSummaryMetricModel>;
  new_lease_rent: Array<RentSummaryMetricModel>;
  renewal_rent: Array<RentSummaryMetricModel>;
};

export type RentalRevenueCapture = {
  __typename?: 'RentalRevenueCapture';
  accountId?: Maybe<Scalars['String']>;
  accountV2Id?: Maybe<Scalars['String']>;
  budgetCategoryId?: Maybe<Scalars['String']>;
  budgetComponentId?: Maybe<Scalars['String']>;
  chartOfAccountsId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  displayName: Scalars['String'];
  entityType: EntityType;
  id: Scalars['String'];
  order: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type RentalRevenueCaptureBudgetComponentIdBudgetCategoryIdAccountIdAccountV2IdEntityTypeChartOfAccountsIdCompoundUniqueInput = {
  accountId: Scalars['String'];
  accountV2Id: Scalars['String'];
  budgetCategoryId: Scalars['String'];
  budgetComponentId: Scalars['String'];
  chartOfAccountsId: Scalars['String'];
  entityType: EntityType;
};

export type RentalRevenueCaptureCreateManyAccountV2Input = {
  accountId?: Maybe<Scalars['String']>;
  budgetCategoryId?: Maybe<Scalars['String']>;
  budgetComponentId?: Maybe<Scalars['String']>;
  chartOfAccountsId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  entityType: EntityType;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RentalRevenueCaptureCreateManyAccountV2InputEnvelope = {
  data: Array<RentalRevenueCaptureCreateManyAccountV2Input>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RentalRevenueCaptureCreateManyChartOfAccountsInput = {
  accountId?: Maybe<Scalars['String']>;
  accountV2Id?: Maybe<Scalars['String']>;
  budgetCategoryId?: Maybe<Scalars['String']>;
  budgetComponentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  entityType: EntityType;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RentalRevenueCaptureCreateManyChartOfAccountsInputEnvelope = {
  data: Array<RentalRevenueCaptureCreateManyChartOfAccountsInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RentalRevenueCaptureCreateNestedManyWithoutAccountV2Input = {
  connect?: Maybe<Array<RentalRevenueCaptureWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RentalRevenueCaptureCreateOrConnectWithoutAccountV2Input>>;
  create?: Maybe<Array<RentalRevenueCaptureCreateWithoutAccountV2Input>>;
  createMany?: Maybe<RentalRevenueCaptureCreateManyAccountV2InputEnvelope>;
};

export type RentalRevenueCaptureCreateNestedManyWithoutChartOfAccountsInput = {
  connect?: Maybe<Array<RentalRevenueCaptureWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RentalRevenueCaptureCreateOrConnectWithoutChartOfAccountsInput>>;
  create?: Maybe<Array<RentalRevenueCaptureCreateWithoutChartOfAccountsInput>>;
  createMany?: Maybe<RentalRevenueCaptureCreateManyChartOfAccountsInputEnvelope>;
};

export type RentalRevenueCaptureCreateOrConnectWithoutAccountV2Input = {
  create: RentalRevenueCaptureCreateWithoutAccountV2Input;
  where: RentalRevenueCaptureWhereUniqueInput;
};

export type RentalRevenueCaptureCreateOrConnectWithoutChartOfAccountsInput = {
  create: RentalRevenueCaptureCreateWithoutChartOfAccountsInput;
  where: RentalRevenueCaptureWhereUniqueInput;
};

export type RentalRevenueCaptureCreateWithoutAccountV2Input = {
  accountId?: Maybe<Scalars['String']>;
  budgetCategoryId?: Maybe<Scalars['String']>;
  budgetComponentId?: Maybe<Scalars['String']>;
  chartOfAccounts: ChartOfAccountsCreateNestedOneWithoutRentalRevenueCapturesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  entityType: EntityType;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RentalRevenueCaptureCreateWithoutChartOfAccountsInput = {
  accountId?: Maybe<Scalars['String']>;
  accountV2?: Maybe<AccountV2CreateNestedOneWithoutRentalRevenueCaptureInput>;
  budgetCategoryId?: Maybe<Scalars['String']>;
  budgetComponentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  entityType: EntityType;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RentalRevenueCaptureListRelationFilter = {
  every?: Maybe<RentalRevenueCaptureWhereInput>;
  none?: Maybe<RentalRevenueCaptureWhereInput>;
  some?: Maybe<RentalRevenueCaptureWhereInput>;
};

export type RentalRevenueCaptureModel = {
  __typename?: 'RentalRevenueCaptureModel';
  accountV2Id?: Maybe<Scalars['ID']>;
  budgetComponentId?: Maybe<Scalars['ID']>;
  displayName: Scalars['String'];
  entityType: EntityType;
  id: Scalars['ID'];
  order: Scalars['Float'];
};

export type RentalRevenueCaptureOrderByInput = {
  accountId?: Maybe<SortOrder>;
  accountV2Id?: Maybe<SortOrder>;
  budgetCategoryId?: Maybe<SortOrder>;
  budgetComponentId?: Maybe<SortOrder>;
  chartOfAccountsId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  entityType?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  order?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum RentalRevenueCaptureScalarFieldEnum {
  AccountId = 'accountId',
  AccountV2Id = 'accountV2Id',
  BudgetCategoryId = 'budgetCategoryId',
  BudgetComponentId = 'budgetComponentId',
  ChartOfAccountsId = 'chartOfAccountsId',
  CreatedAt = 'createdAt',
  DisplayName = 'displayName',
  EntityType = 'entityType',
  Id = 'id',
  Order = 'order',
  UpdatedAt = 'updatedAt'
}

export type RentalRevenueCaptureScalarWhereInput = {
  AND?: Maybe<Array<RentalRevenueCaptureScalarWhereInput>>;
  NOT?: Maybe<Array<RentalRevenueCaptureScalarWhereInput>>;
  OR?: Maybe<Array<RentalRevenueCaptureScalarWhereInput>>;
  accountId?: Maybe<StringNullableFilter>;
  accountV2Id?: Maybe<StringNullableFilter>;
  budgetCategoryId?: Maybe<StringNullableFilter>;
  budgetComponentId?: Maybe<StringNullableFilter>;
  chartOfAccountsId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  displayName?: Maybe<StringFilter>;
  entityType?: Maybe<EnumEntityTypeFilter>;
  id?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RentalRevenueCaptureUpdateManyMutationInput = {
  accountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetComponentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  entityType?: Maybe<EnumEntityTypeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type RentalRevenueCaptureUpdateManyWithWhereWithoutAccountV2Input = {
  data: RentalRevenueCaptureUpdateManyMutationInput;
  where: RentalRevenueCaptureScalarWhereInput;
};

export type RentalRevenueCaptureUpdateManyWithWhereWithoutChartOfAccountsInput = {
  data: RentalRevenueCaptureUpdateManyMutationInput;
  where: RentalRevenueCaptureScalarWhereInput;
};

export type RentalRevenueCaptureUpdateManyWithoutAccountV2Input = {
  connect?: Maybe<Array<RentalRevenueCaptureWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RentalRevenueCaptureCreateOrConnectWithoutAccountV2Input>>;
  create?: Maybe<Array<RentalRevenueCaptureCreateWithoutAccountV2Input>>;
  createMany?: Maybe<RentalRevenueCaptureCreateManyAccountV2InputEnvelope>;
  delete?: Maybe<Array<RentalRevenueCaptureWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RentalRevenueCaptureScalarWhereInput>>;
  disconnect?: Maybe<Array<RentalRevenueCaptureWhereUniqueInput>>;
  set?: Maybe<Array<RentalRevenueCaptureWhereUniqueInput>>;
  update?: Maybe<Array<RentalRevenueCaptureUpdateWithWhereUniqueWithoutAccountV2Input>>;
  updateMany?: Maybe<Array<RentalRevenueCaptureUpdateManyWithWhereWithoutAccountV2Input>>;
  upsert?: Maybe<Array<RentalRevenueCaptureUpsertWithWhereUniqueWithoutAccountV2Input>>;
};

export type RentalRevenueCaptureUpdateManyWithoutChartOfAccountsInput = {
  connect?: Maybe<Array<RentalRevenueCaptureWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RentalRevenueCaptureCreateOrConnectWithoutChartOfAccountsInput>>;
  create?: Maybe<Array<RentalRevenueCaptureCreateWithoutChartOfAccountsInput>>;
  createMany?: Maybe<RentalRevenueCaptureCreateManyChartOfAccountsInputEnvelope>;
  delete?: Maybe<Array<RentalRevenueCaptureWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RentalRevenueCaptureScalarWhereInput>>;
  disconnect?: Maybe<Array<RentalRevenueCaptureWhereUniqueInput>>;
  set?: Maybe<Array<RentalRevenueCaptureWhereUniqueInput>>;
  update?: Maybe<Array<RentalRevenueCaptureUpdateWithWhereUniqueWithoutChartOfAccountsInput>>;
  updateMany?: Maybe<Array<RentalRevenueCaptureUpdateManyWithWhereWithoutChartOfAccountsInput>>;
  upsert?: Maybe<Array<RentalRevenueCaptureUpsertWithWhereUniqueWithoutChartOfAccountsInput>>;
};

export type RentalRevenueCaptureUpdateWithWhereUniqueWithoutAccountV2Input = {
  data: RentalRevenueCaptureUpdateWithoutAccountV2Input;
  where: RentalRevenueCaptureWhereUniqueInput;
};

export type RentalRevenueCaptureUpdateWithWhereUniqueWithoutChartOfAccountsInput = {
  data: RentalRevenueCaptureUpdateWithoutChartOfAccountsInput;
  where: RentalRevenueCaptureWhereUniqueInput;
};

export type RentalRevenueCaptureUpdateWithoutAccountV2Input = {
  accountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetCategoryId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetComponentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  chartOfAccounts?: Maybe<ChartOfAccountsUpdateOneRequiredWithoutRentalRevenueCapturesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  entityType?: Maybe<EnumEntityTypeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type RentalRevenueCaptureUpdateWithoutChartOfAccountsInput = {
  accountId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  accountV2?: Maybe<AccountV2UpdateOneWithoutRentalRevenueCaptureInput>;
  budgetCategoryId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  budgetComponentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: Maybe<StringFieldUpdateOperationsInput>;
  entityType?: Maybe<EnumEntityTypeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type RentalRevenueCaptureUpsertWithWhereUniqueWithoutAccountV2Input = {
  create: RentalRevenueCaptureCreateWithoutAccountV2Input;
  update: RentalRevenueCaptureUpdateWithoutAccountV2Input;
  where: RentalRevenueCaptureWhereUniqueInput;
};

export type RentalRevenueCaptureUpsertWithWhereUniqueWithoutChartOfAccountsInput = {
  create: RentalRevenueCaptureCreateWithoutChartOfAccountsInput;
  update: RentalRevenueCaptureUpdateWithoutChartOfAccountsInput;
  where: RentalRevenueCaptureWhereUniqueInput;
};

export type RentalRevenueCaptureWhereInput = {
  AND?: Maybe<Array<RentalRevenueCaptureWhereInput>>;
  NOT?: Maybe<Array<RentalRevenueCaptureWhereInput>>;
  OR?: Maybe<Array<RentalRevenueCaptureWhereInput>>;
  accountId?: Maybe<StringNullableFilter>;
  accountV2?: Maybe<AccountV2RelationFilter>;
  accountV2Id?: Maybe<StringNullableFilter>;
  budgetCategoryId?: Maybe<StringNullableFilter>;
  budgetComponentId?: Maybe<StringNullableFilter>;
  chartOfAccounts?: Maybe<ChartOfAccountsRelationFilter>;
  chartOfAccountsId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  displayName?: Maybe<StringFilter>;
  entityType?: Maybe<EnumEntityTypeFilter>;
  id?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RentalRevenueCaptureWhereUniqueInput = {
  budgetComponentId_budgetCategoryId_accountId_accountV2Id_entityType_chartOfAccountsId?: Maybe<RentalRevenueCaptureBudgetComponentIdBudgetCategoryIdAccountIdAccountV2IdEntityTypeChartOfAccountsIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type ReportTableAccount = {
  __typename?: 'ReportTableAccount';
  accountId: Scalars['String'];
  id: Scalars['String'];
  reportTableId: Scalars['String'];
};

export type ReportTableAccountCreateManyAccountInput = {
  id?: Maybe<Scalars['String']>;
  reportTableId: Scalars['String'];
};

export type ReportTableAccountCreateManyAccountInputEnvelope = {
  data: Array<ReportTableAccountCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ReportTableAccountCreateManyReportTableInput = {
  accountId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
};

export type ReportTableAccountCreateManyReportTableInputEnvelope = {
  data: Array<ReportTableAccountCreateManyReportTableInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ReportTableAccountCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<ReportTableAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReportTableAccountCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<ReportTableAccountCreateWithoutAccountInput>>;
  createMany?: Maybe<ReportTableAccountCreateManyAccountInputEnvelope>;
};

export type ReportTableAccountCreateNestedManyWithoutReportTableInput = {
  connect?: Maybe<Array<ReportTableAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReportTableAccountCreateOrConnectWithoutReportTableInput>>;
  create?: Maybe<Array<ReportTableAccountCreateWithoutReportTableInput>>;
  createMany?: Maybe<ReportTableAccountCreateManyReportTableInputEnvelope>;
};

export type ReportTableAccountCreateOrConnectWithoutAccountInput = {
  create: ReportTableAccountCreateWithoutAccountInput;
  where: ReportTableAccountWhereUniqueInput;
};

export type ReportTableAccountCreateOrConnectWithoutReportTableInput = {
  create: ReportTableAccountCreateWithoutReportTableInput;
  where: ReportTableAccountWhereUniqueInput;
};

export type ReportTableAccountCreateWithoutAccountInput = {
  id?: Maybe<Scalars['String']>;
  reportTable: ReportTableCreateNestedOneWithoutAccountsInput;
};

export type ReportTableAccountCreateWithoutReportTableInput = {
  account: AccountV2CreateNestedOneWithoutReportTableAccountsInput;
  id?: Maybe<Scalars['String']>;
};

export type ReportTableAccountListRelationFilter = {
  every?: Maybe<ReportTableAccountWhereInput>;
  none?: Maybe<ReportTableAccountWhereInput>;
  some?: Maybe<ReportTableAccountWhereInput>;
};

export type ReportTableAccountOrderByInput = {
  accountId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  reportTableId?: Maybe<SortOrder>;
};

export enum ReportTableAccountScalarFieldEnum {
  AccountId = 'accountId',
  Id = 'id',
  ReportTableId = 'reportTableId'
}

export type ReportTableAccountScalarWhereInput = {
  AND?: Maybe<Array<ReportTableAccountScalarWhereInput>>;
  NOT?: Maybe<Array<ReportTableAccountScalarWhereInput>>;
  OR?: Maybe<Array<ReportTableAccountScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  reportTableId?: Maybe<StringFilter>;
};

export type ReportTableAccountUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ReportTableAccountUpdateManyWithWhereWithoutAccountInput = {
  data: ReportTableAccountUpdateManyMutationInput;
  where: ReportTableAccountScalarWhereInput;
};

export type ReportTableAccountUpdateManyWithWhereWithoutReportTableInput = {
  data: ReportTableAccountUpdateManyMutationInput;
  where: ReportTableAccountScalarWhereInput;
};

export type ReportTableAccountUpdateManyWithoutAccountInput = {
  connect?: Maybe<Array<ReportTableAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReportTableAccountCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<ReportTableAccountCreateWithoutAccountInput>>;
  createMany?: Maybe<ReportTableAccountCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<ReportTableAccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ReportTableAccountScalarWhereInput>>;
  disconnect?: Maybe<Array<ReportTableAccountWhereUniqueInput>>;
  set?: Maybe<Array<ReportTableAccountWhereUniqueInput>>;
  update?: Maybe<Array<ReportTableAccountUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<ReportTableAccountUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<ReportTableAccountUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type ReportTableAccountUpdateManyWithoutReportTableInput = {
  connect?: Maybe<Array<ReportTableAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReportTableAccountCreateOrConnectWithoutReportTableInput>>;
  create?: Maybe<Array<ReportTableAccountCreateWithoutReportTableInput>>;
  createMany?: Maybe<ReportTableAccountCreateManyReportTableInputEnvelope>;
  delete?: Maybe<Array<ReportTableAccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ReportTableAccountScalarWhereInput>>;
  disconnect?: Maybe<Array<ReportTableAccountWhereUniqueInput>>;
  set?: Maybe<Array<ReportTableAccountWhereUniqueInput>>;
  update?: Maybe<Array<ReportTableAccountUpdateWithWhereUniqueWithoutReportTableInput>>;
  updateMany?: Maybe<Array<ReportTableAccountUpdateManyWithWhereWithoutReportTableInput>>;
  upsert?: Maybe<Array<ReportTableAccountUpsertWithWhereUniqueWithoutReportTableInput>>;
};

export type ReportTableAccountUpdateWithWhereUniqueWithoutAccountInput = {
  data: ReportTableAccountUpdateWithoutAccountInput;
  where: ReportTableAccountWhereUniqueInput;
};

export type ReportTableAccountUpdateWithWhereUniqueWithoutReportTableInput = {
  data: ReportTableAccountUpdateWithoutReportTableInput;
  where: ReportTableAccountWhereUniqueInput;
};

export type ReportTableAccountUpdateWithoutAccountInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  reportTable?: Maybe<ReportTableUpdateOneRequiredWithoutAccountsInput>;
};

export type ReportTableAccountUpdateWithoutReportTableInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutReportTableAccountsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ReportTableAccountUpsertWithWhereUniqueWithoutAccountInput = {
  create: ReportTableAccountCreateWithoutAccountInput;
  update: ReportTableAccountUpdateWithoutAccountInput;
  where: ReportTableAccountWhereUniqueInput;
};

export type ReportTableAccountUpsertWithWhereUniqueWithoutReportTableInput = {
  create: ReportTableAccountCreateWithoutReportTableInput;
  update: ReportTableAccountUpdateWithoutReportTableInput;
  where: ReportTableAccountWhereUniqueInput;
};

export type ReportTableAccountViewModel = {
  __typename?: 'ReportTableAccountViewModel';
  accountId: Scalars['String'];
};

export type ReportTableAccountWhereInput = {
  AND?: Maybe<Array<ReportTableAccountWhereInput>>;
  NOT?: Maybe<Array<ReportTableAccountWhereInput>>;
  OR?: Maybe<Array<ReportTableAccountWhereInput>>;
  account?: Maybe<AccountV2RelationFilter>;
  accountId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  reportTable?: Maybe<ReportTableRelationFilter>;
  reportTableId?: Maybe<StringFilter>;
};

export type ReportTableAccountWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ReportTableBudgetComponentCreateManyBudgetComponentInput = {
  id?: Maybe<Scalars['String']>;
  reportTableId: Scalars['String'];
};

export type ReportTableBudgetComponentCreateManyBudgetComponentInputEnvelope = {
  data: Array<ReportTableBudgetComponentCreateManyBudgetComponentInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ReportTableBudgetComponentCreateManyReportTableInput = {
  budgetComponentId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
};

export type ReportTableBudgetComponentCreateManyReportTableInputEnvelope = {
  data: Array<ReportTableBudgetComponentCreateManyReportTableInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ReportTableBudgetComponentCreateNestedManyWithoutBudgetComponentInput = {
  connect?: Maybe<Array<ReportTableBudgetComponentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReportTableBudgetComponentCreateOrConnectWithoutBudgetComponentInput>>;
  create?: Maybe<Array<ReportTableBudgetComponentCreateWithoutBudgetComponentInput>>;
  createMany?: Maybe<ReportTableBudgetComponentCreateManyBudgetComponentInputEnvelope>;
};

export type ReportTableBudgetComponentCreateNestedManyWithoutReportTableInput = {
  connect?: Maybe<Array<ReportTableBudgetComponentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReportTableBudgetComponentCreateOrConnectWithoutReportTableInput>>;
  create?: Maybe<Array<ReportTableBudgetComponentCreateWithoutReportTableInput>>;
  createMany?: Maybe<ReportTableBudgetComponentCreateManyReportTableInputEnvelope>;
};

export type ReportTableBudgetComponentCreateOrConnectWithoutBudgetComponentInput = {
  create: ReportTableBudgetComponentCreateWithoutBudgetComponentInput;
  where: ReportTableBudgetComponentWhereUniqueInput;
};

export type ReportTableBudgetComponentCreateOrConnectWithoutReportTableInput = {
  create: ReportTableBudgetComponentCreateWithoutReportTableInput;
  where: ReportTableBudgetComponentWhereUniqueInput;
};

export type ReportTableBudgetComponentCreateWithoutBudgetComponentInput = {
  id?: Maybe<Scalars['String']>;
  reportTable: ReportTableCreateNestedOneWithoutBudgetComponentsInput;
};

export type ReportTableBudgetComponentCreateWithoutReportTableInput = {
  budgetComponent: BudgetComponentV2CreateNestedOneWithoutReportTableBudgetComponentsInput;
  id?: Maybe<Scalars['String']>;
};

export type ReportTableBudgetComponentListRelationFilter = {
  every?: Maybe<ReportTableBudgetComponentWhereInput>;
  none?: Maybe<ReportTableBudgetComponentWhereInput>;
  some?: Maybe<ReportTableBudgetComponentWhereInput>;
};

export type ReportTableBudgetComponentScalarWhereInput = {
  AND?: Maybe<Array<ReportTableBudgetComponentScalarWhereInput>>;
  NOT?: Maybe<Array<ReportTableBudgetComponentScalarWhereInput>>;
  OR?: Maybe<Array<ReportTableBudgetComponentScalarWhereInput>>;
  budgetComponentId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  reportTableId?: Maybe<StringFilter>;
};

export type ReportTableBudgetComponentUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ReportTableBudgetComponentUpdateManyWithWhereWithoutBudgetComponentInput = {
  data: ReportTableBudgetComponentUpdateManyMutationInput;
  where: ReportTableBudgetComponentScalarWhereInput;
};

export type ReportTableBudgetComponentUpdateManyWithWhereWithoutReportTableInput = {
  data: ReportTableBudgetComponentUpdateManyMutationInput;
  where: ReportTableBudgetComponentScalarWhereInput;
};

export type ReportTableBudgetComponentUpdateManyWithoutBudgetComponentInput = {
  connect?: Maybe<Array<ReportTableBudgetComponentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReportTableBudgetComponentCreateOrConnectWithoutBudgetComponentInput>>;
  create?: Maybe<Array<ReportTableBudgetComponentCreateWithoutBudgetComponentInput>>;
  createMany?: Maybe<ReportTableBudgetComponentCreateManyBudgetComponentInputEnvelope>;
  delete?: Maybe<Array<ReportTableBudgetComponentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ReportTableBudgetComponentScalarWhereInput>>;
  disconnect?: Maybe<Array<ReportTableBudgetComponentWhereUniqueInput>>;
  set?: Maybe<Array<ReportTableBudgetComponentWhereUniqueInput>>;
  update?: Maybe<Array<ReportTableBudgetComponentUpdateWithWhereUniqueWithoutBudgetComponentInput>>;
  updateMany?: Maybe<Array<ReportTableBudgetComponentUpdateManyWithWhereWithoutBudgetComponentInput>>;
  upsert?: Maybe<Array<ReportTableBudgetComponentUpsertWithWhereUniqueWithoutBudgetComponentInput>>;
};

export type ReportTableBudgetComponentUpdateManyWithoutReportTableInput = {
  connect?: Maybe<Array<ReportTableBudgetComponentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReportTableBudgetComponentCreateOrConnectWithoutReportTableInput>>;
  create?: Maybe<Array<ReportTableBudgetComponentCreateWithoutReportTableInput>>;
  createMany?: Maybe<ReportTableBudgetComponentCreateManyReportTableInputEnvelope>;
  delete?: Maybe<Array<ReportTableBudgetComponentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ReportTableBudgetComponentScalarWhereInput>>;
  disconnect?: Maybe<Array<ReportTableBudgetComponentWhereUniqueInput>>;
  set?: Maybe<Array<ReportTableBudgetComponentWhereUniqueInput>>;
  update?: Maybe<Array<ReportTableBudgetComponentUpdateWithWhereUniqueWithoutReportTableInput>>;
  updateMany?: Maybe<Array<ReportTableBudgetComponentUpdateManyWithWhereWithoutReportTableInput>>;
  upsert?: Maybe<Array<ReportTableBudgetComponentUpsertWithWhereUniqueWithoutReportTableInput>>;
};

export type ReportTableBudgetComponentUpdateWithWhereUniqueWithoutBudgetComponentInput = {
  data: ReportTableBudgetComponentUpdateWithoutBudgetComponentInput;
  where: ReportTableBudgetComponentWhereUniqueInput;
};

export type ReportTableBudgetComponentUpdateWithWhereUniqueWithoutReportTableInput = {
  data: ReportTableBudgetComponentUpdateWithoutReportTableInput;
  where: ReportTableBudgetComponentWhereUniqueInput;
};

export type ReportTableBudgetComponentUpdateWithoutBudgetComponentInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  reportTable?: Maybe<ReportTableUpdateOneRequiredWithoutBudgetComponentsInput>;
};

export type ReportTableBudgetComponentUpdateWithoutReportTableInput = {
  budgetComponent?: Maybe<BudgetComponentV2UpdateOneRequiredWithoutReportTableBudgetComponentsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ReportTableBudgetComponentUpsertWithWhereUniqueWithoutBudgetComponentInput = {
  create: ReportTableBudgetComponentCreateWithoutBudgetComponentInput;
  update: ReportTableBudgetComponentUpdateWithoutBudgetComponentInput;
  where: ReportTableBudgetComponentWhereUniqueInput;
};

export type ReportTableBudgetComponentUpsertWithWhereUniqueWithoutReportTableInput = {
  create: ReportTableBudgetComponentCreateWithoutReportTableInput;
  update: ReportTableBudgetComponentUpdateWithoutReportTableInput;
  where: ReportTableBudgetComponentWhereUniqueInput;
};

export type ReportTableBudgetComponentWhereInput = {
  AND?: Maybe<Array<ReportTableBudgetComponentWhereInput>>;
  NOT?: Maybe<Array<ReportTableBudgetComponentWhereInput>>;
  OR?: Maybe<Array<ReportTableBudgetComponentWhereInput>>;
  budgetComponent?: Maybe<BudgetComponentV2RelationFilter>;
  budgetComponentId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  reportTable?: Maybe<ReportTableRelationFilter>;
  reportTableId?: Maybe<StringFilter>;
};

export type ReportTableBudgetComponentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ReportTableClientIdBudgetYearNameCompoundUniqueInput = {
  budgetYear: Scalars['Int'];
  clientId: Scalars['String'];
  name: Scalars['String'];
};

export type ReportTableCreateManyClientInput = {
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type ReportTableCreateManyClientInputEnvelope = {
  data: Array<ReportTableCreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ReportTableCreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<ReportTableWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReportTableCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<ReportTableCreateWithoutClientInput>>;
  createMany?: Maybe<ReportTableCreateManyClientInputEnvelope>;
};

export type ReportTableCreateNestedOneWithoutAccountsInput = {
  connect?: Maybe<ReportTableWhereUniqueInput>;
  connectOrCreate?: Maybe<ReportTableCreateOrConnectWithoutAccountsInput>;
  create?: Maybe<ReportTableCreateWithoutAccountsInput>;
};

export type ReportTableCreateNestedOneWithoutBudgetComponentsInput = {
  connect?: Maybe<ReportTableWhereUniqueInput>;
  connectOrCreate?: Maybe<ReportTableCreateOrConnectWithoutBudgetComponentsInput>;
  create?: Maybe<ReportTableCreateWithoutBudgetComponentsInput>;
};

export type ReportTableCreateOrConnectWithoutAccountsInput = {
  create: ReportTableCreateWithoutAccountsInput;
  where: ReportTableWhereUniqueInput;
};

export type ReportTableCreateOrConnectWithoutBudgetComponentsInput = {
  create: ReportTableCreateWithoutBudgetComponentsInput;
  where: ReportTableWhereUniqueInput;
};

export type ReportTableCreateOrConnectWithoutClientInput = {
  create: ReportTableCreateWithoutClientInput;
  where: ReportTableWhereUniqueInput;
};

export type ReportTableCreateWithoutAccountsInput = {
  budgetComponents?: Maybe<ReportTableBudgetComponentCreateNestedManyWithoutReportTableInput>;
  budgetYear: Scalars['Int'];
  client: ClientCreateNestedOneWithoutReportTablesInput;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type ReportTableCreateWithoutBudgetComponentsInput = {
  accounts?: Maybe<ReportTableAccountCreateNestedManyWithoutReportTableInput>;
  budgetYear: Scalars['Int'];
  client: ClientCreateNestedOneWithoutReportTablesInput;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type ReportTableCreateWithoutClientInput = {
  accounts?: Maybe<ReportTableAccountCreateNestedManyWithoutReportTableInput>;
  budgetComponents?: Maybe<ReportTableBudgetComponentCreateNestedManyWithoutReportTableInput>;
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type ReportTableListRelationFilter = {
  every?: Maybe<ReportTableWhereInput>;
  none?: Maybe<ReportTableWhereInput>;
  some?: Maybe<ReportTableWhereInput>;
};

export type ReportTableRelationFilter = {
  is?: Maybe<ReportTableWhereInput>;
  isNot?: Maybe<ReportTableWhereInput>;
};

export type ReportTableScalarWhereInput = {
  AND?: Maybe<Array<ReportTableScalarWhereInput>>;
  NOT?: Maybe<Array<ReportTableScalarWhereInput>>;
  OR?: Maybe<Array<ReportTableScalarWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  clientId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
};

export type ReportTableUpdateManyMutationInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ReportTableUpdateManyWithWhereWithoutClientInput = {
  data: ReportTableUpdateManyMutationInput;
  where: ReportTableScalarWhereInput;
};

export type ReportTableUpdateManyWithoutClientInput = {
  connect?: Maybe<Array<ReportTableWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReportTableCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<ReportTableCreateWithoutClientInput>>;
  createMany?: Maybe<ReportTableCreateManyClientInputEnvelope>;
  delete?: Maybe<Array<ReportTableWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ReportTableScalarWhereInput>>;
  disconnect?: Maybe<Array<ReportTableWhereUniqueInput>>;
  set?: Maybe<Array<ReportTableWhereUniqueInput>>;
  update?: Maybe<Array<ReportTableUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<ReportTableUpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<ReportTableUpsertWithWhereUniqueWithoutClientInput>>;
};

export type ReportTableUpdateOneRequiredWithoutAccountsInput = {
  connect?: Maybe<ReportTableWhereUniqueInput>;
  connectOrCreate?: Maybe<ReportTableCreateOrConnectWithoutAccountsInput>;
  create?: Maybe<ReportTableCreateWithoutAccountsInput>;
  update?: Maybe<ReportTableUpdateWithoutAccountsInput>;
  upsert?: Maybe<ReportTableUpsertWithoutAccountsInput>;
};

export type ReportTableUpdateOneRequiredWithoutBudgetComponentsInput = {
  connect?: Maybe<ReportTableWhereUniqueInput>;
  connectOrCreate?: Maybe<ReportTableCreateOrConnectWithoutBudgetComponentsInput>;
  create?: Maybe<ReportTableCreateWithoutBudgetComponentsInput>;
  update?: Maybe<ReportTableUpdateWithoutBudgetComponentsInput>;
  upsert?: Maybe<ReportTableUpsertWithoutBudgetComponentsInput>;
};

export type ReportTableUpdateWithWhereUniqueWithoutClientInput = {
  data: ReportTableUpdateWithoutClientInput;
  where: ReportTableWhereUniqueInput;
};

export type ReportTableUpdateWithoutAccountsInput = {
  budgetComponents?: Maybe<ReportTableBudgetComponentUpdateManyWithoutReportTableInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutReportTablesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ReportTableUpdateWithoutBudgetComponentsInput = {
  accounts?: Maybe<ReportTableAccountUpdateManyWithoutReportTableInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutReportTablesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ReportTableUpdateWithoutClientInput = {
  accounts?: Maybe<ReportTableAccountUpdateManyWithoutReportTableInput>;
  budgetComponents?: Maybe<ReportTableBudgetComponentUpdateManyWithoutReportTableInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ReportTableUpsertWithWhereUniqueWithoutClientInput = {
  create: ReportTableCreateWithoutClientInput;
  update: ReportTableUpdateWithoutClientInput;
  where: ReportTableWhereUniqueInput;
};

export type ReportTableUpsertWithoutAccountsInput = {
  create: ReportTableCreateWithoutAccountsInput;
  update: ReportTableUpdateWithoutAccountsInput;
};

export type ReportTableUpsertWithoutBudgetComponentsInput = {
  create: ReportTableCreateWithoutBudgetComponentsInput;
  update: ReportTableUpdateWithoutBudgetComponentsInput;
};

export type ReportTableViewModel = {
  __typename?: 'ReportTableViewModel';
  accounts: Array<ReportTableAccountViewModel>;
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type ReportTableWhereInput = {
  AND?: Maybe<Array<ReportTableWhereInput>>;
  NOT?: Maybe<Array<ReportTableWhereInput>>;
  OR?: Maybe<Array<ReportTableWhereInput>>;
  accounts?: Maybe<ReportTableAccountListRelationFilter>;
  budgetComponents?: Maybe<ReportTableBudgetComponentListRelationFilter>;
  budgetYear?: Maybe<IntFilter>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  order?: Maybe<IntFilter>;
};

export type ReportTableWhereUniqueInput = {
  clientId_budgetYear_name?: Maybe<ReportTableClientIdBudgetYearNameCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type RevenueDriverAssumption = {
  __typename?: 'RevenueDriverAssumption';
  createdAt: Scalars['DateTime'];
  delayRecognition: Scalars['Int'];
  effectiveEndMonthIndex: Scalars['Int'];
  effectiveEndYear: Scalars['Int'];
  effectiveStartMonthIndex: Scalars['Int'];
  effectiveStartYear: Scalars['Int'];
  id: Scalars['String'];
  locked: Scalars['Boolean'];
  percentOf?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  revenueDriverTypeId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RevenueDriverAssumptionCreateManyPropertyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition?: Maybe<Scalars['Int']>;
  effectiveEndMonthIndex: Scalars['Int'];
  effectiveEndYear: Scalars['Int'];
  effectiveStartMonthIndex: Scalars['Int'];
  effectiveStartYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  percentOf?: Maybe<Scalars['Decimal']>;
  revenueDriverTypeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RevenueDriverAssumptionCreateManyPropertyInputEnvelope = {
  data: Array<RevenueDriverAssumptionCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RevenueDriverAssumptionCreateManyRevenueDriverTypeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition?: Maybe<Scalars['Int']>;
  effectiveEndMonthIndex: Scalars['Int'];
  effectiveEndYear: Scalars['Int'];
  effectiveStartMonthIndex: Scalars['Int'];
  effectiveStartYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  percentOf?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RevenueDriverAssumptionCreateManyRevenueDriverTypeInputEnvelope = {
  data: Array<RevenueDriverAssumptionCreateManyRevenueDriverTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RevenueDriverAssumptionCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<RevenueDriverAssumptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RevenueDriverAssumptionCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<RevenueDriverAssumptionCreateWithoutPropertyInput>>;
  createMany?: Maybe<RevenueDriverAssumptionCreateManyPropertyInputEnvelope>;
};

export type RevenueDriverAssumptionCreateNestedManyWithoutRevenueDriverTypeInput = {
  connect?: Maybe<Array<RevenueDriverAssumptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RevenueDriverAssumptionCreateOrConnectWithoutRevenueDriverTypeInput>>;
  create?: Maybe<Array<RevenueDriverAssumptionCreateWithoutRevenueDriverTypeInput>>;
  createMany?: Maybe<RevenueDriverAssumptionCreateManyRevenueDriverTypeInputEnvelope>;
};

export type RevenueDriverAssumptionCreateOrConnectWithoutPropertyInput = {
  create: RevenueDriverAssumptionCreateWithoutPropertyInput;
  where: RevenueDriverAssumptionWhereUniqueInput;
};

export type RevenueDriverAssumptionCreateOrConnectWithoutRevenueDriverTypeInput = {
  create: RevenueDriverAssumptionCreateWithoutRevenueDriverTypeInput;
  where: RevenueDriverAssumptionWhereUniqueInput;
};

export type RevenueDriverAssumptionCreateWithoutPropertyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition?: Maybe<Scalars['Int']>;
  effectiveEndMonthIndex: Scalars['Int'];
  effectiveEndYear: Scalars['Int'];
  effectiveStartMonthIndex: Scalars['Int'];
  effectiveStartYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  percentOf?: Maybe<Scalars['Decimal']>;
  revenueDriverType: RevenueDriverTypeCreateNestedOneWithoutRevenueDriverAssumptionsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RevenueDriverAssumptionCreateWithoutRevenueDriverTypeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  delayRecognition?: Maybe<Scalars['Int']>;
  effectiveEndMonthIndex: Scalars['Int'];
  effectiveEndYear: Scalars['Int'];
  effectiveStartMonthIndex: Scalars['Int'];
  effectiveStartYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  percentOf?: Maybe<Scalars['Decimal']>;
  property: PropertyCreateNestedOneWithoutRevenueDriverAssumptionsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RevenueDriverAssumptionListRelationFilter = {
  every?: Maybe<RevenueDriverAssumptionWhereInput>;
  none?: Maybe<RevenueDriverAssumptionWhereInput>;
  some?: Maybe<RevenueDriverAssumptionWhereInput>;
};

export type RevenueDriverAssumptionOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  delayRecognition?: Maybe<SortOrder>;
  effectiveEndMonthIndex?: Maybe<SortOrder>;
  effectiveEndYear?: Maybe<SortOrder>;
  effectiveStartMonthIndex?: Maybe<SortOrder>;
  effectiveStartYear?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  locked?: Maybe<SortOrder>;
  percentOf?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  revenueDriverTypeId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type RevenueDriverAssumptionRevenueDriverTypeIdEffectiveEndYearPropertyIdCompoundUniqueInput = {
  effectiveEndYear: Scalars['Int'];
  propertyId: Scalars['String'];
  revenueDriverTypeId: Scalars['String'];
};

export enum RevenueDriverAssumptionScalarFieldEnum {
  CreatedAt = 'createdAt',
  DelayRecognition = 'delayRecognition',
  EffectiveEndMonthIndex = 'effectiveEndMonthIndex',
  EffectiveEndYear = 'effectiveEndYear',
  EffectiveStartMonthIndex = 'effectiveStartMonthIndex',
  EffectiveStartYear = 'effectiveStartYear',
  Id = 'id',
  Locked = 'locked',
  PercentOf = 'percentOf',
  PropertyId = 'propertyId',
  RevenueDriverTypeId = 'revenueDriverTypeId',
  UpdatedAt = 'updatedAt'
}

export type RevenueDriverAssumptionScalarWhereInput = {
  AND?: Maybe<Array<RevenueDriverAssumptionScalarWhereInput>>;
  NOT?: Maybe<Array<RevenueDriverAssumptionScalarWhereInput>>;
  OR?: Maybe<Array<RevenueDriverAssumptionScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  delayRecognition?: Maybe<IntFilter>;
  effectiveEndMonthIndex?: Maybe<IntFilter>;
  effectiveEndYear?: Maybe<IntFilter>;
  effectiveStartMonthIndex?: Maybe<IntFilter>;
  effectiveStartYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  locked?: Maybe<BoolFilter>;
  percentOf?: Maybe<DecimalNullableFilter>;
  propertyId?: Maybe<StringFilter>;
  revenueDriverTypeId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RevenueDriverAssumptionUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveEndMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveEndYear?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveStartYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  locked?: Maybe<BoolFieldUpdateOperationsInput>;
  percentOf?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type RevenueDriverAssumptionUpdateManyWithWhereWithoutPropertyInput = {
  data: RevenueDriverAssumptionUpdateManyMutationInput;
  where: RevenueDriverAssumptionScalarWhereInput;
};

export type RevenueDriverAssumptionUpdateManyWithWhereWithoutRevenueDriverTypeInput = {
  data: RevenueDriverAssumptionUpdateManyMutationInput;
  where: RevenueDriverAssumptionScalarWhereInput;
};

export type RevenueDriverAssumptionUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<RevenueDriverAssumptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RevenueDriverAssumptionCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<RevenueDriverAssumptionCreateWithoutPropertyInput>>;
  createMany?: Maybe<RevenueDriverAssumptionCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<RevenueDriverAssumptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RevenueDriverAssumptionScalarWhereInput>>;
  disconnect?: Maybe<Array<RevenueDriverAssumptionWhereUniqueInput>>;
  set?: Maybe<Array<RevenueDriverAssumptionWhereUniqueInput>>;
  update?: Maybe<Array<RevenueDriverAssumptionUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<RevenueDriverAssumptionUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<RevenueDriverAssumptionUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type RevenueDriverAssumptionUpdateManyWithoutRevenueDriverTypeInput = {
  connect?: Maybe<Array<RevenueDriverAssumptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RevenueDriverAssumptionCreateOrConnectWithoutRevenueDriverTypeInput>>;
  create?: Maybe<Array<RevenueDriverAssumptionCreateWithoutRevenueDriverTypeInput>>;
  createMany?: Maybe<RevenueDriverAssumptionCreateManyRevenueDriverTypeInputEnvelope>;
  delete?: Maybe<Array<RevenueDriverAssumptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RevenueDriverAssumptionScalarWhereInput>>;
  disconnect?: Maybe<Array<RevenueDriverAssumptionWhereUniqueInput>>;
  set?: Maybe<Array<RevenueDriverAssumptionWhereUniqueInput>>;
  update?: Maybe<Array<RevenueDriverAssumptionUpdateWithWhereUniqueWithoutRevenueDriverTypeInput>>;
  updateMany?: Maybe<Array<RevenueDriverAssumptionUpdateManyWithWhereWithoutRevenueDriverTypeInput>>;
  upsert?: Maybe<Array<RevenueDriverAssumptionUpsertWithWhereUniqueWithoutRevenueDriverTypeInput>>;
};

export type RevenueDriverAssumptionUpdateWithWhereUniqueWithoutPropertyInput = {
  data: RevenueDriverAssumptionUpdateWithoutPropertyInput;
  where: RevenueDriverAssumptionWhereUniqueInput;
};

export type RevenueDriverAssumptionUpdateWithWhereUniqueWithoutRevenueDriverTypeInput = {
  data: RevenueDriverAssumptionUpdateWithoutRevenueDriverTypeInput;
  where: RevenueDriverAssumptionWhereUniqueInput;
};

export type RevenueDriverAssumptionUpdateWithoutPropertyInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveEndMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveEndYear?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveStartYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  locked?: Maybe<BoolFieldUpdateOperationsInput>;
  percentOf?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  revenueDriverType?: Maybe<RevenueDriverTypeUpdateOneRequiredWithoutRevenueDriverAssumptionsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type RevenueDriverAssumptionUpdateWithoutRevenueDriverTypeInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  delayRecognition?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveEndMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveEndYear?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveStartMonthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  effectiveStartYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  locked?: Maybe<BoolFieldUpdateOperationsInput>;
  percentOf?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutRevenueDriverAssumptionsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type RevenueDriverAssumptionUpsertWithWhereUniqueWithoutPropertyInput = {
  create: RevenueDriverAssumptionCreateWithoutPropertyInput;
  update: RevenueDriverAssumptionUpdateWithoutPropertyInput;
  where: RevenueDriverAssumptionWhereUniqueInput;
};

export type RevenueDriverAssumptionUpsertWithWhereUniqueWithoutRevenueDriverTypeInput = {
  create: RevenueDriverAssumptionCreateWithoutRevenueDriverTypeInput;
  update: RevenueDriverAssumptionUpdateWithoutRevenueDriverTypeInput;
  where: RevenueDriverAssumptionWhereUniqueInput;
};

export type RevenueDriverAssumptionWhereInput = {
  AND?: Maybe<Array<RevenueDriverAssumptionWhereInput>>;
  NOT?: Maybe<Array<RevenueDriverAssumptionWhereInput>>;
  OR?: Maybe<Array<RevenueDriverAssumptionWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  delayRecognition?: Maybe<IntFilter>;
  effectiveEndMonthIndex?: Maybe<IntFilter>;
  effectiveEndYear?: Maybe<IntFilter>;
  effectiveStartMonthIndex?: Maybe<IntFilter>;
  effectiveStartYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  locked?: Maybe<BoolFilter>;
  percentOf?: Maybe<DecimalNullableFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  revenueDriverType?: Maybe<RevenueDriverTypeRelationFilter>;
  revenueDriverTypeId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RevenueDriverAssumptionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  revenueDriverTypeId_effectiveEndYear_propertyId?: Maybe<RevenueDriverAssumptionRevenueDriverTypeIdEffectiveEndYearPropertyIdCompoundUniqueInput>;
};

export type RevenueDriverModel = {
  __typename?: 'RevenueDriverModel';
  isDriven: Scalars['Boolean'];
  sourceRevenueMetrics: Array<SourceRevenueMetricModel>;
};

export type RevenueDriverTypeCreateManyDriverInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  revenueType: RevenueType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RevenueDriverTypeCreateManyDriverInputEnvelope = {
  data: Array<RevenueDriverTypeCreateManyDriverInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RevenueDriverTypeCreateNestedManyWithoutDriverInput = {
  connect?: Maybe<Array<RevenueDriverTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RevenueDriverTypeCreateOrConnectWithoutDriverInput>>;
  create?: Maybe<Array<RevenueDriverTypeCreateWithoutDriverInput>>;
  createMany?: Maybe<RevenueDriverTypeCreateManyDriverInputEnvelope>;
};

export type RevenueDriverTypeCreateNestedOneWithoutRevenueDriverAssumptionsInput = {
  connect?: Maybe<RevenueDriverTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<RevenueDriverTypeCreateOrConnectWithoutRevenueDriverAssumptionsInput>;
  create?: Maybe<RevenueDriverTypeCreateWithoutRevenueDriverAssumptionsInput>;
};

export type RevenueDriverTypeCreateOrConnectWithoutDriverInput = {
  create: RevenueDriverTypeCreateWithoutDriverInput;
  where: RevenueDriverTypeWhereUniqueInput;
};

export type RevenueDriverTypeCreateOrConnectWithoutRevenueDriverAssumptionsInput = {
  create: RevenueDriverTypeCreateWithoutRevenueDriverAssumptionsInput;
  where: RevenueDriverTypeWhereUniqueInput;
};

export type RevenueDriverTypeCreateWithoutDriverInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionCreateNestedManyWithoutRevenueDriverTypeInput>;
  revenueType: RevenueType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RevenueDriverTypeCreateWithoutRevenueDriverAssumptionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  driver: DriverCreateNestedOneWithoutRevenueDriverTypesInput;
  id?: Maybe<Scalars['String']>;
  revenueType: RevenueType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RevenueDriverTypeDriverIdRevenueTypeCompoundUniqueInput = {
  driverId: Scalars['String'];
  revenueType: RevenueType;
};

export type RevenueDriverTypeListRelationFilter = {
  every?: Maybe<RevenueDriverTypeWhereInput>;
  none?: Maybe<RevenueDriverTypeWhereInput>;
  some?: Maybe<RevenueDriverTypeWhereInput>;
};

export type RevenueDriverTypeRelationFilter = {
  is?: Maybe<RevenueDriverTypeWhereInput>;
  isNot?: Maybe<RevenueDriverTypeWhereInput>;
};

export type RevenueDriverTypeScalarWhereInput = {
  AND?: Maybe<Array<RevenueDriverTypeScalarWhereInput>>;
  NOT?: Maybe<Array<RevenueDriverTypeScalarWhereInput>>;
  OR?: Maybe<Array<RevenueDriverTypeScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  driverId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  revenueType?: Maybe<EnumRevenueTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RevenueDriverTypeUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  revenueType?: Maybe<EnumRevenueTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type RevenueDriverTypeUpdateManyWithWhereWithoutDriverInput = {
  data: RevenueDriverTypeUpdateManyMutationInput;
  where: RevenueDriverTypeScalarWhereInput;
};

export type RevenueDriverTypeUpdateManyWithoutDriverInput = {
  connect?: Maybe<Array<RevenueDriverTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RevenueDriverTypeCreateOrConnectWithoutDriverInput>>;
  create?: Maybe<Array<RevenueDriverTypeCreateWithoutDriverInput>>;
  createMany?: Maybe<RevenueDriverTypeCreateManyDriverInputEnvelope>;
  delete?: Maybe<Array<RevenueDriverTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RevenueDriverTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<RevenueDriverTypeWhereUniqueInput>>;
  set?: Maybe<Array<RevenueDriverTypeWhereUniqueInput>>;
  update?: Maybe<Array<RevenueDriverTypeUpdateWithWhereUniqueWithoutDriverInput>>;
  updateMany?: Maybe<Array<RevenueDriverTypeUpdateManyWithWhereWithoutDriverInput>>;
  upsert?: Maybe<Array<RevenueDriverTypeUpsertWithWhereUniqueWithoutDriverInput>>;
};

export type RevenueDriverTypeUpdateOneRequiredWithoutRevenueDriverAssumptionsInput = {
  connect?: Maybe<RevenueDriverTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<RevenueDriverTypeCreateOrConnectWithoutRevenueDriverAssumptionsInput>;
  create?: Maybe<RevenueDriverTypeCreateWithoutRevenueDriverAssumptionsInput>;
  update?: Maybe<RevenueDriverTypeUpdateWithoutRevenueDriverAssumptionsInput>;
  upsert?: Maybe<RevenueDriverTypeUpsertWithoutRevenueDriverAssumptionsInput>;
};

export type RevenueDriverTypeUpdateWithWhereUniqueWithoutDriverInput = {
  data: RevenueDriverTypeUpdateWithoutDriverInput;
  where: RevenueDriverTypeWhereUniqueInput;
};

export type RevenueDriverTypeUpdateWithoutDriverInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionUpdateManyWithoutRevenueDriverTypeInput>;
  revenueType?: Maybe<EnumRevenueTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type RevenueDriverTypeUpdateWithoutRevenueDriverAssumptionsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  driver?: Maybe<DriverUpdateOneRequiredWithoutRevenueDriverTypesInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  revenueType?: Maybe<EnumRevenueTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type RevenueDriverTypeUpsertWithWhereUniqueWithoutDriverInput = {
  create: RevenueDriverTypeCreateWithoutDriverInput;
  update: RevenueDriverTypeUpdateWithoutDriverInput;
  where: RevenueDriverTypeWhereUniqueInput;
};

export type RevenueDriverTypeUpsertWithoutRevenueDriverAssumptionsInput = {
  create: RevenueDriverTypeCreateWithoutRevenueDriverAssumptionsInput;
  update: RevenueDriverTypeUpdateWithoutRevenueDriverAssumptionsInput;
};

export type RevenueDriverTypeWhereInput = {
  AND?: Maybe<Array<RevenueDriverTypeWhereInput>>;
  NOT?: Maybe<Array<RevenueDriverTypeWhereInput>>;
  OR?: Maybe<Array<RevenueDriverTypeWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  driver?: Maybe<DriverRelationFilter>;
  driverId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  revenueDriverAssumptions?: Maybe<RevenueDriverAssumptionListRelationFilter>;
  revenueType?: Maybe<EnumRevenueTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RevenueDriverTypeWhereUniqueInput = {
  driverId_revenueType?: Maybe<RevenueDriverTypeDriverIdRevenueTypeCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type RevenueExpirationCurve = {
  __typename?: 'RevenueExpirationCurve';
  budgetYear: Scalars['Int'];
  id: Scalars['String'];
  month0: Scalars['Decimal'];
  month1: Scalars['Decimal'];
  month2: Scalars['Decimal'];
  month3: Scalars['Decimal'];
  month4: Scalars['Decimal'];
  month5: Scalars['Decimal'];
  month6: Scalars['Decimal'];
  month7: Scalars['Decimal'];
  month8: Scalars['Decimal'];
  month9: Scalars['Decimal'];
  month10: Scalars['Decimal'];
  month11: Scalars['Decimal'];
  propertyId: Scalars['String'];
};

export type RevenueExpirationCurveCreateManyPropertyInput = {
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  month0?: Maybe<Scalars['Decimal']>;
  month1?: Maybe<Scalars['Decimal']>;
  month2?: Maybe<Scalars['Decimal']>;
  month3?: Maybe<Scalars['Decimal']>;
  month4?: Maybe<Scalars['Decimal']>;
  month5?: Maybe<Scalars['Decimal']>;
  month6?: Maybe<Scalars['Decimal']>;
  month7?: Maybe<Scalars['Decimal']>;
  month8?: Maybe<Scalars['Decimal']>;
  month9?: Maybe<Scalars['Decimal']>;
  month10?: Maybe<Scalars['Decimal']>;
  month11?: Maybe<Scalars['Decimal']>;
};

export type RevenueExpirationCurveCreateManyPropertyInputEnvelope = {
  data: Array<RevenueExpirationCurveCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RevenueExpirationCurveCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<RevenueExpirationCurveWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RevenueExpirationCurveCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<RevenueExpirationCurveCreateWithoutPropertyInput>>;
  createMany?: Maybe<RevenueExpirationCurveCreateManyPropertyInputEnvelope>;
};

export type RevenueExpirationCurveCreateOrConnectWithoutPropertyInput = {
  create: RevenueExpirationCurveCreateWithoutPropertyInput;
  where: RevenueExpirationCurveWhereUniqueInput;
};

export type RevenueExpirationCurveCreateWithoutPropertyInput = {
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  month0?: Maybe<Scalars['Decimal']>;
  month1?: Maybe<Scalars['Decimal']>;
  month2?: Maybe<Scalars['Decimal']>;
  month3?: Maybe<Scalars['Decimal']>;
  month4?: Maybe<Scalars['Decimal']>;
  month5?: Maybe<Scalars['Decimal']>;
  month6?: Maybe<Scalars['Decimal']>;
  month7?: Maybe<Scalars['Decimal']>;
  month8?: Maybe<Scalars['Decimal']>;
  month9?: Maybe<Scalars['Decimal']>;
  month10?: Maybe<Scalars['Decimal']>;
  month11?: Maybe<Scalars['Decimal']>;
};

export type RevenueExpirationCurveListRelationFilter = {
  every?: Maybe<RevenueExpirationCurveWhereInput>;
  none?: Maybe<RevenueExpirationCurveWhereInput>;
  some?: Maybe<RevenueExpirationCurveWhereInput>;
};

export type RevenueExpirationCurveOrderByInput = {
  budgetYear?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  month0?: Maybe<SortOrder>;
  month1?: Maybe<SortOrder>;
  month2?: Maybe<SortOrder>;
  month3?: Maybe<SortOrder>;
  month4?: Maybe<SortOrder>;
  month5?: Maybe<SortOrder>;
  month6?: Maybe<SortOrder>;
  month7?: Maybe<SortOrder>;
  month8?: Maybe<SortOrder>;
  month9?: Maybe<SortOrder>;
  month10?: Maybe<SortOrder>;
  month11?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
};

export type RevenueExpirationCurvePropertyIdBudgetYearCompoundUniqueInput = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['String'];
};

export enum RevenueExpirationCurveScalarFieldEnum {
  BudgetYear = 'budgetYear',
  Id = 'id',
  Month0 = 'month0',
  Month1 = 'month1',
  Month2 = 'month2',
  Month3 = 'month3',
  Month4 = 'month4',
  Month5 = 'month5',
  Month6 = 'month6',
  Month7 = 'month7',
  Month8 = 'month8',
  Month9 = 'month9',
  Month10 = 'month10',
  Month11 = 'month11',
  PropertyId = 'propertyId'
}

export type RevenueExpirationCurveScalarWhereInput = {
  AND?: Maybe<Array<RevenueExpirationCurveScalarWhereInput>>;
  NOT?: Maybe<Array<RevenueExpirationCurveScalarWhereInput>>;
  OR?: Maybe<Array<RevenueExpirationCurveScalarWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  month0?: Maybe<DecimalFilter>;
  month1?: Maybe<DecimalFilter>;
  month2?: Maybe<DecimalFilter>;
  month3?: Maybe<DecimalFilter>;
  month4?: Maybe<DecimalFilter>;
  month5?: Maybe<DecimalFilter>;
  month6?: Maybe<DecimalFilter>;
  month7?: Maybe<DecimalFilter>;
  month8?: Maybe<DecimalFilter>;
  month9?: Maybe<DecimalFilter>;
  month10?: Maybe<DecimalFilter>;
  month11?: Maybe<DecimalFilter>;
  propertyId?: Maybe<StringFilter>;
};

export type RevenueExpirationCurveUpdateManyMutationInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  month0?: Maybe<DecimalFieldUpdateOperationsInput>;
  month1?: Maybe<DecimalFieldUpdateOperationsInput>;
  month2?: Maybe<DecimalFieldUpdateOperationsInput>;
  month3?: Maybe<DecimalFieldUpdateOperationsInput>;
  month4?: Maybe<DecimalFieldUpdateOperationsInput>;
  month5?: Maybe<DecimalFieldUpdateOperationsInput>;
  month6?: Maybe<DecimalFieldUpdateOperationsInput>;
  month7?: Maybe<DecimalFieldUpdateOperationsInput>;
  month8?: Maybe<DecimalFieldUpdateOperationsInput>;
  month9?: Maybe<DecimalFieldUpdateOperationsInput>;
  month10?: Maybe<DecimalFieldUpdateOperationsInput>;
  month11?: Maybe<DecimalFieldUpdateOperationsInput>;
};

export type RevenueExpirationCurveUpdateManyWithWhereWithoutPropertyInput = {
  data: RevenueExpirationCurveUpdateManyMutationInput;
  where: RevenueExpirationCurveScalarWhereInput;
};

export type RevenueExpirationCurveUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<RevenueExpirationCurveWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RevenueExpirationCurveCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<RevenueExpirationCurveCreateWithoutPropertyInput>>;
  createMany?: Maybe<RevenueExpirationCurveCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<RevenueExpirationCurveWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RevenueExpirationCurveScalarWhereInput>>;
  disconnect?: Maybe<Array<RevenueExpirationCurveWhereUniqueInput>>;
  set?: Maybe<Array<RevenueExpirationCurveWhereUniqueInput>>;
  update?: Maybe<Array<RevenueExpirationCurveUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<RevenueExpirationCurveUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<RevenueExpirationCurveUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type RevenueExpirationCurveUpdateWithWhereUniqueWithoutPropertyInput = {
  data: RevenueExpirationCurveUpdateWithoutPropertyInput;
  where: RevenueExpirationCurveWhereUniqueInput;
};

export type RevenueExpirationCurveUpdateWithoutPropertyInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  month0?: Maybe<DecimalFieldUpdateOperationsInput>;
  month1?: Maybe<DecimalFieldUpdateOperationsInput>;
  month2?: Maybe<DecimalFieldUpdateOperationsInput>;
  month3?: Maybe<DecimalFieldUpdateOperationsInput>;
  month4?: Maybe<DecimalFieldUpdateOperationsInput>;
  month5?: Maybe<DecimalFieldUpdateOperationsInput>;
  month6?: Maybe<DecimalFieldUpdateOperationsInput>;
  month7?: Maybe<DecimalFieldUpdateOperationsInput>;
  month8?: Maybe<DecimalFieldUpdateOperationsInput>;
  month9?: Maybe<DecimalFieldUpdateOperationsInput>;
  month10?: Maybe<DecimalFieldUpdateOperationsInput>;
  month11?: Maybe<DecimalFieldUpdateOperationsInput>;
};

export type RevenueExpirationCurveUpsertWithWhereUniqueWithoutPropertyInput = {
  create: RevenueExpirationCurveCreateWithoutPropertyInput;
  update: RevenueExpirationCurveUpdateWithoutPropertyInput;
  where: RevenueExpirationCurveWhereUniqueInput;
};

export type RevenueExpirationCurveWhereInput = {
  AND?: Maybe<Array<RevenueExpirationCurveWhereInput>>;
  NOT?: Maybe<Array<RevenueExpirationCurveWhereInput>>;
  OR?: Maybe<Array<RevenueExpirationCurveWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  month0?: Maybe<DecimalFilter>;
  month1?: Maybe<DecimalFilter>;
  month2?: Maybe<DecimalFilter>;
  month3?: Maybe<DecimalFilter>;
  month4?: Maybe<DecimalFilter>;
  month5?: Maybe<DecimalFilter>;
  month6?: Maybe<DecimalFilter>;
  month7?: Maybe<DecimalFilter>;
  month8?: Maybe<DecimalFilter>;
  month9?: Maybe<DecimalFilter>;
  month10?: Maybe<DecimalFilter>;
  month11?: Maybe<DecimalFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
};

export type RevenueExpirationCurveWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  propertyId_budgetYear?: Maybe<RevenueExpirationCurvePropertyIdBudgetYearCompoundUniqueInput>;
};

export type RevenueMetric = {
  __typename?: 'RevenueMetric';
  id: Scalars['String'];
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  subsidyAmount: Scalars['Decimal'];
  subsidyPercentage: Scalars['Decimal'];
  versionId: Scalars['String'];
};

export type RevenueMetricCreateManyPropertyInput = {
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  subsidyAmount?: Maybe<Scalars['Decimal']>;
  subsidyPercentage?: Maybe<Scalars['Decimal']>;
  versionId: Scalars['String'];
};

export type RevenueMetricCreateManyPropertyInputEnvelope = {
  data: Array<RevenueMetricCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RevenueMetricCreateManyVersionInput = {
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  subsidyAmount?: Maybe<Scalars['Decimal']>;
  subsidyPercentage?: Maybe<Scalars['Decimal']>;
};

export type RevenueMetricCreateManyVersionInputEnvelope = {
  data: Array<RevenueMetricCreateManyVersionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RevenueMetricCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<RevenueMetricWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RevenueMetricCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<RevenueMetricCreateWithoutPropertyInput>>;
  createMany?: Maybe<RevenueMetricCreateManyPropertyInputEnvelope>;
};

export type RevenueMetricCreateNestedManyWithoutVersionInput = {
  connect?: Maybe<Array<RevenueMetricWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RevenueMetricCreateOrConnectWithoutVersionInput>>;
  create?: Maybe<Array<RevenueMetricCreateWithoutVersionInput>>;
  createMany?: Maybe<RevenueMetricCreateManyVersionInputEnvelope>;
};

export type RevenueMetricCreateOrConnectWithoutPropertyInput = {
  create: RevenueMetricCreateWithoutPropertyInput;
  where: RevenueMetricWhereUniqueInput;
};

export type RevenueMetricCreateOrConnectWithoutVersionInput = {
  create: RevenueMetricCreateWithoutVersionInput;
  where: RevenueMetricWhereUniqueInput;
};

export type RevenueMetricCreateWithoutPropertyInput = {
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  subsidyAmount?: Maybe<Scalars['Decimal']>;
  subsidyPercentage?: Maybe<Scalars['Decimal']>;
  version: ValueVersionCreateNestedOneWithoutRevenueMetricsInput;
};

export type RevenueMetricCreateWithoutVersionInput = {
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  property: PropertyCreateNestedOneWithoutRevenueMetricsInput;
  subsidyAmount?: Maybe<Scalars['Decimal']>;
  subsidyPercentage?: Maybe<Scalars['Decimal']>;
};

export type RevenueMetricListRelationFilter = {
  every?: Maybe<RevenueMetricWhereInput>;
  none?: Maybe<RevenueMetricWhereInput>;
  some?: Maybe<RevenueMetricWhereInput>;
};

export type RevenueMetricOrderByInput = {
  id?: Maybe<SortOrder>;
  monthIndex?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  subsidyAmount?: Maybe<SortOrder>;
  subsidyPercentage?: Maybe<SortOrder>;
  versionId?: Maybe<SortOrder>;
};

export enum RevenueMetricScalarFieldEnum {
  Id = 'id',
  MonthIndex = 'monthIndex',
  PropertyId = 'propertyId',
  SubsidyAmount = 'subsidyAmount',
  SubsidyPercentage = 'subsidyPercentage',
  VersionId = 'versionId'
}

export type RevenueMetricScalarWhereInput = {
  AND?: Maybe<Array<RevenueMetricScalarWhereInput>>;
  NOT?: Maybe<Array<RevenueMetricScalarWhereInput>>;
  OR?: Maybe<Array<RevenueMetricScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  monthIndex?: Maybe<IntFilter>;
  propertyId?: Maybe<StringFilter>;
  subsidyAmount?: Maybe<DecimalFilter>;
  subsidyPercentage?: Maybe<DecimalFilter>;
  versionId?: Maybe<StringFilter>;
};

export type RevenueMetricUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  subsidyAmount?: Maybe<DecimalFieldUpdateOperationsInput>;
  subsidyPercentage?: Maybe<DecimalFieldUpdateOperationsInput>;
};

export type RevenueMetricUpdateManyWithWhereWithoutPropertyInput = {
  data: RevenueMetricUpdateManyMutationInput;
  where: RevenueMetricScalarWhereInput;
};

export type RevenueMetricUpdateManyWithWhereWithoutVersionInput = {
  data: RevenueMetricUpdateManyMutationInput;
  where: RevenueMetricScalarWhereInput;
};

export type RevenueMetricUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<RevenueMetricWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RevenueMetricCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<RevenueMetricCreateWithoutPropertyInput>>;
  createMany?: Maybe<RevenueMetricCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<RevenueMetricWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RevenueMetricScalarWhereInput>>;
  disconnect?: Maybe<Array<RevenueMetricWhereUniqueInput>>;
  set?: Maybe<Array<RevenueMetricWhereUniqueInput>>;
  update?: Maybe<Array<RevenueMetricUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<RevenueMetricUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<RevenueMetricUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type RevenueMetricUpdateManyWithoutVersionInput = {
  connect?: Maybe<Array<RevenueMetricWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RevenueMetricCreateOrConnectWithoutVersionInput>>;
  create?: Maybe<Array<RevenueMetricCreateWithoutVersionInput>>;
  createMany?: Maybe<RevenueMetricCreateManyVersionInputEnvelope>;
  delete?: Maybe<Array<RevenueMetricWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RevenueMetricScalarWhereInput>>;
  disconnect?: Maybe<Array<RevenueMetricWhereUniqueInput>>;
  set?: Maybe<Array<RevenueMetricWhereUniqueInput>>;
  update?: Maybe<Array<RevenueMetricUpdateWithWhereUniqueWithoutVersionInput>>;
  updateMany?: Maybe<Array<RevenueMetricUpdateManyWithWhereWithoutVersionInput>>;
  upsert?: Maybe<Array<RevenueMetricUpsertWithWhereUniqueWithoutVersionInput>>;
};

export type RevenueMetricUpdateWithWhereUniqueWithoutPropertyInput = {
  data: RevenueMetricUpdateWithoutPropertyInput;
  where: RevenueMetricWhereUniqueInput;
};

export type RevenueMetricUpdateWithWhereUniqueWithoutVersionInput = {
  data: RevenueMetricUpdateWithoutVersionInput;
  where: RevenueMetricWhereUniqueInput;
};

export type RevenueMetricUpdateWithoutPropertyInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  subsidyAmount?: Maybe<DecimalFieldUpdateOperationsInput>;
  subsidyPercentage?: Maybe<DecimalFieldUpdateOperationsInput>;
  version?: Maybe<ValueVersionUpdateOneRequiredWithoutRevenueMetricsInput>;
};

export type RevenueMetricUpdateWithoutVersionInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutRevenueMetricsInput>;
  subsidyAmount?: Maybe<DecimalFieldUpdateOperationsInput>;
  subsidyPercentage?: Maybe<DecimalFieldUpdateOperationsInput>;
};

export type RevenueMetricUpsertWithWhereUniqueWithoutPropertyInput = {
  create: RevenueMetricCreateWithoutPropertyInput;
  update: RevenueMetricUpdateWithoutPropertyInput;
  where: RevenueMetricWhereUniqueInput;
};

export type RevenueMetricUpsertWithWhereUniqueWithoutVersionInput = {
  create: RevenueMetricCreateWithoutVersionInput;
  update: RevenueMetricUpdateWithoutVersionInput;
  where: RevenueMetricWhereUniqueInput;
};

export type RevenueMetricWhereInput = {
  AND?: Maybe<Array<RevenueMetricWhereInput>>;
  NOT?: Maybe<Array<RevenueMetricWhereInput>>;
  OR?: Maybe<Array<RevenueMetricWhereInput>>;
  id?: Maybe<StringFilter>;
  monthIndex?: Maybe<IntFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  subsidyAmount?: Maybe<DecimalFilter>;
  subsidyPercentage?: Maybe<DecimalFilter>;
  version?: Maybe<ValueVersionRelationFilter>;
  versionId?: Maybe<StringFilter>;
};

export type RevenueMetricWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum RevenueModelType {
  Original = 'ORIGINAL',
  Simplified = 'SIMPLIFIED',
  UnitLevel = 'UNIT_LEVEL'
}

export enum RevenueType {
  Gpr = 'GPR',
  GprAndVacancy = 'GPR_AND_VACANCY',
  LossToLease = 'LOSS_TO_LEASE',
  Rent = 'RENT',
  RentSubsidy = 'RENT_SUBSIDY',
  Vacancy = 'VACANCY'
}

export type SetPropertyDriversArgsItem = {
  accItems: Array<AccPercentageDriverAssumptionsUpdateInput>;
  accountId: Scalars['String'];
  customDriverItems: Array<CustomDriverItemForPropertyDriversUpdateInput>;
  isUserRequestingWorksheetDeletion: Scalars['Boolean'];
  opItems: Array<OperationalDriverAssumptionsUpdateInput>;
  pendingUpdates?: Maybe<PendingUpdates>;
  wsItems: Array<WorksheetDriverLineUpdateByDescriptionInput>;
};

export type SharedLinkViewModel = {
  __typename?: 'SharedLinkViewModel';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  url: Scalars['String'];
};

export type SharedReportLink = {
  __typename?: 'SharedReportLink';
  budgetYear: Scalars['Int'];
  ccEmails: Scalars['String'];
  clientId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  propertyId: Scalars['String'];
  sharedWithEmail: Scalars['String'];
};

export type SharedReportLinkCreateManyClientInput = {
  budgetYear: Scalars['Int'];
  ccEmails: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  propertyId: Scalars['String'];
  sharedWithEmail: Scalars['String'];
};

export type SharedReportLinkCreateManyClientInputEnvelope = {
  data: Array<SharedReportLinkCreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SharedReportLinkCreateManyPropertyInput = {
  budgetYear: Scalars['Int'];
  ccEmails: Scalars['String'];
  clientId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  sharedWithEmail: Scalars['String'];
};

export type SharedReportLinkCreateManyPropertyInputEnvelope = {
  data: Array<SharedReportLinkCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SharedReportLinkCreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<SharedReportLinkWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SharedReportLinkCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<SharedReportLinkCreateWithoutClientInput>>;
  createMany?: Maybe<SharedReportLinkCreateManyClientInputEnvelope>;
};

export type SharedReportLinkCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<SharedReportLinkWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SharedReportLinkCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<SharedReportLinkCreateWithoutPropertyInput>>;
  createMany?: Maybe<SharedReportLinkCreateManyPropertyInputEnvelope>;
};

export type SharedReportLinkCreateOrConnectWithoutClientInput = {
  create: SharedReportLinkCreateWithoutClientInput;
  where: SharedReportLinkWhereUniqueInput;
};

export type SharedReportLinkCreateOrConnectWithoutPropertyInput = {
  create: SharedReportLinkCreateWithoutPropertyInput;
  where: SharedReportLinkWhereUniqueInput;
};

export type SharedReportLinkCreateWithoutClientInput = {
  budgetYear: Scalars['Int'];
  ccEmails: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  property: PropertyCreateNestedOneWithoutSharedReportLinksInput;
  sharedWithEmail: Scalars['String'];
};

export type SharedReportLinkCreateWithoutPropertyInput = {
  budgetYear: Scalars['Int'];
  ccEmails: Scalars['String'];
  client: ClientCreateNestedOneWithoutSharedReportLinksInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  sharedWithEmail: Scalars['String'];
};

export type SharedReportLinkListRelationFilter = {
  every?: Maybe<SharedReportLinkWhereInput>;
  none?: Maybe<SharedReportLinkWhereInput>;
  some?: Maybe<SharedReportLinkWhereInput>;
};

export type SharedReportLinkOrderByInput = {
  budgetYear?: Maybe<SortOrder>;
  ccEmails?: Maybe<SortOrder>;
  clientId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  sharedWithEmail?: Maybe<SortOrder>;
};

export enum SharedReportLinkScalarFieldEnum {
  BudgetYear = 'budgetYear',
  CcEmails = 'ccEmails',
  ClientId = 'clientId',
  CreatedAt = 'createdAt',
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName',
  PropertyId = 'propertyId',
  SharedWithEmail = 'sharedWithEmail'
}

export type SharedReportLinkScalarWhereInput = {
  AND?: Maybe<Array<SharedReportLinkScalarWhereInput>>;
  NOT?: Maybe<Array<SharedReportLinkScalarWhereInput>>;
  OR?: Maybe<Array<SharedReportLinkScalarWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  ccEmails?: Maybe<StringFilter>;
  clientId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  firstName?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  lastName?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  sharedWithEmail?: Maybe<StringFilter>;
};

export type SharedReportLinkUpdateManyMutationInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  ccEmails?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  firstName?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastName?: Maybe<StringFieldUpdateOperationsInput>;
  sharedWithEmail?: Maybe<StringFieldUpdateOperationsInput>;
};

export type SharedReportLinkUpdateManyWithWhereWithoutClientInput = {
  data: SharedReportLinkUpdateManyMutationInput;
  where: SharedReportLinkScalarWhereInput;
};

export type SharedReportLinkUpdateManyWithWhereWithoutPropertyInput = {
  data: SharedReportLinkUpdateManyMutationInput;
  where: SharedReportLinkScalarWhereInput;
};

export type SharedReportLinkUpdateManyWithoutClientInput = {
  connect?: Maybe<Array<SharedReportLinkWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SharedReportLinkCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<SharedReportLinkCreateWithoutClientInput>>;
  createMany?: Maybe<SharedReportLinkCreateManyClientInputEnvelope>;
  delete?: Maybe<Array<SharedReportLinkWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SharedReportLinkScalarWhereInput>>;
  disconnect?: Maybe<Array<SharedReportLinkWhereUniqueInput>>;
  set?: Maybe<Array<SharedReportLinkWhereUniqueInput>>;
  update?: Maybe<Array<SharedReportLinkUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<SharedReportLinkUpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<SharedReportLinkUpsertWithWhereUniqueWithoutClientInput>>;
};

export type SharedReportLinkUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<SharedReportLinkWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SharedReportLinkCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<SharedReportLinkCreateWithoutPropertyInput>>;
  createMany?: Maybe<SharedReportLinkCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<SharedReportLinkWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SharedReportLinkScalarWhereInput>>;
  disconnect?: Maybe<Array<SharedReportLinkWhereUniqueInput>>;
  set?: Maybe<Array<SharedReportLinkWhereUniqueInput>>;
  update?: Maybe<Array<SharedReportLinkUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<SharedReportLinkUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<SharedReportLinkUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type SharedReportLinkUpdateWithWhereUniqueWithoutClientInput = {
  data: SharedReportLinkUpdateWithoutClientInput;
  where: SharedReportLinkWhereUniqueInput;
};

export type SharedReportLinkUpdateWithWhereUniqueWithoutPropertyInput = {
  data: SharedReportLinkUpdateWithoutPropertyInput;
  where: SharedReportLinkWhereUniqueInput;
};

export type SharedReportLinkUpdateWithoutClientInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  ccEmails?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  firstName?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastName?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutSharedReportLinksInput>;
  sharedWithEmail?: Maybe<StringFieldUpdateOperationsInput>;
};

export type SharedReportLinkUpdateWithoutPropertyInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  ccEmails?: Maybe<StringFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutSharedReportLinksInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  firstName?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastName?: Maybe<StringFieldUpdateOperationsInput>;
  sharedWithEmail?: Maybe<StringFieldUpdateOperationsInput>;
};

export type SharedReportLinkUpsertWithWhereUniqueWithoutClientInput = {
  create: SharedReportLinkCreateWithoutClientInput;
  update: SharedReportLinkUpdateWithoutClientInput;
  where: SharedReportLinkWhereUniqueInput;
};

export type SharedReportLinkUpsertWithWhereUniqueWithoutPropertyInput = {
  create: SharedReportLinkCreateWithoutPropertyInput;
  update: SharedReportLinkUpdateWithoutPropertyInput;
  where: SharedReportLinkWhereUniqueInput;
};

export type SharedReportLinkWhereInput = {
  AND?: Maybe<Array<SharedReportLinkWhereInput>>;
  NOT?: Maybe<Array<SharedReportLinkWhereInput>>;
  OR?: Maybe<Array<SharedReportLinkWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  ccEmails?: Maybe<StringFilter>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  firstName?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  lastName?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  sharedWithEmail?: Maybe<StringFilter>;
};

export type SharedReportLinkWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SimplifiedRevenueFakeUnitTypeMarketRentInput = {
  name: Scalars['String'];
  startingMarketRent: Scalars['Decimal'];
};

export type SimplifiedRevenueFakeUnitTypeStartingMarketRentModel = {
  __typename?: 'SimplifiedRevenueFakeUnitTypeStartingMarketRentModel';
  name: Scalars['String'];
  startingMarketRent: Scalars['Decimal'];
};

export type SimplifiedRevenueMarketRentIncreaseInput = {
  budgetMonthlyValues: Array<SimplifiedRevenueMonthlyNumberInput>;
  budgetYearlyIncrease?: Maybe<Scalars['Float']>;
  reforecastMonthlyValues: Array<SimplifiedRevenueMonthlyNumberInput>;
  reforecastYearlyIncrease?: Maybe<Scalars['Float']>;
};

export type SimplifiedRevenueMarketRentItemModel = {
  __typename?: 'SimplifiedRevenueMarketRentItemModel';
  /** The average market rent after the growth percentage is applied. Will be null if the growth percentage is null. */
  averageMarketRent?: Maybe<Scalars['Float']>;
  /** Pre-computed month-over-month growth as a dollar amount. Will be null if growthPercentage is null. If null, treat as 0 in calculations. */
  growthAmount?: Maybe<Scalars['Float']>;
  /** The month-over-month growth percentage. Will be null if not available. If null, treat as 0 in calculations. */
  growthPercentage?: Maybe<Scalars['Float']>;
  /** The average market rent for a month. */
  startingAverageMarketRent: Scalars['Float'];
};

export type SimplifiedRevenueMarketRentModel = {
  __typename?: 'SimplifiedRevenueMarketRentModel';
  /** The market rent information for the budget year. The array is always length 12. */
  budget: Array<SimplifiedRevenueMarketRentItemModel>;
  /** An optional annual increase percentage. When set the monthly increases the for budget items are calculated and output in an annualized fashion. The budget monthly values are only editable when this value is null. */
  budgetAnnualIncreasePercentage?: Maybe<Scalars['Float']>;
  /** The market rent information that occurs before the budget year. This may contain null values when no information exists for a month. The array is always length 12. */
  reforecast: Array<Maybe<SimplifiedRevenueMarketRentItemModel>>;
  /** An optional annual increase percentage. When set the monthly increases the for reforecast items are calculated and output in an annualized fashion. The reforecast monthly values are only editable when this value is null. */
  reforecastAnnualIncreasePercentage?: Maybe<Scalars['Float']>;
};

export type SimplifiedRevenueModel = {
  __typename?: 'SimplifiedRevenueModel';
  availableRentRolls: Array<RentRollMetaDataModel>;
  budgetYear: Scalars['Float'];
  /** Returns the default property level market rent information. */
  defaultMarketRents: SimplifiedRevenueMarketRentModel;
  defaultRenewalRatios: SimplifiedRevenueRenewalRatio;
  defaultRenovatedRenewalRatios: SimplifiedRevenueRenewalRatio;
  defaultRenovationRatio: SimplifiedRevenueRenovationRatio;
  defaultVacancyPercentage: SimplifiedRevenueVacancyPercentage;
  fakeUnitTypeMarketRents: Array<SimplifiedRevenueFakeUnitTypeStartingMarketRentModel>;
  propertyId: Scalars['ID'];
  renewalConfiguration?: Maybe<SimplifiedRevenueRenewalConfiguration>;
  rentRoll?: Maybe<RentRollModel>;
  unitTypeAvgMarketRents: Array<SimplifiedRevenueUnitTypeSummaryModel>;
  unitTypeBlendedInPlaceRent: Array<SimplifiedRevenueUnitTypeSummaryModel>;
  unitTypeMarketRents: Array<SimplifiedRevenueUnitTypeStartingMarketRentModel>;
  unitTypeNewLeaseInPlaceRent: Array<SimplifiedRevenueUnitTypeSummaryModel>;
  unitTypeOccupancy: SimplifiedRevenueUnitTypeOccupancySummaryModel;
  unitTypeRenewalInPlaceRent: Array<SimplifiedRevenueUnitTypeSummaryModel>;
  unitTypeRenewalTradeOutAmount: Array<SimplifiedRevenueUnitTypeSummaryModel>;
  unitTypeRenewals: SimplifiedRevenueUnitTypeRenewalsSummaryModel;
  unitTypeRenovations: SimplifiedRevenueUnitTypeRenovationsSummaryModel;
  unitTypeUnitCounts: Array<SimplifiedRevenueUnitTypeSummaryModel>;
};


export type SimplifiedRevenueModelRentRollArgs = {
  rentRollDate?: Maybe<Scalars['DateNoTime']>;
};

export type SimplifiedRevenueMonthlyNumberInput = {
  /** A month number from 0 to 11, where 0 is January and 11 is December. */
  month: Scalars['Int'];
  /** A floating point decimal representing the value for the month, or if null, indicates that value for the given month should be deleted. */
  value?: Maybe<Scalars['Float']>;
};

export type SimplifiedRevenuePropertyMarketRentGrowthPercent = {
  __typename?: 'SimplifiedRevenuePropertyMarketRentGrowthPercent';
  annualized?: Maybe<Scalars['Decimal']>;
  budgetYear: Scalars['Int'];
  month0: Scalars['Decimal'];
  month1: Scalars['Decimal'];
  month2: Scalars['Decimal'];
  month3: Scalars['Decimal'];
  month4: Scalars['Decimal'];
  month5: Scalars['Decimal'];
  month6: Scalars['Decimal'];
  month7: Scalars['Decimal'];
  month8: Scalars['Decimal'];
  month9: Scalars['Decimal'];
  month10: Scalars['Decimal'];
  month11: Scalars['Decimal'];
  propertyId: Scalars['String'];
  versionType: VersionType;
};

export type SimplifiedRevenuePropertyMarketRentGrowthPercentCreateManyPropertyInput = {
  annualized?: Maybe<Scalars['Decimal']>;
  budgetYear: Scalars['Int'];
  month0?: Maybe<Scalars['Decimal']>;
  month1?: Maybe<Scalars['Decimal']>;
  month2?: Maybe<Scalars['Decimal']>;
  month3?: Maybe<Scalars['Decimal']>;
  month4?: Maybe<Scalars['Decimal']>;
  month5?: Maybe<Scalars['Decimal']>;
  month6?: Maybe<Scalars['Decimal']>;
  month7?: Maybe<Scalars['Decimal']>;
  month8?: Maybe<Scalars['Decimal']>;
  month9?: Maybe<Scalars['Decimal']>;
  month10?: Maybe<Scalars['Decimal']>;
  month11?: Maybe<Scalars['Decimal']>;
  versionType: VersionType;
};

export type SimplifiedRevenuePropertyMarketRentGrowthPercentCreateManyPropertyInputEnvelope = {
  data: Array<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SimplifiedRevenuePropertyMarketRentGrowthPercentCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<SimplifiedRevenuePropertyMarketRentGrowthPercentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateWithoutPropertyInput>>;
  createMany?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateManyPropertyInputEnvelope>;
};

export type SimplifiedRevenuePropertyMarketRentGrowthPercentCreateOrConnectWithoutPropertyInput = {
  create: SimplifiedRevenuePropertyMarketRentGrowthPercentCreateWithoutPropertyInput;
  where: SimplifiedRevenuePropertyMarketRentGrowthPercentWhereUniqueInput;
};

export type SimplifiedRevenuePropertyMarketRentGrowthPercentCreateWithoutPropertyInput = {
  annualized?: Maybe<Scalars['Decimal']>;
  budgetYear: Scalars['Int'];
  month0?: Maybe<Scalars['Decimal']>;
  month1?: Maybe<Scalars['Decimal']>;
  month2?: Maybe<Scalars['Decimal']>;
  month3?: Maybe<Scalars['Decimal']>;
  month4?: Maybe<Scalars['Decimal']>;
  month5?: Maybe<Scalars['Decimal']>;
  month6?: Maybe<Scalars['Decimal']>;
  month7?: Maybe<Scalars['Decimal']>;
  month8?: Maybe<Scalars['Decimal']>;
  month9?: Maybe<Scalars['Decimal']>;
  month10?: Maybe<Scalars['Decimal']>;
  month11?: Maybe<Scalars['Decimal']>;
  versionType: VersionType;
};

export type SimplifiedRevenuePropertyMarketRentGrowthPercentListRelationFilter = {
  every?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentWhereInput>;
  none?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentWhereInput>;
  some?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentWhereInput>;
};

export type SimplifiedRevenuePropertyMarketRentGrowthPercentOrderByInput = {
  annualized?: Maybe<SortOrder>;
  budgetYear?: Maybe<SortOrder>;
  month0?: Maybe<SortOrder>;
  month1?: Maybe<SortOrder>;
  month2?: Maybe<SortOrder>;
  month3?: Maybe<SortOrder>;
  month4?: Maybe<SortOrder>;
  month5?: Maybe<SortOrder>;
  month6?: Maybe<SortOrder>;
  month7?: Maybe<SortOrder>;
  month8?: Maybe<SortOrder>;
  month9?: Maybe<SortOrder>;
  month10?: Maybe<SortOrder>;
  month11?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  versionType?: Maybe<SortOrder>;
};

export type SimplifiedRevenuePropertyMarketRentGrowthPercentPropertyIdVersionTypeBudgetYearCompoundUniqueInput = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['String'];
  versionType: VersionType;
};

export enum SimplifiedRevenuePropertyMarketRentGrowthPercentScalarFieldEnum {
  Annualized = 'annualized',
  BudgetYear = 'budgetYear',
  Month0 = 'month0',
  Month1 = 'month1',
  Month2 = 'month2',
  Month3 = 'month3',
  Month4 = 'month4',
  Month5 = 'month5',
  Month6 = 'month6',
  Month7 = 'month7',
  Month8 = 'month8',
  Month9 = 'month9',
  Month10 = 'month10',
  Month11 = 'month11',
  PropertyId = 'propertyId',
  VersionType = 'versionType'
}

export type SimplifiedRevenuePropertyMarketRentGrowthPercentScalarWhereInput = {
  AND?: Maybe<Array<SimplifiedRevenuePropertyMarketRentGrowthPercentScalarWhereInput>>;
  NOT?: Maybe<Array<SimplifiedRevenuePropertyMarketRentGrowthPercentScalarWhereInput>>;
  OR?: Maybe<Array<SimplifiedRevenuePropertyMarketRentGrowthPercentScalarWhereInput>>;
  annualized?: Maybe<DecimalNullableFilter>;
  budgetYear?: Maybe<IntFilter>;
  month0?: Maybe<DecimalFilter>;
  month1?: Maybe<DecimalFilter>;
  month2?: Maybe<DecimalFilter>;
  month3?: Maybe<DecimalFilter>;
  month4?: Maybe<DecimalFilter>;
  month5?: Maybe<DecimalFilter>;
  month6?: Maybe<DecimalFilter>;
  month7?: Maybe<DecimalFilter>;
  month8?: Maybe<DecimalFilter>;
  month9?: Maybe<DecimalFilter>;
  month10?: Maybe<DecimalFilter>;
  month11?: Maybe<DecimalFilter>;
  propertyId?: Maybe<StringFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
};

export type SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyMutationInput = {
  annualized?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  month0?: Maybe<DecimalFieldUpdateOperationsInput>;
  month1?: Maybe<DecimalFieldUpdateOperationsInput>;
  month2?: Maybe<DecimalFieldUpdateOperationsInput>;
  month3?: Maybe<DecimalFieldUpdateOperationsInput>;
  month4?: Maybe<DecimalFieldUpdateOperationsInput>;
  month5?: Maybe<DecimalFieldUpdateOperationsInput>;
  month6?: Maybe<DecimalFieldUpdateOperationsInput>;
  month7?: Maybe<DecimalFieldUpdateOperationsInput>;
  month8?: Maybe<DecimalFieldUpdateOperationsInput>;
  month9?: Maybe<DecimalFieldUpdateOperationsInput>;
  month10?: Maybe<DecimalFieldUpdateOperationsInput>;
  month11?: Maybe<DecimalFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithWhereWithoutPropertyInput = {
  data: SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyMutationInput;
  where: SimplifiedRevenuePropertyMarketRentGrowthPercentScalarWhereInput;
};

export type SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<SimplifiedRevenuePropertyMarketRentGrowthPercentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateWithoutPropertyInput>>;
  createMany?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<SimplifiedRevenuePropertyMarketRentGrowthPercentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SimplifiedRevenuePropertyMarketRentGrowthPercentScalarWhereInput>>;
  disconnect?: Maybe<Array<SimplifiedRevenuePropertyMarketRentGrowthPercentWhereUniqueInput>>;
  set?: Maybe<Array<SimplifiedRevenuePropertyMarketRentGrowthPercentWhereUniqueInput>>;
  update?: Maybe<Array<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<SimplifiedRevenuePropertyMarketRentGrowthPercentUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateWithWhereUniqueWithoutPropertyInput = {
  data: SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateWithoutPropertyInput;
  where: SimplifiedRevenuePropertyMarketRentGrowthPercentWhereUniqueInput;
};

export type SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateWithoutPropertyInput = {
  annualized?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  month0?: Maybe<DecimalFieldUpdateOperationsInput>;
  month1?: Maybe<DecimalFieldUpdateOperationsInput>;
  month2?: Maybe<DecimalFieldUpdateOperationsInput>;
  month3?: Maybe<DecimalFieldUpdateOperationsInput>;
  month4?: Maybe<DecimalFieldUpdateOperationsInput>;
  month5?: Maybe<DecimalFieldUpdateOperationsInput>;
  month6?: Maybe<DecimalFieldUpdateOperationsInput>;
  month7?: Maybe<DecimalFieldUpdateOperationsInput>;
  month8?: Maybe<DecimalFieldUpdateOperationsInput>;
  month9?: Maybe<DecimalFieldUpdateOperationsInput>;
  month10?: Maybe<DecimalFieldUpdateOperationsInput>;
  month11?: Maybe<DecimalFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type SimplifiedRevenuePropertyMarketRentGrowthPercentUpsertWithWhereUniqueWithoutPropertyInput = {
  create: SimplifiedRevenuePropertyMarketRentGrowthPercentCreateWithoutPropertyInput;
  update: SimplifiedRevenuePropertyMarketRentGrowthPercentUpdateWithoutPropertyInput;
  where: SimplifiedRevenuePropertyMarketRentGrowthPercentWhereUniqueInput;
};

export type SimplifiedRevenuePropertyMarketRentGrowthPercentWhereInput = {
  AND?: Maybe<Array<SimplifiedRevenuePropertyMarketRentGrowthPercentWhereInput>>;
  NOT?: Maybe<Array<SimplifiedRevenuePropertyMarketRentGrowthPercentWhereInput>>;
  OR?: Maybe<Array<SimplifiedRevenuePropertyMarketRentGrowthPercentWhereInput>>;
  annualized?: Maybe<DecimalNullableFilter>;
  budgetYear?: Maybe<IntFilter>;
  month0?: Maybe<DecimalFilter>;
  month1?: Maybe<DecimalFilter>;
  month2?: Maybe<DecimalFilter>;
  month3?: Maybe<DecimalFilter>;
  month4?: Maybe<DecimalFilter>;
  month5?: Maybe<DecimalFilter>;
  month6?: Maybe<DecimalFilter>;
  month7?: Maybe<DecimalFilter>;
  month8?: Maybe<DecimalFilter>;
  month9?: Maybe<DecimalFilter>;
  month10?: Maybe<DecimalFilter>;
  month11?: Maybe<DecimalFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
};

export type SimplifiedRevenuePropertyMarketRentGrowthPercentWhereUniqueInput = {
  propertyId_versionType_budgetYear?: Maybe<SimplifiedRevenuePropertyMarketRentGrowthPercentPropertyIdVersionTypeBudgetYearCompoundUniqueInput>;
};

export type SimplifiedRevenuePropertyRenewalPercent = {
  __typename?: 'SimplifiedRevenuePropertyRenewalPercent';
  budgetYear: Scalars['Int'];
  propertyId: Scalars['String'];
  renovatedMonth0: Scalars['Decimal'];
  renovatedMonth1: Scalars['Decimal'];
  renovatedMonth2: Scalars['Decimal'];
  renovatedMonth3: Scalars['Decimal'];
  renovatedMonth4: Scalars['Decimal'];
  renovatedMonth5: Scalars['Decimal'];
  renovatedMonth6: Scalars['Decimal'];
  renovatedMonth7: Scalars['Decimal'];
  renovatedMonth8: Scalars['Decimal'];
  renovatedMonth9: Scalars['Decimal'];
  renovatedMonth10: Scalars['Decimal'];
  renovatedMonth11: Scalars['Decimal'];
  unrenovatedMonth0: Scalars['Decimal'];
  unrenovatedMonth1: Scalars['Decimal'];
  unrenovatedMonth2: Scalars['Decimal'];
  unrenovatedMonth3: Scalars['Decimal'];
  unrenovatedMonth4: Scalars['Decimal'];
  unrenovatedMonth5: Scalars['Decimal'];
  unrenovatedMonth6: Scalars['Decimal'];
  unrenovatedMonth7: Scalars['Decimal'];
  unrenovatedMonth8: Scalars['Decimal'];
  unrenovatedMonth9: Scalars['Decimal'];
  unrenovatedMonth10: Scalars['Decimal'];
  unrenovatedMonth11: Scalars['Decimal'];
  versionType: VersionType;
};

export type SimplifiedRevenuePropertyRenewalPercentCreateManyPropertyInput = {
  budgetYear: Scalars['Int'];
  renovatedMonth0?: Maybe<Scalars['Decimal']>;
  renovatedMonth1?: Maybe<Scalars['Decimal']>;
  renovatedMonth2?: Maybe<Scalars['Decimal']>;
  renovatedMonth3?: Maybe<Scalars['Decimal']>;
  renovatedMonth4?: Maybe<Scalars['Decimal']>;
  renovatedMonth5?: Maybe<Scalars['Decimal']>;
  renovatedMonth6?: Maybe<Scalars['Decimal']>;
  renovatedMonth7?: Maybe<Scalars['Decimal']>;
  renovatedMonth8?: Maybe<Scalars['Decimal']>;
  renovatedMonth9?: Maybe<Scalars['Decimal']>;
  renovatedMonth10?: Maybe<Scalars['Decimal']>;
  renovatedMonth11?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth0?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth1?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth2?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth3?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth4?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth5?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth6?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth7?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth8?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth9?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth10?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth11?: Maybe<Scalars['Decimal']>;
  versionType: VersionType;
};

export type SimplifiedRevenuePropertyRenewalPercentCreateManyPropertyInputEnvelope = {
  data: Array<SimplifiedRevenuePropertyRenewalPercentCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SimplifiedRevenuePropertyRenewalPercentCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<SimplifiedRevenuePropertyRenewalPercentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SimplifiedRevenuePropertyRenewalPercentCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<SimplifiedRevenuePropertyRenewalPercentCreateWithoutPropertyInput>>;
  createMany?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateManyPropertyInputEnvelope>;
};

export type SimplifiedRevenuePropertyRenewalPercentCreateOrConnectWithoutPropertyInput = {
  create: SimplifiedRevenuePropertyRenewalPercentCreateWithoutPropertyInput;
  where: SimplifiedRevenuePropertyRenewalPercentWhereUniqueInput;
};

export type SimplifiedRevenuePropertyRenewalPercentCreateWithoutPropertyInput = {
  budgetYear: Scalars['Int'];
  renovatedMonth0?: Maybe<Scalars['Decimal']>;
  renovatedMonth1?: Maybe<Scalars['Decimal']>;
  renovatedMonth2?: Maybe<Scalars['Decimal']>;
  renovatedMonth3?: Maybe<Scalars['Decimal']>;
  renovatedMonth4?: Maybe<Scalars['Decimal']>;
  renovatedMonth5?: Maybe<Scalars['Decimal']>;
  renovatedMonth6?: Maybe<Scalars['Decimal']>;
  renovatedMonth7?: Maybe<Scalars['Decimal']>;
  renovatedMonth8?: Maybe<Scalars['Decimal']>;
  renovatedMonth9?: Maybe<Scalars['Decimal']>;
  renovatedMonth10?: Maybe<Scalars['Decimal']>;
  renovatedMonth11?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth0?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth1?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth2?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth3?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth4?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth5?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth6?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth7?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth8?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth9?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth10?: Maybe<Scalars['Decimal']>;
  unrenovatedMonth11?: Maybe<Scalars['Decimal']>;
  versionType: VersionType;
};

export type SimplifiedRevenuePropertyRenewalPercentListRelationFilter = {
  every?: Maybe<SimplifiedRevenuePropertyRenewalPercentWhereInput>;
  none?: Maybe<SimplifiedRevenuePropertyRenewalPercentWhereInput>;
  some?: Maybe<SimplifiedRevenuePropertyRenewalPercentWhereInput>;
};

export type SimplifiedRevenuePropertyRenewalPercentOrderByInput = {
  budgetYear?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  renovatedMonth0?: Maybe<SortOrder>;
  renovatedMonth1?: Maybe<SortOrder>;
  renovatedMonth2?: Maybe<SortOrder>;
  renovatedMonth3?: Maybe<SortOrder>;
  renovatedMonth4?: Maybe<SortOrder>;
  renovatedMonth5?: Maybe<SortOrder>;
  renovatedMonth6?: Maybe<SortOrder>;
  renovatedMonth7?: Maybe<SortOrder>;
  renovatedMonth8?: Maybe<SortOrder>;
  renovatedMonth9?: Maybe<SortOrder>;
  renovatedMonth10?: Maybe<SortOrder>;
  renovatedMonth11?: Maybe<SortOrder>;
  unrenovatedMonth0?: Maybe<SortOrder>;
  unrenovatedMonth1?: Maybe<SortOrder>;
  unrenovatedMonth2?: Maybe<SortOrder>;
  unrenovatedMonth3?: Maybe<SortOrder>;
  unrenovatedMonth4?: Maybe<SortOrder>;
  unrenovatedMonth5?: Maybe<SortOrder>;
  unrenovatedMonth6?: Maybe<SortOrder>;
  unrenovatedMonth7?: Maybe<SortOrder>;
  unrenovatedMonth8?: Maybe<SortOrder>;
  unrenovatedMonth9?: Maybe<SortOrder>;
  unrenovatedMonth10?: Maybe<SortOrder>;
  unrenovatedMonth11?: Maybe<SortOrder>;
  versionType?: Maybe<SortOrder>;
};

export type SimplifiedRevenuePropertyRenewalPercentPropertyIdVersionTypeBudgetYearCompoundUniqueInput = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['String'];
  versionType: VersionType;
};

export enum SimplifiedRevenuePropertyRenewalPercentScalarFieldEnum {
  BudgetYear = 'budgetYear',
  PropertyId = 'propertyId',
  RenovatedMonth0 = 'renovatedMonth0',
  RenovatedMonth1 = 'renovatedMonth1',
  RenovatedMonth2 = 'renovatedMonth2',
  RenovatedMonth3 = 'renovatedMonth3',
  RenovatedMonth4 = 'renovatedMonth4',
  RenovatedMonth5 = 'renovatedMonth5',
  RenovatedMonth6 = 'renovatedMonth6',
  RenovatedMonth7 = 'renovatedMonth7',
  RenovatedMonth8 = 'renovatedMonth8',
  RenovatedMonth9 = 'renovatedMonth9',
  RenovatedMonth10 = 'renovatedMonth10',
  RenovatedMonth11 = 'renovatedMonth11',
  UnrenovatedMonth0 = 'unrenovatedMonth0',
  UnrenovatedMonth1 = 'unrenovatedMonth1',
  UnrenovatedMonth2 = 'unrenovatedMonth2',
  UnrenovatedMonth3 = 'unrenovatedMonth3',
  UnrenovatedMonth4 = 'unrenovatedMonth4',
  UnrenovatedMonth5 = 'unrenovatedMonth5',
  UnrenovatedMonth6 = 'unrenovatedMonth6',
  UnrenovatedMonth7 = 'unrenovatedMonth7',
  UnrenovatedMonth8 = 'unrenovatedMonth8',
  UnrenovatedMonth9 = 'unrenovatedMonth9',
  UnrenovatedMonth10 = 'unrenovatedMonth10',
  UnrenovatedMonth11 = 'unrenovatedMonth11',
  VersionType = 'versionType'
}

export type SimplifiedRevenuePropertyRenewalPercentScalarWhereInput = {
  AND?: Maybe<Array<SimplifiedRevenuePropertyRenewalPercentScalarWhereInput>>;
  NOT?: Maybe<Array<SimplifiedRevenuePropertyRenewalPercentScalarWhereInput>>;
  OR?: Maybe<Array<SimplifiedRevenuePropertyRenewalPercentScalarWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  propertyId?: Maybe<StringFilter>;
  renovatedMonth0?: Maybe<DecimalFilter>;
  renovatedMonth1?: Maybe<DecimalFilter>;
  renovatedMonth2?: Maybe<DecimalFilter>;
  renovatedMonth3?: Maybe<DecimalFilter>;
  renovatedMonth4?: Maybe<DecimalFilter>;
  renovatedMonth5?: Maybe<DecimalFilter>;
  renovatedMonth6?: Maybe<DecimalFilter>;
  renovatedMonth7?: Maybe<DecimalFilter>;
  renovatedMonth8?: Maybe<DecimalFilter>;
  renovatedMonth9?: Maybe<DecimalFilter>;
  renovatedMonth10?: Maybe<DecimalFilter>;
  renovatedMonth11?: Maybe<DecimalFilter>;
  unrenovatedMonth0?: Maybe<DecimalFilter>;
  unrenovatedMonth1?: Maybe<DecimalFilter>;
  unrenovatedMonth2?: Maybe<DecimalFilter>;
  unrenovatedMonth3?: Maybe<DecimalFilter>;
  unrenovatedMonth4?: Maybe<DecimalFilter>;
  unrenovatedMonth5?: Maybe<DecimalFilter>;
  unrenovatedMonth6?: Maybe<DecimalFilter>;
  unrenovatedMonth7?: Maybe<DecimalFilter>;
  unrenovatedMonth8?: Maybe<DecimalFilter>;
  unrenovatedMonth9?: Maybe<DecimalFilter>;
  unrenovatedMonth10?: Maybe<DecimalFilter>;
  unrenovatedMonth11?: Maybe<DecimalFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
};

export type SimplifiedRevenuePropertyRenewalPercentUpdateManyMutationInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovatedMonth0?: Maybe<DecimalFieldUpdateOperationsInput>;
  renovatedMonth1?: Maybe<DecimalFieldUpdateOperationsInput>;
  renovatedMonth2?: Maybe<DecimalFieldUpdateOperationsInput>;
  renovatedMonth3?: Maybe<DecimalFieldUpdateOperationsInput>;
  renovatedMonth4?: Maybe<DecimalFieldUpdateOperationsInput>;
  renovatedMonth5?: Maybe<DecimalFieldUpdateOperationsInput>;
  renovatedMonth6?: Maybe<DecimalFieldUpdateOperationsInput>;
  renovatedMonth7?: Maybe<DecimalFieldUpdateOperationsInput>;
  renovatedMonth8?: Maybe<DecimalFieldUpdateOperationsInput>;
  renovatedMonth9?: Maybe<DecimalFieldUpdateOperationsInput>;
  renovatedMonth10?: Maybe<DecimalFieldUpdateOperationsInput>;
  renovatedMonth11?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth0?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth1?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth2?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth3?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth4?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth5?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth6?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth7?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth8?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth9?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth10?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth11?: Maybe<DecimalFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type SimplifiedRevenuePropertyRenewalPercentUpdateManyWithWhereWithoutPropertyInput = {
  data: SimplifiedRevenuePropertyRenewalPercentUpdateManyMutationInput;
  where: SimplifiedRevenuePropertyRenewalPercentScalarWhereInput;
};

export type SimplifiedRevenuePropertyRenewalPercentUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<SimplifiedRevenuePropertyRenewalPercentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SimplifiedRevenuePropertyRenewalPercentCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<SimplifiedRevenuePropertyRenewalPercentCreateWithoutPropertyInput>>;
  createMany?: Maybe<SimplifiedRevenuePropertyRenewalPercentCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<SimplifiedRevenuePropertyRenewalPercentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SimplifiedRevenuePropertyRenewalPercentScalarWhereInput>>;
  disconnect?: Maybe<Array<SimplifiedRevenuePropertyRenewalPercentWhereUniqueInput>>;
  set?: Maybe<Array<SimplifiedRevenuePropertyRenewalPercentWhereUniqueInput>>;
  update?: Maybe<Array<SimplifiedRevenuePropertyRenewalPercentUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<SimplifiedRevenuePropertyRenewalPercentUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<SimplifiedRevenuePropertyRenewalPercentUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type SimplifiedRevenuePropertyRenewalPercentUpdateWithWhereUniqueWithoutPropertyInput = {
  data: SimplifiedRevenuePropertyRenewalPercentUpdateWithoutPropertyInput;
  where: SimplifiedRevenuePropertyRenewalPercentWhereUniqueInput;
};

export type SimplifiedRevenuePropertyRenewalPercentUpdateWithoutPropertyInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  renovatedMonth0?: Maybe<DecimalFieldUpdateOperationsInput>;
  renovatedMonth1?: Maybe<DecimalFieldUpdateOperationsInput>;
  renovatedMonth2?: Maybe<DecimalFieldUpdateOperationsInput>;
  renovatedMonth3?: Maybe<DecimalFieldUpdateOperationsInput>;
  renovatedMonth4?: Maybe<DecimalFieldUpdateOperationsInput>;
  renovatedMonth5?: Maybe<DecimalFieldUpdateOperationsInput>;
  renovatedMonth6?: Maybe<DecimalFieldUpdateOperationsInput>;
  renovatedMonth7?: Maybe<DecimalFieldUpdateOperationsInput>;
  renovatedMonth8?: Maybe<DecimalFieldUpdateOperationsInput>;
  renovatedMonth9?: Maybe<DecimalFieldUpdateOperationsInput>;
  renovatedMonth10?: Maybe<DecimalFieldUpdateOperationsInput>;
  renovatedMonth11?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth0?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth1?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth2?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth3?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth4?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth5?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth6?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth7?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth8?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth9?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth10?: Maybe<DecimalFieldUpdateOperationsInput>;
  unrenovatedMonth11?: Maybe<DecimalFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type SimplifiedRevenuePropertyRenewalPercentUpsertWithWhereUniqueWithoutPropertyInput = {
  create: SimplifiedRevenuePropertyRenewalPercentCreateWithoutPropertyInput;
  update: SimplifiedRevenuePropertyRenewalPercentUpdateWithoutPropertyInput;
  where: SimplifiedRevenuePropertyRenewalPercentWhereUniqueInput;
};

export type SimplifiedRevenuePropertyRenewalPercentWhereInput = {
  AND?: Maybe<Array<SimplifiedRevenuePropertyRenewalPercentWhereInput>>;
  NOT?: Maybe<Array<SimplifiedRevenuePropertyRenewalPercentWhereInput>>;
  OR?: Maybe<Array<SimplifiedRevenuePropertyRenewalPercentWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  renovatedMonth0?: Maybe<DecimalFilter>;
  renovatedMonth1?: Maybe<DecimalFilter>;
  renovatedMonth2?: Maybe<DecimalFilter>;
  renovatedMonth3?: Maybe<DecimalFilter>;
  renovatedMonth4?: Maybe<DecimalFilter>;
  renovatedMonth5?: Maybe<DecimalFilter>;
  renovatedMonth6?: Maybe<DecimalFilter>;
  renovatedMonth7?: Maybe<DecimalFilter>;
  renovatedMonth8?: Maybe<DecimalFilter>;
  renovatedMonth9?: Maybe<DecimalFilter>;
  renovatedMonth10?: Maybe<DecimalFilter>;
  renovatedMonth11?: Maybe<DecimalFilter>;
  unrenovatedMonth0?: Maybe<DecimalFilter>;
  unrenovatedMonth1?: Maybe<DecimalFilter>;
  unrenovatedMonth2?: Maybe<DecimalFilter>;
  unrenovatedMonth3?: Maybe<DecimalFilter>;
  unrenovatedMonth4?: Maybe<DecimalFilter>;
  unrenovatedMonth5?: Maybe<DecimalFilter>;
  unrenovatedMonth6?: Maybe<DecimalFilter>;
  unrenovatedMonth7?: Maybe<DecimalFilter>;
  unrenovatedMonth8?: Maybe<DecimalFilter>;
  unrenovatedMonth9?: Maybe<DecimalFilter>;
  unrenovatedMonth10?: Maybe<DecimalFilter>;
  unrenovatedMonth11?: Maybe<DecimalFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
};

export type SimplifiedRevenuePropertyRenewalPercentWhereUniqueInput = {
  propertyId_versionType_budgetYear?: Maybe<SimplifiedRevenuePropertyRenewalPercentPropertyIdVersionTypeBudgetYearCompoundUniqueInput>;
};

export type SimplifiedRevenuePropertyRenewalTradeOutRule = {
  __typename?: 'SimplifiedRevenuePropertyRenewalTradeOutRule';
  budgetYear: Scalars['Int'];
  formula: Scalars['String'];
  increasePercentIfInPlaceGtLtlCalc: Scalars['Decimal'];
  ltlCapturePercent: Scalars['Decimal'];
  maxOfMarketPercent: Scalars['Decimal'];
  propertyId: Scalars['String'];
  renewalTargetIncreaseMaxAmount: Scalars['Decimal'];
  renewalTargetIncreaseMaxPercent: Scalars['Decimal'];
  versionType: VersionType;
};

export type SimplifiedRevenuePropertyRenewalTradeOutRuleCreateManyPropertyInput = {
  budgetYear: Scalars['Int'];
  formula: Scalars['String'];
  increasePercentIfInPlaceGtLtlCalc?: Maybe<Scalars['Decimal']>;
  ltlCapturePercent?: Maybe<Scalars['Decimal']>;
  maxOfMarketPercent?: Maybe<Scalars['Decimal']>;
  renewalTargetIncreaseMaxAmount?: Maybe<Scalars['Decimal']>;
  renewalTargetIncreaseMaxPercent?: Maybe<Scalars['Decimal']>;
  versionType: VersionType;
};

export type SimplifiedRevenuePropertyRenewalTradeOutRuleCreateManyPropertyInputEnvelope = {
  data: Array<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SimplifiedRevenuePropertyRenewalTradeOutRuleCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<SimplifiedRevenuePropertyRenewalTradeOutRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateWithoutPropertyInput>>;
  createMany?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateManyPropertyInputEnvelope>;
};

export type SimplifiedRevenuePropertyRenewalTradeOutRuleCreateOrConnectWithoutPropertyInput = {
  create: SimplifiedRevenuePropertyRenewalTradeOutRuleCreateWithoutPropertyInput;
  where: SimplifiedRevenuePropertyRenewalTradeOutRuleWhereUniqueInput;
};

export type SimplifiedRevenuePropertyRenewalTradeOutRuleCreateWithoutPropertyInput = {
  budgetYear: Scalars['Int'];
  formula: Scalars['String'];
  increasePercentIfInPlaceGtLtlCalc?: Maybe<Scalars['Decimal']>;
  ltlCapturePercent?: Maybe<Scalars['Decimal']>;
  maxOfMarketPercent?: Maybe<Scalars['Decimal']>;
  renewalTargetIncreaseMaxAmount?: Maybe<Scalars['Decimal']>;
  renewalTargetIncreaseMaxPercent?: Maybe<Scalars['Decimal']>;
  versionType: VersionType;
};

export type SimplifiedRevenuePropertyRenewalTradeOutRuleListRelationFilter = {
  every?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleWhereInput>;
  none?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleWhereInput>;
  some?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleWhereInput>;
};

export type SimplifiedRevenuePropertyRenewalTradeOutRuleOrderByInput = {
  budgetYear?: Maybe<SortOrder>;
  formula?: Maybe<SortOrder>;
  increasePercentIfInPlaceGtLtlCalc?: Maybe<SortOrder>;
  ltlCapturePercent?: Maybe<SortOrder>;
  maxOfMarketPercent?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  renewalTargetIncreaseMaxAmount?: Maybe<SortOrder>;
  renewalTargetIncreaseMaxPercent?: Maybe<SortOrder>;
  versionType?: Maybe<SortOrder>;
};

export type SimplifiedRevenuePropertyRenewalTradeOutRulePropertyIdVersionTypeBudgetYearCompoundUniqueInput = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['String'];
  versionType: VersionType;
};

export enum SimplifiedRevenuePropertyRenewalTradeOutRuleScalarFieldEnum {
  BudgetYear = 'budgetYear',
  Formula = 'formula',
  IncreasePercentIfInPlaceGtLtlCalc = 'increasePercentIfInPlaceGtLtlCalc',
  LtlCapturePercent = 'ltlCapturePercent',
  MaxOfMarketPercent = 'maxOfMarketPercent',
  PropertyId = 'propertyId',
  RenewalTargetIncreaseMaxAmount = 'renewalTargetIncreaseMaxAmount',
  RenewalTargetIncreaseMaxPercent = 'renewalTargetIncreaseMaxPercent',
  VersionType = 'versionType'
}

export type SimplifiedRevenuePropertyRenewalTradeOutRuleScalarWhereInput = {
  AND?: Maybe<Array<SimplifiedRevenuePropertyRenewalTradeOutRuleScalarWhereInput>>;
  NOT?: Maybe<Array<SimplifiedRevenuePropertyRenewalTradeOutRuleScalarWhereInput>>;
  OR?: Maybe<Array<SimplifiedRevenuePropertyRenewalTradeOutRuleScalarWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  formula?: Maybe<StringFilter>;
  increasePercentIfInPlaceGtLtlCalc?: Maybe<DecimalFilter>;
  ltlCapturePercent?: Maybe<DecimalFilter>;
  maxOfMarketPercent?: Maybe<DecimalFilter>;
  propertyId?: Maybe<StringFilter>;
  renewalTargetIncreaseMaxAmount?: Maybe<DecimalFilter>;
  renewalTargetIncreaseMaxPercent?: Maybe<DecimalFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
};

export type SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyMutationInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  formula?: Maybe<StringFieldUpdateOperationsInput>;
  increasePercentIfInPlaceGtLtlCalc?: Maybe<DecimalFieldUpdateOperationsInput>;
  ltlCapturePercent?: Maybe<DecimalFieldUpdateOperationsInput>;
  maxOfMarketPercent?: Maybe<DecimalFieldUpdateOperationsInput>;
  renewalTargetIncreaseMaxAmount?: Maybe<DecimalFieldUpdateOperationsInput>;
  renewalTargetIncreaseMaxPercent?: Maybe<DecimalFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithWhereWithoutPropertyInput = {
  data: SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyMutationInput;
  where: SimplifiedRevenuePropertyRenewalTradeOutRuleScalarWhereInput;
};

export type SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<SimplifiedRevenuePropertyRenewalTradeOutRuleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateWithoutPropertyInput>>;
  createMany?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRuleCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<SimplifiedRevenuePropertyRenewalTradeOutRuleWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SimplifiedRevenuePropertyRenewalTradeOutRuleScalarWhereInput>>;
  disconnect?: Maybe<Array<SimplifiedRevenuePropertyRenewalTradeOutRuleWhereUniqueInput>>;
  set?: Maybe<Array<SimplifiedRevenuePropertyRenewalTradeOutRuleWhereUniqueInput>>;
  update?: Maybe<Array<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<SimplifiedRevenuePropertyRenewalTradeOutRuleUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateWithWhereUniqueWithoutPropertyInput = {
  data: SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateWithoutPropertyInput;
  where: SimplifiedRevenuePropertyRenewalTradeOutRuleWhereUniqueInput;
};

export type SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateWithoutPropertyInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  formula?: Maybe<StringFieldUpdateOperationsInput>;
  increasePercentIfInPlaceGtLtlCalc?: Maybe<DecimalFieldUpdateOperationsInput>;
  ltlCapturePercent?: Maybe<DecimalFieldUpdateOperationsInput>;
  maxOfMarketPercent?: Maybe<DecimalFieldUpdateOperationsInput>;
  renewalTargetIncreaseMaxAmount?: Maybe<DecimalFieldUpdateOperationsInput>;
  renewalTargetIncreaseMaxPercent?: Maybe<DecimalFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type SimplifiedRevenuePropertyRenewalTradeOutRuleUpsertWithWhereUniqueWithoutPropertyInput = {
  create: SimplifiedRevenuePropertyRenewalTradeOutRuleCreateWithoutPropertyInput;
  update: SimplifiedRevenuePropertyRenewalTradeOutRuleUpdateWithoutPropertyInput;
  where: SimplifiedRevenuePropertyRenewalTradeOutRuleWhereUniqueInput;
};

export type SimplifiedRevenuePropertyRenewalTradeOutRuleWhereInput = {
  AND?: Maybe<Array<SimplifiedRevenuePropertyRenewalTradeOutRuleWhereInput>>;
  NOT?: Maybe<Array<SimplifiedRevenuePropertyRenewalTradeOutRuleWhereInput>>;
  OR?: Maybe<Array<SimplifiedRevenuePropertyRenewalTradeOutRuleWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  formula?: Maybe<StringFilter>;
  increasePercentIfInPlaceGtLtlCalc?: Maybe<DecimalFilter>;
  ltlCapturePercent?: Maybe<DecimalFilter>;
  maxOfMarketPercent?: Maybe<DecimalFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  renewalTargetIncreaseMaxAmount?: Maybe<DecimalFilter>;
  renewalTargetIncreaseMaxPercent?: Maybe<DecimalFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
};

export type SimplifiedRevenuePropertyRenewalTradeOutRuleWhereUniqueInput = {
  propertyId_versionType_budgetYear?: Maybe<SimplifiedRevenuePropertyRenewalTradeOutRulePropertyIdVersionTypeBudgetYearCompoundUniqueInput>;
};

export type SimplifiedRevenuePropertyRenovationPercent = {
  __typename?: 'SimplifiedRevenuePropertyRenovationPercent';
  budgetYear: Scalars['Int'];
  month0: Scalars['Decimal'];
  month1: Scalars['Decimal'];
  month2: Scalars['Decimal'];
  month3: Scalars['Decimal'];
  month4: Scalars['Decimal'];
  month5: Scalars['Decimal'];
  month6: Scalars['Decimal'];
  month7: Scalars['Decimal'];
  month8: Scalars['Decimal'];
  month9: Scalars['Decimal'];
  month10: Scalars['Decimal'];
  month11: Scalars['Decimal'];
  propertyId: Scalars['String'];
  versionType: VersionType;
};

export type SimplifiedRevenuePropertyRenovationPercentCreateManyPropertyInput = {
  budgetYear: Scalars['Int'];
  month0?: Maybe<Scalars['Decimal']>;
  month1?: Maybe<Scalars['Decimal']>;
  month2?: Maybe<Scalars['Decimal']>;
  month3?: Maybe<Scalars['Decimal']>;
  month4?: Maybe<Scalars['Decimal']>;
  month5?: Maybe<Scalars['Decimal']>;
  month6?: Maybe<Scalars['Decimal']>;
  month7?: Maybe<Scalars['Decimal']>;
  month8?: Maybe<Scalars['Decimal']>;
  month9?: Maybe<Scalars['Decimal']>;
  month10?: Maybe<Scalars['Decimal']>;
  month11?: Maybe<Scalars['Decimal']>;
  versionType: VersionType;
};

export type SimplifiedRevenuePropertyRenovationPercentCreateManyPropertyInputEnvelope = {
  data: Array<SimplifiedRevenuePropertyRenovationPercentCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SimplifiedRevenuePropertyRenovationPercentCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<SimplifiedRevenuePropertyRenovationPercentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SimplifiedRevenuePropertyRenovationPercentCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<SimplifiedRevenuePropertyRenovationPercentCreateWithoutPropertyInput>>;
  createMany?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateManyPropertyInputEnvelope>;
};

export type SimplifiedRevenuePropertyRenovationPercentCreateOrConnectWithoutPropertyInput = {
  create: SimplifiedRevenuePropertyRenovationPercentCreateWithoutPropertyInput;
  where: SimplifiedRevenuePropertyRenovationPercentWhereUniqueInput;
};

export type SimplifiedRevenuePropertyRenovationPercentCreateWithoutPropertyInput = {
  budgetYear: Scalars['Int'];
  month0?: Maybe<Scalars['Decimal']>;
  month1?: Maybe<Scalars['Decimal']>;
  month2?: Maybe<Scalars['Decimal']>;
  month3?: Maybe<Scalars['Decimal']>;
  month4?: Maybe<Scalars['Decimal']>;
  month5?: Maybe<Scalars['Decimal']>;
  month6?: Maybe<Scalars['Decimal']>;
  month7?: Maybe<Scalars['Decimal']>;
  month8?: Maybe<Scalars['Decimal']>;
  month9?: Maybe<Scalars['Decimal']>;
  month10?: Maybe<Scalars['Decimal']>;
  month11?: Maybe<Scalars['Decimal']>;
  versionType: VersionType;
};

export type SimplifiedRevenuePropertyRenovationPercentListRelationFilter = {
  every?: Maybe<SimplifiedRevenuePropertyRenovationPercentWhereInput>;
  none?: Maybe<SimplifiedRevenuePropertyRenovationPercentWhereInput>;
  some?: Maybe<SimplifiedRevenuePropertyRenovationPercentWhereInput>;
};

export type SimplifiedRevenuePropertyRenovationPercentOrderByInput = {
  budgetYear?: Maybe<SortOrder>;
  month0?: Maybe<SortOrder>;
  month1?: Maybe<SortOrder>;
  month2?: Maybe<SortOrder>;
  month3?: Maybe<SortOrder>;
  month4?: Maybe<SortOrder>;
  month5?: Maybe<SortOrder>;
  month6?: Maybe<SortOrder>;
  month7?: Maybe<SortOrder>;
  month8?: Maybe<SortOrder>;
  month9?: Maybe<SortOrder>;
  month10?: Maybe<SortOrder>;
  month11?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  versionType?: Maybe<SortOrder>;
};

export type SimplifiedRevenuePropertyRenovationPercentPropertyIdVersionTypeBudgetYearCompoundUniqueInput = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['String'];
  versionType: VersionType;
};

export enum SimplifiedRevenuePropertyRenovationPercentScalarFieldEnum {
  BudgetYear = 'budgetYear',
  Month0 = 'month0',
  Month1 = 'month1',
  Month2 = 'month2',
  Month3 = 'month3',
  Month4 = 'month4',
  Month5 = 'month5',
  Month6 = 'month6',
  Month7 = 'month7',
  Month8 = 'month8',
  Month9 = 'month9',
  Month10 = 'month10',
  Month11 = 'month11',
  PropertyId = 'propertyId',
  VersionType = 'versionType'
}

export type SimplifiedRevenuePropertyRenovationPercentScalarWhereInput = {
  AND?: Maybe<Array<SimplifiedRevenuePropertyRenovationPercentScalarWhereInput>>;
  NOT?: Maybe<Array<SimplifiedRevenuePropertyRenovationPercentScalarWhereInput>>;
  OR?: Maybe<Array<SimplifiedRevenuePropertyRenovationPercentScalarWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  month0?: Maybe<DecimalFilter>;
  month1?: Maybe<DecimalFilter>;
  month2?: Maybe<DecimalFilter>;
  month3?: Maybe<DecimalFilter>;
  month4?: Maybe<DecimalFilter>;
  month5?: Maybe<DecimalFilter>;
  month6?: Maybe<DecimalFilter>;
  month7?: Maybe<DecimalFilter>;
  month8?: Maybe<DecimalFilter>;
  month9?: Maybe<DecimalFilter>;
  month10?: Maybe<DecimalFilter>;
  month11?: Maybe<DecimalFilter>;
  propertyId?: Maybe<StringFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
};

export type SimplifiedRevenuePropertyRenovationPercentUpdateManyMutationInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  month0?: Maybe<DecimalFieldUpdateOperationsInput>;
  month1?: Maybe<DecimalFieldUpdateOperationsInput>;
  month2?: Maybe<DecimalFieldUpdateOperationsInput>;
  month3?: Maybe<DecimalFieldUpdateOperationsInput>;
  month4?: Maybe<DecimalFieldUpdateOperationsInput>;
  month5?: Maybe<DecimalFieldUpdateOperationsInput>;
  month6?: Maybe<DecimalFieldUpdateOperationsInput>;
  month7?: Maybe<DecimalFieldUpdateOperationsInput>;
  month8?: Maybe<DecimalFieldUpdateOperationsInput>;
  month9?: Maybe<DecimalFieldUpdateOperationsInput>;
  month10?: Maybe<DecimalFieldUpdateOperationsInput>;
  month11?: Maybe<DecimalFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type SimplifiedRevenuePropertyRenovationPercentUpdateManyWithWhereWithoutPropertyInput = {
  data: SimplifiedRevenuePropertyRenovationPercentUpdateManyMutationInput;
  where: SimplifiedRevenuePropertyRenovationPercentScalarWhereInput;
};

export type SimplifiedRevenuePropertyRenovationPercentUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<SimplifiedRevenuePropertyRenovationPercentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SimplifiedRevenuePropertyRenovationPercentCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<SimplifiedRevenuePropertyRenovationPercentCreateWithoutPropertyInput>>;
  createMany?: Maybe<SimplifiedRevenuePropertyRenovationPercentCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<SimplifiedRevenuePropertyRenovationPercentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SimplifiedRevenuePropertyRenovationPercentScalarWhereInput>>;
  disconnect?: Maybe<Array<SimplifiedRevenuePropertyRenovationPercentWhereUniqueInput>>;
  set?: Maybe<Array<SimplifiedRevenuePropertyRenovationPercentWhereUniqueInput>>;
  update?: Maybe<Array<SimplifiedRevenuePropertyRenovationPercentUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<SimplifiedRevenuePropertyRenovationPercentUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<SimplifiedRevenuePropertyRenovationPercentUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type SimplifiedRevenuePropertyRenovationPercentUpdateWithWhereUniqueWithoutPropertyInput = {
  data: SimplifiedRevenuePropertyRenovationPercentUpdateWithoutPropertyInput;
  where: SimplifiedRevenuePropertyRenovationPercentWhereUniqueInput;
};

export type SimplifiedRevenuePropertyRenovationPercentUpdateWithoutPropertyInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  month0?: Maybe<DecimalFieldUpdateOperationsInput>;
  month1?: Maybe<DecimalFieldUpdateOperationsInput>;
  month2?: Maybe<DecimalFieldUpdateOperationsInput>;
  month3?: Maybe<DecimalFieldUpdateOperationsInput>;
  month4?: Maybe<DecimalFieldUpdateOperationsInput>;
  month5?: Maybe<DecimalFieldUpdateOperationsInput>;
  month6?: Maybe<DecimalFieldUpdateOperationsInput>;
  month7?: Maybe<DecimalFieldUpdateOperationsInput>;
  month8?: Maybe<DecimalFieldUpdateOperationsInput>;
  month9?: Maybe<DecimalFieldUpdateOperationsInput>;
  month10?: Maybe<DecimalFieldUpdateOperationsInput>;
  month11?: Maybe<DecimalFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type SimplifiedRevenuePropertyRenovationPercentUpsertWithWhereUniqueWithoutPropertyInput = {
  create: SimplifiedRevenuePropertyRenovationPercentCreateWithoutPropertyInput;
  update: SimplifiedRevenuePropertyRenovationPercentUpdateWithoutPropertyInput;
  where: SimplifiedRevenuePropertyRenovationPercentWhereUniqueInput;
};

export type SimplifiedRevenuePropertyRenovationPercentWhereInput = {
  AND?: Maybe<Array<SimplifiedRevenuePropertyRenovationPercentWhereInput>>;
  NOT?: Maybe<Array<SimplifiedRevenuePropertyRenovationPercentWhereInput>>;
  OR?: Maybe<Array<SimplifiedRevenuePropertyRenovationPercentWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  month0?: Maybe<DecimalFilter>;
  month1?: Maybe<DecimalFilter>;
  month2?: Maybe<DecimalFilter>;
  month3?: Maybe<DecimalFilter>;
  month4?: Maybe<DecimalFilter>;
  month5?: Maybe<DecimalFilter>;
  month6?: Maybe<DecimalFilter>;
  month7?: Maybe<DecimalFilter>;
  month8?: Maybe<DecimalFilter>;
  month9?: Maybe<DecimalFilter>;
  month10?: Maybe<DecimalFilter>;
  month11?: Maybe<DecimalFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
};

export type SimplifiedRevenuePropertyRenovationPercentWhereUniqueInput = {
  propertyId_versionType_budgetYear?: Maybe<SimplifiedRevenuePropertyRenovationPercentPropertyIdVersionTypeBudgetYearCompoundUniqueInput>;
};

export type SimplifiedRevenuePropertyVacancyPercent = {
  __typename?: 'SimplifiedRevenuePropertyVacancyPercent';
  budgetYear: Scalars['Int'];
  month0: Scalars['Decimal'];
  month1: Scalars['Decimal'];
  month2: Scalars['Decimal'];
  month3: Scalars['Decimal'];
  month4: Scalars['Decimal'];
  month5: Scalars['Decimal'];
  month6: Scalars['Decimal'];
  month7: Scalars['Decimal'];
  month8: Scalars['Decimal'];
  month9: Scalars['Decimal'];
  month10: Scalars['Decimal'];
  month11: Scalars['Decimal'];
  propertyId: Scalars['String'];
  versionType: VersionType;
};

export type SimplifiedRevenuePropertyVacancyPercentCreateManyPropertyInput = {
  budgetYear: Scalars['Int'];
  month0?: Maybe<Scalars['Decimal']>;
  month1?: Maybe<Scalars['Decimal']>;
  month2?: Maybe<Scalars['Decimal']>;
  month3?: Maybe<Scalars['Decimal']>;
  month4?: Maybe<Scalars['Decimal']>;
  month5?: Maybe<Scalars['Decimal']>;
  month6?: Maybe<Scalars['Decimal']>;
  month7?: Maybe<Scalars['Decimal']>;
  month8?: Maybe<Scalars['Decimal']>;
  month9?: Maybe<Scalars['Decimal']>;
  month10?: Maybe<Scalars['Decimal']>;
  month11?: Maybe<Scalars['Decimal']>;
  versionType: VersionType;
};

export type SimplifiedRevenuePropertyVacancyPercentCreateManyPropertyInputEnvelope = {
  data: Array<SimplifiedRevenuePropertyVacancyPercentCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SimplifiedRevenuePropertyVacancyPercentCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<SimplifiedRevenuePropertyVacancyPercentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SimplifiedRevenuePropertyVacancyPercentCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<SimplifiedRevenuePropertyVacancyPercentCreateWithoutPropertyInput>>;
  createMany?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateManyPropertyInputEnvelope>;
};

export type SimplifiedRevenuePropertyVacancyPercentCreateOrConnectWithoutPropertyInput = {
  create: SimplifiedRevenuePropertyVacancyPercentCreateWithoutPropertyInput;
  where: SimplifiedRevenuePropertyVacancyPercentWhereUniqueInput;
};

export type SimplifiedRevenuePropertyVacancyPercentCreateWithoutPropertyInput = {
  budgetYear: Scalars['Int'];
  month0?: Maybe<Scalars['Decimal']>;
  month1?: Maybe<Scalars['Decimal']>;
  month2?: Maybe<Scalars['Decimal']>;
  month3?: Maybe<Scalars['Decimal']>;
  month4?: Maybe<Scalars['Decimal']>;
  month5?: Maybe<Scalars['Decimal']>;
  month6?: Maybe<Scalars['Decimal']>;
  month7?: Maybe<Scalars['Decimal']>;
  month8?: Maybe<Scalars['Decimal']>;
  month9?: Maybe<Scalars['Decimal']>;
  month10?: Maybe<Scalars['Decimal']>;
  month11?: Maybe<Scalars['Decimal']>;
  versionType: VersionType;
};

export type SimplifiedRevenuePropertyVacancyPercentListRelationFilter = {
  every?: Maybe<SimplifiedRevenuePropertyVacancyPercentWhereInput>;
  none?: Maybe<SimplifiedRevenuePropertyVacancyPercentWhereInput>;
  some?: Maybe<SimplifiedRevenuePropertyVacancyPercentWhereInput>;
};

export type SimplifiedRevenuePropertyVacancyPercentOrderByInput = {
  budgetYear?: Maybe<SortOrder>;
  month0?: Maybe<SortOrder>;
  month1?: Maybe<SortOrder>;
  month2?: Maybe<SortOrder>;
  month3?: Maybe<SortOrder>;
  month4?: Maybe<SortOrder>;
  month5?: Maybe<SortOrder>;
  month6?: Maybe<SortOrder>;
  month7?: Maybe<SortOrder>;
  month8?: Maybe<SortOrder>;
  month9?: Maybe<SortOrder>;
  month10?: Maybe<SortOrder>;
  month11?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  versionType?: Maybe<SortOrder>;
};

export type SimplifiedRevenuePropertyVacancyPercentPropertyIdVersionTypeBudgetYearCompoundUniqueInput = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['String'];
  versionType: VersionType;
};

export enum SimplifiedRevenuePropertyVacancyPercentScalarFieldEnum {
  BudgetYear = 'budgetYear',
  Month0 = 'month0',
  Month1 = 'month1',
  Month2 = 'month2',
  Month3 = 'month3',
  Month4 = 'month4',
  Month5 = 'month5',
  Month6 = 'month6',
  Month7 = 'month7',
  Month8 = 'month8',
  Month9 = 'month9',
  Month10 = 'month10',
  Month11 = 'month11',
  PropertyId = 'propertyId',
  VersionType = 'versionType'
}

export type SimplifiedRevenuePropertyVacancyPercentScalarWhereInput = {
  AND?: Maybe<Array<SimplifiedRevenuePropertyVacancyPercentScalarWhereInput>>;
  NOT?: Maybe<Array<SimplifiedRevenuePropertyVacancyPercentScalarWhereInput>>;
  OR?: Maybe<Array<SimplifiedRevenuePropertyVacancyPercentScalarWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  month0?: Maybe<DecimalFilter>;
  month1?: Maybe<DecimalFilter>;
  month2?: Maybe<DecimalFilter>;
  month3?: Maybe<DecimalFilter>;
  month4?: Maybe<DecimalFilter>;
  month5?: Maybe<DecimalFilter>;
  month6?: Maybe<DecimalFilter>;
  month7?: Maybe<DecimalFilter>;
  month8?: Maybe<DecimalFilter>;
  month9?: Maybe<DecimalFilter>;
  month10?: Maybe<DecimalFilter>;
  month11?: Maybe<DecimalFilter>;
  propertyId?: Maybe<StringFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
};

export type SimplifiedRevenuePropertyVacancyPercentUpdateManyMutationInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  month0?: Maybe<DecimalFieldUpdateOperationsInput>;
  month1?: Maybe<DecimalFieldUpdateOperationsInput>;
  month2?: Maybe<DecimalFieldUpdateOperationsInput>;
  month3?: Maybe<DecimalFieldUpdateOperationsInput>;
  month4?: Maybe<DecimalFieldUpdateOperationsInput>;
  month5?: Maybe<DecimalFieldUpdateOperationsInput>;
  month6?: Maybe<DecimalFieldUpdateOperationsInput>;
  month7?: Maybe<DecimalFieldUpdateOperationsInput>;
  month8?: Maybe<DecimalFieldUpdateOperationsInput>;
  month9?: Maybe<DecimalFieldUpdateOperationsInput>;
  month10?: Maybe<DecimalFieldUpdateOperationsInput>;
  month11?: Maybe<DecimalFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type SimplifiedRevenuePropertyVacancyPercentUpdateManyWithWhereWithoutPropertyInput = {
  data: SimplifiedRevenuePropertyVacancyPercentUpdateManyMutationInput;
  where: SimplifiedRevenuePropertyVacancyPercentScalarWhereInput;
};

export type SimplifiedRevenuePropertyVacancyPercentUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<SimplifiedRevenuePropertyVacancyPercentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SimplifiedRevenuePropertyVacancyPercentCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<SimplifiedRevenuePropertyVacancyPercentCreateWithoutPropertyInput>>;
  createMany?: Maybe<SimplifiedRevenuePropertyVacancyPercentCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<SimplifiedRevenuePropertyVacancyPercentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SimplifiedRevenuePropertyVacancyPercentScalarWhereInput>>;
  disconnect?: Maybe<Array<SimplifiedRevenuePropertyVacancyPercentWhereUniqueInput>>;
  set?: Maybe<Array<SimplifiedRevenuePropertyVacancyPercentWhereUniqueInput>>;
  update?: Maybe<Array<SimplifiedRevenuePropertyVacancyPercentUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<SimplifiedRevenuePropertyVacancyPercentUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<SimplifiedRevenuePropertyVacancyPercentUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type SimplifiedRevenuePropertyVacancyPercentUpdateWithWhereUniqueWithoutPropertyInput = {
  data: SimplifiedRevenuePropertyVacancyPercentUpdateWithoutPropertyInput;
  where: SimplifiedRevenuePropertyVacancyPercentWhereUniqueInput;
};

export type SimplifiedRevenuePropertyVacancyPercentUpdateWithoutPropertyInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  month0?: Maybe<DecimalFieldUpdateOperationsInput>;
  month1?: Maybe<DecimalFieldUpdateOperationsInput>;
  month2?: Maybe<DecimalFieldUpdateOperationsInput>;
  month3?: Maybe<DecimalFieldUpdateOperationsInput>;
  month4?: Maybe<DecimalFieldUpdateOperationsInput>;
  month5?: Maybe<DecimalFieldUpdateOperationsInput>;
  month6?: Maybe<DecimalFieldUpdateOperationsInput>;
  month7?: Maybe<DecimalFieldUpdateOperationsInput>;
  month8?: Maybe<DecimalFieldUpdateOperationsInput>;
  month9?: Maybe<DecimalFieldUpdateOperationsInput>;
  month10?: Maybe<DecimalFieldUpdateOperationsInput>;
  month11?: Maybe<DecimalFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
};

export type SimplifiedRevenuePropertyVacancyPercentUpsertWithWhereUniqueWithoutPropertyInput = {
  create: SimplifiedRevenuePropertyVacancyPercentCreateWithoutPropertyInput;
  update: SimplifiedRevenuePropertyVacancyPercentUpdateWithoutPropertyInput;
  where: SimplifiedRevenuePropertyVacancyPercentWhereUniqueInput;
};

export type SimplifiedRevenuePropertyVacancyPercentWhereInput = {
  AND?: Maybe<Array<SimplifiedRevenuePropertyVacancyPercentWhereInput>>;
  NOT?: Maybe<Array<SimplifiedRevenuePropertyVacancyPercentWhereInput>>;
  OR?: Maybe<Array<SimplifiedRevenuePropertyVacancyPercentWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  month0?: Maybe<DecimalFilter>;
  month1?: Maybe<DecimalFilter>;
  month2?: Maybe<DecimalFilter>;
  month3?: Maybe<DecimalFilter>;
  month4?: Maybe<DecimalFilter>;
  month5?: Maybe<DecimalFilter>;
  month6?: Maybe<DecimalFilter>;
  month7?: Maybe<DecimalFilter>;
  month8?: Maybe<DecimalFilter>;
  month9?: Maybe<DecimalFilter>;
  month10?: Maybe<DecimalFilter>;
  month11?: Maybe<DecimalFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
};

export type SimplifiedRevenuePropertyVacancyPercentWhereUniqueInput = {
  propertyId_versionType_budgetYear?: Maybe<SimplifiedRevenuePropertyVacancyPercentPropertyIdVersionTypeBudgetYearCompoundUniqueInput>;
};

export type SimplifiedRevenueRenewalConfiguration = {
  __typename?: 'SimplifiedRevenueRenewalConfiguration';
  budget: SimplifiedRevenueRenewalPeriodConfiguration;
  reforecast: SimplifiedRevenueRenewalPeriodConfiguration;
};

export type SimplifiedRevenueRenewalPeriodConfiguration = {
  __typename?: 'SimplifiedRevenueRenewalPeriodConfiguration';
  increaseInPlaceGreaterThanLossToLease: Scalars['Float'];
  lossToLeaseCaptureRate: Scalars['Float'];
  maxIncreaseAmount: Scalars['Float'];
  maxIncreasePercentage: Scalars['Float'];
  maxPercentOfMarketRent: Scalars['Float'];
};

export type SimplifiedRevenueRenewalRatio = {
  __typename?: 'SimplifiedRevenueRenewalRatio';
  /** The renewal ratio expected for budget months. Values may be null when they don't exist for the months. When null they are treated as 0s for purposes of calculation. The array is always of length 12. */
  budget: Array<Maybe<Scalars['Float']>>;
  /** The renewal ratio expected for reforecast months. Values may be null when they don't exist for the months. When null they are treated as 0s for purposes of calculation. The array is always of length 12. */
  reforecast: Array<Maybe<Scalars['Float']>>;
};

export type SimplifiedRevenueRenewalRatioInput = {
  budgetMonthlyValues: Array<SimplifiedRevenueMonthlyNumberInput>;
  reforecastMonthlyValues: Array<SimplifiedRevenueMonthlyNumberInput>;
};

export type SimplifiedRevenueRenewalTradeOutConfigurationInput = {
  budget?: Maybe<SimplifiedRevenueRenewalTradeOutConfigurationPeriodInput>;
  reforecast?: Maybe<SimplifiedRevenueRenewalTradeOutConfigurationPeriodInput>;
};

export type SimplifiedRevenueRenewalTradeOutConfigurationPeriodInput = {
  increaseInPlaceGreaterThanLossToLease: Scalars['Float'];
  lossToLeaseCaptureRate: Scalars['Float'];
  maxIncreaseAmount: Scalars['Float'];
  maxIncreasePercentage: Scalars['Float'];
  maxPercentOfMarketRent: Scalars['Float'];
};

/** Contains a mapping of the unrenovated unit type (aka the unit type the package was created for), and the renovated unit type. Note: The renovated unit type may be fake and not something you can match to a real unit type. */
export type SimplifiedRevenueRenovationMapping = {
  __typename?: 'SimplifiedRevenueRenovationMapping';
  renovatedUnitTypeId: Scalars['ID'];
  renovatedUnitTypeName: Scalars['ID'];
  /** The starting rent of a fake unit type. This value is only set when the unit type is fake. */
  renovatedUnitTypeStartingRent?: Maybe<Scalars['Float']>;
  unrenovatedUnitTypeId: Scalars['ID'];
  unrenovatedUnitTypeName: Scalars['ID'];
};

export type SimplifiedRevenueRenovationRatio = {
  __typename?: 'SimplifiedRevenueRenovationRatio';
  /** The renewal ratio expected for budget months. Values may be null when they don't exist for the months. When null they are treated as 0s for purposes of calculation. The array is always of length 12. */
  budget: Array<Maybe<Scalars['Float']>>;
  /** The renewal ratio expected for reforecast months. Values may be null when they don't exist for the months. When null they are treated as 0s for purposes of calculation. The array is always of length 12. */
  reforecast: Array<Maybe<Scalars['Float']>>;
};

export type SimplifiedRevenueRenovationRatioInput = {
  budgetMonthlyValues: Array<SimplifiedRevenueMonthlyNumberInput>;
  reforecastMonthlyValues: Array<SimplifiedRevenueMonthlyNumberInput>;
};

/** ////////////////////////////////////////////////////////// */
export type SimplifiedRevenueRentRoll = {
  __typename?: 'SimplifiedRevenueRentRoll';
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  loadedDate: Scalars['DateTime'];
  propertyId: Scalars['String'];
};

export type SimplifiedRevenueRentRollCreateManyPropertyInput = {
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  loadedDate: Scalars['DateTime'];
};

export type SimplifiedRevenueRentRollCreateManyPropertyInputEnvelope = {
  data: Array<SimplifiedRevenueRentRollCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SimplifiedRevenueRentRollCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<SimplifiedRevenueRentRollWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SimplifiedRevenueRentRollCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<SimplifiedRevenueRentRollCreateWithoutPropertyInput>>;
  createMany?: Maybe<SimplifiedRevenueRentRollCreateManyPropertyInputEnvelope>;
};

export type SimplifiedRevenueRentRollCreateOrConnectWithoutPropertyInput = {
  create: SimplifiedRevenueRentRollCreateWithoutPropertyInput;
  where: SimplifiedRevenueRentRollWhereUniqueInput;
};

export type SimplifiedRevenueRentRollCreateWithoutPropertyInput = {
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  loadedDate: Scalars['DateTime'];
  rentRollRecords?: Maybe<SimplifiedRevenueRentRollRecordCreateNestedManyWithoutRentRollInput>;
};

export type SimplifiedRevenueRentRollListRelationFilter = {
  every?: Maybe<SimplifiedRevenueRentRollWhereInput>;
  none?: Maybe<SimplifiedRevenueRentRollWhereInput>;
  some?: Maybe<SimplifiedRevenueRentRollWhereInput>;
};

export type SimplifiedRevenueRentRollOrderByInput = {
  id?: Maybe<SortOrder>;
  isActive?: Maybe<SortOrder>;
  loadedDate?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
};

export type SimplifiedRevenueRentRollRecordAdjustedLeaseEndCreateNestedOneWithoutRentRollRecordInput = {
  connect?: Maybe<SimplifiedRevenueRentRollRecordAdjustedLeaseEndWhereUniqueInput>;
  connectOrCreate?: Maybe<SimplifiedRevenueRentRollRecordAdjustedLeaseEndCreateOrConnectWithoutRentRollRecordInput>;
  create?: Maybe<SimplifiedRevenueRentRollRecordAdjustedLeaseEndCreateWithoutRentRollRecordInput>;
};

export type SimplifiedRevenueRentRollRecordAdjustedLeaseEndCreateOrConnectWithoutRentRollRecordInput = {
  create: SimplifiedRevenueRentRollRecordAdjustedLeaseEndCreateWithoutRentRollRecordInput;
  where: SimplifiedRevenueRentRollRecordAdjustedLeaseEndWhereUniqueInput;
};

export type SimplifiedRevenueRentRollRecordAdjustedLeaseEndCreateWithoutRentRollRecordInput = {
  budgetYear: Scalars['Int'];
  leaseEndDate: Scalars['DateTime'];
};

export type SimplifiedRevenueRentRollRecordAdjustedLeaseEndRelationFilter = {
  is?: Maybe<SimplifiedRevenueRentRollRecordAdjustedLeaseEndWhereInput>;
  isNot?: Maybe<SimplifiedRevenueRentRollRecordAdjustedLeaseEndWhereInput>;
};

export type SimplifiedRevenueRentRollRecordAdjustedLeaseEndUpdateOneWithoutRentRollRecordInput = {
  connect?: Maybe<SimplifiedRevenueRentRollRecordAdjustedLeaseEndWhereUniqueInput>;
  connectOrCreate?: Maybe<SimplifiedRevenueRentRollRecordAdjustedLeaseEndCreateOrConnectWithoutRentRollRecordInput>;
  create?: Maybe<SimplifiedRevenueRentRollRecordAdjustedLeaseEndCreateWithoutRentRollRecordInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<SimplifiedRevenueRentRollRecordAdjustedLeaseEndUpdateWithoutRentRollRecordInput>;
  upsert?: Maybe<SimplifiedRevenueRentRollRecordAdjustedLeaseEndUpsertWithoutRentRollRecordInput>;
};

export type SimplifiedRevenueRentRollRecordAdjustedLeaseEndUpdateWithoutRentRollRecordInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  leaseEndDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type SimplifiedRevenueRentRollRecordAdjustedLeaseEndUpsertWithoutRentRollRecordInput = {
  create: SimplifiedRevenueRentRollRecordAdjustedLeaseEndCreateWithoutRentRollRecordInput;
  update: SimplifiedRevenueRentRollRecordAdjustedLeaseEndUpdateWithoutRentRollRecordInput;
};

export type SimplifiedRevenueRentRollRecordAdjustedLeaseEndWhereInput = {
  AND?: Maybe<Array<SimplifiedRevenueRentRollRecordAdjustedLeaseEndWhereInput>>;
  NOT?: Maybe<Array<SimplifiedRevenueRentRollRecordAdjustedLeaseEndWhereInput>>;
  OR?: Maybe<Array<SimplifiedRevenueRentRollRecordAdjustedLeaseEndWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  leaseEndDate?: Maybe<DateTimeFilter>;
  rentRollRecord?: Maybe<SimplifiedRevenueRentRollRecordRelationFilter>;
  rentRollRecordId?: Maybe<StringFilter>;
};

export type SimplifiedRevenueRentRollRecordAdjustedLeaseEndWhereUniqueInput = {
  rentRollRecordId?: Maybe<Scalars['String']>;
};

export type SimplifiedRevenueRentRollRecordCreateManyRentRollInput = {
  budgetedRent: Scalars['Decimal'];
  expectedMoveOutDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  leaseEndDate?: Maybe<Scalars['DateTime']>;
  leaseStartDate?: Maybe<Scalars['DateTime']>;
  moveInDate?: Maybe<Scalars['DateTime']>;
  resident?: Maybe<Scalars['String']>;
  scheduledCharges?: Maybe<Scalars['Decimal']>;
  sqft: Scalars['Decimal'];
  unitName: Scalars['String'];
  unitTypeId: Scalars['String'];
};

export type SimplifiedRevenueRentRollRecordCreateManyRentRollInputEnvelope = {
  data: Array<SimplifiedRevenueRentRollRecordCreateManyRentRollInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SimplifiedRevenueRentRollRecordCreateNestedManyWithoutRentRollInput = {
  connect?: Maybe<Array<SimplifiedRevenueRentRollRecordWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SimplifiedRevenueRentRollRecordCreateOrConnectWithoutRentRollInput>>;
  create?: Maybe<Array<SimplifiedRevenueRentRollRecordCreateWithoutRentRollInput>>;
  createMany?: Maybe<SimplifiedRevenueRentRollRecordCreateManyRentRollInputEnvelope>;
};

export type SimplifiedRevenueRentRollRecordCreateOrConnectWithoutRentRollInput = {
  create: SimplifiedRevenueRentRollRecordCreateWithoutRentRollInput;
  where: SimplifiedRevenueRentRollRecordWhereUniqueInput;
};

export type SimplifiedRevenueRentRollRecordCreateWithoutRentRollInput = {
  adjustedLeaseEnd?: Maybe<SimplifiedRevenueRentRollRecordAdjustedLeaseEndCreateNestedOneWithoutRentRollRecordInput>;
  budgetedRent: Scalars['Decimal'];
  expectedMoveOutDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  leaseEndDate?: Maybe<Scalars['DateTime']>;
  leaseStartDate?: Maybe<Scalars['DateTime']>;
  moveInDate?: Maybe<Scalars['DateTime']>;
  resident?: Maybe<Scalars['String']>;
  scheduledCharges?: Maybe<Scalars['Decimal']>;
  sqft: Scalars['Decimal'];
  unitName: Scalars['String'];
  unitTypeId: Scalars['String'];
};

export type SimplifiedRevenueRentRollRecordListRelationFilter = {
  every?: Maybe<SimplifiedRevenueRentRollRecordWhereInput>;
  none?: Maybe<SimplifiedRevenueRentRollRecordWhereInput>;
  some?: Maybe<SimplifiedRevenueRentRollRecordWhereInput>;
};

export type SimplifiedRevenueRentRollRecordRelationFilter = {
  is?: Maybe<SimplifiedRevenueRentRollRecordWhereInput>;
  isNot?: Maybe<SimplifiedRevenueRentRollRecordWhereInput>;
};

export type SimplifiedRevenueRentRollRecordRentRollIdUnitNameCompoundUniqueInput = {
  rentRollId: Scalars['String'];
  unitName: Scalars['String'];
};

export type SimplifiedRevenueRentRollRecordScalarWhereInput = {
  AND?: Maybe<Array<SimplifiedRevenueRentRollRecordScalarWhereInput>>;
  NOT?: Maybe<Array<SimplifiedRevenueRentRollRecordScalarWhereInput>>;
  OR?: Maybe<Array<SimplifiedRevenueRentRollRecordScalarWhereInput>>;
  budgetedRent?: Maybe<DecimalFilter>;
  expectedMoveOutDate?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  leaseEndDate?: Maybe<DateTimeNullableFilter>;
  leaseStartDate?: Maybe<DateTimeNullableFilter>;
  moveInDate?: Maybe<DateTimeNullableFilter>;
  rentRollId?: Maybe<StringFilter>;
  resident?: Maybe<StringFilter>;
  scheduledCharges?: Maybe<DecimalNullableFilter>;
  sqft?: Maybe<DecimalFilter>;
  unitName?: Maybe<StringFilter>;
  unitTypeId?: Maybe<StringFilter>;
};

export type SimplifiedRevenueRentRollRecordUpdateManyMutationInput = {
  budgetedRent?: Maybe<DecimalFieldUpdateOperationsInput>;
  expectedMoveOutDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  leaseEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  leaseStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  moveInDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  resident?: Maybe<StringFieldUpdateOperationsInput>;
  scheduledCharges?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  sqft?: Maybe<DecimalFieldUpdateOperationsInput>;
  unitName?: Maybe<StringFieldUpdateOperationsInput>;
  unitTypeId?: Maybe<StringFieldUpdateOperationsInput>;
};

export type SimplifiedRevenueRentRollRecordUpdateManyWithWhereWithoutRentRollInput = {
  data: SimplifiedRevenueRentRollRecordUpdateManyMutationInput;
  where: SimplifiedRevenueRentRollRecordScalarWhereInput;
};

export type SimplifiedRevenueRentRollRecordUpdateManyWithoutRentRollInput = {
  connect?: Maybe<Array<SimplifiedRevenueRentRollRecordWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SimplifiedRevenueRentRollRecordCreateOrConnectWithoutRentRollInput>>;
  create?: Maybe<Array<SimplifiedRevenueRentRollRecordCreateWithoutRentRollInput>>;
  createMany?: Maybe<SimplifiedRevenueRentRollRecordCreateManyRentRollInputEnvelope>;
  delete?: Maybe<Array<SimplifiedRevenueRentRollRecordWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SimplifiedRevenueRentRollRecordScalarWhereInput>>;
  disconnect?: Maybe<Array<SimplifiedRevenueRentRollRecordWhereUniqueInput>>;
  set?: Maybe<Array<SimplifiedRevenueRentRollRecordWhereUniqueInput>>;
  update?: Maybe<Array<SimplifiedRevenueRentRollRecordUpdateWithWhereUniqueWithoutRentRollInput>>;
  updateMany?: Maybe<Array<SimplifiedRevenueRentRollRecordUpdateManyWithWhereWithoutRentRollInput>>;
  upsert?: Maybe<Array<SimplifiedRevenueRentRollRecordUpsertWithWhereUniqueWithoutRentRollInput>>;
};

export type SimplifiedRevenueRentRollRecordUpdateWithWhereUniqueWithoutRentRollInput = {
  data: SimplifiedRevenueRentRollRecordUpdateWithoutRentRollInput;
  where: SimplifiedRevenueRentRollRecordWhereUniqueInput;
};

export type SimplifiedRevenueRentRollRecordUpdateWithoutRentRollInput = {
  adjustedLeaseEnd?: Maybe<SimplifiedRevenueRentRollRecordAdjustedLeaseEndUpdateOneWithoutRentRollRecordInput>;
  budgetedRent?: Maybe<DecimalFieldUpdateOperationsInput>;
  expectedMoveOutDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  leaseEndDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  leaseStartDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  moveInDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  resident?: Maybe<StringFieldUpdateOperationsInput>;
  scheduledCharges?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  sqft?: Maybe<DecimalFieldUpdateOperationsInput>;
  unitName?: Maybe<StringFieldUpdateOperationsInput>;
  unitTypeId?: Maybe<StringFieldUpdateOperationsInput>;
};

export type SimplifiedRevenueRentRollRecordUpsertWithWhereUniqueWithoutRentRollInput = {
  create: SimplifiedRevenueRentRollRecordCreateWithoutRentRollInput;
  update: SimplifiedRevenueRentRollRecordUpdateWithoutRentRollInput;
  where: SimplifiedRevenueRentRollRecordWhereUniqueInput;
};

export type SimplifiedRevenueRentRollRecordWhereInput = {
  AND?: Maybe<Array<SimplifiedRevenueRentRollRecordWhereInput>>;
  NOT?: Maybe<Array<SimplifiedRevenueRentRollRecordWhereInput>>;
  OR?: Maybe<Array<SimplifiedRevenueRentRollRecordWhereInput>>;
  adjustedLeaseEnd?: Maybe<SimplifiedRevenueRentRollRecordAdjustedLeaseEndRelationFilter>;
  budgetedRent?: Maybe<DecimalFilter>;
  expectedMoveOutDate?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  leaseEndDate?: Maybe<DateTimeNullableFilter>;
  leaseStartDate?: Maybe<DateTimeNullableFilter>;
  moveInDate?: Maybe<DateTimeNullableFilter>;
  rentRoll?: Maybe<SimplifiedRevenueRentRollRelationFilter>;
  rentRollId?: Maybe<StringFilter>;
  resident?: Maybe<StringFilter>;
  scheduledCharges?: Maybe<DecimalNullableFilter>;
  sqft?: Maybe<DecimalFilter>;
  unitName?: Maybe<StringFilter>;
  unitTypeId?: Maybe<StringFilter>;
};

export type SimplifiedRevenueRentRollRecordWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  rentRollId_unitName?: Maybe<SimplifiedRevenueRentRollRecordRentRollIdUnitNameCompoundUniqueInput>;
};

export type SimplifiedRevenueRentRollRelationFilter = {
  is?: Maybe<SimplifiedRevenueRentRollWhereInput>;
  isNot?: Maybe<SimplifiedRevenueRentRollWhereInput>;
};

export enum SimplifiedRevenueRentRollScalarFieldEnum {
  Id = 'id',
  IsActive = 'isActive',
  LoadedDate = 'loadedDate',
  PropertyId = 'propertyId'
}

export type SimplifiedRevenueRentRollScalarWhereInput = {
  AND?: Maybe<Array<SimplifiedRevenueRentRollScalarWhereInput>>;
  NOT?: Maybe<Array<SimplifiedRevenueRentRollScalarWhereInput>>;
  OR?: Maybe<Array<SimplifiedRevenueRentRollScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  loadedDate?: Maybe<DateTimeFilter>;
  propertyId?: Maybe<StringFilter>;
};

export type SimplifiedRevenueRentRollUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  loadedDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type SimplifiedRevenueRentRollUpdateManyWithWhereWithoutPropertyInput = {
  data: SimplifiedRevenueRentRollUpdateManyMutationInput;
  where: SimplifiedRevenueRentRollScalarWhereInput;
};

export type SimplifiedRevenueRentRollUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<SimplifiedRevenueRentRollWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SimplifiedRevenueRentRollCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<SimplifiedRevenueRentRollCreateWithoutPropertyInput>>;
  createMany?: Maybe<SimplifiedRevenueRentRollCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<SimplifiedRevenueRentRollWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SimplifiedRevenueRentRollScalarWhereInput>>;
  disconnect?: Maybe<Array<SimplifiedRevenueRentRollWhereUniqueInput>>;
  set?: Maybe<Array<SimplifiedRevenueRentRollWhereUniqueInput>>;
  update?: Maybe<Array<SimplifiedRevenueRentRollUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<SimplifiedRevenueRentRollUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<SimplifiedRevenueRentRollUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type SimplifiedRevenueRentRollUpdateWithWhereUniqueWithoutPropertyInput = {
  data: SimplifiedRevenueRentRollUpdateWithoutPropertyInput;
  where: SimplifiedRevenueRentRollWhereUniqueInput;
};

export type SimplifiedRevenueRentRollUpdateWithoutPropertyInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isActive?: Maybe<BoolFieldUpdateOperationsInput>;
  loadedDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  rentRollRecords?: Maybe<SimplifiedRevenueRentRollRecordUpdateManyWithoutRentRollInput>;
};

export type SimplifiedRevenueRentRollUpsertWithWhereUniqueWithoutPropertyInput = {
  create: SimplifiedRevenueRentRollCreateWithoutPropertyInput;
  update: SimplifiedRevenueRentRollUpdateWithoutPropertyInput;
  where: SimplifiedRevenueRentRollWhereUniqueInput;
};

export type SimplifiedRevenueRentRollWhereInput = {
  AND?: Maybe<Array<SimplifiedRevenueRentRollWhereInput>>;
  NOT?: Maybe<Array<SimplifiedRevenueRentRollWhereInput>>;
  OR?: Maybe<Array<SimplifiedRevenueRentRollWhereInput>>;
  id?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  loadedDate?: Maybe<DateTimeFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  rentRollRecords?: Maybe<SimplifiedRevenueRentRollRecordListRelationFilter>;
};

export type SimplifiedRevenueRentRollWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SimplifiedRevenueSetRenovationMappingInput = {
  fakeUnitTypeName?: Maybe<Scalars['String']>;
  fakeUnitTypeStartingInPlaceRent?: Maybe<Scalars['Float']>;
  renovatedUnitTypeId?: Maybe<Scalars['ID']>;
  renovationPackageId: Scalars['ID'];
};

export type SimplifiedRevenueSetUnitTypeStartingMarketRentOverrideInput = {
  overrideStartingMarketRent?: Maybe<Scalars['Decimal']>;
  unitTypeId: Scalars['ID'];
};

export type SimplifiedRevenueStartingMarketRentOverrideCreateManyUnitTypeInput = {
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  overrideValue: Scalars['Decimal'];
};

export type SimplifiedRevenueStartingMarketRentOverrideCreateManyUnitTypeInputEnvelope = {
  data: Array<SimplifiedRevenueStartingMarketRentOverrideCreateManyUnitTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SimplifiedRevenueStartingMarketRentOverrideCreateNestedManyWithoutUnitTypeInput = {
  connect?: Maybe<Array<SimplifiedRevenueStartingMarketRentOverrideWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SimplifiedRevenueStartingMarketRentOverrideCreateOrConnectWithoutUnitTypeInput>>;
  create?: Maybe<Array<SimplifiedRevenueStartingMarketRentOverrideCreateWithoutUnitTypeInput>>;
  createMany?: Maybe<SimplifiedRevenueStartingMarketRentOverrideCreateManyUnitTypeInputEnvelope>;
};

export type SimplifiedRevenueStartingMarketRentOverrideCreateOrConnectWithoutUnitTypeInput = {
  create: SimplifiedRevenueStartingMarketRentOverrideCreateWithoutUnitTypeInput;
  where: SimplifiedRevenueStartingMarketRentOverrideWhereUniqueInput;
};

export type SimplifiedRevenueStartingMarketRentOverrideCreateWithoutUnitTypeInput = {
  budgetYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  overrideValue: Scalars['Decimal'];
};

export type SimplifiedRevenueStartingMarketRentOverrideListRelationFilter = {
  every?: Maybe<SimplifiedRevenueStartingMarketRentOverrideWhereInput>;
  none?: Maybe<SimplifiedRevenueStartingMarketRentOverrideWhereInput>;
  some?: Maybe<SimplifiedRevenueStartingMarketRentOverrideWhereInput>;
};

export type SimplifiedRevenueStartingMarketRentOverrideScalarWhereInput = {
  AND?: Maybe<Array<SimplifiedRevenueStartingMarketRentOverrideScalarWhereInput>>;
  NOT?: Maybe<Array<SimplifiedRevenueStartingMarketRentOverrideScalarWhereInput>>;
  OR?: Maybe<Array<SimplifiedRevenueStartingMarketRentOverrideScalarWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  overrideValue?: Maybe<DecimalFilter>;
  unitTypeId?: Maybe<StringFilter>;
};

export type SimplifiedRevenueStartingMarketRentOverrideUnitTypeIdBudgetYearCompoundUniqueInput = {
  budgetYear: Scalars['Int'];
  unitTypeId: Scalars['String'];
};

export type SimplifiedRevenueStartingMarketRentOverrideUpdateManyMutationInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  overrideValue?: Maybe<DecimalFieldUpdateOperationsInput>;
};

export type SimplifiedRevenueStartingMarketRentOverrideUpdateManyWithWhereWithoutUnitTypeInput = {
  data: SimplifiedRevenueStartingMarketRentOverrideUpdateManyMutationInput;
  where: SimplifiedRevenueStartingMarketRentOverrideScalarWhereInput;
};

export type SimplifiedRevenueStartingMarketRentOverrideUpdateManyWithoutUnitTypeInput = {
  connect?: Maybe<Array<SimplifiedRevenueStartingMarketRentOverrideWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SimplifiedRevenueStartingMarketRentOverrideCreateOrConnectWithoutUnitTypeInput>>;
  create?: Maybe<Array<SimplifiedRevenueStartingMarketRentOverrideCreateWithoutUnitTypeInput>>;
  createMany?: Maybe<SimplifiedRevenueStartingMarketRentOverrideCreateManyUnitTypeInputEnvelope>;
  delete?: Maybe<Array<SimplifiedRevenueStartingMarketRentOverrideWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SimplifiedRevenueStartingMarketRentOverrideScalarWhereInput>>;
  disconnect?: Maybe<Array<SimplifiedRevenueStartingMarketRentOverrideWhereUniqueInput>>;
  set?: Maybe<Array<SimplifiedRevenueStartingMarketRentOverrideWhereUniqueInput>>;
  update?: Maybe<Array<SimplifiedRevenueStartingMarketRentOverrideUpdateWithWhereUniqueWithoutUnitTypeInput>>;
  updateMany?: Maybe<Array<SimplifiedRevenueStartingMarketRentOverrideUpdateManyWithWhereWithoutUnitTypeInput>>;
  upsert?: Maybe<Array<SimplifiedRevenueStartingMarketRentOverrideUpsertWithWhereUniqueWithoutUnitTypeInput>>;
};

export type SimplifiedRevenueStartingMarketRentOverrideUpdateWithWhereUniqueWithoutUnitTypeInput = {
  data: SimplifiedRevenueStartingMarketRentOverrideUpdateWithoutUnitTypeInput;
  where: SimplifiedRevenueStartingMarketRentOverrideWhereUniqueInput;
};

export type SimplifiedRevenueStartingMarketRentOverrideUpdateWithoutUnitTypeInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  overrideValue?: Maybe<DecimalFieldUpdateOperationsInput>;
};

export type SimplifiedRevenueStartingMarketRentOverrideUpsertWithWhereUniqueWithoutUnitTypeInput = {
  create: SimplifiedRevenueStartingMarketRentOverrideCreateWithoutUnitTypeInput;
  update: SimplifiedRevenueStartingMarketRentOverrideUpdateWithoutUnitTypeInput;
  where: SimplifiedRevenueStartingMarketRentOverrideWhereUniqueInput;
};

export type SimplifiedRevenueStartingMarketRentOverrideWhereInput = {
  AND?: Maybe<Array<SimplifiedRevenueStartingMarketRentOverrideWhereInput>>;
  NOT?: Maybe<Array<SimplifiedRevenueStartingMarketRentOverrideWhereInput>>;
  OR?: Maybe<Array<SimplifiedRevenueStartingMarketRentOverrideWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  overrideValue?: Maybe<DecimalFilter>;
  unitType?: Maybe<UnitTypeRelationFilter>;
  unitTypeId?: Maybe<StringFilter>;
};

export type SimplifiedRevenueStartingMarketRentOverrideWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  unitTypeId_budgetYear?: Maybe<SimplifiedRevenueStartingMarketRentOverrideUnitTypeIdBudgetYearCompoundUniqueInput>;
};

export type SimplifiedRevenueUnitTypeOccupancySummaryModel = {
  __typename?: 'SimplifiedRevenueUnitTypeOccupancySummaryModel';
  moveIns: Array<SimplifiedRevenueUnitTypeSummaryModel>;
  moveOuts: Array<SimplifiedRevenueUnitTypeSummaryModel>;
  occupancyPercentage: Array<SimplifiedRevenueUnitTypeSummaryModel>;
  occupiedUnitCount: Array<SimplifiedRevenueUnitTypeSummaryModel>;
  vacancyPercentage: Array<SimplifiedRevenueUnitTypeSummaryModel>;
  vacantCount: Array<SimplifiedRevenueUnitTypeSummaryModel>;
};

export type SimplifiedRevenueUnitTypeRenewalsSummaryModel = {
  __typename?: 'SimplifiedRevenueUnitTypeRenewalsSummaryModel';
  expirationCount: Array<SimplifiedRevenueUnitTypeSummaryModel>;
  expirationPercentage: Array<SimplifiedRevenueUnitTypeSummaryModel>;
  renewalCount: Array<SimplifiedRevenueUnitTypeSummaryModel>;
  renewalPercentage: Array<SimplifiedRevenueUnitTypeSummaryModel>;
};

export type SimplifiedRevenueUnitTypeRenovationsSummaryModel = {
  __typename?: 'SimplifiedRevenueUnitTypeRenovationsSummaryModel';
  renovatedExpirationCount: Array<SimplifiedRevenueUnitTypeSummaryModel>;
  renovatedRenewalRatio: Array<SimplifiedRevenueUnitTypeSummaryModel>;
  renovatedUnitCount: Array<SimplifiedRevenueUnitTypeSummaryModel>;
  renovationCount: Array<SimplifiedRevenueUnitTypeSummaryModel>;
  unrenovatedExpirationCount: Array<SimplifiedRevenueUnitTypeSummaryModel>;
  unrenovatedRenewalRatio: Array<SimplifiedRevenueUnitTypeSummaryModel>;
  unrenovatedUnitCount: Array<SimplifiedRevenueUnitTypeSummaryModel>;
};

export type SimplifiedRevenueUnitTypeStartingMarketRentModel = {
  __typename?: 'SimplifiedRevenueUnitTypeStartingMarketRentModel';
  overrideStartingMarketRent?: Maybe<Scalars['Decimal']>;
  rentRollStartingMarketRent: Scalars['Decimal'];
  unitTypeId: Scalars['ID'];
  unitTypeName: Scalars['String'];
};

export type SimplifiedRevenueUnitTypeSummaryModel = {
  __typename?: 'SimplifiedRevenueUnitTypeSummaryModel';
  actuals: Array<Maybe<Scalars['Float']>>;
  budget: Array<Maybe<Scalars['Float']>>;
  reforecast: Array<Maybe<Scalars['Float']>>;
  unitTypeId: Scalars['ID'];
  unitTypeName: Scalars['String'];
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMapping = {
  __typename?: 'SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMapping';
  budgetYear: Scalars['Int'];
  fakeRenovatedUnitTypeName?: Maybe<Scalars['String']>;
  fakeRenovatedUnitTypeStartingInPlaceRent?: Maybe<Scalars['Decimal']>;
  fakeRenovatedUnitTypeStartingUnitCount?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  propertyId: Scalars['String'];
  renovatedUnitTypeId?: Maybe<Scalars['String']>;
  renovationPackageId: Scalars['String'];
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateManyPropertyInput = {
  budgetYear?: Maybe<Scalars['Int']>;
  fakeRenovatedUnitTypeName?: Maybe<Scalars['String']>;
  fakeRenovatedUnitTypeStartingInPlaceRent?: Maybe<Scalars['Decimal']>;
  fakeRenovatedUnitTypeStartingUnitCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  renovatedUnitTypeId?: Maybe<Scalars['String']>;
  renovationPackageId: Scalars['String'];
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateManyPropertyInputEnvelope = {
  data: Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateManyRenovatedUnitTypeInput = {
  budgetYear?: Maybe<Scalars['Int']>;
  fakeRenovatedUnitTypeName?: Maybe<Scalars['String']>;
  fakeRenovatedUnitTypeStartingInPlaceRent?: Maybe<Scalars['Decimal']>;
  fakeRenovatedUnitTypeStartingUnitCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  renovationPackageId: Scalars['String'];
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateManyRenovatedUnitTypeInputEnvelope = {
  data: Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateManyRenovatedUnitTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateManyRenovationPackageInput = {
  budgetYear?: Maybe<Scalars['Int']>;
  fakeRenovatedUnitTypeName?: Maybe<Scalars['String']>;
  fakeRenovatedUnitTypeStartingInPlaceRent?: Maybe<Scalars['Decimal']>;
  fakeRenovatedUnitTypeStartingUnitCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  renovatedUnitTypeId?: Maybe<Scalars['String']>;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateManyRenovationPackageInputEnvelope = {
  data: Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateManyRenovationPackageInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateWithoutPropertyInput>>;
  createMany?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateManyPropertyInputEnvelope>;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutRenovatedUnitTypeInput = {
  connect?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateOrConnectWithoutRenovatedUnitTypeInput>>;
  create?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateWithoutRenovatedUnitTypeInput>>;
  createMany?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateManyRenovatedUnitTypeInputEnvelope>;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutRenovationPackageInput = {
  connect?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateOrConnectWithoutRenovationPackageInput>>;
  create?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateWithoutRenovationPackageInput>>;
  createMany?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateManyRenovationPackageInputEnvelope>;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateOrConnectWithoutPropertyInput = {
  create: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateWithoutPropertyInput;
  where: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateOrConnectWithoutRenovatedUnitTypeInput = {
  create: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateWithoutRenovatedUnitTypeInput;
  where: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateOrConnectWithoutRenovationPackageInput = {
  create: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateWithoutRenovationPackageInput;
  where: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateWithoutPropertyInput = {
  budgetYear?: Maybe<Scalars['Int']>;
  fakeRenovatedUnitTypeName?: Maybe<Scalars['String']>;
  fakeRenovatedUnitTypeStartingInPlaceRent?: Maybe<Scalars['Decimal']>;
  fakeRenovatedUnitTypeStartingUnitCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  renovatedUnitType?: Maybe<UnitTypeCreateNestedOneWithoutUnrenovatedUnitTypeMappingInput>;
  renovationPackage: RenovationPackageCreateNestedOneWithoutUnitTypeMappingInput;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateWithoutRenovatedUnitTypeInput = {
  budgetYear?: Maybe<Scalars['Int']>;
  fakeRenovatedUnitTypeName?: Maybe<Scalars['String']>;
  fakeRenovatedUnitTypeStartingInPlaceRent?: Maybe<Scalars['Decimal']>;
  fakeRenovatedUnitTypeStartingUnitCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  property: PropertyCreateNestedOneWithoutSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingsInput;
  renovationPackage: RenovationPackageCreateNestedOneWithoutUnitTypeMappingInput;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateWithoutRenovationPackageInput = {
  budgetYear?: Maybe<Scalars['Int']>;
  fakeRenovatedUnitTypeName?: Maybe<Scalars['String']>;
  fakeRenovatedUnitTypeStartingInPlaceRent?: Maybe<Scalars['Decimal']>;
  fakeRenovatedUnitTypeStartingUnitCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  property: PropertyCreateNestedOneWithoutSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingsInput;
  renovatedUnitType?: Maybe<UnitTypeCreateNestedOneWithoutUnrenovatedUnitTypeMappingInput>;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingListRelationFilter = {
  every?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereInput>;
  none?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereInput>;
  some?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereInput>;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingOrderByInput = {
  budgetYear?: Maybe<SortOrder>;
  fakeRenovatedUnitTypeName?: Maybe<SortOrder>;
  fakeRenovatedUnitTypeStartingInPlaceRent?: Maybe<SortOrder>;
  fakeRenovatedUnitTypeStartingUnitCount?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  renovatedUnitTypeId?: Maybe<SortOrder>;
  renovationPackageId?: Maybe<SortOrder>;
};

export enum SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingScalarFieldEnum {
  BudgetYear = 'budgetYear',
  FakeRenovatedUnitTypeName = 'fakeRenovatedUnitTypeName',
  FakeRenovatedUnitTypeStartingInPlaceRent = 'fakeRenovatedUnitTypeStartingInPlaceRent',
  FakeRenovatedUnitTypeStartingUnitCount = 'fakeRenovatedUnitTypeStartingUnitCount',
  Id = 'id',
  PropertyId = 'propertyId',
  RenovatedUnitTypeId = 'renovatedUnitTypeId',
  RenovationPackageId = 'renovationPackageId'
}

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingScalarWhereInput = {
  AND?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingScalarWhereInput>>;
  NOT?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingScalarWhereInput>>;
  OR?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingScalarWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  fakeRenovatedUnitTypeName?: Maybe<StringNullableFilter>;
  fakeRenovatedUnitTypeStartingInPlaceRent?: Maybe<DecimalNullableFilter>;
  fakeRenovatedUnitTypeStartingUnitCount?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  renovatedUnitTypeId?: Maybe<StringNullableFilter>;
  renovationPackageId?: Maybe<StringFilter>;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMapping_FakeCompoundUniqueInput = {
  budgetYear: Scalars['Int'];
  fakeRenovatedUnitTypeName: Scalars['String'];
  propertyId: Scalars['String'];
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMapping_PackageCompoundUniqueInput = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['String'];
  renovationPackageId: Scalars['String'];
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMapping_UnitTypeCompoundUniqueInput = {
  budgetYear: Scalars['Int'];
  propertyId: Scalars['String'];
  renovatedUnitTypeId: Scalars['String'];
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyMutationInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  fakeRenovatedUnitTypeName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fakeRenovatedUnitTypeStartingInPlaceRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  fakeRenovatedUnitTypeStartingUnitCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithWhereWithoutPropertyInput = {
  data: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyMutationInput;
  where: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingScalarWhereInput;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithWhereWithoutRenovatedUnitTypeInput = {
  data: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyMutationInput;
  where: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingScalarWhereInput;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithWhereWithoutRenovationPackageInput = {
  data: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyMutationInput;
  where: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingScalarWhereInput;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateWithoutPropertyInput>>;
  createMany?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingScalarWhereInput>>;
  disconnect?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput>>;
  set?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput>>;
  update?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutRenovatedUnitTypeInput = {
  connect?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateOrConnectWithoutRenovatedUnitTypeInput>>;
  create?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateWithoutRenovatedUnitTypeInput>>;
  createMany?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateManyRenovatedUnitTypeInputEnvelope>;
  delete?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingScalarWhereInput>>;
  disconnect?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput>>;
  set?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput>>;
  update?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateWithWhereUniqueWithoutRenovatedUnitTypeInput>>;
  updateMany?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithWhereWithoutRenovatedUnitTypeInput>>;
  upsert?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpsertWithWhereUniqueWithoutRenovatedUnitTypeInput>>;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutRenovationPackageInput = {
  connect?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateOrConnectWithoutRenovationPackageInput>>;
  create?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateWithoutRenovationPackageInput>>;
  createMany?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateManyRenovationPackageInputEnvelope>;
  delete?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingScalarWhereInput>>;
  disconnect?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput>>;
  set?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput>>;
  update?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateWithWhereUniqueWithoutRenovationPackageInput>>;
  updateMany?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithWhereWithoutRenovationPackageInput>>;
  upsert?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpsertWithWhereUniqueWithoutRenovationPackageInput>>;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateWithWhereUniqueWithoutPropertyInput = {
  data: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateWithoutPropertyInput;
  where: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateWithWhereUniqueWithoutRenovatedUnitTypeInput = {
  data: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateWithoutRenovatedUnitTypeInput;
  where: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateWithWhereUniqueWithoutRenovationPackageInput = {
  data: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateWithoutRenovationPackageInput;
  where: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateWithoutPropertyInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  fakeRenovatedUnitTypeName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fakeRenovatedUnitTypeStartingInPlaceRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  fakeRenovatedUnitTypeStartingUnitCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  renovatedUnitType?: Maybe<UnitTypeUpdateOneWithoutUnrenovatedUnitTypeMappingInput>;
  renovationPackage?: Maybe<RenovationPackageUpdateOneRequiredWithoutUnitTypeMappingInput>;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateWithoutRenovatedUnitTypeInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  fakeRenovatedUnitTypeName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fakeRenovatedUnitTypeStartingInPlaceRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  fakeRenovatedUnitTypeStartingUnitCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingsInput>;
  renovationPackage?: Maybe<RenovationPackageUpdateOneRequiredWithoutUnitTypeMappingInput>;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateWithoutRenovationPackageInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  fakeRenovatedUnitTypeName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  fakeRenovatedUnitTypeStartingInPlaceRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  fakeRenovatedUnitTypeStartingUnitCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingsInput>;
  renovatedUnitType?: Maybe<UnitTypeUpdateOneWithoutUnrenovatedUnitTypeMappingInput>;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpsertWithWhereUniqueWithoutPropertyInput = {
  create: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateWithoutPropertyInput;
  update: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateWithoutPropertyInput;
  where: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpsertWithWhereUniqueWithoutRenovatedUnitTypeInput = {
  create: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateWithoutRenovatedUnitTypeInput;
  update: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateWithoutRenovatedUnitTypeInput;
  where: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpsertWithWhereUniqueWithoutRenovationPackageInput = {
  create: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateWithoutRenovationPackageInput;
  update: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateWithoutRenovationPackageInput;
  where: SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereInput = {
  AND?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereInput>>;
  NOT?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereInput>>;
  OR?: Maybe<Array<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  fakeRenovatedUnitTypeName?: Maybe<StringNullableFilter>;
  fakeRenovatedUnitTypeStartingInPlaceRent?: Maybe<DecimalNullableFilter>;
  fakeRenovatedUnitTypeStartingUnitCount?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  renovatedUnitType?: Maybe<UnitTypeRelationFilter>;
  renovatedUnitTypeId?: Maybe<StringNullableFilter>;
  renovationPackage?: Maybe<RenovationPackageRelationFilter>;
  renovationPackageId?: Maybe<StringFilter>;
};

export type SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingWhereUniqueInput = {
  SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMapping_fake?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMapping_FakeCompoundUniqueInput>;
  SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMapping_package?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMapping_PackageCompoundUniqueInput>;
  SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMapping_unitType?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingSimplifiedRevenueUnrenovatedToRenovatedUnitTypeMapping_UnitTypeCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type SimplifiedRevenueVacancyPercentage = {
  __typename?: 'SimplifiedRevenueVacancyPercentage';
  /** The vacancy percentage expected for reforecast months. Values may be null when they don't exist for the months. When null they are treated as 0s for purposes of calculation. The array is always of length 12. */
  budget: Array<Maybe<Scalars['Float']>>;
  /** The vacancy percentage expected for reforecast months. Values may be null when they don't exist for the months. When null they are treated as 0s for purposes of calculation. The array is always of length 12. */
  reforecast: Array<Maybe<Scalars['Float']>>;
};

export type SimplifiedRevenueVacancyPercentageInput = {
  budgetMonthlyValues: Array<SimplifiedRevenueMonthlyNumberInput>;
  reforecastMonthlyValues: Array<SimplifiedRevenueMonthlyNumberInput>;
};

export type SortArgs = {
  specs: Array<SortSpec>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortSpec = {
  field: Scalars['String'];
  order: SortOrder;
};

export type SourceAccountAssumptionsModel = {
  __typename?: 'SourceAccountAssumptionsModel';
  accountId: Scalars['ID'];
  acctPercentageDriverAccountId: Scalars['String'];
  assumptionId: Scalars['String'];
  assumptions: Array<AccPercentageDriverAssumptionModel>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
};

export type SourceAccountBareModel = {
  __typename?: 'SourceAccountBareModel';
  accountId: Scalars['ID'];
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  lookbackPeriod: Scalars['Int'];
};

export type SourceAccountModel = {
  __typename?: 'SourceAccountModel';
  accountId: Scalars['ID'];
  acctPercentageDriverAccountId: Scalars['String'];
  assumptionId: Scalars['String'];
  assumptionsAndValues: Array<AccPercentageDriverAssumptionAndValueModel>;
  glName: Scalars['String'];
  glNumber?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
};

export type SourceMetricAssumptionsModel = {
  __typename?: 'SourceMetricAssumptionsModel';
  assumptions: Array<OperationalMetricDriverAssumptionModel>;
  driverMetricTypeId: Scalars['String'];
  locked?: Maybe<Scalars['Boolean']>;
  metricName: Scalars['String'];
  sourceMetricId: Scalars['String'];
};

export type SourceMetricModel = {
  __typename?: 'SourceMetricModel';
  assumptionsAndValues: Array<OperationalMetricDriverAssumptionAndValueModel>;
  driverMetricTypeId: Scalars['String'];
  locked?: Maybe<Scalars['Boolean']>;
  metricName: Scalars['String'];
  sourceMetricId: Scalars['String'];
};

export type SourceRevenueMetricModel = {
  __typename?: 'SourceRevenueMetricModel';
  revenueType: RevenueType;
  sourceId: Scalars['String'];
};

export type StarredAccount = {
  __typename?: 'StarredAccount';
  accountId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isStarred: Scalars['Boolean'];
  propertyId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  versionId: Scalars['String'];
};

export type StarredAccountCreateManyAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isStarred: Scalars['Boolean'];
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId: Scalars['String'];
};

export type StarredAccountCreateManyAccountInputEnvelope = {
  data: Array<StarredAccountCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StarredAccountCreateManyPropertyInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isStarred: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId: Scalars['String'];
};

export type StarredAccountCreateManyPropertyInputEnvelope = {
  data: Array<StarredAccountCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StarredAccountCreateManyVersionInput = {
  accountId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isStarred: Scalars['Boolean'];
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StarredAccountCreateManyVersionInputEnvelope = {
  data: Array<StarredAccountCreateManyVersionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StarredAccountCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<StarredAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StarredAccountCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<StarredAccountCreateWithoutAccountInput>>;
  createMany?: Maybe<StarredAccountCreateManyAccountInputEnvelope>;
};

export type StarredAccountCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<StarredAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StarredAccountCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<StarredAccountCreateWithoutPropertyInput>>;
  createMany?: Maybe<StarredAccountCreateManyPropertyInputEnvelope>;
};

export type StarredAccountCreateNestedManyWithoutVersionInput = {
  connect?: Maybe<Array<StarredAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StarredAccountCreateOrConnectWithoutVersionInput>>;
  create?: Maybe<Array<StarredAccountCreateWithoutVersionInput>>;
  createMany?: Maybe<StarredAccountCreateManyVersionInputEnvelope>;
};

export type StarredAccountCreateOrConnectWithoutAccountInput = {
  create: StarredAccountCreateWithoutAccountInput;
  where: StarredAccountWhereUniqueInput;
};

export type StarredAccountCreateOrConnectWithoutPropertyInput = {
  create: StarredAccountCreateWithoutPropertyInput;
  where: StarredAccountWhereUniqueInput;
};

export type StarredAccountCreateOrConnectWithoutVersionInput = {
  create: StarredAccountCreateWithoutVersionInput;
  where: StarredAccountWhereUniqueInput;
};

export type StarredAccountCreateWithoutAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isStarred: Scalars['Boolean'];
  property: PropertyCreateNestedOneWithoutStarredAccountsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version: ValueVersionCreateNestedOneWithoutStarredAccountsInput;
};

export type StarredAccountCreateWithoutPropertyInput = {
  account: AccountV2CreateNestedOneWithoutStarredAccountsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isStarred: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  version: ValueVersionCreateNestedOneWithoutStarredAccountsInput;
};

export type StarredAccountCreateWithoutVersionInput = {
  account: AccountV2CreateNestedOneWithoutStarredAccountsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isStarred: Scalars['Boolean'];
  property: PropertyCreateNestedOneWithoutStarredAccountsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StarredAccountListRelationFilter = {
  every?: Maybe<StarredAccountWhereInput>;
  none?: Maybe<StarredAccountWhereInput>;
  some?: Maybe<StarredAccountWhereInput>;
};

export type StarredAccountModel = {
  __typename?: 'StarredAccountModel';
  accountId: Scalars['ID'];
  id: Scalars['ID'];
  isStarred: Scalars['Boolean'];
  propertyId: Scalars['ID'];
  version: StarredAccountVersionModel;
};

export type StarredAccountMutationArgs = {
  accountId: Scalars['ID'];
  isStarred: Scalars['Boolean'];
  propertyId: Scalars['ID'];
  versionId: Scalars['ID'];
};

export type StarredAccountOrderByInput = {
  accountId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isStarred?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  versionId?: Maybe<SortOrder>;
};

export type StarredAccountPropertyIdAccountIdVersionIdCompoundUniqueInput = {
  accountId: Scalars['String'];
  propertyId: Scalars['String'];
  versionId: Scalars['String'];
};

export enum StarredAccountScalarFieldEnum {
  AccountId = 'accountId',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsStarred = 'isStarred',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt',
  VersionId = 'versionId'
}

export type StarredAccountScalarWhereInput = {
  AND?: Maybe<Array<StarredAccountScalarWhereInput>>;
  NOT?: Maybe<Array<StarredAccountScalarWhereInput>>;
  OR?: Maybe<Array<StarredAccountScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isStarred?: Maybe<BoolFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionId?: Maybe<StringFilter>;
};

export type StarredAccountUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isStarred?: Maybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type StarredAccountUpdateManyWithWhereWithoutAccountInput = {
  data: StarredAccountUpdateManyMutationInput;
  where: StarredAccountScalarWhereInput;
};

export type StarredAccountUpdateManyWithWhereWithoutPropertyInput = {
  data: StarredAccountUpdateManyMutationInput;
  where: StarredAccountScalarWhereInput;
};

export type StarredAccountUpdateManyWithWhereWithoutVersionInput = {
  data: StarredAccountUpdateManyMutationInput;
  where: StarredAccountScalarWhereInput;
};

export type StarredAccountUpdateManyWithoutAccountInput = {
  connect?: Maybe<Array<StarredAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StarredAccountCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<StarredAccountCreateWithoutAccountInput>>;
  createMany?: Maybe<StarredAccountCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<StarredAccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StarredAccountScalarWhereInput>>;
  disconnect?: Maybe<Array<StarredAccountWhereUniqueInput>>;
  set?: Maybe<Array<StarredAccountWhereUniqueInput>>;
  update?: Maybe<Array<StarredAccountUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<StarredAccountUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<StarredAccountUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type StarredAccountUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<StarredAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StarredAccountCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<StarredAccountCreateWithoutPropertyInput>>;
  createMany?: Maybe<StarredAccountCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<StarredAccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StarredAccountScalarWhereInput>>;
  disconnect?: Maybe<Array<StarredAccountWhereUniqueInput>>;
  set?: Maybe<Array<StarredAccountWhereUniqueInput>>;
  update?: Maybe<Array<StarredAccountUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<StarredAccountUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<StarredAccountUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type StarredAccountUpdateManyWithoutVersionInput = {
  connect?: Maybe<Array<StarredAccountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StarredAccountCreateOrConnectWithoutVersionInput>>;
  create?: Maybe<Array<StarredAccountCreateWithoutVersionInput>>;
  createMany?: Maybe<StarredAccountCreateManyVersionInputEnvelope>;
  delete?: Maybe<Array<StarredAccountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<StarredAccountScalarWhereInput>>;
  disconnect?: Maybe<Array<StarredAccountWhereUniqueInput>>;
  set?: Maybe<Array<StarredAccountWhereUniqueInput>>;
  update?: Maybe<Array<StarredAccountUpdateWithWhereUniqueWithoutVersionInput>>;
  updateMany?: Maybe<Array<StarredAccountUpdateManyWithWhereWithoutVersionInput>>;
  upsert?: Maybe<Array<StarredAccountUpsertWithWhereUniqueWithoutVersionInput>>;
};

export type StarredAccountUpdateWithWhereUniqueWithoutAccountInput = {
  data: StarredAccountUpdateWithoutAccountInput;
  where: StarredAccountWhereUniqueInput;
};

export type StarredAccountUpdateWithWhereUniqueWithoutPropertyInput = {
  data: StarredAccountUpdateWithoutPropertyInput;
  where: StarredAccountWhereUniqueInput;
};

export type StarredAccountUpdateWithWhereUniqueWithoutVersionInput = {
  data: StarredAccountUpdateWithoutVersionInput;
  where: StarredAccountWhereUniqueInput;
};

export type StarredAccountUpdateWithoutAccountInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isStarred?: Maybe<BoolFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutStarredAccountsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  version?: Maybe<ValueVersionUpdateOneRequiredWithoutStarredAccountsInput>;
};

export type StarredAccountUpdateWithoutPropertyInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutStarredAccountsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isStarred?: Maybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  version?: Maybe<ValueVersionUpdateOneRequiredWithoutStarredAccountsInput>;
};

export type StarredAccountUpdateWithoutVersionInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutStarredAccountsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isStarred?: Maybe<BoolFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutStarredAccountsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type StarredAccountUpsertWithWhereUniqueWithoutAccountInput = {
  create: StarredAccountCreateWithoutAccountInput;
  update: StarredAccountUpdateWithoutAccountInput;
  where: StarredAccountWhereUniqueInput;
};

export type StarredAccountUpsertWithWhereUniqueWithoutPropertyInput = {
  create: StarredAccountCreateWithoutPropertyInput;
  update: StarredAccountUpdateWithoutPropertyInput;
  where: StarredAccountWhereUniqueInput;
};

export type StarredAccountUpsertWithWhereUniqueWithoutVersionInput = {
  create: StarredAccountCreateWithoutVersionInput;
  update: StarredAccountUpdateWithoutVersionInput;
  where: StarredAccountWhereUniqueInput;
};

export type StarredAccountVersionModel = {
  __typename?: 'StarredAccountVersionModel';
  id: Scalars['ID'];
};

export type StarredAccountWhereInput = {
  AND?: Maybe<Array<StarredAccountWhereInput>>;
  NOT?: Maybe<Array<StarredAccountWhereInput>>;
  OR?: Maybe<Array<StarredAccountWhereInput>>;
  account?: Maybe<AccountV2RelationFilter>;
  accountId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isStarred?: Maybe<BoolFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  version?: Maybe<ValueVersionRelationFilter>;
  versionId?: Maybe<StringFilter>;
};

export type StarredAccountWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  propertyId_accountId_versionId?: Maybe<StarredAccountPropertyIdAccountIdVersionIdCompoundUniqueInput>;
};

export type StarringMetaArgInput = {
  type: Scalars['String'];
  versionId: Scalars['String'];
};

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type StringIdListResult = {
  __typename?: 'StringIdListResult';
  ids: Array<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type StringNullableListFilter = {
  equals?: Maybe<Array<Scalars['String']>>;
  has?: Maybe<Scalars['String']>;
  hasEvery?: Maybe<Array<Scalars['String']>>;
  hasSome?: Maybe<Array<Scalars['String']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: Maybe<NestedIntNullableFilter>;
  _max?: Maybe<NestedStringNullableFilter>;
  _min?: Maybe<NestedStringNullableFilter>;
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type StringWithAggregatesFilter = {
  _count?: Maybe<NestedIntFilter>;
  _max?: Maybe<NestedStringFilter>;
  _min?: Maybe<NestedStringFilter>;
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringWithAggregatesFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  newFinancialValue: FinancialValue;
};


export type SubscriptionNewFinancialValueArgs = {
  propertyIds?: Maybe<Array<Scalars['String']>>;
  versionIds?: Maybe<Array<Scalars['String']>>;
};

export type T12ReportRecordModel = {
  __typename?: 'T12ReportRecordModel';
  accountId: Scalars['ID'];
  annualizedNMonthsActualsTotal: Scalars['Float'];
  budgetValues: Array<Scalars['Float']>;
  glName: Scalars['String'];
  glNumber: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  twelveMonthsActualsTotal: Scalars['Float'];
  type: FinancialEntityType;
};

export type TForecastPeriodLocks = {
  __typename?: 'TForecastPeriodLocks';
  isBudgetLocked: Scalars['Boolean'];
  isReforecastLocked: Scalars['Boolean'];
};

export type TSetDriversResolverReturn = {
  __typename?: 'TSetDriversResolverReturn';
  versionType: VersionType;
  year: Scalars['Int'];
};

export type TSetPropertyDriversResolverReturn = {
  __typename?: 'TSetPropertyDriversResolverReturn';
  versionType: VersionType;
  year: Scalars['Int'];
};

export enum TopCardTagType {
  GrossPotentialRent = 'GROSS_POTENTIAL_RENT',
  MarketRent = 'MARKET_RENT',
  NetOperatingIncome = 'NET_OPERATING_INCOME',
  RentalIncome = 'RENTAL_INCOME',
  TotalExpenses = 'TOTAL_EXPENSES',
  TotalRevenue = 'TOTAL_REVENUE'
}

export type Transaction = {
  __typename?: 'Transaction';
  accountId: Scalars['String'];
  amount: Scalars['Decimal'];
  chargeCodeDesc?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  extId: Scalars['String'];
  id: Scalars['String'];
  invoiceDate?: Maybe<Scalars['DateTime']>;
  invoiceDueDate?: Maybe<Scalars['DateTime']>;
  invoiceImgS3Bucket?: Maybe<Scalars['String']>;
  invoiceImgS3Key?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  postDate: Scalars['DateTime'];
  propertyId: Scalars['String'];
  year: Scalars['Int'];
};

export type TransactionCreateManyAccountInput = {
  amount: Scalars['Decimal'];
  chargeCodeDesc?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  extId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['DateTime']>;
  invoiceDueDate?: Maybe<Scalars['DateTime']>;
  invoiceImgS3Bucket?: Maybe<Scalars['String']>;
  invoiceImgS3Key?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  postDate: Scalars['DateTime'];
  propertyId: Scalars['String'];
  year: Scalars['Int'];
};

export type TransactionCreateManyAccountInputEnvelope = {
  data: Array<TransactionCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TransactionCreateManyPropertyInput = {
  accountId: Scalars['String'];
  amount: Scalars['Decimal'];
  chargeCodeDesc?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  extId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['DateTime']>;
  invoiceDueDate?: Maybe<Scalars['DateTime']>;
  invoiceImgS3Bucket?: Maybe<Scalars['String']>;
  invoiceImgS3Key?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  postDate: Scalars['DateTime'];
  year: Scalars['Int'];
};

export type TransactionCreateManyPropertyInputEnvelope = {
  data: Array<TransactionCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TransactionCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<TransactionCreateWithoutAccountInput>>;
  createMany?: Maybe<TransactionCreateManyAccountInputEnvelope>;
};

export type TransactionCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<TransactionCreateWithoutPropertyInput>>;
  createMany?: Maybe<TransactionCreateManyPropertyInputEnvelope>;
};

export type TransactionCreateOrConnectWithoutAccountInput = {
  create: TransactionCreateWithoutAccountInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateOrConnectWithoutPropertyInput = {
  create: TransactionCreateWithoutPropertyInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateWithoutAccountInput = {
  amount: Scalars['Decimal'];
  chargeCodeDesc?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  extId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['DateTime']>;
  invoiceDueDate?: Maybe<Scalars['DateTime']>;
  invoiceImgS3Bucket?: Maybe<Scalars['String']>;
  invoiceImgS3Key?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  postDate: Scalars['DateTime'];
  property: PropertyCreateNestedOneWithoutTransactionsInput;
  year: Scalars['Int'];
};

export type TransactionCreateWithoutPropertyInput = {
  account: AccountV2CreateNestedOneWithoutTransactionsInput;
  amount: Scalars['Decimal'];
  chargeCodeDesc?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  extId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['DateTime']>;
  invoiceDueDate?: Maybe<Scalars['DateTime']>;
  invoiceImgS3Bucket?: Maybe<Scalars['String']>;
  invoiceImgS3Key?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  postDate: Scalars['DateTime'];
  year: Scalars['Int'];
};

export type TransactionListRelationFilter = {
  every?: Maybe<TransactionWhereInput>;
  none?: Maybe<TransactionWhereInput>;
  some?: Maybe<TransactionWhereInput>;
};

export type TransactionModel = {
  __typename?: 'TransactionModel';
  accountId: Scalars['String'];
  amount: Scalars['Float'];
  chargeCodeDescription?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['String'];
  invoiceDate?: Maybe<Scalars['DateTime']>;
  invoiceDueDate?: Maybe<Scalars['DateTime']>;
  invoiceImgUrl?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<Scalars['String']>;
  memo: Scalars['String'];
  postDate: Scalars['DateTime'];
  propertyId: Scalars['String'];
};

export type TransactionOrderByInput = {
  accountId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  chargeCodeDesc?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  extId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoiceDate?: Maybe<SortOrder>;
  invoiceDueDate?: Maybe<SortOrder>;
  invoiceImgS3Bucket?: Maybe<SortOrder>;
  invoiceImgS3Key?: Maybe<SortOrder>;
  invoiceNumber?: Maybe<SortOrder>;
  invoiceStatus?: Maybe<SortOrder>;
  memo?: Maybe<SortOrder>;
  monthIndex?: Maybe<SortOrder>;
  postDate?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  year?: Maybe<SortOrder>;
};

export type TransactionPropertyIdAccountIdYearMonthIndexExtIdCompoundUniqueInput = {
  accountId: Scalars['String'];
  extId: Scalars['String'];
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  year: Scalars['Int'];
};

export enum TransactionScalarFieldEnum {
  AccountId = 'accountId',
  Amount = 'amount',
  ChargeCodeDesc = 'chargeCodeDesc',
  Description = 'description',
  ExtId = 'extId',
  Id = 'id',
  InvoiceDate = 'invoiceDate',
  InvoiceDueDate = 'invoiceDueDate',
  InvoiceImgS3Bucket = 'invoiceImgS3Bucket',
  InvoiceImgS3Key = 'invoiceImgS3Key',
  InvoiceNumber = 'invoiceNumber',
  InvoiceStatus = 'invoiceStatus',
  Memo = 'memo',
  MonthIndex = 'monthIndex',
  PostDate = 'postDate',
  PropertyId = 'propertyId',
  Year = 'year'
}

export type TransactionScalarWhereInput = {
  AND?: Maybe<Array<TransactionScalarWhereInput>>;
  NOT?: Maybe<Array<TransactionScalarWhereInput>>;
  OR?: Maybe<Array<TransactionScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  amount?: Maybe<DecimalFilter>;
  chargeCodeDesc?: Maybe<StringNullableFilter>;
  description?: Maybe<StringFilter>;
  extId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  invoiceDate?: Maybe<DateTimeNullableFilter>;
  invoiceDueDate?: Maybe<DateTimeNullableFilter>;
  invoiceImgS3Bucket?: Maybe<StringNullableFilter>;
  invoiceImgS3Key?: Maybe<StringNullableFilter>;
  invoiceNumber?: Maybe<StringNullableFilter>;
  invoiceStatus?: Maybe<StringNullableFilter>;
  memo?: Maybe<StringNullableFilter>;
  monthIndex?: Maybe<IntFilter>;
  postDate?: Maybe<DateTimeFilter>;
  propertyId?: Maybe<StringFilter>;
  year?: Maybe<IntFilter>;
};

export type TransactionUpdateManyMutationInput = {
  amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  chargeCodeDesc?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  extId?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  invoiceDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  invoiceDueDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  invoiceImgS3Bucket?: Maybe<NullableStringFieldUpdateOperationsInput>;
  invoiceImgS3Key?: Maybe<NullableStringFieldUpdateOperationsInput>;
  invoiceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  invoiceStatus?: Maybe<NullableStringFieldUpdateOperationsInput>;
  memo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  postDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type TransactionUpdateManyWithWhereWithoutAccountInput = {
  data: TransactionUpdateManyMutationInput;
  where: TransactionScalarWhereInput;
};

export type TransactionUpdateManyWithWhereWithoutPropertyInput = {
  data: TransactionUpdateManyMutationInput;
  where: TransactionScalarWhereInput;
};

export type TransactionUpdateManyWithoutAccountInput = {
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<TransactionCreateWithoutAccountInput>>;
  createMany?: Maybe<TransactionCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<TransactionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>;
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>;
  set?: Maybe<Array<TransactionWhereUniqueInput>>;
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type TransactionUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TransactionCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<TransactionCreateWithoutPropertyInput>>;
  createMany?: Maybe<TransactionCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<TransactionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>;
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>;
  set?: Maybe<Array<TransactionWhereUniqueInput>>;
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type TransactionUpdateWithWhereUniqueWithoutAccountInput = {
  data: TransactionUpdateWithoutAccountInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpdateWithWhereUniqueWithoutPropertyInput = {
  data: TransactionUpdateWithoutPropertyInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpdateWithoutAccountInput = {
  amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  chargeCodeDesc?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  extId?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  invoiceDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  invoiceDueDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  invoiceImgS3Bucket?: Maybe<NullableStringFieldUpdateOperationsInput>;
  invoiceImgS3Key?: Maybe<NullableStringFieldUpdateOperationsInput>;
  invoiceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  invoiceStatus?: Maybe<NullableStringFieldUpdateOperationsInput>;
  memo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  postDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutTransactionsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type TransactionUpdateWithoutPropertyInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutTransactionsInput>;
  amount?: Maybe<DecimalFieldUpdateOperationsInput>;
  chargeCodeDesc?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  extId?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  invoiceDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  invoiceDueDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  invoiceImgS3Bucket?: Maybe<NullableStringFieldUpdateOperationsInput>;
  invoiceImgS3Key?: Maybe<NullableStringFieldUpdateOperationsInput>;
  invoiceNumber?: Maybe<NullableStringFieldUpdateOperationsInput>;
  invoiceStatus?: Maybe<NullableStringFieldUpdateOperationsInput>;
  memo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  postDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type TransactionUpsertWithWhereUniqueWithoutAccountInput = {
  create: TransactionCreateWithoutAccountInput;
  update: TransactionUpdateWithoutAccountInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpsertWithWhereUniqueWithoutPropertyInput = {
  create: TransactionCreateWithoutPropertyInput;
  update: TransactionUpdateWithoutPropertyInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionWhereInput = {
  AND?: Maybe<Array<TransactionWhereInput>>;
  NOT?: Maybe<Array<TransactionWhereInput>>;
  OR?: Maybe<Array<TransactionWhereInput>>;
  account?: Maybe<AccountV2RelationFilter>;
  accountId?: Maybe<StringFilter>;
  amount?: Maybe<DecimalFilter>;
  chargeCodeDesc?: Maybe<StringNullableFilter>;
  description?: Maybe<StringFilter>;
  extId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  invoiceDate?: Maybe<DateTimeNullableFilter>;
  invoiceDueDate?: Maybe<DateTimeNullableFilter>;
  invoiceImgS3Bucket?: Maybe<StringNullableFilter>;
  invoiceImgS3Key?: Maybe<StringNullableFilter>;
  invoiceNumber?: Maybe<StringNullableFilter>;
  invoiceStatus?: Maybe<StringNullableFilter>;
  memo?: Maybe<StringNullableFilter>;
  monthIndex?: Maybe<IntFilter>;
  postDate?: Maybe<DateTimeFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  year?: Maybe<IntFilter>;
};

export type TransactionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  propertyId_accountId_year_monthIndex_extId?: Maybe<TransactionPropertyIdAccountIdYearMonthIndexExtIdCompoundUniqueInput>;
};

export type TraversablePaginatedTransactionsModel = {
  __typename?: 'TraversablePaginatedTransactionsModel';
  items: Array<TransactionModel>;
  maxPage: Scalars['Int'];
  page: Scalars['Int'];
  take: Scalars['Int'];
  total: Scalars['Int'];
};

export type TraversablePaginatedUnitModel = {
  __typename?: 'TraversablePaginatedUnitModel';
  items: Array<UnitModel>;
  maxPage: Scalars['Int'];
  page: Scalars['Int'];
  take: Scalars['Int'];
  total: Scalars['Int'];
};

export type TraversablePaginationArgs = {
  page: Scalars['Int'];
  take: Scalars['Int'];
};

export type TrendsModel = {
  __typename?: 'TrendsModel';
  currentValue?: Maybe<Scalars['Decimal']>;
  previousMonth?: Maybe<VarianceMetricModel>;
  previousYear?: Maybe<VarianceMetricModel>;
};

export type Unit = {
  __typename?: 'Unit';
  createdAt: Scalars['DateTime'];
  entrataUnitId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  number: Scalars['String'];
  property: Property;
  propertyId: Scalars['String'];
  sqft: Scalars['Decimal'];
  unitEvents: Array<UnitEvent>;
  unitType: UnitType;
  unitTypeId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type UnitUnitEventsArgs = {
  cursor?: Maybe<UnitEventWhereUniqueInput>;
  distinct?: Maybe<Array<UnitEventScalarFieldEnum>>;
  orderBy?: Maybe<Array<UnitEventOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UnitEventWhereInput>;
};

export type UnitAvgAggregate = {
  __typename?: 'UnitAvgAggregate';
  sqft?: Maybe<Scalars['Decimal']>;
};

export type UnitCountAggregate = {
  __typename?: 'UnitCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  entrataUnitId: Scalars['Int'];
  id: Scalars['Int'];
  number: Scalars['Int'];
  propertyId: Scalars['Int'];
  sqft: Scalars['Int'];
  unitTypeId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type UnitCreateManyPropertyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entrataUnitId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  sqft: Scalars['Decimal'];
  unitTypeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitCreateManyPropertyInputEnvelope = {
  data: Array<UnitCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UnitCreateManyUnitTypeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entrataUnitId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  propertyId: Scalars['String'];
  sqft: Scalars['Decimal'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitCreateManyUnitTypeInputEnvelope = {
  data: Array<UnitCreateManyUnitTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UnitCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<UnitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UnitCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<UnitCreateWithoutPropertyInput>>;
  createMany?: Maybe<UnitCreateManyPropertyInputEnvelope>;
};

export type UnitCreateNestedManyWithoutUnitTypeInput = {
  connect?: Maybe<Array<UnitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UnitCreateOrConnectWithoutUnitTypeInput>>;
  create?: Maybe<Array<UnitCreateWithoutUnitTypeInput>>;
  createMany?: Maybe<UnitCreateManyUnitTypeInputEnvelope>;
};

export type UnitCreateNestedOneWithoutUnitEventsInput = {
  connect?: Maybe<UnitWhereUniqueInput>;
  connectOrCreate?: Maybe<UnitCreateOrConnectWithoutUnitEventsInput>;
  create?: Maybe<UnitCreateWithoutUnitEventsInput>;
};

export type UnitCreateOrConnectWithoutPropertyInput = {
  create: UnitCreateWithoutPropertyInput;
  where: UnitWhereUniqueInput;
};

export type UnitCreateOrConnectWithoutUnitEventsInput = {
  create: UnitCreateWithoutUnitEventsInput;
  where: UnitWhereUniqueInput;
};

export type UnitCreateOrConnectWithoutUnitTypeInput = {
  create: UnitCreateWithoutUnitTypeInput;
  where: UnitWhereUniqueInput;
};

export type UnitCreateWithoutPropertyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entrataUnitId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  sqft: Scalars['Decimal'];
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutUnitInput>;
  unitType: UnitTypeCreateNestedOneWithoutUnitsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitCreateWithoutUnitEventsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entrataUnitId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  property: PropertyCreateNestedOneWithoutUnitsInput;
  sqft: Scalars['Decimal'];
  unitType: UnitTypeCreateNestedOneWithoutUnitsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitCreateWithoutUnitTypeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  entrataUnitId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  property: PropertyCreateNestedOneWithoutUnitsInput;
  sqft: Scalars['Decimal'];
  unitEvents?: Maybe<UnitEventCreateNestedManyWithoutUnitInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitEvent = {
  __typename?: 'UnitEvent';
  budgetYear?: Maybe<Scalars['Int']>;
  concessionMonths?: Maybe<Scalars['Decimal']>;
  createdAt: Scalars['DateTime'];
  eventEnd: Scalars['DateTime'];
  eventStart: Scalars['DateTime'];
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  importedEvent: Scalars['Boolean'];
  inPlaceRent?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  renewalAdjustmentPercentage?: Maybe<Scalars['Decimal']>;
  renovationCost?: Maybe<Scalars['Decimal']>;
  renovationPremium?: Maybe<Scalars['Decimal']>;
  shortTermEffectiveAvgDailyRent?: Maybe<Scalars['Decimal']>;
  tenantName?: Maybe<Scalars['String']>;
  type: UnitEventType;
  unitId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type UnitEventCreateManyPropertyInput = {
  budgetYear?: Maybe<Scalars['Int']>;
  concessionMonths?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  eventEnd: Scalars['DateTime'];
  eventStart: Scalars['DateTime'];
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  importedEvent?: Maybe<Scalars['Boolean']>;
  inPlaceRent?: Maybe<Scalars['Decimal']>;
  renewalAdjustmentPercentage?: Maybe<Scalars['Decimal']>;
  renovationCost?: Maybe<Scalars['Decimal']>;
  renovationPremium?: Maybe<Scalars['Decimal']>;
  shortTermEffectiveAvgDailyRent?: Maybe<Scalars['Decimal']>;
  tenantName?: Maybe<Scalars['String']>;
  type: UnitEventType;
  unitId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitEventCreateManyPropertyInputEnvelope = {
  data: Array<UnitEventCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UnitEventCreateManyUnitInput = {
  budgetYear?: Maybe<Scalars['Int']>;
  concessionMonths?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  eventEnd: Scalars['DateTime'];
  eventStart: Scalars['DateTime'];
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  importedEvent?: Maybe<Scalars['Boolean']>;
  inPlaceRent?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  renewalAdjustmentPercentage?: Maybe<Scalars['Decimal']>;
  renovationCost?: Maybe<Scalars['Decimal']>;
  renovationPremium?: Maybe<Scalars['Decimal']>;
  shortTermEffectiveAvgDailyRent?: Maybe<Scalars['Decimal']>;
  tenantName?: Maybe<Scalars['String']>;
  type: UnitEventType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitEventCreateManyUnitInputEnvelope = {
  data: Array<UnitEventCreateManyUnitInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UnitEventCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<UnitEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UnitEventCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<UnitEventCreateWithoutPropertyInput>>;
  createMany?: Maybe<UnitEventCreateManyPropertyInputEnvelope>;
};

export type UnitEventCreateNestedManyWithoutUnitInput = {
  connect?: Maybe<Array<UnitEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UnitEventCreateOrConnectWithoutUnitInput>>;
  create?: Maybe<Array<UnitEventCreateWithoutUnitInput>>;
  createMany?: Maybe<UnitEventCreateManyUnitInputEnvelope>;
};

export type UnitEventCreateOrConnectWithoutPropertyInput = {
  create: UnitEventCreateWithoutPropertyInput;
  where: UnitEventWhereUniqueInput;
};

export type UnitEventCreateOrConnectWithoutUnitInput = {
  create: UnitEventCreateWithoutUnitInput;
  where: UnitEventWhereUniqueInput;
};

export type UnitEventCreateWithoutPropertyInput = {
  budgetYear?: Maybe<Scalars['Int']>;
  concessionMonths?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  eventEnd: Scalars['DateTime'];
  eventStart: Scalars['DateTime'];
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  importedEvent?: Maybe<Scalars['Boolean']>;
  inPlaceRent?: Maybe<Scalars['Decimal']>;
  renewalAdjustmentPercentage?: Maybe<Scalars['Decimal']>;
  renovationCost?: Maybe<Scalars['Decimal']>;
  renovationPremium?: Maybe<Scalars['Decimal']>;
  shortTermEffectiveAvgDailyRent?: Maybe<Scalars['Decimal']>;
  tenantName?: Maybe<Scalars['String']>;
  type: UnitEventType;
  unit: UnitCreateNestedOneWithoutUnitEventsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitEventCreateWithoutUnitInput = {
  budgetYear?: Maybe<Scalars['Int']>;
  concessionMonths?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  eventEnd: Scalars['DateTime'];
  eventStart: Scalars['DateTime'];
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  importedEvent?: Maybe<Scalars['Boolean']>;
  inPlaceRent?: Maybe<Scalars['Decimal']>;
  property: PropertyCreateNestedOneWithoutUnitEventsInput;
  renewalAdjustmentPercentage?: Maybe<Scalars['Decimal']>;
  renovationCost?: Maybe<Scalars['Decimal']>;
  renovationPremium?: Maybe<Scalars['Decimal']>;
  shortTermEffectiveAvgDailyRent?: Maybe<Scalars['Decimal']>;
  tenantName?: Maybe<Scalars['String']>;
  type: UnitEventType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitEventExternalIdPropertyIdCompoundUniqueInput = {
  externalId: Scalars['String'];
  propertyId: Scalars['String'];
};

export type UnitEventListRelationFilter = {
  every?: Maybe<UnitEventWhereInput>;
  none?: Maybe<UnitEventWhereInput>;
  some?: Maybe<UnitEventWhereInput>;
};

export type UnitEventModel = {
  __typename?: 'UnitEventModel';
  concessionMonths?: Maybe<Scalars['Decimal']>;
  eventEnd: Scalars['DateTime'];
  eventStart: Scalars['DateTime'];
  eventType: UnitEventType;
  id: Scalars['ID'];
  importedEvent: Scalars['Boolean'];
  inPlaceRent?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  renewalAdjustmentPercentage?: Maybe<Scalars['Decimal']>;
  renovationCost?: Maybe<Scalars['Decimal']>;
  renovationPremium?: Maybe<Scalars['Decimal']>;
  shortTermEffectiveAvgDailyRent?: Maybe<Scalars['Decimal']>;
  tenantName?: Maybe<Scalars['String']>;
  unitId: Scalars['ID'];
};

export type UnitEventOrderByInput = {
  budgetYear?: Maybe<SortOrder>;
  concessionMonths?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  eventEnd?: Maybe<SortOrder>;
  eventStart?: Maybe<SortOrder>;
  externalId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  importedEvent?: Maybe<SortOrder>;
  inPlaceRent?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  renewalAdjustmentPercentage?: Maybe<SortOrder>;
  renovationCost?: Maybe<SortOrder>;
  renovationPremium?: Maybe<SortOrder>;
  shortTermEffectiveAvgDailyRent?: Maybe<SortOrder>;
  tenantName?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  unitId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum UnitEventScalarFieldEnum {
  BudgetYear = 'budgetYear',
  ConcessionMonths = 'concessionMonths',
  CreatedAt = 'createdAt',
  EventEnd = 'eventEnd',
  EventStart = 'eventStart',
  ExternalId = 'externalId',
  Id = 'id',
  ImportedEvent = 'importedEvent',
  InPlaceRent = 'inPlaceRent',
  PropertyId = 'propertyId',
  RenewalAdjustmentPercentage = 'renewalAdjustmentPercentage',
  RenovationCost = 'renovationCost',
  RenovationPremium = 'renovationPremium',
  ShortTermEffectiveAvgDailyRent = 'shortTermEffectiveAvgDailyRent',
  TenantName = 'tenantName',
  Type = 'type',
  UnitId = 'unitId',
  UpdatedAt = 'updatedAt'
}

export type UnitEventScalarWhereInput = {
  AND?: Maybe<Array<UnitEventScalarWhereInput>>;
  NOT?: Maybe<Array<UnitEventScalarWhereInput>>;
  OR?: Maybe<Array<UnitEventScalarWhereInput>>;
  budgetYear?: Maybe<IntNullableFilter>;
  concessionMonths?: Maybe<DecimalNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  eventEnd?: Maybe<DateTimeFilter>;
  eventStart?: Maybe<DateTimeFilter>;
  externalId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  importedEvent?: Maybe<BoolFilter>;
  inPlaceRent?: Maybe<DecimalNullableFilter>;
  propertyId?: Maybe<StringFilter>;
  renewalAdjustmentPercentage?: Maybe<DecimalNullableFilter>;
  renovationCost?: Maybe<DecimalNullableFilter>;
  renovationPremium?: Maybe<DecimalNullableFilter>;
  shortTermEffectiveAvgDailyRent?: Maybe<DecimalNullableFilter>;
  tenantName?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumUnitEventTypeFilter>;
  unitId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum UnitEventType {
  MonthToMonth = 'MONTH_TO_MONTH',
  Renewal = 'RENEWAL',
  Renovation = 'RENOVATION',
  ShortTermRental = 'SHORT_TERM_RENTAL',
  TermLease = 'TERM_LEASE'
}

export type UnitEventUpdateManyMutationInput = {
  budgetYear?: Maybe<NullableIntFieldUpdateOperationsInput>;
  concessionMonths?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  eventEnd?: Maybe<DateTimeFieldUpdateOperationsInput>;
  eventStart?: Maybe<DateTimeFieldUpdateOperationsInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  importedEvent?: Maybe<BoolFieldUpdateOperationsInput>;
  inPlaceRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalAdjustmentPercentage?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renovationCost?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renovationPremium?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  shortTermEffectiveAvgDailyRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  tenantName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumUnitEventTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UnitEventUpdateManyWithWhereWithoutPropertyInput = {
  data: UnitEventUpdateManyMutationInput;
  where: UnitEventScalarWhereInput;
};

export type UnitEventUpdateManyWithWhereWithoutUnitInput = {
  data: UnitEventUpdateManyMutationInput;
  where: UnitEventScalarWhereInput;
};

export type UnitEventUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<UnitEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UnitEventCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<UnitEventCreateWithoutPropertyInput>>;
  createMany?: Maybe<UnitEventCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<UnitEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UnitEventScalarWhereInput>>;
  disconnect?: Maybe<Array<UnitEventWhereUniqueInput>>;
  set?: Maybe<Array<UnitEventWhereUniqueInput>>;
  update?: Maybe<Array<UnitEventUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<UnitEventUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<UnitEventUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type UnitEventUpdateManyWithoutUnitInput = {
  connect?: Maybe<Array<UnitEventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UnitEventCreateOrConnectWithoutUnitInput>>;
  create?: Maybe<Array<UnitEventCreateWithoutUnitInput>>;
  createMany?: Maybe<UnitEventCreateManyUnitInputEnvelope>;
  delete?: Maybe<Array<UnitEventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UnitEventScalarWhereInput>>;
  disconnect?: Maybe<Array<UnitEventWhereUniqueInput>>;
  set?: Maybe<Array<UnitEventWhereUniqueInput>>;
  update?: Maybe<Array<UnitEventUpdateWithWhereUniqueWithoutUnitInput>>;
  updateMany?: Maybe<Array<UnitEventUpdateManyWithWhereWithoutUnitInput>>;
  upsert?: Maybe<Array<UnitEventUpsertWithWhereUniqueWithoutUnitInput>>;
};

export type UnitEventUpdateWithWhereUniqueWithoutPropertyInput = {
  data: UnitEventUpdateWithoutPropertyInput;
  where: UnitEventWhereUniqueInput;
};

export type UnitEventUpdateWithWhereUniqueWithoutUnitInput = {
  data: UnitEventUpdateWithoutUnitInput;
  where: UnitEventWhereUniqueInput;
};

export type UnitEventUpdateWithoutPropertyInput = {
  budgetYear?: Maybe<NullableIntFieldUpdateOperationsInput>;
  concessionMonths?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  eventEnd?: Maybe<DateTimeFieldUpdateOperationsInput>;
  eventStart?: Maybe<DateTimeFieldUpdateOperationsInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  importedEvent?: Maybe<BoolFieldUpdateOperationsInput>;
  inPlaceRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalAdjustmentPercentage?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renovationCost?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renovationPremium?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  shortTermEffectiveAvgDailyRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  tenantName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumUnitEventTypeFieldUpdateOperationsInput>;
  unit?: Maybe<UnitUpdateOneRequiredWithoutUnitEventsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UnitEventUpdateWithoutUnitInput = {
  budgetYear?: Maybe<NullableIntFieldUpdateOperationsInput>;
  concessionMonths?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  eventEnd?: Maybe<DateTimeFieldUpdateOperationsInput>;
  eventStart?: Maybe<DateTimeFieldUpdateOperationsInput>;
  externalId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  importedEvent?: Maybe<BoolFieldUpdateOperationsInput>;
  inPlaceRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutUnitEventsInput>;
  renewalAdjustmentPercentage?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renovationCost?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renovationPremium?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  shortTermEffectiveAvgDailyRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  tenantName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumUnitEventTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UnitEventUpsertWithWhereUniqueWithoutPropertyInput = {
  create: UnitEventCreateWithoutPropertyInput;
  update: UnitEventUpdateWithoutPropertyInput;
  where: UnitEventWhereUniqueInput;
};

export type UnitEventUpsertWithWhereUniqueWithoutUnitInput = {
  create: UnitEventCreateWithoutUnitInput;
  update: UnitEventUpdateWithoutUnitInput;
  where: UnitEventWhereUniqueInput;
};

export type UnitEventWhereInput = {
  AND?: Maybe<Array<UnitEventWhereInput>>;
  NOT?: Maybe<Array<UnitEventWhereInput>>;
  OR?: Maybe<Array<UnitEventWhereInput>>;
  budgetYear?: Maybe<IntNullableFilter>;
  concessionMonths?: Maybe<DecimalNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  eventEnd?: Maybe<DateTimeFilter>;
  eventStart?: Maybe<DateTimeFilter>;
  externalId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  importedEvent?: Maybe<BoolFilter>;
  inPlaceRent?: Maybe<DecimalNullableFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  renewalAdjustmentPercentage?: Maybe<DecimalNullableFilter>;
  renovationCost?: Maybe<DecimalNullableFilter>;
  renovationPremium?: Maybe<DecimalNullableFilter>;
  shortTermEffectiveAvgDailyRent?: Maybe<DecimalNullableFilter>;
  tenantName?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumUnitEventTypeFilter>;
  unit?: Maybe<UnitRelationFilter>;
  unitId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type UnitEventWhereUniqueInput = {
  externalId_propertyId?: Maybe<UnitEventExternalIdPropertyIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type UnitLevelMarketRentModel = {
  __typename?: 'UnitLevelMarketRentModel';
  adjustedMarketRentsActuals: Array<Maybe<Scalars['Decimal']>>;
  adjustedMarketRentsBudget: Array<Maybe<Scalars['Decimal']>>;
  adjustedMarketRentsReforecast: Array<Maybe<Scalars['Decimal']>>;
  proratedRenovationPremiums: Array<Maybe<Scalars['Decimal']>>;
  rawMarketRentsActuals: Array<Maybe<Scalars['Decimal']>>;
  rawMarketRentsBudget: Array<Maybe<Scalars['Decimal']>>;
  rawMarketRentsReforecast: Array<Maybe<Scalars['Decimal']>>;
  unitId: Scalars['ID'];
  year: Scalars['Int'];
};

export type UnitLevelPropertyFinancialMetricsModel = {
  __typename?: 'UnitLevelPropertyFinancialMetricsModel';
  accountName: Scalars['ID'];
  budgetValues: Array<Maybe<Scalars['Float']>>;
  order: Scalars['Int'];
  reforecastValues: Array<Maybe<Scalars['Float']>>;
};

export type UnitLevelPropertyMetricsModel = {
  __typename?: 'UnitLevelPropertyMetricsModel';
  averageMarketRent: Array<Maybe<Scalars['Float']>>;
  expirations: Array<Maybe<Scalars['Float']>>;
  monthToMonthMoveOuts: Array<Maybe<Scalars['Float']>>;
  moveOutPercentage: Array<Maybe<Scalars['Float']>>;
  moveOuts: Array<Maybe<Scalars['Float']>>;
  occupancy: Array<Maybe<Scalars['Float']>>;
  occupiedUnits: Array<Maybe<Scalars['Float']>>;
  propertyId: Scalars['ID'];
  renewalIncrease: Array<Maybe<Scalars['Float']>>;
  renewalRatio: Array<Maybe<Scalars['Float']>>;
  renewals: Array<Maybe<Scalars['Float']>>;
  totalMoveOuts: Array<Maybe<Scalars['Float']>>;
  unitCount: Scalars['Int'];
  valuesType: Scalars['String'];
};

export type UnitListRelationFilter = {
  every?: Maybe<UnitWhereInput>;
  none?: Maybe<UnitWhereInput>;
  some?: Maybe<UnitWhereInput>;
};

export type UnitMaxAggregate = {
  __typename?: 'UnitMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  entrataUnitId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['String']>;
  sqft?: Maybe<Scalars['Decimal']>;
  unitTypeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitMinAggregate = {
  __typename?: 'UnitMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  entrataUnitId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['String']>;
  sqft?: Maybe<Scalars['Decimal']>;
  unitTypeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitModel = {
  __typename?: 'UnitModel';
  events: Array<EnrichedUnitEventModel>;
  eventsByBudgetYear: Array<EnrichedUnitEventModel>;
  id: Scalars['ID'];
  marketRents?: Maybe<UnitLevelMarketRentModel>;
  marketRentsByBudgetYear?: Maybe<UnitLevelMarketRentModel>;
  number: Scalars['String'];
  propertyId: Scalars['String'];
  squareFeet: Scalars['Decimal'];
  unitTypeId: Scalars['String'];
};


export type UnitModelEventsArgs = {
  maxDate?: Maybe<Scalars['DateTime']>;
  minDate?: Maybe<Scalars['DateTime']>;
};


export type UnitModelEventsByBudgetYearArgs = {
  budgetYear: Scalars['Int'];
  maxDate?: Maybe<Scalars['DateTime']>;
  minDate?: Maybe<Scalars['DateTime']>;
};


export type UnitModelMarketRentsArgs = {
  year: Scalars['Int'];
};


export type UnitModelMarketRentsByBudgetYearArgs = {
  budgeYear: Scalars['Int'];
  year: Scalars['Int'];
};

export type UnitOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  entrataUnitId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  sqft?: Maybe<SortOrder>;
  unitTypeId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type UnitPropertyIdNumberUnitTypeIdCompoundUniqueInput = {
  number: Scalars['String'];
  propertyId: Scalars['String'];
  unitTypeId: Scalars['String'];
};

export type UnitRelationFilter = {
  is?: Maybe<UnitWhereInput>;
  isNot?: Maybe<UnitWhereInput>;
};

export enum UnitScalarFieldEnum {
  CreatedAt = 'createdAt',
  EntrataUnitId = 'entrataUnitId',
  Id = 'id',
  Number = 'number',
  PropertyId = 'propertyId',
  Sqft = 'sqft',
  UnitTypeId = 'unitTypeId',
  UpdatedAt = 'updatedAt'
}

export type UnitScalarWhereInput = {
  AND?: Maybe<Array<UnitScalarWhereInput>>;
  NOT?: Maybe<Array<UnitScalarWhereInput>>;
  OR?: Maybe<Array<UnitScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  entrataUnitId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  number?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  sqft?: Maybe<DecimalFilter>;
  unitTypeId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type UnitSumAggregate = {
  __typename?: 'UnitSumAggregate';
  sqft?: Maybe<Scalars['Decimal']>;
};

export type UnitType = {
  __typename?: 'UnitType';
  budgetYear: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  propertyId: Scalars['String'];
  renewalIncrease: RenewalIncreaseAmountAndPercent;
  renovationPackages: Array<RenovationPackageModel>;
  updatedAt: Scalars['DateTime'];
};


export type UnitTypeRenewalIncreaseArgs = {
  versionType: VersionType;
  year: Scalars['Int'];
};

export type UnitTypeCreateManyPropertyInput = {
  budgetYear: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitTypeCreateManyPropertyInputEnvelope = {
  data: Array<UnitTypeCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UnitTypeCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<UnitTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UnitTypeCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<UnitTypeCreateWithoutPropertyInput>>;
  createMany?: Maybe<UnitTypeCreateManyPropertyInputEnvelope>;
};

export type UnitTypeCreateNestedOneWithoutOriginalRevenueMarketRentOverridesInput = {
  connect?: Maybe<UnitTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<UnitTypeCreateOrConnectWithoutOriginalRevenueMarketRentOverridesInput>;
  create?: Maybe<UnitTypeCreateWithoutOriginalRevenueMarketRentOverridesInput>;
};

export type UnitTypeCreateNestedOneWithoutPmsActualMetricOverrideAverageResidentialRentsInput = {
  connect?: Maybe<UnitTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<UnitTypeCreateOrConnectWithoutPmsActualMetricOverrideAverageResidentialRentsInput>;
  create?: Maybe<UnitTypeCreateWithoutPmsActualMetricOverrideAverageResidentialRentsInput>;
};

export type UnitTypeCreateNestedOneWithoutPmsActualMetricOverrideOccupancyRatesInput = {
  connect?: Maybe<UnitTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<UnitTypeCreateOrConnectWithoutPmsActualMetricOverrideOccupancyRatesInput>;
  create?: Maybe<UnitTypeCreateWithoutPmsActualMetricOverrideOccupancyRatesInput>;
};

export type UnitTypeCreateNestedOneWithoutPmsActualMetricOverrideUnitCountsInput = {
  connect?: Maybe<UnitTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<UnitTypeCreateOrConnectWithoutPmsActualMetricOverrideUnitCountsInput>;
  create?: Maybe<UnitTypeCreateWithoutPmsActualMetricOverrideUnitCountsInput>;
};

export type UnitTypeCreateNestedOneWithoutRenovationPackagesInput = {
  connect?: Maybe<UnitTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<UnitTypeCreateOrConnectWithoutRenovationPackagesInput>;
  create?: Maybe<UnitTypeCreateWithoutRenovationPackagesInput>;
};

export type UnitTypeCreateNestedOneWithoutUnitTypeScheduleValuesInput = {
  connect?: Maybe<UnitTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<UnitTypeCreateOrConnectWithoutUnitTypeScheduleValuesInput>;
  create?: Maybe<UnitTypeCreateWithoutUnitTypeScheduleValuesInput>;
};

export type UnitTypeCreateNestedOneWithoutUnitsInput = {
  connect?: Maybe<UnitTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<UnitTypeCreateOrConnectWithoutUnitsInput>;
  create?: Maybe<UnitTypeCreateWithoutUnitsInput>;
};

export type UnitTypeCreateNestedOneWithoutUnrenovatedUnitTypeMappingInput = {
  connect?: Maybe<UnitTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<UnitTypeCreateOrConnectWithoutUnrenovatedUnitTypeMappingInput>;
  create?: Maybe<UnitTypeCreateWithoutUnrenovatedUnitTypeMappingInput>;
};

export type UnitTypeCreateOrConnectWithoutOriginalRevenueMarketRentOverridesInput = {
  create: UnitTypeCreateWithoutOriginalRevenueMarketRentOverridesInput;
  where: UnitTypeWhereUniqueInput;
};

export type UnitTypeCreateOrConnectWithoutPmsActualMetricOverrideAverageResidentialRentsInput = {
  create: UnitTypeCreateWithoutPmsActualMetricOverrideAverageResidentialRentsInput;
  where: UnitTypeWhereUniqueInput;
};

export type UnitTypeCreateOrConnectWithoutPmsActualMetricOverrideOccupancyRatesInput = {
  create: UnitTypeCreateWithoutPmsActualMetricOverrideOccupancyRatesInput;
  where: UnitTypeWhereUniqueInput;
};

export type UnitTypeCreateOrConnectWithoutPmsActualMetricOverrideUnitCountsInput = {
  create: UnitTypeCreateWithoutPmsActualMetricOverrideUnitCountsInput;
  where: UnitTypeWhereUniqueInput;
};

export type UnitTypeCreateOrConnectWithoutPropertyInput = {
  create: UnitTypeCreateWithoutPropertyInput;
  where: UnitTypeWhereUniqueInput;
};

export type UnitTypeCreateOrConnectWithoutRenovationPackagesInput = {
  create: UnitTypeCreateWithoutRenovationPackagesInput;
  where: UnitTypeWhereUniqueInput;
};

export type UnitTypeCreateOrConnectWithoutUnitTypeScheduleValuesInput = {
  create: UnitTypeCreateWithoutUnitTypeScheduleValuesInput;
  where: UnitTypeWhereUniqueInput;
};

export type UnitTypeCreateOrConnectWithoutUnitsInput = {
  create: UnitTypeCreateWithoutUnitsInput;
  where: UnitTypeWhereUniqueInput;
};

export type UnitTypeCreateOrConnectWithoutUnrenovatedUnitTypeMappingInput = {
  create: UnitTypeCreateWithoutUnrenovatedUnitTypeMappingInput;
  where: UnitTypeWhereUniqueInput;
};

export type UnitTypeCreateWithoutOriginalRevenueMarketRentOverridesInput = {
  budgetYear: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutUnitTypeInput>;
  property: PropertyCreateNestedOneWithoutUnitTypesInput;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutUnitTypeInput>;
  startingMarketRentOverrides?: Maybe<SimplifiedRevenueStartingMarketRentOverrideCreateNestedManyWithoutUnitTypeInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutUnitTypeInput>;
  units?: Maybe<UnitCreateNestedManyWithoutUnitTypeInput>;
  unrenovatedUnitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutRenovatedUnitTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitTypeCreateWithoutPmsActualMetricOverrideAverageResidentialRentsInput = {
  budgetYear: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutUnitTypeInput>;
  property: PropertyCreateNestedOneWithoutUnitTypesInput;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutUnitTypeInput>;
  startingMarketRentOverrides?: Maybe<SimplifiedRevenueStartingMarketRentOverrideCreateNestedManyWithoutUnitTypeInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutUnitTypeInput>;
  units?: Maybe<UnitCreateNestedManyWithoutUnitTypeInput>;
  unrenovatedUnitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutRenovatedUnitTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitTypeCreateWithoutPmsActualMetricOverrideOccupancyRatesInput = {
  budgetYear: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutUnitTypeInput>;
  property: PropertyCreateNestedOneWithoutUnitTypesInput;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutUnitTypeInput>;
  startingMarketRentOverrides?: Maybe<SimplifiedRevenueStartingMarketRentOverrideCreateNestedManyWithoutUnitTypeInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutUnitTypeInput>;
  units?: Maybe<UnitCreateNestedManyWithoutUnitTypeInput>;
  unrenovatedUnitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutRenovatedUnitTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitTypeCreateWithoutPmsActualMetricOverrideUnitCountsInput = {
  budgetYear: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutUnitTypeInput>;
  property: PropertyCreateNestedOneWithoutUnitTypesInput;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutUnitTypeInput>;
  startingMarketRentOverrides?: Maybe<SimplifiedRevenueStartingMarketRentOverrideCreateNestedManyWithoutUnitTypeInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutUnitTypeInput>;
  units?: Maybe<UnitCreateNestedManyWithoutUnitTypeInput>;
  unrenovatedUnitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutRenovatedUnitTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitTypeCreateWithoutPropertyInput = {
  budgetYear: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutUnitTypeInput>;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutUnitTypeInput>;
  startingMarketRentOverrides?: Maybe<SimplifiedRevenueStartingMarketRentOverrideCreateNestedManyWithoutUnitTypeInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutUnitTypeInput>;
  units?: Maybe<UnitCreateNestedManyWithoutUnitTypeInput>;
  unrenovatedUnitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutRenovatedUnitTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitTypeCreateWithoutRenovationPackagesInput = {
  budgetYear: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutUnitTypeInput>;
  property: PropertyCreateNestedOneWithoutUnitTypesInput;
  startingMarketRentOverrides?: Maybe<SimplifiedRevenueStartingMarketRentOverrideCreateNestedManyWithoutUnitTypeInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutUnitTypeInput>;
  units?: Maybe<UnitCreateNestedManyWithoutUnitTypeInput>;
  unrenovatedUnitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutRenovatedUnitTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitTypeCreateWithoutUnitTypeScheduleValuesInput = {
  budgetYear: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutUnitTypeInput>;
  property: PropertyCreateNestedOneWithoutUnitTypesInput;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutUnitTypeInput>;
  startingMarketRentOverrides?: Maybe<SimplifiedRevenueStartingMarketRentOverrideCreateNestedManyWithoutUnitTypeInput>;
  units?: Maybe<UnitCreateNestedManyWithoutUnitTypeInput>;
  unrenovatedUnitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutRenovatedUnitTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitTypeCreateWithoutUnitsInput = {
  budgetYear: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutUnitTypeInput>;
  property: PropertyCreateNestedOneWithoutUnitTypesInput;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutUnitTypeInput>;
  startingMarketRentOverrides?: Maybe<SimplifiedRevenueStartingMarketRentOverrideCreateNestedManyWithoutUnitTypeInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutUnitTypeInput>;
  unrenovatedUnitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingCreateNestedManyWithoutRenovatedUnitTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitTypeCreateWithoutUnrenovatedUnitTypeMappingInput = {
  budgetYear: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateCreateNestedManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountCreateNestedManyWithoutUnitTypeInput>;
  property: PropertyCreateNestedOneWithoutUnitTypesInput;
  renovationPackages?: Maybe<RenovationPackageCreateNestedManyWithoutUnitTypeInput>;
  startingMarketRentOverrides?: Maybe<SimplifiedRevenueStartingMarketRentOverrideCreateNestedManyWithoutUnitTypeInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutUnitTypeInput>;
  units?: Maybe<UnitCreateNestedManyWithoutUnitTypeInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitTypeExtViewModel = {
  __typename?: 'UnitTypeExtViewModel';
  budgetYear: Scalars['Int'];
  createdAt: Scalars['DateNoTime'];
  id: Scalars['String'];
  inUse: Scalars['Boolean'];
  name: Scalars['String'];
  propertyId: Scalars['String'];
  updatedAt: Scalars['DateNoTime'];
};

export type UnitTypeForPmsActualOverridesFullModel = {
  __typename?: 'UnitTypeForPMSActualOverridesFullModel';
  inPlaceRent: Array<Maybe<Scalars['Float']>>;
  occupancy: Array<Maybe<Scalars['Float']>>;
  propertyId: Scalars['ID'];
  unitCount: Array<Maybe<Scalars['Float']>>;
  unitTypeId: Scalars['ID'];
  unitTypeName: Scalars['String'];
};

export type UnitTypeForPmsActualOverridesModel = {
  __typename?: 'UnitTypeForPMSActualOverridesModel';
  inPlaceRent: UnitTypePmsActualOverrideYearModel;
  occupancy: UnitTypePmsActualOverrideYearModel;
  propertyId: Scalars['ID'];
  unitCount: UnitTypePmsActualOverrideYearModel;
  unitTypeId: Scalars['ID'];
  unitTypeName: Scalars['String'];
};

export type UnitTypeForPmsActualOverridesUpdateInput = {
  unitTypeId: Scalars['ID'];
  valueM0?: Maybe<Scalars['Float']>;
  valueM1?: Maybe<Scalars['Float']>;
  valueM2?: Maybe<Scalars['Float']>;
  valueM3?: Maybe<Scalars['Float']>;
  valueM4?: Maybe<Scalars['Float']>;
  valueM5?: Maybe<Scalars['Float']>;
  valueM6?: Maybe<Scalars['Float']>;
  valueM7?: Maybe<Scalars['Float']>;
  valueM8?: Maybe<Scalars['Float']>;
  valueM9?: Maybe<Scalars['Float']>;
  valueM10?: Maybe<Scalars['Float']>;
  valueM11?: Maybe<Scalars['Float']>;
};

export type UnitTypeListRelationFilter = {
  every?: Maybe<UnitTypeWhereInput>;
  none?: Maybe<UnitTypeWhereInput>;
  some?: Maybe<UnitTypeWhereInput>;
};

export type UnitTypeMetrics = {
  metrics: Array<MetricInput>;
  propertyId: Scalars['String'];
  unitTypeId: Scalars['String'];
};

export type UnitTypeOrderByInput = {
  budgetYear?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type UnitTypePmsActualOverrideMonthModel = {
  __typename?: 'UnitTypePMSActualOverrideMonthModel';
  original?: Maybe<Scalars['Float']>;
  override?: Maybe<Scalars['Float']>;
  unitTypeId: Scalars['ID'];
  unitTypeName: Scalars['String'];
};

export type UnitTypePmsActualOverrideYearModel = {
  __typename?: 'UnitTypePMSActualOverrideYearModel';
  originals: Array<Maybe<Scalars['Float']>>;
  overrides: Array<Maybe<Scalars['Float']>>;
  unitTypeId: Scalars['ID'];
  unitTypeName: Scalars['String'];
};

export type UnitTypePropertyIdNameBudgetYearCompoundUniqueInput = {
  budgetYear: Scalars['Int'];
  name: Scalars['String'];
  propertyId: Scalars['String'];
};

export type UnitTypeRelationFilter = {
  is?: Maybe<UnitTypeWhereInput>;
  isNot?: Maybe<UnitTypeWhereInput>;
};

export enum UnitTypeScalarFieldEnum {
  BudgetYear = 'budgetYear',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt'
}

export type UnitTypeScalarWhereInput = {
  AND?: Maybe<Array<UnitTypeScalarWhereInput>>;
  NOT?: Maybe<Array<UnitTypeScalarWhereInput>>;
  OR?: Maybe<Array<UnitTypeScalarWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type UnitTypeScheduleValue = {
  __typename?: 'UnitTypeScheduleValue';
  averageMarketRent: Scalars['Decimal'];
  averageResidentialRent: Scalars['Decimal'];
  averageSquareFeet: Scalars['Int'];
  combinedTradeOut?: Maybe<Scalars['Decimal']>;
  createdAt: Scalars['DateTime'];
  earlyTerminationCount?: Maybe<Scalars['Int']>;
  entrataUnitTypeId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  leaseExpirationCount: Scalars['Int'];
  monthIndex: Scalars['Int'];
  monthToMonthCount?: Maybe<Scalars['Int']>;
  monthToMonthMoveOutCount?: Maybe<Scalars['Int']>;
  moveInCount?: Maybe<Scalars['Decimal']>;
  moveOutCount?: Maybe<Scalars['Decimal']>;
  moveOutRate?: Maybe<Scalars['Decimal']>;
  newLeaseRent?: Maybe<Scalars['Decimal']>;
  newLeaseRentCount?: Maybe<Scalars['Int']>;
  newLeaseTradeOut?: Maybe<Scalars['Decimal']>;
  occupancyRate?: Maybe<Scalars['Decimal']>;
  occupiedUnits?: Maybe<Scalars['Decimal']>;
  property: Property;
  propertyId: Scalars['String'];
  renewalCount?: Maybe<Scalars['Decimal']>;
  renewalRate?: Maybe<Scalars['Decimal']>;
  renewalRatio?: Maybe<Scalars['Decimal']>;
  renewalRent?: Maybe<Scalars['Decimal']>;
  renewalRentCount?: Maybe<Scalars['Int']>;
  totalMoveOutCount?: Maybe<Scalars['Decimal']>;
  unitCount: Scalars['Int'];
  unitType: UnitType;
  unitTypeId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  version: ValueVersion;
  versionId: Scalars['String'];
};

export type UnitTypeScheduleValueAvgAggregate = {
  __typename?: 'UnitTypeScheduleValueAvgAggregate';
  averageMarketRent?: Maybe<Scalars['Decimal']>;
  averageResidentialRent?: Maybe<Scalars['Decimal']>;
  averageSquareFeet?: Maybe<Scalars['Float']>;
  combinedTradeOut?: Maybe<Scalars['Decimal']>;
  earlyTerminationCount?: Maybe<Scalars['Float']>;
  leaseExpirationCount?: Maybe<Scalars['Float']>;
  monthIndex?: Maybe<Scalars['Float']>;
  monthToMonthCount?: Maybe<Scalars['Float']>;
  monthToMonthMoveOutCount?: Maybe<Scalars['Float']>;
  moveInCount?: Maybe<Scalars['Decimal']>;
  moveOutCount?: Maybe<Scalars['Decimal']>;
  moveOutRate?: Maybe<Scalars['Decimal']>;
  newLeaseRent?: Maybe<Scalars['Decimal']>;
  newLeaseRentCount?: Maybe<Scalars['Float']>;
  newLeaseTradeOut?: Maybe<Scalars['Decimal']>;
  occupancyRate?: Maybe<Scalars['Decimal']>;
  occupiedUnits?: Maybe<Scalars['Decimal']>;
  renewalCount?: Maybe<Scalars['Decimal']>;
  renewalRate?: Maybe<Scalars['Decimal']>;
  renewalRatio?: Maybe<Scalars['Decimal']>;
  renewalRent?: Maybe<Scalars['Decimal']>;
  renewalRentCount?: Maybe<Scalars['Float']>;
  totalMoveOutCount?: Maybe<Scalars['Decimal']>;
  unitCount?: Maybe<Scalars['Float']>;
};

export type UnitTypeScheduleValueCountAggregate = {
  __typename?: 'UnitTypeScheduleValueCountAggregate';
  _all: Scalars['Int'];
  averageMarketRent: Scalars['Int'];
  averageResidentialRent: Scalars['Int'];
  averageSquareFeet: Scalars['Int'];
  combinedTradeOut: Scalars['Int'];
  createdAt: Scalars['Int'];
  earlyTerminationCount: Scalars['Int'];
  entrataUnitTypeId: Scalars['Int'];
  id: Scalars['Int'];
  leaseExpirationCount: Scalars['Int'];
  monthIndex: Scalars['Int'];
  monthToMonthCount: Scalars['Int'];
  monthToMonthMoveOutCount: Scalars['Int'];
  moveInCount: Scalars['Int'];
  moveOutCount: Scalars['Int'];
  moveOutRate: Scalars['Int'];
  newLeaseRent: Scalars['Int'];
  newLeaseRentCount: Scalars['Int'];
  newLeaseTradeOut: Scalars['Int'];
  occupancyRate: Scalars['Int'];
  occupiedUnits: Scalars['Int'];
  propertyId: Scalars['Int'];
  renewalCount: Scalars['Int'];
  renewalRate: Scalars['Int'];
  renewalRatio: Scalars['Int'];
  renewalRent: Scalars['Int'];
  renewalRentCount: Scalars['Int'];
  totalMoveOutCount: Scalars['Int'];
  unitCount: Scalars['Int'];
  unitTypeId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  versionId: Scalars['Int'];
};

export type UnitTypeScheduleValueCreateInput = {
  averageMarketRent: Scalars['Decimal'];
  averageResidentialRent: Scalars['Decimal'];
  averageSquareFeet: Scalars['Int'];
  combinedTradeOut?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earlyTerminationCount?: Maybe<Scalars['Int']>;
  entrataUnitTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  leaseExpirationCount: Scalars['Int'];
  monthIndex: Scalars['Int'];
  monthToMonthCount?: Maybe<Scalars['Int']>;
  monthToMonthMoveOutCount?: Maybe<Scalars['Int']>;
  moveInCount?: Maybe<Scalars['Decimal']>;
  moveOutCount?: Maybe<Scalars['Decimal']>;
  moveOutRate?: Maybe<Scalars['Decimal']>;
  newLeaseRent?: Maybe<Scalars['Decimal']>;
  newLeaseRentCount?: Maybe<Scalars['Int']>;
  newLeaseTradeOut?: Maybe<Scalars['Decimal']>;
  occupancyRate?: Maybe<Scalars['Decimal']>;
  occupiedUnits?: Maybe<Scalars['Decimal']>;
  property: PropertyCreateNestedOneWithoutUnitTypeScheduleValuesInput;
  renewalCount?: Maybe<Scalars['Decimal']>;
  renewalRate?: Maybe<Scalars['Decimal']>;
  renewalRatio?: Maybe<Scalars['Decimal']>;
  renewalRent?: Maybe<Scalars['Decimal']>;
  renewalRentCount?: Maybe<Scalars['Int']>;
  totalMoveOutCount?: Maybe<Scalars['Decimal']>;
  unitCount: Scalars['Int'];
  unitType: UnitTypeCreateNestedOneWithoutUnitTypeScheduleValuesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version: ValueVersionCreateNestedOneWithoutUnitTypeScheduleValuesInput;
};

export type UnitTypeScheduleValueCreateManyInput = {
  averageMarketRent: Scalars['Decimal'];
  averageResidentialRent: Scalars['Decimal'];
  averageSquareFeet: Scalars['Int'];
  combinedTradeOut?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earlyTerminationCount?: Maybe<Scalars['Int']>;
  entrataUnitTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  leaseExpirationCount: Scalars['Int'];
  monthIndex: Scalars['Int'];
  monthToMonthCount?: Maybe<Scalars['Int']>;
  monthToMonthMoveOutCount?: Maybe<Scalars['Int']>;
  moveInCount?: Maybe<Scalars['Decimal']>;
  moveOutCount?: Maybe<Scalars['Decimal']>;
  moveOutRate?: Maybe<Scalars['Decimal']>;
  newLeaseRent?: Maybe<Scalars['Decimal']>;
  newLeaseRentCount?: Maybe<Scalars['Int']>;
  newLeaseTradeOut?: Maybe<Scalars['Decimal']>;
  occupancyRate?: Maybe<Scalars['Decimal']>;
  occupiedUnits?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  renewalCount?: Maybe<Scalars['Decimal']>;
  renewalRate?: Maybe<Scalars['Decimal']>;
  renewalRatio?: Maybe<Scalars['Decimal']>;
  renewalRent?: Maybe<Scalars['Decimal']>;
  renewalRentCount?: Maybe<Scalars['Int']>;
  totalMoveOutCount?: Maybe<Scalars['Decimal']>;
  unitCount: Scalars['Int'];
  unitTypeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId: Scalars['String'];
};

export type UnitTypeScheduleValueCreateManyPropertyInput = {
  averageMarketRent: Scalars['Decimal'];
  averageResidentialRent: Scalars['Decimal'];
  averageSquareFeet: Scalars['Int'];
  combinedTradeOut?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earlyTerminationCount?: Maybe<Scalars['Int']>;
  entrataUnitTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  leaseExpirationCount: Scalars['Int'];
  monthIndex: Scalars['Int'];
  monthToMonthCount?: Maybe<Scalars['Int']>;
  monthToMonthMoveOutCount?: Maybe<Scalars['Int']>;
  moveInCount?: Maybe<Scalars['Decimal']>;
  moveOutCount?: Maybe<Scalars['Decimal']>;
  moveOutRate?: Maybe<Scalars['Decimal']>;
  newLeaseRent?: Maybe<Scalars['Decimal']>;
  newLeaseRentCount?: Maybe<Scalars['Int']>;
  newLeaseTradeOut?: Maybe<Scalars['Decimal']>;
  occupancyRate?: Maybe<Scalars['Decimal']>;
  occupiedUnits?: Maybe<Scalars['Decimal']>;
  renewalCount?: Maybe<Scalars['Decimal']>;
  renewalRate?: Maybe<Scalars['Decimal']>;
  renewalRatio?: Maybe<Scalars['Decimal']>;
  renewalRent?: Maybe<Scalars['Decimal']>;
  renewalRentCount?: Maybe<Scalars['Int']>;
  totalMoveOutCount?: Maybe<Scalars['Decimal']>;
  unitCount: Scalars['Int'];
  unitTypeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId: Scalars['String'];
};

export type UnitTypeScheduleValueCreateManyPropertyInputEnvelope = {
  data: Array<UnitTypeScheduleValueCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UnitTypeScheduleValueCreateManyUnitTypeInput = {
  averageMarketRent: Scalars['Decimal'];
  averageResidentialRent: Scalars['Decimal'];
  averageSquareFeet: Scalars['Int'];
  combinedTradeOut?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earlyTerminationCount?: Maybe<Scalars['Int']>;
  entrataUnitTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  leaseExpirationCount: Scalars['Int'];
  monthIndex: Scalars['Int'];
  monthToMonthCount?: Maybe<Scalars['Int']>;
  monthToMonthMoveOutCount?: Maybe<Scalars['Int']>;
  moveInCount?: Maybe<Scalars['Decimal']>;
  moveOutCount?: Maybe<Scalars['Decimal']>;
  moveOutRate?: Maybe<Scalars['Decimal']>;
  newLeaseRent?: Maybe<Scalars['Decimal']>;
  newLeaseRentCount?: Maybe<Scalars['Int']>;
  newLeaseTradeOut?: Maybe<Scalars['Decimal']>;
  occupancyRate?: Maybe<Scalars['Decimal']>;
  occupiedUnits?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  renewalCount?: Maybe<Scalars['Decimal']>;
  renewalRate?: Maybe<Scalars['Decimal']>;
  renewalRatio?: Maybe<Scalars['Decimal']>;
  renewalRent?: Maybe<Scalars['Decimal']>;
  renewalRentCount?: Maybe<Scalars['Int']>;
  totalMoveOutCount?: Maybe<Scalars['Decimal']>;
  unitCount: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId: Scalars['String'];
};

export type UnitTypeScheduleValueCreateManyUnitTypeInputEnvelope = {
  data: Array<UnitTypeScheduleValueCreateManyUnitTypeInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UnitTypeScheduleValueCreateManyVersionInput = {
  averageMarketRent: Scalars['Decimal'];
  averageResidentialRent: Scalars['Decimal'];
  averageSquareFeet: Scalars['Int'];
  combinedTradeOut?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earlyTerminationCount?: Maybe<Scalars['Int']>;
  entrataUnitTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  leaseExpirationCount: Scalars['Int'];
  monthIndex: Scalars['Int'];
  monthToMonthCount?: Maybe<Scalars['Int']>;
  monthToMonthMoveOutCount?: Maybe<Scalars['Int']>;
  moveInCount?: Maybe<Scalars['Decimal']>;
  moveOutCount?: Maybe<Scalars['Decimal']>;
  moveOutRate?: Maybe<Scalars['Decimal']>;
  newLeaseRent?: Maybe<Scalars['Decimal']>;
  newLeaseRentCount?: Maybe<Scalars['Int']>;
  newLeaseTradeOut?: Maybe<Scalars['Decimal']>;
  occupancyRate?: Maybe<Scalars['Decimal']>;
  occupiedUnits?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  renewalCount?: Maybe<Scalars['Decimal']>;
  renewalRate?: Maybe<Scalars['Decimal']>;
  renewalRatio?: Maybe<Scalars['Decimal']>;
  renewalRent?: Maybe<Scalars['Decimal']>;
  renewalRentCount?: Maybe<Scalars['Int']>;
  totalMoveOutCount?: Maybe<Scalars['Decimal']>;
  unitCount: Scalars['Int'];
  unitTypeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitTypeScheduleValueCreateManyVersionInputEnvelope = {
  data: Array<UnitTypeScheduleValueCreateManyVersionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UnitTypeScheduleValueCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<UnitTypeScheduleValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UnitTypeScheduleValueCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<UnitTypeScheduleValueCreateWithoutPropertyInput>>;
  createMany?: Maybe<UnitTypeScheduleValueCreateManyPropertyInputEnvelope>;
};

export type UnitTypeScheduleValueCreateNestedManyWithoutUnitTypeInput = {
  connect?: Maybe<Array<UnitTypeScheduleValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UnitTypeScheduleValueCreateOrConnectWithoutUnitTypeInput>>;
  create?: Maybe<Array<UnitTypeScheduleValueCreateWithoutUnitTypeInput>>;
  createMany?: Maybe<UnitTypeScheduleValueCreateManyUnitTypeInputEnvelope>;
};

export type UnitTypeScheduleValueCreateNestedManyWithoutVersionInput = {
  connect?: Maybe<Array<UnitTypeScheduleValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UnitTypeScheduleValueCreateOrConnectWithoutVersionInput>>;
  create?: Maybe<Array<UnitTypeScheduleValueCreateWithoutVersionInput>>;
  createMany?: Maybe<UnitTypeScheduleValueCreateManyVersionInputEnvelope>;
};

export type UnitTypeScheduleValueCreateOrConnectWithoutPropertyInput = {
  create: UnitTypeScheduleValueCreateWithoutPropertyInput;
  where: UnitTypeScheduleValueWhereUniqueInput;
};

export type UnitTypeScheduleValueCreateOrConnectWithoutUnitTypeInput = {
  create: UnitTypeScheduleValueCreateWithoutUnitTypeInput;
  where: UnitTypeScheduleValueWhereUniqueInput;
};

export type UnitTypeScheduleValueCreateOrConnectWithoutVersionInput = {
  create: UnitTypeScheduleValueCreateWithoutVersionInput;
  where: UnitTypeScheduleValueWhereUniqueInput;
};

export type UnitTypeScheduleValueCreateWithoutPropertyInput = {
  averageMarketRent: Scalars['Decimal'];
  averageResidentialRent: Scalars['Decimal'];
  averageSquareFeet: Scalars['Int'];
  combinedTradeOut?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earlyTerminationCount?: Maybe<Scalars['Int']>;
  entrataUnitTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  leaseExpirationCount: Scalars['Int'];
  monthIndex: Scalars['Int'];
  monthToMonthCount?: Maybe<Scalars['Int']>;
  monthToMonthMoveOutCount?: Maybe<Scalars['Int']>;
  moveInCount?: Maybe<Scalars['Decimal']>;
  moveOutCount?: Maybe<Scalars['Decimal']>;
  moveOutRate?: Maybe<Scalars['Decimal']>;
  newLeaseRent?: Maybe<Scalars['Decimal']>;
  newLeaseRentCount?: Maybe<Scalars['Int']>;
  newLeaseTradeOut?: Maybe<Scalars['Decimal']>;
  occupancyRate?: Maybe<Scalars['Decimal']>;
  occupiedUnits?: Maybe<Scalars['Decimal']>;
  renewalCount?: Maybe<Scalars['Decimal']>;
  renewalRate?: Maybe<Scalars['Decimal']>;
  renewalRatio?: Maybe<Scalars['Decimal']>;
  renewalRent?: Maybe<Scalars['Decimal']>;
  renewalRentCount?: Maybe<Scalars['Int']>;
  totalMoveOutCount?: Maybe<Scalars['Decimal']>;
  unitCount: Scalars['Int'];
  unitType: UnitTypeCreateNestedOneWithoutUnitTypeScheduleValuesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version: ValueVersionCreateNestedOneWithoutUnitTypeScheduleValuesInput;
};

export type UnitTypeScheduleValueCreateWithoutUnitTypeInput = {
  averageMarketRent: Scalars['Decimal'];
  averageResidentialRent: Scalars['Decimal'];
  averageSquareFeet: Scalars['Int'];
  combinedTradeOut?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earlyTerminationCount?: Maybe<Scalars['Int']>;
  entrataUnitTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  leaseExpirationCount: Scalars['Int'];
  monthIndex: Scalars['Int'];
  monthToMonthCount?: Maybe<Scalars['Int']>;
  monthToMonthMoveOutCount?: Maybe<Scalars['Int']>;
  moveInCount?: Maybe<Scalars['Decimal']>;
  moveOutCount?: Maybe<Scalars['Decimal']>;
  moveOutRate?: Maybe<Scalars['Decimal']>;
  newLeaseRent?: Maybe<Scalars['Decimal']>;
  newLeaseRentCount?: Maybe<Scalars['Int']>;
  newLeaseTradeOut?: Maybe<Scalars['Decimal']>;
  occupancyRate?: Maybe<Scalars['Decimal']>;
  occupiedUnits?: Maybe<Scalars['Decimal']>;
  property: PropertyCreateNestedOneWithoutUnitTypeScheduleValuesInput;
  renewalCount?: Maybe<Scalars['Decimal']>;
  renewalRate?: Maybe<Scalars['Decimal']>;
  renewalRatio?: Maybe<Scalars['Decimal']>;
  renewalRent?: Maybe<Scalars['Decimal']>;
  renewalRentCount?: Maybe<Scalars['Int']>;
  totalMoveOutCount?: Maybe<Scalars['Decimal']>;
  unitCount: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  version: ValueVersionCreateNestedOneWithoutUnitTypeScheduleValuesInput;
};

export type UnitTypeScheduleValueCreateWithoutVersionInput = {
  averageMarketRent: Scalars['Decimal'];
  averageResidentialRent: Scalars['Decimal'];
  averageSquareFeet: Scalars['Int'];
  combinedTradeOut?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earlyTerminationCount?: Maybe<Scalars['Int']>;
  entrataUnitTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  leaseExpirationCount: Scalars['Int'];
  monthIndex: Scalars['Int'];
  monthToMonthCount?: Maybe<Scalars['Int']>;
  monthToMonthMoveOutCount?: Maybe<Scalars['Int']>;
  moveInCount?: Maybe<Scalars['Decimal']>;
  moveOutCount?: Maybe<Scalars['Decimal']>;
  moveOutRate?: Maybe<Scalars['Decimal']>;
  newLeaseRent?: Maybe<Scalars['Decimal']>;
  newLeaseRentCount?: Maybe<Scalars['Int']>;
  newLeaseTradeOut?: Maybe<Scalars['Decimal']>;
  occupancyRate?: Maybe<Scalars['Decimal']>;
  occupiedUnits?: Maybe<Scalars['Decimal']>;
  property: PropertyCreateNestedOneWithoutUnitTypeScheduleValuesInput;
  renewalCount?: Maybe<Scalars['Decimal']>;
  renewalRate?: Maybe<Scalars['Decimal']>;
  renewalRatio?: Maybe<Scalars['Decimal']>;
  renewalRent?: Maybe<Scalars['Decimal']>;
  renewalRentCount?: Maybe<Scalars['Int']>;
  totalMoveOutCount?: Maybe<Scalars['Decimal']>;
  unitCount: Scalars['Int'];
  unitType: UnitTypeCreateNestedOneWithoutUnitTypeScheduleValuesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UnitTypeScheduleValueGroupBy = {
  __typename?: 'UnitTypeScheduleValueGroupBy';
  _avg?: Maybe<UnitTypeScheduleValueAvgAggregate>;
  _count?: Maybe<UnitTypeScheduleValueCountAggregate>;
  _max?: Maybe<UnitTypeScheduleValueMaxAggregate>;
  _min?: Maybe<UnitTypeScheduleValueMinAggregate>;
  _sum?: Maybe<UnitTypeScheduleValueSumAggregate>;
  averageMarketRent: Scalars['Decimal'];
  averageResidentialRent: Scalars['Decimal'];
  averageSquareFeet: Scalars['Int'];
  combinedTradeOut?: Maybe<Scalars['Decimal']>;
  createdAt: Scalars['DateTime'];
  earlyTerminationCount?: Maybe<Scalars['Int']>;
  entrataUnitTypeId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  leaseExpirationCount: Scalars['Int'];
  monthIndex: Scalars['Int'];
  monthToMonthCount?: Maybe<Scalars['Int']>;
  monthToMonthMoveOutCount?: Maybe<Scalars['Int']>;
  moveInCount?: Maybe<Scalars['Decimal']>;
  moveOutCount?: Maybe<Scalars['Decimal']>;
  moveOutRate?: Maybe<Scalars['Decimal']>;
  newLeaseRent?: Maybe<Scalars['Decimal']>;
  newLeaseRentCount?: Maybe<Scalars['Int']>;
  newLeaseTradeOut?: Maybe<Scalars['Decimal']>;
  occupancyRate?: Maybe<Scalars['Decimal']>;
  occupiedUnits?: Maybe<Scalars['Decimal']>;
  propertyId: Scalars['String'];
  renewalCount?: Maybe<Scalars['Decimal']>;
  renewalRate?: Maybe<Scalars['Decimal']>;
  renewalRatio?: Maybe<Scalars['Decimal']>;
  renewalRent?: Maybe<Scalars['Decimal']>;
  renewalRentCount?: Maybe<Scalars['Int']>;
  totalMoveOutCount?: Maybe<Scalars['Decimal']>;
  unitCount: Scalars['Int'];
  unitTypeId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  versionId: Scalars['String'];
};

export type UnitTypeScheduleValueListRelationFilter = {
  every?: Maybe<UnitTypeScheduleValueWhereInput>;
  none?: Maybe<UnitTypeScheduleValueWhereInput>;
  some?: Maybe<UnitTypeScheduleValueWhereInput>;
};

export type UnitTypeScheduleValueMaxAggregate = {
  __typename?: 'UnitTypeScheduleValueMaxAggregate';
  averageMarketRent?: Maybe<Scalars['Decimal']>;
  averageResidentialRent?: Maybe<Scalars['Decimal']>;
  averageSquareFeet?: Maybe<Scalars['Int']>;
  combinedTradeOut?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earlyTerminationCount?: Maybe<Scalars['Int']>;
  entrataUnitTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  leaseExpirationCount?: Maybe<Scalars['Int']>;
  monthIndex?: Maybe<Scalars['Int']>;
  monthToMonthCount?: Maybe<Scalars['Int']>;
  monthToMonthMoveOutCount?: Maybe<Scalars['Int']>;
  moveInCount?: Maybe<Scalars['Decimal']>;
  moveOutCount?: Maybe<Scalars['Decimal']>;
  moveOutRate?: Maybe<Scalars['Decimal']>;
  newLeaseRent?: Maybe<Scalars['Decimal']>;
  newLeaseRentCount?: Maybe<Scalars['Int']>;
  newLeaseTradeOut?: Maybe<Scalars['Decimal']>;
  occupancyRate?: Maybe<Scalars['Decimal']>;
  occupiedUnits?: Maybe<Scalars['Decimal']>;
  propertyId?: Maybe<Scalars['String']>;
  renewalCount?: Maybe<Scalars['Decimal']>;
  renewalRate?: Maybe<Scalars['Decimal']>;
  renewalRatio?: Maybe<Scalars['Decimal']>;
  renewalRent?: Maybe<Scalars['Decimal']>;
  renewalRentCount?: Maybe<Scalars['Int']>;
  totalMoveOutCount?: Maybe<Scalars['Decimal']>;
  unitCount?: Maybe<Scalars['Int']>;
  unitTypeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId?: Maybe<Scalars['String']>;
};

export type UnitTypeScheduleValueMinAggregate = {
  __typename?: 'UnitTypeScheduleValueMinAggregate';
  averageMarketRent?: Maybe<Scalars['Decimal']>;
  averageResidentialRent?: Maybe<Scalars['Decimal']>;
  averageSquareFeet?: Maybe<Scalars['Int']>;
  combinedTradeOut?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  earlyTerminationCount?: Maybe<Scalars['Int']>;
  entrataUnitTypeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  leaseExpirationCount?: Maybe<Scalars['Int']>;
  monthIndex?: Maybe<Scalars['Int']>;
  monthToMonthCount?: Maybe<Scalars['Int']>;
  monthToMonthMoveOutCount?: Maybe<Scalars['Int']>;
  moveInCount?: Maybe<Scalars['Decimal']>;
  moveOutCount?: Maybe<Scalars['Decimal']>;
  moveOutRate?: Maybe<Scalars['Decimal']>;
  newLeaseRent?: Maybe<Scalars['Decimal']>;
  newLeaseRentCount?: Maybe<Scalars['Int']>;
  newLeaseTradeOut?: Maybe<Scalars['Decimal']>;
  occupancyRate?: Maybe<Scalars['Decimal']>;
  occupiedUnits?: Maybe<Scalars['Decimal']>;
  propertyId?: Maybe<Scalars['String']>;
  renewalCount?: Maybe<Scalars['Decimal']>;
  renewalRate?: Maybe<Scalars['Decimal']>;
  renewalRatio?: Maybe<Scalars['Decimal']>;
  renewalRent?: Maybe<Scalars['Decimal']>;
  renewalRentCount?: Maybe<Scalars['Int']>;
  totalMoveOutCount?: Maybe<Scalars['Decimal']>;
  unitCount?: Maybe<Scalars['Int']>;
  unitTypeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId?: Maybe<Scalars['String']>;
};

export type UnitTypeScheduleValueOrderByInput = {
  averageMarketRent?: Maybe<SortOrder>;
  averageResidentialRent?: Maybe<SortOrder>;
  averageSquareFeet?: Maybe<SortOrder>;
  combinedTradeOut?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  earlyTerminationCount?: Maybe<SortOrder>;
  entrataUnitTypeId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  leaseExpirationCount?: Maybe<SortOrder>;
  monthIndex?: Maybe<SortOrder>;
  monthToMonthCount?: Maybe<SortOrder>;
  monthToMonthMoveOutCount?: Maybe<SortOrder>;
  moveInCount?: Maybe<SortOrder>;
  moveOutCount?: Maybe<SortOrder>;
  moveOutRate?: Maybe<SortOrder>;
  newLeaseRent?: Maybe<SortOrder>;
  newLeaseRentCount?: Maybe<SortOrder>;
  newLeaseTradeOut?: Maybe<SortOrder>;
  occupancyRate?: Maybe<SortOrder>;
  occupiedUnits?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  renewalCount?: Maybe<SortOrder>;
  renewalRate?: Maybe<SortOrder>;
  renewalRatio?: Maybe<SortOrder>;
  renewalRent?: Maybe<SortOrder>;
  renewalRentCount?: Maybe<SortOrder>;
  totalMoveOutCount?: Maybe<SortOrder>;
  unitCount?: Maybe<SortOrder>;
  unitTypeId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  versionId?: Maybe<SortOrder>;
};

export type UnitTypeScheduleValuePropertyIdVersionIdMonthIndexUnitTypeIdCompoundUniqueInput = {
  monthIndex: Scalars['Int'];
  propertyId: Scalars['String'];
  unitTypeId: Scalars['String'];
  versionId: Scalars['String'];
};

export enum UnitTypeScheduleValueScalarFieldEnum {
  AverageMarketRent = 'averageMarketRent',
  AverageResidentialRent = 'averageResidentialRent',
  AverageSquareFeet = 'averageSquareFeet',
  CombinedTradeOut = 'combinedTradeOut',
  CreatedAt = 'createdAt',
  EarlyTerminationCount = 'earlyTerminationCount',
  EntrataUnitTypeId = 'entrataUnitTypeId',
  Id = 'id',
  LeaseExpirationCount = 'leaseExpirationCount',
  MonthIndex = 'monthIndex',
  MonthToMonthCount = 'monthToMonthCount',
  MonthToMonthMoveOutCount = 'monthToMonthMoveOutCount',
  MoveInCount = 'moveInCount',
  MoveOutCount = 'moveOutCount',
  MoveOutRate = 'moveOutRate',
  NewLeaseRent = 'newLeaseRent',
  NewLeaseRentCount = 'newLeaseRentCount',
  NewLeaseTradeOut = 'newLeaseTradeOut',
  OccupancyRate = 'occupancyRate',
  OccupiedUnits = 'occupiedUnits',
  PropertyId = 'propertyId',
  RenewalCount = 'renewalCount',
  RenewalRate = 'renewalRate',
  RenewalRatio = 'renewalRatio',
  RenewalRent = 'renewalRent',
  RenewalRentCount = 'renewalRentCount',
  TotalMoveOutCount = 'totalMoveOutCount',
  UnitCount = 'unitCount',
  UnitTypeId = 'unitTypeId',
  UpdatedAt = 'updatedAt',
  VersionId = 'versionId'
}

export type UnitTypeScheduleValueScalarWhereInput = {
  AND?: Maybe<Array<UnitTypeScheduleValueScalarWhereInput>>;
  NOT?: Maybe<Array<UnitTypeScheduleValueScalarWhereInput>>;
  OR?: Maybe<Array<UnitTypeScheduleValueScalarWhereInput>>;
  averageMarketRent?: Maybe<DecimalFilter>;
  averageResidentialRent?: Maybe<DecimalFilter>;
  averageSquareFeet?: Maybe<IntFilter>;
  combinedTradeOut?: Maybe<DecimalNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  earlyTerminationCount?: Maybe<IntNullableFilter>;
  entrataUnitTypeId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  leaseExpirationCount?: Maybe<IntFilter>;
  monthIndex?: Maybe<IntFilter>;
  monthToMonthCount?: Maybe<IntNullableFilter>;
  monthToMonthMoveOutCount?: Maybe<IntNullableFilter>;
  moveInCount?: Maybe<DecimalNullableFilter>;
  moveOutCount?: Maybe<DecimalNullableFilter>;
  moveOutRate?: Maybe<DecimalNullableFilter>;
  newLeaseRent?: Maybe<DecimalNullableFilter>;
  newLeaseRentCount?: Maybe<IntNullableFilter>;
  newLeaseTradeOut?: Maybe<DecimalNullableFilter>;
  occupancyRate?: Maybe<DecimalNullableFilter>;
  occupiedUnits?: Maybe<DecimalNullableFilter>;
  propertyId?: Maybe<StringFilter>;
  renewalCount?: Maybe<DecimalNullableFilter>;
  renewalRate?: Maybe<DecimalNullableFilter>;
  renewalRatio?: Maybe<DecimalNullableFilter>;
  renewalRent?: Maybe<DecimalNullableFilter>;
  renewalRentCount?: Maybe<IntNullableFilter>;
  totalMoveOutCount?: Maybe<DecimalNullableFilter>;
  unitCount?: Maybe<IntFilter>;
  unitTypeId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionId?: Maybe<StringFilter>;
};

export type UnitTypeScheduleValueScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<UnitTypeScheduleValueScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<UnitTypeScheduleValueScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<UnitTypeScheduleValueScalarWhereWithAggregatesInput>>;
  averageMarketRent?: Maybe<DecimalWithAggregatesFilter>;
  averageResidentialRent?: Maybe<DecimalWithAggregatesFilter>;
  averageSquareFeet?: Maybe<IntWithAggregatesFilter>;
  combinedTradeOut?: Maybe<DecimalNullableWithAggregatesFilter>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  earlyTerminationCount?: Maybe<IntNullableWithAggregatesFilter>;
  entrataUnitTypeId?: Maybe<StringNullableWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  leaseExpirationCount?: Maybe<IntWithAggregatesFilter>;
  monthIndex?: Maybe<IntWithAggregatesFilter>;
  monthToMonthCount?: Maybe<IntNullableWithAggregatesFilter>;
  monthToMonthMoveOutCount?: Maybe<IntNullableWithAggregatesFilter>;
  moveInCount?: Maybe<DecimalNullableWithAggregatesFilter>;
  moveOutCount?: Maybe<DecimalNullableWithAggregatesFilter>;
  moveOutRate?: Maybe<DecimalNullableWithAggregatesFilter>;
  newLeaseRent?: Maybe<DecimalNullableWithAggregatesFilter>;
  newLeaseRentCount?: Maybe<IntNullableWithAggregatesFilter>;
  newLeaseTradeOut?: Maybe<DecimalNullableWithAggregatesFilter>;
  occupancyRate?: Maybe<DecimalNullableWithAggregatesFilter>;
  occupiedUnits?: Maybe<DecimalNullableWithAggregatesFilter>;
  propertyId?: Maybe<StringWithAggregatesFilter>;
  renewalCount?: Maybe<DecimalNullableWithAggregatesFilter>;
  renewalRate?: Maybe<DecimalNullableWithAggregatesFilter>;
  renewalRatio?: Maybe<DecimalNullableWithAggregatesFilter>;
  renewalRent?: Maybe<DecimalNullableWithAggregatesFilter>;
  renewalRentCount?: Maybe<IntNullableWithAggregatesFilter>;
  totalMoveOutCount?: Maybe<DecimalNullableWithAggregatesFilter>;
  unitCount?: Maybe<IntWithAggregatesFilter>;
  unitTypeId?: Maybe<StringWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  versionId?: Maybe<StringWithAggregatesFilter>;
};

export type UnitTypeScheduleValueSumAggregate = {
  __typename?: 'UnitTypeScheduleValueSumAggregate';
  averageMarketRent?: Maybe<Scalars['Decimal']>;
  averageResidentialRent?: Maybe<Scalars['Decimal']>;
  averageSquareFeet?: Maybe<Scalars['Int']>;
  combinedTradeOut?: Maybe<Scalars['Decimal']>;
  earlyTerminationCount?: Maybe<Scalars['Int']>;
  leaseExpirationCount?: Maybe<Scalars['Int']>;
  monthIndex?: Maybe<Scalars['Int']>;
  monthToMonthCount?: Maybe<Scalars['Int']>;
  monthToMonthMoveOutCount?: Maybe<Scalars['Int']>;
  moveInCount?: Maybe<Scalars['Decimal']>;
  moveOutCount?: Maybe<Scalars['Decimal']>;
  moveOutRate?: Maybe<Scalars['Decimal']>;
  newLeaseRent?: Maybe<Scalars['Decimal']>;
  newLeaseRentCount?: Maybe<Scalars['Int']>;
  newLeaseTradeOut?: Maybe<Scalars['Decimal']>;
  occupancyRate?: Maybe<Scalars['Decimal']>;
  occupiedUnits?: Maybe<Scalars['Decimal']>;
  renewalCount?: Maybe<Scalars['Decimal']>;
  renewalRate?: Maybe<Scalars['Decimal']>;
  renewalRatio?: Maybe<Scalars['Decimal']>;
  renewalRent?: Maybe<Scalars['Decimal']>;
  renewalRentCount?: Maybe<Scalars['Int']>;
  totalMoveOutCount?: Maybe<Scalars['Decimal']>;
  unitCount?: Maybe<Scalars['Int']>;
};

export type UnitTypeScheduleValueUpdateInput = {
  averageMarketRent?: Maybe<DecimalFieldUpdateOperationsInput>;
  averageResidentialRent?: Maybe<DecimalFieldUpdateOperationsInput>;
  averageSquareFeet?: Maybe<IntFieldUpdateOperationsInput>;
  combinedTradeOut?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  earlyTerminationCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  entrataUnitTypeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  leaseExpirationCount?: Maybe<IntFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  monthToMonthCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  monthToMonthMoveOutCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  moveInCount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  moveOutCount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  moveOutRate?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  newLeaseRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  newLeaseRentCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  newLeaseTradeOut?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  occupancyRate?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  occupiedUnits?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutUnitTypeScheduleValuesInput>;
  renewalCount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRate?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRatio?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRentCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  totalMoveOutCount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  unitCount?: Maybe<IntFieldUpdateOperationsInput>;
  unitType?: Maybe<UnitTypeUpdateOneRequiredWithoutUnitTypeScheduleValuesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  version?: Maybe<ValueVersionUpdateOneRequiredWithoutUnitTypeScheduleValuesInput>;
};

export type UnitTypeScheduleValueUpdateManyMutationInput = {
  averageMarketRent?: Maybe<DecimalFieldUpdateOperationsInput>;
  averageResidentialRent?: Maybe<DecimalFieldUpdateOperationsInput>;
  averageSquareFeet?: Maybe<IntFieldUpdateOperationsInput>;
  combinedTradeOut?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  earlyTerminationCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  entrataUnitTypeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  leaseExpirationCount?: Maybe<IntFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  monthToMonthCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  monthToMonthMoveOutCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  moveInCount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  moveOutCount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  moveOutRate?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  newLeaseRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  newLeaseRentCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  newLeaseTradeOut?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  occupancyRate?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  occupiedUnits?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalCount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRate?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRatio?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRentCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  totalMoveOutCount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  unitCount?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UnitTypeScheduleValueUpdateManyWithWhereWithoutPropertyInput = {
  data: UnitTypeScheduleValueUpdateManyMutationInput;
  where: UnitTypeScheduleValueScalarWhereInput;
};

export type UnitTypeScheduleValueUpdateManyWithWhereWithoutUnitTypeInput = {
  data: UnitTypeScheduleValueUpdateManyMutationInput;
  where: UnitTypeScheduleValueScalarWhereInput;
};

export type UnitTypeScheduleValueUpdateManyWithWhereWithoutVersionInput = {
  data: UnitTypeScheduleValueUpdateManyMutationInput;
  where: UnitTypeScheduleValueScalarWhereInput;
};

export type UnitTypeScheduleValueUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<UnitTypeScheduleValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UnitTypeScheduleValueCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<UnitTypeScheduleValueCreateWithoutPropertyInput>>;
  createMany?: Maybe<UnitTypeScheduleValueCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<UnitTypeScheduleValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UnitTypeScheduleValueScalarWhereInput>>;
  disconnect?: Maybe<Array<UnitTypeScheduleValueWhereUniqueInput>>;
  set?: Maybe<Array<UnitTypeScheduleValueWhereUniqueInput>>;
  update?: Maybe<Array<UnitTypeScheduleValueUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<UnitTypeScheduleValueUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<UnitTypeScheduleValueUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type UnitTypeScheduleValueUpdateManyWithoutUnitTypeInput = {
  connect?: Maybe<Array<UnitTypeScheduleValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UnitTypeScheduleValueCreateOrConnectWithoutUnitTypeInput>>;
  create?: Maybe<Array<UnitTypeScheduleValueCreateWithoutUnitTypeInput>>;
  createMany?: Maybe<UnitTypeScheduleValueCreateManyUnitTypeInputEnvelope>;
  delete?: Maybe<Array<UnitTypeScheduleValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UnitTypeScheduleValueScalarWhereInput>>;
  disconnect?: Maybe<Array<UnitTypeScheduleValueWhereUniqueInput>>;
  set?: Maybe<Array<UnitTypeScheduleValueWhereUniqueInput>>;
  update?: Maybe<Array<UnitTypeScheduleValueUpdateWithWhereUniqueWithoutUnitTypeInput>>;
  updateMany?: Maybe<Array<UnitTypeScheduleValueUpdateManyWithWhereWithoutUnitTypeInput>>;
  upsert?: Maybe<Array<UnitTypeScheduleValueUpsertWithWhereUniqueWithoutUnitTypeInput>>;
};

export type UnitTypeScheduleValueUpdateManyWithoutVersionInput = {
  connect?: Maybe<Array<UnitTypeScheduleValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UnitTypeScheduleValueCreateOrConnectWithoutVersionInput>>;
  create?: Maybe<Array<UnitTypeScheduleValueCreateWithoutVersionInput>>;
  createMany?: Maybe<UnitTypeScheduleValueCreateManyVersionInputEnvelope>;
  delete?: Maybe<Array<UnitTypeScheduleValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UnitTypeScheduleValueScalarWhereInput>>;
  disconnect?: Maybe<Array<UnitTypeScheduleValueWhereUniqueInput>>;
  set?: Maybe<Array<UnitTypeScheduleValueWhereUniqueInput>>;
  update?: Maybe<Array<UnitTypeScheduleValueUpdateWithWhereUniqueWithoutVersionInput>>;
  updateMany?: Maybe<Array<UnitTypeScheduleValueUpdateManyWithWhereWithoutVersionInput>>;
  upsert?: Maybe<Array<UnitTypeScheduleValueUpsertWithWhereUniqueWithoutVersionInput>>;
};

export type UnitTypeScheduleValueUpdateWithWhereUniqueWithoutPropertyInput = {
  data: UnitTypeScheduleValueUpdateWithoutPropertyInput;
  where: UnitTypeScheduleValueWhereUniqueInput;
};

export type UnitTypeScheduleValueUpdateWithWhereUniqueWithoutUnitTypeInput = {
  data: UnitTypeScheduleValueUpdateWithoutUnitTypeInput;
  where: UnitTypeScheduleValueWhereUniqueInput;
};

export type UnitTypeScheduleValueUpdateWithWhereUniqueWithoutVersionInput = {
  data: UnitTypeScheduleValueUpdateWithoutVersionInput;
  where: UnitTypeScheduleValueWhereUniqueInput;
};

export type UnitTypeScheduleValueUpdateWithoutPropertyInput = {
  averageMarketRent?: Maybe<DecimalFieldUpdateOperationsInput>;
  averageResidentialRent?: Maybe<DecimalFieldUpdateOperationsInput>;
  averageSquareFeet?: Maybe<IntFieldUpdateOperationsInput>;
  combinedTradeOut?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  earlyTerminationCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  entrataUnitTypeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  leaseExpirationCount?: Maybe<IntFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  monthToMonthCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  monthToMonthMoveOutCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  moveInCount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  moveOutCount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  moveOutRate?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  newLeaseRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  newLeaseRentCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  newLeaseTradeOut?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  occupancyRate?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  occupiedUnits?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalCount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRate?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRatio?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRentCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  totalMoveOutCount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  unitCount?: Maybe<IntFieldUpdateOperationsInput>;
  unitType?: Maybe<UnitTypeUpdateOneRequiredWithoutUnitTypeScheduleValuesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  version?: Maybe<ValueVersionUpdateOneRequiredWithoutUnitTypeScheduleValuesInput>;
};

export type UnitTypeScheduleValueUpdateWithoutUnitTypeInput = {
  averageMarketRent?: Maybe<DecimalFieldUpdateOperationsInput>;
  averageResidentialRent?: Maybe<DecimalFieldUpdateOperationsInput>;
  averageSquareFeet?: Maybe<IntFieldUpdateOperationsInput>;
  combinedTradeOut?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  earlyTerminationCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  entrataUnitTypeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  leaseExpirationCount?: Maybe<IntFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  monthToMonthCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  monthToMonthMoveOutCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  moveInCount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  moveOutCount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  moveOutRate?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  newLeaseRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  newLeaseRentCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  newLeaseTradeOut?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  occupancyRate?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  occupiedUnits?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutUnitTypeScheduleValuesInput>;
  renewalCount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRate?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRatio?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRentCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  totalMoveOutCount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  unitCount?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  version?: Maybe<ValueVersionUpdateOneRequiredWithoutUnitTypeScheduleValuesInput>;
};

export type UnitTypeScheduleValueUpdateWithoutVersionInput = {
  averageMarketRent?: Maybe<DecimalFieldUpdateOperationsInput>;
  averageResidentialRent?: Maybe<DecimalFieldUpdateOperationsInput>;
  averageSquareFeet?: Maybe<IntFieldUpdateOperationsInput>;
  combinedTradeOut?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  earlyTerminationCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  entrataUnitTypeId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  leaseExpirationCount?: Maybe<IntFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  monthToMonthCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  monthToMonthMoveOutCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  moveInCount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  moveOutCount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  moveOutRate?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  newLeaseRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  newLeaseRentCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  newLeaseTradeOut?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  occupancyRate?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  occupiedUnits?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutUnitTypeScheduleValuesInput>;
  renewalCount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRate?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRatio?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRent?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  renewalRentCount?: Maybe<NullableIntFieldUpdateOperationsInput>;
  totalMoveOutCount?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  unitCount?: Maybe<IntFieldUpdateOperationsInput>;
  unitType?: Maybe<UnitTypeUpdateOneRequiredWithoutUnitTypeScheduleValuesInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UnitTypeScheduleValueUpsertInput = {
  monthIndex: Scalars['Int'];
  unitTypeName: Scalars['String'];
  value: Scalars['Decimal'];
};

export type UnitTypeScheduleValueUpsertWithWhereUniqueWithoutPropertyInput = {
  create: UnitTypeScheduleValueCreateWithoutPropertyInput;
  update: UnitTypeScheduleValueUpdateWithoutPropertyInput;
  where: UnitTypeScheduleValueWhereUniqueInput;
};

export type UnitTypeScheduleValueUpsertWithWhereUniqueWithoutUnitTypeInput = {
  create: UnitTypeScheduleValueCreateWithoutUnitTypeInput;
  update: UnitTypeScheduleValueUpdateWithoutUnitTypeInput;
  where: UnitTypeScheduleValueWhereUniqueInput;
};

export type UnitTypeScheduleValueUpsertWithWhereUniqueWithoutVersionInput = {
  create: UnitTypeScheduleValueCreateWithoutVersionInput;
  update: UnitTypeScheduleValueUpdateWithoutVersionInput;
  where: UnitTypeScheduleValueWhereUniqueInput;
};

export type UnitTypeScheduleValueWhereInput = {
  AND?: Maybe<Array<UnitTypeScheduleValueWhereInput>>;
  NOT?: Maybe<Array<UnitTypeScheduleValueWhereInput>>;
  OR?: Maybe<Array<UnitTypeScheduleValueWhereInput>>;
  averageMarketRent?: Maybe<DecimalFilter>;
  averageResidentialRent?: Maybe<DecimalFilter>;
  averageSquareFeet?: Maybe<IntFilter>;
  combinedTradeOut?: Maybe<DecimalNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  earlyTerminationCount?: Maybe<IntNullableFilter>;
  entrataUnitTypeId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  leaseExpirationCount?: Maybe<IntFilter>;
  monthIndex?: Maybe<IntFilter>;
  monthToMonthCount?: Maybe<IntNullableFilter>;
  monthToMonthMoveOutCount?: Maybe<IntNullableFilter>;
  moveInCount?: Maybe<DecimalNullableFilter>;
  moveOutCount?: Maybe<DecimalNullableFilter>;
  moveOutRate?: Maybe<DecimalNullableFilter>;
  newLeaseRent?: Maybe<DecimalNullableFilter>;
  newLeaseRentCount?: Maybe<IntNullableFilter>;
  newLeaseTradeOut?: Maybe<DecimalNullableFilter>;
  occupancyRate?: Maybe<DecimalNullableFilter>;
  occupiedUnits?: Maybe<DecimalNullableFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  renewalCount?: Maybe<DecimalNullableFilter>;
  renewalRate?: Maybe<DecimalNullableFilter>;
  renewalRatio?: Maybe<DecimalNullableFilter>;
  renewalRent?: Maybe<DecimalNullableFilter>;
  renewalRentCount?: Maybe<IntNullableFilter>;
  totalMoveOutCount?: Maybe<DecimalNullableFilter>;
  unitCount?: Maybe<IntFilter>;
  unitType?: Maybe<UnitTypeRelationFilter>;
  unitTypeId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  version?: Maybe<ValueVersionRelationFilter>;
  versionId?: Maybe<StringFilter>;
};

export type UnitTypeScheduleValueWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  propertyId_versionId_monthIndex_unitTypeId?: Maybe<UnitTypeScheduleValuePropertyIdVersionIdMonthIndexUnitTypeIdCompoundUniqueInput>;
};

export type UnitTypeUpdateManyMutationInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UnitTypeUpdateManyWithWhereWithoutPropertyInput = {
  data: UnitTypeUpdateManyMutationInput;
  where: UnitTypeScalarWhereInput;
};

export type UnitTypeUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<UnitTypeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UnitTypeCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<UnitTypeCreateWithoutPropertyInput>>;
  createMany?: Maybe<UnitTypeCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<UnitTypeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UnitTypeScalarWhereInput>>;
  disconnect?: Maybe<Array<UnitTypeWhereUniqueInput>>;
  set?: Maybe<Array<UnitTypeWhereUniqueInput>>;
  update?: Maybe<Array<UnitTypeUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<UnitTypeUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<UnitTypeUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type UnitTypeUpdateOneRequiredWithoutOriginalRevenueMarketRentOverridesInput = {
  connect?: Maybe<UnitTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<UnitTypeCreateOrConnectWithoutOriginalRevenueMarketRentOverridesInput>;
  create?: Maybe<UnitTypeCreateWithoutOriginalRevenueMarketRentOverridesInput>;
  update?: Maybe<UnitTypeUpdateWithoutOriginalRevenueMarketRentOverridesInput>;
  upsert?: Maybe<UnitTypeUpsertWithoutOriginalRevenueMarketRentOverridesInput>;
};

export type UnitTypeUpdateOneRequiredWithoutPmsActualMetricOverrideAverageResidentialRentsInput = {
  connect?: Maybe<UnitTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<UnitTypeCreateOrConnectWithoutPmsActualMetricOverrideAverageResidentialRentsInput>;
  create?: Maybe<UnitTypeCreateWithoutPmsActualMetricOverrideAverageResidentialRentsInput>;
  update?: Maybe<UnitTypeUpdateWithoutPmsActualMetricOverrideAverageResidentialRentsInput>;
  upsert?: Maybe<UnitTypeUpsertWithoutPmsActualMetricOverrideAverageResidentialRentsInput>;
};

export type UnitTypeUpdateOneRequiredWithoutPmsActualMetricOverrideOccupancyRatesInput = {
  connect?: Maybe<UnitTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<UnitTypeCreateOrConnectWithoutPmsActualMetricOverrideOccupancyRatesInput>;
  create?: Maybe<UnitTypeCreateWithoutPmsActualMetricOverrideOccupancyRatesInput>;
  update?: Maybe<UnitTypeUpdateWithoutPmsActualMetricOverrideOccupancyRatesInput>;
  upsert?: Maybe<UnitTypeUpsertWithoutPmsActualMetricOverrideOccupancyRatesInput>;
};

export type UnitTypeUpdateOneRequiredWithoutPmsActualMetricOverrideUnitCountsInput = {
  connect?: Maybe<UnitTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<UnitTypeCreateOrConnectWithoutPmsActualMetricOverrideUnitCountsInput>;
  create?: Maybe<UnitTypeCreateWithoutPmsActualMetricOverrideUnitCountsInput>;
  update?: Maybe<UnitTypeUpdateWithoutPmsActualMetricOverrideUnitCountsInput>;
  upsert?: Maybe<UnitTypeUpsertWithoutPmsActualMetricOverrideUnitCountsInput>;
};

export type UnitTypeUpdateOneRequiredWithoutRenovationPackagesInput = {
  connect?: Maybe<UnitTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<UnitTypeCreateOrConnectWithoutRenovationPackagesInput>;
  create?: Maybe<UnitTypeCreateWithoutRenovationPackagesInput>;
  update?: Maybe<UnitTypeUpdateWithoutRenovationPackagesInput>;
  upsert?: Maybe<UnitTypeUpsertWithoutRenovationPackagesInput>;
};

export type UnitTypeUpdateOneRequiredWithoutUnitTypeScheduleValuesInput = {
  connect?: Maybe<UnitTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<UnitTypeCreateOrConnectWithoutUnitTypeScheduleValuesInput>;
  create?: Maybe<UnitTypeCreateWithoutUnitTypeScheduleValuesInput>;
  update?: Maybe<UnitTypeUpdateWithoutUnitTypeScheduleValuesInput>;
  upsert?: Maybe<UnitTypeUpsertWithoutUnitTypeScheduleValuesInput>;
};

export type UnitTypeUpdateOneRequiredWithoutUnitsInput = {
  connect?: Maybe<UnitTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<UnitTypeCreateOrConnectWithoutUnitsInput>;
  create?: Maybe<UnitTypeCreateWithoutUnitsInput>;
  update?: Maybe<UnitTypeUpdateWithoutUnitsInput>;
  upsert?: Maybe<UnitTypeUpsertWithoutUnitsInput>;
};

export type UnitTypeUpdateOneWithoutUnrenovatedUnitTypeMappingInput = {
  connect?: Maybe<UnitTypeWhereUniqueInput>;
  connectOrCreate?: Maybe<UnitTypeCreateOrConnectWithoutUnrenovatedUnitTypeMappingInput>;
  create?: Maybe<UnitTypeCreateWithoutUnrenovatedUnitTypeMappingInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UnitTypeUpdateWithoutUnrenovatedUnitTypeMappingInput>;
  upsert?: Maybe<UnitTypeUpsertWithoutUnrenovatedUnitTypeMappingInput>;
};

export type UnitTypeUpdateWithWhereUniqueWithoutPropertyInput = {
  data: UnitTypeUpdateWithoutPropertyInput;
  where: UnitTypeWhereUniqueInput;
};

export type UnitTypeUpdateWithoutOriginalRevenueMarketRentOverridesInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutUnitTypeInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutUnitTypesInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutUnitTypeInput>;
  startingMarketRentOverrides?: Maybe<SimplifiedRevenueStartingMarketRentOverrideUpdateManyWithoutUnitTypeInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutUnitTypeInput>;
  units?: Maybe<UnitUpdateManyWithoutUnitTypeInput>;
  unrenovatedUnitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutRenovatedUnitTypeInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UnitTypeUpdateWithoutPmsActualMetricOverrideAverageResidentialRentsInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutUnitTypeInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutUnitTypesInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutUnitTypeInput>;
  startingMarketRentOverrides?: Maybe<SimplifiedRevenueStartingMarketRentOverrideUpdateManyWithoutUnitTypeInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutUnitTypeInput>;
  units?: Maybe<UnitUpdateManyWithoutUnitTypeInput>;
  unrenovatedUnitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutRenovatedUnitTypeInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UnitTypeUpdateWithoutPmsActualMetricOverrideOccupancyRatesInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutUnitTypeInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutUnitTypesInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutUnitTypeInput>;
  startingMarketRentOverrides?: Maybe<SimplifiedRevenueStartingMarketRentOverrideUpdateManyWithoutUnitTypeInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutUnitTypeInput>;
  units?: Maybe<UnitUpdateManyWithoutUnitTypeInput>;
  unrenovatedUnitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutRenovatedUnitTypeInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UnitTypeUpdateWithoutPmsActualMetricOverrideUnitCountsInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutUnitTypeInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutUnitTypesInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutUnitTypeInput>;
  startingMarketRentOverrides?: Maybe<SimplifiedRevenueStartingMarketRentOverrideUpdateManyWithoutUnitTypeInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutUnitTypeInput>;
  units?: Maybe<UnitUpdateManyWithoutUnitTypeInput>;
  unrenovatedUnitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutRenovatedUnitTypeInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UnitTypeUpdateWithoutPropertyInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutUnitTypeInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutUnitTypeInput>;
  startingMarketRentOverrides?: Maybe<SimplifiedRevenueStartingMarketRentOverrideUpdateManyWithoutUnitTypeInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutUnitTypeInput>;
  units?: Maybe<UnitUpdateManyWithoutUnitTypeInput>;
  unrenovatedUnitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutRenovatedUnitTypeInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UnitTypeUpdateWithoutRenovationPackagesInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutUnitTypeInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutUnitTypesInput>;
  startingMarketRentOverrides?: Maybe<SimplifiedRevenueStartingMarketRentOverrideUpdateManyWithoutUnitTypeInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutUnitTypeInput>;
  units?: Maybe<UnitUpdateManyWithoutUnitTypeInput>;
  unrenovatedUnitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutRenovatedUnitTypeInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UnitTypeUpdateWithoutUnitTypeScheduleValuesInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutUnitTypeInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutUnitTypesInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutUnitTypeInput>;
  startingMarketRentOverrides?: Maybe<SimplifiedRevenueStartingMarketRentOverrideUpdateManyWithoutUnitTypeInput>;
  units?: Maybe<UnitUpdateManyWithoutUnitTypeInput>;
  unrenovatedUnitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutRenovatedUnitTypeInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UnitTypeUpdateWithoutUnitsInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutUnitTypeInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutUnitTypesInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutUnitTypeInput>;
  startingMarketRentOverrides?: Maybe<SimplifiedRevenueStartingMarketRentOverrideUpdateManyWithoutUnitTypeInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutUnitTypeInput>;
  unrenovatedUnitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingUpdateManyWithoutRenovatedUnitTypeInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UnitTypeUpdateWithoutUnrenovatedUnitTypeMappingInput = {
  budgetYear?: Maybe<IntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateUpdateManyWithoutUnitTypeInput>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountUpdateManyWithoutUnitTypeInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutUnitTypesInput>;
  renovationPackages?: Maybe<RenovationPackageUpdateManyWithoutUnitTypeInput>;
  startingMarketRentOverrides?: Maybe<SimplifiedRevenueStartingMarketRentOverrideUpdateManyWithoutUnitTypeInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutUnitTypeInput>;
  units?: Maybe<UnitUpdateManyWithoutUnitTypeInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UnitTypeUpsertWithWhereUniqueWithoutPropertyInput = {
  create: UnitTypeCreateWithoutPropertyInput;
  update: UnitTypeUpdateWithoutPropertyInput;
  where: UnitTypeWhereUniqueInput;
};

export type UnitTypeUpsertWithoutOriginalRevenueMarketRentOverridesInput = {
  create: UnitTypeCreateWithoutOriginalRevenueMarketRentOverridesInput;
  update: UnitTypeUpdateWithoutOriginalRevenueMarketRentOverridesInput;
};

export type UnitTypeUpsertWithoutPmsActualMetricOverrideAverageResidentialRentsInput = {
  create: UnitTypeCreateWithoutPmsActualMetricOverrideAverageResidentialRentsInput;
  update: UnitTypeUpdateWithoutPmsActualMetricOverrideAverageResidentialRentsInput;
};

export type UnitTypeUpsertWithoutPmsActualMetricOverrideOccupancyRatesInput = {
  create: UnitTypeCreateWithoutPmsActualMetricOverrideOccupancyRatesInput;
  update: UnitTypeUpdateWithoutPmsActualMetricOverrideOccupancyRatesInput;
};

export type UnitTypeUpsertWithoutPmsActualMetricOverrideUnitCountsInput = {
  create: UnitTypeCreateWithoutPmsActualMetricOverrideUnitCountsInput;
  update: UnitTypeUpdateWithoutPmsActualMetricOverrideUnitCountsInput;
};

export type UnitTypeUpsertWithoutRenovationPackagesInput = {
  create: UnitTypeCreateWithoutRenovationPackagesInput;
  update: UnitTypeUpdateWithoutRenovationPackagesInput;
};

export type UnitTypeUpsertWithoutUnitTypeScheduleValuesInput = {
  create: UnitTypeCreateWithoutUnitTypeScheduleValuesInput;
  update: UnitTypeUpdateWithoutUnitTypeScheduleValuesInput;
};

export type UnitTypeUpsertWithoutUnitsInput = {
  create: UnitTypeCreateWithoutUnitsInput;
  update: UnitTypeUpdateWithoutUnitsInput;
};

export type UnitTypeUpsertWithoutUnrenovatedUnitTypeMappingInput = {
  create: UnitTypeCreateWithoutUnrenovatedUnitTypeMappingInput;
  update: UnitTypeUpdateWithoutUnrenovatedUnitTypeMappingInput;
};

export type UnitTypeViewModel = {
  __typename?: 'UnitTypeViewModel';
  budgetYear: Scalars['Int'];
  createdAt: Scalars['DateNoTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  propertyId: Scalars['String'];
  updatedAt: Scalars['DateNoTime'];
};

export type UnitTypeWhereInput = {
  AND?: Maybe<Array<UnitTypeWhereInput>>;
  NOT?: Maybe<Array<UnitTypeWhereInput>>;
  OR?: Maybe<Array<UnitTypeWhereInput>>;
  budgetYear?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  originalRevenueMarketRentOverrides?: Maybe<OriginalRevenueMarketRentOverrideListRelationFilter>;
  pmsActualMetricOverrideAverageResidentialRents?: Maybe<PmsActualMetricOverrideAverageResidentialRentListRelationFilter>;
  pmsActualMetricOverrideOccupancyRates?: Maybe<PmsActualMetricOverrideOccupancyRateListRelationFilter>;
  pmsActualMetricOverrideUnitCounts?: Maybe<PmsActualMetricOverrideUnitCountListRelationFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  renovationPackages?: Maybe<RenovationPackageListRelationFilter>;
  startingMarketRentOverrides?: Maybe<SimplifiedRevenueStartingMarketRentOverrideListRelationFilter>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueListRelationFilter>;
  units?: Maybe<UnitListRelationFilter>;
  unrenovatedUnitTypeMapping?: Maybe<SimplifiedRevenueUnrenovatedToRenovatedUnitTypeMappingListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type UnitTypeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  propertyId_name_budgetYear?: Maybe<UnitTypePropertyIdNameBudgetYearCompoundUniqueInput>;
};

export type UnitTypeWithOriginalrevenueMarketRentOverridesGranularInput = {
  ovM0?: Maybe<Scalars['Float']>;
  ovM1?: Maybe<Scalars['Float']>;
  ovM2?: Maybe<Scalars['Float']>;
  ovM3?: Maybe<Scalars['Float']>;
  ovM4?: Maybe<Scalars['Float']>;
  ovM5?: Maybe<Scalars['Float']>;
  ovM6?: Maybe<Scalars['Float']>;
  ovM7?: Maybe<Scalars['Float']>;
  ovM8?: Maybe<Scalars['Float']>;
  ovM9?: Maybe<Scalars['Float']>;
  ovM10?: Maybe<Scalars['Float']>;
  ovM11?: Maybe<Scalars['Float']>;
  ovM12?: Maybe<Scalars['Float']>;
  unitTypeId: Scalars['ID'];
};

export type UnitTypeWithOriginalrevenueMarketRentOverridesInput = {
  overrides: Array<Maybe<Scalars['Float']>>;
  unitTypeId: Scalars['ID'];
};

export type UnitUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  entrataUnitId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  number?: Maybe<StringFieldUpdateOperationsInput>;
  sqft?: Maybe<DecimalFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UnitUpdateManyWithWhereWithoutPropertyInput = {
  data: UnitUpdateManyMutationInput;
  where: UnitScalarWhereInput;
};

export type UnitUpdateManyWithWhereWithoutUnitTypeInput = {
  data: UnitUpdateManyMutationInput;
  where: UnitScalarWhereInput;
};

export type UnitUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<UnitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UnitCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<UnitCreateWithoutPropertyInput>>;
  createMany?: Maybe<UnitCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<UnitWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UnitScalarWhereInput>>;
  disconnect?: Maybe<Array<UnitWhereUniqueInput>>;
  set?: Maybe<Array<UnitWhereUniqueInput>>;
  update?: Maybe<Array<UnitUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<UnitUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<UnitUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type UnitUpdateManyWithoutUnitTypeInput = {
  connect?: Maybe<Array<UnitWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UnitCreateOrConnectWithoutUnitTypeInput>>;
  create?: Maybe<Array<UnitCreateWithoutUnitTypeInput>>;
  createMany?: Maybe<UnitCreateManyUnitTypeInputEnvelope>;
  delete?: Maybe<Array<UnitWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UnitScalarWhereInput>>;
  disconnect?: Maybe<Array<UnitWhereUniqueInput>>;
  set?: Maybe<Array<UnitWhereUniqueInput>>;
  update?: Maybe<Array<UnitUpdateWithWhereUniqueWithoutUnitTypeInput>>;
  updateMany?: Maybe<Array<UnitUpdateManyWithWhereWithoutUnitTypeInput>>;
  upsert?: Maybe<Array<UnitUpsertWithWhereUniqueWithoutUnitTypeInput>>;
};

export type UnitUpdateOneRequiredWithoutUnitEventsInput = {
  connect?: Maybe<UnitWhereUniqueInput>;
  connectOrCreate?: Maybe<UnitCreateOrConnectWithoutUnitEventsInput>;
  create?: Maybe<UnitCreateWithoutUnitEventsInput>;
  update?: Maybe<UnitUpdateWithoutUnitEventsInput>;
  upsert?: Maybe<UnitUpsertWithoutUnitEventsInput>;
};

export type UnitUpdateWithWhereUniqueWithoutPropertyInput = {
  data: UnitUpdateWithoutPropertyInput;
  where: UnitWhereUniqueInput;
};

export type UnitUpdateWithWhereUniqueWithoutUnitTypeInput = {
  data: UnitUpdateWithoutUnitTypeInput;
  where: UnitWhereUniqueInput;
};

export type UnitUpdateWithoutPropertyInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  entrataUnitId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  number?: Maybe<StringFieldUpdateOperationsInput>;
  sqft?: Maybe<DecimalFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutUnitInput>;
  unitType?: Maybe<UnitTypeUpdateOneRequiredWithoutUnitsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UnitUpdateWithoutUnitEventsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  entrataUnitId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  number?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutUnitsInput>;
  sqft?: Maybe<DecimalFieldUpdateOperationsInput>;
  unitType?: Maybe<UnitTypeUpdateOneRequiredWithoutUnitsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UnitUpdateWithoutUnitTypeInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  entrataUnitId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  number?: Maybe<StringFieldUpdateOperationsInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutUnitsInput>;
  sqft?: Maybe<DecimalFieldUpdateOperationsInput>;
  unitEvents?: Maybe<UnitEventUpdateManyWithoutUnitInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UnitUpsertWithWhereUniqueWithoutPropertyInput = {
  create: UnitCreateWithoutPropertyInput;
  update: UnitUpdateWithoutPropertyInput;
  where: UnitWhereUniqueInput;
};

export type UnitUpsertWithWhereUniqueWithoutUnitTypeInput = {
  create: UnitCreateWithoutUnitTypeInput;
  update: UnitUpdateWithoutUnitTypeInput;
  where: UnitWhereUniqueInput;
};

export type UnitUpsertWithoutUnitEventsInput = {
  create: UnitCreateWithoutUnitEventsInput;
  update: UnitUpdateWithoutUnitEventsInput;
};

export type UnitWhereInput = {
  AND?: Maybe<Array<UnitWhereInput>>;
  NOT?: Maybe<Array<UnitWhereInput>>;
  OR?: Maybe<Array<UnitWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  entrataUnitId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  number?: Maybe<StringFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  sqft?: Maybe<DecimalFilter>;
  unitEvents?: Maybe<UnitEventListRelationFilter>;
  unitType?: Maybe<UnitTypeRelationFilter>;
  unitTypeId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type UnitWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  propertyId_number_unitTypeId?: Maybe<UnitPropertyIdNumberUnitTypeIdCompoundUniqueInput>;
};

export type UpdateAndRemoveDrivers = {
  account: Array<DriverAccountMetric>;
  accountPercentageAugment?: Maybe<DriverAccountPercentageAugmentInput>;
  annualTargetValue: Array<GrowthDriverInput>;
  customItems?: Maybe<Array<Scalars['String']>>;
  monthlyAverage: Array<GrowthDriverInput>;
  operational: Array<RemoveDriverOperationalMetric>;
  payroll: Array<DriverPayrollMetric>;
  percentGrowth: Array<GrowthDriverInput>;
  revenue: Array<DriverRevenueMetric>;
  worksheet: Array<DriverWorksheetMetric>;
};

export type UpdateRentRollExpiringUnitConversionInput = {
  conversionDate: Scalars['DateNoTime'];
  unitId: Scalars['ID'];
};

export type UpsertAccPercentageAssumption = {
  delayRecognition: Scalars['Int'];
  effectiveEndMonthIndex: Scalars['Int'];
  effectiveEndYear: Scalars['Int'];
  effectiveStartMonthIndex: Scalars['Int'];
  effectiveStartYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  percentOf: Scalars['Decimal'];
};

export type UpsertAccPercentageAssumptions = {
  accPercentageAssumption: UpsertAccPercentageAssumption;
  accPercentageDriverAccountToDriverAssignment: UpsertAccPercentageDriverAccountToDriverAssignmentArgs;
  propertyId: Scalars['String'];
};

export type UpsertAccPercentageDriverAccountToDriverAssignmentArgs = {
  accountId: Scalars['String'];
  driverType: DriverType;
  order: Scalars['Int'];
  propertyId: Scalars['String'];
  sourceAccountId: Scalars['String'];
  versionType: VersionType;
};

export type UpsertOperationMetricAssumption = {
  amount: Scalars['Decimal'];
  delayRecognition: Scalars['Int'];
  effectiveEndMonthIndex: Scalars['Int'];
  effectiveEndYear: Scalars['Int'];
  effectiveStartMonthIndex: Scalars['Int'];
  effectiveStartYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  percentOf: Scalars['Decimal'];
};

export type UpsertOperationMetricAssumptions = {
  operationalDriverMetricTypeToDriverAssignment: UpsertOperationalDriverMetricTypeToDriverAssignmentArgs;
  operationalMetricAssumption: UpsertOperationMetricAssumption;
  propertyId: Scalars['String'];
};

export type UpsertOperationalDriverMetricTypeToDriverAssignmentArgs = {
  accountId: Scalars['String'];
  driverType: DriverType;
  operationalMetricTypeId: Scalars['String'];
  order: Scalars['Int'];
  propertyId: Scalars['String'];
  versionType: VersionType;
};

export type User = {
  __typename?: 'User';
  cognitoSub: Scalars['String'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  isReadOnly: Scalars['Boolean'];
  lastName: Scalars['String'];
  role: UserRole;
  updatedAt: Scalars['DateTime'];
};

export type UserAuthorizationsCreateManyAuthorizationInput = {
  unused?: Maybe<Scalars['Int']>;
  userId: Scalars['String'];
};

export type UserAuthorizationsCreateManyAuthorizationInputEnvelope = {
  data: Array<UserAuthorizationsCreateManyAuthorizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserAuthorizationsCreateManyUserInput = {
  authorizationId: Scalars['Int'];
  unused?: Maybe<Scalars['Int']>;
};

export type UserAuthorizationsCreateManyUserInputEnvelope = {
  data: Array<UserAuthorizationsCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserAuthorizationsCreateNestedManyWithoutAuthorizationInput = {
  connect?: Maybe<Array<UserAuthorizationsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAuthorizationsCreateOrConnectWithoutAuthorizationInput>>;
  create?: Maybe<Array<UserAuthorizationsCreateWithoutAuthorizationInput>>;
  createMany?: Maybe<UserAuthorizationsCreateManyAuthorizationInputEnvelope>;
};

export type UserAuthorizationsCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<UserAuthorizationsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAuthorizationsCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserAuthorizationsCreateWithoutUserInput>>;
  createMany?: Maybe<UserAuthorizationsCreateManyUserInputEnvelope>;
};

export type UserAuthorizationsCreateOrConnectWithoutAuthorizationInput = {
  create: UserAuthorizationsCreateWithoutAuthorizationInput;
  where: UserAuthorizationsWhereUniqueInput;
};

export type UserAuthorizationsCreateOrConnectWithoutUserInput = {
  create: UserAuthorizationsCreateWithoutUserInput;
  where: UserAuthorizationsWhereUniqueInput;
};

export type UserAuthorizationsCreateWithoutAuthorizationInput = {
  unused?: Maybe<Scalars['Int']>;
  user: UserCreateNestedOneWithoutUserAuthorizationsInput;
};

export type UserAuthorizationsCreateWithoutUserInput = {
  authorization: AuthorizationCreateNestedOneWithoutUserAuthorizationsInput;
  unused?: Maybe<Scalars['Int']>;
};

export type UserAuthorizationsListRelationFilter = {
  every?: Maybe<UserAuthorizationsWhereInput>;
  none?: Maybe<UserAuthorizationsWhereInput>;
  some?: Maybe<UserAuthorizationsWhereInput>;
};

export type UserAuthorizationsScalarWhereInput = {
  AND?: Maybe<Array<UserAuthorizationsScalarWhereInput>>;
  NOT?: Maybe<Array<UserAuthorizationsScalarWhereInput>>;
  OR?: Maybe<Array<UserAuthorizationsScalarWhereInput>>;
  authorizationId?: Maybe<IntFilter>;
  unused?: Maybe<IntNullableFilter>;
  userId?: Maybe<StringFilter>;
};

export type UserAuthorizationsUpdateManyMutationInput = {
  unused?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type UserAuthorizationsUpdateManyWithWhereWithoutAuthorizationInput = {
  data: UserAuthorizationsUpdateManyMutationInput;
  where: UserAuthorizationsScalarWhereInput;
};

export type UserAuthorizationsUpdateManyWithWhereWithoutUserInput = {
  data: UserAuthorizationsUpdateManyMutationInput;
  where: UserAuthorizationsScalarWhereInput;
};

export type UserAuthorizationsUpdateManyWithoutAuthorizationInput = {
  connect?: Maybe<Array<UserAuthorizationsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAuthorizationsCreateOrConnectWithoutAuthorizationInput>>;
  create?: Maybe<Array<UserAuthorizationsCreateWithoutAuthorizationInput>>;
  createMany?: Maybe<UserAuthorizationsCreateManyAuthorizationInputEnvelope>;
  delete?: Maybe<Array<UserAuthorizationsWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserAuthorizationsScalarWhereInput>>;
  disconnect?: Maybe<Array<UserAuthorizationsWhereUniqueInput>>;
  set?: Maybe<Array<UserAuthorizationsWhereUniqueInput>>;
  update?: Maybe<Array<UserAuthorizationsUpdateWithWhereUniqueWithoutAuthorizationInput>>;
  updateMany?: Maybe<Array<UserAuthorizationsUpdateManyWithWhereWithoutAuthorizationInput>>;
  upsert?: Maybe<Array<UserAuthorizationsUpsertWithWhereUniqueWithoutAuthorizationInput>>;
};

export type UserAuthorizationsUpdateManyWithoutUserInput = {
  connect?: Maybe<Array<UserAuthorizationsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserAuthorizationsCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserAuthorizationsCreateWithoutUserInput>>;
  createMany?: Maybe<UserAuthorizationsCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<UserAuthorizationsWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserAuthorizationsScalarWhereInput>>;
  disconnect?: Maybe<Array<UserAuthorizationsWhereUniqueInput>>;
  set?: Maybe<Array<UserAuthorizationsWhereUniqueInput>>;
  update?: Maybe<Array<UserAuthorizationsUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserAuthorizationsUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<UserAuthorizationsUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserAuthorizationsUpdateWithWhereUniqueWithoutAuthorizationInput = {
  data: UserAuthorizationsUpdateWithoutAuthorizationInput;
  where: UserAuthorizationsWhereUniqueInput;
};

export type UserAuthorizationsUpdateWithWhereUniqueWithoutUserInput = {
  data: UserAuthorizationsUpdateWithoutUserInput;
  where: UserAuthorizationsWhereUniqueInput;
};

export type UserAuthorizationsUpdateWithoutAuthorizationInput = {
  unused?: Maybe<NullableIntFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutUserAuthorizationsInput>;
};

export type UserAuthorizationsUpdateWithoutUserInput = {
  authorization?: Maybe<AuthorizationUpdateOneRequiredWithoutUserAuthorizationsInput>;
  unused?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type UserAuthorizationsUpsertWithWhereUniqueWithoutAuthorizationInput = {
  create: UserAuthorizationsCreateWithoutAuthorizationInput;
  update: UserAuthorizationsUpdateWithoutAuthorizationInput;
  where: UserAuthorizationsWhereUniqueInput;
};

export type UserAuthorizationsUpsertWithWhereUniqueWithoutUserInput = {
  create: UserAuthorizationsCreateWithoutUserInput;
  update: UserAuthorizationsUpdateWithoutUserInput;
  where: UserAuthorizationsWhereUniqueInput;
};

export type UserAuthorizationsUserIdAuthorizationIdCompoundUniqueInput = {
  authorizationId: Scalars['Int'];
  userId: Scalars['String'];
};

export type UserAuthorizationsWhereInput = {
  AND?: Maybe<Array<UserAuthorizationsWhereInput>>;
  NOT?: Maybe<Array<UserAuthorizationsWhereInput>>;
  OR?: Maybe<Array<UserAuthorizationsWhereInput>>;
  authorization?: Maybe<AuthorizationRelationFilter>;
  authorizationId?: Maybe<IntFilter>;
  unused?: Maybe<IntNullableFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringFilter>;
};

export type UserAuthorizationsWhereUniqueInput = {
  userId_authorizationId?: Maybe<UserAuthorizationsUserIdAuthorizationIdCompoundUniqueInput>;
};

export type UserClientsCreateManyClientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type UserClientsCreateManyClientInputEnvelope = {
  data: Array<UserClientsCreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserClientsCreateManyUserInput = {
  clientId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserClientsCreateManyUserInputEnvelope = {
  data: Array<UserClientsCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserClientsCreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<UserClientsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserClientsCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<UserClientsCreateWithoutClientInput>>;
  createMany?: Maybe<UserClientsCreateManyClientInputEnvelope>;
};

export type UserClientsCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<UserClientsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserClientsCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserClientsCreateWithoutUserInput>>;
  createMany?: Maybe<UserClientsCreateManyUserInputEnvelope>;
};

export type UserClientsCreateOrConnectWithoutClientInput = {
  create: UserClientsCreateWithoutClientInput;
  where: UserClientsWhereUniqueInput;
};

export type UserClientsCreateOrConnectWithoutUserInput = {
  create: UserClientsCreateWithoutUserInput;
  where: UserClientsWhereUniqueInput;
};

export type UserClientsCreateWithoutClientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutClientsInput;
};

export type UserClientsCreateWithoutUserInput = {
  client: ClientCreateNestedOneWithoutUsersInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserClientsListRelationFilter = {
  every?: Maybe<UserClientsWhereInput>;
  none?: Maybe<UserClientsWhereInput>;
  some?: Maybe<UserClientsWhereInput>;
};

export type UserClientsScalarWhereInput = {
  AND?: Maybe<Array<UserClientsScalarWhereInput>>;
  NOT?: Maybe<Array<UserClientsScalarWhereInput>>;
  OR?: Maybe<Array<UserClientsScalarWhereInput>>;
  clientId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringFilter>;
};

export type UserClientsUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserClientsUpdateManyWithWhereWithoutClientInput = {
  data: UserClientsUpdateManyMutationInput;
  where: UserClientsScalarWhereInput;
};

export type UserClientsUpdateManyWithWhereWithoutUserInput = {
  data: UserClientsUpdateManyMutationInput;
  where: UserClientsScalarWhereInput;
};

export type UserClientsUpdateManyWithoutClientInput = {
  connect?: Maybe<Array<UserClientsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserClientsCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<UserClientsCreateWithoutClientInput>>;
  createMany?: Maybe<UserClientsCreateManyClientInputEnvelope>;
  delete?: Maybe<Array<UserClientsWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserClientsScalarWhereInput>>;
  disconnect?: Maybe<Array<UserClientsWhereUniqueInput>>;
  set?: Maybe<Array<UserClientsWhereUniqueInput>>;
  update?: Maybe<Array<UserClientsUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<UserClientsUpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<UserClientsUpsertWithWhereUniqueWithoutClientInput>>;
};

export type UserClientsUpdateManyWithoutUserInput = {
  connect?: Maybe<Array<UserClientsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserClientsCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserClientsCreateWithoutUserInput>>;
  createMany?: Maybe<UserClientsCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<UserClientsWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserClientsScalarWhereInput>>;
  disconnect?: Maybe<Array<UserClientsWhereUniqueInput>>;
  set?: Maybe<Array<UserClientsWhereUniqueInput>>;
  update?: Maybe<Array<UserClientsUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserClientsUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<UserClientsUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserClientsUpdateWithWhereUniqueWithoutClientInput = {
  data: UserClientsUpdateWithoutClientInput;
  where: UserClientsWhereUniqueInput;
};

export type UserClientsUpdateWithWhereUniqueWithoutUserInput = {
  data: UserClientsUpdateWithoutUserInput;
  where: UserClientsWhereUniqueInput;
};

export type UserClientsUpdateWithoutClientInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutClientsInput>;
};

export type UserClientsUpdateWithoutUserInput = {
  client?: Maybe<ClientUpdateOneRequiredWithoutUsersInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserClientsUpsertWithWhereUniqueWithoutClientInput = {
  create: UserClientsCreateWithoutClientInput;
  update: UserClientsUpdateWithoutClientInput;
  where: UserClientsWhereUniqueInput;
};

export type UserClientsUpsertWithWhereUniqueWithoutUserInput = {
  create: UserClientsCreateWithoutUserInput;
  update: UserClientsUpdateWithoutUserInput;
  where: UserClientsWhereUniqueInput;
};

export type UserClientsUserIdClientIdCompoundUniqueInput = {
  clientId: Scalars['String'];
  userId: Scalars['String'];
};

export type UserClientsWhereInput = {
  AND?: Maybe<Array<UserClientsWhereInput>>;
  NOT?: Maybe<Array<UserClientsWhereInput>>;
  OR?: Maybe<Array<UserClientsWhereInput>>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringFilter>;
};

export type UserClientsWhereUniqueInput = {
  userId_clientId?: Maybe<UserClientsUserIdClientIdCompoundUniqueInput>;
};

export type UserCreateNestedOneWithoutClientsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutClientsInput>;
  create?: Maybe<UserCreateWithoutClientsInput>;
};

export type UserCreateNestedOneWithoutFileUploadRequestsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutFileUploadRequestsInput>;
  create?: Maybe<UserCreateWithoutFileUploadRequestsInput>;
};

export type UserCreateNestedOneWithoutGroupsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutGroupsInput>;
  create?: Maybe<UserCreateWithoutGroupsInput>;
};

export type UserCreateNestedOneWithoutUserAuthorizationsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserAuthorizationsInput>;
  create?: Maybe<UserCreateWithoutUserAuthorizationsInput>;
};

export type UserCreateOrConnectWithoutClientsInput = {
  create: UserCreateWithoutClientsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFileUploadRequestsInput = {
  create: UserCreateWithoutFileUploadRequestsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutGroupsInput = {
  create: UserCreateWithoutGroupsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserAuthorizationsInput = {
  create: UserCreateWithoutUserAuthorizationsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutClientsInput = {
  backgroundJobs?: Maybe<BackgroundJobCreateNestedManyWithoutUserInput>;
  cognitoSub: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutUserInput>;
  firstName?: Maybe<Scalars['String']>;
  groups?: Maybe<UserUserGroupCreateNestedManyWithoutUserInput>;
  id?: Maybe<Scalars['String']>;
  isReadOnly?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  permissions?: Maybe<UserPermissionCreateNestedManyWithoutUserInput>;
  role?: Maybe<UserRole>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAuthorizations?: Maybe<UserAuthorizationsCreateNestedManyWithoutUserInput>;
  userLevelFeatures?: Maybe<FeatureUserCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutFileUploadRequestsInput = {
  backgroundJobs?: Maybe<BackgroundJobCreateNestedManyWithoutUserInput>;
  clients?: Maybe<UserClientsCreateNestedManyWithoutUserInput>;
  cognitoSub: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  groups?: Maybe<UserUserGroupCreateNestedManyWithoutUserInput>;
  id?: Maybe<Scalars['String']>;
  isReadOnly?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  permissions?: Maybe<UserPermissionCreateNestedManyWithoutUserInput>;
  role?: Maybe<UserRole>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAuthorizations?: Maybe<UserAuthorizationsCreateNestedManyWithoutUserInput>;
  userLevelFeatures?: Maybe<FeatureUserCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutGroupsInput = {
  backgroundJobs?: Maybe<BackgroundJobCreateNestedManyWithoutUserInput>;
  clients?: Maybe<UserClientsCreateNestedManyWithoutUserInput>;
  cognitoSub: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutUserInput>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isReadOnly?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  permissions?: Maybe<UserPermissionCreateNestedManyWithoutUserInput>;
  role?: Maybe<UserRole>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAuthorizations?: Maybe<UserAuthorizationsCreateNestedManyWithoutUserInput>;
  userLevelFeatures?: Maybe<FeatureUserCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutUserAuthorizationsInput = {
  backgroundJobs?: Maybe<BackgroundJobCreateNestedManyWithoutUserInput>;
  clients?: Maybe<UserClientsCreateNestedManyWithoutUserInput>;
  cognitoSub: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  fileUploadRequests?: Maybe<FileUploadRequestCreateNestedManyWithoutUserInput>;
  firstName?: Maybe<Scalars['String']>;
  groups?: Maybe<UserUserGroupCreateNestedManyWithoutUserInput>;
  id?: Maybe<Scalars['String']>;
  isReadOnly?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  permissions?: Maybe<UserPermissionCreateNestedManyWithoutUserInput>;
  role?: Maybe<UserRole>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userLevelFeatures?: Maybe<FeatureUserCreateNestedManyWithoutUserInput>;
};

export type UserGroupAuthorizationsCreateManyAuthorizationInput = {
  groupId: Scalars['String'];
  unused?: Maybe<Scalars['Int']>;
};

export type UserGroupAuthorizationsCreateManyAuthorizationInputEnvelope = {
  data: Array<UserGroupAuthorizationsCreateManyAuthorizationInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserGroupAuthorizationsCreateManyGroupInput = {
  authorizationId: Scalars['Int'];
  unused?: Maybe<Scalars['Int']>;
};

export type UserGroupAuthorizationsCreateManyGroupInputEnvelope = {
  data: Array<UserGroupAuthorizationsCreateManyGroupInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserGroupAuthorizationsCreateNestedManyWithoutAuthorizationInput = {
  connect?: Maybe<Array<UserGroupAuthorizationsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserGroupAuthorizationsCreateOrConnectWithoutAuthorizationInput>>;
  create?: Maybe<Array<UserGroupAuthorizationsCreateWithoutAuthorizationInput>>;
  createMany?: Maybe<UserGroupAuthorizationsCreateManyAuthorizationInputEnvelope>;
};

export type UserGroupAuthorizationsCreateNestedManyWithoutGroupInput = {
  connect?: Maybe<Array<UserGroupAuthorizationsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserGroupAuthorizationsCreateOrConnectWithoutGroupInput>>;
  create?: Maybe<Array<UserGroupAuthorizationsCreateWithoutGroupInput>>;
  createMany?: Maybe<UserGroupAuthorizationsCreateManyGroupInputEnvelope>;
};

export type UserGroupAuthorizationsCreateOrConnectWithoutAuthorizationInput = {
  create: UserGroupAuthorizationsCreateWithoutAuthorizationInput;
  where: UserGroupAuthorizationsWhereUniqueInput;
};

export type UserGroupAuthorizationsCreateOrConnectWithoutGroupInput = {
  create: UserGroupAuthorizationsCreateWithoutGroupInput;
  where: UserGroupAuthorizationsWhereUniqueInput;
};

export type UserGroupAuthorizationsCreateWithoutAuthorizationInput = {
  group: UserGroupCreateNestedOneWithoutGroupAuthorizationsInput;
  unused?: Maybe<Scalars['Int']>;
};

export type UserGroupAuthorizationsCreateWithoutGroupInput = {
  authorization: AuthorizationCreateNestedOneWithoutUserGroupAuthorizationsInput;
  unused?: Maybe<Scalars['Int']>;
};

export type UserGroupAuthorizationsGroupIdAuthorizationIdCompoundUniqueInput = {
  authorizationId: Scalars['Int'];
  groupId: Scalars['String'];
};

export type UserGroupAuthorizationsListRelationFilter = {
  every?: Maybe<UserGroupAuthorizationsWhereInput>;
  none?: Maybe<UserGroupAuthorizationsWhereInput>;
  some?: Maybe<UserGroupAuthorizationsWhereInput>;
};

export type UserGroupAuthorizationsScalarWhereInput = {
  AND?: Maybe<Array<UserGroupAuthorizationsScalarWhereInput>>;
  NOT?: Maybe<Array<UserGroupAuthorizationsScalarWhereInput>>;
  OR?: Maybe<Array<UserGroupAuthorizationsScalarWhereInput>>;
  authorizationId?: Maybe<IntFilter>;
  groupId?: Maybe<StringFilter>;
  unused?: Maybe<IntNullableFilter>;
};

export type UserGroupAuthorizationsUpdateManyMutationInput = {
  unused?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type UserGroupAuthorizationsUpdateManyWithWhereWithoutAuthorizationInput = {
  data: UserGroupAuthorizationsUpdateManyMutationInput;
  where: UserGroupAuthorizationsScalarWhereInput;
};

export type UserGroupAuthorizationsUpdateManyWithWhereWithoutGroupInput = {
  data: UserGroupAuthorizationsUpdateManyMutationInput;
  where: UserGroupAuthorizationsScalarWhereInput;
};

export type UserGroupAuthorizationsUpdateManyWithoutAuthorizationInput = {
  connect?: Maybe<Array<UserGroupAuthorizationsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserGroupAuthorizationsCreateOrConnectWithoutAuthorizationInput>>;
  create?: Maybe<Array<UserGroupAuthorizationsCreateWithoutAuthorizationInput>>;
  createMany?: Maybe<UserGroupAuthorizationsCreateManyAuthorizationInputEnvelope>;
  delete?: Maybe<Array<UserGroupAuthorizationsWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserGroupAuthorizationsScalarWhereInput>>;
  disconnect?: Maybe<Array<UserGroupAuthorizationsWhereUniqueInput>>;
  set?: Maybe<Array<UserGroupAuthorizationsWhereUniqueInput>>;
  update?: Maybe<Array<UserGroupAuthorizationsUpdateWithWhereUniqueWithoutAuthorizationInput>>;
  updateMany?: Maybe<Array<UserGroupAuthorizationsUpdateManyWithWhereWithoutAuthorizationInput>>;
  upsert?: Maybe<Array<UserGroupAuthorizationsUpsertWithWhereUniqueWithoutAuthorizationInput>>;
};

export type UserGroupAuthorizationsUpdateManyWithoutGroupInput = {
  connect?: Maybe<Array<UserGroupAuthorizationsWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserGroupAuthorizationsCreateOrConnectWithoutGroupInput>>;
  create?: Maybe<Array<UserGroupAuthorizationsCreateWithoutGroupInput>>;
  createMany?: Maybe<UserGroupAuthorizationsCreateManyGroupInputEnvelope>;
  delete?: Maybe<Array<UserGroupAuthorizationsWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserGroupAuthorizationsScalarWhereInput>>;
  disconnect?: Maybe<Array<UserGroupAuthorizationsWhereUniqueInput>>;
  set?: Maybe<Array<UserGroupAuthorizationsWhereUniqueInput>>;
  update?: Maybe<Array<UserGroupAuthorizationsUpdateWithWhereUniqueWithoutGroupInput>>;
  updateMany?: Maybe<Array<UserGroupAuthorizationsUpdateManyWithWhereWithoutGroupInput>>;
  upsert?: Maybe<Array<UserGroupAuthorizationsUpsertWithWhereUniqueWithoutGroupInput>>;
};

export type UserGroupAuthorizationsUpdateWithWhereUniqueWithoutAuthorizationInput = {
  data: UserGroupAuthorizationsUpdateWithoutAuthorizationInput;
  where: UserGroupAuthorizationsWhereUniqueInput;
};

export type UserGroupAuthorizationsUpdateWithWhereUniqueWithoutGroupInput = {
  data: UserGroupAuthorizationsUpdateWithoutGroupInput;
  where: UserGroupAuthorizationsWhereUniqueInput;
};

export type UserGroupAuthorizationsUpdateWithoutAuthorizationInput = {
  group?: Maybe<UserGroupUpdateOneRequiredWithoutGroupAuthorizationsInput>;
  unused?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type UserGroupAuthorizationsUpdateWithoutGroupInput = {
  authorization?: Maybe<AuthorizationUpdateOneRequiredWithoutUserGroupAuthorizationsInput>;
  unused?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type UserGroupAuthorizationsUpsertWithWhereUniqueWithoutAuthorizationInput = {
  create: UserGroupAuthorizationsCreateWithoutAuthorizationInput;
  update: UserGroupAuthorizationsUpdateWithoutAuthorizationInput;
  where: UserGroupAuthorizationsWhereUniqueInput;
};

export type UserGroupAuthorizationsUpsertWithWhereUniqueWithoutGroupInput = {
  create: UserGroupAuthorizationsCreateWithoutGroupInput;
  update: UserGroupAuthorizationsUpdateWithoutGroupInput;
  where: UserGroupAuthorizationsWhereUniqueInput;
};

export type UserGroupAuthorizationsWhereInput = {
  AND?: Maybe<Array<UserGroupAuthorizationsWhereInput>>;
  NOT?: Maybe<Array<UserGroupAuthorizationsWhereInput>>;
  OR?: Maybe<Array<UserGroupAuthorizationsWhereInput>>;
  authorization?: Maybe<AuthorizationRelationFilter>;
  authorizationId?: Maybe<IntFilter>;
  group?: Maybe<UserGroupRelationFilter>;
  groupId?: Maybe<StringFilter>;
  unused?: Maybe<IntNullableFilter>;
};

export type UserGroupAuthorizationsWhereUniqueInput = {
  groupId_authorizationId?: Maybe<UserGroupAuthorizationsGroupIdAuthorizationIdCompoundUniqueInput>;
};

export type UserGroupClientIdNameCompoundUniqueInput = {
  clientId: Scalars['String'];
  name: Scalars['String'];
};

export type UserGroupCreateManyClientInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type UserGroupCreateManyClientInputEnvelope = {
  data: Array<UserGroupCreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserGroupCreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<UserGroupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserGroupCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<UserGroupCreateWithoutClientInput>>;
  createMany?: Maybe<UserGroupCreateManyClientInputEnvelope>;
};

export type UserGroupCreateNestedOneWithoutGroupAuthorizationsInput = {
  connect?: Maybe<UserGroupWhereUniqueInput>;
  connectOrCreate?: Maybe<UserGroupCreateOrConnectWithoutGroupAuthorizationsInput>;
  create?: Maybe<UserGroupCreateWithoutGroupAuthorizationsInput>;
};

export type UserGroupCreateNestedOneWithoutUserUserGroupInput = {
  connect?: Maybe<UserGroupWhereUniqueInput>;
  connectOrCreate?: Maybe<UserGroupCreateOrConnectWithoutUserUserGroupInput>;
  create?: Maybe<UserGroupCreateWithoutUserUserGroupInput>;
};

export type UserGroupCreateOrConnectWithoutClientInput = {
  create: UserGroupCreateWithoutClientInput;
  where: UserGroupWhereUniqueInput;
};

export type UserGroupCreateOrConnectWithoutGroupAuthorizationsInput = {
  create: UserGroupCreateWithoutGroupAuthorizationsInput;
  where: UserGroupWhereUniqueInput;
};

export type UserGroupCreateOrConnectWithoutUserUserGroupInput = {
  create: UserGroupCreateWithoutUserUserGroupInput;
  where: UserGroupWhereUniqueInput;
};

export type UserGroupCreateWithoutClientInput = {
  groupAuthorizations?: Maybe<UserGroupAuthorizationsCreateNestedManyWithoutGroupInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  userUserGroup?: Maybe<UserUserGroupCreateNestedManyWithoutGroupInput>;
};

export type UserGroupCreateWithoutGroupAuthorizationsInput = {
  client: ClientCreateNestedOneWithoutUserGroupsInput;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  userUserGroup?: Maybe<UserUserGroupCreateNestedManyWithoutGroupInput>;
};

export type UserGroupCreateWithoutUserUserGroupInput = {
  client: ClientCreateNestedOneWithoutUserGroupsInput;
  groupAuthorizations?: Maybe<UserGroupAuthorizationsCreateNestedManyWithoutGroupInput>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type UserGroupListRelationFilter = {
  every?: Maybe<UserGroupWhereInput>;
  none?: Maybe<UserGroupWhereInput>;
  some?: Maybe<UserGroupWhereInput>;
};

export type UserGroupModel = {
  __typename?: 'UserGroupModel';
  authorizations: Array<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  userCount: Scalars['Int'];
};

export type UserGroupRelationFilter = {
  is?: Maybe<UserGroupWhereInput>;
  isNot?: Maybe<UserGroupWhereInput>;
};

export type UserGroupScalarWhereInput = {
  AND?: Maybe<Array<UserGroupScalarWhereInput>>;
  NOT?: Maybe<Array<UserGroupScalarWhereInput>>;
  OR?: Maybe<Array<UserGroupScalarWhereInput>>;
  clientId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
};

export type UserGroupUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type UserGroupUpdateManyWithWhereWithoutClientInput = {
  data: UserGroupUpdateManyMutationInput;
  where: UserGroupScalarWhereInput;
};

export type UserGroupUpdateManyWithoutClientInput = {
  connect?: Maybe<Array<UserGroupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserGroupCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<UserGroupCreateWithoutClientInput>>;
  createMany?: Maybe<UserGroupCreateManyClientInputEnvelope>;
  delete?: Maybe<Array<UserGroupWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserGroupScalarWhereInput>>;
  disconnect?: Maybe<Array<UserGroupWhereUniqueInput>>;
  set?: Maybe<Array<UserGroupWhereUniqueInput>>;
  update?: Maybe<Array<UserGroupUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<UserGroupUpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<UserGroupUpsertWithWhereUniqueWithoutClientInput>>;
};

export type UserGroupUpdateOneRequiredWithoutGroupAuthorizationsInput = {
  connect?: Maybe<UserGroupWhereUniqueInput>;
  connectOrCreate?: Maybe<UserGroupCreateOrConnectWithoutGroupAuthorizationsInput>;
  create?: Maybe<UserGroupCreateWithoutGroupAuthorizationsInput>;
  update?: Maybe<UserGroupUpdateWithoutGroupAuthorizationsInput>;
  upsert?: Maybe<UserGroupUpsertWithoutGroupAuthorizationsInput>;
};

export type UserGroupUpdateOneRequiredWithoutUserUserGroupInput = {
  connect?: Maybe<UserGroupWhereUniqueInput>;
  connectOrCreate?: Maybe<UserGroupCreateOrConnectWithoutUserUserGroupInput>;
  create?: Maybe<UserGroupCreateWithoutUserUserGroupInput>;
  update?: Maybe<UserGroupUpdateWithoutUserUserGroupInput>;
  upsert?: Maybe<UserGroupUpsertWithoutUserUserGroupInput>;
};

export type UserGroupUpdateWithWhereUniqueWithoutClientInput = {
  data: UserGroupUpdateWithoutClientInput;
  where: UserGroupWhereUniqueInput;
};

export type UserGroupUpdateWithoutClientInput = {
  groupAuthorizations?: Maybe<UserGroupAuthorizationsUpdateManyWithoutGroupInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  userUserGroup?: Maybe<UserUserGroupUpdateManyWithoutGroupInput>;
};

export type UserGroupUpdateWithoutGroupAuthorizationsInput = {
  client?: Maybe<ClientUpdateOneRequiredWithoutUserGroupsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  userUserGroup?: Maybe<UserUserGroupUpdateManyWithoutGroupInput>;
};

export type UserGroupUpdateWithoutUserUserGroupInput = {
  client?: Maybe<ClientUpdateOneRequiredWithoutUserGroupsInput>;
  groupAuthorizations?: Maybe<UserGroupAuthorizationsUpdateManyWithoutGroupInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type UserGroupUpsertWithWhereUniqueWithoutClientInput = {
  create: UserGroupCreateWithoutClientInput;
  update: UserGroupUpdateWithoutClientInput;
  where: UserGroupWhereUniqueInput;
};

export type UserGroupUpsertWithoutGroupAuthorizationsInput = {
  create: UserGroupCreateWithoutGroupAuthorizationsInput;
  update: UserGroupUpdateWithoutGroupAuthorizationsInput;
};

export type UserGroupUpsertWithoutUserUserGroupInput = {
  create: UserGroupCreateWithoutUserUserGroupInput;
  update: UserGroupUpdateWithoutUserUserGroupInput;
};

export type UserGroupWhereInput = {
  AND?: Maybe<Array<UserGroupWhereInput>>;
  NOT?: Maybe<Array<UserGroupWhereInput>>;
  OR?: Maybe<Array<UserGroupWhereInput>>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  groupAuthorizations?: Maybe<UserGroupAuthorizationsListRelationFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  userUserGroup?: Maybe<UserUserGroupListRelationFilter>;
};

export type UserGroupWhereUniqueInput = {
  clientId_name?: Maybe<UserGroupClientIdNameCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type UserModel = {
  __typename?: 'UserModel';
  authorizations: Array<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  groups: Array<UserModelGroupModel>;
  id: Scalars['ID'];
  isReadOnly: Scalars['Boolean'];
  lastName: Scalars['String'];
  properties: Array<Property>;
  role: UserRole;
};

export type UserModelGroupModel = {
  __typename?: 'UserModelGroupModel';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UserPermissionCreateManyUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  permissionId: Scalars['String'];
  updateAt?: Maybe<Scalars['DateTime']>;
};

export type UserPermissionCreateManyUserInputEnvelope = {
  data: Array<UserPermissionCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserPermissionCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<UserPermissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserPermissionCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserPermissionCreateWithoutUserInput>>;
  createMany?: Maybe<UserPermissionCreateManyUserInputEnvelope>;
};

export type UserPermissionCreateOrConnectWithoutUserInput = {
  create: UserPermissionCreateWithoutUserInput;
  where: UserPermissionWhereUniqueInput;
};

export type UserPermissionCreateWithoutUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  permission: PermissionCreateNestedOneWithoutUsersInput;
  updateAt?: Maybe<Scalars['DateTime']>;
};

export type UserPermissionListRelationFilter = {
  every?: Maybe<UserPermissionWhereInput>;
  none?: Maybe<UserPermissionWhereInput>;
  some?: Maybe<UserPermissionWhereInput>;
};

export type UserPermissionScalarWhereInput = {
  AND?: Maybe<Array<UserPermissionScalarWhereInput>>;
  NOT?: Maybe<Array<UserPermissionScalarWhereInput>>;
  OR?: Maybe<Array<UserPermissionScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  permissionId?: Maybe<StringFilter>;
  updateAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringFilter>;
};

export type UserPermissionUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updateAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserPermissionUpdateManyWithWhereWithoutUserInput = {
  data: UserPermissionUpdateManyMutationInput;
  where: UserPermissionScalarWhereInput;
};

export type UserPermissionUpdateManyWithoutUserInput = {
  connect?: Maybe<Array<UserPermissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserPermissionCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserPermissionCreateWithoutUserInput>>;
  createMany?: Maybe<UserPermissionCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<UserPermissionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserPermissionScalarWhereInput>>;
  disconnect?: Maybe<Array<UserPermissionWhereUniqueInput>>;
  set?: Maybe<Array<UserPermissionWhereUniqueInput>>;
  update?: Maybe<Array<UserPermissionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserPermissionUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<UserPermissionUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserPermissionUpdateWithWhereUniqueWithoutUserInput = {
  data: UserPermissionUpdateWithoutUserInput;
  where: UserPermissionWhereUniqueInput;
};

export type UserPermissionUpdateWithoutUserInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  permission?: Maybe<PermissionUpdateOneRequiredWithoutUsersInput>;
  updateAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserPermissionUpsertWithWhereUniqueWithoutUserInput = {
  create: UserPermissionCreateWithoutUserInput;
  update: UserPermissionUpdateWithoutUserInput;
  where: UserPermissionWhereUniqueInput;
};

export type UserPermissionUserIdPermissionIdCompoundUniqueInput = {
  permissionId: Scalars['String'];
  userId: Scalars['String'];
};

export type UserPermissionWhereInput = {
  AND?: Maybe<Array<UserPermissionWhereInput>>;
  NOT?: Maybe<Array<UserPermissionWhereInput>>;
  OR?: Maybe<Array<UserPermissionWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  permission?: Maybe<PermissionRelationFilter>;
  permissionId?: Maybe<StringFilter>;
  updateAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringFilter>;
};

export type UserPermissionWhereUniqueInput = {
  userId_permissionId?: Maybe<UserPermissionUserIdPermissionIdCompoundUniqueInput>;
};

export type UserPropertiesForecastLockModel = {
  __typename?: 'UserPropertiesForecastLockModel';
  budgetLocked: Array<Scalars['String']>;
  reforecastLocked: Array<Scalars['String']>;
};

export type UserRelationFilter = {
  is?: Maybe<UserWhereInput>;
  isNot?: Maybe<UserWhereInput>;
};

export enum UserRole {
  Admin = 'ADMIN',
  Manager = 'MANAGER'
}

export type UserUpdateOneRequiredWithoutClientsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutClientsInput>;
  create?: Maybe<UserCreateWithoutClientsInput>;
  update?: Maybe<UserUpdateWithoutClientsInput>;
  upsert?: Maybe<UserUpsertWithoutClientsInput>;
};

export type UserUpdateOneRequiredWithoutFileUploadRequestsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutFileUploadRequestsInput>;
  create?: Maybe<UserCreateWithoutFileUploadRequestsInput>;
  update?: Maybe<UserUpdateWithoutFileUploadRequestsInput>;
  upsert?: Maybe<UserUpsertWithoutFileUploadRequestsInput>;
};

export type UserUpdateOneRequiredWithoutGroupsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutGroupsInput>;
  create?: Maybe<UserCreateWithoutGroupsInput>;
  update?: Maybe<UserUpdateWithoutGroupsInput>;
  upsert?: Maybe<UserUpsertWithoutGroupsInput>;
};

export type UserUpdateOneRequiredWithoutUserAuthorizationsInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUserAuthorizationsInput>;
  create?: Maybe<UserCreateWithoutUserAuthorizationsInput>;
  update?: Maybe<UserUpdateWithoutUserAuthorizationsInput>;
  upsert?: Maybe<UserUpsertWithoutUserAuthorizationsInput>;
};

export type UserUpdateWithoutClientsInput = {
  backgroundJobs?: Maybe<BackgroundJobUpdateManyWithoutUserInput>;
  cognitoSub?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutUserInput>;
  firstName?: Maybe<StringFieldUpdateOperationsInput>;
  groups?: Maybe<UserUserGroupUpdateManyWithoutUserInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isReadOnly?: Maybe<BoolFieldUpdateOperationsInput>;
  lastName?: Maybe<StringFieldUpdateOperationsInput>;
  permissions?: Maybe<UserPermissionUpdateManyWithoutUserInput>;
  role?: Maybe<EnumUserRoleFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userAuthorizations?: Maybe<UserAuthorizationsUpdateManyWithoutUserInput>;
  userLevelFeatures?: Maybe<FeatureUserUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutFileUploadRequestsInput = {
  backgroundJobs?: Maybe<BackgroundJobUpdateManyWithoutUserInput>;
  clients?: Maybe<UserClientsUpdateManyWithoutUserInput>;
  cognitoSub?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  firstName?: Maybe<StringFieldUpdateOperationsInput>;
  groups?: Maybe<UserUserGroupUpdateManyWithoutUserInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isReadOnly?: Maybe<BoolFieldUpdateOperationsInput>;
  lastName?: Maybe<StringFieldUpdateOperationsInput>;
  permissions?: Maybe<UserPermissionUpdateManyWithoutUserInput>;
  role?: Maybe<EnumUserRoleFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userAuthorizations?: Maybe<UserAuthorizationsUpdateManyWithoutUserInput>;
  userLevelFeatures?: Maybe<FeatureUserUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutGroupsInput = {
  backgroundJobs?: Maybe<BackgroundJobUpdateManyWithoutUserInput>;
  clients?: Maybe<UserClientsUpdateManyWithoutUserInput>;
  cognitoSub?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutUserInput>;
  firstName?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isReadOnly?: Maybe<BoolFieldUpdateOperationsInput>;
  lastName?: Maybe<StringFieldUpdateOperationsInput>;
  permissions?: Maybe<UserPermissionUpdateManyWithoutUserInput>;
  role?: Maybe<EnumUserRoleFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userAuthorizations?: Maybe<UserAuthorizationsUpdateManyWithoutUserInput>;
  userLevelFeatures?: Maybe<FeatureUserUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutUserAuthorizationsInput = {
  backgroundJobs?: Maybe<BackgroundJobUpdateManyWithoutUserInput>;
  clients?: Maybe<UserClientsUpdateManyWithoutUserInput>;
  cognitoSub?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  fileUploadRequests?: Maybe<FileUploadRequestUpdateManyWithoutUserInput>;
  firstName?: Maybe<StringFieldUpdateOperationsInput>;
  groups?: Maybe<UserUserGroupUpdateManyWithoutUserInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  isReadOnly?: Maybe<BoolFieldUpdateOperationsInput>;
  lastName?: Maybe<StringFieldUpdateOperationsInput>;
  permissions?: Maybe<UserPermissionUpdateManyWithoutUserInput>;
  role?: Maybe<EnumUserRoleFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  userLevelFeatures?: Maybe<FeatureUserUpdateManyWithoutUserInput>;
};

export type UserUpsertWithoutClientsInput = {
  create: UserCreateWithoutClientsInput;
  update: UserUpdateWithoutClientsInput;
};

export type UserUpsertWithoutFileUploadRequestsInput = {
  create: UserCreateWithoutFileUploadRequestsInput;
  update: UserUpdateWithoutFileUploadRequestsInput;
};

export type UserUpsertWithoutGroupsInput = {
  create: UserCreateWithoutGroupsInput;
  update: UserUpdateWithoutGroupsInput;
};

export type UserUpsertWithoutUserAuthorizationsInput = {
  create: UserCreateWithoutUserAuthorizationsInput;
  update: UserUpdateWithoutUserAuthorizationsInput;
};

export type UserUserGroupCreateManyGroupInput = {
  unused?: Maybe<Scalars['Int']>;
  userId: Scalars['String'];
};

export type UserUserGroupCreateManyGroupInputEnvelope = {
  data: Array<UserUserGroupCreateManyGroupInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserUserGroupCreateManyUserInput = {
  groupId: Scalars['String'];
  unused?: Maybe<Scalars['Int']>;
};

export type UserUserGroupCreateManyUserInputEnvelope = {
  data: Array<UserUserGroupCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type UserUserGroupCreateNestedManyWithoutGroupInput = {
  connect?: Maybe<Array<UserUserGroupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserUserGroupCreateOrConnectWithoutGroupInput>>;
  create?: Maybe<Array<UserUserGroupCreateWithoutGroupInput>>;
  createMany?: Maybe<UserUserGroupCreateManyGroupInputEnvelope>;
};

export type UserUserGroupCreateNestedManyWithoutUserInput = {
  connect?: Maybe<Array<UserUserGroupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserUserGroupCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserUserGroupCreateWithoutUserInput>>;
  createMany?: Maybe<UserUserGroupCreateManyUserInputEnvelope>;
};

export type UserUserGroupCreateOrConnectWithoutGroupInput = {
  create: UserUserGroupCreateWithoutGroupInput;
  where: UserUserGroupWhereUniqueInput;
};

export type UserUserGroupCreateOrConnectWithoutUserInput = {
  create: UserUserGroupCreateWithoutUserInput;
  where: UserUserGroupWhereUniqueInput;
};

export type UserUserGroupCreateWithoutGroupInput = {
  unused?: Maybe<Scalars['Int']>;
  user: UserCreateNestedOneWithoutGroupsInput;
};

export type UserUserGroupCreateWithoutUserInput = {
  group: UserGroupCreateNestedOneWithoutUserUserGroupInput;
  unused?: Maybe<Scalars['Int']>;
};

export type UserUserGroupListRelationFilter = {
  every?: Maybe<UserUserGroupWhereInput>;
  none?: Maybe<UserUserGroupWhereInput>;
  some?: Maybe<UserUserGroupWhereInput>;
};

export type UserUserGroupScalarWhereInput = {
  AND?: Maybe<Array<UserUserGroupScalarWhereInput>>;
  NOT?: Maybe<Array<UserUserGroupScalarWhereInput>>;
  OR?: Maybe<Array<UserUserGroupScalarWhereInput>>;
  groupId?: Maybe<StringFilter>;
  unused?: Maybe<IntNullableFilter>;
  userId?: Maybe<StringFilter>;
};

export type UserUserGroupUpdateManyMutationInput = {
  unused?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type UserUserGroupUpdateManyWithWhereWithoutGroupInput = {
  data: UserUserGroupUpdateManyMutationInput;
  where: UserUserGroupScalarWhereInput;
};

export type UserUserGroupUpdateManyWithWhereWithoutUserInput = {
  data: UserUserGroupUpdateManyMutationInput;
  where: UserUserGroupScalarWhereInput;
};

export type UserUserGroupUpdateManyWithoutGroupInput = {
  connect?: Maybe<Array<UserUserGroupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserUserGroupCreateOrConnectWithoutGroupInput>>;
  create?: Maybe<Array<UserUserGroupCreateWithoutGroupInput>>;
  createMany?: Maybe<UserUserGroupCreateManyGroupInputEnvelope>;
  delete?: Maybe<Array<UserUserGroupWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserUserGroupScalarWhereInput>>;
  disconnect?: Maybe<Array<UserUserGroupWhereUniqueInput>>;
  set?: Maybe<Array<UserUserGroupWhereUniqueInput>>;
  update?: Maybe<Array<UserUserGroupUpdateWithWhereUniqueWithoutGroupInput>>;
  updateMany?: Maybe<Array<UserUserGroupUpdateManyWithWhereWithoutGroupInput>>;
  upsert?: Maybe<Array<UserUserGroupUpsertWithWhereUniqueWithoutGroupInput>>;
};

export type UserUserGroupUpdateManyWithoutUserInput = {
  connect?: Maybe<Array<UserUserGroupWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserUserGroupCreateOrConnectWithoutUserInput>>;
  create?: Maybe<Array<UserUserGroupCreateWithoutUserInput>>;
  createMany?: Maybe<UserUserGroupCreateManyUserInputEnvelope>;
  delete?: Maybe<Array<UserUserGroupWhereUniqueInput>>;
  deleteMany?: Maybe<Array<UserUserGroupScalarWhereInput>>;
  disconnect?: Maybe<Array<UserUserGroupWhereUniqueInput>>;
  set?: Maybe<Array<UserUserGroupWhereUniqueInput>>;
  update?: Maybe<Array<UserUserGroupUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserUserGroupUpdateManyWithWhereWithoutUserInput>>;
  upsert?: Maybe<Array<UserUserGroupUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserUserGroupUpdateWithWhereUniqueWithoutGroupInput = {
  data: UserUserGroupUpdateWithoutGroupInput;
  where: UserUserGroupWhereUniqueInput;
};

export type UserUserGroupUpdateWithWhereUniqueWithoutUserInput = {
  data: UserUserGroupUpdateWithoutUserInput;
  where: UserUserGroupWhereUniqueInput;
};

export type UserUserGroupUpdateWithoutGroupInput = {
  unused?: Maybe<NullableIntFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutGroupsInput>;
};

export type UserUserGroupUpdateWithoutUserInput = {
  group?: Maybe<UserGroupUpdateOneRequiredWithoutUserUserGroupInput>;
  unused?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type UserUserGroupUpsertWithWhereUniqueWithoutGroupInput = {
  create: UserUserGroupCreateWithoutGroupInput;
  update: UserUserGroupUpdateWithoutGroupInput;
  where: UserUserGroupWhereUniqueInput;
};

export type UserUserGroupUpsertWithWhereUniqueWithoutUserInput = {
  create: UserUserGroupCreateWithoutUserInput;
  update: UserUserGroupUpdateWithoutUserInput;
  where: UserUserGroupWhereUniqueInput;
};

export type UserUserGroupUserIdGroupIdCompoundUniqueInput = {
  groupId: Scalars['String'];
  userId: Scalars['String'];
};

export type UserUserGroupWhereInput = {
  AND?: Maybe<Array<UserUserGroupWhereInput>>;
  NOT?: Maybe<Array<UserUserGroupWhereInput>>;
  OR?: Maybe<Array<UserUserGroupWhereInput>>;
  group?: Maybe<UserGroupRelationFilter>;
  groupId?: Maybe<StringFilter>;
  unused?: Maybe<IntNullableFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<StringFilter>;
};

export type UserUserGroupWhereUniqueInput = {
  userId_groupId?: Maybe<UserUserGroupUserIdGroupIdCompoundUniqueInput>;
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  backgroundJobs?: Maybe<BackgroundJobListRelationFilter>;
  clients?: Maybe<UserClientsListRelationFilter>;
  cognitoSub?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  fileUploadRequests?: Maybe<FileUploadRequestListRelationFilter>;
  firstName?: Maybe<StringFilter>;
  groups?: Maybe<UserUserGroupListRelationFilter>;
  id?: Maybe<StringFilter>;
  isReadOnly?: Maybe<BoolFilter>;
  lastName?: Maybe<StringFilter>;
  permissions?: Maybe<UserPermissionListRelationFilter>;
  role?: Maybe<EnumUserRoleFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userAuthorizations?: Maybe<UserAuthorizationsListRelationFilter>;
  userLevelFeatures?: Maybe<FeatureUserListRelationFilter>;
};

export type UserWhereUniqueInput = {
  cognitoSub?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type ValueVersion = {
  __typename?: 'ValueVersion';
  AccountValueOverride: Array<AccountValueOverride>;
  accountTotalValues: Array<AccountTotalValue>;
  accountValues: Array<AccountValue>;
  budgetCategoryTotalValues: Array<BudgetCategoryTotalValue>;
  budgetCategoryValues: Array<BudgetCategoryValue>;
  budgetComponentTotalValues: Array<BudgetComponentTotalValue>;
  budgetComponentValues: Array<BudgetComponentValue>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  instructions: Array<Instruction>;
  name?: Maybe<Scalars['String']>;
  operationalMetrics: Array<OperationalMetric>;
  revenueMetrics: Array<RevenueMetric>;
  sequenceNumber: Scalars['Int'];
  starredAccounts: Array<StarredAccount>;
  type: VersionType;
  unitTypeScheduleValues: Array<UnitTypeScheduleValue>;
  updatedAt: Scalars['DateTime'];
  year: Scalars['Int'];
};


export type ValueVersionAccountValueOverrideArgs = {
  cursor?: Maybe<AccountValueOverrideWhereUniqueInput>;
  distinct?: Maybe<Array<AccountValueOverrideScalarFieldEnum>>;
  orderBy?: Maybe<Array<AccountValueOverrideOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AccountValueOverrideWhereInput>;
};


export type ValueVersionAccountTotalValuesArgs = {
  cursor?: Maybe<AccountTotalValueWhereUniqueInput>;
  distinct?: Maybe<Array<AccountTotalValueScalarFieldEnum>>;
  orderBy?: Maybe<Array<AccountTotalValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AccountTotalValueWhereInput>;
};


export type ValueVersionAccountValuesArgs = {
  cursor?: Maybe<AccountValueWhereUniqueInput>;
  distinct?: Maybe<Array<AccountValueScalarFieldEnum>>;
  orderBy?: Maybe<Array<AccountValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AccountValueWhereInput>;
};


export type ValueVersionBudgetCategoryTotalValuesArgs = {
  cursor?: Maybe<BudgetCategoryTotalValueWhereUniqueInput>;
  distinct?: Maybe<Array<BudgetCategoryTotalValueScalarFieldEnum>>;
  orderBy?: Maybe<Array<BudgetCategoryTotalValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BudgetCategoryTotalValueWhereInput>;
};


export type ValueVersionBudgetCategoryValuesArgs = {
  cursor?: Maybe<BudgetCategoryValueWhereUniqueInput>;
  distinct?: Maybe<Array<BudgetCategoryValueScalarFieldEnum>>;
  orderBy?: Maybe<Array<BudgetCategoryValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BudgetCategoryValueWhereInput>;
};


export type ValueVersionBudgetComponentTotalValuesArgs = {
  cursor?: Maybe<BudgetComponentTotalValueWhereUniqueInput>;
  distinct?: Maybe<Array<BudgetComponentTotalValueScalarFieldEnum>>;
  orderBy?: Maybe<Array<BudgetComponentTotalValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BudgetComponentTotalValueWhereInput>;
};


export type ValueVersionBudgetComponentValuesArgs = {
  cursor?: Maybe<BudgetComponentValueWhereUniqueInput>;
  distinct?: Maybe<Array<BudgetComponentValueScalarFieldEnum>>;
  orderBy?: Maybe<Array<BudgetComponentValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BudgetComponentValueWhereInput>;
};


export type ValueVersionInstructionsArgs = {
  cursor?: Maybe<InstructionWhereUniqueInput>;
  distinct?: Maybe<Array<InstructionScalarFieldEnum>>;
  orderBy?: Maybe<Array<InstructionOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InstructionWhereInput>;
};


export type ValueVersionOperationalMetricsArgs = {
  cursor?: Maybe<OperationalMetricWhereUniqueInput>;
  distinct?: Maybe<Array<OperationalMetricScalarFieldEnum>>;
  orderBy?: Maybe<Array<OperationalMetricOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<OperationalMetricWhereInput>;
};


export type ValueVersionRevenueMetricsArgs = {
  cursor?: Maybe<RevenueMetricWhereUniqueInput>;
  distinct?: Maybe<Array<RevenueMetricScalarFieldEnum>>;
  orderBy?: Maybe<Array<RevenueMetricOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<RevenueMetricWhereInput>;
};


export type ValueVersionStarredAccountsArgs = {
  cursor?: Maybe<StarredAccountWhereUniqueInput>;
  distinct?: Maybe<Array<StarredAccountScalarFieldEnum>>;
  orderBy?: Maybe<Array<StarredAccountOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<StarredAccountWhereInput>;
};


export type ValueVersionUnitTypeScheduleValuesArgs = {
  cursor?: Maybe<UnitTypeScheduleValueWhereUniqueInput>;
  distinct?: Maybe<Array<UnitTypeScheduleValueScalarFieldEnum>>;
  orderBy?: Maybe<Array<UnitTypeScheduleValueOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UnitTypeScheduleValueWhereInput>;
};

export type ValueVersionAvgAggregate = {
  __typename?: 'ValueVersionAvgAggregate';
  sequenceNumber?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type ValueVersionCountAggregate = {
  __typename?: 'ValueVersionCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  sequenceNumber: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  year: Scalars['Int'];
};

export type ValueVersionCreateInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutVersionInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutVersionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutValueVersionInput>;
  name?: Maybe<Scalars['String']>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutVersionInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutVersionInput>;
  sequenceNumber: Scalars['Int'];
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutVersionInput>;
  type: VersionType;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutVersionInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year: Scalars['Int'];
};

export type ValueVersionCreateManyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sequenceNumber: Scalars['Int'];
  type: VersionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year: Scalars['Int'];
};

export type ValueVersionCreateNestedOneWithoutAccountTotalValuesInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutAccountTotalValuesInput>;
  create?: Maybe<ValueVersionCreateWithoutAccountTotalValuesInput>;
};

export type ValueVersionCreateNestedOneWithoutAccountValueOverrideInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutAccountValueOverrideInput>;
  create?: Maybe<ValueVersionCreateWithoutAccountValueOverrideInput>;
};

export type ValueVersionCreateNestedOneWithoutAccountValuesInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutAccountValuesInput>;
  create?: Maybe<ValueVersionCreateWithoutAccountValuesInput>;
};

export type ValueVersionCreateNestedOneWithoutBudgetCategoryTotalValuesInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutBudgetCategoryTotalValuesInput>;
  create?: Maybe<ValueVersionCreateWithoutBudgetCategoryTotalValuesInput>;
};

export type ValueVersionCreateNestedOneWithoutBudgetCategoryValuesInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutBudgetCategoryValuesInput>;
  create?: Maybe<ValueVersionCreateWithoutBudgetCategoryValuesInput>;
};

export type ValueVersionCreateNestedOneWithoutBudgetComponentTotalValuesInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutBudgetComponentTotalValuesInput>;
  create?: Maybe<ValueVersionCreateWithoutBudgetComponentTotalValuesInput>;
};

export type ValueVersionCreateNestedOneWithoutBudgetComponentValuesInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutBudgetComponentValuesInput>;
  create?: Maybe<ValueVersionCreateWithoutBudgetComponentValuesInput>;
};

export type ValueVersionCreateNestedOneWithoutInstructionsInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutInstructionsInput>;
  create?: Maybe<ValueVersionCreateWithoutInstructionsInput>;
};

export type ValueVersionCreateNestedOneWithoutOperationalMetricsInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutOperationalMetricsInput>;
  create?: Maybe<ValueVersionCreateWithoutOperationalMetricsInput>;
};

export type ValueVersionCreateNestedOneWithoutRevenueMetricsInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutRevenueMetricsInput>;
  create?: Maybe<ValueVersionCreateWithoutRevenueMetricsInput>;
};

export type ValueVersionCreateNestedOneWithoutStarredAccountsInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutStarredAccountsInput>;
  create?: Maybe<ValueVersionCreateWithoutStarredAccountsInput>;
};

export type ValueVersionCreateNestedOneWithoutUnitTypeScheduleValuesInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutUnitTypeScheduleValuesInput>;
  create?: Maybe<ValueVersionCreateWithoutUnitTypeScheduleValuesInput>;
};

export type ValueVersionCreateOrConnectWithoutAccountTotalValuesInput = {
  create: ValueVersionCreateWithoutAccountTotalValuesInput;
  where: ValueVersionWhereUniqueInput;
};

export type ValueVersionCreateOrConnectWithoutAccountValueOverrideInput = {
  create: ValueVersionCreateWithoutAccountValueOverrideInput;
  where: ValueVersionWhereUniqueInput;
};

export type ValueVersionCreateOrConnectWithoutAccountValuesInput = {
  create: ValueVersionCreateWithoutAccountValuesInput;
  where: ValueVersionWhereUniqueInput;
};

export type ValueVersionCreateOrConnectWithoutBudgetCategoryTotalValuesInput = {
  create: ValueVersionCreateWithoutBudgetCategoryTotalValuesInput;
  where: ValueVersionWhereUniqueInput;
};

export type ValueVersionCreateOrConnectWithoutBudgetCategoryValuesInput = {
  create: ValueVersionCreateWithoutBudgetCategoryValuesInput;
  where: ValueVersionWhereUniqueInput;
};

export type ValueVersionCreateOrConnectWithoutBudgetComponentTotalValuesInput = {
  create: ValueVersionCreateWithoutBudgetComponentTotalValuesInput;
  where: ValueVersionWhereUniqueInput;
};

export type ValueVersionCreateOrConnectWithoutBudgetComponentValuesInput = {
  create: ValueVersionCreateWithoutBudgetComponentValuesInput;
  where: ValueVersionWhereUniqueInput;
};

export type ValueVersionCreateOrConnectWithoutInstructionsInput = {
  create: ValueVersionCreateWithoutInstructionsInput;
  where: ValueVersionWhereUniqueInput;
};

export type ValueVersionCreateOrConnectWithoutOperationalMetricsInput = {
  create: ValueVersionCreateWithoutOperationalMetricsInput;
  where: ValueVersionWhereUniqueInput;
};

export type ValueVersionCreateOrConnectWithoutRevenueMetricsInput = {
  create: ValueVersionCreateWithoutRevenueMetricsInput;
  where: ValueVersionWhereUniqueInput;
};

export type ValueVersionCreateOrConnectWithoutStarredAccountsInput = {
  create: ValueVersionCreateWithoutStarredAccountsInput;
  where: ValueVersionWhereUniqueInput;
};

export type ValueVersionCreateOrConnectWithoutUnitTypeScheduleValuesInput = {
  create: ValueVersionCreateWithoutUnitTypeScheduleValuesInput;
  where: ValueVersionWhereUniqueInput;
};

export type ValueVersionCreateWithoutAccountTotalValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutVersionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutValueVersionInput>;
  name?: Maybe<Scalars['String']>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutVersionInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutVersionInput>;
  sequenceNumber: Scalars['Int'];
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutVersionInput>;
  type: VersionType;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutVersionInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year: Scalars['Int'];
};

export type ValueVersionCreateWithoutAccountValueOverrideInput = {
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutVersionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutValueVersionInput>;
  name?: Maybe<Scalars['String']>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutVersionInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutVersionInput>;
  sequenceNumber: Scalars['Int'];
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutVersionInput>;
  type: VersionType;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutVersionInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year: Scalars['Int'];
};

export type ValueVersionCreateWithoutAccountValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutVersionInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutVersionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutValueVersionInput>;
  name?: Maybe<Scalars['String']>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutVersionInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutVersionInput>;
  sequenceNumber: Scalars['Int'];
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutVersionInput>;
  type: VersionType;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutVersionInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year: Scalars['Int'];
};

export type ValueVersionCreateWithoutBudgetCategoryTotalValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutVersionInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutVersionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutValueVersionInput>;
  name?: Maybe<Scalars['String']>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutVersionInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutVersionInput>;
  sequenceNumber: Scalars['Int'];
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutVersionInput>;
  type: VersionType;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutVersionInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year: Scalars['Int'];
};

export type ValueVersionCreateWithoutBudgetCategoryValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutVersionInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutVersionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutValueVersionInput>;
  name?: Maybe<Scalars['String']>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutVersionInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutVersionInput>;
  sequenceNumber: Scalars['Int'];
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutVersionInput>;
  type: VersionType;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutVersionInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year: Scalars['Int'];
};

export type ValueVersionCreateWithoutBudgetComponentTotalValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutVersionInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutVersionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutValueVersionInput>;
  name?: Maybe<Scalars['String']>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutVersionInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutVersionInput>;
  sequenceNumber: Scalars['Int'];
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutVersionInput>;
  type: VersionType;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutVersionInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year: Scalars['Int'];
};

export type ValueVersionCreateWithoutBudgetComponentValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutVersionInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutVersionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutValueVersionInput>;
  name?: Maybe<Scalars['String']>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutVersionInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutVersionInput>;
  sequenceNumber: Scalars['Int'];
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutVersionInput>;
  type: VersionType;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutVersionInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year: Scalars['Int'];
};

export type ValueVersionCreateWithoutInstructionsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutVersionInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutVersionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutVersionInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutVersionInput>;
  sequenceNumber: Scalars['Int'];
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutVersionInput>;
  type: VersionType;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutVersionInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year: Scalars['Int'];
};

export type ValueVersionCreateWithoutOperationalMetricsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutVersionInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutVersionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutValueVersionInput>;
  name?: Maybe<Scalars['String']>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutVersionInput>;
  sequenceNumber: Scalars['Int'];
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutVersionInput>;
  type: VersionType;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutVersionInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year: Scalars['Int'];
};

export type ValueVersionCreateWithoutRevenueMetricsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutVersionInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutVersionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutValueVersionInput>;
  name?: Maybe<Scalars['String']>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutVersionInput>;
  sequenceNumber: Scalars['Int'];
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutVersionInput>;
  type: VersionType;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutVersionInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year: Scalars['Int'];
};

export type ValueVersionCreateWithoutStarredAccountsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutVersionInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutVersionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutValueVersionInput>;
  name?: Maybe<Scalars['String']>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutVersionInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutVersionInput>;
  sequenceNumber: Scalars['Int'];
  type: VersionType;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueCreateNestedManyWithoutVersionInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year: Scalars['Int'];
};

export type ValueVersionCreateWithoutUnitTypeScheduleValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideCreateNestedManyWithoutVersionInput>;
  accountTotalValues?: Maybe<AccountTotalValueCreateNestedManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueCreateNestedManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueCreateNestedManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueCreateNestedManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueCreateNestedManyWithoutVersionInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<InstructionCreateNestedManyWithoutValueVersionInput>;
  name?: Maybe<Scalars['String']>;
  operationalMetrics?: Maybe<OperationalMetricCreateNestedManyWithoutVersionInput>;
  revenueMetrics?: Maybe<RevenueMetricCreateNestedManyWithoutVersionInput>;
  sequenceNumber: Scalars['Int'];
  starredAccounts?: Maybe<StarredAccountCreateNestedManyWithoutVersionInput>;
  type: VersionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year: Scalars['Int'];
};

export type ValueVersionGroupBy = {
  __typename?: 'ValueVersionGroupBy';
  _avg?: Maybe<ValueVersionAvgAggregate>;
  _count?: Maybe<ValueVersionCountAggregate>;
  _max?: Maybe<ValueVersionMaxAggregate>;
  _min?: Maybe<ValueVersionMinAggregate>;
  _sum?: Maybe<ValueVersionSumAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  sequenceNumber: Scalars['Int'];
  type: VersionType;
  updatedAt: Scalars['DateTime'];
  year: Scalars['Int'];
};

export type ValueVersionMaxAggregate = {
  __typename?: 'ValueVersionMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sequenceNumber?: Maybe<Scalars['Int']>;
  type?: Maybe<VersionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
};

export type ValueVersionMinAggregate = {
  __typename?: 'ValueVersionMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sequenceNumber?: Maybe<Scalars['Int']>;
  type?: Maybe<VersionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
};

export type ValueVersionOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  sequenceNumber?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  year?: Maybe<SortOrder>;
};

export type ValueVersionRelationFilter = {
  is?: Maybe<ValueVersionWhereInput>;
  isNot?: Maybe<ValueVersionWhereInput>;
};

export enum ValueVersionScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  SequenceNumber = 'sequenceNumber',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  Year = 'year'
}

export type ValueVersionScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<ValueVersionScalarWhereWithAggregatesInput>>;
  NOT?: Maybe<Array<ValueVersionScalarWhereWithAggregatesInput>>;
  OR?: Maybe<Array<ValueVersionScalarWhereWithAggregatesInput>>;
  createdAt?: Maybe<DateTimeWithAggregatesFilter>;
  id?: Maybe<StringWithAggregatesFilter>;
  name?: Maybe<StringNullableWithAggregatesFilter>;
  sequenceNumber?: Maybe<IntWithAggregatesFilter>;
  type?: Maybe<EnumVersionTypeWithAggregatesFilter>;
  updatedAt?: Maybe<DateTimeWithAggregatesFilter>;
  year?: Maybe<IntWithAggregatesFilter>;
};

export type ValueVersionSumAggregate = {
  __typename?: 'ValueVersionSumAggregate';
  sequenceNumber?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type ValueVersionUpdateInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutVersionInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutVersionInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutValueVersionInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutVersionInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutVersionInput>;
  sequenceNumber?: Maybe<IntFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutVersionInput>;
  type?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutVersionInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ValueVersionUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sequenceNumber?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ValueVersionUpdateOneRequiredWithoutAccountTotalValuesInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutAccountTotalValuesInput>;
  create?: Maybe<ValueVersionCreateWithoutAccountTotalValuesInput>;
  update?: Maybe<ValueVersionUpdateWithoutAccountTotalValuesInput>;
  upsert?: Maybe<ValueVersionUpsertWithoutAccountTotalValuesInput>;
};

export type ValueVersionUpdateOneRequiredWithoutAccountValueOverrideInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutAccountValueOverrideInput>;
  create?: Maybe<ValueVersionCreateWithoutAccountValueOverrideInput>;
  update?: Maybe<ValueVersionUpdateWithoutAccountValueOverrideInput>;
  upsert?: Maybe<ValueVersionUpsertWithoutAccountValueOverrideInput>;
};

export type ValueVersionUpdateOneRequiredWithoutAccountValuesInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutAccountValuesInput>;
  create?: Maybe<ValueVersionCreateWithoutAccountValuesInput>;
  update?: Maybe<ValueVersionUpdateWithoutAccountValuesInput>;
  upsert?: Maybe<ValueVersionUpsertWithoutAccountValuesInput>;
};

export type ValueVersionUpdateOneRequiredWithoutBudgetCategoryTotalValuesInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutBudgetCategoryTotalValuesInput>;
  create?: Maybe<ValueVersionCreateWithoutBudgetCategoryTotalValuesInput>;
  update?: Maybe<ValueVersionUpdateWithoutBudgetCategoryTotalValuesInput>;
  upsert?: Maybe<ValueVersionUpsertWithoutBudgetCategoryTotalValuesInput>;
};

export type ValueVersionUpdateOneRequiredWithoutBudgetCategoryValuesInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutBudgetCategoryValuesInput>;
  create?: Maybe<ValueVersionCreateWithoutBudgetCategoryValuesInput>;
  update?: Maybe<ValueVersionUpdateWithoutBudgetCategoryValuesInput>;
  upsert?: Maybe<ValueVersionUpsertWithoutBudgetCategoryValuesInput>;
};

export type ValueVersionUpdateOneRequiredWithoutBudgetComponentTotalValuesInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutBudgetComponentTotalValuesInput>;
  create?: Maybe<ValueVersionCreateWithoutBudgetComponentTotalValuesInput>;
  update?: Maybe<ValueVersionUpdateWithoutBudgetComponentTotalValuesInput>;
  upsert?: Maybe<ValueVersionUpsertWithoutBudgetComponentTotalValuesInput>;
};

export type ValueVersionUpdateOneRequiredWithoutBudgetComponentValuesInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutBudgetComponentValuesInput>;
  create?: Maybe<ValueVersionCreateWithoutBudgetComponentValuesInput>;
  update?: Maybe<ValueVersionUpdateWithoutBudgetComponentValuesInput>;
  upsert?: Maybe<ValueVersionUpsertWithoutBudgetComponentValuesInput>;
};

export type ValueVersionUpdateOneRequiredWithoutInstructionsInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutInstructionsInput>;
  create?: Maybe<ValueVersionCreateWithoutInstructionsInput>;
  update?: Maybe<ValueVersionUpdateWithoutInstructionsInput>;
  upsert?: Maybe<ValueVersionUpsertWithoutInstructionsInput>;
};

export type ValueVersionUpdateOneRequiredWithoutOperationalMetricsInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutOperationalMetricsInput>;
  create?: Maybe<ValueVersionCreateWithoutOperationalMetricsInput>;
  update?: Maybe<ValueVersionUpdateWithoutOperationalMetricsInput>;
  upsert?: Maybe<ValueVersionUpsertWithoutOperationalMetricsInput>;
};

export type ValueVersionUpdateOneRequiredWithoutRevenueMetricsInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutRevenueMetricsInput>;
  create?: Maybe<ValueVersionCreateWithoutRevenueMetricsInput>;
  update?: Maybe<ValueVersionUpdateWithoutRevenueMetricsInput>;
  upsert?: Maybe<ValueVersionUpsertWithoutRevenueMetricsInput>;
};

export type ValueVersionUpdateOneRequiredWithoutStarredAccountsInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutStarredAccountsInput>;
  create?: Maybe<ValueVersionCreateWithoutStarredAccountsInput>;
  update?: Maybe<ValueVersionUpdateWithoutStarredAccountsInput>;
  upsert?: Maybe<ValueVersionUpsertWithoutStarredAccountsInput>;
};

export type ValueVersionUpdateOneRequiredWithoutUnitTypeScheduleValuesInput = {
  connect?: Maybe<ValueVersionWhereUniqueInput>;
  connectOrCreate?: Maybe<ValueVersionCreateOrConnectWithoutUnitTypeScheduleValuesInput>;
  create?: Maybe<ValueVersionCreateWithoutUnitTypeScheduleValuesInput>;
  update?: Maybe<ValueVersionUpdateWithoutUnitTypeScheduleValuesInput>;
  upsert?: Maybe<ValueVersionUpsertWithoutUnitTypeScheduleValuesInput>;
};

export type ValueVersionUpdateWithoutAccountTotalValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutVersionInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutValueVersionInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutVersionInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutVersionInput>;
  sequenceNumber?: Maybe<IntFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutVersionInput>;
  type?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutVersionInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ValueVersionUpdateWithoutAccountValueOverrideInput = {
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutVersionInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutValueVersionInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutVersionInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutVersionInput>;
  sequenceNumber?: Maybe<IntFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutVersionInput>;
  type?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutVersionInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ValueVersionUpdateWithoutAccountValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutVersionInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutVersionInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutValueVersionInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutVersionInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutVersionInput>;
  sequenceNumber?: Maybe<IntFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutVersionInput>;
  type?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutVersionInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ValueVersionUpdateWithoutBudgetCategoryTotalValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutVersionInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutVersionInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutValueVersionInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutVersionInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutVersionInput>;
  sequenceNumber?: Maybe<IntFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutVersionInput>;
  type?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutVersionInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ValueVersionUpdateWithoutBudgetCategoryValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutVersionInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutVersionInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutValueVersionInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutVersionInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutVersionInput>;
  sequenceNumber?: Maybe<IntFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutVersionInput>;
  type?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutVersionInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ValueVersionUpdateWithoutBudgetComponentTotalValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutVersionInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutVersionInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutValueVersionInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutVersionInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutVersionInput>;
  sequenceNumber?: Maybe<IntFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutVersionInput>;
  type?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutVersionInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ValueVersionUpdateWithoutBudgetComponentValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutVersionInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutVersionInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutValueVersionInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutVersionInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutVersionInput>;
  sequenceNumber?: Maybe<IntFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutVersionInput>;
  type?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutVersionInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ValueVersionUpdateWithoutInstructionsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutVersionInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutVersionInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutVersionInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutVersionInput>;
  sequenceNumber?: Maybe<IntFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutVersionInput>;
  type?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutVersionInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ValueVersionUpdateWithoutOperationalMetricsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutVersionInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutVersionInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutValueVersionInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutVersionInput>;
  sequenceNumber?: Maybe<IntFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutVersionInput>;
  type?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutVersionInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ValueVersionUpdateWithoutRevenueMetricsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutVersionInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutVersionInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutValueVersionInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutVersionInput>;
  sequenceNumber?: Maybe<IntFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutVersionInput>;
  type?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutVersionInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ValueVersionUpdateWithoutStarredAccountsInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutVersionInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutVersionInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutValueVersionInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutVersionInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutVersionInput>;
  sequenceNumber?: Maybe<IntFieldUpdateOperationsInput>;
  type?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueUpdateManyWithoutVersionInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ValueVersionUpdateWithoutUnitTypeScheduleValuesInput = {
  AccountValueOverride?: Maybe<AccountValueOverrideUpdateManyWithoutVersionInput>;
  accountTotalValues?: Maybe<AccountTotalValueUpdateManyWithoutVersionInput>;
  accountValues?: Maybe<AccountValueUpdateManyWithoutVersionInput>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueUpdateManyWithoutVersionInput>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueUpdateManyWithoutVersionInput>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueUpdateManyWithoutVersionInput>;
  budgetComponentValues?: Maybe<BudgetComponentValueUpdateManyWithoutVersionInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  instructions?: Maybe<InstructionUpdateManyWithoutValueVersionInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  operationalMetrics?: Maybe<OperationalMetricUpdateManyWithoutVersionInput>;
  revenueMetrics?: Maybe<RevenueMetricUpdateManyWithoutVersionInput>;
  sequenceNumber?: Maybe<IntFieldUpdateOperationsInput>;
  starredAccounts?: Maybe<StarredAccountUpdateManyWithoutVersionInput>;
  type?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ValueVersionUpsertWithoutAccountTotalValuesInput = {
  create: ValueVersionCreateWithoutAccountTotalValuesInput;
  update: ValueVersionUpdateWithoutAccountTotalValuesInput;
};

export type ValueVersionUpsertWithoutAccountValueOverrideInput = {
  create: ValueVersionCreateWithoutAccountValueOverrideInput;
  update: ValueVersionUpdateWithoutAccountValueOverrideInput;
};

export type ValueVersionUpsertWithoutAccountValuesInput = {
  create: ValueVersionCreateWithoutAccountValuesInput;
  update: ValueVersionUpdateWithoutAccountValuesInput;
};

export type ValueVersionUpsertWithoutBudgetCategoryTotalValuesInput = {
  create: ValueVersionCreateWithoutBudgetCategoryTotalValuesInput;
  update: ValueVersionUpdateWithoutBudgetCategoryTotalValuesInput;
};

export type ValueVersionUpsertWithoutBudgetCategoryValuesInput = {
  create: ValueVersionCreateWithoutBudgetCategoryValuesInput;
  update: ValueVersionUpdateWithoutBudgetCategoryValuesInput;
};

export type ValueVersionUpsertWithoutBudgetComponentTotalValuesInput = {
  create: ValueVersionCreateWithoutBudgetComponentTotalValuesInput;
  update: ValueVersionUpdateWithoutBudgetComponentTotalValuesInput;
};

export type ValueVersionUpsertWithoutBudgetComponentValuesInput = {
  create: ValueVersionCreateWithoutBudgetComponentValuesInput;
  update: ValueVersionUpdateWithoutBudgetComponentValuesInput;
};

export type ValueVersionUpsertWithoutInstructionsInput = {
  create: ValueVersionCreateWithoutInstructionsInput;
  update: ValueVersionUpdateWithoutInstructionsInput;
};

export type ValueVersionUpsertWithoutOperationalMetricsInput = {
  create: ValueVersionCreateWithoutOperationalMetricsInput;
  update: ValueVersionUpdateWithoutOperationalMetricsInput;
};

export type ValueVersionUpsertWithoutRevenueMetricsInput = {
  create: ValueVersionCreateWithoutRevenueMetricsInput;
  update: ValueVersionUpdateWithoutRevenueMetricsInput;
};

export type ValueVersionUpsertWithoutStarredAccountsInput = {
  create: ValueVersionCreateWithoutStarredAccountsInput;
  update: ValueVersionUpdateWithoutStarredAccountsInput;
};

export type ValueVersionUpsertWithoutUnitTypeScheduleValuesInput = {
  create: ValueVersionCreateWithoutUnitTypeScheduleValuesInput;
  update: ValueVersionUpdateWithoutUnitTypeScheduleValuesInput;
};

export type ValueVersionWhereInput = {
  AND?: Maybe<Array<ValueVersionWhereInput>>;
  AccountValueOverride?: Maybe<AccountValueOverrideListRelationFilter>;
  NOT?: Maybe<Array<ValueVersionWhereInput>>;
  OR?: Maybe<Array<ValueVersionWhereInput>>;
  accountTotalValues?: Maybe<AccountTotalValueListRelationFilter>;
  accountValues?: Maybe<AccountValueListRelationFilter>;
  budgetCategoryTotalValues?: Maybe<BudgetCategoryTotalValueListRelationFilter>;
  budgetCategoryValues?: Maybe<BudgetCategoryValueListRelationFilter>;
  budgetComponentTotalValues?: Maybe<BudgetComponentTotalValueListRelationFilter>;
  budgetComponentValues?: Maybe<BudgetComponentValueListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  instructions?: Maybe<InstructionListRelationFilter>;
  name?: Maybe<StringNullableFilter>;
  operationalMetrics?: Maybe<OperationalMetricListRelationFilter>;
  revenueMetrics?: Maybe<RevenueMetricListRelationFilter>;
  sequenceNumber?: Maybe<IntFilter>;
  starredAccounts?: Maybe<StarredAccountListRelationFilter>;
  type?: Maybe<EnumVersionTypeFilter>;
  unitTypeScheduleValues?: Maybe<UnitTypeScheduleValueListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  year?: Maybe<IntFilter>;
};

export type ValueVersionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VarianceMetricModel = {
  __typename?: 'VarianceMetricModel';
  monthIndex: Scalars['Int'];
  value: Scalars['Decimal'];
  varianceAmount: Scalars['Decimal'];
  variancePercentage: Scalars['Decimal'];
  year: Scalars['Int'];
};

export enum VersionType {
  Actuals = 'ACTUALS',
  Budget = 'BUDGET',
  Reforecast = 'REFORECAST',
  YearTotal = 'YEAR_TOTAL'
}

export type Worksheet = {
  __typename?: 'Worksheet';
  createdAt: Scalars['DateTime'];
  destinationAccountId: Scalars['String'];
  id: Scalars['String'];
  propertyId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export type WorksheetAssumption = {
  __typename?: 'WorksheetAssumption';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  propertyId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  worksheetDriverId: Scalars['String'];
};

export type WorksheetAssumptionCreateManyPropertyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetDriverId: Scalars['String'];
};

export type WorksheetAssumptionCreateManyPropertyInputEnvelope = {
  data: Array<WorksheetAssumptionCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorksheetAssumptionCreateManyWorksheetDriverInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorksheetAssumptionCreateManyWorksheetDriverInputEnvelope = {
  data: Array<WorksheetAssumptionCreateManyWorksheetDriverInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorksheetAssumptionCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<WorksheetAssumptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorksheetAssumptionCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<WorksheetAssumptionCreateWithoutPropertyInput>>;
  createMany?: Maybe<WorksheetAssumptionCreateManyPropertyInputEnvelope>;
};

export type WorksheetAssumptionCreateNestedManyWithoutWorksheetDriverInput = {
  connect?: Maybe<Array<WorksheetAssumptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorksheetAssumptionCreateOrConnectWithoutWorksheetDriverInput>>;
  create?: Maybe<Array<WorksheetAssumptionCreateWithoutWorksheetDriverInput>>;
  createMany?: Maybe<WorksheetAssumptionCreateManyWorksheetDriverInputEnvelope>;
};

export type WorksheetAssumptionCreateOrConnectWithoutPropertyInput = {
  create: WorksheetAssumptionCreateWithoutPropertyInput;
  where: WorksheetAssumptionWhereUniqueInput;
};

export type WorksheetAssumptionCreateOrConnectWithoutWorksheetDriverInput = {
  create: WorksheetAssumptionCreateWithoutWorksheetDriverInput;
  where: WorksheetAssumptionWhereUniqueInput;
};

export type WorksheetAssumptionCreateWithoutPropertyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lines?: Maybe<WorksheetAssumptionLineCreateNestedManyWithoutWorksheetAssumptionInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worksheetDriver: DriverCreateNestedOneWithoutWorksheetAssumptionsInput;
};

export type WorksheetAssumptionCreateWithoutWorksheetDriverInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lines?: Maybe<WorksheetAssumptionLineCreateNestedManyWithoutWorksheetAssumptionInput>;
  property: PropertyCreateNestedOneWithoutWorksheetAssumptionsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorksheetAssumptionLineCreateManyWorksheetAssumptionInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WorksheetAssumptionLineCreateManyWorksheetAssumptionInputEnvelope = {
  data: Array<WorksheetAssumptionLineCreateManyWorksheetAssumptionInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorksheetAssumptionLineCreateNestedManyWithoutWorksheetAssumptionInput = {
  connect?: Maybe<Array<WorksheetAssumptionLineWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorksheetAssumptionLineCreateOrConnectWithoutWorksheetAssumptionInput>>;
  create?: Maybe<Array<WorksheetAssumptionLineCreateWithoutWorksheetAssumptionInput>>;
  createMany?: Maybe<WorksheetAssumptionLineCreateManyWorksheetAssumptionInputEnvelope>;
};

export type WorksheetAssumptionLineCreateOrConnectWithoutWorksheetAssumptionInput = {
  create: WorksheetAssumptionLineCreateWithoutWorksheetAssumptionInput;
  where: WorksheetAssumptionLineWhereUniqueInput;
};

export type WorksheetAssumptionLineCreateWithoutWorksheetAssumptionInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  values?: Maybe<WorksheetAssumptionLineValueCreateNestedManyWithoutWorksheetAssumptionLineInput>;
};

export type WorksheetAssumptionLineListRelationFilter = {
  every?: Maybe<WorksheetAssumptionLineWhereInput>;
  none?: Maybe<WorksheetAssumptionLineWhereInput>;
  some?: Maybe<WorksheetAssumptionLineWhereInput>;
};

export type WorksheetAssumptionLineRelationFilter = {
  is?: Maybe<WorksheetAssumptionLineWhereInput>;
  isNot?: Maybe<WorksheetAssumptionLineWhereInput>;
};

export type WorksheetAssumptionLineScalarWhereInput = {
  AND?: Maybe<Array<WorksheetAssumptionLineScalarWhereInput>>;
  NOT?: Maybe<Array<WorksheetAssumptionLineScalarWhereInput>>;
  OR?: Maybe<Array<WorksheetAssumptionLineScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  locked?: Maybe<BoolFilter>;
  order?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  worksheetAssumptionId?: Maybe<StringFilter>;
};

export type WorksheetAssumptionLineUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  locked?: Maybe<BoolFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorksheetAssumptionLineUpdateManyWithWhereWithoutWorksheetAssumptionInput = {
  data: WorksheetAssumptionLineUpdateManyMutationInput;
  where: WorksheetAssumptionLineScalarWhereInput;
};

export type WorksheetAssumptionLineUpdateManyWithoutWorksheetAssumptionInput = {
  connect?: Maybe<Array<WorksheetAssumptionLineWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorksheetAssumptionLineCreateOrConnectWithoutWorksheetAssumptionInput>>;
  create?: Maybe<Array<WorksheetAssumptionLineCreateWithoutWorksheetAssumptionInput>>;
  createMany?: Maybe<WorksheetAssumptionLineCreateManyWorksheetAssumptionInputEnvelope>;
  delete?: Maybe<Array<WorksheetAssumptionLineWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorksheetAssumptionLineScalarWhereInput>>;
  disconnect?: Maybe<Array<WorksheetAssumptionLineWhereUniqueInput>>;
  set?: Maybe<Array<WorksheetAssumptionLineWhereUniqueInput>>;
  update?: Maybe<Array<WorksheetAssumptionLineUpdateWithWhereUniqueWithoutWorksheetAssumptionInput>>;
  updateMany?: Maybe<Array<WorksheetAssumptionLineUpdateManyWithWhereWithoutWorksheetAssumptionInput>>;
  upsert?: Maybe<Array<WorksheetAssumptionLineUpsertWithWhereUniqueWithoutWorksheetAssumptionInput>>;
};

export type WorksheetAssumptionLineUpdateWithWhereUniqueWithoutWorksheetAssumptionInput = {
  data: WorksheetAssumptionLineUpdateWithoutWorksheetAssumptionInput;
  where: WorksheetAssumptionLineWhereUniqueInput;
};

export type WorksheetAssumptionLineUpdateWithoutWorksheetAssumptionInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  locked?: Maybe<BoolFieldUpdateOperationsInput>;
  order?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  values?: Maybe<WorksheetAssumptionLineValueUpdateManyWithoutWorksheetAssumptionLineInput>;
};

export type WorksheetAssumptionLineUpsertWithWhereUniqueWithoutWorksheetAssumptionInput = {
  create: WorksheetAssumptionLineCreateWithoutWorksheetAssumptionInput;
  update: WorksheetAssumptionLineUpdateWithoutWorksheetAssumptionInput;
  where: WorksheetAssumptionLineWhereUniqueInput;
};

export type WorksheetAssumptionLineValueCreateManyWorksheetAssumptionLineInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
  year: Scalars['Int'];
};

export type WorksheetAssumptionLineValueCreateManyWorksheetAssumptionLineInputEnvelope = {
  data: Array<WorksheetAssumptionLineValueCreateManyWorksheetAssumptionLineInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorksheetAssumptionLineValueCreateNestedManyWithoutWorksheetAssumptionLineInput = {
  connect?: Maybe<Array<WorksheetAssumptionLineValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorksheetAssumptionLineValueCreateOrConnectWithoutWorksheetAssumptionLineInput>>;
  create?: Maybe<Array<WorksheetAssumptionLineValueCreateWithoutWorksheetAssumptionLineInput>>;
  createMany?: Maybe<WorksheetAssumptionLineValueCreateManyWorksheetAssumptionLineInputEnvelope>;
};

export type WorksheetAssumptionLineValueCreateOrConnectWithoutWorksheetAssumptionLineInput = {
  create: WorksheetAssumptionLineValueCreateWithoutWorksheetAssumptionLineInput;
  where: WorksheetAssumptionLineValueWhereUniqueInput;
};

export type WorksheetAssumptionLineValueCreateWithoutWorksheetAssumptionLineInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  monthIndex: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Decimal'];
  year: Scalars['Int'];
};

export type WorksheetAssumptionLineValueListRelationFilter = {
  every?: Maybe<WorksheetAssumptionLineValueWhereInput>;
  none?: Maybe<WorksheetAssumptionLineValueWhereInput>;
  some?: Maybe<WorksheetAssumptionLineValueWhereInput>;
};

export type WorksheetAssumptionLineValueScalarWhereInput = {
  AND?: Maybe<Array<WorksheetAssumptionLineValueScalarWhereInput>>;
  NOT?: Maybe<Array<WorksheetAssumptionLineValueScalarWhereInput>>;
  OR?: Maybe<Array<WorksheetAssumptionLineValueScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  monthIndex?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<DecimalFilter>;
  worksheetAssumptionLineId?: Maybe<StringFilter>;
  year?: Maybe<IntFilter>;
};

export type WorksheetAssumptionLineValueUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<DecimalFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type WorksheetAssumptionLineValueUpdateManyWithWhereWithoutWorksheetAssumptionLineInput = {
  data: WorksheetAssumptionLineValueUpdateManyMutationInput;
  where: WorksheetAssumptionLineValueScalarWhereInput;
};

export type WorksheetAssumptionLineValueUpdateManyWithoutWorksheetAssumptionLineInput = {
  connect?: Maybe<Array<WorksheetAssumptionLineValueWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorksheetAssumptionLineValueCreateOrConnectWithoutWorksheetAssumptionLineInput>>;
  create?: Maybe<Array<WorksheetAssumptionLineValueCreateWithoutWorksheetAssumptionLineInput>>;
  createMany?: Maybe<WorksheetAssumptionLineValueCreateManyWorksheetAssumptionLineInputEnvelope>;
  delete?: Maybe<Array<WorksheetAssumptionLineValueWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorksheetAssumptionLineValueScalarWhereInput>>;
  disconnect?: Maybe<Array<WorksheetAssumptionLineValueWhereUniqueInput>>;
  set?: Maybe<Array<WorksheetAssumptionLineValueWhereUniqueInput>>;
  update?: Maybe<Array<WorksheetAssumptionLineValueUpdateWithWhereUniqueWithoutWorksheetAssumptionLineInput>>;
  updateMany?: Maybe<Array<WorksheetAssumptionLineValueUpdateManyWithWhereWithoutWorksheetAssumptionLineInput>>;
  upsert?: Maybe<Array<WorksheetAssumptionLineValueUpsertWithWhereUniqueWithoutWorksheetAssumptionLineInput>>;
};

export type WorksheetAssumptionLineValueUpdateWithWhereUniqueWithoutWorksheetAssumptionLineInput = {
  data: WorksheetAssumptionLineValueUpdateWithoutWorksheetAssumptionLineInput;
  where: WorksheetAssumptionLineValueWhereUniqueInput;
};

export type WorksheetAssumptionLineValueUpdateWithoutWorksheetAssumptionLineInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  monthIndex?: Maybe<IntFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<DecimalFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type WorksheetAssumptionLineValueUpsertWithWhereUniqueWithoutWorksheetAssumptionLineInput = {
  create: WorksheetAssumptionLineValueCreateWithoutWorksheetAssumptionLineInput;
  update: WorksheetAssumptionLineValueUpdateWithoutWorksheetAssumptionLineInput;
  where: WorksheetAssumptionLineValueWhereUniqueInput;
};

export type WorksheetAssumptionLineValueWhereInput = {
  AND?: Maybe<Array<WorksheetAssumptionLineValueWhereInput>>;
  NOT?: Maybe<Array<WorksheetAssumptionLineValueWhereInput>>;
  OR?: Maybe<Array<WorksheetAssumptionLineValueWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  monthIndex?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<DecimalFilter>;
  worksheetAssumptionLine?: Maybe<WorksheetAssumptionLineRelationFilter>;
  worksheetAssumptionLineId?: Maybe<StringFilter>;
  year?: Maybe<IntFilter>;
};

export type WorksheetAssumptionLineValueWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type WorksheetAssumptionLineWhereInput = {
  AND?: Maybe<Array<WorksheetAssumptionLineWhereInput>>;
  NOT?: Maybe<Array<WorksheetAssumptionLineWhereInput>>;
  OR?: Maybe<Array<WorksheetAssumptionLineWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  locked?: Maybe<BoolFilter>;
  order?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  values?: Maybe<WorksheetAssumptionLineValueListRelationFilter>;
  worksheetAssumption?: Maybe<WorksheetAssumptionRelationFilter>;
  worksheetAssumptionId?: Maybe<StringFilter>;
};

export type WorksheetAssumptionLineWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type WorksheetAssumptionListRelationFilter = {
  every?: Maybe<WorksheetAssumptionWhereInput>;
  none?: Maybe<WorksheetAssumptionWhereInput>;
  some?: Maybe<WorksheetAssumptionWhereInput>;
};

export type WorksheetAssumptionOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  worksheetDriverId?: Maybe<SortOrder>;
};

export type WorksheetAssumptionRelationFilter = {
  is?: Maybe<WorksheetAssumptionWhereInput>;
  isNot?: Maybe<WorksheetAssumptionWhereInput>;
};

export enum WorksheetAssumptionScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt',
  WorksheetDriverId = 'worksheetDriverId'
}

export type WorksheetAssumptionScalarWhereInput = {
  AND?: Maybe<Array<WorksheetAssumptionScalarWhereInput>>;
  NOT?: Maybe<Array<WorksheetAssumptionScalarWhereInput>>;
  OR?: Maybe<Array<WorksheetAssumptionScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  worksheetDriverId?: Maybe<StringFilter>;
};

export type WorksheetAssumptionUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorksheetAssumptionUpdateManyWithWhereWithoutPropertyInput = {
  data: WorksheetAssumptionUpdateManyMutationInput;
  where: WorksheetAssumptionScalarWhereInput;
};

export type WorksheetAssumptionUpdateManyWithWhereWithoutWorksheetDriverInput = {
  data: WorksheetAssumptionUpdateManyMutationInput;
  where: WorksheetAssumptionScalarWhereInput;
};

export type WorksheetAssumptionUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<WorksheetAssumptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorksheetAssumptionCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<WorksheetAssumptionCreateWithoutPropertyInput>>;
  createMany?: Maybe<WorksheetAssumptionCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<WorksheetAssumptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorksheetAssumptionScalarWhereInput>>;
  disconnect?: Maybe<Array<WorksheetAssumptionWhereUniqueInput>>;
  set?: Maybe<Array<WorksheetAssumptionWhereUniqueInput>>;
  update?: Maybe<Array<WorksheetAssumptionUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<WorksheetAssumptionUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<WorksheetAssumptionUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type WorksheetAssumptionUpdateManyWithoutWorksheetDriverInput = {
  connect?: Maybe<Array<WorksheetAssumptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorksheetAssumptionCreateOrConnectWithoutWorksheetDriverInput>>;
  create?: Maybe<Array<WorksheetAssumptionCreateWithoutWorksheetDriverInput>>;
  createMany?: Maybe<WorksheetAssumptionCreateManyWorksheetDriverInputEnvelope>;
  delete?: Maybe<Array<WorksheetAssumptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorksheetAssumptionScalarWhereInput>>;
  disconnect?: Maybe<Array<WorksheetAssumptionWhereUniqueInput>>;
  set?: Maybe<Array<WorksheetAssumptionWhereUniqueInput>>;
  update?: Maybe<Array<WorksheetAssumptionUpdateWithWhereUniqueWithoutWorksheetDriverInput>>;
  updateMany?: Maybe<Array<WorksheetAssumptionUpdateManyWithWhereWithoutWorksheetDriverInput>>;
  upsert?: Maybe<Array<WorksheetAssumptionUpsertWithWhereUniqueWithoutWorksheetDriverInput>>;
};

export type WorksheetAssumptionUpdateWithWhereUniqueWithoutPropertyInput = {
  data: WorksheetAssumptionUpdateWithoutPropertyInput;
  where: WorksheetAssumptionWhereUniqueInput;
};

export type WorksheetAssumptionUpdateWithWhereUniqueWithoutWorksheetDriverInput = {
  data: WorksheetAssumptionUpdateWithoutWorksheetDriverInput;
  where: WorksheetAssumptionWhereUniqueInput;
};

export type WorksheetAssumptionUpdateWithoutPropertyInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lines?: Maybe<WorksheetAssumptionLineUpdateManyWithoutWorksheetAssumptionInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  worksheetDriver?: Maybe<DriverUpdateOneRequiredWithoutWorksheetAssumptionsInput>;
};

export type WorksheetAssumptionUpdateWithoutWorksheetDriverInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lines?: Maybe<WorksheetAssumptionLineUpdateManyWithoutWorksheetAssumptionInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutWorksheetAssumptionsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorksheetAssumptionUpsertWithWhereUniqueWithoutPropertyInput = {
  create: WorksheetAssumptionCreateWithoutPropertyInput;
  update: WorksheetAssumptionUpdateWithoutPropertyInput;
  where: WorksheetAssumptionWhereUniqueInput;
};

export type WorksheetAssumptionUpsertWithWhereUniqueWithoutWorksheetDriverInput = {
  create: WorksheetAssumptionCreateWithoutWorksheetDriverInput;
  update: WorksheetAssumptionUpdateWithoutWorksheetDriverInput;
  where: WorksheetAssumptionWhereUniqueInput;
};

export type WorksheetAssumptionWhereInput = {
  AND?: Maybe<Array<WorksheetAssumptionWhereInput>>;
  NOT?: Maybe<Array<WorksheetAssumptionWhereInput>>;
  OR?: Maybe<Array<WorksheetAssumptionWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  lines?: Maybe<WorksheetAssumptionLineListRelationFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  worksheetDriver?: Maybe<DriverRelationFilter>;
  worksheetDriverId?: Maybe<StringFilter>;
};

export type WorksheetAssumptionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  worksheetDriverId_propertyId?: Maybe<WorksheetAssumptionWorksheetDriverIdPropertyIdCompoundUniqueInput>;
};

export type WorksheetAssumptionWorksheetDriverIdPropertyIdCompoundUniqueInput = {
  propertyId: Scalars['String'];
  worksheetDriverId: Scalars['String'];
};

export type WorksheetCreateManyDestinationAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  propertyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type WorksheetCreateManyDestinationAccountInputEnvelope = {
  data: Array<WorksheetCreateManyDestinationAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorksheetCreateManyPropertyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationAccountId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type WorksheetCreateManyPropertyInputEnvelope = {
  data: Array<WorksheetCreateManyPropertyInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorksheetCreateNestedManyWithoutDestinationAccountInput = {
  connect?: Maybe<Array<WorksheetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorksheetCreateOrConnectWithoutDestinationAccountInput>>;
  create?: Maybe<Array<WorksheetCreateWithoutDestinationAccountInput>>;
  createMany?: Maybe<WorksheetCreateManyDestinationAccountInputEnvelope>;
};

export type WorksheetCreateNestedManyWithoutPropertyInput = {
  connect?: Maybe<Array<WorksheetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorksheetCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<WorksheetCreateWithoutPropertyInput>>;
  createMany?: Maybe<WorksheetCreateManyPropertyInputEnvelope>;
};

export type WorksheetCreateOrConnectWithoutDestinationAccountInput = {
  create: WorksheetCreateWithoutDestinationAccountInput;
  where: WorksheetWhereUniqueInput;
};

export type WorksheetCreateOrConnectWithoutPropertyInput = {
  create: WorksheetCreateWithoutPropertyInput;
  where: WorksheetWhereUniqueInput;
};

export type WorksheetCreateWithoutDestinationAccountInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  lines?: Maybe<WorksheetLineCreateNestedManyWithoutWorksheetInput>;
  property: PropertyCreateNestedOneWithoutWorksheetsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type WorksheetCreateWithoutPropertyInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  destinationAccount: AccountV2CreateNestedOneWithoutWorksheetsInput;
  id?: Maybe<Scalars['String']>;
  lines?: Maybe<WorksheetLineCreateNestedManyWithoutWorksheetInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionType: VersionType;
  year: Scalars['Int'];
};

export type WorksheetDriverLineCreateInput = {
  accountId: Scalars['String'];
  lineName: Scalars['String'];
  lineValues: Array<WorksheetLineValueCreateInput>;
  locked?: Maybe<Scalars['Boolean']>;
  propertyId: Scalars['String'];
  versionType: VersionType;
};

export type WorksheetDriverLineUpdateByDescriptionInput = {
  destinationAccountId: Scalars['String'];
  lineName: Scalars['String'];
  lineValues: Array<WorksheetLineValueUpdateInput>;
  locked?: Maybe<Scalars['Boolean']>;
  propertyId: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Float'];
};

export type WorksheetDriverLineUpdateInput = {
  lineId: Scalars['String'];
  lineName?: Maybe<Scalars['String']>;
  lineValues: Array<WorksheetLineValueUpdateInput>;
  locked?: Maybe<Scalars['Boolean']>;
};

export type WorksheetDriverModel = {
  __typename?: 'WorksheetDriverModel';
  isDriven: Scalars['Boolean'];
  worksheetLines: Array<WorksheetLineModel>;
};

export type WorksheetLine = {
  __typename?: 'WorksheetLine';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  locked: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
  worksheetId: Scalars['String'];
};

export type WorksheetLineCreateManyWorksheetInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  locked: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
};

export type WorksheetLineCreateManyWorksheetInputEnvelope = {
  data: Array<WorksheetLineCreateManyWorksheetInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type WorksheetLineCreateNestedManyWithoutWorksheetInput = {
  connect?: Maybe<Array<WorksheetLineWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorksheetLineCreateOrConnectWithoutWorksheetInput>>;
  create?: Maybe<Array<WorksheetLineCreateWithoutWorksheetInput>>;
  createMany?: Maybe<WorksheetLineCreateManyWorksheetInputEnvelope>;
};

export type WorksheetLineCreateOrConnectWithoutWorksheetInput = {
  create: WorksheetLineCreateWithoutWorksheetInput;
  where: WorksheetLineWhereUniqueInput;
};

export type WorksheetLineCreateWithoutWorksheetInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  locked: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  valueMonth0?: Maybe<Scalars['Decimal']>;
  valueMonth1?: Maybe<Scalars['Decimal']>;
  valueMonth2?: Maybe<Scalars['Decimal']>;
  valueMonth3?: Maybe<Scalars['Decimal']>;
  valueMonth4?: Maybe<Scalars['Decimal']>;
  valueMonth5?: Maybe<Scalars['Decimal']>;
  valueMonth6?: Maybe<Scalars['Decimal']>;
  valueMonth7?: Maybe<Scalars['Decimal']>;
  valueMonth8?: Maybe<Scalars['Decimal']>;
  valueMonth9?: Maybe<Scalars['Decimal']>;
  valueMonth10?: Maybe<Scalars['Decimal']>;
  valueMonth11?: Maybe<Scalars['Decimal']>;
};

export type WorksheetLineListRelationFilter = {
  every?: Maybe<WorksheetLineWhereInput>;
  none?: Maybe<WorksheetLineWhereInput>;
  some?: Maybe<WorksheetLineWhereInput>;
};

export type WorksheetLineModel = {
  __typename?: 'WorksheetLineModel';
  description: Scalars['String'];
  lineId: Scalars['String'];
  locked: Scalars['Boolean'];
  values: Array<Maybe<Scalars['Float']>>;
};

export type WorksheetLineScalarWhereInput = {
  AND?: Maybe<Array<WorksheetLineScalarWhereInput>>;
  NOT?: Maybe<Array<WorksheetLineScalarWhereInput>>;
  OR?: Maybe<Array<WorksheetLineScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  locked?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  valueMonth0?: Maybe<DecimalNullableFilter>;
  valueMonth1?: Maybe<DecimalNullableFilter>;
  valueMonth2?: Maybe<DecimalNullableFilter>;
  valueMonth3?: Maybe<DecimalNullableFilter>;
  valueMonth4?: Maybe<DecimalNullableFilter>;
  valueMonth5?: Maybe<DecimalNullableFilter>;
  valueMonth6?: Maybe<DecimalNullableFilter>;
  valueMonth7?: Maybe<DecimalNullableFilter>;
  valueMonth8?: Maybe<DecimalNullableFilter>;
  valueMonth9?: Maybe<DecimalNullableFilter>;
  valueMonth10?: Maybe<DecimalNullableFilter>;
  valueMonth11?: Maybe<DecimalNullableFilter>;
  worksheetId?: Maybe<StringFilter>;
};

export type WorksheetLineUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  locked?: Maybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  valueMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
};

export type WorksheetLineUpdateManyWithWhereWithoutWorksheetInput = {
  data: WorksheetLineUpdateManyMutationInput;
  where: WorksheetLineScalarWhereInput;
};

export type WorksheetLineUpdateManyWithoutWorksheetInput = {
  connect?: Maybe<Array<WorksheetLineWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorksheetLineCreateOrConnectWithoutWorksheetInput>>;
  create?: Maybe<Array<WorksheetLineCreateWithoutWorksheetInput>>;
  createMany?: Maybe<WorksheetLineCreateManyWorksheetInputEnvelope>;
  delete?: Maybe<Array<WorksheetLineWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorksheetLineScalarWhereInput>>;
  disconnect?: Maybe<Array<WorksheetLineWhereUniqueInput>>;
  set?: Maybe<Array<WorksheetLineWhereUniqueInput>>;
  update?: Maybe<Array<WorksheetLineUpdateWithWhereUniqueWithoutWorksheetInput>>;
  updateMany?: Maybe<Array<WorksheetLineUpdateManyWithWhereWithoutWorksheetInput>>;
  upsert?: Maybe<Array<WorksheetLineUpsertWithWhereUniqueWithoutWorksheetInput>>;
};

export type WorksheetLineUpdateWithWhereUniqueWithoutWorksheetInput = {
  data: WorksheetLineUpdateWithoutWorksheetInput;
  where: WorksheetLineWhereUniqueInput;
};

export type WorksheetLineUpdateWithoutWorksheetInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  locked?: Maybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  valueMonth0?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth1?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth2?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth3?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth4?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth5?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth6?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth7?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth8?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth9?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth10?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  valueMonth11?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
};

export type WorksheetLineUpsertWithWhereUniqueWithoutWorksheetInput = {
  create: WorksheetLineCreateWithoutWorksheetInput;
  update: WorksheetLineUpdateWithoutWorksheetInput;
  where: WorksheetLineWhereUniqueInput;
};

export type WorksheetLineValueCreateInput = {
  monthIndex: Scalars['Int'];
  value: Scalars['Decimal'];
};

export type WorksheetLineValueUpdateInput = {
  monthIndex: Scalars['Int'];
  value?: Maybe<Scalars['Decimal']>;
};

export type WorksheetLineWhereInput = {
  AND?: Maybe<Array<WorksheetLineWhereInput>>;
  NOT?: Maybe<Array<WorksheetLineWhereInput>>;
  OR?: Maybe<Array<WorksheetLineWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  locked?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  valueMonth0?: Maybe<DecimalNullableFilter>;
  valueMonth1?: Maybe<DecimalNullableFilter>;
  valueMonth2?: Maybe<DecimalNullableFilter>;
  valueMonth3?: Maybe<DecimalNullableFilter>;
  valueMonth4?: Maybe<DecimalNullableFilter>;
  valueMonth5?: Maybe<DecimalNullableFilter>;
  valueMonth6?: Maybe<DecimalNullableFilter>;
  valueMonth7?: Maybe<DecimalNullableFilter>;
  valueMonth8?: Maybe<DecimalNullableFilter>;
  valueMonth9?: Maybe<DecimalNullableFilter>;
  valueMonth10?: Maybe<DecimalNullableFilter>;
  valueMonth11?: Maybe<DecimalNullableFilter>;
  worksheet?: Maybe<WorksheetRelationFilter>;
  worksheetId?: Maybe<StringFilter>;
};

export type WorksheetLineWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  worksheetId_description?: Maybe<WorksheetLineWorksheetIdDescriptionCompoundUniqueInput>;
};

export type WorksheetLineWithPropertyAccountVersionTypeModel = {
  __typename?: 'WorksheetLineWithPropertyAccountVersionTypeModel';
  accountId: Scalars['ID'];
  assumptionId: Scalars['ID'];
  description: Scalars['String'];
  lineId: Scalars['ID'];
  locked: Scalars['Boolean'];
  propertyId: Scalars['ID'];
  propertyName: Scalars['String'];
  values: Array<Maybe<Scalars['Float']>>;
  versionType: VersionType;
};

export type WorksheetLineWorksheetIdDescriptionCompoundUniqueInput = {
  description: Scalars['String'];
  worksheetId: Scalars['String'];
};

export type WorksheetListRelationFilter = {
  every?: Maybe<WorksheetWhereInput>;
  none?: Maybe<WorksheetWhereInput>;
  some?: Maybe<WorksheetWhereInput>;
};

export type WorksheetOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  destinationAccountId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  propertyId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  versionType?: Maybe<SortOrder>;
  year?: Maybe<SortOrder>;
};

export type WorksheetRelationFilter = {
  is?: Maybe<WorksheetWhereInput>;
  isNot?: Maybe<WorksheetWhereInput>;
};

export enum WorksheetScalarFieldEnum {
  CreatedAt = 'createdAt',
  DestinationAccountId = 'destinationAccountId',
  Id = 'id',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt',
  VersionType = 'versionType',
  Year = 'year'
}

export type WorksheetScalarWhereInput = {
  AND?: Maybe<Array<WorksheetScalarWhereInput>>;
  NOT?: Maybe<Array<WorksheetScalarWhereInput>>;
  OR?: Maybe<Array<WorksheetScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  destinationAccountId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type WorksheetUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type WorksheetUpdateManyWithWhereWithoutDestinationAccountInput = {
  data: WorksheetUpdateManyMutationInput;
  where: WorksheetScalarWhereInput;
};

export type WorksheetUpdateManyWithWhereWithoutPropertyInput = {
  data: WorksheetUpdateManyMutationInput;
  where: WorksheetScalarWhereInput;
};

export type WorksheetUpdateManyWithoutDestinationAccountInput = {
  connect?: Maybe<Array<WorksheetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorksheetCreateOrConnectWithoutDestinationAccountInput>>;
  create?: Maybe<Array<WorksheetCreateWithoutDestinationAccountInput>>;
  createMany?: Maybe<WorksheetCreateManyDestinationAccountInputEnvelope>;
  delete?: Maybe<Array<WorksheetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorksheetScalarWhereInput>>;
  disconnect?: Maybe<Array<WorksheetWhereUniqueInput>>;
  set?: Maybe<Array<WorksheetWhereUniqueInput>>;
  update?: Maybe<Array<WorksheetUpdateWithWhereUniqueWithoutDestinationAccountInput>>;
  updateMany?: Maybe<Array<WorksheetUpdateManyWithWhereWithoutDestinationAccountInput>>;
  upsert?: Maybe<Array<WorksheetUpsertWithWhereUniqueWithoutDestinationAccountInput>>;
};

export type WorksheetUpdateManyWithoutPropertyInput = {
  connect?: Maybe<Array<WorksheetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<WorksheetCreateOrConnectWithoutPropertyInput>>;
  create?: Maybe<Array<WorksheetCreateWithoutPropertyInput>>;
  createMany?: Maybe<WorksheetCreateManyPropertyInputEnvelope>;
  delete?: Maybe<Array<WorksheetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WorksheetScalarWhereInput>>;
  disconnect?: Maybe<Array<WorksheetWhereUniqueInput>>;
  set?: Maybe<Array<WorksheetWhereUniqueInput>>;
  update?: Maybe<Array<WorksheetUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: Maybe<Array<WorksheetUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: Maybe<Array<WorksheetUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type WorksheetUpdateWithWhereUniqueWithoutDestinationAccountInput = {
  data: WorksheetUpdateWithoutDestinationAccountInput;
  where: WorksheetWhereUniqueInput;
};

export type WorksheetUpdateWithWhereUniqueWithoutPropertyInput = {
  data: WorksheetUpdateWithoutPropertyInput;
  where: WorksheetWhereUniqueInput;
};

export type WorksheetUpdateWithoutDestinationAccountInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lines?: Maybe<WorksheetLineUpdateManyWithoutWorksheetInput>;
  property?: Maybe<PropertyUpdateOneRequiredWithoutWorksheetsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type WorksheetUpdateWithoutPropertyInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  destinationAccount?: Maybe<AccountV2UpdateOneRequiredWithoutWorksheetsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lines?: Maybe<WorksheetLineUpdateManyWithoutWorksheetInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  versionType?: Maybe<EnumVersionTypeFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
};

export type WorksheetUpsertWithWhereUniqueWithoutDestinationAccountInput = {
  create: WorksheetCreateWithoutDestinationAccountInput;
  update: WorksheetUpdateWithoutDestinationAccountInput;
  where: WorksheetWhereUniqueInput;
};

export type WorksheetUpsertWithWhereUniqueWithoutPropertyInput = {
  create: WorksheetCreateWithoutPropertyInput;
  update: WorksheetUpdateWithoutPropertyInput;
  where: WorksheetWhereUniqueInput;
};

export type WorksheetWhereInput = {
  AND?: Maybe<Array<WorksheetWhereInput>>;
  NOT?: Maybe<Array<WorksheetWhereInput>>;
  OR?: Maybe<Array<WorksheetWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  destinationAccount?: Maybe<AccountV2RelationFilter>;
  destinationAccountId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  lines?: Maybe<WorksheetLineListRelationFilter>;
  property?: Maybe<PropertyRelationFilter>;
  propertyId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  versionType?: Maybe<EnumVersionTypeFilter>;
  year?: Maybe<IntFilter>;
};

export type WorksheetWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  year_versionType_propertyId_destinationAccountId?: Maybe<WorksheetYearVersionTypePropertyIdDestinationAccountIdCompoundUniqueInput>;
};

export type WorksheetYearVersionTypePropertyIdDestinationAccountIdCompoundUniqueInput = {
  destinationAccountId: Scalars['String'];
  propertyId: Scalars['String'];
  versionType: VersionType;
  year: Scalars['Int'];
};

export type YardiChartOfAccountsMetadata = {
  __typename?: 'YardiChartOfAccountsMetadata';
  bookNum: Scalars['String'];
  chartOfAccountsId: Scalars['String'];
};

export type YardiChartOfAccountsMetadataCreateManyChartOfAccountsInput = {
  bookNum: Scalars['String'];
};

export type YardiChartOfAccountsMetadataCreateManyChartOfAccountsInputEnvelope = {
  data: Array<YardiChartOfAccountsMetadataCreateManyChartOfAccountsInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type YardiChartOfAccountsMetadataCreateNestedManyWithoutChartOfAccountsInput = {
  connect?: Maybe<Array<YardiChartOfAccountsMetadataWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<YardiChartOfAccountsMetadataCreateOrConnectWithoutChartOfAccountsInput>>;
  create?: Maybe<Array<YardiChartOfAccountsMetadataCreateWithoutChartOfAccountsInput>>;
  createMany?: Maybe<YardiChartOfAccountsMetadataCreateManyChartOfAccountsInputEnvelope>;
};

export type YardiChartOfAccountsMetadataCreateOrConnectWithoutChartOfAccountsInput = {
  create: YardiChartOfAccountsMetadataCreateWithoutChartOfAccountsInput;
  where: YardiChartOfAccountsMetadataWhereUniqueInput;
};

export type YardiChartOfAccountsMetadataCreateWithoutChartOfAccountsInput = {
  bookNum: Scalars['String'];
};

export type YardiChartOfAccountsMetadataListRelationFilter = {
  every?: Maybe<YardiChartOfAccountsMetadataWhereInput>;
  none?: Maybe<YardiChartOfAccountsMetadataWhereInput>;
  some?: Maybe<YardiChartOfAccountsMetadataWhereInput>;
};

export type YardiChartOfAccountsMetadataOrderByInput = {
  bookNum?: Maybe<SortOrder>;
  chartOfAccountsId?: Maybe<SortOrder>;
};

export enum YardiChartOfAccountsMetadataScalarFieldEnum {
  BookNum = 'bookNum',
  ChartOfAccountsId = 'chartOfAccountsId'
}

export type YardiChartOfAccountsMetadataScalarWhereInput = {
  AND?: Maybe<Array<YardiChartOfAccountsMetadataScalarWhereInput>>;
  NOT?: Maybe<Array<YardiChartOfAccountsMetadataScalarWhereInput>>;
  OR?: Maybe<Array<YardiChartOfAccountsMetadataScalarWhereInput>>;
  bookNum?: Maybe<StringFilter>;
  chartOfAccountsId?: Maybe<StringFilter>;
};

export type YardiChartOfAccountsMetadataUpdateManyMutationInput = {
  bookNum?: Maybe<StringFieldUpdateOperationsInput>;
};

export type YardiChartOfAccountsMetadataUpdateManyWithWhereWithoutChartOfAccountsInput = {
  data: YardiChartOfAccountsMetadataUpdateManyMutationInput;
  where: YardiChartOfAccountsMetadataScalarWhereInput;
};

export type YardiChartOfAccountsMetadataUpdateManyWithoutChartOfAccountsInput = {
  connect?: Maybe<Array<YardiChartOfAccountsMetadataWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<YardiChartOfAccountsMetadataCreateOrConnectWithoutChartOfAccountsInput>>;
  create?: Maybe<Array<YardiChartOfAccountsMetadataCreateWithoutChartOfAccountsInput>>;
  createMany?: Maybe<YardiChartOfAccountsMetadataCreateManyChartOfAccountsInputEnvelope>;
  delete?: Maybe<Array<YardiChartOfAccountsMetadataWhereUniqueInput>>;
  deleteMany?: Maybe<Array<YardiChartOfAccountsMetadataScalarWhereInput>>;
  disconnect?: Maybe<Array<YardiChartOfAccountsMetadataWhereUniqueInput>>;
  set?: Maybe<Array<YardiChartOfAccountsMetadataWhereUniqueInput>>;
  update?: Maybe<Array<YardiChartOfAccountsMetadataUpdateWithWhereUniqueWithoutChartOfAccountsInput>>;
  updateMany?: Maybe<Array<YardiChartOfAccountsMetadataUpdateManyWithWhereWithoutChartOfAccountsInput>>;
  upsert?: Maybe<Array<YardiChartOfAccountsMetadataUpsertWithWhereUniqueWithoutChartOfAccountsInput>>;
};

export type YardiChartOfAccountsMetadataUpdateWithWhereUniqueWithoutChartOfAccountsInput = {
  data: YardiChartOfAccountsMetadataUpdateWithoutChartOfAccountsInput;
  where: YardiChartOfAccountsMetadataWhereUniqueInput;
};

export type YardiChartOfAccountsMetadataUpdateWithoutChartOfAccountsInput = {
  bookNum?: Maybe<StringFieldUpdateOperationsInput>;
};

export type YardiChartOfAccountsMetadataUpsertWithWhereUniqueWithoutChartOfAccountsInput = {
  create: YardiChartOfAccountsMetadataCreateWithoutChartOfAccountsInput;
  update: YardiChartOfAccountsMetadataUpdateWithoutChartOfAccountsInput;
  where: YardiChartOfAccountsMetadataWhereUniqueInput;
};

export type YardiChartOfAccountsMetadataWhereInput = {
  AND?: Maybe<Array<YardiChartOfAccountsMetadataWhereInput>>;
  NOT?: Maybe<Array<YardiChartOfAccountsMetadataWhereInput>>;
  OR?: Maybe<Array<YardiChartOfAccountsMetadataWhereInput>>;
  bookNum?: Maybe<StringFilter>;
  chartOfAccounts?: Maybe<ChartOfAccountsRelationFilter>;
  chartOfAccountsId?: Maybe<StringFilter>;
};

export type YardiChartOfAccountsMetadataWhereUniqueInput = {
  chartOfAccountsId?: Maybe<Scalars['String']>;
};

export type YardiReportAccountMetadata = {
  __typename?: 'YardiReportAccountMetadata';
  accountId: Scalars['String'];
  clientId: Scalars['String'];
  exportAccountId: Scalars['String'];
};

export type YardiReportAccountMetadataClientIdAccountIdCompoundUniqueInput = {
  accountId: Scalars['String'];
  clientId: Scalars['String'];
};

export type YardiReportAccountMetadataCreateManyAccountInput = {
  clientId: Scalars['String'];
  exportAccountId: Scalars['String'];
};

export type YardiReportAccountMetadataCreateManyAccountInputEnvelope = {
  data: Array<YardiReportAccountMetadataCreateManyAccountInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type YardiReportAccountMetadataCreateManyClientInput = {
  accountId: Scalars['String'];
  exportAccountId: Scalars['String'];
};

export type YardiReportAccountMetadataCreateManyClientInputEnvelope = {
  data: Array<YardiReportAccountMetadataCreateManyClientInput>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type YardiReportAccountMetadataCreateNestedManyWithoutAccountInput = {
  connect?: Maybe<Array<YardiReportAccountMetadataWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<YardiReportAccountMetadataCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<YardiReportAccountMetadataCreateWithoutAccountInput>>;
  createMany?: Maybe<YardiReportAccountMetadataCreateManyAccountInputEnvelope>;
};

export type YardiReportAccountMetadataCreateNestedManyWithoutClientInput = {
  connect?: Maybe<Array<YardiReportAccountMetadataWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<YardiReportAccountMetadataCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<YardiReportAccountMetadataCreateWithoutClientInput>>;
  createMany?: Maybe<YardiReportAccountMetadataCreateManyClientInputEnvelope>;
};

export type YardiReportAccountMetadataCreateOrConnectWithoutAccountInput = {
  create: YardiReportAccountMetadataCreateWithoutAccountInput;
  where: YardiReportAccountMetadataWhereUniqueInput;
};

export type YardiReportAccountMetadataCreateOrConnectWithoutClientInput = {
  create: YardiReportAccountMetadataCreateWithoutClientInput;
  where: YardiReportAccountMetadataWhereUniqueInput;
};

export type YardiReportAccountMetadataCreateWithoutAccountInput = {
  client: ClientCreateNestedOneWithoutYardiReportAccountMetadataInput;
  exportAccountId: Scalars['String'];
};

export type YardiReportAccountMetadataCreateWithoutClientInput = {
  account: AccountV2CreateNestedOneWithoutYardiReportAccountMetadataInput;
  exportAccountId: Scalars['String'];
};

export type YardiReportAccountMetadataListRelationFilter = {
  every?: Maybe<YardiReportAccountMetadataWhereInput>;
  none?: Maybe<YardiReportAccountMetadataWhereInput>;
  some?: Maybe<YardiReportAccountMetadataWhereInput>;
};

export type YardiReportAccountMetadataOrderByInput = {
  accountId?: Maybe<SortOrder>;
  clientId?: Maybe<SortOrder>;
  exportAccountId?: Maybe<SortOrder>;
};

export enum YardiReportAccountMetadataScalarFieldEnum {
  AccountId = 'accountId',
  ClientId = 'clientId',
  ExportAccountId = 'exportAccountId'
}

export type YardiReportAccountMetadataScalarWhereInput = {
  AND?: Maybe<Array<YardiReportAccountMetadataScalarWhereInput>>;
  NOT?: Maybe<Array<YardiReportAccountMetadataScalarWhereInput>>;
  OR?: Maybe<Array<YardiReportAccountMetadataScalarWhereInput>>;
  accountId?: Maybe<StringFilter>;
  clientId?: Maybe<StringFilter>;
  exportAccountId?: Maybe<StringFilter>;
};

export type YardiReportAccountMetadataUpdateManyMutationInput = {
  exportAccountId?: Maybe<StringFieldUpdateOperationsInput>;
};

export type YardiReportAccountMetadataUpdateManyWithWhereWithoutAccountInput = {
  data: YardiReportAccountMetadataUpdateManyMutationInput;
  where: YardiReportAccountMetadataScalarWhereInput;
};

export type YardiReportAccountMetadataUpdateManyWithWhereWithoutClientInput = {
  data: YardiReportAccountMetadataUpdateManyMutationInput;
  where: YardiReportAccountMetadataScalarWhereInput;
};

export type YardiReportAccountMetadataUpdateManyWithoutAccountInput = {
  connect?: Maybe<Array<YardiReportAccountMetadataWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<YardiReportAccountMetadataCreateOrConnectWithoutAccountInput>>;
  create?: Maybe<Array<YardiReportAccountMetadataCreateWithoutAccountInput>>;
  createMany?: Maybe<YardiReportAccountMetadataCreateManyAccountInputEnvelope>;
  delete?: Maybe<Array<YardiReportAccountMetadataWhereUniqueInput>>;
  deleteMany?: Maybe<Array<YardiReportAccountMetadataScalarWhereInput>>;
  disconnect?: Maybe<Array<YardiReportAccountMetadataWhereUniqueInput>>;
  set?: Maybe<Array<YardiReportAccountMetadataWhereUniqueInput>>;
  update?: Maybe<Array<YardiReportAccountMetadataUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: Maybe<Array<YardiReportAccountMetadataUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: Maybe<Array<YardiReportAccountMetadataUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type YardiReportAccountMetadataUpdateManyWithoutClientInput = {
  connect?: Maybe<Array<YardiReportAccountMetadataWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<YardiReportAccountMetadataCreateOrConnectWithoutClientInput>>;
  create?: Maybe<Array<YardiReportAccountMetadataCreateWithoutClientInput>>;
  createMany?: Maybe<YardiReportAccountMetadataCreateManyClientInputEnvelope>;
  delete?: Maybe<Array<YardiReportAccountMetadataWhereUniqueInput>>;
  deleteMany?: Maybe<Array<YardiReportAccountMetadataScalarWhereInput>>;
  disconnect?: Maybe<Array<YardiReportAccountMetadataWhereUniqueInput>>;
  set?: Maybe<Array<YardiReportAccountMetadataWhereUniqueInput>>;
  update?: Maybe<Array<YardiReportAccountMetadataUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<YardiReportAccountMetadataUpdateManyWithWhereWithoutClientInput>>;
  upsert?: Maybe<Array<YardiReportAccountMetadataUpsertWithWhereUniqueWithoutClientInput>>;
};

export type YardiReportAccountMetadataUpdateWithWhereUniqueWithoutAccountInput = {
  data: YardiReportAccountMetadataUpdateWithoutAccountInput;
  where: YardiReportAccountMetadataWhereUniqueInput;
};

export type YardiReportAccountMetadataUpdateWithWhereUniqueWithoutClientInput = {
  data: YardiReportAccountMetadataUpdateWithoutClientInput;
  where: YardiReportAccountMetadataWhereUniqueInput;
};

export type YardiReportAccountMetadataUpdateWithoutAccountInput = {
  client?: Maybe<ClientUpdateOneRequiredWithoutYardiReportAccountMetadataInput>;
  exportAccountId?: Maybe<StringFieldUpdateOperationsInput>;
};

export type YardiReportAccountMetadataUpdateWithoutClientInput = {
  account?: Maybe<AccountV2UpdateOneRequiredWithoutYardiReportAccountMetadataInput>;
  exportAccountId?: Maybe<StringFieldUpdateOperationsInput>;
};

export type YardiReportAccountMetadataUpsertWithWhereUniqueWithoutAccountInput = {
  create: YardiReportAccountMetadataCreateWithoutAccountInput;
  update: YardiReportAccountMetadataUpdateWithoutAccountInput;
  where: YardiReportAccountMetadataWhereUniqueInput;
};

export type YardiReportAccountMetadataUpsertWithWhereUniqueWithoutClientInput = {
  create: YardiReportAccountMetadataCreateWithoutClientInput;
  update: YardiReportAccountMetadataUpdateWithoutClientInput;
  where: YardiReportAccountMetadataWhereUniqueInput;
};

export type YardiReportAccountMetadataWhereInput = {
  AND?: Maybe<Array<YardiReportAccountMetadataWhereInput>>;
  NOT?: Maybe<Array<YardiReportAccountMetadataWhereInput>>;
  OR?: Maybe<Array<YardiReportAccountMetadataWhereInput>>;
  account?: Maybe<AccountV2RelationFilter>;
  accountId?: Maybe<StringFilter>;
  client?: Maybe<ClientRelationFilter>;
  clientId?: Maybe<StringFilter>;
  exportAccountId?: Maybe<StringFilter>;
};

export type YardiReportAccountMetadataWhereUniqueInput = {
  clientId_accountId?: Maybe<YardiReportAccountMetadataClientIdAccountIdCompoundUniqueInput>;
};

export type YearAndType = {
  versionType: VersionType;
  year: Scalars['Int'];
};

export type UpdateAccPercentageDriverAssumptionsMutationVariables = Exact<{
  items: Array<AccPercentageDriverAssumptionsUpdateInput> | AccPercentageDriverAssumptionsUpdateInput;
}>;


export type UpdateAccPercentageDriverAssumptionsMutation = (
  { __typename?: 'Mutation' }
  & { updateAccPercentageDriverAssumptions: Array<(
    { __typename?: 'AccPercentageDriverAssumptionViewModel' }
    & Pick<AccPercentageDriverAssumptionViewModel, 'propertyId' | 'year' | 'versionType' | 'destinationAccountId' | 'sourceAccountId' | 'delayRecognition' | 'percentMonth0' | 'percentMonth1' | 'percentMonth2' | 'percentMonth3' | 'percentMonth4' | 'percentMonth5' | 'percentMonth6' | 'percentMonth7' | 'percentMonth8' | 'percentMonth9' | 'percentMonth10' | 'percentMonth11'>
  )> }
);

export type BulkUpdateAccPercentageDriverAssumptionsMutationVariables = Exact<{
  item: AccPercentageDriverAssumptionsBulkUpdateInput;
}>;


export type BulkUpdateAccPercentageDriverAssumptionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'bulkUpdateAccPercentageDriverAssumptions'>
);

export type UpdateOperationalDriverAssumptionsMutationVariables = Exact<{
  items: Array<OperationalDriverAssumptionsUpdateInput> | OperationalDriverAssumptionsUpdateInput;
}>;


export type UpdateOperationalDriverAssumptionsMutation = (
  { __typename?: 'Mutation' }
  & { updateOperationalDriverAssumptions: Array<(
    { __typename?: 'OperationalDriverAssumptionViewModel' }
    & Pick<OperationalDriverAssumptionViewModel, 'propertyId' | 'year' | 'versionType' | 'destinationAccountId' | 'sourceMetricId' | 'delayRecognition' | 'percentMonth0' | 'percentMonth1' | 'percentMonth2' | 'percentMonth3' | 'percentMonth4' | 'percentMonth5' | 'percentMonth6' | 'percentMonth7' | 'percentMonth8' | 'percentMonth9' | 'percentMonth10' | 'percentMonth11' | 'amountMonth0' | 'amountMonth1' | 'amountMonth2' | 'amountMonth3' | 'amountMonth4' | 'amountMonth5' | 'amountMonth6' | 'amountMonth7' | 'amountMonth8' | 'amountMonth9' | 'amountMonth10' | 'amountMonth11'>
  )> }
);

export type SetCustomDriverAssumptionsMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  destinationAccountId: Scalars['ID'];
  year: Scalars['Int'];
  versionType: VersionType;
  items: Array<CustomDriverItemUpdateInput> | CustomDriverItemUpdateInput;
}>;


export type SetCustomDriverAssumptionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setCustomDriverAssumptions'>
);

export type CreateEmployeeMutationVariables = Exact<{
  budgetYear: Scalars['Int'];
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  externalSystemEmployeeId?: Maybe<Scalars['String']>;
  type: PayrollModeledEmployeeType;
  classification: PayrollEmployeeClassification;
  positionId: Scalars['ID'];
  properties: Array<PayrollEmployeePropertyAttributionInput> | PayrollEmployeePropertyAttributionInput;
  startDate: Scalars['DateTime'];
  terminationDate?: Maybe<Scalars['DateTime']>;
  baseComp: PayrollCreateEmployeeBaseCompInput;
  overtime: Array<PayrollCreateEmployeeOvertimeInput> | PayrollCreateEmployeeOvertimeInput;
  raises: Array<PayrollCreateEmployeeRaiseInput> | PayrollCreateEmployeeRaiseInput;
  bonuses: Array<PayrollCreateEmployeeBonusInput> | PayrollCreateEmployeeBonusInput;
  items: Array<PayrollCreateEmployeeCompItemInput> | PayrollCreateEmployeeCompItemInput;
}>;


export type CreateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { createPayrollEmployeeForBudgetYear: (
    { __typename?: 'PayrollEmployeeModel' }
    & Pick<PayrollEmployeeModel, 'id'>
  ) }
);

export type DeleteEmployeesMutationVariables = Exact<{
  employeeIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteEmployeesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePayrollEmployees'>
);

export type GetEmployeeQueryVariables = Exact<{
  employeeId: Scalars['ID'];
}>;


export type GetEmployeeQuery = (
  { __typename?: 'Query' }
  & { getEmployee?: Maybe<(
    { __typename?: 'PayrollEmployeeModel' }
    & Pick<PayrollEmployeeModel, 'id' | 'type' | 'firstName' | 'lastName' | 'startDate' | 'terminationDate' | 'classification' | 'externalSystemEmployeeId'>
    & { propertyAttributions: Array<(
      { __typename?: 'PayrollEmployeePropertyAttributionModel' }
      & Pick<PayrollEmployeePropertyAttributionModel, 'attributionPercentage'>
      & { property: (
        { __typename?: 'Property' }
        & Pick<Property, 'id' | 'name'>
      ) }
    )>, position: (
      { __typename?: 'PayrollPositionModel' }
      & Pick<PayrollPositionModel, 'id' | 'name'>
    ), baseComp?: Maybe<(
      { __typename?: 'PayrollEmployeeBaseCompModel' }
      & Pick<PayrollEmployeeBaseCompModel, 'compensationType' | 'annualCompensation' | 'hourlyCompensation' | 'expectedHoursPerWeek'>
    )>, overtime: Array<(
      { __typename?: 'PayrollEmployeeOvertimeModel' }
      & Pick<PayrollEmployeeOvertimeModel, 'hourlyCompensation' | 'expectedHoursPerWeek' | 'effectiveStartDate' | 'effectiveEndDate'>
    )>, bonuses: Array<(
      { __typename?: 'PayrollEmployeeBonusModel' }
      & Pick<PayrollEmployeeBonusModel, 'frequency' | 'bonusType' | 'amount' | 'onetimeBonusDate' | 'bonusDay' | 'bonusMonth' | 'effectiveStartDate' | 'effectiveEndDate'>
    )>, raises: Array<(
      { __typename?: 'PayrollEmployeeRaiseModel' }
      & Pick<PayrollEmployeeRaiseModel, 'frequency' | 'raiseType' | 'amount' | 'onetimeRaiseDate' | 'raiseMonth' | 'raiseDay' | 'effectiveStartDate' | 'effectiveEndDate'>
    )>, compItems: Array<(
      { __typename?: 'PayrollEmployeeCompItemModel' }
      & Pick<PayrollEmployeeCompItemModel, 'id' | 'calculationType' | 'costPerPayPeriod' | 'annualCostCap' | 'effectiveStartDate' | 'effectiveEndDate'>
      & { compensationItem: (
        { __typename?: 'PayrollCompensationItemModel' }
        & Pick<PayrollCompensationItemModel, 'id' | 'type' | 'customName'>
      ) }
    )> }
  )> }
);

export type GetPayrollCompensationItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPayrollCompensationItemsQuery = (
  { __typename?: 'Query' }
  & { listCompensationItems: Array<(
    { __typename?: 'PayrollCompensationItemModel' }
    & Pick<PayrollCompensationItemModel, 'id' | 'type' | 'customName'>
  )> }
);

export type GetPayRolPositionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPayRolPositionsQuery = (
  { __typename?: 'Query' }
  & { listPayrollPositions: Array<(
    { __typename?: 'PayrollPositionModel' }
    & Pick<PayrollPositionModel, 'id' | 'name'>
  )> }
);

export type GetPayrollDefaultsQueryVariables = Exact<{
  propertyId: Scalars['String'];
  positionId: Scalars['ID'];
}>;


export type GetPayrollDefaultsQuery = (
  { __typename?: 'Query' }
  & { payrollDefaults: (
    { __typename?: 'PayrollDefaultsModel' }
    & { position: (
      { __typename?: 'PayrollPositionModel' }
      & Pick<PayrollPositionModel, 'id' | 'name'>
    ), baseComp?: Maybe<(
      { __typename?: 'PayrollDefaultBaseCompModel' }
      & Pick<PayrollDefaultBaseCompModel, 'compensationType' | 'annualCompensation' | 'hourlyCompensation' | 'expectedHoursPerWeek'>
    )>, overtime: Array<(
      { __typename?: 'PayrollDefaultOvertimeModel' }
      & Pick<PayrollDefaultOvertimeModel, 'hourlyCompensation' | 'expectedHoursPerWeek' | 'effectiveStartDate' | 'effectiveEndDate'>
    )>, raises: Array<(
      { __typename?: 'PayrollDefaultRaiseModel' }
      & Pick<PayrollDefaultRaiseModel, 'frequency' | 'raiseType' | 'amount' | 'onetimeRaiseDate' | 'raiseMonth' | 'raiseDay' | 'effectiveStartDate' | 'effectiveEndDate'>
    )>, bonuses: Array<(
      { __typename?: 'PayrollDefaultBonusModel' }
      & Pick<PayrollDefaultBonusModel, 'frequency' | 'bonusType' | 'amount' | 'onetimeBonusDate' | 'bonusMonth' | 'bonusDay' | 'effectiveStartDate' | 'effectiveEndDate'>
    )>, compItems: Array<(
      { __typename?: 'PayrollDefaultCompItemModel' }
      & Pick<PayrollDefaultCompItemModel, 'calculationType' | 'costPerPayPeriod' | 'annualCostCap' | 'effectiveStartDate' | 'effectiveEndDate'>
      & { compensationItem: (
        { __typename?: 'PayrollCompensationItemModel' }
        & Pick<PayrollCompensationItemModel, 'id' | 'clientId' | 'type' | 'customName'>
      ) }
    )> }
  ) }
);

export type UpdatePayrollEmployeeDataMutationVariables = Exact<{
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  externalSystemEmployeeId?: Maybe<Scalars['String']>;
  type: PayrollModeledEmployeeType;
  positionId: Scalars['ID'];
  classification: PayrollEmployeeClassification;
  startDate: Scalars['DateTime'];
  terminationDate?: Maybe<Scalars['DateTime']>;
  properties: Array<PayrollEmployeePropertyAttributionInput> | PayrollEmployeePropertyAttributionInput;
  baseComp: PayrollCreateEmployeeBaseCompInput;
  overtime: Array<PayrollDifferentialEmployeeOvertimeInput> | PayrollDifferentialEmployeeOvertimeInput;
  bonuses: Array<PayrollDifferentialEmployeeBonusInput> | PayrollDifferentialEmployeeBonusInput;
  raises: Array<PayrollDifferentialEmployeeRaiseInput> | PayrollDifferentialEmployeeRaiseInput;
  items: Array<PayrollDifferentialEmployeeCompItemInput> | PayrollDifferentialEmployeeCompItemInput;
}>;


export type UpdatePayrollEmployeeDataMutation = (
  { __typename?: 'Mutation' }
  & { setPayrollEmployeeData: (
    { __typename?: 'PayrollEmployeeModel' }
    & Pick<PayrollEmployeeModel, 'id'>
  ) }
);

export type CreateRenovationPackageMutationVariables = Exact<{
  propertyId: Scalars['String'];
  unitTypeId: Scalars['String'];
  name: Scalars['String'];
  costCategoryMappings: Array<CostCategoryInput> | CostCategoryInput;
  premium?: Maybe<Scalars['Decimal']>;
  premiumDelayRecognition?: Maybe<Scalars['Int']>;
}>;


export type CreateRenovationPackageMutation = (
  { __typename?: 'Mutation' }
  & { createRenovationPackage: (
    { __typename?: 'RenovationPackageModel' }
    & Pick<RenovationPackageModel, 'id' | 'name' | 'premium' | 'premiumDelayRecognition'>
    & { costCategoryMappings: Array<(
      { __typename?: 'RenovationCostCategoryMappingModel' }
      & Pick<RenovationCostCategoryMappingModel, 'renovationCostCategoryId' | 'amount' | 'delayRecognition'>
    )> }
  ) }
);

export type DeleteRenovationPackageMutationVariables = Exact<{
  id: Scalars['String'];
  force?: Maybe<Scalars['Boolean']>;
}>;


export type DeleteRenovationPackageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeRenovationPackage'>
);

export type GetAnalystRenovationDrawerRenovationPackagesQueryVariables = Exact<{
  propertyIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetAnalystRenovationDrawerRenovationPackagesQuery = (
  { __typename?: 'Query' }
  & { queryRenovationPackages: Array<(
    { __typename?: 'RenovationPackageModel' }
    & Pick<RenovationPackageModel, 'id' | 'propertyId' | 'unitTypeId' | 'name' | 'premium' | 'premiumDelayRecognition'>
    & { costCategoryMappings: Array<(
      { __typename?: 'RenovationCostCategoryMappingModel' }
      & Pick<RenovationCostCategoryMappingModel, 'renovationCostCategoryId' | 'amount' | 'delayRecognition'>
    )> }
  )> }
);

export type GetRenovationCostCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRenovationCostCategoriesQuery = (
  { __typename?: 'Query' }
  & { queryRenovationCostCategories: Array<(
    { __typename?: 'RenovationCostCategoryModel' }
    & Pick<RenovationCostCategoryModel, 'id' | 'name'>
    & { account: (
      { __typename?: 'AccountV2' }
      & Pick<AccountV2, 'id' | 'glName' | 'level' | 'type'>
    ) }
  )> }
);

export type GetPropertiesAndUnitTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPropertiesAndUnitTypesQuery = (
  { __typename?: 'Query' }
  & { myProperties: Array<(
    { __typename?: 'Property' }
    & Pick<Property, 'id' | 'name' | 'displayName' | 'reforecastYear'>
    & { unitTypesCustom: Array<(
      { __typename?: 'UnitType' }
      & Pick<UnitType, 'id' | 'name'>
    )> }
  )> }
);

export type UpdateRenovationPackageMutationVariables = Exact<{
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  costCategoryMappings: Array<CostCategoryInput> | CostCategoryInput;
  premium?: Maybe<Scalars['Decimal']>;
  premiumDelayRecognition?: Maybe<Scalars['Int']>;
}>;


export type UpdateRenovationPackageMutation = (
  { __typename?: 'Mutation' }
  & { updateRenovationPackage: (
    { __typename?: 'RenovationPackageModel' }
    & Pick<RenovationPackageModel, 'id' | 'name' | 'premium' | 'premiumDelayRecognition'>
    & { costCategoryMappings: Array<(
      { __typename?: 'RenovationCostCategoryMappingModel' }
      & Pick<RenovationCostCategoryMappingModel, 'renovationCostCategoryId' | 'amount' | 'delayRecognition'>
    )> }
  ) }
);

export type UpsertOperationalMetricAssumptionsMutationVariables = Exact<{
  args: Array<UpsertOperationMetricAssumptions> | UpsertOperationMetricAssumptions;
}>;


export type UpsertOperationalMetricAssumptionsMutation = (
  { __typename?: 'Mutation' }
  & { upsertOperationalMetricAssumptions: Array<(
    { __typename?: 'OperationalMetricAssumption' }
    & Pick<OperationalMetricAssumption, 'id' | 'amount' | 'percentOf' | 'delayRecognition' | 'effectiveStartYear' | 'effectiveStartMonthIndex' | 'effectiveEndYear' | 'effectiveEndMonthIndex' | 'propertyId' | 'locked'>
  )> }
);

export type DeleteOperationalMetricAssumptionsMutationVariables = Exact<{
  args: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteOperationalMetricAssumptionsMutation = (
  { __typename?: 'Mutation' }
  & { deleteOperationalMetricAssumptions: (
    { __typename?: 'StringIdListResult' }
    & Pick<StringIdListResult, 'ids'>
  ) }
);

export type UpsertAccPercentageAssumptionsMutationVariables = Exact<{
  args: Array<UpsertAccPercentageAssumptions> | UpsertAccPercentageAssumptions;
}>;


export type UpsertAccPercentageAssumptionsMutation = (
  { __typename?: 'Mutation' }
  & { upsertAccPercentageAssumptions: Array<(
    { __typename?: 'AccPercentageAssumption' }
    & Pick<AccPercentageAssumption, 'id' | 'percentOf' | 'delayRecognition' | 'effectiveStartYear' | 'effectiveStartMonthIndex' | 'effectiveEndYear' | 'effectiveEndMonthIndex' | 'propertyId' | 'locked'>
  )> }
);

export type DeleteAccPercentageAssumptionsMutationVariables = Exact<{
  args: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteAccPercentageAssumptionsMutation = (
  { __typename?: 'Mutation' }
  & { deleteAccPercentageAssumptions: (
    { __typename?: 'StringIdListResult' }
    & Pick<StringIdListResult, 'ids'>
  ) }
);

export type AnalystDrawerOperationalDriverTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type AnalystDrawerOperationalDriverTypesQuery = (
  { __typename?: 'Query' }
  & { operationalMetricTypes: Array<(
    { __typename?: 'OperationalMetricType' }
    & Pick<OperationalMetricType, 'id' | 'name'>
  )> }
);

export type RemoveWorksheetDriverDataMutationVariables = Exact<{
  lineIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type RemoveWorksheetDriverDataMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeWorksheetDriverData'>
);

export type AddWorksheetDriverDataMutationVariables = Exact<{
  creates: Array<WorksheetDriverLineCreateInput> | WorksheetDriverLineCreateInput;
}>;


export type AddWorksheetDriverDataMutation = (
  { __typename?: 'Mutation' }
  & { createWorksheetDriverData: Array<(
    { __typename?: 'WorksheetLine' }
    & Pick<WorksheetLine, 'id'>
  )> }
);

export type UpdateWorksheetDriverDataMutationVariables = Exact<{
  creates: Array<WorksheetDriverLineCreateInput> | WorksheetDriverLineCreateInput;
  updates: Array<WorksheetDriverLineUpdateInput> | WorksheetDriverLineUpdateInput;
}>;


export type UpdateWorksheetDriverDataMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateWorksheetDriverData'>
);

export type ExportPropertyBudgetQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  requestValueType: VersionType;
  referenceYear: Scalars['Int'];
  referenceValueType: VersionType;
  extraReferenceYear: Scalars['Int'];
  extraReferenceValueType: VersionType;
  includeExtraReferenceYear: Scalars['Boolean'];
}>;


export type ExportPropertyBudgetQuery = (
  { __typename?: 'Query' }
  & { getPropertyBudgetExportSingleYear?: Maybe<Array<(
    { __typename?: 'PropertyBudgetExportSingleYearModel' }
    & MakeOptional<Pick<PropertyBudgetExportSingleYearModel, 'id' | 'glNumber' | 'glName' | 'type' | 'order' | 'yearValues' | 'yearTotal' | 'referenceYearTotal' | 'extraReferenceYearTotal' | 'note' | 'accountClassification' | 'yardiExternalAccountId'>, 'extraReferenceYearTotal'>
  )>> }
);

export type ExportPropertyBudgetT12ReportQueryVariables = Exact<{
  propertyId: Scalars['ID'];
}>;


export type ExportPropertyBudgetT12ReportQuery = (
  { __typename?: 'Query' }
  & { getPropertyBudgetT12Report: (
    { __typename?: 'PropertyBudgetT12ReportModel' }
    & Pick<PropertyBudgetT12ReportModel, 'propertyId' | 'unitCount' | 'totalSqFoot' | 'totalRevenueBudget' | 'totalRevenueActualT12' | 'totalRevenueActualAnnualized' | 'totalExpensesBudget' | 'totalExpensesActualT12' | 'totalExpensesActualAnnualized'>
    & { records: Array<(
      { __typename?: 'T12ReportRecordModel' }
      & Pick<T12ReportRecordModel, 'accountId' | 'glName' | 'glNumber' | 'type' | 'order' | 'annualizedNMonthsActualsTotal' | 'twelveMonthsActualsTotal' | 'budgetValues' | 'note'>
    )> }
  ) }
);

export type GetWorksheetTypeaheadQueryVariables = Exact<{
  accountId: Scalars['String'];
  budgetYear: Scalars['Int'];
}>;


export type GetWorksheetTypeaheadQuery = (
  { __typename?: 'Query' }
  & { worksheetTypeahead: Query['worksheetDescriptionsForBudgetYear'] }
);

export type GetInstructionQueryVariables = Exact<{
  accountId: Scalars['ID'];
  versionId: Scalars['ID'];
}>;


export type GetInstructionQuery = (
  { __typename?: 'Query' }
  & { getInstructionByAccountIdAndValueVersionId?: Maybe<(
    { __typename?: 'InstructionModel' }
    & Pick<InstructionModel, 'id' | 'accountId' | 'text'>
    & { version: (
      { __typename?: 'InstructionValueVersionModel' }
      & Pick<InstructionValueVersionModel, 'type' | 'year'>
    ) }
  )> }
);

export type GetNoteQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  year: Scalars['Int'];
  versionType: VersionType;
  entityId: Scalars['ID'];
  entityType: EntityType;
}>;


export type GetNoteQuery = (
  { __typename?: 'Query' }
  & { queryNote?: Maybe<(
    { __typename?: 'NoteModel' }
    & Pick<NoteModel, 'propertyId' | 'versionType' | 'year' | 'entityId' | 'entityType' | 'text'>
  )> }
);

export type UpsertNoteMutationVariables = Exact<{
  text: Scalars['String'];
  entityId: Scalars['ID'];
  entityType: EntityType;
  versionType: VersionType;
  propertyId: Scalars['ID'];
  year: Scalars['Int'];
}>;


export type UpsertNoteMutation = (
  { __typename?: 'Mutation' }
  & { upsertNoteText: (
    { __typename?: 'NoteModel' }
    & Pick<NoteModel, 'propertyId' | 'versionType' | 'year' | 'entityId' | 'entityType' | 'text'>
  ) }
);

export type GetPortfolioSnapshotAccountPerPropertyDataQueryVariables = Exact<{
  snapshotId: Scalars['ID'];
  propertyIds: Array<Scalars['ID']> | Scalars['ID'];
  accountId: Scalars['ID'];
}>;


export type GetPortfolioSnapshotAccountPerPropertyDataQuery = (
  { __typename?: 'Query' }
  & { getPortfolioSnapshotAccountPerPropertyData: (
    { __typename?: 'PortfolioCalcSnapshotAccountPropertiesDataModel' }
    & Pick<PortfolioCalcSnapshotAccountPropertiesDataModel, 'accountId'>
    & { propertiesValues: Array<(
      { __typename?: 'PortfolioCalcSnapshotValuesRow' }
      & Pick<PortfolioCalcSnapshotValuesRow, 'id' | 'values'>
    )> }
  ) }
);

export type GetPortfolioSnapshotDataQueryVariables = Exact<{
  snapshotId: Scalars['ID'];
  propertyIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type GetPortfolioSnapshotDataQuery = (
  { __typename?: 'Query' }
  & { getPortfolioSnapshotData: (
    { __typename?: 'PortfolioCalcSnapshotDataModel' }
    & { accountValues: Array<(
      { __typename?: 'PortfolioCalcSnapshotValuesRow' }
      & Pick<PortfolioCalcSnapshotValuesRow, 'id' | 'values'>
    )>, propertiesData: Array<(
      { __typename?: 'PortfolioCalcSnapshotPropertyData' }
      & Pick<PortfolioCalcSnapshotPropertyData, 'id' | 'reforecastStartMonthIndex'>
    )> }
  ) }
);

export type FinancialValuesSubscriptionVariables = Exact<{
  propertyIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  versionIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type FinancialValuesSubscription = (
  { __typename?: 'Subscription' }
  & { newFinancialValue: (
    { __typename?: 'FinancialValue' }
    & Pick<FinancialValue, 'propertyId' | 'versionId' | 'monthIndex' | 'entityType' | 'entityId' | 'value'>
  ) }
);

export type GetFinancialValuesQueryVariables = Exact<{
  financialValueSelectors: Array<FinancialValueQueryInput> | FinancialValueQueryInput;
  financialTotalValueSelectorsProjections: Array<FinancialTotalValueQueryInput> | FinancialTotalValueQueryInput;
  financialTotalValueSelectorsHistorical: Array<FinancialTotalValueQueryInput> | FinancialTotalValueQueryInput;
  useCache?: Maybe<Scalars['Boolean']>;
}>;


export type GetFinancialValuesQuery = (
  { __typename?: 'Query' }
  & { financialValues: (
    { __typename?: 'FinancialValuesQueryResult' }
    & { financialValues: Array<(
      { __typename?: 'FinancialValueO' }
      & Pick<FinancialValueO, 'entityId' | 'entityType' | 'versionId' | 'propertyId' | 'monthIndex' | 'value' | 'originalValue'>
    )>, financialTotalValues: Array<(
      { __typename?: 'FinancialTotalValueO' }
      & Pick<FinancialTotalValueO, 'entityId' | 'entityType' | 'versionId' | 'propertyId' | 'value'>
    )> }
  ) }
);

export type UpsertAccountValuesMutationVariables = Exact<{
  accountId: Scalars['String'];
  versionId: Scalars['String'];
  monthIndexes: Array<Scalars['Int']> | Scalars['Int'];
  propertyId: Scalars['String'];
  values: Array<Scalars['Decimal']> | Scalars['Decimal'];
}>;


export type UpsertAccountValuesMutation = (
  { __typename?: 'Mutation' }
  & { upsertAccountValues: Array<(
    { __typename?: 'AccountValue' }
    & Pick<AccountValue, 'id' | 'accountId' | 'versionId' | 'monthIndex' | 'propertyId' | 'value'>
  )> }
);

export type AccountValueOverrideMutationVariables = Exact<{
  accountId: Scalars['String'];
  propertyId: Scalars['String'];
  versionId: Scalars['String'];
  values: Array<Scalars['Decimal']> | Scalars['Decimal'];
  monthIndexes: Array<Scalars['Int']> | Scalars['Int'];
  shouldRevert: Scalars['Boolean'];
}>;


export type AccountValueOverrideMutation = (
  { __typename?: 'Mutation' }
  & { accountValueOverride: Array<(
    { __typename?: 'AccountValueOverride' }
    & Pick<AccountValueOverride, 'id' | 'value' | 'versionId' | 'monthIndex' | 'updatedAt' | 'accountId'>
  )> }
);

export type DeleteAccountOverrideValueMutationVariables = Exact<{
  accountId: Scalars['String'];
  propertyId: Scalars['String'];
  versionId: Scalars['String'];
  monthIndexes: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeleteAccountOverrideValueMutation = (
  { __typename?: 'Mutation' }
  & { deleteAccountValueOverrides?: Maybe<Array<(
    { __typename?: 'AccountValueOverride' }
    & Pick<AccountValueOverride, 'monthIndex' | 'value'>
  )>> }
);

export type GetStarredAccountsQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  accountId: Scalars['ID'];
  versionIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type GetStarredAccountsQuery = (
  { __typename?: 'Query' }
  & { getStarredAccountByPropertyIdAndAccountIdAndVersionIds?: Maybe<Array<(
    { __typename?: 'StarredAccountModel' }
    & Pick<StarredAccountModel, 'id' | 'propertyId' | 'accountId' | 'isStarred'>
    & { version: (
      { __typename?: 'StarredAccountVersionModel' }
      & Pick<StarredAccountVersionModel, 'id'>
    ) }
  )>> }
);

export type UpsertStarredAccountsMutationVariables = Exact<{
  data: Array<StarredAccountMutationArgs> | StarredAccountMutationArgs;
}>;


export type UpsertStarredAccountsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertStarredAccounts'>
);

export type MyUserQueryVariables = Exact<{ [key: string]: never; }>;


export type MyUserQuery = (
  { __typename?: 'Query' }
  & { myUser?: Maybe<(
    { __typename?: 'MyUser' }
    & Pick<MyUser, 'id' | 'firstName' | 'lastName' | 'email' | 'role' | 'isReadOnly' | 'hasPowerBi' | 'authorizations' | 'clientId' | 'clientName' | 'clientDisplayName'>
    & { properties: Array<(
      { __typename?: 'Property' }
      & Pick<Property, 'id' | 'name' | 'displayName' | 'unitLevelModelingEnabled' | 'address' | 'cappedRenewalIncreasePercent' | 'externalId' | 'code' | 'fiscalYearStartMonthIndex' | 'reforecastStartMonthIndex' | 'reforecastYear' | 'revenueModel' | 'originalRevenueMarketRentModeType' | 'propertyManagementSystem'>
    )> }
  )> }
);

export type GetChartOfAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetChartOfAccountsQuery = (
  { __typename?: 'Query' }
  & { findFirstChartOfAccounts?: Maybe<(
    { __typename?: 'ChartOfAccounts' }
    & Pick<ChartOfAccounts, 'id' | 'operationalFinancialMetricsAggregateId' | 'operationalFinancialMetricsAggregateType'>
    & { yardiChartOfAccountsMetadata: Array<(
      { __typename?: 'YardiChartOfAccountsMetadata' }
      & Pick<YardiChartOfAccountsMetadata, 'bookNum'>
    )> }
  )>, budgetComponentMappings: Array<(
    { __typename?: 'BudgetComponentMapping' }
    & Pick<BudgetComponentMapping, 'id' | 'negate'>
    & { component: (
      { __typename?: 'BudgetComponentV2' }
      & Pick<BudgetComponentV2, 'id' | 'name' | 'budgetComponentType' | 'order'>
    ), sourceAccount?: Maybe<(
      { __typename?: 'AccountV2' }
      & Pick<AccountV2, 'id' | 'glName' | 'glNumber' | 'order' | 'type' | 'parentId'>
      & { children: Array<(
        { __typename?: 'AccountV2' }
        & Pick<AccountV2, 'id' | 'glName' | 'glNumber' | 'order' | 'type' | 'parentId'>
        & { children: Array<(
          { __typename?: 'AccountV2' }
          & Pick<AccountV2, 'id' | 'glName' | 'glNumber' | 'order' | 'type' | 'parentId'>
          & { children: Array<(
            { __typename?: 'AccountV2' }
            & Pick<AccountV2, 'id' | 'glName' | 'glNumber' | 'order' | 'type' | 'parentId'>
            & { children: Array<(
              { __typename?: 'AccountV2' }
              & Pick<AccountV2, 'id' | 'glName' | 'glNumber' | 'order' | 'type' | 'parentId'>
              & { children: Array<(
                { __typename?: 'AccountV2' }
                & Pick<AccountV2, 'id' | 'glName' | 'glNumber' | 'order' | 'type' | 'parentId'>
                & { children: Array<(
                  { __typename?: 'AccountV2' }
                  & Pick<AccountV2, 'id' | 'glName' | 'glNumber' | 'order' | 'type' | 'parentId'>
                  & { children: Array<(
                    { __typename?: 'AccountV2' }
                    & Pick<AccountV2, 'id' | 'glName' | 'glNumber' | 'order' | 'type' | 'parentId'>
                    & { children: Array<(
                      { __typename?: 'AccountV2' }
                      & Pick<AccountV2, 'id' | 'glName' | 'glNumber' | 'order' | 'type' | 'parentId'>
                      & { children: Array<(
                        { __typename?: 'AccountV2' }
                        & Pick<AccountV2, 'id' | 'glName' | 'glNumber' | 'order' | 'type' | 'parentId'>
                        & { children: Array<(
                          { __typename?: 'AccountV2' }
                          & Pick<AccountV2, 'id' | 'glName' | 'glNumber' | 'order' | 'type' | 'parentId'>
                          & { children: Array<(
                            { __typename?: 'AccountV2' }
                            & Pick<AccountV2, 'id' | 'glName' | 'glNumber' | 'order' | 'type' | 'parentId'>
                            & { children: Array<(
                              { __typename?: 'AccountV2' }
                              & Pick<AccountV2, 'id' | 'glName' | 'glNumber' | 'order' | 'type' | 'parentId'>
                              & { children: Array<(
                                { __typename?: 'AccountV2' }
                                & Pick<AccountV2, 'id' | 'glName' | 'glNumber' | 'order' | 'type' | 'parentId'>
                                & { children: Array<(
                                  { __typename?: 'AccountV2' }
                                  & Pick<AccountV2, 'id' | 'glName' | 'glNumber' | 'order' | 'type' | 'parentId'>
                                  & { children: Array<(
                                    { __typename?: 'AccountV2' }
                                    & Pick<AccountV2, 'id' | 'glName' | 'glNumber' | 'order' | 'type' | 'parentId'>
                                  )> }
                                )> }
                              )> }
                            )> }
                          )> }
                        )> }
                      )> }
                    )> }
                  )> }
                )> }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, sourceComponent?: Maybe<(
      { __typename?: 'BudgetComponentV2' }
      & Pick<BudgetComponentV2, 'id' | 'name' | 'budgetComponentType'>
    )> }
  )> }
);

export type AuthenticateGuestTokenQueryVariables = Exact<{
  accessToken: Scalars['String'];
  otp: Scalars['String'];
}>;


export type AuthenticateGuestTokenQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'authenticateToken'>
);

export type RequestGuestOtpQueryVariables = Exact<{
  accessToken: Scalars['String'];
}>;


export type RequestGuestOtpQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'requestTokenOTP'>
);

export type GetUnitsCountQueryVariables = Exact<{
  propertyId: Scalars['String'];
}>;


export type GetUnitsCountQuery = (
  { __typename?: 'Query' }
  & { propertyUnitCounts: Array<(
    { __typename?: 'PropertyUnitCountResult' }
    & Pick<PropertyUnitCountResult, 'unitTypeId' | 'unitTypeName' | 'count' | 'sqft'>
  )> }
);

export type GetSettingsFromPropertyQueryVariables = Exact<{
  propertyId: Scalars['String'];
}>;


export type GetSettingsFromPropertyQuery = (
  { __typename?: 'Query' }
  & { findFirstProperty?: Maybe<(
    { __typename?: 'Property' }
    & Pick<Property, 'fiscalYearStartMonthIndex' | 'reforecastStartMonthIndex' | 'reforecastYear'>
  )> }
);

export type GetVersionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVersionsQuery = (
  { __typename?: 'Query' }
  & { valueVersions: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'type' | 'year'>
  )> }
);

export type GetAccountNoteQueryVariables = Exact<{
  propertyId: Scalars['String'];
  accountId: Scalars['String'];
  year: Scalars['Int'];
  versionType: VersionType;
}>;


export type GetAccountNoteQuery = (
  { __typename?: 'Query' }
  & { queryAccountNote?: Maybe<(
    { __typename?: 'AccountNoteModel' }
    & Pick<AccountNoteModel, 'propertyId' | 'accountId' | 'year' | 'versionType' | 'text'>
  )> }
);

export type GetAccountVarianceNoteQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  accountId: Scalars['ID'];
  year: Scalars['Int'];
  monthIndex: Scalars['Int'];
  versionType: VersionType;
}>;


export type GetAccountVarianceNoteQuery = (
  { __typename?: 'Query' }
  & { queryAccountVarianceNotes: Array<(
    { __typename?: 'AccountVarianceNoteModel' }
    & Pick<AccountVarianceNoteModel, 'year' | 'versionType' | 'monthIndex' | 'text'>
  )> }
);

export type SetAccountVarianceNoteMutationVariables = Exact<{
  propertyId: Scalars['String'];
  accountId: Scalars['String'];
  year: Scalars['Int'];
  monthIndex: Scalars['Int'];
  versionType: VersionType;
  text: Scalars['String'];
}>;


export type SetAccountVarianceNoteMutation = (
  { __typename?: 'Mutation' }
  & { upsertAccountVarianceNote: (
    { __typename?: 'AccountVarianceNoteModel' }
    & Pick<AccountVarianceNoteModel, 'year' | 'versionType' | 'monthIndex' | 'text'>
  ) }
);

export type GetIsAccountLockedQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  accountId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  versionType: VersionType;
}>;


export type GetIsAccountLockedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getIsAccountLocked'>
);

export type GetMultiPropertyAccountDriversQueryVariables = Exact<{
  accountId: Scalars['ID'];
  yearsVersionTypes: Array<YearAndType> | YearAndType;
  propertyIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  driverTypes?: Maybe<Array<DriverType> | DriverType>;
}>;


export type GetMultiPropertyAccountDriversQuery = (
  { __typename?: 'Query' }
  & { multiPropertyAccountDrivers: (
    { __typename?: 'MultiPropertyDriverModel' }
    & { operational: Array<(
      { __typename?: 'MultiPropertyOperationalDriverModel' }
      & Pick<MultiPropertyOperationalDriverModel, 'sourceMetricId' | 'assumptionId' | 'sourceMetricName' | 'amount' | 'driverType' | 'percentageOf' | 'delayRecognition' | 'driverMetricTypeId' | 'locked' | 'propertyName' | 'propertyId' | 'accountId' | 'versionType' | 'effectiveEndYear' | 'effectiveEndMonthIndex' | 'effectiveStartYear' | 'effectiveStartMonthIndex'>
    )>, acctPercentage: Array<(
      { __typename?: 'MultiPropertySourceAccountModel' }
      & Pick<MultiPropertySourceAccountModel, 'sourceAccountId' | 'glNumber' | 'glName' | 'acctPercentageDriverAccountId' | 'delayRecognition' | 'locked' | 'propertyName' | 'propertyId' | 'assumptionId' | 'percentageOf' | 'accountId' | 'effectiveEndYear' | 'effectiveEndMonthIndex' | 'effectiveStartYear' | 'effectiveStartMonthIndex' | 'versionType' | 'driverType'>
    )>, worksheet: Array<(
      { __typename?: 'WorksheetLineWithPropertyAccountVersionTypeModel' }
      & Pick<WorksheetLineWithPropertyAccountVersionTypeModel, 'propertyId' | 'propertyName' | 'assumptionId' | 'lineId' | 'locked' | 'accountId' | 'versionType' | 'description' | 'values'>
    )> }
  ) }
);

export type GetSinglePropertyDriversAndWorksheetItemsQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  accountId: Scalars['ID'];
  year: Scalars['Int'];
  versionType: VersionType;
}>;


export type GetSinglePropertyDriversAndWorksheetItemsQuery = (
  { __typename?: 'Query' }
  & { singlePropertyAccount: (
    { __typename?: 'PropertyAccountVersionTypeModel' }
    & Pick<PropertyAccountVersionTypeModel, 'year' | 'propertyId' | 'accountId' | 'versionType' | 'originalValues' | 'originalValuesRaw' | 'accountValues' | 'overrideValues' | 'payrollBreakdown'>
    & { accountPercentageDriver?: Maybe<(
      { __typename?: 'AccountPercentageDriverModel' }
      & Pick<AccountPercentageDriverModel, 'isDriven'>
      & { augments: (
        { __typename?: 'AccountPercentageAugmentsModel' }
        & Pick<AccountPercentageAugmentsModel, 'minValue' | 'maxValue'>
      ), sourceAccounts: Array<(
        { __typename?: 'SourceAccountModel' }
        & Pick<SourceAccountModel, 'accountId' | 'glNumber' | 'glName' | 'assumptionId' | 'acctPercentageDriverAccountId' | 'locked'>
        & { assumptionsAndValues: Array<(
          { __typename?: 'AccPercentageDriverAssumptionAndValueModel' }
          & Pick<AccPercentageDriverAssumptionAndValueModel, 'percentage' | 'lookbackPeriod' | 'accountValue'>
        )> }
      )> }
    )>, operationalMetricDriver?: Maybe<(
      { __typename?: 'OperationalMetricDriverModel' }
      & Pick<OperationalMetricDriverModel, 'isDriven'>
      & { sourceMetrics: Array<(
        { __typename?: 'SourceMetricModel' }
        & Pick<SourceMetricModel, 'sourceMetricId' | 'metricName' | 'locked' | 'driverMetricTypeId'>
        & { assumptionsAndValues: Array<(
          { __typename?: 'OperationalMetricDriverAssumptionAndValueModel' }
          & Pick<OperationalMetricDriverAssumptionAndValueModel, 'amount' | 'lookbackPeriod' | 'percentage' | 'metricValue'>
        )> }
      )> }
    )>, worksheetDriver?: Maybe<(
      { __typename?: 'WorksheetDriverModel' }
      & Pick<WorksheetDriverModel, 'isDriven'>
      & { worksheetLines: Array<(
        { __typename?: 'WorksheetLineModel' }
        & Pick<WorksheetLineModel, 'lineId' | 'description' | 'values'>
      )> }
    )>, payrollDriver?: Maybe<(
      { __typename?: 'PayrollDriverModel' }
      & Pick<PayrollDriverModel, 'isDriven'>
      & { compensationItems: Array<(
        { __typename?: 'PayrollDriverCompensationItemModel' }
        & Pick<PayrollDriverCompensationItemModel, 'itemType'>
        & { positions: Array<(
          { __typename?: 'PayrollDriverCompensationItemPositionsModel' }
          & Pick<PayrollDriverCompensationItemPositionsModel, 'id' | 'name'>
        )> }
      )> }
    )>, renovationsDriver?: Maybe<(
      { __typename?: 'RenovationsDriverModel' }
      & Pick<RenovationsDriverModel, 'isDriven'>
      & { costCategories: Array<(
        { __typename?: 'RenovationCostCategoryModel' }
        & Pick<RenovationCostCategoryModel, 'id' | 'name'>
      )> }
    )>, growthDriver: Array<(
      { __typename?: 'GrowthDriverModel' }
      & Pick<GrowthDriverModel, 'id' | 'year' | 'versionType' | 'propertyId' | 'accountId' | 'growthCalcMethod' | 'annualTargetValueManualEntry' | 'distributionMethod' | 'monthlyAdjustmentType' | 'monthlyAdjustmentValue' | 'monthlyAverageBasePeriod' | 'lookbackPeriodStart' | 'lookbackPeriodEnd' | 'percentGrowthBasePeriod' | 'percentGrowthAdjustmentValue' | 'annualValues'>
    )>, revenueDriver?: Maybe<(
      { __typename?: 'RevenueDriverModel' }
      & Pick<RevenueDriverModel, 'isDriven'>
      & { sourceRevenueMetrics: Array<(
        { __typename?: 'SourceRevenueMetricModel' }
        & Pick<SourceRevenueMetricModel, 'sourceId' | 'revenueType'>
      )> }
    )>, customDriver?: Maybe<(
      { __typename?: 'CustomDriverModel' }
      & Pick<CustomDriverModel, 'isDriven'>
      & { items: Array<(
        { __typename?: 'CustomDriverItemModel' }
        & Pick<CustomDriverItemModel, 'id' | 'itemName'>
        & { assumptions: Array<(
          { __typename?: 'CustomDriverItemAssumptionModel' }
          & Pick<CustomDriverItemAssumptionModel, 'amount' | 'count' | 'percentOf'>
        )> }
      )> }
    )> }
  ) }
);

export type GetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  accountId: Scalars['ID'];
  year: Scalars['Int'];
  versionType: VersionType;
}>;


export type GetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQuery = (
  { __typename?: 'Query' }
  & { singlePropertyAccount: (
    { __typename?: 'PropertyAccountVersionTypeModel' }
    & Pick<PropertyAccountVersionTypeModel, 'year' | 'propertyId' | 'accountId' | 'versionType'>
    & { accountPercentageDriver?: Maybe<(
      { __typename?: 'AccountPercentageDriverModel' }
      & Pick<AccountPercentageDriverModel, 'isDriven'>
      & { augments: (
        { __typename?: 'AccountPercentageAugmentsModel' }
        & Pick<AccountPercentageAugmentsModel, 'minValue' | 'maxValue'>
      ), sourceAccountAssumptions: Array<(
        { __typename?: 'SourceAccountAssumptionsModel' }
        & Pick<SourceAccountAssumptionsModel, 'accountId' | 'glNumber' | 'glName' | 'assumptionId' | 'acctPercentageDriverAccountId' | 'locked'>
        & { assumptions: Array<(
          { __typename?: 'AccPercentageDriverAssumptionModel' }
          & Pick<AccPercentageDriverAssumptionModel, 'percentage' | 'lookbackPeriod'>
        )> }
      )> }
    )>, operationalMetricDriver?: Maybe<(
      { __typename?: 'OperationalMetricDriverModel' }
      & Pick<OperationalMetricDriverModel, 'isDriven'>
      & { sourceMetricAssumptions: Array<(
        { __typename?: 'SourceMetricAssumptionsModel' }
        & Pick<SourceMetricAssumptionsModel, 'sourceMetricId' | 'metricName' | 'locked' | 'driverMetricTypeId'>
        & { assumptions: Array<(
          { __typename?: 'OperationalMetricDriverAssumptionModel' }
          & Pick<OperationalMetricDriverAssumptionModel, 'amount' | 'lookbackPeriod' | 'percentage'>
        )> }
      )> }
    )>, worksheetDriver?: Maybe<(
      { __typename?: 'WorksheetDriverModel' }
      & Pick<WorksheetDriverModel, 'isDriven'>
      & { worksheetLines: Array<(
        { __typename?: 'WorksheetLineModel' }
        & Pick<WorksheetLineModel, 'lineId' | 'description' | 'values'>
      )> }
    )>, payrollDriver?: Maybe<(
      { __typename?: 'PayrollDriverModel' }
      & Pick<PayrollDriverModel, 'isDriven'>
      & { compensationItems: Array<(
        { __typename?: 'PayrollDriverCompensationItemModel' }
        & Pick<PayrollDriverCompensationItemModel, 'itemType'>
        & { positions: Array<(
          { __typename?: 'PayrollDriverCompensationItemPositionsModel' }
          & Pick<PayrollDriverCompensationItemPositionsModel, 'id' | 'name'>
        )> }
      )> }
    )>, renovationsDriver?: Maybe<(
      { __typename?: 'RenovationsDriverModel' }
      & Pick<RenovationsDriverModel, 'isDriven'>
      & { costCategories: Array<(
        { __typename?: 'RenovationCostCategoryModel' }
        & Pick<RenovationCostCategoryModel, 'id' | 'name'>
      )> }
    )>, growthDriver: Array<(
      { __typename?: 'GrowthDriverModel' }
      & Pick<GrowthDriverModel, 'id' | 'year' | 'versionType' | 'propertyId' | 'accountId' | 'growthCalcMethod' | 'annualTargetValueManualEntry' | 'distributionMethod' | 'monthlyAdjustmentType' | 'monthlyAdjustmentValue' | 'monthlyAverageBasePeriod' | 'lookbackPeriodStart' | 'lookbackPeriodEnd' | 'percentGrowthBasePeriod' | 'percentGrowthAdjustmentValue'>
    )>, revenueDriver?: Maybe<(
      { __typename?: 'RevenueDriverModel' }
      & Pick<RevenueDriverModel, 'isDriven'>
      & { sourceRevenueMetrics: Array<(
        { __typename?: 'SourceRevenueMetricModel' }
        & Pick<SourceRevenueMetricModel, 'sourceId' | 'revenueType'>
      )> }
    )>, customDriver?: Maybe<(
      { __typename?: 'CustomDriverModel' }
      & Pick<CustomDriverModel, 'isDriven'>
      & { items: Array<(
        { __typename?: 'CustomDriverItemModel' }
        & Pick<CustomDriverItemModel, 'id' | 'itemName'>
        & { assumptions: Array<(
          { __typename?: 'CustomDriverItemAssumptionModel' }
          & Pick<CustomDriverItemAssumptionModel, 'amount' | 'count' | 'percentOf'>
        )> }
      )> }
    )> }
  ) }
);

export type SetAccountLockMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  accountId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  versionType: VersionType;
  locked: Scalars['Boolean'];
}>;


export type SetAccountLockMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setAccountLock'>
);

export type OperationalMetricTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type OperationalMetricTypeQuery = (
  { __typename?: 'Query' }
  & { operationalMetricTypes: Array<(
    { __typename?: 'OperationalMetricType' }
    & Pick<OperationalMetricType, 'id' | 'name'>
  )> }
);

export type RevenueTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type RevenueTypesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'revenueTypes'>
);

export type SetDriversMutationVariables = Exact<{
  accountId: Scalars['String'];
  propertyId: Scalars['String'];
  year: Scalars['Int'];
  versionType: VersionType;
  isUserRequestingWorksheetDeletion: Scalars['Boolean'];
  pendingUpdates: PendingUpdates;
}>;


export type SetDriversMutation = (
  { __typename?: 'Mutation' }
  & { setDrivers: (
    { __typename?: 'TSetDriversResolverReturn' }
    & Pick<TSetDriversResolverReturn, 'versionType' | 'year'>
  ) }
);

export type SetForecastPeriodLocksMutationVariables = Exact<{
  propertyId: Scalars['String'];
  budgetYear: Scalars['Int'];
  locks: PropertyForecastLocksArgs;
}>;


export type SetForecastPeriodLocksMutation = (
  { __typename?: 'Mutation' }
  & { setPropertyForecastPeriodLocks: (
    { __typename?: 'TForecastPeriodLocks' }
    & Pick<TForecastPeriodLocks, 'isBudgetLocked' | 'isReforecastLocked'>
  ) }
);

export type SetMultiAccountAndPropertyLocksMutationVariables = Exact<{
  propertyIds: Array<Scalars['String']> | Scalars['String'];
  accountIds: Array<Scalars['String']> | Scalars['String'];
  budgetYear: Scalars['Int'];
  lockRfcst?: Maybe<Scalars['Boolean']>;
  lockBdgt?: Maybe<Scalars['Boolean']>;
}>;


export type SetMultiAccountAndPropertyLocksMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setMultipleAccountAndPropertyLocks'>
);

export type SetMultiForecastPeriodLocksMutationVariables = Exact<{
  propertyIds: Array<Scalars['String']> | Scalars['String'];
  budgetYear: Scalars['Int'];
  locks: PropertyForecastLocksArgs;
}>;


export type SetMultiForecastPeriodLocksMutation = (
  { __typename?: 'Mutation' }
  & { setMultiPropertyForecastPeriodLocks: Array<(
    { __typename?: 'PropertyRevenueConfiguration' }
    & Pick<PropertyRevenueConfiguration, 'propertyId' | 'isBudgetLocked' | 'isReforecastLocked'>
  )> }
);

export type SetPropertyDriversMutationVariables = Exact<{
  propertyId: Scalars['String'];
  year: Scalars['Int'];
  versionType: VersionType;
  items: Array<SetPropertyDriversArgsItem> | SetPropertyDriversArgsItem;
  accountLocks: Array<AccountLocksArgsItem> | AccountLocksArgsItem;
}>;


export type SetPropertyDriversMutation = (
  { __typename?: 'Mutation' }
  & { setPropertyDrivers: (
    { __typename?: 'TSetPropertyDriversResolverReturn' }
    & Pick<TSetPropertyDriversResolverReturn, 'versionType' | 'year'>
  ) }
);

export type GetBulkExportDownloadLinkFromS3QueryVariables = Exact<{
  date: Scalars['String'];
  reportType: BulkReportType;
}>;


export type GetBulkExportDownloadLinkFromS3Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'downloadLink'>
);

export type GetFinancialReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFinancialReportsQuery = (
  { __typename?: 'Query' }
  & { financialReports?: Maybe<Array<(
    { __typename?: 'BulkExportReportsReturn' }
    & Pick<BulkExportReportsReturn, 'dateNoTimeString' | 'fileName' | 'path' | 'lastModified'>
  )>> }
);

export type GetOperationalReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOperationalReportsQuery = (
  { __typename?: 'Query' }
  & { operationalReports?: Maybe<Array<(
    { __typename?: 'BulkExportReportsReturn' }
    & Pick<BulkExportReportsReturn, 'dateNoTimeString' | 'fileName' | 'path' | 'lastModified'>
  )>> }
);

export type UpdateCombinedDriverAssumptionsMutationVariables = Exact<{
  accPercentageAssumptionItems: Array<AccPercentageDriverUpdateInput> | AccPercentageDriverUpdateInput;
  operationalMetricAssumptionItems: Array<OperationalDriverUpdateInput> | OperationalDriverUpdateInput;
}>;


export type UpdateCombinedDriverAssumptionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOperationalDrivers'>
  & { updateAccPercentageDrivers: Array<(
    { __typename?: 'AccPercentageDriverAccountUpdate' }
    & { sourceAccountId: AccPercentageDriverAccountUpdate['accountV2Id'] }
    & { accPercentageAssumptions: Array<(
      { __typename?: 'AccPercentageDriverAccountUpdateAssumptions' }
      & Pick<AccPercentageDriverAccountUpdateAssumptions, 'accPercentageDriverAccountId'>
      & { percentage: AccPercentageDriverAccountUpdateAssumptions['percentOf'], monthsAgo: AccPercentageDriverAccountUpdateAssumptions['delayRecognition'], locked: AccPercentageDriverAccountUpdateAssumptions['locked'] }
    )> }
  )> }
);

export type UpdateWorksheetDataMutationVariables = Exact<{
  creates: Array<WorksheetDriverLineCreateInput> | WorksheetDriverLineCreateInput;
  updates: Array<WorksheetDriverLineUpdateInput> | WorksheetDriverLineUpdateInput;
  removeLineIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateWorksheetDataMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateWorksheetDriverData' | 'removeWorksheetDriverData'>
  & { createWorksheetDriverData: Array<(
    { __typename?: 'WorksheetLine' }
    & Pick<WorksheetLine, 'id'>
  )> }
);

export type CreateFileUploadRequestMutationVariables = Exact<{
  input: FileUploadRequestCreateInput;
}>;


export type CreateFileUploadRequestMutation = (
  { __typename?: 'Mutation' }
  & { createFileUploadRequest: (
    { __typename?: 'FileUploadRequest' }
    & Pick<FileUploadRequest, 'id'>
  ) }
);

export type QueryFileUploadRequestQueryVariables = Exact<{
  input: FileUploadRequestQueryInput;
}>;


export type QueryFileUploadRequestQuery = (
  { __typename?: 'Query' }
  & { queryFileUploadRequests: Array<(
    { __typename?: 'FileUploadRequest' }
    & Pick<FileUploadRequest, 'id' | 'status' | 'errors'>
  )> }
);

export type GetFinancialValuesForYearQueryVariables = Exact<{
  propertyIds: Array<Scalars['ID']> | Scalars['ID'];
  entityIds: Array<Scalars['ID']> | Scalars['ID'];
  years: Array<YearAndType> | YearAndType;
}>;


export type GetFinancialValuesForYearQuery = (
  { __typename?: 'Query' }
  & { financialValuesForYear: Array<(
    { __typename?: 'FinancialValuesYearModel' }
    & Pick<FinancialValuesYearModel, 'entityId' | 'entityType' | 'year' | 'versionType' | 'propertyId' | 'values'>
  )> }
);

export type GetForecastLocksQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  accountId?: Maybe<Scalars['ID']>;
}>;


export type GetForecastLocksQuery = (
  { __typename?: 'Query' }
  & { forecastLocks?: Maybe<(
    { __typename?: 'ForecastLockModel' }
    & { property: (
      { __typename?: 'ForecastLocks' }
      & Pick<ForecastLocks, 'reforecastLocked' | 'budgetLocked'>
    ), account?: Maybe<(
      { __typename?: 'ForecastLocks' }
      & Pick<ForecastLocks, 'reforecastLocked' | 'budgetLocked'>
    )> }
  )> }
);

export type GetPropertyForecastLocksQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
}>;


export type GetPropertyForecastLocksQuery = (
  { __typename?: 'Query' }
  & { propertyForecastLocks?: Maybe<(
    { __typename?: 'TForecastPeriodLocks' }
    & Pick<TForecastPeriodLocks, 'isReforecastLocked' | 'isBudgetLocked'>
  )> }
);

export type GetUserPropertiesForecastLocksQueryVariables = Exact<{
  budgetYear: Scalars['Int'];
}>;


export type GetUserPropertiesForecastLocksQuery = (
  { __typename?: 'Query' }
  & { userPropertiesForecastLocks?: Maybe<(
    { __typename?: 'UserPropertiesForecastLockModel' }
    & Pick<UserPropertiesForecastLockModel, 'reforecastLocked' | 'budgetLocked'>
  )> }
);

export type GetWorksheetLinesForWorksheetLinesReportQueryVariables = Exact<{
  propertyIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type GetWorksheetLinesForWorksheetLinesReportQuery = (
  { __typename?: 'Query' }
  & { worksheetLines: Array<(
    { __typename?: 'WorksheetLineWithPropertyAccountVersionTypeModel' }
    & Pick<WorksheetLineWithPropertyAccountVersionTypeModel, 'propertyId' | 'accountId' | 'versionType' | 'description' | 'values'>
  )> }
);

export type GetAccountVarianceInsightsQueryVariables = Exact<{
  accountId: Scalars['String'];
  propertyId: Scalars['String'];
  year: Scalars['Int'];
  month: Scalars['Int'];
}>;


export type GetAccountVarianceInsightsQuery = (
  { __typename?: 'Query' }
  & { getAccountVarianceInsights: (
    { __typename?: 'AccountVarianceInsightsModel' }
    & { account: (
      { __typename?: 'InsightsAccountModel' }
      & Pick<InsightsAccountModel, 'budgetComponentType' | 'negate'>
    ), insights: (
      { __typename?: 'InsightsModel' }
      & { trends: (
        { __typename?: 'TrendsModel' }
        & Pick<TrendsModel, 'currentValue'>
        & { previousMonth?: Maybe<(
          { __typename?: 'VarianceMetricModel' }
          & Pick<VarianceMetricModel, 'year' | 'monthIndex' | 'varianceAmount' | 'variancePercentage' | 'value'>
        )>, previousYear?: Maybe<(
          { __typename?: 'VarianceMetricModel' }
          & Pick<VarianceMetricModel, 'year' | 'monthIndex' | 'varianceAmount' | 'variancePercentage' | 'value'>
        )> }
      ), glContributions: Array<(
        { __typename?: 'GlContributionsEntityModel' }
        & Pick<GlContributionsEntityModel, 'id' | 'type' | 'name' | 'glNumber' | 'percentContributed' | 'parentTotal'>
      )>, annualBudgetRemaining: (
        { __typename?: 'AnnualBudgetRemainingModel' }
        & Pick<AnnualBudgetRemainingModel, 'year' | 'budgetTotal' | 'percentRemaining' | 'totalRemaining'>
      ), topVendors: Array<(
        { __typename?: 'AccountVendorModel' }
        & Pick<AccountVendorModel, 'vendorName' | 'amount'>
      )> }
    ) }
  ) }
);

export type GetMvrBudgetAssumptionsForPropertyAndAccountQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  accountId: Scalars['ID'];
  year: Scalars['Int'];
}>;


export type GetMvrBudgetAssumptionsForPropertyAndAccountQuery = (
  { __typename?: 'Query' }
  & { singlePropertyAccount: (
    { __typename?: 'PropertyAccountVersionTypeModel' }
    & Pick<PropertyAccountVersionTypeModel, 'propertyId' | 'accountId' | 'versionType' | 'originalValues'>
    & { accountPercentageDriver?: Maybe<(
      { __typename?: 'AccountPercentageDriverModel' }
      & Pick<AccountPercentageDriverModel, 'isDriven'>
      & { sourceAccounts: Array<(
        { __typename?: 'SourceAccountModel' }
        & Pick<SourceAccountModel, 'accountId' | 'glNumber' | 'glName' | 'assumptionId' | 'acctPercentageDriverAccountId' | 'locked'>
        & { assumptionsAndValues: Array<(
          { __typename?: 'AccPercentageDriverAssumptionAndValueModel' }
          & Pick<AccPercentageDriverAssumptionAndValueModel, 'percentage' | 'lookbackPeriod' | 'accountValue'>
        )> }
      )> }
    )>, operationalMetricDriver?: Maybe<(
      { __typename?: 'OperationalMetricDriverModel' }
      & Pick<OperationalMetricDriverModel, 'isDriven'>
      & { sourceMetrics: Array<(
        { __typename?: 'SourceMetricModel' }
        & Pick<SourceMetricModel, 'sourceMetricId' | 'metricName' | 'locked' | 'driverMetricTypeId'>
        & { assumptionsAndValues: Array<(
          { __typename?: 'OperationalMetricDriverAssumptionAndValueModel' }
          & Pick<OperationalMetricDriverAssumptionAndValueModel, 'amount' | 'lookbackPeriod' | 'percentage' | 'metricValue'>
        )> }
      )> }
    )>, worksheetDriver?: Maybe<(
      { __typename?: 'WorksheetDriverModel' }
      & Pick<WorksheetDriverModel, 'isDriven'>
      & { worksheetLines: Array<(
        { __typename?: 'WorksheetLineModel' }
        & Pick<WorksheetLineModel, 'description' | 'values'>
      )> }
    )>, payrollDriver?: Maybe<(
      { __typename?: 'PayrollDriverModel' }
      & Pick<PayrollDriverModel, 'isDriven'>
      & { compensationItems: Array<(
        { __typename?: 'PayrollDriverCompensationItemModel' }
        & Pick<PayrollDriverCompensationItemModel, 'itemType'>
        & { positions: Array<(
          { __typename?: 'PayrollDriverCompensationItemPositionsModel' }
          & Pick<PayrollDriverCompensationItemPositionsModel, 'id' | 'name'>
        )> }
      )> }
    )>, renovationsDriver?: Maybe<(
      { __typename?: 'RenovationsDriverModel' }
      & Pick<RenovationsDriverModel, 'isDriven'>
      & { costCategories: Array<(
        { __typename?: 'RenovationCostCategoryModel' }
        & Pick<RenovationCostCategoryModel, 'id' | 'name'>
      )> }
    )>, revenueDriver?: Maybe<(
      { __typename?: 'RevenueDriverModel' }
      & Pick<RevenueDriverModel, 'isDriven'>
      & { sourceRevenueMetrics: Array<(
        { __typename?: 'SourceRevenueMetricModel' }
        & Pick<SourceRevenueMetricModel, 'sourceId' | 'revenueType'>
      )> }
    )> }
  ) }
);

export type GetMvrFinanciaValuesWithVarianceNotesQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  year: Scalars['Int'];
  varianceNoteMonthIndex: Scalars['Int'];
}>;


export type GetMvrFinanciaValuesWithVarianceNotesQuery = (
  { __typename?: 'Query' }
  & { financialValuesForYear: Array<(
    { __typename?: 'FinancialValuesYearModel' }
    & Pick<FinancialValuesYearModel, 'entityId' | 'entityType' | 'year' | 'versionType' | 'propertyId' | 'values'>
  )>, queryAccountVarianceNotes: Array<(
    { __typename?: 'AccountVarianceNoteModel' }
    & Pick<AccountVarianceNoteModel, 'accountId' | 'year' | 'versionType' | 'monthIndex' | 'text'>
  )> }
);

export type GetMvrFinancialsSingleAccountQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  accountId: Scalars['ID'];
  year: Scalars['Int'];
}>;


export type GetMvrFinancialsSingleAccountQuery = (
  { __typename?: 'Query' }
  & { financialValuesForYear: Array<(
    { __typename?: 'FinancialValuesYearModel' }
    & Pick<FinancialValuesYearModel, 'entityId' | 'entityType' | 'year' | 'versionType' | 'propertyId' | 'values'>
  )> }
);

export type GetMvrVarianceNotesQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  year: Scalars['Int'];
  varianceNoteMonthIndex: Scalars['Int'];
}>;


export type GetMvrVarianceNotesQuery = (
  { __typename?: 'Query' }
  & { queryAccountVarianceNotes: Array<(
    { __typename?: 'AccountVarianceNoteModel' }
    & Pick<AccountVarianceNoteModel, 'accountId' | 'year' | 'versionType' | 'monthIndex' | 'text'>
  )> }
);

export type GetOperationalPropertyPerformanceQueryVariables = Exact<{
  propertyId: Scalars['String'];
  budgetYear: Scalars['Int'];
}>;


export type GetOperationalPropertyPerformanceQuery = (
  { __typename?: 'Query' }
  & { getOperationalPropertyPerformance: (
    { __typename?: 'OperationalMetricPerformanceModel' }
    & { move_out_rates: Array<(
      { __typename?: 'OperationalMetricPerformanceItemModel' }
      & Pick<OperationalMetricPerformanceItemModel, 'year' | 'versionType' | 'values' | 'counts'>
    )>, expirations: Array<(
      { __typename?: 'OperationalMetricPerformanceItemModel' }
      & Pick<OperationalMetricPerformanceItemModel, 'year' | 'versionType' | 'values' | 'counts'>
    )>, occupancy_rates: Array<(
      { __typename?: 'OperationalMetricPerformanceItemModel' }
      & Pick<OperationalMetricPerformanceItemModel, 'year' | 'versionType' | 'values' | 'counts'>
    )>, renewal_ratio: Array<(
      { __typename?: 'OperationalMetricPerformanceItemModel' }
      & Pick<OperationalMetricPerformanceItemModel, 'year' | 'versionType' | 'values' | 'counts'>
    )> }
  ) }
);

export type GetPropertyPerformanceQueryVariables = Exact<{
  propertyId: Scalars['String'];
  budgetYear: Scalars['Int'];
}>;


export type GetPropertyPerformanceQuery = (
  { __typename?: 'Query' }
  & { getPropertyPerformance: (
    { __typename?: 'PropertyPerformanceModel' }
    & { financial_summary: (
      { __typename?: 'FinancialSummaryModel' }
      & { gross_potential_rent: Array<(
        { __typename?: 'PerformanceMetricModel' }
        & Pick<PerformanceMetricModel, 'year' | 'type' | 'monthly_values' | 'year_total'>
      )>, total_revenue: Array<(
        { __typename?: 'PerformanceMetricModel' }
        & Pick<PerformanceMetricModel, 'year' | 'type' | 'monthly_values' | 'year_total'>
      )>, total_expenses: Array<(
        { __typename?: 'PerformanceMetricModel' }
        & Pick<PerformanceMetricModel, 'year' | 'type' | 'monthly_values' | 'year_total'>
      )>, net_operating_income: Array<(
        { __typename?: 'PerformanceMetricModel' }
        & Pick<PerformanceMetricModel, 'year' | 'type' | 'monthly_values' | 'year_total'>
      )>, occupancy: Array<(
        { __typename?: 'PerformanceMetricModel' }
        & Pick<PerformanceMetricModel, 'year' | 'type' | 'monthly_values' | 'year_total'>
      )> }
    ), rent: (
      { __typename?: 'RentSummaryModel' }
      & { market_rents: Array<(
        { __typename?: 'RentSummaryMetricModel' }
        & Pick<RentSummaryMetricModel, 'year' | 'type' | 'year_total'>
      )>, in_place_rent: Array<(
        { __typename?: 'RentSummaryMetricModel' }
        & Pick<RentSummaryMetricModel, 'year' | 'type' | 'year_total'>
      )>, renewal_rent: Array<(
        { __typename?: 'RentSummaryMetricModel' }
        & Pick<RentSummaryMetricModel, 'year' | 'type' | 'year_total'>
      )>, new_lease_rent: Array<(
        { __typename?: 'RentSummaryMetricModel' }
        & Pick<RentSummaryMetricModel, 'year' | 'type' | 'year_total'>
      )> }
    ) }
  ) }
);

export type GetAccountsTopCardTagsQueryVariables = Exact<{
  accountIds: Array<Scalars['String']> | Scalars['String'];
  topCardTagTypes: Array<TopCardTagType> | TopCardTagType;
}>;


export type GetAccountsTopCardTagsQuery = (
  { __typename?: 'Query' }
  & { getAccountsTopCardTags: Array<(
    { __typename?: 'AccountsTopCardTagsModel' }
    & Pick<AccountsTopCardTagsModel, 'accountId' | 'topCardTagType'>
  )> }
);

export type SetAccountsTopCardTagsMutationVariables = Exact<{
  accountIds: Array<Scalars['String']> | Scalars['String'];
  topCardTagType: TopCardTagType;
}>;


export type SetAccountsTopCardTagsMutation = (
  { __typename?: 'Mutation' }
  & { setAccountsTopCardTags: Array<(
    { __typename?: 'AccountsTopCardTagsModel' }
    & Pick<AccountsTopCardTagsModel, 'accountId' | 'topCardTagType'>
  )> }
);

export type GetTransactionsQueryVariables = Exact<{
  propertyId: Scalars['String'];
  accountId: Scalars['String'];
  year: Scalars['Int'];
  monthIndex: Scalars['Int'];
  pagination: TraversablePaginationArgs;
  sort: SortArgs;
  filter: FilterArgs;
}>;


export type GetTransactionsQuery = (
  { __typename?: 'Query' }
  & { queryTransactions: (
    { __typename?: 'TraversablePaginatedTransactionsModel' }
    & Pick<TraversablePaginatedTransactionsModel, 'total' | 'take' | 'page' | 'maxPage'>
    & { items: Array<(
      { __typename?: 'TransactionModel' }
      & Pick<TransactionModel, 'id' | 'propertyId' | 'accountId' | 'description' | 'memo' | 'postDate' | 'amount'>
    )> }
  ) }
);

export type GetCaseSensitiveEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetCaseSensitiveEmailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'caseSensitiveEmail'>
);

export type GetWelcomeUnauthorizedUserQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetWelcomeUnauthorizedUserQuery = (
  { __typename?: 'Query' }
  & { welcomeUnauthorizedUser: (
    { __typename?: 'User' }
    & Pick<User, 'email' | 'firstName' | 'lastName'>
  ) }
);

export type CreateSendBudgetJobMutationVariables = Exact<{
  propertyIds: Array<Scalars['ID']> | Scalars['ID'];
  budgetNameTemplate: Scalars['String'];
}>;


export type CreateSendBudgetJobMutation = (
  { __typename?: 'Mutation' }
  & { submitSendBudget: (
    { __typename?: 'BackgroundJob' }
    & Pick<BackgroundJob, 'id' | 'jobType' | 'status' | 'createdAt' | 'started' | 'ended' | 'errorMessage'>
  ) }
);

export type GetJobQueryVariables = Exact<{
  jobId: Scalars['String'];
}>;


export type GetJobQuery = (
  { __typename?: 'Query' }
  & { getJob?: Maybe<(
    { __typename?: 'BackgroundJob' }
    & Pick<BackgroundJob, 'id' | 'jobType' | 'status' | 'createdAt' | 'started' | 'ended' | 'errorMessage'>
  )> }
);

export type ListJobsQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  userId?: Maybe<Scalars['ID']>;
  jobType?: Maybe<JobType>;
}>;


export type ListJobsQuery = (
  { __typename?: 'Query' }
  & { listJobs: (
    { __typename?: 'PaginatedBackgroundJobs' }
    & Pick<PaginatedBackgroundJobs, 'page' | 'take' | 'maxPage' | 'total'>
    & { items: Array<(
      { __typename?: 'BackgroundJob' }
      & Pick<BackgroundJob, 'id' | 'jobType' | 'status' | 'createdAt' | 'started' | 'ended' | 'errorMessage' | 'userId' | 'userEmail' | 'userFirstName' | 'userLastName'>
    )> }
  ) }
);

export type CreateGroupMutationVariables = Exact<{
  name: Scalars['String'];
  authorizations: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateGroupMutation = (
  { __typename?: 'Mutation' }
  & { createUserGroup: (
    { __typename?: 'UserGroupModel' }
    & Pick<UserGroupModel, 'id'>
  ) }
);

export type CreateUserMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  generatePassword: Scalars['Boolean'];
  password?: Maybe<Scalars['PasswordScalar']>;
  role: UserRole;
  isReadOnly: Scalars['Boolean'];
  authorizations: Array<Scalars['String']> | Scalars['String'];
  properties: Array<Scalars['String']> | Scalars['String'];
  groups: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'UserModel' }
    & Pick<UserModel, 'id' | 'email' | 'firstName' | 'lastName' | 'role' | 'isReadOnly' | 'authorizations'>
    & { properties: Array<(
      { __typename?: 'Property' }
      & Pick<Property, 'id' | 'name'>
    )>, groups: Array<(
      { __typename?: 'UserModelGroupModel' }
      & Pick<UserModelGroupModel, 'id' | 'name'>
    )> }
  ) }
);

export type DeleteGroupsMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteGroupsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUserGroups'>
);

export type DeleteUsersMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteUsersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUsers'>
);

export type FindGroupUserCountsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type FindGroupUserCountsQuery = (
  { __typename?: 'Query' }
  & { findUserGroups: Array<(
    { __typename?: 'UserGroupModel' }
    & Pick<UserGroupModel, 'id' | 'name' | 'userCount'>
  )> }
);

export type GetRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getRoles'>
);

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'UserModel' }
    & Pick<UserModel, 'id' | 'email' | 'firstName' | 'lastName' | 'role' | 'isReadOnly' | 'authorizations'>
    & { properties: Array<(
      { __typename?: 'Property' }
      & Pick<Property, 'id' | 'name'>
    )>, groups: Array<(
      { __typename?: 'UserModelGroupModel' }
      & Pick<UserModelGroupModel, 'id' | 'name'>
    )> }
  ) }
);

export type ListAuthorizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAuthorizationsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'listAuthorizations'>
);

export type ListGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListGroupsQuery = (
  { __typename?: 'Query' }
  & { listUserGroups: Array<(
    { __typename?: 'UserGroupModel' }
    & Pick<UserGroupModel, 'id' | 'name' | 'authorizations'>
  )> }
);

export type ListUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type ListUsersQuery = (
  { __typename?: 'Query' }
  & { listUsers: Array<(
    { __typename?: 'UserModel' }
    & Pick<UserModel, 'id' | 'email' | 'firstName' | 'lastName' | 'role' | 'isReadOnly' | 'authorizations'>
    & { properties: Array<(
      { __typename?: 'Property' }
      & Pick<Property, 'id' | 'name'>
    )>, groups: Array<(
      { __typename?: 'UserModelGroupModel' }
      & Pick<UserModelGroupModel, 'id' | 'name'>
    )> }
  )> }
);

export type SetUsersBulkMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
  propertyIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  readOnly?: Maybe<Scalars['Boolean']>;
  groupIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type SetUsersBulkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setUsersBulk'>
);

export type UpdateGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  authorizations?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type UpdateGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateUserGroup?: Maybe<(
    { __typename?: 'UserGroupModel' }
    & Pick<UserGroupModel, 'id'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  isReadOnly?: Maybe<Scalars['Boolean']>;
  authorizations?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  properties?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  groups: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'UserModel' }
    & Pick<UserModel, 'id' | 'email' | 'firstName' | 'lastName' | 'role' | 'isReadOnly' | 'authorizations'>
    & { properties: Array<(
      { __typename?: 'Property' }
      & Pick<Property, 'id' | 'name'>
    )>, groups: Array<(
      { __typename?: 'UserModelGroupModel' }
      & Pick<UserModelGroupModel, 'id' | 'name'>
    )> }
  )> }
);

export type GetAnalystDriversQueryVariables = Exact<{
  propertyIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetAnalystDriversQuery = (
  { __typename?: 'Query' }
  & { analystDrivers: Array<(
    { __typename?: 'AnalystDriverInfo' }
    & Pick<AnalystDriverInfo, 'driverAssignmentId' | 'accountV2Id' | 'propertyId' | 'versionType' | 'driverType'>
  )> }
);

export type UpsertMetricValuesMultiPropertyMutationVariables = Exact<{
  propertyMetrics: Array<PropertyMetrics> | PropertyMetrics;
  unitTypeMetrics: Array<UnitTypeMetrics> | UnitTypeMetrics;
}>;


export type UpsertMetricValuesMultiPropertyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertMetricValuesMultiProperty'>
);

export type GetAnalystOpDriversQueryVariables = Exact<{
  year: Scalars['Int'];
}>;


export type GetAnalystOpDriversQuery = (
  { __typename?: 'Query' }
  & { analystOpDrivers: (
    { __typename?: 'AnalystOpDriversQueryResult' }
    & { properties: Array<(
      { __typename?: 'AnalystPropertyModel' }
      & Pick<AnalystPropertyModel, 'propertyName' | 'propertyId' | 'propertyCappedRenewalIncreasePercent' | 'totalUnitCount'>
      & { propertyMetrics: (
        { __typename?: 'AnalystPropertyAllMetricsModel' }
        & { earlyTerminations: Array<(
          { __typename?: 'AnalystUnitYearIntegerMetric' }
          & Pick<AnalystUnitYearIntegerMetric, 'year' | 'actuals' | 'expected'>
        )>, monthlyMoveOuts: Array<(
          { __typename?: 'AnalystUnitYearIntegerMetric' }
          & Pick<AnalystUnitYearIntegerMetric, 'year' | 'actuals' | 'expected'>
        )> }
      ), propertyUnits: Array<(
        { __typename?: 'AnalystPropertyUnitTypeModel' }
        & Pick<AnalystPropertyUnitTypeModel, 'unitType' | 'unitTypeId' | 'numUnits'>
        & { unitMetrics: (
          { __typename?: 'AnalystUnitAllMetricsModel' }
          & { avgMarketRents: Array<(
            { __typename?: 'AnalystUnitYearDecimalMetric' }
            & Pick<AnalystUnitYearDecimalMetric, 'year' | 'actuals' | 'expected'>
          )>, leaseExpirations: Array<(
            { __typename?: 'AnalystUnitYearIntegerMetric' }
            & Pick<AnalystUnitYearIntegerMetric, 'year' | 'actuals' | 'expected'>
          )>, renewalRatios: Array<(
            { __typename?: 'AnalystUnitYearDecimalMetric' }
            & Pick<AnalystUnitYearDecimalMetric, 'year' | 'actuals' | 'expected'>
          )>, renewalIncreases: Array<(
            { __typename?: 'AnalystUnitYearDecimalMetric' }
            & Pick<AnalystUnitYearDecimalMetric, 'expected' | 'actuals' | 'year'>
          )>, moveOutRates: Array<(
            { __typename?: 'AnalystUnitYearDecimalMetric' }
            & Pick<AnalystUnitYearDecimalMetric, 'year' | 'actuals' | 'expected'>
          )>, occupancyRates: Array<(
            { __typename?: 'AnalystUnitYearDecimalMetric' }
            & Pick<AnalystUnitYearDecimalMetric, 'year' | 'actuals' | 'expected'>
          )> }
        ) }
      )> }
    )> }
  ) }
);

export type GetPayrollEmployeeDataQueryVariables = Exact<{
  cursor?: Maybe<Scalars['String']>;
  budgetYear: Scalars['Int'];
}>;


export type GetPayrollEmployeeDataQuery = (
  { __typename?: 'Query' }
  & { listEmployees: (
    { __typename?: 'PaginatedPayrollEmployeeModel' }
    & Pick<PaginatedPayrollEmployeeModel, 'total' | 'cursor' | 'hasMore'>
    & { items: Array<(
      { __typename?: 'PayrollEmployeeModel' }
      & Pick<PayrollEmployeeModel, 'id' | 'externalSystemEmployeeId' | 'firstName' | 'lastName' | 'type' | 'classification' | 'startDate' | 'terminationDate'>
      & { position: (
        { __typename?: 'PayrollPositionModel' }
        & Pick<PayrollPositionModel, 'id' | 'name'>
      ), propertyAttributions: Array<(
        { __typename?: 'PayrollEmployeePropertyAttributionModel' }
        & Pick<PayrollEmployeePropertyAttributionModel, 'attributionPercentage'>
        & { property: (
          { __typename?: 'Property' }
          & Pick<Property, 'id' | 'name' | 'displayName'>
        ) }
      )>, baseComp?: Maybe<(
        { __typename?: 'PayrollEmployeeBaseCompModel' }
        & Pick<PayrollEmployeeBaseCompModel, 'expectedHoursPerWeek' | 'hourlyCompensation' | 'annualCompensation' | 'compensationType'>
      )>, overtime: Array<(
        { __typename?: 'PayrollEmployeeOvertimeModel' }
        & Pick<PayrollEmployeeOvertimeModel, 'id' | 'hourlyCompensation' | 'expectedHoursPerWeek' | 'effectiveStartDate' | 'effectiveEndDate'>
      )>, bonuses: Array<(
        { __typename?: 'PayrollEmployeeBonusModel' }
        & Pick<PayrollEmployeeBonusModel, 'id' | 'bonusType' | 'frequency' | 'amount' | 'bonusMonth' | 'bonusDay' | 'onetimeBonusDate' | 'effectiveStartDate'>
      )>, raises: Array<(
        { __typename?: 'PayrollEmployeeRaiseModel' }
        & Pick<PayrollEmployeeRaiseModel, 'id' | 'raiseType' | 'frequency' | 'amount' | 'raiseMonth' | 'raiseDay' | 'onetimeRaiseDate' | 'effectiveStartDate'>
      )>, compItems: Array<(
        { __typename?: 'PayrollEmployeeCompItemModel' }
        & Pick<PayrollEmployeeCompItemModel, 'id' | 'calculationType' | 'costPerPayPeriod' | 'effectiveStartDate' | 'annualCostCap'>
        & { compensationItem: (
          { __typename?: 'PayrollCompensationItemModel' }
          & Pick<PayrollCompensationItemModel, 'id' | 'clientId' | 'type'>
        ) }
      )> }
    )> }
  ) }
);

export type GetAnalystRenovationsQueryVariables = Exact<{
  propertyIds: Array<Scalars['String']> | Scalars['String'];
  yearAndTypes: Array<YearAndType> | YearAndType;
  cursor?: Maybe<Scalars['String']>;
}>;


export type GetAnalystRenovationsQuery = (
  { __typename?: 'Query' }
  & { queryRenovationPackagesPaginated: (
    { __typename?: 'PaginatedRenovationPackageModel' }
    & Pick<PaginatedRenovationPackageModel, 'total' | 'cursor' | 'hasMore'>
    & { items: Array<(
      { __typename?: 'RenovationPackageModel' }
      & Pick<RenovationPackageModel, 'id' | 'propertyId' | 'unitTypeId' | 'name' | 'premium' | 'premiumDelayRecognition'>
      & { unitTypeScheduleValues: Array<(
        { __typename?: 'UnitTypeScheduleValue' }
        & Pick<UnitTypeScheduleValue, 'monthIndex' | 'leaseExpirationCount' | 'moveOutCount' | 'moveOutRate'>
        & { version: (
          { __typename?: 'ValueVersion' }
          & Pick<ValueVersion, 'type' | 'year'>
        ) }
      )>, renovationNumbers: Array<(
        { __typename?: 'RenovationNumbersModel' }
        & Pick<RenovationNumbersModel, 'year' | 'versionType'>
        & { expected: Array<(
          { __typename?: 'RenovationNumberModel' }
          & Pick<RenovationNumberModel, 'monthIndex' | 'value'>
        )> }
      )> }
    )> }
  ) }
);

export type GetPropertyBudgetCompletionsQueryVariables = Exact<{
  budgetYear: Scalars['Int'];
}>;


export type GetPropertyBudgetCompletionsQuery = (
  { __typename?: 'Query' }
  & { queryPropertyBudgetCompletions: Array<(
    { __typename?: 'PropertyBudgetCompletionModel' }
    & Pick<PropertyBudgetCompletionModel, 'propertyId' | 'propertyName' | 'propertyDisplayName' | 'budgetYear' | 'completed' | 'updatedAt'>
  )>, getBudgetApprovalStatusForProperties: Array<(
    { __typename?: 'PropertyBudgetApprovalModel' }
    & Pick<PropertyBudgetApprovalModel, 'id' | 'propertyId' | 'budgetYear' | 'userId' | 'userFirstName' | 'userLastName' | 'userEmail' | 'timestamp' | 'approved'>
  )> }
);

export type SetPropertyBudgetCompletionMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  completed: Scalars['Boolean'];
}>;


export type SetPropertyBudgetCompletionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePropertyBudgetCompletion'>
);

export type ActivateBudgetYearMutationVariables = Exact<{
  budgetYear: Scalars['Int'];
}>;


export type ActivateBudgetYearMutation = (
  { __typename?: 'Mutation' }
  & { activateBudgetYear: (
    { __typename?: 'BudgetYearsListViewModel' }
    & Pick<BudgetYearsListViewModel, 'activeBudgetYear'>
    & { budgetYears: Array<(
      { __typename?: 'BudgetYearModel' }
      & Pick<BudgetYearModel, 'year' | 'protected'>
    )> }
  ) }
);

export type AddBudgetYearMutationVariables = Exact<{
  budgetYear: Scalars['Int'];
}>;


export type AddBudgetYearMutation = (
  { __typename?: 'Mutation' }
  & { addBudgetYear: (
    { __typename?: 'BudgetYearsListViewModel' }
    & Pick<BudgetYearsListViewModel, 'activeBudgetYear'>
    & { budgetYears: Array<(
      { __typename?: 'BudgetYearModel' }
      & Pick<BudgetYearModel, 'year' | 'protected' | 'isAutoUnitTypeUpdateEnabled'>
    )> }
  ) }
);

export type CopyDriversClientLevelMutationVariables = Exact<{
  destinationBudgetYear: Scalars['Int'];
  destinationVersionType: VersionType;
  sourceBudgetYear: Scalars['Int'];
  sourceVersionType: VersionType;
}>;


export type CopyDriversClientLevelMutation = (
  { __typename?: 'Mutation' }
  & { copyDriversClientLevel: (
    { __typename?: 'CopyDriversResponseModel' }
    & Pick<CopyDriversResponseModel, 'result' | 'resultString'>
  ) }
);

export type CopyPayrollEmployeesClientLevelMutationVariables = Exact<{
  sourceBudgetYear: Scalars['Int'];
  destBudgetYear: Scalars['Int'];
}>;


export type CopyPayrollEmployeesClientLevelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'copyPayrollEmployees'>
);

export type CopyRevenueAssumptionsClientLevelMutationVariables = Exact<{
  destinationBudgetYear: Scalars['Int'];
  destinationVersionType: VersionType;
  sourceBudgetYear: Scalars['Int'];
  sourceVersionType: VersionType;
  renovationCopyBothPeriods: Scalars['Boolean'];
}>;


export type CopyRevenueAssumptionsClientLevelMutation = (
  { __typename?: 'Mutation' }
  & { copyRevenueAssumptionsClientLevel: (
    { __typename?: 'CopyRevenueAssumptionsClientLevelResponseModel' }
    & Pick<CopyRevenueAssumptionsClientLevelResponseModel, 'result' | 'resultString'>
  ) }
);

export type CountPayrollEmployeesForBudgetYearGroupByPropertyQueryVariables = Exact<{
  budgetYear: Scalars['Int'];
}>;


export type CountPayrollEmployeesForBudgetYearGroupByPropertyQuery = (
  { __typename?: 'Query' }
  & { countEmployeesForBudgetYearGroupByProperty: Array<(
    { __typename?: 'PayrollPropertyEmployeeCountModel' }
    & Pick<PayrollPropertyEmployeeCountModel, 'propertyId' | 'propertyName' | 'employeeCount'>
  )> }
);

export type ListBudgetYearsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListBudgetYearsQuery = (
  { __typename?: 'Query' }
  & { listBudgetYears: (
    { __typename?: 'BudgetYearsListViewModel' }
    & Pick<BudgetYearsListViewModel, 'activeBudgetYear'>
    & { budgetYears: Array<(
      { __typename?: 'BudgetYearModel' }
      & Pick<BudgetYearModel, 'year' | 'protected' | 'isAutoUnitTypeUpdateEnabled'>
    )> }
  ) }
);

export type QueryDriverStatusQueryVariables = Exact<{
  budgetYear: Scalars['Int'];
  versionType: VersionType;
}>;


export type QueryDriverStatusQuery = (
  { __typename?: 'Query' }
  & { queryDriverStatus: Array<(
    { __typename?: 'PropertyDriverStatusViewModel' }
    & Pick<PropertyDriverStatusViewModel, 'propertyId' | 'propertyName' | 'year' | 'versionType' | 'revenue' | 'accPercentage' | 'operational' | 'worksheet' | 'growth' | 'payroll' | 'customDriver'>
  )> }
);

export type RevenueAssumptionsStatusQueryVariables = Exact<{
  budgetYear: Scalars['Int'];
  versionType: VersionType;
}>;


export type RevenueAssumptionsStatusQuery = (
  { __typename?: 'Query' }
  & { queryRevenueAssumptionsStatus: Array<(
    { __typename?: 'PropertyRevenueAssumptionsStatusViewModel' }
    & Pick<PropertyRevenueAssumptionsStatusViewModel, 'propertyId' | 'propertyName' | 'hasAssumptions' | 'year' | 'versionType'>
  )> }
);

export type UpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutationVariables = Exact<{
  budgetYear: Scalars['Int'];
  isAutoUnitTypeUpdateEnabled: Scalars['Boolean'];
}>;


export type UpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutation = (
  { __typename?: 'Mutation' }
  & { updateBudgetYearIsAutoUnitTypeUpdateEnabled: (
    { __typename?: 'BudgetYearsListViewModel' }
    & Pick<BudgetYearsListViewModel, 'activeBudgetYear'>
    & { budgetYears: Array<(
      { __typename?: 'BudgetYearModel' }
      & Pick<BudgetYearModel, 'year' | 'protected' | 'isAutoUnitTypeUpdateEnabled'>
    )> }
  ) }
);

export type UpdateBudgetYearProtectionMutationVariables = Exact<{
  budgetYear: Scalars['Int'];
  protected: Scalars['Boolean'];
}>;


export type UpdateBudgetYearProtectionMutation = (
  { __typename?: 'Mutation' }
  & { updateBudgetYearProtection: (
    { __typename?: 'BudgetYearsListViewModel' }
    & Pick<BudgetYearsListViewModel, 'activeBudgetYear'>
    & { budgetYears: Array<(
      { __typename?: 'BudgetYearModel' }
      & Pick<BudgetYearModel, 'year' | 'protected' | 'isAutoUnitTypeUpdateEnabled'>
    )> }
  ) }
);

export type QueryAccountPercentageDriverCyclesIndicatorsQueryVariables = Exact<{
  propertyId: Scalars['ID'];
}>;


export type QueryAccountPercentageDriverCyclesIndicatorsQuery = (
  { __typename?: 'Query' }
  & { queryAccountPercentageDriverCyclesIndicators: (
    { __typename?: 'AccountPercentageDriverCyclesIndicatorsModel' }
    & Pick<AccountPercentageDriverCyclesIndicatorsModel, 'reforecast' | 'budget'>
  ) }
);

export type QueryAccountPercentageDestinationAccountsWithCyclesQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  versionType: VersionType;
}>;


export type QueryAccountPercentageDestinationAccountsWithCyclesQuery = (
  { __typename?: 'Query' }
  & { queryAccountPercentageDestinationAccountsWithCycles: Array<(
    { __typename?: 'AccountShortModel' }
    & Pick<AccountShortModel, 'id' | 'glName' | 'glNumber' | 'order'>
  )> }
);

export type QuerySourceAccountsQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  accountId: Scalars['ID'];
  year: Scalars['Int'];
  versionType: VersionType;
}>;


export type QuerySourceAccountsQuery = (
  { __typename?: 'Query' }
  & { singlePropertyAccount: (
    { __typename?: 'PropertyAccountVersionTypeModel' }
    & Pick<PropertyAccountVersionTypeModel, 'propertyId' | 'accountId' | 'versionType'>
    & { accountPercentageDriver?: Maybe<(
      { __typename?: 'AccountPercentageDriverModel' }
      & { sourceAccountsBare: Array<(
        { __typename?: 'SourceAccountBareModel' }
        & Pick<SourceAccountBareModel, 'accountId' | 'glName' | 'glNumber' | 'lookbackPeriod'>
      )> }
    )> }
  ) }
);

export type SetAccPercentageDriversMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  versionType: VersionType;
  year: Scalars['Int'];
  destinationAccountId: Scalars['ID'];
  drivers: Array<AccPercentageDriverSetInput> | AccPercentageDriverSetInput;
}>;


export type SetAccPercentageDriversMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setAccPercentageDrivers'>
);

export type GetCoaManagementDataQueryVariables = Exact<{
  chartOfAccountsId: Scalars['ID'];
}>;


export type GetCoaManagementDataQuery = (
  { __typename?: 'Query' }
  & { listChartOfAccountsById: (
    { __typename?: 'COAListResponse' }
    & Pick<CoaListResponse, 'versionNum'>
    & { records: Array<(
      { __typename?: 'COAListRecord' }
      & Pick<CoaListRecord, 'id' | 'glNumber' | 'glName' | 'type' | 'order' | 'parentIds' | 'negate'>
    )> }
  ), fetchPMSAccounts: Array<(
    { __typename?: 'PMSAccountSnapshot' }
    & Pick<PmsAccountSnapshot, 'glName' | 'glNumber' | 'type' | 'order' | 'seenEarliest' | 'seenLatest'>
  )> }
);

export type GetDriverStatusForAccountQueryVariables = Exact<{
  accountId: Scalars['ID'];
}>;


export type GetDriverStatusForAccountQuery = (
  { __typename?: 'Query' }
  & { queryDriverStatusForAccount: (
    { __typename?: 'DriverStatusForAccount' }
    & Pick<DriverStatusForAccount, 'hasModellingMethods' | 'renovationCostCategories' | 'financialMetrics'>
    & { accPercentageSourceForAccounts: Array<(
      { __typename?: 'AccountV2' }
      & Pick<AccountV2, 'id' | 'glNumber' | 'glName'>
    )> }
  ) }
);

export type UpdateCoaManagementDataMutationVariables = Exact<{
  chartOfAccountsId: Scalars['ID'];
  versionNum: Scalars['Int'];
  records: Array<CoaListRecordInput> | CoaListRecordInput;
}>;


export type UpdateCoaManagementDataMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateChartOfAccountsById'>
);

export type AddAccountTagsMutationVariables = Exact<{
  accountIds: Array<Scalars['ID']> | Scalars['ID'];
  tags: Array<Scalars['String']> | Scalars['String'];
}>;


export type AddAccountTagsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addAccountTags'>
);

export type QueryAccountTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type QueryAccountTagsQuery = (
  { __typename?: 'Query' }
  & { queryAccountTags: Array<(
    { __typename?: 'AccountTagsModel' }
    & Pick<AccountTagsModel, 'accountId' | 'tags'>
  )> }
);

export type RemoveAccountTagsMutationVariables = Exact<{
  accountIds: Array<Scalars['ID']> | Scalars['ID'];
  tags: Array<Scalars['String']> | Scalars['String'];
}>;


export type RemoveAccountTagsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeAccountTags'>
);

export type SetAccountTagsMutationVariables = Exact<{
  accountIds: Array<Scalars['ID']> | Scalars['ID'];
  tags: Array<Scalars['String']> | Scalars['String'];
}>;


export type SetAccountTagsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setAccountTags'>
);

export type AddCustomItemMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type AddCustomItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addCustomItem'>
);

export type DeleteCustomItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCustomItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCustomItem'>
);

export type QueryCustomItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type QueryCustomItemsQuery = (
  { __typename?: 'Query' }
  & { queryCustomItems: Array<(
    { __typename?: 'CustomItemViewModel' }
    & Pick<CustomItemViewModel, 'id' | 'name'>
  )> }
);

export type UpdateCustomItemMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type UpdateCustomItemMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomItem: (
    { __typename?: 'CustomItemUpdateResultModel' }
    & Pick<CustomItemUpdateResultModel, 'success' | 'errorCode' | 'name'>
  ) }
);

export type AddReportTableMutationVariables = Exact<{
  budgetYear: Scalars['Int'];
  reportTableName: Scalars['String'];
  afterTableId?: Maybe<Scalars['ID']>;
}>;


export type AddReportTableMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addReportTable'>
);

export type DeleteReportTableMutationVariables = Exact<{
  budgetYear: Scalars['Int'];
  reportTableId: Scalars['ID'];
}>;


export type DeleteReportTableMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteReportTable'>
);

export type GetReportTablesQueryVariables = Exact<{
  budgetYear: Scalars['Int'];
}>;


export type GetReportTablesQuery = (
  { __typename?: 'Query' }
  & { getReportTables: Array<(
    { __typename?: 'ReportTableViewModel' }
    & Pick<ReportTableViewModel, 'id' | 'name' | 'order'>
    & { accounts: Array<(
      { __typename?: 'ReportTableAccountViewModel' }
      & Pick<ReportTableAccountViewModel, 'accountId'>
    )> }
  )> }
);

export type MoveReportTableMutationVariables = Exact<{
  budgetYear: Scalars['Int'];
  reportTableId: Scalars['ID'];
  afterTableId?: Maybe<Scalars['ID']>;
}>;


export type MoveReportTableMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'moveReportTable'>
);

export type SetReportTableAccountsMutationVariables = Exact<{
  budgetYear: Scalars['Int'];
  reportTableId: Scalars['ID'];
  accountIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type SetReportTableAccountsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setReportTableAccounts'>
);

export type UpdateReportTableMutationVariables = Exact<{
  budgetYear: Scalars['Int'];
  reportTableId: Scalars['ID'];
  newName: Scalars['String'];
}>;


export type UpdateReportTableMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateReportTable'>
);

export type CreatePayrollEmployeeManyBonusesMutationVariables = Exact<{
  employeeIds: Array<Scalars['ID']> | Scalars['ID'];
  bonusType: PayrollBonusType;
  frequency: PayrollBonusFrequency;
  amount: Scalars['Decimal'];
  bonusDay?: Maybe<Scalars['Int']>;
  bonusMonth?: Maybe<Scalars['Int']>;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  onetimeBonusDate?: Maybe<Scalars['DateTime']>;
}>;


export type CreatePayrollEmployeeManyBonusesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPayrollEmployeeManyBonuses'>
);

export type CreatePayrollEmployeeManyOvertimesMutationVariables = Exact<{
  employeeIds: Array<Scalars['ID']> | Scalars['ID'];
  hourlyRate: Scalars['Decimal'];
  hoursWeek: Scalars['Decimal'];
  effectiveStartDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
}>;


export type CreatePayrollEmployeeManyOvertimesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPayrollEmployeeManyOvertimes'>
);

export type CreatePayrollEmployeeManyRaisesMutationVariables = Exact<{
  employeeIds: Array<Scalars['ID']> | Scalars['ID'];
  raiseType: PayrollRaiseType;
  frequency: PayrollRaiseFrequency;
  amount: Scalars['Decimal'];
  raiseDay?: Maybe<Scalars['Int']>;
  raiseMonth?: Maybe<Scalars['Int']>;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  onetimeRaiseDate?: Maybe<Scalars['DateTime']>;
}>;


export type CreatePayrollEmployeeManyRaisesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPayrollEmployeeManyRaises'>
);

export type DeleteCompItemsFromEmployeesMutationVariables = Exact<{
  itemType: PayrollCompensationItemType;
  employeeIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteCompItemsFromEmployeesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCompItemsFromEmployees'>
);

export type DeletePayrollEmployeeManyBonusesMutationVariables = Exact<{
  bonusIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeletePayrollEmployeeManyBonusesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePayrollEmployeeManyBonuses'>
);

export type DeletePayrollEmployeeManyOvertimesMutationVariables = Exact<{
  overtimeIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeletePayrollEmployeeManyOvertimesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePayrollEmployeeManyOvertimes'>
);

export type DeletePayrollEmployeeManyRaisesMutationVariables = Exact<{
  raiseIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeletePayrollEmployeeManyRaisesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePayrollEmployeeManyRaises'>
);

export type SetPayrollEmployeeManyCompItemsMutationVariables = Exact<{
  employeeIds: Array<Scalars['ID']> | Scalars['ID'];
  input: PayrollUpdateEmployeeCompItemInput;
  type: PayrollCompensationItemType;
  createCalcType: PayrollCompensationItemCalculationType;
}>;


export type SetPayrollEmployeeManyCompItemsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setPayrollEmployeeManyCompItems'>
);

export type UpdatePayrollEmployeeManyBonusesMutationVariables = Exact<{
  bonusIds: Array<Scalars['ID']> | Scalars['ID'];
  bonusType: PayrollBonusType;
  frequency: PayrollBonusFrequency;
  amount: Scalars['Decimal'];
  bonusDay?: Maybe<Scalars['Int']>;
  bonusMonth?: Maybe<Scalars['Int']>;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  onetimeBonusDate?: Maybe<Scalars['DateTime']>;
}>;


export type UpdatePayrollEmployeeManyBonusesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePayrollEmployeeManyBonuses'>
);

export type UpdatePayrollEmployeeManyOvertimesMutationVariables = Exact<{
  overtimeIds: Array<Scalars['ID']> | Scalars['ID'];
  hourlyRate: Scalars['Decimal'];
  hoursWeek: Scalars['Decimal'];
  effectiveStartDate: Scalars['DateTime'];
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
}>;


export type UpdatePayrollEmployeeManyOvertimesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePayrollEmployeeManyOvertimes'>
);

export type UpdatePayrollEmployeeManyRaisesMutationVariables = Exact<{
  raiseIds: Array<Scalars['ID']> | Scalars['ID'];
  raiseType: PayrollRaiseType;
  frequency: PayrollRaiseFrequency;
  amount: Scalars['Decimal'];
  raiseDay?: Maybe<Scalars['Int']>;
  raiseMonth?: Maybe<Scalars['Int']>;
  effectiveEndDate?: Maybe<Scalars['DateTime']>;
  effectiveStartDate?: Maybe<Scalars['DateTime']>;
  onetimeRaiseDate?: Maybe<Scalars['DateTime']>;
}>;


export type UpdatePayrollEmployeeManyRaisesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePayrollEmployeeManyRaises'>
);

export type GetLatestPortfolioCalcSnapshotInfoQueryVariables = Exact<{
  budgetYear: Scalars['Int'];
}>;


export type GetLatestPortfolioCalcSnapshotInfoQuery = (
  { __typename?: 'Query' }
  & { getLatestPortfolioCalcSnapshotInfo?: Maybe<(
    { __typename?: 'PortfolioCalcSnapshotModel' }
    & Pick<PortfolioCalcSnapshotModel, 'id' | 'createdAt'>
  )> }
);

export type RequestPortfolioCalcSnapshotCreationMutationVariables = Exact<{
  budgetYear: Scalars['Int'];
}>;


export type RequestPortfolioCalcSnapshotCreationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'requestPortfolioCalcSnapshotCreation'>
);

export type CopyPropertyDriversMutationVariables = Exact<{
  budgetYear: Scalars['Int'];
  sourcePropertyId: Scalars['String'];
  destinationPropertyId: Scalars['String'];
  period?: Maybe<DriversCopyPeriodInput>;
  accountIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  copyAssumptions?: Maybe<Scalars['Boolean']>;
}>;


export type CopyPropertyDriversMutation = (
  { __typename?: 'Mutation' }
  & { copyDrivers: (
    { __typename?: 'CopyDriversResponseModel' }
    & Pick<CopyDriversResponseModel, 'result' | 'resultString' | 'destinationPropertyId'>
  ) }
);

export type SubmitCopyDriversMutationVariables = Exact<{
  budgetYear: Scalars['Int'];
  sourcePropertyId: Scalars['ID'];
  destinationPropertyIds: Array<Scalars['ID']> | Scalars['ID'];
  period?: Maybe<DriversCopyPeriodInput>;
  accountIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  copyAssumptions?: Maybe<Scalars['Boolean']>;
}>;


export type SubmitCopyDriversMutation = (
  { __typename?: 'Mutation' }
  & { submitCopyDrivers?: Maybe<(
    { __typename?: 'BackgroundJob' }
    & Pick<BackgroundJob, 'id' | 'jobType' | 'status' | 'createdAt' | 'started' | 'ended' | 'errorMessage'>
  )> }
);

export type CreateSharedReportLinksMutationVariables = Exact<{
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  persons: Array<PersonEmailInput> | PersonEmailInput;
}>;


export type CreateSharedReportLinksMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSharedReportLinks'>
);

export type DeleteClientCommentMutationVariables = Exact<{
  clientCommentId: Scalars['ID'];
}>;


export type DeleteClientCommentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteClientComment'>
);

export type GetBudgetApprovalStatusQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
}>;


export type GetBudgetApprovalStatusQuery = (
  { __typename?: 'Query' }
  & { getBudgetApprovalStatus?: Maybe<(
    { __typename?: 'PropertyBudgetApprovalModel' }
    & Pick<PropertyBudgetApprovalModel, 'id' | 'propertyId' | 'budgetYear' | 'userId' | 'userFirstName' | 'userLastName' | 'userEmail' | 'timestamp' | 'approved'>
  )> }
);

export type GetClientCommentSummaryForAccountQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  accountId: Scalars['ID'];
}>;


export type GetClientCommentSummaryForAccountQuery = (
  { __typename?: 'Query' }
  & { getClientCommentSummaryForAccount?: Maybe<(
    { __typename?: 'ClientCommentPreviewViewModel' }
    & Pick<ClientCommentPreviewViewModel, 'id' | 'propertyId' | 'accountId' | 'budgetYear' | 'text' | 'createdAt' | 'count'>
  )> }
);

export type GetClientCommentsForAccountQueryVariables = Exact<{
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
  accountId: Scalars['ID'];
}>;


export type GetClientCommentsForAccountQuery = (
  { __typename?: 'Query' }
  & { getClientCommentsForAccount: Array<(
    { __typename?: 'ClientCommentViewModel' }
    & Pick<ClientCommentViewModel, 'id' | 'propertyId' | 'accountId' | 'budgetYear' | 'text' | 'authorId' | 'authorName' | 'authorLastName' | 'authorEmail' | 'createdAt'>
  )> }
);

export type GetPropertyAccountDriverStatusQueryVariables = Exact<{
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
}>;


export type GetPropertyAccountDriverStatusQuery = (
  { __typename?: 'Query' }
  & { queryPropertyAccountDriverStatus: Array<(
    { __typename?: 'PropertyAccountDriverStatusViewModel' }
    & Pick<PropertyAccountDriverStatusViewModel, 'accountId' | 'revenue' | 'renovation' | 'accPercentage' | 'operational' | 'worksheet' | 'growth' | 'payroll' | 'customDriver' | 'hasOverrides'>
  )> }
);

export type GetPropertyExecutiveSummaryDataQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  topCardTagTypes: Array<TopCardTagType> | TopCardTagType;
}>;


export type GetPropertyExecutiveSummaryDataQuery = (
  { __typename?: 'Query' }
  & { financialValuesPropertyBudgetSeason: Array<(
    { __typename?: 'FinancialValuesYearBareModel' }
    & Pick<FinancialValuesYearBareModel, 'accountId' | 'year' | 'versionType' | 'values'>
  )>, clientReportT12FinancialValues?: Maybe<(
    { __typename?: 'ClientReportT12FinancialValues' }
    & Pick<ClientReportT12FinancialValues, 't12Month' | 't12Year'>
    & { financialValues: Array<(
      { __typename?: 'ClientReportT12AccountValues' }
      & Pick<ClientReportT12AccountValues, 'accountId' | 'values'>
    )> }
  )>, getReportTables: Array<(
    { __typename?: 'ReportTableViewModel' }
    & Pick<ReportTableViewModel, 'id' | 'name' | 'order'>
    & { accounts: Array<(
      { __typename?: 'ReportTableAccountViewModel' }
      & Pick<ReportTableAccountViewModel, 'accountId'>
    )> }
  )>, queryNotesForProperty: Array<(
    { __typename?: 'AccountTwoPeriodNoteModel' }
    & Pick<AccountTwoPeriodNoteModel, 'accountId' | 'reforecastNote' | 'budgetNote'>
  )>, getClientFirstComments: Array<(
    { __typename?: 'ClientCommentPreviewViewModel' }
    & Pick<ClientCommentPreviewViewModel, 'id' | 'propertyId' | 'accountId' | 'budgetYear' | 'text' | 'createdAt' | 'count'>
  )>, getAccountsTopCardTags: Array<(
    { __typename?: 'AccountsTopCardTagsModel' }
    & Pick<AccountsTopCardTagsModel, 'accountId' | 'topCardTagType'>
  )>, originalRevenueModel?: Maybe<(
    { __typename?: 'OriginalRevenueModel' }
    & Pick<OriginalRevenueModel, 'propertyId' | 'budgetYear'>
    & { earlyTerminationCount: (
      { __typename?: 'OriginalRevenueForecastedNumbersModel' }
      & Pick<OriginalRevenueForecastedNumbersModel, 'actuals' | 'reforecast' | 'budget'>
    ), grossPotentialRent: (
      { __typename?: 'OriginalRevenueForecastedNumbersModel' }
      & Pick<OriginalRevenueForecastedNumbersModel, 'actuals' | 'reforecast' | 'budget'>
    ), grossPotentialRentAndVacancyLoss: (
      { __typename?: 'OriginalRevenueForecastedNumbersModel' }
      & Pick<OriginalRevenueForecastedNumbersModel, 'actuals' | 'reforecast' | 'budget'>
    ), lossToLease: (
      { __typename?: 'OriginalRevenueForecastedNumbersModel' }
      & Pick<OriginalRevenueForecastedNumbersModel, 'actuals' | 'reforecast' | 'budget'>
    ), m2mMoveOutCount: (
      { __typename?: 'OriginalRevenueForecastedNumbersModel' }
      & Pick<OriginalRevenueForecastedNumbersModel, 'actuals' | 'reforecast' | 'budget'>
    ), momLossToLeaseChange: (
      { __typename?: 'OriginalRevenueForecastedNumbersModel' }
      & Pick<OriginalRevenueForecastedNumbersModel, 'actuals' | 'reforecast' | 'budget'>
    ), rent: (
      { __typename?: 'OriginalRevenueForecastedNumbersModel' }
      & Pick<OriginalRevenueForecastedNumbersModel, 'actuals' | 'reforecast' | 'budget'>
    ), vacancyLoss: (
      { __typename?: 'OriginalRevenueForecastedNumbersModel' }
      & Pick<OriginalRevenueForecastedNumbersModel, 'actuals' | 'reforecast' | 'budget'>
    ), unitTypeAvgMarketRent: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumbersModel' }
      & Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeBeginningOccupancy: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumberModel' }
      & Pick<OriginalRevenueUnitTypeNumberModel, 'unitTypeId' | 'unitTypeName' | 'value'>
    )>, unitTypeExpirationCount: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumbersModel' }
      & Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeInPlaceRent: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumbersModel' }
      & Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, totalMoveInCount: (
      { __typename?: 'OriginalRevenueForecastedNumbersModel' }
      & Pick<OriginalRevenueForecastedNumbersModel, 'actuals' | 'reforecast' | 'budget'>
    ), totalMoveOutCount: (
      { __typename?: 'OriginalRevenueForecastedNumbersModel' }
      & Pick<OriginalRevenueForecastedNumbersModel, 'actuals' | 'reforecast' | 'budget'>
    ), unitTypeMoveInCount: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumbersModel' }
      & Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeMoveOutCount: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumbersModel' }
      & Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeNewLeaseTradeOut: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumbersModel' }
      & Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeOccupiedCount: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumbersModel' }
      & Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeRenewalCount: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumbersModel' }
      & Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeRenewalTradeOut: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumbersModel' }
      & Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeStartingInPlaceRent: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumberModel' }
      & Pick<OriginalRevenueUnitTypeNumberModel, 'unitTypeId' | 'unitTypeName' | 'value'>
    )>, unitTypeUnitCount: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumberModel' }
      & Pick<OriginalRevenueUnitTypeNumberModel, 'unitTypeId' | 'unitTypeName' | 'value'>
    )>, unitTypeUnitCountMonthly: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumbersModel' }
      & Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeVacancyCount: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumbersModel' }
      & Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )> }
  )>, employeeTotals: Array<(
    { __typename?: 'PayrollEmployeeTotalsModel' }
    & Pick<PayrollEmployeeTotalsModel, 'employeeId' | 'positionName' | 'basePayTotal' | 'raiseTotal'>
  )> }
);

export type GetSharedReportLinksQueryVariables = Exact<{
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
}>;


export type GetSharedReportLinksQuery = (
  { __typename?: 'Query' }
  & { getSharedReportLinks: Array<(
    { __typename?: 'SharedLinkViewModel' }
    & Pick<SharedLinkViewModel, 'id' | 'firstName' | 'lastName' | 'email' | 'url'>
  )> }
);

export type RemoveSharedReportLinkMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveSharedReportLinkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeSharedReportLink'>
);

export type ResendSharedReportLinkMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResendSharedReportLinkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resendSharedReportLink'>
);

export type SetBudgetApprovalMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  approved: Scalars['Boolean'];
}>;


export type SetBudgetApprovalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setBudgetApproval'>
);

export type SetClientCommentMutationVariables = Exact<{
  budgetYear: Scalars['Int'];
  propertyId: Scalars['ID'];
  accountId: Scalars['ID'];
  text: Scalars['String'];
}>;


export type SetClientCommentMutation = (
  { __typename?: 'Mutation' }
  & { setClientComment?: Maybe<(
    { __typename?: 'ClientCommentViewModel' }
    & Pick<ClientCommentViewModel, 'id' | 'propertyId' | 'accountId' | 'budgetYear' | 'text'>
  )> }
);

export type FetchPmsPropertiesQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchPmsPropertiesQuery = (
  { __typename?: 'Query' }
  & { fetchPMSProperties: Array<(
    { __typename?: 'PMSPropertySnapshot' }
    & Pick<PmsPropertySnapshot, 'name' | 'seenEarliest' | 'seenLatest'>
  )> }
);

export type UpdateReforecastStartMonthMutationVariables = Exact<{
  reforecastStartMonthIndex: Scalars['Int'];
  propertyIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type UpdateReforecastStartMonthMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateReforecastStartMonth'>
);

export type ListRenovationPackagesQueryVariables = Exact<{
  propertyIds: Array<Scalars['String']> | Scalars['String'];
  budgetYear: Scalars['Int'];
}>;


export type ListRenovationPackagesQuery = (
  { __typename?: 'Query' }
  & { queryRenovationPackages: Array<(
    { __typename?: 'RenovationPackageModel' }
    & Pick<RenovationPackageModel, 'id' | 'name' | 'premium' | 'propertyId' | 'unitTypeId'>
    & { simplifiedRevenueRenovationMapping?: Maybe<(
      { __typename?: 'SimplifiedRevenueRenovationMapping' }
      & Pick<SimplifiedRevenueRenovationMapping, 'unrenovatedUnitTypeId' | 'unrenovatedUnitTypeName' | 'renovatedUnitTypeId' | 'renovatedUnitTypeName' | 'renovatedUnitTypeStartingRent'>
    )> }
  )> }
);

export type GetOccupancyRatesActualsQueryVariables = Exact<{
  propertyId: Scalars['String'];
  year: Scalars['Int'];
}>;


export type GetOccupancyRatesActualsQuery = (
  { __typename?: 'Query' }
  & { actuals: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'unitCount' | 'occupiedUnits' | 'unitTypeId'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type GetRenewalRatioCountsActualsQueryVariables = Exact<{
  propertyId: Scalars['String'];
  year: Scalars['Int'];
}>;


export type GetRenewalRatioCountsActualsQuery = (
  { __typename?: 'Query' }
  & { actuals: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'renewalRatio' | 'leaseExpirationCount'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type GetSimplifiedRevenueQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
}>;


export type GetSimplifiedRevenueQuery = (
  { __typename?: 'Query' }
  & { simplifiedRevenueModel?: Maybe<(
    { __typename?: 'SimplifiedRevenueModel' }
    & Pick<SimplifiedRevenueModel, 'propertyId' | 'budgetYear'>
    & { rentRoll?: Maybe<(
      { __typename?: 'RentRollModel' }
      & Pick<RentRollModel, 'date'>
      & { expiringUnits: Array<(
        { __typename?: 'RentRollExpiringUnitModel' }
        & Pick<RentRollExpiringUnitModel, 'unitId' | 'unitNumber' | 'reason' | 'conversionMonth'>
      )> }
    )>, defaultMarketRents: (
      { __typename?: 'SimplifiedRevenueMarketRentModel' }
      & Pick<SimplifiedRevenueMarketRentModel, 'reforecastAnnualIncreasePercentage' | 'budgetAnnualIncreasePercentage'>
      & { reforecast: Array<Maybe<(
        { __typename?: 'SimplifiedRevenueMarketRentItemModel' }
        & Pick<SimplifiedRevenueMarketRentItemModel, 'averageMarketRent' | 'growthAmount' | 'growthPercentage' | 'startingAverageMarketRent'>
      )>>, budget: Array<(
        { __typename?: 'SimplifiedRevenueMarketRentItemModel' }
        & Pick<SimplifiedRevenueMarketRentItemModel, 'averageMarketRent' | 'growthAmount' | 'growthPercentage' | 'startingAverageMarketRent'>
      )> }
    ), defaultVacancyPercentage: (
      { __typename?: 'SimplifiedRevenueVacancyPercentage' }
      & Pick<SimplifiedRevenueVacancyPercentage, 'reforecast' | 'budget'>
    ), renewalConfiguration?: Maybe<(
      { __typename?: 'SimplifiedRevenueRenewalConfiguration' }
      & { reforecast: (
        { __typename?: 'SimplifiedRevenueRenewalPeriodConfiguration' }
        & Pick<SimplifiedRevenueRenewalPeriodConfiguration, 'maxIncreaseAmount' | 'maxIncreasePercentage' | 'maxPercentOfMarketRent' | 'lossToLeaseCaptureRate' | 'increaseInPlaceGreaterThanLossToLease'>
      ) }
    )>, defaultRenovationRatio: (
      { __typename?: 'SimplifiedRevenueRenovationRatio' }
      & Pick<SimplifiedRevenueRenovationRatio, 'reforecast' | 'budget'>
    ), defaultRenewalRatios: (
      { __typename?: 'SimplifiedRevenueRenewalRatio' }
      & Pick<SimplifiedRevenueRenewalRatio, 'reforecast' | 'budget'>
    ), defaultRenovatedRenewalRatios: (
      { __typename?: 'SimplifiedRevenueRenewalRatio' }
      & Pick<SimplifiedRevenueRenewalRatio, 'reforecast' | 'budget'>
    ), availableRentRolls: Array<(
      { __typename?: 'RentRollMetaDataModel' }
      & Pick<RentRollMetaDataModel, 'id' | 'propertyId' | 'date' | 'isActive'>
    )>, unitTypeMarketRents: Array<(
      { __typename?: 'SimplifiedRevenueUnitTypeStartingMarketRentModel' }
      & Pick<SimplifiedRevenueUnitTypeStartingMarketRentModel, 'unitTypeId' | 'unitTypeName' | 'rentRollStartingMarketRent' | 'overrideStartingMarketRent'>
    )>, fakeUnitTypeMarketRents: Array<(
      { __typename?: 'SimplifiedRevenueFakeUnitTypeStartingMarketRentModel' }
      & Pick<SimplifiedRevenueFakeUnitTypeStartingMarketRentModel, 'name' | 'startingMarketRent'>
    )> }
  )> }
);

export type GetSimplifiedRevenueSummaryQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
}>;


export type GetSimplifiedRevenueSummaryQuery = (
  { __typename?: 'Query' }
  & { simplifiedRevenueModel?: Maybe<(
    { __typename?: 'SimplifiedRevenueModel' }
    & Pick<SimplifiedRevenueModel, 'propertyId' | 'budgetYear'>
    & { unitTypeUnitCounts: Array<(
      { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
      & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeAvgMarketRents: Array<(
      { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
      & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeBlendedInPlaceRent: Array<(
      { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
      & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeNewLeaseInPlaceRent: Array<(
      { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
      & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeRenewalInPlaceRent: Array<(
      { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
      & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeRenewalTradeOutAmount: Array<(
      { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
      & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeOccupancy: (
      { __typename?: 'SimplifiedRevenueUnitTypeOccupancySummaryModel' }
      & { moveIns: Array<(
        { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
        & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
      )>, moveOuts: Array<(
        { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
        & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
      )>, occupancyPercentage: Array<(
        { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
        & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
      )>, occupiedUnitCount: Array<(
        { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
        & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
      )>, vacancyPercentage: Array<(
        { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
        & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
      )>, vacantCount: Array<(
        { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
        & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
      )> }
    ), unitTypeRenewals: (
      { __typename?: 'SimplifiedRevenueUnitTypeRenewalsSummaryModel' }
      & { expirationCount: Array<(
        { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
        & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
      )>, expirationPercentage: Array<(
        { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
        & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
      )>, renewalCount: Array<(
        { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
        & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
      )>, renewalPercentage: Array<(
        { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
        & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
      )> }
    ), unitTypeRenovations: (
      { __typename?: 'SimplifiedRevenueUnitTypeRenovationsSummaryModel' }
      & { renovationCount: Array<(
        { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
        & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
      )>, renovatedUnitCount: Array<(
        { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
        & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
      )>, renovatedExpirationCount: Array<(
        { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
        & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
      )>, renovatedRenewalRatio: Array<(
        { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
        & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
      )>, unrenovatedUnitCount: Array<(
        { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
        & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
      )>, unrenovatedExpirationCount: Array<(
        { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
        & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
      )>, unrenovatedRenewalRatio: Array<(
        { __typename?: 'SimplifiedRevenueUnitTypeSummaryModel' }
        & Pick<SimplifiedRevenueUnitTypeSummaryModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
      )> }
    ) }
  )> }
);

export type RemoveUnitTypeRenovationMapMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  renovationPackageId: Scalars['ID'];
}>;


export type RemoveUnitTypeRenovationMapMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'simplifiedRevenueRemoveRenovationMapping'>
);

export type UpdateActiveRentRollMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  rentRollId: Scalars['ID'];
}>;


export type UpdateActiveRentRollMutation = (
  { __typename?: 'Mutation' }
  & { setActiveRentRoll: (
    { __typename?: 'RentRollMetaDataModel' }
    & Pick<RentRollMetaDataModel, 'id' | 'propertyId' | 'date' | 'isActive'>
  ) }
);

export type UpdateFakeUnitStartingMarketRentMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  marketRents: Array<SimplifiedRevenueFakeUnitTypeMarketRentInput> | SimplifiedRevenueFakeUnitTypeMarketRentInput;
}>;


export type UpdateFakeUnitStartingMarketRentMutation = (
  { __typename?: 'Mutation' }
  & { simplifiedRevenueUpdateFakeUnitTypeMarketRent: Array<(
    { __typename?: 'SimplifiedRevenueFakeUnitTypeStartingMarketRentModel' }
    & Pick<SimplifiedRevenueFakeUnitTypeStartingMarketRentModel, 'name' | 'startingMarketRent'>
  )> }
);

export type UpdateMarketRentGrowthMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  increases: SimplifiedRevenueMarketRentIncreaseInput;
}>;


export type UpdateMarketRentGrowthMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSimplifiedRevenueDefaultMarketRentIncrease'>
);

export type UpdateRealUnitStartingMarketRentMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  overrides: Array<SimplifiedRevenueSetUnitTypeStartingMarketRentOverrideInput> | SimplifiedRevenueSetUnitTypeStartingMarketRentOverrideInput;
}>;


export type UpdateRealUnitStartingMarketRentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'simplifiedRevenueSetUnitTypeStartingMarketRent'>
);

export type UpdateRenewalConfigurationMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  configuration: SimplifiedRevenueRenewalTradeOutConfigurationInput;
}>;


export type UpdateRenewalConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { updateSimplifiedRevenueRenewalTradeOutConfiguration: (
    { __typename?: 'SimplifiedRevenueRenewalConfiguration' }
    & { reforecast: (
      { __typename?: 'SimplifiedRevenueRenewalPeriodConfiguration' }
      & Pick<SimplifiedRevenueRenewalPeriodConfiguration, 'maxIncreaseAmount' | 'maxIncreasePercentage' | 'maxPercentOfMarketRent' | 'lossToLeaseCaptureRate' | 'increaseInPlaceGreaterThanLossToLease'>
    ) }
  ) }
);

export type UpdateRenewalRatiosMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  renewalRatios: SimplifiedRevenueRenewalRatioInput;
  renewalRenoRatios: SimplifiedRevenueRenewalRatioInput;
}>;


export type UpdateRenewalRatiosMutation = (
  { __typename?: 'Mutation' }
  & { updateSimplifiedRevenueDefaultRenewalRatios: (
    { __typename?: 'SimplifiedRevenueRenewalRatio' }
    & Pick<SimplifiedRevenueRenewalRatio, 'reforecast' | 'budget'>
  ), updateSimplifiedRevenueDefaultRenovatedRenewalRatios: (
    { __typename?: 'SimplifiedRevenueRenewalRatio' }
    & Pick<SimplifiedRevenueRenewalRatio, 'reforecast' | 'budget'>
  ) }
);

export type UpdateDefaultRenovationRatioMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  renovationRatios: SimplifiedRevenueRenovationRatioInput;
}>;


export type UpdateDefaultRenovationRatioMutation = (
  { __typename?: 'Mutation' }
  & { updateSimplifiedDefaultRevenueRenovationRatio: (
    { __typename?: 'SimplifiedRevenueRenovationRatio' }
    & Pick<SimplifiedRevenueRenovationRatio, 'reforecast' | 'budget'>
  ) }
);

export type UpdateRentRollMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  updates: Array<UpdateRentRollExpiringUnitConversionInput> | UpdateRentRollExpiringUnitConversionInput;
}>;


export type UpdateRentRollMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSimplifiedRevenueExpiringUnits'>
);

export type UpdateUnitTypeRenovationMapMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  setData: SimplifiedRevenueSetRenovationMappingInput;
}>;


export type UpdateUnitTypeRenovationMapMutation = (
  { __typename?: 'Mutation' }
  & { simplifiedRevenueSetRenovationMapping: (
    { __typename?: 'SimplifiedRevenueRenovationMapping' }
    & Pick<SimplifiedRevenueRenovationMapping, 'unrenovatedUnitTypeId' | 'unrenovatedUnitTypeName' | 'renovatedUnitTypeId' | 'renovatedUnitTypeName' | 'renovatedUnitTypeStartingRent'>
  ) }
);

export type UpdateVacancyRatesMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  vacancyPercentages: SimplifiedRevenueVacancyPercentageInput;
}>;


export type UpdateVacancyRatesMutation = (
  { __typename?: 'Mutation' }
  & { updateSimplifiedRevenueDefaultVacancyPercentage: (
    { __typename?: 'SimplifiedRevenueVacancyPercentage' }
    & Pick<SimplifiedRevenueVacancyPercentage, 'reforecast' | 'budget'>
  ) }
);

export type GetTableViewForEmptyAccountsByPropertyIdAndVersionIdsQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  starringMetaArgInput: Array<StarringMetaArgInput> | StarringMetaArgInput;
  financialTotalValueArgInput: Array<FinancialTotalValueArgInput> | FinancialTotalValueArgInput;
}>;


export type GetTableViewForEmptyAccountsByPropertyIdAndVersionIdsQuery = (
  { __typename?: 'Query' }
  & { getTableViewForEmptyAccountsByPropertyIdAndVersionIds?: Maybe<Array<(
    { __typename?: 'AccountsTableViewModel' }
    & Pick<AccountsTableViewModel, 'propertyId' | 'accountId' | 'parentAccountId' | 'accountGlNumber' | 'accountGlName' | 'reforecastVersionId' | 'budgetVersionId' | 'isReforecastVersionStarred' | 'isBudgetVersionStarred' | 'reforecastValue' | 'budgetValue' | 'reforecastVersionNote' | 'budgetVersionNote'>
  )>> }
);

export type GetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  reforecastStartMonthIndex: Scalars['Int'];
  starringMetaArgInput: Array<StarringMetaArgInput> | StarringMetaArgInput;
  financialTotalValueArgInput: Array<FinancialTotalValueArgInput> | FinancialTotalValueArgInput;
}>;


export type GetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsQuery = (
  { __typename?: 'Query' }
  & { getTableViewForOverriddenAccountsByPropertyIdAndVersionIds?: Maybe<Array<(
    { __typename?: 'AccountsTableViewModel' }
    & Pick<AccountsTableViewModel, 'propertyId' | 'accountId' | 'parentAccountId' | 'accountGlNumber' | 'accountGlName' | 'reforecastVersionId' | 'budgetVersionId' | 'isReforecastVersionStarred' | 'isBudgetVersionStarred' | 'reforecastValue' | 'budgetValue' | 'reforecastVersionNote' | 'budgetVersionNote'>
  )>> }
);

export type GetTableViewForStarredAccountsByPropertyIdAndVersionIdsQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  versionIds: Array<Scalars['ID']> | Scalars['ID'];
  financialTotalValueArgInput: Array<FinancialTotalValueArgInput> | FinancialTotalValueArgInput;
}>;


export type GetTableViewForStarredAccountsByPropertyIdAndVersionIdsQuery = (
  { __typename?: 'Query' }
  & { getTableViewForStarredAccountsByPropertyIdAndVersionIds?: Maybe<Array<(
    { __typename?: 'AccountsTableViewModel' }
    & Pick<AccountsTableViewModel, 'propertyId' | 'accountId' | 'parentAccountId' | 'accountGlNumber' | 'accountGlName' | 'reforecastVersionId' | 'budgetVersionId' | 'isReforecastVersionStarred' | 'isBudgetVersionStarred' | 'reforecastValue' | 'budgetValue' | 'reforecastVersionNote' | 'budgetVersionNote'>
  )>> }
);

export type DeleteUnitEventsMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUnitEventsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUnitEvents'>
);

export type ListUnitEventsQueryVariables = Exact<{
  propertyId: Scalars['String'];
  budgetYear: Scalars['Int'];
  page: Scalars['Int'];
  take: Scalars['Int'];
}>;


export type ListUnitEventsQuery = (
  { __typename?: 'Query' }
  & { listUnits: (
    { __typename?: 'TraversablePaginatedUnitModel' }
    & Pick<TraversablePaginatedUnitModel, 'page' | 'take' | 'maxPage' | 'total'>
    & { items: Array<(
      { __typename?: 'UnitModel' }
      & Pick<UnitModel, 'id' | 'number' | 'unitTypeId'>
      & { events: Array<(
        { __typename?: 'EnrichedUnitEventModel' }
        & Pick<EnrichedUnitEventModel, 'id' | 'propertyId' | 'unitId' | 'eventType' | 'eventStart' | 'eventEnd' | 'importedEvent' | 'tenantName' | 'inPlaceRent' | 'startingCalculatedInPlaceRent' | 'endingCalculatedInPlaceRent' | 'startingMarketRent' | 'endingMarketRent' | 'startingLossToLease' | 'endingLossToLease' | 'concessionMonths' | 'renewalAdjustmentPercentage' | 'renovationCost' | 'renovationPremium' | 'shortTermEffectiveAvgDailyRent'>
      )> }
    )> }
  ) }
);

export type UpdateTermLeaseMutationVariables = Exact<{
  id: Scalars['ID'];
  eventType: UnitEventType;
  eventStart: Scalars['DateTime'];
  eventEnd: Scalars['DateTime'];
  concessionMonths?: Maybe<Scalars['Decimal']>;
}>;


export type UpdateTermLeaseMutation = (
  { __typename?: 'Mutation' }
  & { updateUnitEvent: (
    { __typename?: 'UnitEventModel' }
    & Pick<UnitEventModel, 'id' | 'eventType' | 'eventStart' | 'eventEnd' | 'inPlaceRent' | 'concessionMonths' | 'renewalAdjustmentPercentage' | 'renovationCost' | 'renovationPremium' | 'shortTermEffectiveAvgDailyRent'>
  ) }
);

export type CreateUnitEventMutationVariables = Exact<{
  propertyId: Scalars['String'];
  budgetYear: Scalars['Int'];
  unitId: Scalars['ID'];
  eventType: UnitEventType;
  eventStart: Scalars['DateTime'];
  eventEnd: Scalars['DateTime'];
  concessionMonths?: Maybe<Scalars['Decimal']>;
  inPlaceRent?: Maybe<Scalars['Decimal']>;
  renewalAdjustmentPercentage?: Maybe<Scalars['Decimal']>;
  shortTermEffectiveAvgDailyRent?: Maybe<Scalars['Decimal']>;
  renovationCost?: Maybe<Scalars['Decimal']>;
  renovationPremium?: Maybe<Scalars['Decimal']>;
}>;


export type CreateUnitEventMutation = (
  { __typename?: 'Mutation' }
  & { createUnitEvent: (
    { __typename?: 'UnitEventModel' }
    & Pick<UnitEventModel, 'id' | 'eventType' | 'eventStart' | 'eventEnd' | 'concessionMonths' | 'inPlaceRent' | 'renewalAdjustmentPercentage' | 'shortTermEffectiveAvgDailyRent' | 'renovationCost' | 'renovationPremium'>
  ) }
);

export type EnrichPossibleUnitEventQueryVariables = Exact<{
  propertyId: Scalars['String'];
  budgetYear: Scalars['Int'];
  unitId: Scalars['ID'];
  eventType: UnitEventType;
  eventStart: Scalars['DateTime'];
  eventEnd: Scalars['DateTime'];
  concessionMonths?: Maybe<Scalars['Decimal']>;
  inPlaceRent?: Maybe<Scalars['Decimal']>;
  renewalAdjustmentPercentage?: Maybe<Scalars['Decimal']>;
  shortTermEffectiveAvgDailyRent?: Maybe<Scalars['Decimal']>;
  renovationCost?: Maybe<Scalars['Decimal']>;
  renovationPremium?: Maybe<Scalars['Decimal']>;
}>;


export type EnrichPossibleUnitEventQuery = (
  { __typename?: 'Query' }
  & { enrichPossibleUnitEvent: (
    { __typename?: 'FakeEnrichedUnitEventModel' }
    & Pick<FakeEnrichedUnitEventModel, 'propertyId' | 'unitId' | 'eventType' | 'eventStart' | 'eventEnd' | 'inPlaceRent' | 'startingCalculatedInPlaceRent' | 'endingCalculatedInPlaceRent' | 'startingMarketRent' | 'endingMarketRent' | 'startingLossToLease' | 'endingLossToLease'>
  ) }
);

export type UpdateUnitEventMutationVariables = Exact<{
  id: Scalars['ID'];
  eventType: UnitEventType;
  eventStart: Scalars['DateTime'];
  eventEnd: Scalars['DateTime'];
  concessionMonths?: Maybe<Scalars['Decimal']>;
  inPlaceRent?: Maybe<Scalars['Decimal']>;
  renewalAdjustmentPercentage?: Maybe<Scalars['Decimal']>;
  shortTermEffectiveAvgDailyRent?: Maybe<Scalars['Decimal']>;
  renovationCost?: Maybe<Scalars['Decimal']>;
  renovationPremium?: Maybe<Scalars['Decimal']>;
}>;


export type UpdateUnitEventMutation = (
  { __typename?: 'Mutation' }
  & { updateUnitEvent: (
    { __typename?: 'UnitEventModel' }
    & Pick<UnitEventModel, 'id' | 'eventType' | 'eventStart' | 'eventEnd' | 'concessionMonths' | 'inPlaceRent' | 'renewalAdjustmentPercentage' | 'shortTermEffectiveAvgDailyRent' | 'renovationCost' | 'renovationPremium'>
  ) }
);

export type ListUnitsAndMarketRentsQueryVariables = Exact<{
  propertyId: Scalars['String'];
  page: Scalars['Int'];
  take: Scalars['Int'];
  reforecastYear: Scalars['Int'];
  budgetYear: Scalars['Int'];
}>;


export type ListUnitsAndMarketRentsQuery = (
  { __typename?: 'Query' }
  & { listUnits: (
    { __typename?: 'TraversablePaginatedUnitModel' }
    & Pick<TraversablePaginatedUnitModel, 'page' | 'take' | 'maxPage' | 'total'>
    & { items: Array<(
      { __typename?: 'UnitModel' }
      & Pick<UnitModel, 'id' | 'number' | 'unitTypeId'>
      & { reforecastMarketRents?: Maybe<(
        { __typename?: 'UnitLevelMarketRentModel' }
        & Pick<UnitLevelMarketRentModel, 'unitId' | 'year' | 'rawMarketRentsActuals' | 'rawMarketRentsReforecast' | 'rawMarketRentsBudget' | 'adjustedMarketRentsActuals' | 'adjustedMarketRentsReforecast' | 'adjustedMarketRentsBudget' | 'proratedRenovationPremiums'>
      )>, budgetMarketRents?: Maybe<(
        { __typename?: 'UnitLevelMarketRentModel' }
        & Pick<UnitLevelMarketRentModel, 'unitId' | 'year' | 'rawMarketRentsActuals' | 'rawMarketRentsReforecast' | 'rawMarketRentsBudget' | 'adjustedMarketRentsActuals' | 'adjustedMarketRentsReforecast' | 'adjustedMarketRentsBudget' | 'proratedRenovationPremiums'>
      )> }
    )> }
  ) }
);

export type SetMarketRentMutationVariables = Exact<{
  unitId: Scalars['ID'];
  year: Scalars['Int'];
  month: Scalars['Int'];
  type: VersionType;
  marketRent: Scalars['Decimal'];
}>;


export type SetMarketRentMutation = (
  { __typename?: 'Mutation' }
  & { setMarketRent: (
    { __typename?: 'UnitLevelMarketRentModel' }
    & Pick<UnitLevelMarketRentModel, 'unitId' | 'year' | 'rawMarketRentsActuals' | 'rawMarketRentsReforecast' | 'rawMarketRentsBudget' | 'adjustedMarketRentsActuals' | 'adjustedMarketRentsReforecast' | 'adjustedMarketRentsBudget' | 'proratedRenovationPremiums'>
  ) }
);

export type GetUnitLevelPropertyMetricsQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  chartOfAccountsId: Scalars['ID'];
}>;


export type GetUnitLevelPropertyMetricsQuery = (
  { __typename?: 'Query' }
  & { unitLevelPropertyMetrics: Array<(
    { __typename?: 'UnitLevelPropertyMetricsModel' }
    & Pick<UnitLevelPropertyMetricsModel, 'propertyId' | 'unitCount' | 'valuesType' | 'averageMarketRent' | 'expirations' | 'monthToMonthMoveOuts' | 'moveOutPercentage' | 'moveOuts' | 'totalMoveOuts' | 'occupancy' | 'occupiedUnits' | 'renewalIncrease' | 'renewalRatio' | 'renewals'>
  )>, unitLevelPropertyFinancialMetrics: Array<(
    { __typename?: 'UnitLevelPropertyFinancialMetricsModel' }
    & Pick<UnitLevelPropertyFinancialMetricsModel, 'accountName' | 'order' | 'budgetValues' | 'reforecastValues'>
  )> }
);

export type AddUnitTypeMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  name: Scalars['String'];
}>;


export type AddUnitTypeMutation = (
  { __typename?: 'Mutation' }
  & { addUnitType: (
    { __typename?: 'UnitTypeViewModel' }
    & Pick<UnitTypeViewModel, 'id' | 'name' | 'propertyId' | 'createdAt' | 'budgetYear'>
  ) }
);

export type DeleteUnitTypeMutationVariables = Exact<{
  unitTypeId: Scalars['ID'];
}>;


export type DeleteUnitTypeMutation = (
  { __typename?: 'Mutation' }
  & { deleteUnitType: (
    { __typename?: 'UnitTypeViewModel' }
    & Pick<UnitTypeViewModel, 'id' | 'name' | 'propertyId' | 'createdAt' | 'budgetYear'>
  ) }
);

export type GetUnitTypeDependenciesQueryVariables = Exact<{
  unitTypeId: Scalars['ID'];
  budgetYear: Scalars['Int'];
}>;


export type GetUnitTypeDependenciesQuery = (
  { __typename?: 'Query' }
  & { renovationPackagesByUnitType: Array<(
    { __typename?: 'RenovationPackageModel' }
    & Pick<RenovationPackageModel, 'name'>
  )> }
);

export type GetUnitTypePmsActualsWithOverridesYearQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  unitTypeId: Scalars['ID'];
}>;


export type GetUnitTypePmsActualsWithOverridesYearQuery = (
  { __typename?: 'Query' }
  & { queryUnitTypePMSActualsWithOverridesYear?: Maybe<(
    { __typename?: 'UnitTypeForPMSActualOverridesModel' }
    & Pick<UnitTypeForPmsActualOverridesModel, 'propertyId' | 'unitTypeId' | 'unitTypeName'>
    & { unitCount: (
      { __typename?: 'UnitTypePMSActualOverrideYearModel' }
      & Pick<UnitTypePmsActualOverrideYearModel, 'unitTypeId' | 'unitTypeName' | 'originals' | 'overrides'>
    ), inPlaceRent: (
      { __typename?: 'UnitTypePMSActualOverrideYearModel' }
      & Pick<UnitTypePmsActualOverrideYearModel, 'unitTypeId' | 'unitTypeName' | 'originals' | 'overrides'>
    ), occupancy: (
      { __typename?: 'UnitTypePMSActualOverrideYearModel' }
      & Pick<UnitTypePmsActualOverrideYearModel, 'unitTypeId' | 'unitTypeName' | 'originals' | 'overrides'>
    ) }
  )> }
);

export type ListUnitTypesQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  monthIndex: Scalars['Int'];
}>;


export type ListUnitTypesQuery = (
  { __typename?: 'Query' }
  & { listUnitTypes: Array<(
    { __typename?: 'UnitTypeExtViewModel' }
    & Pick<UnitTypeExtViewModel, 'id' | 'name' | 'propertyId' | 'createdAt' | 'budgetYear' | 'inUse'>
  )>, queryPropertyPMSActualsWithOverridesMonth?: Maybe<(
    { __typename?: 'PropertyForPMSActualOverridesModel' }
    & Pick<PropertyForPmsActualOverridesModel, 'propertyId' | 'monthIndex'>
    & { unitCount: Array<(
      { __typename?: 'UnitTypePMSActualOverrideMonthModel' }
      & Pick<UnitTypePmsActualOverrideMonthModel, 'unitTypeId' | 'unitTypeName' | 'original' | 'override'>
    )>, inPlaceRent: Array<(
      { __typename?: 'UnitTypePMSActualOverrideMonthModel' }
      & Pick<UnitTypePmsActualOverrideMonthModel, 'unitTypeId' | 'unitTypeName' | 'original' | 'override'>
    )>, occupancy: Array<(
      { __typename?: 'UnitTypePMSActualOverrideMonthModel' }
      & Pick<UnitTypePmsActualOverrideMonthModel, 'unitTypeId' | 'unitTypeName' | 'original' | 'override'>
    )> }
  )> }
);

export type SetUnitTypePmsOverridesMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  unitCount?: Maybe<Array<UnitTypeForPmsActualOverridesUpdateInput> | UnitTypeForPmsActualOverridesUpdateInput>;
  inPlaceRent?: Maybe<Array<UnitTypeForPmsActualOverridesUpdateInput> | UnitTypeForPmsActualOverridesUpdateInput>;
  occupancy?: Maybe<Array<UnitTypeForPmsActualOverridesUpdateInput> | UnitTypeForPmsActualOverridesUpdateInput>;
}>;


export type SetUnitTypePmsOverridesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setUnitTypePMSOverrides'>
);

export type UpdateUnitTypeMutationVariables = Exact<{
  unitTypeId: Scalars['ID'];
  name: Scalars['String'];
}>;


export type UpdateUnitTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateUnitType: (
    { __typename?: 'UnitTypeViewModel' }
    & Pick<UnitTypeViewModel, 'id' | 'name' | 'propertyId' | 'createdAt' | 'budgetYear'>
  ) }
);

export type LoadChartOfAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type LoadChartOfAccountsQuery = (
  { __typename?: 'Query' }
  & { listChartOfAccounts: (
    { __typename?: 'COAListResponse' }
    & Pick<CoaListResponse, 'versionNum'>
    & { records: Array<(
      { __typename?: 'COAListRecord' }
      & Pick<CoaListRecord, 'id' | 'glNumber' | 'glName' | 'type' | 'order' | 'parentIds'>
    )> }
  ) }
);

export type QueryAccountPercentageDriverCyclesQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  versionType: VersionType;
  year: Scalars['Int'];
  destinationAccountIds: Array<Scalars['ID']> | Scalars['ID'];
  sourceAccountIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type QueryAccountPercentageDriverCyclesQuery = (
  { __typename?: 'Query' }
  & { queryAccountPercentageDriverCycles: Array<(
    { __typename?: 'AccountPercentageDriverCycleModel' }
    & Pick<AccountPercentageDriverCycleModel, 'accountIds'>
  )> }
);

export type GetUnitTypesPmsActualsOverridesFullQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  year: Scalars['Int'];
}>;


export type GetUnitTypesPmsActualsOverridesFullQuery = (
  { __typename?: 'Query' }
  & { queryUnitTypesPMSActualsOverridesFull: Array<(
    { __typename?: 'UnitTypeForPMSActualOverridesFullModel' }
    & Pick<UnitTypeForPmsActualOverridesFullModel, 'propertyId' | 'unitTypeId' | 'unitTypeName' | 'unitCount' | 'inPlaceRent' | 'occupancy'>
  )> }
);

export type GetAverageRentQueryVariables = Exact<{
  propertyId: Scalars['String'];
  year: Scalars['Int'];
  budgetYear: Scalars['Int'];
  versionYear: Scalars['Int'];
  versionType?: Maybe<VersionType>;
}>;


export type GetAverageRentQuery = (
  { __typename?: 'Query' }
  & { actuals: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'unitCount' | 'averageMarketRent'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )>, budget: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'unitCount' | 'averageMarketRent'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )>, currentVersion: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'unitCount' | 'averageMarketRent'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type UpdateOriginalRevenueMarketRentModeMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  originalRevenueMarketRentModeType: OriginalRevenueMarketRentModeType;
}>;


export type UpdateOriginalRevenueMarketRentModeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setOriginalRevenueMarketRentMode'>
);

export type GetOriginalRevenueMarketRentDataQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  versionType: VersionType;
}>;


export type GetOriginalRevenueMarketRentDataQuery = (
  { __typename?: 'Query' }
  & { originalRevenueMarketRentAdjustments: (
    { __typename?: 'OriginalRevenueMarketRentAdjustmentsModel' }
    & Pick<OriginalRevenueMarketRentAdjustmentsModel, 'propertyId' | 'versionType' | 'year' | 'adjustments' | 'adjustmentTypes'>
  ), originalRevenueMarketRentOverrides: Array<(
    { __typename?: 'OriginalRevenueUnitTypeMarketRentOverridesModel' }
    & Pick<OriginalRevenueUnitTypeMarketRentOverridesModel, 'unitTypeId' | 'unitTypeName' | 'propertyId' | 'overrides'>
  )>, originalRevenueMarketRentValues: Array<(
    { __typename?: 'OriginalRevenueUnitTypeMarketRentValuesModel' }
    & Pick<OriginalRevenueUnitTypeMarketRentValuesModel, 'unitTypeId' | 'unitTypeName' | 'propertyId' | 'actuals' | 'reforecast' | 'budget'>
  )> }
);

export type UpdateOriginalRevenueMarketRentDataMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  versionType: VersionType;
  adjM0?: Maybe<Scalars['Float']>;
  adjM1?: Maybe<Scalars['Float']>;
  adjM2?: Maybe<Scalars['Float']>;
  adjM3?: Maybe<Scalars['Float']>;
  adjM4?: Maybe<Scalars['Float']>;
  adjM5?: Maybe<Scalars['Float']>;
  adjM6?: Maybe<Scalars['Float']>;
  adjM7?: Maybe<Scalars['Float']>;
  adjM8?: Maybe<Scalars['Float']>;
  adjM9?: Maybe<Scalars['Float']>;
  adjM10?: Maybe<Scalars['Float']>;
  adjM11?: Maybe<Scalars['Float']>;
  adjTpM0?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjTpM1?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjTpM2?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjTpM3?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjTpM4?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjTpM5?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjTpM6?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjTpM7?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjTpM8?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjTpM9?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjTpM10?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  adjTpM11?: Maybe<OriginalRevenueMarketRentAdjustmentType>;
  unitTypeOverrides: Array<UnitTypeWithOriginalrevenueMarketRentOverridesGranularInput> | UnitTypeWithOriginalrevenueMarketRentOverridesGranularInput;
}>;


export type UpdateOriginalRevenueMarketRentDataMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setOriginalRevenueMarketRentAdjustmentsGranular' | 'setOriginalRevenueMarketRentOverridesGranular'>
);

export type GetEarlyTerminationsQueryVariables = Exact<{
  propertyId: Scalars['String'];
  year: Scalars['Int'];
  versionYear: Scalars['Int'];
  budgetYear: Scalars['Int'];
  versionType?: Maybe<VersionType>;
}>;


export type GetEarlyTerminationsQuery = (
  { __typename?: 'Query' }
  & { actuals: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { operationalMetrics: Array<(
      { __typename?: 'OperationalMetric' }
      & Pick<OperationalMetric, 'id' | 'monthIndex' | 'unitCount' | 'earlyTerminationCount'>
    )> }
  )>, budget: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { operationalMetrics: Array<(
      { __typename?: 'OperationalMetric' }
      & Pick<OperationalMetric, 'id' | 'monthIndex' | 'unitCount' | 'earlyTerminationCount'>
    )> }
  )>, currentVersion: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { operationalMetrics: Array<(
      { __typename?: 'OperationalMetric' }
      & Pick<OperationalMetric, 'id' | 'monthIndex' | 'unitCount' | 'earlyTerminationCount'>
    )> }
  )>, reforecastVersion: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { operationalMetrics: Array<(
      { __typename?: 'OperationalMetric' }
      & Pick<OperationalMetric, 'id' | 'monthIndex' | 'earlyTerminationCount' | 'unitCount'>
    )> }
  )> }
);

export type GetLeaseExpirationCountQueryVariables = Exact<{
  propertyId: Scalars['String'];
  year: Scalars['Int'];
  budgetYear: Scalars['Int'];
  versionYear: Scalars['Int'];
  versionType?: Maybe<VersionType>;
}>;


export type GetLeaseExpirationCountQuery = (
  { __typename?: 'Query' }
  & { actuals: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'unitCount' | 'leaseExpirationCount'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )>, currentVersion: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'unitCount' | 'leaseExpirationCount'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type GetLeaseExpirationCountsForRewriteQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  versionType: VersionType;
}>;


export type GetLeaseExpirationCountsForRewriteQuery = (
  { __typename?: 'Query' }
  & { queryPMSLeaseExpirationCountsForPropertyAndPeriod: Array<(
    { __typename?: 'PMSLeaseExpirationCountModel' }
    & Pick<PmsLeaseExpirationCountModel, 'unitTypeId' | 'unitTypeName' | 'values'>
  )>, queryForecastedLeaseExpirationCountsForPropertyAndPeriod: Array<(
    { __typename?: 'ForecastedLeaseExpirationCountModel' }
    & Pick<ForecastedLeaseExpirationCountModel, 'unitTypeId' | 'unitTypeName' | 'values'>
  )> }
);

export type MassUpdateLeaseExpirationCountsForPropertyAndPeriodMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  versionType: VersionType;
  updates: Array<LeaseExpirationCountsInput> | LeaseExpirationCountsInput;
}>;


export type MassUpdateLeaseExpirationCountsForPropertyAndPeriodMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'massUpdateLeaseExpirationCountsForPropertyAndPeriod'>
);

export type CalculateCurveExpirationsQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  curve: Array<Scalars['Decimal']> | Scalars['Decimal'];
}>;


export type CalculateCurveExpirationsQuery = (
  { __typename?: 'Query' }
  & { calculateExpirationCurveExpirations: Array<(
    { __typename?: 'OriginalRevenueUnitTypeCalculatedExpirationsModel' }
    & Pick<OriginalRevenueUnitTypeCalculatedExpirationsModel, 'propertyId' | 'unitTypeId' | 'unitTypeName' | 'reforecastExpirations' | 'budgetExpirations'>
  )> }
);

export type GetExpirationCurveQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
}>;


export type GetExpirationCurveQuery = (
  { __typename?: 'Query' }
  & { expirationCurve?: Maybe<(
    { __typename?: 'OriginalRevenueExpirationCurveModel' }
    & Pick<OriginalRevenueExpirationCurveModel, 'propertyId' | 'budgetYear' | 'curve'>
  )> }
);

export type SetExpirationCurveMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  curve: Array<Scalars['Decimal']> | Scalars['Decimal'];
}>;


export type SetExpirationCurveMutation = (
  { __typename?: 'Mutation' }
  & { setExpirationCurve: (
    { __typename?: 'OriginalRevenueExpirationCurveModel' }
    & Pick<OriginalRevenueExpirationCurveModel, 'propertyId' | 'budgetYear' | 'curve'>
  ) }
);

export type GetMonthToMonthMoveOutsQueryVariables = Exact<{
  propertyId: Scalars['String'];
  year: Scalars['Int'];
  versionYear: Scalars['Int'];
  budgetYear: Scalars['Int'];
  versionType?: Maybe<VersionType>;
}>;


export type GetMonthToMonthMoveOutsQuery = (
  { __typename?: 'Query' }
  & { actuals: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { operationalMetrics: Array<(
      { __typename?: 'OperationalMetric' }
      & Pick<OperationalMetric, 'id' | 'monthIndex' | 'monthToMonthMoveOutCount' | 'unitCount'>
    )> }
  )>, budget: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { operationalMetrics: Array<(
      { __typename?: 'OperationalMetric' }
      & Pick<OperationalMetric, 'id' | 'monthIndex' | 'monthToMonthMoveOutCount' | 'unitCount'>
    )> }
  )>, currentVersion: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { operationalMetrics: Array<(
      { __typename?: 'OperationalMetric' }
      & Pick<OperationalMetric, 'id' | 'monthIndex' | 'monthToMonthMoveOutCount' | 'unitCount'>
    )> }
  )>, reforecastVersion: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { operationalMetrics: Array<(
      { __typename?: 'OperationalMetric' }
      & Pick<OperationalMetric, 'id' | 'monthIndex' | 'monthToMonthMoveOutCount' | 'unitCount'>
    )> }
  )> }
);

export type GetMoveOutRatesQueryVariables = Exact<{
  propertyId: Scalars['String'];
  year: Scalars['Int'];
  versionYear: Scalars['Int'];
  budgetYear: Scalars['Int'];
  versionType?: Maybe<VersionType>;
}>;


export type GetMoveOutRatesQuery = (
  { __typename?: 'Query' }
  & { actuals: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'moveOutRate' | 'leaseExpirationCount' | 'unitCount'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )>, budget: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'moveOutRate' | 'leaseExpirationCount' | 'unitCount'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )>, currentVersion: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'moveOutRate' | 'leaseExpirationCount' | 'unitCount'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )>, reforecastVersion: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'moveOutRate' | 'leaseExpirationCount' | 'unitCount'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type GetOccupancyRatesQueryVariables = Exact<{
  propertyId: Scalars['String'];
  year: Scalars['Int'];
  versionYear: Scalars['Int'];
  budgetYear: Scalars['Int'];
  versionType?: Maybe<VersionType>;
}>;


export type GetOccupancyRatesQuery = (
  { __typename?: 'Query' }
  & { actuals: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'occupancyRate' | 'unitCount'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )>, budget: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'occupancyRate' | 'unitCount'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )>, currentVersion: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'occupancyRate' | 'unitCount'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )>, reforecastVersion: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'occupancyRate' | 'unitCount'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type GetOriginalRevenuePropertyRenewalIncreasePercentQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
}>;


export type GetOriginalRevenuePropertyRenewalIncreasePercentQuery = (
  { __typename?: 'Query' }
  & { originalRevenuePropertyRenewalIncreasePercent: (
    { __typename?: 'OriginalRevenuePropertyRenewalIncreasePercentModel' }
    & Pick<OriginalRevenuePropertyRenewalIncreasePercentModel, 'type' | 'percent'>
  ) }
);

export type SetOriginalRevenuePropertyRenewalIncreasePercentMutationVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
  type?: Maybe<OriginalRevenueRenewalIncreaseType>;
  percent?: Maybe<Scalars['Float']>;
}>;


export type SetOriginalRevenuePropertyRenewalIncreasePercentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setOriginalRevenuePropertyRenewalIncreasePercent'>
);

export type GetCappedRenewalIncreaseQueryVariables = Exact<{
  propertyId: Scalars['String'];
  thisYearM3: Scalars['Int'];
  thisYearM2: Scalars['Int'];
  thisYearM1: Scalars['Int'];
  thisYear: Scalars['Int'];
  nextYear: Scalars['Int'];
}>;


export type GetCappedRenewalIncreaseQuery = (
  { __typename?: 'Query' }
  & { property?: Maybe<(
    { __typename?: 'Property' }
    & { unitTypes: Array<(
      { __typename?: 'UnitType' }
      & Pick<UnitType, 'id' | 'name' | 'budgetYear'>
      & { nextYearBudget: (
        { __typename?: 'RenewalIncreaseAmountAndPercent' }
        & Pick<RenewalIncreaseAmountAndPercent, 'year' | 'versionType' | 'amounts' | 'percents' | 'inPlaceRents'>
      ), thisYearReforecast: (
        { __typename?: 'RenewalIncreaseAmountAndPercent' }
        & Pick<RenewalIncreaseAmountAndPercent, 'year' | 'versionType' | 'amounts' | 'percents' | 'inPlaceRents'>
      ), thisYearActuals: (
        { __typename?: 'RenewalIncreaseAmountAndPercent' }
        & Pick<RenewalIncreaseAmountAndPercent, 'year' | 'versionType' | 'amounts' | 'percents' | 'inPlaceRents'>
      ) }
    )>, nextYearBudget: (
      { __typename?: 'RenewalIncreaseAmountAndPercent' }
      & Pick<RenewalIncreaseAmountAndPercent, 'year' | 'versionType' | 'amounts' | 'percents' | 'inPlaceRents'>
    ), thisYearBudget: (
      { __typename?: 'RenewalIncreaseAmountAndPercent' }
      & Pick<RenewalIncreaseAmountAndPercent, 'year' | 'versionType' | 'amounts' | 'percents' | 'inPlaceRents'>
    ), thisYearReforecast: (
      { __typename?: 'RenewalIncreaseAmountAndPercent' }
      & Pick<RenewalIncreaseAmountAndPercent, 'year' | 'versionType' | 'amounts' | 'percents' | 'inPlaceRents'>
    ), thisYearActuals: (
      { __typename?: 'RenewalIncreaseAmountAndPercent' }
      & Pick<RenewalIncreaseAmountAndPercent, 'year' | 'versionType' | 'amounts' | 'percents' | 'inPlaceRents'>
    ), thisYearM1Actuals: (
      { __typename?: 'RenewalIncreaseAmountAndPercent' }
      & Pick<RenewalIncreaseAmountAndPercent, 'year' | 'versionType' | 'amounts' | 'percents' | 'inPlaceRents'>
    ), thisYearM2Actuals: (
      { __typename?: 'RenewalIncreaseAmountAndPercent' }
      & Pick<RenewalIncreaseAmountAndPercent, 'year' | 'versionType' | 'amounts' | 'percents' | 'inPlaceRents'>
    ), thisYearM3Actuals: (
      { __typename?: 'RenewalIncreaseAmountAndPercent' }
      & Pick<RenewalIncreaseAmountAndPercent, 'year' | 'versionType' | 'amounts' | 'percents' | 'inPlaceRents'>
    ) }
  )> }
);

export type GetRenewalRateCountsQueryVariables = Exact<{
  propertyId: Scalars['String'];
  year: Scalars['Int'];
  versionYear: Scalars['Int'];
  budgetYear: Scalars['Int'];
  versionType?: Maybe<VersionType>;
}>;


export type GetRenewalRateCountsQuery = (
  { __typename?: 'Query' }
  & { actuals: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'renewalRate' | 'leaseExpirationCount' | 'renewalRatio' | 'unitCount'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )>, budget: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'renewalRate' | 'leaseExpirationCount' | 'renewalRatio' | 'unitCount'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )>, currentVersion: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'renewalRate' | 'leaseExpirationCount' | 'renewalRatio' | 'unitCount'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )>, reforecastVersion: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'renewalRate' | 'leaseExpirationCount' | 'renewalRatio' | 'unitCount'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type GetRenewalRatioCountQueryVariables = Exact<{
  propertyId: Scalars['String'];
  year: Scalars['Int'];
  versionYear: Scalars['Int'];
  budgetYear: Scalars['Int'];
  versionType?: Maybe<VersionType>;
}>;


export type GetRenewalRatioCountQuery = (
  { __typename?: 'Query' }
  & { actuals: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'renewalRatio' | 'leaseExpirationCount' | 'unitCount'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )>, budget: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'renewalRatio' | 'leaseExpirationCount' | 'unitCount'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )>, currentVersion: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'renewalRatio' | 'leaseExpirationCount' | 'unitCount'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )>, reforecastVersion: Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'renewalRatio' | 'leaseExpirationCount' | 'unitCount'>
      & { unitType: (
        { __typename?: 'UnitType' }
        & Pick<UnitType, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type GetRevenueSummaryUnitTypeQueryVariables = Exact<{
  propertyId: Scalars['ID'];
  budgetYear: Scalars['Int'];
}>;


export type GetRevenueSummaryUnitTypeQuery = (
  { __typename?: 'Query' }
  & { originalRevenueModel?: Maybe<(
    { __typename?: 'OriginalRevenueModel' }
    & Pick<OriginalRevenueModel, 'propertyId' | 'budgetYear' | 'beginningGrossPotentialRent' | 'beginningOccupancy'>
    & { earlyTerminationCount: (
      { __typename?: 'OriginalRevenueForecastedNumbersModel' }
      & Pick<OriginalRevenueForecastedNumbersModel, 'actuals' | 'reforecast' | 'budget'>
    ), grossPotentialRent: (
      { __typename?: 'OriginalRevenueForecastedNumbersModel' }
      & Pick<OriginalRevenueForecastedNumbersModel, 'actuals' | 'reforecast' | 'budget'>
    ), grossPotentialRentAndVacancyLoss: (
      { __typename?: 'OriginalRevenueForecastedNumbersModel' }
      & Pick<OriginalRevenueForecastedNumbersModel, 'actuals' | 'reforecast' | 'budget'>
    ), lossToLease: (
      { __typename?: 'OriginalRevenueForecastedNumbersModel' }
      & Pick<OriginalRevenueForecastedNumbersModel, 'actuals' | 'reforecast' | 'budget'>
    ), m2mMoveOutCount: (
      { __typename?: 'OriginalRevenueForecastedNumbersModel' }
      & Pick<OriginalRevenueForecastedNumbersModel, 'actuals' | 'reforecast' | 'budget'>
    ), momLossToLeaseChange: (
      { __typename?: 'OriginalRevenueForecastedNumbersModel' }
      & Pick<OriginalRevenueForecastedNumbersModel, 'actuals' | 'reforecast' | 'budget'>
    ), rent: (
      { __typename?: 'OriginalRevenueForecastedNumbersModel' }
      & Pick<OriginalRevenueForecastedNumbersModel, 'actuals' | 'reforecast' | 'budget'>
    ), vacancyLoss: (
      { __typename?: 'OriginalRevenueForecastedNumbersModel' }
      & Pick<OriginalRevenueForecastedNumbersModel, 'actuals' | 'reforecast' | 'budget'>
    ), unitTypeAvgMarketRent: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumbersModel' }
      & Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeBeginningOccupancy: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumberModel' }
      & Pick<OriginalRevenueUnitTypeNumberModel, 'unitTypeId' | 'unitTypeName' | 'value'>
    )>, unitTypeExpirationCount: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumbersModel' }
      & Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeInPlaceRent: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumbersModel' }
      & Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, totalMoveInCount: (
      { __typename?: 'OriginalRevenueForecastedNumbersModel' }
      & Pick<OriginalRevenueForecastedNumbersModel, 'actuals' | 'reforecast' | 'budget'>
    ), totalMoveOutCount: (
      { __typename?: 'OriginalRevenueForecastedNumbersModel' }
      & Pick<OriginalRevenueForecastedNumbersModel, 'actuals' | 'reforecast' | 'budget'>
    ), unitTypeMoveInCount: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumbersModel' }
      & Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeMoveOutCount: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumbersModel' }
      & Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeNewLeaseTradeOut: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumbersModel' }
      & Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeOccupiedCount: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumbersModel' }
      & Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeRenewalCount: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumbersModel' }
      & Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeRenewalTradeOut: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumbersModel' }
      & Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeStartingInPlaceRent: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumberModel' }
      & Pick<OriginalRevenueUnitTypeNumberModel, 'unitTypeId' | 'unitTypeName' | 'value'>
    )>, unitTypeUnitCount: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumberModel' }
      & Pick<OriginalRevenueUnitTypeNumberModel, 'unitTypeId' | 'unitTypeName' | 'value'>
    )>, unitTypeUnitCountMonthly: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumbersModel' }
      & Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )>, unitTypeVacancyCount: Array<(
      { __typename?: 'OriginalRevenueUnitTypeNumbersModel' }
      & Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>
    )> }
  )> }
);

export type UpsertOpMetricValuesMutationVariables = Exact<{
  propertyId: Scalars['String'];
  versionId: Scalars['String'];
  fieldName: Scalars['String'];
  monthIndexes: Array<Scalars['Int']> | Scalars['Int'];
  values: Array<Scalars['Decimal']> | Scalars['Decimal'];
}>;


export type UpsertOpMetricValuesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertOpMetricValues'>
);

export type UpsertUnitTypeScheduleValuesMutationVariables = Exact<{
  propertyId: Scalars['String'];
  versionId: Scalars['String'];
  fieldName: Scalars['String'];
  values: Array<UnitTypeScheduleValueUpsertInput> | UnitTypeScheduleValueUpsertInput;
}>;


export type UpsertUnitTypeScheduleValuesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertUnitTypeScheduleValues'>
);

export type AcctRenovationPackagesQueryVariables = Exact<{
  propertyIds: Array<Scalars['String']> | Scalars['String'];
  yearAndType: YearAndType;
}>;


export type AcctRenovationPackagesQuery = (
  { __typename?: 'Query' }
  & { queryRenovationPackages: Array<(
    { __typename?: 'RenovationPackageModel' }
    & Pick<RenovationPackageModel, 'id' | 'name' | 'premium' | 'propertyId' | 'unitTypeId'>
    & { renovationNumbers: Array<(
      { __typename?: 'RenovationNumbersModel' }
      & Pick<RenovationNumbersModel, 'year' | 'versionType'>
      & { expected: Array<(
        { __typename?: 'RenovationNumberModel' }
        & Pick<RenovationNumberModel, 'monthIndex' | 'value'>
      )> }
    )> }
  )> }
);

export type GetAcctRenoMoveOutRatesQueryVariables = Exact<{
  propertyId: Scalars['String'];
  year: Scalars['Int'];
  budgetYear: Scalars['Int'];
  unitTypeId: Scalars['String'];
  getReforecast: Scalars['Boolean'];
}>;


export type GetAcctRenoMoveOutRatesQuery = (
  { __typename?: 'Query' }
  & { actuals: Maybe<Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'moveOutRate' | 'leaseExpirationCount'>
    )> }
  )>>, reforecast: Maybe<Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'moveOutRate' | 'leaseExpirationCount'>
    )> }
  )>>, budget: Maybe<Array<(
    { __typename?: 'ValueVersion' }
    & Pick<ValueVersion, 'id' | 'name' | 'type' | 'year'>
    & { unitTypeScheduleValues: Array<(
      { __typename?: 'UnitTypeScheduleValue' }
      & Pick<UnitTypeScheduleValue, 'id' | 'monthIndex' | 'moveOutRate' | 'leaseExpirationCount'>
    )> }
  )>> }
);

export type UpdateRenovationPackageCountMutationVariables = Exact<{
  renovationPackageId: Scalars['String'];
  yearAndType: YearAndType;
  expected: Array<RenovationNumberInput> | RenovationNumberInput;
}>;


export type UpdateRenovationPackageCountMutation = (
  { __typename?: 'Mutation' }
  & { updateRenovationsNumbersByYearAndType: (
    { __typename?: 'RenovationNumbersModel' }
    & Pick<RenovationNumbersModel, 'renovationPackageId' | 'year' | 'versionType'>
    & { expected: Array<(
      { __typename?: 'RenovationNumberModel' }
      & Pick<RenovationNumberModel, 'monthIndex' | 'value'>
    )> }
  ) }
);


export const UpdateAccPercentageDriverAssumptionsDocument = gql`
    mutation UpdateAccPercentageDriverAssumptions($items: [AccPercentageDriverAssumptionsUpdateInput!]!) {
  updateAccPercentageDriverAssumptions(items: $items) {
    propertyId
    year
    versionType
    destinationAccountId
    sourceAccountId
    delayRecognition
    percentMonth0
    percentMonth1
    percentMonth2
    percentMonth3
    percentMonth4
    percentMonth5
    percentMonth6
    percentMonth7
    percentMonth8
    percentMonth9
    percentMonth10
    percentMonth11
  }
}
    `;
export type UpdateAccPercentageDriverAssumptionsMutationFn = Apollo.MutationFunction<UpdateAccPercentageDriverAssumptionsMutation, UpdateAccPercentageDriverAssumptionsMutationVariables>;

/**
 * __useUpdateAccPercentageDriverAssumptionsMutation__
 *
 * To run a mutation, you first call `useUpdateAccPercentageDriverAssumptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccPercentageDriverAssumptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccPercentageDriverAssumptionsMutation, { data, loading, error }] = useUpdateAccPercentageDriverAssumptionsMutation({
 *   variables: {
 *      items: // value for 'items'
 *   },
 * });
 */
export function useUpdateAccPercentageDriverAssumptionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccPercentageDriverAssumptionsMutation, UpdateAccPercentageDriverAssumptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccPercentageDriverAssumptionsMutation, UpdateAccPercentageDriverAssumptionsMutationVariables>(UpdateAccPercentageDriverAssumptionsDocument, options);
      }
export type UpdateAccPercentageDriverAssumptionsMutationHookResult = ReturnType<typeof useUpdateAccPercentageDriverAssumptionsMutation>;
export type UpdateAccPercentageDriverAssumptionsMutationResult = Apollo.MutationResult<UpdateAccPercentageDriverAssumptionsMutation>;
export type UpdateAccPercentageDriverAssumptionsMutationOptions = Apollo.BaseMutationOptions<UpdateAccPercentageDriverAssumptionsMutation, UpdateAccPercentageDriverAssumptionsMutationVariables>;
export const BulkUpdateAccPercentageDriverAssumptionsDocument = gql`
    mutation BulkUpdateAccPercentageDriverAssumptions($item: AccPercentageDriverAssumptionsBulkUpdateInput!) {
  bulkUpdateAccPercentageDriverAssumptions(item: $item)
}
    `;
export type BulkUpdateAccPercentageDriverAssumptionsMutationFn = Apollo.MutationFunction<BulkUpdateAccPercentageDriverAssumptionsMutation, BulkUpdateAccPercentageDriverAssumptionsMutationVariables>;

/**
 * __useBulkUpdateAccPercentageDriverAssumptionsMutation__
 *
 * To run a mutation, you first call `useBulkUpdateAccPercentageDriverAssumptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateAccPercentageDriverAssumptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateAccPercentageDriverAssumptionsMutation, { data, loading, error }] = useBulkUpdateAccPercentageDriverAssumptionsMutation({
 *   variables: {
 *      item: // value for 'item'
 *   },
 * });
 */
export function useBulkUpdateAccPercentageDriverAssumptionsMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdateAccPercentageDriverAssumptionsMutation, BulkUpdateAccPercentageDriverAssumptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdateAccPercentageDriverAssumptionsMutation, BulkUpdateAccPercentageDriverAssumptionsMutationVariables>(BulkUpdateAccPercentageDriverAssumptionsDocument, options);
      }
export type BulkUpdateAccPercentageDriverAssumptionsMutationHookResult = ReturnType<typeof useBulkUpdateAccPercentageDriverAssumptionsMutation>;
export type BulkUpdateAccPercentageDriverAssumptionsMutationResult = Apollo.MutationResult<BulkUpdateAccPercentageDriverAssumptionsMutation>;
export type BulkUpdateAccPercentageDriverAssumptionsMutationOptions = Apollo.BaseMutationOptions<BulkUpdateAccPercentageDriverAssumptionsMutation, BulkUpdateAccPercentageDriverAssumptionsMutationVariables>;
export const UpdateOperationalDriverAssumptionsDocument = gql`
    mutation UpdateOperationalDriverAssumptions($items: [OperationalDriverAssumptionsUpdateInput!]!) {
  updateOperationalDriverAssumptions(items: $items) {
    propertyId
    year
    versionType
    destinationAccountId
    sourceMetricId
    delayRecognition
    percentMonth0
    percentMonth1
    percentMonth2
    percentMonth3
    percentMonth4
    percentMonth5
    percentMonth6
    percentMonth7
    percentMonth8
    percentMonth9
    percentMonth10
    percentMonth11
    amountMonth0
    amountMonth1
    amountMonth2
    amountMonth3
    amountMonth4
    amountMonth5
    amountMonth6
    amountMonth7
    amountMonth8
    amountMonth9
    amountMonth10
    amountMonth11
  }
}
    `;
export type UpdateOperationalDriverAssumptionsMutationFn = Apollo.MutationFunction<UpdateOperationalDriverAssumptionsMutation, UpdateOperationalDriverAssumptionsMutationVariables>;

/**
 * __useUpdateOperationalDriverAssumptionsMutation__
 *
 * To run a mutation, you first call `useUpdateOperationalDriverAssumptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOperationalDriverAssumptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOperationalDriverAssumptionsMutation, { data, loading, error }] = useUpdateOperationalDriverAssumptionsMutation({
 *   variables: {
 *      items: // value for 'items'
 *   },
 * });
 */
export function useUpdateOperationalDriverAssumptionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOperationalDriverAssumptionsMutation, UpdateOperationalDriverAssumptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOperationalDriverAssumptionsMutation, UpdateOperationalDriverAssumptionsMutationVariables>(UpdateOperationalDriverAssumptionsDocument, options);
      }
export type UpdateOperationalDriverAssumptionsMutationHookResult = ReturnType<typeof useUpdateOperationalDriverAssumptionsMutation>;
export type UpdateOperationalDriverAssumptionsMutationResult = Apollo.MutationResult<UpdateOperationalDriverAssumptionsMutation>;
export type UpdateOperationalDriverAssumptionsMutationOptions = Apollo.BaseMutationOptions<UpdateOperationalDriverAssumptionsMutation, UpdateOperationalDriverAssumptionsMutationVariables>;
export const SetCustomDriverAssumptionsDocument = gql`
    mutation SetCustomDriverAssumptions($propertyId: ID!, $destinationAccountId: ID!, $year: Int!, $versionType: VersionType!, $items: [CustomDriverItemUpdateInput!]!) {
  setCustomDriverAssumptions(
    propertyId: $propertyId
    destinationAccountId: $destinationAccountId
    year: $year
    versionType: $versionType
    items: $items
  )
}
    `;
export type SetCustomDriverAssumptionsMutationFn = Apollo.MutationFunction<SetCustomDriverAssumptionsMutation, SetCustomDriverAssumptionsMutationVariables>;

/**
 * __useSetCustomDriverAssumptionsMutation__
 *
 * To run a mutation, you first call `useSetCustomDriverAssumptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCustomDriverAssumptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCustomDriverAssumptionsMutation, { data, loading, error }] = useSetCustomDriverAssumptionsMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      destinationAccountId: // value for 'destinationAccountId'
 *      year: // value for 'year'
 *      versionType: // value for 'versionType'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useSetCustomDriverAssumptionsMutation(baseOptions?: Apollo.MutationHookOptions<SetCustomDriverAssumptionsMutation, SetCustomDriverAssumptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCustomDriverAssumptionsMutation, SetCustomDriverAssumptionsMutationVariables>(SetCustomDriverAssumptionsDocument, options);
      }
export type SetCustomDriverAssumptionsMutationHookResult = ReturnType<typeof useSetCustomDriverAssumptionsMutation>;
export type SetCustomDriverAssumptionsMutationResult = Apollo.MutationResult<SetCustomDriverAssumptionsMutation>;
export type SetCustomDriverAssumptionsMutationOptions = Apollo.BaseMutationOptions<SetCustomDriverAssumptionsMutation, SetCustomDriverAssumptionsMutationVariables>;
export const CreateEmployeeDocument = gql`
    mutation createEmployee($budgetYear: Int!, $firstName: String!, $lastName: String, $externalSystemEmployeeId: String, $type: PayrollModeledEmployeeType!, $classification: PayrollEmployeeClassification!, $positionId: ID!, $properties: [PayrollEmployeePropertyAttributionInput!]!, $startDate: DateTime!, $terminationDate: DateTime, $baseComp: PayrollCreateEmployeeBaseCompInput!, $overtime: [PayrollCreateEmployeeOvertimeInput!]!, $raises: [PayrollCreateEmployeeRaiseInput!]!, $bonuses: [PayrollCreateEmployeeBonusInput!]!, $items: [PayrollCreateEmployeeCompItemInput!]!) {
  createPayrollEmployeeForBudgetYear(
    budgetYear: $budgetYear
    firstName: $firstName
    lastName: $lastName
    externalSystemEmployeeId: $externalSystemEmployeeId
    type: $type
    classification: $classification
    positionId: $positionId
    propertyAttributions: $properties
    startDate: $startDate
    terminationDate: $terminationDate
    baseComp: $baseComp
    overtime: $overtime
    bonuses: $bonuses
    raises: $raises
    items: $items
  ) {
    id
  }
}
    `;
export type CreateEmployeeMutationFn = Apollo.MutationFunction<CreateEmployeeMutation, CreateEmployeeMutationVariables>;

/**
 * __useCreateEmployeeMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeMutation, { data, loading, error }] = useCreateEmployeeMutation({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      externalSystemEmployeeId: // value for 'externalSystemEmployeeId'
 *      type: // value for 'type'
 *      classification: // value for 'classification'
 *      positionId: // value for 'positionId'
 *      properties: // value for 'properties'
 *      startDate: // value for 'startDate'
 *      terminationDate: // value for 'terminationDate'
 *      baseComp: // value for 'baseComp'
 *      overtime: // value for 'overtime'
 *      raises: // value for 'raises'
 *      bonuses: // value for 'bonuses'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useCreateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmployeeMutation, CreateEmployeeMutationVariables>(CreateEmployeeDocument, options);
      }
export type CreateEmployeeMutationHookResult = ReturnType<typeof useCreateEmployeeMutation>;
export type CreateEmployeeMutationResult = Apollo.MutationResult<CreateEmployeeMutation>;
export type CreateEmployeeMutationOptions = Apollo.BaseMutationOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>;
export const DeleteEmployeesDocument = gql`
    mutation deleteEmployees($employeeIds: [ID!]!) {
  deletePayrollEmployees(ids: $employeeIds)
}
    `;
export type DeleteEmployeesMutationFn = Apollo.MutationFunction<DeleteEmployeesMutation, DeleteEmployeesMutationVariables>;

/**
 * __useDeleteEmployeesMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeesMutation, { data, loading, error }] = useDeleteEmployeesMutation({
 *   variables: {
 *      employeeIds: // value for 'employeeIds'
 *   },
 * });
 */
export function useDeleteEmployeesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmployeesMutation, DeleteEmployeesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmployeesMutation, DeleteEmployeesMutationVariables>(DeleteEmployeesDocument, options);
      }
export type DeleteEmployeesMutationHookResult = ReturnType<typeof useDeleteEmployeesMutation>;
export type DeleteEmployeesMutationResult = Apollo.MutationResult<DeleteEmployeesMutation>;
export type DeleteEmployeesMutationOptions = Apollo.BaseMutationOptions<DeleteEmployeesMutation, DeleteEmployeesMutationVariables>;
export const GetEmployeeDocument = gql`
    query getEmployee($employeeId: ID!) {
  getEmployee(id: $employeeId) {
    id
    type
    firstName
    lastName
    startDate
    terminationDate
    classification
    externalSystemEmployeeId
    propertyAttributions {
      property {
        id
        name
      }
      attributionPercentage
    }
    position {
      id
      name
    }
    baseComp {
      compensationType
      annualCompensation
      hourlyCompensation
      expectedHoursPerWeek
    }
    overtime {
      hourlyCompensation
      expectedHoursPerWeek
      effectiveStartDate
      effectiveEndDate
    }
    bonuses {
      frequency
      bonusType
      amount
      onetimeBonusDate
      bonusDay
      bonusMonth
      effectiveStartDate
      effectiveEndDate
    }
    raises {
      frequency
      raiseType
      amount
      onetimeRaiseDate
      raiseMonth
      raiseDay
      effectiveStartDate
      effectiveEndDate
    }
    compItems {
      compensationItem {
        id
        type
        customName
      }
      id
      calculationType
      costPerPayPeriod
      annualCostCap
      effectiveStartDate
      effectiveEndDate
    }
  }
}
    `;

/**
 * __useGetEmployeeQuery__
 *
 * To run a query within a React component, call `useGetEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetEmployeeQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeQuery, GetEmployeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeQuery, GetEmployeeQueryVariables>(GetEmployeeDocument, options);
      }
export function useGetEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeQuery, GetEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeQuery, GetEmployeeQueryVariables>(GetEmployeeDocument, options);
        }
export type GetEmployeeQueryHookResult = ReturnType<typeof useGetEmployeeQuery>;
export type GetEmployeeLazyQueryHookResult = ReturnType<typeof useGetEmployeeLazyQuery>;
export type GetEmployeeQueryResult = Apollo.QueryResult<GetEmployeeQuery, GetEmployeeQueryVariables>;
export const GetPayrollCompensationItemsDocument = gql`
    query getPayrollCompensationItems {
  listCompensationItems {
    id
    type
    customName
  }
}
    `;

/**
 * __useGetPayrollCompensationItemsQuery__
 *
 * To run a query within a React component, call `useGetPayrollCompensationItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrollCompensationItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrollCompensationItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPayrollCompensationItemsQuery(baseOptions?: Apollo.QueryHookOptions<GetPayrollCompensationItemsQuery, GetPayrollCompensationItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayrollCompensationItemsQuery, GetPayrollCompensationItemsQueryVariables>(GetPayrollCompensationItemsDocument, options);
      }
export function useGetPayrollCompensationItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayrollCompensationItemsQuery, GetPayrollCompensationItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayrollCompensationItemsQuery, GetPayrollCompensationItemsQueryVariables>(GetPayrollCompensationItemsDocument, options);
        }
export type GetPayrollCompensationItemsQueryHookResult = ReturnType<typeof useGetPayrollCompensationItemsQuery>;
export type GetPayrollCompensationItemsLazyQueryHookResult = ReturnType<typeof useGetPayrollCompensationItemsLazyQuery>;
export type GetPayrollCompensationItemsQueryResult = Apollo.QueryResult<GetPayrollCompensationItemsQuery, GetPayrollCompensationItemsQueryVariables>;
export const GetPayRolPositionsDocument = gql`
    query getPayRolPositions {
  listPayrollPositions {
    id
    name
  }
}
    `;

/**
 * __useGetPayRolPositionsQuery__
 *
 * To run a query within a React component, call `useGetPayRolPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayRolPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayRolPositionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPayRolPositionsQuery(baseOptions?: Apollo.QueryHookOptions<GetPayRolPositionsQuery, GetPayRolPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayRolPositionsQuery, GetPayRolPositionsQueryVariables>(GetPayRolPositionsDocument, options);
      }
export function useGetPayRolPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayRolPositionsQuery, GetPayRolPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayRolPositionsQuery, GetPayRolPositionsQueryVariables>(GetPayRolPositionsDocument, options);
        }
export type GetPayRolPositionsQueryHookResult = ReturnType<typeof useGetPayRolPositionsQuery>;
export type GetPayRolPositionsLazyQueryHookResult = ReturnType<typeof useGetPayRolPositionsLazyQuery>;
export type GetPayRolPositionsQueryResult = Apollo.QueryResult<GetPayRolPositionsQuery, GetPayRolPositionsQueryVariables>;
export const GetPayrollDefaultsDocument = gql`
    query getPayrollDefaults($propertyId: String!, $positionId: ID!) {
  payrollDefaults(propertyId: $propertyId, positionId: $positionId) {
    position {
      id
      name
    }
    baseComp {
      compensationType
      annualCompensation
      hourlyCompensation
      expectedHoursPerWeek
    }
    overtime {
      hourlyCompensation
      expectedHoursPerWeek
      effectiveStartDate
      effectiveEndDate
    }
    raises {
      frequency
      raiseType
      amount
      onetimeRaiseDate
      raiseMonth
      raiseDay
      effectiveStartDate
      effectiveEndDate
    }
    bonuses {
      frequency
      bonusType
      amount
      onetimeBonusDate
      bonusMonth
      bonusDay
      effectiveStartDate
      effectiveEndDate
    }
    compItems {
      compensationItem {
        id
        clientId
        type
        customName
      }
      calculationType
      costPerPayPeriod
      annualCostCap
      effectiveStartDate
      effectiveEndDate
    }
  }
}
    `;

/**
 * __useGetPayrollDefaultsQuery__
 *
 * To run a query within a React component, call `useGetPayrollDefaultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrollDefaultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrollDefaultsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useGetPayrollDefaultsQuery(baseOptions: Apollo.QueryHookOptions<GetPayrollDefaultsQuery, GetPayrollDefaultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayrollDefaultsQuery, GetPayrollDefaultsQueryVariables>(GetPayrollDefaultsDocument, options);
      }
export function useGetPayrollDefaultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayrollDefaultsQuery, GetPayrollDefaultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayrollDefaultsQuery, GetPayrollDefaultsQueryVariables>(GetPayrollDefaultsDocument, options);
        }
export type GetPayrollDefaultsQueryHookResult = ReturnType<typeof useGetPayrollDefaultsQuery>;
export type GetPayrollDefaultsLazyQueryHookResult = ReturnType<typeof useGetPayrollDefaultsLazyQuery>;
export type GetPayrollDefaultsQueryResult = Apollo.QueryResult<GetPayrollDefaultsQuery, GetPayrollDefaultsQueryVariables>;
export const UpdatePayrollEmployeeDataDocument = gql`
    mutation updatePayrollEmployeeData($id: ID!, $firstName: String!, $lastName: String, $externalSystemEmployeeId: String, $type: PayrollModeledEmployeeType!, $positionId: ID!, $classification: PayrollEmployeeClassification!, $startDate: DateTime!, $terminationDate: DateTime, $properties: [PayrollEmployeePropertyAttributionInput!]!, $baseComp: PayrollCreateEmployeeBaseCompInput!, $overtime: [PayrollDifferentialEmployeeOvertimeInput!]!, $bonuses: [PayrollDifferentialEmployeeBonusInput!]!, $raises: [PayrollDifferentialEmployeeRaiseInput!]!, $items: [PayrollDifferentialEmployeeCompItemInput!]!) {
  setPayrollEmployeeData(
    id: $id
    firstName: $firstName
    lastName: $lastName
    externalSystemEmployeeId: $externalSystemEmployeeId
    type: $type
    positionId: $positionId
    classification: $classification
    startDate: $startDate
    terminationDate: $terminationDate
    propertyAttributions: $properties
    baseComp: $baseComp
    overtime: $overtime
    bonuses: $bonuses
    raises: $raises
    items: $items
  ) {
    id
  }
}
    `;
export type UpdatePayrollEmployeeDataMutationFn = Apollo.MutationFunction<UpdatePayrollEmployeeDataMutation, UpdatePayrollEmployeeDataMutationVariables>;

/**
 * __useUpdatePayrollEmployeeDataMutation__
 *
 * To run a mutation, you first call `useUpdatePayrollEmployeeDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayrollEmployeeDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayrollEmployeeDataMutation, { data, loading, error }] = useUpdatePayrollEmployeeDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      externalSystemEmployeeId: // value for 'externalSystemEmployeeId'
 *      type: // value for 'type'
 *      positionId: // value for 'positionId'
 *      classification: // value for 'classification'
 *      startDate: // value for 'startDate'
 *      terminationDate: // value for 'terminationDate'
 *      properties: // value for 'properties'
 *      baseComp: // value for 'baseComp'
 *      overtime: // value for 'overtime'
 *      bonuses: // value for 'bonuses'
 *      raises: // value for 'raises'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useUpdatePayrollEmployeeDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePayrollEmployeeDataMutation, UpdatePayrollEmployeeDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePayrollEmployeeDataMutation, UpdatePayrollEmployeeDataMutationVariables>(UpdatePayrollEmployeeDataDocument, options);
      }
export type UpdatePayrollEmployeeDataMutationHookResult = ReturnType<typeof useUpdatePayrollEmployeeDataMutation>;
export type UpdatePayrollEmployeeDataMutationResult = Apollo.MutationResult<UpdatePayrollEmployeeDataMutation>;
export type UpdatePayrollEmployeeDataMutationOptions = Apollo.BaseMutationOptions<UpdatePayrollEmployeeDataMutation, UpdatePayrollEmployeeDataMutationVariables>;
export const CreateRenovationPackageDocument = gql`
    mutation createRenovationPackage($propertyId: String!, $unitTypeId: String!, $name: String!, $costCategoryMappings: [CostCategoryInput!]!, $premium: Decimal, $premiumDelayRecognition: Int) {
  createRenovationPackage(
    propertyId: $propertyId
    unitTypeId: $unitTypeId
    name: $name
    costCategoryMapping: $costCategoryMappings
    premium: $premium
    premiumDelayRecognition: $premiumDelayRecognition
  ) {
    id
    name
    premium
    premiumDelayRecognition
    costCategoryMappings {
      renovationCostCategoryId
      amount
      delayRecognition
    }
  }
}
    `;
export type CreateRenovationPackageMutationFn = Apollo.MutationFunction<CreateRenovationPackageMutation, CreateRenovationPackageMutationVariables>;

/**
 * __useCreateRenovationPackageMutation__
 *
 * To run a mutation, you first call `useCreateRenovationPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRenovationPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRenovationPackageMutation, { data, loading, error }] = useCreateRenovationPackageMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      unitTypeId: // value for 'unitTypeId'
 *      name: // value for 'name'
 *      costCategoryMappings: // value for 'costCategoryMappings'
 *      premium: // value for 'premium'
 *      premiumDelayRecognition: // value for 'premiumDelayRecognition'
 *   },
 * });
 */
export function useCreateRenovationPackageMutation(baseOptions?: Apollo.MutationHookOptions<CreateRenovationPackageMutation, CreateRenovationPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRenovationPackageMutation, CreateRenovationPackageMutationVariables>(CreateRenovationPackageDocument, options);
      }
export type CreateRenovationPackageMutationHookResult = ReturnType<typeof useCreateRenovationPackageMutation>;
export type CreateRenovationPackageMutationResult = Apollo.MutationResult<CreateRenovationPackageMutation>;
export type CreateRenovationPackageMutationOptions = Apollo.BaseMutationOptions<CreateRenovationPackageMutation, CreateRenovationPackageMutationVariables>;
export const DeleteRenovationPackageDocument = gql`
    mutation deleteRenovationPackage($id: String!, $force: Boolean) {
  removeRenovationPackage(id: $id, force: $force)
}
    `;
export type DeleteRenovationPackageMutationFn = Apollo.MutationFunction<DeleteRenovationPackageMutation, DeleteRenovationPackageMutationVariables>;

/**
 * __useDeleteRenovationPackageMutation__
 *
 * To run a mutation, you first call `useDeleteRenovationPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRenovationPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRenovationPackageMutation, { data, loading, error }] = useDeleteRenovationPackageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      force: // value for 'force'
 *   },
 * });
 */
export function useDeleteRenovationPackageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRenovationPackageMutation, DeleteRenovationPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRenovationPackageMutation, DeleteRenovationPackageMutationVariables>(DeleteRenovationPackageDocument, options);
      }
export type DeleteRenovationPackageMutationHookResult = ReturnType<typeof useDeleteRenovationPackageMutation>;
export type DeleteRenovationPackageMutationResult = Apollo.MutationResult<DeleteRenovationPackageMutation>;
export type DeleteRenovationPackageMutationOptions = Apollo.BaseMutationOptions<DeleteRenovationPackageMutation, DeleteRenovationPackageMutationVariables>;
export const GetAnalystRenovationDrawerRenovationPackagesDocument = gql`
    query getAnalystRenovationDrawerRenovationPackages($propertyIds: [String!]!) {
  queryRenovationPackages(propertyIds: $propertyIds) {
    id
    propertyId
    unitTypeId
    name
    costCategoryMappings {
      renovationCostCategoryId
      amount
      delayRecognition
    }
    premium
    premiumDelayRecognition
  }
}
    `;

/**
 * __useGetAnalystRenovationDrawerRenovationPackagesQuery__
 *
 * To run a query within a React component, call `useGetAnalystRenovationDrawerRenovationPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalystRenovationDrawerRenovationPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalystRenovationDrawerRenovationPackagesQuery({
 *   variables: {
 *      propertyIds: // value for 'propertyIds'
 *   },
 * });
 */
export function useGetAnalystRenovationDrawerRenovationPackagesQuery(baseOptions: Apollo.QueryHookOptions<GetAnalystRenovationDrawerRenovationPackagesQuery, GetAnalystRenovationDrawerRenovationPackagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnalystRenovationDrawerRenovationPackagesQuery, GetAnalystRenovationDrawerRenovationPackagesQueryVariables>(GetAnalystRenovationDrawerRenovationPackagesDocument, options);
      }
export function useGetAnalystRenovationDrawerRenovationPackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnalystRenovationDrawerRenovationPackagesQuery, GetAnalystRenovationDrawerRenovationPackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnalystRenovationDrawerRenovationPackagesQuery, GetAnalystRenovationDrawerRenovationPackagesQueryVariables>(GetAnalystRenovationDrawerRenovationPackagesDocument, options);
        }
export type GetAnalystRenovationDrawerRenovationPackagesQueryHookResult = ReturnType<typeof useGetAnalystRenovationDrawerRenovationPackagesQuery>;
export type GetAnalystRenovationDrawerRenovationPackagesLazyQueryHookResult = ReturnType<typeof useGetAnalystRenovationDrawerRenovationPackagesLazyQuery>;
export type GetAnalystRenovationDrawerRenovationPackagesQueryResult = Apollo.QueryResult<GetAnalystRenovationDrawerRenovationPackagesQuery, GetAnalystRenovationDrawerRenovationPackagesQueryVariables>;
export const GetRenovationCostCategoriesDocument = gql`
    query getRenovationCostCategories {
  queryRenovationCostCategories {
    id
    name
    account {
      id
      glName
      level
      type
    }
  }
}
    `;

/**
 * __useGetRenovationCostCategoriesQuery__
 *
 * To run a query within a React component, call `useGetRenovationCostCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRenovationCostCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRenovationCostCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRenovationCostCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetRenovationCostCategoriesQuery, GetRenovationCostCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRenovationCostCategoriesQuery, GetRenovationCostCategoriesQueryVariables>(GetRenovationCostCategoriesDocument, options);
      }
export function useGetRenovationCostCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRenovationCostCategoriesQuery, GetRenovationCostCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRenovationCostCategoriesQuery, GetRenovationCostCategoriesQueryVariables>(GetRenovationCostCategoriesDocument, options);
        }
export type GetRenovationCostCategoriesQueryHookResult = ReturnType<typeof useGetRenovationCostCategoriesQuery>;
export type GetRenovationCostCategoriesLazyQueryHookResult = ReturnType<typeof useGetRenovationCostCategoriesLazyQuery>;
export type GetRenovationCostCategoriesQueryResult = Apollo.QueryResult<GetRenovationCostCategoriesQuery, GetRenovationCostCategoriesQueryVariables>;
export const GetPropertiesAndUnitTypesDocument = gql`
    query getPropertiesAndUnitTypes {
  myProperties {
    id
    name
    displayName
    reforecastYear
    unitTypesCustom {
      id
      name
    }
  }
}
    `;

/**
 * __useGetPropertiesAndUnitTypesQuery__
 *
 * To run a query within a React component, call `useGetPropertiesAndUnitTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertiesAndUnitTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertiesAndUnitTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPropertiesAndUnitTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetPropertiesAndUnitTypesQuery, GetPropertiesAndUnitTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPropertiesAndUnitTypesQuery, GetPropertiesAndUnitTypesQueryVariables>(GetPropertiesAndUnitTypesDocument, options);
      }
export function useGetPropertiesAndUnitTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropertiesAndUnitTypesQuery, GetPropertiesAndUnitTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPropertiesAndUnitTypesQuery, GetPropertiesAndUnitTypesQueryVariables>(GetPropertiesAndUnitTypesDocument, options);
        }
export type GetPropertiesAndUnitTypesQueryHookResult = ReturnType<typeof useGetPropertiesAndUnitTypesQuery>;
export type GetPropertiesAndUnitTypesLazyQueryHookResult = ReturnType<typeof useGetPropertiesAndUnitTypesLazyQuery>;
export type GetPropertiesAndUnitTypesQueryResult = Apollo.QueryResult<GetPropertiesAndUnitTypesQuery, GetPropertiesAndUnitTypesQueryVariables>;
export const UpdateRenovationPackageDocument = gql`
    mutation updateRenovationPackage($id: String!, $name: String, $costCategoryMappings: [CostCategoryInput!]!, $premium: Decimal, $premiumDelayRecognition: Int) {
  updateRenovationPackage(
    id: $id
    name: $name
    costCategoryMapping: $costCategoryMappings
    premium: $premium
    premiumDelayRecognition: $premiumDelayRecognition
  ) {
    id
    name
    premium
    premiumDelayRecognition
    costCategoryMappings {
      renovationCostCategoryId
      amount
      delayRecognition
    }
  }
}
    `;
export type UpdateRenovationPackageMutationFn = Apollo.MutationFunction<UpdateRenovationPackageMutation, UpdateRenovationPackageMutationVariables>;

/**
 * __useUpdateRenovationPackageMutation__
 *
 * To run a mutation, you first call `useUpdateRenovationPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRenovationPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRenovationPackageMutation, { data, loading, error }] = useUpdateRenovationPackageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      costCategoryMappings: // value for 'costCategoryMappings'
 *      premium: // value for 'premium'
 *      premiumDelayRecognition: // value for 'premiumDelayRecognition'
 *   },
 * });
 */
export function useUpdateRenovationPackageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRenovationPackageMutation, UpdateRenovationPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRenovationPackageMutation, UpdateRenovationPackageMutationVariables>(UpdateRenovationPackageDocument, options);
      }
export type UpdateRenovationPackageMutationHookResult = ReturnType<typeof useUpdateRenovationPackageMutation>;
export type UpdateRenovationPackageMutationResult = Apollo.MutationResult<UpdateRenovationPackageMutation>;
export type UpdateRenovationPackageMutationOptions = Apollo.BaseMutationOptions<UpdateRenovationPackageMutation, UpdateRenovationPackageMutationVariables>;
export const UpsertOperationalMetricAssumptionsDocument = gql`
    mutation upsertOperationalMetricAssumptions($args: [UpsertOperationMetricAssumptions!]!) {
  upsertOperationalMetricAssumptions(multiArgs: $args) {
    id
    amount
    percentOf
    delayRecognition
    effectiveStartYear
    effectiveStartMonthIndex
    effectiveEndYear
    effectiveEndMonthIndex
    propertyId
    locked
  }
}
    `;
export type UpsertOperationalMetricAssumptionsMutationFn = Apollo.MutationFunction<UpsertOperationalMetricAssumptionsMutation, UpsertOperationalMetricAssumptionsMutationVariables>;

/**
 * __useUpsertOperationalMetricAssumptionsMutation__
 *
 * To run a mutation, you first call `useUpsertOperationalMetricAssumptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOperationalMetricAssumptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOperationalMetricAssumptionsMutation, { data, loading, error }] = useUpsertOperationalMetricAssumptionsMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useUpsertOperationalMetricAssumptionsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertOperationalMetricAssumptionsMutation, UpsertOperationalMetricAssumptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertOperationalMetricAssumptionsMutation, UpsertOperationalMetricAssumptionsMutationVariables>(UpsertOperationalMetricAssumptionsDocument, options);
      }
export type UpsertOperationalMetricAssumptionsMutationHookResult = ReturnType<typeof useUpsertOperationalMetricAssumptionsMutation>;
export type UpsertOperationalMetricAssumptionsMutationResult = Apollo.MutationResult<UpsertOperationalMetricAssumptionsMutation>;
export type UpsertOperationalMetricAssumptionsMutationOptions = Apollo.BaseMutationOptions<UpsertOperationalMetricAssumptionsMutation, UpsertOperationalMetricAssumptionsMutationVariables>;
export const DeleteOperationalMetricAssumptionsDocument = gql`
    mutation deleteOperationalMetricAssumptions($args: [String!]!) {
  deleteOperationalMetricAssumptions(operationalMetricAssumptionIds: $args) {
    ids
  }
}
    `;
export type DeleteOperationalMetricAssumptionsMutationFn = Apollo.MutationFunction<DeleteOperationalMetricAssumptionsMutation, DeleteOperationalMetricAssumptionsMutationVariables>;

/**
 * __useDeleteOperationalMetricAssumptionsMutation__
 *
 * To run a mutation, you first call `useDeleteOperationalMetricAssumptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOperationalMetricAssumptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOperationalMetricAssumptionsMutation, { data, loading, error }] = useDeleteOperationalMetricAssumptionsMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useDeleteOperationalMetricAssumptionsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOperationalMetricAssumptionsMutation, DeleteOperationalMetricAssumptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOperationalMetricAssumptionsMutation, DeleteOperationalMetricAssumptionsMutationVariables>(DeleteOperationalMetricAssumptionsDocument, options);
      }
export type DeleteOperationalMetricAssumptionsMutationHookResult = ReturnType<typeof useDeleteOperationalMetricAssumptionsMutation>;
export type DeleteOperationalMetricAssumptionsMutationResult = Apollo.MutationResult<DeleteOperationalMetricAssumptionsMutation>;
export type DeleteOperationalMetricAssumptionsMutationOptions = Apollo.BaseMutationOptions<DeleteOperationalMetricAssumptionsMutation, DeleteOperationalMetricAssumptionsMutationVariables>;
export const UpsertAccPercentageAssumptionsDocument = gql`
    mutation upsertAccPercentageAssumptions($args: [UpsertAccPercentageAssumptions!]!) {
  upsertAccPercentageAssumptions(multiArgs: $args) {
    id
    percentOf
    delayRecognition
    effectiveStartYear
    effectiveStartMonthIndex
    effectiveEndYear
    effectiveEndMonthIndex
    propertyId
    locked
  }
}
    `;
export type UpsertAccPercentageAssumptionsMutationFn = Apollo.MutationFunction<UpsertAccPercentageAssumptionsMutation, UpsertAccPercentageAssumptionsMutationVariables>;

/**
 * __useUpsertAccPercentageAssumptionsMutation__
 *
 * To run a mutation, you first call `useUpsertAccPercentageAssumptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAccPercentageAssumptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAccPercentageAssumptionsMutation, { data, loading, error }] = useUpsertAccPercentageAssumptionsMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useUpsertAccPercentageAssumptionsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertAccPercentageAssumptionsMutation, UpsertAccPercentageAssumptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertAccPercentageAssumptionsMutation, UpsertAccPercentageAssumptionsMutationVariables>(UpsertAccPercentageAssumptionsDocument, options);
      }
export type UpsertAccPercentageAssumptionsMutationHookResult = ReturnType<typeof useUpsertAccPercentageAssumptionsMutation>;
export type UpsertAccPercentageAssumptionsMutationResult = Apollo.MutationResult<UpsertAccPercentageAssumptionsMutation>;
export type UpsertAccPercentageAssumptionsMutationOptions = Apollo.BaseMutationOptions<UpsertAccPercentageAssumptionsMutation, UpsertAccPercentageAssumptionsMutationVariables>;
export const DeleteAccPercentageAssumptionsDocument = gql`
    mutation deleteAccPercentageAssumptions($args: [String!]!) {
  deleteAccPercentageAssumptions(accPercentageAssumptionIds: $args) {
    ids
  }
}
    `;
export type DeleteAccPercentageAssumptionsMutationFn = Apollo.MutationFunction<DeleteAccPercentageAssumptionsMutation, DeleteAccPercentageAssumptionsMutationVariables>;

/**
 * __useDeleteAccPercentageAssumptionsMutation__
 *
 * To run a mutation, you first call `useDeleteAccPercentageAssumptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccPercentageAssumptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccPercentageAssumptionsMutation, { data, loading, error }] = useDeleteAccPercentageAssumptionsMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useDeleteAccPercentageAssumptionsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccPercentageAssumptionsMutation, DeleteAccPercentageAssumptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccPercentageAssumptionsMutation, DeleteAccPercentageAssumptionsMutationVariables>(DeleteAccPercentageAssumptionsDocument, options);
      }
export type DeleteAccPercentageAssumptionsMutationHookResult = ReturnType<typeof useDeleteAccPercentageAssumptionsMutation>;
export type DeleteAccPercentageAssumptionsMutationResult = Apollo.MutationResult<DeleteAccPercentageAssumptionsMutation>;
export type DeleteAccPercentageAssumptionsMutationOptions = Apollo.BaseMutationOptions<DeleteAccPercentageAssumptionsMutation, DeleteAccPercentageAssumptionsMutationVariables>;
export const AnalystDrawerOperationalDriverTypesDocument = gql`
    query analystDrawerOperationalDriverTypes {
  operationalMetricTypes {
    id
    name
  }
}
    `;

/**
 * __useAnalystDrawerOperationalDriverTypesQuery__
 *
 * To run a query within a React component, call `useAnalystDrawerOperationalDriverTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalystDrawerOperationalDriverTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalystDrawerOperationalDriverTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnalystDrawerOperationalDriverTypesQuery(baseOptions?: Apollo.QueryHookOptions<AnalystDrawerOperationalDriverTypesQuery, AnalystDrawerOperationalDriverTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalystDrawerOperationalDriverTypesQuery, AnalystDrawerOperationalDriverTypesQueryVariables>(AnalystDrawerOperationalDriverTypesDocument, options);
      }
export function useAnalystDrawerOperationalDriverTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalystDrawerOperationalDriverTypesQuery, AnalystDrawerOperationalDriverTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalystDrawerOperationalDriverTypesQuery, AnalystDrawerOperationalDriverTypesQueryVariables>(AnalystDrawerOperationalDriverTypesDocument, options);
        }
export type AnalystDrawerOperationalDriverTypesQueryHookResult = ReturnType<typeof useAnalystDrawerOperationalDriverTypesQuery>;
export type AnalystDrawerOperationalDriverTypesLazyQueryHookResult = ReturnType<typeof useAnalystDrawerOperationalDriverTypesLazyQuery>;
export type AnalystDrawerOperationalDriverTypesQueryResult = Apollo.QueryResult<AnalystDrawerOperationalDriverTypesQuery, AnalystDrawerOperationalDriverTypesQueryVariables>;
export const RemoveWorksheetDriverDataDocument = gql`
    mutation RemoveWorksheetDriverData($lineIds: [String!]!) {
  removeWorksheetDriverData(lineIds: $lineIds)
}
    `;
export type RemoveWorksheetDriverDataMutationFn = Apollo.MutationFunction<RemoveWorksheetDriverDataMutation, RemoveWorksheetDriverDataMutationVariables>;

/**
 * __useRemoveWorksheetDriverDataMutation__
 *
 * To run a mutation, you first call `useRemoveWorksheetDriverDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorksheetDriverDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorksheetDriverDataMutation, { data, loading, error }] = useRemoveWorksheetDriverDataMutation({
 *   variables: {
 *      lineIds: // value for 'lineIds'
 *   },
 * });
 */
export function useRemoveWorksheetDriverDataMutation(baseOptions?: Apollo.MutationHookOptions<RemoveWorksheetDriverDataMutation, RemoveWorksheetDriverDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveWorksheetDriverDataMutation, RemoveWorksheetDriverDataMutationVariables>(RemoveWorksheetDriverDataDocument, options);
      }
export type RemoveWorksheetDriverDataMutationHookResult = ReturnType<typeof useRemoveWorksheetDriverDataMutation>;
export type RemoveWorksheetDriverDataMutationResult = Apollo.MutationResult<RemoveWorksheetDriverDataMutation>;
export type RemoveWorksheetDriverDataMutationOptions = Apollo.BaseMutationOptions<RemoveWorksheetDriverDataMutation, RemoveWorksheetDriverDataMutationVariables>;
export const AddWorksheetDriverDataDocument = gql`
    mutation AddWorksheetDriverData($creates: [WorksheetDriverLineCreateInput!]!) {
  createWorksheetDriverData(inputs: $creates) {
    id
  }
}
    `;
export type AddWorksheetDriverDataMutationFn = Apollo.MutationFunction<AddWorksheetDriverDataMutation, AddWorksheetDriverDataMutationVariables>;

/**
 * __useAddWorksheetDriverDataMutation__
 *
 * To run a mutation, you first call `useAddWorksheetDriverDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorksheetDriverDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorksheetDriverDataMutation, { data, loading, error }] = useAddWorksheetDriverDataMutation({
 *   variables: {
 *      creates: // value for 'creates'
 *   },
 * });
 */
export function useAddWorksheetDriverDataMutation(baseOptions?: Apollo.MutationHookOptions<AddWorksheetDriverDataMutation, AddWorksheetDriverDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddWorksheetDriverDataMutation, AddWorksheetDriverDataMutationVariables>(AddWorksheetDriverDataDocument, options);
      }
export type AddWorksheetDriverDataMutationHookResult = ReturnType<typeof useAddWorksheetDriverDataMutation>;
export type AddWorksheetDriverDataMutationResult = Apollo.MutationResult<AddWorksheetDriverDataMutation>;
export type AddWorksheetDriverDataMutationOptions = Apollo.BaseMutationOptions<AddWorksheetDriverDataMutation, AddWorksheetDriverDataMutationVariables>;
export const UpdateWorksheetDriverDataDocument = gql`
    mutation UpdateWorksheetDriverData($creates: [WorksheetDriverLineCreateInput!]!, $updates: [WorksheetDriverLineUpdateInput!]!) {
  updateWorksheetDriverData(creates: $creates, updates: $updates)
}
    `;
export type UpdateWorksheetDriverDataMutationFn = Apollo.MutationFunction<UpdateWorksheetDriverDataMutation, UpdateWorksheetDriverDataMutationVariables>;

/**
 * __useUpdateWorksheetDriverDataMutation__
 *
 * To run a mutation, you first call `useUpdateWorksheetDriverDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorksheetDriverDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorksheetDriverDataMutation, { data, loading, error }] = useUpdateWorksheetDriverDataMutation({
 *   variables: {
 *      creates: // value for 'creates'
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateWorksheetDriverDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorksheetDriverDataMutation, UpdateWorksheetDriverDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorksheetDriverDataMutation, UpdateWorksheetDriverDataMutationVariables>(UpdateWorksheetDriverDataDocument, options);
      }
export type UpdateWorksheetDriverDataMutationHookResult = ReturnType<typeof useUpdateWorksheetDriverDataMutation>;
export type UpdateWorksheetDriverDataMutationResult = Apollo.MutationResult<UpdateWorksheetDriverDataMutation>;
export type UpdateWorksheetDriverDataMutationOptions = Apollo.BaseMutationOptions<UpdateWorksheetDriverDataMutation, UpdateWorksheetDriverDataMutationVariables>;
export const ExportPropertyBudgetDocument = gql`
    query exportPropertyBudget($propertyId: ID!, $requestValueType: VersionType!, $referenceYear: Int!, $referenceValueType: VersionType!, $extraReferenceYear: Int!, $extraReferenceValueType: VersionType!, $includeExtraReferenceYear: Boolean!) {
  getPropertyBudgetExportSingleYear(
    propertyId: $propertyId
    requestValueType: $requestValueType
    referenceYear: $referenceYear
    referenceValueType: $referenceValueType
  ) {
    id
    glNumber
    glName
    type
    order
    yearValues
    yearTotal
    referenceYearTotal
    extraReferenceYearTotal(
      valueType: $extraReferenceValueType
      year: $extraReferenceYear
    ) @include(if: $includeExtraReferenceYear)
    note
    accountClassification
    yardiExternalAccountId
  }
}
    `;

/**
 * __useExportPropertyBudgetQuery__
 *
 * To run a query within a React component, call `useExportPropertyBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportPropertyBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportPropertyBudgetQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      requestValueType: // value for 'requestValueType'
 *      referenceYear: // value for 'referenceYear'
 *      referenceValueType: // value for 'referenceValueType'
 *      extraReferenceYear: // value for 'extraReferenceYear'
 *      extraReferenceValueType: // value for 'extraReferenceValueType'
 *      includeExtraReferenceYear: // value for 'includeExtraReferenceYear'
 *   },
 * });
 */
export function useExportPropertyBudgetQuery(baseOptions: Apollo.QueryHookOptions<ExportPropertyBudgetQuery, ExportPropertyBudgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportPropertyBudgetQuery, ExportPropertyBudgetQueryVariables>(ExportPropertyBudgetDocument, options);
      }
export function useExportPropertyBudgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportPropertyBudgetQuery, ExportPropertyBudgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportPropertyBudgetQuery, ExportPropertyBudgetQueryVariables>(ExportPropertyBudgetDocument, options);
        }
export type ExportPropertyBudgetQueryHookResult = ReturnType<typeof useExportPropertyBudgetQuery>;
export type ExportPropertyBudgetLazyQueryHookResult = ReturnType<typeof useExportPropertyBudgetLazyQuery>;
export type ExportPropertyBudgetQueryResult = Apollo.QueryResult<ExportPropertyBudgetQuery, ExportPropertyBudgetQueryVariables>;
export const ExportPropertyBudgetT12ReportDocument = gql`
    query exportPropertyBudgetT12Report($propertyId: ID!) {
  getPropertyBudgetT12Report(propertyId: $propertyId) {
    propertyId
    unitCount
    totalSqFoot
    totalRevenueBudget
    totalRevenueActualT12
    totalRevenueActualAnnualized
    totalExpensesBudget
    totalExpensesActualT12
    totalExpensesActualAnnualized
    records {
      accountId
      glName
      glNumber
      type
      order
      annualizedNMonthsActualsTotal
      twelveMonthsActualsTotal
      budgetValues
      note
    }
  }
}
    `;

/**
 * __useExportPropertyBudgetT12ReportQuery__
 *
 * To run a query within a React component, call `useExportPropertyBudgetT12ReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportPropertyBudgetT12ReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportPropertyBudgetT12ReportQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useExportPropertyBudgetT12ReportQuery(baseOptions: Apollo.QueryHookOptions<ExportPropertyBudgetT12ReportQuery, ExportPropertyBudgetT12ReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportPropertyBudgetT12ReportQuery, ExportPropertyBudgetT12ReportQueryVariables>(ExportPropertyBudgetT12ReportDocument, options);
      }
export function useExportPropertyBudgetT12ReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportPropertyBudgetT12ReportQuery, ExportPropertyBudgetT12ReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportPropertyBudgetT12ReportQuery, ExportPropertyBudgetT12ReportQueryVariables>(ExportPropertyBudgetT12ReportDocument, options);
        }
export type ExportPropertyBudgetT12ReportQueryHookResult = ReturnType<typeof useExportPropertyBudgetT12ReportQuery>;
export type ExportPropertyBudgetT12ReportLazyQueryHookResult = ReturnType<typeof useExportPropertyBudgetT12ReportLazyQuery>;
export type ExportPropertyBudgetT12ReportQueryResult = Apollo.QueryResult<ExportPropertyBudgetT12ReportQuery, ExportPropertyBudgetT12ReportQueryVariables>;
export const GetWorksheetTypeaheadDocument = gql`
    query getWorksheetTypeahead($accountId: String!, $budgetYear: Int!) {
  worksheetTypeahead: worksheetDescriptionsForBudgetYear(
    accountId: $accountId
    budgetYear: $budgetYear
  )
}
    `;

/**
 * __useGetWorksheetTypeaheadQuery__
 *
 * To run a query within a React component, call `useGetWorksheetTypeaheadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorksheetTypeaheadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorksheetTypeaheadQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      budgetYear: // value for 'budgetYear'
 *   },
 * });
 */
export function useGetWorksheetTypeaheadQuery(baseOptions: Apollo.QueryHookOptions<GetWorksheetTypeaheadQuery, GetWorksheetTypeaheadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorksheetTypeaheadQuery, GetWorksheetTypeaheadQueryVariables>(GetWorksheetTypeaheadDocument, options);
      }
export function useGetWorksheetTypeaheadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorksheetTypeaheadQuery, GetWorksheetTypeaheadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorksheetTypeaheadQuery, GetWorksheetTypeaheadQueryVariables>(GetWorksheetTypeaheadDocument, options);
        }
export type GetWorksheetTypeaheadQueryHookResult = ReturnType<typeof useGetWorksheetTypeaheadQuery>;
export type GetWorksheetTypeaheadLazyQueryHookResult = ReturnType<typeof useGetWorksheetTypeaheadLazyQuery>;
export type GetWorksheetTypeaheadQueryResult = Apollo.QueryResult<GetWorksheetTypeaheadQuery, GetWorksheetTypeaheadQueryVariables>;
export const GetInstructionDocument = gql`
    query getInstruction($accountId: ID!, $versionId: ID!) {
  getInstructionByAccountIdAndValueVersionId(
    accountId: $accountId
    versionId: $versionId
  ) {
    id
    accountId
    text
    version {
      type
      year
    }
  }
}
    `;

/**
 * __useGetInstructionQuery__
 *
 * To run a query within a React component, call `useGetInstructionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructionQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useGetInstructionQuery(baseOptions: Apollo.QueryHookOptions<GetInstructionQuery, GetInstructionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstructionQuery, GetInstructionQueryVariables>(GetInstructionDocument, options);
      }
export function useGetInstructionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstructionQuery, GetInstructionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstructionQuery, GetInstructionQueryVariables>(GetInstructionDocument, options);
        }
export type GetInstructionQueryHookResult = ReturnType<typeof useGetInstructionQuery>;
export type GetInstructionLazyQueryHookResult = ReturnType<typeof useGetInstructionLazyQuery>;
export type GetInstructionQueryResult = Apollo.QueryResult<GetInstructionQuery, GetInstructionQueryVariables>;
export const GetNoteDocument = gql`
    query getNote($propertyId: ID!, $year: Int!, $versionType: VersionType!, $entityId: ID!, $entityType: EntityType!) {
  queryNote(
    propertyId: $propertyId
    year: $year
    versionType: $versionType
    entityId: $entityId
    entityType: $entityType
  ) {
    propertyId
    versionType
    year
    entityId
    entityType
    text
  }
}
    `;

/**
 * __useGetNoteQuery__
 *
 * To run a query within a React component, call `useGetNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNoteQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      year: // value for 'year'
 *      versionType: // value for 'versionType'
 *      entityId: // value for 'entityId'
 *      entityType: // value for 'entityType'
 *   },
 * });
 */
export function useGetNoteQuery(baseOptions: Apollo.QueryHookOptions<GetNoteQuery, GetNoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNoteQuery, GetNoteQueryVariables>(GetNoteDocument, options);
      }
export function useGetNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNoteQuery, GetNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNoteQuery, GetNoteQueryVariables>(GetNoteDocument, options);
        }
export type GetNoteQueryHookResult = ReturnType<typeof useGetNoteQuery>;
export type GetNoteLazyQueryHookResult = ReturnType<typeof useGetNoteLazyQuery>;
export type GetNoteQueryResult = Apollo.QueryResult<GetNoteQuery, GetNoteQueryVariables>;
export const UpsertNoteDocument = gql`
    mutation UpsertNote($text: String!, $entityId: ID!, $entityType: EntityType!, $versionType: VersionType!, $propertyId: ID!, $year: Int!) {
  upsertNoteText(
    text: $text
    entityId: $entityId
    entityType: $entityType
    versionType: $versionType
    propertyId: $propertyId
    year: $year
  ) {
    propertyId
    versionType
    year
    entityId
    entityType
    text
  }
}
    `;
export type UpsertNoteMutationFn = Apollo.MutationFunction<UpsertNoteMutation, UpsertNoteMutationVariables>;

/**
 * __useUpsertNoteMutation__
 *
 * To run a mutation, you first call `useUpsertNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertNoteMutation, { data, loading, error }] = useUpsertNoteMutation({
 *   variables: {
 *      text: // value for 'text'
 *      entityId: // value for 'entityId'
 *      entityType: // value for 'entityType'
 *      versionType: // value for 'versionType'
 *      propertyId: // value for 'propertyId'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useUpsertNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpsertNoteMutation, UpsertNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertNoteMutation, UpsertNoteMutationVariables>(UpsertNoteDocument, options);
      }
export type UpsertNoteMutationHookResult = ReturnType<typeof useUpsertNoteMutation>;
export type UpsertNoteMutationResult = Apollo.MutationResult<UpsertNoteMutation>;
export type UpsertNoteMutationOptions = Apollo.BaseMutationOptions<UpsertNoteMutation, UpsertNoteMutationVariables>;
export const GetPortfolioSnapshotAccountPerPropertyDataDocument = gql`
    query GetPortfolioSnapshotAccountPerPropertyData($snapshotId: ID!, $propertyIds: [ID!]!, $accountId: ID!) {
  getPortfolioSnapshotAccountPerPropertyData(
    snapshotId: $snapshotId
    propertyIds: $propertyIds
    accountId: $accountId
  ) {
    accountId
    propertiesValues {
      id
      values
    }
  }
}
    `;

/**
 * __useGetPortfolioSnapshotAccountPerPropertyDataQuery__
 *
 * To run a query within a React component, call `useGetPortfolioSnapshotAccountPerPropertyDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortfolioSnapshotAccountPerPropertyDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortfolioSnapshotAccountPerPropertyDataQuery({
 *   variables: {
 *      snapshotId: // value for 'snapshotId'
 *      propertyIds: // value for 'propertyIds'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetPortfolioSnapshotAccountPerPropertyDataQuery(baseOptions: Apollo.QueryHookOptions<GetPortfolioSnapshotAccountPerPropertyDataQuery, GetPortfolioSnapshotAccountPerPropertyDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPortfolioSnapshotAccountPerPropertyDataQuery, GetPortfolioSnapshotAccountPerPropertyDataQueryVariables>(GetPortfolioSnapshotAccountPerPropertyDataDocument, options);
      }
export function useGetPortfolioSnapshotAccountPerPropertyDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPortfolioSnapshotAccountPerPropertyDataQuery, GetPortfolioSnapshotAccountPerPropertyDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPortfolioSnapshotAccountPerPropertyDataQuery, GetPortfolioSnapshotAccountPerPropertyDataQueryVariables>(GetPortfolioSnapshotAccountPerPropertyDataDocument, options);
        }
export type GetPortfolioSnapshotAccountPerPropertyDataQueryHookResult = ReturnType<typeof useGetPortfolioSnapshotAccountPerPropertyDataQuery>;
export type GetPortfolioSnapshotAccountPerPropertyDataLazyQueryHookResult = ReturnType<typeof useGetPortfolioSnapshotAccountPerPropertyDataLazyQuery>;
export type GetPortfolioSnapshotAccountPerPropertyDataQueryResult = Apollo.QueryResult<GetPortfolioSnapshotAccountPerPropertyDataQuery, GetPortfolioSnapshotAccountPerPropertyDataQueryVariables>;
export const GetPortfolioSnapshotDataDocument = gql`
    query GetPortfolioSnapshotData($snapshotId: ID!, $propertyIds: [ID!]!) {
  getPortfolioSnapshotData(snapshotId: $snapshotId, propertyIds: $propertyIds) {
    accountValues {
      id
      values
    }
    propertiesData {
      id
      reforecastStartMonthIndex
    }
  }
}
    `;

/**
 * __useGetPortfolioSnapshotDataQuery__
 *
 * To run a query within a React component, call `useGetPortfolioSnapshotDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortfolioSnapshotDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortfolioSnapshotDataQuery({
 *   variables: {
 *      snapshotId: // value for 'snapshotId'
 *      propertyIds: // value for 'propertyIds'
 *   },
 * });
 */
export function useGetPortfolioSnapshotDataQuery(baseOptions: Apollo.QueryHookOptions<GetPortfolioSnapshotDataQuery, GetPortfolioSnapshotDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPortfolioSnapshotDataQuery, GetPortfolioSnapshotDataQueryVariables>(GetPortfolioSnapshotDataDocument, options);
      }
export function useGetPortfolioSnapshotDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPortfolioSnapshotDataQuery, GetPortfolioSnapshotDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPortfolioSnapshotDataQuery, GetPortfolioSnapshotDataQueryVariables>(GetPortfolioSnapshotDataDocument, options);
        }
export type GetPortfolioSnapshotDataQueryHookResult = ReturnType<typeof useGetPortfolioSnapshotDataQuery>;
export type GetPortfolioSnapshotDataLazyQueryHookResult = ReturnType<typeof useGetPortfolioSnapshotDataLazyQuery>;
export type GetPortfolioSnapshotDataQueryResult = Apollo.QueryResult<GetPortfolioSnapshotDataQuery, GetPortfolioSnapshotDataQueryVariables>;
export const FinancialValuesDocument = gql`
    subscription financialValues($propertyIds: [String!], $versionIds: [String!]) {
  newFinancialValue(propertyIds: $propertyIds, versionIds: $versionIds) {
    propertyId
    versionId
    monthIndex
    entityType
    entityId
    value
  }
}
    `;

/**
 * __useFinancialValuesSubscription__
 *
 * To run a query within a React component, call `useFinancialValuesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFinancialValuesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinancialValuesSubscription({
 *   variables: {
 *      propertyIds: // value for 'propertyIds'
 *      versionIds: // value for 'versionIds'
 *   },
 * });
 */
export function useFinancialValuesSubscription(baseOptions?: Apollo.SubscriptionHookOptions<FinancialValuesSubscription, FinancialValuesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<FinancialValuesSubscription, FinancialValuesSubscriptionVariables>(FinancialValuesDocument, options);
      }
export type FinancialValuesSubscriptionHookResult = ReturnType<typeof useFinancialValuesSubscription>;
export type FinancialValuesSubscriptionResult = Apollo.SubscriptionResult<FinancialValuesSubscription>;
export const GetFinancialValuesDocument = gql`
    query GetFinancialValues($financialValueSelectors: [FinancialValueQueryInput!]!, $financialTotalValueSelectorsProjections: [FinancialTotalValueQueryInput!]!, $financialTotalValueSelectorsHistorical: [FinancialTotalValueQueryInput!]!, $useCache: Boolean) {
  financialValues(
    financialValueSelectors: $financialValueSelectors
    financialTotalValueSelectorsProjections: $financialTotalValueSelectorsProjections
    financialTotalValueSelectorsHistorical: $financialTotalValueSelectorsHistorical
    useCache: $useCache
  ) {
    financialValues {
      entityId
      entityType
      versionId
      propertyId
      monthIndex
      value
      originalValue
    }
    financialTotalValues {
      entityId
      entityType
      versionId
      propertyId
      value
    }
  }
}
    `;

/**
 * __useGetFinancialValuesQuery__
 *
 * To run a query within a React component, call `useGetFinancialValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialValuesQuery({
 *   variables: {
 *      financialValueSelectors: // value for 'financialValueSelectors'
 *      financialTotalValueSelectorsProjections: // value for 'financialTotalValueSelectorsProjections'
 *      financialTotalValueSelectorsHistorical: // value for 'financialTotalValueSelectorsHistorical'
 *      useCache: // value for 'useCache'
 *   },
 * });
 */
export function useGetFinancialValuesQuery(baseOptions: Apollo.QueryHookOptions<GetFinancialValuesQuery, GetFinancialValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFinancialValuesQuery, GetFinancialValuesQueryVariables>(GetFinancialValuesDocument, options);
      }
export function useGetFinancialValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFinancialValuesQuery, GetFinancialValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFinancialValuesQuery, GetFinancialValuesQueryVariables>(GetFinancialValuesDocument, options);
        }
export type GetFinancialValuesQueryHookResult = ReturnType<typeof useGetFinancialValuesQuery>;
export type GetFinancialValuesLazyQueryHookResult = ReturnType<typeof useGetFinancialValuesLazyQuery>;
export type GetFinancialValuesQueryResult = Apollo.QueryResult<GetFinancialValuesQuery, GetFinancialValuesQueryVariables>;
export const UpsertAccountValuesDocument = gql`
    mutation upsertAccountValues($accountId: String!, $versionId: String!, $monthIndexes: [Int!]!, $propertyId: String!, $values: [Decimal!]!) {
  upsertAccountValues(
    accountId: $accountId
    versionId: $versionId
    monthIndexes: $monthIndexes
    values: $values
    propertyId: $propertyId
  ) {
    id
    accountId
    versionId
    monthIndex
    propertyId
    value
  }
}
    `;
export type UpsertAccountValuesMutationFn = Apollo.MutationFunction<UpsertAccountValuesMutation, UpsertAccountValuesMutationVariables>;

/**
 * __useUpsertAccountValuesMutation__
 *
 * To run a mutation, you first call `useUpsertAccountValuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAccountValuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAccountValuesMutation, { data, loading, error }] = useUpsertAccountValuesMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      versionId: // value for 'versionId'
 *      monthIndexes: // value for 'monthIndexes'
 *      propertyId: // value for 'propertyId'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useUpsertAccountValuesMutation(baseOptions?: Apollo.MutationHookOptions<UpsertAccountValuesMutation, UpsertAccountValuesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertAccountValuesMutation, UpsertAccountValuesMutationVariables>(UpsertAccountValuesDocument, options);
      }
export type UpsertAccountValuesMutationHookResult = ReturnType<typeof useUpsertAccountValuesMutation>;
export type UpsertAccountValuesMutationResult = Apollo.MutationResult<UpsertAccountValuesMutation>;
export type UpsertAccountValuesMutationOptions = Apollo.BaseMutationOptions<UpsertAccountValuesMutation, UpsertAccountValuesMutationVariables>;
export const AccountValueOverrideDocument = gql`
    mutation AccountValueOverride($accountId: String!, $propertyId: String!, $versionId: String!, $values: [Decimal!]!, $monthIndexes: [Int!]!, $shouldRevert: Boolean!) {
  accountValueOverride(
    accountId: $accountId
    propertyId: $propertyId
    versionId: $versionId
    values: $values
    monthIndexes: $monthIndexes
    shouldRevert: $shouldRevert
  ) {
    id
    value
    versionId
    monthIndex
    updatedAt
    accountId
  }
}
    `;
export type AccountValueOverrideMutationFn = Apollo.MutationFunction<AccountValueOverrideMutation, AccountValueOverrideMutationVariables>;

/**
 * __useAccountValueOverrideMutation__
 *
 * To run a mutation, you first call `useAccountValueOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountValueOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountValueOverrideMutation, { data, loading, error }] = useAccountValueOverrideMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      propertyId: // value for 'propertyId'
 *      versionId: // value for 'versionId'
 *      values: // value for 'values'
 *      monthIndexes: // value for 'monthIndexes'
 *      shouldRevert: // value for 'shouldRevert'
 *   },
 * });
 */
export function useAccountValueOverrideMutation(baseOptions?: Apollo.MutationHookOptions<AccountValueOverrideMutation, AccountValueOverrideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountValueOverrideMutation, AccountValueOverrideMutationVariables>(AccountValueOverrideDocument, options);
      }
export type AccountValueOverrideMutationHookResult = ReturnType<typeof useAccountValueOverrideMutation>;
export type AccountValueOverrideMutationResult = Apollo.MutationResult<AccountValueOverrideMutation>;
export type AccountValueOverrideMutationOptions = Apollo.BaseMutationOptions<AccountValueOverrideMutation, AccountValueOverrideMutationVariables>;
export const DeleteAccountOverrideValueDocument = gql`
    mutation DeleteAccountOverrideValue($accountId: String!, $propertyId: String!, $versionId: String!, $monthIndexes: [Int!]!) {
  deleteAccountValueOverrides(
    accountId: $accountId
    propertyId: $propertyId
    versionId: $versionId
    monthIndexes: $monthIndexes
  ) {
    monthIndex
    value
  }
}
    `;
export type DeleteAccountOverrideValueMutationFn = Apollo.MutationFunction<DeleteAccountOverrideValueMutation, DeleteAccountOverrideValueMutationVariables>;

/**
 * __useDeleteAccountOverrideValueMutation__
 *
 * To run a mutation, you first call `useDeleteAccountOverrideValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountOverrideValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountOverrideValueMutation, { data, loading, error }] = useDeleteAccountOverrideValueMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      propertyId: // value for 'propertyId'
 *      versionId: // value for 'versionId'
 *      monthIndexes: // value for 'monthIndexes'
 *   },
 * });
 */
export function useDeleteAccountOverrideValueMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountOverrideValueMutation, DeleteAccountOverrideValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccountOverrideValueMutation, DeleteAccountOverrideValueMutationVariables>(DeleteAccountOverrideValueDocument, options);
      }
export type DeleteAccountOverrideValueMutationHookResult = ReturnType<typeof useDeleteAccountOverrideValueMutation>;
export type DeleteAccountOverrideValueMutationResult = Apollo.MutationResult<DeleteAccountOverrideValueMutation>;
export type DeleteAccountOverrideValueMutationOptions = Apollo.BaseMutationOptions<DeleteAccountOverrideValueMutation, DeleteAccountOverrideValueMutationVariables>;
export const GetStarredAccountsDocument = gql`
    query getStarredAccounts($propertyId: ID!, $accountId: ID!, $versionIds: [ID!]!) {
  getStarredAccountByPropertyIdAndAccountIdAndVersionIds(
    propertyId: $propertyId
    accountId: $accountId
    versionIds: $versionIds
  ) {
    id
    propertyId
    accountId
    isStarred
    version {
      id
    }
  }
}
    `;

/**
 * __useGetStarredAccountsQuery__
 *
 * To run a query within a React component, call `useGetStarredAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStarredAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStarredAccountsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      accountId: // value for 'accountId'
 *      versionIds: // value for 'versionIds'
 *   },
 * });
 */
export function useGetStarredAccountsQuery(baseOptions: Apollo.QueryHookOptions<GetStarredAccountsQuery, GetStarredAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStarredAccountsQuery, GetStarredAccountsQueryVariables>(GetStarredAccountsDocument, options);
      }
export function useGetStarredAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStarredAccountsQuery, GetStarredAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStarredAccountsQuery, GetStarredAccountsQueryVariables>(GetStarredAccountsDocument, options);
        }
export type GetStarredAccountsQueryHookResult = ReturnType<typeof useGetStarredAccountsQuery>;
export type GetStarredAccountsLazyQueryHookResult = ReturnType<typeof useGetStarredAccountsLazyQuery>;
export type GetStarredAccountsQueryResult = Apollo.QueryResult<GetStarredAccountsQuery, GetStarredAccountsQueryVariables>;
export const UpsertStarredAccountsDocument = gql`
    mutation upsertStarredAccounts($data: [StarredAccountMutationArgs!]!) {
  upsertStarredAccounts(multiAccountsArgs: $data)
}
    `;
export type UpsertStarredAccountsMutationFn = Apollo.MutationFunction<UpsertStarredAccountsMutation, UpsertStarredAccountsMutationVariables>;

/**
 * __useUpsertStarredAccountsMutation__
 *
 * To run a mutation, you first call `useUpsertStarredAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertStarredAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertStarredAccountsMutation, { data, loading, error }] = useUpsertStarredAccountsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertStarredAccountsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertStarredAccountsMutation, UpsertStarredAccountsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertStarredAccountsMutation, UpsertStarredAccountsMutationVariables>(UpsertStarredAccountsDocument, options);
      }
export type UpsertStarredAccountsMutationHookResult = ReturnType<typeof useUpsertStarredAccountsMutation>;
export type UpsertStarredAccountsMutationResult = Apollo.MutationResult<UpsertStarredAccountsMutation>;
export type UpsertStarredAccountsMutationOptions = Apollo.BaseMutationOptions<UpsertStarredAccountsMutation, UpsertStarredAccountsMutationVariables>;
export const MyUserDocument = gql`
    query myUser {
  myUser {
    id
    firstName
    lastName
    email
    role
    isReadOnly
    hasPowerBi
    authorizations
    properties {
      id
      name
      displayName
      unitLevelModelingEnabled
      address
      cappedRenewalIncreasePercent
      externalId
      code
      fiscalYearStartMonthIndex
      reforecastStartMonthIndex
      reforecastYear
      revenueModel
      originalRevenueMarketRentModeType
      propertyManagementSystem
    }
    clientId
    clientName
    clientDisplayName
  }
}
    `;

/**
 * __useMyUserQuery__
 *
 * To run a query within a React component, call `useMyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyUserQuery(baseOptions?: Apollo.QueryHookOptions<MyUserQuery, MyUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyUserQuery, MyUserQueryVariables>(MyUserDocument, options);
      }
export function useMyUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyUserQuery, MyUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyUserQuery, MyUserQueryVariables>(MyUserDocument, options);
        }
export type MyUserQueryHookResult = ReturnType<typeof useMyUserQuery>;
export type MyUserLazyQueryHookResult = ReturnType<typeof useMyUserLazyQuery>;
export type MyUserQueryResult = Apollo.QueryResult<MyUserQuery, MyUserQueryVariables>;
export const GetChartOfAccountsDocument = gql`
    query GetChartOfAccounts {
  findFirstChartOfAccounts {
    id
    operationalFinancialMetricsAggregateId
    operationalFinancialMetricsAggregateType
    yardiChartOfAccountsMetadata {
      bookNum
    }
  }
  budgetComponentMappings {
    id
    component {
      id
      name
      budgetComponentType
      order
    }
    sourceAccount {
      id
      glName
      glNumber
      order
      type
      parentId
      children {
        id
        glName
        glNumber
        order
        type
        parentId
        children {
          id
          glName
          glNumber
          order
          type
          parentId
          children {
            id
            glName
            glNumber
            order
            type
            parentId
            children {
              id
              glName
              glNumber
              order
              type
              parentId
              children {
                id
                glName
                glNumber
                order
                type
                parentId
                children {
                  id
                  glName
                  glNumber
                  order
                  type
                  parentId
                  children {
                    id
                    glName
                    glNumber
                    order
                    type
                    parentId
                    children {
                      id
                      glName
                      glNumber
                      order
                      type
                      parentId
                      children {
                        id
                        glName
                        glNumber
                        order
                        type
                        parentId
                        children {
                          id
                          glName
                          glNumber
                          order
                          type
                          parentId
                          children {
                            id
                            glName
                            glNumber
                            order
                            type
                            parentId
                            children {
                              id
                              glName
                              glNumber
                              order
                              type
                              parentId
                              children {
                                id
                                glName
                                glNumber
                                order
                                type
                                parentId
                                children {
                                  id
                                  glName
                                  glNumber
                                  order
                                  type
                                  parentId
                                  children {
                                    id
                                    glName
                                    glNumber
                                    order
                                    type
                                    parentId
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    sourceComponent {
      id
      name
      budgetComponentType
    }
    negate
  }
}
    `;

/**
 * __useGetChartOfAccountsQuery__
 *
 * To run a query within a React component, call `useGetChartOfAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChartOfAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChartOfAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChartOfAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetChartOfAccountsQuery, GetChartOfAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChartOfAccountsQuery, GetChartOfAccountsQueryVariables>(GetChartOfAccountsDocument, options);
      }
export function useGetChartOfAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChartOfAccountsQuery, GetChartOfAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChartOfAccountsQuery, GetChartOfAccountsQueryVariables>(GetChartOfAccountsDocument, options);
        }
export type GetChartOfAccountsQueryHookResult = ReturnType<typeof useGetChartOfAccountsQuery>;
export type GetChartOfAccountsLazyQueryHookResult = ReturnType<typeof useGetChartOfAccountsLazyQuery>;
export type GetChartOfAccountsQueryResult = Apollo.QueryResult<GetChartOfAccountsQuery, GetChartOfAccountsQueryVariables>;
export const AuthenticateGuestTokenDocument = gql`
    query AuthenticateGuestToken($accessToken: String!, $otp: String!) {
  authenticateToken(accessToken: $accessToken, otp: $otp)
}
    `;

/**
 * __useAuthenticateGuestTokenQuery__
 *
 * To run a query within a React component, call `useAuthenticateGuestTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateGuestTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthenticateGuestTokenQuery({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *      otp: // value for 'otp'
 *   },
 * });
 */
export function useAuthenticateGuestTokenQuery(baseOptions: Apollo.QueryHookOptions<AuthenticateGuestTokenQuery, AuthenticateGuestTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthenticateGuestTokenQuery, AuthenticateGuestTokenQueryVariables>(AuthenticateGuestTokenDocument, options);
      }
export function useAuthenticateGuestTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthenticateGuestTokenQuery, AuthenticateGuestTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthenticateGuestTokenQuery, AuthenticateGuestTokenQueryVariables>(AuthenticateGuestTokenDocument, options);
        }
export type AuthenticateGuestTokenQueryHookResult = ReturnType<typeof useAuthenticateGuestTokenQuery>;
export type AuthenticateGuestTokenLazyQueryHookResult = ReturnType<typeof useAuthenticateGuestTokenLazyQuery>;
export type AuthenticateGuestTokenQueryResult = Apollo.QueryResult<AuthenticateGuestTokenQuery, AuthenticateGuestTokenQueryVariables>;
export const RequestGuestOtpDocument = gql`
    query RequestGuestOTP($accessToken: String!) {
  requestTokenOTP(accessToken: $accessToken)
}
    `;

/**
 * __useRequestGuestOtpQuery__
 *
 * To run a query within a React component, call `useRequestGuestOtpQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestGuestOtpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestGuestOtpQuery({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useRequestGuestOtpQuery(baseOptions: Apollo.QueryHookOptions<RequestGuestOtpQuery, RequestGuestOtpQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RequestGuestOtpQuery, RequestGuestOtpQueryVariables>(RequestGuestOtpDocument, options);
      }
export function useRequestGuestOtpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestGuestOtpQuery, RequestGuestOtpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RequestGuestOtpQuery, RequestGuestOtpQueryVariables>(RequestGuestOtpDocument, options);
        }
export type RequestGuestOtpQueryHookResult = ReturnType<typeof useRequestGuestOtpQuery>;
export type RequestGuestOtpLazyQueryHookResult = ReturnType<typeof useRequestGuestOtpLazyQuery>;
export type RequestGuestOtpQueryResult = Apollo.QueryResult<RequestGuestOtpQuery, RequestGuestOtpQueryVariables>;
export const GetUnitsCountDocument = gql`
    query getUnitsCount($propertyId: String!) {
  propertyUnitCounts(propertyId: $propertyId) {
    unitTypeId
    unitTypeName
    count
    sqft
  }
}
    `;

/**
 * __useGetUnitsCountQuery__
 *
 * To run a query within a React component, call `useGetUnitsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitsCountQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetUnitsCountQuery(baseOptions: Apollo.QueryHookOptions<GetUnitsCountQuery, GetUnitsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitsCountQuery, GetUnitsCountQueryVariables>(GetUnitsCountDocument, options);
      }
export function useGetUnitsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitsCountQuery, GetUnitsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitsCountQuery, GetUnitsCountQueryVariables>(GetUnitsCountDocument, options);
        }
export type GetUnitsCountQueryHookResult = ReturnType<typeof useGetUnitsCountQuery>;
export type GetUnitsCountLazyQueryHookResult = ReturnType<typeof useGetUnitsCountLazyQuery>;
export type GetUnitsCountQueryResult = Apollo.QueryResult<GetUnitsCountQuery, GetUnitsCountQueryVariables>;
export const GetSettingsFromPropertyDocument = gql`
    query getSettingsFromProperty($propertyId: String!) {
  findFirstProperty(where: {id: {equals: $propertyId}}) {
    fiscalYearStartMonthIndex
    reforecastStartMonthIndex
    reforecastYear
  }
}
    `;

/**
 * __useGetSettingsFromPropertyQuery__
 *
 * To run a query within a React component, call `useGetSettingsFromPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettingsFromPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettingsFromPropertyQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetSettingsFromPropertyQuery(baseOptions: Apollo.QueryHookOptions<GetSettingsFromPropertyQuery, GetSettingsFromPropertyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSettingsFromPropertyQuery, GetSettingsFromPropertyQueryVariables>(GetSettingsFromPropertyDocument, options);
      }
export function useGetSettingsFromPropertyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSettingsFromPropertyQuery, GetSettingsFromPropertyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSettingsFromPropertyQuery, GetSettingsFromPropertyQueryVariables>(GetSettingsFromPropertyDocument, options);
        }
export type GetSettingsFromPropertyQueryHookResult = ReturnType<typeof useGetSettingsFromPropertyQuery>;
export type GetSettingsFromPropertyLazyQueryHookResult = ReturnType<typeof useGetSettingsFromPropertyLazyQuery>;
export type GetSettingsFromPropertyQueryResult = Apollo.QueryResult<GetSettingsFromPropertyQuery, GetSettingsFromPropertyQueryVariables>;
export const GetVersionsDocument = gql`
    query getVersions {
  valueVersions {
    id
    type
    year
  }
}
    `;

/**
 * __useGetVersionsQuery__
 *
 * To run a query within a React component, call `useGetVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVersionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVersionsQuery(baseOptions?: Apollo.QueryHookOptions<GetVersionsQuery, GetVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVersionsQuery, GetVersionsQueryVariables>(GetVersionsDocument, options);
      }
export function useGetVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVersionsQuery, GetVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVersionsQuery, GetVersionsQueryVariables>(GetVersionsDocument, options);
        }
export type GetVersionsQueryHookResult = ReturnType<typeof useGetVersionsQuery>;
export type GetVersionsLazyQueryHookResult = ReturnType<typeof useGetVersionsLazyQuery>;
export type GetVersionsQueryResult = Apollo.QueryResult<GetVersionsQuery, GetVersionsQueryVariables>;
export const GetAccountNoteDocument = gql`
    query GetAccountNote($propertyId: String!, $accountId: String!, $year: Int!, $versionType: VersionType!) {
  queryAccountNote(
    propertyId: $propertyId
    accountId: $accountId
    year: $year
    versionType: $versionType
  ) {
    propertyId
    accountId
    year
    versionType
    text
  }
}
    `;

/**
 * __useGetAccountNoteQuery__
 *
 * To run a query within a React component, call `useGetAccountNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountNoteQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      accountId: // value for 'accountId'
 *      year: // value for 'year'
 *      versionType: // value for 'versionType'
 *   },
 * });
 */
export function useGetAccountNoteQuery(baseOptions: Apollo.QueryHookOptions<GetAccountNoteQuery, GetAccountNoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountNoteQuery, GetAccountNoteQueryVariables>(GetAccountNoteDocument, options);
      }
export function useGetAccountNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountNoteQuery, GetAccountNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountNoteQuery, GetAccountNoteQueryVariables>(GetAccountNoteDocument, options);
        }
export type GetAccountNoteQueryHookResult = ReturnType<typeof useGetAccountNoteQuery>;
export type GetAccountNoteLazyQueryHookResult = ReturnType<typeof useGetAccountNoteLazyQuery>;
export type GetAccountNoteQueryResult = Apollo.QueryResult<GetAccountNoteQuery, GetAccountNoteQueryVariables>;
export const GetAccountVarianceNoteDocument = gql`
    query GetAccountVarianceNote($propertyId: ID!, $accountId: ID!, $year: Int!, $monthIndex: Int!, $versionType: VersionType!) {
  queryAccountVarianceNotes(
    input: {propertyId: $propertyId, accountIdFilter: [$accountId], year: $year, monthIndex: $monthIndex, versionType: $versionType}
  ) {
    year
    versionType
    monthIndex
    text
  }
}
    `;

/**
 * __useGetAccountVarianceNoteQuery__
 *
 * To run a query within a React component, call `useGetAccountVarianceNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountVarianceNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountVarianceNoteQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      accountId: // value for 'accountId'
 *      year: // value for 'year'
 *      monthIndex: // value for 'monthIndex'
 *      versionType: // value for 'versionType'
 *   },
 * });
 */
export function useGetAccountVarianceNoteQuery(baseOptions: Apollo.QueryHookOptions<GetAccountVarianceNoteQuery, GetAccountVarianceNoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountVarianceNoteQuery, GetAccountVarianceNoteQueryVariables>(GetAccountVarianceNoteDocument, options);
      }
export function useGetAccountVarianceNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountVarianceNoteQuery, GetAccountVarianceNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountVarianceNoteQuery, GetAccountVarianceNoteQueryVariables>(GetAccountVarianceNoteDocument, options);
        }
export type GetAccountVarianceNoteQueryHookResult = ReturnType<typeof useGetAccountVarianceNoteQuery>;
export type GetAccountVarianceNoteLazyQueryHookResult = ReturnType<typeof useGetAccountVarianceNoteLazyQuery>;
export type GetAccountVarianceNoteQueryResult = Apollo.QueryResult<GetAccountVarianceNoteQuery, GetAccountVarianceNoteQueryVariables>;
export const SetAccountVarianceNoteDocument = gql`
    mutation SetAccountVarianceNote($propertyId: String!, $accountId: String!, $year: Int!, $monthIndex: Int!, $versionType: VersionType!, $text: String!) {
  upsertAccountVarianceNote(
    input: {propertyId: $propertyId, accountId: $accountId, year: $year, monthIndex: $monthIndex, versionType: $versionType, text: $text}
  ) {
    year
    versionType
    monthIndex
    text
  }
}
    `;
export type SetAccountVarianceNoteMutationFn = Apollo.MutationFunction<SetAccountVarianceNoteMutation, SetAccountVarianceNoteMutationVariables>;

/**
 * __useSetAccountVarianceNoteMutation__
 *
 * To run a mutation, you first call `useSetAccountVarianceNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAccountVarianceNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAccountVarianceNoteMutation, { data, loading, error }] = useSetAccountVarianceNoteMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      accountId: // value for 'accountId'
 *      year: // value for 'year'
 *      monthIndex: // value for 'monthIndex'
 *      versionType: // value for 'versionType'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useSetAccountVarianceNoteMutation(baseOptions?: Apollo.MutationHookOptions<SetAccountVarianceNoteMutation, SetAccountVarianceNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAccountVarianceNoteMutation, SetAccountVarianceNoteMutationVariables>(SetAccountVarianceNoteDocument, options);
      }
export type SetAccountVarianceNoteMutationHookResult = ReturnType<typeof useSetAccountVarianceNoteMutation>;
export type SetAccountVarianceNoteMutationResult = Apollo.MutationResult<SetAccountVarianceNoteMutation>;
export type SetAccountVarianceNoteMutationOptions = Apollo.BaseMutationOptions<SetAccountVarianceNoteMutation, SetAccountVarianceNoteMutationVariables>;
export const GetIsAccountLockedDocument = gql`
    query GetIsAccountLocked($propertyId: ID!, $accountId: ID!, $budgetYear: Int!, $versionType: VersionType!) {
  getIsAccountLocked(
    propertyId: $propertyId
    accountId: $accountId
    budgetYear: $budgetYear
    versionType: $versionType
  )
}
    `;

/**
 * __useGetIsAccountLockedQuery__
 *
 * To run a query within a React component, call `useGetIsAccountLockedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsAccountLockedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsAccountLockedQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      accountId: // value for 'accountId'
 *      budgetYear: // value for 'budgetYear'
 *      versionType: // value for 'versionType'
 *   },
 * });
 */
export function useGetIsAccountLockedQuery(baseOptions: Apollo.QueryHookOptions<GetIsAccountLockedQuery, GetIsAccountLockedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIsAccountLockedQuery, GetIsAccountLockedQueryVariables>(GetIsAccountLockedDocument, options);
      }
export function useGetIsAccountLockedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIsAccountLockedQuery, GetIsAccountLockedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIsAccountLockedQuery, GetIsAccountLockedQueryVariables>(GetIsAccountLockedDocument, options);
        }
export type GetIsAccountLockedQueryHookResult = ReturnType<typeof useGetIsAccountLockedQuery>;
export type GetIsAccountLockedLazyQueryHookResult = ReturnType<typeof useGetIsAccountLockedLazyQuery>;
export type GetIsAccountLockedQueryResult = Apollo.QueryResult<GetIsAccountLockedQuery, GetIsAccountLockedQueryVariables>;
export const GetMultiPropertyAccountDriversDocument = gql`
    query GetMultiPropertyAccountDrivers($accountId: ID!, $yearsVersionTypes: [YearAndType!]!, $propertyIds: [ID!], $driverTypes: [DriverType!]) {
  multiPropertyAccountDrivers(
    accountId: $accountId
    yearsVersionTypes: $yearsVersionTypes
    propertyIds: $propertyIds
    driverTypes: $driverTypes
  ) {
    operational {
      sourceMetricId
      assumptionId
      sourceMetricName
      amount
      driverType
      percentageOf
      delayRecognition
      driverMetricTypeId
      locked
      propertyName
      propertyId
      accountId
      versionType
      effectiveEndYear
      effectiveEndMonthIndex
      effectiveStartYear
      effectiveStartMonthIndex
    }
    acctPercentage {
      sourceAccountId
      glNumber
      glName
      acctPercentageDriverAccountId
      delayRecognition
      locked
      propertyName
      propertyId
      assumptionId
      percentageOf
      accountId
      effectiveEndYear
      effectiveEndMonthIndex
      effectiveStartYear
      effectiveStartMonthIndex
      versionType
      driverType
    }
    worksheet {
      propertyId
      propertyName
      assumptionId
      lineId
      locked
      accountId
      versionType
      description
      values
    }
  }
}
    `;

/**
 * __useGetMultiPropertyAccountDriversQuery__
 *
 * To run a query within a React component, call `useGetMultiPropertyAccountDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMultiPropertyAccountDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMultiPropertyAccountDriversQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      yearsVersionTypes: // value for 'yearsVersionTypes'
 *      propertyIds: // value for 'propertyIds'
 *      driverTypes: // value for 'driverTypes'
 *   },
 * });
 */
export function useGetMultiPropertyAccountDriversQuery(baseOptions: Apollo.QueryHookOptions<GetMultiPropertyAccountDriversQuery, GetMultiPropertyAccountDriversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMultiPropertyAccountDriversQuery, GetMultiPropertyAccountDriversQueryVariables>(GetMultiPropertyAccountDriversDocument, options);
      }
export function useGetMultiPropertyAccountDriversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMultiPropertyAccountDriversQuery, GetMultiPropertyAccountDriversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMultiPropertyAccountDriversQuery, GetMultiPropertyAccountDriversQueryVariables>(GetMultiPropertyAccountDriversDocument, options);
        }
export type GetMultiPropertyAccountDriversQueryHookResult = ReturnType<typeof useGetMultiPropertyAccountDriversQuery>;
export type GetMultiPropertyAccountDriversLazyQueryHookResult = ReturnType<typeof useGetMultiPropertyAccountDriversLazyQuery>;
export type GetMultiPropertyAccountDriversQueryResult = Apollo.QueryResult<GetMultiPropertyAccountDriversQuery, GetMultiPropertyAccountDriversQueryVariables>;
export const GetSinglePropertyDriversAndWorksheetItemsDocument = gql`
    query GetSinglePropertyDriversAndWorksheetItems($propertyId: ID!, $accountId: ID!, $year: Int!, $versionType: VersionType!) {
  singlePropertyAccount(
    propertyId: $propertyId
    accountId: $accountId
    versionType: $versionType
    year: $year
  ) {
    year
    propertyId
    accountId
    versionType
    accountPercentageDriver {
      isDriven
      augments {
        minValue
        maxValue
      }
      sourceAccounts {
        accountId
        glNumber
        glName
        assumptionId
        acctPercentageDriverAccountId
        locked
        assumptionsAndValues {
          percentage
          lookbackPeriod
          accountValue
        }
      }
    }
    operationalMetricDriver {
      isDriven
      sourceMetrics {
        sourceMetricId
        metricName
        locked
        driverMetricTypeId
        assumptionsAndValues {
          amount
          lookbackPeriod
          percentage
          metricValue
        }
      }
    }
    worksheetDriver {
      isDriven
      worksheetLines {
        lineId
        description
        values
      }
    }
    payrollDriver {
      isDriven
      compensationItems {
        itemType
        positions {
          id
          name
        }
      }
    }
    renovationsDriver {
      isDriven
      costCategories {
        id
        name
      }
    }
    growthDriver {
      id
      year
      versionType
      propertyId
      accountId
      growthCalcMethod
      annualTargetValueManualEntry
      distributionMethod
      monthlyAdjustmentType
      monthlyAdjustmentValue
      monthlyAverageBasePeriod
      lookbackPeriodStart
      lookbackPeriodEnd
      percentGrowthBasePeriod
      percentGrowthAdjustmentValue
      annualValues
    }
    revenueDriver {
      isDriven
      sourceRevenueMetrics {
        sourceId
        revenueType
      }
    }
    customDriver {
      isDriven
      items {
        id
        itemName
        assumptions {
          amount
          count
          percentOf
        }
      }
    }
    originalValues
    originalValuesRaw
    accountValues
    overrideValues
    payrollBreakdown
  }
}
    `;

/**
 * __useGetSinglePropertyDriversAndWorksheetItemsQuery__
 *
 * To run a query within a React component, call `useGetSinglePropertyDriversAndWorksheetItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSinglePropertyDriversAndWorksheetItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSinglePropertyDriversAndWorksheetItemsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      accountId: // value for 'accountId'
 *      year: // value for 'year'
 *      versionType: // value for 'versionType'
 *   },
 * });
 */
export function useGetSinglePropertyDriversAndWorksheetItemsQuery(baseOptions: Apollo.QueryHookOptions<GetSinglePropertyDriversAndWorksheetItemsQuery, GetSinglePropertyDriversAndWorksheetItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSinglePropertyDriversAndWorksheetItemsQuery, GetSinglePropertyDriversAndWorksheetItemsQueryVariables>(GetSinglePropertyDriversAndWorksheetItemsDocument, options);
      }
export function useGetSinglePropertyDriversAndWorksheetItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSinglePropertyDriversAndWorksheetItemsQuery, GetSinglePropertyDriversAndWorksheetItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSinglePropertyDriversAndWorksheetItemsQuery, GetSinglePropertyDriversAndWorksheetItemsQueryVariables>(GetSinglePropertyDriversAndWorksheetItemsDocument, options);
        }
export type GetSinglePropertyDriversAndWorksheetItemsQueryHookResult = ReturnType<typeof useGetSinglePropertyDriversAndWorksheetItemsQuery>;
export type GetSinglePropertyDriversAndWorksheetItemsLazyQueryHookResult = ReturnType<typeof useGetSinglePropertyDriversAndWorksheetItemsLazyQuery>;
export type GetSinglePropertyDriversAndWorksheetItemsQueryResult = Apollo.QueryResult<GetSinglePropertyDriversAndWorksheetItemsQuery, GetSinglePropertyDriversAndWorksheetItemsQueryVariables>;
export const GetSinglePropertyDriversAndWorksheetItemsNoCalcEngineDocument = gql`
    query GetSinglePropertyDriversAndWorksheetItemsNoCalcEngine($propertyId: ID!, $accountId: ID!, $year: Int!, $versionType: VersionType!) {
  singlePropertyAccount(
    propertyId: $propertyId
    accountId: $accountId
    versionType: $versionType
    year: $year
  ) {
    year
    propertyId
    accountId
    versionType
    accountPercentageDriver {
      isDriven
      augments {
        minValue
        maxValue
      }
      sourceAccountAssumptions {
        accountId
        glNumber
        glName
        assumptionId
        acctPercentageDriverAccountId
        locked
        assumptions {
          percentage
          lookbackPeriod
        }
      }
    }
    operationalMetricDriver {
      isDriven
      sourceMetricAssumptions {
        sourceMetricId
        metricName
        locked
        driverMetricTypeId
        assumptions {
          amount
          lookbackPeriod
          percentage
        }
      }
    }
    worksheetDriver {
      isDriven
      worksheetLines {
        lineId
        description
        values
      }
    }
    payrollDriver {
      isDriven
      compensationItems {
        itemType
        positions {
          id
          name
        }
      }
    }
    renovationsDriver {
      isDriven
      costCategories {
        id
        name
      }
    }
    growthDriver {
      id
      year
      versionType
      propertyId
      accountId
      growthCalcMethod
      annualTargetValueManualEntry
      distributionMethod
      monthlyAdjustmentType
      monthlyAdjustmentValue
      monthlyAverageBasePeriod
      lookbackPeriodStart
      lookbackPeriodEnd
      percentGrowthBasePeriod
      percentGrowthAdjustmentValue
    }
    revenueDriver {
      isDriven
      sourceRevenueMetrics {
        sourceId
        revenueType
      }
    }
    customDriver {
      isDriven
      items {
        id
        itemName
        assumptions {
          amount
          count
          percentOf
        }
      }
    }
  }
}
    `;

/**
 * __useGetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQuery__
 *
 * To run a query within a React component, call `useGetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      accountId: // value for 'accountId'
 *      year: // value for 'year'
 *      versionType: // value for 'versionType'
 *   },
 * });
 */
export function useGetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQuery(baseOptions: Apollo.QueryHookOptions<GetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQuery, GetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQuery, GetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQueryVariables>(GetSinglePropertyDriversAndWorksheetItemsNoCalcEngineDocument, options);
      }
export function useGetSinglePropertyDriversAndWorksheetItemsNoCalcEngineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQuery, GetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQuery, GetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQueryVariables>(GetSinglePropertyDriversAndWorksheetItemsNoCalcEngineDocument, options);
        }
export type GetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQueryHookResult = ReturnType<typeof useGetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQuery>;
export type GetSinglePropertyDriversAndWorksheetItemsNoCalcEngineLazyQueryHookResult = ReturnType<typeof useGetSinglePropertyDriversAndWorksheetItemsNoCalcEngineLazyQuery>;
export type GetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQueryResult = Apollo.QueryResult<GetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQuery, GetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQueryVariables>;
export const SetAccountLockDocument = gql`
    mutation SetAccountLock($propertyId: ID!, $accountId: ID!, $budgetYear: Int!, $versionType: VersionType!, $locked: Boolean!) {
  setAccountLock(
    propertyId: $propertyId
    accountId: $accountId
    budgetYear: $budgetYear
    versionType: $versionType
    locked: $locked
  )
}
    `;
export type SetAccountLockMutationFn = Apollo.MutationFunction<SetAccountLockMutation, SetAccountLockMutationVariables>;

/**
 * __useSetAccountLockMutation__
 *
 * To run a mutation, you first call `useSetAccountLockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAccountLockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAccountLockMutation, { data, loading, error }] = useSetAccountLockMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      accountId: // value for 'accountId'
 *      budgetYear: // value for 'budgetYear'
 *      versionType: // value for 'versionType'
 *      locked: // value for 'locked'
 *   },
 * });
 */
export function useSetAccountLockMutation(baseOptions?: Apollo.MutationHookOptions<SetAccountLockMutation, SetAccountLockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAccountLockMutation, SetAccountLockMutationVariables>(SetAccountLockDocument, options);
      }
export type SetAccountLockMutationHookResult = ReturnType<typeof useSetAccountLockMutation>;
export type SetAccountLockMutationResult = Apollo.MutationResult<SetAccountLockMutation>;
export type SetAccountLockMutationOptions = Apollo.BaseMutationOptions<SetAccountLockMutation, SetAccountLockMutationVariables>;
export const OperationalMetricTypeDocument = gql`
    query operationalMetricType {
  operationalMetricTypes {
    id
    name
  }
}
    `;

/**
 * __useOperationalMetricTypeQuery__
 *
 * To run a query within a React component, call `useOperationalMetricTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperationalMetricTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperationalMetricTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useOperationalMetricTypeQuery(baseOptions?: Apollo.QueryHookOptions<OperationalMetricTypeQuery, OperationalMetricTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OperationalMetricTypeQuery, OperationalMetricTypeQueryVariables>(OperationalMetricTypeDocument, options);
      }
export function useOperationalMetricTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OperationalMetricTypeQuery, OperationalMetricTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OperationalMetricTypeQuery, OperationalMetricTypeQueryVariables>(OperationalMetricTypeDocument, options);
        }
export type OperationalMetricTypeQueryHookResult = ReturnType<typeof useOperationalMetricTypeQuery>;
export type OperationalMetricTypeLazyQueryHookResult = ReturnType<typeof useOperationalMetricTypeLazyQuery>;
export type OperationalMetricTypeQueryResult = Apollo.QueryResult<OperationalMetricTypeQuery, OperationalMetricTypeQueryVariables>;
export const RevenueTypesDocument = gql`
    query RevenueTypes {
  revenueTypes
}
    `;

/**
 * __useRevenueTypesQuery__
 *
 * To run a query within a React component, call `useRevenueTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRevenueTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRevenueTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRevenueTypesQuery(baseOptions?: Apollo.QueryHookOptions<RevenueTypesQuery, RevenueTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RevenueTypesQuery, RevenueTypesQueryVariables>(RevenueTypesDocument, options);
      }
export function useRevenueTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RevenueTypesQuery, RevenueTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RevenueTypesQuery, RevenueTypesQueryVariables>(RevenueTypesDocument, options);
        }
export type RevenueTypesQueryHookResult = ReturnType<typeof useRevenueTypesQuery>;
export type RevenueTypesLazyQueryHookResult = ReturnType<typeof useRevenueTypesLazyQuery>;
export type RevenueTypesQueryResult = Apollo.QueryResult<RevenueTypesQuery, RevenueTypesQueryVariables>;
export const SetDriversDocument = gql`
    mutation SetDrivers($accountId: String!, $propertyId: String!, $year: Int!, $versionType: VersionType!, $isUserRequestingWorksheetDeletion: Boolean!, $pendingUpdates: PendingUpdates!) {
  setDrivers(
    accountId: $accountId
    propertyId: $propertyId
    year: $year
    versionType: $versionType
    isUserRequestingWorksheetDeletion: $isUserRequestingWorksheetDeletion
    pendingUpdates: $pendingUpdates
  ) {
    versionType
    year
  }
}
    `;
export type SetDriversMutationFn = Apollo.MutationFunction<SetDriversMutation, SetDriversMutationVariables>;

/**
 * __useSetDriversMutation__
 *
 * To run a mutation, you first call `useSetDriversMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDriversMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDriversMutation, { data, loading, error }] = useSetDriversMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      propertyId: // value for 'propertyId'
 *      year: // value for 'year'
 *      versionType: // value for 'versionType'
 *      isUserRequestingWorksheetDeletion: // value for 'isUserRequestingWorksheetDeletion'
 *      pendingUpdates: // value for 'pendingUpdates'
 *   },
 * });
 */
export function useSetDriversMutation(baseOptions?: Apollo.MutationHookOptions<SetDriversMutation, SetDriversMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDriversMutation, SetDriversMutationVariables>(SetDriversDocument, options);
      }
export type SetDriversMutationHookResult = ReturnType<typeof useSetDriversMutation>;
export type SetDriversMutationResult = Apollo.MutationResult<SetDriversMutation>;
export type SetDriversMutationOptions = Apollo.BaseMutationOptions<SetDriversMutation, SetDriversMutationVariables>;
export const SetForecastPeriodLocksDocument = gql`
    mutation SetForecastPeriodLocks($propertyId: String!, $budgetYear: Int!, $locks: PropertyForecastLocksArgs!) {
  setPropertyForecastPeriodLocks(
    propertyId: $propertyId
    budgetYear: $budgetYear
    locks: $locks
  ) {
    isBudgetLocked
    isReforecastLocked
  }
}
    `;
export type SetForecastPeriodLocksMutationFn = Apollo.MutationFunction<SetForecastPeriodLocksMutation, SetForecastPeriodLocksMutationVariables>;

/**
 * __useSetForecastPeriodLocksMutation__
 *
 * To run a mutation, you first call `useSetForecastPeriodLocksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetForecastPeriodLocksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setForecastPeriodLocksMutation, { data, loading, error }] = useSetForecastPeriodLocksMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      locks: // value for 'locks'
 *   },
 * });
 */
export function useSetForecastPeriodLocksMutation(baseOptions?: Apollo.MutationHookOptions<SetForecastPeriodLocksMutation, SetForecastPeriodLocksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetForecastPeriodLocksMutation, SetForecastPeriodLocksMutationVariables>(SetForecastPeriodLocksDocument, options);
      }
export type SetForecastPeriodLocksMutationHookResult = ReturnType<typeof useSetForecastPeriodLocksMutation>;
export type SetForecastPeriodLocksMutationResult = Apollo.MutationResult<SetForecastPeriodLocksMutation>;
export type SetForecastPeriodLocksMutationOptions = Apollo.BaseMutationOptions<SetForecastPeriodLocksMutation, SetForecastPeriodLocksMutationVariables>;
export const SetMultiAccountAndPropertyLocksDocument = gql`
    mutation SetMultiAccountAndPropertyLocks($propertyIds: [String!]!, $accountIds: [String!]!, $budgetYear: Int!, $lockRfcst: Boolean, $lockBdgt: Boolean) {
  setMultipleAccountAndPropertyLocks(
    propertyIds: $propertyIds
    accountIds: $accountIds
    budgetYear: $budgetYear
    lockRfcst: $lockRfcst
    lockBdgt: $lockBdgt
  )
}
    `;
export type SetMultiAccountAndPropertyLocksMutationFn = Apollo.MutationFunction<SetMultiAccountAndPropertyLocksMutation, SetMultiAccountAndPropertyLocksMutationVariables>;

/**
 * __useSetMultiAccountAndPropertyLocksMutation__
 *
 * To run a mutation, you first call `useSetMultiAccountAndPropertyLocksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMultiAccountAndPropertyLocksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMultiAccountAndPropertyLocksMutation, { data, loading, error }] = useSetMultiAccountAndPropertyLocksMutation({
 *   variables: {
 *      propertyIds: // value for 'propertyIds'
 *      accountIds: // value for 'accountIds'
 *      budgetYear: // value for 'budgetYear'
 *      lockRfcst: // value for 'lockRfcst'
 *      lockBdgt: // value for 'lockBdgt'
 *   },
 * });
 */
export function useSetMultiAccountAndPropertyLocksMutation(baseOptions?: Apollo.MutationHookOptions<SetMultiAccountAndPropertyLocksMutation, SetMultiAccountAndPropertyLocksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMultiAccountAndPropertyLocksMutation, SetMultiAccountAndPropertyLocksMutationVariables>(SetMultiAccountAndPropertyLocksDocument, options);
      }
export type SetMultiAccountAndPropertyLocksMutationHookResult = ReturnType<typeof useSetMultiAccountAndPropertyLocksMutation>;
export type SetMultiAccountAndPropertyLocksMutationResult = Apollo.MutationResult<SetMultiAccountAndPropertyLocksMutation>;
export type SetMultiAccountAndPropertyLocksMutationOptions = Apollo.BaseMutationOptions<SetMultiAccountAndPropertyLocksMutation, SetMultiAccountAndPropertyLocksMutationVariables>;
export const SetMultiForecastPeriodLocksDocument = gql`
    mutation SetMultiForecastPeriodLocks($propertyIds: [String!]!, $budgetYear: Int!, $locks: PropertyForecastLocksArgs!) {
  setMultiPropertyForecastPeriodLocks(
    propertyIds: $propertyIds
    budgetYear: $budgetYear
    locks: $locks
  ) {
    propertyId
    isBudgetLocked
    isReforecastLocked
  }
}
    `;
export type SetMultiForecastPeriodLocksMutationFn = Apollo.MutationFunction<SetMultiForecastPeriodLocksMutation, SetMultiForecastPeriodLocksMutationVariables>;

/**
 * __useSetMultiForecastPeriodLocksMutation__
 *
 * To run a mutation, you first call `useSetMultiForecastPeriodLocksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMultiForecastPeriodLocksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMultiForecastPeriodLocksMutation, { data, loading, error }] = useSetMultiForecastPeriodLocksMutation({
 *   variables: {
 *      propertyIds: // value for 'propertyIds'
 *      budgetYear: // value for 'budgetYear'
 *      locks: // value for 'locks'
 *   },
 * });
 */
export function useSetMultiForecastPeriodLocksMutation(baseOptions?: Apollo.MutationHookOptions<SetMultiForecastPeriodLocksMutation, SetMultiForecastPeriodLocksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMultiForecastPeriodLocksMutation, SetMultiForecastPeriodLocksMutationVariables>(SetMultiForecastPeriodLocksDocument, options);
      }
export type SetMultiForecastPeriodLocksMutationHookResult = ReturnType<typeof useSetMultiForecastPeriodLocksMutation>;
export type SetMultiForecastPeriodLocksMutationResult = Apollo.MutationResult<SetMultiForecastPeriodLocksMutation>;
export type SetMultiForecastPeriodLocksMutationOptions = Apollo.BaseMutationOptions<SetMultiForecastPeriodLocksMutation, SetMultiForecastPeriodLocksMutationVariables>;
export const SetPropertyDriversDocument = gql`
    mutation SetPropertyDrivers($propertyId: String!, $year: Int!, $versionType: VersionType!, $items: [SetPropertyDriversArgsItem!]!, $accountLocks: [AccountLocksArgsItem!]!) {
  setPropertyDrivers(
    propertyId: $propertyId
    year: $year
    versionType: $versionType
    items: $items
    accountLocks: $accountLocks
  ) {
    versionType
    year
  }
}
    `;
export type SetPropertyDriversMutationFn = Apollo.MutationFunction<SetPropertyDriversMutation, SetPropertyDriversMutationVariables>;

/**
 * __useSetPropertyDriversMutation__
 *
 * To run a mutation, you first call `useSetPropertyDriversMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPropertyDriversMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPropertyDriversMutation, { data, loading, error }] = useSetPropertyDriversMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      year: // value for 'year'
 *      versionType: // value for 'versionType'
 *      items: // value for 'items'
 *      accountLocks: // value for 'accountLocks'
 *   },
 * });
 */
export function useSetPropertyDriversMutation(baseOptions?: Apollo.MutationHookOptions<SetPropertyDriversMutation, SetPropertyDriversMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPropertyDriversMutation, SetPropertyDriversMutationVariables>(SetPropertyDriversDocument, options);
      }
export type SetPropertyDriversMutationHookResult = ReturnType<typeof useSetPropertyDriversMutation>;
export type SetPropertyDriversMutationResult = Apollo.MutationResult<SetPropertyDriversMutation>;
export type SetPropertyDriversMutationOptions = Apollo.BaseMutationOptions<SetPropertyDriversMutation, SetPropertyDriversMutationVariables>;
export const GetBulkExportDownloadLinkFromS3Document = gql`
    query GetBulkExportDownloadLinkFromS3($date: String!, $reportType: BulkReportType!) {
  downloadLink(date: $date, reportType: $reportType)
}
    `;

/**
 * __useGetBulkExportDownloadLinkFromS3Query__
 *
 * To run a query within a React component, call `useGetBulkExportDownloadLinkFromS3Query` and pass it any options that fit your needs.
 * When your component renders, `useGetBulkExportDownloadLinkFromS3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBulkExportDownloadLinkFromS3Query({
 *   variables: {
 *      date: // value for 'date'
 *      reportType: // value for 'reportType'
 *   },
 * });
 */
export function useGetBulkExportDownloadLinkFromS3Query(baseOptions: Apollo.QueryHookOptions<GetBulkExportDownloadLinkFromS3Query, GetBulkExportDownloadLinkFromS3QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBulkExportDownloadLinkFromS3Query, GetBulkExportDownloadLinkFromS3QueryVariables>(GetBulkExportDownloadLinkFromS3Document, options);
      }
export function useGetBulkExportDownloadLinkFromS3LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBulkExportDownloadLinkFromS3Query, GetBulkExportDownloadLinkFromS3QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBulkExportDownloadLinkFromS3Query, GetBulkExportDownloadLinkFromS3QueryVariables>(GetBulkExportDownloadLinkFromS3Document, options);
        }
export type GetBulkExportDownloadLinkFromS3QueryHookResult = ReturnType<typeof useGetBulkExportDownloadLinkFromS3Query>;
export type GetBulkExportDownloadLinkFromS3LazyQueryHookResult = ReturnType<typeof useGetBulkExportDownloadLinkFromS3LazyQuery>;
export type GetBulkExportDownloadLinkFromS3QueryResult = Apollo.QueryResult<GetBulkExportDownloadLinkFromS3Query, GetBulkExportDownloadLinkFromS3QueryVariables>;
export const GetFinancialReportsDocument = gql`
    query GetFinancialReports {
  financialReports {
    dateNoTimeString
    fileName
    path
    lastModified
  }
}
    `;

/**
 * __useGetFinancialReportsQuery__
 *
 * To run a query within a React component, call `useGetFinancialReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFinancialReportsQuery(baseOptions?: Apollo.QueryHookOptions<GetFinancialReportsQuery, GetFinancialReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFinancialReportsQuery, GetFinancialReportsQueryVariables>(GetFinancialReportsDocument, options);
      }
export function useGetFinancialReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFinancialReportsQuery, GetFinancialReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFinancialReportsQuery, GetFinancialReportsQueryVariables>(GetFinancialReportsDocument, options);
        }
export type GetFinancialReportsQueryHookResult = ReturnType<typeof useGetFinancialReportsQuery>;
export type GetFinancialReportsLazyQueryHookResult = ReturnType<typeof useGetFinancialReportsLazyQuery>;
export type GetFinancialReportsQueryResult = Apollo.QueryResult<GetFinancialReportsQuery, GetFinancialReportsQueryVariables>;
export const GetOperationalReportsDocument = gql`
    query GetOperationalReports {
  operationalReports {
    dateNoTimeString
    fileName
    path
    lastModified
  }
}
    `;

/**
 * __useGetOperationalReportsQuery__
 *
 * To run a query within a React component, call `useGetOperationalReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperationalReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperationalReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOperationalReportsQuery(baseOptions?: Apollo.QueryHookOptions<GetOperationalReportsQuery, GetOperationalReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOperationalReportsQuery, GetOperationalReportsQueryVariables>(GetOperationalReportsDocument, options);
      }
export function useGetOperationalReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOperationalReportsQuery, GetOperationalReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOperationalReportsQuery, GetOperationalReportsQueryVariables>(GetOperationalReportsDocument, options);
        }
export type GetOperationalReportsQueryHookResult = ReturnType<typeof useGetOperationalReportsQuery>;
export type GetOperationalReportsLazyQueryHookResult = ReturnType<typeof useGetOperationalReportsLazyQuery>;
export type GetOperationalReportsQueryResult = Apollo.QueryResult<GetOperationalReportsQuery, GetOperationalReportsQueryVariables>;
export const UpdateCombinedDriverAssumptionsDocument = gql`
    mutation updateCombinedDriverAssumptions($accPercentageAssumptionItems: [AccPercentageDriverUpdateInput!]!, $operationalMetricAssumptionItems: [OperationalDriverUpdateInput!]!) {
  updateAccPercentageDrivers(items: $accPercentageAssumptionItems) {
    sourceAccountId: accountV2Id
    accPercentageAssumptions {
      accPercentageDriverAccountId
      percentage: percentOf
      monthsAgo: delayRecognition
      locked: locked
    }
  }
  updateOperationalDrivers(items: $operationalMetricAssumptionItems)
}
    `;
export type UpdateCombinedDriverAssumptionsMutationFn = Apollo.MutationFunction<UpdateCombinedDriverAssumptionsMutation, UpdateCombinedDriverAssumptionsMutationVariables>;

/**
 * __useUpdateCombinedDriverAssumptionsMutation__
 *
 * To run a mutation, you first call `useUpdateCombinedDriverAssumptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCombinedDriverAssumptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCombinedDriverAssumptionsMutation, { data, loading, error }] = useUpdateCombinedDriverAssumptionsMutation({
 *   variables: {
 *      accPercentageAssumptionItems: // value for 'accPercentageAssumptionItems'
 *      operationalMetricAssumptionItems: // value for 'operationalMetricAssumptionItems'
 *   },
 * });
 */
export function useUpdateCombinedDriverAssumptionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCombinedDriverAssumptionsMutation, UpdateCombinedDriverAssumptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCombinedDriverAssumptionsMutation, UpdateCombinedDriverAssumptionsMutationVariables>(UpdateCombinedDriverAssumptionsDocument, options);
      }
export type UpdateCombinedDriverAssumptionsMutationHookResult = ReturnType<typeof useUpdateCombinedDriverAssumptionsMutation>;
export type UpdateCombinedDriverAssumptionsMutationResult = Apollo.MutationResult<UpdateCombinedDriverAssumptionsMutation>;
export type UpdateCombinedDriverAssumptionsMutationOptions = Apollo.BaseMutationOptions<UpdateCombinedDriverAssumptionsMutation, UpdateCombinedDriverAssumptionsMutationVariables>;
export const UpdateWorksheetDataDocument = gql`
    mutation UpdateWorksheetData($creates: [WorksheetDriverLineCreateInput!]!, $updates: [WorksheetDriverLineUpdateInput!]!, $removeLineIds: [String!]!) {
  createWorksheetDriverData(inputs: $creates) {
    id
  }
  updateWorksheetDriverData(creates: [], updates: $updates)
  removeWorksheetDriverData(lineIds: $removeLineIds)
}
    `;
export type UpdateWorksheetDataMutationFn = Apollo.MutationFunction<UpdateWorksheetDataMutation, UpdateWorksheetDataMutationVariables>;

/**
 * __useUpdateWorksheetDataMutation__
 *
 * To run a mutation, you first call `useUpdateWorksheetDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorksheetDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorksheetDataMutation, { data, loading, error }] = useUpdateWorksheetDataMutation({
 *   variables: {
 *      creates: // value for 'creates'
 *      updates: // value for 'updates'
 *      removeLineIds: // value for 'removeLineIds'
 *   },
 * });
 */
export function useUpdateWorksheetDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorksheetDataMutation, UpdateWorksheetDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorksheetDataMutation, UpdateWorksheetDataMutationVariables>(UpdateWorksheetDataDocument, options);
      }
export type UpdateWorksheetDataMutationHookResult = ReturnType<typeof useUpdateWorksheetDataMutation>;
export type UpdateWorksheetDataMutationResult = Apollo.MutationResult<UpdateWorksheetDataMutation>;
export type UpdateWorksheetDataMutationOptions = Apollo.BaseMutationOptions<UpdateWorksheetDataMutation, UpdateWorksheetDataMutationVariables>;
export const CreateFileUploadRequestDocument = gql`
    mutation CreateFileUploadRequest($input: FileUploadRequestCreateInput!) {
  createFileUploadRequest(input: $input) {
    id
  }
}
    `;
export type CreateFileUploadRequestMutationFn = Apollo.MutationFunction<CreateFileUploadRequestMutation, CreateFileUploadRequestMutationVariables>;

/**
 * __useCreateFileUploadRequestMutation__
 *
 * To run a mutation, you first call `useCreateFileUploadRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileUploadRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileUploadRequestMutation, { data, loading, error }] = useCreateFileUploadRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFileUploadRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateFileUploadRequestMutation, CreateFileUploadRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFileUploadRequestMutation, CreateFileUploadRequestMutationVariables>(CreateFileUploadRequestDocument, options);
      }
export type CreateFileUploadRequestMutationHookResult = ReturnType<typeof useCreateFileUploadRequestMutation>;
export type CreateFileUploadRequestMutationResult = Apollo.MutationResult<CreateFileUploadRequestMutation>;
export type CreateFileUploadRequestMutationOptions = Apollo.BaseMutationOptions<CreateFileUploadRequestMutation, CreateFileUploadRequestMutationVariables>;
export const QueryFileUploadRequestDocument = gql`
    query QueryFileUploadRequest($input: FileUploadRequestQueryInput!) {
  queryFileUploadRequests(input: $input) {
    id
    status
    errors
  }
}
    `;

/**
 * __useQueryFileUploadRequestQuery__
 *
 * To run a query within a React component, call `useQueryFileUploadRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFileUploadRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFileUploadRequestQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryFileUploadRequestQuery(baseOptions: Apollo.QueryHookOptions<QueryFileUploadRequestQuery, QueryFileUploadRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryFileUploadRequestQuery, QueryFileUploadRequestQueryVariables>(QueryFileUploadRequestDocument, options);
      }
export function useQueryFileUploadRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryFileUploadRequestQuery, QueryFileUploadRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryFileUploadRequestQuery, QueryFileUploadRequestQueryVariables>(QueryFileUploadRequestDocument, options);
        }
export type QueryFileUploadRequestQueryHookResult = ReturnType<typeof useQueryFileUploadRequestQuery>;
export type QueryFileUploadRequestLazyQueryHookResult = ReturnType<typeof useQueryFileUploadRequestLazyQuery>;
export type QueryFileUploadRequestQueryResult = Apollo.QueryResult<QueryFileUploadRequestQuery, QueryFileUploadRequestQueryVariables>;
export const GetFinancialValuesForYearDocument = gql`
    query GetFinancialValuesForYear($propertyIds: [ID!]!, $entityIds: [ID!]!, $years: [YearAndType!]!) {
  financialValuesForYear(
    propertyIds: $propertyIds
    entityIds: $entityIds
    years: $years
  ) {
    entityId
    entityType
    year
    versionType
    propertyId
    values
  }
}
    `;

/**
 * __useGetFinancialValuesForYearQuery__
 *
 * To run a query within a React component, call `useGetFinancialValuesForYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialValuesForYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialValuesForYearQuery({
 *   variables: {
 *      propertyIds: // value for 'propertyIds'
 *      entityIds: // value for 'entityIds'
 *      years: // value for 'years'
 *   },
 * });
 */
export function useGetFinancialValuesForYearQuery(baseOptions: Apollo.QueryHookOptions<GetFinancialValuesForYearQuery, GetFinancialValuesForYearQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFinancialValuesForYearQuery, GetFinancialValuesForYearQueryVariables>(GetFinancialValuesForYearDocument, options);
      }
export function useGetFinancialValuesForYearLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFinancialValuesForYearQuery, GetFinancialValuesForYearQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFinancialValuesForYearQuery, GetFinancialValuesForYearQueryVariables>(GetFinancialValuesForYearDocument, options);
        }
export type GetFinancialValuesForYearQueryHookResult = ReturnType<typeof useGetFinancialValuesForYearQuery>;
export type GetFinancialValuesForYearLazyQueryHookResult = ReturnType<typeof useGetFinancialValuesForYearLazyQuery>;
export type GetFinancialValuesForYearQueryResult = Apollo.QueryResult<GetFinancialValuesForYearQuery, GetFinancialValuesForYearQueryVariables>;
export const GetForecastLocksDocument = gql`
    query GetForecastLocks($propertyId: ID!, $budgetYear: Int!, $accountId: ID) {
  forecastLocks(
    propertyId: $propertyId
    budgetYear: $budgetYear
    accountId: $accountId
  ) {
    property {
      reforecastLocked
      budgetLocked
    }
    account {
      reforecastLocked
      budgetLocked
    }
  }
}
    `;

/**
 * __useGetForecastLocksQuery__
 *
 * To run a query within a React component, call `useGetForecastLocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetForecastLocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetForecastLocksQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetForecastLocksQuery(baseOptions: Apollo.QueryHookOptions<GetForecastLocksQuery, GetForecastLocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetForecastLocksQuery, GetForecastLocksQueryVariables>(GetForecastLocksDocument, options);
      }
export function useGetForecastLocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetForecastLocksQuery, GetForecastLocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetForecastLocksQuery, GetForecastLocksQueryVariables>(GetForecastLocksDocument, options);
        }
export type GetForecastLocksQueryHookResult = ReturnType<typeof useGetForecastLocksQuery>;
export type GetForecastLocksLazyQueryHookResult = ReturnType<typeof useGetForecastLocksLazyQuery>;
export type GetForecastLocksQueryResult = Apollo.QueryResult<GetForecastLocksQuery, GetForecastLocksQueryVariables>;
export const GetPropertyForecastLocksDocument = gql`
    query GetPropertyForecastLocks($propertyId: ID!, $budgetYear: Int!) {
  propertyForecastLocks(propertyId: $propertyId, budgetYear: $budgetYear) {
    isReforecastLocked
    isBudgetLocked
  }
}
    `;

/**
 * __useGetPropertyForecastLocksQuery__
 *
 * To run a query within a React component, call `useGetPropertyForecastLocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyForecastLocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyForecastLocksQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *   },
 * });
 */
export function useGetPropertyForecastLocksQuery(baseOptions: Apollo.QueryHookOptions<GetPropertyForecastLocksQuery, GetPropertyForecastLocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPropertyForecastLocksQuery, GetPropertyForecastLocksQueryVariables>(GetPropertyForecastLocksDocument, options);
      }
export function useGetPropertyForecastLocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropertyForecastLocksQuery, GetPropertyForecastLocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPropertyForecastLocksQuery, GetPropertyForecastLocksQueryVariables>(GetPropertyForecastLocksDocument, options);
        }
export type GetPropertyForecastLocksQueryHookResult = ReturnType<typeof useGetPropertyForecastLocksQuery>;
export type GetPropertyForecastLocksLazyQueryHookResult = ReturnType<typeof useGetPropertyForecastLocksLazyQuery>;
export type GetPropertyForecastLocksQueryResult = Apollo.QueryResult<GetPropertyForecastLocksQuery, GetPropertyForecastLocksQueryVariables>;
export const GetUserPropertiesForecastLocksDocument = gql`
    query GetUserPropertiesForecastLocks($budgetYear: Int!) {
  userPropertiesForecastLocks(budgetYear: $budgetYear) {
    reforecastLocked
    budgetLocked
  }
}
    `;

/**
 * __useGetUserPropertiesForecastLocksQuery__
 *
 * To run a query within a React component, call `useGetUserPropertiesForecastLocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPropertiesForecastLocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPropertiesForecastLocksQuery({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *   },
 * });
 */
export function useGetUserPropertiesForecastLocksQuery(baseOptions: Apollo.QueryHookOptions<GetUserPropertiesForecastLocksQuery, GetUserPropertiesForecastLocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPropertiesForecastLocksQuery, GetUserPropertiesForecastLocksQueryVariables>(GetUserPropertiesForecastLocksDocument, options);
      }
export function useGetUserPropertiesForecastLocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPropertiesForecastLocksQuery, GetUserPropertiesForecastLocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPropertiesForecastLocksQuery, GetUserPropertiesForecastLocksQueryVariables>(GetUserPropertiesForecastLocksDocument, options);
        }
export type GetUserPropertiesForecastLocksQueryHookResult = ReturnType<typeof useGetUserPropertiesForecastLocksQuery>;
export type GetUserPropertiesForecastLocksLazyQueryHookResult = ReturnType<typeof useGetUserPropertiesForecastLocksLazyQuery>;
export type GetUserPropertiesForecastLocksQueryResult = Apollo.QueryResult<GetUserPropertiesForecastLocksQuery, GetUserPropertiesForecastLocksQueryVariables>;
export const GetWorksheetLinesForWorksheetLinesReportDocument = gql`
    query GetWorksheetLinesForWorksheetLinesReport($propertyIds: [ID!]!) {
  worksheetLines(
    propertyIds: $propertyIds
    accountIds: []
    versionTypes: [REFORECAST, BUDGET]
  ) {
    propertyId
    accountId
    versionType
    description
    values
  }
}
    `;

/**
 * __useGetWorksheetLinesForWorksheetLinesReportQuery__
 *
 * To run a query within a React component, call `useGetWorksheetLinesForWorksheetLinesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorksheetLinesForWorksheetLinesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorksheetLinesForWorksheetLinesReportQuery({
 *   variables: {
 *      propertyIds: // value for 'propertyIds'
 *   },
 * });
 */
export function useGetWorksheetLinesForWorksheetLinesReportQuery(baseOptions: Apollo.QueryHookOptions<GetWorksheetLinesForWorksheetLinesReportQuery, GetWorksheetLinesForWorksheetLinesReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorksheetLinesForWorksheetLinesReportQuery, GetWorksheetLinesForWorksheetLinesReportQueryVariables>(GetWorksheetLinesForWorksheetLinesReportDocument, options);
      }
export function useGetWorksheetLinesForWorksheetLinesReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorksheetLinesForWorksheetLinesReportQuery, GetWorksheetLinesForWorksheetLinesReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorksheetLinesForWorksheetLinesReportQuery, GetWorksheetLinesForWorksheetLinesReportQueryVariables>(GetWorksheetLinesForWorksheetLinesReportDocument, options);
        }
export type GetWorksheetLinesForWorksheetLinesReportQueryHookResult = ReturnType<typeof useGetWorksheetLinesForWorksheetLinesReportQuery>;
export type GetWorksheetLinesForWorksheetLinesReportLazyQueryHookResult = ReturnType<typeof useGetWorksheetLinesForWorksheetLinesReportLazyQuery>;
export type GetWorksheetLinesForWorksheetLinesReportQueryResult = Apollo.QueryResult<GetWorksheetLinesForWorksheetLinesReportQuery, GetWorksheetLinesForWorksheetLinesReportQueryVariables>;
export const GetAccountVarianceInsightsDocument = gql`
    query GetAccountVarianceInsights($accountId: String!, $propertyId: String!, $year: Int!, $month: Int!) {
  getAccountVarianceInsights(
    accountId: $accountId
    propertyId: $propertyId
    year: $year
    month: $month
  ) {
    account {
      budgetComponentType
      negate
    }
    insights {
      trends {
        currentValue
        previousMonth {
          year
          monthIndex
          varianceAmount
          variancePercentage
          value
        }
        previousYear {
          year
          monthIndex
          varianceAmount
          variancePercentage
          value
        }
      }
      glContributions {
        id
        type
        name
        glNumber
        percentContributed
        parentTotal
      }
      annualBudgetRemaining {
        year
        budgetTotal
        percentRemaining
        totalRemaining
      }
      topVendors {
        vendorName
        amount
      }
    }
  }
}
    `;

/**
 * __useGetAccountVarianceInsightsQuery__
 *
 * To run a query within a React component, call `useGetAccountVarianceInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountVarianceInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountVarianceInsightsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      propertyId: // value for 'propertyId'
 *      year: // value for 'year'
 *      month: // value for 'month'
 *   },
 * });
 */
export function useGetAccountVarianceInsightsQuery(baseOptions: Apollo.QueryHookOptions<GetAccountVarianceInsightsQuery, GetAccountVarianceInsightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountVarianceInsightsQuery, GetAccountVarianceInsightsQueryVariables>(GetAccountVarianceInsightsDocument, options);
      }
export function useGetAccountVarianceInsightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountVarianceInsightsQuery, GetAccountVarianceInsightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountVarianceInsightsQuery, GetAccountVarianceInsightsQueryVariables>(GetAccountVarianceInsightsDocument, options);
        }
export type GetAccountVarianceInsightsQueryHookResult = ReturnType<typeof useGetAccountVarianceInsightsQuery>;
export type GetAccountVarianceInsightsLazyQueryHookResult = ReturnType<typeof useGetAccountVarianceInsightsLazyQuery>;
export type GetAccountVarianceInsightsQueryResult = Apollo.QueryResult<GetAccountVarianceInsightsQuery, GetAccountVarianceInsightsQueryVariables>;
export const GetMvrBudgetAssumptionsForPropertyAndAccountDocument = gql`
    query GetMVRBudgetAssumptionsForPropertyAndAccount($propertyId: ID!, $accountId: ID!, $year: Int!) {
  singlePropertyAccount(
    propertyId: $propertyId
    accountId: $accountId
    versionType: BUDGET
    year: $year
  ) {
    propertyId
    accountId
    versionType
    accountPercentageDriver {
      isDriven
      sourceAccounts {
        accountId
        glNumber
        glName
        assumptionId
        acctPercentageDriverAccountId
        locked
        assumptionsAndValues {
          percentage
          lookbackPeriod
          accountValue
        }
      }
    }
    operationalMetricDriver {
      isDriven
      sourceMetrics {
        sourceMetricId
        metricName
        locked
        driverMetricTypeId
        assumptionsAndValues {
          amount
          lookbackPeriod
          percentage
          metricValue
        }
      }
    }
    worksheetDriver {
      isDriven
      worksheetLines {
        description
        values
      }
    }
    payrollDriver {
      isDriven
      compensationItems {
        itemType
        positions {
          id
          name
        }
      }
    }
    renovationsDriver {
      isDriven
      costCategories {
        id
        name
      }
    }
    revenueDriver {
      isDriven
      sourceRevenueMetrics {
        sourceId
        revenueType
      }
    }
    originalValues
  }
}
    `;

/**
 * __useGetMvrBudgetAssumptionsForPropertyAndAccountQuery__
 *
 * To run a query within a React component, call `useGetMvrBudgetAssumptionsForPropertyAndAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMvrBudgetAssumptionsForPropertyAndAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMvrBudgetAssumptionsForPropertyAndAccountQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      accountId: // value for 'accountId'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetMvrBudgetAssumptionsForPropertyAndAccountQuery(baseOptions: Apollo.QueryHookOptions<GetMvrBudgetAssumptionsForPropertyAndAccountQuery, GetMvrBudgetAssumptionsForPropertyAndAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMvrBudgetAssumptionsForPropertyAndAccountQuery, GetMvrBudgetAssumptionsForPropertyAndAccountQueryVariables>(GetMvrBudgetAssumptionsForPropertyAndAccountDocument, options);
      }
export function useGetMvrBudgetAssumptionsForPropertyAndAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMvrBudgetAssumptionsForPropertyAndAccountQuery, GetMvrBudgetAssumptionsForPropertyAndAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMvrBudgetAssumptionsForPropertyAndAccountQuery, GetMvrBudgetAssumptionsForPropertyAndAccountQueryVariables>(GetMvrBudgetAssumptionsForPropertyAndAccountDocument, options);
        }
export type GetMvrBudgetAssumptionsForPropertyAndAccountQueryHookResult = ReturnType<typeof useGetMvrBudgetAssumptionsForPropertyAndAccountQuery>;
export type GetMvrBudgetAssumptionsForPropertyAndAccountLazyQueryHookResult = ReturnType<typeof useGetMvrBudgetAssumptionsForPropertyAndAccountLazyQuery>;
export type GetMvrBudgetAssumptionsForPropertyAndAccountQueryResult = Apollo.QueryResult<GetMvrBudgetAssumptionsForPropertyAndAccountQuery, GetMvrBudgetAssumptionsForPropertyAndAccountQueryVariables>;
export const GetMvrFinanciaValuesWithVarianceNotesDocument = gql`
    query GetMVRFinanciaValuesWithVarianceNotes($propertyId: ID!, $year: Int!, $varianceNoteMonthIndex: Int!) {
  financialValuesForYear(
    propertyIds: [$propertyId]
    entityIds: []
    years: [{year: $year, versionType: ACTUALS}, {year: $year, versionType: BUDGET}]
  ) {
    entityId
    entityType
    year
    versionType
    propertyId
    values
  }
  queryAccountVarianceNotes(
    input: {propertyId: $propertyId, year: $year, monthIndex: $varianceNoteMonthIndex, versionType: BUDGET}
  ) {
    accountId
    year
    versionType
    monthIndex
    text
  }
}
    `;

/**
 * __useGetMvrFinanciaValuesWithVarianceNotesQuery__
 *
 * To run a query within a React component, call `useGetMvrFinanciaValuesWithVarianceNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMvrFinanciaValuesWithVarianceNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMvrFinanciaValuesWithVarianceNotesQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      year: // value for 'year'
 *      varianceNoteMonthIndex: // value for 'varianceNoteMonthIndex'
 *   },
 * });
 */
export function useGetMvrFinanciaValuesWithVarianceNotesQuery(baseOptions: Apollo.QueryHookOptions<GetMvrFinanciaValuesWithVarianceNotesQuery, GetMvrFinanciaValuesWithVarianceNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMvrFinanciaValuesWithVarianceNotesQuery, GetMvrFinanciaValuesWithVarianceNotesQueryVariables>(GetMvrFinanciaValuesWithVarianceNotesDocument, options);
      }
export function useGetMvrFinanciaValuesWithVarianceNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMvrFinanciaValuesWithVarianceNotesQuery, GetMvrFinanciaValuesWithVarianceNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMvrFinanciaValuesWithVarianceNotesQuery, GetMvrFinanciaValuesWithVarianceNotesQueryVariables>(GetMvrFinanciaValuesWithVarianceNotesDocument, options);
        }
export type GetMvrFinanciaValuesWithVarianceNotesQueryHookResult = ReturnType<typeof useGetMvrFinanciaValuesWithVarianceNotesQuery>;
export type GetMvrFinanciaValuesWithVarianceNotesLazyQueryHookResult = ReturnType<typeof useGetMvrFinanciaValuesWithVarianceNotesLazyQuery>;
export type GetMvrFinanciaValuesWithVarianceNotesQueryResult = Apollo.QueryResult<GetMvrFinanciaValuesWithVarianceNotesQuery, GetMvrFinanciaValuesWithVarianceNotesQueryVariables>;
export const GetMvrFinancialsSingleAccountDocument = gql`
    query GetMVRFinancialsSingleAccount($propertyId: ID!, $accountId: ID!, $year: Int!) {
  financialValuesForYear(
    propertyIds: [$propertyId]
    entityIds: [$accountId]
    years: [{year: $year, versionType: ACTUALS}, {year: $year, versionType: BUDGET}]
  ) {
    entityId
    entityType
    year
    versionType
    propertyId
    values
  }
}
    `;

/**
 * __useGetMvrFinancialsSingleAccountQuery__
 *
 * To run a query within a React component, call `useGetMvrFinancialsSingleAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMvrFinancialsSingleAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMvrFinancialsSingleAccountQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      accountId: // value for 'accountId'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetMvrFinancialsSingleAccountQuery(baseOptions: Apollo.QueryHookOptions<GetMvrFinancialsSingleAccountQuery, GetMvrFinancialsSingleAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMvrFinancialsSingleAccountQuery, GetMvrFinancialsSingleAccountQueryVariables>(GetMvrFinancialsSingleAccountDocument, options);
      }
export function useGetMvrFinancialsSingleAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMvrFinancialsSingleAccountQuery, GetMvrFinancialsSingleAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMvrFinancialsSingleAccountQuery, GetMvrFinancialsSingleAccountQueryVariables>(GetMvrFinancialsSingleAccountDocument, options);
        }
export type GetMvrFinancialsSingleAccountQueryHookResult = ReturnType<typeof useGetMvrFinancialsSingleAccountQuery>;
export type GetMvrFinancialsSingleAccountLazyQueryHookResult = ReturnType<typeof useGetMvrFinancialsSingleAccountLazyQuery>;
export type GetMvrFinancialsSingleAccountQueryResult = Apollo.QueryResult<GetMvrFinancialsSingleAccountQuery, GetMvrFinancialsSingleAccountQueryVariables>;
export const GetMvrVarianceNotesDocument = gql`
    query GetMVRVarianceNotes($propertyId: ID!, $year: Int!, $varianceNoteMonthIndex: Int!) {
  queryAccountVarianceNotes(
    input: {propertyId: $propertyId, year: $year, monthIndex: $varianceNoteMonthIndex, versionType: BUDGET}
  ) {
    accountId
    year
    versionType
    monthIndex
    text
  }
}
    `;

/**
 * __useGetMvrVarianceNotesQuery__
 *
 * To run a query within a React component, call `useGetMvrVarianceNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMvrVarianceNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMvrVarianceNotesQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      year: // value for 'year'
 *      varianceNoteMonthIndex: // value for 'varianceNoteMonthIndex'
 *   },
 * });
 */
export function useGetMvrVarianceNotesQuery(baseOptions: Apollo.QueryHookOptions<GetMvrVarianceNotesQuery, GetMvrVarianceNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMvrVarianceNotesQuery, GetMvrVarianceNotesQueryVariables>(GetMvrVarianceNotesDocument, options);
      }
export function useGetMvrVarianceNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMvrVarianceNotesQuery, GetMvrVarianceNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMvrVarianceNotesQuery, GetMvrVarianceNotesQueryVariables>(GetMvrVarianceNotesDocument, options);
        }
export type GetMvrVarianceNotesQueryHookResult = ReturnType<typeof useGetMvrVarianceNotesQuery>;
export type GetMvrVarianceNotesLazyQueryHookResult = ReturnType<typeof useGetMvrVarianceNotesLazyQuery>;
export type GetMvrVarianceNotesQueryResult = Apollo.QueryResult<GetMvrVarianceNotesQuery, GetMvrVarianceNotesQueryVariables>;
export const GetOperationalPropertyPerformanceDocument = gql`
    query GetOperationalPropertyPerformance($propertyId: String!, $budgetYear: Int!) {
  getOperationalPropertyPerformance(
    propertyId: $propertyId
    budgetYear: $budgetYear
  ) {
    move_out_rates {
      year
      versionType
      values
      counts
    }
    expirations {
      year
      versionType
      values
      counts
    }
    occupancy_rates {
      year
      versionType
      values
      counts
    }
    renewal_ratio {
      year
      versionType
      values
      counts
    }
  }
}
    `;

/**
 * __useGetOperationalPropertyPerformanceQuery__
 *
 * To run a query within a React component, call `useGetOperationalPropertyPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperationalPropertyPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperationalPropertyPerformanceQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *   },
 * });
 */
export function useGetOperationalPropertyPerformanceQuery(baseOptions: Apollo.QueryHookOptions<GetOperationalPropertyPerformanceQuery, GetOperationalPropertyPerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOperationalPropertyPerformanceQuery, GetOperationalPropertyPerformanceQueryVariables>(GetOperationalPropertyPerformanceDocument, options);
      }
export function useGetOperationalPropertyPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOperationalPropertyPerformanceQuery, GetOperationalPropertyPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOperationalPropertyPerformanceQuery, GetOperationalPropertyPerformanceQueryVariables>(GetOperationalPropertyPerformanceDocument, options);
        }
export type GetOperationalPropertyPerformanceQueryHookResult = ReturnType<typeof useGetOperationalPropertyPerformanceQuery>;
export type GetOperationalPropertyPerformanceLazyQueryHookResult = ReturnType<typeof useGetOperationalPropertyPerformanceLazyQuery>;
export type GetOperationalPropertyPerformanceQueryResult = Apollo.QueryResult<GetOperationalPropertyPerformanceQuery, GetOperationalPropertyPerformanceQueryVariables>;
export const GetPropertyPerformanceDocument = gql`
    query GetPropertyPerformance($propertyId: String!, $budgetYear: Int!) {
  getPropertyPerformance(propertyId: $propertyId, budgetYear: $budgetYear) {
    financial_summary {
      gross_potential_rent {
        year
        type
        monthly_values
        year_total
      }
      total_revenue {
        year
        type
        monthly_values
        year_total
      }
      total_expenses {
        year
        type
        monthly_values
        year_total
      }
      net_operating_income {
        year
        type
        monthly_values
        year_total
      }
      occupancy {
        year
        type
        monthly_values
        year_total
      }
    }
    rent {
      market_rents {
        year
        type
        year_total
      }
      in_place_rent {
        year
        type
        year_total
      }
      renewal_rent {
        year
        type
        year_total
      }
      new_lease_rent {
        year
        type
        year_total
      }
    }
  }
}
    `;

/**
 * __useGetPropertyPerformanceQuery__
 *
 * To run a query within a React component, call `useGetPropertyPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyPerformanceQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *   },
 * });
 */
export function useGetPropertyPerformanceQuery(baseOptions: Apollo.QueryHookOptions<GetPropertyPerformanceQuery, GetPropertyPerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPropertyPerformanceQuery, GetPropertyPerformanceQueryVariables>(GetPropertyPerformanceDocument, options);
      }
export function useGetPropertyPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropertyPerformanceQuery, GetPropertyPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPropertyPerformanceQuery, GetPropertyPerformanceQueryVariables>(GetPropertyPerformanceDocument, options);
        }
export type GetPropertyPerformanceQueryHookResult = ReturnType<typeof useGetPropertyPerformanceQuery>;
export type GetPropertyPerformanceLazyQueryHookResult = ReturnType<typeof useGetPropertyPerformanceLazyQuery>;
export type GetPropertyPerformanceQueryResult = Apollo.QueryResult<GetPropertyPerformanceQuery, GetPropertyPerformanceQueryVariables>;
export const GetAccountsTopCardTagsDocument = gql`
    query GetAccountsTopCardTags($accountIds: [String!]!, $topCardTagTypes: [TopCardTagType!]!) {
  getAccountsTopCardTags(
    accountIds: $accountIds
    topCardTagTypes: $topCardTagTypes
  ) {
    accountId
    topCardTagType
  }
}
    `;

/**
 * __useGetAccountsTopCardTagsQuery__
 *
 * To run a query within a React component, call `useGetAccountsTopCardTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsTopCardTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsTopCardTagsQuery({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      topCardTagTypes: // value for 'topCardTagTypes'
 *   },
 * });
 */
export function useGetAccountsTopCardTagsQuery(baseOptions: Apollo.QueryHookOptions<GetAccountsTopCardTagsQuery, GetAccountsTopCardTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountsTopCardTagsQuery, GetAccountsTopCardTagsQueryVariables>(GetAccountsTopCardTagsDocument, options);
      }
export function useGetAccountsTopCardTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountsTopCardTagsQuery, GetAccountsTopCardTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountsTopCardTagsQuery, GetAccountsTopCardTagsQueryVariables>(GetAccountsTopCardTagsDocument, options);
        }
export type GetAccountsTopCardTagsQueryHookResult = ReturnType<typeof useGetAccountsTopCardTagsQuery>;
export type GetAccountsTopCardTagsLazyQueryHookResult = ReturnType<typeof useGetAccountsTopCardTagsLazyQuery>;
export type GetAccountsTopCardTagsQueryResult = Apollo.QueryResult<GetAccountsTopCardTagsQuery, GetAccountsTopCardTagsQueryVariables>;
export const SetAccountsTopCardTagsDocument = gql`
    mutation SetAccountsTopCardTags($accountIds: [String!]!, $topCardTagType: TopCardTagType!) {
  setAccountsTopCardTags(accountIds: $accountIds, topCardTagType: $topCardTagType) {
    accountId
    topCardTagType
  }
}
    `;
export type SetAccountsTopCardTagsMutationFn = Apollo.MutationFunction<SetAccountsTopCardTagsMutation, SetAccountsTopCardTagsMutationVariables>;

/**
 * __useSetAccountsTopCardTagsMutation__
 *
 * To run a mutation, you first call `useSetAccountsTopCardTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAccountsTopCardTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAccountsTopCardTagsMutation, { data, loading, error }] = useSetAccountsTopCardTagsMutation({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      topCardTagType: // value for 'topCardTagType'
 *   },
 * });
 */
export function useSetAccountsTopCardTagsMutation(baseOptions?: Apollo.MutationHookOptions<SetAccountsTopCardTagsMutation, SetAccountsTopCardTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAccountsTopCardTagsMutation, SetAccountsTopCardTagsMutationVariables>(SetAccountsTopCardTagsDocument, options);
      }
export type SetAccountsTopCardTagsMutationHookResult = ReturnType<typeof useSetAccountsTopCardTagsMutation>;
export type SetAccountsTopCardTagsMutationResult = Apollo.MutationResult<SetAccountsTopCardTagsMutation>;
export type SetAccountsTopCardTagsMutationOptions = Apollo.BaseMutationOptions<SetAccountsTopCardTagsMutation, SetAccountsTopCardTagsMutationVariables>;
export const GetTransactionsDocument = gql`
    query GetTransactions($propertyId: String!, $accountId: String!, $year: Int!, $monthIndex: Int!, $pagination: TraversablePaginationArgs!, $sort: SortArgs!, $filter: FilterArgs!) {
  queryTransactions(
    propertyId: $propertyId
    accountId: $accountId
    year: $year
    monthIndex: $monthIndex
    pagination: $pagination
    sort: $sort
    filter: $filter
  ) {
    total
    take
    page
    maxPage
    items {
      id
      propertyId
      accountId
      description
      memo
      postDate
      amount
    }
  }
}
    `;

/**
 * __useGetTransactionsQuery__
 *
 * To run a query within a React component, call `useGetTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      accountId: // value for 'accountId'
 *      year: // value for 'year'
 *      monthIndex: // value for 'monthIndex'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetTransactionsQuery(baseOptions: Apollo.QueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, options);
      }
export function useGetTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, options);
        }
export type GetTransactionsQueryHookResult = ReturnType<typeof useGetTransactionsQuery>;
export type GetTransactionsLazyQueryHookResult = ReturnType<typeof useGetTransactionsLazyQuery>;
export type GetTransactionsQueryResult = Apollo.QueryResult<GetTransactionsQuery, GetTransactionsQueryVariables>;
export const GetCaseSensitiveEmailDocument = gql`
    query GetCaseSensitiveEmail($email: String!) {
  caseSensitiveEmail(email: $email)
}
    `;

/**
 * __useGetCaseSensitiveEmailQuery__
 *
 * To run a query within a React component, call `useGetCaseSensitiveEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseSensitiveEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseSensitiveEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetCaseSensitiveEmailQuery(baseOptions: Apollo.QueryHookOptions<GetCaseSensitiveEmailQuery, GetCaseSensitiveEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCaseSensitiveEmailQuery, GetCaseSensitiveEmailQueryVariables>(GetCaseSensitiveEmailDocument, options);
      }
export function useGetCaseSensitiveEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCaseSensitiveEmailQuery, GetCaseSensitiveEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCaseSensitiveEmailQuery, GetCaseSensitiveEmailQueryVariables>(GetCaseSensitiveEmailDocument, options);
        }
export type GetCaseSensitiveEmailQueryHookResult = ReturnType<typeof useGetCaseSensitiveEmailQuery>;
export type GetCaseSensitiveEmailLazyQueryHookResult = ReturnType<typeof useGetCaseSensitiveEmailLazyQuery>;
export type GetCaseSensitiveEmailQueryResult = Apollo.QueryResult<GetCaseSensitiveEmailQuery, GetCaseSensitiveEmailQueryVariables>;
export const GetWelcomeUnauthorizedUserDocument = gql`
    query GetWelcomeUnauthorizedUser($userId: String!) {
  welcomeUnauthorizedUser(userId: $userId) {
    email
    firstName
    lastName
  }
}
    `;

/**
 * __useGetWelcomeUnauthorizedUserQuery__
 *
 * To run a query within a React component, call `useGetWelcomeUnauthorizedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWelcomeUnauthorizedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWelcomeUnauthorizedUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetWelcomeUnauthorizedUserQuery(baseOptions: Apollo.QueryHookOptions<GetWelcomeUnauthorizedUserQuery, GetWelcomeUnauthorizedUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWelcomeUnauthorizedUserQuery, GetWelcomeUnauthorizedUserQueryVariables>(GetWelcomeUnauthorizedUserDocument, options);
      }
export function useGetWelcomeUnauthorizedUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWelcomeUnauthorizedUserQuery, GetWelcomeUnauthorizedUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWelcomeUnauthorizedUserQuery, GetWelcomeUnauthorizedUserQueryVariables>(GetWelcomeUnauthorizedUserDocument, options);
        }
export type GetWelcomeUnauthorizedUserQueryHookResult = ReturnType<typeof useGetWelcomeUnauthorizedUserQuery>;
export type GetWelcomeUnauthorizedUserLazyQueryHookResult = ReturnType<typeof useGetWelcomeUnauthorizedUserLazyQuery>;
export type GetWelcomeUnauthorizedUserQueryResult = Apollo.QueryResult<GetWelcomeUnauthorizedUserQuery, GetWelcomeUnauthorizedUserQueryVariables>;
export const CreateSendBudgetJobDocument = gql`
    mutation CreateSendBudgetJob($propertyIds: [ID!]!, $budgetNameTemplate: String!) {
  submitSendBudget(
    propertyIds: $propertyIds
    budgetNameTemplate: $budgetNameTemplate
  ) {
    id
    jobType
    status
    createdAt
    started
    ended
    errorMessage
  }
}
    `;
export type CreateSendBudgetJobMutationFn = Apollo.MutationFunction<CreateSendBudgetJobMutation, CreateSendBudgetJobMutationVariables>;

/**
 * __useCreateSendBudgetJobMutation__
 *
 * To run a mutation, you first call `useCreateSendBudgetJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSendBudgetJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSendBudgetJobMutation, { data, loading, error }] = useCreateSendBudgetJobMutation({
 *   variables: {
 *      propertyIds: // value for 'propertyIds'
 *      budgetNameTemplate: // value for 'budgetNameTemplate'
 *   },
 * });
 */
export function useCreateSendBudgetJobMutation(baseOptions?: Apollo.MutationHookOptions<CreateSendBudgetJobMutation, CreateSendBudgetJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSendBudgetJobMutation, CreateSendBudgetJobMutationVariables>(CreateSendBudgetJobDocument, options);
      }
export type CreateSendBudgetJobMutationHookResult = ReturnType<typeof useCreateSendBudgetJobMutation>;
export type CreateSendBudgetJobMutationResult = Apollo.MutationResult<CreateSendBudgetJobMutation>;
export type CreateSendBudgetJobMutationOptions = Apollo.BaseMutationOptions<CreateSendBudgetJobMutation, CreateSendBudgetJobMutationVariables>;
export const GetJobDocument = gql`
    query GetJob($jobId: String!) {
  getJob(jobId: $jobId) {
    id
    jobType
    status
    createdAt
    started
    ended
    errorMessage
  }
}
    `;

/**
 * __useGetJobQuery__
 *
 * To run a query within a React component, call `useGetJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetJobQuery(baseOptions: Apollo.QueryHookOptions<GetJobQuery, GetJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobQuery, GetJobQueryVariables>(GetJobDocument, options);
      }
export function useGetJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobQuery, GetJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobQuery, GetJobQueryVariables>(GetJobDocument, options);
        }
export type GetJobQueryHookResult = ReturnType<typeof useGetJobQuery>;
export type GetJobLazyQueryHookResult = ReturnType<typeof useGetJobLazyQuery>;
export type GetJobQueryResult = Apollo.QueryResult<GetJobQuery, GetJobQueryVariables>;
export const ListJobsDocument = gql`
    query ListJobs($page: Int!, $take: Int!, $userId: ID, $jobType: JobType) {
  listJobs(page: $page, take: $take, userId: $userId, jobType: $jobType) {
    page
    take
    maxPage
    total
    items {
      id
      jobType
      status
      createdAt
      started
      ended
      errorMessage
      userId
      userEmail
      userFirstName
      userLastName
    }
  }
}
    `;

/**
 * __useListJobsQuery__
 *
 * To run a query within a React component, call `useListJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListJobsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      userId: // value for 'userId'
 *      jobType: // value for 'jobType'
 *   },
 * });
 */
export function useListJobsQuery(baseOptions: Apollo.QueryHookOptions<ListJobsQuery, ListJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListJobsQuery, ListJobsQueryVariables>(ListJobsDocument, options);
      }
export function useListJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListJobsQuery, ListJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListJobsQuery, ListJobsQueryVariables>(ListJobsDocument, options);
        }
export type ListJobsQueryHookResult = ReturnType<typeof useListJobsQuery>;
export type ListJobsLazyQueryHookResult = ReturnType<typeof useListJobsLazyQuery>;
export type ListJobsQueryResult = Apollo.QueryResult<ListJobsQuery, ListJobsQueryVariables>;
export const CreateGroupDocument = gql`
    mutation CreateGroup($name: String!, $authorizations: [String!]!) {
  createUserGroup(name: $name, authorizations: $authorizations) {
    id
  }
}
    `;
export type CreateGroupMutationFn = Apollo.MutationFunction<CreateGroupMutation, CreateGroupMutationVariables>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *      authorizations: // value for 'authorizations'
 *   },
 * });
 */
export function useCreateGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroupMutation, CreateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, options);
      }
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export type CreateGroupMutationResult = Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<CreateGroupMutation, CreateGroupMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($firstName: String!, $lastName: String!, $email: String!, $generatePassword: Boolean!, $password: PasswordScalar, $role: UserRole!, $isReadOnly: Boolean!, $authorizations: [String!]!, $properties: [String!]!, $groups: [String!]!) {
  createUser(
    firstName: $firstName
    lastName: $lastName
    email: $email
    generatePassword: $generatePassword
    password: $password
    role: $role
    isReadOnly: $isReadOnly
    authorizations: $authorizations
    properties: $properties
    groups: $groups
  ) {
    id
    email
    firstName
    lastName
    role
    isReadOnly
    properties {
      id
      name
    }
    authorizations
    groups {
      id
      name
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      generatePassword: // value for 'generatePassword'
 *      password: // value for 'password'
 *      role: // value for 'role'
 *      isReadOnly: // value for 'isReadOnly'
 *      authorizations: // value for 'authorizations'
 *      properties: // value for 'properties'
 *      groups: // value for 'groups'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteGroupsDocument = gql`
    mutation DeleteGroups($ids: [ID!]!) {
  deleteUserGroups(ids: $ids)
}
    `;
export type DeleteGroupsMutationFn = Apollo.MutationFunction<DeleteGroupsMutation, DeleteGroupsMutationVariables>;

/**
 * __useDeleteGroupsMutation__
 *
 * To run a mutation, you first call `useDeleteGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupsMutation, { data, loading, error }] = useDeleteGroupsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteGroupsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGroupsMutation, DeleteGroupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGroupsMutation, DeleteGroupsMutationVariables>(DeleteGroupsDocument, options);
      }
export type DeleteGroupsMutationHookResult = ReturnType<typeof useDeleteGroupsMutation>;
export type DeleteGroupsMutationResult = Apollo.MutationResult<DeleteGroupsMutation>;
export type DeleteGroupsMutationOptions = Apollo.BaseMutationOptions<DeleteGroupsMutation, DeleteGroupsMutationVariables>;
export const DeleteUsersDocument = gql`
    mutation DeleteUsers($ids: [ID!]!) {
  deleteUsers(userIds: $ids)
}
    `;
export type DeleteUsersMutationFn = Apollo.MutationFunction<DeleteUsersMutation, DeleteUsersMutationVariables>;

/**
 * __useDeleteUsersMutation__
 *
 * To run a mutation, you first call `useDeleteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUsersMutation, { data, loading, error }] = useDeleteUsersMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteUsersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUsersMutation, DeleteUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUsersMutation, DeleteUsersMutationVariables>(DeleteUsersDocument, options);
      }
export type DeleteUsersMutationHookResult = ReturnType<typeof useDeleteUsersMutation>;
export type DeleteUsersMutationResult = Apollo.MutationResult<DeleteUsersMutation>;
export type DeleteUsersMutationOptions = Apollo.BaseMutationOptions<DeleteUsersMutation, DeleteUsersMutationVariables>;
export const FindGroupUserCountsDocument = gql`
    query FindGroupUserCounts($ids: [ID!]!) {
  findUserGroups(ids: $ids) {
    id
    name
    userCount
  }
}
    `;

/**
 * __useFindGroupUserCountsQuery__
 *
 * To run a query within a React component, call `useFindGroupUserCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindGroupUserCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindGroupUserCountsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useFindGroupUserCountsQuery(baseOptions: Apollo.QueryHookOptions<FindGroupUserCountsQuery, FindGroupUserCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindGroupUserCountsQuery, FindGroupUserCountsQueryVariables>(FindGroupUserCountsDocument, options);
      }
export function useFindGroupUserCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindGroupUserCountsQuery, FindGroupUserCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindGroupUserCountsQuery, FindGroupUserCountsQueryVariables>(FindGroupUserCountsDocument, options);
        }
export type FindGroupUserCountsQueryHookResult = ReturnType<typeof useFindGroupUserCountsQuery>;
export type FindGroupUserCountsLazyQueryHookResult = ReturnType<typeof useFindGroupUserCountsLazyQuery>;
export type FindGroupUserCountsQueryResult = Apollo.QueryResult<FindGroupUserCountsQuery, FindGroupUserCountsQueryVariables>;
export const GetRolesDocument = gql`
    query GetRoles {
  getRoles
}
    `;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    email
    firstName
    lastName
    role
    isReadOnly
    properties {
      id
      name
    }
    authorizations
    groups {
      id
      name
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const ListAuthorizationsDocument = gql`
    query ListAuthorizations {
  listAuthorizations
}
    `;

/**
 * __useListAuthorizationsQuery__
 *
 * To run a query within a React component, call `useListAuthorizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAuthorizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAuthorizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAuthorizationsQuery(baseOptions?: Apollo.QueryHookOptions<ListAuthorizationsQuery, ListAuthorizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAuthorizationsQuery, ListAuthorizationsQueryVariables>(ListAuthorizationsDocument, options);
      }
export function useListAuthorizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAuthorizationsQuery, ListAuthorizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAuthorizationsQuery, ListAuthorizationsQueryVariables>(ListAuthorizationsDocument, options);
        }
export type ListAuthorizationsQueryHookResult = ReturnType<typeof useListAuthorizationsQuery>;
export type ListAuthorizationsLazyQueryHookResult = ReturnType<typeof useListAuthorizationsLazyQuery>;
export type ListAuthorizationsQueryResult = Apollo.QueryResult<ListAuthorizationsQuery, ListAuthorizationsQueryVariables>;
export const ListGroupsDocument = gql`
    query ListGroups {
  listUserGroups {
    id
    name
    authorizations
  }
}
    `;

/**
 * __useListGroupsQuery__
 *
 * To run a query within a React component, call `useListGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListGroupsQuery(baseOptions?: Apollo.QueryHookOptions<ListGroupsQuery, ListGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListGroupsQuery, ListGroupsQueryVariables>(ListGroupsDocument, options);
      }
export function useListGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListGroupsQuery, ListGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListGroupsQuery, ListGroupsQueryVariables>(ListGroupsDocument, options);
        }
export type ListGroupsQueryHookResult = ReturnType<typeof useListGroupsQuery>;
export type ListGroupsLazyQueryHookResult = ReturnType<typeof useListGroupsLazyQuery>;
export type ListGroupsQueryResult = Apollo.QueryResult<ListGroupsQuery, ListGroupsQueryVariables>;
export const ListUsersDocument = gql`
    query ListUsers {
  listUsers {
    id
    email
    firstName
    lastName
    role
    isReadOnly
    properties {
      id
      name
    }
    authorizations
    groups {
      id
      name
    }
  }
}
    `;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListUsersQuery(baseOptions?: Apollo.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
      }
export function useListUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
        }
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersQueryResult = Apollo.QueryResult<ListUsersQuery, ListUsersQueryVariables>;
export const SetUsersBulkDocument = gql`
    mutation SetUsersBulk($ids: [ID!]!, $propertyIds: [ID!], $readOnly: Boolean, $groupIds: [ID!]) {
  setUsersBulk(
    ids: $ids
    propertyIds: $propertyIds
    readOnly: $readOnly
    groupIds: $groupIds
  )
}
    `;
export type SetUsersBulkMutationFn = Apollo.MutationFunction<SetUsersBulkMutation, SetUsersBulkMutationVariables>;

/**
 * __useSetUsersBulkMutation__
 *
 * To run a mutation, you first call `useSetUsersBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUsersBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUsersBulkMutation, { data, loading, error }] = useSetUsersBulkMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      propertyIds: // value for 'propertyIds'
 *      readOnly: // value for 'readOnly'
 *      groupIds: // value for 'groupIds'
 *   },
 * });
 */
export function useSetUsersBulkMutation(baseOptions?: Apollo.MutationHookOptions<SetUsersBulkMutation, SetUsersBulkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUsersBulkMutation, SetUsersBulkMutationVariables>(SetUsersBulkDocument, options);
      }
export type SetUsersBulkMutationHookResult = ReturnType<typeof useSetUsersBulkMutation>;
export type SetUsersBulkMutationResult = Apollo.MutationResult<SetUsersBulkMutation>;
export type SetUsersBulkMutationOptions = Apollo.BaseMutationOptions<SetUsersBulkMutation, SetUsersBulkMutationVariables>;
export const UpdateGroupDocument = gql`
    mutation UpdateGroup($id: ID!, $name: String, $authorizations: [String!]) {
  updateUserGroup(id: $id, name: $name, authorizations: $authorizations) {
    id
  }
}
    `;
export type UpdateGroupMutationFn = Apollo.MutationFunction<UpdateGroupMutation, UpdateGroupMutationVariables>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      authorizations: // value for 'authorizations'
 *   },
 * });
 */
export function useUpdateGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroupMutation, UpdateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(UpdateGroupDocument, options);
      }
export type UpdateGroupMutationHookResult = ReturnType<typeof useUpdateGroupMutation>;
export type UpdateGroupMutationResult = Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<UpdateGroupMutation, UpdateGroupMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: ID!, $firstName: String, $lastName: String, $email: String, $role: UserRole, $isReadOnly: Boolean, $authorizations: [String!], $properties: [String!], $groups: [String!]!) {
  updateUser(
    id: $id
    firstName: $firstName
    lastName: $lastName
    email: $email
    role: $role
    isReadOnly: $isReadOnly
    authorizations: $authorizations
    properties: $properties
    groups: $groups
  ) {
    id
    email
    firstName
    lastName
    role
    isReadOnly
    properties {
      id
      name
    }
    authorizations
    groups {
      id
      name
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      role: // value for 'role'
 *      isReadOnly: // value for 'isReadOnly'
 *      authorizations: // value for 'authorizations'
 *      properties: // value for 'properties'
 *      groups: // value for 'groups'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetAnalystDriversDocument = gql`
    query GetAnalystDrivers($propertyIds: [String!]!) {
  analystDrivers(propertyIds: $propertyIds) {
    driverAssignmentId
    accountV2Id
    propertyId
    versionType
    driverType
  }
}
    `;

/**
 * __useGetAnalystDriversQuery__
 *
 * To run a query within a React component, call `useGetAnalystDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalystDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalystDriversQuery({
 *   variables: {
 *      propertyIds: // value for 'propertyIds'
 *   },
 * });
 */
export function useGetAnalystDriversQuery(baseOptions: Apollo.QueryHookOptions<GetAnalystDriversQuery, GetAnalystDriversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnalystDriversQuery, GetAnalystDriversQueryVariables>(GetAnalystDriversDocument, options);
      }
export function useGetAnalystDriversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnalystDriversQuery, GetAnalystDriversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnalystDriversQuery, GetAnalystDriversQueryVariables>(GetAnalystDriversDocument, options);
        }
export type GetAnalystDriversQueryHookResult = ReturnType<typeof useGetAnalystDriversQuery>;
export type GetAnalystDriversLazyQueryHookResult = ReturnType<typeof useGetAnalystDriversLazyQuery>;
export type GetAnalystDriversQueryResult = Apollo.QueryResult<GetAnalystDriversQuery, GetAnalystDriversQueryVariables>;
export const UpsertMetricValuesMultiPropertyDocument = gql`
    mutation UpsertMetricValuesMultiProperty($propertyMetrics: [PropertyMetrics!]!, $unitTypeMetrics: [UnitTypeMetrics!]!) {
  upsertMetricValuesMultiProperty(
    propertyMetrics: $propertyMetrics
    unitTypeMetrics: $unitTypeMetrics
  )
}
    `;
export type UpsertMetricValuesMultiPropertyMutationFn = Apollo.MutationFunction<UpsertMetricValuesMultiPropertyMutation, UpsertMetricValuesMultiPropertyMutationVariables>;

/**
 * __useUpsertMetricValuesMultiPropertyMutation__
 *
 * To run a mutation, you first call `useUpsertMetricValuesMultiPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMetricValuesMultiPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMetricValuesMultiPropertyMutation, { data, loading, error }] = useUpsertMetricValuesMultiPropertyMutation({
 *   variables: {
 *      propertyMetrics: // value for 'propertyMetrics'
 *      unitTypeMetrics: // value for 'unitTypeMetrics'
 *   },
 * });
 */
export function useUpsertMetricValuesMultiPropertyMutation(baseOptions?: Apollo.MutationHookOptions<UpsertMetricValuesMultiPropertyMutation, UpsertMetricValuesMultiPropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertMetricValuesMultiPropertyMutation, UpsertMetricValuesMultiPropertyMutationVariables>(UpsertMetricValuesMultiPropertyDocument, options);
      }
export type UpsertMetricValuesMultiPropertyMutationHookResult = ReturnType<typeof useUpsertMetricValuesMultiPropertyMutation>;
export type UpsertMetricValuesMultiPropertyMutationResult = Apollo.MutationResult<UpsertMetricValuesMultiPropertyMutation>;
export type UpsertMetricValuesMultiPropertyMutationOptions = Apollo.BaseMutationOptions<UpsertMetricValuesMultiPropertyMutation, UpsertMetricValuesMultiPropertyMutationVariables>;
export const GetAnalystOpDriversDocument = gql`
    query GetAnalystOpDrivers($year: Int!) {
  analystOpDrivers(year: $year) {
    properties {
      propertyName
      propertyId
      propertyCappedRenewalIncreasePercent
      totalUnitCount
      propertyMetrics {
        earlyTerminations {
          year
          actuals
          expected
        }
        monthlyMoveOuts {
          year
          actuals
          expected
        }
      }
      propertyUnits {
        unitType
        unitTypeId
        numUnits
        unitMetrics {
          avgMarketRents {
            year
            actuals
            expected
          }
          leaseExpirations {
            year
            actuals
            expected
          }
          renewalRatios {
            year
            actuals
            expected
          }
          renewalIncreases {
            expected
            actuals
            year
          }
          moveOutRates {
            year
            actuals
            expected
          }
          occupancyRates {
            year
            actuals
            expected
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAnalystOpDriversQuery__
 *
 * To run a query within a React component, call `useGetAnalystOpDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalystOpDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalystOpDriversQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetAnalystOpDriversQuery(baseOptions: Apollo.QueryHookOptions<GetAnalystOpDriversQuery, GetAnalystOpDriversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnalystOpDriversQuery, GetAnalystOpDriversQueryVariables>(GetAnalystOpDriversDocument, options);
      }
export function useGetAnalystOpDriversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnalystOpDriversQuery, GetAnalystOpDriversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnalystOpDriversQuery, GetAnalystOpDriversQueryVariables>(GetAnalystOpDriversDocument, options);
        }
export type GetAnalystOpDriversQueryHookResult = ReturnType<typeof useGetAnalystOpDriversQuery>;
export type GetAnalystOpDriversLazyQueryHookResult = ReturnType<typeof useGetAnalystOpDriversLazyQuery>;
export type GetAnalystOpDriversQueryResult = Apollo.QueryResult<GetAnalystOpDriversQuery, GetAnalystOpDriversQueryVariables>;
export const GetPayrollEmployeeDataDocument = gql`
    query getPayrollEmployeeData($cursor: String, $budgetYear: Int!) {
  listEmployees: listEmployeesForBudgetYear(
    budgetYear: $budgetYear
    take: 100
    cursor: $cursor
  ) {
    total
    cursor
    hasMore
    items {
      id
      externalSystemEmployeeId
      firstName
      lastName
      type
      classification
      position {
        id
        name
      }
      propertyAttributions {
        attributionPercentage
        property {
          id
          name
          displayName
        }
      }
      startDate
      terminationDate
      baseComp {
        expectedHoursPerWeek
        hourlyCompensation
        annualCompensation
        compensationType
      }
      overtime {
        id
        hourlyCompensation
        expectedHoursPerWeek
        effectiveStartDate
        effectiveEndDate
      }
      bonuses {
        id
        bonusType
        frequency
        amount
        bonusMonth
        bonusDay
        onetimeBonusDate
        effectiveStartDate
      }
      raises {
        id
        raiseType
        frequency
        amount
        raiseMonth
        raiseDay
        onetimeRaiseDate
        effectiveStartDate
      }
      compItems {
        id
        calculationType
        costPerPayPeriod
        effectiveStartDate
        annualCostCap
        compensationItem {
          id
          clientId
          type
        }
      }
    }
  }
}
    `;

/**
 * __useGetPayrollEmployeeDataQuery__
 *
 * To run a query within a React component, call `useGetPayrollEmployeeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrollEmployeeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrollEmployeeDataQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      budgetYear: // value for 'budgetYear'
 *   },
 * });
 */
export function useGetPayrollEmployeeDataQuery(baseOptions: Apollo.QueryHookOptions<GetPayrollEmployeeDataQuery, GetPayrollEmployeeDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayrollEmployeeDataQuery, GetPayrollEmployeeDataQueryVariables>(GetPayrollEmployeeDataDocument, options);
      }
export function useGetPayrollEmployeeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayrollEmployeeDataQuery, GetPayrollEmployeeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayrollEmployeeDataQuery, GetPayrollEmployeeDataQueryVariables>(GetPayrollEmployeeDataDocument, options);
        }
export type GetPayrollEmployeeDataQueryHookResult = ReturnType<typeof useGetPayrollEmployeeDataQuery>;
export type GetPayrollEmployeeDataLazyQueryHookResult = ReturnType<typeof useGetPayrollEmployeeDataLazyQuery>;
export type GetPayrollEmployeeDataQueryResult = Apollo.QueryResult<GetPayrollEmployeeDataQuery, GetPayrollEmployeeDataQueryVariables>;
export const GetAnalystRenovationsDocument = gql`
    query getAnalystRenovations($propertyIds: [String!]!, $yearAndTypes: [YearAndType!]!, $cursor: String) {
  queryRenovationPackagesPaginated(
    propertyIds: $propertyIds
    take: 25
    cursor: $cursor
  ) {
    total
    cursor
    hasMore
    items {
      id
      propertyId
      unitTypeId
      name
      premium
      premiumDelayRecognition
      unitTypeScheduleValues {
        version {
          type
          year
        }
        monthIndex
        leaseExpirationCount
        moveOutCount
        moveOutRate
      }
      renovationNumbers(yearAndTypes: $yearAndTypes) {
        year
        versionType
        expected {
          monthIndex
          value
        }
      }
    }
  }
}
    `;

/**
 * __useGetAnalystRenovationsQuery__
 *
 * To run a query within a React component, call `useGetAnalystRenovationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalystRenovationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalystRenovationsQuery({
 *   variables: {
 *      propertyIds: // value for 'propertyIds'
 *      yearAndTypes: // value for 'yearAndTypes'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetAnalystRenovationsQuery(baseOptions: Apollo.QueryHookOptions<GetAnalystRenovationsQuery, GetAnalystRenovationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnalystRenovationsQuery, GetAnalystRenovationsQueryVariables>(GetAnalystRenovationsDocument, options);
      }
export function useGetAnalystRenovationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnalystRenovationsQuery, GetAnalystRenovationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnalystRenovationsQuery, GetAnalystRenovationsQueryVariables>(GetAnalystRenovationsDocument, options);
        }
export type GetAnalystRenovationsQueryHookResult = ReturnType<typeof useGetAnalystRenovationsQuery>;
export type GetAnalystRenovationsLazyQueryHookResult = ReturnType<typeof useGetAnalystRenovationsLazyQuery>;
export type GetAnalystRenovationsQueryResult = Apollo.QueryResult<GetAnalystRenovationsQuery, GetAnalystRenovationsQueryVariables>;
export const GetPropertyBudgetCompletionsDocument = gql`
    query GetPropertyBudgetCompletions($budgetYear: Int!) {
  queryPropertyBudgetCompletions {
    propertyId
    propertyName
    propertyDisplayName
    budgetYear
    completed
    updatedAt
  }
  getBudgetApprovalStatusForProperties(budgetYear: $budgetYear) {
    id
    propertyId
    budgetYear
    userId
    userFirstName
    userLastName
    userEmail
    timestamp
    approved
  }
}
    `;

/**
 * __useGetPropertyBudgetCompletionsQuery__
 *
 * To run a query within a React component, call `useGetPropertyBudgetCompletionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyBudgetCompletionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyBudgetCompletionsQuery({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *   },
 * });
 */
export function useGetPropertyBudgetCompletionsQuery(baseOptions: Apollo.QueryHookOptions<GetPropertyBudgetCompletionsQuery, GetPropertyBudgetCompletionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPropertyBudgetCompletionsQuery, GetPropertyBudgetCompletionsQueryVariables>(GetPropertyBudgetCompletionsDocument, options);
      }
export function useGetPropertyBudgetCompletionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropertyBudgetCompletionsQuery, GetPropertyBudgetCompletionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPropertyBudgetCompletionsQuery, GetPropertyBudgetCompletionsQueryVariables>(GetPropertyBudgetCompletionsDocument, options);
        }
export type GetPropertyBudgetCompletionsQueryHookResult = ReturnType<typeof useGetPropertyBudgetCompletionsQuery>;
export type GetPropertyBudgetCompletionsLazyQueryHookResult = ReturnType<typeof useGetPropertyBudgetCompletionsLazyQuery>;
export type GetPropertyBudgetCompletionsQueryResult = Apollo.QueryResult<GetPropertyBudgetCompletionsQuery, GetPropertyBudgetCompletionsQueryVariables>;
export const SetPropertyBudgetCompletionDocument = gql`
    mutation SetPropertyBudgetCompletion($propertyId: ID!, $budgetYear: Int!, $completed: Boolean!) {
  updatePropertyBudgetCompletion(
    propertyId: $propertyId
    budgetYear: $budgetYear
    completed: $completed
  )
}
    `;
export type SetPropertyBudgetCompletionMutationFn = Apollo.MutationFunction<SetPropertyBudgetCompletionMutation, SetPropertyBudgetCompletionMutationVariables>;

/**
 * __useSetPropertyBudgetCompletionMutation__
 *
 * To run a mutation, you first call `useSetPropertyBudgetCompletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPropertyBudgetCompletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPropertyBudgetCompletionMutation, { data, loading, error }] = useSetPropertyBudgetCompletionMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      completed: // value for 'completed'
 *   },
 * });
 */
export function useSetPropertyBudgetCompletionMutation(baseOptions?: Apollo.MutationHookOptions<SetPropertyBudgetCompletionMutation, SetPropertyBudgetCompletionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPropertyBudgetCompletionMutation, SetPropertyBudgetCompletionMutationVariables>(SetPropertyBudgetCompletionDocument, options);
      }
export type SetPropertyBudgetCompletionMutationHookResult = ReturnType<typeof useSetPropertyBudgetCompletionMutation>;
export type SetPropertyBudgetCompletionMutationResult = Apollo.MutationResult<SetPropertyBudgetCompletionMutation>;
export type SetPropertyBudgetCompletionMutationOptions = Apollo.BaseMutationOptions<SetPropertyBudgetCompletionMutation, SetPropertyBudgetCompletionMutationVariables>;
export const ActivateBudgetYearDocument = gql`
    mutation ActivateBudgetYear($budgetYear: Int!) {
  activateBudgetYear(budgetYear: $budgetYear) {
    activeBudgetYear
    budgetYears {
      year
      protected
    }
  }
}
    `;
export type ActivateBudgetYearMutationFn = Apollo.MutationFunction<ActivateBudgetYearMutation, ActivateBudgetYearMutationVariables>;

/**
 * __useActivateBudgetYearMutation__
 *
 * To run a mutation, you first call `useActivateBudgetYearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateBudgetYearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateBudgetYearMutation, { data, loading, error }] = useActivateBudgetYearMutation({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *   },
 * });
 */
export function useActivateBudgetYearMutation(baseOptions?: Apollo.MutationHookOptions<ActivateBudgetYearMutation, ActivateBudgetYearMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateBudgetYearMutation, ActivateBudgetYearMutationVariables>(ActivateBudgetYearDocument, options);
      }
export type ActivateBudgetYearMutationHookResult = ReturnType<typeof useActivateBudgetYearMutation>;
export type ActivateBudgetYearMutationResult = Apollo.MutationResult<ActivateBudgetYearMutation>;
export type ActivateBudgetYearMutationOptions = Apollo.BaseMutationOptions<ActivateBudgetYearMutation, ActivateBudgetYearMutationVariables>;
export const AddBudgetYearDocument = gql`
    mutation AddBudgetYear($budgetYear: Int!) {
  addBudgetYear(budgetYear: $budgetYear) {
    activeBudgetYear
    budgetYears {
      year
      protected
      isAutoUnitTypeUpdateEnabled
    }
  }
}
    `;
export type AddBudgetYearMutationFn = Apollo.MutationFunction<AddBudgetYearMutation, AddBudgetYearMutationVariables>;

/**
 * __useAddBudgetYearMutation__
 *
 * To run a mutation, you first call `useAddBudgetYearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBudgetYearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBudgetYearMutation, { data, loading, error }] = useAddBudgetYearMutation({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *   },
 * });
 */
export function useAddBudgetYearMutation(baseOptions?: Apollo.MutationHookOptions<AddBudgetYearMutation, AddBudgetYearMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBudgetYearMutation, AddBudgetYearMutationVariables>(AddBudgetYearDocument, options);
      }
export type AddBudgetYearMutationHookResult = ReturnType<typeof useAddBudgetYearMutation>;
export type AddBudgetYearMutationResult = Apollo.MutationResult<AddBudgetYearMutation>;
export type AddBudgetYearMutationOptions = Apollo.BaseMutationOptions<AddBudgetYearMutation, AddBudgetYearMutationVariables>;
export const CopyDriversClientLevelDocument = gql`
    mutation copyDriversClientLevel($destinationBudgetYear: Int!, $destinationVersionType: VersionType!, $sourceBudgetYear: Int!, $sourceVersionType: VersionType!) {
  copyDriversClientLevel(
    destinationBudgetYear: $destinationBudgetYear
    destinationVersionType: $destinationVersionType
    sourceBudgetYear: $sourceBudgetYear
    sourceVersionType: $sourceVersionType
  ) {
    result
    resultString
  }
}
    `;
export type CopyDriversClientLevelMutationFn = Apollo.MutationFunction<CopyDriversClientLevelMutation, CopyDriversClientLevelMutationVariables>;

/**
 * __useCopyDriversClientLevelMutation__
 *
 * To run a mutation, you first call `useCopyDriversClientLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyDriversClientLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyDriversClientLevelMutation, { data, loading, error }] = useCopyDriversClientLevelMutation({
 *   variables: {
 *      destinationBudgetYear: // value for 'destinationBudgetYear'
 *      destinationVersionType: // value for 'destinationVersionType'
 *      sourceBudgetYear: // value for 'sourceBudgetYear'
 *      sourceVersionType: // value for 'sourceVersionType'
 *   },
 * });
 */
export function useCopyDriversClientLevelMutation(baseOptions?: Apollo.MutationHookOptions<CopyDriversClientLevelMutation, CopyDriversClientLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyDriversClientLevelMutation, CopyDriversClientLevelMutationVariables>(CopyDriversClientLevelDocument, options);
      }
export type CopyDriversClientLevelMutationHookResult = ReturnType<typeof useCopyDriversClientLevelMutation>;
export type CopyDriversClientLevelMutationResult = Apollo.MutationResult<CopyDriversClientLevelMutation>;
export type CopyDriversClientLevelMutationOptions = Apollo.BaseMutationOptions<CopyDriversClientLevelMutation, CopyDriversClientLevelMutationVariables>;
export const CopyPayrollEmployeesClientLevelDocument = gql`
    mutation copyPayrollEmployeesClientLevel($sourceBudgetYear: Int!, $destBudgetYear: Int!) {
  copyPayrollEmployees(
    sourceBudgetYear: $sourceBudgetYear
    destBudgetYear: $destBudgetYear
  )
}
    `;
export type CopyPayrollEmployeesClientLevelMutationFn = Apollo.MutationFunction<CopyPayrollEmployeesClientLevelMutation, CopyPayrollEmployeesClientLevelMutationVariables>;

/**
 * __useCopyPayrollEmployeesClientLevelMutation__
 *
 * To run a mutation, you first call `useCopyPayrollEmployeesClientLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyPayrollEmployeesClientLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyPayrollEmployeesClientLevelMutation, { data, loading, error }] = useCopyPayrollEmployeesClientLevelMutation({
 *   variables: {
 *      sourceBudgetYear: // value for 'sourceBudgetYear'
 *      destBudgetYear: // value for 'destBudgetYear'
 *   },
 * });
 */
export function useCopyPayrollEmployeesClientLevelMutation(baseOptions?: Apollo.MutationHookOptions<CopyPayrollEmployeesClientLevelMutation, CopyPayrollEmployeesClientLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyPayrollEmployeesClientLevelMutation, CopyPayrollEmployeesClientLevelMutationVariables>(CopyPayrollEmployeesClientLevelDocument, options);
      }
export type CopyPayrollEmployeesClientLevelMutationHookResult = ReturnType<typeof useCopyPayrollEmployeesClientLevelMutation>;
export type CopyPayrollEmployeesClientLevelMutationResult = Apollo.MutationResult<CopyPayrollEmployeesClientLevelMutation>;
export type CopyPayrollEmployeesClientLevelMutationOptions = Apollo.BaseMutationOptions<CopyPayrollEmployeesClientLevelMutation, CopyPayrollEmployeesClientLevelMutationVariables>;
export const CopyRevenueAssumptionsClientLevelDocument = gql`
    mutation copyRevenueAssumptionsClientLevel($destinationBudgetYear: Int!, $destinationVersionType: VersionType!, $sourceBudgetYear: Int!, $sourceVersionType: VersionType!, $renovationCopyBothPeriods: Boolean!) {
  copyRevenueAssumptionsClientLevel(
    destinationBudgetYear: $destinationBudgetYear
    destinationVersionType: $destinationVersionType
    sourceBudgetYear: $sourceBudgetYear
    sourceVersionType: $sourceVersionType
    renovationCopyBothPeriods: $renovationCopyBothPeriods
  ) {
    result
    resultString
  }
}
    `;
export type CopyRevenueAssumptionsClientLevelMutationFn = Apollo.MutationFunction<CopyRevenueAssumptionsClientLevelMutation, CopyRevenueAssumptionsClientLevelMutationVariables>;

/**
 * __useCopyRevenueAssumptionsClientLevelMutation__
 *
 * To run a mutation, you first call `useCopyRevenueAssumptionsClientLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyRevenueAssumptionsClientLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyRevenueAssumptionsClientLevelMutation, { data, loading, error }] = useCopyRevenueAssumptionsClientLevelMutation({
 *   variables: {
 *      destinationBudgetYear: // value for 'destinationBudgetYear'
 *      destinationVersionType: // value for 'destinationVersionType'
 *      sourceBudgetYear: // value for 'sourceBudgetYear'
 *      sourceVersionType: // value for 'sourceVersionType'
 *      renovationCopyBothPeriods: // value for 'renovationCopyBothPeriods'
 *   },
 * });
 */
export function useCopyRevenueAssumptionsClientLevelMutation(baseOptions?: Apollo.MutationHookOptions<CopyRevenueAssumptionsClientLevelMutation, CopyRevenueAssumptionsClientLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyRevenueAssumptionsClientLevelMutation, CopyRevenueAssumptionsClientLevelMutationVariables>(CopyRevenueAssumptionsClientLevelDocument, options);
      }
export type CopyRevenueAssumptionsClientLevelMutationHookResult = ReturnType<typeof useCopyRevenueAssumptionsClientLevelMutation>;
export type CopyRevenueAssumptionsClientLevelMutationResult = Apollo.MutationResult<CopyRevenueAssumptionsClientLevelMutation>;
export type CopyRevenueAssumptionsClientLevelMutationOptions = Apollo.BaseMutationOptions<CopyRevenueAssumptionsClientLevelMutation, CopyRevenueAssumptionsClientLevelMutationVariables>;
export const CountPayrollEmployeesForBudgetYearGroupByPropertyDocument = gql`
    query countPayrollEmployeesForBudgetYearGroupByProperty($budgetYear: Int!) {
  countEmployeesForBudgetYearGroupByProperty(budgetYear: $budgetYear) {
    propertyId
    propertyName
    employeeCount
  }
}
    `;

/**
 * __useCountPayrollEmployeesForBudgetYearGroupByPropertyQuery__
 *
 * To run a query within a React component, call `useCountPayrollEmployeesForBudgetYearGroupByPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountPayrollEmployeesForBudgetYearGroupByPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountPayrollEmployeesForBudgetYearGroupByPropertyQuery({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *   },
 * });
 */
export function useCountPayrollEmployeesForBudgetYearGroupByPropertyQuery(baseOptions: Apollo.QueryHookOptions<CountPayrollEmployeesForBudgetYearGroupByPropertyQuery, CountPayrollEmployeesForBudgetYearGroupByPropertyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountPayrollEmployeesForBudgetYearGroupByPropertyQuery, CountPayrollEmployeesForBudgetYearGroupByPropertyQueryVariables>(CountPayrollEmployeesForBudgetYearGroupByPropertyDocument, options);
      }
export function useCountPayrollEmployeesForBudgetYearGroupByPropertyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountPayrollEmployeesForBudgetYearGroupByPropertyQuery, CountPayrollEmployeesForBudgetYearGroupByPropertyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountPayrollEmployeesForBudgetYearGroupByPropertyQuery, CountPayrollEmployeesForBudgetYearGroupByPropertyQueryVariables>(CountPayrollEmployeesForBudgetYearGroupByPropertyDocument, options);
        }
export type CountPayrollEmployeesForBudgetYearGroupByPropertyQueryHookResult = ReturnType<typeof useCountPayrollEmployeesForBudgetYearGroupByPropertyQuery>;
export type CountPayrollEmployeesForBudgetYearGroupByPropertyLazyQueryHookResult = ReturnType<typeof useCountPayrollEmployeesForBudgetYearGroupByPropertyLazyQuery>;
export type CountPayrollEmployeesForBudgetYearGroupByPropertyQueryResult = Apollo.QueryResult<CountPayrollEmployeesForBudgetYearGroupByPropertyQuery, CountPayrollEmployeesForBudgetYearGroupByPropertyQueryVariables>;
export const ListBudgetYearsDocument = gql`
    query ListBudgetYears {
  listBudgetYears {
    budgetYears {
      year
      protected
      isAutoUnitTypeUpdateEnabled
    }
    activeBudgetYear
  }
}
    `;

/**
 * __useListBudgetYearsQuery__
 *
 * To run a query within a React component, call `useListBudgetYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBudgetYearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBudgetYearsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListBudgetYearsQuery(baseOptions?: Apollo.QueryHookOptions<ListBudgetYearsQuery, ListBudgetYearsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListBudgetYearsQuery, ListBudgetYearsQueryVariables>(ListBudgetYearsDocument, options);
      }
export function useListBudgetYearsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListBudgetYearsQuery, ListBudgetYearsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListBudgetYearsQuery, ListBudgetYearsQueryVariables>(ListBudgetYearsDocument, options);
        }
export type ListBudgetYearsQueryHookResult = ReturnType<typeof useListBudgetYearsQuery>;
export type ListBudgetYearsLazyQueryHookResult = ReturnType<typeof useListBudgetYearsLazyQuery>;
export type ListBudgetYearsQueryResult = Apollo.QueryResult<ListBudgetYearsQuery, ListBudgetYearsQueryVariables>;
export const QueryDriverStatusDocument = gql`
    query QueryDriverStatus($budgetYear: Int!, $versionType: VersionType!) {
  queryDriverStatus(budgetYear: $budgetYear, versionType: $versionType) {
    propertyId
    propertyName
    year
    versionType
    revenue
    accPercentage
    operational
    worksheet
    growth
    payroll
    customDriver
  }
}
    `;

/**
 * __useQueryDriverStatusQuery__
 *
 * To run a query within a React component, call `useQueryDriverStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryDriverStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryDriverStatusQuery({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *      versionType: // value for 'versionType'
 *   },
 * });
 */
export function useQueryDriverStatusQuery(baseOptions: Apollo.QueryHookOptions<QueryDriverStatusQuery, QueryDriverStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryDriverStatusQuery, QueryDriverStatusQueryVariables>(QueryDriverStatusDocument, options);
      }
export function useQueryDriverStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryDriverStatusQuery, QueryDriverStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryDriverStatusQuery, QueryDriverStatusQueryVariables>(QueryDriverStatusDocument, options);
        }
export type QueryDriverStatusQueryHookResult = ReturnType<typeof useQueryDriverStatusQuery>;
export type QueryDriverStatusLazyQueryHookResult = ReturnType<typeof useQueryDriverStatusLazyQuery>;
export type QueryDriverStatusQueryResult = Apollo.QueryResult<QueryDriverStatusQuery, QueryDriverStatusQueryVariables>;
export const RevenueAssumptionsStatusDocument = gql`
    query revenueAssumptionsStatus($budgetYear: Int!, $versionType: VersionType!) {
  queryRevenueAssumptionsStatus(
    budgetYear: $budgetYear
    versionType: $versionType
  ) {
    propertyId
    propertyName
    hasAssumptions
    year
    versionType
  }
}
    `;

/**
 * __useRevenueAssumptionsStatusQuery__
 *
 * To run a query within a React component, call `useRevenueAssumptionsStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useRevenueAssumptionsStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRevenueAssumptionsStatusQuery({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *      versionType: // value for 'versionType'
 *   },
 * });
 */
export function useRevenueAssumptionsStatusQuery(baseOptions: Apollo.QueryHookOptions<RevenueAssumptionsStatusQuery, RevenueAssumptionsStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RevenueAssumptionsStatusQuery, RevenueAssumptionsStatusQueryVariables>(RevenueAssumptionsStatusDocument, options);
      }
export function useRevenueAssumptionsStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RevenueAssumptionsStatusQuery, RevenueAssumptionsStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RevenueAssumptionsStatusQuery, RevenueAssumptionsStatusQueryVariables>(RevenueAssumptionsStatusDocument, options);
        }
export type RevenueAssumptionsStatusQueryHookResult = ReturnType<typeof useRevenueAssumptionsStatusQuery>;
export type RevenueAssumptionsStatusLazyQueryHookResult = ReturnType<typeof useRevenueAssumptionsStatusLazyQuery>;
export type RevenueAssumptionsStatusQueryResult = Apollo.QueryResult<RevenueAssumptionsStatusQuery, RevenueAssumptionsStatusQueryVariables>;
export const UpdateBudgetYearIsAutoUnitTypeUpdateEnabledDocument = gql`
    mutation UpdateBudgetYearIsAutoUnitTypeUpdateEnabled($budgetYear: Int!, $isAutoUnitTypeUpdateEnabled: Boolean!) {
  updateBudgetYearIsAutoUnitTypeUpdateEnabled(
    budgetYear: $budgetYear
    isAutoUnitTypeUpdateEnabled: $isAutoUnitTypeUpdateEnabled
  ) {
    activeBudgetYear
    budgetYears {
      year
      protected
      isAutoUnitTypeUpdateEnabled
    }
  }
}
    `;
export type UpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutationFn = Apollo.MutationFunction<UpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutation, UpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutationVariables>;

/**
 * __useUpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutation__
 *
 * To run a mutation, you first call `useUpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBudgetYearIsAutoUnitTypeUpdateEnabledMutation, { data, loading, error }] = useUpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutation({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *      isAutoUnitTypeUpdateEnabled: // value for 'isAutoUnitTypeUpdateEnabled'
 *   },
 * });
 */
export function useUpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutation, UpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutation, UpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutationVariables>(UpdateBudgetYearIsAutoUnitTypeUpdateEnabledDocument, options);
      }
export type UpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutationHookResult = ReturnType<typeof useUpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutation>;
export type UpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutationResult = Apollo.MutationResult<UpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutation>;
export type UpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutationOptions = Apollo.BaseMutationOptions<UpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutation, UpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutationVariables>;
export const UpdateBudgetYearProtectionDocument = gql`
    mutation UpdateBudgetYearProtection($budgetYear: Int!, $protected: Boolean!) {
  updateBudgetYearProtection(budgetYear: $budgetYear, protected: $protected) {
    activeBudgetYear
    budgetYears {
      year
      protected
      isAutoUnitTypeUpdateEnabled
    }
  }
}
    `;
export type UpdateBudgetYearProtectionMutationFn = Apollo.MutationFunction<UpdateBudgetYearProtectionMutation, UpdateBudgetYearProtectionMutationVariables>;

/**
 * __useUpdateBudgetYearProtectionMutation__
 *
 * To run a mutation, you first call `useUpdateBudgetYearProtectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBudgetYearProtectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBudgetYearProtectionMutation, { data, loading, error }] = useUpdateBudgetYearProtectionMutation({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *      protected: // value for 'protected'
 *   },
 * });
 */
export function useUpdateBudgetYearProtectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBudgetYearProtectionMutation, UpdateBudgetYearProtectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBudgetYearProtectionMutation, UpdateBudgetYearProtectionMutationVariables>(UpdateBudgetYearProtectionDocument, options);
      }
export type UpdateBudgetYearProtectionMutationHookResult = ReturnType<typeof useUpdateBudgetYearProtectionMutation>;
export type UpdateBudgetYearProtectionMutationResult = Apollo.MutationResult<UpdateBudgetYearProtectionMutation>;
export type UpdateBudgetYearProtectionMutationOptions = Apollo.BaseMutationOptions<UpdateBudgetYearProtectionMutation, UpdateBudgetYearProtectionMutationVariables>;
export const QueryAccountPercentageDriverCyclesIndicatorsDocument = gql`
    query QueryAccountPercentageDriverCyclesIndicators($propertyId: ID!) {
  queryAccountPercentageDriverCyclesIndicators(propertyId: $propertyId) {
    reforecast
    budget
  }
}
    `;

/**
 * __useQueryAccountPercentageDriverCyclesIndicatorsQuery__
 *
 * To run a query within a React component, call `useQueryAccountPercentageDriverCyclesIndicatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryAccountPercentageDriverCyclesIndicatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryAccountPercentageDriverCyclesIndicatorsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useQueryAccountPercentageDriverCyclesIndicatorsQuery(baseOptions: Apollo.QueryHookOptions<QueryAccountPercentageDriverCyclesIndicatorsQuery, QueryAccountPercentageDriverCyclesIndicatorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryAccountPercentageDriverCyclesIndicatorsQuery, QueryAccountPercentageDriverCyclesIndicatorsQueryVariables>(QueryAccountPercentageDriverCyclesIndicatorsDocument, options);
      }
export function useQueryAccountPercentageDriverCyclesIndicatorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryAccountPercentageDriverCyclesIndicatorsQuery, QueryAccountPercentageDriverCyclesIndicatorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryAccountPercentageDriverCyclesIndicatorsQuery, QueryAccountPercentageDriverCyclesIndicatorsQueryVariables>(QueryAccountPercentageDriverCyclesIndicatorsDocument, options);
        }
export type QueryAccountPercentageDriverCyclesIndicatorsQueryHookResult = ReturnType<typeof useQueryAccountPercentageDriverCyclesIndicatorsQuery>;
export type QueryAccountPercentageDriverCyclesIndicatorsLazyQueryHookResult = ReturnType<typeof useQueryAccountPercentageDriverCyclesIndicatorsLazyQuery>;
export type QueryAccountPercentageDriverCyclesIndicatorsQueryResult = Apollo.QueryResult<QueryAccountPercentageDriverCyclesIndicatorsQuery, QueryAccountPercentageDriverCyclesIndicatorsQueryVariables>;
export const QueryAccountPercentageDestinationAccountsWithCyclesDocument = gql`
    query QueryAccountPercentageDestinationAccountsWithCycles($propertyId: ID!, $versionType: VersionType!) {
  queryAccountPercentageDestinationAccountsWithCycles(
    propertyId: $propertyId
    versionType: $versionType
  ) {
    id
    glName
    glNumber
    order
  }
}
    `;

/**
 * __useQueryAccountPercentageDestinationAccountsWithCyclesQuery__
 *
 * To run a query within a React component, call `useQueryAccountPercentageDestinationAccountsWithCyclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryAccountPercentageDestinationAccountsWithCyclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryAccountPercentageDestinationAccountsWithCyclesQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      versionType: // value for 'versionType'
 *   },
 * });
 */
export function useQueryAccountPercentageDestinationAccountsWithCyclesQuery(baseOptions: Apollo.QueryHookOptions<QueryAccountPercentageDestinationAccountsWithCyclesQuery, QueryAccountPercentageDestinationAccountsWithCyclesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryAccountPercentageDestinationAccountsWithCyclesQuery, QueryAccountPercentageDestinationAccountsWithCyclesQueryVariables>(QueryAccountPercentageDestinationAccountsWithCyclesDocument, options);
      }
export function useQueryAccountPercentageDestinationAccountsWithCyclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryAccountPercentageDestinationAccountsWithCyclesQuery, QueryAccountPercentageDestinationAccountsWithCyclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryAccountPercentageDestinationAccountsWithCyclesQuery, QueryAccountPercentageDestinationAccountsWithCyclesQueryVariables>(QueryAccountPercentageDestinationAccountsWithCyclesDocument, options);
        }
export type QueryAccountPercentageDestinationAccountsWithCyclesQueryHookResult = ReturnType<typeof useQueryAccountPercentageDestinationAccountsWithCyclesQuery>;
export type QueryAccountPercentageDestinationAccountsWithCyclesLazyQueryHookResult = ReturnType<typeof useQueryAccountPercentageDestinationAccountsWithCyclesLazyQuery>;
export type QueryAccountPercentageDestinationAccountsWithCyclesQueryResult = Apollo.QueryResult<QueryAccountPercentageDestinationAccountsWithCyclesQuery, QueryAccountPercentageDestinationAccountsWithCyclesQueryVariables>;
export const QuerySourceAccountsDocument = gql`
    query QuerySourceAccounts($propertyId: ID!, $accountId: ID!, $year: Int!, $versionType: VersionType!) {
  singlePropertyAccount(
    propertyId: $propertyId
    accountId: $accountId
    year: $year
    versionType: $versionType
  ) {
    propertyId
    accountId
    versionType
    accountPercentageDriver {
      sourceAccountsBare {
        accountId
        glName
        glNumber
        lookbackPeriod
      }
    }
  }
}
    `;

/**
 * __useQuerySourceAccountsQuery__
 *
 * To run a query within a React component, call `useQuerySourceAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuerySourceAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySourceAccountsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      accountId: // value for 'accountId'
 *      year: // value for 'year'
 *      versionType: // value for 'versionType'
 *   },
 * });
 */
export function useQuerySourceAccountsQuery(baseOptions: Apollo.QueryHookOptions<QuerySourceAccountsQuery, QuerySourceAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuerySourceAccountsQuery, QuerySourceAccountsQueryVariables>(QuerySourceAccountsDocument, options);
      }
export function useQuerySourceAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuerySourceAccountsQuery, QuerySourceAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuerySourceAccountsQuery, QuerySourceAccountsQueryVariables>(QuerySourceAccountsDocument, options);
        }
export type QuerySourceAccountsQueryHookResult = ReturnType<typeof useQuerySourceAccountsQuery>;
export type QuerySourceAccountsLazyQueryHookResult = ReturnType<typeof useQuerySourceAccountsLazyQuery>;
export type QuerySourceAccountsQueryResult = Apollo.QueryResult<QuerySourceAccountsQuery, QuerySourceAccountsQueryVariables>;
export const SetAccPercentageDriversDocument = gql`
    mutation setAccPercentageDrivers($propertyId: ID!, $versionType: VersionType!, $year: Int!, $destinationAccountId: ID!, $drivers: [AccPercentageDriverSetInput!]!) {
  setAccPercentageDrivers(
    propertyId: $propertyId
    versionType: $versionType
    year: $year
    destinationAccountId: $destinationAccountId
    drivers: $drivers
  )
}
    `;
export type SetAccPercentageDriversMutationFn = Apollo.MutationFunction<SetAccPercentageDriversMutation, SetAccPercentageDriversMutationVariables>;

/**
 * __useSetAccPercentageDriversMutation__
 *
 * To run a mutation, you first call `useSetAccPercentageDriversMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAccPercentageDriversMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAccPercentageDriversMutation, { data, loading, error }] = useSetAccPercentageDriversMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      versionType: // value for 'versionType'
 *      year: // value for 'year'
 *      destinationAccountId: // value for 'destinationAccountId'
 *      drivers: // value for 'drivers'
 *   },
 * });
 */
export function useSetAccPercentageDriversMutation(baseOptions?: Apollo.MutationHookOptions<SetAccPercentageDriversMutation, SetAccPercentageDriversMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAccPercentageDriversMutation, SetAccPercentageDriversMutationVariables>(SetAccPercentageDriversDocument, options);
      }
export type SetAccPercentageDriversMutationHookResult = ReturnType<typeof useSetAccPercentageDriversMutation>;
export type SetAccPercentageDriversMutationResult = Apollo.MutationResult<SetAccPercentageDriversMutation>;
export type SetAccPercentageDriversMutationOptions = Apollo.BaseMutationOptions<SetAccPercentageDriversMutation, SetAccPercentageDriversMutationVariables>;
export const GetCoaManagementDataDocument = gql`
    query GetCOAManagementData($chartOfAccountsId: ID!) {
  listChartOfAccountsById(chartOfAccountsId: $chartOfAccountsId) {
    versionNum
    records {
      id
      glNumber
      glName
      type
      order
      parentIds
      negate
    }
  }
  fetchPMSAccounts(chartOfAccountsId: $chartOfAccountsId) {
    glName
    glNumber
    type
    order
    seenEarliest
    seenLatest
  }
}
    `;

/**
 * __useGetCoaManagementDataQuery__
 *
 * To run a query within a React component, call `useGetCoaManagementDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoaManagementDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoaManagementDataQuery({
 *   variables: {
 *      chartOfAccountsId: // value for 'chartOfAccountsId'
 *   },
 * });
 */
export function useGetCoaManagementDataQuery(baseOptions: Apollo.QueryHookOptions<GetCoaManagementDataQuery, GetCoaManagementDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCoaManagementDataQuery, GetCoaManagementDataQueryVariables>(GetCoaManagementDataDocument, options);
      }
export function useGetCoaManagementDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoaManagementDataQuery, GetCoaManagementDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCoaManagementDataQuery, GetCoaManagementDataQueryVariables>(GetCoaManagementDataDocument, options);
        }
export type GetCoaManagementDataQueryHookResult = ReturnType<typeof useGetCoaManagementDataQuery>;
export type GetCoaManagementDataLazyQueryHookResult = ReturnType<typeof useGetCoaManagementDataLazyQuery>;
export type GetCoaManagementDataQueryResult = Apollo.QueryResult<GetCoaManagementDataQuery, GetCoaManagementDataQueryVariables>;
export const GetDriverStatusForAccountDocument = gql`
    query GetDriverStatusForAccount($accountId: ID!) {
  queryDriverStatusForAccount(accountId: $accountId) {
    hasModellingMethods
    accPercentageSourceForAccounts {
      id
      glNumber
      glName
    }
    renovationCostCategories
    financialMetrics
  }
}
    `;

/**
 * __useGetDriverStatusForAccountQuery__
 *
 * To run a query within a React component, call `useGetDriverStatusForAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverStatusForAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverStatusForAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetDriverStatusForAccountQuery(baseOptions: Apollo.QueryHookOptions<GetDriverStatusForAccountQuery, GetDriverStatusForAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriverStatusForAccountQuery, GetDriverStatusForAccountQueryVariables>(GetDriverStatusForAccountDocument, options);
      }
export function useGetDriverStatusForAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriverStatusForAccountQuery, GetDriverStatusForAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriverStatusForAccountQuery, GetDriverStatusForAccountQueryVariables>(GetDriverStatusForAccountDocument, options);
        }
export type GetDriverStatusForAccountQueryHookResult = ReturnType<typeof useGetDriverStatusForAccountQuery>;
export type GetDriverStatusForAccountLazyQueryHookResult = ReturnType<typeof useGetDriverStatusForAccountLazyQuery>;
export type GetDriverStatusForAccountQueryResult = Apollo.QueryResult<GetDriverStatusForAccountQuery, GetDriverStatusForAccountQueryVariables>;
export const UpdateCoaManagementDataDocument = gql`
    mutation UpdateCOAManagementData($chartOfAccountsId: ID!, $versionNum: Int!, $records: [COAListRecordInput!]!) {
  updateChartOfAccountsById(
    chartOfAccountsId: $chartOfAccountsId
    versionNum: $versionNum
    records: $records
  )
}
    `;
export type UpdateCoaManagementDataMutationFn = Apollo.MutationFunction<UpdateCoaManagementDataMutation, UpdateCoaManagementDataMutationVariables>;

/**
 * __useUpdateCoaManagementDataMutation__
 *
 * To run a mutation, you first call `useUpdateCoaManagementDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoaManagementDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoaManagementDataMutation, { data, loading, error }] = useUpdateCoaManagementDataMutation({
 *   variables: {
 *      chartOfAccountsId: // value for 'chartOfAccountsId'
 *      versionNum: // value for 'versionNum'
 *      records: // value for 'records'
 *   },
 * });
 */
export function useUpdateCoaManagementDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCoaManagementDataMutation, UpdateCoaManagementDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCoaManagementDataMutation, UpdateCoaManagementDataMutationVariables>(UpdateCoaManagementDataDocument, options);
      }
export type UpdateCoaManagementDataMutationHookResult = ReturnType<typeof useUpdateCoaManagementDataMutation>;
export type UpdateCoaManagementDataMutationResult = Apollo.MutationResult<UpdateCoaManagementDataMutation>;
export type UpdateCoaManagementDataMutationOptions = Apollo.BaseMutationOptions<UpdateCoaManagementDataMutation, UpdateCoaManagementDataMutationVariables>;
export const AddAccountTagsDocument = gql`
    mutation AddAccountTags($accountIds: [ID!]!, $tags: [String!]!) {
  addAccountTags(accountIds: $accountIds, tags: $tags)
}
    `;
export type AddAccountTagsMutationFn = Apollo.MutationFunction<AddAccountTagsMutation, AddAccountTagsMutationVariables>;

/**
 * __useAddAccountTagsMutation__
 *
 * To run a mutation, you first call `useAddAccountTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAccountTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAccountTagsMutation, { data, loading, error }] = useAddAccountTagsMutation({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useAddAccountTagsMutation(baseOptions?: Apollo.MutationHookOptions<AddAccountTagsMutation, AddAccountTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAccountTagsMutation, AddAccountTagsMutationVariables>(AddAccountTagsDocument, options);
      }
export type AddAccountTagsMutationHookResult = ReturnType<typeof useAddAccountTagsMutation>;
export type AddAccountTagsMutationResult = Apollo.MutationResult<AddAccountTagsMutation>;
export type AddAccountTagsMutationOptions = Apollo.BaseMutationOptions<AddAccountTagsMutation, AddAccountTagsMutationVariables>;
export const QueryAccountTagsDocument = gql`
    query QueryAccountTags {
  queryAccountTags {
    accountId
    tags
  }
}
    `;

/**
 * __useQueryAccountTagsQuery__
 *
 * To run a query within a React component, call `useQueryAccountTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryAccountTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryAccountTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryAccountTagsQuery(baseOptions?: Apollo.QueryHookOptions<QueryAccountTagsQuery, QueryAccountTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryAccountTagsQuery, QueryAccountTagsQueryVariables>(QueryAccountTagsDocument, options);
      }
export function useQueryAccountTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryAccountTagsQuery, QueryAccountTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryAccountTagsQuery, QueryAccountTagsQueryVariables>(QueryAccountTagsDocument, options);
        }
export type QueryAccountTagsQueryHookResult = ReturnType<typeof useQueryAccountTagsQuery>;
export type QueryAccountTagsLazyQueryHookResult = ReturnType<typeof useQueryAccountTagsLazyQuery>;
export type QueryAccountTagsQueryResult = Apollo.QueryResult<QueryAccountTagsQuery, QueryAccountTagsQueryVariables>;
export const RemoveAccountTagsDocument = gql`
    mutation RemoveAccountTags($accountIds: [ID!]!, $tags: [String!]!) {
  removeAccountTags(accountIds: $accountIds, tags: $tags)
}
    `;
export type RemoveAccountTagsMutationFn = Apollo.MutationFunction<RemoveAccountTagsMutation, RemoveAccountTagsMutationVariables>;

/**
 * __useRemoveAccountTagsMutation__
 *
 * To run a mutation, you first call `useRemoveAccountTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAccountTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAccountTagsMutation, { data, loading, error }] = useRemoveAccountTagsMutation({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useRemoveAccountTagsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAccountTagsMutation, RemoveAccountTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAccountTagsMutation, RemoveAccountTagsMutationVariables>(RemoveAccountTagsDocument, options);
      }
export type RemoveAccountTagsMutationHookResult = ReturnType<typeof useRemoveAccountTagsMutation>;
export type RemoveAccountTagsMutationResult = Apollo.MutationResult<RemoveAccountTagsMutation>;
export type RemoveAccountTagsMutationOptions = Apollo.BaseMutationOptions<RemoveAccountTagsMutation, RemoveAccountTagsMutationVariables>;
export const SetAccountTagsDocument = gql`
    mutation SetAccountTags($accountIds: [ID!]!, $tags: [String!]!) {
  setAccountTags(accountIds: $accountIds, tags: $tags)
}
    `;
export type SetAccountTagsMutationFn = Apollo.MutationFunction<SetAccountTagsMutation, SetAccountTagsMutationVariables>;

/**
 * __useSetAccountTagsMutation__
 *
 * To run a mutation, you first call `useSetAccountTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAccountTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAccountTagsMutation, { data, loading, error }] = useSetAccountTagsMutation({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useSetAccountTagsMutation(baseOptions?: Apollo.MutationHookOptions<SetAccountTagsMutation, SetAccountTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAccountTagsMutation, SetAccountTagsMutationVariables>(SetAccountTagsDocument, options);
      }
export type SetAccountTagsMutationHookResult = ReturnType<typeof useSetAccountTagsMutation>;
export type SetAccountTagsMutationResult = Apollo.MutationResult<SetAccountTagsMutation>;
export type SetAccountTagsMutationOptions = Apollo.BaseMutationOptions<SetAccountTagsMutation, SetAccountTagsMutationVariables>;
export const AddCustomItemDocument = gql`
    mutation AddCustomItem($name: String!) {
  addCustomItem(name: $name)
}
    `;
export type AddCustomItemMutationFn = Apollo.MutationFunction<AddCustomItemMutation, AddCustomItemMutationVariables>;

/**
 * __useAddCustomItemMutation__
 *
 * To run a mutation, you first call `useAddCustomItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomItemMutation, { data, loading, error }] = useAddCustomItemMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddCustomItemMutation(baseOptions?: Apollo.MutationHookOptions<AddCustomItemMutation, AddCustomItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCustomItemMutation, AddCustomItemMutationVariables>(AddCustomItemDocument, options);
      }
export type AddCustomItemMutationHookResult = ReturnType<typeof useAddCustomItemMutation>;
export type AddCustomItemMutationResult = Apollo.MutationResult<AddCustomItemMutation>;
export type AddCustomItemMutationOptions = Apollo.BaseMutationOptions<AddCustomItemMutation, AddCustomItemMutationVariables>;
export const DeleteCustomItemDocument = gql`
    mutation DeleteCustomItem($id: ID!) {
  deleteCustomItem(id: $id)
}
    `;
export type DeleteCustomItemMutationFn = Apollo.MutationFunction<DeleteCustomItemMutation, DeleteCustomItemMutationVariables>;

/**
 * __useDeleteCustomItemMutation__
 *
 * To run a mutation, you first call `useDeleteCustomItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomItemMutation, { data, loading, error }] = useDeleteCustomItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomItemMutation, DeleteCustomItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomItemMutation, DeleteCustomItemMutationVariables>(DeleteCustomItemDocument, options);
      }
export type DeleteCustomItemMutationHookResult = ReturnType<typeof useDeleteCustomItemMutation>;
export type DeleteCustomItemMutationResult = Apollo.MutationResult<DeleteCustomItemMutation>;
export type DeleteCustomItemMutationOptions = Apollo.BaseMutationOptions<DeleteCustomItemMutation, DeleteCustomItemMutationVariables>;
export const QueryCustomItemsDocument = gql`
    query QueryCustomItems {
  queryCustomItems {
    id
    name
  }
}
    `;

/**
 * __useQueryCustomItemsQuery__
 *
 * To run a query within a React component, call `useQueryCustomItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCustomItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCustomItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryCustomItemsQuery(baseOptions?: Apollo.QueryHookOptions<QueryCustomItemsQuery, QueryCustomItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryCustomItemsQuery, QueryCustomItemsQueryVariables>(QueryCustomItemsDocument, options);
      }
export function useQueryCustomItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryCustomItemsQuery, QueryCustomItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryCustomItemsQuery, QueryCustomItemsQueryVariables>(QueryCustomItemsDocument, options);
        }
export type QueryCustomItemsQueryHookResult = ReturnType<typeof useQueryCustomItemsQuery>;
export type QueryCustomItemsLazyQueryHookResult = ReturnType<typeof useQueryCustomItemsLazyQuery>;
export type QueryCustomItemsQueryResult = Apollo.QueryResult<QueryCustomItemsQuery, QueryCustomItemsQueryVariables>;
export const UpdateCustomItemDocument = gql`
    mutation UpdateCustomItem($id: ID!, $name: String!) {
  updateCustomItem(id: $id, name: $name) {
    success
    errorCode
    name
  }
}
    `;
export type UpdateCustomItemMutationFn = Apollo.MutationFunction<UpdateCustomItemMutation, UpdateCustomItemMutationVariables>;

/**
 * __useUpdateCustomItemMutation__
 *
 * To run a mutation, you first call `useUpdateCustomItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomItemMutation, { data, loading, error }] = useUpdateCustomItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateCustomItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomItemMutation, UpdateCustomItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomItemMutation, UpdateCustomItemMutationVariables>(UpdateCustomItemDocument, options);
      }
export type UpdateCustomItemMutationHookResult = ReturnType<typeof useUpdateCustomItemMutation>;
export type UpdateCustomItemMutationResult = Apollo.MutationResult<UpdateCustomItemMutation>;
export type UpdateCustomItemMutationOptions = Apollo.BaseMutationOptions<UpdateCustomItemMutation, UpdateCustomItemMutationVariables>;
export const AddReportTableDocument = gql`
    mutation AddReportTable($budgetYear: Int!, $reportTableName: String!, $afterTableId: ID) {
  addReportTable(
    budgetYear: $budgetYear
    reportTableName: $reportTableName
    afterTableId: $afterTableId
  )
}
    `;
export type AddReportTableMutationFn = Apollo.MutationFunction<AddReportTableMutation, AddReportTableMutationVariables>;

/**
 * __useAddReportTableMutation__
 *
 * To run a mutation, you first call `useAddReportTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReportTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReportTableMutation, { data, loading, error }] = useAddReportTableMutation({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *      reportTableName: // value for 'reportTableName'
 *      afterTableId: // value for 'afterTableId'
 *   },
 * });
 */
export function useAddReportTableMutation(baseOptions?: Apollo.MutationHookOptions<AddReportTableMutation, AddReportTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddReportTableMutation, AddReportTableMutationVariables>(AddReportTableDocument, options);
      }
export type AddReportTableMutationHookResult = ReturnType<typeof useAddReportTableMutation>;
export type AddReportTableMutationResult = Apollo.MutationResult<AddReportTableMutation>;
export type AddReportTableMutationOptions = Apollo.BaseMutationOptions<AddReportTableMutation, AddReportTableMutationVariables>;
export const DeleteReportTableDocument = gql`
    mutation DeleteReportTable($budgetYear: Int!, $reportTableId: ID!) {
  deleteReportTable(budgetYear: $budgetYear, reportTableId: $reportTableId)
}
    `;
export type DeleteReportTableMutationFn = Apollo.MutationFunction<DeleteReportTableMutation, DeleteReportTableMutationVariables>;

/**
 * __useDeleteReportTableMutation__
 *
 * To run a mutation, you first call `useDeleteReportTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportTableMutation, { data, loading, error }] = useDeleteReportTableMutation({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *      reportTableId: // value for 'reportTableId'
 *   },
 * });
 */
export function useDeleteReportTableMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReportTableMutation, DeleteReportTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReportTableMutation, DeleteReportTableMutationVariables>(DeleteReportTableDocument, options);
      }
export type DeleteReportTableMutationHookResult = ReturnType<typeof useDeleteReportTableMutation>;
export type DeleteReportTableMutationResult = Apollo.MutationResult<DeleteReportTableMutation>;
export type DeleteReportTableMutationOptions = Apollo.BaseMutationOptions<DeleteReportTableMutation, DeleteReportTableMutationVariables>;
export const GetReportTablesDocument = gql`
    query GetReportTables($budgetYear: Int!) {
  getReportTables(budgetYear: $budgetYear) {
    id
    name
    order
    accounts {
      accountId
    }
  }
}
    `;

/**
 * __useGetReportTablesQuery__
 *
 * To run a query within a React component, call `useGetReportTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportTablesQuery({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *   },
 * });
 */
export function useGetReportTablesQuery(baseOptions: Apollo.QueryHookOptions<GetReportTablesQuery, GetReportTablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportTablesQuery, GetReportTablesQueryVariables>(GetReportTablesDocument, options);
      }
export function useGetReportTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportTablesQuery, GetReportTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportTablesQuery, GetReportTablesQueryVariables>(GetReportTablesDocument, options);
        }
export type GetReportTablesQueryHookResult = ReturnType<typeof useGetReportTablesQuery>;
export type GetReportTablesLazyQueryHookResult = ReturnType<typeof useGetReportTablesLazyQuery>;
export type GetReportTablesQueryResult = Apollo.QueryResult<GetReportTablesQuery, GetReportTablesQueryVariables>;
export const MoveReportTableDocument = gql`
    mutation MoveReportTable($budgetYear: Int!, $reportTableId: ID!, $afterTableId: ID) {
  moveReportTable(
    budgetYear: $budgetYear
    reportTableId: $reportTableId
    afterTableId: $afterTableId
  )
}
    `;
export type MoveReportTableMutationFn = Apollo.MutationFunction<MoveReportTableMutation, MoveReportTableMutationVariables>;

/**
 * __useMoveReportTableMutation__
 *
 * To run a mutation, you first call `useMoveReportTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveReportTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveReportTableMutation, { data, loading, error }] = useMoveReportTableMutation({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *      reportTableId: // value for 'reportTableId'
 *      afterTableId: // value for 'afterTableId'
 *   },
 * });
 */
export function useMoveReportTableMutation(baseOptions?: Apollo.MutationHookOptions<MoveReportTableMutation, MoveReportTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveReportTableMutation, MoveReportTableMutationVariables>(MoveReportTableDocument, options);
      }
export type MoveReportTableMutationHookResult = ReturnType<typeof useMoveReportTableMutation>;
export type MoveReportTableMutationResult = Apollo.MutationResult<MoveReportTableMutation>;
export type MoveReportTableMutationOptions = Apollo.BaseMutationOptions<MoveReportTableMutation, MoveReportTableMutationVariables>;
export const SetReportTableAccountsDocument = gql`
    mutation SetReportTableAccounts($budgetYear: Int!, $reportTableId: ID!, $accountIds: [ID!]!) {
  setReportTableAccounts(
    budgetYear: $budgetYear
    reportTableId: $reportTableId
    accountIds: $accountIds
  )
}
    `;
export type SetReportTableAccountsMutationFn = Apollo.MutationFunction<SetReportTableAccountsMutation, SetReportTableAccountsMutationVariables>;

/**
 * __useSetReportTableAccountsMutation__
 *
 * To run a mutation, you first call `useSetReportTableAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReportTableAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReportTableAccountsMutation, { data, loading, error }] = useSetReportTableAccountsMutation({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *      reportTableId: // value for 'reportTableId'
 *      accountIds: // value for 'accountIds'
 *   },
 * });
 */
export function useSetReportTableAccountsMutation(baseOptions?: Apollo.MutationHookOptions<SetReportTableAccountsMutation, SetReportTableAccountsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetReportTableAccountsMutation, SetReportTableAccountsMutationVariables>(SetReportTableAccountsDocument, options);
      }
export type SetReportTableAccountsMutationHookResult = ReturnType<typeof useSetReportTableAccountsMutation>;
export type SetReportTableAccountsMutationResult = Apollo.MutationResult<SetReportTableAccountsMutation>;
export type SetReportTableAccountsMutationOptions = Apollo.BaseMutationOptions<SetReportTableAccountsMutation, SetReportTableAccountsMutationVariables>;
export const UpdateReportTableDocument = gql`
    mutation UpdateReportTable($budgetYear: Int!, $reportTableId: ID!, $newName: String!) {
  updateReportTable(
    budgetYear: $budgetYear
    reportTableId: $reportTableId
    newName: $newName
  )
}
    `;
export type UpdateReportTableMutationFn = Apollo.MutationFunction<UpdateReportTableMutation, UpdateReportTableMutationVariables>;

/**
 * __useUpdateReportTableMutation__
 *
 * To run a mutation, you first call `useUpdateReportTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportTableMutation, { data, loading, error }] = useUpdateReportTableMutation({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *      reportTableId: // value for 'reportTableId'
 *      newName: // value for 'newName'
 *   },
 * });
 */
export function useUpdateReportTableMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportTableMutation, UpdateReportTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReportTableMutation, UpdateReportTableMutationVariables>(UpdateReportTableDocument, options);
      }
export type UpdateReportTableMutationHookResult = ReturnType<typeof useUpdateReportTableMutation>;
export type UpdateReportTableMutationResult = Apollo.MutationResult<UpdateReportTableMutation>;
export type UpdateReportTableMutationOptions = Apollo.BaseMutationOptions<UpdateReportTableMutation, UpdateReportTableMutationVariables>;
export const CreatePayrollEmployeeManyBonusesDocument = gql`
    mutation CreatePayrollEmployeeManyBonuses($employeeIds: [ID!]!, $bonusType: PayrollBonusType!, $frequency: PayrollBonusFrequency!, $amount: Decimal!, $bonusDay: Int, $bonusMonth: Int, $effectiveEndDate: DateTime, $effectiveStartDate: DateTime, $onetimeBonusDate: DateTime) {
  createPayrollEmployeeManyBonuses(
    employeeIds: $employeeIds
    bonusType: $bonusType
    frequency: $frequency
    amount: $amount
    bonusDay: $bonusDay
    bonusMonth: $bonusMonth
    effectiveEndDate: $effectiveEndDate
    effectiveStartDate: $effectiveStartDate
    onetimeBonusDate: $onetimeBonusDate
  )
}
    `;
export type CreatePayrollEmployeeManyBonusesMutationFn = Apollo.MutationFunction<CreatePayrollEmployeeManyBonusesMutation, CreatePayrollEmployeeManyBonusesMutationVariables>;

/**
 * __useCreatePayrollEmployeeManyBonusesMutation__
 *
 * To run a mutation, you first call `useCreatePayrollEmployeeManyBonusesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayrollEmployeeManyBonusesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayrollEmployeeManyBonusesMutation, { data, loading, error }] = useCreatePayrollEmployeeManyBonusesMutation({
 *   variables: {
 *      employeeIds: // value for 'employeeIds'
 *      bonusType: // value for 'bonusType'
 *      frequency: // value for 'frequency'
 *      amount: // value for 'amount'
 *      bonusDay: // value for 'bonusDay'
 *      bonusMonth: // value for 'bonusMonth'
 *      effectiveEndDate: // value for 'effectiveEndDate'
 *      effectiveStartDate: // value for 'effectiveStartDate'
 *      onetimeBonusDate: // value for 'onetimeBonusDate'
 *   },
 * });
 */
export function useCreatePayrollEmployeeManyBonusesMutation(baseOptions?: Apollo.MutationHookOptions<CreatePayrollEmployeeManyBonusesMutation, CreatePayrollEmployeeManyBonusesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePayrollEmployeeManyBonusesMutation, CreatePayrollEmployeeManyBonusesMutationVariables>(CreatePayrollEmployeeManyBonusesDocument, options);
      }
export type CreatePayrollEmployeeManyBonusesMutationHookResult = ReturnType<typeof useCreatePayrollEmployeeManyBonusesMutation>;
export type CreatePayrollEmployeeManyBonusesMutationResult = Apollo.MutationResult<CreatePayrollEmployeeManyBonusesMutation>;
export type CreatePayrollEmployeeManyBonusesMutationOptions = Apollo.BaseMutationOptions<CreatePayrollEmployeeManyBonusesMutation, CreatePayrollEmployeeManyBonusesMutationVariables>;
export const CreatePayrollEmployeeManyOvertimesDocument = gql`
    mutation CreatePayrollEmployeeManyOvertimes($employeeIds: [ID!]!, $hourlyRate: Decimal!, $hoursWeek: Decimal!, $effectiveStartDate: DateTime!, $effectiveEndDate: DateTime) {
  createPayrollEmployeeManyOvertimes(
    employeeIds: $employeeIds
    hourlyCompensation: $hourlyRate
    expectedHoursPerWeek: $hoursWeek
    effectiveStartDate: $effectiveStartDate
    effectiveEndDate: $effectiveEndDate
  )
}
    `;
export type CreatePayrollEmployeeManyOvertimesMutationFn = Apollo.MutationFunction<CreatePayrollEmployeeManyOvertimesMutation, CreatePayrollEmployeeManyOvertimesMutationVariables>;

/**
 * __useCreatePayrollEmployeeManyOvertimesMutation__
 *
 * To run a mutation, you first call `useCreatePayrollEmployeeManyOvertimesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayrollEmployeeManyOvertimesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayrollEmployeeManyOvertimesMutation, { data, loading, error }] = useCreatePayrollEmployeeManyOvertimesMutation({
 *   variables: {
 *      employeeIds: // value for 'employeeIds'
 *      hourlyRate: // value for 'hourlyRate'
 *      hoursWeek: // value for 'hoursWeek'
 *      effectiveStartDate: // value for 'effectiveStartDate'
 *      effectiveEndDate: // value for 'effectiveEndDate'
 *   },
 * });
 */
export function useCreatePayrollEmployeeManyOvertimesMutation(baseOptions?: Apollo.MutationHookOptions<CreatePayrollEmployeeManyOvertimesMutation, CreatePayrollEmployeeManyOvertimesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePayrollEmployeeManyOvertimesMutation, CreatePayrollEmployeeManyOvertimesMutationVariables>(CreatePayrollEmployeeManyOvertimesDocument, options);
      }
export type CreatePayrollEmployeeManyOvertimesMutationHookResult = ReturnType<typeof useCreatePayrollEmployeeManyOvertimesMutation>;
export type CreatePayrollEmployeeManyOvertimesMutationResult = Apollo.MutationResult<CreatePayrollEmployeeManyOvertimesMutation>;
export type CreatePayrollEmployeeManyOvertimesMutationOptions = Apollo.BaseMutationOptions<CreatePayrollEmployeeManyOvertimesMutation, CreatePayrollEmployeeManyOvertimesMutationVariables>;
export const CreatePayrollEmployeeManyRaisesDocument = gql`
    mutation CreatePayrollEmployeeManyRaises($employeeIds: [ID!]!, $raiseType: PayrollRaiseType!, $frequency: PayrollRaiseFrequency!, $amount: Decimal!, $raiseDay: Int, $raiseMonth: Int, $effectiveEndDate: DateTime, $effectiveStartDate: DateTime, $onetimeRaiseDate: DateTime) {
  createPayrollEmployeeManyRaises(
    employeeIds: $employeeIds
    raiseType: $raiseType
    frequency: $frequency
    amount: $amount
    raiseDay: $raiseDay
    raiseMonth: $raiseMonth
    effectiveEndDate: $effectiveEndDate
    effectiveStartDate: $effectiveStartDate
    onetimeRaiseDate: $onetimeRaiseDate
  )
}
    `;
export type CreatePayrollEmployeeManyRaisesMutationFn = Apollo.MutationFunction<CreatePayrollEmployeeManyRaisesMutation, CreatePayrollEmployeeManyRaisesMutationVariables>;

/**
 * __useCreatePayrollEmployeeManyRaisesMutation__
 *
 * To run a mutation, you first call `useCreatePayrollEmployeeManyRaisesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayrollEmployeeManyRaisesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayrollEmployeeManyRaisesMutation, { data, loading, error }] = useCreatePayrollEmployeeManyRaisesMutation({
 *   variables: {
 *      employeeIds: // value for 'employeeIds'
 *      raiseType: // value for 'raiseType'
 *      frequency: // value for 'frequency'
 *      amount: // value for 'amount'
 *      raiseDay: // value for 'raiseDay'
 *      raiseMonth: // value for 'raiseMonth'
 *      effectiveEndDate: // value for 'effectiveEndDate'
 *      effectiveStartDate: // value for 'effectiveStartDate'
 *      onetimeRaiseDate: // value for 'onetimeRaiseDate'
 *   },
 * });
 */
export function useCreatePayrollEmployeeManyRaisesMutation(baseOptions?: Apollo.MutationHookOptions<CreatePayrollEmployeeManyRaisesMutation, CreatePayrollEmployeeManyRaisesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePayrollEmployeeManyRaisesMutation, CreatePayrollEmployeeManyRaisesMutationVariables>(CreatePayrollEmployeeManyRaisesDocument, options);
      }
export type CreatePayrollEmployeeManyRaisesMutationHookResult = ReturnType<typeof useCreatePayrollEmployeeManyRaisesMutation>;
export type CreatePayrollEmployeeManyRaisesMutationResult = Apollo.MutationResult<CreatePayrollEmployeeManyRaisesMutation>;
export type CreatePayrollEmployeeManyRaisesMutationOptions = Apollo.BaseMutationOptions<CreatePayrollEmployeeManyRaisesMutation, CreatePayrollEmployeeManyRaisesMutationVariables>;
export const DeleteCompItemsFromEmployeesDocument = gql`
    mutation DeleteCompItemsFromEmployees($itemType: PayrollCompensationItemType!, $employeeIds: [ID!]!) {
  deleteCompItemsFromEmployees(itemType: $itemType, employeeIds: $employeeIds)
}
    `;
export type DeleteCompItemsFromEmployeesMutationFn = Apollo.MutationFunction<DeleteCompItemsFromEmployeesMutation, DeleteCompItemsFromEmployeesMutationVariables>;

/**
 * __useDeleteCompItemsFromEmployeesMutation__
 *
 * To run a mutation, you first call `useDeleteCompItemsFromEmployeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompItemsFromEmployeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompItemsFromEmployeesMutation, { data, loading, error }] = useDeleteCompItemsFromEmployeesMutation({
 *   variables: {
 *      itemType: // value for 'itemType'
 *      employeeIds: // value for 'employeeIds'
 *   },
 * });
 */
export function useDeleteCompItemsFromEmployeesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompItemsFromEmployeesMutation, DeleteCompItemsFromEmployeesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompItemsFromEmployeesMutation, DeleteCompItemsFromEmployeesMutationVariables>(DeleteCompItemsFromEmployeesDocument, options);
      }
export type DeleteCompItemsFromEmployeesMutationHookResult = ReturnType<typeof useDeleteCompItemsFromEmployeesMutation>;
export type DeleteCompItemsFromEmployeesMutationResult = Apollo.MutationResult<DeleteCompItemsFromEmployeesMutation>;
export type DeleteCompItemsFromEmployeesMutationOptions = Apollo.BaseMutationOptions<DeleteCompItemsFromEmployeesMutation, DeleteCompItemsFromEmployeesMutationVariables>;
export const DeletePayrollEmployeeManyBonusesDocument = gql`
    mutation DeletePayrollEmployeeManyBonuses($bonusIds: [ID!]!) {
  deletePayrollEmployeeManyBonuses(bonusIds: $bonusIds)
}
    `;
export type DeletePayrollEmployeeManyBonusesMutationFn = Apollo.MutationFunction<DeletePayrollEmployeeManyBonusesMutation, DeletePayrollEmployeeManyBonusesMutationVariables>;

/**
 * __useDeletePayrollEmployeeManyBonusesMutation__
 *
 * To run a mutation, you first call `useDeletePayrollEmployeeManyBonusesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePayrollEmployeeManyBonusesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePayrollEmployeeManyBonusesMutation, { data, loading, error }] = useDeletePayrollEmployeeManyBonusesMutation({
 *   variables: {
 *      bonusIds: // value for 'bonusIds'
 *   },
 * });
 */
export function useDeletePayrollEmployeeManyBonusesMutation(baseOptions?: Apollo.MutationHookOptions<DeletePayrollEmployeeManyBonusesMutation, DeletePayrollEmployeeManyBonusesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePayrollEmployeeManyBonusesMutation, DeletePayrollEmployeeManyBonusesMutationVariables>(DeletePayrollEmployeeManyBonusesDocument, options);
      }
export type DeletePayrollEmployeeManyBonusesMutationHookResult = ReturnType<typeof useDeletePayrollEmployeeManyBonusesMutation>;
export type DeletePayrollEmployeeManyBonusesMutationResult = Apollo.MutationResult<DeletePayrollEmployeeManyBonusesMutation>;
export type DeletePayrollEmployeeManyBonusesMutationOptions = Apollo.BaseMutationOptions<DeletePayrollEmployeeManyBonusesMutation, DeletePayrollEmployeeManyBonusesMutationVariables>;
export const DeletePayrollEmployeeManyOvertimesDocument = gql`
    mutation DeletePayrollEmployeeManyOvertimes($overtimeIds: [ID!]!) {
  deletePayrollEmployeeManyOvertimes(overtimeIds: $overtimeIds)
}
    `;
export type DeletePayrollEmployeeManyOvertimesMutationFn = Apollo.MutationFunction<DeletePayrollEmployeeManyOvertimesMutation, DeletePayrollEmployeeManyOvertimesMutationVariables>;

/**
 * __useDeletePayrollEmployeeManyOvertimesMutation__
 *
 * To run a mutation, you first call `useDeletePayrollEmployeeManyOvertimesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePayrollEmployeeManyOvertimesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePayrollEmployeeManyOvertimesMutation, { data, loading, error }] = useDeletePayrollEmployeeManyOvertimesMutation({
 *   variables: {
 *      overtimeIds: // value for 'overtimeIds'
 *   },
 * });
 */
export function useDeletePayrollEmployeeManyOvertimesMutation(baseOptions?: Apollo.MutationHookOptions<DeletePayrollEmployeeManyOvertimesMutation, DeletePayrollEmployeeManyOvertimesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePayrollEmployeeManyOvertimesMutation, DeletePayrollEmployeeManyOvertimesMutationVariables>(DeletePayrollEmployeeManyOvertimesDocument, options);
      }
export type DeletePayrollEmployeeManyOvertimesMutationHookResult = ReturnType<typeof useDeletePayrollEmployeeManyOvertimesMutation>;
export type DeletePayrollEmployeeManyOvertimesMutationResult = Apollo.MutationResult<DeletePayrollEmployeeManyOvertimesMutation>;
export type DeletePayrollEmployeeManyOvertimesMutationOptions = Apollo.BaseMutationOptions<DeletePayrollEmployeeManyOvertimesMutation, DeletePayrollEmployeeManyOvertimesMutationVariables>;
export const DeletePayrollEmployeeManyRaisesDocument = gql`
    mutation DeletePayrollEmployeeManyRaises($raiseIds: [ID!]!) {
  deletePayrollEmployeeManyRaises(raiseIds: $raiseIds)
}
    `;
export type DeletePayrollEmployeeManyRaisesMutationFn = Apollo.MutationFunction<DeletePayrollEmployeeManyRaisesMutation, DeletePayrollEmployeeManyRaisesMutationVariables>;

/**
 * __useDeletePayrollEmployeeManyRaisesMutation__
 *
 * To run a mutation, you first call `useDeletePayrollEmployeeManyRaisesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePayrollEmployeeManyRaisesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePayrollEmployeeManyRaisesMutation, { data, loading, error }] = useDeletePayrollEmployeeManyRaisesMutation({
 *   variables: {
 *      raiseIds: // value for 'raiseIds'
 *   },
 * });
 */
export function useDeletePayrollEmployeeManyRaisesMutation(baseOptions?: Apollo.MutationHookOptions<DeletePayrollEmployeeManyRaisesMutation, DeletePayrollEmployeeManyRaisesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePayrollEmployeeManyRaisesMutation, DeletePayrollEmployeeManyRaisesMutationVariables>(DeletePayrollEmployeeManyRaisesDocument, options);
      }
export type DeletePayrollEmployeeManyRaisesMutationHookResult = ReturnType<typeof useDeletePayrollEmployeeManyRaisesMutation>;
export type DeletePayrollEmployeeManyRaisesMutationResult = Apollo.MutationResult<DeletePayrollEmployeeManyRaisesMutation>;
export type DeletePayrollEmployeeManyRaisesMutationOptions = Apollo.BaseMutationOptions<DeletePayrollEmployeeManyRaisesMutation, DeletePayrollEmployeeManyRaisesMutationVariables>;
export const SetPayrollEmployeeManyCompItemsDocument = gql`
    mutation SetPayrollEmployeeManyCompItems($employeeIds: [ID!]!, $input: PayrollUpdateEmployeeCompItemInput!, $type: PayrollCompensationItemType!, $createCalcType: PayrollCompensationItemCalculationType!) {
  setPayrollEmployeeManyCompItems(
    employeeIds: $employeeIds
    input: $input
    type: $type
    createCalcType: $createCalcType
  )
}
    `;
export type SetPayrollEmployeeManyCompItemsMutationFn = Apollo.MutationFunction<SetPayrollEmployeeManyCompItemsMutation, SetPayrollEmployeeManyCompItemsMutationVariables>;

/**
 * __useSetPayrollEmployeeManyCompItemsMutation__
 *
 * To run a mutation, you first call `useSetPayrollEmployeeManyCompItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPayrollEmployeeManyCompItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPayrollEmployeeManyCompItemsMutation, { data, loading, error }] = useSetPayrollEmployeeManyCompItemsMutation({
 *   variables: {
 *      employeeIds: // value for 'employeeIds'
 *      input: // value for 'input'
 *      type: // value for 'type'
 *      createCalcType: // value for 'createCalcType'
 *   },
 * });
 */
export function useSetPayrollEmployeeManyCompItemsMutation(baseOptions?: Apollo.MutationHookOptions<SetPayrollEmployeeManyCompItemsMutation, SetPayrollEmployeeManyCompItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPayrollEmployeeManyCompItemsMutation, SetPayrollEmployeeManyCompItemsMutationVariables>(SetPayrollEmployeeManyCompItemsDocument, options);
      }
export type SetPayrollEmployeeManyCompItemsMutationHookResult = ReturnType<typeof useSetPayrollEmployeeManyCompItemsMutation>;
export type SetPayrollEmployeeManyCompItemsMutationResult = Apollo.MutationResult<SetPayrollEmployeeManyCompItemsMutation>;
export type SetPayrollEmployeeManyCompItemsMutationOptions = Apollo.BaseMutationOptions<SetPayrollEmployeeManyCompItemsMutation, SetPayrollEmployeeManyCompItemsMutationVariables>;
export const UpdatePayrollEmployeeManyBonusesDocument = gql`
    mutation UpdatePayrollEmployeeManyBonuses($bonusIds: [ID!]!, $bonusType: PayrollBonusType!, $frequency: PayrollBonusFrequency!, $amount: Decimal!, $bonusDay: Int, $bonusMonth: Int, $effectiveEndDate: DateTime, $effectiveStartDate: DateTime, $onetimeBonusDate: DateTime) {
  updatePayrollEmployeeManyBonuses(
    bonusIds: $bonusIds
    bonusType: $bonusType
    frequency: $frequency
    amount: $amount
    bonusDay: $bonusDay
    bonusMonth: $bonusMonth
    effectiveEndDate: $effectiveEndDate
    effectiveStartDate: $effectiveStartDate
    onetimeBonusDate: $onetimeBonusDate
  )
}
    `;
export type UpdatePayrollEmployeeManyBonusesMutationFn = Apollo.MutationFunction<UpdatePayrollEmployeeManyBonusesMutation, UpdatePayrollEmployeeManyBonusesMutationVariables>;

/**
 * __useUpdatePayrollEmployeeManyBonusesMutation__
 *
 * To run a mutation, you first call `useUpdatePayrollEmployeeManyBonusesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayrollEmployeeManyBonusesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayrollEmployeeManyBonusesMutation, { data, loading, error }] = useUpdatePayrollEmployeeManyBonusesMutation({
 *   variables: {
 *      bonusIds: // value for 'bonusIds'
 *      bonusType: // value for 'bonusType'
 *      frequency: // value for 'frequency'
 *      amount: // value for 'amount'
 *      bonusDay: // value for 'bonusDay'
 *      bonusMonth: // value for 'bonusMonth'
 *      effectiveEndDate: // value for 'effectiveEndDate'
 *      effectiveStartDate: // value for 'effectiveStartDate'
 *      onetimeBonusDate: // value for 'onetimeBonusDate'
 *   },
 * });
 */
export function useUpdatePayrollEmployeeManyBonusesMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePayrollEmployeeManyBonusesMutation, UpdatePayrollEmployeeManyBonusesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePayrollEmployeeManyBonusesMutation, UpdatePayrollEmployeeManyBonusesMutationVariables>(UpdatePayrollEmployeeManyBonusesDocument, options);
      }
export type UpdatePayrollEmployeeManyBonusesMutationHookResult = ReturnType<typeof useUpdatePayrollEmployeeManyBonusesMutation>;
export type UpdatePayrollEmployeeManyBonusesMutationResult = Apollo.MutationResult<UpdatePayrollEmployeeManyBonusesMutation>;
export type UpdatePayrollEmployeeManyBonusesMutationOptions = Apollo.BaseMutationOptions<UpdatePayrollEmployeeManyBonusesMutation, UpdatePayrollEmployeeManyBonusesMutationVariables>;
export const UpdatePayrollEmployeeManyOvertimesDocument = gql`
    mutation UpdatePayrollEmployeeManyOvertimes($overtimeIds: [ID!]!, $hourlyRate: Decimal!, $hoursWeek: Decimal!, $effectiveStartDate: DateTime!, $effectiveEndDate: DateTime) {
  updatePayrollEmployeeManyOvertimes(
    overtimeIds: $overtimeIds
    hourlyCompensation: $hourlyRate
    expectedHoursPerWeek: $hoursWeek
    effectiveStartDate: $effectiveStartDate
    effectiveEndDate: $effectiveEndDate
  )
}
    `;
export type UpdatePayrollEmployeeManyOvertimesMutationFn = Apollo.MutationFunction<UpdatePayrollEmployeeManyOvertimesMutation, UpdatePayrollEmployeeManyOvertimesMutationVariables>;

/**
 * __useUpdatePayrollEmployeeManyOvertimesMutation__
 *
 * To run a mutation, you first call `useUpdatePayrollEmployeeManyOvertimesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayrollEmployeeManyOvertimesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayrollEmployeeManyOvertimesMutation, { data, loading, error }] = useUpdatePayrollEmployeeManyOvertimesMutation({
 *   variables: {
 *      overtimeIds: // value for 'overtimeIds'
 *      hourlyRate: // value for 'hourlyRate'
 *      hoursWeek: // value for 'hoursWeek'
 *      effectiveStartDate: // value for 'effectiveStartDate'
 *      effectiveEndDate: // value for 'effectiveEndDate'
 *   },
 * });
 */
export function useUpdatePayrollEmployeeManyOvertimesMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePayrollEmployeeManyOvertimesMutation, UpdatePayrollEmployeeManyOvertimesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePayrollEmployeeManyOvertimesMutation, UpdatePayrollEmployeeManyOvertimesMutationVariables>(UpdatePayrollEmployeeManyOvertimesDocument, options);
      }
export type UpdatePayrollEmployeeManyOvertimesMutationHookResult = ReturnType<typeof useUpdatePayrollEmployeeManyOvertimesMutation>;
export type UpdatePayrollEmployeeManyOvertimesMutationResult = Apollo.MutationResult<UpdatePayrollEmployeeManyOvertimesMutation>;
export type UpdatePayrollEmployeeManyOvertimesMutationOptions = Apollo.BaseMutationOptions<UpdatePayrollEmployeeManyOvertimesMutation, UpdatePayrollEmployeeManyOvertimesMutationVariables>;
export const UpdatePayrollEmployeeManyRaisesDocument = gql`
    mutation UpdatePayrollEmployeeManyRaises($raiseIds: [ID!]!, $raiseType: PayrollRaiseType!, $frequency: PayrollRaiseFrequency!, $amount: Decimal!, $raiseDay: Int, $raiseMonth: Int, $effectiveEndDate: DateTime, $effectiveStartDate: DateTime, $onetimeRaiseDate: DateTime) {
  updatePayrollEmployeeManyRaises(
    raiseIds: $raiseIds
    raiseType: $raiseType
    frequency: $frequency
    amount: $amount
    raiseDay: $raiseDay
    raiseMonth: $raiseMonth
    effectiveEndDate: $effectiveEndDate
    effectiveStartDate: $effectiveStartDate
    onetimeRaiseDate: $onetimeRaiseDate
  )
}
    `;
export type UpdatePayrollEmployeeManyRaisesMutationFn = Apollo.MutationFunction<UpdatePayrollEmployeeManyRaisesMutation, UpdatePayrollEmployeeManyRaisesMutationVariables>;

/**
 * __useUpdatePayrollEmployeeManyRaisesMutation__
 *
 * To run a mutation, you first call `useUpdatePayrollEmployeeManyRaisesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayrollEmployeeManyRaisesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayrollEmployeeManyRaisesMutation, { data, loading, error }] = useUpdatePayrollEmployeeManyRaisesMutation({
 *   variables: {
 *      raiseIds: // value for 'raiseIds'
 *      raiseType: // value for 'raiseType'
 *      frequency: // value for 'frequency'
 *      amount: // value for 'amount'
 *      raiseDay: // value for 'raiseDay'
 *      raiseMonth: // value for 'raiseMonth'
 *      effectiveEndDate: // value for 'effectiveEndDate'
 *      effectiveStartDate: // value for 'effectiveStartDate'
 *      onetimeRaiseDate: // value for 'onetimeRaiseDate'
 *   },
 * });
 */
export function useUpdatePayrollEmployeeManyRaisesMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePayrollEmployeeManyRaisesMutation, UpdatePayrollEmployeeManyRaisesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePayrollEmployeeManyRaisesMutation, UpdatePayrollEmployeeManyRaisesMutationVariables>(UpdatePayrollEmployeeManyRaisesDocument, options);
      }
export type UpdatePayrollEmployeeManyRaisesMutationHookResult = ReturnType<typeof useUpdatePayrollEmployeeManyRaisesMutation>;
export type UpdatePayrollEmployeeManyRaisesMutationResult = Apollo.MutationResult<UpdatePayrollEmployeeManyRaisesMutation>;
export type UpdatePayrollEmployeeManyRaisesMutationOptions = Apollo.BaseMutationOptions<UpdatePayrollEmployeeManyRaisesMutation, UpdatePayrollEmployeeManyRaisesMutationVariables>;
export const GetLatestPortfolioCalcSnapshotInfoDocument = gql`
    query GetLatestPortfolioCalcSnapshotInfo($budgetYear: Int!) {
  getLatestPortfolioCalcSnapshotInfo(budgetYear: $budgetYear) {
    id
    createdAt
  }
}
    `;

/**
 * __useGetLatestPortfolioCalcSnapshotInfoQuery__
 *
 * To run a query within a React component, call `useGetLatestPortfolioCalcSnapshotInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestPortfolioCalcSnapshotInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestPortfolioCalcSnapshotInfoQuery({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *   },
 * });
 */
export function useGetLatestPortfolioCalcSnapshotInfoQuery(baseOptions: Apollo.QueryHookOptions<GetLatestPortfolioCalcSnapshotInfoQuery, GetLatestPortfolioCalcSnapshotInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestPortfolioCalcSnapshotInfoQuery, GetLatestPortfolioCalcSnapshotInfoQueryVariables>(GetLatestPortfolioCalcSnapshotInfoDocument, options);
      }
export function useGetLatestPortfolioCalcSnapshotInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestPortfolioCalcSnapshotInfoQuery, GetLatestPortfolioCalcSnapshotInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestPortfolioCalcSnapshotInfoQuery, GetLatestPortfolioCalcSnapshotInfoQueryVariables>(GetLatestPortfolioCalcSnapshotInfoDocument, options);
        }
export type GetLatestPortfolioCalcSnapshotInfoQueryHookResult = ReturnType<typeof useGetLatestPortfolioCalcSnapshotInfoQuery>;
export type GetLatestPortfolioCalcSnapshotInfoLazyQueryHookResult = ReturnType<typeof useGetLatestPortfolioCalcSnapshotInfoLazyQuery>;
export type GetLatestPortfolioCalcSnapshotInfoQueryResult = Apollo.QueryResult<GetLatestPortfolioCalcSnapshotInfoQuery, GetLatestPortfolioCalcSnapshotInfoQueryVariables>;
export const RequestPortfolioCalcSnapshotCreationDocument = gql`
    mutation RequestPortfolioCalcSnapshotCreation($budgetYear: Int!) {
  requestPortfolioCalcSnapshotCreation(budgetYear: $budgetYear)
}
    `;
export type RequestPortfolioCalcSnapshotCreationMutationFn = Apollo.MutationFunction<RequestPortfolioCalcSnapshotCreationMutation, RequestPortfolioCalcSnapshotCreationMutationVariables>;

/**
 * __useRequestPortfolioCalcSnapshotCreationMutation__
 *
 * To run a mutation, you first call `useRequestPortfolioCalcSnapshotCreationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPortfolioCalcSnapshotCreationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPortfolioCalcSnapshotCreationMutation, { data, loading, error }] = useRequestPortfolioCalcSnapshotCreationMutation({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *   },
 * });
 */
export function useRequestPortfolioCalcSnapshotCreationMutation(baseOptions?: Apollo.MutationHookOptions<RequestPortfolioCalcSnapshotCreationMutation, RequestPortfolioCalcSnapshotCreationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPortfolioCalcSnapshotCreationMutation, RequestPortfolioCalcSnapshotCreationMutationVariables>(RequestPortfolioCalcSnapshotCreationDocument, options);
      }
export type RequestPortfolioCalcSnapshotCreationMutationHookResult = ReturnType<typeof useRequestPortfolioCalcSnapshotCreationMutation>;
export type RequestPortfolioCalcSnapshotCreationMutationResult = Apollo.MutationResult<RequestPortfolioCalcSnapshotCreationMutation>;
export type RequestPortfolioCalcSnapshotCreationMutationOptions = Apollo.BaseMutationOptions<RequestPortfolioCalcSnapshotCreationMutation, RequestPortfolioCalcSnapshotCreationMutationVariables>;
export const CopyPropertyDriversDocument = gql`
    mutation copyPropertyDrivers($budgetYear: Int!, $sourcePropertyId: String!, $destinationPropertyId: String!, $period: DriversCopyPeriodInput, $accountIds: [ID!], $copyAssumptions: Boolean) {
  copyDrivers(
    budgetYear: $budgetYear
    sourcePropertyId: $sourcePropertyId
    destinationPropertyId: $destinationPropertyId
    period: $period
    accountIds: $accountIds
    copyAssumptions: $copyAssumptions
  ) {
    result
    resultString
    destinationPropertyId
  }
}
    `;
export type CopyPropertyDriversMutationFn = Apollo.MutationFunction<CopyPropertyDriversMutation, CopyPropertyDriversMutationVariables>;

/**
 * __useCopyPropertyDriversMutation__
 *
 * To run a mutation, you first call `useCopyPropertyDriversMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyPropertyDriversMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyPropertyDriversMutation, { data, loading, error }] = useCopyPropertyDriversMutation({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *      sourcePropertyId: // value for 'sourcePropertyId'
 *      destinationPropertyId: // value for 'destinationPropertyId'
 *      period: // value for 'period'
 *      accountIds: // value for 'accountIds'
 *      copyAssumptions: // value for 'copyAssumptions'
 *   },
 * });
 */
export function useCopyPropertyDriversMutation(baseOptions?: Apollo.MutationHookOptions<CopyPropertyDriversMutation, CopyPropertyDriversMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyPropertyDriversMutation, CopyPropertyDriversMutationVariables>(CopyPropertyDriversDocument, options);
      }
export type CopyPropertyDriversMutationHookResult = ReturnType<typeof useCopyPropertyDriversMutation>;
export type CopyPropertyDriversMutationResult = Apollo.MutationResult<CopyPropertyDriversMutation>;
export type CopyPropertyDriversMutationOptions = Apollo.BaseMutationOptions<CopyPropertyDriversMutation, CopyPropertyDriversMutationVariables>;
export const SubmitCopyDriversDocument = gql`
    mutation SubmitCopyDrivers($budgetYear: Int!, $sourcePropertyId: ID!, $destinationPropertyIds: [ID!]!, $period: DriversCopyPeriodInput, $accountIds: [ID!], $copyAssumptions: Boolean) {
  submitCopyDrivers(
    budgetYear: $budgetYear
    sourcePropertyId: $sourcePropertyId
    destinationPropertyIds: $destinationPropertyIds
    period: $period
    accountIds: $accountIds
    copyAssumptions: $copyAssumptions
  ) {
    id
    jobType
    status
    createdAt
    started
    ended
    errorMessage
  }
}
    `;
export type SubmitCopyDriversMutationFn = Apollo.MutationFunction<SubmitCopyDriversMutation, SubmitCopyDriversMutationVariables>;

/**
 * __useSubmitCopyDriversMutation__
 *
 * To run a mutation, you first call `useSubmitCopyDriversMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitCopyDriversMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitCopyDriversMutation, { data, loading, error }] = useSubmitCopyDriversMutation({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *      sourcePropertyId: // value for 'sourcePropertyId'
 *      destinationPropertyIds: // value for 'destinationPropertyIds'
 *      period: // value for 'period'
 *      accountIds: // value for 'accountIds'
 *      copyAssumptions: // value for 'copyAssumptions'
 *   },
 * });
 */
export function useSubmitCopyDriversMutation(baseOptions?: Apollo.MutationHookOptions<SubmitCopyDriversMutation, SubmitCopyDriversMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitCopyDriversMutation, SubmitCopyDriversMutationVariables>(SubmitCopyDriversDocument, options);
      }
export type SubmitCopyDriversMutationHookResult = ReturnType<typeof useSubmitCopyDriversMutation>;
export type SubmitCopyDriversMutationResult = Apollo.MutationResult<SubmitCopyDriversMutation>;
export type SubmitCopyDriversMutationOptions = Apollo.BaseMutationOptions<SubmitCopyDriversMutation, SubmitCopyDriversMutationVariables>;
export const CreateSharedReportLinksDocument = gql`
    mutation CreateSharedReportLinks($budgetYear: Int!, $propertyId: ID!, $message: String, $persons: [PersonEmailInput!]!) {
  createSharedReportLinks(
    budgetYear: $budgetYear
    propertyId: $propertyId
    message: $message
    persons: $persons
  )
}
    `;
export type CreateSharedReportLinksMutationFn = Apollo.MutationFunction<CreateSharedReportLinksMutation, CreateSharedReportLinksMutationVariables>;

/**
 * __useCreateSharedReportLinksMutation__
 *
 * To run a mutation, you first call `useCreateSharedReportLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSharedReportLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSharedReportLinksMutation, { data, loading, error }] = useCreateSharedReportLinksMutation({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *      propertyId: // value for 'propertyId'
 *      message: // value for 'message'
 *      persons: // value for 'persons'
 *   },
 * });
 */
export function useCreateSharedReportLinksMutation(baseOptions?: Apollo.MutationHookOptions<CreateSharedReportLinksMutation, CreateSharedReportLinksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSharedReportLinksMutation, CreateSharedReportLinksMutationVariables>(CreateSharedReportLinksDocument, options);
      }
export type CreateSharedReportLinksMutationHookResult = ReturnType<typeof useCreateSharedReportLinksMutation>;
export type CreateSharedReportLinksMutationResult = Apollo.MutationResult<CreateSharedReportLinksMutation>;
export type CreateSharedReportLinksMutationOptions = Apollo.BaseMutationOptions<CreateSharedReportLinksMutation, CreateSharedReportLinksMutationVariables>;
export const DeleteClientCommentDocument = gql`
    mutation deleteClientComment($clientCommentId: ID!) {
  deleteClientComment(clientCommentId: $clientCommentId)
}
    `;
export type DeleteClientCommentMutationFn = Apollo.MutationFunction<DeleteClientCommentMutation, DeleteClientCommentMutationVariables>;

/**
 * __useDeleteClientCommentMutation__
 *
 * To run a mutation, you first call `useDeleteClientCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientCommentMutation, { data, loading, error }] = useDeleteClientCommentMutation({
 *   variables: {
 *      clientCommentId: // value for 'clientCommentId'
 *   },
 * });
 */
export function useDeleteClientCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClientCommentMutation, DeleteClientCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClientCommentMutation, DeleteClientCommentMutationVariables>(DeleteClientCommentDocument, options);
      }
export type DeleteClientCommentMutationHookResult = ReturnType<typeof useDeleteClientCommentMutation>;
export type DeleteClientCommentMutationResult = Apollo.MutationResult<DeleteClientCommentMutation>;
export type DeleteClientCommentMutationOptions = Apollo.BaseMutationOptions<DeleteClientCommentMutation, DeleteClientCommentMutationVariables>;
export const GetBudgetApprovalStatusDocument = gql`
    query GetBudgetApprovalStatus($propertyId: ID!, $budgetYear: Int!) {
  getBudgetApprovalStatus(propertyId: $propertyId, budgetYear: $budgetYear) {
    id
    propertyId
    budgetYear
    userId
    userFirstName
    userLastName
    userEmail
    timestamp
    approved
  }
}
    `;

/**
 * __useGetBudgetApprovalStatusQuery__
 *
 * To run a query within a React component, call `useGetBudgetApprovalStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetApprovalStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetApprovalStatusQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *   },
 * });
 */
export function useGetBudgetApprovalStatusQuery(baseOptions: Apollo.QueryHookOptions<GetBudgetApprovalStatusQuery, GetBudgetApprovalStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBudgetApprovalStatusQuery, GetBudgetApprovalStatusQueryVariables>(GetBudgetApprovalStatusDocument, options);
      }
export function useGetBudgetApprovalStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBudgetApprovalStatusQuery, GetBudgetApprovalStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBudgetApprovalStatusQuery, GetBudgetApprovalStatusQueryVariables>(GetBudgetApprovalStatusDocument, options);
        }
export type GetBudgetApprovalStatusQueryHookResult = ReturnType<typeof useGetBudgetApprovalStatusQuery>;
export type GetBudgetApprovalStatusLazyQueryHookResult = ReturnType<typeof useGetBudgetApprovalStatusLazyQuery>;
export type GetBudgetApprovalStatusQueryResult = Apollo.QueryResult<GetBudgetApprovalStatusQuery, GetBudgetApprovalStatusQueryVariables>;
export const GetClientCommentSummaryForAccountDocument = gql`
    query GetClientCommentSummaryForAccount($propertyId: ID!, $budgetYear: Int!, $accountId: ID!) {
  getClientCommentSummaryForAccount(
    propertyId: $propertyId
    budgetYear: $budgetYear
    accountId: $accountId
  ) {
    id
    propertyId
    accountId
    budgetYear
    text
    createdAt
    count
  }
}
    `;

/**
 * __useGetClientCommentSummaryForAccountQuery__
 *
 * To run a query within a React component, call `useGetClientCommentSummaryForAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientCommentSummaryForAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientCommentSummaryForAccountQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetClientCommentSummaryForAccountQuery(baseOptions: Apollo.QueryHookOptions<GetClientCommentSummaryForAccountQuery, GetClientCommentSummaryForAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientCommentSummaryForAccountQuery, GetClientCommentSummaryForAccountQueryVariables>(GetClientCommentSummaryForAccountDocument, options);
      }
export function useGetClientCommentSummaryForAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientCommentSummaryForAccountQuery, GetClientCommentSummaryForAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientCommentSummaryForAccountQuery, GetClientCommentSummaryForAccountQueryVariables>(GetClientCommentSummaryForAccountDocument, options);
        }
export type GetClientCommentSummaryForAccountQueryHookResult = ReturnType<typeof useGetClientCommentSummaryForAccountQuery>;
export type GetClientCommentSummaryForAccountLazyQueryHookResult = ReturnType<typeof useGetClientCommentSummaryForAccountLazyQuery>;
export type GetClientCommentSummaryForAccountQueryResult = Apollo.QueryResult<GetClientCommentSummaryForAccountQuery, GetClientCommentSummaryForAccountQueryVariables>;
export const GetClientCommentsForAccountDocument = gql`
    query GetClientCommentsForAccount($budgetYear: Int!, $propertyId: ID!, $accountId: ID!) {
  getClientCommentsForAccount(
    budgetYear: $budgetYear
    propertyId: $propertyId
    accountId: $accountId
  ) {
    id
    propertyId
    accountId
    budgetYear
    text
    authorId
    authorName
    authorLastName
    authorEmail
    createdAt
  }
}
    `;

/**
 * __useGetClientCommentsForAccountQuery__
 *
 * To run a query within a React component, call `useGetClientCommentsForAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientCommentsForAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientCommentsForAccountQuery({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *      propertyId: // value for 'propertyId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetClientCommentsForAccountQuery(baseOptions: Apollo.QueryHookOptions<GetClientCommentsForAccountQuery, GetClientCommentsForAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientCommentsForAccountQuery, GetClientCommentsForAccountQueryVariables>(GetClientCommentsForAccountDocument, options);
      }
export function useGetClientCommentsForAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientCommentsForAccountQuery, GetClientCommentsForAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientCommentsForAccountQuery, GetClientCommentsForAccountQueryVariables>(GetClientCommentsForAccountDocument, options);
        }
export type GetClientCommentsForAccountQueryHookResult = ReturnType<typeof useGetClientCommentsForAccountQuery>;
export type GetClientCommentsForAccountLazyQueryHookResult = ReturnType<typeof useGetClientCommentsForAccountLazyQuery>;
export type GetClientCommentsForAccountQueryResult = Apollo.QueryResult<GetClientCommentsForAccountQuery, GetClientCommentsForAccountQueryVariables>;
export const GetPropertyAccountDriverStatusDocument = gql`
    query GetPropertyAccountDriverStatus($budgetYear: Int!, $propertyId: ID!) {
  queryPropertyAccountDriverStatus(
    budgetYear: $budgetYear
    propertyId: $propertyId
    versionType: BUDGET
  ) {
    accountId
    revenue
    renovation
    accPercentage
    operational
    worksheet
    growth
    payroll
    customDriver
    hasOverrides
  }
}
    `;

/**
 * __useGetPropertyAccountDriverStatusQuery__
 *
 * To run a query within a React component, call `useGetPropertyAccountDriverStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyAccountDriverStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyAccountDriverStatusQuery({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetPropertyAccountDriverStatusQuery(baseOptions: Apollo.QueryHookOptions<GetPropertyAccountDriverStatusQuery, GetPropertyAccountDriverStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPropertyAccountDriverStatusQuery, GetPropertyAccountDriverStatusQueryVariables>(GetPropertyAccountDriverStatusDocument, options);
      }
export function useGetPropertyAccountDriverStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropertyAccountDriverStatusQuery, GetPropertyAccountDriverStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPropertyAccountDriverStatusQuery, GetPropertyAccountDriverStatusQueryVariables>(GetPropertyAccountDriverStatusDocument, options);
        }
export type GetPropertyAccountDriverStatusQueryHookResult = ReturnType<typeof useGetPropertyAccountDriverStatusQuery>;
export type GetPropertyAccountDriverStatusLazyQueryHookResult = ReturnType<typeof useGetPropertyAccountDriverStatusLazyQuery>;
export type GetPropertyAccountDriverStatusQueryResult = Apollo.QueryResult<GetPropertyAccountDriverStatusQuery, GetPropertyAccountDriverStatusQueryVariables>;
export const GetPropertyExecutiveSummaryDataDocument = gql`
    query GetPropertyExecutiveSummaryData($propertyId: ID!, $budgetYear: Int!, $topCardTagTypes: [TopCardTagType!]!) {
  financialValuesPropertyBudgetSeason(propertyId: $propertyId) {
    accountId
    year
    versionType
    values
  }
  clientReportT12FinancialValues(propertyId: $propertyId, budgetYear: $budgetYear) {
    t12Month
    t12Year
    financialValues {
      accountId
      values
    }
  }
  getReportTables(budgetYear: $budgetYear) {
    id
    name
    order
    accounts {
      accountId
    }
  }
  queryNotesForProperty(budgetYear: $budgetYear, propertyId: $propertyId) {
    accountId
    reforecastNote
    budgetNote
  }
  getClientFirstComments(budgetYear: $budgetYear, propertyId: $propertyId) {
    id
    propertyId
    accountId
    budgetYear
    text
    createdAt
    count
  }
  getAccountsTopCardTags(accountIds: [], topCardTagTypes: $topCardTagTypes) {
    accountId
    topCardTagType
  }
  originalRevenueModel(propertyId: $propertyId, budgetYear: $budgetYear) {
    propertyId
    budgetYear
    earlyTerminationCount {
      actuals
      reforecast
      budget
    }
    grossPotentialRent {
      actuals
      reforecast
      budget
    }
    grossPotentialRentAndVacancyLoss {
      actuals
      reforecast
      budget
    }
    lossToLease {
      actuals
      reforecast
      budget
    }
    m2mMoveOutCount {
      actuals
      reforecast
      budget
    }
    momLossToLeaseChange {
      actuals
      reforecast
      budget
    }
    rent {
      actuals
      reforecast
      budget
    }
    vacancyLoss {
      actuals
      reforecast
      budget
    }
    unitTypeAvgMarketRent {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeBeginningOccupancy {
      unitTypeId
      unitTypeName
      value
    }
    unitTypeExpirationCount {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeInPlaceRent {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    totalMoveInCount {
      actuals
      reforecast
      budget
    }
    totalMoveOutCount {
      actuals
      reforecast
      budget
    }
    unitTypeMoveInCount {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeMoveOutCount {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeNewLeaseTradeOut {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeOccupiedCount {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeRenewalCount {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeRenewalTradeOut {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeStartingInPlaceRent {
      unitTypeId
      unitTypeName
      value
    }
    unitTypeUnitCount {
      unitTypeId
      unitTypeName
      value
    }
    unitTypeUnitCountMonthly {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeVacancyCount {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
  }
  employeeTotals(propertyId: $propertyId) {
    employeeId
    positionName
    basePayTotal
    raiseTotal
  }
}
    `;

/**
 * __useGetPropertyExecutiveSummaryDataQuery__
 *
 * To run a query within a React component, call `useGetPropertyExecutiveSummaryDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyExecutiveSummaryDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyExecutiveSummaryDataQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      topCardTagTypes: // value for 'topCardTagTypes'
 *   },
 * });
 */
export function useGetPropertyExecutiveSummaryDataQuery(baseOptions: Apollo.QueryHookOptions<GetPropertyExecutiveSummaryDataQuery, GetPropertyExecutiveSummaryDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPropertyExecutiveSummaryDataQuery, GetPropertyExecutiveSummaryDataQueryVariables>(GetPropertyExecutiveSummaryDataDocument, options);
      }
export function useGetPropertyExecutiveSummaryDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropertyExecutiveSummaryDataQuery, GetPropertyExecutiveSummaryDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPropertyExecutiveSummaryDataQuery, GetPropertyExecutiveSummaryDataQueryVariables>(GetPropertyExecutiveSummaryDataDocument, options);
        }
export type GetPropertyExecutiveSummaryDataQueryHookResult = ReturnType<typeof useGetPropertyExecutiveSummaryDataQuery>;
export type GetPropertyExecutiveSummaryDataLazyQueryHookResult = ReturnType<typeof useGetPropertyExecutiveSummaryDataLazyQuery>;
export type GetPropertyExecutiveSummaryDataQueryResult = Apollo.QueryResult<GetPropertyExecutiveSummaryDataQuery, GetPropertyExecutiveSummaryDataQueryVariables>;
export const GetSharedReportLinksDocument = gql`
    query GetSharedReportLinks($budgetYear: Int!, $propertyId: ID!) {
  getSharedReportLinks(budgetYear: $budgetYear, propertyId: $propertyId) {
    id
    firstName
    lastName
    email
    url
  }
}
    `;

/**
 * __useGetSharedReportLinksQuery__
 *
 * To run a query within a React component, call `useGetSharedReportLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSharedReportLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSharedReportLinksQuery({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetSharedReportLinksQuery(baseOptions: Apollo.QueryHookOptions<GetSharedReportLinksQuery, GetSharedReportLinksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSharedReportLinksQuery, GetSharedReportLinksQueryVariables>(GetSharedReportLinksDocument, options);
      }
export function useGetSharedReportLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSharedReportLinksQuery, GetSharedReportLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSharedReportLinksQuery, GetSharedReportLinksQueryVariables>(GetSharedReportLinksDocument, options);
        }
export type GetSharedReportLinksQueryHookResult = ReturnType<typeof useGetSharedReportLinksQuery>;
export type GetSharedReportLinksLazyQueryHookResult = ReturnType<typeof useGetSharedReportLinksLazyQuery>;
export type GetSharedReportLinksQueryResult = Apollo.QueryResult<GetSharedReportLinksQuery, GetSharedReportLinksQueryVariables>;
export const RemoveSharedReportLinkDocument = gql`
    mutation RemoveSharedReportLink($id: ID!) {
  removeSharedReportLink(id: $id)
}
    `;
export type RemoveSharedReportLinkMutationFn = Apollo.MutationFunction<RemoveSharedReportLinkMutation, RemoveSharedReportLinkMutationVariables>;

/**
 * __useRemoveSharedReportLinkMutation__
 *
 * To run a mutation, you first call `useRemoveSharedReportLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSharedReportLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSharedReportLinkMutation, { data, loading, error }] = useRemoveSharedReportLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSharedReportLinkMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSharedReportLinkMutation, RemoveSharedReportLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSharedReportLinkMutation, RemoveSharedReportLinkMutationVariables>(RemoveSharedReportLinkDocument, options);
      }
export type RemoveSharedReportLinkMutationHookResult = ReturnType<typeof useRemoveSharedReportLinkMutation>;
export type RemoveSharedReportLinkMutationResult = Apollo.MutationResult<RemoveSharedReportLinkMutation>;
export type RemoveSharedReportLinkMutationOptions = Apollo.BaseMutationOptions<RemoveSharedReportLinkMutation, RemoveSharedReportLinkMutationVariables>;
export const ResendSharedReportLinkDocument = gql`
    mutation ResendSharedReportLink($id: ID!) {
  resendSharedReportLink(id: $id)
}
    `;
export type ResendSharedReportLinkMutationFn = Apollo.MutationFunction<ResendSharedReportLinkMutation, ResendSharedReportLinkMutationVariables>;

/**
 * __useResendSharedReportLinkMutation__
 *
 * To run a mutation, you first call `useResendSharedReportLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendSharedReportLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendSharedReportLinkMutation, { data, loading, error }] = useResendSharedReportLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendSharedReportLinkMutation(baseOptions?: Apollo.MutationHookOptions<ResendSharedReportLinkMutation, ResendSharedReportLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendSharedReportLinkMutation, ResendSharedReportLinkMutationVariables>(ResendSharedReportLinkDocument, options);
      }
export type ResendSharedReportLinkMutationHookResult = ReturnType<typeof useResendSharedReportLinkMutation>;
export type ResendSharedReportLinkMutationResult = Apollo.MutationResult<ResendSharedReportLinkMutation>;
export type ResendSharedReportLinkMutationOptions = Apollo.BaseMutationOptions<ResendSharedReportLinkMutation, ResendSharedReportLinkMutationVariables>;
export const SetBudgetApprovalDocument = gql`
    mutation SetBudgetApproval($propertyId: ID!, $budgetYear: Int!, $approved: Boolean!) {
  setBudgetApproval(
    propertyId: $propertyId
    budgetYear: $budgetYear
    approved: $approved
  )
}
    `;
export type SetBudgetApprovalMutationFn = Apollo.MutationFunction<SetBudgetApprovalMutation, SetBudgetApprovalMutationVariables>;

/**
 * __useSetBudgetApprovalMutation__
 *
 * To run a mutation, you first call `useSetBudgetApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBudgetApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBudgetApprovalMutation, { data, loading, error }] = useSetBudgetApprovalMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      approved: // value for 'approved'
 *   },
 * });
 */
export function useSetBudgetApprovalMutation(baseOptions?: Apollo.MutationHookOptions<SetBudgetApprovalMutation, SetBudgetApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetBudgetApprovalMutation, SetBudgetApprovalMutationVariables>(SetBudgetApprovalDocument, options);
      }
export type SetBudgetApprovalMutationHookResult = ReturnType<typeof useSetBudgetApprovalMutation>;
export type SetBudgetApprovalMutationResult = Apollo.MutationResult<SetBudgetApprovalMutation>;
export type SetBudgetApprovalMutationOptions = Apollo.BaseMutationOptions<SetBudgetApprovalMutation, SetBudgetApprovalMutationVariables>;
export const SetClientCommentDocument = gql`
    mutation SetClientComment($budgetYear: Int!, $propertyId: ID!, $accountId: ID!, $text: String!) {
  setClientComment(
    budgetYear: $budgetYear
    propertyId: $propertyId
    accountId: $accountId
    text: $text
  ) {
    id
    propertyId
    accountId
    budgetYear
    text
  }
}
    `;
export type SetClientCommentMutationFn = Apollo.MutationFunction<SetClientCommentMutation, SetClientCommentMutationVariables>;

/**
 * __useSetClientCommentMutation__
 *
 * To run a mutation, you first call `useSetClientCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetClientCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setClientCommentMutation, { data, loading, error }] = useSetClientCommentMutation({
 *   variables: {
 *      budgetYear: // value for 'budgetYear'
 *      propertyId: // value for 'propertyId'
 *      accountId: // value for 'accountId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useSetClientCommentMutation(baseOptions?: Apollo.MutationHookOptions<SetClientCommentMutation, SetClientCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetClientCommentMutation, SetClientCommentMutationVariables>(SetClientCommentDocument, options);
      }
export type SetClientCommentMutationHookResult = ReturnType<typeof useSetClientCommentMutation>;
export type SetClientCommentMutationResult = Apollo.MutationResult<SetClientCommentMutation>;
export type SetClientCommentMutationOptions = Apollo.BaseMutationOptions<SetClientCommentMutation, SetClientCommentMutationVariables>;
export const FetchPmsPropertiesDocument = gql`
    query FetchPMSProperties {
  fetchPMSProperties {
    name
    seenEarliest
    seenLatest
  }
}
    `;

/**
 * __useFetchPmsPropertiesQuery__
 *
 * To run a query within a React component, call `useFetchPmsPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPmsPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPmsPropertiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchPmsPropertiesQuery(baseOptions?: Apollo.QueryHookOptions<FetchPmsPropertiesQuery, FetchPmsPropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchPmsPropertiesQuery, FetchPmsPropertiesQueryVariables>(FetchPmsPropertiesDocument, options);
      }
export function useFetchPmsPropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchPmsPropertiesQuery, FetchPmsPropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchPmsPropertiesQuery, FetchPmsPropertiesQueryVariables>(FetchPmsPropertiesDocument, options);
        }
export type FetchPmsPropertiesQueryHookResult = ReturnType<typeof useFetchPmsPropertiesQuery>;
export type FetchPmsPropertiesLazyQueryHookResult = ReturnType<typeof useFetchPmsPropertiesLazyQuery>;
export type FetchPmsPropertiesQueryResult = Apollo.QueryResult<FetchPmsPropertiesQuery, FetchPmsPropertiesQueryVariables>;
export const UpdateReforecastStartMonthDocument = gql`
    mutation UpdateReforecastStartMonth($reforecastStartMonthIndex: Int!, $propertyIds: [ID!]!) {
  updateReforecastStartMonth(
    reforecastStartMonthIndex: $reforecastStartMonthIndex
    propertyIds: $propertyIds
  )
}
    `;
export type UpdateReforecastStartMonthMutationFn = Apollo.MutationFunction<UpdateReforecastStartMonthMutation, UpdateReforecastStartMonthMutationVariables>;

/**
 * __useUpdateReforecastStartMonthMutation__
 *
 * To run a mutation, you first call `useUpdateReforecastStartMonthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReforecastStartMonthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReforecastStartMonthMutation, { data, loading, error }] = useUpdateReforecastStartMonthMutation({
 *   variables: {
 *      reforecastStartMonthIndex: // value for 'reforecastStartMonthIndex'
 *      propertyIds: // value for 'propertyIds'
 *   },
 * });
 */
export function useUpdateReforecastStartMonthMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReforecastStartMonthMutation, UpdateReforecastStartMonthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReforecastStartMonthMutation, UpdateReforecastStartMonthMutationVariables>(UpdateReforecastStartMonthDocument, options);
      }
export type UpdateReforecastStartMonthMutationHookResult = ReturnType<typeof useUpdateReforecastStartMonthMutation>;
export type UpdateReforecastStartMonthMutationResult = Apollo.MutationResult<UpdateReforecastStartMonthMutation>;
export type UpdateReforecastStartMonthMutationOptions = Apollo.BaseMutationOptions<UpdateReforecastStartMonthMutation, UpdateReforecastStartMonthMutationVariables>;
export const ListRenovationPackagesDocument = gql`
    query listRenovationPackages($propertyIds: [String!]!, $budgetYear: Int!) {
  queryRenovationPackages(propertyIds: $propertyIds) {
    id
    name
    premium
    propertyId
    unitTypeId
    simplifiedRevenueRenovationMapping(budgetYear: $budgetYear) {
      unrenovatedUnitTypeId
      unrenovatedUnitTypeName
      renovatedUnitTypeId
      renovatedUnitTypeName
      renovatedUnitTypeStartingRent
    }
  }
}
    `;

/**
 * __useListRenovationPackagesQuery__
 *
 * To run a query within a React component, call `useListRenovationPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRenovationPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRenovationPackagesQuery({
 *   variables: {
 *      propertyIds: // value for 'propertyIds'
 *      budgetYear: // value for 'budgetYear'
 *   },
 * });
 */
export function useListRenovationPackagesQuery(baseOptions: Apollo.QueryHookOptions<ListRenovationPackagesQuery, ListRenovationPackagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListRenovationPackagesQuery, ListRenovationPackagesQueryVariables>(ListRenovationPackagesDocument, options);
      }
export function useListRenovationPackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListRenovationPackagesQuery, ListRenovationPackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListRenovationPackagesQuery, ListRenovationPackagesQueryVariables>(ListRenovationPackagesDocument, options);
        }
export type ListRenovationPackagesQueryHookResult = ReturnType<typeof useListRenovationPackagesQuery>;
export type ListRenovationPackagesLazyQueryHookResult = ReturnType<typeof useListRenovationPackagesLazyQuery>;
export type ListRenovationPackagesQueryResult = Apollo.QueryResult<ListRenovationPackagesQuery, ListRenovationPackagesQueryVariables>;
export const GetOccupancyRatesActualsDocument = gql`
    query GetOccupancyRatesActuals($propertyId: String!, $year: Int!) {
  actuals: valueVersions(
    where: {AND: [{year: {lte: $year}}, {type: {equals: ACTUALS}}]}
  ) {
    id
    name
    type
    year
    unitTypeScheduleValues(where: {propertyId: {equals: $propertyId}}) {
      id
      monthIndex
      unitType {
        id
        name
      }
      unitCount
      occupiedUnits
      unitTypeId
    }
  }
}
    `;

/**
 * __useGetOccupancyRatesActualsQuery__
 *
 * To run a query within a React component, call `useGetOccupancyRatesActualsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOccupancyRatesActualsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOccupancyRatesActualsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetOccupancyRatesActualsQuery(baseOptions: Apollo.QueryHookOptions<GetOccupancyRatesActualsQuery, GetOccupancyRatesActualsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOccupancyRatesActualsQuery, GetOccupancyRatesActualsQueryVariables>(GetOccupancyRatesActualsDocument, options);
      }
export function useGetOccupancyRatesActualsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOccupancyRatesActualsQuery, GetOccupancyRatesActualsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOccupancyRatesActualsQuery, GetOccupancyRatesActualsQueryVariables>(GetOccupancyRatesActualsDocument, options);
        }
export type GetOccupancyRatesActualsQueryHookResult = ReturnType<typeof useGetOccupancyRatesActualsQuery>;
export type GetOccupancyRatesActualsLazyQueryHookResult = ReturnType<typeof useGetOccupancyRatesActualsLazyQuery>;
export type GetOccupancyRatesActualsQueryResult = Apollo.QueryResult<GetOccupancyRatesActualsQuery, GetOccupancyRatesActualsQueryVariables>;
export const GetRenewalRatioCountsActualsDocument = gql`
    query GetRenewalRatioCountsActuals($propertyId: String!, $year: Int!) {
  actuals: valueVersions(
    where: {AND: [{year: {lte: $year}}, {type: {equals: ACTUALS}}]}
  ) {
    id
    name
    type
    year
    unitTypeScheduleValues(where: {propertyId: {equals: $propertyId}}) {
      id
      monthIndex
      unitType {
        id
        name
      }
      renewalRatio
      leaseExpirationCount
    }
  }
}
    `;

/**
 * __useGetRenewalRatioCountsActualsQuery__
 *
 * To run a query within a React component, call `useGetRenewalRatioCountsActualsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRenewalRatioCountsActualsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRenewalRatioCountsActualsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetRenewalRatioCountsActualsQuery(baseOptions: Apollo.QueryHookOptions<GetRenewalRatioCountsActualsQuery, GetRenewalRatioCountsActualsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRenewalRatioCountsActualsQuery, GetRenewalRatioCountsActualsQueryVariables>(GetRenewalRatioCountsActualsDocument, options);
      }
export function useGetRenewalRatioCountsActualsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRenewalRatioCountsActualsQuery, GetRenewalRatioCountsActualsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRenewalRatioCountsActualsQuery, GetRenewalRatioCountsActualsQueryVariables>(GetRenewalRatioCountsActualsDocument, options);
        }
export type GetRenewalRatioCountsActualsQueryHookResult = ReturnType<typeof useGetRenewalRatioCountsActualsQuery>;
export type GetRenewalRatioCountsActualsLazyQueryHookResult = ReturnType<typeof useGetRenewalRatioCountsActualsLazyQuery>;
export type GetRenewalRatioCountsActualsQueryResult = Apollo.QueryResult<GetRenewalRatioCountsActualsQuery, GetRenewalRatioCountsActualsQueryVariables>;
export const GetSimplifiedRevenueDocument = gql`
    query GetSimplifiedRevenue($propertyId: ID!, $budgetYear: Int!) {
  simplifiedRevenueModel(propertyId: $propertyId, budgetYear: $budgetYear) {
    propertyId
    budgetYear
    rentRoll {
      date
      expiringUnits {
        unitId
        unitNumber
        reason
        conversionMonth
      }
    }
    defaultMarketRents {
      reforecastAnnualIncreasePercentage
      reforecast {
        averageMarketRent
        growthAmount
        growthPercentage
        startingAverageMarketRent
      }
      budgetAnnualIncreasePercentage
      budget {
        averageMarketRent
        growthAmount
        growthPercentage
        startingAverageMarketRent
      }
    }
    defaultVacancyPercentage {
      reforecast
      budget
    }
    renewalConfiguration {
      reforecast {
        maxIncreaseAmount
        maxIncreasePercentage
        maxPercentOfMarketRent
        lossToLeaseCaptureRate
        increaseInPlaceGreaterThanLossToLease
      }
    }
    defaultRenovationRatio {
      reforecast
      budget
    }
    defaultRenewalRatios {
      reforecast
      budget
    }
    defaultRenovatedRenewalRatios {
      reforecast
      budget
    }
    availableRentRolls {
      id
      propertyId
      date
      isActive
    }
    unitTypeMarketRents {
      unitTypeId
      unitTypeName
      rentRollStartingMarketRent
      overrideStartingMarketRent
    }
    fakeUnitTypeMarketRents {
      name
      startingMarketRent
    }
  }
}
    `;

/**
 * __useGetSimplifiedRevenueQuery__
 *
 * To run a query within a React component, call `useGetSimplifiedRevenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimplifiedRevenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimplifiedRevenueQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *   },
 * });
 */
export function useGetSimplifiedRevenueQuery(baseOptions: Apollo.QueryHookOptions<GetSimplifiedRevenueQuery, GetSimplifiedRevenueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSimplifiedRevenueQuery, GetSimplifiedRevenueQueryVariables>(GetSimplifiedRevenueDocument, options);
      }
export function useGetSimplifiedRevenueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSimplifiedRevenueQuery, GetSimplifiedRevenueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSimplifiedRevenueQuery, GetSimplifiedRevenueQueryVariables>(GetSimplifiedRevenueDocument, options);
        }
export type GetSimplifiedRevenueQueryHookResult = ReturnType<typeof useGetSimplifiedRevenueQuery>;
export type GetSimplifiedRevenueLazyQueryHookResult = ReturnType<typeof useGetSimplifiedRevenueLazyQuery>;
export type GetSimplifiedRevenueQueryResult = Apollo.QueryResult<GetSimplifiedRevenueQuery, GetSimplifiedRevenueQueryVariables>;
export const GetSimplifiedRevenueSummaryDocument = gql`
    query GetSimplifiedRevenueSummary($propertyId: ID!, $budgetYear: Int!) {
  simplifiedRevenueModel(propertyId: $propertyId, budgetYear: $budgetYear) {
    propertyId
    budgetYear
    unitTypeUnitCounts {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeAvgMarketRents {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeBlendedInPlaceRent {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeNewLeaseInPlaceRent {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeRenewalInPlaceRent {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeRenewalTradeOutAmount {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeOccupancy {
      moveIns {
        unitTypeId
        unitTypeName
        actuals
        reforecast
        budget
      }
      moveOuts {
        unitTypeId
        unitTypeName
        actuals
        reforecast
        budget
      }
      occupancyPercentage {
        unitTypeId
        unitTypeName
        actuals
        reforecast
        budget
      }
      occupiedUnitCount {
        unitTypeId
        unitTypeName
        actuals
        reforecast
        budget
      }
      vacancyPercentage {
        unitTypeId
        unitTypeName
        actuals
        reforecast
        budget
      }
      vacantCount {
        unitTypeId
        unitTypeName
        actuals
        reforecast
        budget
      }
    }
    unitTypeRenewals {
      expirationCount {
        unitTypeId
        unitTypeName
        actuals
        reforecast
        budget
      }
      expirationPercentage {
        unitTypeId
        unitTypeName
        actuals
        reforecast
        budget
      }
      renewalCount {
        unitTypeId
        unitTypeName
        actuals
        reforecast
        budget
      }
      renewalPercentage {
        unitTypeId
        unitTypeName
        actuals
        reforecast
        budget
      }
    }
    unitTypeRenovations {
      renovationCount {
        unitTypeId
        unitTypeName
        actuals
        reforecast
        budget
      }
      renovatedUnitCount {
        unitTypeId
        unitTypeName
        actuals
        reforecast
        budget
      }
      renovatedExpirationCount {
        unitTypeId
        unitTypeName
        actuals
        reforecast
        budget
      }
      renovatedRenewalRatio {
        unitTypeId
        unitTypeName
        actuals
        reforecast
        budget
      }
      unrenovatedUnitCount {
        unitTypeId
        unitTypeName
        actuals
        reforecast
        budget
      }
      unrenovatedExpirationCount {
        unitTypeId
        unitTypeName
        actuals
        reforecast
        budget
      }
      unrenovatedRenewalRatio {
        unitTypeId
        unitTypeName
        actuals
        reforecast
        budget
      }
    }
  }
}
    `;

/**
 * __useGetSimplifiedRevenueSummaryQuery__
 *
 * To run a query within a React component, call `useGetSimplifiedRevenueSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimplifiedRevenueSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimplifiedRevenueSummaryQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *   },
 * });
 */
export function useGetSimplifiedRevenueSummaryQuery(baseOptions: Apollo.QueryHookOptions<GetSimplifiedRevenueSummaryQuery, GetSimplifiedRevenueSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSimplifiedRevenueSummaryQuery, GetSimplifiedRevenueSummaryQueryVariables>(GetSimplifiedRevenueSummaryDocument, options);
      }
export function useGetSimplifiedRevenueSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSimplifiedRevenueSummaryQuery, GetSimplifiedRevenueSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSimplifiedRevenueSummaryQuery, GetSimplifiedRevenueSummaryQueryVariables>(GetSimplifiedRevenueSummaryDocument, options);
        }
export type GetSimplifiedRevenueSummaryQueryHookResult = ReturnType<typeof useGetSimplifiedRevenueSummaryQuery>;
export type GetSimplifiedRevenueSummaryLazyQueryHookResult = ReturnType<typeof useGetSimplifiedRevenueSummaryLazyQuery>;
export type GetSimplifiedRevenueSummaryQueryResult = Apollo.QueryResult<GetSimplifiedRevenueSummaryQuery, GetSimplifiedRevenueSummaryQueryVariables>;
export const RemoveUnitTypeRenovationMapDocument = gql`
    mutation RemoveUnitTypeRenovationMap($propertyId: ID!, $budgetYear: Int!, $renovationPackageId: ID!) {
  simplifiedRevenueRemoveRenovationMapping(
    propertyId: $propertyId
    budgetYear: $budgetYear
    renovationPackageId: $renovationPackageId
  )
}
    `;
export type RemoveUnitTypeRenovationMapMutationFn = Apollo.MutationFunction<RemoveUnitTypeRenovationMapMutation, RemoveUnitTypeRenovationMapMutationVariables>;

/**
 * __useRemoveUnitTypeRenovationMapMutation__
 *
 * To run a mutation, you first call `useRemoveUnitTypeRenovationMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUnitTypeRenovationMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUnitTypeRenovationMapMutation, { data, loading, error }] = useRemoveUnitTypeRenovationMapMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      renovationPackageId: // value for 'renovationPackageId'
 *   },
 * });
 */
export function useRemoveUnitTypeRenovationMapMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUnitTypeRenovationMapMutation, RemoveUnitTypeRenovationMapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUnitTypeRenovationMapMutation, RemoveUnitTypeRenovationMapMutationVariables>(RemoveUnitTypeRenovationMapDocument, options);
      }
export type RemoveUnitTypeRenovationMapMutationHookResult = ReturnType<typeof useRemoveUnitTypeRenovationMapMutation>;
export type RemoveUnitTypeRenovationMapMutationResult = Apollo.MutationResult<RemoveUnitTypeRenovationMapMutation>;
export type RemoveUnitTypeRenovationMapMutationOptions = Apollo.BaseMutationOptions<RemoveUnitTypeRenovationMapMutation, RemoveUnitTypeRenovationMapMutationVariables>;
export const UpdateActiveRentRollDocument = gql`
    mutation UpdateActiveRentRoll($propertyId: ID!, $budgetYear: Int!, $rentRollId: ID!) {
  setActiveRentRoll(
    propertyId: $propertyId
    budgetYear: $budgetYear
    rentRollId: $rentRollId
  ) {
    id
    propertyId
    date
    isActive
  }
}
    `;
export type UpdateActiveRentRollMutationFn = Apollo.MutationFunction<UpdateActiveRentRollMutation, UpdateActiveRentRollMutationVariables>;

/**
 * __useUpdateActiveRentRollMutation__
 *
 * To run a mutation, you first call `useUpdateActiveRentRollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActiveRentRollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActiveRentRollMutation, { data, loading, error }] = useUpdateActiveRentRollMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      rentRollId: // value for 'rentRollId'
 *   },
 * });
 */
export function useUpdateActiveRentRollMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActiveRentRollMutation, UpdateActiveRentRollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActiveRentRollMutation, UpdateActiveRentRollMutationVariables>(UpdateActiveRentRollDocument, options);
      }
export type UpdateActiveRentRollMutationHookResult = ReturnType<typeof useUpdateActiveRentRollMutation>;
export type UpdateActiveRentRollMutationResult = Apollo.MutationResult<UpdateActiveRentRollMutation>;
export type UpdateActiveRentRollMutationOptions = Apollo.BaseMutationOptions<UpdateActiveRentRollMutation, UpdateActiveRentRollMutationVariables>;
export const UpdateFakeUnitStartingMarketRentDocument = gql`
    mutation UpdateFakeUnitStartingMarketRent($propertyId: ID!, $budgetYear: Int!, $marketRents: [SimplifiedRevenueFakeUnitTypeMarketRentInput!]!) {
  simplifiedRevenueUpdateFakeUnitTypeMarketRent(
    propertyId: $propertyId
    budgetYear: $budgetYear
    marketRents: $marketRents
  ) {
    name
    startingMarketRent
  }
}
    `;
export type UpdateFakeUnitStartingMarketRentMutationFn = Apollo.MutationFunction<UpdateFakeUnitStartingMarketRentMutation, UpdateFakeUnitStartingMarketRentMutationVariables>;

/**
 * __useUpdateFakeUnitStartingMarketRentMutation__
 *
 * To run a mutation, you first call `useUpdateFakeUnitStartingMarketRentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFakeUnitStartingMarketRentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFakeUnitStartingMarketRentMutation, { data, loading, error }] = useUpdateFakeUnitStartingMarketRentMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      marketRents: // value for 'marketRents'
 *   },
 * });
 */
export function useUpdateFakeUnitStartingMarketRentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFakeUnitStartingMarketRentMutation, UpdateFakeUnitStartingMarketRentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFakeUnitStartingMarketRentMutation, UpdateFakeUnitStartingMarketRentMutationVariables>(UpdateFakeUnitStartingMarketRentDocument, options);
      }
export type UpdateFakeUnitStartingMarketRentMutationHookResult = ReturnType<typeof useUpdateFakeUnitStartingMarketRentMutation>;
export type UpdateFakeUnitStartingMarketRentMutationResult = Apollo.MutationResult<UpdateFakeUnitStartingMarketRentMutation>;
export type UpdateFakeUnitStartingMarketRentMutationOptions = Apollo.BaseMutationOptions<UpdateFakeUnitStartingMarketRentMutation, UpdateFakeUnitStartingMarketRentMutationVariables>;
export const UpdateMarketRentGrowthDocument = gql`
    mutation UpdateMarketRentGrowth($propertyId: ID!, $budgetYear: Int!, $increases: SimplifiedRevenueMarketRentIncreaseInput!) {
  updateSimplifiedRevenueDefaultMarketRentIncrease(
    propertyId: $propertyId
    budgetYear: $budgetYear
    increases: $increases
  )
}
    `;
export type UpdateMarketRentGrowthMutationFn = Apollo.MutationFunction<UpdateMarketRentGrowthMutation, UpdateMarketRentGrowthMutationVariables>;

/**
 * __useUpdateMarketRentGrowthMutation__
 *
 * To run a mutation, you first call `useUpdateMarketRentGrowthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMarketRentGrowthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMarketRentGrowthMutation, { data, loading, error }] = useUpdateMarketRentGrowthMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      increases: // value for 'increases'
 *   },
 * });
 */
export function useUpdateMarketRentGrowthMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMarketRentGrowthMutation, UpdateMarketRentGrowthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMarketRentGrowthMutation, UpdateMarketRentGrowthMutationVariables>(UpdateMarketRentGrowthDocument, options);
      }
export type UpdateMarketRentGrowthMutationHookResult = ReturnType<typeof useUpdateMarketRentGrowthMutation>;
export type UpdateMarketRentGrowthMutationResult = Apollo.MutationResult<UpdateMarketRentGrowthMutation>;
export type UpdateMarketRentGrowthMutationOptions = Apollo.BaseMutationOptions<UpdateMarketRentGrowthMutation, UpdateMarketRentGrowthMutationVariables>;
export const UpdateRealUnitStartingMarketRentDocument = gql`
    mutation UpdateRealUnitStartingMarketRent($propertyId: ID!, $budgetYear: Int!, $overrides: [SimplifiedRevenueSetUnitTypeStartingMarketRentOverrideInput!]!) {
  simplifiedRevenueSetUnitTypeStartingMarketRent(
    propertyId: $propertyId
    budgetYear: $budgetYear
    overrides: $overrides
  )
}
    `;
export type UpdateRealUnitStartingMarketRentMutationFn = Apollo.MutationFunction<UpdateRealUnitStartingMarketRentMutation, UpdateRealUnitStartingMarketRentMutationVariables>;

/**
 * __useUpdateRealUnitStartingMarketRentMutation__
 *
 * To run a mutation, you first call `useUpdateRealUnitStartingMarketRentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRealUnitStartingMarketRentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRealUnitStartingMarketRentMutation, { data, loading, error }] = useUpdateRealUnitStartingMarketRentMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      overrides: // value for 'overrides'
 *   },
 * });
 */
export function useUpdateRealUnitStartingMarketRentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRealUnitStartingMarketRentMutation, UpdateRealUnitStartingMarketRentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRealUnitStartingMarketRentMutation, UpdateRealUnitStartingMarketRentMutationVariables>(UpdateRealUnitStartingMarketRentDocument, options);
      }
export type UpdateRealUnitStartingMarketRentMutationHookResult = ReturnType<typeof useUpdateRealUnitStartingMarketRentMutation>;
export type UpdateRealUnitStartingMarketRentMutationResult = Apollo.MutationResult<UpdateRealUnitStartingMarketRentMutation>;
export type UpdateRealUnitStartingMarketRentMutationOptions = Apollo.BaseMutationOptions<UpdateRealUnitStartingMarketRentMutation, UpdateRealUnitStartingMarketRentMutationVariables>;
export const UpdateRenewalConfigurationDocument = gql`
    mutation UpdateRenewalConfiguration($propertyId: ID!, $budgetYear: Int!, $configuration: SimplifiedRevenueRenewalTradeOutConfigurationInput!) {
  updateSimplifiedRevenueRenewalTradeOutConfiguration(
    propertyId: $propertyId
    budgetYear: $budgetYear
    configuration: $configuration
  ) {
    reforecast {
      maxIncreaseAmount
      maxIncreasePercentage
      maxPercentOfMarketRent
      lossToLeaseCaptureRate
      increaseInPlaceGreaterThanLossToLease
    }
  }
}
    `;
export type UpdateRenewalConfigurationMutationFn = Apollo.MutationFunction<UpdateRenewalConfigurationMutation, UpdateRenewalConfigurationMutationVariables>;

/**
 * __useUpdateRenewalConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateRenewalConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRenewalConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRenewalConfigurationMutation, { data, loading, error }] = useUpdateRenewalConfigurationMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      configuration: // value for 'configuration'
 *   },
 * });
 */
export function useUpdateRenewalConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRenewalConfigurationMutation, UpdateRenewalConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRenewalConfigurationMutation, UpdateRenewalConfigurationMutationVariables>(UpdateRenewalConfigurationDocument, options);
      }
export type UpdateRenewalConfigurationMutationHookResult = ReturnType<typeof useUpdateRenewalConfigurationMutation>;
export type UpdateRenewalConfigurationMutationResult = Apollo.MutationResult<UpdateRenewalConfigurationMutation>;
export type UpdateRenewalConfigurationMutationOptions = Apollo.BaseMutationOptions<UpdateRenewalConfigurationMutation, UpdateRenewalConfigurationMutationVariables>;
export const UpdateRenewalRatiosDocument = gql`
    mutation UpdateRenewalRatios($propertyId: ID!, $budgetYear: Int!, $renewalRatios: SimplifiedRevenueRenewalRatioInput!, $renewalRenoRatios: SimplifiedRevenueRenewalRatioInput!) {
  updateSimplifiedRevenueDefaultRenewalRatios(
    propertyId: $propertyId
    budgetYear: $budgetYear
    renewalRatios: $renewalRatios
  ) {
    reforecast
    budget
  }
  updateSimplifiedRevenueDefaultRenovatedRenewalRatios(
    propertyId: $propertyId
    budgetYear: $budgetYear
    renewalRatios: $renewalRenoRatios
  ) {
    reforecast
    budget
  }
}
    `;
export type UpdateRenewalRatiosMutationFn = Apollo.MutationFunction<UpdateRenewalRatiosMutation, UpdateRenewalRatiosMutationVariables>;

/**
 * __useUpdateRenewalRatiosMutation__
 *
 * To run a mutation, you first call `useUpdateRenewalRatiosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRenewalRatiosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRenewalRatiosMutation, { data, loading, error }] = useUpdateRenewalRatiosMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      renewalRatios: // value for 'renewalRatios'
 *      renewalRenoRatios: // value for 'renewalRenoRatios'
 *   },
 * });
 */
export function useUpdateRenewalRatiosMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRenewalRatiosMutation, UpdateRenewalRatiosMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRenewalRatiosMutation, UpdateRenewalRatiosMutationVariables>(UpdateRenewalRatiosDocument, options);
      }
export type UpdateRenewalRatiosMutationHookResult = ReturnType<typeof useUpdateRenewalRatiosMutation>;
export type UpdateRenewalRatiosMutationResult = Apollo.MutationResult<UpdateRenewalRatiosMutation>;
export type UpdateRenewalRatiosMutationOptions = Apollo.BaseMutationOptions<UpdateRenewalRatiosMutation, UpdateRenewalRatiosMutationVariables>;
export const UpdateDefaultRenovationRatioDocument = gql`
    mutation UpdateDefaultRenovationRatio($propertyId: ID!, $budgetYear: Int!, $renovationRatios: SimplifiedRevenueRenovationRatioInput!) {
  updateSimplifiedDefaultRevenueRenovationRatio(
    propertyId: $propertyId
    budgetYear: $budgetYear
    renovationRatios: $renovationRatios
  ) {
    reforecast
    budget
  }
}
    `;
export type UpdateDefaultRenovationRatioMutationFn = Apollo.MutationFunction<UpdateDefaultRenovationRatioMutation, UpdateDefaultRenovationRatioMutationVariables>;

/**
 * __useUpdateDefaultRenovationRatioMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultRenovationRatioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultRenovationRatioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultRenovationRatioMutation, { data, loading, error }] = useUpdateDefaultRenovationRatioMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      renovationRatios: // value for 'renovationRatios'
 *   },
 * });
 */
export function useUpdateDefaultRenovationRatioMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDefaultRenovationRatioMutation, UpdateDefaultRenovationRatioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDefaultRenovationRatioMutation, UpdateDefaultRenovationRatioMutationVariables>(UpdateDefaultRenovationRatioDocument, options);
      }
export type UpdateDefaultRenovationRatioMutationHookResult = ReturnType<typeof useUpdateDefaultRenovationRatioMutation>;
export type UpdateDefaultRenovationRatioMutationResult = Apollo.MutationResult<UpdateDefaultRenovationRatioMutation>;
export type UpdateDefaultRenovationRatioMutationOptions = Apollo.BaseMutationOptions<UpdateDefaultRenovationRatioMutation, UpdateDefaultRenovationRatioMutationVariables>;
export const UpdateRentRollDocument = gql`
    mutation UpdateRentRoll($propertyId: ID!, $budgetYear: Int!, $updates: [UpdateRentRollExpiringUnitConversionInput!]!) {
  updateSimplifiedRevenueExpiringUnits(
    propertyId: $propertyId
    budgetYear: $budgetYear
    updates: $updates
  )
}
    `;
export type UpdateRentRollMutationFn = Apollo.MutationFunction<UpdateRentRollMutation, UpdateRentRollMutationVariables>;

/**
 * __useUpdateRentRollMutation__
 *
 * To run a mutation, you first call `useUpdateRentRollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRentRollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRentRollMutation, { data, loading, error }] = useUpdateRentRollMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateRentRollMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRentRollMutation, UpdateRentRollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRentRollMutation, UpdateRentRollMutationVariables>(UpdateRentRollDocument, options);
      }
export type UpdateRentRollMutationHookResult = ReturnType<typeof useUpdateRentRollMutation>;
export type UpdateRentRollMutationResult = Apollo.MutationResult<UpdateRentRollMutation>;
export type UpdateRentRollMutationOptions = Apollo.BaseMutationOptions<UpdateRentRollMutation, UpdateRentRollMutationVariables>;
export const UpdateUnitTypeRenovationMapDocument = gql`
    mutation UpdateUnitTypeRenovationMap($propertyId: ID!, $budgetYear: Int!, $setData: SimplifiedRevenueSetRenovationMappingInput!) {
  simplifiedRevenueSetRenovationMapping(
    propertyId: $propertyId
    budgetYear: $budgetYear
    setData: $setData
  ) {
    unrenovatedUnitTypeId
    unrenovatedUnitTypeName
    renovatedUnitTypeId
    renovatedUnitTypeName
    renovatedUnitTypeStartingRent
  }
}
    `;
export type UpdateUnitTypeRenovationMapMutationFn = Apollo.MutationFunction<UpdateUnitTypeRenovationMapMutation, UpdateUnitTypeRenovationMapMutationVariables>;

/**
 * __useUpdateUnitTypeRenovationMapMutation__
 *
 * To run a mutation, you first call `useUpdateUnitTypeRenovationMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnitTypeRenovationMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnitTypeRenovationMapMutation, { data, loading, error }] = useUpdateUnitTypeRenovationMapMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      setData: // value for 'setData'
 *   },
 * });
 */
export function useUpdateUnitTypeRenovationMapMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUnitTypeRenovationMapMutation, UpdateUnitTypeRenovationMapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUnitTypeRenovationMapMutation, UpdateUnitTypeRenovationMapMutationVariables>(UpdateUnitTypeRenovationMapDocument, options);
      }
export type UpdateUnitTypeRenovationMapMutationHookResult = ReturnType<typeof useUpdateUnitTypeRenovationMapMutation>;
export type UpdateUnitTypeRenovationMapMutationResult = Apollo.MutationResult<UpdateUnitTypeRenovationMapMutation>;
export type UpdateUnitTypeRenovationMapMutationOptions = Apollo.BaseMutationOptions<UpdateUnitTypeRenovationMapMutation, UpdateUnitTypeRenovationMapMutationVariables>;
export const UpdateVacancyRatesDocument = gql`
    mutation UpdateVacancyRates($propertyId: ID!, $budgetYear: Int!, $vacancyPercentages: SimplifiedRevenueVacancyPercentageInput!) {
  updateSimplifiedRevenueDefaultVacancyPercentage(
    propertyId: $propertyId
    budgetYear: $budgetYear
    vacancyPercentages: $vacancyPercentages
  ) {
    reforecast
    budget
  }
}
    `;
export type UpdateVacancyRatesMutationFn = Apollo.MutationFunction<UpdateVacancyRatesMutation, UpdateVacancyRatesMutationVariables>;

/**
 * __useUpdateVacancyRatesMutation__
 *
 * To run a mutation, you first call `useUpdateVacancyRatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVacancyRatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVacancyRatesMutation, { data, loading, error }] = useUpdateVacancyRatesMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      vacancyPercentages: // value for 'vacancyPercentages'
 *   },
 * });
 */
export function useUpdateVacancyRatesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVacancyRatesMutation, UpdateVacancyRatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVacancyRatesMutation, UpdateVacancyRatesMutationVariables>(UpdateVacancyRatesDocument, options);
      }
export type UpdateVacancyRatesMutationHookResult = ReturnType<typeof useUpdateVacancyRatesMutation>;
export type UpdateVacancyRatesMutationResult = Apollo.MutationResult<UpdateVacancyRatesMutation>;
export type UpdateVacancyRatesMutationOptions = Apollo.BaseMutationOptions<UpdateVacancyRatesMutation, UpdateVacancyRatesMutationVariables>;
export const GetTableViewForEmptyAccountsByPropertyIdAndVersionIdsDocument = gql`
    query getTableViewForEmptyAccountsByPropertyIdAndVersionIds($propertyId: ID!, $starringMetaArgInput: [StarringMetaArgInput!]!, $financialTotalValueArgInput: [FinancialTotalValueArgInput!]!) {
  getTableViewForEmptyAccountsByPropertyIdAndVersionIds(
    propertyId: $propertyId
    starringMetaArgInput: $starringMetaArgInput
    financialTotalValueArgInput: $financialTotalValueArgInput
  ) {
    propertyId
    accountId
    parentAccountId
    accountGlNumber
    accountGlName
    reforecastVersionId
    budgetVersionId
    isReforecastVersionStarred
    isBudgetVersionStarred
    reforecastValue
    budgetValue
    reforecastVersionNote
    budgetVersionNote
  }
}
    `;

/**
 * __useGetTableViewForEmptyAccountsByPropertyIdAndVersionIdsQuery__
 *
 * To run a query within a React component, call `useGetTableViewForEmptyAccountsByPropertyIdAndVersionIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTableViewForEmptyAccountsByPropertyIdAndVersionIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTableViewForEmptyAccountsByPropertyIdAndVersionIdsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      starringMetaArgInput: // value for 'starringMetaArgInput'
 *      financialTotalValueArgInput: // value for 'financialTotalValueArgInput'
 *   },
 * });
 */
export function useGetTableViewForEmptyAccountsByPropertyIdAndVersionIdsQuery(baseOptions: Apollo.QueryHookOptions<GetTableViewForEmptyAccountsByPropertyIdAndVersionIdsQuery, GetTableViewForEmptyAccountsByPropertyIdAndVersionIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTableViewForEmptyAccountsByPropertyIdAndVersionIdsQuery, GetTableViewForEmptyAccountsByPropertyIdAndVersionIdsQueryVariables>(GetTableViewForEmptyAccountsByPropertyIdAndVersionIdsDocument, options);
      }
export function useGetTableViewForEmptyAccountsByPropertyIdAndVersionIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTableViewForEmptyAccountsByPropertyIdAndVersionIdsQuery, GetTableViewForEmptyAccountsByPropertyIdAndVersionIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTableViewForEmptyAccountsByPropertyIdAndVersionIdsQuery, GetTableViewForEmptyAccountsByPropertyIdAndVersionIdsQueryVariables>(GetTableViewForEmptyAccountsByPropertyIdAndVersionIdsDocument, options);
        }
export type GetTableViewForEmptyAccountsByPropertyIdAndVersionIdsQueryHookResult = ReturnType<typeof useGetTableViewForEmptyAccountsByPropertyIdAndVersionIdsQuery>;
export type GetTableViewForEmptyAccountsByPropertyIdAndVersionIdsLazyQueryHookResult = ReturnType<typeof useGetTableViewForEmptyAccountsByPropertyIdAndVersionIdsLazyQuery>;
export type GetTableViewForEmptyAccountsByPropertyIdAndVersionIdsQueryResult = Apollo.QueryResult<GetTableViewForEmptyAccountsByPropertyIdAndVersionIdsQuery, GetTableViewForEmptyAccountsByPropertyIdAndVersionIdsQueryVariables>;
export const GetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsDocument = gql`
    query getTableViewForOverriddenAccountsByPropertyIdAndVersionIds($propertyId: ID!, $reforecastStartMonthIndex: Int!, $starringMetaArgInput: [StarringMetaArgInput!]!, $financialTotalValueArgInput: [FinancialTotalValueArgInput!]!) {
  getTableViewForOverriddenAccountsByPropertyIdAndVersionIds(
    propertyId: $propertyId
    starringMetaArgInput: $starringMetaArgInput
    financialTotalValueArgInput: $financialTotalValueArgInput
    reforecastStartMonthIndex: $reforecastStartMonthIndex
  ) {
    propertyId
    accountId
    parentAccountId
    accountGlNumber
    accountGlName
    reforecastVersionId
    budgetVersionId
    isReforecastVersionStarred
    isBudgetVersionStarred
    reforecastValue
    budgetValue
    reforecastVersionNote
    budgetVersionNote
  }
}
    `;

/**
 * __useGetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsQuery__
 *
 * To run a query within a React component, call `useGetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      reforecastStartMonthIndex: // value for 'reforecastStartMonthIndex'
 *      starringMetaArgInput: // value for 'starringMetaArgInput'
 *      financialTotalValueArgInput: // value for 'financialTotalValueArgInput'
 *   },
 * });
 */
export function useGetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsQuery(baseOptions: Apollo.QueryHookOptions<GetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsQuery, GetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsQuery, GetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsQueryVariables>(GetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsDocument, options);
      }
export function useGetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsQuery, GetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsQuery, GetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsQueryVariables>(GetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsDocument, options);
        }
export type GetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsQueryHookResult = ReturnType<typeof useGetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsQuery>;
export type GetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsLazyQueryHookResult = ReturnType<typeof useGetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsLazyQuery>;
export type GetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsQueryResult = Apollo.QueryResult<GetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsQuery, GetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsQueryVariables>;
export const GetTableViewForStarredAccountsByPropertyIdAndVersionIdsDocument = gql`
    query getTableViewForStarredAccountsByPropertyIdAndVersionIds($propertyId: ID!, $versionIds: [ID!]!, $financialTotalValueArgInput: [FinancialTotalValueArgInput!]!) {
  getTableViewForStarredAccountsByPropertyIdAndVersionIds(
    propertyId: $propertyId
    versionIds: $versionIds
    financialTotalValueArgInput: $financialTotalValueArgInput
  ) {
    propertyId
    accountId
    parentAccountId
    accountGlNumber
    accountGlName
    reforecastVersionId
    budgetVersionId
    isReforecastVersionStarred
    isBudgetVersionStarred
    reforecastValue
    budgetValue
    reforecastVersionNote
    budgetVersionNote
  }
}
    `;

/**
 * __useGetTableViewForStarredAccountsByPropertyIdAndVersionIdsQuery__
 *
 * To run a query within a React component, call `useGetTableViewForStarredAccountsByPropertyIdAndVersionIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTableViewForStarredAccountsByPropertyIdAndVersionIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTableViewForStarredAccountsByPropertyIdAndVersionIdsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      versionIds: // value for 'versionIds'
 *      financialTotalValueArgInput: // value for 'financialTotalValueArgInput'
 *   },
 * });
 */
export function useGetTableViewForStarredAccountsByPropertyIdAndVersionIdsQuery(baseOptions: Apollo.QueryHookOptions<GetTableViewForStarredAccountsByPropertyIdAndVersionIdsQuery, GetTableViewForStarredAccountsByPropertyIdAndVersionIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTableViewForStarredAccountsByPropertyIdAndVersionIdsQuery, GetTableViewForStarredAccountsByPropertyIdAndVersionIdsQueryVariables>(GetTableViewForStarredAccountsByPropertyIdAndVersionIdsDocument, options);
      }
export function useGetTableViewForStarredAccountsByPropertyIdAndVersionIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTableViewForStarredAccountsByPropertyIdAndVersionIdsQuery, GetTableViewForStarredAccountsByPropertyIdAndVersionIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTableViewForStarredAccountsByPropertyIdAndVersionIdsQuery, GetTableViewForStarredAccountsByPropertyIdAndVersionIdsQueryVariables>(GetTableViewForStarredAccountsByPropertyIdAndVersionIdsDocument, options);
        }
export type GetTableViewForStarredAccountsByPropertyIdAndVersionIdsQueryHookResult = ReturnType<typeof useGetTableViewForStarredAccountsByPropertyIdAndVersionIdsQuery>;
export type GetTableViewForStarredAccountsByPropertyIdAndVersionIdsLazyQueryHookResult = ReturnType<typeof useGetTableViewForStarredAccountsByPropertyIdAndVersionIdsLazyQuery>;
export type GetTableViewForStarredAccountsByPropertyIdAndVersionIdsQueryResult = Apollo.QueryResult<GetTableViewForStarredAccountsByPropertyIdAndVersionIdsQuery, GetTableViewForStarredAccountsByPropertyIdAndVersionIdsQueryVariables>;
export const DeleteUnitEventsDocument = gql`
    mutation deleteUnitEvents($id: ID!) {
  deleteUnitEvents(ids: [$id])
}
    `;
export type DeleteUnitEventsMutationFn = Apollo.MutationFunction<DeleteUnitEventsMutation, DeleteUnitEventsMutationVariables>;

/**
 * __useDeleteUnitEventsMutation__
 *
 * To run a mutation, you first call `useDeleteUnitEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUnitEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUnitEventsMutation, { data, loading, error }] = useDeleteUnitEventsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUnitEventsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUnitEventsMutation, DeleteUnitEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUnitEventsMutation, DeleteUnitEventsMutationVariables>(DeleteUnitEventsDocument, options);
      }
export type DeleteUnitEventsMutationHookResult = ReturnType<typeof useDeleteUnitEventsMutation>;
export type DeleteUnitEventsMutationResult = Apollo.MutationResult<DeleteUnitEventsMutation>;
export type DeleteUnitEventsMutationOptions = Apollo.BaseMutationOptions<DeleteUnitEventsMutation, DeleteUnitEventsMutationVariables>;
export const ListUnitEventsDocument = gql`
    query listUnitEvents($propertyId: String!, $budgetYear: Int!, $page: Int!, $take: Int!) {
  listUnits(propertyId: $propertyId, page: $page, take: $take) {
    page
    take
    maxPage
    total
    items {
      id
      number
      unitTypeId
      events: eventsByBudgetYear(budgetYear: $budgetYear) {
        id
        propertyId
        unitId
        eventType
        eventStart
        eventEnd
        importedEvent
        tenantName
        inPlaceRent
        startingCalculatedInPlaceRent
        endingCalculatedInPlaceRent
        startingMarketRent
        endingMarketRent
        startingLossToLease
        endingLossToLease
        concessionMonths
        renewalAdjustmentPercentage
        renovationCost
        renovationPremium
        shortTermEffectiveAvgDailyRent
      }
    }
  }
}
    `;

/**
 * __useListUnitEventsQuery__
 *
 * To run a query within a React component, call `useListUnitEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUnitEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUnitEventsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useListUnitEventsQuery(baseOptions: Apollo.QueryHookOptions<ListUnitEventsQuery, ListUnitEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUnitEventsQuery, ListUnitEventsQueryVariables>(ListUnitEventsDocument, options);
      }
export function useListUnitEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUnitEventsQuery, ListUnitEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUnitEventsQuery, ListUnitEventsQueryVariables>(ListUnitEventsDocument, options);
        }
export type ListUnitEventsQueryHookResult = ReturnType<typeof useListUnitEventsQuery>;
export type ListUnitEventsLazyQueryHookResult = ReturnType<typeof useListUnitEventsLazyQuery>;
export type ListUnitEventsQueryResult = Apollo.QueryResult<ListUnitEventsQuery, ListUnitEventsQueryVariables>;
export const UpdateTermLeaseDocument = gql`
    mutation updateTermLease($id: ID!, $eventType: UnitEventType!, $eventStart: DateTime!, $eventEnd: DateTime!, $concessionMonths: Decimal) {
  updateUnitEvent(
    id: $id
    eventType: $eventType
    eventStart: $eventStart
    eventEnd: $eventEnd
    concessionMonths: $concessionMonths
  ) {
    id
    eventType
    eventStart
    eventEnd
    inPlaceRent
    concessionMonths
    renewalAdjustmentPercentage
    renovationCost
    renovationPremium
    shortTermEffectiveAvgDailyRent
  }
}
    `;
export type UpdateTermLeaseMutationFn = Apollo.MutationFunction<UpdateTermLeaseMutation, UpdateTermLeaseMutationVariables>;

/**
 * __useUpdateTermLeaseMutation__
 *
 * To run a mutation, you first call `useUpdateTermLeaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTermLeaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTermLeaseMutation, { data, loading, error }] = useUpdateTermLeaseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      eventType: // value for 'eventType'
 *      eventStart: // value for 'eventStart'
 *      eventEnd: // value for 'eventEnd'
 *      concessionMonths: // value for 'concessionMonths'
 *   },
 * });
 */
export function useUpdateTermLeaseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTermLeaseMutation, UpdateTermLeaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTermLeaseMutation, UpdateTermLeaseMutationVariables>(UpdateTermLeaseDocument, options);
      }
export type UpdateTermLeaseMutationHookResult = ReturnType<typeof useUpdateTermLeaseMutation>;
export type UpdateTermLeaseMutationResult = Apollo.MutationResult<UpdateTermLeaseMutation>;
export type UpdateTermLeaseMutationOptions = Apollo.BaseMutationOptions<UpdateTermLeaseMutation, UpdateTermLeaseMutationVariables>;
export const CreateUnitEventDocument = gql`
    mutation createUnitEvent($propertyId: String!, $budgetYear: Int!, $unitId: ID!, $eventType: UnitEventType!, $eventStart: DateTime!, $eventEnd: DateTime!, $concessionMonths: Decimal, $inPlaceRent: Decimal, $renewalAdjustmentPercentage: Decimal, $shortTermEffectiveAvgDailyRent: Decimal, $renovationCost: Decimal, $renovationPremium: Decimal) {
  createUnitEvent: createUnitEventWithBudgetYear(
    propertyId: $propertyId
    budgetYear: $budgetYear
    unitId: $unitId
    eventType: $eventType
    eventStart: $eventStart
    eventEnd: $eventEnd
    concessionMonths: $concessionMonths
    inPlaceRent: $inPlaceRent
    renewalAdjustmentPercentage: $renewalAdjustmentPercentage
    shortTermEffectiveAvgDailyRent: $shortTermEffectiveAvgDailyRent
    renovationCost: $renovationCost
    renovationPremium: $renovationPremium
  ) {
    id
    eventType
    eventStart
    eventEnd
    concessionMonths
    inPlaceRent
    renewalAdjustmentPercentage
    shortTermEffectiveAvgDailyRent
    renovationCost
    renovationPremium
  }
}
    `;
export type CreateUnitEventMutationFn = Apollo.MutationFunction<CreateUnitEventMutation, CreateUnitEventMutationVariables>;

/**
 * __useCreateUnitEventMutation__
 *
 * To run a mutation, you first call `useCreateUnitEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUnitEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnitEventMutation, { data, loading, error }] = useCreateUnitEventMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      unitId: // value for 'unitId'
 *      eventType: // value for 'eventType'
 *      eventStart: // value for 'eventStart'
 *      eventEnd: // value for 'eventEnd'
 *      concessionMonths: // value for 'concessionMonths'
 *      inPlaceRent: // value for 'inPlaceRent'
 *      renewalAdjustmentPercentage: // value for 'renewalAdjustmentPercentage'
 *      shortTermEffectiveAvgDailyRent: // value for 'shortTermEffectiveAvgDailyRent'
 *      renovationCost: // value for 'renovationCost'
 *      renovationPremium: // value for 'renovationPremium'
 *   },
 * });
 */
export function useCreateUnitEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateUnitEventMutation, CreateUnitEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUnitEventMutation, CreateUnitEventMutationVariables>(CreateUnitEventDocument, options);
      }
export type CreateUnitEventMutationHookResult = ReturnType<typeof useCreateUnitEventMutation>;
export type CreateUnitEventMutationResult = Apollo.MutationResult<CreateUnitEventMutation>;
export type CreateUnitEventMutationOptions = Apollo.BaseMutationOptions<CreateUnitEventMutation, CreateUnitEventMutationVariables>;
export const EnrichPossibleUnitEventDocument = gql`
    query enrichPossibleUnitEvent($propertyId: String!, $budgetYear: Int!, $unitId: ID!, $eventType: UnitEventType!, $eventStart: DateTime!, $eventEnd: DateTime!, $concessionMonths: Decimal, $inPlaceRent: Decimal, $renewalAdjustmentPercentage: Decimal, $shortTermEffectiveAvgDailyRent: Decimal, $renovationCost: Decimal, $renovationPremium: Decimal) {
  enrichPossibleUnitEvent: enrichPossibleUnitEventContainingBudgetYear(
    propertyId: $propertyId
    budgetYear: $budgetYear
    unitId: $unitId
    eventType: $eventType
    eventStart: $eventStart
    eventEnd: $eventEnd
    concessionMonths: $concessionMonths
    inPlaceRent: $inPlaceRent
    renewalAdjustmentPercentage: $renewalAdjustmentPercentage
    shortTermEffectiveAvgDailyRent: $shortTermEffectiveAvgDailyRent
    renovationCost: $renovationCost
    renovationPremium: $renovationPremium
  ) {
    propertyId
    unitId
    eventType
    eventStart
    eventEnd
    inPlaceRent
    startingCalculatedInPlaceRent
    endingCalculatedInPlaceRent
    startingMarketRent
    endingMarketRent
    startingLossToLease
    endingLossToLease
  }
}
    `;

/**
 * __useEnrichPossibleUnitEventQuery__
 *
 * To run a query within a React component, call `useEnrichPossibleUnitEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrichPossibleUnitEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrichPossibleUnitEventQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      unitId: // value for 'unitId'
 *      eventType: // value for 'eventType'
 *      eventStart: // value for 'eventStart'
 *      eventEnd: // value for 'eventEnd'
 *      concessionMonths: // value for 'concessionMonths'
 *      inPlaceRent: // value for 'inPlaceRent'
 *      renewalAdjustmentPercentage: // value for 'renewalAdjustmentPercentage'
 *      shortTermEffectiveAvgDailyRent: // value for 'shortTermEffectiveAvgDailyRent'
 *      renovationCost: // value for 'renovationCost'
 *      renovationPremium: // value for 'renovationPremium'
 *   },
 * });
 */
export function useEnrichPossibleUnitEventQuery(baseOptions: Apollo.QueryHookOptions<EnrichPossibleUnitEventQuery, EnrichPossibleUnitEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnrichPossibleUnitEventQuery, EnrichPossibleUnitEventQueryVariables>(EnrichPossibleUnitEventDocument, options);
      }
export function useEnrichPossibleUnitEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnrichPossibleUnitEventQuery, EnrichPossibleUnitEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnrichPossibleUnitEventQuery, EnrichPossibleUnitEventQueryVariables>(EnrichPossibleUnitEventDocument, options);
        }
export type EnrichPossibleUnitEventQueryHookResult = ReturnType<typeof useEnrichPossibleUnitEventQuery>;
export type EnrichPossibleUnitEventLazyQueryHookResult = ReturnType<typeof useEnrichPossibleUnitEventLazyQuery>;
export type EnrichPossibleUnitEventQueryResult = Apollo.QueryResult<EnrichPossibleUnitEventQuery, EnrichPossibleUnitEventQueryVariables>;
export const UpdateUnitEventDocument = gql`
    mutation updateUnitEvent($id: ID!, $eventType: UnitEventType!, $eventStart: DateTime!, $eventEnd: DateTime!, $concessionMonths: Decimal, $inPlaceRent: Decimal, $renewalAdjustmentPercentage: Decimal, $shortTermEffectiveAvgDailyRent: Decimal, $renovationCost: Decimal, $renovationPremium: Decimal) {
  updateUnitEvent(
    id: $id
    eventType: $eventType
    eventStart: $eventStart
    eventEnd: $eventEnd
    concessionMonths: $concessionMonths
    inPlaceRent: $inPlaceRent
    renewalAdjustmentPercentage: $renewalAdjustmentPercentage
    shortTermEffectiveAvgDailyRent: $shortTermEffectiveAvgDailyRent
    renovationCost: $renovationCost
    renovationPremium: $renovationPremium
  ) {
    id
    eventType
    eventStart
    eventEnd
    concessionMonths
    inPlaceRent
    renewalAdjustmentPercentage
    shortTermEffectiveAvgDailyRent
    renovationCost
    renovationPremium
  }
}
    `;
export type UpdateUnitEventMutationFn = Apollo.MutationFunction<UpdateUnitEventMutation, UpdateUnitEventMutationVariables>;

/**
 * __useUpdateUnitEventMutation__
 *
 * To run a mutation, you first call `useUpdateUnitEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnitEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnitEventMutation, { data, loading, error }] = useUpdateUnitEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      eventType: // value for 'eventType'
 *      eventStart: // value for 'eventStart'
 *      eventEnd: // value for 'eventEnd'
 *      concessionMonths: // value for 'concessionMonths'
 *      inPlaceRent: // value for 'inPlaceRent'
 *      renewalAdjustmentPercentage: // value for 'renewalAdjustmentPercentage'
 *      shortTermEffectiveAvgDailyRent: // value for 'shortTermEffectiveAvgDailyRent'
 *      renovationCost: // value for 'renovationCost'
 *      renovationPremium: // value for 'renovationPremium'
 *   },
 * });
 */
export function useUpdateUnitEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUnitEventMutation, UpdateUnitEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUnitEventMutation, UpdateUnitEventMutationVariables>(UpdateUnitEventDocument, options);
      }
export type UpdateUnitEventMutationHookResult = ReturnType<typeof useUpdateUnitEventMutation>;
export type UpdateUnitEventMutationResult = Apollo.MutationResult<UpdateUnitEventMutation>;
export type UpdateUnitEventMutationOptions = Apollo.BaseMutationOptions<UpdateUnitEventMutation, UpdateUnitEventMutationVariables>;
export const ListUnitsAndMarketRentsDocument = gql`
    query ListUnitsAndMarketRents($propertyId: String!, $page: Int!, $take: Int!, $reforecastYear: Int!, $budgetYear: Int!) {
  listUnits(propertyId: $propertyId, page: $page, take: $take) {
    page
    take
    maxPage
    total
    items {
      id
      number
      unitTypeId
      reforecastMarketRents: marketRents(year: $reforecastYear) {
        unitId
        year
        rawMarketRentsActuals
        rawMarketRentsReforecast
        rawMarketRentsBudget
        adjustedMarketRentsActuals
        adjustedMarketRentsReforecast
        adjustedMarketRentsBudget
        proratedRenovationPremiums
      }
      budgetMarketRents: marketRents(year: $budgetYear) {
        unitId
        year
        rawMarketRentsActuals
        rawMarketRentsReforecast
        rawMarketRentsBudget
        adjustedMarketRentsActuals
        adjustedMarketRentsReforecast
        adjustedMarketRentsBudget
        proratedRenovationPremiums
      }
    }
  }
}
    `;

/**
 * __useListUnitsAndMarketRentsQuery__
 *
 * To run a query within a React component, call `useListUnitsAndMarketRentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUnitsAndMarketRentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUnitsAndMarketRentsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      reforecastYear: // value for 'reforecastYear'
 *      budgetYear: // value for 'budgetYear'
 *   },
 * });
 */
export function useListUnitsAndMarketRentsQuery(baseOptions: Apollo.QueryHookOptions<ListUnitsAndMarketRentsQuery, ListUnitsAndMarketRentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUnitsAndMarketRentsQuery, ListUnitsAndMarketRentsQueryVariables>(ListUnitsAndMarketRentsDocument, options);
      }
export function useListUnitsAndMarketRentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUnitsAndMarketRentsQuery, ListUnitsAndMarketRentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUnitsAndMarketRentsQuery, ListUnitsAndMarketRentsQueryVariables>(ListUnitsAndMarketRentsDocument, options);
        }
export type ListUnitsAndMarketRentsQueryHookResult = ReturnType<typeof useListUnitsAndMarketRentsQuery>;
export type ListUnitsAndMarketRentsLazyQueryHookResult = ReturnType<typeof useListUnitsAndMarketRentsLazyQuery>;
export type ListUnitsAndMarketRentsQueryResult = Apollo.QueryResult<ListUnitsAndMarketRentsQuery, ListUnitsAndMarketRentsQueryVariables>;
export const SetMarketRentDocument = gql`
    mutation SetMarketRent($unitId: ID!, $year: Int!, $month: Int!, $type: VersionType!, $marketRent: Decimal!) {
  setMarketRent(
    unitId: $unitId
    year: $year
    month: $month
    type: $type
    marketRent: $marketRent
  ) {
    unitId
    year
    rawMarketRentsActuals
    rawMarketRentsReforecast
    rawMarketRentsBudget
    adjustedMarketRentsActuals
    adjustedMarketRentsReforecast
    adjustedMarketRentsBudget
    proratedRenovationPremiums
  }
}
    `;
export type SetMarketRentMutationFn = Apollo.MutationFunction<SetMarketRentMutation, SetMarketRentMutationVariables>;

/**
 * __useSetMarketRentMutation__
 *
 * To run a mutation, you first call `useSetMarketRentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMarketRentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMarketRentMutation, { data, loading, error }] = useSetMarketRentMutation({
 *   variables: {
 *      unitId: // value for 'unitId'
 *      year: // value for 'year'
 *      month: // value for 'month'
 *      type: // value for 'type'
 *      marketRent: // value for 'marketRent'
 *   },
 * });
 */
export function useSetMarketRentMutation(baseOptions?: Apollo.MutationHookOptions<SetMarketRentMutation, SetMarketRentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMarketRentMutation, SetMarketRentMutationVariables>(SetMarketRentDocument, options);
      }
export type SetMarketRentMutationHookResult = ReturnType<typeof useSetMarketRentMutation>;
export type SetMarketRentMutationResult = Apollo.MutationResult<SetMarketRentMutation>;
export type SetMarketRentMutationOptions = Apollo.BaseMutationOptions<SetMarketRentMutation, SetMarketRentMutationVariables>;
export const GetUnitLevelPropertyMetricsDocument = gql`
    query getUnitLevelPropertyMetrics($propertyId: ID!, $chartOfAccountsId: ID!) {
  unitLevelPropertyMetrics(propertyId: $propertyId) {
    propertyId
    unitCount
    valuesType
    averageMarketRent
    expirations
    monthToMonthMoveOuts
    moveOutPercentage
    moveOuts
    totalMoveOuts
    occupancy
    occupiedUnits
    renewalIncrease
    renewalRatio
    renewals
  }
  unitLevelPropertyFinancialMetrics(
    propertyId: $propertyId
    chartOfAccountsId: $chartOfAccountsId
  ) {
    accountName
    order
    budgetValues
    reforecastValues
  }
}
    `;

/**
 * __useGetUnitLevelPropertyMetricsQuery__
 *
 * To run a query within a React component, call `useGetUnitLevelPropertyMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitLevelPropertyMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitLevelPropertyMetricsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      chartOfAccountsId: // value for 'chartOfAccountsId'
 *   },
 * });
 */
export function useGetUnitLevelPropertyMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetUnitLevelPropertyMetricsQuery, GetUnitLevelPropertyMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitLevelPropertyMetricsQuery, GetUnitLevelPropertyMetricsQueryVariables>(GetUnitLevelPropertyMetricsDocument, options);
      }
export function useGetUnitLevelPropertyMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitLevelPropertyMetricsQuery, GetUnitLevelPropertyMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitLevelPropertyMetricsQuery, GetUnitLevelPropertyMetricsQueryVariables>(GetUnitLevelPropertyMetricsDocument, options);
        }
export type GetUnitLevelPropertyMetricsQueryHookResult = ReturnType<typeof useGetUnitLevelPropertyMetricsQuery>;
export type GetUnitLevelPropertyMetricsLazyQueryHookResult = ReturnType<typeof useGetUnitLevelPropertyMetricsLazyQuery>;
export type GetUnitLevelPropertyMetricsQueryResult = Apollo.QueryResult<GetUnitLevelPropertyMetricsQuery, GetUnitLevelPropertyMetricsQueryVariables>;
export const AddUnitTypeDocument = gql`
    mutation AddUnitType($propertyId: ID!, $name: String!) {
  addUnitType(propertyId: $propertyId, name: $name) {
    id
    name
    propertyId
    createdAt
    budgetYear
  }
}
    `;
export type AddUnitTypeMutationFn = Apollo.MutationFunction<AddUnitTypeMutation, AddUnitTypeMutationVariables>;

/**
 * __useAddUnitTypeMutation__
 *
 * To run a mutation, you first call `useAddUnitTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUnitTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUnitTypeMutation, { data, loading, error }] = useAddUnitTypeMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddUnitTypeMutation(baseOptions?: Apollo.MutationHookOptions<AddUnitTypeMutation, AddUnitTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUnitTypeMutation, AddUnitTypeMutationVariables>(AddUnitTypeDocument, options);
      }
export type AddUnitTypeMutationHookResult = ReturnType<typeof useAddUnitTypeMutation>;
export type AddUnitTypeMutationResult = Apollo.MutationResult<AddUnitTypeMutation>;
export type AddUnitTypeMutationOptions = Apollo.BaseMutationOptions<AddUnitTypeMutation, AddUnitTypeMutationVariables>;
export const DeleteUnitTypeDocument = gql`
    mutation DeleteUnitType($unitTypeId: ID!) {
  deleteUnitType(unitTypeId: $unitTypeId) {
    id
    name
    propertyId
    createdAt
    budgetYear
  }
}
    `;
export type DeleteUnitTypeMutationFn = Apollo.MutationFunction<DeleteUnitTypeMutation, DeleteUnitTypeMutationVariables>;

/**
 * __useDeleteUnitTypeMutation__
 *
 * To run a mutation, you first call `useDeleteUnitTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUnitTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUnitTypeMutation, { data, loading, error }] = useDeleteUnitTypeMutation({
 *   variables: {
 *      unitTypeId: // value for 'unitTypeId'
 *   },
 * });
 */
export function useDeleteUnitTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUnitTypeMutation, DeleteUnitTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUnitTypeMutation, DeleteUnitTypeMutationVariables>(DeleteUnitTypeDocument, options);
      }
export type DeleteUnitTypeMutationHookResult = ReturnType<typeof useDeleteUnitTypeMutation>;
export type DeleteUnitTypeMutationResult = Apollo.MutationResult<DeleteUnitTypeMutation>;
export type DeleteUnitTypeMutationOptions = Apollo.BaseMutationOptions<DeleteUnitTypeMutation, DeleteUnitTypeMutationVariables>;
export const GetUnitTypeDependenciesDocument = gql`
    query GetUnitTypeDependencies($unitTypeId: ID!, $budgetYear: Int!) {
  renovationPackagesByUnitType(unitTypeId: $unitTypeId, budgetYear: $budgetYear) {
    name
  }
}
    `;

/**
 * __useGetUnitTypeDependenciesQuery__
 *
 * To run a query within a React component, call `useGetUnitTypeDependenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitTypeDependenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitTypeDependenciesQuery({
 *   variables: {
 *      unitTypeId: // value for 'unitTypeId'
 *      budgetYear: // value for 'budgetYear'
 *   },
 * });
 */
export function useGetUnitTypeDependenciesQuery(baseOptions: Apollo.QueryHookOptions<GetUnitTypeDependenciesQuery, GetUnitTypeDependenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitTypeDependenciesQuery, GetUnitTypeDependenciesQueryVariables>(GetUnitTypeDependenciesDocument, options);
      }
export function useGetUnitTypeDependenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitTypeDependenciesQuery, GetUnitTypeDependenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitTypeDependenciesQuery, GetUnitTypeDependenciesQueryVariables>(GetUnitTypeDependenciesDocument, options);
        }
export type GetUnitTypeDependenciesQueryHookResult = ReturnType<typeof useGetUnitTypeDependenciesQuery>;
export type GetUnitTypeDependenciesLazyQueryHookResult = ReturnType<typeof useGetUnitTypeDependenciesLazyQuery>;
export type GetUnitTypeDependenciesQueryResult = Apollo.QueryResult<GetUnitTypeDependenciesQuery, GetUnitTypeDependenciesQueryVariables>;
export const GetUnitTypePmsActualsWithOverridesYearDocument = gql`
    query GetUnitTypePMSActualsWithOverridesYear($propertyId: ID!, $unitTypeId: ID!) {
  queryUnitTypePMSActualsWithOverridesYear(
    propertyId: $propertyId
    unitTypeId: $unitTypeId
  ) {
    propertyId
    unitTypeId
    unitTypeName
    unitCount {
      unitTypeId
      unitTypeName
      originals
      overrides
    }
    inPlaceRent {
      unitTypeId
      unitTypeName
      originals
      overrides
    }
    occupancy {
      unitTypeId
      unitTypeName
      originals
      overrides
    }
  }
}
    `;

/**
 * __useGetUnitTypePmsActualsWithOverridesYearQuery__
 *
 * To run a query within a React component, call `useGetUnitTypePmsActualsWithOverridesYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitTypePmsActualsWithOverridesYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitTypePmsActualsWithOverridesYearQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      unitTypeId: // value for 'unitTypeId'
 *   },
 * });
 */
export function useGetUnitTypePmsActualsWithOverridesYearQuery(baseOptions: Apollo.QueryHookOptions<GetUnitTypePmsActualsWithOverridesYearQuery, GetUnitTypePmsActualsWithOverridesYearQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitTypePmsActualsWithOverridesYearQuery, GetUnitTypePmsActualsWithOverridesYearQueryVariables>(GetUnitTypePmsActualsWithOverridesYearDocument, options);
      }
export function useGetUnitTypePmsActualsWithOverridesYearLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitTypePmsActualsWithOverridesYearQuery, GetUnitTypePmsActualsWithOverridesYearQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitTypePmsActualsWithOverridesYearQuery, GetUnitTypePmsActualsWithOverridesYearQueryVariables>(GetUnitTypePmsActualsWithOverridesYearDocument, options);
        }
export type GetUnitTypePmsActualsWithOverridesYearQueryHookResult = ReturnType<typeof useGetUnitTypePmsActualsWithOverridesYearQuery>;
export type GetUnitTypePmsActualsWithOverridesYearLazyQueryHookResult = ReturnType<typeof useGetUnitTypePmsActualsWithOverridesYearLazyQuery>;
export type GetUnitTypePmsActualsWithOverridesYearQueryResult = Apollo.QueryResult<GetUnitTypePmsActualsWithOverridesYearQuery, GetUnitTypePmsActualsWithOverridesYearQueryVariables>;
export const ListUnitTypesDocument = gql`
    query ListUnitTypes($propertyId: ID!, $monthIndex: Int!) {
  listUnitTypes(propertyId: $propertyId) {
    id
    name
    propertyId
    createdAt
    budgetYear
    inUse
  }
  queryPropertyPMSActualsWithOverridesMonth(
    propertyId: $propertyId
    monthIndex: $monthIndex
  ) {
    propertyId
    monthIndex
    unitCount {
      unitTypeId
      unitTypeName
      original
      override
    }
    inPlaceRent {
      unitTypeId
      unitTypeName
      original
      override
    }
    occupancy {
      unitTypeId
      unitTypeName
      original
      override
    }
  }
}
    `;

/**
 * __useListUnitTypesQuery__
 *
 * To run a query within a React component, call `useListUnitTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUnitTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUnitTypesQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      monthIndex: // value for 'monthIndex'
 *   },
 * });
 */
export function useListUnitTypesQuery(baseOptions: Apollo.QueryHookOptions<ListUnitTypesQuery, ListUnitTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUnitTypesQuery, ListUnitTypesQueryVariables>(ListUnitTypesDocument, options);
      }
export function useListUnitTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUnitTypesQuery, ListUnitTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUnitTypesQuery, ListUnitTypesQueryVariables>(ListUnitTypesDocument, options);
        }
export type ListUnitTypesQueryHookResult = ReturnType<typeof useListUnitTypesQuery>;
export type ListUnitTypesLazyQueryHookResult = ReturnType<typeof useListUnitTypesLazyQuery>;
export type ListUnitTypesQueryResult = Apollo.QueryResult<ListUnitTypesQuery, ListUnitTypesQueryVariables>;
export const SetUnitTypePmsOverridesDocument = gql`
    mutation SetUnitTypePMSOverrides($propertyId: ID!, $unitCount: [UnitTypeForPMSActualOverridesUpdateInput!], $inPlaceRent: [UnitTypeForPMSActualOverridesUpdateInput!], $occupancy: [UnitTypeForPMSActualOverridesUpdateInput!]) {
  setUnitTypePMSOverrides(
    propertyId: $propertyId
    unitCount: $unitCount
    inPlaceRent: $inPlaceRent
    occupancy: $occupancy
  )
}
    `;
export type SetUnitTypePmsOverridesMutationFn = Apollo.MutationFunction<SetUnitTypePmsOverridesMutation, SetUnitTypePmsOverridesMutationVariables>;

/**
 * __useSetUnitTypePmsOverridesMutation__
 *
 * To run a mutation, you first call `useSetUnitTypePmsOverridesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUnitTypePmsOverridesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUnitTypePmsOverridesMutation, { data, loading, error }] = useSetUnitTypePmsOverridesMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      unitCount: // value for 'unitCount'
 *      inPlaceRent: // value for 'inPlaceRent'
 *      occupancy: // value for 'occupancy'
 *   },
 * });
 */
export function useSetUnitTypePmsOverridesMutation(baseOptions?: Apollo.MutationHookOptions<SetUnitTypePmsOverridesMutation, SetUnitTypePmsOverridesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUnitTypePmsOverridesMutation, SetUnitTypePmsOverridesMutationVariables>(SetUnitTypePmsOverridesDocument, options);
      }
export type SetUnitTypePmsOverridesMutationHookResult = ReturnType<typeof useSetUnitTypePmsOverridesMutation>;
export type SetUnitTypePmsOverridesMutationResult = Apollo.MutationResult<SetUnitTypePmsOverridesMutation>;
export type SetUnitTypePmsOverridesMutationOptions = Apollo.BaseMutationOptions<SetUnitTypePmsOverridesMutation, SetUnitTypePmsOverridesMutationVariables>;
export const UpdateUnitTypeDocument = gql`
    mutation UpdateUnitType($unitTypeId: ID!, $name: String!) {
  updateUnitType(unitTypeId: $unitTypeId, name: $name) {
    id
    name
    propertyId
    createdAt
    budgetYear
  }
}
    `;
export type UpdateUnitTypeMutationFn = Apollo.MutationFunction<UpdateUnitTypeMutation, UpdateUnitTypeMutationVariables>;

/**
 * __useUpdateUnitTypeMutation__
 *
 * To run a mutation, you first call `useUpdateUnitTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnitTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnitTypeMutation, { data, loading, error }] = useUpdateUnitTypeMutation({
 *   variables: {
 *      unitTypeId: // value for 'unitTypeId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateUnitTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUnitTypeMutation, UpdateUnitTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUnitTypeMutation, UpdateUnitTypeMutationVariables>(UpdateUnitTypeDocument, options);
      }
export type UpdateUnitTypeMutationHookResult = ReturnType<typeof useUpdateUnitTypeMutation>;
export type UpdateUnitTypeMutationResult = Apollo.MutationResult<UpdateUnitTypeMutation>;
export type UpdateUnitTypeMutationOptions = Apollo.BaseMutationOptions<UpdateUnitTypeMutation, UpdateUnitTypeMutationVariables>;
export const LoadChartOfAccountsDocument = gql`
    query LoadChartOfAccounts {
  listChartOfAccounts {
    versionNum
    records {
      id
      glNumber
      glName
      type
      order
      parentIds
    }
  }
}
    `;

/**
 * __useLoadChartOfAccountsQuery__
 *
 * To run a query within a React component, call `useLoadChartOfAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadChartOfAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadChartOfAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadChartOfAccountsQuery(baseOptions?: Apollo.QueryHookOptions<LoadChartOfAccountsQuery, LoadChartOfAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadChartOfAccountsQuery, LoadChartOfAccountsQueryVariables>(LoadChartOfAccountsDocument, options);
      }
export function useLoadChartOfAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadChartOfAccountsQuery, LoadChartOfAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadChartOfAccountsQuery, LoadChartOfAccountsQueryVariables>(LoadChartOfAccountsDocument, options);
        }
export type LoadChartOfAccountsQueryHookResult = ReturnType<typeof useLoadChartOfAccountsQuery>;
export type LoadChartOfAccountsLazyQueryHookResult = ReturnType<typeof useLoadChartOfAccountsLazyQuery>;
export type LoadChartOfAccountsQueryResult = Apollo.QueryResult<LoadChartOfAccountsQuery, LoadChartOfAccountsQueryVariables>;
export const QueryAccountPercentageDriverCyclesDocument = gql`
    query QueryAccountPercentageDriverCycles($propertyId: ID!, $versionType: VersionType!, $year: Int!, $destinationAccountIds: [ID!]!, $sourceAccountIds: [ID!]!) {
  queryAccountPercentageDriverCycles(
    propertyId: $propertyId
    versionType: $versionType
    year: $year
    destinationAccountIds: $destinationAccountIds
    sourceAccountIds: $sourceAccountIds
  ) {
    accountIds
  }
}
    `;

/**
 * __useQueryAccountPercentageDriverCyclesQuery__
 *
 * To run a query within a React component, call `useQueryAccountPercentageDriverCyclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryAccountPercentageDriverCyclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryAccountPercentageDriverCyclesQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      versionType: // value for 'versionType'
 *      year: // value for 'year'
 *      destinationAccountIds: // value for 'destinationAccountIds'
 *      sourceAccountIds: // value for 'sourceAccountIds'
 *   },
 * });
 */
export function useQueryAccountPercentageDriverCyclesQuery(baseOptions: Apollo.QueryHookOptions<QueryAccountPercentageDriverCyclesQuery, QueryAccountPercentageDriverCyclesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryAccountPercentageDriverCyclesQuery, QueryAccountPercentageDriverCyclesQueryVariables>(QueryAccountPercentageDriverCyclesDocument, options);
      }
export function useQueryAccountPercentageDriverCyclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryAccountPercentageDriverCyclesQuery, QueryAccountPercentageDriverCyclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryAccountPercentageDriverCyclesQuery, QueryAccountPercentageDriverCyclesQueryVariables>(QueryAccountPercentageDriverCyclesDocument, options);
        }
export type QueryAccountPercentageDriverCyclesQueryHookResult = ReturnType<typeof useQueryAccountPercentageDriverCyclesQuery>;
export type QueryAccountPercentageDriverCyclesLazyQueryHookResult = ReturnType<typeof useQueryAccountPercentageDriverCyclesLazyQuery>;
export type QueryAccountPercentageDriverCyclesQueryResult = Apollo.QueryResult<QueryAccountPercentageDriverCyclesQuery, QueryAccountPercentageDriverCyclesQueryVariables>;
export const GetUnitTypesPmsActualsOverridesFullDocument = gql`
    query GetUnitTypesPMSActualsOverridesFull($propertyId: ID!, $year: Int!) {
  queryUnitTypesPMSActualsOverridesFull(propertyId: $propertyId, year: $year) {
    propertyId
    unitTypeId
    unitTypeName
    unitCount
    inPlaceRent
    occupancy
  }
}
    `;

/**
 * __useGetUnitTypesPmsActualsOverridesFullQuery__
 *
 * To run a query within a React component, call `useGetUnitTypesPmsActualsOverridesFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitTypesPmsActualsOverridesFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitTypesPmsActualsOverridesFullQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetUnitTypesPmsActualsOverridesFullQuery(baseOptions: Apollo.QueryHookOptions<GetUnitTypesPmsActualsOverridesFullQuery, GetUnitTypesPmsActualsOverridesFullQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitTypesPmsActualsOverridesFullQuery, GetUnitTypesPmsActualsOverridesFullQueryVariables>(GetUnitTypesPmsActualsOverridesFullDocument, options);
      }
export function useGetUnitTypesPmsActualsOverridesFullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitTypesPmsActualsOverridesFullQuery, GetUnitTypesPmsActualsOverridesFullQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitTypesPmsActualsOverridesFullQuery, GetUnitTypesPmsActualsOverridesFullQueryVariables>(GetUnitTypesPmsActualsOverridesFullDocument, options);
        }
export type GetUnitTypesPmsActualsOverridesFullQueryHookResult = ReturnType<typeof useGetUnitTypesPmsActualsOverridesFullQuery>;
export type GetUnitTypesPmsActualsOverridesFullLazyQueryHookResult = ReturnType<typeof useGetUnitTypesPmsActualsOverridesFullLazyQuery>;
export type GetUnitTypesPmsActualsOverridesFullQueryResult = Apollo.QueryResult<GetUnitTypesPmsActualsOverridesFullQuery, GetUnitTypesPmsActualsOverridesFullQueryVariables>;
export const GetAverageRentDocument = gql`
    query GetAverageRent($propertyId: String!, $year: Int!, $budgetYear: Int!, $versionYear: Int!, $versionType: VersionType) {
  actuals: valueVersions(
    where: {AND: [{year: {equals: $year}}, {type: {equals: ACTUALS}}]}
  ) {
    id
    name
    type
    unitTypeScheduleValues(
      where: {propertyId: {equals: $propertyId}, unitType: {is: {budgetYear: {equals: $budgetYear}}}}
    ) {
      id
      monthIndex
      unitType {
        id
        name
      }
      unitCount
      averageMarketRent
    }
  }
  budget: valueVersions(
    where: {AND: [{year: {equals: $budgetYear}}, {type: {equals: BUDGET}}]}
  ) {
    id
    name
    type
    unitTypeScheduleValues(
      where: {propertyId: {equals: $propertyId}, unitType: {is: {budgetYear: {equals: $budgetYear}}}}
    ) {
      id
      monthIndex
      unitType {
        id
        name
      }
      unitCount
      averageMarketRent
    }
  }
  currentVersion: valueVersions(
    where: {AND: [{year: {equals: $versionYear}}, {type: {equals: $versionType}}]}
  ) {
    id
    name
    type
    unitTypeScheduleValues(
      where: {propertyId: {equals: $propertyId}, unitType: {is: {budgetYear: {equals: $budgetYear}}}}
    ) {
      id
      monthIndex
      unitType {
        id
        name
      }
      unitCount
      averageMarketRent
    }
  }
}
    `;

/**
 * __useGetAverageRentQuery__
 *
 * To run a query within a React component, call `useGetAverageRentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAverageRentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAverageRentQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      year: // value for 'year'
 *      budgetYear: // value for 'budgetYear'
 *      versionYear: // value for 'versionYear'
 *      versionType: // value for 'versionType'
 *   },
 * });
 */
export function useGetAverageRentQuery(baseOptions: Apollo.QueryHookOptions<GetAverageRentQuery, GetAverageRentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAverageRentQuery, GetAverageRentQueryVariables>(GetAverageRentDocument, options);
      }
export function useGetAverageRentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAverageRentQuery, GetAverageRentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAverageRentQuery, GetAverageRentQueryVariables>(GetAverageRentDocument, options);
        }
export type GetAverageRentQueryHookResult = ReturnType<typeof useGetAverageRentQuery>;
export type GetAverageRentLazyQueryHookResult = ReturnType<typeof useGetAverageRentLazyQuery>;
export type GetAverageRentQueryResult = Apollo.QueryResult<GetAverageRentQuery, GetAverageRentQueryVariables>;
export const UpdateOriginalRevenueMarketRentModeDocument = gql`
    mutation UpdateOriginalRevenueMarketRentMode($propertyId: ID!, $budgetYear: Int!, $originalRevenueMarketRentModeType: OriginalRevenueMarketRentModeType!) {
  setOriginalRevenueMarketRentMode(
    propertyId: $propertyId
    budgetYear: $budgetYear
    originalRevenueMarketRentModeType: $originalRevenueMarketRentModeType
  )
}
    `;
export type UpdateOriginalRevenueMarketRentModeMutationFn = Apollo.MutationFunction<UpdateOriginalRevenueMarketRentModeMutation, UpdateOriginalRevenueMarketRentModeMutationVariables>;

/**
 * __useUpdateOriginalRevenueMarketRentModeMutation__
 *
 * To run a mutation, you first call `useUpdateOriginalRevenueMarketRentModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOriginalRevenueMarketRentModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOriginalRevenueMarketRentModeMutation, { data, loading, error }] = useUpdateOriginalRevenueMarketRentModeMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      originalRevenueMarketRentModeType: // value for 'originalRevenueMarketRentModeType'
 *   },
 * });
 */
export function useUpdateOriginalRevenueMarketRentModeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOriginalRevenueMarketRentModeMutation, UpdateOriginalRevenueMarketRentModeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOriginalRevenueMarketRentModeMutation, UpdateOriginalRevenueMarketRentModeMutationVariables>(UpdateOriginalRevenueMarketRentModeDocument, options);
      }
export type UpdateOriginalRevenueMarketRentModeMutationHookResult = ReturnType<typeof useUpdateOriginalRevenueMarketRentModeMutation>;
export type UpdateOriginalRevenueMarketRentModeMutationResult = Apollo.MutationResult<UpdateOriginalRevenueMarketRentModeMutation>;
export type UpdateOriginalRevenueMarketRentModeMutationOptions = Apollo.BaseMutationOptions<UpdateOriginalRevenueMarketRentModeMutation, UpdateOriginalRevenueMarketRentModeMutationVariables>;
export const GetOriginalRevenueMarketRentDataDocument = gql`
    query GetOriginalRevenueMarketRentData($propertyId: ID!, $budgetYear: Int!, $versionType: VersionType!) {
  originalRevenueMarketRentAdjustments(
    propertyId: $propertyId
    budgetYear: $budgetYear
    versionType: $versionType
  ) {
    propertyId
    versionType
    year
    adjustments
    adjustmentTypes
  }
  originalRevenueMarketRentOverrides(
    propertyId: $propertyId
    budgetYear: $budgetYear
    versionType: $versionType
  ) {
    unitTypeId
    unitTypeName
    propertyId
    overrides
  }
  originalRevenueMarketRentValues(
    propertyId: $propertyId
    budgetYear: $budgetYear
  ) {
    unitTypeId
    unitTypeName
    propertyId
    actuals
    reforecast
    budget
  }
}
    `;

/**
 * __useGetOriginalRevenueMarketRentDataQuery__
 *
 * To run a query within a React component, call `useGetOriginalRevenueMarketRentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOriginalRevenueMarketRentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOriginalRevenueMarketRentDataQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      versionType: // value for 'versionType'
 *   },
 * });
 */
export function useGetOriginalRevenueMarketRentDataQuery(baseOptions: Apollo.QueryHookOptions<GetOriginalRevenueMarketRentDataQuery, GetOriginalRevenueMarketRentDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOriginalRevenueMarketRentDataQuery, GetOriginalRevenueMarketRentDataQueryVariables>(GetOriginalRevenueMarketRentDataDocument, options);
      }
export function useGetOriginalRevenueMarketRentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOriginalRevenueMarketRentDataQuery, GetOriginalRevenueMarketRentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOriginalRevenueMarketRentDataQuery, GetOriginalRevenueMarketRentDataQueryVariables>(GetOriginalRevenueMarketRentDataDocument, options);
        }
export type GetOriginalRevenueMarketRentDataQueryHookResult = ReturnType<typeof useGetOriginalRevenueMarketRentDataQuery>;
export type GetOriginalRevenueMarketRentDataLazyQueryHookResult = ReturnType<typeof useGetOriginalRevenueMarketRentDataLazyQuery>;
export type GetOriginalRevenueMarketRentDataQueryResult = Apollo.QueryResult<GetOriginalRevenueMarketRentDataQuery, GetOriginalRevenueMarketRentDataQueryVariables>;
export const UpdateOriginalRevenueMarketRentDataDocument = gql`
    mutation UpdateOriginalRevenueMarketRentData($propertyId: ID!, $budgetYear: Int!, $versionType: VersionType!, $adjM0: Float, $adjM1: Float, $adjM2: Float, $adjM3: Float, $adjM4: Float, $adjM5: Float, $adjM6: Float, $adjM7: Float, $adjM8: Float, $adjM9: Float, $adjM10: Float, $adjM11: Float, $adjTpM0: OriginalRevenueMarketRentAdjustmentType, $adjTpM1: OriginalRevenueMarketRentAdjustmentType, $adjTpM2: OriginalRevenueMarketRentAdjustmentType, $adjTpM3: OriginalRevenueMarketRentAdjustmentType, $adjTpM4: OriginalRevenueMarketRentAdjustmentType, $adjTpM5: OriginalRevenueMarketRentAdjustmentType, $adjTpM6: OriginalRevenueMarketRentAdjustmentType, $adjTpM7: OriginalRevenueMarketRentAdjustmentType, $adjTpM8: OriginalRevenueMarketRentAdjustmentType, $adjTpM9: OriginalRevenueMarketRentAdjustmentType, $adjTpM10: OriginalRevenueMarketRentAdjustmentType, $adjTpM11: OriginalRevenueMarketRentAdjustmentType, $unitTypeOverrides: [UnitTypeWithOriginalrevenueMarketRentOverridesGranularInput!]!) {
  setOriginalRevenueMarketRentAdjustmentsGranular(
    propertyId: $propertyId
    budgetYear: $budgetYear
    versionType: $versionType
    adjM0: $adjM0
    adjM1: $adjM1
    adjM2: $adjM2
    adjM3: $adjM3
    adjM4: $adjM4
    adjM5: $adjM5
    adjM6: $adjM6
    adjM7: $adjM7
    adjM8: $adjM8
    adjM9: $adjM9
    adjM10: $adjM10
    adjM11: $adjM11
    adjTpM0: $adjTpM0
    adjTpM1: $adjTpM1
    adjTpM2: $adjTpM2
    adjTpM3: $adjTpM3
    adjTpM4: $adjTpM4
    adjTpM5: $adjTpM5
    adjTpM6: $adjTpM6
    adjTpM7: $adjTpM7
    adjTpM8: $adjTpM8
    adjTpM9: $adjTpM9
    adjTpM10: $adjTpM10
    adjTpM11: $adjTpM11
  )
  setOriginalRevenueMarketRentOverridesGranular(
    propertyId: $propertyId
    budgetYear: $budgetYear
    versionType: $versionType
    unitTypeOverrides: $unitTypeOverrides
  )
}
    `;
export type UpdateOriginalRevenueMarketRentDataMutationFn = Apollo.MutationFunction<UpdateOriginalRevenueMarketRentDataMutation, UpdateOriginalRevenueMarketRentDataMutationVariables>;

/**
 * __useUpdateOriginalRevenueMarketRentDataMutation__
 *
 * To run a mutation, you first call `useUpdateOriginalRevenueMarketRentDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOriginalRevenueMarketRentDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOriginalRevenueMarketRentDataMutation, { data, loading, error }] = useUpdateOriginalRevenueMarketRentDataMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      versionType: // value for 'versionType'
 *      adjM0: // value for 'adjM0'
 *      adjM1: // value for 'adjM1'
 *      adjM2: // value for 'adjM2'
 *      adjM3: // value for 'adjM3'
 *      adjM4: // value for 'adjM4'
 *      adjM5: // value for 'adjM5'
 *      adjM6: // value for 'adjM6'
 *      adjM7: // value for 'adjM7'
 *      adjM8: // value for 'adjM8'
 *      adjM9: // value for 'adjM9'
 *      adjM10: // value for 'adjM10'
 *      adjM11: // value for 'adjM11'
 *      adjTpM0: // value for 'adjTpM0'
 *      adjTpM1: // value for 'adjTpM1'
 *      adjTpM2: // value for 'adjTpM2'
 *      adjTpM3: // value for 'adjTpM3'
 *      adjTpM4: // value for 'adjTpM4'
 *      adjTpM5: // value for 'adjTpM5'
 *      adjTpM6: // value for 'adjTpM6'
 *      adjTpM7: // value for 'adjTpM7'
 *      adjTpM8: // value for 'adjTpM8'
 *      adjTpM9: // value for 'adjTpM9'
 *      adjTpM10: // value for 'adjTpM10'
 *      adjTpM11: // value for 'adjTpM11'
 *      unitTypeOverrides: // value for 'unitTypeOverrides'
 *   },
 * });
 */
export function useUpdateOriginalRevenueMarketRentDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOriginalRevenueMarketRentDataMutation, UpdateOriginalRevenueMarketRentDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOriginalRevenueMarketRentDataMutation, UpdateOriginalRevenueMarketRentDataMutationVariables>(UpdateOriginalRevenueMarketRentDataDocument, options);
      }
export type UpdateOriginalRevenueMarketRentDataMutationHookResult = ReturnType<typeof useUpdateOriginalRevenueMarketRentDataMutation>;
export type UpdateOriginalRevenueMarketRentDataMutationResult = Apollo.MutationResult<UpdateOriginalRevenueMarketRentDataMutation>;
export type UpdateOriginalRevenueMarketRentDataMutationOptions = Apollo.BaseMutationOptions<UpdateOriginalRevenueMarketRentDataMutation, UpdateOriginalRevenueMarketRentDataMutationVariables>;
export const GetEarlyTerminationsDocument = gql`
    query GetEarlyTerminations($propertyId: String!, $year: Int!, $versionYear: Int!, $budgetYear: Int!, $versionType: VersionType) {
  actuals: valueVersions(
    where: {AND: [{year: {lte: $year}}, {type: {equals: ACTUALS}}]}
  ) {
    id
    name
    type
    year
    operationalMetrics(
      where: {propertyId: {equals: $propertyId}, budgetYear: {equals: $budgetYear}}
    ) {
      id
      monthIndex
      unitCount
      earlyTerminationCount
    }
  }
  budget: valueVersions(
    where: {AND: [{year: {equals: $budgetYear}}, {type: {equals: BUDGET}}]}
  ) {
    id
    name
    type
    year
    operationalMetrics(
      where: {propertyId: {equals: $propertyId}, budgetYear: {equals: $budgetYear}}
    ) {
      id
      monthIndex
      unitCount
      earlyTerminationCount
    }
  }
  currentVersion: valueVersions(
    where: {AND: [{year: {equals: $versionYear}}, {type: {equals: $versionType}}]}
  ) {
    id
    name
    type
    year
    operationalMetrics(
      where: {propertyId: {equals: $propertyId}, budgetYear: {equals: $budgetYear}}
    ) {
      id
      monthIndex
      unitCount
      earlyTerminationCount
    }
  }
  reforecastVersion: valueVersions(
    where: {AND: [{year: {equals: $year}}, {type: {equals: REFORECAST}}]}
  ) {
    id
    name
    type
    year
    operationalMetrics(
      where: {propertyId: {equals: $propertyId}, budgetYear: {equals: $budgetYear}}
    ) {
      id
      monthIndex
      earlyTerminationCount
      unitCount
    }
  }
}
    `;

/**
 * __useGetEarlyTerminationsQuery__
 *
 * To run a query within a React component, call `useGetEarlyTerminationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEarlyTerminationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEarlyTerminationsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      year: // value for 'year'
 *      versionYear: // value for 'versionYear'
 *      budgetYear: // value for 'budgetYear'
 *      versionType: // value for 'versionType'
 *   },
 * });
 */
export function useGetEarlyTerminationsQuery(baseOptions: Apollo.QueryHookOptions<GetEarlyTerminationsQuery, GetEarlyTerminationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEarlyTerminationsQuery, GetEarlyTerminationsQueryVariables>(GetEarlyTerminationsDocument, options);
      }
export function useGetEarlyTerminationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEarlyTerminationsQuery, GetEarlyTerminationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEarlyTerminationsQuery, GetEarlyTerminationsQueryVariables>(GetEarlyTerminationsDocument, options);
        }
export type GetEarlyTerminationsQueryHookResult = ReturnType<typeof useGetEarlyTerminationsQuery>;
export type GetEarlyTerminationsLazyQueryHookResult = ReturnType<typeof useGetEarlyTerminationsLazyQuery>;
export type GetEarlyTerminationsQueryResult = Apollo.QueryResult<GetEarlyTerminationsQuery, GetEarlyTerminationsQueryVariables>;
export const GetLeaseExpirationCountDocument = gql`
    query GetLeaseExpirationCount($propertyId: String!, $year: Int!, $budgetYear: Int!, $versionYear: Int!, $versionType: VersionType) {
  actuals: valueVersions(
    where: {AND: [{year: {equals: $year}}, {type: {equals: ACTUALS}}]}
  ) {
    id
    name
    type
    year
    unitTypeScheduleValues(
      where: {propertyId: {equals: $propertyId}, unitType: {is: {budgetYear: {equals: $budgetYear}}}}
    ) {
      id
      monthIndex
      unitType {
        id
        name
      }
      unitCount
      leaseExpirationCount
    }
  }
  currentVersion: valueVersions(
    where: {AND: [{year: {equals: $versionYear}}, {type: {equals: $versionType}}]}
  ) {
    id
    name
    type
    year
    unitTypeScheduleValues(
      where: {propertyId: {equals: $propertyId}, unitType: {is: {budgetYear: {equals: $budgetYear}}}}
    ) {
      id
      monthIndex
      unitType {
        id
        name
      }
      unitCount
      leaseExpirationCount
    }
  }
}
    `;

/**
 * __useGetLeaseExpirationCountQuery__
 *
 * To run a query within a React component, call `useGetLeaseExpirationCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaseExpirationCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaseExpirationCountQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      year: // value for 'year'
 *      budgetYear: // value for 'budgetYear'
 *      versionYear: // value for 'versionYear'
 *      versionType: // value for 'versionType'
 *   },
 * });
 */
export function useGetLeaseExpirationCountQuery(baseOptions: Apollo.QueryHookOptions<GetLeaseExpirationCountQuery, GetLeaseExpirationCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLeaseExpirationCountQuery, GetLeaseExpirationCountQueryVariables>(GetLeaseExpirationCountDocument, options);
      }
export function useGetLeaseExpirationCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLeaseExpirationCountQuery, GetLeaseExpirationCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLeaseExpirationCountQuery, GetLeaseExpirationCountQueryVariables>(GetLeaseExpirationCountDocument, options);
        }
export type GetLeaseExpirationCountQueryHookResult = ReturnType<typeof useGetLeaseExpirationCountQuery>;
export type GetLeaseExpirationCountLazyQueryHookResult = ReturnType<typeof useGetLeaseExpirationCountLazyQuery>;
export type GetLeaseExpirationCountQueryResult = Apollo.QueryResult<GetLeaseExpirationCountQuery, GetLeaseExpirationCountQueryVariables>;
export const GetLeaseExpirationCountsForRewriteDocument = gql`
    query GetLeaseExpirationCountsForRewrite($propertyId: ID!, $versionType: VersionType!) {
  queryPMSLeaseExpirationCountsForPropertyAndPeriod(
    propertyId: $propertyId
    versionType: $versionType
  ) {
    unitTypeId
    unitTypeName
    values
  }
  queryForecastedLeaseExpirationCountsForPropertyAndPeriod(
    propertyId: $propertyId
    versionType: $versionType
  ) {
    unitTypeId
    unitTypeName
    values
  }
}
    `;

/**
 * __useGetLeaseExpirationCountsForRewriteQuery__
 *
 * To run a query within a React component, call `useGetLeaseExpirationCountsForRewriteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaseExpirationCountsForRewriteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaseExpirationCountsForRewriteQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      versionType: // value for 'versionType'
 *   },
 * });
 */
export function useGetLeaseExpirationCountsForRewriteQuery(baseOptions: Apollo.QueryHookOptions<GetLeaseExpirationCountsForRewriteQuery, GetLeaseExpirationCountsForRewriteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLeaseExpirationCountsForRewriteQuery, GetLeaseExpirationCountsForRewriteQueryVariables>(GetLeaseExpirationCountsForRewriteDocument, options);
      }
export function useGetLeaseExpirationCountsForRewriteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLeaseExpirationCountsForRewriteQuery, GetLeaseExpirationCountsForRewriteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLeaseExpirationCountsForRewriteQuery, GetLeaseExpirationCountsForRewriteQueryVariables>(GetLeaseExpirationCountsForRewriteDocument, options);
        }
export type GetLeaseExpirationCountsForRewriteQueryHookResult = ReturnType<typeof useGetLeaseExpirationCountsForRewriteQuery>;
export type GetLeaseExpirationCountsForRewriteLazyQueryHookResult = ReturnType<typeof useGetLeaseExpirationCountsForRewriteLazyQuery>;
export type GetLeaseExpirationCountsForRewriteQueryResult = Apollo.QueryResult<GetLeaseExpirationCountsForRewriteQuery, GetLeaseExpirationCountsForRewriteQueryVariables>;
export const MassUpdateLeaseExpirationCountsForPropertyAndPeriodDocument = gql`
    mutation MassUpdateLeaseExpirationCountsForPropertyAndPeriod($propertyId: ID!, $versionType: VersionType!, $updates: [LeaseExpirationCountsInput!]!) {
  massUpdateLeaseExpirationCountsForPropertyAndPeriod(
    propertyId: $propertyId
    versionType: $versionType
    updates: $updates
  )
}
    `;
export type MassUpdateLeaseExpirationCountsForPropertyAndPeriodMutationFn = Apollo.MutationFunction<MassUpdateLeaseExpirationCountsForPropertyAndPeriodMutation, MassUpdateLeaseExpirationCountsForPropertyAndPeriodMutationVariables>;

/**
 * __useMassUpdateLeaseExpirationCountsForPropertyAndPeriodMutation__
 *
 * To run a mutation, you first call `useMassUpdateLeaseExpirationCountsForPropertyAndPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMassUpdateLeaseExpirationCountsForPropertyAndPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [massUpdateLeaseExpirationCountsForPropertyAndPeriodMutation, { data, loading, error }] = useMassUpdateLeaseExpirationCountsForPropertyAndPeriodMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      versionType: // value for 'versionType'
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useMassUpdateLeaseExpirationCountsForPropertyAndPeriodMutation(baseOptions?: Apollo.MutationHookOptions<MassUpdateLeaseExpirationCountsForPropertyAndPeriodMutation, MassUpdateLeaseExpirationCountsForPropertyAndPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MassUpdateLeaseExpirationCountsForPropertyAndPeriodMutation, MassUpdateLeaseExpirationCountsForPropertyAndPeriodMutationVariables>(MassUpdateLeaseExpirationCountsForPropertyAndPeriodDocument, options);
      }
export type MassUpdateLeaseExpirationCountsForPropertyAndPeriodMutationHookResult = ReturnType<typeof useMassUpdateLeaseExpirationCountsForPropertyAndPeriodMutation>;
export type MassUpdateLeaseExpirationCountsForPropertyAndPeriodMutationResult = Apollo.MutationResult<MassUpdateLeaseExpirationCountsForPropertyAndPeriodMutation>;
export type MassUpdateLeaseExpirationCountsForPropertyAndPeriodMutationOptions = Apollo.BaseMutationOptions<MassUpdateLeaseExpirationCountsForPropertyAndPeriodMutation, MassUpdateLeaseExpirationCountsForPropertyAndPeriodMutationVariables>;
export const CalculateCurveExpirationsDocument = gql`
    query CalculateCurveExpirations($propertyId: ID!, $budgetYear: Int!, $curve: [Decimal!]!) {
  calculateExpirationCurveExpirations(
    propertyId: $propertyId
    budgetYear: $budgetYear
    curve: $curve
  ) {
    propertyId
    unitTypeId
    unitTypeName
    reforecastExpirations
    budgetExpirations
  }
}
    `;

/**
 * __useCalculateCurveExpirationsQuery__
 *
 * To run a query within a React component, call `useCalculateCurveExpirationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateCurveExpirationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateCurveExpirationsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      curve: // value for 'curve'
 *   },
 * });
 */
export function useCalculateCurveExpirationsQuery(baseOptions: Apollo.QueryHookOptions<CalculateCurveExpirationsQuery, CalculateCurveExpirationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalculateCurveExpirationsQuery, CalculateCurveExpirationsQueryVariables>(CalculateCurveExpirationsDocument, options);
      }
export function useCalculateCurveExpirationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalculateCurveExpirationsQuery, CalculateCurveExpirationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalculateCurveExpirationsQuery, CalculateCurveExpirationsQueryVariables>(CalculateCurveExpirationsDocument, options);
        }
export type CalculateCurveExpirationsQueryHookResult = ReturnType<typeof useCalculateCurveExpirationsQuery>;
export type CalculateCurveExpirationsLazyQueryHookResult = ReturnType<typeof useCalculateCurveExpirationsLazyQuery>;
export type CalculateCurveExpirationsQueryResult = Apollo.QueryResult<CalculateCurveExpirationsQuery, CalculateCurveExpirationsQueryVariables>;
export const GetExpirationCurveDocument = gql`
    query GetExpirationCurve($propertyId: ID!, $budgetYear: Int!) {
  expirationCurve(propertyId: $propertyId, budgetYear: $budgetYear) {
    propertyId
    budgetYear
    curve
  }
}
    `;

/**
 * __useGetExpirationCurveQuery__
 *
 * To run a query within a React component, call `useGetExpirationCurveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpirationCurveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpirationCurveQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *   },
 * });
 */
export function useGetExpirationCurveQuery(baseOptions: Apollo.QueryHookOptions<GetExpirationCurveQuery, GetExpirationCurveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExpirationCurveQuery, GetExpirationCurveQueryVariables>(GetExpirationCurveDocument, options);
      }
export function useGetExpirationCurveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExpirationCurveQuery, GetExpirationCurveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExpirationCurveQuery, GetExpirationCurveQueryVariables>(GetExpirationCurveDocument, options);
        }
export type GetExpirationCurveQueryHookResult = ReturnType<typeof useGetExpirationCurveQuery>;
export type GetExpirationCurveLazyQueryHookResult = ReturnType<typeof useGetExpirationCurveLazyQuery>;
export type GetExpirationCurveQueryResult = Apollo.QueryResult<GetExpirationCurveQuery, GetExpirationCurveQueryVariables>;
export const SetExpirationCurveDocument = gql`
    mutation SetExpirationCurve($propertyId: ID!, $budgetYear: Int!, $curve: [Decimal!]!) {
  setExpirationCurve(
    propertyId: $propertyId
    budgetYear: $budgetYear
    curve: $curve
  ) {
    propertyId
    budgetYear
    curve
  }
}
    `;
export type SetExpirationCurveMutationFn = Apollo.MutationFunction<SetExpirationCurveMutation, SetExpirationCurveMutationVariables>;

/**
 * __useSetExpirationCurveMutation__
 *
 * To run a mutation, you first call `useSetExpirationCurveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetExpirationCurveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setExpirationCurveMutation, { data, loading, error }] = useSetExpirationCurveMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      curve: // value for 'curve'
 *   },
 * });
 */
export function useSetExpirationCurveMutation(baseOptions?: Apollo.MutationHookOptions<SetExpirationCurveMutation, SetExpirationCurveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetExpirationCurveMutation, SetExpirationCurveMutationVariables>(SetExpirationCurveDocument, options);
      }
export type SetExpirationCurveMutationHookResult = ReturnType<typeof useSetExpirationCurveMutation>;
export type SetExpirationCurveMutationResult = Apollo.MutationResult<SetExpirationCurveMutation>;
export type SetExpirationCurveMutationOptions = Apollo.BaseMutationOptions<SetExpirationCurveMutation, SetExpirationCurveMutationVariables>;
export const GetMonthToMonthMoveOutsDocument = gql`
    query GetMonthToMonthMoveOuts($propertyId: String!, $year: Int!, $versionYear: Int!, $budgetYear: Int!, $versionType: VersionType) {
  actuals: valueVersions(
    where: {AND: [{year: {lte: $year}}, {type: {equals: ACTUALS}}]}
  ) {
    id
    name
    type
    year
    operationalMetrics(where: {propertyId: {equals: $propertyId}}) {
      id
      monthIndex
      monthToMonthMoveOutCount
      unitCount
    }
  }
  budget: valueVersions(
    where: {AND: [{year: {equals: $budgetYear}}, {type: {equals: BUDGET}}]}
  ) {
    id
    name
    type
    year
    operationalMetrics(
      where: {propertyId: {equals: $propertyId}, budgetYear: {equals: $budgetYear}}
    ) {
      id
      monthIndex
      monthToMonthMoveOutCount
      unitCount
    }
  }
  currentVersion: valueVersions(
    where: {AND: [{year: {equals: $versionYear}}, {type: {equals: $versionType}}]}
  ) {
    id
    name
    type
    year
    operationalMetrics(
      where: {propertyId: {equals: $propertyId}, budgetYear: {equals: $budgetYear}}
    ) {
      id
      monthIndex
      monthToMonthMoveOutCount
      unitCount
    }
  }
  reforecastVersion: valueVersions(
    where: {AND: [{year: {equals: $year}}, {type: {equals: REFORECAST}}]}
  ) {
    id
    name
    type
    year
    operationalMetrics(
      where: {propertyId: {equals: $propertyId}, budgetYear: {equals: $budgetYear}}
    ) {
      id
      monthIndex
      monthToMonthMoveOutCount
      unitCount
    }
  }
}
    `;

/**
 * __useGetMonthToMonthMoveOutsQuery__
 *
 * To run a query within a React component, call `useGetMonthToMonthMoveOutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonthToMonthMoveOutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonthToMonthMoveOutsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      year: // value for 'year'
 *      versionYear: // value for 'versionYear'
 *      budgetYear: // value for 'budgetYear'
 *      versionType: // value for 'versionType'
 *   },
 * });
 */
export function useGetMonthToMonthMoveOutsQuery(baseOptions: Apollo.QueryHookOptions<GetMonthToMonthMoveOutsQuery, GetMonthToMonthMoveOutsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMonthToMonthMoveOutsQuery, GetMonthToMonthMoveOutsQueryVariables>(GetMonthToMonthMoveOutsDocument, options);
      }
export function useGetMonthToMonthMoveOutsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMonthToMonthMoveOutsQuery, GetMonthToMonthMoveOutsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMonthToMonthMoveOutsQuery, GetMonthToMonthMoveOutsQueryVariables>(GetMonthToMonthMoveOutsDocument, options);
        }
export type GetMonthToMonthMoveOutsQueryHookResult = ReturnType<typeof useGetMonthToMonthMoveOutsQuery>;
export type GetMonthToMonthMoveOutsLazyQueryHookResult = ReturnType<typeof useGetMonthToMonthMoveOutsLazyQuery>;
export type GetMonthToMonthMoveOutsQueryResult = Apollo.QueryResult<GetMonthToMonthMoveOutsQuery, GetMonthToMonthMoveOutsQueryVariables>;
export const GetMoveOutRatesDocument = gql`
    query GetMoveOutRates($propertyId: String!, $year: Int!, $versionYear: Int!, $budgetYear: Int!, $versionType: VersionType) {
  actuals: valueVersions(
    where: {AND: [{year: {lte: $year}}, {type: {equals: ACTUALS}}]}
  ) {
    id
    name
    type
    year
    unitTypeScheduleValues(
      where: {propertyId: {equals: $propertyId}, unitType: {is: {budgetYear: {equals: $budgetYear}}}}
    ) {
      id
      monthIndex
      unitType {
        id
        name
      }
      moveOutRate
      leaseExpirationCount
      unitCount
    }
  }
  budget: valueVersions(
    where: {AND: [{year: {equals: $budgetYear}}, {type: {equals: BUDGET}}]}
  ) {
    id
    name
    type
    year
    unitTypeScheduleValues(
      where: {propertyId: {equals: $propertyId}, unitType: {is: {budgetYear: {equals: $budgetYear}}}}
    ) {
      id
      monthIndex
      unitType {
        id
        name
      }
      moveOutRate
      leaseExpirationCount
      unitCount
    }
  }
  currentVersion: valueVersions(
    where: {AND: [{year: {equals: $versionYear}}, {type: {equals: $versionType}}]}
  ) {
    id
    name
    type
    year
    unitTypeScheduleValues(
      where: {propertyId: {equals: $propertyId}, unitType: {is: {budgetYear: {equals: $budgetYear}}}}
    ) {
      id
      monthIndex
      unitType {
        id
        name
      }
      moveOutRate
      leaseExpirationCount
      unitCount
    }
  }
  reforecastVersion: valueVersions(
    where: {AND: [{year: {equals: $year}}, {type: {equals: REFORECAST}}]}
  ) {
    id
    name
    type
    year
    unitTypeScheduleValues(
      where: {propertyId: {equals: $propertyId}, unitType: {is: {budgetYear: {equals: $budgetYear}}}}
    ) {
      id
      monthIndex
      unitType {
        id
        name
      }
      moveOutRate
      leaseExpirationCount
      unitCount
    }
  }
}
    `;

/**
 * __useGetMoveOutRatesQuery__
 *
 * To run a query within a React component, call `useGetMoveOutRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoveOutRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoveOutRatesQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      year: // value for 'year'
 *      versionYear: // value for 'versionYear'
 *      budgetYear: // value for 'budgetYear'
 *      versionType: // value for 'versionType'
 *   },
 * });
 */
export function useGetMoveOutRatesQuery(baseOptions: Apollo.QueryHookOptions<GetMoveOutRatesQuery, GetMoveOutRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMoveOutRatesQuery, GetMoveOutRatesQueryVariables>(GetMoveOutRatesDocument, options);
      }
export function useGetMoveOutRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMoveOutRatesQuery, GetMoveOutRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMoveOutRatesQuery, GetMoveOutRatesQueryVariables>(GetMoveOutRatesDocument, options);
        }
export type GetMoveOutRatesQueryHookResult = ReturnType<typeof useGetMoveOutRatesQuery>;
export type GetMoveOutRatesLazyQueryHookResult = ReturnType<typeof useGetMoveOutRatesLazyQuery>;
export type GetMoveOutRatesQueryResult = Apollo.QueryResult<GetMoveOutRatesQuery, GetMoveOutRatesQueryVariables>;
export const GetOccupancyRatesDocument = gql`
    query GetOccupancyRates($propertyId: String!, $year: Int!, $versionYear: Int!, $budgetYear: Int!, $versionType: VersionType) {
  actuals: valueVersions(
    where: {AND: [{year: {lte: $year}}, {type: {equals: ACTUALS}}]}
  ) {
    id
    name
    type
    year
    unitTypeScheduleValues(
      where: {propertyId: {equals: $propertyId}, unitType: {is: {budgetYear: {equals: $budgetYear}}}}
    ) {
      id
      monthIndex
      unitType {
        id
        name
      }
      occupancyRate
      unitCount
    }
  }
  budget: valueVersions(
    where: {AND: [{year: {equals: $budgetYear}}, {type: {equals: BUDGET}}]}
  ) {
    id
    name
    type
    year
    unitTypeScheduleValues(
      where: {propertyId: {equals: $propertyId}, unitType: {is: {budgetYear: {equals: $budgetYear}}}}
    ) {
      id
      monthIndex
      unitType {
        id
        name
      }
      occupancyRate
      unitCount
    }
  }
  currentVersion: valueVersions(
    where: {AND: [{year: {equals: $versionYear}}, {type: {equals: $versionType}}]}
  ) {
    id
    name
    type
    year
    unitTypeScheduleValues(
      where: {propertyId: {equals: $propertyId}, unitType: {is: {budgetYear: {equals: $budgetYear}}}}
    ) {
      id
      monthIndex
      unitType {
        id
        name
      }
      occupancyRate
      unitCount
    }
  }
  reforecastVersion: valueVersions(
    where: {AND: [{year: {equals: $year}}, {type: {equals: REFORECAST}}]}
  ) {
    id
    name
    type
    year
    unitTypeScheduleValues(
      where: {propertyId: {equals: $propertyId}, unitType: {is: {budgetYear: {equals: $budgetYear}}}}
    ) {
      id
      monthIndex
      unitType {
        id
        name
      }
      occupancyRate
      unitCount
    }
  }
}
    `;

/**
 * __useGetOccupancyRatesQuery__
 *
 * To run a query within a React component, call `useGetOccupancyRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOccupancyRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOccupancyRatesQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      year: // value for 'year'
 *      versionYear: // value for 'versionYear'
 *      budgetYear: // value for 'budgetYear'
 *      versionType: // value for 'versionType'
 *   },
 * });
 */
export function useGetOccupancyRatesQuery(baseOptions: Apollo.QueryHookOptions<GetOccupancyRatesQuery, GetOccupancyRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOccupancyRatesQuery, GetOccupancyRatesQueryVariables>(GetOccupancyRatesDocument, options);
      }
export function useGetOccupancyRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOccupancyRatesQuery, GetOccupancyRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOccupancyRatesQuery, GetOccupancyRatesQueryVariables>(GetOccupancyRatesDocument, options);
        }
export type GetOccupancyRatesQueryHookResult = ReturnType<typeof useGetOccupancyRatesQuery>;
export type GetOccupancyRatesLazyQueryHookResult = ReturnType<typeof useGetOccupancyRatesLazyQuery>;
export type GetOccupancyRatesQueryResult = Apollo.QueryResult<GetOccupancyRatesQuery, GetOccupancyRatesQueryVariables>;
export const GetOriginalRevenuePropertyRenewalIncreasePercentDocument = gql`
    query GetOriginalRevenuePropertyRenewalIncreasePercent($propertyId: ID!, $budgetYear: Int!) {
  originalRevenuePropertyRenewalIncreasePercent(
    propertyId: $propertyId
    budgetYear: $budgetYear
  ) {
    type
    percent
  }
}
    `;

/**
 * __useGetOriginalRevenuePropertyRenewalIncreasePercentQuery__
 *
 * To run a query within a React component, call `useGetOriginalRevenuePropertyRenewalIncreasePercentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOriginalRevenuePropertyRenewalIncreasePercentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOriginalRevenuePropertyRenewalIncreasePercentQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *   },
 * });
 */
export function useGetOriginalRevenuePropertyRenewalIncreasePercentQuery(baseOptions: Apollo.QueryHookOptions<GetOriginalRevenuePropertyRenewalIncreasePercentQuery, GetOriginalRevenuePropertyRenewalIncreasePercentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOriginalRevenuePropertyRenewalIncreasePercentQuery, GetOriginalRevenuePropertyRenewalIncreasePercentQueryVariables>(GetOriginalRevenuePropertyRenewalIncreasePercentDocument, options);
      }
export function useGetOriginalRevenuePropertyRenewalIncreasePercentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOriginalRevenuePropertyRenewalIncreasePercentQuery, GetOriginalRevenuePropertyRenewalIncreasePercentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOriginalRevenuePropertyRenewalIncreasePercentQuery, GetOriginalRevenuePropertyRenewalIncreasePercentQueryVariables>(GetOriginalRevenuePropertyRenewalIncreasePercentDocument, options);
        }
export type GetOriginalRevenuePropertyRenewalIncreasePercentQueryHookResult = ReturnType<typeof useGetOriginalRevenuePropertyRenewalIncreasePercentQuery>;
export type GetOriginalRevenuePropertyRenewalIncreasePercentLazyQueryHookResult = ReturnType<typeof useGetOriginalRevenuePropertyRenewalIncreasePercentLazyQuery>;
export type GetOriginalRevenuePropertyRenewalIncreasePercentQueryResult = Apollo.QueryResult<GetOriginalRevenuePropertyRenewalIncreasePercentQuery, GetOriginalRevenuePropertyRenewalIncreasePercentQueryVariables>;
export const SetOriginalRevenuePropertyRenewalIncreasePercentDocument = gql`
    mutation SetOriginalRevenuePropertyRenewalIncreasePercent($propertyId: ID!, $budgetYear: Int!, $type: OriginalRevenueRenewalIncreaseType, $percent: Float) {
  setOriginalRevenuePropertyRenewalIncreasePercent(
    propertyId: $propertyId
    budgetYear: $budgetYear
    type: $type
    percent: $percent
  )
}
    `;
export type SetOriginalRevenuePropertyRenewalIncreasePercentMutationFn = Apollo.MutationFunction<SetOriginalRevenuePropertyRenewalIncreasePercentMutation, SetOriginalRevenuePropertyRenewalIncreasePercentMutationVariables>;

/**
 * __useSetOriginalRevenuePropertyRenewalIncreasePercentMutation__
 *
 * To run a mutation, you first call `useSetOriginalRevenuePropertyRenewalIncreasePercentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOriginalRevenuePropertyRenewalIncreasePercentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOriginalRevenuePropertyRenewalIncreasePercentMutation, { data, loading, error }] = useSetOriginalRevenuePropertyRenewalIncreasePercentMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *      type: // value for 'type'
 *      percent: // value for 'percent'
 *   },
 * });
 */
export function useSetOriginalRevenuePropertyRenewalIncreasePercentMutation(baseOptions?: Apollo.MutationHookOptions<SetOriginalRevenuePropertyRenewalIncreasePercentMutation, SetOriginalRevenuePropertyRenewalIncreasePercentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOriginalRevenuePropertyRenewalIncreasePercentMutation, SetOriginalRevenuePropertyRenewalIncreasePercentMutationVariables>(SetOriginalRevenuePropertyRenewalIncreasePercentDocument, options);
      }
export type SetOriginalRevenuePropertyRenewalIncreasePercentMutationHookResult = ReturnType<typeof useSetOriginalRevenuePropertyRenewalIncreasePercentMutation>;
export type SetOriginalRevenuePropertyRenewalIncreasePercentMutationResult = Apollo.MutationResult<SetOriginalRevenuePropertyRenewalIncreasePercentMutation>;
export type SetOriginalRevenuePropertyRenewalIncreasePercentMutationOptions = Apollo.BaseMutationOptions<SetOriginalRevenuePropertyRenewalIncreasePercentMutation, SetOriginalRevenuePropertyRenewalIncreasePercentMutationVariables>;
export const GetCappedRenewalIncreaseDocument = gql`
    query GetCappedRenewalIncrease($propertyId: String!, $thisYearM3: Int!, $thisYearM2: Int!, $thisYearM1: Int!, $thisYear: Int!, $nextYear: Int!) {
  property(where: {id: $propertyId}) {
    unitTypes {
      id
      name
      budgetYear
      nextYearBudget: renewalIncrease(versionType: BUDGET, year: $nextYear) {
        year
        versionType
        amounts
        percents
        inPlaceRents
      }
      thisYearReforecast: renewalIncrease(versionType: REFORECAST, year: $thisYear) {
        year
        versionType
        amounts
        percents
        inPlaceRents
      }
      thisYearActuals: renewalIncrease(versionType: ACTUALS, year: $thisYear) {
        year
        versionType
        amounts
        percents
        inPlaceRents
      }
    }
    nextYearBudget: renewalIncrease(versionType: BUDGET, year: $nextYear) {
      year
      versionType
      amounts
      percents
      inPlaceRents
    }
    thisYearBudget: renewalIncrease(versionType: BUDGET, year: $thisYear) {
      year
      versionType
      amounts
      percents
      inPlaceRents
    }
    thisYearReforecast: renewalIncrease(versionType: REFORECAST, year: $thisYear) {
      year
      versionType
      amounts
      percents
      inPlaceRents
    }
    thisYearActuals: renewalIncrease(versionType: ACTUALS, year: $thisYear) {
      year
      versionType
      amounts
      percents
      inPlaceRents
    }
    thisYearM1Actuals: renewalIncrease(versionType: ACTUALS, year: $thisYearM1) {
      year
      versionType
      amounts
      percents
      inPlaceRents
    }
    thisYearM2Actuals: renewalIncrease(versionType: ACTUALS, year: $thisYearM2) {
      year
      versionType
      amounts
      percents
      inPlaceRents
    }
    thisYearM3Actuals: renewalIncrease(versionType: ACTUALS, year: $thisYearM3) {
      year
      versionType
      amounts
      percents
      inPlaceRents
    }
  }
}
    `;

/**
 * __useGetCappedRenewalIncreaseQuery__
 *
 * To run a query within a React component, call `useGetCappedRenewalIncreaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCappedRenewalIncreaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCappedRenewalIncreaseQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      thisYearM3: // value for 'thisYearM3'
 *      thisYearM2: // value for 'thisYearM2'
 *      thisYearM1: // value for 'thisYearM1'
 *      thisYear: // value for 'thisYear'
 *      nextYear: // value for 'nextYear'
 *   },
 * });
 */
export function useGetCappedRenewalIncreaseQuery(baseOptions: Apollo.QueryHookOptions<GetCappedRenewalIncreaseQuery, GetCappedRenewalIncreaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCappedRenewalIncreaseQuery, GetCappedRenewalIncreaseQueryVariables>(GetCappedRenewalIncreaseDocument, options);
      }
export function useGetCappedRenewalIncreaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCappedRenewalIncreaseQuery, GetCappedRenewalIncreaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCappedRenewalIncreaseQuery, GetCappedRenewalIncreaseQueryVariables>(GetCappedRenewalIncreaseDocument, options);
        }
export type GetCappedRenewalIncreaseQueryHookResult = ReturnType<typeof useGetCappedRenewalIncreaseQuery>;
export type GetCappedRenewalIncreaseLazyQueryHookResult = ReturnType<typeof useGetCappedRenewalIncreaseLazyQuery>;
export type GetCappedRenewalIncreaseQueryResult = Apollo.QueryResult<GetCappedRenewalIncreaseQuery, GetCappedRenewalIncreaseQueryVariables>;
export const GetRenewalRateCountsDocument = gql`
    query GetRenewalRateCounts($propertyId: String!, $year: Int!, $versionYear: Int!, $budgetYear: Int!, $versionType: VersionType) {
  actuals: valueVersions(
    where: {AND: [{year: {lte: $year}}, {type: {equals: ACTUALS}}]}
  ) {
    id
    name
    type
    year
    unitTypeScheduleValues(
      where: {propertyId: {equals: $propertyId}, unitType: {is: {budgetYear: {equals: $budgetYear}}}}
    ) {
      id
      monthIndex
      unitType {
        id
        name
      }
      renewalRate
      leaseExpirationCount
      renewalRatio
      unitCount
    }
  }
  budget: valueVersions(
    where: {AND: [{year: {equals: $budgetYear}}, {type: {equals: BUDGET}}]}
  ) {
    id
    name
    type
    year
    unitTypeScheduleValues(
      where: {propertyId: {equals: $propertyId}, unitType: {is: {budgetYear: {equals: $budgetYear}}}}
    ) {
      id
      monthIndex
      unitType {
        id
        name
      }
      renewalRate
      leaseExpirationCount
      renewalRatio
      unitCount
    }
  }
  currentVersion: valueVersions(
    where: {AND: [{year: {equals: $versionYear}}, {type: {equals: $versionType}}]}
  ) {
    id
    name
    type
    year
    unitTypeScheduleValues(
      where: {propertyId: {equals: $propertyId}, unitType: {is: {budgetYear: {equals: $budgetYear}}}}
    ) {
      id
      monthIndex
      unitType {
        id
        name
      }
      renewalRate
      leaseExpirationCount
      renewalRatio
      unitCount
    }
  }
  reforecastVersion: valueVersions(
    where: {AND: [{year: {equals: $year}}, {type: {equals: REFORECAST}}]}
  ) {
    id
    name
    type
    year
    unitTypeScheduleValues(
      where: {propertyId: {equals: $propertyId}, unitType: {is: {budgetYear: {equals: $budgetYear}}}}
    ) {
      id
      monthIndex
      unitType {
        id
        name
      }
      renewalRate
      leaseExpirationCount
      renewalRatio
      unitCount
    }
  }
}
    `;

/**
 * __useGetRenewalRateCountsQuery__
 *
 * To run a query within a React component, call `useGetRenewalRateCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRenewalRateCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRenewalRateCountsQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      year: // value for 'year'
 *      versionYear: // value for 'versionYear'
 *      budgetYear: // value for 'budgetYear'
 *      versionType: // value for 'versionType'
 *   },
 * });
 */
export function useGetRenewalRateCountsQuery(baseOptions: Apollo.QueryHookOptions<GetRenewalRateCountsQuery, GetRenewalRateCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRenewalRateCountsQuery, GetRenewalRateCountsQueryVariables>(GetRenewalRateCountsDocument, options);
      }
export function useGetRenewalRateCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRenewalRateCountsQuery, GetRenewalRateCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRenewalRateCountsQuery, GetRenewalRateCountsQueryVariables>(GetRenewalRateCountsDocument, options);
        }
export type GetRenewalRateCountsQueryHookResult = ReturnType<typeof useGetRenewalRateCountsQuery>;
export type GetRenewalRateCountsLazyQueryHookResult = ReturnType<typeof useGetRenewalRateCountsLazyQuery>;
export type GetRenewalRateCountsQueryResult = Apollo.QueryResult<GetRenewalRateCountsQuery, GetRenewalRateCountsQueryVariables>;
export const GetRenewalRatioCountDocument = gql`
    query GetRenewalRatioCount($propertyId: String!, $year: Int!, $versionYear: Int!, $budgetYear: Int!, $versionType: VersionType) {
  actuals: valueVersions(
    where: {AND: [{year: {lte: $year}}, {type: {equals: ACTUALS}}]}
  ) {
    id
    name
    type
    year
    unitTypeScheduleValues(
      where: {propertyId: {equals: $propertyId}, unitType: {is: {budgetYear: {equals: $budgetYear}}}}
    ) {
      id
      monthIndex
      unitType {
        id
        name
      }
      renewalRatio
      leaseExpirationCount
      unitCount
    }
  }
  budget: valueVersions(
    where: {AND: [{year: {equals: $budgetYear}}, {type: {equals: BUDGET}}]}
  ) {
    id
    name
    type
    year
    unitTypeScheduleValues(
      where: {propertyId: {equals: $propertyId}, unitType: {is: {budgetYear: {equals: $budgetYear}}}}
    ) {
      id
      monthIndex
      unitType {
        id
        name
      }
      renewalRatio
      leaseExpirationCount
      unitCount
    }
  }
  currentVersion: valueVersions(
    where: {AND: [{year: {equals: $versionYear}}, {type: {equals: $versionType}}]}
  ) {
    id
    name
    type
    year
    unitTypeScheduleValues(
      where: {propertyId: {equals: $propertyId}, unitType: {is: {budgetYear: {equals: $budgetYear}}}}
    ) {
      id
      monthIndex
      unitType {
        id
        name
      }
      renewalRatio
      leaseExpirationCount
      unitCount
    }
  }
  reforecastVersion: valueVersions(
    where: {AND: [{year: {equals: $year}}, {type: {equals: REFORECAST}}]}
  ) {
    id
    name
    type
    year
    unitTypeScheduleValues(
      where: {propertyId: {equals: $propertyId}, unitType: {is: {budgetYear: {equals: $budgetYear}}}}
    ) {
      id
      monthIndex
      unitType {
        id
        name
      }
      renewalRatio
      leaseExpirationCount
      unitCount
    }
  }
}
    `;

/**
 * __useGetRenewalRatioCountQuery__
 *
 * To run a query within a React component, call `useGetRenewalRatioCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRenewalRatioCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRenewalRatioCountQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      year: // value for 'year'
 *      versionYear: // value for 'versionYear'
 *      budgetYear: // value for 'budgetYear'
 *      versionType: // value for 'versionType'
 *   },
 * });
 */
export function useGetRenewalRatioCountQuery(baseOptions: Apollo.QueryHookOptions<GetRenewalRatioCountQuery, GetRenewalRatioCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRenewalRatioCountQuery, GetRenewalRatioCountQueryVariables>(GetRenewalRatioCountDocument, options);
      }
export function useGetRenewalRatioCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRenewalRatioCountQuery, GetRenewalRatioCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRenewalRatioCountQuery, GetRenewalRatioCountQueryVariables>(GetRenewalRatioCountDocument, options);
        }
export type GetRenewalRatioCountQueryHookResult = ReturnType<typeof useGetRenewalRatioCountQuery>;
export type GetRenewalRatioCountLazyQueryHookResult = ReturnType<typeof useGetRenewalRatioCountLazyQuery>;
export type GetRenewalRatioCountQueryResult = Apollo.QueryResult<GetRenewalRatioCountQuery, GetRenewalRatioCountQueryVariables>;
export const GetRevenueSummaryUnitTypeDocument = gql`
    query GetRevenueSummaryUnitType($propertyId: ID!, $budgetYear: Int!) {
  originalRevenueModel(propertyId: $propertyId, budgetYear: $budgetYear) {
    propertyId
    budgetYear
    earlyTerminationCount {
      actuals
      reforecast
      budget
    }
    beginningGrossPotentialRent
    beginningOccupancy
    grossPotentialRent {
      actuals
      reforecast
      budget
    }
    grossPotentialRentAndVacancyLoss {
      actuals
      reforecast
      budget
    }
    lossToLease {
      actuals
      reforecast
      budget
    }
    m2mMoveOutCount {
      actuals
      reforecast
      budget
    }
    momLossToLeaseChange {
      actuals
      reforecast
      budget
    }
    rent {
      actuals
      reforecast
      budget
    }
    vacancyLoss {
      actuals
      reforecast
      budget
    }
    unitTypeAvgMarketRent {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeBeginningOccupancy {
      unitTypeId
      unitTypeName
      value
    }
    unitTypeExpirationCount {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeInPlaceRent {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    totalMoveInCount {
      actuals
      reforecast
      budget
    }
    totalMoveOutCount {
      actuals
      reforecast
      budget
    }
    unitTypeMoveInCount {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeMoveOutCount {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeNewLeaseTradeOut {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeOccupiedCount {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeRenewalCount {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeRenewalTradeOut {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeStartingInPlaceRent {
      unitTypeId
      unitTypeName
      value
    }
    unitTypeUnitCount {
      unitTypeId
      unitTypeName
      value
    }
    unitTypeUnitCountMonthly {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
    unitTypeVacancyCount {
      unitTypeId
      unitTypeName
      actuals
      reforecast
      budget
    }
  }
}
    `;

/**
 * __useGetRevenueSummaryUnitTypeQuery__
 *
 * To run a query within a React component, call `useGetRevenueSummaryUnitTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRevenueSummaryUnitTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRevenueSummaryUnitTypeQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      budgetYear: // value for 'budgetYear'
 *   },
 * });
 */
export function useGetRevenueSummaryUnitTypeQuery(baseOptions: Apollo.QueryHookOptions<GetRevenueSummaryUnitTypeQuery, GetRevenueSummaryUnitTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRevenueSummaryUnitTypeQuery, GetRevenueSummaryUnitTypeQueryVariables>(GetRevenueSummaryUnitTypeDocument, options);
      }
export function useGetRevenueSummaryUnitTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRevenueSummaryUnitTypeQuery, GetRevenueSummaryUnitTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRevenueSummaryUnitTypeQuery, GetRevenueSummaryUnitTypeQueryVariables>(GetRevenueSummaryUnitTypeDocument, options);
        }
export type GetRevenueSummaryUnitTypeQueryHookResult = ReturnType<typeof useGetRevenueSummaryUnitTypeQuery>;
export type GetRevenueSummaryUnitTypeLazyQueryHookResult = ReturnType<typeof useGetRevenueSummaryUnitTypeLazyQuery>;
export type GetRevenueSummaryUnitTypeQueryResult = Apollo.QueryResult<GetRevenueSummaryUnitTypeQuery, GetRevenueSummaryUnitTypeQueryVariables>;
export const UpsertOpMetricValuesDocument = gql`
    mutation UpsertOpMetricValues($propertyId: String!, $versionId: String!, $fieldName: String!, $monthIndexes: [Int!]!, $values: [Decimal!]!) {
  upsertOpMetricValues(
    propertyId: $propertyId
    versionId: $versionId
    fieldName: $fieldName
    monthIndexes: $monthIndexes
    values: $values
  )
}
    `;
export type UpsertOpMetricValuesMutationFn = Apollo.MutationFunction<UpsertOpMetricValuesMutation, UpsertOpMetricValuesMutationVariables>;

/**
 * __useUpsertOpMetricValuesMutation__
 *
 * To run a mutation, you first call `useUpsertOpMetricValuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOpMetricValuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOpMetricValuesMutation, { data, loading, error }] = useUpsertOpMetricValuesMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      versionId: // value for 'versionId'
 *      fieldName: // value for 'fieldName'
 *      monthIndexes: // value for 'monthIndexes'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useUpsertOpMetricValuesMutation(baseOptions?: Apollo.MutationHookOptions<UpsertOpMetricValuesMutation, UpsertOpMetricValuesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertOpMetricValuesMutation, UpsertOpMetricValuesMutationVariables>(UpsertOpMetricValuesDocument, options);
      }
export type UpsertOpMetricValuesMutationHookResult = ReturnType<typeof useUpsertOpMetricValuesMutation>;
export type UpsertOpMetricValuesMutationResult = Apollo.MutationResult<UpsertOpMetricValuesMutation>;
export type UpsertOpMetricValuesMutationOptions = Apollo.BaseMutationOptions<UpsertOpMetricValuesMutation, UpsertOpMetricValuesMutationVariables>;
export const UpsertUnitTypeScheduleValuesDocument = gql`
    mutation UpsertUnitTypeScheduleValues($propertyId: String!, $versionId: String!, $fieldName: String!, $values: [UnitTypeScheduleValueUpsertInput!]!) {
  upsertUnitTypeScheduleValues(
    propertyId: $propertyId
    versionId: $versionId
    fieldName: $fieldName
    values: $values
  )
}
    `;
export type UpsertUnitTypeScheduleValuesMutationFn = Apollo.MutationFunction<UpsertUnitTypeScheduleValuesMutation, UpsertUnitTypeScheduleValuesMutationVariables>;

/**
 * __useUpsertUnitTypeScheduleValuesMutation__
 *
 * To run a mutation, you first call `useUpsertUnitTypeScheduleValuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUnitTypeScheduleValuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUnitTypeScheduleValuesMutation, { data, loading, error }] = useUpsertUnitTypeScheduleValuesMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      versionId: // value for 'versionId'
 *      fieldName: // value for 'fieldName'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useUpsertUnitTypeScheduleValuesMutation(baseOptions?: Apollo.MutationHookOptions<UpsertUnitTypeScheduleValuesMutation, UpsertUnitTypeScheduleValuesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertUnitTypeScheduleValuesMutation, UpsertUnitTypeScheduleValuesMutationVariables>(UpsertUnitTypeScheduleValuesDocument, options);
      }
export type UpsertUnitTypeScheduleValuesMutationHookResult = ReturnType<typeof useUpsertUnitTypeScheduleValuesMutation>;
export type UpsertUnitTypeScheduleValuesMutationResult = Apollo.MutationResult<UpsertUnitTypeScheduleValuesMutation>;
export type UpsertUnitTypeScheduleValuesMutationOptions = Apollo.BaseMutationOptions<UpsertUnitTypeScheduleValuesMutation, UpsertUnitTypeScheduleValuesMutationVariables>;
export const AcctRenovationPackagesDocument = gql`
    query acctRenovationPackages($propertyIds: [String!]!, $yearAndType: YearAndType!) {
  queryRenovationPackages(propertyIds: $propertyIds) {
    id
    name
    premium
    propertyId
    unitTypeId
    renovationNumbers(yearAndTypes: [$yearAndType]) {
      year
      versionType
      expected {
        monthIndex
        value
      }
    }
  }
}
    `;

/**
 * __useAcctRenovationPackagesQuery__
 *
 * To run a query within a React component, call `useAcctRenovationPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcctRenovationPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcctRenovationPackagesQuery({
 *   variables: {
 *      propertyIds: // value for 'propertyIds'
 *      yearAndType: // value for 'yearAndType'
 *   },
 * });
 */
export function useAcctRenovationPackagesQuery(baseOptions: Apollo.QueryHookOptions<AcctRenovationPackagesQuery, AcctRenovationPackagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AcctRenovationPackagesQuery, AcctRenovationPackagesQueryVariables>(AcctRenovationPackagesDocument, options);
      }
export function useAcctRenovationPackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AcctRenovationPackagesQuery, AcctRenovationPackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AcctRenovationPackagesQuery, AcctRenovationPackagesQueryVariables>(AcctRenovationPackagesDocument, options);
        }
export type AcctRenovationPackagesQueryHookResult = ReturnType<typeof useAcctRenovationPackagesQuery>;
export type AcctRenovationPackagesLazyQueryHookResult = ReturnType<typeof useAcctRenovationPackagesLazyQuery>;
export type AcctRenovationPackagesQueryResult = Apollo.QueryResult<AcctRenovationPackagesQuery, AcctRenovationPackagesQueryVariables>;
export const GetAcctRenoMoveOutRatesDocument = gql`
    query GetAcctRenoMoveOutRates($propertyId: String!, $year: Int!, $budgetYear: Int!, $unitTypeId: String!, $getReforecast: Boolean!) {
  actuals: valueVersions(
    where: {AND: [{year: {equals: $year}}, {type: {equals: ACTUALS}}]}
  ) @include(if: $getReforecast) {
    id
    name
    type
    year
    unitTypeScheduleValues(
      where: {unitTypeId: {equals: $unitTypeId}, propertyId: {equals: $propertyId}}
    ) {
      id
      monthIndex
      moveOutRate
      leaseExpirationCount
    }
  }
  reforecast: valueVersions(
    where: {AND: [{year: {equals: $year}}, {type: {equals: REFORECAST}}]}
  ) @include(if: $getReforecast) {
    id
    name
    type
    year
    unitTypeScheduleValues(
      where: {unitTypeId: {equals: $unitTypeId}, propertyId: {equals: $propertyId}}
    ) {
      id
      monthIndex
      moveOutRate
      leaseExpirationCount
    }
  }
  budget: valueVersions(
    where: {AND: [{year: {equals: $budgetYear}}, {type: {equals: BUDGET}}]}
  ) @skip(if: $getReforecast) {
    id
    name
    type
    year
    unitTypeScheduleValues(
      where: {unitTypeId: {equals: $unitTypeId}, propertyId: {equals: $propertyId}}
    ) {
      id
      monthIndex
      moveOutRate
      leaseExpirationCount
    }
  }
}
    `;

/**
 * __useGetAcctRenoMoveOutRatesQuery__
 *
 * To run a query within a React component, call `useGetAcctRenoMoveOutRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAcctRenoMoveOutRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAcctRenoMoveOutRatesQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      year: // value for 'year'
 *      budgetYear: // value for 'budgetYear'
 *      unitTypeId: // value for 'unitTypeId'
 *      getReforecast: // value for 'getReforecast'
 *   },
 * });
 */
export function useGetAcctRenoMoveOutRatesQuery(baseOptions: Apollo.QueryHookOptions<GetAcctRenoMoveOutRatesQuery, GetAcctRenoMoveOutRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAcctRenoMoveOutRatesQuery, GetAcctRenoMoveOutRatesQueryVariables>(GetAcctRenoMoveOutRatesDocument, options);
      }
export function useGetAcctRenoMoveOutRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAcctRenoMoveOutRatesQuery, GetAcctRenoMoveOutRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAcctRenoMoveOutRatesQuery, GetAcctRenoMoveOutRatesQueryVariables>(GetAcctRenoMoveOutRatesDocument, options);
        }
export type GetAcctRenoMoveOutRatesQueryHookResult = ReturnType<typeof useGetAcctRenoMoveOutRatesQuery>;
export type GetAcctRenoMoveOutRatesLazyQueryHookResult = ReturnType<typeof useGetAcctRenoMoveOutRatesLazyQuery>;
export type GetAcctRenoMoveOutRatesQueryResult = Apollo.QueryResult<GetAcctRenoMoveOutRatesQuery, GetAcctRenoMoveOutRatesQueryVariables>;
export const UpdateRenovationPackageCountDocument = gql`
    mutation updateRenovationPackageCount($renovationPackageId: String!, $yearAndType: YearAndType!, $expected: [RenovationNumberInput!]!) {
  updateRenovationsNumbersByYearAndType(
    renovationPackageId: $renovationPackageId
    yearAndType: $yearAndType
    expected: $expected
  ) {
    renovationPackageId
    year
    versionType
    expected {
      monthIndex
      value
    }
  }
}
    `;
export type UpdateRenovationPackageCountMutationFn = Apollo.MutationFunction<UpdateRenovationPackageCountMutation, UpdateRenovationPackageCountMutationVariables>;

/**
 * __useUpdateRenovationPackageCountMutation__
 *
 * To run a mutation, you first call `useUpdateRenovationPackageCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRenovationPackageCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRenovationPackageCountMutation, { data, loading, error }] = useUpdateRenovationPackageCountMutation({
 *   variables: {
 *      renovationPackageId: // value for 'renovationPackageId'
 *      yearAndType: // value for 'yearAndType'
 *      expected: // value for 'expected'
 *   },
 * });
 */
export function useUpdateRenovationPackageCountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRenovationPackageCountMutation, UpdateRenovationPackageCountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRenovationPackageCountMutation, UpdateRenovationPackageCountMutationVariables>(UpdateRenovationPackageCountDocument, options);
      }
export type UpdateRenovationPackageCountMutationHookResult = ReturnType<typeof useUpdateRenovationPackageCountMutation>;
export type UpdateRenovationPackageCountMutationResult = Apollo.MutationResult<UpdateRenovationPackageCountMutation>;
export type UpdateRenovationPackageCountMutationOptions = Apollo.BaseMutationOptions<UpdateRenovationPackageCountMutation, UpdateRenovationPackageCountMutationVariables>;