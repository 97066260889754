/* eslint-disable @typescript-eslint/no-empty-function */
import React, {useContext, useEffect, useState} from "react";
import {
    OriginalRevenueMarketRentModeType,
    PropertyManagementSystem,
    RevenueModelType,
    useGetUnitsCountLazyQuery
} from "../../__generated__/generated_types";
import {AuthContext, AuthUser} from "../AuthContext";
import { GuestAuthenticationContext } from "../guest-auth-context/GuestAuthContext";

const PROPERTY_ID_KEY = 'vizibly-property-id';

export interface Property {
    id: string;
    name: string;
    systemName: string;
    unitLevelModelingEnabled: boolean;
    address?: string | null;
    externalId?: string | null;
    code?: string | null;
    fiscalYearStartMonthIndex: number;
    reforecastStartMonthIndex: number;
    reforecastYear: number;
    budgetYear: number;
    revenueModel: RevenueModelType;
    originalRevenueMarketRentModeType: OriginalRevenueMarketRentModeType;
    propertyManagementSystem?: PropertyManagementSystem | null;
}

export type UnitType = {
    id: string,
    type: string,
}

export interface PropertiesState {
    properties: Property[] | undefined;
    currentProperty: Property | undefined;
    propertyId: string;
    chooseProperty: (propertyId: string) => void;
    loading: boolean;
    loaded: boolean;
    unitsCount: number;
    unitsTotalSQFT: number;
    unitTypes: UnitType[];
    unitTypesById: Record<string, UnitType>;
}

const PropertiesStateContext = React.createContext<PropertiesState | undefined>(undefined);

function usePropertiesState() {
    const { user } = useContext(AuthContext);
    const [properties, setProperties] = useState<Property[]>(buildPropertiesFromUser(user));
    const [currentProperty, setCurrentProperty] = useState<Property>();
    const [currentPropertyId, setCurrentPropertyId] = useState<string | undefined>(() => localStorage.getItem(PROPERTY_ID_KEY) || undefined);
    const [unitsCount, setUnitsCount] = useState<number>();
    const [unitsTotalSQFT, setUnitsTotalSQFT] = useState<number>();
    const [unitTypes, setUnitTypes] = useState<UnitType[]>();
    const [unitTypesById, setUnitTypesById] = useState<Record<string, UnitType>>({});
    const [getUnitsCount, { data: unitsCountData, loading: unitsCountLoading}] = useGetUnitsCountLazyQuery();
    const [isDataLoaded, setDataLoaded] = useState<boolean>();

    function buildPropertiesFromUser(user: AuthUser | null): Property[] {
        return user?.properties.map(p => ({...p, budgetYear: p.reforecastYear + 1, name: p.displayName ? p.displayName : p.name, systemName: p.name})).sortBy("name") ?? [];
    }

    function choosePropertyInternal(propertyId: string, properties: Property[]) {
        const chosenProperty = properties?.find(property => property.id === propertyId);
        const chosenPropertyId = chosenProperty?.id ?? '';
        setCurrentProperty(chosenProperty);
        setCurrentPropertyId(chosenPropertyId);
        getUnitsCount({variables: {propertyId: propertyId}});
        localStorage.setItem(PROPERTY_ID_KEY, chosenPropertyId);
    }

    function chooseProperty(propertyId: string): void {
        if (properties) {
            choosePropertyInternal(propertyId, properties);
        }
    }

    useEffect(() => {
        if(currentPropertyId) {
            chooseProperty(currentPropertyId);
        } else if(properties.length > 0 && properties[0]) {
            chooseProperty(properties[0].id);
        }
    }, [properties]);

    useEffect(
        () => {
            if (unitsCountData && !unitsCountLoading) {
                setUnitsCount(unitsCountData.propertyUnitCounts.map(row => row.count).sum());
                setUnitsTotalSQFT(unitsCountData.propertyUnitCounts.map(row => row.sqft).sum());
                const unitTypes = unitsCountData.propertyUnitCounts.map(v => ({id: v.unitTypeId, type: v.unitTypeName} as UnitType));
                const unitTypesById = unitTypes.toIdMap("id");
                setUnitTypes(unitTypes);
                setUnitTypesById(unitTypesById);
            }
        },
        [unitsCountData, unitsCountLoading]
    );

    useEffect(
        () => {
            const dataLoaded = (properties?.length ?? 0) > 0 && unitsCountData && !unitsCountLoading && unitTypes != undefined;
            if (dataLoaded) {
                if(currentPropertyId && properties.map(p => p.id).includes(currentPropertyId)) {
                    chooseProperty(currentPropertyId);
                } else if(properties.length > 0 && properties[0]) {
                    chooseProperty(properties[0].id);
                }
            }
            setDataLoaded(dataLoaded);
        },
        [unitsCountData, unitsCountLoading, unitTypes, properties?.length]
    );

    useEffect(
        () => {
            setProperties(buildPropertiesFromUser(user));
        },
        [user]
    )

    return {
        properties,
        currentProperty,
        chooseProperty,
        loading: unitsCountLoading,
        unitsCount,
        unitsTotalSQFT,
        unitTypes,
        unitTypesById,
        loaded: isDataLoaded
    };
}

export const PropertiesProvider: React.FunctionComponent = ({ children }) => {
    const { properties, currentProperty, chooseProperty, loading, unitsCount, unitsTotalSQFT, unitTypes, unitTypesById, loaded } = usePropertiesState();

    const value: PropertiesState = {
        properties,
        currentProperty,
        chooseProperty,
        propertyId: currentProperty?.id ?? "",
        loading,
        unitsCount: unitsCount ?? 0,
        unitsTotalSQFT: unitsTotalSQFT ?? 0,
        unitTypes: unitTypes ?? [],
        loaded: loaded ?? false,
        unitTypesById
    };

    return (
        <PropertiesStateContext.Provider value={value}>
            {children}
        </PropertiesStateContext.Provider>
    );
};

export function useProperties(): PropertiesState {
    const context = React.useContext(PropertiesStateContext);
    const guestAuthContext = React.useContext(GuestAuthenticationContext);

    if (context) {
        return context;
    }

    if (guestAuthContext) {
        return {
            get properties(): Property[] | undefined { throw new Error("Not allowed for guest users"); },
            get currentProperty() { return guestAuthContext.user?.property; },
            get propertyId() { return guestAuthContext.user?.property?.id ?? ""; },
            chooseProperty: (_: string) => { throw new Error("Not allowed for guest users"); },
            get loading(): boolean { throw new Error("Not allowed for guest users"); },
            get unitsCount(): number { throw new Error("Not allowed for guest users"); }, 
            get unitsTotalSQFT(): number { throw new Error("Not allowed for guest users"); },
            get unitTypes(): UnitType[] { throw new Error("Not allowed for guest users"); },
            get loaded(): boolean { throw new Error("Not allowed for guest users"); },
            get unitTypesById(): Record<string, UnitType> { throw new Error("Not allowed for guest users"); }
        };
    }

    throw new Error('useProperties must be used within a PropertiesProvider or GuestAuthenticationContextProvider');
}

/**
 * Simple function that given a property will tell you if it has the given revenue model.
 * If the property is undefined or null, because it isn't available yet for some reason, this
 * will default to saying the property has the original model.
 *
 * @param property Property object.
 * @param revenueModel The revenue model type to check if the property has.
 */
export function propertyHasRevenueModel(property: Property | undefined | null, revenueModel: RevenueModelType): boolean {
    if(property === undefined || property === null) {
        return revenueModel === RevenueModelType.Original;
    }

    return property.revenueModel === revenueModel;
}
