import {FinancialEntityType} from "../../__generated__/generated_types";
import {FinancialEntity} from "../../contexts/chartofaccounts/ChartOfAccountsContext";
import {Property} from "../../contexts/properties/PropertiesContext";
import {TTableRow} from "./types";

export function buildDisplayRows(rows: TTableRow[], properties: Property[], collapsedRowIds: string[], selectedAccountIds: string[]): TTableRow[] {
    const updated: TTableRow[] = [];
    for (const row of rows) {
        const rowCollapsed = collapsedRowIds.includes(row.id);
        let canDisplayRow =
            // always show first level rows (no parents) and first level rows (one parent)
            row.parentIds.length == 0
            && (
                !row.isSubtotalRow
                || row.isSubtotalRow && !rowCollapsed
            )
            || (
                // show rows that have all parents expanded
                !row.parentIds.some(id => collapsedRowIds.includes(id))
                && (
                    !row.isSubtotalRow
                    || row.isSubtotalRow && !rowCollapsed
                )
            );

        canDisplayRow = canDisplayRow && selectedAccountIds.includes(row.id);

        if (canDisplayRow) {
            updated.push(row);
            // add property ids
            if (row.type === FinancialEntityType.Account
                && !row.isSubtotalRow
                && !rowCollapsed) {
                for (const property of properties) {
                    updated.push({
                        id: property.id,
                        name: property.name,
                        parentIds: [row.id, ...row.parentIds],
                        type: FinancialEntityType.Account,
                        isPropertyRow: true,
                        isSubtotalRow: false,
                        reforecastStartMonthIndex: property.reforecastStartMonthIndex,
                    });
                }
            }
        }
    }
    return updated;
}

export function mapFEToTableRow(e: FinancialEntity, parentIds: string[], isSubtotalRow: boolean): TTableRow {
    return {
        id: e.id,
        name: {
            name: e.name,
            number: e.number
        },
        parentIds: [...parentIds],
        type: e.type,
        isPropertyRow: false,
        isSubtotalRow: isSubtotalRow
    };
}

export function addChildren(parent: FinancialEntity, parentIdsIn: string[], result: TTableRow[]): void {
    const parentIds = [...parentIdsIn];
    if (parent.type !== FinancialEntityType.Component) {
        parentIds.unshift(parent.id);
    }
    for (const child of parent.children.sortBy("order")) {
        result.push(mapFEToTableRow(child, parentIds, false));
        addChildren(child, parentIds, result);
        result.push(mapFEToTableRow(child, parentIds, true));
    }
}

export function buildRows(chartOfAccountsTree: FinancialEntity[]): TTableRow[] {
    const rows: TTableRow[] = [];
    for (const record of chartOfAccountsTree.sortBy("order")) {
        const parentIds = [] as string[];
        addChildren(record, parentIds, rows);
        rows.push(mapFEToTableRow(record, [], false));
    }
    return rows;
}

