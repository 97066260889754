import { DraggableProvided } from "react-beautiful-dnd";
import { FinancialEntityType } from "../../../__generated__/generated_types";
import { ItemData } from "../types";
import { Button } from "@zendeskgarden/react-buttons";
import { ReactComponent as GripIcon } from '@zendeskgarden/svg-icons/src/16/grip.svg';
import { ReactComponent as ChevronDownIcon } from '@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg';
import { ReactComponent as ChevronRightIcon } from '@zendeskgarden/svg-icons/src/16/chevron-right-stroke.svg';
import { ReactComponent as PencilIcon } from '@zendeskgarden/svg-icons/src/16/pencil-stroke.svg';
import { ReactComponent as PlusIcon } from '@zendeskgarden/svg-icons/src/16/plus-stroke.svg';
import { ReactComponent as XIcon } from '@zendeskgarden/svg-icons/src/16/x-stroke.svg';
import { ReactComponent as InfoIcon } from '@zendeskgarden/svg-icons/src/16/info-stroke.svg';
import { ReactComponent as AlertIcon } from '@zendeskgarden/svg-icons/src/16/alert-warning-fill.svg';

import React, { ReactElement } from "react";
import * as css from "../styles/coaManagement.module.scss";
import cn from "classnames";
import {Tooltip} from "@zendeskgarden/react-tooltips";

export function getStyle({ provided, style, isDragging }: {
    provided: DraggableProvided,
    style?: React.CSSProperties,
    isDragging?: boolean
}): React.CSSProperties {
    // If you don't want any spacing between your items
    // then you could just return this.
    // I do a little bit of magic to have some nice visual space
    // between the row items
    const combined = {
        ...style,
        ...provided.draggableProps.style
    };

    const marginBottom = 8;
    const withSpacing = {
        ...combined,
        height: isDragging ? combined.height : Number(combined?.height) - marginBottom,
        marginBottom,
    };
    return withSpacing;
}

export type ItemProps = {
    provided: DraggableProvided,
    item?: ItemData,
    style?: React.CSSProperties,
    isDragging?: boolean,
    isDestinationAllowed?: boolean,
    isDeleteDisabled?: boolean,
    isAllDisabled?: boolean,
    isAddingAfter?: boolean,
    isEditing?: boolean,
    isInAlert: boolean,
    hasDeletedChildren?: boolean,
    hasAddedChildren?: boolean,
    handleCollapse?: (item: ItemData, collapsed: boolean) => void,
    onInfoClicked?: (item: ItemData) => void,
    onAddClicked?: (item: ItemData) => void,
    onEditClicked?: (item: ItemData) => void,
    onDeleteClicked?: (item: ItemData) => void,
    index: number
}

export function Item({
    provided,
    item,
    style,
    isDragging,
    isDestinationAllowed,
    isDeleteDisabled,
    isAllDisabled,
    isAddingAfter,
    isEditing,
    isInAlert,
    hasDeletedChildren,
    hasAddedChildren,
    handleCollapse,
    onInfoClicked,
    onAddClicked,
    onEditClicked,
    onDeleteClicked
}: ItemProps): ReactElement {
    if (!item) {
        return <></>;
    }

    return (
        <div
            {...provided.draggableProps}
            ref={provided.innerRef}
            style={{
                ...getStyle({ provided, style, isDragging }),
                paddingLeft: item.type == FinancialEntityType.Component ? "16px" : undefined
            }}
            className={cn(
                css.item,
                hasAddedChildren ? css.hasAddedChildren : undefined,
                hasDeletedChildren ? css.hasDeletedChildren : undefined,
                item.isDeleted ? css.isDeleted : undefined,
                item.isNew ? css.isNew : undefined,
                isDragging ? css.isDragging : undefined,
                isDragging && !isDestinationAllowed ? css.notavailable : undefined,
                item.type == FinancialEntityType.Account ? css.plain : undefined
            )}
        >
            {item.type != FinancialEntityType.Component && <div {...provided.dragHandleProps}><GripIcon /></div>}
            <div
                className={cn(
                    css.content,
                    isAddingAfter ? css.isAdding : undefined,
                    isEditing ? css.isEditing : undefined,
                )}
                style={{paddingLeft: `${item.parentIds.length * 48}px`}}
            >
                <div className={css.chevron}>
                    {item.type != FinancialEntityType.Account &&
                    <Button
                        isBasic
                        onClick={() => handleCollapse?.(item, !item.collapsed)}
                        disabled={isAllDisabled}
                    >
                        {item.collapsed ? <ChevronRightIcon /> : <ChevronDownIcon />}
                    </Button>
                    }
                </div>
                {item.glNumber ? `${item.glNumber} - ` : ""}{item.glName}
                <div
                    className={cn(
                        css.controls,
                        item.type == FinancialEntityType.Component ? css.noDelete : undefined
                    )}
                >
                    {isInAlert &&
                    <Tooltip content="Account not found in PMS">
                        <AlertIcon style={{color: "var(--yellow-200)"}}/>
                    </Tooltip>
                    }
                    <Button
                        isBasic
                        onClick={() => onInfoClicked?.(item)}
                        disabled={isAllDisabled || item.isDeleted}
                    >
                        <InfoIcon />
                    </Button>
                    <Button
                        isBasic
                        onClick={() => onAddClicked?.(item)}
                        disabled={isAllDisabled || item.isDeleted}
                    >
                        <PlusIcon />
                    </Button>
                    {item.type != FinancialEntityType.Component && <Button
                        isBasic
                        onClick={() => onEditClicked?.(item)}
                        disabled={isAllDisabled || item.isDeleted}
                    >
                        <PencilIcon />
                    </Button>
                    }
                    {item.type != FinancialEntityType.Component &&
                        <Button
                            isBasic
                            isDanger
                            disabled={isDeleteDisabled || isAllDisabled || item.isDeleted}
                            onClick={() => onDeleteClicked?.(item)}
                        >
                            <XIcon/>
                        </Button>
                    }
                </div>
            </div>
        </div>
    );
}
