import { ReactElement } from "react";
import css from "./css.module.scss";
import cn from "classnames";
import { COLORS } from "../../constants/Colors";

export interface BarLoaderProps {
    loading: boolean;
    color?: string;
}
// displays an infinite animating loader bar similar to material ui loader
// places itself at the top of its first relative parent and spans 100% width
export default function BarLoader({loading, color}: BarLoaderProps): ReactElement {
    return (
        <div className={cn(css.wrapper, loading && css.isVisible)}>
                <div className={css.line} style={{background: color ?? COLORS.PRIMARY_500}}></div>
            <div className={cn(css.subline, css.inc)} style={{background: color ?? COLORS.PRIMARY_500}}></div>
            <div className={cn(css.subline, css.dec)} style={{background: color ?? COLORS.PRIMARY_500}}></div>
        </div>
    );
}
