import { ReactElement } from "react";
import { BackgroundJob, JobStatus } from "../../../__generated__/generated_types";
import { Inline } from "@zendeskgarden/react-loaders";
import { Check, ErrorOutline } from "@material-ui/icons";
import { formatterDateTimeUS } from "../../../utils/formatters";
import { COLORS } from "../../../constants/Colors";

export function renderJobInformation(copyJob: BackgroundJob): ReactElement {
    if(copyJob.status === JobStatus.Submitted) {
        return <><Inline color={COLORS.PRIMARY_500} /> Job Submitted {formatterDateTimeUS(copyJob.createdAt)}</>;
    } else if(copyJob.status === JobStatus.Running) {
        return <><Inline color={COLORS.PRIMARY_500} /> Job Started at {formatterDateTimeUS(copyJob.started)}</>;
    } else if(copyJob.status === JobStatus.Completed) {
        return <><Check htmlColor={COLORS.GREEN_500} /> Job Completed at {formatterDateTimeUS(copyJob.ended)}</>;
    } else {
        return <><ErrorOutline htmlColor={COLORS.NEGATIVE_COLOR} /> Job Failed at {formatterDateTimeUS(copyJob.ended)}</>;
    }
}
